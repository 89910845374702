import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import db from "./Firebase";

function MyBusiness() {
    const navigate = useNavigate();
    const [userData, setUserData] = useState([]);
    const [sortColumn, setSortColumn] = useState(null);
    const [sortOrder, setSortOrder] = useState("asc");
    const [dataLimit, setDataLimit] = useState(20);

    useEffect(() => {
        const storedUsername = localStorage.getItem("email");
        const storedPassword = localStorage.getItem("password");
        const storedRemember = localStorage.getItem("remember");

        if (storedRemember === "false" || !storedUsername || !storedPassword) {
            navigate('/signin');
        }
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            const appointmentSnapshot = await db.collection("appointments_data").get();
            const enquirySnapshot = await db.collection("enquiry").get();
            const expenseSnapshot = await db.collection("expenses").get();
            const userDataMap = new Map(); // Using a Map to store unique usernames and their appointment counts

            // Process appointment data
            appointmentSnapshot.forEach((doc) => {
                const { username, type, status, service_name } = doc.data();

                if (!userDataMap.has(username)) {
                    userDataMap.set(username, {
                        username,
                        walkinCount: 0,
                        websiteCount: 0,
                        onlineCount: 0,
                        appointmentsBooked: 0,
                        completedCount: 0,
                        notCompletedCount: 0,
                        rejectedCount: 0,
                        skinCount: 0,
                        hairCount: 0,
                        makeupCount: 0,
                        nailCount: 0,
                        othersCount: 0,
                        enquiryCount: 0,
                        totalExpense: 0,
                        totalIncome: 0,
                    });
                }

                const userRecord = userDataMap.get(username);

                if (type === "walkin") {
                    userRecord.walkinCount += 1;
                } else if (type === "Website") {
                    userRecord.websiteCount += 1;
                } else if (type === "online") {
                    userRecord.onlineCount += 1;
                }

                if (status !== "0") {
                    userRecord.appointmentsBooked += 1;
                }
                if (status === "3") {
                    userRecord.rejectedCount += 1;
                }

                if (status === "2") {
                    userRecord.completedCount += 1;
                } else {
                    userRecord.notCompletedCount += 1;
                }

                switch (service_name) {
                    case "Skin":
                        userRecord.skinCount += 1;
                        break;
                    case "Hair":
                        userRecord.hairCount += 1;
                        break;
                    case "Makeup":
                        userRecord.makeupCount += 1;
                        break;
                    case "Nail":
                        userRecord.nailCount += 1;
                        break;
                    case "Others":
                        userRecord.othersCount += 1;
                        break;
                    default:
                        break;
                }
            });

            // Process enquiry data
            enquirySnapshot.forEach((doc) => {
                const { username } = doc.data();

                if (userDataMap.has(username)) {
                    userDataMap.get(username).enquiryCount += 1;
                }
            });

            // Process expenses data
            expenseSnapshot.forEach((doc) => {
                const { username, charges } = doc.data();

                if (userDataMap.has(username)) {
                    userDataMap.get(username).totalExpense = (userDataMap.get(username).totalExpense || 0) + charges;
                }
            });

            // Process income data
            appointmentSnapshot.forEach((doc) => {
                const { username, charges } = doc.data();

                if (userDataMap.has(username)) {
                    userDataMap.get(username).totalIncome = (userDataMap.get(username).totalIncome || 0) + charges;
                }
            });

            const formattedData = Array.from(userDataMap.values());

            setUserData(formattedData);
        };

        fetchData();
    }, []);

    const handleSort = (column) => {
        if (sortColumn === column) {
            // Toggle sorting order if the same column is clicked again
            setSortOrder(sortOrder === "asc" ? "desc" : "asc");
        } else {
            // Set the new sorting column and reset sorting order to ascending
            setSortColumn(column);
            setSortOrder("asc");
        }
    };

    const sortedData = userData.slice().sort((a, b) => {
        if (sortColumn === null) return 0;

        const aValue = a[sortColumn];
        const bValue = b[sortColumn];

        if (aValue < bValue) return sortOrder === "asc" ? -1 : 1;
        if (aValue > bValue) return sortOrder === "asc" ? 1 : -1;
        return 0;
    });

    // Function to render sorting icons for column headers
    const renderSortingIcon = (column) => {
        if (column === sortColumn) {
            return sortOrder === 'asc' ? '▲' : '▼';
        } return '';
    };

    const limitedData = sortedData.slice(0, dataLimit);

    const loadMoreData = () => {
        setDataLimit(dataLimit + 20);
    };

    return (
        <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg">
            <div class="p-2" style={{ textAlign: "start", paddingBottom: "5px" }}>
                <Link to="/admin_panel"><img src="/images/menubutton.png" class="menu-toggle" alt="Responsive image" style={{ height: "2rem" }} /></Link>
            </div>
            <div className="">
                <div className="p-2">
                    <div className="card">
                        <div className="card-header">
                            <h6 className="mb-2">Appointment Analyzer</h6>
                        </div>
                        <div>
                            <div className="card">
                                <div class="table-responsive fix-width scroll-inner">
                                    <table class="table table-bordered table-hover">
                                        <thead class="thead-dark" style={{ position: "sticky", top: "0" }}>
                                            <tr style={{ textAlign: "left" }}>
                                                <th className="col ps-2" onClick={() => handleSort("username")}>
                                                    Username {renderSortingIcon("username")}
                                                </th>
                                                <th className="col ps-2" style={{ cursor: "pointer" }} onClick={() => handleSort("walkinCount")}>
                                                    Walkin {renderSortingIcon("walkinCount")}
                                                </th>
                                                <th className="col ps-2" style={{ cursor: "pointer" }} onClick={() => handleSort("websiteCount")}>
                                                    Website {renderSortingIcon("websiteCount")}
                                                </th>
                                                <th className="col ps-2" style={{ cursor: "pointer" }} onClick={() => handleSort("onlineCount")}>
                                                    Online {renderSortingIcon("onlineCount")}
                                                </th>
                                                <th className="col ps-2" style={{ cursor: "pointer" }} onClick={() => handleSort("appointmentsBooked")}>
                                                    App. Booked {renderSortingIcon("appointmentsBooked")}
                                                </th>
                                                <th className="col ps-2" style={{ cursor: "pointer" }} onClick={() => handleSort("completedCount")}>
                                                    Completed {renderSortingIcon("completedCount")}
                                                </th>
                                                <th className="col ps-2" style={{ cursor: "pointer" }} onClick={() => handleSort("notCompletedCount")}>
                                                    Not Completed {renderSortingIcon("notCompletedCount")}
                                                </th>
                                                <th className="col ps-2" style={{ cursor: "pointer" }} onClick={() => handleSort("rejectedCount")}>
                                                    Rejected {renderSortingIcon("rejectedCount")}
                                                </th>
                                                <th className="col ps-2" style={{ cursor: "pointer" }} onClick={() => handleSort("skinCount")}>
                                                    Skin {renderSortingIcon("skinCount")}
                                                </th>
                                                <th className="col ps-2" style={{ cursor: "pointer" }} onClick={() => handleSort("hairCount")}>
                                                    Hair {renderSortingIcon("hairCount")}
                                                </th>
                                                <th className="col ps-2" style={{ cursor: "pointer" }} onClick={() => handleSort("makeupCount")}>
                                                    Makeup {renderSortingIcon("makeupCount")}
                                                </th>
                                                <th className="col ps-2" style={{ cursor: "pointer" }} onClick={() => handleSort("nailCount")}>
                                                    Nail {renderSortingIcon("nailCount")}
                                                </th>
                                                <th className="col ps-2" style={{ cursor: "pointer" }} onClick={() => handleSort("othersCount")}>
                                                    Others {renderSortingIcon("othersCount")}
                                                </th>
                                                <th className="col ps-2" style={{ cursor: "pointer" }} onClick={() => handleSort("enquiryCount")}>
                                                    Enquiry Count {renderSortingIcon("enquiryCount")}
                                                </th>
                                                <th className="col ps-2" style={{ cursor: "pointer" }} onClick={() => handleSort("totalExpense")}>
                                                    Total Expense {renderSortingIcon("totalExpense")}
                                                </th>
                                                <th className="col ps-2" style={{ cursor: "pointer" }} onClick={() => handleSort("totalIncome")}>
                                                    Total Income {renderSortingIcon("totalIncome")}
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody style={{ textAlign: "left" }}>
                                            {limitedData.map((userRecord, index) => (
                                                <tr key={index}>
                                                    <td>
                                                        <a href={userRecord.username} target="_blank" rel="noopener noreferrer">{userRecord.username}</a>
                                                    </td>
                                                    <td>{userRecord.walkinCount}</td>
                                                    <td>{userRecord.websiteCount}</td>
                                                    <td>{userRecord.onlineCount}</td>
                                                    <td>{userRecord.appointmentsBooked}</td>
                                                    <td>{userRecord.completedCount}</td>
                                                    <td>{userRecord.notCompletedCount}</td>
                                                    <td>{userRecord.rejectedCount}</td>
                                                    <td>{userRecord.skinCount}</td>
                                                    <td>{userRecord.hairCount}</td>
                                                    <td>{userRecord.makeupCount}</td>
                                                    <td>{userRecord.nailCount}</td>
                                                    <td>{userRecord.othersCount}</td>
                                                    <td>{userRecord.enquiryCount}</td>
                                                    <td>{userRecord.totalExpense || 0}</td>
                                                    <td>{userRecord.totalIncome || 0}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    <div className="" style={{ paddingTop: "2rem" }}>
                                        <button onClick={loadMoreData} className="btn btn-dark">Load More</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
}

export default MyBusiness;



// import React, { useState, useEffect } from 'react';
// import { useNavigate, Link } from 'react-router-dom';
// import db from "./Firebase";

// function MyBusiness() {
//     const navigate = useNavigate();
//     const [userData, setUserData] = useState([]);
//     const [sortColumn, setSortColumn] = useState(null);
//     const [sortOrder, setSortOrder] = useState("asc");

//     useEffect(() => {
//         const storedUsername = localStorage.getItem("email");
//         const storedPassword = localStorage.getItem("password");
//         const storedRemember = localStorage.getItem("remember");

//         if (storedRemember === "false" || !storedUsername || !storedPassword) {
//             navigate('/signin');
//         }
//     }, []);

//     useEffect(() => {
//         const fetchData = async () => {
//             const appointmentSnapshot = await db.collection("appointments_data").get();
//             const enquirySnapshot = await db.collection("enquiry").get();
//             const expenseSnapshot = await db.collection("expenses").get();
//             const userDataMap = new Map(); // Using a Map to store unique usernames and their appointment counts

//             // Process appointment data
//             appointmentSnapshot.forEach((doc) => {
//                 const { username, type, status, service_name } = doc.data();

//                 if (!userDataMap.has(username)) {
//                     userDataMap.set(username, {
//                         username,
//                         walkinCount: 0,
//                         websiteCount: 0,
//                         onlineCount: 0,
//                         appointmentsBooked: 0,
//                         completedCount: 0,
//                         notCompletedCount: 0,
//                         rejectedCount: 0,
//                         skinCount: 0,
//                         hairCount: 0,
//                         makeupCount: 0,
//                         nailCount: 0,
//                         othersCount: 0,
//                         enquiryCount: 0,
//                         totalExpense: 0,
//                         totalIncome: 0,
//                     });
//                 }

//                 const userRecord = userDataMap.get(username);

//                 if (type === "walkin") {
//                     userRecord.walkinCount += 1;
//                 } else if (type === "Website") {
//                     userRecord.websiteCount += 1;
//                 } else if (type === "online") {
//                     userRecord.onlineCount += 1;
//                 }

//                 // userRecord.appointmentsBooked += 1;

//                 if (status !== "0") {
//                     userRecord.appointmentsBooked += 1;
//                 }
//                 if (status === "3") {
//                     userRecord.rejectedCount += 1;
//                 }

//                 if (status === "2") {
//                     userRecord.completedCount += 1;
//                 } else {
//                     userRecord.notCompletedCount += 1;
//                 }

//                 switch (service_name) {
//                     case "Skin":
//                         userRecord.skinCount += 1;
//                         break;
//                     case "Hair":
//                         userRecord.hairCount += 1;
//                         break;
//                     case "Makeup":
//                         userRecord.makeupCount += 1;
//                         break;
//                     case "Nail":
//                         userRecord.nailCount += 1;
//                         break;
//                     case "Others":
//                         userRecord.othersCount += 1;
//                         break;
//                     default:
//                         break;
//                 }
//             });

//             // Process enquiry data
//             enquirySnapshot.forEach((doc) => {
//                 const { username } = doc.data();

//                 if (userDataMap.has(username)) {
//                     userDataMap.get(username).enquiryCount += 1;
//                 }
//             });

//             // Process expenses data
//             expenseSnapshot.forEach((doc) => {
//                 const { username, charges } = doc.data();

//                 if (userDataMap.has(username)) {
//                     userDataMap.get(username).totalExpense = (userDataMap.get(username).totalExpense || 0) + charges;
//                 }
//             });

//             // Process income data
//             appointmentSnapshot.forEach((doc) => {
//                 const { username, charges } = doc.data();

//                 if (userDataMap.has(username)) {
//                     userDataMap.get(username).totalIncome = (userDataMap.get(username).totalIncome || 0) + charges;
//                 }
//             });



//             const formattedData = Array.from(userDataMap.values());

//             setUserData(formattedData);
//         };

//         fetchData();
//     }, []);

//     const handleSort = (column) => {
//         if (sortColumn === column) {
//             // Toggle sorting order if the same column is clicked again
//             setSortOrder(sortOrder === "asc" ? "desc" : "asc");
//         } else {
//             // Set the new sorting column and reset sorting order to ascending
//             setSortColumn(column);
//             setSortOrder("asc");
//         }
//     };

//     const sortedData = userData.slice().sort((a, b) => {
//         if (sortColumn === null) return 0;

//         const aValue = a[sortColumn];
//         const bValue = b[sortColumn];

//         if (aValue < bValue) return sortOrder === "asc" ? -1 : 1;
//         if (aValue > bValue) return sortOrder === "asc" ? 1 : -1;
//         return 0;
//     });

//     // Function to render sorting icons for column headers
//     const renderSortingIcon = (column) => {
//         if (column === sortColumn) {
//             return sortOrder === 'asc' ? '▲' : '▼';
//         } return '';
//     };

//     return (
//         <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg">
//             <div class="p-2" style={{ textAlign: "start", paddingBottom: "5px" }}>
//                 {/* <span class="btn bg-gradient-secondary btn-sm mb-0 mr-2" style={{ color: "white" }} >&nbsp; Download Excel</span> */}
//                 <Link to="/admin_panel"><img src="/images/menubutton.png" class="menu-toggle" alt="Responsive image" style={{ height: "2rem" }} /></Link>

//             </div>
//             <div className="">
//                 <div className="p-2">
//                     <div className="card">
//                         <div className="card-header">
//                             <h6 className="mb-2">Appointment Analyzer</h6>
//                         </div>
//                         <div>
//                             <div className="card">
//                                 <div class="table-responsive fix-width scroll-inner">
//                                     <table class="table table-bordered table-hover">
//                                         <thead class="thead-dark" style={{ position: "sticky", top: "0" }}>
//                                             <tr style={{ textAlign: "left" }}>
//                                                 <th className="col ps-2" onClick={() => handleSort("username")}>
//                                                     Username {renderSortingIcon("username")}
//                                                 </th>
//                                                 <th className="col ps-2" style={{ cursor: "pointer" }} onClick={() => handleSort("walkinCount")}>
//                                                     Walkin {renderSortingIcon("walkinCount")}
//                                                 </th>
//                                                 <th className="col ps-2" style={{ cursor: "pointer" }} onClick={() => handleSort("websiteCount")}>
//                                                     Website {renderSortingIcon("websiteCount")}
//                                                 </th>
//                                                 <th className="col ps-2" style={{ cursor: "pointer" }} onClick={() => handleSort("onlineCount")}>
//                                                     Online {renderSortingIcon("onlineCount")}
//                                                 </th>
//                                                 <th className="col ps-2" style={{ cursor: "pointer" }} onClick={() => handleSort("appointmentsBooked")}>
//                                                     App. Booked {renderSortingIcon("appointmentsBooked")}
//                                                 </th>
//                                                 <th className="col ps-2" style={{ cursor: "pointer" }} onClick={() => handleSort("completedCount")}>
//                                                     Completed {renderSortingIcon("completedCount")}
//                                                 </th>
//                                                 <th className="col ps-2" style={{ cursor: "pointer" }} onClick={() => handleSort("notCompletedCount")}>
//                                                     Not Completed {renderSortingIcon("notCompletedCount")}
//                                                 </th>
//                                                 <th className="col ps-2" style={{ cursor: "pointer" }} onClick={() => handleSort("notCompletedCount")}>
//                                                     Rejected {renderSortingIcon("notCompletedCount")}
//                                                 </th>
//                                                 <th className="col ps-2" style={{ cursor: "pointer" }} onClick={() => handleSort("skinCount")}>
//                                                     Skin {renderSortingIcon("skinCount")}
//                                                 </th>
//                                                 <th className="col ps-2" style={{ cursor: "pointer" }} onClick={() => handleSort("hairCount")}>
//                                                     Hair {renderSortingIcon("hairCount")}
//                                                 </th>
//                                                 <th className="col ps-2" style={{ cursor: "pointer" }} onClick={() => handleSort("makeupCount")}>
//                                                     Makeup {renderSortingIcon("makeupCount")}
//                                                 </th>
//                                                 <th className="col ps-2" style={{ cursor: "pointer" }} onClick={() => handleSort("nailCount")}>
//                                                     Nail {renderSortingIcon("nailCount")}
//                                                 </th>
//                                                 <th className="col ps-2" style={{ cursor: "pointer" }} onClick={() => handleSort("othersCount")}>
//                                                     Others {renderSortingIcon("othersCount")}
//                                                 </th>
//                                                 <th className="col ps-2" style={{ cursor: "pointer" }} onClick={() => handleSort("enquiryCount")}>
//                                                     Enquiry Count {renderSortingIcon("enquiryCount")}
//                                                 </th>
//                                                 <th className="col ps-2" style={{ cursor: "pointer" }} onClick={() => handleSort("totalExpense")}>
//                                                     Total Expense {renderSortingIcon("totalExpense")}
//                                                 </th>
//                                                 <th className="col ps-2" style={{ cursor: "pointer" }} onClick={() => handleSort("totalExpense")}>
//                                                     Total Income
//                                                 </th>
//                                             </tr>
//                                         </thead>
//                                         <tbody style={{ textAlign: "left" }}>
//                                             {sortedData.map((userRecord, index) => (
//                                                 <tr key={index}>
//                                                     <td>
//                                                         <a href={userRecord.username} target="_blank" rel="noopener noreferrer">{userRecord.username}</a>
//                                                     </td>
//                                                     <td>{userRecord.walkinCount}</td>
//                                                     <td>{userRecord.websiteCount}</td>
//                                                     <td>{userRecord.onlineCount}</td>
//                                                     <td>{userRecord.appointmentsBooked}</td>
//                                                     <td>{userRecord.completedCount}</td>
//                                                     <td>{userRecord.notCompletedCount}</td>
//                                                     <td>{userRecord.rejectedCount}</td>
//                                                     <td>{userRecord.skinCount}</td>
//                                                     <td>{userRecord.hairCount}</td>
//                                                     <td>{userRecord.makeupCount}</td>
//                                                     <td>{userRecord.nailCount}</td>
//                                                     <td>{userRecord.othersCount}</td>
//                                                     <td>{userRecord.enquiryCount}</td>
//                                                     <td>{userRecord.totalExpense || 0}</td>
//                                                     <td>{userRecord.totalIncome || 0}</td>
//                                                 </tr>
//                                             ))}
//                                         </tbody>
//                                     </table>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </main>
//     );
// }

// export default MyBusiness;





// // import React, { useState, useEffect } from 'react';
// // import { useNavigate } from 'react-router-dom';
// // import db from "./Firebase";

// // function AppointmentAnalyser() {
// //     const navigate = useNavigate();
// //     const [userData, setUserData] = useState([]);

// //     useEffect(() => {
// //         const storedUsername = localStorage.getItem("email");
// //         const storedPassword = localStorage.getItem("password");
// //         const storedRemember = localStorage.getItem("remember");

// //         if (storedRemember === "false" || !storedUsername || !storedPassword) {
// //             navigate('/signin');
// //         }
// //     }, []);

// //     useEffect(() => {
// //         const fetchData = async () => {
// //             const snapshot = await db.collection("appointments").get();
// //             const userDataMap = new Map(); // Using a Map to store unique usernames and their appointment counts

// //             snapshot.forEach((doc) => {
// //                 const { username, type, status, service_name } = doc.data();

// //                 if (!userDataMap.has(username)) {
// //                     userDataMap.set(username, {
// //                         username,
// //                         walkinCount: 0,
// //                         websiteCount: 0,
// //                         onlineCount: 0,
// //                         appointmentsBooked: 0,
// //                         completedCount: 0,
// //                         notCompletedCount: 0,
// //                         rejectedCount: 0,
// //                         skinCount: 0,
// //                         hairCount: 0,
// //                         makeupCount: 0,
// //                         nailCount: 0,
// //                         othersCount: 0,
// //                     });
// //                 }

// //                 const userRecord = userDataMap.get(username);

// //                 if (type === "walkin") {
// //                     userRecord.walkinCount += 1;
// //                 } else if (type === "Website") {
// //                     userRecord.websiteCount += 1;
// //                 } else if (type === "online") {
// //                     userRecord.onlineCount += 1;
// //                 }

// //                 userRecord.appointmentsBooked += 1;

// //                 if (status === "2") {
// //                     userRecord.completedCount += 1;
// //                 }
// //                 else if (status === "3") {
// //                     userRecord.rejectedCount += 1;
// //                 } else {
// //                     userRecord.notCompletedCount += 1;
// //                 }

// //                 switch (service_name) {
// //                     case "Skin":
// //                         userRecord.skinCount += 1;
// //                         break;
// //                     case "Hair":
// //                         userRecord.hairCount += 1;
// //                         break;
// //                     case "Makeup":
// //                         userRecord.makeupCount += 1;
// //                         break;
// //                     case "Nail":
// //                         userRecord.nailCount += 1;
// //                         break;
// //                     case "Others":
// //                         userRecord.othersCount += 1;
// //                         break;
// //                     default:
// //                         break;
// //                 }
// //             });

// //             const formattedData = Array.from(userDataMap.values());

// //             setUserData(formattedData);
// //         };

// //         fetchData();
// //     }, []);

// //     return (
// //         <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg">
// //             <div className="row">
// //                 <div className="mt-4 p-4">
// //                     <div className="card">
// //                         <div className="card-header pb-0 px-3">
// //                             <h6 className="mb-2">Appointment Analyzer</h6>
// //                         </div>
// //                         <div>
// //                             <div className="card">
// //                                 <div className="table-responsive">
// //                                     <table className="table align-items-center mb-0">
// //                                         <thead>
// //                                             <tr style={{ textAlign: "left" }}>
// //                                                 <th className="col ps-2">
// //                                                     Username
// //                                                 </th>
// //                                                 <th className="col ps-2">
// //                                                     Appo. Booked
// //                                                 </th>
// //                                                 <th className="col ps-2">
// //                                                     Walkin
// //                                                 </th>
// //                                                 <th className="col ps-2">
// //                                                     Website
// //                                                 </th>
// //                                                 <th className="col ps-2">
// //                                                     Online
// //                                                 </th>

// //                                                 <th className="col ps-2">
// //                                                     Completed
// //                                                 </th>
// //                                                 <th className="col ps-2">
// //                                                     Not Completed
// //                                                 </th>
// //                                                 <th className="col ps-2">
// //                                                     Rejected
// //                                                 </th>
// //                                                 <th className="col ps-2">
// //                                                     Skin
// //                                                 </th>
// //                                                 <th className="col ps-2">
// //                                                     Hair
// //                                                 </th>
// //                                                 <th className="col ps-2">
// //                                                     Makeup
// //                                                 </th>
// //                                                 <th className="col ps-2">
// //                                                     Nail
// //                                                 </th>
// //                                                 <th className="col ps-2">
// //                                                     Others
// //                                                 </th>
// //                                             </tr>
// //                                         </thead>
// //                                         <tbody style={{ textAlign: "left" }}>
// //                                             {userData.map((userRecord, index) => (
// //                                                 <tr key={index}>
// //                                                     <td>
// //                                                         <a href={userRecord.username} target="_blank" rel="noopener noreferrer">{userRecord.username}</a>
// //                                                     </td>
// //                                                     <td>{userRecord.appointmentsBooked}</td>
// //                                                     <td>{userRecord.walkinCount}</td>
// //                                                     <td>{userRecord.websiteCount}</td>
// //                                                     <td>{userRecord.onlineCount}</td>
// //                                                     <td>{userRecord.completedCount}</td>
// //                                                     <td>{userRecord.notCompletedCount}</td>
// //                                                     <td>{userRecord.rejectedCount}</td>
// //                                                     <td>{userRecord.skinCount}</td>
// //                                                     <td>{userRecord.hairCount}</td>
// //                                                     <td>{userRecord.makeupCount}</td>
// //                                                     <td>{userRecord.nailCount}</td>
// //                                                     <td>{userRecord.othersCount}</td>
// //                                                 </tr>
// //                                             ))}
// //                                         </tbody>
// //                                     </table>
// //                                 </div>
// //                             </div>
// //                         </div>
// //                     </div>
// //                 </div>
// //             </div>
// //         </main>
// //     );
// // }

// // export default AppointmentAnalyser;
