import React from "react";

function GlorySlider() {
    return (
        <>
            <div id="carousel_new">

                <div class="hideLeft">
                    <img src="https://images.pexels.com/photos/7031286/pexels-photo-7031286.jpeg?auto=compress&cs=tinysrgb&w=600" width="150" height="250" />
                </div>

                <div class="prevLeftSecond">
                    <img src="https://images.pexels.com/photos/6942657/pexels-photo-6942657.jpeg?auto=compress&cs=tinysrgb&w=600" width="150" height="250" />
                </div>

                <div class="prev">
                    <img src="https://images.pexels.com/photos/10434979/pexels-photo-10434979.jpeg?auto=compress&cs=tinysrgb&w=600" width="150" height="250" />
                </div>

                <div class="selected">
                    <img src="https://i1.sndcdn.com/artworks-000062423439-lf7ll2-t500x500.jpg" width="150" height="250" />
                </div>

                <div class="next">
                    <img src="https://i1.sndcdn.com/artworks-000028787381-1vad7y-t500x500.jpg" width="150" height="250" />
                </div>

                <div class="nextRightSecond">
                    <img src="https://i1.sndcdn.com/artworks-000108468163-dp0b6y-t500x500.jpg" width="150" height="250" />
                </div>

                <div class="hideRight">
                    <img src="https://i1.sndcdn.com/artworks-000064920701-xrez5z-t500x500.jpg" width="150" height="250" />
                </div>

            </div>

            {/* <div class="buttons">
                    <button onClick={() => window.moveToSelected("prev")}>Prev</button>
                    <button onClick={() => window.moveToSelected("next")}>Next</button>
                </div> */}

        </>
    );
}

export default GlorySlider;


// import React, { useState, useEffect } from "react";

// function GlorySlider() {
//     return (
//         <>
//             <div id="carousel" style={{ marginLeft: "68px" }}>

//                 <div class="slide">
//                     <p><img class="carousel-image" alt="Image Caption" src="https://unsplash.it/300/200?image=432" />
//                         <span> Curabitur nec dapibus neque. Aenean orci enim, rutrum ac lacinia in, dapibus sit amet lorem. </span>
//                     </p>
//                 </div>

//                 <div class="slide">
//                     <p><a href="#"><img class="carousel-image" alt="Image Caption" src="https://unsplash.it/300/200?image=232" /></a>
//                         <span> Curabitur nec dapibus neque. Aenean orci enim, rutrum ac lacinia in, dapibus sit amet lorem. </span>
//                     </p>
//                 </div>

//                 <div class="slide">
//                     <p><a href="#"><img class="carousel-image" alt="Image Caption" src="https://unsplash.it/300/200?image=200" /></a>
//                         <span> Curabitur nec dapibus neque. Aenean orci enim, rutrum ac lacinia in, dapibus sit amet lorem. </span>
//                     </p>
//                 </div>

//                 <div class="slide">
//                     <p><a href="#"><img class="carousel-image" alt="Image Caption" src="https://unsplash.it/300/200?image=350" /></a>
//                         <span> Curabitur nec dapibus neque. Aenean orci enim, rutrum ac lacinia in, dapibus sit amet lorem. </span>
//                     </p>
//                 </div>

//                 <div class="slide">
//                     <p><a href="#"><img class="carousel-image" alt="Image Caption" src="https://unsplash.it/300/200?image=400" /></a>
//                         <span> Curabitur nec dapibus neque. Aenean orci enim, rutrum ac lacinia in, dapibus sit amet lorem. </span>
//                     </p>
//                 </div>

//                 <div class="slide">
//                     <p><a href="#"><img class="carousel-image" alt="Image Caption" src="https://unsplash.it/300/200?image=450" /></a>
//                         <span> Curabitur nec dapibus neque. Aenean orci enim, rutrum ac lacinia in, dapibus sit amet lorem. </span>
//                     </p>
//                 </div>

//                 <div class="slide">
//                     <p><a href="#"><img class="carousel-image" alt="Image Caption" src="https://unsplash.it/300/200?image=500" /></a>
//                         <span> Curabitur nec dapibus neque. Aenean orci enim, rutrum ac lacinia in, dapibus sit amet lorem. </span>
//                     </p>
//                 </div>

//                 <div class="slide">
//                     <p><a href="#"><img class="carousel-image" alt="Image Caption" src="https://unsplash.it/300/200?image=550" /></a>
//                         <span> Curabitur nec dapibus neque. Aenean orci enim, rutrum ac lacinia in, dapibus sit amet lorem. </span>
//                     </p>
//                 </div>

//                 <div class="slide">
//                     <p><a href="#"><img class="carousel-image" alt="Image Caption" src="https://unsplash.it/300/200?image=600" /></a>
//                         <span> Curabitur nec dapibus neque. Aenean orci enim, rutrum ac lacinia in, dapibus sit amet lorem. </span>
//                     </p>
//                 </div>

//                 <div class="slide">
//                     <p><a href="#"><img class="carousel-image" alt="Image Caption" src="https://unsplash.it/300/200?image=630" /></a>
//                         <span> Curabitur nec dapibus neque. Aenean orci enim, rutrum ac lacinia in, dapibus sit amet lorem. </span>
//                     </p>
//                 </div>

//                 <div class="navigate-left"><img
//                     src="https://images.vexels.com/media/users/3/128922/isolated/preview/1cec3d0747fc796080b17ca07e14d187--cone-redondo-da-seta-do-aeroporto-de-viagem-by-vexels.png"
//                     width="35px" /></div>
//                 <div class="navigate-right"><img
//                     src="https://images.vexels.com/media/users/3/128927/isolated/preview/25652eb37d257753ffab9b4ac8edfa28--cone-de-rodada-do-aeroporto-de-viagem-seta-by-vexels.png"
//                     width="35px" /></div>

//             </div>
//         </>
//     );
// }

// export default GlorySlider;
