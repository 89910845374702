import React, { useState, useEffect } from 'react';
import AdminPanel from './AdminPanel';
import Swal from 'sweetalert2'
import { useNavigate, Link, useParams } from 'react-router-dom';
import db from "./Firebase";
import { storage } from './Firebase';
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import ViewAppData from './ViewAppData';
import Multiselect from 'multiselect-react-dropdown';


function FreeBannerDesign() {
    const navigate = useNavigate();
    const moment = extendMoment(Moment);
    const initialFormStateFeedback = { id: '', name: '', rating: '', status: '', image_url: '', created_date: new Date() }
    const [submit, setSubmit] = useState(initialFormStateFeedback)
    const [data, setData] = useState([]);
    const [editID, setEditID] = useState("");
    const [edit, setEdit] = useState(false);
    const [ShowProgress, setShowProgress] = useState(false);
    const [show, setshow] = useState(true);
    const [progresspercent, setProgresspercent] = useState(0);

    const [options, setOptions] = useState([
        { name: 'Online' },
        { name: 'Aurangabad' },
        { name: 'Bathinda' },
        { name: 'Ghaziabad' },
        { name: 'Meerut' },
        { name: 'Mansa' },
        { name: 'Patiala' }
    ]);

    const [selectedValue, setSelectedValue] = useState([]);


    const onSelect = (selectedList, selectedItem) => {
        // Handle selection
        // ...
        setSelectedValue(selectedList);
    }

    const onRemove = (selectedList, removedItem) => {
        // Handle removal
        // ...
        setSelectedValue(selectedList);
    }

    useEffect(() => {
        const storedUsername = localStorage.getItem("email");
        const storedPassword = localStorage.getItem("password");
        const storedRemember = localStorage.getItem("remember");

        if (storedRemember === "false" || !storedUsername || !storedPassword) {
            navigate('/signin');
        }
    }, []);


    const handleChange = (e) => {
        const { id, value } = e.target
        setSubmit({ ...submit, [id]: value })
    }

    const changeStatus = (data, id) => {
        setEdit(true)
        setEditID(id)
        setSubmit({ id: id, name: data.name, rating: data.rating, status: data.status, image_url: data.image_url, created_date: new Date() });
        openModalShow();
    }

    const changeStatusAdd = () => {
        setEdit(false)
        setSubmit({ id: '', name: '', rating: '', status: '', image_url: '', created_date: new Date() });
        openModalShow();
    }


    const openModalShow = () => {
        // alert("ooo")
        window.openModelshow();
    }

    const closeModel = () => {
        window.openModelNew();
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        // console.log(locationData);

        if (edit === false) {
            const file = e.target[0]?.files[0]
            // console.log(file)
            if (!file) return;
            const storageRef = ref(storage, `smg_files/${file.name}`);
            const uploadTask = uploadBytesResumable(storageRef, file);
            setshow(false)

            uploadTask.on("state_changed",
                (snapshot) => {
                    const progress =
                        Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                    setProgresspercent(progress);
                },
                (error) => {
                    alert(error);
                },
                () => {

                    getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                        // setImgUrl(downloadURL)
                        db.collection("free_banner_designs").add({
                            'image_url': downloadURL,
                            'created_date': new Date(),
                            'name': submit.name,
                            'status': "1",
                            'rating': submit.rating,
                        }).then(() => {
                            console.log('Created Successfully');
                            const Toast = Swal.mixin({
                                toast: true,
                                background: '#69aba6',
                                position: 'top-end',
                                showConfirmButton: false,
                                timer: 3000,
                                timerProgressBar: true,
                                didOpen: (toast) => {
                                    toast.addEventListener('mouseenter', Swal.stopTimer)
                                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                                }
                            })

                            Toast.fire({
                                icon: 'success',
                                title: 'Created Successfully'
                            })
                            closeModel();
                            setshow(true)
                            setSubmit({ image_url: '', created_date: new Date() })
                        })
                    });
                }
            );

        } else {
            if (e.target[0]?.files[0] == null) {
                db.collection("free_banner_designs").doc(editID).update({
                    'name': submit.name,
                    'status': "1",
                    'rating': submit.rating,
                    'image_url': submit.image_url,
                    'created_date': new Date(),
                }).then(() => {
                    console.log('Updated Successfully');
                    const Toast = Swal.mixin({
                        toast: true,
                        background: '#69aba6',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', Swal.stopTimer)
                            toast.addEventListener('mouseleave', Swal.resumeTimer)
                        }
                    })

                    Toast.fire({
                        icon: 'success',
                        title: 'Updated Successfully'
                    })
                    closeModel();

                    setSubmit({ id: '', name: '', rating: '', status: '', image_url: '', created_date: new Date() })
                })
            } else {
                const file = e.target[0]?.files[0]
                // console.log(file)
                if (!file) return;
                const storageRef = ref(storage, `smg_files/${file.name}`);
                const uploadTask = uploadBytesResumable(storageRef, file);
                setshow(false)

                uploadTask.on("state_changed",
                    (snapshot) => {
                        const progress =
                            Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                        setProgresspercent(progress);
                    },
                    (error) => {
                        alert(error);
                    },
                    () => {

                        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                            // setImgUrl(downloadURL)
                            db.collection("free_banner_designs").doc(editID).update({
                                'name': submit.name,
                                'status': "1",
                                'rating': submit.rating,
                                'image_url': downloadURL,
                                'created_date': new Date(),
                            }).then(() => {
                                console.log('Updated Successfully');
                                const Toast = Swal.mixin({
                                    toast: true,
                                    background: '#69aba6',
                                    position: 'top-end',
                                    showConfirmButton: false,
                                    timer: 3000,
                                    timerProgressBar: true,
                                    didOpen: (toast) => {
                                        toast.addEventListener('mouseenter', Swal.stopTimer)
                                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                                    }
                                })

                                Toast.fire({
                                    icon: 'success',
                                    title: 'Updated Successfully'
                                })
                                closeModel();
                                setshow(true)
                                setSubmit({ id: '', name: '', rating: '', status: '', image_url: '', created_date: new Date() })
                            })
                        });
                    }
                );
            }
        }



    }



    useEffect(() => {
        db.collection("free_banner_designs").orderBy("created_date", "desc").onSnapshot((snapshot) => {

            setData(
                snapshot.docs.map((doc) => ({
                    id: doc.id,
                    data: doc.data(),
                }))
            );
        });


    }, []);

    return (
        <>
            <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
                <div class="p-2" style={{ paddingBottom: "5px", display: "flex", justifyContent: "space-between" }}>
                    {/* <span class="btn bg-gradient-secondary btn-sm mb-0 mr-2" style={{ color: "white" }} >&nbsp; Download Excel</span> */}
                    <Link to="/admin_panel"><img src="/images/menubutton.png" class="menu-toggle" alt="Responsive image" style={{ height: "2rem" }} /></Link>
                    <a class="btn bg-gradient-primary btn-sm mb-0" style={{ color: "white" }} onClick={() => changeStatusAdd()}>+&nbsp; Add</a>
                </div>

                <div class="p-2">
                    <div style={{ textAlign: "end", paddingBottom: "5px" }}>


                    </div>

                    <div class="card">
                        <div class="card-header pb-0 px-3">
                            <h6 class="mb-2">Free Banner Design</h6>
                        </div>
                        <div class="card">
                            <div class="table-responsive fix-width scroll-inner">
                                <table class="table table-bordered table-hover">
                                    <thead class="thead-dark" style={{ position: "sticky", top: "0" }}>
                                        <tr style={{ textAlign: "left" }}>
                                            <th class="col">Image</th>
                                            <th class="col ps-2">Name</th>
                                            <th class="text-center col">Path</th>
                                            <th class="text-center col">Status</th>
                                            <th class="text-center col">Updated Date</th>
                                            <th class="text-center col">Action</th>
                                            {/* <th class="text-secondary opacity-7"></th> */}
                                        </tr>
                                    </thead>
                                    <tbody style={{ textAlign: "left" }}>
                                        {data?.map(({ id, data }, index) => (
                                            <>
                                                <tr>
                                                    <td>
                                                        <div class="d-flex px-2 py-1">
                                                            <div style={{ width: "7rem", marginRight: "13px" }}>
                                                                <img src={data.image_url} class="me-3" style={{ height: "4rem" }} />
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <p class="text-xs font-weight-bold mb-0">{data.name}</p>
                                                        {/* <p class="text-xs text-secondary mb-0">Organization</p> */}
                                                    </td>
                                                    <td class="align-middle text-center text-sm">
                                                        <p class="text-xs font-weight-bold mb-0">{data.rating}</p>
                                                    </td>
                                                    <td class="align-middle text-center text-sm">
                                                        <p class="text-xs font-weight-bold mb-0">{data.status}</p>
                                                    </td>
                                                    <td class="align-middle text-center text-sm">
                                                        <span class="text-secondary text-xs font-weight-normal">{moment(data.created_date.toDate().toISOString()).format("MM/DD/YY, h:mm a")}</span>
                                                    </td>

                                                    <td class="align-middle text-center" onClick={() => changeStatus(data, id)}>
                                                        <span class="text-info text-xs font-weight-normal" style={{ cursor: "pointer" }}>Edit</span>
                                                    </td>

                                                </tr>
                                            </>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

            </main>

            <div class="col-md-4">
                <div class="myId modal fade" id="" tabindex="-1" role="dialog" aria-labelledby="modal-form" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered" role="document">
                        <div class="modal-content">
                            <div class="modal-body p-0">
                                <div class="card card-plain">
                                    <form onSubmit={handleSubmit}>
                                        <div class="card-body">
                                            <div class="modal-body">
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <span>Image:</span>
                                                        {submit.image_url == "" ? <></> : <img src={submit.image_url} class="avatar avatar-sm me-3" />}
                                                        <div class="">
                                                            {edit ? <input type="file" id="image" class="" onfocus="focused(this)" onfocusout="defocused(this)" placeholder="Image" /> : <input type="file" id="image" class="" onfocus="focused(this)" onfocusout="defocused(this)" placeholder="Image" required />}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="">
                                                    <div class="col-md-6">
                                                        <li>
                                                            <label style={{ display: "flex" }}>Name</label>
                                                            <input type="text" id="name" class="form-control" onfocus="focused(this)" onfocusout="defocused(this)" placeholder="Name" onChange={handleChange} value={submit.name} required />
                                                        </li>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <li>
                                                            <label style={{ display: "flex" }}>Rating</label>
                                                            <input type="text" id="rating" class="form-control" onfocus="focused(this)" onfocusout="defocused(this)" placeholder="Rating" onChange={handleChange} value={submit.rating} required />
                                                        </li>
                                                    </div>
                                                    {/* <div class="col-md-6">
                                                        <li>
                                                            <label style={{ display: "flex" }}>Select Type</label>
                                                            <select class="form-control" id="type" onChange={handleChange} value={submit.rating} required>
                                                                <option value="">Select Rating</option>
                                                                <option value="WorkshopList">Workshop List</option>
                                                                <option value="PortfolioPreview">Portfolio Preview</option>
                                                                <option value="Courses">Courses</option>
                                                                <option value="CreatePoster">Create Poster</option>
                                                                <option value="VisitingCard">Visiting Card</option>
                                                                <option value="AppointmentList">Appointment List</option>
                                                                <option value="Enquiries">Enquiries</option>
                                                                <option value="UnderReviewPage">UnderReview Page</option>
                                                                <option value="noti">Notification</option>
                                                            </select>
                                                        </li>
                                                    </div> */}

                                                </div>

                                            </div>
                                            <div class="modal-footer">
                                                <a class="btn bg-gradient-info btn-sm mb-0" style={{ color: "white" }} onClick={closeModel}>Close</a>
                                                <button type='submit' class="btn bg-gradient-danger btn-sm mb-0" style={{ backgroundColor: "green", color: "white" }}>{edit ? "Update" : "Submit"}</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default FreeBannerDesign;
