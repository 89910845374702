import React, { useState, useEffect } from 'react';
import { useNavigate, Link, useParams } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2'
import db from "./Firebase";
import { storage } from './Firebase';
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import Footer from './Footer';
import { auth, provider } from "./Firebase";
import { signInWithPopup } from "firebase/auth";

function ReviewPage() {
    const [value, setValue] = useState('')
    const [showButton, setShowButton] = useState(false)
    const handleClick = () => {
        signInWithPopup(auth, provider).then((data) => {
            setValue(data.user.email)
            localStorage.setItem("email", data.user.email)
            setShowButton(true);
        })
    }

    useEffect(() => {
        setValue(localStorage.getItem('email'))
    })

    const logout = () => {
        localStorage.clear()
        window.location.reload()
    }

    let params = useParams();
    const navigate = useNavigate();
    const [personalData, setPersonalData] = useState({});
    const [userLocation, setUserLocation] = useState({ latitude: null, longitude: null });
    const initialFormStateReview = { full_name: '', phone_no: '', review: '', created_date: new Date() }
    const [submit, setSubmit] = useState(initialFormStateReview)

    const [show, setshow] = useState(true);
    const [ratingStar, setRatingStar] = useState(0)

    const [imgUrl, setImgUrl] = useState(null);
    const [progresspercent, setProgresspercent] = useState(0);

    const ratingvalue = (val) => {
        setRatingStar(val)
        console.log(val)
    }

    const handleChange = (e) => {
        const { id, value } = e.target
        setSubmit({ ...submit, [id]: value });
        console.log(e.target.value)
    }
    const reset = () => {
        setSubmit({ full_name: '', phone_no: '', review: '', created_date: new Date() })
    }



    const isGeolocationAvailable = 'geolocation' in navigator;


    // const requestUserLocation = () => {
    //     if (isGeolocationAvailable) {
    //         navigator.geolocation.getCurrentPosition(
    //             (position) => {
    //                 // User allowed location access, and we have the coordinates in `position`
    //                 const latitude = position.coords.latitude;
    //                 const longitude = position.coords.longitude;
    //                 // Call the function to get city, state, and pin code using reverse geocoding
    //                 getCityStatePincode(latitude, longitude);
    //             },
    //             (error) => {
    //                 // User denied location access or an error occurred
    //                 console.error('Error getting location:', error.message);
    //             }
    //         );
    //     } else {
    //         // Geolocation is not available in this browser
    //         console.log('Geolocation is not available.');
    //     }
    // };

    // const getCityStatePincode = async (latitude, longitude) => {
    //     const apiKey = 'AIzaSyAw0My4Arc0Ht1EBA2z_7b07eA00jPs5VU&q';
    //     const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`;

    //     try {
    //         const response = await fetch(url);
    //         const data = await response.json();

    //         if (data.status === 'OK' && data.results.length > 0) {
    //             const addressComponents = data.results[0].address_components;
    //             let city, state, pincode;

    //             for (const component of addressComponents) {
    //                 if (component.types.includes('locality')) {
    //                     city = component.long_name;
    //                 } else if (component.types.includes('administrative_area_level_1')) {
    //                     state = component.long_name;
    //                 } else if (component.types.includes('postal_code')) {
    //                     pincode = component.long_name;
    //                 }
    //             }

    //             console.log('City:', city);
    //             console.log('State:', state);
    //             console.log('Pincode:', pincode);
    //         } else {
    //             console.error('Reverse geocoding failed. Status:', data.status);
    //         }
    //     } catch (error) {
    //         console.error('Error fetching geolocation data:', error);
    //     }
    // };


    const requestUserLocation = () => {
        if (isGeolocationAvailable) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    // User allowed location access, and we have the coordinates in `position`
                    console.log('Latitude:', position.coords.latitude);
                    console.log('Longitude:', position.coords.longitude);
                },
                (error) => {
                    // User denied location access or an error occurred
                    console.error('Error getting location:', error.message);
                }
            );
        } else {
            // Geolocation is not available in this browser
            console.log('Geolocation is not available.');
        }
    };


    // function requestUserLocation() {
    //     navigator.geolocation.getCurrentPosition(
    //         (position) => {
    //             // User allowed location access, and we have the coordinates in `position`
    //             console.log('Latitude:', position.coords.latitude);
    //             console.log('Longitude:', position.coords.longitude);
    //         },
    //         (error) => {
    //             // User denied location access or an error occurred
    //             console.error('Error getting location:', error.message);
    //         }
    //     );
    // }

    // useEffect(() => {
    //     // Check if geolocation is available
    //     if (isGeolocationAvailable()) {
    //         // Request user's location
    //         requestUserLocation();
    //     } else {
    //         // Geolocation is not available in this browser
    //         console.log('Geolocation is not available.');
    //     }
    // }, []);
    const getUserLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setUserLocation({
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude
                    });
                    console.log(position.coords.latitude)
                    console.log(position.coords.longitude)
                },
                (error) => {
                    console.error('Error getting user location:', error);
                }
            );
        } else {
            console.error('Geolocation is not supported by this browser.');
        }
    };



    const handleSubmit = (e) => {
        e.preventDefault()
        console.log(submit);
        if (ratingStar == 0) {
            alert("Please Choose Rating Star")
        } else {
            if (e.target[8]?.files[0] == null) {
                setshow(false)
                const currentDate = new Date();
                currentDate.setHours(0, 0, 0, 0);
                db.collection("parlour_feedback")
                    .where("phone_no", "==", submit.phone_no)
                    .where("created_date", ">=", currentDate)
                    .where("created_date", "<", new Date(currentDate.getTime() + 86400000)) // Add 24 hours in milliseconds
                    .get()
                    .then((querySnapshot) => {
                        if (!querySnapshot.empty) {
                            alert("You already submitted a review for today");
                            // Display an error message to the user indicating they have already submitted a review today
                            return;
                        } else {
                            db.collection("parlour_feedback").add({
                                'full_name': submit.full_name,
                                'review': submit.review,
                                'image': "",
                                'rating': ratingStar,
                                'username': params.id,
                                'email_d': value,
                                'phone_no': submit.phone_no,
                                'created_date': new Date(),
                            }).then(() => {
                                db.collection("customer_data").add({ 'name': submit.full_name, 'email': '', 'phone_no': submit.phone_no, 'type': "review", 'additional': params.id, 'created_date': new Date(), }).then(() => {
                                    console.log("data added");
                                });
                                db.collection("app_notification").add({ 'message': `Conguralations! You've got a new review from ${submit.full_name}`, 'link': "PortfolioPreview", 'status': "0", "uid": personalData.uid, 'created_date': new Date() }).then(() => {
                                    console.log("user added");
                                })
                                console.log('Submitted Successfully');

                                const Toast = Swal.mixin({
                                    toast: true,
                                    background: 'white',
                                    position: 'center',
                                    showConfirmButton: false,
                                    timer: 3000,
                                    timerProgressBar: true,
                                    didOpen: (toast) => {
                                        toast.addEventListener('mouseenter', Swal.stopTimer)
                                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                                    }
                                })

                                Toast.fire({
                                    icon: 'success',
                                    title: 'Submitted Successfully'
                                })
                                setshow(true)
                                // navigate(`/${submit.username}`);
                                setSubmit({ full_name: '', phone_no: '', review: '', created_date: new Date() })
                            })
                        }
                    })


            } else {
                const file = e.target[8]?.files[0]
                // console.log(file)
                if (!file) return;
                const storageRef = ref(storage, `files/${file.name}`);
                const uploadTask = uploadBytesResumable(storageRef, file);
                setshow(false)

                uploadTask.on("state_changed",
                    (snapshot) => {
                        const progress =
                            Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                        setProgresspercent(progress);
                    },
                    (error) => {
                        alert(error);
                    },
                    () => {
                        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                            // setImgUrl(downloadURL)
                            const currentDate = new Date();
                            currentDate.setHours(0, 0, 0, 0);
                            db.collection("parlour_feedback")
                                .where("phone_no", "==", submit.phone_no)
                                .where("created_date", ">=", currentDate)
                                .where("created_date", "<", new Date(currentDate.getTime() + 86400000)) // Add 24 hours in milliseconds
                                .get()
                                .then((querySnapshot) => {
                                    if (!querySnapshot.empty) {
                                        alert("You already submitted a review for today");
                                        // Display an error message to the user indicating they have already submitted a review today
                                        return;
                                    } else {
                                        db.collection("parlour_feedback").add({
                                            'full_name': submit.full_name,
                                            'review': submit.review,
                                            'image': downloadURL,
                                            'rating': ratingStar,
                                            'email_d': value,
                                            'phone_no': submit.phone_no,
                                            'username': params.id,
                                            'created_date': new Date(),
                                        }).then(() => {
                                            db.collection("customer_data").add({ 'name': submit.full_name, 'email': '', 'phone_no': submit.phone_no, 'type': "review", 'additional': params.id, 'created_date': new Date(), }).then(() => {
                                                console.log("data added");
                                            });
                                            console.log('Submitted Successfully');
                                            const Toast = Swal.mixin({
                                                toast: true,
                                                background: 'white',
                                                position: 'top-end',
                                                showConfirmButton: false,
                                                timer: 3000,
                                                timerProgressBar: true,
                                                didOpen: (toast) => {
                                                    toast.addEventListener('mouseenter', Swal.stopTimer)
                                                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                                                }
                                            })

                                            Toast.fire({
                                                icon: 'success',
                                                title: 'Submitted Successfully'
                                            })
                                            setshow(true)
                                            // navigate(`/${submit.username}`);
                                            setSubmit({ full_name: '', review: '', created_date: new Date() })
                                        })
                                    }
                                })
                        });
                    }
                );
            }
        }
    }

    // const handleSubmit = (e) => {
    //     // console.log(submit.usercode)
    //     // alert("okkk")
    //     console.log(e.target[7]?.files[0])
    //     // e.preventDefault()

    //     if (ratingStar == 0) {
    //         alert("Please Choose Rating Star")
    //     } else {
    //         const file = e.target[7]?.files[0]
    //         // console.log(file)
    //         if (!file) return;
    //         const storageRef = ref(storage, `files/${file.name}`);
    //         const uploadTask = uploadBytesResumable(storageRef, file);
    //         setshow(false)

    //         uploadTask.on("state_changed",
    //             (snapshot) => {
    //                 const progress =
    //                     Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
    //                 setProgresspercent(progress);
    //             },
    //             (error) => {
    //                 alert(error);
    //             },
    //             () => {
    //                 getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
    //                     // setImgUrl(downloadURL)
    //                     db.collection("parlour_feedback").add({
    //                         'full_name': submit.full_name,
    //                         'review': submit.review,
    //                         'image': downloadURL,
    //                         'rating': ratingStar,
    //                         'username': params.id,
    //                         'created_date': new Date(),
    //                     }).then(() => {

    //                         console.log('Submitted Successfully');
    //                         const Toast = Swal.mixin({
    //                             toast: true,
    //                             background: '#69aba6',
    //                             position: 'top-end',
    //                             showConfirmButton: false,
    //                             timer: 3000,
    //                             timerProgressBar: true,
    //                             didOpen: (toast) => {
    //                                 toast.addEventListener('mouseenter', Swal.stopTimer)
    //                                 toast.addEventListener('mouseleave', Swal.resumeTimer)
    //                             }
    //                         })

    //                         Toast.fire({
    //                             icon: 'success',
    //                             title: 'Submitted Successfully'
    //                         })
    //                         setshow(true)
    //                         // navigate(`/${submit.username}`);
    //                         setSubmit({ full_name: '', review: '', created_date: new Date() })
    //                     })
    //                 });
    //             }
    //         );
    //     }



    // }
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        db.collection("portfolio_details").where('username', '==', params.id.toLowerCase()).onSnapshot((snapshot) => {
            snapshot.docs.map(e => setPersonalData(e.data()))
            // if (snapshot.docs.length == 0) {
            //     navigate('/error');
            // } else {
            //     // alert("exists")
            //     setIsLoading(false);
            // }
        });
    }, []);

    return (
        <>
            <section>
                <div class="navi" >
                    <input type="checkbox" id="nav-check" />
                    <div class="nav-header">
                        <div class="nav-title">
                            <Link to={`/${params.id.toLowerCase()}`} > <img src="/images/youvati_logo.png" alt="" style={{ height: "27px", marginLeft: "2rem" }} /></Link>
                        </div>
                    </div>


                </div>
            </section>

            {/* <button onClick={requestUserLocation}>Get My Location</button> */}
            <div class="p-1" style={{ backgroundImage: "url('/images/reviewbg.jpg')", height: "40rem" }}>
                <form class="" id="" onSubmit={handleSubmit}>
                    <div class="infobox">
                        <div class="" style={{ textAlign: "center", }}><p style={{ color: "black", fontSize: "25px", fontWeight: "700", textTransform: "uppercase" }}>{personalData.parlour_name}</p></div>
                        <div class="" style={{ textAlign: "center", color: "black", paddingTop: "10px" }}>
                            <section class='rating-widget'>

                                {/* <div class='rating-stars text-center'>
                                    <ul id='stars'>
                                        <li class='star' title='Poor' data-value='1' onClick={() => ratingvalue(1)}>
                                            <i class='fa fa-star fa-fw'></i>
                                        </li>
                                        <li class='star' title='Fair' data-value='2' onClick={() => ratingvalue(2)}>
                                            <i class='fa fa-star fa-fw'></i>
                                        </li>
                                        <li class='star' title='Good' data-value='3' onClick={() => ratingvalue(3)}>
                                            <i class='fa fa-star fa-fw'></i>
                                        </li>
                                        <li class='star' title='Excellent' data-value='4' onClick={() => ratingvalue(4)}>
                                            <i class='fa fa-star fa-fw'></i>
                                        </li>
                                        <li class='star' title='WOW!!!' data-value='5' onClick={() => ratingvalue(5)}>
                                            <i class='fa fa-star fa-fw'></i>
                                        </li>
                                    </ul>
                                </div> */}
                                <div class="star-rating text-center">
                                    <input type="radio" id="5-stars" name="rating" value="5" onClick={() => ratingvalue(5)} />
                                    <label for="5-stars" class="star" style={{ fontSize: "25px" }}>&#9733;</label>
                                    <input type="radio" id="4-stars" name="rating" value="4" onClick={() => ratingvalue(4)} />
                                    <label for="4-stars" class="star" style={{ fontSize: "25px" }}>&#9733;</label>
                                    <input type="radio" id="3-stars" name="rating" value="3" onClick={() => ratingvalue(3)} />
                                    <label for="3-stars" class="star" style={{ fontSize: "25px" }}>&#9733;</label>
                                    <input type="radio" id="2-stars" name="rating" value="2" onClick={() => ratingvalue(2)} />
                                    <label for="2-stars" class="star" style={{ fontSize: "25px" }}>&#9733;</label>
                                    <input type="radio" id="1-star" name="rating" value="1" onClick={() => ratingvalue(1)} />
                                    <label for="1-star" class="star" style={{ fontSize: "25px" }}>&#9733;</label>
                                </div>

                            </section>
                            {/* <i class="fa fa-star" style={{ color: "orangered" }} aria-hidden="true"></i><i class="fa fa-star" style={{ color: "orangered" }} aria-hidden="true"></i><i class="fa fa-star" style={{ color: "orangered" }} aria-hidden="true"></i><i class="fa fa-star" style={{ color: "orangered" }} aria-hidden="true"></i><i class="fa fa-star" style={{ color: "orangered" }} aria-hidden="true"></i> */}
                        </div>

                        <textarea style={{
                            width: "100%",
                            height: "15px",
                            padding: "12px 20px",
                            boxSizing: "border-box",
                            border: "2px solid #ccc",
                            borderRadius: "4px",
                            backgroundColor: "#f8f8f8",
                            fontSize: "16px",
                            resize: "none"
                        }} class="w3l_text_box" id="review" onChange={handleChange} value={submit.review} placeholder="Write a review..." name="Review" required />
                        <div class="">
                            <div class="row">
                                <div class="col-md-6">
                                    {/* <textarea onChange={handleChange} value={submit.full_name} placeholder="Full Name" type="text" required style={{
                                    width: "17rem",
                                    height: "60px",
                                    padding: "12px 20px",
                                    boxSizing: "border-box",
                                    border: "2px solid #ccc",
                                    borderRadius: "4px",
                                    backgroundColor: "#f8f8f8",
                                    fontSize: "16px",
                                    resize: "none"
                                }}></textarea> */}
                                    <input style={{
                                        // width: "17rem",
                                        height: "45px",
                                        padding: "12px 20px",
                                        boxSizing: "border-box",
                                        border: "2px solid #ccc",
                                        borderRadius: "4px",
                                        backgroundColor: "#f8f8f8",
                                        fontSize: "16px",
                                        resize: "none"
                                    }} class="w3l_text_box" id="full_name" onChange={handleChange} value={submit.full_name} placeholder="Full Name" name="Full Name" type="text" required />
                                </div>

                                <div class="col-md-6">
                                    <input style={{
                                        // width: "17rem",
                                        height: "45px",
                                        padding: "12px 20px",
                                        boxSizing: "border-box",
                                        border: "2px solid #ccc",
                                        borderRadius: "4px",
                                        backgroundColor: "#f8f8f8",
                                        fontSize: "16px",
                                        resize: "none"
                                    }} class="w3l_text_box" id="phone_no" onChange={handleChange} value={submit.phone_no} placeholder="Phone Number" name="Phone Number" type="text" required />
                                </div>

                            </div>

                            <div class="">
                                <div class="file-upload-wrapper" data-text="Select your file!">
                                    <input type="file" style={{ height: "45px", padding: "10px" }} id="image" name="Image" accept="image/*" />
                                </div>
                                {/* <input type="file" id="image" name="image" accept="image/*" capture style={{ display: "" }} />
                                        <img onClick={() => window.prevS()} src="http://macgroup.org/blog/wp-content/uploads/2011/10/iphone-camera-icon-150x150.jpg" style={{ cursor: "pointer" }} /> */}

                            </div>
                        </div>

                        {show ? <><div class="" style={{ textAlign: "end", color: "black", paddingBottom: "1rem" }}><input style={{ background: "linear-gradient(#fe8e4e, #fedc56)", borderRadius: "8px", color: "black", padding: "7px", fontSize: "14px", marginRight: "1rem" }} type="submit" value="Submit" /></div></> : <></>}
                        {/* {value != null ? <div class="" style={{ textAlign: "end", color: "black", paddingBottom: "1rem" }}>{show ? <input style={{ background: "linear-gradient(#fe8e4e, #fedc56)", borderRadius: "8px", color: "black", padding: "7px", fontSize: "14px", marginRight: "1rem" }} type="submit" value="Submit" /> : "Please Wait..."}</div> : null} */}
                        {/* {value == null ? <div style={{ color: "blue", cursor: "pointer", display: "flex", justifyContent: "end" }} onClick={handleClick}>Signup to give a review <img class="pl-4" src="/images/googlelogo.png" style={{ height: "30px", paddingRight: "10px" }}></img></div> : null} */}
                        {/* <li>{show ? <input style={{ background: "linear-gradient(#fe8e4e, #fedc56)", color: "black", padding: "7px", marginRight: "1rem" }} type="submit" value="Submit" /> : "Please Wait..."}</li> */}
                        {/* <button onClick={logout}>Logout</button> */}
                        {/* <p style={{ color: "black", fontWeight: "600" }}>Logged in via:-  {value}</p> */}

                    </div>
                </form>

            </div >


            <Footer />

        </>
    );
}

export default ReviewPage;




// import React, { useEffect, useState } from "react";

// function ReviewPage() {
//     const [add, setAdd] = useState('')
//     // `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`

//     useEffect(() => {
//         navigator.geolocation.getCurrentPosition(pos => {
//             const { latitude, longitude } = pos.coords;
//             console.log(latitude, longitude)
//             const url = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`;
//             fetch(url).then(res => res.json()).then(data => setAdd(data.address))
//         })
//     }, [])
//     console.log(add, "sfsfh")
//     return (
//         <>
//             <p>road : {add.road}</p>
//             <p>city : {add.city}</p>
//             <p>country :{add.country}</p>
//         </>
//     )
// }

// export default ReviewPage;