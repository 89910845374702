import axios from 'axios';

const apiKey = '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'; // Replace 'YOUR_API_KEY' with your actual API key

async function generateText(prompt) {
    const url = 'https://api.openai.com/v1/engines/text-davinci-002/completions';

    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${apiKey}`
    };

    const data = {
        prompt: prompt,
        max_tokens: 100 // Control the length of the generated text
    };

    try {
        const response = await axios.post(url, data, { headers });
        return response.data.choices[0].text;
    } catch (error) {
        console.error('OpenAI API error:', error);
        throw error;
    }
}

export { generateText };

