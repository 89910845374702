import React, { useState, useEffect } from "react";
import { useNavigate, Link, useParams } from 'react-router-dom';
import "./style.css";
import db from "./Firebase";
import AdminPanel from './AdminPanel';
import Swal from 'sweetalert2'

function SignIn() {
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const [register, setRegister] = useState(false);



    const togglePasswordVisibility = () => {
        if (showPassword === false) {
            setShowPassword(true);
        } else {
            setShowPassword(false);
        }

    };

    const initialFormState = {
        email: "",
        password: "",
        confirm_password: "",
        remember: true,
    };
    const [submit, setSubmit] = useState(initialFormState);
    const [Show, setShow] = useState(false);
    const [ShowAdmin, setShowAdmin] = useState(false);

    useEffect(() => {
        const storedEmail = localStorage.getItem("email");
        const storedPassword = localStorage.getItem("password");
        const storedRemember = localStorage.getItem("remember");

        // if (storedRemember === "true") {
        //     setSubmit({
        //         email: storedEmail || "",
        //         password: storedPassword || "",
        //         remember: true,
        //     });
        //     return (navigate('/admin_panel'));
        // }
        db.collection("user_based_role").where("email", "==", storedEmail).where("status", "==", "0").onSnapshot((snapshot) => {
            if (!snapshot.empty) {
                navigate('/signin');

            }
        });
    }, []);

    const handleChange = (e) => {
        const { id, value, type, checked } = e.target;

        if (type === "checkbox") {
            setSubmit({ ...submit, [id]: checked });
        } else {
            setSubmit({ ...submit, [id]: value });
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // alert(JSON.stringify(submit));
        db.collection("user_based_role").where('email', '==', submit.email).onSnapshot((snapshot) => {
            if (!snapshot.empty) {

                const userRoles = snapshot.docs.map((doc) => doc.data().role);
                const email = snapshot.docs.map((doc) => doc.data().email);
                const userPassword = snapshot.docs.map((doc) => doc.data().password);
                const status = snapshot.docs.map((doc) => doc.data().status);
                console.log(userRoles[0]);

                if (email[0] === submit.email && userPassword[0] === submit.password) {
                    if (status[0] === "0") {
                        Swal.fire('Your email is currently under review.Please wait for some time or contact the administrator for further assistance')
                    } else {
                        if (submit.remember) {
                            localStorage.setItem("email", submit.email);
                            localStorage.setItem("password", submit.password);
                            localStorage.setItem("remember", submit.remember);
                        } else {
                            localStorage.removeItem("email");
                            localStorage.removeItem("password");
                            localStorage.removeItem("remember");
                        }
                        navigate('/admin_panel')
                    }
                } else {
                    const Toast = Swal.mixin({
                        toast: true,
                        background: '#69aba6',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', Swal.stopTimer)
                            toast.addEventListener('mouseleave', Swal.resumeTimer)
                        }
                    })

                    Toast.fire({
                        icon: 'danser',
                        title: 'Invalid Password !!!'
                    })

                }

                // You can perform further actions here if the email is found.
            } else {

                console.log('email not found');

                const Toast = Swal.mixin({
                    toast: true,
                    background: '#69aba6',
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer)
                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                    }
                })

                Toast.fire({
                    icon: 'danser',
                    title: 'Invalid Email & Password !!!'
                })

            }



        });


    };

    // const handleSubmitSignup = (e) => {
    //     e.preventDefault();
    //     if (submit.password === submit.confirm_password) {

    //         db.collection("user_based_role").where('email', '==', submit.email).onSnapshot((snapshot) => {
    //             if (!snapshot.empty) {
    //                 // alert("You already have an account? Please log in. If you've forgotten your password, please contact the administrator for assistance")
    //                 Swal.fire({
    //                     icon: 'error',
    //                     text: "You already have an account? Please log in. If you've forgotten your password, please contact the administrator for assistance",
    //                 })

    //             } else {
    //                 db.collection("user_based_role").add({ 'email': submit.email, 'password': submit.password, 'role': ['guest'], 'created_date': new Date(), 'updated_date': new Date(), }).then(() => {
    //                     console.log("data added");
    //                     const Toast = Swal.mixin({
    //                         toast: true,
    //                         position: 'top-end',
    //                         showConfirmButton: false,
    //                         timer: 3000,
    //                         timerProgressBar: true,
    //                         didOpen: (toast) => {
    //                             toast.addEventListener('mouseenter', Swal.stopTimer)
    //                             toast.addEventListener('mouseleave', Swal.resumeTimer)
    //                         }
    //                     })

    //                     Toast.fire({
    //                         icon: 'success',
    //                         title: 'Registered successfully'
    //                     })
    //                     setRegister(false)
    //                     setSubmit({ email: "", password: "", confirm_password: "", remember: false });
    //                 })

    //             }
    //         });


    //     } else {
    //         alert("Password mismatch")
    //     }

    // }

    const handleSubmitSignup = (e) => {
        e.preventDefault();
        if (submit.password === submit.confirm_password) {
            // Check if the email already exists in the database
            db.collection("user_based_role")
                .where('email', '==', submit.email)
                .get()
                .then((querySnapshot) => {
                    if (!querySnapshot.empty) {
                        // Email already exists
                        Swal.fire({
                            icon: 'error',
                            text: "You already have an account. If you've forgotten your password, please contact the administrator for assistance.",
                        });
                    } else {
                        // Email doesn't exist, proceed with registration
                        db.collection("user_based_role").add({
                            'email': submit.email,
                            'password': submit.password, // Hash and salt this password
                            'role': ['guest'],
                            'status': "0",
                            'created_date': new Date(),
                            'updated_date': new Date(),
                        }).then(() => {
                            console.log("Data added");
                            const Toast = Swal.mixin({
                                toast: true,
                                position: 'top-end',
                                showConfirmButton: false,
                                timer: 3000,
                                timerProgressBar: true,
                                didOpen: (toast) => {
                                    toast.addEventListener('mouseenter', Swal.stopTimer);
                                    toast.addEventListener('mouseleave', Swal.resumeTimer);
                                },
                            });

                            // Toast.fire({
                            //     icon: 'success',
                            //     title: 'Registered successfully',
                            // });
                            Swal.fire('Regestered Successfull : Please be advised that your request is currently under review. For further assistance, kindly contact the administration or wait for two hours before attempting to sign in again.')
                            setRegister(false);
                            setSubmit({ email: "", password: "", confirm_password: "", remember: false });
                        });
                    }
                });
        } else {
            Swal.fire({
                icon: 'error',
                text: "Password mismatch.",
            });
        }
    };

    const signup = () => {
        setSubmit({ email: "", password: "", confirm_password: "", remember: false });
        setRegister(true)
    }


    const signIn = () => {
        setSubmit({ email: "", password: "", confirm_password: "", remember: false });
        setRegister(false)
    }



    return (
        <>
            <div class="align-items-start min-vh-100" style={{ "background": "black" }}>
                <span class="mask  opacity-6"></span>
                <div class="container my-auto">
                    <div class="row mt-5">
                        <div class="col-lg-4 col-md-8 col-12 mx-auto">
                            <div class="card z-index-0 fadeIn3 fadeInBottom">
                                <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
                                    <div class="bg-gradient-primary shadow-primary border-radius-lg py-3 pe-1">
                                        <h4 class="text-white font-weight-bolder text-center mt-2 mb-0">{register ? "Register" : "Sign in"}</h4>
                                        <div class="row mt-3">
                                            <div class="col-2 text-center ms-auto">
                                                <a class="btn btn-link px-3" href="javascript:;">
                                                    <i class="fa fa-facebook text-white text-lg"></i>
                                                </a>
                                            </div>
                                            <div class="col-2 text-center px-1">
                                                <a class="btn btn-link px-3" href="javascript:;">
                                                    <i class="fa fa-github text-white text-lg"></i>
                                                </a>
                                            </div>
                                            <div class="col-2 text-center me-auto">
                                                <a class="btn btn-link px-3" href="javascript:;">
                                                    <i class="fa fa-google text-white text-lg"></i>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                {register ?

                                    <>
                                        <div class="card-body">
                                            <form role="form" class="text-start" onSubmit={handleSubmitSignup}>
                                                <div class="input-group input-group-outline my-3">
                                                    <input type="email" class="form-control" placeholder="email" id="email" onChange={handleChange} value={submit.email} />
                                                </div>
                                                <div class="input-group input-group-outline my-3">
                                                    <input class="form-control" id="password" type='password' name="password" onChange={handleChange} value={submit.password} placeholder="password" />
                                                </div>
                                                <div class="input-group input-group-outline">
                                                    <input class="form-control" id="confirm_password" type={showPassword ? 'text' : 'password'} name="password" onChange={handleChange} value={submit.confirm_password} placeholder="confirm password" />
                                                </div>
                                                <div style={{ flexDirection: "row", justifyContent: "space-between", display: "flex" }}>
                                                    <span class="pt-2" style={{ fontSize: "12px", fontWeight: "600", cursor: "pointer" }} onClick={togglePasswordVisibility}>{showPassword ? 'Hide' : 'Show Password'}</span>
                                                    {/* <span class="pt-2" style={{ fontSize: "12px", fontWeight: "600", cursor: "pointer" }} onClick={togglePasswordVisibility}>Register</span> */}
                                                </div>

                                                <div class="text-center">
                                                    <button type="submit" class="btn bg-gradient-primary w-100 my-4 mb-2">Register</button>
                                                </div>
                                                <p class="mt-4 text-sm text-center">
                                                    Already have an account?
                                                    <span class="text-primary text-gradient font-weight-bold" style={{ cursor: "pointer" }} onClick={() => signIn()}>Sign In</span>
                                                </p>
                                            </form>
                                        </div>
                                    </>

                                    :
                                    <>

                                        <div class="card-body">
                                            <form role="form" class="text-start" onSubmit={handleSubmit}>
                                                <div class="input-group input-group-outline my-3">
                                                    <input type="text" class="form-control" placeholder="email" id="email" onChange={handleChange} value={submit.email} />
                                                </div>
                                                <div class="input-group input-group-outline">
                                                    <input class="form-control" id="password" type={showPassword ? 'text' : 'password'} name="password" onChange={handleChange} value={submit.password} placeholder="password" />
                                                </div>
                                                <div style={{ flexDirection: "row", justifyContent: "space-between", display: "flex" }}>
                                                    <span class="pt-2" style={{ fontSize: "12px", fontWeight: "600", cursor: "pointer" }} onClick={togglePasswordVisibility}>{showPassword ? 'Hide' : 'Show Password'}</span>
                                                    {/* <span class="pt-2" style={{ fontSize: "12px", fontWeight: "600", cursor: "pointer" }} onClick={togglePasswordVisibility}>Register</span> */}
                                                </div>
                                                {/* <button
                                            className="btn btn-outline-secondary"
                                            type="button"
                                            onClick={togglePasswordVisibility}
                                        ></button> */}
                                                {/* <div class="form-check form-switch d-flex align-items-center mb-3">
                                            <input
                                                class="form-check-input"
                                                type="checkbox"
                                                id="remember"
                                                onChange={handleChange}
                                                checked={submit.remember} // Set the checked property here
                                            />
                                            <label class="form-check-label mb-0 ms-2" for="rememberMe">
                                                Remember me
                                            </label>
                                        </div> */}
                                                <div class="text-center">
                                                    <button type="submit" class="btn bg-gradient-primary w-100 my-4 mb-2">Sign in</button>
                                                </div>
                                                <p class="mt-4 text-sm text-center">
                                                    Don't have an account?
                                                    <span class="text-primary text-gradient font-weight-bold" style={{ cursor: "pointer" }} onClick={() => signup()}>Sign up</span>
                                                </p>
                                            </form>
                                        </div>

                                    </>}



                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SignIn;
