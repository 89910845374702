import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom"
import './style.css';
import Slider from './Slider';
import Card from './Card';
import db from "./Firebase";
import axios from "axios";

function Portfolio() {
    let params = useParams();
    const [personalData, setPersonalData] = useState({});
    const [parlourData, setParlourData] = useState({});
    const [imageArray, setImageArray] = useState([])
    const [servicesArray, setServicesArray] = useState([])
    const [customerName, setCustomerName] = useState("");
    const [customerPassword, setCustomerPassword] = useState("");
    const [customersData, setCustomersData] = useState([]);
    const [updatedCustomerName, setUpdatedCustomerName] = useState("");
    const [updatedCustomerPassword, setUpdatedCustomerPassword] = useState("");
    const [dataIdToBeUpdated, setDataIdToBeUpdated] = useState("");

    var amount = 0;



    return (
        <>
            <div id="home" class="banner">
                <div class="container">
                    <div class="w3_agile_logo">
                        <h1><a href="index.html"><span>Port</span> Folio</a></h1>
                    </div>
                    {/* <div class="w3layouts_menu">
                        <nav class="cd-stretchy-nav edit-content">
                            <a class="cd-nav-trigger" href="#0"> Menu <span aria-hidden="true"></span> </a>
                            <ul>
                                <li><a href="#home" class="scroll"><span>Home</span></a></li>
                                <li><a href="#about" class="scroll"><span>About</span></a></li>
                                <li><a href="#team" class="scroll"><span>Team</span></a></li>
                                <li><a href="#portfolio" class="scroll"><span>Portfolio</span></a></li>
                                <li><a href="#testimonials" class="scroll"><span>Testimonials</span></a></li>
                                <li><a href="#events" class="scroll"><span>Events</span></a></li>
                                <li><a href="#contact" class="scroll"><span>Contact</span></a></li>
                            </ul>
                            <span aria-hidden="true" class="stretchy-nav-bg"></span>
                        </nav>
                    </div> */}
                    <div class="agile_banner_info">
                        <h2><span class="d4">Maria</span> <i>Hair Salon</i></h2>
                        <p>In malesuada ligula libero, at elementum lorem tincidunt et.</p>
                    </div>
                    <div class="agileits_w3layouts_call_mail">
                        <ul>
                            <li><i class="fa fa-phone" aria-hidden="true"></i>(+000) 123 456 78</li>
                            <li><i class="fa fa-envelope-o" aria-hidden="true"></i><a
                                href="mailto:maria@example.com">maria@example.com</a></li>
                        </ul>
                    </div>
                    {/* <div class="w3_agileits_banner_social_icons_pos">
                        <ul class="agileits_social_list">
                            <li><a href="#" class="w3_agile_facebook"><i class="fa fa-facebook" aria-hidden="true"></i></a></li>
                            <li><a href="#" class="agile_twitter"><i class="fa fa-twitter" aria-hidden="true"></i></a></li>
                            <li><a href="#" class="w3_agile_dribble"><i class="fa fa-dribbble" aria-hidden="true"></i></a></li>
                            <li><a href="#" class="w3_agile_vimeo"><i class="fa fa-vimeo" aria-hidden="true"></i></a></li>
                        </ul>
                    </div> */}
                </div>
            </div>

            <div id="about" class="banner-bottom">
                <div class="container">
                    <div class="w3_banner_bottom_grid_pos">
                        <div class="w3_banner_bottom_grid">
                            <h3>Hair Style</h3>
                        </div>
                    </div>
                    <div class="w3ls_banner_bottom_grid1">
                        <div class="col-md-6 w3l_banner_bottom_left">
                            <img src="images/1_1.jpg" alt=" " class="img-responsive" />
                        </div>
                        <div class="col-md-6 w3l_banner_bottom_right">
                            <h3>Welcome to <span><i>Hair</i> Salon</span></h3>
                            <p>Praesent lobortis, ligula vitae scelerisque condimentum,
                                risus orci lobortis nibh, at gravida justo magna in metus.</p>
                            <ul>
                                <li><i class="fa fa-table" aria-hidden="true"></i>10 years experience</li>
                                <li><i class="fa fa-certificate" aria-hidden="true"></i>Certified Salon</li>
                                <li><i class="fa fa-usd" aria-hidden="true"></i>Low Prices</li>
                            </ul>
                        </div>
                        <div class="clearfix"> </div>
                    </div>
                    <div class="agileits_banner_bottom_grid_three">
                        <div class="col-md-4 agileinfo_banner_bottom_grid_three_left">
                            <div class="wthree_banner_bottom_grid_three_left1 grid">
                                <figure class="effect-roxy">
                                    <img src="images/2_1.jpg" alt=" " class="img-responsive" />
                                    <figcaption>
                                        <h3>Hair <span>Dressing</span></h3>
                                        <p>Vestibulum pulvinar lobortis lorem lectus pretium non.</p>
                                    </figcaption>
                                </figure>
                            </div>
                            <p class="w3_agileits_para">Pellentesque vehicula augue eget nisl ullamcorper,
                                molestie blandit ipsum auctor. Mauris volutpat augue dolor.</p>
                        </div>
                        <div class="col-md-4 agileinfo_banner_bottom_grid_three_left">
                            <div class="wthree_banner_bottom_grid_three_left1 grid">
                                <figure class="effect-roxy">
                                    <img src="images/3_1.jpg" alt=" " class="img-responsive" />
                                    <figcaption>
                                        <h3>Hair <span>Dressing</span></h3>
                                        <p>Vestibulum pulvinar lobortis lorem lectus pretium non.</p>
                                    </figcaption>
                                </figure>
                            </div>
                            <p class="w3_agileits_para">Pellentesque vehicula augue eget nisl ullamcorper,
                                molestie blandit ipsum auctor. Mauris volutpat augue dolor.</p>
                        </div>
                        <div class="col-md-4 agileinfo_banner_bottom_grid_three_left">
                            <div class="wthree_banner_bottom_grid_three_left1 grid">
                                <figure class="effect-roxy">
                                    <img src="images/4_1.jpg" alt=" " class="img-responsive" />
                                    <figcaption>
                                        <h3>Hair <span>Dressing</span></h3>
                                        <p>Vestibulum pulvinar lobortis lorem lectus pretium non.</p>
                                    </figcaption>
                                </figure>
                            </div>
                            <p class="w3_agileits_para">Pellentesque vehicula augue eget nisl ullamcorper,
                                molestie blandit ipsum auctor. Mauris volutpat augue dolor.</p>
                        </div>
                        <div class="clearfix"> </div>
                    </div>
                </div>
            </div>

            <div class="schedule">
                <div class="container">
                    <div class="agileits_w3layouts_schedule">
                        <h3>Our Schedule</h3>
                        <img src="images/1_1.png" alt=" " class="img-responsive" />
                    </div>
                    <div class="agile_schedule_list">
                        <ul>
                            <li><span>Week Days</span> <i class="fa fa-clock-o" aria-hidden="true"></i>10:00 AM to 4:00 PM</li>
                            <li><span>Saturday</span> <i class="fa fa-clock-o" aria-hidden="true"></i>10:00 AM to 2:00 PM</li>
                            <li><span>Sunday</span> <i class="fa fa-clock-o" aria-hidden="true"></i>9:00 AM to 1:00 PM</li>
                        </ul>
                    </div>
                    <div class="wthree_more">
                        <a href="#small-dialog"
                            class="popup-with-zoom-anim button--wayra button--border-thick button--text-upper button--size-s">book
                            now</a>
                    </div>
                </div>
            </div>

            <div class="schedule-bottom">
                <div class="col-md-6 agileinfo_schedule_bottom_left">
                    <img src="images/16_1.jpg" alt=" " class="img-responsive" />
                </div>
                <div class="col-md-6 agileits_schedule_bottom_right">
                    <div class="w3ls_schedule_bottom_right_grid">
                        <h3>save up to <span>50%</span> in this week</h3>
                        <p>Suspendisse varius turpis efficitur erat laoreet dapibus.
                            Mauris sollicitudin scelerisque commodo.Nunc dapibus mauris sed metus finibus posuere.</p>
                        <div class="col-md-4 w3l_schedule_bottom_right_grid1">
                            <i class="fa fa-user-o" aria-hidden="true"></i>
                            <h4>Customers</h4>
                            <h5 class="counter">653</h5>
                        </div>
                        <div class="col-md-4 w3l_schedule_bottom_right_grid1">
                            <i class="fa fa-calendar-o" aria-hidden="true"></i>
                            <h4>Events</h4>
                            <h5 class="counter">823</h5>
                        </div>
                        <div class="col-md-4 w3l_schedule_bottom_right_grid1">
                            <i class="fa fa-shield" aria-hidden="true"></i>
                            <h4>Awards</h4>
                            <h5 class="counter">45</h5>
                        </div>
                        <div class="clearfix"> </div>
                    </div>
                </div>
                <div class="clearfix"> </div>
            </div>
            {/* ------------------------------------ */}
            <div id="small-dialog" class="mfp-hide">
                <div class="w3layouts_payment_online_form">
                    <form action="#" method="post">
                        <h4>Account Info</h4>
                        <ul>
                            <li><input class="w3l_text_box" placeholder="First Name" name="First Name" type="text" required="" />
                            </li>
                            <li><input class="w3l_text_box" placeholder="Last Name" name="Last Name" type="text" required="" />
                            </li>
                        </ul>
                        <ul>
                            <li><input class="w3l_text_box" placeholder="Email" name="Email" type="email" required="" /></li>
                            <li><input class="w3l_text_box" placeholder="Phone Number" name="Phone" type="text" required="" />
                            </li>
                        </ul>
                        <ul>
                            <li>
                                <label>No of Persons</label>
                                <select class="form-control">
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="6">More</option>
                                </select>
                            </li>
                            <li>
                                <label>Coiffeur</label>
                                <select class="form-control">
                                    <option value="1">John Mark</option>
                                    <option value="2">Michael Lii</option>
                                    <option value="3">Gambriel</option>
                                    <option value="4">Laura Carl</option>
                                </select>
                            </li>
                        </ul>
                        <ul>
                            <li class="w3ls_address"><input class="w3l_text_box" placeholder="Address" name="Address"
                                type="text" required="" /></li>
                        </ul>
                        <ul class="w3_payment_sendbtns">
                            <li><input type="reset" value="Reset" /></li>
                            <li><input type="submit" value="Request Appointment" /></li>
                        </ul>
                        <div class="clearfix"> </div>
                    </form>
                </div>
            </div>

            {/* --------------------------- */}

            <div id="team" class="team">
                <div class="container">
                    <div class="agileits_w3layouts_schedule">
                        <h3>Meet Our Specialists</h3>
                        <img src="images/1_1.png" alt=" " class="img-responsive" />
                    </div>
                    <div class="w3_agile_team_grids">
                        <div class="col-md-3 w3_agile_team_grid">
                            <div class="hover14 column">
                                <figure><img src="images/6_1.jpg" alt=" " class="img-responsive" /></figure>
                            </div>
                            <h3>Amanda Seylon</h3>
                            <p>Coiffeur</p>
                            <ul class="agileits_social_list">
                                <li><a href="#" class="w3_agile_facebook"><i class="fa fa-facebook" aria-hidden="true"></i></a>
                                </li>
                                <li><a href="#" class="agile_twitter"><i class="fa fa-twitter" aria-hidden="true"></i></a></li>
                                <li><a href="#" class="w3_agile_dribble"><i class="fa fa-dribbble" aria-hidden="true"></i></a>
                                </li>
                                <li><a href="#" class="w3_agile_vimeo"><i class="fa fa-vimeo" aria-hidden="true"></i></a></li>
                            </ul>
                        </div>
                        <div class="col-md-3 w3_agile_team_grid">
                            <div class="hover14 column">
                                <figure><img src="images/7_1.jpg" alt=" " class="img-responsive" /></figure>
                            </div>
                            <h3>Laura Mark</h3>
                            <p>Coiffeur</p>
                            <ul class="agileits_social_list">
                                <li><a href="#" class="w3_agile_facebook"><i class="fa fa-facebook" aria-hidden="true"></i></a>
                                </li>
                                <li><a href="#" class="agile_twitter"><i class="fa fa-twitter" aria-hidden="true"></i></a></li>
                                <li><a href="#" class="w3_agile_dribble"><i class="fa fa-dribbble" aria-hidden="true"></i></a>
                                </li>
                                <li><a href="#" class="w3_agile_vimeo"><i class="fa fa-vimeo" aria-hidden="true"></i></a></li>
                            </ul>
                        </div>
                        <div class="col-md-3 w3_agile_team_grid">
                            <div class="hover14 column">
                                <figure><img src="images/8_1.jpg" alt=" " class="img-responsive" /></figure>
                            </div>
                            <h3>Lusiana James</h3>
                            <p>Coiffeur</p>
                            <ul class="agileits_social_list">
                                <li><a href="#" class="w3_agile_facebook"><i class="fa fa-facebook" aria-hidden="true"></i></a>
                                </li>
                                <li><a href="#" class="agile_twitter"><i class="fa fa-twitter" aria-hidden="true"></i></a></li>
                                <li><a href="#" class="w3_agile_dribble"><i class="fa fa-dribbble" aria-hidden="true"></i></a>
                                </li>
                                <li><a href="#" class="w3_agile_vimeo"><i class="fa fa-vimeo" aria-hidden="true"></i></a></li>
                            </ul>
                        </div>
                        <div class="col-md-3 w3_agile_team_grid">
                            <div class="hover14 column">
                                <figure><img src="images/9_1.jpg" alt=" " class="img-responsive" /></figure>
                            </div>
                            <h3>Catharine</h3>
                            <p>Coiffeur</p>
                            <ul class="agileits_social_list">
                                <li><a href="#" class="w3_agile_facebook"><i class="fa fa-facebook" aria-hidden="true"></i></a>
                                </li>
                                <li><a href="#" class="agile_twitter"><i class="fa fa-twitter" aria-hidden="true"></i></a></li>
                                <li><a href="#" class="w3_agile_dribble"><i class="fa fa-dribbble" aria-hidden="true"></i></a>
                                </li>
                                <li><a href="#" class="w3_agile_vimeo"><i class="fa fa-vimeo" aria-hidden="true"></i></a></li>
                            </ul>
                        </div>
                        <div class="clearfix"> </div>
                    </div>
                </div>
            </div>

            <div id="portfolio" class="portfolio">
                <ul id="flexiselDemo1">
                    <li>
                        <div class="w3_agile_portfolio_grid">
                            <a href="images/10_1.jpg">
                                <img src="images/10_1.jpg" alt=" " class="img-responsive" />
                                <div class="w3layouts_news_grid_pos">
                                    <div class="wthree_text">
                                        <h3>Hair <span>Dressing</span></h3>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </li>
                    <li>
                        <div class="w3_agile_portfolio_grid">
                            <a href="images/11_1.jpg">
                                <img src="images/11_1.jpg" alt=" " class="img-responsive" />
                                <div class="w3layouts_news_grid_pos">
                                    <div class="wthree_text">
                                        <h3>Hair <span>Dressing</span></h3>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </li>
                    <li>
                        <div class="w3_agile_portfolio_grid">
                            <a href="images/12_1.jpg">
                                <img src="images/12_1.jpg" alt=" " class="img-responsive" />
                                <div class="w3layouts_news_grid_pos">
                                    <div class="wthree_text">
                                        <h3>Hair <span>Dressing</span></h3>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </li>
                    <li>
                        <div class="w3_agile_portfolio_grid">
                            <a href="images/13_1.jpg">
                                <img src="images/13_1.jpg" alt=" " class="img-responsive" />
                                <div class="w3layouts_news_grid_pos">
                                    <div class="wthree_text">
                                        <h3>Hair <span>Dressing</span></h3>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </li>
                    <li>
                        <div class="w3_agile_portfolio_grid">
                            <a href="images/14_1.jpg">
                                <img src="images/14_1.jpg" alt=" " class="img-responsive" />
                                <div class="w3layouts_news_grid_pos">
                                    <div class="wthree_text">
                                        <h3>Hair <span>Dressing</span></h3>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </li>
                    <li>
                        <div class="w3_agile_portfolio_grid">
                            <a href="images/2_1.jpg">
                                <img src="images/2_1.jpg" alt=" " class="img-responsive" />
                                <div class="w3layouts_news_grid_pos">
                                    <div class="wthree_text">
                                        <h3>Hair <span>Dressing</span></h3>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </li>
                    <li>
                        <div class="w3_agile_portfolio_grid">
                            <a href="images/3_1.jpg">
                                <img src="images/3_1.jpg" alt=" " class="img-responsive" />
                                <div class="w3layouts_news_grid_pos">
                                    <div class="wthree_text">
                                        <h3>Hair <span>Dressing</span></h3>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </li>
                    <li>
                        <div class="w3_agile_portfolio_grid">
                            <a href="images/4_1.jpg">
                                <img src="images/4_1.jpg" alt=" " class="img-responsive" />
                                <div class="w3layouts_news_grid_pos">
                                    <div class="wthree_text">
                                        <h3>Hair <span>Dressing</span></h3>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </li>
                </ul>
            </div>

            <div id="testimonials" class="testimonials">
                <div class="container">
                    <div class="agileits_w3layouts_schedule">
                        <h3>Our Testimonials</h3>
                        <img src="images/1_1.png" alt=" " class="img-responsive" />
                    </div>
                    <div class="w3_agile_team_grids">
                        <section class="slider">
                            <div class="">
                                {/* <div class="flexslider"> */}
                                <ul class="slides">
                                    <li>
                                        <div class="agile_testimonials_grid">
                                            <div class="agileits_w3layouts_testimonials_grid">
                                                <img src="images/2_1.png" alt=" " class="img-responsive" />
                                                <div class="wthree_test_social_pos">
                                                    <ul class="w3_agileits_social_list1">
                                                        <li><a href="#" class="w3_agile_facebook1"><i class="fa fa-facebook"
                                                            aria-hidden="true"></i></a></li>
                                                        <li><a href="#" class="agile_twitter2"><i class="fa fa-twitter"
                                                            aria-hidden="true"></i></a></li>
                                                        <li><a href="#" class="w3_agile_dribble3"><i class="fa fa-dribbble"
                                                            aria-hidden="true"></i></a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <h3>Gretchen <span>Customer</span></h3>
                                            <p>Maecenas interdum, metus vitae tincidunt porttitor, magna quam egestas sem,
                                                ac scelerisque nisl nibh vel lacus. Proin eget gravida odio. Donec ullamcorper
                                                est
                                                eu accumsan cursus.</p>
                                        </div>
                                    </li>
                                    {/* <li>
                                        <div class="agile_testimonials_grid">
                                            <div class="agileits_w3layouts_testimonials_grid">
                                                <img src="images/4_1.png" alt=" " class="img-responsive" />
                                                <div class="wthree_test_social_pos">
                                                    <ul class="w3_agileits_social_list1">
                                                        <li><a href="#" class="w3_agile_facebook1"><i class="fa fa-facebook"
                                                            aria-hidden="true"></i></a></li>
                                                        <li><a href="#" class="agile_twitter2"><i class="fa fa-twitter"
                                                            aria-hidden="true"></i></a></li>
                                                        <li><a href="#" class="w3_agile_dribble3"><i class="fa fa-dribbble"
                                                            aria-hidden="true"></i></a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <h3>Esmeralda <span>Customer</span></h3>
                                            <p>Maecenas interdum, metus vitae tincidunt porttitor, magna quam egestas sem,
                                                ac scelerisque nisl nibh vel lacus. Proin eget gravida odio. Donec ullamcorper
                                                est
                                                eu accumsan cursus.</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="agile_testimonials_grid">
                                            <div class="agileits_w3layouts_testimonials_grid">
                                                <img src="images/3_1.png" alt=" " class="img-responsive" />
                                                <div class="wthree_test_social_pos">
                                                    <ul class="w3_agileits_social_list1">
                                                        <li><a href="#" class="w3_agile_facebook1"><i class="fa fa-facebook"
                                                            aria-hidden="true"></i></a></li>
                                                        <li><a href="#" class="agile_twitter2"><i class="fa fa-twitter"
                                                            aria-hidden="true"></i></a></li>
                                                        <li><a href="#" class="w3_agile_dribble3"><i class="fa fa-dribbble"
                                                            aria-hidden="true"></i></a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <h3>Anamaria <span>Customer</span></h3>
                                            <p>Maecenas interdum, metus vitae tincidunt porttitor, magna quam egestas sem,
                                                ac scelerisque nisl nibh vel lacus. Proin eget gravida odio. Donec ullamcorper
                                                est
                                                eu accumsan cursus.</p>
                                        </div>
                                    </li> */}
                                </ul>
                            </div>
                        </section>
                    </div>
                </div>
            </div>


            <div id="contact" class="contact" style={{ background: "url(../images/17_1.jpg) no-repeat 0px 0px" }}>
                <div class="container">
                    <div class="agileits_w3layouts_schedule">
                        <h3 class="w3_head">Get in touch with us</h3>
                        <img src="images/5_1.png" alt=" " class="img-responsive" />
                    </div>
                    <div class="w3layouts_contact_grid">
                        <p>Phasellus ut quam sit amet enim bibendum sodales quis ut urna. Aliquam erat dolor,
                            pulvinar nec nulla faucibus, bibendum tristique risus. Suspendisse varius turpis efficitur
                            erat laoreet dapibus.</p>
                        {/* <form action="#" method="post">
					<div class="col-md-6 w3_agileits_contact_grid_left">
						<input type="text" name="Name" placeholder="Name" required="">
						<input type="email" name="Email" placeholder="Email" required="">
						<input type="text" name="Phone" placeholder="Phone" required="">
					</div>
					<div class="col-md-6 w3_agileits_contact_grid_right">
						<textarea name="Message" placeholder="Type your message..." required=""></textarea>
					</div>
					<div class="clearfix"> </div>
					<div class="w3ls_submit">
						<input type="submit" value="Submit">
					</div>
				</form> */}
                        <div class="agileinfo_contact_grid_info">
                            <div class="agileits_footer_nav">
                                <ul>
                                    <li><a href="#about" class="scroll">About Us</a><i>-</i></li>
                                    <li><a href="#portfolio" class="scroll">Portfolio</a><i>-</i></li>
                                    <li><a href="#team" class="scroll">Team</a><i>-</i></li>
                                    <li><a href="#events" class="scroll">Events</a></li>
                                </ul>
                            </div>
                            <div class="agileits_footer_nav_bottom">
                                <div class="col-md-4 w3ls_footer_nav_bottom_left">
                                    <h4><i class="fa fa-phone" aria-hidden="true"></i></h4>
                                    <p>+(000) 1234 532 123</p>
                                </div>
                                <div class="col-md-4 w3ls_footer_nav_bottom_left">
                                    <h4><i class="fa fa-home" aria-hidden="true"></i></h4>
                                    <p>New York City, USA</p>
                                </div>
                                <div class="col-md-4 w3ls_footer_nav_bottom_left">
                                    <h4><i class="fa fa-envelope" aria-hidden="true"></i></h4>
                                    <p><a href="mailto:info@maria.com">info@maria.com</a></p>
                                </div>
                                <div class="clearfix"> </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    );
}

export default Portfolio;
