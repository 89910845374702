
import React, { useState, useEffect } from 'react';
import { useNavigate, Link, useParams } from 'react-router-dom';
import db from "./Firebase";
import "./style.css";
import { arrayUnion } from "firebase/firestore";
import { storage } from './Firebase';
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import Footer from "./Footer";
import Select from 'react-select';
import NumberCounter from 'number-counter';
import FooterMain from "./FooterMain";

const options = [
    { value: 'Andhra Pradesh', label: 'Andhra Pradesh' },
    { value: 'Arunachal Pradesh', label: 'Arunachal Pradesh' },
    { value: 'Assam', label: 'Assam' },
    { value: 'Bihar', label: 'Bihar' },
    { value: 'Chhattisgarh', label: 'Chhattisgarh' },
    { value: 'Goa', label: 'Goa' },
    { value: 'Gujarat', label: 'Gujarat' },
    { value: 'Haryana', label: 'Haryana' },
    { value: 'Himachal Pradesh', label: 'Himachal Pradesh' },
    { value: 'Jammu and Kashmir', label: 'Jammu and Kashmir' },
    { value: 'Jharkhand', label: 'Jharkhand' },
    { value: 'Karnataka', label: 'Karnataka' },
    { value: 'Kerala', label: 'Kerala' },
    { value: 'Madhya Pradesh', label: 'Madhya Pradesh' },
    { value: 'Maharashtra', label: 'Maharashtra' },
    { value: 'Manipur', label: 'Manipur' },
    { value: 'Meghalaya', label: 'Meghalaya' },
    { value: 'Mizoram', label: 'Mizoram' },
    { value: 'Nagaland', label: 'Nagaland' },
    { value: 'Odisha', label: 'Odisha' },
    { value: 'Punjab', label: 'Punjab' },
    { value: 'Rajasthan', label: 'Rajasthan' },
    { value: 'Sikkim', label: 'Sikkim' },
    { value: 'Tamil Nadu', label: 'Tamil Nadu' },
    { value: 'Telangana', label: 'Telangana' },
    { value: 'Tripura', label: 'Tripura' },
    { value: 'Uttarakhand', label: 'Uttarakhand' },
    { value: 'Uttar Pradesh', label: 'Uttar Pradesh' },
    { value: 'West Bengal', label: 'West Bengal' },
    { value: 'Andaman and Nicobar Islands', label: 'Andaman and Nicobar Islands' },
    { value: 'Chandigarh', label: 'Chandigarh' },
    { value: 'Dadra and Nagar Haveli', label: 'Dadra and Nagar Haveli' },
    { value: 'Daman and Diu', label: 'Daman and Diu' },
    { value: 'Delhi', label: 'Delhi' },
    { value: 'Lakshadweep', label: 'Lakshadweep' },
    { value: 'Puducherry', label: 'Puducherry' },
];

function ListTest() {
    const [data, setData] = useState([]);
    const [mergedData, setMergedData] = useState([]);

    const containerStyle = {
        display: "flex",
        width: "100%", // Ensure the container spans the entire width
        marginTop: "-5px",
    };

    const leftPartStyle = {
        flex: 1,
        border: "none",
        boxSizing: "border-box",
        overflow: "hidden", // Ensure the image doesn't overflow its container
    };

    const rightPartStyle = {
        flex: 1,
        border: "none",
        boxSizing: "border-box",
        backgroundColor: "#7d6853",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    };

    const circleContainerStyle = {
        display: "flex",
        justifyContent: "space-around",
        marginTop: "20px",
    };

    const circleImageStyle = {
        borderRadius: "50%",
        width: "180px", // Adjust the size as needed
        height: "180px", // Adjust the size as needed
        objectFit: "cover",
    };

    const circleTitleStyle = {
        marginTop: "10px",
        fontSize: "18px",
        fontWeight: "600",
        textAlign: "center",
        color: "black"
    };

    const squareContainerStyle = {
        display: "flex",
        justifyContent: "space-around",
        marginTop: "20px",
    };

    const squareImageStyle = {
        width: "250px", // Adjust the size as needed
        height: "250px", // Adjust the size as needed
        objectFit: "cover",
    };

    const squareContentStyle = {
        textAlign: "center",
        marginTop: "10px",
        display: "flex", justifyContent: "center"
    };

    const ratingStarStyle = {
        color: "gold", // Adjust the color as needed
    };



    const [count, setCount] = useState(0);



    const [selectedOption, setSelectedOption] = useState(null);

    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
        for (let count = 0; count <= 500; count++) {
            setTimeout(() => {
                setCount(count)
            }, 1000);
        }
    }, []);

    // useEffect(() => {
    //     db.collection("website_portfolio_listing").orderBy("listID", "asc").limit(4).onSnapshot((snapshot) => {
    //         setData(
    //             snapshot.docs.map((doc) => ({
    //                 id: doc.id,
    //                 data: doc.data(),
    //             }))
    //         );
    //     });
    // }, []);

    useEffect(() => {
        const fetchData = async () => {
            const portfolioDetailsSnapshot = await db.collection("portfolio_details").get();
            const portfolioDetailsData = portfolioDetailsSnapshot.docs.reduce((acc, doc) => {
                acc[doc.data().username] = {
                    id: doc.id,
                    data: doc.data(),
                };
                return acc;
            }, {});

            const websitePortfolioSnapshot = await db.collection("website_portfolio_listing").orderBy("listID", "asc").limit(4).get();
            const websitePortfolioData = websitePortfolioSnapshot.docs.map((doc) => ({
                id: doc.id,
                data: doc.data(),
            }));

            // Merge the data based on the common field 'username'
            const mergedData = websitePortfolioData.map((wpData) => {
                const matchingPortfolioDetail = portfolioDetailsData[wpData.data.username];
                return {
                    ...wpData,
                    portfolioDetail: matchingPortfolioDetail,
                };
            });

            setMergedData(mergedData);
        };

        fetchData();
    }, []);


    var img = [
        "https://images.pexels.com/photos/9901802/pexels-photo-9901802.jpeg?auto=compress&cs=tinysrgb&w=600",
        "https://images.pexels.com/photos/2100868/pexels-photo-2100868.jpeg?auto=compress&cs=tinysrgb&w=600",
        "https://images.pexels.com/photos/12952755/pexels-photo-12952755.jpeg?auto=compress&cs=tinysrgb&w=600",
        "https://images.pexels.com/photos/157757/wedding-dresses-fashion-character-bride-157757.jpeg?auto=compress&cs=tinysrgb&w=600",
        "https://images.pexels.com/photos/206329/pexels-photo-206329.jpeg?auto=compress&cs=tinysrgb&w=600",

    ];

    const handleChange = e => {
        setSelectedOption(e.value);
        console.log(e.value)
        navigate(`/artist/${e.value}`);
    }

    return (
        <>

            <section>
                <div class="navi" style={{ backgroundColor: "#ec5d69" }}>
                    <input type="checkbox" id="nav-check" />
                    <div class="nav-header">
                        <div class="nav-title">
                            <Link to="/"> <img src="/bridal/youvati_white_logo.png" alt="" style={{ height: "22px", marginLeft: "1rem" }} /></Link>
                        </div>
                    </div>
                    <div class="nav-btn">
                        <label for="nav-check">
                            <span></span>
                            <span></span>
                            <span></span>
                        </label>
                    </div>

                    <div class="nav-links" id="top">
                        <a href="#" class="linkcolor" style={{ color: "white" }}>Bridal</a>
                        <span class="line" style={{ color: "white" }}>|</span>
                        <a href="#portfolio" class="linkcolor" style={{ color: "white" }}>Why Choose Us</a>
                        <span class="line" style={{ color: "white" }}>|</span>
                        <a href="#partners" class="linkcolor" style={{ color: "white" }}>For Partners</a>

                        <span class="line" style={{ color: "white" }}>|</span>
                        <a href="#reachtous" class="linkcolor" style={{ color: "white" }}>Contact Us</a>

                    </div>
                </div>
            </section>


            {/* <div class="row" >

                <div class="col-md-7" style={{ height: "15rem", backgroundColor: "red" }}>

                </div>

                <div class="col-md-5" style={{ height: "15rem", backgroundColor: "green" }}>

                </div>

            </div> */}

            {/* <div style={{
                justifyContent: "space-between", display: "flex",
                width: "100%", marginTop: "-5px",
            }}>
                <div style={leftPartStyle}>
                    <img
                        src="/bridal/Group_27.png" // Replace with the actual image URL
                        alt="Left Part Image"
                        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                    />
                </div>
                <div style={{ ...rightPartStyle, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <img
                        src="/bridal/rectangle-40@2x.png" // Replace with the actual image URL
                        alt="Left Part Image"
                        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                    />
                </div>

            </div> */}

            <div class="">
                <div class="row" style={{ marginTop: "-5px" }}>

                    <div class="col-12 text-center">
                        {/* <h2 class="mb-2 h1 font-weight-bold">Super clean user interface for easier use.</h2>
                        <p class="text-muted mb-5 pb-3">Our design projects are fresh and simple and will benefit your
                            business greatly.</p> */}
                        <div class="row">
                            <div class="col-lg-6 col-md-6">
                                <img src="/bridal/Group_27.png" class="" style={{ width: "103%" }} />

                                {/* <p class="mb-4 text-muted">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus ut mattis metus, id accumsan erat.</p>
                                <a href="" class="btn btn-link">View More &rarr;</a> */}
                            </div>
                            <div class="col-lg-6 col-md-6" style={{ ...rightPartStyle, }}>
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: "1rem" }}>
                                    <h3 style={{ color: "white" }}>Find the Perfect Beauty</h3>
                                    <h3 style={{ color: "white" }}>Professional Next Door!</h3>
                                    <div style={{ textAlign: 'center', marginTop: '20px', display: "flex", width: "15rem" }}>

                                        <div style={{ width: "25rem" }}>
                                            <Select
                                                defaultValue={selectedOption}
                                                onChange={handleChange}
                                                options={options}
                                                placeholder="Search via state"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>



            {/* <div style={containerStyle}>
                <div style={leftPartStyle}>
                    <img
                        src="/bridal/Group_27.png"
                        alt="Left Part Image"
                        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                    />
                </div>
                <div style={{ ...rightPartStyle, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <h3 style={{ color: "white" }}>Find the Perfect Beauty</h3>
                    <h3 style={{ color: "white" }}>Professional Next Door!</h3>
                    <div style={{ textAlign: 'center', marginTop: '20px' }}>

                        <div style={{ width: "25rem" }}>
                            <Select
                                defaultValue={selectedOption}
                                onChange={handleChange}
                                options={options}
                                placeholder="Search via state"
                            />
                        </div>
                    </div>
                </div>
            </div> */}

            <div style={{ textAlign: 'center', marginTop: '20px' }}>
                <p style={{ fontSize: "22px", fontWeight: "600", color: "black", textDecoration: "underline" }}>We Have Beauty Professionals Listed From The Following Cities</p>
            </div>
            <div style={{ textAlign: "center" }}>
                <p style={{ fontSize: "17px", fontWeight: "400", color: "black", marginBottom: "10px" }}>
                    Delhi | Mumbai | Aurangabad | Washim | Jalna | Agra | Kanpur | Meerut |
                </p>
                <p style={{ fontSize: "17px", fontWeight: "400", color: "black", marginTop: "-8px", marginBottom: "10px" }}>
                    Ghaziabad | Chandigarh | Patiala | Amritsar | Bathinda | Mansa
                </p>
            </div>
            <h2 style={{ fontSize: "24px", fontWeight: "600", color: "black", paddingLeft: "4rem", paddingTop: "2rem" }}>Service Categories</h2>

            <div class="">
                <div class="row py-2">

                    <div class="col-12 text-center">
                        {/* <h2 class="mb-2 h1 font-weight-bold">Super clean user interface for easier use.</h2>
                        <p class="text-muted mb-5 pb-3">Our design projects are fresh and simple and will benefit your
                            business greatly.</p> */}
                        <div class="row">
                            <div class="col-lg-3 col-md-6 vv profile-circel-image-200 mt-2">
                                <Link to="/bridal_page"> <img src="/bridal/ellipse-6@2x.png" class="img-fluid rounded-circle" />
                                    <h5 class="mb-3 mt-2" style={circleTitleStyle}>Bridal</h5>
                                </Link>
                                {/* <p class="mb-4 text-muted">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus ut mattis metus, id accumsan erat.</p>
                                <a href="" class="btn btn-link">View More &rarr;</a> */}
                            </div>
                            <div class="col-lg-3 col-md-6 vv profile-circel-image-200 mt-2">
                                <Link to="/bridal_page">  <img src="/bridal/ellipse-1@2x.png" class="rounded img-fluid rounded-circle" />
                                    <h5 class="mb-3 mt-2" style={circleTitleStyle}>Makeup</h5>
                                </Link>
                                {/* <p class="mb-4 text-muted">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus ut mattis metus, id accumsan erat.</p>
                                <a href="" class="btn btn-link">View More &rarr;</a> */}
                            </div>
                            <div class="col-lg-3 col-md-6 vv profile-circel-image-200 mt-2">
                                <Link to="/bridal_page">  <img src="/bridal/ellipse-2@2x.png" class="rounded img-fluid rounded-circle" />
                                    <h5 class="mb-3 mt-2" style={circleTitleStyle}>Skin</h5>
                                </Link>
                                {/* <p class="mb-4 text-muted">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus ut mattis metus, id accumsan erat.</p>
                                <a href="" class="btn btn-link">View More &rarr;</a> */}
                            </div>
                            <div class="col-lg-3 col-md-6 vv profile-circel-image-200 mt-2">
                                <Link to="/bridal_page">  <img src="/bridal/ellipse-3@2x.png" class="rounded img-fluid rounded-circle" />
                                    <h5 class="mb-3 mt-2" style={circleTitleStyle}>Others</h5>
                                </Link>
                                {/* <p class="mb-4 text-muted">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus ut mattis metus, id accumsan erat.</p>
                                <a href="" class="btn btn-link">View More &rarr;</a> */}
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            {/* <div style={{ textAlign: "left", marginTop: "20px" }}>
                <h2 style={{ fontSize: "24px", fontWeight: "600", color: "black", paddingLeft: "4rem", paddingTop: "2rem" }}>Service Categories</h2>
            </div>
            <div style={circleContainerStyle}>
                <Link to="/bridal_page">
                    <div>
                        <img
                            src="/bridal/ellipse-6@2x.png" // Replace with the actual image URL
                            alt="Category 1"
                            style={circleImageStyle}
                        />
                        <p style={circleTitleStyle}>Bridal</p>
                    </div></Link>
                <div>
                    <img
                        src="/bridal/ellipse-1@2x.png" // Replace with the actual image URL
                        alt="Category 2"
                        style={circleImageStyle}
                    />
                    <p style={circleTitleStyle}>Makeup</p>
                </div>
                <div>
                    <img
                        src="/bridal/ellipse-2@2x.png" // Replace with the actual image URL
                        alt="Category 3"
                        style={circleImageStyle}
                    />
                    <p style={circleTitleStyle}>Skin</p>
                </div>
                <div>
                    <img
                        src="/bridal/ellipse-3@2x.png" // Replace with the actual image URL
                        alt="Category 4"
                        style={circleImageStyle}
                    />
                    <p style={circleTitleStyle}>Hair</p>
                </div>
                <div>
                    <img
                        src="/bridal/ellipse-5@2x.png" // Replace with the actual image URL
                        alt="Category 5"
                        style={circleImageStyle}
                    />
                    <p style={circleTitleStyle}>Others</p>
                </div>
            </div> */}


            <div class="pt-2 pb-4">
                <div class="row pt-4" style={{ backgroundColor: "black" }}>
                    <div class="col d-flex justify-content-center">
                        <div class="card card-2 pb-2 ccard" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <div> <span style={{ fontSize: "25px", color: "black", fontWeight: "700" }}><NumberCounter end={48} delay={4} /></span>
                                <span style={{ color: "black", fontSize: "15px", }}>Cities</span>
                            </div>

                        </div>
                    </div>
                    <div class="col d-flex justify-content-center">
                        <div class="card card-2 ccard" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <div> <span style={{ fontSize: "25px", color: "black", fontWeight: "700" }}><NumberCounter end={500} delay={10} postFix="+" /></span>
                                <span style={{ color: "black", fontSize: "15px", }}>Beauty Professionals</span>
                            </div>

                        </div>
                    </div>
                    <div class="col d-flex justify-content-center">
                        <div class="card card-2 ccard" style={{ display: "flex", justifyContent: "center", alignItems: "center" }} >
                            <div> <span style={{ fontSize: "25px", color: "black", fontWeight: "700" }}><NumberCounter end={10000} delay={10} postFix="+" /></span>
                                <span style={{ color: "black", fontSize: "15px", }}>Bookings Done</span>
                            </div>

                        </div>
                    </div>

                    <div class="col d-flex justify-content-center">
                        <div class="card card-2 ccard" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <span style={{ fontSize: "25px", color: "black", fontWeight: "700" }}>4.8 <span><i class="fa fa-star" style={{ color: "orange" }}></i></span>  </span>

                            <span style={{ color: "black", fontSize: "15px", paddingTop: "1pc" }}>Average Rating</span>
                        </div>
                    </div>

                </div>
            </div>


            <div style={{ textAlign: "left", marginTop: "20px" }}>
                <h2 style={{ fontSize: "24px", fontWeight: "600", color: "black", paddingLeft: "4rem", paddingTop: "2rem" }}>Top Bridal Artist</h2>
            </div>
            <div class="">
                <div class="row py-2">

                    <div class="col-12 text-center">
                        {/* <h2 class="mb-2 h1 font-weight-bold">Super clean user interface for easier use.</h2>
                        <p class="text-muted mb-5 pb-3">Our design projects are fresh and simple and will benefit your
                            business greatly.</p> */}
                        <div class="row">
                            {mergedData.map(({ id, data, portfolioDetail }, index) => (
                                <>
                                    <div class="col-lg-3 col-md-6 profile-square-image-250 mt-2 ">
                                        <Link to={`/${data.username}`}><div class="img-wrapper"> <img src={portfolioDetail ? portfolioDetail.data.portfolio_image : '/default-image.jpg'} class="inner-img" /></div>
                                            <div style={squareContentStyle}>
                                                <div style={{ width: "150px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", }}>
                                                    <span style={{ fontSize: "16px", fontWeight: "600", color: "black" }}>
                                                        {data.parlour_name}
                                                    </span>
                                                    <br />
                                                    <span style={ratingStarStyle}>★</span>
                                                    <span style={ratingStarStyle}>★</span>
                                                    <span style={ratingStarStyle}>★</span>
                                                    <span style={ratingStarStyle}>★</span>
                                                    <span style={ratingStarStyle}>★</span>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                </>
                            ))}

                        </div>

                    </div>
                </div>
            </div>

            {/* <div style={squareContainerStyle}>
                {mergedData.map(({ id, data, portfolioDetail }, index) => (
                    <>
                        <Link to={`/${data.username}`}>
                            <div>
                                <div class="img-wrapper">
                                    <img
                                        class="inner-img"
                                        src={portfolioDetail ? portfolioDetail.data.portfolio_image : '/default-image.jpg'} // Replace with the actual image URL
                                        alt="Category 1"
                                        style={squareImageStyle}
                                    />
                                </div>
                                <div style={squareContentStyle}>
                                    <div style={{ width: "150px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", }}>
                                        <span style={{ fontSize: "16px", fontWeight: "600", color: "black" }}>
                                            {data.parlour_name}
                                        </span>
                                        <br />
                                        <span style={ratingStarStyle}>★</span>
                                        <span style={ratingStarStyle}>★</span>
                                        <span style={ratingStarStyle}>★</span>
                                        <span style={ratingStarStyle}>★</span>
                                        <span style={ratingStarStyle}>★</span>
                                    </div>
                                </div>

                            </div>
                        </Link>
                    </>
                ))}
                {/* <div>
                    <div class="img-wrapper">
                        <img
                            class="inner-img"
                            src="/bridal/rectangle-12@2x.png" // Replace with the actual image URL
                            alt="Category 1"
                            style={squareImageStyle}
                        />
                    </div>
                    <div style={squareContentStyle}>
                        <div style={{ width: "150px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", }}>
                            <span style={{ fontSize: "16px", fontWeight: "600", color: "black" }}>
                                Aliya Makeovers
                            </span>
                            <br />
                            <span style={ratingStarStyle}>★</span>
                            <span style={ratingStarStyle}>★</span>
                            <span style={ratingStarStyle}>★</span>
                            <span style={ratingStarStyle}>★</span>
                            <span style={ratingStarStyle}>★</span>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="img-wrapper">
                        <img
                            class="inner-img"
                            src="/bridal/rectangle-17@2x.png" // Replace with the actual image URL
                            alt="Category 1"
                            style={squareImageStyle}
                        />
                    </div>
                    <div style={squareContentStyle}>
                        <div style={{ width: "150px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", }}>
                            <span style={{ fontSize: "16px", fontWeight: "600", color: "black" }}>
                                Zoya Makeovers
                            </span>
                            <br />
                            <span style={ratingStarStyle}>★</span>
                            <span style={ratingStarStyle}>★</span>
                            <span style={ratingStarStyle}>★</span>
                            <span style={ratingStarStyle}>★</span>
                            <span style={ratingStarStyle}>★</span>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="img-wrapper">
                        <img
                            class="inner-img"
                            src="/bridal/rectangle-14@2x.png" // Replace with the actual image URL
                            alt="Category 1"
                            style={squareImageStyle}
                        />
                    </div>
                    <div style={squareContentStyle}>
                        <div style={{ width: "150px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", }}>
                            <span style={{ fontSize: "16px", fontWeight: "600", color: "black" }}>
                                Sita Makeovers
                            </span>
                            <br />
                            <span style={ratingStarStyle}>★</span>
                            <span style={ratingStarStyle}>★</span>
                            <span style={ratingStarStyle}>★</span>
                            <span style={ratingStarStyle}>★</span>
                            <span style={ratingStarStyle}>★</span>
                        </div>
                    </div>
                </div> */}
            {/* </div> */}

            {/* <div class="container pt-5 pb-3 p-4">

                <div class="row">
                    <div class="col-sm-6 col-md-6 col-lg-6" style={{ backgroundColor: "" }}>
                        <span class="mainheading">Find the Perfect Beauty Professional Next Door!</span>
                        <div style={{ paddingTop: "17px", paddingBottom: "24px" }}><span style={{ fontSize: "17px", paddingRight: "20px", fontStyle: "italic" }}>Beautify</span>  |  <span style={{ fontSize: "17px", paddingRight: "20px", paddingLeft: "20px", fontStyle: "italic" }}>Celebrate</span>  |   <span style={{ fontSize: "17px", paddingRight: "20px", paddingLeft: "20px", fontStyle: "italic" }}>Share</span></div>


                        <div style={{ paddingRight: "6rem" }}>
                            <Select
                                defaultValue={selectedOption}
                                onChange={handleChange}
                                options={options}
                                placeholder="Search via state"
                            />
                        </div>

                        <div class="pt-5" style={{
                            paddingBottom: "6px", borderBottomStyle: "solid",
                            borderBottomWidth: "2.1px",
                            width: "fit-content"
                        }}><span style={{ fontSize: "16px", fontWeight: "500" }}>We Have Beauty Professionals Listed From The Following Cities:</span></div>
                        <div class="pt-3" style={{ wordSpacing: "7px", lineHeight: "26px", fontSize: "14px" }}><span>Delhi | Mumbai | Aurangabad | Washim | Jalna | Agra | Kanpur | Meerut | Ghaziabad | Chandigargh | Patiala | Amritsar | Bathinda | Mansa</span></div>
                    </div>
                    <div class="col-sm-6 col-md-6 col-lg-6" >
                        <div  ><img class="img-fluid" src="/images/yuvatihomeimage.png" style={{

                        }}></img></div>
                    </div>
                </div>
            </div> */}




            {/* <div class="pt-5" style={{ textAlign: "center" }}><span style={{ fontSize: "22px", fontWeight: "700" }}>TOP BEAUTY ARTISTS!</span></div>
            <div class="container">
                <p class="block-lg p-3" style={{ textAlign: "justify", textJustify: "inter-word", }}>From makeup artists to hairstylists, we feature a range of experts with varying skills and styles. Each artist has their own profile, which showcases their portfolio, services, and pricing. You can also read reviews from previous clients to help you make an informed decision.</p>
            </div> */}
            {/* 
            <section class="">
                <div class="container">
                    <div class="">
                        <div class="row">

                            <div class="col-md-4 mb-3" style={{ padding: "3rem" }}>
                                <div class="" style={{
                                    borderStyle: "solid", borderWidth: "3px",
                                    borderImage: "linear-gradient(45deg, rgb(252,79,135), rgb(255,242,86)) 1"
                                }}>
                                    <img class="img-fluid" alt="100%x280" src="/images/indianbridal.jpg" />
                                    <div class="p-3" style={{ color: "white", backgroundColor: "#e92b91", textAlign: "center", fontSize: "17px", fontWeight: "600" }}><span>Maharashtrian Bridal Look</span></div>
                                    <div class="card-body">
                                        <div><span class="card-title" style={{ fontSize: "14px" }}>From : Rashika Makeover <img src="/images/verified-badge.png" style={{ width: "16px" }}></img></span></div>
                                        <div><span class="card-title" style={{ fontSize: "14px" }}>Location : Aurangabad, Maharashtra</span></div>
                                        <div class="row">
                                            <div class="col-md-8 col-sm-8 col-lg-8" style={{ fontSize: "14px", color: "#007bff" }}><span>Willing to travel <img src="/images/plane.png" style={{ width: "16px" }}></img></span></div>
                                            <div class="col-md-4 col-sm-4 col-lg-4" style={{ fontSize: "12px", textAlign: "end", fontWeight: "500" }}><Link to='harpreetmakeovers'>Explore</Link></div>
                                        </div>

                                    </div>

                                </div>
                            </div>

                            <div class="col-md-4 mb-3" style={{ padding: "3rem" }}>
                                <div class="" style={{
                                    borderStyle: "solid", borderWidth: "3px",
                                    borderImage: "linear-gradient(45deg, rgb(252,79,135), rgb(255,242,86)) 1"
                                }}>
                                    <img class="img-fluid" alt="100%x280" src="/images/savitaekaur.jpg" />
                                    <div class="p-3" style={{ color: "white", backgroundColor: "#e92b91", textAlign: "center", fontSize: "17px", fontWeight: "600" }}><span>Eyelash Extension</span></div>
                                    <div class="card-body">
                                        <div><span class="card-title" style={{ fontSize: "14px" }}>From : Rashika Makeover <img src="/images/verified-badge.png" style={{ width: "16px" }}></img></span></div>
                                        <div><span class="card-title" style={{ fontSize: "14px" }}>Location : Aurangabad, Maharashtra</span></div>
                                        <div class="row">
                                            <div class="col-md-8 col-sm-8 col-lg-8" style={{ fontSize: "14px", color: "#007bff" }}><span>Willing to travel <img src="/images/plane.png" style={{ width: "16px" }}></img></span></div>
                                            <div class="col-md-4 col-sm-4 col-lg-4" style={{ fontSize: "12px", textAlign: "end", fontWeight: "500" }}><Link to='harpreetmakeovers'>Explore</Link></div>
                                        </div>

                                    </div>

                                </div>
                            </div>

                            <div class="col-md-4 mb-3" style={{ padding: "3rem" }}>
                                <div class="" style={{
                                    borderStyle: "solid", borderWidth: "3px",
                                    borderImage: "linear-gradient(45deg, rgb(252,79,135), rgb(255,242,86)) 1"
                                }}>
                                    <img class="img-fluid" alt="100%x280" src="/images/image3.jpg" />
                                    <div class="p-3" style={{ color: "white", backgroundColor: "#e92b91", textAlign: "center", fontSize: "17px", fontWeight: "600" }}><span>Party Makeup Look</span></div>
                                    <div class="card-body">
                                        <div><span class="card-title" style={{ fontSize: "14px" }}>From : Rashika Makeover <img src="/images/verified-badge.png" style={{ width: "16px" }}></img></span></div>
                                        <div><span class="card-title" style={{ fontSize: "14px" }}>Location : Aurangabad, Maharashtra</span></div>
                                        <div class="row">
                                            <div class="col-md-8 col-sm-8 col-lg-8" style={{ fontSize: "14px", color: "#007bff" }}><span>Willing to travel <img src="/images/plane.png" style={{ width: "16px" }}></img></span></div>
                                            <div class="col-md-4 col-sm-4 col-lg-4" style={{ fontSize: "12px", textAlign: "end", fontWeight: "500" }}><Link to='harpreetmakeovers'>Explore</Link></div>
                                        </div>

                                    </div>

                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </section> */}

            {/* <div class="container">
                <div class="grid-container grido" style={{}}>

                    <div class="" style={{
                        height: "auto",
                        borderStyle: "solid", borderWidth: "3px",
                        borderImage: "linear-gradient(45deg, rgb(252,79,135), rgb(255,242,86)) 1"
                    }}>
                        <img src="https://firebasestorage.googleapis.com/v0/b/youvati.appspot.com/o/portfolio%2Fscaled_image_picker2843540904606069872.jpg?alt=media&token=86874398-22f9-4023-840d-d098fdb6fb4e" class="img-fluid" alt="Bhavya Makeovers" style={{ width: "100%", height: "308px", objectFit: "cover" }} />
                        <div class="p-3" style={{ color: "white", backgroundColor: "#e92b91", textAlign: "center", fontSize: "15px", fontWeight: "600", textTransform: "capitalize" }}><span>Bhavya 🥰Makeovers</span></div>
                        <div class="p-2" style={{ textAlign: "start" }}>
                            <div><span class="card-title" style={{ fontSize: "14px", textTransform: "capitalize" }}>Location : Dulhera Chauhan modipuram meerut</span></div>
                        </div>
                        <div class="grid-container-element">
                            <div class="grid-child-element purple"><span style={{ color: "#007bff" }}>Willing to travel <img src="/images/plane.png" style={{ width: "16px" }}></img></span></div>
                            <div class="grid-child-element green"><Link to='@bhawanachauhan'>Explore</Link></div>
                        </div>
                    </div>

                    <div class="" style={{
                        height: "auto",
                        borderStyle: "solid", borderWidth: "3px",
                        borderImage: "linear-gradient(45deg, rgb(252,79,135), rgb(255,242,86)) 1"
                    }}>
                        <img src="https://firebasestorage.googleapis.com/v0/b/youvati.appspot.com/o/portfolio%2Fscaled_image_picker1232703533219158540.jpg?alt=media&token=85a70e7e-a932-4add-843f-d3eefd66268a" class="img-fluid" alt="Reenu Makeover" style={{ width: "100%", height: "308px", objectFit: "cover" }} />
                        <div class="p-3" style={{ color: "white", backgroundColor: "#e92b91", textAlign: "center", fontSize: "15px", fontWeight: "600", textTransform: "capitalize" }}><span>Reenu Makeover</span></div>
                        <div class="p-2" style={{ textAlign: "start" }}>
                            <div><span class="card-title" style={{ fontSize: "14px", textTransform: "capitalize" }}>Location : Khushaupur bulandshahr chehla &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></div>
                        </div>
                        <div class="grid-container-element">
                            <div class="grid-child-element purple"><span style={{ color: "#007bff" }}>Willing to travel <img src="/images/plane.png" style={{ width: "16px" }}></img></span></div>
                            <div class="grid-child-element green"><Link to='reenu'>Explore</Link></div>
                        </div>
                    </div>

                    <div class="" style={{
                        height: "auto",
                        borderStyle: "solid", borderWidth: "3px",
                        borderImage: "linear-gradient(45deg, rgb(252,79,135), rgb(255,242,86)) 1"
                    }}>
                        <img src="https://firebasestorage.googleapis.com/v0/b/youvati.appspot.com/o/portfolio%2Fscaled_image_picker7716656348709190945.jpg?alt=media&token=1121876b-58e8-4f3b-b5a7-edb78593ae54" class="img-fluid" alt="Reena_Beautician Makeover By Prerna" style={{ width: "100%", height: "308px", objectFit: "cover" }} />
                        <div class="p-3" style={{ color: "white", backgroundColor: "#e92b91", textAlign: "center", fontSize: "15px", fontWeight: "600", textTransform: "capitalize" }}><span>Reena_Beautician Makeover By Prerna</span></div>
                        <div class="p-2" style={{ textAlign: "start" }}>
                            <div><span class="card-title" style={{ fontSize: "14px", textTransform: "capitalize" }}>Location : Reena niwas Sai garden colony,amheda</span></div>
                        </div>
                        <div class="grid-container-element">
                            <div class="grid-child-element purple"><span style={{ color: "#007bff" }}>Willing to travel <img src="/images/plane.png" style={{ width: "16px" }}></img></span></div>
                            <div class="grid-child-element green"><Link to='prernarajput'>Explore</Link></div>
                        </div>
                    </div>


                </div>
            </div> */}
            {/* <div class="row">

                <div class="col-md-12" style={{ textAlign: "center" }}>
                    <img class="bannermain" src="/images/banner101.png"></img>

                </div>


            </div> */}
            <div class="row">
                <img src="/images/banner101.png" class="img-fluid" alt="Wild Landscape" />
            </div>


            <section class="section bg-default text-center" id="portfolio" style={{ padding: "20px", }}>
                <div class="pt-6">
                    <h2>SHOWCASE</h2>
                    <p class="block-lg p-3" style={{ textAlign: "justify", textJustify: "inter-word", }}>We employ the best experts in the beauty industry and educate our own award-winning
                        specialists through constant training. Here are the most prominent talents of our team who make your beauty
                        dreams come true.</p>
                </div>
                <div class="">
                    <div class="row">
                        {/* <div class="col-lg-1" style={{ textAlign: "right", marginTop: "8rem" }}>
                                <span onClick={() => window.moveToSelected("prev")}><p style={{ fontSize: "30px", cursor: "pointer" }}>{`<`}</p></span>
                            </div> */}
                        <div class="col-lg-12">
                            {/* {TopLink()} */}
                            <div id="slider-container" class="slider">
                                {img.map((e) => (
                                    <div class="slide">
                                        <img src={e} alt="" />
                                    </div>
                                ))
                                }

                                <div onClick={() => window.prevS()} class="control-prev-btn">
                                    {`<`}
                                </div>
                                <div onClick={() => window.nextS()} class="control-next-btn">
                                    {`>`}
                                </div>
                            </div>


                            <div class="overlay"></div>
                            {/* {GlorySlider()} */}
                        </div>
                        {/* <div class="col-lg-1" style={{ textAlign: "left", marginTop: "8rem" }}>
                                <span onClick={() => window.moveToSelected("next")}><p style={{ fontSize: "30px", cursor: "pointer" }}>{`>`}</p></span>
                            </div> */}
                    </div>
                    {/* <div class="col-12" style={{ textAlign: "center", }}><Link to={`/${params.id.toLowerCase()}/gallery`} class="button" style={{ marginBottom: "0rem", marginTop: "2rem", color: "black", backgroundImage: "linear-gradient(#fe8e4e, #fedc56)", borderColor: "white" }}>View more </Link></div> */}
                </div>
            </section>

            <section class="section bg-default text-center p-5" id="reviews" style={{ padding: "50px", backgroundColor: "#212529" }}>

                <div class="row row-50">
                    <div class="col-lg-6" style={{ textAlign: "initial" }}>
                        <div class="block-xs">
                            <h2 class="heading-decorate" style={{ fontFamily: "Roboto, sans-serif", color: "white" }}>Testimonials<br class="d-inline" /><span class="divider"></span>of Our Clients</h2>
                            <p class="big  text-gray-800" style={{ fontFamily: "Roboto, sans-serif", color: "white", textAlign: "justify", fontSize: "15px", }}>We like to maintain a constant connection with our customers and receive feedback on every service, whether it's a simple haircut or complex wedding makeup. If you've already tried out our services,feel free to contact us and send your testimonial.</p>

                        </div>
                    </div>
                    <div class="col-lg-6">
                        {/* <section class="carousel" aria-label="Gallery"> */}
                        <section class="" aria-label="Gallery">
                            {/* <TestimonialSlider /> */}
                            <div class="testimonial-container">
                                <div class="progress-bar"></div>

                                <p class="testimonial pt-4" style={{ color: "white" }}>Unlike other salons, we prefer to maintain a constant connection with our customers and receive feedback on every service, whether it’s a simple haircut or complex wedding makeup. If you’ve already visited Glory, feel free to contact us and send your testimonial.</p>
                                <div class="user" style={{ display: "flex" }}>
                                    <img src="" style={{ display: "none" }} alt="user" class="user-image" />
                                    <div class="user-details">
                                        <h4 class="username" style={{ color: "white" }} >Anushka Sharma</h4>
                                        <p class="role"></p>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </section>

            <section class="section bg-default text-center  p-5" id="portfolio" style={{ padding: "50px", }}>
                <h3>BECOME A PARTNER</h3>
                <div class="row">
                    <div class="col">
                        {/* <h5>Manage your success with</h5> */}
                        <img class="partnerone" src="/images/youvatiappdata.png"></img>

                        {/* <div class="row" style={{ marginTop: "-1rem", marginLeft: "2rem" }}> */}

                        {/* </div> */}
                        {/* <div class="pt-5"><span>Or click here to become a </span><span style={{ background: "linear-gradient(yellow,#ff6200)", borderRadius: "8px", fontWeight: "600", fontSize: "12px", padding: "7px" }}>Partner</span></div>
                        <div class="pb-4"><a href="https://play.google.com/store/apps/details?id=com.empower.youvati" target="_blank" without rel="noreferrer"><img style={{ width: "7rem", paddingTop: "1rem" }} alt="playstore" src="https://b.zmtcdn.com/data/webuikit/23e930757c3df49840c482a8638bf5c31556001144.png" loading="lazy" class="sc-s1isp7-5 fyZwWD" /></a></div> */}
                    </div>
                    <div class="col pt-5">
                        <img class="partnerone" src="/images/mobilepreviewmain.png"></img>
                    </div>
                </div>
                <div class="row">
                    {/* <div class="col-md-6" style={{ textAlign: "left", marginTop: "50px", fontWeight: "500", fontSize: "15px", paddingLeft: "3rem" }}>To Become Partner Download The App Now! </div> */}
                    <div class="col-md-12">To Become Partner Download The App Now! <br /><br /><a href="https://play.google.com/store/apps/details?id=com.empower.youvati" target="_blank" without rel="noreferrer"><img style={{ width: "7rem", paddingTop: "0rem", marginLeft: "1rem" }} alt="playstore" src="https://b.zmtcdn.com/data/webuikit/23e930757c3df49840c482a8638bf5c31556001144.png" loading="lazy" class="sc-s1isp7-5 fyZwWD" /></a></div>
                </div>
            </section>

            {/* <div id="partners" class="" style={{ background: "#212529", padding: "20px" }}>
                <div class="row">
                    <div class="col-sm" style={{ color: "white", textAlign: "center", marginBottom: "2rem" }}>
                        <span style={{ fontSize: "25px", color: "white", fontWeight: "700", }}><img src="/images/ps4.png" width="200"></img></span><br />
                    </div>
                    <div class="col-sm" style={{ color: "white", textAlign: "center", marginBottom: "2rem" }}>
                        <span style={{ fontSize: "25px", color: "white", fontWeight: "700", }}><img src="/images/ps2.png" width="200"></img></span><br />
                    </div>
                    <div class="col-sm" style={{ color: "white", textAlign: "center", marginBottom: "2rem" }}>
                        <span style={{ fontSize: "25px", color: "white", fontWeight: "700", }}><img src="/images/ps1.png" width="200"></img></span><br />
                    </div>
                    <div class="col-sm" style={{ color: "white", textAlign: "center", marginBottom: "2rem" }}>
                        <span style={{ fontSize: "25px", color: "white", fontWeight: "700", }}><img src="/images/ps3.png" width="200"></img></span><br />
                    </div>

                </div>
            </div> */}

            <div class="row" id="partners">
                <img src="/images/logoband1.jpg" class="img-fluid" alt="Wild Landscape" />
            </div>

            {/* <section id="reachtous" class="section bg-default text-center pt-5 pb-5">
                <div class="container">
                    <h2>REACH OUT TO US</h2>
                </div>
                <div class="container">
                    <div class="row justify-content-md-center">
                        <div class="col col-lg-3" style={{ textAlign: "initial", paddingBottom: "10px", }}>
                            <img src="/images/call.png" style={{ height: "30px", paddingRight: "10px", }}></img> +91-7878675676
                        </div>
                        <div class="col-lg-3" style={{ textAlign: "initial", paddingBottom: "10px", }}>
                            <img src="/images/whatsappp.png" style={{ height: "30px", paddingRight: "10px", }}></img> xxxxxx xxxxx
                        </div>
                        <div class="col-lg-6" style={{ textAlign: "center", paddingBottom: "10px", }}>
                            <img src="/images/location.png" style={{ height: "30px", paddingRight: "10px" }}></img>
                            Ground Floor, 35, Khasra No. 369,370,382, Ghitorni Branch, 371, 100 Feet Rd, near Canara Bank, Ghitorni, New Delhi, Delhi 110030"
                        </div>
                    </div>
                </div>
            </section> */}

            {/* <FooterMain /> */}
            <div class="footer-04">
                <div class="container">
                    <div class="row">
                        <div class="col-md-6">
                            <ul style={{ paddingLeft: "3rem", paddingTop: "2rem", }}>
                                <Link to="/" onClick={() => window.scrollTo(0, 0)}><li style={{ color: "white" }}>Home</li></Link>
                                <Link to="/aboutus"><li style={{ color: "white" }}>About Us</li></Link>
                                <Link to="/privacy_policy"><li style={{ color: "white" }}>Privacy Policy</li></Link>
                                <Link to="/contactus"><li style={{ color: "white" }}>Contact Us</li></Link>
                                {/* <li style={{ color: "white" }}>About Us</li> */}
                                {/* <li style={{ color: "white" }}>Privacy Policy</li> */}
                                {/* <li style={{ color: "white" }}>Contact Us</li> */}
                                <li><img style={{ width: "9rem", paddingTop: "1rem" }} alt="playstore" src="https://b.zmtcdn.com/data/webuikit/23e930757c3df49840c482a8638bf5c31556001144.png" loading="lazy" class="sc-s1isp7-5 fyZwWD" /></li>
                            </ul>
                        </div>
                        <div class="col-md-6 pt-3">
                            <div class="wrapper">
                                <div class="">
                                    <Select
                                        defaultValue={selectedOption}
                                        onChange={handleChange}
                                        options={options}
                                        placeholder="Search via state"
                                    />

                                </div>
                                <ul class="ftco-footer-social p-0 pt-4 mr-4" style={{ display: "flex", justifyContent: "center" }}>
                                    <a href="https://www.facebook.com/youvatiofficial?mibextid=ZbWKwL" target="_blank"><img src="/images/facebookk.png" style={{ height: "30px", paddingRight: "10px" }}></img></a>
                                    <a href="https://api.whatsapp.com/send?phone=7428790444" target="_blank"><img src="/images/whatsappp.png" style={{ height: "30px", paddingRight: "10px" }}></img></a>
                                    <a href="https://instagram.com/youvatiofficial?igshid=MzRlODBiNWFlZA==" target="_blank"><img src="/images/insta.png" style={{ height: "30px", paddingRight: "10px" }}></img></a>
                                    <a href="https://twitter.com/Youvatiofficial?s=20" target="_blank"><img src="/images/twitter.png" style={{ height: "30px", paddingRight: "10px" }}></img></a>
                                    <a href="https://www.linkedin.com/company/youvati/ " target="_blank"><img src="/images/linkedin.png" style={{ height: "30px", paddingRight: "10px" }}></img></a>
                                </ul>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="" style={{ textAlign: "center", background: "#272727", color: "white", paddingBottom: "1rem" }}>Powred By ~ Youvati</div>
            </div>


        </>
    )
}

export default ListTest;




{/* <div class="container">
<div class="row" style={{ marginTop: "2px", border: "double 2px transparent", borderRadius: "10px", backgroundImage: "linear-gradient(white, white), linear-gradient(to right, rgb(252,79,135), rgb(255,242,86))", backgroundOrigin: "border-box", backgroundClip: "content-box, border-box", marginBottom: "1rem" }}>
    <div class="col-xs-6 col-sm-4 col-lg-2" style={{ position: "relative", height: "150px", width: "100%" }}>
        <img style={{ borderRadius: "6px 4% 4% 4%", position: "absolute", top: "0", right: "0", left: "0", bottom: "0", width: "100%", height: "100%", objectFit: "cover" }} src="https://images.pexels.com/photos/11534490/pexels-photo-11534490.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load" />
    </div>
    <div class="col-xs-6 col-sm-8 col-lg-10">
        <div class="col-md-12"><h3 style={{ font: "bold 20px/1.5 Roboto, sans-serif" }}>Bridal Makeup</h3></div>
        <p style={{ font: "200 12px/1.5 Roboto, sans-serif", paddingLeft: "1rem" }}>Our facials, microdermabrasion, and chemical peel services are designed to improve the overall health and appearance of your skin. We will customize a treatment plan to meet your specific skincare needs.</p>
        <div class="pb-3" style={{ display: "flex" }}>
            <div class="col-md-6"><h3 style={{ fontSize: "14px", fontWeight: "500", color: "black" }}>Price : INR 555/-</h3></div>
            <div class="col-md-6" style={{ textAlign: "end" }}><span style={{ font: "bold 14px/1.5 Roboto, sans-serif", color: "#fe8e4e" }}>Book Now</span></div>
        </div>
    </div>
</div>
</div> */}