import React, { useState, useEffect } from 'react'
import DataTable from 'react-data-table-component';
import db from "./Firebase";
import moment from 'moment';
import Swal from 'sweetalert2'

function RegisteredUser() {
    const initialFormState = { username: '', password: '' }
    const [submit, setSubmit] = useState(initialFormState)
    const handleChange = (e) => {
        const { id, value } = e.target
        setSubmit({ ...submit, [id]: value })
    }

    const [Show, setShow] = useState(true)

    const handleSubmit = (e) => {
        // alert(JSON.stringify(submit))
        e.preventDefault();
        if (submit.username == 'admin' && submit.password == 'Security@#pass12') {
            setShow(true)
        } else {
            const Toast = Swal.mixin({
                toast: true,
                background: '#69aba6',
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            })

            Toast.fire({
                icon: 'danser',
                title: 'Invalid Username Password !!!'
            })
            //   setSubmit({username: '', password: ''})
        }

    }

    const [data, setdata] = useState([])
    const columns = [
        {
            name: 'full_name',
            selector: 'full_name',
            sortable: true,
        },
        {
            name: 'Phone No',
            selector: 'phone_no',
            sortable: true,
            // right: true,
        },
        {
            name: 'Created Date',
            selector: 'created_date',
            sortable: true,
            // right: true,
        },
    ];

    const [query, setQuery] = useState([])
    useEffect(() => {
        db.collection("personal_details")
            .onSnapshot((snapshot) => {
                console.log(snapshot.docs.length)
                snapshot.docs.map((doc, index) => {
                    // console.log()
                    //   console.log("parlour_name", doc.data().parlour_name);
                    // console.log("timeeeeeeeeeeee", doc.data().timestamp)
                })
                setQuery(
                    snapshot.docs.map((doc) => ({
                        'full_name': doc.data().full_name,
                        'phone_no': doc.data().phone_no,
                        created_date: moment.unix(doc.data().created_date).format("MM/DD, h:mm a"),
                    }))
                );

            });
    }, []);
    return (
        <>
            {Show == false ? <>
                <div class="grid aa" style={{ paddingTop: "10rem" }}>

                    <form class="form login" onSubmit={handleSubmit}>
                        <div class="form__field">
                            <label for="login__username" style={{ margin: "auto" }}><i class="fa fa-user"></i></label>
                            <input autocomplete="username" id="username" onChange={handleChange} value={submit.username} type="text" name="username" class="form__input" placeholder="Username" required />
                        </div>

                        <div class="form__field">
                            <label for="login__password" style={{ margin: "auto" }}><i class="fa fa-lock"></i></label>
                            <input id="password" type="password" name="password" onChange={handleChange} value={submit.password} class="form__input" placeholder="Password" required />
                        </div>

                        <div class="form__field">
                            <input type="submit" value="Sign In" />
                        </div>

                    </form>
                </div>
            </>
                :
                <>
                    <h1 style={{ textAlign: "center", textDecoration: "underline" }}>Registered User List</h1>
                    <div>
                        <DataTable
                            pagination
                            highlightOnHover
                            columns={columns}
                            data={query}
                            responsive={true}
                        />
                    </div>
                </>
            }


        </>

    )
}

export default RegisteredUser;