import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate, Link, useParams } from "react-router-dom"
import Footer from "./Footer";
import Select from 'react-select';
import db from "./Firebase";
import axios from "axios";

const options = [
    { value: 'Andhra Pradesh', label: 'Andhra Pradesh' },
    { value: 'Arunachal Pradesh', label: 'Arunachal Pradesh' },
    { value: 'Assam', label: 'Assam' },
    { value: 'Bihar', label: 'Bihar' },
    { value: 'Chhattisgarh', label: 'Chhattisgarh' },
    { value: 'Goa', label: 'Goa' },
    { value: 'Gujarat', label: 'Gujarat' },
    { value: 'Haryana', label: 'Haryana' },
    { value: 'Himachal Pradesh', label: 'Himachal Pradesh' },
    { value: 'Jammu and Kashmir', label: 'Jammu and Kashmir' },
    { value: 'Jharkhand', label: 'Jharkhand' },
    { value: 'Karnataka', label: 'Karnataka' },
    { value: 'Kerala', label: 'Kerala' },
    { value: 'Madhya Pradesh', label: 'Madhya Pradesh' },
    { value: 'Maharashtra', label: 'Maharashtra' },
    { value: 'Manipur', label: 'Manipur' },
    { value: 'Meghalaya', label: 'Meghalaya' },
    { value: 'Mizoram', label: 'Mizoram' },
    { value: 'Nagaland', label: 'Nagaland' },
    { value: 'Odisha', label: 'Odisha' },
    { value: 'Punjab', label: 'Punjab' },
    { value: 'Rajasthan', label: 'Rajasthan' },
    { value: 'Sikkim', label: 'Sikkim' },
    { value: 'Tamil Nadu', label: 'Tamil Nadu' },
    { value: 'Telangana', label: 'Telangana' },
    { value: 'Tripura', label: 'Tripura' },
    { value: 'Uttarakhand', label: 'Uttarakhand' },
    { value: 'Uttar Pradesh', label: 'Uttar Pradesh' },
    { value: 'West Bengal', label: 'West Bengal' },
    { value: 'Andaman and Nicobar Islands', label: 'Andaman and Nicobar Islands' },
    { value: 'Chandigarh', label: 'Chandigarh' },
    { value: 'Dadra and Nagar Haveli', label: 'Dadra and Nagar Haveli' },
    { value: 'Daman and Diu', label: 'Daman and Diu' },
    { value: 'Delhi', label: 'Delhi' },
    { value: 'Lakshadweep', label: 'Lakshadweep' },
    { value: 'Puducherry', label: 'Puducherry' },
];

function Artist() {
    let params = useParams();
    const [selectedOption, setSelectedOption] = useState(null);
    const [artistData, setArtistData] = useState([]);
    const navigate = useNavigate();
    const [userAverageRatings, setUserAverageRatings] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    const [selectedService, setSelectedService] = useState(null);
    const [selectedPricing, setSelectedPricing] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedRating, setSelectedRating] = useState(null);

    useEffect(() => {
        // Calculate average ratings for each user
        const calculateAverageRatings = () => {
            const averageRatings = {};
            db.collection('parlour_feedback').onSnapshot((snapshot) => {
                snapshot.docs.forEach((doc) => {
                    const username = doc.data().username;
                    const rating = doc.data().rating;
                    if (averageRatings[username]) {
                        // If user exists, update their average rating
                        averageRatings[username].total += rating;
                        averageRatings[username].count += 1;
                    } else {
                        // If user doesn't exist, initialize their average rating
                        averageRatings[username] = {
                            total: rating,
                            count: 1,
                        };
                    }
                });

                // Calculate and set average ratings
                for (const username in averageRatings) {
                    averageRatings[username].average =
                        averageRatings[username].total /
                        averageRatings[username].count;
                }
                setUserAverageRatings(averageRatings);
            });
        };

        calculateAverageRatings();
    }, []);


    useEffect(() => {
        db.collection('parlour_feedback').onSnapshot((snapshot) => {
            const reviewCounts = {};
            snapshot.docs.forEach((doc) => {
                const username = doc.data().username;
                reviewCounts[username] = reviewCounts[username] ? reviewCounts[username] + 1 : 1;
            });
            // setUserReviewCount(reviewCounts); // Commented out as it's not used in this code snippet
        });
    }, []);

    useEffect(() => {
        db.collection("portfolio_details").where('state', '==', params.id).onSnapshot((snapshot) => {
            const artistList = snapshot.docs.map((doc) => ({
                id: doc.id,
                data: doc.data(),
            }));

            artistList = artistList.filter(({ data }) => {
                // Access the 'services' array
                const services = data.services || []; // Ensure 'services' is an array

                // Map the services to an array of service names
                const serviceNames = services.map(service => service.type);

                // Format the services as a comma-separated string
                const formattedServices = serviceNames.join(", ");

                console.log(formattedServices)

            });
        });




    }, []);


    useEffect(() => {
        db.collection("portfolio_details").where('state', '==', params.id).onSnapshot((snapshot) => {
            const artistList = snapshot.docs.map((doc) => ({
                id: doc.id,
                data: doc.data(),
            }));

            // artistList = artistList.filter(({ data }) => {
            //     //     // Access the 'services' array
            //     //     const services = data.services || []; // Ensure 'services' is an array

            //     //     // Map the services to an array of service names
            //     //     const serviceNames = services.map(service => service.type);

            //     //     // Format the services as a comma-separated string
            //     //     const formattedServices = serviceNames.join(", ");

            // });

            // Sort artistData based on average rating in descending order
            const sortedArtistList = artistList.slice().sort((a, b) => {
                const artistARating = userAverageRatings[a.data.username]?.average || 0;
                const artistBRating = userAverageRatings[b.data.username]?.average || 0;
                return artistBRating - artistARating;
            });

            setArtistData(sortedArtistList);
            setIsLoading(false);
        });
    }, [params.id, userAverageRatings]);

    const handleChange = e => {
        setSelectedOption(e.value);
        console.log(e.value)
        db.collection("portfolio_details").where('state', '==', e.value).onSnapshot((snapshot) => {
            setArtistData(
                snapshot.docs.map((doc) => ({
                    id: doc.id,
                    data: doc.data(),
                }))
            );
            if (snapshot.docs.length == 0) {
                // navigate('/error');
            } else {
                // alert("exists")
                setIsLoading(false);
                navigate(`/artist/${e.value}`);
            }
        }
        )
    }

    const filterArtists = () => {
        let filteredArtists = artistData;

        if (selectedService) {
            // Filter by selected service
            filteredArtists = filteredArtists.filter(({ data }) => {
                // Access the 'services' array
                const services = data.services || []; // Ensure 'services' is an array
                const hasSelectedService = services.some(service => service.type === selectedService);
                return hasSelectedService;
            });
        }


        if (selectedPricing) {
            // Filter by selected pricing range
            filteredArtists = filteredArtists.filter(({ data }) => {
                const services = data.services || [];
                return services.some(service => {
                    const amount = parseFloat(service.amount);
                    if (selectedPricing === "500-1000") {
                        return amount >= 500 && amount <= 1000;
                    } else if (selectedPricing === "1000-2000") {
                        return amount >= 1000 && amount <= 2000;
                    } else if (selectedPricing === "+2000") {
                        return amount >= 2000;
                    }
                    return false; // Default case: no match
                });
            });
        }




        if (selectedCategory) {
            // Filter by selected category
            filteredArtists = filteredArtists.filter(({ data }) => {
                // Access the 'category' field (assuming it's a string)
                const category = data.title_designation || ""; // Ensure 'category' is a string

                // Check if the category matches the selected category
                return category === selectedCategory;
            });
        }


        if (selectedRating) {
            // Filter by selected rating
            filteredArtists = filteredArtists.filter(({ data }) => {
                // Access the 'username' field from your artist's data
                const username = data.username || "";

                // Access the user's average rating based on the 'username' from your 'userAverageRatings' state
                const userAverageRating = userAverageRatings[username] || { average: 0 }; // Default to 0 if no rating found

                // Convert the selectedRating to an integer
                const selectedRatingValue = parseInt(selectedRating);

                // Calculate the lower and upper bounds for the selected rating range
                const lowerBound = selectedRatingValue;
                const upperBound = selectedRatingValue + 0.9; // For example, if selectedRatingValue is 4, the range is 4.0 to 4.9

                // Check if the user's average rating is within the selected rating range
                return userAverageRating.average >= lowerBound && userAverageRating.average <= upperBound;
            });
        }









        return filteredArtists;
    };

    const handleChangeService = (e) => {
        if (e.target.textContent === "All") {
            setSelectedService('');
        } else {
            setSelectedService(e.target.textContent);
        }

    };

    const handleChangePricing = (e) => {
        if (e.target.textContent === "All") {
            setSelectedPricing('');
        } else {
            setSelectedPricing(e.target.textContent);
        }
    };

    const handleChangeCategory = (e) => {
        if (e.target.textContent === "All") {
            setSelectedCategory('');
        } else {
            setSelectedCategory(e.target.textContent);
        }

    };

    const handleChangeRating = (e) => {
        if (e.target.textContent === "All") {
            setSelectedRating('');
        } else {
            setSelectedRating(e.target.textContent);
        }

    };

    return (
        <>
            <section style={{ boxShadow: "0 6px 6px -6px #777" }}>
                <div class="navi" >
                    <input type="checkbox" id="nav-check" />
                    <div class="nav-header">
                        <div class="nav-title">
                            <Link to="/"> <img src="/images/youvati_logo.png" alt="" style={{ height: "27px", marginLeft: "2rem" }} /></Link>
                        </div>
                    </div>
                </div>
            </section>
            <div class="container">
                <div class="row pt-4">
                    <div class="col pl-4">
                        <p style={{
                            fontSize: "18px",
                            fontWeight: "600",
                            color: "black",
                            paddingBottom: "2px",
                            borderBottomStyle: "solid",
                            borderBottomWidth: "2.1px",
                            width: "fit-content",
                            textTransform: "capitalize",
                        }}>Artists In :  {params.id}</p>
                    </div>
                </div>

                <div class="pl-3 pb-2" style={{ "display": "flex" }}>

                    <div class="card-footer p-1" style={{ borderStyle: "none" }}>
                        <div class="row">
                            <div class="pr-4" style={{ display: "flex", justifyContent: "end" }}>
                                <span class="badge badge-warning dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ cursor: "pointer", backgroundColor: "#ec407a", color: "white" }}>{selectedService === null || selectedService === '' ? "Services" : selectedService}</span>
                                <div class="dropdown-menu">
                                    <a class="dropdown-item" onClick={handleChangeService} href="#">Skin</a>
                                    <a class="dropdown-item" onClick={handleChangeService} href="#">Hair</a>
                                    <a class="dropdown-item" onClick={handleChangeService} href="#">Make Up</a>
                                    <a class="dropdown-item" onClick={handleChangeService} href="#">Others</a>
                                    <a class="dropdown-item" onClick={handleChangeService} href="#">All</a>
                                    {/* <div class="dropdown-divider"></div>
            <a class="dropdown-item" href="#">Separated link</a> */}
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="card-footer p-1" style={{ borderStyle: "none" }}>
                        <div class="row">
                            <div class="pr-4" style={{ display: "flex", justifyContent: "end" }}>
                                <span class="badge badge-warning dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ cursor: "pointer", backgroundColor: "#ec407a", color: "white" }}>{selectedPricing === null || selectedPricing === '' ? "Pricing" : selectedPricing}</span>
                                <div class="dropdown-menu">
                                    <a class="dropdown-item" onClick={handleChangePricing} href="#">500-1000</a>
                                    <a class="dropdown-item" onClick={handleChangePricing} href="#">1000-2000</a>
                                    <a class="dropdown-item" onClick={handleChangePricing} href="#">+2000</a>
                                    <a class="dropdown-item" onClick={handleChangePricing} href="#">All</a>
                                    {/* <div class="dropdown-divider"></div>
            <a class="dropdown-item" href="#">Separated link</a> */}
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="card-footer p-1" style={{ borderStyle: "none" }}>
                        <div class="row">
                            <div class="pr-4" style={{ display: "flex", justifyContent: "end" }}>
                                <span class="badge badge-warning dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ cursor: "pointer", backgroundColor: "#ec407a", color: "white" }}>{selectedCategory === null || selectedCategory === '' ? "Category" : selectedCategory}</span>
                                <div class="dropdown-menu">
                                    <a class="dropdown-item" onClick={handleChangeCategory} href="#">Beauty Professional</a>
                                    <a class="dropdown-item" onClick={handleChangeCategory} href="#">Freelancer</a>
                                    <a class="dropdown-item" onClick={handleChangeCategory} href="#">All</a>
                                    {/* <div class="dropdown-divider"></div>
            <a class="dropdown-item" href="#">Separated link</a> */}
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="card-footer p-1" stylfe={{ borderStyle: "none" }}>
                        <div class="row">
                            <div class="pr-4" style={{ display: "flex", justifyContent: "end" }}>
                                <span class="badge badge-warning dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ cursor: "pointer", backgroundColor: "#ec407a", color: "white" }}>{selectedRating === null || selectedRating === '' ? "Rating" : selectedRating}</span>
                                <div class="dropdown-menu">
                                    <a class="dropdown-item" onClick={handleChangeRating} href="#">1</a>
                                    <a class="dropdown-item" onClick={handleChangeRating} href="#">2</a>
                                    <a class="dropdown-item" onClick={handleChangeRating} href="#">3</a>
                                    <a class="dropdown-item" onClick={handleChangeRating} href="#">4</a>
                                    <a class="dropdown-item" onClick={handleChangeRating} href="#">5</a>
                                    <a class="dropdown-item" onClick={handleChangeRating} href="#">All</a>
                                    {/* <div class="dropdown-divider"></div>
            <a class="dropdown-item" href="#">Separated link</a> */}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <div class="col-md-6">
                    <div style={{ paddingRight: "6rem" }}>
                        <Select
                            defaultValue={selectedOption}
                            onChange={handleChange}
                            options={options}
                            placeholder="Search via city"
                        />
                    </div>
                </div>

            </div>
            <section class="">

                <div class="container">
                    <div class="">
                        <div class="row">
                            {isLoading ? <div>
                                <img src="/images/loader.gif" alt="Popup Image" class="" style={{ display: "block", marginLeft: "auto", marginRight: "auto", width: "40%" }} />
                            </div> :

                                <div class="grid-container grido" style={{}}>
                                    {filterArtists()?.map(({ id, data }, index) => {

                                        // Access the 'services' array
                                        const services = data.services || []; // Ensure 'services' is an array

                                        // Create a Set to store unique service names
                                        const uniqueServices = new Set();

                                        // Iterate through the services and add them to the Set
                                        services.forEach(service => {
                                            uniqueServices.add(service.type);
                                        });

                                        // Convert the Set to an array and format as a comma-separated string
                                        const formattedServices = [...uniqueServices].join(", ");

                                        return <>

                                            <div
                                                className="badg"
                                                data-label={`${
                                                    // Access the average rating based on the artist's username
                                                    userAverageRatings[data.username] ? userAverageRatings[data.username].average.toFixed(1) : "0"} ⭐ Rating`}
                                                style={{
                                                    height: "auto",
                                                    borderStyle: "solid", borderWidth: "3px",
                                                    borderImage: "linear-gradient(45deg, rgb(245 80 93), rgb(245 80 93)) 1"
                                                }} >
                                                <Link to={`/${data.username}`}><img src={data.portfolio_image} class="img-fluid" style={{ width: "100%", height: "308px", objectFit: "cover" }} /></Link>
                                                <Link to={`/${data.username}`}> <div class="p-3" style={{ color: "white", backgroundColor: "#e92b91", textAlign: "center", fontSize: "15px", fontWeight: "600", textTransform: "capitalize" }}><span>{data.parlour_name}</span></div></Link>
                                                <div class="p-2" style={{ textAlign: "start" }}>
                                                    {/* <div><span class="card-title" style={{ fontSize: "14px" }}>From : {data.parlour_name} <img src="/images/verified-badge.png" style={{ width: "16px" }}></img></span></div> */}
                                                    <div><span class="card-title" style={{ fontSize: "14px", textTransform: "capitalize" }}>Services: {formattedServices}</span></div>
                                                    {/* <div><span class="card-title" style={{ fontSize: "14px", textTransform: "capitalize" }}>Services : Skin, Hair, Nail, Makeup, Others</span></div> */}
                                                </div>
                                                <div class="grid-container-element">
                                                    <div class="grid-child-element purple"><span style={{ color: "#007bff", fontSize: "14px", }}>Location : {data.city}</span></div>
                                                    <div class="grid-child-element green"><Link to={`/${data.username}`}><span style={{ color: "#007bff", fontSize: "14px", }}>Explore</span></Link></div>
                                                </div>
                                            </div>

                                        </>
                                    }
                                    )}


                                </div>
                            }
                            <div class="row mb-10">
                                {filterArtists()?.length == 0 ? <h1 style={{
                                    color: "grey",
                                    fontSize: "24px",
                                    textAlign: "center"
                                }}>No Artist found !!!</h1> : <></>}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />

        </>
    );
}

export default Artist;