import React, { useState, useEffect } from 'react';
import { useNavigate, Link, useParams } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2'
import db from "./Firebase";
import { storage } from './Firebase';
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import Moment from 'moment';
import DatePicker from "react-datepicker";
import { extendMoment } from 'moment-range';
import "react-datepicker/dist/react-datepicker.css";

function UploadAwardShow() {
    let params = useParams();
    const navigate = useNavigate();
    const [startDate, setStartDate] = useState(new Date());
    const moment = extendMoment(Moment);
    const initialFormStateFeedback = {
        organizer_name: '',
        phone_no: '',
        mode: '',
        venue: '',
        topic: '',
        authorization_code: '',
        pincode: '',
        city: '',
        state: '',
        country: '',
        sponsor_1: '',
        sponsorship_1_amount: '',
        sponsor_2: '',
        sponsorship_2_amount: '',
        sponsor_3: '',
        sponsorship_3_amount: '',
        entry_fee: '',
        price_1: '',
        price_2: '',
        price_3: '',
        judges_name1: '',
        judges_name1_phone_no: '',
        judges_name2: '',
        judges_name2_phone_no: '',
        judges_name3: '',
        judges_name3_phone_no: '',
        event_date: '',
        created_date: new Date()
    }
    const [submit, setSubmit] = useState(initialFormStateFeedback)

    const [show, setshow] = useState(true);

    const [imgUrl, setImgUrl] = useState(null);
    const [progresspercent, setProgresspercent] = useState(0);


    const handleChange = (e) => {
        const { id, value } = e.target
        setSubmit({ ...submit, [id]: value })
        console.log(e.target.value)
    }

    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [country, setCountry] = useState('');
    const handleChangePincode = (e) => {

        const { id, value } = e.target
        setSubmit({ ...submit, [id]: value })
        if (e.target.value.length == 6) {
            axios.get(`https://api.postalpincode.in/pincode/${e.target.value}`)
                .then(res => {
                    const alldata = res.data;
                    console.log(alldata[0]['PostOffice'][0]);
                    setCity(alldata[0]['PostOffice'][0]['District'])
                    setState(alldata[0]['PostOffice'][0]['State'])
                    setCountry(alldata[0]['PostOffice'][0]['Country'])
                })
        }
    }

    const reset = () => {
        setSubmit({
            organizer_name: '',
            phone_no: '',
            mode: '',
            venue: '',
            topic: '',
            authorization_code: '',
            pincode: '',
            sponsor_1: '',
            sponsorship_1_amount: '',
            sponsor_2: '',
            sponsorship_2_amount: '',
            sponsor_3: '',
            sponsorship_3_amount: '',
            entry_fee: '',
            price_1: '',
            price_2: '',
            price_3: '',
            judges_name1: '',
            judges_name1_phone_no: '',
            judges_name2: '',
            judges_name2_phone_no: '',
            judges_name3: '',
            judges_name3_phone_no: '',
            event_date: '',
            created_date: new Date()
        })
        setCity('');
        setState('');
        setCountry('');
    }



    const ids = ["rahul_122002", "ashutosh_560001", "mrinal_122004"];

    const handleSubmit = (e) => {
        // console.log(submit.usercode)
        // alert("okkk")
        e.preventDefault()
        if (ids.includes(submit.authorization_code)) {
            db.collection("award_show_data").add({
                'organizer_name': submit.organizer_name,
                'phone_no': submit.phone_no,
                'mode': submit.mode,
                'venue': submit.venue,
                'topic': submit.topic,
                'authorization_code': submit.authorization_code,
                'pincode': submit.pincode,
                'city': city,
                'state': state,
                'country': country,
                'sponsor_1': submit.sponsor_1,
                'sponsorship_1_amount': submit.sponsorship_1_amount,
                'sponsor_2': submit.sponsor_2,
                'sponsorship_2_amount': submit.sponsorship_2_amount,
                'sponsor_3': submit.sponsor_3,
                'sponsorship_3_amount': submit.sponsorship_3_amount,
                'entry_fee': submit.entry_fee,
                'price_1': submit.price_1,
                'price_2': submit.price_2,
                'price_3': submit.price_1,
                'judges_name1': submit.judges_name1,
                'judges_name1_phone_no': submit.judges_name1_phone_no,
                'judges_name2': submit.judges_name2,
                'judges_name2_phone_no': submit.judges_name2_phone_no,
                'judges_name3': submit.judges_name3,
                'judges_name3_phone_no': submit.judges_name3_phone_no,
                'event_date': moment(startDate).format("YYYY-MM-DD"),
                'created_date': new Date()
            }).then(() => {
                console.log('Submitted Successfully');
                const Toast = Swal.mixin({
                    toast: true,
                    background: '#69aba6',
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer)
                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                    }
                })

                Toast.fire({
                    icon: 'success',
                    title: 'Submitted Successfully'
                })
                setshow(true)
                // navigate(`/${submit.username}`);
                setSubmit({
                    organizer_name: '',
                    phone_no: '',
                    mode: '',
                    venue: '',
                    topic: '',
                    authorization_code: '',
                    sponsor_1: '',
                    sponsorship_1_amount: '',
                    sponsor_2: '',
                    sponsorship_2_amount: '',
                    sponsor_3: '',
                    sponsorship_3_amount: '',
                    entry_fee: '',
                    price_1: '',
                    price_2: '',
                    price_3: '',
                    judges_name1: '',
                    judges_name1_phone_no: '',
                    judges_name2: '',
                    judges_name2_phone_no: '',
                    judges_name3: '',
                    judges_name3_phone_no: '',
                    event_date: '',
                    created_date: new Date(),
                })
                setCity('');
                setState('');
                setCountry('');
            })
        } else {
            alert("You are not authorised to perform this action")
        }
    }

    useEffect(() => {
        // alert(params.id)
    }, []);



    return (
        <>
            <div id="small-dialog" class="m-3">
                <div class="w3layouts_payment_online_form">
                    <form class="" id="" onSubmit={handleSubmit}>
                        <h4>Add Award Show Data</h4>
                        <p style={{ textAlign: "left", color: "red" }}>* Denotes Required Fields</p>
                        <ul>
                            <li>
                                <input class="w3l_text_box" id="organizer_name" onChange={handleChange} value={submit.organizer_name} placeholder="*Organizer Name" name="Organizer Name" type="text" required />
                            </li> <li>
                                <input class="w3l_text_box" id="phone_no" onChange={handleChange} value={submit.phone_no} placeholder="*Organizer 10 Digit Phone Number" name="Phone" type="tel" minlength="10" maxlength="10" required />
                            </li>
                        </ul>

                        <ul>
                            <li>
                                {/* <label>Type</label> */}
                                <select class="form-control" id="mode" onChange={handleChange} required>
                                    <option value="">--*Select Mode--</option>
                                    <option value="yes">Online</option>
                                    <option value="no">Offline</option>
                                </select>
                            </li>
                            <li>
                                <input class="w3l_text_box" id="venue" placeholder="*Venue" onChange={handleChange} value={submit.venue} name="Venue" type="text" required="" />
                            </li>
                        </ul>
                        <ul>
                            <li>
                                <input class="w3l_text_box" id="topic" placeholder="*Topic" onChange={handleChange} value={submit.topic} name="Topic" type="text" required />
                            </li>
                            <li>
                                <input class="w3l_text_box" id="authorization_code" placeholder="*Authorization Code" onChange={handleChange} value={submit.authorization_code} name="Authorization Code" type="text" required />
                            </li>
                        </ul>
                        <ul>
                            <li><input class="w3l_text_box" id="pincode" placeholder="*Pincode" onChange={handleChangePincode} value={submit.pincode} name="Pincode" type="number" required="" /></li>
                            <li><input class="w3l_text_box" id="city" placeholder="*City" onChange={handleChange} value={city} name="City" type="text" required="" /></li>

                        </ul>
                        <ul>
                            <li><input class="w3l_text_box" id="state" placeholder="*State" onChange={handleChange} value={state} name="State" type="text" required="" /></li>
                            <li><input class="w3l_text_box" id="country" placeholder="*Country" onChange={handleChange} value={country} name="Country" type="text" required="" /></li>

                        </ul>
                        <ul>
                            <li><input class="w3l_text_box" id="sponsor_1" placeholder="Sponsor 1" onChange={handleChange} value={submit.sponsor_1} name="Sponsor 1" type="text" required="" /></li>
                            <li><input class="w3l_text_box" id="sponsorship_1_amount" placeholder="Sponsorship 1 Amount" onChange={handleChange} value={submit.sponsorship_1_amount} name="Sponsorship 1 Amount" type="text" required="" /></li>

                        </ul>
                        <ul>
                            <li><input class="w3l_text_box" id="sponsor_2" placeholder="Sponsor 2" onChange={handleChange} value={submit.sponsor_2} name="Sponsor 2" type="text" required="" /></li>
                            <li><input class="w3l_text_box" id="sponsorship_2_amount" placeholder="Sponsorship 2 Amount" onChange={handleChange} value={submit.sponsorship_2_amount} name="Sponsorship 2 Amount" type="text" required="" /></li>

                        </ul>
                        <ul>
                            <li><input class="w3l_text_box" id="sponsor_3" placeholder="Sponsor 3" onChange={handleChange} value={submit.sponsor_3} name="Sponsor 3" type="text" required="" /></li>
                            <li><input class="w3l_text_box" id="sponsorship_3_amount" placeholder="Sponsorship 3 Amount" onChange={handleChange} value={submit.sponsorship_3_amount} name="Sponsorship 3 Amount" type="text" required="" /></li>

                        </ul>
                        <ul>
                            <li><input class="w3l_text_box" id="entry_fee" placeholder="*Entry Fees" onChange={handleChange} value={submit.entry_fee} name="Entry Fee" type="text" required="" /></li>
                            <li><input class="w3l_text_box" id="price_1" placeholder="*1st Price" onChange={handleChange} value={submit.price_1} name="1st Price" type="text" required="" /></li>
                        </ul>
                        <ul>
                            <li><input class="w3l_text_box" id="price_2" placeholder="2nd Price" onChange={handleChange} value={submit.price_2} name="2nd Price" type="text" required="" /></li>
                            <li><input class="w3l_text_box" id="price_3" placeholder="3rd Price" onChange={handleChange} value={submit.price_3} name="3rd Price" type="text" required="" /></li>
                        </ul>

                        <ul>
                            <li><input class="w3l_text_box" id="judges_name1" placeholder="*Judges Name 1" onChange={handleChange} value={submit.judges_name1} name="Judges Name 1" type="text" required="" /></li>
                            <li> <input class="w3l_text_box" id="judges_name1_phone_no" onChange={handleChange} value={submit.judges_name1_phone_no} placeholder="Judges Name 1 Phone Number" name="Judges Name 1 Phone Number" type="tel" minlength="10" maxlength="10" required /></li>
                        </ul>
                        <ul>
                            <li><input class="w3l_text_box" id="judges_name2" placeholder="Judges Name 2" onChange={handleChange} value={submit.judges_name2} name="Judges Name 2" type="text" required="" /></li>
                            <li><input class="w3l_text_box" id="judges_name2_phone_no" onChange={handleChange} value={submit.judges_name2_phone_no} placeholder="Judges Name 2 Phone Number" name="Judges Name 2 Phone Number" type="tel" minlength="10" maxlength="10" required /></li>
                        </ul>
                        <ul>

                            <li><input class="w3l_text_box" id="judges_name3" placeholder="Judges Name 3" onChange={handleChange} value={submit.judges_name3} name="Judges Name 3" type="text" required="" /></li>
                            <li><input class="w3l_text_box" id="judges_name3_phone_no" onChange={handleChange} value={submit.judges_name3_phone_no} placeholder="Judges Name 3 Phone Number" name="Judges Name 3 Phone Number" type="tel" minlength="10" maxlength="10" required /></li>

                        </ul>
                        <ul>
                            <li>
                                <label>Event date</label>
                                <DatePicker
                                    selected={startDate}
                                    onChange={date => setStartDate(date)}
                                    minDate={new Date()}
                                    showDisabledMonthNavigation
                                    required
                                    className="form-control w-120"
                                    placeholderText="Select a date before 5 days in the future"
                                />
                            </li>
                            <li>
                                {/* <input class="w3l_text_box" id="event_date" placeholder="Event Date" onChange={handleChange} value={submit.event_date} name="Event Date" type="text" required="" /> */}
                            </li>

                        </ul>

                        <ul class="w3_payment_sendbtns">
                            <li><input type="reset" value="Cancel" onClick={() => navigate(`/quicklinks`)} /></li>
                            <li class="mr-4"><input type="reset" data-dismiss="modal" aria-label="Close" onClick={reset} value="Reset" /></li>
                            <li>{show ? <input type="submit" value="Submit" /> : "Please Wait..."}</li>
                        </ul>
                        <div class="clearfix"> </div>
                    </form>
                </div>
            </div>
        </>



    );
}
export default UploadAwardShow;