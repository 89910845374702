import React, { useState, useEffect } from "react";
import Swal from 'sweetalert2'
import db from "./Firebase";
import { storage } from './Firebase';
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import Moment from 'moment';
import { extendMoment } from 'moment-range';

function UploadRecordedVideo() {

    const moment = extendMoment(Moment);
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [show, setshow] = useState(true);
    const [addTrends, setAddTrends] = useState(false);
    const [progresspercent, setProgresspercent] = useState(0);

    const [editID, setEditID] = useState("");
    const [languageID, setLanguageID] = useState("english");

    const [searchQuery, setSearchQuery] = useState("");
    const [availabilityFilter, setAvailabilityFilter] = useState("all");


    useEffect(() => {
        setLanguageID("english")
        db.collection("workshop_event_data").orderBy("created_date", "desc").onSnapshot((snapshot) => {

            setData(
                snapshot.docs.map((doc) => ({
                    id: doc.id,
                    data: doc.data(),
                }))
            );
        });

    }, []);

    // Step 3: Filter data based on search query and availability filter
    const filteredData = data.filter((record) => {
        return (
            record.data.name.toLowerCase().includes(searchQuery.toLowerCase()) &&
            (availabilityFilter === "all" || (availabilityFilter === "available" && record.data.recorded_session) || (availabilityFilter === "notAvailable" && !record.data.recorded_session))
        );
    });


    // // Step 3: Filter data based on search query
    // const filteredData = data.filter((record) => {
    //     return record.data.name.toLowerCase().includes(searchQuery.toLowerCase());
    // });

    const handleSearch = (e) => {
        setSearchQuery(e.target.value); // Step 3: Update search query state
    }

    const handleAvailabilityFilter = (e) => {
        setAvailabilityFilter(e.target.value); // Step 2: Update availability filter state
    }

    const change = () => {
        setLanguageID("hindi")
        db.collection("workshop_event_data_hindi").orderBy("created_date", "desc").onSnapshot((snapshot) => {

            setData(
                snapshot.docs.map((doc) => ({
                    id: doc.id,
                    data: doc.data(),
                }))
            );
        });
    }

    const changeE = () => {
        setLanguageID("english")
        db.collection("workshop_event_data").orderBy("created_date", "desc").onSnapshot((snapshot) => {

            setData(
                snapshot.docs.map((doc) => ({
                    id: doc.id,
                    data: doc.data(),
                }))
            );
        });
    }


    const changeStatus = async (uid) => {

        const { value: text } = await Swal.fire({
            input: 'text',
            inputLabel: 'Workshop Recorded Video ID',
            inputPlaceholder: 'Enter Video id here...'
        })

        if (text) {
            // Swal.fire(`Entered text: ${text}`)
            db.collection("workshop_event_data").doc(uid).update({ 'recorded_session': text, }).then(() => {
                console.log("video added");
            })
        }

    }

    const changeStatusH = async (uid) => {

        const { value: text } = await Swal.fire({
            input: 'text',
            inputLabel: 'Workshop Recorded Video ID',
            inputPlaceholder: 'Enter Video id here...'
        })

        if (text) {
            // Swal.fire(`Entered text: ${text}`)
            db.collection("workshop_event_data_hindi").doc(uid).update({ 'recorded_session': text, }).then(() => {
                console.log("video added");
            })
        }

    }

    const changeStatusTS = async (uid, data) => {

        const { value: text } = await Swal.fire({
            input: 'textarea',
            inputLabel: 'Recorded Video TimeStamp',
            inputPlaceholder: 'Enter TimeStamp here...',
            inputValue: data,
        })

        if (text) {
            // Swal.fire(`Entered text: ${text}`)
            db.collection("workshop_event_data").doc(uid).update({ 'uid': text, }).then(() => {
                console.log("timestamp added");
            })
        }

    }

    const changeStatusTSH = async (uid, data) => {

        const { value: text } = await Swal.fire({
            input: 'textarea',
            inputLabel: 'Recorded Video TimeStamp',
            inputPlaceholder: 'Enter TimeStamp here...',
            inputValue: data,

        })

        if (text) {
            // Swal.fire(`Entered text: ${text}`)
            db.collection("workshop_event_data_hindi").doc(uid).update({ 'uid': text, }).then(() => {
                console.log("timestamp added");
            })
        }

    }

    const view = async (data) => {
        const { value: text } = await Swal.fire({
            input: 'textarea',
            inputLabel: 'Recorded Video TimeStamp',
            inputPlaceholder: 'Enter TimeStamp here...',
            inputValue: data,
            inputAttributes: {
                readOnly: true, // Make the textarea read-only
            },
            showCancelButton: false,
            showCloseButton: false,
            showConfirmButton: false,
        })
    }




    return (
        <>
            <div class="p-3">
                {languageID === "english" ?
                    <>
                        <div class="" style={{ float: "right" }}><button type="button" class="btn btn-sm" style={{ backgroundColor: "cadetblue", color: "white", marginRight: "1rem", marginBottom: "10px" }} onClick={() => change()}>Go To Workshop Hindi</button></div>
                    </>
                    :
                    <>
                        <div class="" style={{ float: "right" }}><button type="button" class="btn btn-sm" style={{ backgroundColor: "cadetblue", color: "white", marginRight: "1rem", marginBottom: "10px" }} onClick={() => changeE()}>Go To Workshop English</button></div>
                    </>}

                <input
                    type="text"
                    className="form-control form-control-sm mb-2"
                    placeholder="Search by Workshop Name"
                    value={searchQuery}
                    onChange={handleSearch}
                />

                <div>
                    <label class="mr-3">Recording: </label>
                    <select value={availabilityFilter} onChange={handleAvailabilityFilter} className="form-control-sm mb-2">
                        <option value="all">All</option>
                        <option value="available">Available</option>
                        <option value="notAvailable">Not Available</option>
                    </select>
                </div>

                <div class="table-responsive fix-width scroll-inner">
                    <table class="table table-bordered table-hover">
                        <thead class="thead-dark" style={{ position: "sticky", top: "0" }}>
                            <tr>
                                <th>Sr. No</th>
                                <th>Workshop Name</th>
                                <th>created_date</th>
                                <th>Video ID</th>
                                <th>TimeStamp</th>
                                <th>Action</th>
                                <th>Action 2</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredData?.map(({ id, data }, index) => (
                                <>
                                    <tr>
                                        <td>{index + 1}</td>
                                        <td>{data.name}</td>
                                        <td>{moment(data.created_date.toDate().toISOString()).format("MM/DD/YY, h:mm a")}</td>
                                        <td>{data.recorded_session}</td>
                                        <td><span style={{ cursor: "pointer", fontSize: "17px" }} onClick={() => view(data.uid)}>🛈</span></td>
                                        <td>
                                            {languageID === "english" ? <><span style={{ color: "orange", fontSize: "13px", fontWeight: "700", cursor: "pointer" }} onClick={() => changeStatus(id)}>Update Video ID</span></> : <><span style={{ color: "orange", fontSize: "13px", fontWeight: "700", cursor: "pointer" }} onClick={() => changeStatusH(id)}>Update Video ID</span></>}

                                        </td>
                                        <td>
                                            {languageID === "english" ? <><span style={{ color: "orange", fontSize: "13px", fontWeight: "700", cursor: "pointer" }} onClick={() => changeStatusTS(id, data.uid)}>Update TimeStamp</span></> : <><span style={{ color: "orange", fontSize: "13px", fontWeight: "700", cursor: "pointer" }} onClick={() => changeStatusTSH(id, data.uid)}>Update TimeStamp</span></>}

                                        </td>
                                    </tr>
                                </>
                            ))}


                        </tbody>
                    </table>
                </div>
            </div>



        </>
    )
}

export default UploadRecordedVideo
