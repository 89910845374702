import { Configuration, OpenAIApi } from "openai";
import OptionSelection from "./OptionSelection";
import Translation from "./Translation";
import { arrayItems } from "./AIOptions";
import { useState } from "react";
import { generateText } from './openai';

function MyComponent() {
    //   const configuration = new Configuration({
    //     apiKey: import.meta.env.VITE_Open_AI_Key,
    //   });
    const openai = new OpenAIApi("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");
    const [option, setOption] = useState({});
    const [result, setResult] = useState("");
    const [input, setInput] = useState("");
    // console.log(import.meta.env.VITE_Open_AI_Key);
    const selectOption = (option) => {
        setOption(option);
    };

    const doStuff = async () => {
        let object = { ...option, prompt: input };

        const response = await generateText(prompt);

        setResult(response.data.choices[0].text);
    };

    return (
        <div className="App">
            {Object.values(option).length === 0 ? (
                <OptionSelection arrayItems={arrayItems} selectOption={selectOption} />
            ) : (
                <Translation doStuff={doStuff} setInput={setInput} result={result} />
            )}
        </div>
    );
}

export default MyComponent;

// import { generateText } from './openai';

// function MyComponent() {
//     const handleClick = async () => {
//         const prompt = 'Once upon a time...';
//         try {
//             const generatedText = await generateText(prompt);
//             console.log(generatedText);
//         } catch (error) {
//             // Handle error
//         }
//     };

//     return (
//         <div>
//             <button onClick={handleClick}>Generate Text</button>
//         </div>
//     );
// }

// export default MyComponent;