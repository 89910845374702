import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { getStorage } from "firebase/storage";
import { getAuth, GoogleAuthProvider } from "firebase/auth";

const firebaseConfig = {
    // apiKey: "AIzaSyDswI6balumR9_NP8xqKNcYi_AvGglylmw",
    apiKey: "AIzaSyCG0ezOxdzfRXoDlgsMtl9VZW5aTPZsKqY",
    authDomain: "youvati.firebaseapp.com",
    projectId: "youvati",
    storageBucket: "youvati.appspot.com",
    messagingSenderId: "657189143152",
    appId: "1:683881504876:android:e53bcef0a3fa5b117bb849",
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
const db = firebase.firestore();
var auth = getAuth(firebaseApp);
const provider = new GoogleAuthProvider();
export const storage = getStorage(firebaseApp);
export { provider, auth, firebase };

export default db;




// import firebase from 'firebase/compat/app';
// import 'firebase/compat/auth';
// import 'firebase/compat/firestore';

// const firebaseConfig = {
//     apiKey: "AIzaSyDswI6balumR9_NP8xqKNcYi_AvGglylmw",
//     authDomain: "youvati.firebaseapp.com",
//     projectId: "youvati",
//     storageBucket: "youvati.appspot.com",
//     messagingSenderId: "657189143152",
//     appId: "1:683881504876:android:e53bcef0a3fa5b117bb849",
// };
// firebase.initializeApp(firebaseConfig);
// var auth = firebase.auth();
// export { auth, firebase };