import React, { useState, useEffect } from 'react';
import { useNavigate, Link, useParams } from 'react-router-dom';

function ViewAllRequests() {

    return (
        <>
            <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
                <div class="container-fluid py-4">
                    <div class="row">
                        <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4 pb-4">
                            <Link to="/beauty_kit_requests"> <div class="card" style={{ cursor: "pointer" }}>
                                <div class="card-header p-3 pt-2" style={{ borderStyle: "none" }}>
                                    <div
                                        class="icon-shape bg-gradient-dark shadow-dark text-center border-radius-xl mt-n4 position-absolute">
                                        <i class="material-icons opacity-10">weekend</i>
                                    </div>
                                    <div class="text-end pt-1">
                                        <p class="text-sm mb-0 text-capitalize" style={{ fontWeight: "bold" }}>Beauty Kit Requests</p>
                                    </div>
                                </div>
                            </div>
                            </Link>
                        </div>
                        <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4 pb-4" style={{ cursor: "pointer" }}>
                            <Link to="/book_demo_requests">  <div class="card">
                                <div class="card-header p-3 pt-2" style={{ borderStyle: "none" }}>
                                    <div
                                        class="icon-shape bg-gradient-primary shadow-primary text-center border-radius-xl mt-n4 position-absolute">
                                        <i class="material-icons opacity-10">person</i>
                                    </div>
                                    <div class="text-end pt-1">
                                        <p class="text-sm mb-0 text-capitalize" style={{ fontWeight: "bold" }}>Book Demo Requests</p>
                                    </div>
                                </div>

                            </div></Link>
                        </div>
                        <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4 pb-4">
                            <Link to="/business_card_request"> <div class="card" style={{ cursor: "pointer" }}>
                                <div class="card-header p-3 pt-2" style={{ borderStyle: "none" }}>
                                    <div
                                        class="icon-shape bg-gradient-success shadow-success text-center border-radius-xl mt-n4 position-absolute">
                                        <i class="material-icons opacity-10">handshake</i>
                                    </div>
                                    <div class="text-end pt-1">
                                        <p class="text-sm mb-0 text-capitalize" style={{ fontWeight: "bold" }}>Business card Requests</p>
                                    </div>
                                </div>
                            </div>
                            </Link>
                        </div>

                        {/* <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4 pb-4">
                            <Link to="/event_request"> <div class="card" style={{ cursor: "pointer" }}>
                                <div class="card-header p-3 pt-2" style={{ borderStyle: "none" }}>
                                    <div
                                        class="icon-shape bg-gradient-warning shadow-success text-center border-radius-xl mt-n4 position-absolute">
                                        <i class="material-icons opacity-10">interests</i>
                                    </div>
                                    <div class="text-end pt-1">
                                        <p class="text-sm mb-0 text-capitalize" style={{ fontWeight: "bold" }}>Event Requests</p>
                                    </div>
                                </div>

                            </div>
                            </Link>
                        </div> */}

                        <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4 pb-4">
                            <Link to="/hire_request"> <div class="card" style={{ cursor: "pointer" }}>
                                <div class="card-header p-3 pt-2" style={{ borderStyle: "none" }}>
                                    <div
                                        class="icon-shape bg-gradient-info shadow-success text-center border-radius-xl mt-n4 position-absolute">
                                        <i class="material-icons opacity-10">image</i>
                                    </div>
                                    <div class="text-end pt-1">
                                        <p class="text-sm mb-0 text-capitalize" style={{ fontWeight: "bold" }}>Hire Requests</p>
                                    </div>
                                </div>

                            </div>
                            </Link>
                        </div>

                        <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4 pb-4">
                            <Link to="/loan_request"> <div class="card" style={{ cursor: "pointer" }}>
                                <div class="card-header p-3 pt-2" style={{ borderStyle: "none" }}>
                                    <div
                                        class="icon-shape bg-gradient-secondary shadow-success text-center border-radius-xl mt-n4 position-absolute">
                                        <i class="material-icons opacity-10">style</i>
                                    </div>
                                    <div class="text-end pt-1">
                                        <p class="text-sm mb-0 text-capitalize" style={{ fontWeight: "bold" }}>Loan Requests</p>
                                    </div>
                                </div>

                            </div>
                            </Link>
                        </div>

                        {/* <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4 pb-4">
                            <Link to="/mentorship_request"> <div class="card" style={{ cursor: "pointer" }}>
                                <div class="card-header p-3 pt-2" style={{ borderStyle: "none" }}>
                                    <div
                                        class="icon-shape bg-gradient-danger shadow-success text-center border-radius-xl mt-n4 position-absolute">
                                        <i class="material-icons opacity-10">margin</i>
                                    </div>
                                    <div class="text-end pt-1">
                                        <p class="text-sm mb-0 text-capitalize" style={{ fontWeight: "bold" }}>Mentorship Requests</p>
                                    </div>
                                </div>

                            </div>
                            </Link>
                        </div> */}
                        {/* 
                        <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4 pb-4">
                            <Link to="/consumables_request">    <div class="card">
                                <div class="card-header p-3 pt-2" style={{ borderStyle: "none" }}>
                                    <div
                                        class="icon-shape bg-gradient-primary shadow-success text-center border-radius-xl mt-n4 position-absolute">
                                        <i class="material-icons opacity-10">workspaces</i>
                                    </div>
                                    <div class="text-end pt-1">
                                        <p class="text-sm mb-0 text-capitalize" style={{ fontWeight: "bold" }}>Consumables Order</p>
                                    </div>
                                </div>

                            </div></Link>
                        </div> */}

                        <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4 pb-4">
                            <Link to="/premium_card_request">    <div class="card">
                                <div class="card-header p-3 pt-2" style={{ borderStyle: "none" }}>
                                    <div
                                        class="icon-shape bg-gradient-primary shadow-success text-center border-radius-xl mt-n4 position-absolute">
                                        <i class="material-icons opacity-10">workspaces</i>
                                    </div>
                                    <div class="text-end pt-1">
                                        <p class="text-sm mb-0 text-capitalize" style={{ fontWeight: "bold" }}>Premium Card Req.</p>
                                    </div>
                                </div>

                            </div></Link>
                        </div>

                        {/* <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4 pb-4">
                            <Link to="/rent_request">    <div class="card">
                                <div class="card-header p-3 pt-2" style={{ borderStyle: "none" }}>
                                    <div
                                        class="icon-shape bg-gradient-primary shadow-success text-center border-radius-xl mt-n4 position-absolute">
                                        <i class="material-icons opacity-10">workspaces</i>
                                    </div>
                                    <div class="text-end pt-1">
                                        <p class="text-sm mb-0 text-capitalize" style={{ fontWeight: "bold" }}>Rent Requests</p>
                                    </div>
                                </div>

                            </div></Link>
                        </div> */}

                        <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4 pb-4">
                            <Link to="/workshop_registration">    <div class="card">
                                <div class="card-header p-3 pt-2" style={{ borderStyle: "none" }}>
                                    <div
                                        class="icon-shape bg-gradient-primary shadow-success text-center border-radius-xl mt-n4 position-absolute">
                                        <i class="material-icons opacity-10">workspaces</i>
                                    </div>
                                    <div class="text-end pt-1">
                                        <p class="text-sm mb-0 text-capitalize" style={{ fontWeight: "bold" }}>Workshop Registration</p>
                                    </div>
                                </div>

                            </div></Link>
                        </div>

                        <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4 pb-4">
                            <Link to="/workshop">    <div class="card">
                                <div class="card-header p-3 pt-2" style={{ borderStyle: "none" }}>
                                    <div
                                        class="icon-shape bg-gradient-primary shadow-success text-center border-radius-xl mt-n4 position-absolute">
                                        <i class="material-icons opacity-10">workspaces</i>
                                    </div>
                                    <div class="text-end pt-1">
                                        <p class="text-sm mb-0 text-capitalize" style={{ fontWeight: "bold" }}>All Workshop</p>
                                    </div>
                                </div>

                            </div></Link>
                        </div>

                        <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4 pb-4">
                            <Link to="/request_callback">    <div class="card">
                                <div class="card-header p-3 pt-2" style={{ borderStyle: "none" }}>
                                    <div
                                        class="icon-shape bg-gradient-primary shadow-success text-center border-radius-xl mt-n4 position-absolute">
                                        <i class="material-icons opacity-10">workspaces</i>
                                    </div>
                                    <div class="text-end pt-1">
                                        <p class="text-sm mb-0 text-capitalize" style={{ fontWeight: "bold" }}>Call Back Request</p>
                                    </div>
                                </div>

                            </div></Link>
                        </div>



                    </div>
                </div>


            </main>


        </>
    )
}

export default ViewAllRequests;