import React, { useState, useEffect, Fragment } from 'react';
import { render } from 'react-dom';
import ReactDatatable from '@ashvin27/react-datatable';
import db from "./Firebase";
// import { firebase, auth } from './FirebaseLogin';
import Moment from 'moment';
import { extendMoment } from 'moment-range';

function OrderConfirmation() {
    const moment = extendMoment(Moment);
    const columns = [
        {
            key: "id",
            text: "ID",
            className: "id",
            align: "left",
            sortable: true,
        },
        {
            key: "payment_mode",
            text: "Payment Mode",
            className: "payment_mode",
            align: "left",
            sortable: true,
        },
        {
            key: "pincode",
            text: "Pincode",
            className: "pincode",
            align: "left",
            sortable: true
        },
        {
            key: "status",
            text: "Status",
            className: "status",
            align: "left",
            sortable: true
        },
        {
            key: "total",
            text: "Total",
            className: "total",
            align: "left",
            sortable: true
        },
        {
            key: "orderd_on",
            text: "Orderd On",
            className: "orderd_on",
            align: "left",
            sortable: true
        },
        {
            key: "action",
            text: "Action",
            className: "action",
            width: 100,
            align: "left",
            sortable: false,
            cell: record => {
                return (
                    <Fragment>
                        {/* <button
                            className="btn btn-primary btn-sm"
                            onClick={() => this.editRecord(record)}
                            style={{ marginRight: '5px' }}>
                            <i className="fa fa-edit"></i>
                        </button> */}
                        {record.status == "Delivered" ?
                            <>
                                <button type="button" class="btn" style={{ background: "#28a745", color: "white" }}>Done</button>
                            </>
                            :
                            <div class="dropdown" style={{ background: "rgb(239 105 87)", color: "white" }}>
                                <button type="button" class="btn  dropdown-toggle" data-toggle="dropdown">
                                    Change Status
                                </button>
                                <div class="dropdown-menu">
                                    <a class="dropdown-item" href="#" onClick={() => Confirm(record.id)}>Confirm</a>
                                    <a class="dropdown-item" selected="selected" href="#" onClick={() => in_transit(record.id)}>In Transit</a>
                                    <a class="dropdown-item" href="#" onClick={() => delivered(record.id)}>Delivered</a>
                                </div>
                            </div>
                        }
                        {/* <button
                            className="btn btn-danger btn-sm"
                            onClick={() => this.deleteRecord(record)}>
                            <i className="fa fa-trash"></i>
                        </button> */}
                    </Fragment>
                );
            }
        }
    ];

    const config = {
        page_size: 10,
        length_menu: [10, 20, 50],
        // button: {
        //     excel: true,
        //     print: true,
        //     extra: true,
        // }
    }

    const records = [
        {
            "id": "55f14312c7447c3da7051b26",
            "payment": "228 City Road",
            "id": ".CN Chinese",
            "status": "3JH",
            "total": 5,
        },
        {
            "id": "55f14312c7447c3da7051b27",
            "payment": "376 Rayleigh Road",
            "id": "@ Thai",
            "status": "5PT",
            "total": 5.5,
        },
        {
            "id": "55f14312c7447c3da7051b28",
            "payment": "30 Greyhound Road Hammersmith",
            "id": "@ Thai Restaurant",
            "status": "8NX",
            "total": 4.5,
        },
        {
            "id": "55f14312c7447c3da7051b29",
            "payment": "30 Greyhound Road Hammersmith",
            "id": "@ Thai Restaurant",
            "status": "8NX",
            "total": 4.5,
        }

    ];

    const extraButtons = [
        {
            className: "btn btn-primary buttons-pdf",
            title: "Export TEst",
            children: [
                <span>
                    <i className="glyphicon glyphicon-print fa fa-print" aria-hidden="true"></i>
                </span>
            ],
            onClick: (event) => {
                console.log(event);
            },
        },
        {
            className: "btn btn-primary buttons-pdf",
            title: "Export TEst",
            children: [
                <span>
                    <i className="glyphicon glyphicon-print fa fa-print" aria-hidden="true"></i>
                </span>
            ],
            onClick: (event) => {
                console.log(event);
            },
            onDoubleClick: (event) => {
                console.log("doubleClick")
            }
        },
    ];

    const editRecord = (record) => {
        console.log("Edit Record", record);
    }

    const Confirm = (id) => {
        db.collection("orderd_consumables_products").doc(id).update({ 'status': "Confirmed", 'updated_date': new Date() }).then(() => {
            console.log("user added");
        })
    }

    const in_transit = (id) => {
        db.collection("orderd_consumables_products").doc(id).update({ 'status': "In Transit", 'updated_date': new Date() }).then(() => {
            console.log("user added");
        })
    }

    const delivered = (id) => {
        db.collection("orderd_consumables_products").doc(id).update({ 'status': "Delivered", 'updated_date': new Date() }).then(() => {
            console.log("user added");
        })
    }

    const deleteRecord = (record) => {
        console.log("Delete Record", record.id);
    }

    const [data, setData] = useState([]);

    useEffect(() => {

        db.collection("orderd_consumables_products").onSnapshot((snapshot) => {

            setData(
                snapshot.docs.map((doc) => ({
                    id: doc.id,
                    // "id": "55f14312c7447c3da7051b26", 
                    "pincode": doc.data()['pincode'],
                    "payment_mode": doc.data()['mode'],
                    "status": doc.data()['status'],
                    "total": doc.data()['amount'],
                    // "orderd_on": doc.data()['created_date'].toDate(),
                    "orderd_on": moment(doc.data()['created_date'].toDate()).format("MMM Do YY"),
                    // data: doc.data(),
                }))
            );

            snapshot.docs.map(e => console.log("testi-", e.data()['created_date']))
        });

    }, []);

    return (
        <>
            <div style={{ marginTop: "1rem", marginLeft: "4rem" }}>
                <h1 style={{ fontSize: "25px", fontWeight: "400", textDecoration: "underline" }}>Order Confirmation</h1>
            </div>
            <div style={{ overflowX: "auto", padding: "4rem" }}>
                <ReactDatatable
                    config={config}
                    records={data}
                    columns={columns}
                    extraButtons={extraButtons}
                />
            </div>
        </>
    )
}

export default OrderConfirmation