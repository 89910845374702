import React, { useState } from 'react';
import CryptoJS from 'crypto-js';

const PayUForm = () => {
    const [formData, setFormData] = useState({
        txnid: '',
        amount: '',
        productinfo: '',
        firstname: '',
        email: '',
    });

    const key = 'gtKFFx'; // Use your PayU test key
    const salt = '4R38IvwiV57FwVpsgOvTXBdLE4tHUXFW'; // Use your PayU test salt

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    // const generateHash = (e) => {
    //     e.preventDefault();
    //     const { txnid, amount, productinfo, firstname, email } = formData;
    //     const hashString = `${key}|${txnid}|${amount}|${productinfo}|${firstname}|${email}|||||||||||${salt}`;
    //     const hash = CryptoJS.SHA512(hashString).toString();
    //     console.log('Generated Hash:', hash);

    //     // Create a form and submit to PayU
    //     const payuForm = document.createElement('form');
    //     payuForm.action = 'https://test.payu.in/_payment';
    //     payuForm.method = 'POST';

    //     // Append all required fields to the form
    //     const fields = { ...formData, key, hash, surl: 'http://localhost:3000/success', furl: 'http://localhost:3000/failure' };
    //     Object.keys(fields).forEach((field) => {
    //         const input = document.createElement('input');
    //         input.type = 'hidden';
    //         input.name = field;
    //         input.value = fields[field];
    //         payuForm.appendChild(input);
    //     });

    //     document.body.appendChild(payuForm);
    //     payuForm.submit();
    // };



    // const generateHash = (e) => {
    //     e.preventDefault();
    //     const { txnid, amount, productinfo, firstname, email } = formData;
    //     const hashString = `${key}|${txnid}|${amount}|${productinfo}|${firstname}|${email}|||||||||||${salt}`;
    //     const hash = CryptoJS.SHA512(hashString).toString();
    //     console.log('Generated Hash:', hash);

    //     // Log the transaction ID
    //     console.log('Transaction ID:', txnid);

    //     // Create a form and submit to PayU
    //     const payuForm = document.createElement('form');
    //     payuForm.action = 'https://test.payu.in/_payment';
    //     payuForm.method = 'POST';

    //     // Append all required fields to the form
    //     const fields = { ...formData, key, hash, surl: 'http://your-success-url', furl: 'http://your-failure-url' };
    //     Object.keys(fields).forEach((field) => {
    //         const input = document.createElement('input');
    //         input.type = 'hidden';
    //         input.name = field;
    //         input.value = fields[field];
    //         payuForm.appendChild(input);
    //     });

    //     document.body.appendChild(payuForm);
    //     payuForm.submit();
    // };

    const generateHash = async (e) => {
        e.preventDefault();
        const { txnid, amount, productinfo, firstname, email } = formData;
        const hashString = `${key}|${txnid}|${amount}|${productinfo}|${firstname}|${email}|||||||||||${salt}`;
        const hash = CryptoJS.SHA512(hashString).toString();
        console.log('Generated Hash:', hash);
        console.log('Transaction ID:', txnid);

        // Create form data for the POST request
        const fields = {
            key: key,
            txnid: txnid,
            amount: amount,
            productinfo: productinfo,
            firstname: firstname,
            email: email,
            hash: hash,
            surl: 'http://localhost:3000/success', // Replace with your success URL
            furl: 'http://localhost:3000/failure', // Replace with your failure URL
        };

        try {
            const response = await fetch('https://test.payu.in/_payment', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams(fields).toString(),
            });

            if (response.ok) {
                console.log('Payment successful!');
                const responseData = await response.text(); // assuming response is text, adjust accordingly
                console.log('Response from PayU:', responseData);
                // Handle success, e.g., redirect to a success page
            } else {
                console.error('Payment failed.');
                const errorResponse = await response.text(); // assuming response is text, adjust accordingly
                console.log('Error response from PayU:', errorResponse);
                // Handle failure, e.g., show error message to the user
            }
        } catch (error) {
            console.error('Error:', error);
            // Handle network errors or other exceptions
        }
    };



    return (
        <div>
            <form onSubmit={generateHash}>
                <input type="text" name="txnid" placeholder="Transaction ID" onChange={handleChange} required />
                <input type="text" name="amount" placeholder="Amount" onChange={handleChange} required />
                <input type="text" name="productinfo" placeholder="Product Info" onChange={handleChange} required />
                <input type="text" name="firstname" placeholder="First Name" onChange={handleChange} required />
                <input type="email" name="email" placeholder="Email" onChange={handleChange} required />
                <button type="submit">Generate Hash and Pay</button>
            </form>
        </div>
    );
};

export default PayUForm;


// import React, { useState } from 'react';
// import axios from 'axios';

// const PayUForm = () => {
//     // const [formData, setFormData] = useState({
//     //     txnid: `txnid_${new Date().getTime()}`,
//     //     amount: '',
//     //     productinfo: 'Sample Product',
//     //     firstname: '',
//     //     email: '',
//     //     phone: '',
//     //     surl: 'http://13.210.119.243/',
//     //     furl: 'http://13.210.119.243/',
//     // });

//     // const handleChange = (e) => {
//     //     setFormData({ ...formData, [e.target.name]: e.target.value });
//     // };

//     // const handleSubmit = async (e) => {
//     //     e.preventDefault();
//     //     const myHeaders = new Headers();
//     //     myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
//     //     myHeaders.append("Cookie", "PHPSESSID=mfk5dvgleu9jqd2eikbj3pmpol");

//     //     const urlencoded = new URLSearchParams();
//     //     urlencoded.append("key", "pCVpYPUj");
//     //     urlencoded.append("txnid", "9750ba272c25a96581c5 ");
//     //     urlencoded.append("amount", "10 ");
//     //     urlencoded.append("firstname", "Payu-Admin ");
//     //     urlencoded.append("salt_version", "1");
//     //     urlencoded.append("email", "test@example.com ");
//     //     urlencoded.append("phone", "1234567890 ");
//     //     urlencoded.append("productinfo", "Product Info");
//     //     urlencoded.append("surl", "http://pp50admin.payu.in/test_response");
//     //     urlencoded.append("txtid", "afb82b0dc86628a66f7fc4eb5b166786 ");
//     //     urlencoded.append("furl", "http://pp50admin.payu.in/test_response");
//     //     urlencoded.append("hash", "73b591350b15613947c5862bbed21b3818873d497e2792d30b068d393f14bc5d889bace26a45f63a2da3302c2cbd2706b23282d0ac5202b11435d3545a088afd");

//     //     const requestOptions = {
//     //         method: "POST",
//     //         headers: myHeaders,
//     //         body: urlencoded,
//     //         redirect: "follow"
//     //     };

//     //     fetch("https://test.payu.in/_payment", requestOptions)
//     //         .then((response) => response.text())
//     //         .then((result) => console.log(result))
//     //         .catch((error) => console.error(error));
//     //     // try {
//     //     //     // const hashResponse = await axios.post('http://13.210.119.243/payu_hash_generator.py', {
//     //     //     //     key: 'pCVpYPUj',
//     //     //     //     txnid: formData.txnid,
//     //     //     //     amount: formData.amount,
//     //     //     //     productinfo: formData.productinfo,
//     //     //     //     firstname: formData.firstname,
//     //     //     //     email: formData.email,
//     //     //     // });

//     //     //     const hash = "9870383037bf3033c3d43f046fae87dc595f8081d8822767cad0007be8370be8740bb36d5f7e00637d95e39d832bf1ad3544d9cfe08388159f942cbc53cd2f3e";
//     //     //     const paymentUrl = `https://sandboxsecure.payu.in/_payment?key=pCVpYPUj&txnid=${formData.txnid}&amount=${formData.amount}&productinfo=${formData.productinfo}&firstname=${formData.firstname}&email=${formData.email}&phone=${formData.phone}&surl=${formData.surl}&furl=${formData.furl}&hash=${hash}`;

//     //     //     window.location.href = paymentUrl;
//     //     // } catch (error) {
//     //     //     console.error('Error processing payment:', error);
//     //     // }
//     // };

//     return (
//         <>
//             <form action='https://test.payu.in/_payment' method='post'>
//                 <input type="hidden" name="key" value="gtKFFx" />
//                 <input type="hidden" name="salt" value="eCwWELxi" />
//                 <input type="hidden" name="txnid" value="t6svtqtjRdl34W" />
//                 <input type="hidden" name="productinfo" value="iPhone" />
//                 <input type="hidden" name="amount" value="10" />
//                 <input type="hidden" name="email" value="test@gmail.com" />
//                 <input type="hidden" name="firstname" value="Ashish" />
//                 <input type="hidden" name="lastname" value="Kumar" />
//                 <input type="hidden" name="pg" value="UPI" />
//                 <input type="hidden" name="bankcode" value="UPI" />
//                 <input type="hidden" name="vpa" value="test123@okhdfcbank" />
//                 <input type="hidden" name="surl" value="http://localhost:3000/success" />
//                 <input type="hidden" name="furl" value="http://localhost:3000/failure" />
//                 <input type="hidden" name="phone" value="9988776655" />
//                 <input type="hidden" name="hash"
//                     value="709d596080380c4273625d976a9df47bddc8e72f7ef62a485324a86efa55b51bd496a6b6800256672dc0fcbff2a46304fe05d1722417404dea16e988099875e1" />
//                 <input type="submit" value="submit" />
//             </form>
//         </>
//         // <form onSubmit={handleSubmit}>
//         //     <input type="text" name="amount" value={formData.amount} onChange={handleChange} placeholder="Amount" required />
//         //     <input type="text" name="firstname" value={formData.firstname} onChange={handleChange} placeholder="First Name" required />
//         //     <input type="email" name="email" value={formData.email} onChange={handleChange} placeholder="Email" required />
//         //     <input type="text" name="phone" value={formData.phone} onChange={handleChange} placeholder="Phone" required />
//         //     <button type="submit">Pay Now</button>
//         // </form>
//     );
// };

// export default PayUForm;
