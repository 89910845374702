import React, { useState, useEffect } from 'react'
import DataTable from 'react-data-table-component';
import db from "./Firebase";
import moment from 'moment';
import Swal from 'sweetalert2'

function Search() {
    const initialFormState = { pincode: '' }
    const [submit, setSubmit] = useState(initialFormState)
    const handleChange = (e) => {
        const { id, value } = e.target
        setSubmit({ ...submit, [id]: value })
    }

    const [Show, setShow] = useState(true)
    const [dataLength, setDataLength] = useState(0)

    const [query, setQuery] = useState([])
    const searchByPincode = () => {
        // alert(submit.pincode)
        // db.collection("unregistered_users").where('pincode', '==', submit.pincode)
        db.collection("unregistered_users").where('pincode', '>=', submit.pincode).where('pincode', '<=', submit.pincode + '\uf8ff')
            .onSnapshot((snapshot) => {
                console.log(snapshot.docs.length)
                setDataLength(snapshot.docs.length)
                snapshot.docs.map((doc, index) => {
                    // console.log()
                    console.log("parlour_name", doc.data().parlour_name);
                    // console.log("timeeeeeeeeeeee", doc.data().timestamp)
                })
                setQuery(
                    snapshot.docs.map((doc) => ({
                        'parlour_name': doc.data().parlour_name,
                        'phone_no': doc.data().phone_no,
                        'pincode': doc.data().pincode,
                        'place_best_beauty_parlour_in': doc.data().place_best_beauty_parlour_in,
                        'full_address': doc.data().full_address,
                        'rating': doc.data().rating,
                        'reviews': doc.data().reviews,
                        'image': doc.data().image,
                        'username': doc.data().username,
                        'plus_code': doc.data().plus_code,
                        'email': doc.data().email,
                        'monday': doc.data().monday,
                        'tuesday': doc.data().tuesday,
                        'wednesday': doc.data().wednesday,
                        'thursday': doc.data().thursday,
                        'friday': doc.data().friday,
                        'saturday': doc.data().saturday,
                        'sunday': doc.data().sunday,
                        created_date: moment.unix(doc.data().created_date).format("MM/DD, h:mm a"),

                    }))
                );
            })
    }

    const handleSubmit = (e) => {
        // alert(JSON.stringify(submit))
        e.preventDefault();
        if (submit.username == 'admin' && submit.password == 'Security@#pass12') {
            setShow(true)
        } else {
            const Toast = Swal.mixin({
                toast: true,
                background: '#69aba6',
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            })

            Toast.fire({
                icon: 'danser',
                title: 'Invalid Username Password !!!'
            })
            //   setSubmit({username: '', password: ''})
        }

    }

    const [data, setdata] = useState([])
    const columns = [
        {
            name: 'parlour_name',
            selector: 'parlour_name',
            sortable: true,
        },
        {
            name: 'Full Address',
            selector: 'full_address',
            sortable: true,
            right: true,
        },
        {
            name: 'email',
            selector: 'email',
            sortable: true,
            right: true,
        },
        {
            name: 'image',
            selector: 'image',
            sortable: true,
            right: true,
        },
        {
            name: 'Pincode',
            selector: 'pincode',
            sortable: true,
            right: true,
        },
        {
            name: 'Phone No',
            selector: 'phone_no',
            sortable: true,
            // right: true,
        },
        {
            name: 'Place Best Beauty Parlour In',
            selector: 'place_best_beauty_parlour_in',
            sortable: true,
            // right: true,
        },
        {
            name: 'Plus Code',
            selector: 'plus_code',
            sortable: true,
            // right: true,
        },
        {
            name: 'rating',
            selector: 'rating',
            sortable: true,
            // right: true,
        },
        {
            name: 'Review',
            selector: 'review',
            sortable: true,
            // right: true,
        },
        {
            name: 'Plus Code',
            selector: 'plus_code',
            sortable: true,
            // right: true,
        },
        {
            name: 'User Name',
            selector: 'username',
            sortable: true,
            // right: true,
        },
        {
            name: 'Monday',
            selector: 'monday',
            sortable: true,
            // right: true,
        },
        {
            name: 'Tuesday',
            selector: 'tuesday',
            sortable: true,
            // right: true,
        },
        {
            name: 'Wednesday',
            selector: 'wednesday',
            sortable: true,
            // right: true,
        },
        {
            name: 'Thursday',
            selector: 'thursday',
            sortable: true,
            // right: true,
        },
        {
            name: 'Friday',
            selector: 'friday',
            sortable: true,
            // right: true,
        },
        {
            name: 'Saturday',
            selector: 'saturday',
            sortable: true,
            // right: true,
        },
        {
            name: 'Sunady',
            selector: 'sunday',
            sortable: true,
            // right: true,
        },
        {
            name: 'Created Date',
            selector: 'created_date',
            sortable: true,
            // right: true,
        },
    ];


    useEffect(() => {
        // db.collection("unregistered_users")
        //     .onSnapshot((snapshot) => {
        //         console.log(snapshot.docs.length)
        //         snapshot.docs.map((doc, index) => {
        //             // console.log()
        //             console.log("parlour_name", doc.data().parlour_name);
        //             // console.log("timeeeeeeeeeeee", doc.data().timestamp)
        //         })

        // setQuery(
        //     snapshot.docs.map((doc) => ({
        //         'parlour_name': doc.data().parlour_name,
        //         'phone_no': doc.data().phone_no,
        //         'pincode': doc.data().pincode,
        //         'place_best_beauty_parlour_in': doc.data().place_best_beauty_parlour_in,
        //         'full_address': doc.data().full_address,
        //         'rating': doc.data().rating,
        //         'reviews': doc.data().reviews,
        //         'image': doc.data().image,
        //         'username': doc.data().username,
        //         'plus_code': doc.data().plus_code,
        //         'email': doc.data().email,
        //         'monday': doc.data().monday,
        //         'tuesday': doc.data().tuesday,
        //         'wednesday': doc.data().wednesday,
        //         'thursday': doc.data().thursday,
        //         'friday': doc.data().friday,
        //         'saturday': doc.data().saturday,
        //         'sunday': doc.data().sunday,
        //         created_date: moment.unix(doc.data().created_date).format("MM/DD, h:mm a"),

        //     }))
        // );

        // });
    }, []);
    return (
        <>

            {/* <h1 style={{ textAlign: "center", textDecoration: "underline" }}>Unregistered User List</h1> */}
            <div class="container">
                <div class="row" style={{ marginTop: "2rem" }}>
                    <div class="col-2"></div>
                    <div class="col-8"><input type="tel" minlength="6" maxlength="6" required class="form-control" onChange={handleChange} value={submit.parlour_name} id="pincode" placeholder="Search by pincode" /></div>
                    <div class="col-2"><button style={{ background: "blue" }} type="button" class="btn btn-primary" onClick={searchByPincode}>Search</button></div>
                </div>
            </div>
            <div>
                <h1 style={{ fontSize: "larger", fontWeight: "600", color: "red", padding: "1rem" }}>Total Records Found : <spa>{dataLength}</spa></h1>
            </div>
            <div>
                <DataTable
                    pagination
                    highlightOnHover
                    columns={columns}
                    data={query}
                    responsive={true}
                />
            </div>


        </>

    )
}

export default Search;