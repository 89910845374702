import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import db from "./Firebase";
import { CSVLink } from "react-csv";
import Moment from 'moment';
import { extendMoment } from 'moment-range';

function UsersLastCheckin() {
    const navigate = useNavigate();
    const moment = extendMoment(Moment);
    const [mergedData, setMergedData] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [lastVisible, setLastVisible] = useState(null);
    const [hasMore, setHasMore] = useState(true);

    const headers = [
        { label: "Name", key: "full_name" },
        { label: "Phone No", key: "phone_no" },
        { label: "Username", key: "username" },
        { label: "Last Checkin", key: "last_checkin", dataFormat: "MM/DD/YY, h:mm a" },
    ];

    useEffect(() => {
        const storedUsername = localStorage.getItem("email");
        const storedPassword = localStorage.getItem("password");
        const storedRemember = localStorage.getItem("remember");

        if (storedRemember === "false" || !storedUsername || !storedPassword) {
            navigate('/signin');
        }
    }, []);

    const fetchData = async () => {
        const userCheckinSnapshot = await db.collection("user_checkin")
            .orderBy("last_checkin", "desc")
            .limit(20)
            .get();

        const lastVisibleDoc = userCheckinSnapshot.docs[userCheckinSnapshot.docs.length - 1];
        setLastVisible(lastVisibleDoc);

        const userCheckinData = userCheckinSnapshot.docs.map((doc) => ({
            uid: doc.id,
            last_checkin: doc.data().last_checkin,
        }));

        const personalDetailsSnapshot = await db.collection("personal_details").get();
        const personalDetailsData = personalDetailsSnapshot.docs.map((doc) => ({
            uid: doc.id,
            full_name: doc.data().full_name,
            phone_no: doc.data().phone_no,
            username: doc.data().username,
        }));

        const merged = userCheckinData.map((userCheckin) => {
            const personalDetails = personalDetailsData.find((personal) => personal.uid === userCheckin.uid);
            return {
                ...personalDetails,
                last_checkin: userCheckin.last_checkin,
            };
        });

        setMergedData(merged);
    };

    useEffect(() => {
        fetchData();
    }, []);

    const loadMoreData = async () => {
        const userCheckinSnapshot = await db.collection("user_checkin")
            .orderBy("last_checkin", "desc")
            .startAfter(lastVisible)
            .limit(20)
            .get();

        const lastVisibleDoc = userCheckinSnapshot.docs[userCheckinSnapshot.docs.length - 1];
        setLastVisible(lastVisibleDoc);

        if (userCheckinSnapshot.docs.length < 20) {
            setHasMore(false);
        }

        const userCheckinData = userCheckinSnapshot.docs.map((doc) => ({
            uid: doc.id,
            last_checkin: doc.data().last_checkin,
        }));

        const personalDetailsSnapshot = await db.collection("personal_details").get();
        const personalDetailsData = personalDetailsSnapshot.docs.map((doc) => ({
            uid: doc.id,
            full_name: doc.data().full_name,
            phone_no: doc.data().phone_no,
            username: doc.data().username,
        }));

        const merged = userCheckinData.map((userCheckin) => {
            const personalDetails = personalDetailsData.find((personal) => personal.uid === userCheckin.uid);
            return {
                ...personalDetails,
                last_checkin: userCheckin.last_checkin,
            };
        });

        setMergedData(prevData => [...prevData, ...merged]);
    };

    const handleSearch = (event) => {
        setSearchQuery(event.target.value);
    };

    const filteredData = mergedData.filter(({ full_name, phone_no }) => {
        const query = searchQuery.toLowerCase();
        return (
            (full_name && full_name.toLowerCase().includes(query)) ||
            (phone_no && phone_no.includes(searchQuery))
        );
    });

    const csvData = filteredData.map(({ full_name, phone_no, username, last_checkin }) => ({
        full_name,
        phone_no,
        username,
        last_checkin: moment(last_checkin.toDate()).format("MM/DD/YY, h:mm a"),
    }));

    return (
        <>
            <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
                <div className="p-2" style={{ paddingBottom: "5px", display: "flex", justifyContent: "space-between" }}>
                    <Link to="/admin_panel"><img src="/images/menubutton.png" className="menu-toggle" alt="Responsive image" style={{ height: "2rem" }} /></Link>
                    <CSVLink data={csvData} headers={headers} filename={"users_last_checkin.csv"}>
                        <span className="btn bg-gradient-secondary btn-sm mb-0 mr-2" style={{ color: "white" }} >&nbsp; Download Excel</span>
                    </CSVLink>
                </div>
                <div className="">
                    <div className="p-2">
                        <div className="card">
                            <div className="card-header pb-0 px-3">
                                <h6 className="mb-2">Users Last Checkin</h6>
                            </div>
                            <input
                                type="text"
                                className="form-control form-control-sm"
                                placeholder="Search by name / phone_no"
                                value={searchQuery}
                                onChange={handleSearch}
                            />
                            <div className="">
                                <div className="card">
                                    <div className="table-responsive fix-width scroll-inner">
                                        <table className="table table-bordered table-hover">
                                            <thead className="thead-dark" style={{ position: "sticky", top: "0" }}>
                                                <tr style={{ textAlign: "left" }}>
                                                    <th className=" ps-2">Full Name</th>
                                                    <th className="">Phone</th>
                                                    <th className=" ps-2">Username</th>
                                                    <th className="">Last Checkin</th>
                                                </tr>
                                            </thead>
                                            <tbody style={{ textAlign: "left" }}>
                                                {filteredData.map(({ full_name, phone_no, username, last_checkin }) => (
                                                    <tr key={full_name}>
                                                        <td>
                                                            <p className="text-xs font-weight-bold mb-0">{full_name}</p>
                                                        </td>
                                                        <td>
                                                            <div className="d-flex px-2 py-1">
                                                                <div className="d-flex flex-column justify-content-center">
                                                                    <h6 className="mb-0 text-xs">{phone_no}</h6>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="d-flex px-2 py-1">
                                                                <div className="d-flex flex-column justify-content-center">
                                                                    <h6 className="mb-0 text-xs">{username}</h6>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="d-flex px-2 py-1">
                                                                <div className="d-flex flex-column justify-content-center">
                                                                    <h6 className="mb-0 text-xs">{moment(last_checkin.toDate()).format("MM/DD/YY, h:mm a")}</h6>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                        {hasMore && (
                                            <div className="" style={{ paddingTop: "2rem" }}>
                                                <button onClick={loadMoreData} className="btn btn-dark">Load More</button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}

export default UsersLastCheckin;




// import React, { useState, useEffect } from 'react';
// import { useNavigate, Link } from 'react-router-dom';
// import db from "./Firebase";
// import { CSVLink } from "react-csv";
// import Moment from 'moment';
// import { extendMoment } from 'moment-range';

// function UsersLastCheckin() {
//     const navigate = useNavigate();
//     const moment = extendMoment(Moment);
//     const [mergedData, setMergedData] = useState([]);
//     const [searchQuery, setSearchQuery] = useState('');

//     const headers = [
//         { label: "Name", key: "full_name" },
//         { label: "Phone No", key: "phone_no" },
//         { label: "Username", key: "username" },
//         { label: "Last Checkin", key: "last_checkin", dataFormat: "MM/DD/YY, h:mm a" },
//     ];

//     useEffect(() => {
//         const unsubscribeUserCheckin = db.collection("user_checkin").onSnapshot((userCheckinSnapshot) => {
//             const userCheckinData = userCheckinSnapshot.docs.map((doc) => ({
//                 uid: doc.id,
//                 last_checkin: doc.data().last_checkin,
//             }));

//             const unsubscribePersonalDetails = db.collection("personal_details").onSnapshot((personalDetailsSnapshot) => {
//                 const personalDetailsData = personalDetailsSnapshot.docs.map((doc) => ({
//                     uid: doc.id,
//                     full_name: doc.data().full_name,
//                     phone_no: doc.data().phone_no,
//                     username: doc.data().username,
//                 }));

//                 const merged = userCheckinData.map((userCheckin) => {
//                     const personalDetails = personalDetailsData.find((personal) => personal.uid === userCheckin.uid);
//                     return {
//                         ...personalDetails,
//                         last_checkin: userCheckin.last_checkin,
//                     };
//                 });

//                 setMergedData(merged);
//             });

//             return () => {
//                 unsubscribeUserCheckin();
//                 unsubscribePersonalDetails();
//             };
//         });
//     }, []);

//     const handleSearch = (event) => {
//         setSearchQuery(event.target.value);
//     };


//     const filteredData = mergedData.filter(({ full_name, phone_no }) => {
//         const query = searchQuery.toLowerCase();
//         // Check if full_name and phone_no are defined before calling toLowerCase()
//         return (
//             (full_name && full_name.toLowerCase().includes(query)) ||
//             (phone_no && phone_no.includes(searchQuery))
//         );
//     });

//     const csvData = filteredData.map(({ full_name, phone_no, username, last_checkin }) => ({
//         full_name,
//         phone_no,
//         username,
//         last_checkin: moment(last_checkin.toDate()).format("MM/DD/YY, h:mm a"),
//     }));

//     return (
//         <>
//             <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
//                 <div className="p-2" style={{ paddingBottom: "5px", display: "flex", justifyContent: "space-between" }}>
//                     <Link to="/admin_panel"><img src="/images/menubutton.png" className="menu-toggle" alt="Responsive image" style={{ height: "2rem" }} /></Link>
//                     <CSVLink data={csvData} headers={headers} filename={"users_last_checkin.csv"}>
//                         <span className="btn bg-gradient-secondary btn-sm mb-0 mr-2" style={{ color: "white" }} >&nbsp; Download Excel</span>
//                     </CSVLink>
//                 </div>
//                 <div className="">
//                     <div className="p-2">
//                         <div className="card">
//                             <div className="card-header pb-0 px-3">
//                                 <h6 className="mb-2">Users Last Checkin</h6>
//                             </div>
//                             <input
//                                 type="text"
//                                 className="form-control form-control-sm"
//                                 placeholder="Search by name / phone_no"
//                                 value={searchQuery}
//                                 onChange={handleSearch}
//                             />
//                             <div className="">
//                                 <div className="card">
//                                     <div className="table-responsive fix-width scroll-inner">
//                                         <table className="table table-bordered table-hover">
//                                             <thead className="thead-dark" style={{ position: "sticky", top: "0" }}>
//                                                 <tr style={{ textAlign: "left" }}>
//                                                     <th className=" ps-2">Full Name</th>
//                                                     <th className="">Phone</th>
//                                                     <th className=" ps-2">Username</th>
//                                                     <th className="">Last Checkin</th>
//                                                 </tr>
//                                             </thead>
//                                             <tbody style={{ textAlign: "left" }}>
//                                                 {filteredData.map(({ full_name, phone_no, username, last_checkin }) => (
//                                                     <tr key={full_name}>
//                                                         <td>
//                                                             <p className="text-xs font-weight-bold mb-0">{full_name}</p>
//                                                         </td>
//                                                         <td>
//                                                             <div className="d-flex px-2 py-1">
//                                                                 <div className="d-flex flex-column justify-content-center">
//                                                                     <h6 className="mb-0 text-xs">{phone_no}</h6>
//                                                                 </div>
//                                                             </div>
//                                                         </td>
//                                                         <td>
//                                                             <div className="d-flex px-2 py-1">
//                                                                 <div className="d-flex flex-column justify-content-center">
//                                                                     <h6 className="mb-0 text-xs">{username}</h6>
//                                                                 </div>
//                                                             </div>
//                                                         </td>
//                                                         <td>
//                                                             <div className="d-flex px-2 py-1">
//                                                                 <div className="d-flex flex-column justify-content-center">
//                                                                     <h6 className="mb-0 text-xs">{moment(last_checkin.toDate()).format("MM/DD/YY, h:mm a")}</h6>
//                                                                 </div>
//                                                             </div>
//                                                         </td>
//                                                     </tr>
//                                                 ))}
//                                             </tbody>
//                                         </table>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </main>
//         </>
//     )
// }

// export default UsersLastCheckin;
