import React, { useState, useEffect } from 'react';
import * as XLSX from 'xlsx';
import DataTable from 'react-data-table-component';
import { useNavigate, Link, useParams } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2'
import db from "./Firebase";
import { storage } from './Firebase';
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";


function UploadSellProducts() {
    let params = useParams();
    const navigate = useNavigate();
    const [columns, setColumns] = useState([]);
    const [data, setData] = useState([]);
    const [ShowProgress, setShowProgress] = useState(false);




    const initialFormStateFeedback = { type: '', product_name: '', brand_name: '', quantity: '', itemcount: '', price: '', seller: '', authorization_code: '', created_date: new Date() }
    const [submit, setSubmit] = useState(initialFormStateFeedback)

    const [show, setshow] = useState(true);

    const [showExcel, setshowExcel] = useState(false);

    const [imgUrl, setImgUrl] = useState(null);
    const [progresspercent, setProgresspercent] = useState(0);


    const handleChange = (e) => {
        const { id, value } = e.target
        setSubmit({ ...submit, [id]: value })
    }

    const reset = () => {
        setSubmit({ type: '', product_name: '', brand_name: '', quantity: '', itemcount: '', price: '', seller: '', authorization_code: '', created_date: new Date() })
    }


    var _cartList = [
        {
            "type": "Hair",
            "product_name": "Shampoo",
            "brand_name": "Herbia",
            "quantity": "4L",
            "itemcount": 0,
            "price": 300,
            "created_date": new Date(),
            "seller": "",
            "image_url":
                "https://firebasestorage.googleapis.com/v0/b/beautyx-14ac0.appspot.com/o/consumables_images%2Fherbal_shampoo_1000x1000.webp?alt=media&token=2fdb2d61-0e5d-49f7-9686-a30f0cfbad5d"
        },
        {
            "type": "Hair",
            "product_name": "Conditioner",
            "brand_name": "Herbia",
            "quantity": "4L",
            "itemcount": 0,
            "price": 350,
            "created_date": new Date(),
            "seller": "",
            "image_url":
                "https://firebasestorage.googleapis.com/v0/b/beautyx-14ac0.appspot.com/o/consumables_images%2Fimages.webp?alt=media&token=07ffb0d7-cd90-435b-9d6d-2ffc2faae2ce"
        },
        {
            "type": "Hair",
            "product_name": "Hair Spa",
            "brand_name": "Aromatic",
            "quantity": "500 ml",
            "itemcount": 0,
            "price": 350,
            "created_date": new Date(),
            "seller": "",
            "image_url":
                "https://firebasestorage.googleapis.com/v0/b/beautyx-14ac0.appspot.com/o/consumables_images%2F41Kcj4yPCfL.webp?alt=media&token=4fe01738-edbb-4e1e-8993-65a035545e43"
        },
        {
            "type": "Skin",
            "product_name": "Rica Wax",
            "brand_name": "Rica Wax",
            "quantity": "800 gram",
            "itemcount": 0,
            "price": 900,
            "created_date": new Date(),
            "seller": "",
            "image_url":
                "https://firebasestorage.googleapis.com/v0/b/beautyx-14ac0.appspot.com/o/consumables_images%2F5dea0a13-0bcb-4826-b086-419ff65ee3691649616227572RICADrySkinDarkChocolateLiposolubleWax800ml1.webp?alt=media&token=bca651de-7fcf-47e3-b55a-855b6b65ae05"
        },
        {
            "type": "Skin",
            "product_name": "Brazilian wax",
            "brand_name": "Estavito",
            "quantity": "1 box of 9 Piece",
            "itemcount": 0,
            "price": 550,
            "created_date": new Date(),
            "seller": "",
            "image_url":
                "https://firebasestorage.googleapis.com/v0/b/beautyx-14ac0.appspot.com/o/consumables_images%2F61AX1DD5bQL._SX679_.webp?alt=media&token=042f6698-b9dc-4948-8139-7ce8362ca927"
        },
        {
            "type": "Skin",
            "product_name": "Wax Strips",
            "brand_name": "Herbia",
            "quantity": "1 Packet",
            "itemcount": 0,
            "price": 48,
            "created_date": new Date(),
            "seller": "",
            "image_url":
                "https://firebasestorage.googleapis.com/v0/b/beautyx-14ac0.appspot.com/o/consumables_images%2F41%2Brny1j6qL.webp?alt=media&token=5aa64761-1c4d-4ea9-820b-1725d8243fef"
        },
        {
            "type": "Skin",
            "product_name": "Massage Cream",
            "brand_name": "Herbia",
            "quantity": "500 Gram",
            "itemcount": 0,
            "price": 140,
            "created_date": new Date(),
            "seller": "",
            "image_url":
                "https://firebasestorage.googleapis.com/v0/b/beautyx-14ac0.appspot.com/o/consumables_images%2Fmassage-cream-500x500.webp?alt=media&token=62c2b035-ad93-4e05-b686-ec73d793b227"
        },
        {
            "type": "Skin",
            "product_name": "Scrub",
            "brand_name": "Herbia",
            "quantity": "500 Gram",
            "itemcount": 0,
            "price": 140,
            "created_date": new Date(),
            "seller": "",
            "image_url":
                "https://firebasestorage.googleapis.com/v0/b/beautyx-14ac0.appspot.com/o/consumables_images%2Ffrank-body-original-face-scrub_DOT.COM_MODEL-03-V2.webp?alt=media&token=9b388d66-b7d6-4533-b006-4c254d11a890"
        },
        {
            "type": "Skin",
            "product_name": "Face Pack",
            "brand_name": "Herbia",
            "quantity": "1 Kg",
            "itemcount": 0,
            "price": 300,
            "created_date": new Date(),
            "seller": "",
            "image_url":
                "https://firebasestorage.googleapis.com/v0/b/beautyx-14ac0.appspot.com/o/consumables_images%2Fa-500x500.webp?alt=media&token=b5c8208d-1400-48ba-8afc-fccf11e149a5"
        },
        {
            "type": "Make-up",
            "product_name": "Kryolan Base  Palette",
            "brand_name": "Kryolan",
            "quantity": " 12 Palettes",
            "itemcount": 0,
            "price": 3000,
            "created_date": new Date(),
            "seller": "",
            "image_url":
                "https://firebasestorage.googleapis.com/v0/b/beautyx-14ac0.appspot.com/o/consumables_images%2FPearl_dc72bc0d-a2a8-46b9-b062-257d983baf94_400x.webp?alt=media&token=34b09137-9bca-4a51-957f-81a158123ec0"
        },
        {
            "type": "Make-up",
            "product_name": "Make-up  Foundation",
            "brand_name": "Lakme ",
            "quantity": "50ml",
            "itemcount": 0,
            "price": 140,
            "created_date": new Date(),
            "seller": "",
            "image_url":
                "https://cdn.shopify.com/s/files/1/0014/3514/0183/products/Pearl_dc72bc0d-a2a8-46b9-b062-257d983baf94_400x.jpg?v=1653489278"
        },
        {
            "type": "Make-up",
            "product_name": "Compact Powder",
            "brand_name": "Malioa",
            "quantity": "1 Piece",
            "itemcount": 0,
            "price": 225,
            "created_date": new Date(),
            "seller": "",
            "image_url":
                "https://firebasestorage.googleapis.com/v0/b/beautyx-14ac0.appspot.com/o/consumables_images%2Fmac_sku_M51079_640x600_0.webp?alt=media&token=d605062f-3db4-40eb-bb42-12ffeb4f67f6"
        },
        {
            "type": "Make-up",
            "product_name": "Eye Shadow Palette",
            "brand_name": "Miss Rose",
            "quantity": "1 Piece",
            "itemcount": 0,
            "price": 275,
            "created_date": new Date(),
            "seller": "",
            "image_url":
                "https://firebasestorage.googleapis.com/v0/b/beautyx-14ac0.appspot.com/o/consumables_images%2F5101qjYR2EL._SY450_.webp?alt=media&token=ece71b50-c183-47f3-a559-8415d7be6551"
        },
        {
            "type": "Make-up",
            "product_name": "Eye Liner Liquid",
            "brand_name": "Malioa",
            "quantity": "1 Piece",
            "itemcount": 0,
            "price": 100,
            "created_date": new Date(),
            "seller": "",
            "image_url":
                "https://firebasestorage.googleapis.com/v0/b/beautyx-14ac0.appspot.com/o/consumables_images%2Feyeliner-liquid-3-640x640.webp?alt=media&token=ef3c6261-8006-4d3b-9804-ab74f080ac20"
        },
        {
            "type": "Make-up",
            "product_name": "Eye Liner Gel",
            "brand_name": "Malioa",
            "quantity": "1 Piece",
            "itemcount": 0,
            "price": 150,
            "created_date": new Date(),
            "seller": "",
            "image_url":
                "https://firebasestorage.googleapis.com/v0/b/beautyx-14ac0.appspot.com/o/consumables_images%2Fc2154a1nypac00001624.webp?alt=media&token=5d5d6994-3d66-4eb0-a591-3cb6a5f4c4f7"
        }
    ];

    const processData = dataString => {
        const dataStringLines = dataString.split(/\r\n|\n/);
        const headers = dataStringLines[0].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);

        const list = [];
        for (let i = 1; i < dataStringLines.length; i++) {
            const row = dataStringLines[i].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);
            if (headers && row.length == headers.length) {
                const obj = {};
                for (let j = 0; j < headers.length; j++) {
                    let d = row[j];
                    if (d.length > 0) {
                        if (d[0] == '"')
                            d = d.substring(1, d.length - 1);
                        if (d[d.length - 1] == '"')
                            d = d.substring(d.length - 2, 1);
                    }
                    if (headers[j]) {
                        obj[headers[j]] = d;
                    }
                }

                // remove the blank rows
                if (Object.values(obj).filter(x => x).length > 0) {
                    list.push(obj);
                }
            }
        }

        // prepare columns list from headers
        const columns = headers.map(c => ({
            name: c,
            selector: c,
        }));

        setData(list);
        setColumns(columns);
        console.log(list)
    }

    const [showData, setShowData] = useState([])
    const [showData1, setShowData1] = useState([])


    const upload = () => {
        // db.collection("consumables_data").add({ _cartList }).then(() => {
        //     console.log('Submitted Successfully');
        // })
        _cartList.map((e, index) => {
            db.collection("consumables_data").add(e).then(() => {
                console.log('Submitted Successfully');
            })
        })
    }

    // handle file upload
    const handleFileUpload = e => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onload = (evt) => {
            /* Parse data */
            const bstr = evt.target.result;
            const wb = XLSX.read(bstr, { type: 'binary' });
            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            /* Convert array of arrays */
            const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
            processData(data);
        };
        reader.readAsBinaryString(file);
    }
    const [num, setNum] = useState(0);
    const submitdata = (e) => {
        setShowProgress(true);
        data.map((user, index) => {
            setNum(index + 1);
            db.collection("sell_product_data").add({
                'type': user.type,
                'product_name': user.product_name,
                'brand_name': user.brand_name,
                'quantity': user.quantity,
                'itemcount': "0",
                'price': user.price,
                'seller': user.seller,
                'image': user.image_url,
                'created_date': new Date(),
            });
        })
    };


    const ids = ["rahul_122002", "ashutosh_560001", "mrinal_122004"];
    const handleSubmit = (e) => {
        // console.log(submit.usercode)
        // alert("okkk")
        e.preventDefault()
        if (ids.includes(submit.authorization_code)) {
            const file = e.target[0]?.files[0]
            // console.log(file)
            if (!file) return;
            const storageRef = ref(storage, `files/${file.name}`);
            const uploadTask = uploadBytesResumable(storageRef, file);
            setshow(false)

            uploadTask.on("state_changed",
                (snapshot) => {
                    const progress =
                        Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                    setProgresspercent(progress);
                },
                (error) => {
                    alert(error);
                },
                () => {

                    getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                        // setImgUrl(downloadURL)
                        db.collection("sell_product_data").add({
                            'type': submit.type,
                            'product_name': submit.product_name,
                            'brand_name': submit.brand_name,
                            'quantity': submit.quantity,
                            'itemcount': "0",
                            'price': submit.price,
                            'seller': submit.seller,
                            'image': downloadURL,
                            'created_date': new Date(),
                        }).then(() => {
                            console.log('Submitted Successfully');
                            const Toast = Swal.mixin({
                                toast: true,
                                background: '#69aba6',
                                position: 'top-end',
                                showConfirmButton: false,
                                timer: 3000,
                                timerProgressBar: true,
                                didOpen: (toast) => {
                                    toast.addEventListener('mouseenter', Swal.stopTimer)
                                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                                }
                            })

                            Toast.fire({
                                icon: 'success',
                                title: 'Submitted Successfully'
                            })
                            setshow(true)
                            // navigate(`/${submit.username}`);
                            setSubmit({ type: '', product_name: '', brand_name: '', quantity: '', itemcount: '', price: '', seller: '', authorization_code: '', created_date: new Date() })
                        })
                    });
                }
            );
        } else {
            alert("You are not authorization to perform this action")
        }
    }

    useEffect(() => {
        // alert(params.id)
    }, []);



    return (
        <>
            {/* <button onClick={upload}>Send</button> */}
            {!showExcel ? <div id="small-dialog" class="m-3">
                <div class="row p-1" style={{ float: "right" }}>
                    <button type="button" class="btn-sm" style={{ backgroundColor: "#337ab7", color: "white" }} onClick={() => setshowExcel(true)}>Upload Excel Data</button>
                </div>
                <div class="w3layouts_payment_online_form">
                    <form class="" id="" onSubmit={handleSubmit}>
                        <h4>Upload Sell Products Data</h4>
                        <ul>
                            <li style={{ textAlign: "left" }}><label for="myfile">Product Image:</label><input type="file" id="image" name="Image" accept="image/*" />
                            </li>
                            <li><input class="w3l_text_box" id="product_name" onChange={handleChange} value={submit.product_name} placeholder="Product Name" name="Product Name" type="text" required />
                            </li>
                        </ul>
                        <ul>
                            <li>
                                {/* <label>Type</label> */}
                                <select class="form-control" id="type" onChange={handleChange} required>
                                    <option value="">--Select Product type--</option>
                                    <option value="Skin">Skin</option>
                                    <option value="Hair">Hair</option>
                                    <option value="Make-up">Make-up</option>
                                    <option value="Nails">Nails</option>
                                </select>
                            </li>
                            <li>
                                <input class="w3l_text_box" id="brand_name" placeholder="brand_name" onChange={handleChange} value={submit.brand_name} name="Brand Name" type="text" required="" />
                            </li>
                        </ul>
                        <ul>
                            <li>
                                <input class="w3l_text_box" id="quantity" placeholder="Quantity" onChange={handleChange} value={submit.quantity} name="Quantity" type="text" required="" />
                            </li>
                            <li>
                                <input class="w3l_text_box" id="price" placeholder="Price" onChange={handleChange} value={submit.price} name="Price" type="number" required="" />
                            </li>
                        </ul>
                        <ul>
                            <li>
                                <input class="w3l_text_box" id="seller" placeholder="Seller" onChange={handleChange} value={submit.seller} name="Seller Name" type="text" required="" />
                            </li>
                            <li>
                                <input class="w3l_text_box" id="authorization_code" placeholder="Authorization Code" onChange={handleChange} value={submit.authorization_code} name="Authorization Code" type="text" required />
                            </li>
                        </ul>

                        <ul class="w3_payment_sendbtns">
                            <li><input type="reset" data-dismiss="modal" aria-label="Close" onClick={reset} value="Reset" /></li>
                            <li>{show ? <input type="submit" value="Submit" /> : "Please Wait..."}</li>
                        </ul>
                        <div class="clearfix"> </div>
                    </form>
                </div>
            </div>
                :
                <>
                    <div id="small-dialog" class="m-3">
                        <div class="row p-1" style={{ float: "right" }}>
                            <a class="mr-3" href="/files/sell_products_sample.csv" download><button type="button" class="btn-sm" style={{ backgroundColor: "#337ab7", color: "white" }} onClick={() => setshowExcel(true)}>Download Sample For Sell Products</button></a>
                            <button type="button" class="btn-sm" style={{ backgroundColor: "#337ab7", color: "white" }} onClick={() => setshowExcel(false)}>Back</button>
                        </div>
                        <br />
                        <div class="card-body">
                            <div class="form-group justify-content-end row">
                                <div class="col-lg-12">
                                    <div class="form-control dropzone dropzone-default dropzone-primary dz-clickable h-auto px-4 py-2" style={{ background: "aquamarine", textAlign: "center" }}>
                                        <div class="dropzone-msg dz-message needsclick" style={{ borderStyle: "dashed", padding: "1rem", borderColor: "orange" }}>
                                            <input type="file" accept=".csv,.xlsx,.xls" onChange={handleFileUpload} />
                                            <span class="font-size-base"><i class="fas fa-cloud-upload-alt icon-md mr-1"></i>Drop files here or click.</span><br />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <DataTable
                            pagination
                            highlightOnHover
                            columns={columns}
                            data={data}
                        />

                        {data.length > 0 ? <> <div class="card-footer d-flex justify-content-end py-2">
                            <button class="btn btn-success mr-2" onClick={() => submitdata()}>Upload Data</button>
                            <div class="btn btn-secondary" role="button">Cancel</div>
                        </div></> : null
                        }

                        {ShowProgress ? <><h2 style={{ background: "darkorange", padding: "1rem", textAlign: "center", paddingBottom: "1rem", color: "white" }}>{num == data.length ? "Data Uploaded" : "Uploading Data"} - {num}/{data.length}</h2></> : ""}
                    </div>
                </>


            }
        </>


    );
}
export default UploadSellProducts;