import React, { useState, useEffect } from "react";
import { useNavigate, Link, useParams } from 'react-router-dom';
import Swal from 'sweetalert2'
import db from "./Firebase";
import { storage } from './Firebase';
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import Moment from 'moment';
import { extendMoment } from 'moment-range';

function WorkshopCode() {

    const moment = extendMoment(Moment);
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [show, setshow] = useState(true);
    const [addTrends, setAddTrends] = useState(false);
    const [progresspercent, setProgresspercent] = useState(0);

    const [editID, setEditID] = useState("");
    const [languageID, setLanguageID] = useState("english");

    const [searchQuery, setSearchQuery] = useState("");
    const [availabilityFilter, setAvailabilityFilter] = useState("all");


    useEffect(() => {
        setLanguageID("english")
        db.collection("certificate_code").orderBy("created_date", "desc").onSnapshot((snapshot) => {
            setData(
                snapshot.docs.map((doc) => ({
                    id: doc.id,
                    data: doc.data(),
                }))
            );
        });

    }, []);

    // Step 3: Filter data based on search query and availability filter
    const filteredData = data.filter((record) => {
        return (
            record.data.name.toLowerCase().includes(searchQuery.toLowerCase()) &&
            (availabilityFilter === "all" || (availabilityFilter === "available" && record.data.recorded_session) || (availabilityFilter === "notAvailable" && !record.data.recorded_session))
        );
    });


    // // Step 3: Filter data based on search query
    // const filteredData = data.filter((record) => {
    //     return record.data.name.toLowerCase().includes(searchQuery.toLowerCase());
    // });

    const handleSearch = (e) => {
        setSearchQuery(e.target.value); // Step 3: Update search query state
    }

    const handleAvailabilityFilter = (e) => {
        setAvailabilityFilter(e.target.value); // Step 2: Update availability filter state
    }

    const change = () => {
        setLanguageID("hindi")
        db.collection("workshop_event_data_hindi").orderBy("created_date", "desc").onSnapshot((snapshot) => {

            setData(
                snapshot.docs.map((doc) => ({
                    id: doc.id,
                    data: doc.data(),
                }))
            );
        });
    }

    const changeE = () => {
        setLanguageID("english")
        db.collection("workshop_event_data").orderBy("created_date", "desc").onSnapshot((snapshot) => {

            setData(
                snapshot.docs.map((doc) => ({
                    id: doc.id,
                    data: doc.data(),
                }))
            );
        });
    }


    const addCode = async () => {
        const { value: formValues } = await Swal.fire({
            title: 'Add Workshop Code',
            html:
                '<input id="swal-input1" class="swal2-input" placeholder="Enter Workshop Name">' +
                '<input id="swal-input2" class="swal2-input" placeholder="Enter Workshop Code">',
            focusConfirm: false,
            preConfirm: () => {
                return [
                    document.getElementById('swal-input1').value,
                    document.getElementById('swal-input2').value
                ];
            }
        });

        if (formValues) {
            const [name, code] = formValues;
            if (name && code) {
                // Add the code to Firestore
                db.collection("certificate_code").add({
                    name: name,
                    code: code,
                    created_date: new Date()
                })
                    .then((docRef) => {
                        console.log("Document written with ID: ", docRef.id);
                    })
                    .catch((error) => {
                        console.error("Error adding document: ", error);
                    });
            } else {
                Swal.fire('Both fields are required', '', 'error');
            }
        }
    };


    const changeStatusH = async (uid) => {

        const { value: text } = await Swal.fire({
            input: 'text',
            inputLabel: 'Update Workshop Pin',
            inputPlaceholder: 'Enter Pin id here...'
        })

        if (text) {
            // Swal.fire(`Entered text: ${text}`)
            db.collection("workshop_event_data_hindi").doc(uid).update({ 'pin': parseInt(text), }).then(() => {
                console.log("video added");
            })
        }

    }




    return (
        <>
            <div class="p-2" style={{ paddingBottom: "5px", display: "flex", justifyContent: "space-between" }}>
                {/* <span class="btn bg-gradient-secondary btn-sm mb-0 mr-2" style={{ color: "white" }} >&nbsp; Download Excel</span> */}
                <Link to="/admin_panel"><img src="/images/menubutton.png" class="menu-toggle" alt="Responsive image" style={{ height: "2rem" }} /></Link>
                {/* {languageID === "english" ?
                    <>
                    </>
                    :
                    <>
                    <div class="" style={{ float: "right" }}><button type="button" class="btn btn-sm" style={{ backgroundColor: "cadetblue", color: "white", marginRight: "1rem", marginBottom: "10px" }} onClick={() => changeE()}>Go To Workshop English</button></div>
                </>} */}
                <div class="" style={{ float: "right" }}><button type="button" class="btn btn-sm" style={{ backgroundColor: "cadetblue", color: "white", marginRight: "1rem", marginBottom: "10px" }} onClick={() => addCode()}>Add Workshop Code</button></div>
            </div>
            <div class="p-3">


                <input
                    type="text"
                    className="form-control form-control-sm mb-2"
                    placeholder="Search by Workshop Name"
                    value={searchQuery}
                    onChange={handleSearch}
                />


                <div class="table-responsive fix-width scroll-inner">
                    <table class="table table-bordered table-hover">
                        <thead class="thead-dark" style={{ position: "sticky", top: "0" }}>
                            <tr>
                                <th>Sr. No</th>
                                <th>Workshop Name</th>
                                <th>created_date</th>
                                <th>Pin</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredData?.map(({ id, data }, index) => (
                                <>
                                    <tr>
                                        <td>{index + 1}</td>
                                        <td>{data.name}</td>
                                        <td>{moment(data.created_date.toDate().toISOString()).format("MM/DD/YY, h:mm a")}</td>
                                        <td>{data.code}</td>
                                    </tr>
                                </>
                            ))}


                        </tbody>
                    </table>
                </div>
            </div>



        </>
    )
}

export default WorkshopCode
