import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import db from "./Firebase";
import { CSVLink } from "react-csv";
import Moment from 'moment';
import { extendMoment } from 'moment-range';

function WorkshopPayment() {
    const navigate = useNavigate();
    const moment = extendMoment(Moment);
    const [data, setData] = useState([]);
    const [csvdata, setCsvdata] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [phoneToUserDetails, setPhoneToUserDetails] = useState({});

    const headers = [
        { label: "Payment Id", key: "paymentId" },
        { label: "User Name", key: "userName" },
        { label: "Phone Number", key: "userId" },
        { label: "Order Id", key: "order_id" },
        { label: "Status", key: "status" },
        { label: "Workshop Event Name", key: "sessionId" },
        { label: "Created Date", key: "created_date", dataFormat: "MM/DD/YY, h:mm a" },
    ];

    useEffect(() => {
        const storedUsername = localStorage.getItem("email");
        const storedPassword = localStorage.getItem("password");
        const storedRemember = localStorage.getItem("remember");

        if (storedRemember === "false" || !storedUsername || !storedPassword) {
            navigate('/signin');
        }
    }, [navigate]);

    useEffect(() => {
        const fetchUserData = async () => {
            const userDetailsSnapshot = await db.collection("personal_details").get();
            const phoneToUserDetails = {};
            userDetailsSnapshot.forEach(doc => {
                const data = doc.data();
                phoneToUserDetails[data.phone_no] = { name: data.full_name, phone_no: data.phone_no };
            });
            setPhoneToUserDetails(phoneToUserDetails);
        };

        const fetchPaymentData = async () => {
            const snapshot = await db.collection("payments").orderBy("timestamp", "desc").get();
            const payments = snapshot.docs.map((doc) => ({
                id: doc.id,
                data: doc.data(),
            }));
            setData(payments);
        };

        fetchUserData();
        fetchPaymentData();
    }, []);

    useEffect(() => {
        const csvDataFormatted = data.map((doc) => {
            const data = doc.data;
            return {
                ...data,
                userName: phoneToUserDetails[data.userId]?.name || "N/A",
                created_date: moment(data.timestamp.toDate().toISOString()).format("MM/DD/YY, h:mm a"),
            };
        });
        setCsvdata(csvDataFormatted);
    }, [data, phoneToUserDetails, moment]);

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const filteredData = data.filter((item) => {
        const userId = item.data.userId ? item.data.userId.toLowerCase() : '';
        const sessionId = item.data.sessionId ? item.data.sessionId.toLowerCase() : '';
        const query = searchQuery.toLowerCase();

        return (
            userId.includes(query) ||
            sessionId.includes(query)
        );
    });

    return (
        <>
            <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
                <div className="p-2" style={{ paddingBottom: "5px", display: "flex", justifyContent: "space-between" }}>
                    <Link to="/admin_panel"><img src="/images/menubutton.png" className="menu-toggle" alt="Responsive image" style={{ height: "2rem" }} /></Link>
                    <CSVLink data={csvdata} headers={headers}>
                        <span className="btn bg-gradient-secondary btn-sm mb-0 mr-2" style={{ color: "white" }} >&nbsp; Download Excel</span>
                    </CSVLink>
                </div>
                <div className="">
                    <div className="p-2">
                        <div style={{ textAlign: "end", paddingBottom: "5px" }}>
                        </div>
                        <div className="card">
                            <div className="card-header pb-0 px-3">
                                <h6 className="mb-2">Workshop Payment || {data.length}</h6>
                            </div>
                            <input
                                type="text"
                                className="form-control form-control-sm"
                                placeholder="Search by Phone, Workshop Name"
                                value={searchQuery}
                                onChange={handleSearchChange}
                            />
                            <div className="">
                                <div className="card">
                                    <div className="table-responsive fix-width scroll-inner">
                                        <table className="table table-bordered table-hover">
                                            <thead className="thead-dark" style={{ position: "sticky", top: "0" }}>
                                                <tr style={{ textAlign: "left" }}>
                                                    <th className="col ps-2">Workshop/Event Name</th>
                                                    <th className="col">Phone No</th>
                                                    <th className="col ps-2">Created Date</th>
                                                    <th className="col ps-2">Status</th>
                                                    <th className="col ps-2">Payment ID</th>
                                                </tr>
                                            </thead>
                                            <tbody style={{ textAlign: "left" }}>
                                                {filteredData.map(({ id, data }) => (
                                                    <tr key={id}>
                                                        <td>
                                                            <p className="text-xs font-weight-bold mb-0">{data.sessionId}</p>
                                                        </td>
                                                        <td>
                                                            <div className="d-flex px-2 py-1">
                                                                <div className="d-flex flex-column justify-content-center">
                                                                    <h6 className="mb-0 text-xs">
                                                                        {phoneToUserDetails[data.userId]?.name || data.userId}
                                                                    </h6>
                                                                    <span className="text-xs text-secondary mb-0">
                                                                        {phoneToUserDetails[data.userId]?.phone_no}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="d-flex px-2 py-1">
                                                                <div className="d-flex flex-column justify-content-center">
                                                                    <h6 className="mb-0 text-xs">{moment(data.timestamp.toDate().toISOString()).format("MM/DD/YY, h:mm a")}</h6>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="d-flex px-2 py-1">
                                                                <div className="d-flex flex-column justify-content-center">
                                                                    {data.status === "failure" ? (
                                                                        <h6 className="mb-0 text-xs" style={{ color: "red" }}>{data.status}</h6>
                                                                    ) : (
                                                                        <h6 className="mb-0 text-xs" style={{ color: "green" }}>{data.status}</h6>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="d-flex px-2 py-1">
                                                                <div className="d-flex flex-column justify-content-center">
                                                                    <h6 className="mb-0 text-xs">{data.paymentId}</h6>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
}

export default WorkshopPayment;



// import React, { useState, useEffect } from 'react';
// import { useNavigate, Link } from 'react-router-dom';
// import db from "./Firebase";
// import { CSVLink } from "react-csv";
// import Moment from 'moment';
// import { extendMoment } from 'moment-range';

// function WorkshopPayment() {
//     const navigate = useNavigate();
//     const moment = extendMoment(Moment);
//     const [data, setData] = useState([]);
//     const [csvdata, setCsvdata] = useState([]);
//     const [searchQuery, setSearchQuery] = useState("");
//     const [phoneToUserDetails, setPhoneToUserDetails] = useState({});

//     const headers = [
//         { label: "Payment Id", key: "paymentId" },
//         { label: "User Name", key: "userName" },
//         { label: "Phone Number", key: "userId" },
//         { label: "Order Id", key: "order_id" },
//         { label: "Status", key: "status" },
//         { label: "Workshop Event Name", key: "sessionId" },
//         { label: "Created Date", key: "created_date", dataFormat: "MM/DD/YY, h:mm a" },
//     ];

//     useEffect(() => {
//         const storedUsername = localStorage.getItem("email");
//         const storedPassword = localStorage.getItem("password");
//         const storedRemember = localStorage.getItem("remember");

//         if (storedRemember === "false" || !storedUsername || !storedPassword) {
//             navigate('/signin');
//         }
//     }, [navigate]);

//     useEffect(() => {
//         const fetchUserData = async () => {
//             const userDetailsSnapshot = await db.collection("personal_details").get();
//             const phoneToUserDetails = {};
//             userDetailsSnapshot.forEach(doc => {
//                 const data = doc.data();
//                 phoneToUserDetails[data.phone_no] = { name: data.full_name, phone_no: data.phone_no };
//             });
//             setPhoneToUserDetails(phoneToUserDetails);
//         };

//         const fetchPaymentData = async () => {
//             const snapshot = await db.collection("payments").orderBy("timestamp", "desc").get();
//             const payments = snapshot.docs.map((doc) => ({
//                 id: doc.id,
//                 data: doc.data(),
//             }));
//             setData(payments);

//             const csvDataFormatted = payments.map((doc) => {
//                 const data = doc.data();
//                 return {
//                     ...data,
//                     userName: phoneToUserDetails[data.userId]?.name || "N/A",
//                     created_date: moment(data.timestamp.toDate().toISOString()).format("MM/DD/YY, h:mm a"),
//                 };
//             });

//             setCsvdata(csvDataFormatted);
//         };

//         fetchUserData();
//         fetchPaymentData();
//     }, [phoneToUserDetails]);

//     const handleSearchChange = (event) => {
//         setSearchQuery(event.target.value);
//     };

//     const filteredData = data.filter((item) => {
//         const userId = item.data.userId ? item.data.userId.toLowerCase() : '';
//         const sessionId = item.data.sessionId ? item.data.sessionId.toLowerCase() : '';
//         const query = searchQuery.toLowerCase();

//         return (
//             userId.includes(query) ||
//             sessionId.includes(query)
//         );
//     });

//     return (
//         <>
//             <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
//                 <div className="p-2" style={{ paddingBottom: "5px", display: "flex", justifyContent: "space-between" }}>
//                     <Link to="/admin_panel"><img src="/images/menubutton.png" className="menu-toggle" alt="Responsive image" style={{ height: "2rem" }} /></Link>
//                     <CSVLink data={csvdata} headers={headers}>
//                         <span className="btn bg-gradient-secondary btn-sm mb-0 mr-2" style={{ color: "white" }} >&nbsp; Download Excel</span>
//                     </CSVLink>
//                 </div>
//                 <div className="">
//                     <div className="p-2">
//                         <div style={{ textAlign: "end", paddingBottom: "5px" }}>
//                         </div>
//                         <div className="card">
//                             <div className="card-header pb-0 px-3">
//                                 <h6 className="mb-2">Workshop Payment || {data.length}</h6>
//                             </div>
//                             <input
//                                 type="text"
//                                 className="form-control form-control-sm"
//                                 placeholder="Search by Phone, Workshop Name"
//                                 value={searchQuery}
//                                 onChange={handleSearchChange}
//                             />
//                             <div className="">
//                                 <div className="card">
//                                     <div className="table-responsive fix-width scroll-inner">
//                                         <table className="table table-bordered table-hover">
//                                             <thead className="thead-dark" style={{ position: "sticky", top: "0" }}>
//                                                 <tr style={{ textAlign: "left" }}>
//                                                     <th className="col ps-2">Workshop/Event Name</th>
//                                                     <th className="col">Phone No</th>
//                                                     <th className="col ps-2">Created Date</th>
//                                                     <th className="col ps-2">Status</th>
//                                                     <th className="col ps-2">Payment ID</th>
//                                                 </tr>
//                                             </thead>
//                                             <tbody style={{ textAlign: "left" }}>
//                                                 {filteredData.map(({ id, data }) => (
//                                                     <tr key={id}>
//                                                         <td>
//                                                             <p className="text-xs font-weight-bold mb-0">{data.sessionId}</p>
//                                                         </td>
//                                                         <td>
//                                                             <div className="d-flex px-2 py-1">
//                                                                 <div className="d-flex flex-column justify-content-center">
//                                                                     <h6 className="mb-0 text-xs">
//                                                                         {phoneToUserDetails[data.userId]?.name || data.userId}
//                                                                     </h6>
//                                                                     <span className="text-xs text-secondary mb-0">
//                                                                         {phoneToUserDetails[data.userId]?.phone_no}
//                                                                     </span>
//                                                                 </div>
//                                                             </div>
//                                                         </td>
//                                                         <td>
//                                                             <div className="d-flex px-2 py-1">
//                                                                 <div className="d-flex flex-column justify-content-center">
//                                                                     <h6 className="mb-0 text-xs">{moment(data.timestamp.toDate().toISOString()).format("MM/DD/YY, h:mm a")}</h6>
//                                                                 </div>
//                                                             </div>
//                                                         </td>
//                                                         <td>
//                                                             <div className="d-flex px-2 py-1">
//                                                                 <div className="d-flex flex-column justify-content-center">
//                                                                     {data.status === "failure" ? (
//                                                                         <h6 className="mb-0 text-xs" style={{ color: "red" }}>{data.status}</h6>
//                                                                     ) : (
//                                                                         <h6 className="mb-0 text-xs" style={{ color: "green" }}>{data.status}</h6>
//                                                                     )}
//                                                                 </div>
//                                                             </div>
//                                                         </td>
//                                                         <td>
//                                                             <div className="d-flex px-2 py-1">
//                                                                 <div className="d-flex flex-column justify-content-center">
//                                                                     <h6 className="mb-0 text-xs">{data.paymentId}</h6>
//                                                                 </div>
//                                                             </div>
//                                                         </td>
//                                                     </tr>
//                                                 ))}
//                                             </tbody>
//                                         </table>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </main>
//         </>
//     );
// }

// export default WorkshopPayment;



// // import React, { useState, useEffect } from 'react';
// // import { useNavigate, Link, useParams } from 'react-router-dom';
// // import db from "./Firebase";
// // import { arrayUnion } from "firebase/firestore";
// // import { storage } from './Firebase';
// // import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
// // import Moment from 'moment';
// // import { extendMoment } from 'moment-range';
// // import ViewAllRequests from './ViewAllRequests';
// // import { CSVLink } from "react-csv";

// // function WorkshopPayment() {
// //     const navigate = useNavigate();
// //     const moment = extendMoment(Moment);
// //     const [data, setData] = useState([]);
// //     const [uid, setUid] = useState('');
// //     const [name, setName] = useState('');
// //     const [phone, setPhone] = useState('');
// //     const [uuid, setUuid] = useState('');
// //     const [date, setDate] = useState('');
// //     const [location, setLocation] = useState('');
// //     const [wname, setWname] = useState('');
// //     const [csvdata, setCsvdata] = useState([]);
// //     const [searchQuery, setSearchQuery] = useState("");

// //     const headers = [
// //         { label: "Payment Id", key: "paymentId" },
// //         { label: "Phone Number", key: "userId" },
// //         { label: "order_id", key: "order_id" },
// //         { label: "statusn", key: "status" },
// //         { label: "Workshop Event Name", key: "sessionId" },
// //         { label: "Created Date", key: "timestamp", dataFormat: "MM/DD/YY, h:mm a" },

// //     ];

// //     useEffect(() => {
// //         const storedUsername = localStorage.getItem("email");
// //         const storedPassword = localStorage.getItem("password");
// //         const storedRemember = localStorage.getItem("remember");

// //         if (storedRemember === "false" || !storedUsername || !storedPassword) {
// //             navigate('/signin');
// //         }
// //     }, []);

// //     useEffect(() => {
// //         db.collection("payments").orderBy("timestamp", "desc").onSnapshot((snapshot) => {

// //             setData(
// //                 snapshot.docs.map((doc) => ({
// //                     id: doc.id,
// //                     data: doc.data(),
// //                 }))
// //             );

// //             setCsvdata(
// //                 snapshot.docs.map((doc) => {
// //                     const data = doc.data();
// //                     return {
// //                         ...data,
// //                         created_date: moment(data.timestamp.toDate().toISOString()).format("MM/DD/YY, h:mm a"),
// //                     };
// //                 })
// //             );
// //         });

// //     }, []);

// //     const handleSearchChange = (event) => {
// //         setSearchQuery(event.target.value);
// //     };

// //     const filteredData = data.filter((item) => {
// //         const userId = item.data.userId ? item.data.userId.toLowerCase() : '';
// //         const sessionId = item.data.sessionId ? item.data.sessionId.toLowerCase() : '';
// //         const query = searchQuery.toLowerCase();

// //         return (
// //             userId.includes(query) ||
// //             sessionId.includes(query)
// //         );
// //     });


// //     const notifyApp = (msg) => {
// //         db.collection("app_notification").add({ 'message': msg, 'link': "noti", 'status': "0", "uid": uid, 'created_date': new Date() }).then(() => {
// //             console.log("notified");
// //         })
// //     }

// //     const initialFormStateFeedback = { status: '' }
// //     const [submit, setSubmit] = useState(initialFormStateFeedback)


// //     return (
// //         <>
// //             <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
// //                 <div class="p-2" style={{ paddingBottom: "5px", display: "flex", justifyContent: "space-between" }}>
// //                     <Link to="/admin_panel"><img src="/images/menubutton.png" class="menu-toggle" alt="Responsive image" style={{ height: "2rem" }} /></Link>
// //                     <CSVLink data={csvdata} headers={headers}>
// //                         <span class="btn bg-gradient-secondary btn-sm mb-0 mr-2" style={{ color: "white" }} >&nbsp; Download Excel</span>
// //                     </CSVLink>
// //                 </div>
// //                 <div class="">


// //                     <div class="p-2">
// //                         <div style={{ textAlign: "end", paddingBottom: "5px" }}>
// //                         </div>
// //                         <div class="card">
// //                             <div class="card-header pb-0 px-3">
// //                                 <h6 class="mb-2">Workshop Payment || {data.length}</h6>
// //                             </div>
// //                             <input
// //                                 type="text"
// //                                 className="form-control form-control-sm"
// //                                 placeholder="Search by Phone, Workshop Name"
// //                                 value={searchQuery}
// //                                 onChange={handleSearchChange}
// //                             />
// //                             <div class="">
// //                                 <div class="card">
// //                                     <div class="table-responsive fix-width scroll-inner">
// //                                         <table class="table table-bordered table-hover">
// //                                             <thead class="thead-dark" style={{ position: "sticky", top: "0" }}>
// //                                                 <tr style={{ textAlign: "left" }}>
// //                                                     <th class="col ps-2">Workshop/Event Name</th>
// //                                                     <th class="col">Phone No</th>
// //                                                     <th class="col ps-2">Created Date</th>
// //                                                     <th class="col ps-2">Status</th>
// //                                                     <th class="col ps-2">Payment ID</th>
// //                                                 </tr>
// //                                             </thead>
// //                                             <tbody style={{ textAlign: "left" }}>
// //                                                 {filteredData?.map(({ id, data }, index) => (
// //                                                     <>
// //                                                         <tr>
// //                                                             <td>
// //                                                                 <p class="text-xs font-weight-bold mb-0">{data.sessionId}</p>
// //                                                             </td>
// //                                                             <td>
// //                                                                 <div class="d-flex px-2 py-1">
// //                                                                     <div class="d-flex flex-column justify-content-center">
// //                                                                         <h6 class="mb-0 text-xs">{data.userId}</h6>
// //                                                                     </div>
// //                                                                 </div>
// //                                                             </td>



// //                                                             <td>
// //                                                                 <div class="d-flex px-2 py-1">
// //                                                                     <div class="d-flex flex-column justify-content-center">
// //                                                                         <h6 class="mb-0 text-xs">{moment(data.timestamp.toDate().toISOString()).format("MM/DD/YY, h:mm a")}</h6>
// //                                                                     </div>
// //                                                                 </div>
// //                                                             </td>
// //                                                             <td>
// //                                                                 <div class="d-flex px-2 py-1">
// //                                                                     <div class="d-flex flex-column justify-content-center">
// //                                                                         {data.status === "failure" ? <> <h6 class="mb-0 text-xs" style={{ color: "red" }}>{data.status}</h6></> : <> <h6 class="mb-0 text-xs" style={{ color: "green" }}>{data.status}</h6></>}

// //                                                                     </div>
// //                                                                 </div>
// //                                                             </td>
// //                                                             <td>
// //                                                                 <div class="d-flex px-2 py-1">
// //                                                                     <div class="d-flex flex-column justify-content-center">
// //                                                                         <h6 class="mb-0 text-xs">{data.paymentId}</h6>
// //                                                                     </div>
// //                                                                 </div>
// //                                                             </td>

// //                                                             <td></td>

// //                                                         </tr>

// //                                                     </>
// //                                                 ))}


// //                                             </tbody>
// //                                         </table>
// //                                     </div>
// //                                 </div>
// //                             </div>
// //                         </div>
// //                     </div>

// //                 </div>

// //             </main>


// //         </>
// //     )
// // }

// // export default WorkshopPayment;