import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import db from "./Firebase";
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import { CSVLink } from "react-csv";

function AllCertificates() {
    const navigate = useNavigate();
    const moment = extendMoment(Moment);
    const [data, setData] = useState([]);
    const [csvdata, setCsvdata] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [lastVisible, setLastVisible] = useState(null);
    const [hasMore, setHasMore] = useState(true);

    useEffect(() => {
        const storedUsername = localStorage.getItem("email");
        const storedPassword = localStorage.getItem("password");
        const storedRemember = localStorage.getItem("remember");

        if (storedRemember === "false" || !storedUsername || !storedPassword) {
            navigate('/signin');
        }
    }, []);

    const headers = [
        "Full Name",
        "Username",
        "Phone No",
        "Workshop",
        "Query",
        "Status",
        "Updated Date",
        "Created Date",
    ];

    useEffect(() => {
        const fetchData = async () => {
            const snapshot = await db.collection("all_certificates")
                .orderBy("created_date", "desc")
                .limit(20)
                .get();

            const lastVisible = snapshot.docs[snapshot.docs.length - 1];
            setLastVisible(lastVisible);

            setData(snapshot.docs.map((doc) => ({
                id: doc.id,
                data: doc.data(),
            })));
        };

        fetchData();
    }, []);

    const loadMoreData = async () => {
        const snapshot = await db.collection("all_certificates")
            .orderBy("created_date", "desc")
            .startAfter(lastVisible)
            .limit(20)
            .get();

        const lastVisible = snapshot.docs[snapshot.docs.length - 1];
        setLastVisible(lastVisible);

        if (snapshot.docs.length < 20) {
            setHasMore(false);
        }

        setData((prevData) => [
            ...prevData,
            ...snapshot.docs.map((doc) => ({
                id: doc.id,
                data: doc.data(),
            })),
        ]);
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const filteredData = data.filter((item) => {
        const user_name = item.data.user_name.toLowerCase();
        const name = item.data.name.toLowerCase();
        const phone_no = item.data.phone_no.toLowerCase();
        const type = item.data.type.toLowerCase();
        const query = searchQuery.toLowerCase();

        return (
            user_name.includes(query) ||
            name.includes(query) ||
            phone_no.includes(query) ||
            type.includes(query)
        );
    });

    const prepareCSVData = () => {
        const csvData = filteredData.map((item) => ([
            item.data.name,
            item.data.user_name,
            item.data.phone_no,
            item.data.type,
            item.data.query,
            moment(item.data.created_date.toDate().toISOString()).format("MM/DD/YY, h:mm a"),
        ]));

        return csvData;
    };

    const changeStatus = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                db.collection("all_certificates").doc(id).delete().then(() => {
                    console.log('Deleted Successfully');
                    Swal.fire(
                        'Deleted!',
                        'Data has been deleted.',
                        'success'
                    );
                });
            }
        });
    };

    return (
        <>
            <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg">
                <div class="p-2" style={{ paddingBottom: "5px", display: "flex", justifyContent: "space-between" }}>
                    <Link to="/admin_panel">
                        <img src="/images/menubutton.png" class="menu-toggle" alt="Responsive image" style={{ height: "2rem" }} />
                    </Link>
                    <CSVLink data={prepareCSVData()} headers={headers} filename={"all_certificates.csv"}>
                        <span class="btn bg-gradient-secondary btn-sm mb-0 mr-2" style={{ color: "white" }}>
                            &nbsp; Download Excel
                        </span>
                    </CSVLink>
                </div>
                <div class="">
                    <div class="p-2">
                        <div class="card">
                            <div class="card-header pb-0 px-3">
                                <h6 class="mb-2">All Certificates || {`Records : ${filteredData.length}`}</h6>
                            </div>
                            <input
                                type="text"
                                className="form-control form-control-sm"
                                placeholder="Search by Name, Username, Phone, Workshop, or Status"
                                value={searchQuery}
                                onChange={handleSearchChange}
                            />
                            <div class="card">
                                <div class="table-responsive fix-width scroll-inner">
                                    <table class="table table-bordered table-hover">
                                        <thead class="thead-dark" style={{ position: "sticky", top: "0" }}>
                                            <tr style={{ textAlign: "left" }}>
                                                <th class="col ps-2">S.No</th>
                                                <th class="col ps-2">User Name</th>
                                                <th class="col">Name</th>
                                                <th class="col ps-2">Phone No</th>
                                                <th class="col ps-2">type</th>
                                                <th class="col ps-2">link</th>
                                                <th class="col ps-2">Created Date</th>
                                                <th class="col ps-2">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody style={{ textAlign: "left" }}>
                                            {filteredData.map((item, index) => (
                                                <tr key={item.id}>
                                                    <td>
                                                        <p class="text-xs mb-0">{index + 1}.</p>
                                                    </td>
                                                    <td>
                                                        <p class="text-xs mb-0">{item.data.user_name}</p>
                                                    </td>
                                                    <td>
                                                        <p class="text-xs mb-0">{item.data.name}</p>
                                                    </td>
                                                    <td>
                                                        <p class="text-xs mb-0">{item.data.phone_no}</p>
                                                    </td>
                                                    <td>
                                                        <p class="text-xs mb-0">{item.data.type}</p>
                                                    </td>
                                                    <td style={{ whiteSpace: 'pre-wrap' }}>
                                                        <p class="text-xs font-weight-bold mb-0" style={{ width: "15rem" }}>{item.data.link}</p>
                                                    </td>
                                                    <td>
                                                        <div class="d-flex px-2 py-1">
                                                            <div class="d-flex flex-column justify-content-center">
                                                                <h6 class="mb-0 text-xs">{moment(item.data.created_date.toDate().toISOString()).format("MM/DD/YY, h:mm a")}</h6>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td class="align-middle text-center" onClick={() => changeStatus(item.id)}>
                                                        <span class="text-danger text-xs font-weight-normal" style={{ cursor: "pointer" }}>Delete</span>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    {hasMore && (
                                        <div className="" style={{ paddingTop: "2rem" }}>
                                            <button onClick={loadMoreData} className="btn btn-dark">Load More</button>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
}

export default AllCertificates;


// import React, { useState, useEffect } from 'react';
// import { useNavigate, Link, useParams } from 'react-router-dom';
// import Swal from 'sweetalert2'
// import db from "./Firebase";
// import { arrayUnion } from "firebase/firestore";
// import { storage } from './Firebase';
// import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
// import Moment from 'moment';
// import { extendMoment } from 'moment-range';
// import ViewAllRequests from './ViewAllRequests';
// import ViewAppData from './ViewAppData';
// import { CSVLink } from "react-csv";

// function AllCertificates() {
//     const navigate = useNavigate();
//     const moment = extendMoment(Moment);
//     const [data, setData] = useState([]);
//     const [csvdata, setCsvdata] = useState([]);
//     const [uid, setUid] = useState('');
//     const [searchQuery, setSearchQuery] = useState("");

//     useEffect(() => {
//         const storedUsername = localStorage.getItem("email");
//         const storedPassword = localStorage.getItem("password");
//         const storedRemember = localStorage.getItem("remember");

//         if (storedRemember === "false" || !storedUsername || !storedPassword) {
//             navigate('/signin');
//         }
//     }, []);

//     const headers = [
//         "Full Name",
//         "Username",
//         "Phone No",
//         "Workshop",
//         "Query",
//         "Status",
//         "Updated Date",
//         "Created Date",
//         // Add more column labels as needed
//     ];

//     useEffect(() => {

//         db.collection("all_certificates").orderBy("created_date", "desc").onSnapshot((snapshot) => {
//             setData(
//                 snapshot.docs.map((doc) => ({
//                     id: doc.id,
//                     data: doc.data(),
//                 }))
//             );
//         });

//     }, []);

//     const handleSearchChange = (event) => {
//         setSearchQuery(event.target.value);
//     };


//     // Function to filter data based on the search query
//     const filteredData = data.filter((item) => {
//         const user_name = item.data.user_name.toLowerCase();
//         const name = item.data.name.toLowerCase();
//         const phone_no = item.data.phone_no.toLowerCase();
//         const type = item.data.type.toLowerCase();
//         const query = searchQuery.toLowerCase();

//         return (
//             user_name.includes(query) ||
//             name.includes(query) ||
//             phone_no.includes(query) ||
//             type.includes(query)
//         );
//     });

//     // Function to convert data to CSV format
//     const prepareCSVData = () => {
//         const csvData = filteredData.map((item, index) => ([
//             item.data.name,
//             item.data.user_name,
//             item.data.phone_no,
//             item.data.type,
//             item.data.query,
//             moment(item.data.created_date.toDate().toISOString()).format("MM/DD/YY, h:mm a"),
//             // Add more columns as needed
//         ]));

//         return csvData;
//     };




//     const changeStatus = (id) => {

//         Swal.fire({
//             title: 'Are you sure?',
//             text: "You won't be able to revert this!",
//             icon: 'warning',
//             showCancelButton: true,
//             confirmButtonColor: '#3085d6',
//             cancelButtonColor: '#d33',
//             confirmButtonText: 'Yes, delete it!'
//         }).then((result) => {
//             if (result.isConfirmed) {
//                 db.collection("all_certificates").doc(id).delete().then(() => {
//                     console.log('Deleted Successfully');
//                     Swal.fire(
//                         'Deleted!',
//                         'Data has been deleted.',
//                         'success'
//                     )
//                 })
//             }
//         })
//     }



//     return (
//         <>
//             <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg">

//                 <div class="p-2" style={{ paddingBottom: "5px", display: "flex", justifyContent: "space-between" }}>
//                     <Link to="/admin_panel">
//                         <img src="/images/menubutton.png" class="menu-toggle" alt="Responsive image" style={{ height: "2rem" }} />
//                     </Link>
//                     <CSVLink data={prepareCSVData()} headers={headers} filename={"all_certificates.csv"}>
//                         <span class="btn bg-gradient-secondary btn-sm mb-0 mr-2" style={{ color: "white" }}>
//                             &nbsp; Download Excel
//                         </span>
//                     </CSVLink>
//                 </div>
//                 <div class="">

//                     <div class="p-2">

//                         <div class="card">
//                             <div class="card-header pb-0 px-3">
//                                 <h6 class="mb-2">All Certificates || {`Records : ${filteredData.length}`}</h6>
//                             </div>
//                             <input
//                                 type="text"
//                                 className="form-control form-control-sm"
//                                 placeholder="Search by Name, Username, Phone, Workshop, or Status"
//                                 value={searchQuery}
//                                 onChange={handleSearchChange}
//                             />
//                             <div class="card">
//                                 <div class="table-responsive fix-width scroll-inner">
//                                     <table class="table table-bordered table-hover">
//                                         <thead class="thead-dark" style={{ position: "sticky", top: "0" }}>
//                                             <tr style={{ textAlign: "left" }}>
//                                                 <th class="col ps-2">S.No</th>
//                                                 <th class="col ps-2">User Name</th>
//                                                 <th class="col">Name</th>
//                                                 <th class="col ps-2">Phone No</th>
//                                                 <th class="col ps-2">type</th>
//                                                 <th class="col ps-2">link</th>
//                                                 <th class="col ps-2">Created Date</th>
//                                                 <th class="col ps-2">Action</th>
//                                             </tr>
//                                         </thead>
//                                         <tbody style={{ textAlign: "left" }}>
//                                             {filteredData.map((item, index) => (
//                                                 <tr key={item.id}>
//                                                     <td>
//                                                         <p class="text-xs mb-0">{index + 1}.</p>
//                                                     </td>
//                                                     <td>
//                                                         <p class="text-xs mb-0">{item.data.user_name}</p>
//                                                     </td>
//                                                     <td>
//                                                         <p class="text-xs mb-0">{item.data.name}</p>
//                                                     </td>
//                                                     <td>
//                                                         <p class="text-xs mb-0">{item.data.phone_no}</p>
//                                                     </td>
//                                                     <td>
//                                                         <p class="text-xs mb-0">{item.data.type}</p>
//                                                     </td>
//                                                     <td style={{ whiteSpace: 'pre-wrap' }}>
//                                                         <p class="text-xs font-weight-bold mb-0" style={{ width: "15rem" }}>{item.data.link}</p>

//                                                     </td>


//                                                     <td>
//                                                         <div class="d-flex px-2 py-1">
//                                                             <div class="d-flex flex-column justify-content-center">
//                                                                 <h6 class="mb-0 text-xs">{moment(item.data.created_date.toDate().toISOString()).format("MM/DD/YY, h:mm a")}</h6>
//                                                             </div>
//                                                         </div>
//                                                     </td>

//                                                     <td class="align-middle text-center" onClick={() => changeStatus(item.id)}>
//                                                         <span class="text-danger text-xs font-weight-normal" style={{ cursor: "pointer" }}>Delete</span>
//                                                     </td>


//                                                     {/* Other table cells */}
//                                                 </tr>
//                                             ))}
//                                         </tbody>
//                                     </table>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </main>
//         </>
//     );
// }

// export default AllCertificates;
