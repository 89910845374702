import React, { useState, useEffect } from 'react';
import { useNavigate, Link, useParams } from 'react-router-dom';
import db from "./Firebase";
import { arrayUnion } from "firebase/firestore";
import { storage } from './Firebase';
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import Footer from './Footer';
import ReactStars from "react-rating-stars-component";

function CustomerReview() {
    let params = useParams();
    const moment = extendMoment(Moment);
    const [data, setData] = useState([]);
    const [personalData, setPersonalData] = useState({});
    const [noData, setNoData] = useState("")
    const [index, setIndex] = useState(0)
    const [isLoading, setIsLoading] = useState(true);
    const [rating, setRating] = useState(0);

    const ratingExample = {
        size: 25,
        value: rating,
        edit: false,
        isHalf: true
    };

    const [showOnlyTen, setShowOnlyTen] = useState(true);
    const [visibleReviews, setVisibleReviews] = useState(10); // Initially, display 10 reviews

    // Function to toggle the "Show Only 10 Reviews Every Time" option
    const toggleShowOnlyTen = () => {
        setShowOnlyTen(!showOnlyTen);
        setVisibleReviews(10); // Reset visible reviews when toggling
    };

    // Function to show next 10 reviews
    const showNextReviews = () => {
        setVisibleReviews((prevVisible) => prevVisible + 10);
    };


    useEffect(() => {
        db.collection("portfolio_details").where('username', '==', params.id.toLowerCase()).onSnapshot((snapshot) => {
            snapshot.docs.map(e => setPersonalData(e.data()))
            if (snapshot.docs.length == 0) {
                // navigate('/error');
            } else {
                // alert("exists")
                setIsLoading(false);
            }
        });
        db.collection("parlour_feedback").where('username', '==', params.id.toLowerCase()).orderBy("created_date", "desc").onSnapshot((snapshot) => {

            setData(
                snapshot.docs.map((doc) => ({
                    id: doc.id,
                    data: doc.data(),
                }))
            );
        });

        db.collection("parlour_feedback").where('username', '==', params.id.toLowerCase()).orderBy("created_date", "desc").onSnapshot((snapshot) => {
            let _5star = snapshot.docs.filter(r => r.data().rating == 5).length;
            let _4star = snapshot.docs.filter(r => r.data().rating == 4).length;
            let _3star = snapshot.docs.filter(r => r.data().rating == 3).length;
            let _2star = snapshot.docs.filter(r => r.data().rating == 2).length;
            let _1star = snapshot.docs.filter(r => r.data().rating == 1).length;

            let sumOfRating = parseInt(_5star + _4star + _3star + _2star + _1star);
            let overallRating = parseInt(5 * _5star + 4 * _4star + 3 * _3star + 2 * _2star + 1 * _1star);
            let averageRating = parseFloat(overallRating / sumOfRating);
            // console.log(averageRating.toFixed(1));
        });

    }, []);

    const filter = (star) => {
        db.collection("parlour_feedback").where('rating', '==', star).where('username', '==', params.id.toLowerCase()).onSnapshot((snapshot) => {

            setData(
                snapshot.docs.map((doc) => ({
                    id: doc.id,
                    data: doc.data(),
                }))
            );
        });
        setIndex(star)
    }

    const all = () => {
        db.collection("parlour_feedback").where('username', '==', params.id.toLowerCase()).orderBy("created_date", "desc").onSnapshot((snapshot) => {

            setData(
                snapshot.docs.map((doc) => ({
                    id: doc.id,
                    data: doc.data(),
                }))
            );
        });
        setIndex(0)
    }

    const handleChange = (event) => {
        if (event.target.value == "most_recent") {
            all();
        } else {
            filter(5);
        }
    };

    const showLoadMoreButton = data.length > visibleReviews;


    return (
        <>
            <section>
                <div class="navi" >
                    <input type="checkbox" id="nav-check" />
                    <div class="nav-header">
                        <div class="nav-title">
                            <Link to={`/${params.id.toLowerCase()}`} > <img src="/images/youvati_logo.png" alt="" style={{ height: "27px", marginLeft: "2rem" }} /></Link>
                        </div>
                    </div>


                </div>
            </section>
            <div class="container-fluid">
                <div class="row mt-5" style={{ textAlign: "center" }}>
                    <h4 style={{ textTransform: "uppercase" }}>{personalData.parlour_name}</h4>
                    <span>Customer Review</span>
                    <div class="" style={{ textAlign: "center", color: "white", display: "flex", justifyContent: "center" }}>
                        <ReactStars {...ratingExample} />
                    </div>
                </div>
                <div class="row">
                    <div class="col" style={{ textAlign: "center" }}><button type="button" class="btn btn-sm" style={{ color: "black", backgroundImage: index === 0 ? "linear-gradient(orange, yellow)" : "linear-gradient(grey, grey)", borderColor: "white", borderRadius: "25px", width: "5rem" }} onClick={() => all()}><span style={{ fontWeight: "500", fontSize: "11px", textTransform: "none", color: "white" }}>All   </span></button></div>
                    <div class="col" style={{ textAlign: "center" }}><button type="button" class="btn btn-sm" style={{ color: "black", backgroundImage: index === 5 ? "linear-gradient(orange, yellow)" : "linear-gradient(grey, grey)", borderColor: "white", borderRadius: "25px", width: "5rem" }} onClick={() => filter(5)}><span style={{ fontWeight: "500", fontSize: "11px", textTransform: "none", color: "white" }}>5 Star</span></button></div>
                    <div class="col" style={{ textAlign: "center" }}><button type="button" class="btn btn-sm" style={{ color: "black", backgroundImage: index === 4 ? "linear-gradient(orange, yellow)" : "linear-gradient(grey, grey)", borderColor: "white", borderRadius: "25px", width: "5rem" }} onClick={() => filter(4)}><span style={{ fontWeight: "500", fontSize: "11px", textTransform: "none", color: "white" }}>4 Star</span></button></div>
                    <div class="col" style={{ textAlign: "center" }}><button type="button" class="btn btn-sm" style={{ color: "black", backgroundImage: index === 3 ? "linear-gradient(orange, yellow)" : "linear-gradient(grey, grey)", borderColor: "white", borderRadius: "25px", width: "5rem" }} onClick={() => filter(3)}><span style={{ fontWeight: "500", fontSize: "11px", textTransform: "none", color: "white" }}>3 Star</span></button></div>
                    <div class="col" style={{ textAlign: "center" }}><button type="button" class="btn btn-sm" style={{ color: "black", backgroundImage: index === 2 ? "linear-gradient(orange, yellow)" : "linear-gradient(grey, grey)", borderColor: "white", borderRadius: "25px", width: "5rem" }} onClick={() => filter(2)}><span style={{ fontWeight: "500", fontSize: "11px", textTransform: "none", color: "white" }}>2 Star</span></button></div>
                    <div class="col" style={{ textAlign: "center" }}><button type="button" class="btn btn-sm" style={{ color: "black", backgroundImage: index === 1 ? "linear-gradient(orange, yellow)" : "linear-gradient(grey, grey)", borderColor: "white", borderRadius: "25px", width: "5rem" }} onClick={() => filter(1)}><span style={{ fontWeight: "500", fontSize: "11px", textTransform: "none", color: "white" }}>1 Star</span></button></div>
                </div>
                <div class="" style={{ textAlign: "center" }}>
                    <select style={{ border: "1px solid white", backgroundColor: "#8080809c", borderRadius: "18px", color: "white", fontSize: "13px", fontWeight: "600", padding: "4px" }} onChange={handleChange}>
                        <option value="top_reviews" style={{ color: "white" }}>Top Reviews</option>
                        <option value="most_recent" style={{ color: "white" }} >Most Recent</option>
                    </select>
                </div>

                {data?.length === 0 ? <div class="row" style={{ textAlign: "center", padding: "45px", display: "flow-root" }}>No Reviews Found !!!</div> : ""}

                <div class="col mt-3">
                    {data?.slice(showOnlyTen ? 0 : visibleReviews - 10, visibleReviews)?.map(({ id, data }, index) => (
                        <>
                            <div class="" style={{
                                marginBottom: "12px",
                                border: "double 2px transparent",
                                borderRadius: "14px",
                                backgroundImage: "linear-gradient(white, white), linear-gradient(to right, rgb(252,79,135), rgb(255,242,86))",
                                backgroundOrigin: "border-box",
                                backgroundClip: "content-box, border-box",
                            }}>
                                <div class="row p-3">
                                    <div class="col"><span style={{ marginRight: "1rem" }}>{data.full_name} | Client
                                        <span style={{ marginLeft: "2rem" }}>
                                            {data.rating >= 1 ? <i class="fa fa-star" aria-hidden="true" style={{ color: "orange" }}></i> : ""}
                                            {data.rating >= 2 ? <i class="fa fa-star" aria-hidden="true" style={{ color: "orange" }}></i> : ""}
                                            {data.rating >= 3 ? <i class="fa fa-star" aria-hidden="true" style={{ color: "orange" }}></i> : ""}
                                            {data.rating >= 4 ? <i class="fa fa-star" aria-hidden="true" style={{ color: "orange" }}></i> : ""}
                                            {data.rating === 5 ? <i class="fa fa-star" aria-hidden="true" style={{ color: "orange" }}></i> : ""}

                                        </span></span> <span style={{ marginRight: "1rem" }} >{data.rating} out of 5 </span> <span style={{ fontSize: "11px", color: "grey", }}>{moment(data.created_date.toDate().toISOString()).format("MM/DD/YY, h:mm a")}</span></div>


                                </div>
                                <div class="pl-3 pb-2" style={{ display: "block" }}>
                                    <span>{data.review}</span>
                                </div>

                                {data.image === "" ? <></> : <div class="" id="gallery1">
                                    <div class="gallery1 sets">
                                        <a class="all Bollywood"><img src={data.image} /></a>
                                    </div>
                                </div>}
                            </div>

                        </>
                    ))}



                </div>
                {/* <div class="row mt-3" style={{ display: "flex", textAlign: "center" }}>
                    <p style={{ fontSize: "small", fontWeight: "700", cursor: "pointer", color: "#ec7b3c" }} onClick={showNextReviews}>---- Load More ----</p>
                </div> */}

                <div class="row mt-3" style={{ display: "flex", textAlign: "center" }}>
                    {showLoadMoreButton ? (
                        <p style={{ fontSize: "small", fontWeight: "700", cursor: "pointer", color: "#ec7b3c" }} onClick={showNextReviews}>---- Load More ----</p>
                    ) : (
                        <p style={{ fontSize: "small", fontWeight: "700", color: "#ec7b3c" }}>--- No more reviews ---</p>
                    )}
                </div>

            </div>
            <Footer />
        </>
    );
}

export default CustomerReview;

