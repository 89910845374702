import React, { useState, useEffect } from 'react';
import AdminPanel from './AdminPanel';
import Swal from 'sweetalert2'
import { useNavigate, Link, useParams } from 'react-router-dom';
import db from "./Firebase";
import { storage } from './Firebase';
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import ViewAppData from './ViewAppData';
import Multiselect from 'multiselect-react-dropdown';


function GlobalNotification() {
    const navigate = useNavigate();
    const moment = extendMoment(Moment);
    const initialFormStateFeedback = { id: '', title: '', seen: [], message: '', link: '', delete: [], status: '', image_url: '', created_date: new Date() }
    const [submit, setSubmit] = useState(initialFormStateFeedback)
    const [data, setData] = useState([]);
    const [editID, setEditID] = useState("");
    const [edit, setEdit] = useState(false);
    const [ShowProgress, setShowProgress] = useState(false);
    const [show, setshow] = useState(true);
    const [progresspercent, setProgresspercent] = useState(0);

    const [options, setOptions] = useState([
        { name: 'Online' },
        { name: 'Aurangabad' },
        { name: 'Bathinda' },
        { name: 'Ghaziabad' },
        { name: 'Meerut' },
        { name: 'Mansa' },
        { name: 'Patiala' }
    ]);

    const [selectedValue, setSelectedValue] = useState([]);


    const onSelect = (selectedList, selectedItem) => {
        // Handle selection
        // ...
        setSelectedValue(selectedList);
    }

    const onRemove = (selectedList, removedItem) => {
        // Handle removal
        // ...
        setSelectedValue(selectedList);
    }

    useEffect(() => {
        const storedUsername = localStorage.getItem("email");
        const storedPassword = localStorage.getItem("password");
        const storedRemember = localStorage.getItem("remember");

        if (storedRemember === "false" || !storedUsername || !storedPassword) {
            navigate('/signin');
        }
    }, []);


    const handleChange = (e) => {
        const { id, value } = e.target
        setSubmit({ ...submit, [id]: value })
    }

    const changeStatus = (data, id) => {
        setEdit(true)
        setEditID(id)
        setSubmit({ id: id, title: data.title, seen: data.seen, message: data.message, link: data.link, delete: data.delete, status: data.status, image_url: data.image_url, created_date: new Date() })
        openModalShow();
    }

    const changeStatusAdd = () => {
        setEdit(false)
        setSubmit({ id: '', title: '', seen: [], message: '', link: '', delete: [], status: '', image_url: '', created_date: new Date() });
        openModalShow();
    }


    const openModalShow = () => {
        // alert("ooo")
        window.openModelshow();
    }

    const closeModel = () => {
        window.openModelNew();
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        // console.log(locationData);

        if (edit === false) {
            const file = e.target[0]?.files[0]
            // console.log(file)
            if (!file) return;
            const storageRef = ref(storage, `smg_files/${file.name}`);
            const uploadTask = uploadBytesResumable(storageRef, file);
            setshow(false)

            uploadTask.on("state_changed",
                (snapshot) => {
                    const progress =
                        Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                    setProgresspercent(progress);
                },
                (error) => {
                    alert(error);
                },
                () => {

                    getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                        // setImgUrl(downloadURL)
                        db.collection("global_notification").add({
                            'created_date': new Date(),
                            'title': submit.title,
                            'message': submit.message,
                            'link': submit.link,
                            'status': submit.status,
                            'seen': [],
                            'delete': [],
                            'image_url': downloadURL,
                        }).then(() => {
                            console.log('Created Successfully');
                            const Toast = Swal.mixin({
                                toast: true,
                                background: '#69aba6',
                                position: 'top-end',
                                showConfirmButton: false,
                                timer: 3000,
                                timerProgressBar: true,
                                didOpen: (toast) => {
                                    toast.addEventListener('mouseenter', Swal.stopTimer)
                                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                                }
                            })

                            Toast.fire({
                                icon: 'success',
                                title: 'Created Successfully'
                            })
                            closeModel();
                            setshow(true)
                            setSubmit({ id: '', title: '', seen: [], message: '', link: '', delete: [], status: '', image_url: '', created_date: new Date() })
                        })
                    });
                }
            );

        } else {
            if (e.target[0]?.files[0] == null) {
                db.collection("global_notification").doc(editID).update({
                    'created_date': new Date(),
                    'title': submit.title,
                    'message': submit.message,
                    'link': submit.link,
                    'status': submit.status,
                    'seen': [],
                    'delete': [],
                    'image_url': submit.image_url,
                }).then(() => {
                    console.log('Updated Successfully');
                    const Toast = Swal.mixin({
                        toast: true,
                        background: '#69aba6',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', Swal.stopTimer)
                            toast.addEventListener('mouseleave', Swal.resumeTimer)
                        }
                    })

                    Toast.fire({
                        icon: 'success',
                        title: 'Updated Successfully'
                    })
                    closeModel();

                    setSubmit({ id: '', title: '', seen: [], message: '', link: '', delete: [], status: '', image_url: '', created_date: new Date() })
                })
            } else {
                const file = e.target[0]?.files[0]
                // console.log(file)
                if (!file) return;
                const storageRef = ref(storage, `smg_files/${file.name}`);
                const uploadTask = uploadBytesResumable(storageRef, file);
                setshow(false)

                uploadTask.on("state_changed",
                    (snapshot) => {
                        const progress =
                            Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                        setProgresspercent(progress);
                    },
                    (error) => {
                        alert(error);
                    },
                    () => {

                        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                            // setImgUrl(downloadURL)
                            db.collection("global_notification").doc(editID).update({
                                'created_date': new Date(),
                                'title': submit.title,
                                'message': submit.message,
                                'link': submit.link,
                                'status': submit.status,
                                'seen': [],
                                'delete': [],
                                'image_url': downloadURL,
                            }).then(() => {
                                console.log('Updated Successfully');
                                const Toast = Swal.mixin({
                                    toast: true,
                                    background: '#69aba6',
                                    position: 'top-end',
                                    showConfirmButton: false,
                                    timer: 3000,
                                    timerProgressBar: true,
                                    didOpen: (toast) => {
                                        toast.addEventListener('mouseenter', Swal.stopTimer)
                                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                                    }
                                })

                                Toast.fire({
                                    icon: 'success',
                                    title: 'Updated Successfully'
                                })
                                closeModel();
                                setshow(true)
                                setSubmit({ id: '', title: '', seen: [], message: '', link: '', delete: [], status: '', image_url: '', created_date: new Date() })
                            })
                        });
                    }
                );
            }
        }



    }



    useEffect(() => {
        db.collection("global_notification").orderBy("created_date", "desc").onSnapshot((snapshot) => {

            setData(
                snapshot.docs.map((doc) => ({
                    id: doc.id,
                    data: doc.data(),
                }))
            );
        });


    }, []);

    return (
        <>
            <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">

                <div class="p-2" style={{ paddingBottom: "5px", display: "flex", justifyContent: "space-between" }}>
                    {/* <span class="btn bg-gradient-secondary btn-sm mb-0 mr-2" style={{ color: "white" }} >&nbsp; Download Excel</span> */}
                    <Link to="/admin_panel"><img src="/images/menubutton.png" class="menu-toggle" alt="Responsive image" style={{ height: "2rem" }} /></Link>
                    <a class="btn bg-gradient-primary btn-sm mb-0" style={{ color: "white" }} onClick={() => changeStatusAdd()}>+&nbsp; Add</a>
                </div>

                <div class="p-2">
                    <div class="card-header pb-0 px-3" style={{ display: "flex", justifyContent: "center" }}>
                        <h6 class="mb-2">Global Notification</h6>
                    </div>


                    <div class="card">
                        <div class="table-responsive fix-width scroll-inner">
                            <table class="table table-bordered table-hover">
                                <thead class="thead-dark" style={{ position: "sticky", top: "0" }}>
                                    <tr style={{ textAlign: "left" }}>
                                        <th class="col">Image</th>
                                        <th class="col ps-2">Title</th>
                                        <th class="text-center col">message</th>
                                        <th class="text-center col">Status</th>
                                        <th class="text-center col">Created Date</th>
                                        <th class="text-center col">Action</th>
                                        {/* <th class="text-secondary opacity-7"></th> */}
                                    </tr>
                                </thead>
                                <tbody style={{ textAlign: "left" }}>
                                    {data?.map(({ id, data }, index) => (
                                        <>
                                            <tr>
                                                <td>
                                                    <div class="d-flex px-2 py-1">
                                                        <div style={{ width: "7rem", marginRight: "13px" }}>
                                                            <img src={data.image_url} class="me-3" style={{ height: "4rem" }} />
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <p class="text-xs font-weight-bold mb-0">{data.title}</p>
                                                    {/* <p class="text-xs text-secondary mb-0">Organization</p> */}
                                                </td>
                                                <td class="align-middle text-center text-sm">
                                                    <p class="text-xs font-weight-bold mb-0">{data.message}</p>
                                                </td>
                                                <td class="align-middle text-center text-sm">
                                                    <p class="text-xs font-weight-bold mb-0">{data.status}</p>
                                                </td>
                                                <td class="align-middle text-center text-sm">
                                                    <span class="text-secondary text-xs font-weight-normal">{moment(data.created_date.toDate().toISOString()).format("MM/DD/YY, h:mm a")}</span>
                                                </td>

                                                <td class="align-middle text-center" onClick={() => changeStatus(data, id)}>
                                                    <span class="text-info text-xs font-weight-normal" style={{ cursor: "pointer" }}>Edit</span>
                                                </td>

                                            </tr>
                                        </>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

            </main>






            <div class="col-md-12">
                <div class="myId modal fade" id="" tabindex="-1" role="dialog" aria-labelledby="modal-form" aria-hidden="true" data-backdrop="static">
                    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div class="modal-content">
                            <div class="modal-body">


                                <div class="">
                                    <form onSubmit={handleSubmit}>
                                        <div class="">
                                            <div class="" style={{ textAlign: "center", }}><span style={{ color: "black", fontSize: "25px", fontWeight: "700", textTransform: "uppercase" }}>Global Notification</span></div>
                                            <div class="" style={{ textAlign: "center", color: "black", paddingTop: "10px" }}>
                                            </div>
                                            <div class="">
                                                <div class="row">
                                                    <div class="col-md-4">
                                                        {edit ? <input type="file" id="image" style={{
                                                            // width: "17rem",
                                                            height: "45px",
                                                            padding: "12px 20px",
                                                            boxSizing: "border-box",
                                                            border: "2px solid #ccc",
                                                            borderRadius: "4px",
                                                            backgroundColor: "#f8f8f8",
                                                            fontSize: "16px",
                                                            resize: "none"
                                                        }} class="form-control" onfocus="focused(this)" onfocusout="defocused(this)" placeholder="Image" /> : <input type="file" id="image" style={{
                                                            // width: "17rem",
                                                            height: "45px",
                                                            padding: "12px 20px",
                                                            boxSizing: "border-box",
                                                            border: "2px solid #ccc",
                                                            borderRadius: "4px",
                                                            backgroundColor: "#f8f8f8",
                                                            fontSize: "16px",
                                                            resize: "none"
                                                        }} class="form-control" onfocus="focused(this)" onfocusout="defocused(this)" placeholder="Image" />}
                                                    </div>
                                                    <div class="col-md-4">
                                                        <input style={{
                                                            // width: "17rem",
                                                            height: "45px",
                                                            padding: "12px 20px",
                                                            boxSizing: "border-box",
                                                            border: "2px solid #ccc",
                                                            borderRadius: "4px",
                                                            backgroundColor: "#f8f8f8",
                                                            fontSize: "16px",
                                                            resize: "none"
                                                        }} class="w3l_text_box" id="title" placeholder="Title" name="Title" type="text" onChange={handleChange} value={submit.title} required />
                                                    </div>
                                                    <div class="col-md-4">
                                                        <input style={{
                                                            // width: "17rem",
                                                            height: "45px",
                                                            padding: "12px 20px",
                                                            boxSizing: "border-box",
                                                            border: "2px solid #ccc",
                                                            borderRadius: "4px",
                                                            backgroundColor: "#f8f8f8",
                                                            fontSize: "16px",
                                                            resize: "none"
                                                        }} class="w3l_text_box" id="link" placeholder="Link" name="Link" type="text" onChange={handleChange} value={submit.link} required />
                                                    </div>

                                                </div>
                                            </div>


                                            <div class="pb-4">
                                                <div class="row">
                                                    <div class="col-md-4">
                                                        <li>
                                                            <select class="form-control" style={{
                                                                // width: "17rem",
                                                                height: "45px",
                                                                padding: "5px 16px",
                                                                boxSizing: "border-box",
                                                                border: "2px solid #ccc",
                                                                borderRadius: "4px",
                                                                backgroundColor: "#f8f8f8",
                                                                fontSize: "16px",
                                                                resize: "none"
                                                            }} id="status" onChange={handleChange} value={submit.status} required>
                                                                <option value="">Select Status</option>
                                                                <option value="0">0</option>
                                                                <option value="1">1</option>
                                                            </select>
                                                        </li>
                                                    </div>
                                                    {/* <div class="col-md-4">
                                                        <input style={{
                                                            // width: "17rem",
                                                            height: "45px",
                                                            padding: "12px 20px",
                                                            boxSizing: "border-box",
                                                            border: "2px solid #ccc",
                                                            borderRadius: "4px",
                                                            backgroundColor: "#f8f8f8",
                                                            fontSize: "16px",
                                                            resize: "none"
                                                        }} class="w3l_text_box" id="organiser" placeholder="Organiser" name="Organiser" onChange={handleChange} value={submit.organiser} type="text" required />
                                                    </div>

                                                    <div class="col-md-4">
                                                        <input style={{
                                                            // width: "17rem",
                                                            height: "45px",
                                                            padding: "12px 20px",
                                                            boxSizing: "border-box",
                                                            border: "2px solid #ccc",
                                                            borderRadius: "4px",
                                                            backgroundColor: "#f8f8f8",
                                                            fontSize: "16px",
                                                            resize: "none"
                                                        }} class="w3l_text_box" id="amount" placeholder="Amount" name="amount" type="number" onChange={handleChange} value={submit.amount} required />
                                                    </div> */}
                                                </div>
                                            </div>




                                            <textarea style={{
                                                width: "100%",
                                                height: "15px",
                                                padding: "12px 20px",
                                                boxSizing: "border-box",
                                                border: "2px solid #ccc",
                                                borderRadius: "4px",
                                                backgroundColor: "#f8f8f8",
                                                fontSize: "16px",
                                                resize: "none"
                                            }} class="w3l_text_box" id="message" placeholder="Write a message..." name="Message" onChange={handleChange} value={submit.message} required />





                                            <div class="modal-footer">
                                                {/* {submit.id == "" ? <></> : <a class="btn bg-gradient-danger btn-sm mb-0" style={{ color: "white" }} onClick={() => deleteData(submit.id)}>Delete</a>} */}
                                                <a class="btn bg-gradient-info btn-sm mb-0" style={{ color: "white" }} onClick={closeModel}>Close</a>
                                                <button type='submit' class="btn bg-gradient-danger btn-sm mb-0" style={{ backgroundColor: "green", color: "white" }}>{edit ? "Update" : "Submit"}</button>
                                            </div>


                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >

        </>
    )
}

export default GlobalNotification;
