import React, { useState, useEffect } from "react";
import { useNavigate, Link, useParams } from "react-router-dom"
import db from "./Firebase";
import axios from "axios";
// import { firebase, auth } from './FirebaseLogin';
import Footer from "./Footer";

function Certificates() {
    let params = useParams();
    const navigate = useNavigate();
    const [personalData, setPersonalData] = useState({});
    useEffect(() => {
        db.collection("portfolio_details").where('username', '==', params.id.toLowerCase()).onSnapshot((snapshot) => {
            snapshot.docs.map(e => setPersonalData(e.data()))
            if (snapshot.docs.length == 0) {
                navigate('/error');
            } else {
                // alert("exists")
                setIsLoading(false);
            }
        });
    }, []);
    const [isLoading, setIsLoading] = useState(true)
    const [img, setImg] = useState("")
    // services_images

    const selectedImage = (e) => {
        console.log(e)
        setImg(e)
    }
    return (
        <>
            <section style={{ boxShadow: "0 6px 6px -6px #777" }}>
                <div class="navi" >
                    <input type="checkbox" id="nav-check" />
                    <div class="nav-header">
                        <div class="nav-title">
                            <Link to={`/${params.id.toLowerCase()}`} > <img src="/images/youvati_logo.png" alt="" style={{ height: "27px", marginLeft: "2rem" }} /></Link>
                        </div>
                    </div>


                </div>
            </section>
            {isLoading ? <div>
                <img src="images/loader.gif" alt="" class="" style={{ display: "block", marginLeft: "auto", marginRight: "auto", width: "40%" }} />
            </div> :
                <section class="section bg-default bg-gray-100 mb-5">
                    <div class="container-fluid">

                        <div class="container pt-5 certificateviewH" >
                            <div class="row">
                                {personalData.certificates.map((e) => (
                                    <div class="col">
                                        <div class=" pb-2" style={{ height: "9rem", width: "9rem", marginBottom: "2rem" }}>
                                            <img src={e} alt="" class="img-thumbnail" width="200" height="200" />
                                        </div>
                                    </div>
                                ))
                                }



                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-1"></div>
                            <div class="col-md-2 p-4 certificateviewV" style={{ backgroundColor: "" }}>
                                <ul class="">
                                    {personalData.certificates.map((e) => (
                                        <li>
                                            <img src={e} onClick={() => selectedImage(e)} alt="" class="img-thumbnail" width="204" height="136" />
                                        </li>
                                        //  <br />
                                    ))
                                    }




                                </ul>
                            </div>
                            <div class="col-md-8 p-4" style={{ backgroundColor: "" }}>
                                <img src={img === "" ? personalData.certificates[0] : img} class="img-fluid" alt="Responsive image" width="780" />
                            </div>
                        </div>
                    </div>
                </section>
            }

            <Footer />

        </>
    );
}

export default Certificates;




