
import React, { useState, useEffect } from 'react';
import { useNavigate, Link, useParams } from 'react-router-dom';
import db from "./Firebase";
import { arrayUnion } from "firebase/firestore";
import { storage } from './Firebase';
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import ViewAllRequests from './ViewAllRequests';
import { CSVLink } from "react-csv";
import Swal from 'sweetalert2';

function PortfolioAnalyser() {
    const navigate = useNavigate();
    const moment = extendMoment(Moment);
    const [data, setData] = useState([]);
    const [wdata, setWdata] = useState([]);
    const [csvdata, setCsvdata] = useState([]);
    const [sortBy, setSortBy] = useState('totalReviews');
    const [sortDirection, setSortDirection] = useState('asc');
    const [searchQuery, setSearchQuery] = useState('');
    const [viewGraph, setViewGraph] = useState(false);


    const headers = [
        "name",
        "title_designation",
        "city",
        "username",
        "phone_no",
        "total_images",
        "total_services",
        "total_reviews",
        "average_rating",
        "completion_percentage"
        // Add more column labels as needed
    ];


    useEffect(() => {
        const storedUsername = localStorage.getItem("email");
        const storedPassword = localStorage.getItem("password");
        const storedRemember = localStorage.getItem("remember");

        if (storedRemember === "false" || !storedUsername || !storedPassword) {
            navigate('/signin');
        }
    }, []);

    const [listingArray, setListingArray] = useState([]);


    useEffect(() => {
        const arr = [];
        db.collection("website_portfolio_listing").onSnapshot((snapshot) => {
            snapshot.docs.map((doc) => {
                console.log(doc.data().uid)
                arr.push(doc.data().uid)
            })
            setListingArray(arr)
            console.log(arr)

        });



        db.collection("parlour_feedback").onSnapshot((snapshot) => {
            setWdata(
                snapshot.docs.map((doc) => ({
                    id: doc.id,
                    data: doc.data(),
                }))
            );
        });

        db.collection("portfolio_details").onSnapshot((snapshot) => {
            setData(
                snapshot.docs.map((doc) => ({
                    id: doc.id,
                    data: doc.data(),
                    imageLength: doc.data().services_images.length,
                    servicesLength: doc.data().services.length,
                }))
            );
        });

    }, []);

    useEffect(() => {
        const idNameMapping = wdata.reduce((accumulator, { data }) => {
            const username = data.username;
            accumulator[username] = (accumulator[username] || 0) + 1;
            return accumulator;
        }, {});

        setData((prevData) => {
            return prevData.map((item) => {
                return {
                    ...item,
                    data: {
                        ...item.data,
                        totalReviews: idNameMapping[item.data.username] || 0
                    }
                };
            });
        });
    }, [wdata]);

    useEffect(() => {
        const ratingCounts = wdata.reduce((accumulator, { data }) => {
            const username = data.username;
            const rating = data.rating;
            accumulator[username] = (accumulator[username] || 0) + rating;
            return accumulator;
        }, {});

        const ratingAverages = {};
        Object.keys(ratingCounts).forEach((username) => {
            const ratingCount = ratingCounts[username];
            const averageRating = ratingCount / wdata.filter(({ data }) => data.username === username).length;
            ratingAverages[username] = averageRating.toFixed(1);
        });

        setData((prevData) => {
            return prevData.map((item) => {
                const username = item.data.username;
                const averageRating = ratingAverages[username] || 0;
                return {
                    ...item,
                    data: {
                        ...item.data,
                        averageRating
                    }
                };
            });
        });
        // console.log(data);

    }, [wdata]);



    const handleSearch = (e) => {
        setSearchQuery(e.target.value);
    };

    const filteredData = data.filter(({ data }) => {
        const name = `${data.first_name} ${data.last_name}`;
        const title = data.title_designation;
        const city = data.city;
        const username = data.username;

        return (
            name.toLowerCase().includes(searchQuery.toLowerCase()) ||
            title.toLowerCase().includes(searchQuery.toLowerCase()) ||
            city.toLowerCase().includes(searchQuery.toLowerCase()) ||
            username.toLowerCase().includes(searchQuery.toLowerCase())
        );
    });

    const calculateCompletionPercentage = (data) => {
        let completionPercentage = 20; // Start with a base completion percentage of 0

        if (data.certificates && data.certificates.length > 0) {
            completionPercentage += 20; // Add 20% if there are certificates
        }

        if (data.services && data.services.length > 0) {
            completionPercentage += 20; // Add 20% if there are services
        }

        if (data.services_images && data.services_images.length > 0) {
            completionPercentage += 20; // Add 20% if there are service images
        }

        if (data.totalReviews && data.totalReviews > 0) {
            completionPercentage += 20; // Add 20% if there are total reviews
        }

        return completionPercentage; // Return the final completion percentage
    };




    const getData = () => {
        const counts = [
            data.filter(({ data }) => calculateCompletionPercentage(data) >= 100).length,
            data.filter(({ data }) => calculateCompletionPercentage(data) >= 80 && calculateCompletionPercentage(data) < 100).length,
            data.filter(({ data }) => calculateCompletionPercentage(data) >= 60 && calculateCompletionPercentage(data) < 80).length,
            data.filter(({ data }) => calculateCompletionPercentage(data) >= 40 && calculateCompletionPercentage(data) < 60).length,
            data.filter(({ data }) => calculateCompletionPercentage(data) >= 20 && calculateCompletionPercentage(data) < 40).length,
        ];

        console.log(counts);
        window.polarChart(counts);


        setViewGraph(true);
        ratingGraph();
    }

    const handleSort = (field) => {
        if (sortBy === field) {
            // Toggle sort direction
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            // Set new field and default sort direction
            setSortBy(field);
            setSortDirection('asc');
        }
    };

    // const sortedData = data.slice().sort((a, b) => {
    //     const valueA = a.data[sortBy];
    //     const valueB = b.data[sortBy];
    //     const completionPercentageA = calculateCompletionPercentage(a.data);
    //     const completionPercentageB = calculateCompletionPercentage(b.data);


    //     if (valueA < valueB) {
    //         return sortDirection === 'asc' ? -1 : 1;
    //     }
    //     if (valueA > valueB) {
    //         return sortDirection === 'asc' ? 1 : -1;
    //     }
    //     if (completionPercentageA < completionPercentageB) {
    //         return sortDirection === 'asc' ? -1 : 1;
    //     }
    //     if (completionPercentageA > completionPercentageB) {
    //         return sortDirection === 'asc' ? 1 : -1;
    //     }
    //     return 0;
    // });

    const sortedData = data.slice().sort((a, b) => {
        const valueA = a.data[sortBy];
        const valueB = b.data[sortBy];
        const completionPercentageA = calculateCompletionPercentage(a.data);
        const completionPercentageB = calculateCompletionPercentage(b.data);

        if (sortBy === 'images') {
            return sortDirection === 'asc' ? a.imageLength - b.imageLength : b.imageLength - a.imageLength;
        }

        if (sortBy === 'services') {
            return sortDirection === 'asc' ? a.servicesLength - b.servicesLength : b.servicesLength - a.servicesLength;
        }

        if (valueA < valueB) {
            return sortDirection === 'asc' ? -1 : 1;
        }
        if (valueA > valueB) {
            return sortDirection === 'asc' ? 1 : -1;
        }
        if (completionPercentageA < completionPercentageB) {
            return sortDirection === 'asc' ? -1 : 1;
        }
        if (completionPercentageA > completionPercentageB) {
            return sortDirection === 'asc' ? 1 : -1;
        }
        return 0;
    });


    // useEffect(() => {
    //     const ratingCounts = {
    //         '0-1.9': 0,
    //         '2-2.9': 0,
    //         '3-3.9': 0,
    //         '4-5': 0
    //     };

    //     data.forEach(({ data }) => {
    //         // console.log(data)
    //         // const rating = data.averageRating;
    //         // if (rating >= 0 && rating < 2) {
    //         //     ratingCounts['0-1.9']++;
    //         // } else if (rating >= 2 && rating < 3) {
    //         //     ratingCounts['2-2.9']++;
    //         // } else if (rating >= 3 && rating < 4) {
    //         //     ratingCounts['3-3.9']++;
    //         // } else if (rating >= 4 && rating <= 5) {
    //         //     ratingCounts['4-5']++;
    //         // }
    //     });

    //     const ratingData = Object.entries(ratingCounts).map(([range, count]) => ({
    //         range,
    //         count
    //     }));

    //     // Display the ratingData array in the console
    //     console.log(ratingData);

    //     // Rest of your code...
    // }, [wdata]);


    //

    // useEffect(() => {
    //     const labels = ['100% Completed', '80% Completed', '80% Completed', '80% Completed', '80% Completed',];
    //     const counts = [
    //         sortedData.filter(({ data }) => calculateCompletionPercentage(data) === 100).length,
    //         sortedData.filter(({ data }) => calculateCompletionPercentage(data) === 80).length,
    //         sortedData.filter(({ data }) => calculateCompletionPercentage(data) === 80).length,
    //         sortedData.filter(({ data }) => calculateCompletionPercentage(data) === 80).length,
    //         sortedData.filter(({ data }) => calculateCompletionPercentage(data) === 80).length
    //     ];
    //     window.polarChart(counts, labels);

    // }, [wdata, sortedData]);
    // useEffect(() => {


    // }, []);

    // const labels = ['100% Completed', '80% Completed', '60% Completed', '40% Completed', '20% Completed',];
    // const counts = [
    //     filteredData.filter(({ data }) => calculateCompletionPercentage(data) === 100).length,
    //     filteredData.filter(({ data }) => calculateCompletionPercentage(data) === 80).length,
    //     filteredData.filter(({ data }) => calculateCompletionPercentage(data) === 60).length,
    //     filteredData.filter(({ data }) => calculateCompletionPercentage(data) === 40).length,
    //     filteredData.filter(({ data }) => calculateCompletionPercentage(data) === 20).length,
    // ];
    // // const counts = [21, 23, 28, 33, 21]

    // console.log(counts);
    // window.polarChart(counts, labels);

    // const createChart = () => {
    //     const labels = ['100% Completed', '80% Completed', '60% Completed', '40% Completed', '20% Completed',];
    //     const counts = [
    //         filteredData.filter(({ data }) => calculateCompletionPercentage(data) === 100).length,
    //         filteredData.filter(({ data }) => calculateCompletionPercentage(data) === 80).length,
    //         filteredData.filter(({ data }) => calculateCompletionPercentage(data) === 60).length,
    //         filteredData.filter(({ data }) => calculateCompletionPercentage(data) === 40).length,
    //         filteredData.filter(({ data }) => calculateCompletionPercentage(data) === 20).length,
    //     ];

    //     console.log(counts);
    //     window.polarChart(counts, labels);
    // };

    // useEffect(() => {
    //     // const counts = [
    //     //     data.filter(({ data }) => calculateCompletionPercentage(data) >= 100).length,
    //     //     data.filter(({ data }) => calculateCompletionPercentage(data) >= 80 && calculateCompletionPercentage(data) < 100).length,
    //     //     data.filter(({ data }) => calculateCompletionPercentage(data) >= 60 && calculateCompletionPercentage(data) < 80).length,
    //     //     data.filter(({ data }) => calculateCompletionPercentage(data) >= 40 && calculateCompletionPercentage(data) < 60).length,
    //     //     50,
    //     // ];

    //     // console.log(counts);

    //     // window.polarChart(counts);
    // }, [data]);


    const ratingGraph = () => {
        const ratingCounts = {
            '0-1.9': 0,
            '2-2.9': 0,
            '3-3.9': 0,
            '4-5': 0
        };
        data.forEach(({ data }) => {
            // console.log(data)
            const rating = data.averageRating;
            if (rating >= 0 && rating < 2) {
                ratingCounts['0-1.9']++;
            } else if (rating >= 2 && rating < 3) {
                ratingCounts['2-2.9']++;
            } else if (rating >= 3 && rating < 4) {
                ratingCounts['3-3.9']++;
            } else if (rating >= 4 && rating <= 5) {
                ratingCounts['4-5']++;
            }
        });

        // const ratingData = Object.entries(ratingCounts).map(([range, count]) => ({
        //     range,
        //     count
        // }));

        const countArray = Object.values(ratingCounts);

        // Display the countArray in the console
        window.barGraph(countArray);
        console.log(countArray);
    }


    // Function to convert data to CSV format
    const prepareCSVData = () => {
        const csvData = sortedData.map((item, index) => ([
            item.data.first_name,
            item.data.title_designation,
            item.data.city,
            item.data.username,
            item.data.phone_no,
            item.imageLength,
            item.servicesLength,
            item.data.totalReviews,
            item.data.averageRating,
            calculateCompletionPercentage(item.data),
            // Add more columns as needed
        ]));

        return csvData;
    };



    const addList = (data) => {
        data.pin = 200;
        data.listID = 200;
        data.status = 1;
        data.listing = 200;
        data.starPerformer = 0;
        data.premiumUser = 0;
        data.verifiedUser = 0;
        data.customOne = 0;
        data.customTwo = 0;
        data.customThree = "";
        data.customFour = "";
        data.price = "";
        data.package = [];
        data.updated_date = new Date();

        console.log(data);
        Swal.fire({
            title: 'Are you sure?',
            text: "You can able to revert this later!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, List it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                await db.collection("website_portfolio_listing").add(data)
                    .then(() => {
                        console.log('Added successfull');
                        const arr = [];
                        db.collection("website_portfolio_listing").onSnapshot((snapshot) => {
                            snapshot.docs.map((doc) => {
                                console.log(doc.data().uid)
                                arr.push(doc.data().uid)
                            })
                            setListingArray(arr)
                            console.log(arr)

                        });
                    });
                Swal.fire('Updated!', '', 'success')
            }
        })

    }


    const deleteData = (uid) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You can able to revert this later!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Yes, remove it!'
        }).then((result) => {
            if (result.isConfirmed) {
                db.collection("website_portfolio_listing")
                    .where('uid', '==', uid)
                    .get()
                    .then((querySnapshot) => {
                        querySnapshot.forEach((doc) => {
                            // Delete the document
                            doc.ref.delete().then(() => {
                                console.log('Deleted Successfully');
                                Swal.fire(
                                    'Deleted!',
                                    'Data has been deleted.',
                                    'success'
                                )
                                const arr = [];
                                db.collection("website_portfolio_listing").onSnapshot((snapshot) => {
                                    snapshot.docs.map((doc) => {
                                        console.log(doc.data().uid)
                                        arr.push(doc.data().uid)
                                    })
                                    setListingArray(arr)
                                    console.log(arr)

                                });
                            }).catch((error) => {
                                console.error('Error deleting document:', error);
                            });
                        });
                    })
                    .catch((error) => {
                        console.error('Error getting documents:', error);
                    });

            }
        })

    }



    return (
        <>

            <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
                <div class="p-2" style={{ textAlign: "start", paddingBottom: "5px" }}>
                    {/* <span class="btn bg-gradient-secondary btn-sm mb-0 mr-2" style={{ color: "white" }} >&nbsp; Download Excel</span> */}
                    <Link to="/admin_panel"><img src="/images/menubutton.png" class="menu-toggle" alt="Responsive image" style={{ height: "2rem" }} /></Link>

                </div>
                {viewGraph ? <>
                    <div class="row" style={{ display: "flex", justifyContent: "center" }}>
                        <div class="chart w-40">
                            <canvas id="polar-chart" class="chart-canvas" height="300px"></canvas>
                        </div>
                        <div class="chart w-40">
                            <canvas id="bar-chart" class="chart-canvas" height="300px"></canvas>
                        </div>
                    </div>
                </> : <></>}
                {/* {viewGraph ? <>
                    <div class="chart w-40">
                        <canvas id="bar-chart" class="chart-canvas" height="300px"></canvas>
                    </div>
                </> : <></>} */}
                {/* {viewGraph ? <>  <div class="row mt-4">
                    <div class="col-lg-6 col-12 p-4">
                        <div class="card mt-4">
                            <div class="card-header p-3 pt-2">
                                <div class="icon-lg icon-shape bg-gradient-warning shadow-warning text-center border-radius-xl mt-n4 me-3 float-start">
                                    <i class="material-icons opacity-10">donut_small</i>
                                </div>
                                <h6 class="mb-0">Completion Polar Graph</h6>
                                <p class="mb-0 text-sm">Analytics Insights</p>
                            </div>
                            <div class="card-body d-flex p-3 pt-0">
                                <div class="chart w-80">
                                    <canvas id="polar-chart" class="chart-canvas" height="300px"></canvas>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-12 mt-md-0 mt-4 p-4">
                        <div class="card mt-4">
                            <div class="card-header p-3 pt-2">
                                <div class="icon-lg icon-shape bg-gradient-warning shadow-warning text-center border-radius-xl mt-n4 me-3 float-start">
                                    <i class="material-icons opacity-10">pie_chart</i>
                                </div>
                                <h6 class="mb-0">Completion Bar Graph</h6>
                                <p class="mb-0 text-sm">Analytics Insights</p>
                            </div>
                            <div class="card-body d-flex p-3 pt-0">
                                <div class="chart w-80">
                                    <canvas id="bar-chart" class="chart-canvas" height="460" width="514" style={{ display: "block", boxSizing: "border-box", height: "307.2px", width: "343px" }}></canvas>

                                </div>
                            </div>
                        </div>
                    </div>
                </div></> : <></>} */}
                <div class="">
                    <div class="p-4">
                        <div style={{ textAlign: "end", paddingBottom: "5px" }}>
                            <span class="btn bg-gradient-secondary btn-sm mb-0 mr-2" style={{ color: "white" }} onClick={() => getData()}  >&nbsp; View Graph</span>
                            <CSVLink data={prepareCSVData()} headers={headers} filename={"portfolio_anyliser_data.csv"}>
                                <span class="btn bg-gradient-secondary btn-sm mb-0 mr-2" style={{ color: "white" }}>
                                    &nbsp; Download Excel
                                </span>
                            </CSVLink>
                        </div>
                        <div class="card">
                            <div class="card-header pb-0 px-3">
                                <h6 class="mb-2">Portfolio Analyser</h6>
                            </div>
                            <div class="">
                                <div class="card">
                                    <div class="table-responsive fix-width scroll-inner">
                                        <table class="table table-bordered table-hover">
                                            <thead class="thead-dark" style={{ position: "sticky", top: "0" }}>
                                                <tr style={{ textAlign: "left" }}>
                                                    <th>Sr.No</th>
                                                    <th class="ps-2" >
                                                        Add/Delete
                                                    </th>
                                                    <th class="ps-2">
                                                        Name
                                                    </th>
                                                    <th class="ps-2">
                                                        Title/Designation
                                                    </th>
                                                    <th class="ps-2">
                                                        City
                                                    </th>

                                                    <th class="ps-2">
                                                        Username
                                                    </th>
                                                    {/* <th class="">
                                                        Phone No
                                                    </th> */}
                                                    <th class="">
                                                        <button onClick={() => handleSort('completionPercentage')} className="btn btn-link p-0" style={{ fontSize: "11px", top: "7px" }}>
                                                            Completion Percentage
                                                            {sortBy === 'completionPercentage' && (
                                                                <span>{sortDirection === 'asc' ? '▲' : '▼'}</span>
                                                            )}
                                                        </button>
                                                    </th>

                                                    <th class="">
                                                        <p onClick={() => handleSort('images')} className="btn btn-link p-0" style={{ fontSize: "11px", top: "7px" }}>
                                                            Images
                                                            {sortBy === 'images' && (
                                                                <span>{sortDirection === 'asc' ? '▲' : '▼'}</span>
                                                            )}
                                                        </p>
                                                    </th>

                                                    <th class="">
                                                        <p onClick={() => handleSort('services')} className="btn btn-link p-0" style={{ fontSize: "11px", top: "7px" }}>
                                                            Services
                                                            {sortBy === 'services' && (
                                                                <span>{sortDirection === 'asc' ? '▲' : '▼'}</span>
                                                            )}
                                                        </p>
                                                    </th>

                                                    <th class="">
                                                        <p onClick={() => handleSort('totalReviews')} className="btn btn-link p-0" style={{ fontSize: "11px", top: "7px" }}>
                                                            Total Reviews
                                                            {sortBy === 'totalReviews' && (
                                                                <span>{sortDirection === 'asc' ? '▲' : '▼'}</span>
                                                            )}
                                                        </p>
                                                    </th>
                                                    <th class="">
                                                        <button onClick={() => handleSort('averageRating')} className="btn btn-link p-0" style={{ fontSize: "11px", top: "7px" }}>
                                                            Average Rating
                                                            {sortBy === 'averageRating' && (
                                                                <span>{sortDirection === 'asc' ? '▲' : '▼'}</span>
                                                            )}
                                                        </button>
                                                    </th>

                                                </tr>
                                            </thead>
                                            <tbody style={{ textAlign: "left" }}>
                                                {sortedData.map(({ id, data, imageLength, servicesLength }, index) => {
                                                    const completionPercentage = calculateCompletionPercentage(data);
                                                    return (
                                                        <tr key={id}>
                                                            <td>{index + 1}</td>
                                                            <td>{listingArray.includes(id) ? <><td style={{ fontSize: "25px", color: "red", fontWeight: "800", cursor: "pointer" }}><span onClick={() => deleteData(id)}>-</span></td></> : <><td style={{ fontSize: "25px", color: "#71d82b", fontWeight: "800", cursor: "pointer" }}><span onClick={() => addList(data)}>+</span></td></>}</td>
                                                            {/* <td style={{ fontSize: "25px", color: "#71d82b", fontWeight: "800" }}>+</td> */}
                                                            <td>
                                                                <p class="text-xs font-weight-bold mb-0">{data.first_name} {data.last_name}</p>
                                                            </td>
                                                            <td>
                                                                <p class="text-xs font-weight-bold mb-0">{data.title_designation}</p>
                                                            </td>
                                                            <td>
                                                                <p class="text-xs font-weight-bold mb-0">{data.city}</p>
                                                            </td>

                                                            <td>
                                                                <a href={data.username} target="_blank" rel="noopener noreferrer">{data.username}</a>
                                                            </td>
                                                            {/* <td>
                                                                <div class="d-flex px-2 py-1">
                                                                    <div class="d-flex flex-column justify-content-center">
                                                                        <h6 class="mb-0 text-xs">{data.phone_no}</h6>
                                                                    </div>
                                                                </div>
                                                            </td> */}
                                                            <td>
                                                                <div class="d-flex px-2 py-1">
                                                                    <div class="d-flex flex-column justify-content-center">
                                                                        <h6 class="mb-0 text-xs">{completionPercentage}% Completed</h6>
                                                                        {/* <h6 class="mb-0 text-xs">{data.totalReviews > 0 ? completionPercentage + 20 : completionPercentage}% Completed</h6> */}
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <p class="text-xs font-weight-bold mb-0">{imageLength}</p>
                                                            </td>
                                                            <td>
                                                                <p class="text-xs font-weight-bold mb-0">{servicesLength}</p>
                                                            </td>
                                                            <td>
                                                                <div class="d-flex px-2 py-1">
                                                                    <div class="d-flex flex-column justify-content-center">
                                                                        <h6 class="mb-0 text-xs">{data.totalReviews}</h6>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div class="d-flex px-2 py-1">
                                                                    <div class="d-flex flex-column justify-content-center">
                                                                        <h6 class="mb-0 text-xs">{data.averageRating}</h6>
                                                                    </div>
                                                                </div>
                                                            </td>

                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>

        </>
    )
}

export default PortfolioAnalyser;


