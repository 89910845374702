import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import db from "./Firebase";
import { CSVLink } from "react-csv";
import Moment from 'moment';
import { extendMoment } from 'moment-range';

function WorkshopAttendance() {
    const moment = extendMoment(Moment);
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const [lastVisible, setLastVisible] = useState(null);
    const [hasMore, setHasMore] = useState(true);

    const headers = [
        { label: "Workshop Name", key: "workshop_name" },
        { label: "Full Name", key: "full_name" },
        { label: "Phone No", key: "phone_no" },
    ];

    useEffect(() => {
        fetchInitialData();
    }, []);

    const fetchInitialData = async () => {
        setIsLoading(true);
        const snapshot = await db.collection("workshop_attendance")
            .orderBy("created_date", "desc")
            .limit(20)
            .get();
        const lastVisibleDoc = snapshot.docs[snapshot.docs.length - 1];
        const newData = snapshot.docs.map((doc) => ({
            id: doc.id,
            data: doc.data(),
        }));

        setData(newData);
        setLastVisible(lastVisibleDoc);
        setHasMore(snapshot.docs.length === 20);
        setIsLoading(false);
    };

    const loadMoreData = async () => {
        if (!lastVisible) return;

        setIsLoading(true);
        const snapshot = await db.collection("workshop_attendance")
            .orderBy("created_date", "desc")
            .startAfter(lastVisible)
            .limit(20)
            .get();
        const lastVisibleDoc = snapshot.docs[snapshot.docs.length - 1];
        const newData = snapshot.docs.map((doc) => ({
            id: doc.id,
            data: doc.data(),
        }));

        setData((prevData) => [...prevData, ...newData]);
        setLastVisible(lastVisibleDoc);
        setHasMore(snapshot.docs.length === 20);
        setIsLoading(false);
    };

    const handleSearch = (e) => {
        setSearchQuery(e.target.value);
    };

    const filteredData = data.filter((item) => {
        const full_name = item.data.full_name.toLowerCase();
        const workshop_name = item.data.workshop_name.toLowerCase();
        const phone_no = item.data.phone_no.toLowerCase();
        const query = searchQuery.toLowerCase();

        return (
            full_name.includes(query) ||
            workshop_name.includes(query) ||
            phone_no.includes(query)
        );
    });

    const csvData = filteredData.map(({ data }) => ({
        workshop_name: data.workshop_name,
        full_name: data.full_name,
        phone_no: data.phone_no,
    }));

    return (
        <>
            <div className="p-2" style={{ paddingBottom: "5px", display: "flex", justifyContent: "space-between" }}>
                <Link to="/admin_panel">
                    <img src="/images/menubutton.png" className="menu-toggle" alt="Responsive image" style={{ height: "2rem" }} />
                </Link>
                <CSVLink data={csvData} headers={headers} filename={"workshop_attendance.csv"}>
                    <span className="btn bg-gradient-secondary btn-sm mb-0 mr-2" style={{ color: "white" }}>&nbsp; Download Excel</span>
                </CSVLink>
            </div>
            <div className="card-header pb-0 px-3" style={{ display: "flex", justifyContent: "center" }}>
                <h6 className="mb-2">Workshop Attendance List || {filteredData.length}</h6>
            </div>
            <div className="p-3">
                <input
                    type="text"
                    className="form-control form-control-sm mb-2"
                    placeholder="Search by Workshop Name"
                    value={searchQuery}
                    onChange={handleSearch}
                />
                <div className="table-responsive fix-width scroll-inner">
                    <table className="table table-bordered table-hover">
                        <thead className="thead-dark" style={{ position: "sticky", top: "0" }}>
                            <tr>
                                <th>Sr. No</th>
                                <th>Workshop Name</th>
                                <th>Full Name</th>
                                <th>Phone No.</th>
                                <th>Created Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredData.map(({ id, data }, index) => (
                                <tr key={id}>
                                    <td>{index + 1}</td>
                                    <td>{data.workshop_name}</td>
                                    <td>{data.full_name}</td>
                                    <td>{data.phone_no}</td>
                                    <td>{moment(data.created_date.toDate().toISOString()).format("MM/DD/YY, h:mm a")}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {hasMore && (
                        <div className="" style={{ paddingTop: "2rem", display: "flex", justifyContent: "center" }}>
                            <button onClick={loadMoreData} className="btn btn-dark" disabled={isLoading}>
                                {isLoading ? "Loading..." : "Load More"}
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}

export default WorkshopAttendance;


// import React, { useState, useEffect } from "react";
// import { useNavigate, Link, useParams } from 'react-router-dom';
// import Swal from 'sweetalert2'
// import db from "./Firebase";
// import { storage } from './Firebase';
// import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
// import Moment from 'moment';
// import { extendMoment } from 'moment-range';
// import { CSVLink } from "react-csv";

// function WorkshopAttendance() {

//     const moment = extendMoment(Moment);
//     const [data, setData] = useState([]);
//     const [isLoading, setIsLoading] = useState(false);
//     const [show, setshow] = useState(true);
//     const [addTrends, setAddTrends] = useState(false);
//     const [progresspercent, setProgresspercent] = useState(0);

//     const [editID, setEditID] = useState("");
//     const [languageID, setLanguageID] = useState("english");

//     const [searchQuery, setSearchQuery] = useState("");
//     const [availabilityFilter, setAvailabilityFilter] = useState("all");


//     const headers = [
//         { label: "Workshop Name", key: "workshop_name" },
//         { label: "Full Name", key: "full_name" },
//         { label: "Phone No", key: "phone_no" },
//     ];


//     useEffect(() => {
//         db.collection("workshop_attendance").orderBy("created_date", "desc").onSnapshot((snapshot) => {
//             setData(
//                 snapshot.docs.map((doc) => ({
//                     id: doc.id,
//                     data: doc.data(),
//                 }))
//             );
//         });

//     }, []);


//     const handleSearch = (e) => {
//         setSearchQuery(e.target.value); // Step 3: Update search query state
//     }


//     // Function to filter data based on the search query
//     const filteredData = data.filter((item) => {
//         const full_name = item.data.full_name.toLowerCase();
//         const workshop_name = item.data.workshop_name.toLowerCase();
//         const phone_no = item.data.phone_no.toLowerCase();
//         const query = searchQuery.toLowerCase();

//         return (
//             full_name.includes(query) ||
//             workshop_name.includes(query) ||
//             phone_no.includes(query)
//         );
//     });

//     const csvData = filteredData.map(({ id, data }, index) => ({
//         workshop_name: data.workshop_name,
//         full_name: data.full_name,
//         phone_no: data.phone_no,
//     }));




//     return (
//         <>
//             <div class="p-2" style={{ paddingBottom: "5px", display: "flex", justifyContent: "space-between" }}>
//                 {/* <span class="btn bg-gradient-secondary btn-sm mb-0 mr-2" style={{ color: "white" }} >&nbsp; Download Excel</span> */}
//                 <Link to="/admin_panel"><img src="/images/menubutton.png" class="menu-toggle" alt="Responsive image" style={{ height: "2rem" }} /></Link>
//                 <CSVLink data={csvData} headers={headers} filename={"workshop_attendance.csv"}>
//                     <span class="btn bg-gradient-secondary btn-sm mb-0 mr-2" style={{ color: "white" }} >&nbsp; Download Excel</span>
//                 </CSVLink>

//             </div>
//             <div class="card-header pb-0 px-3" style={{ display: "flex", justifyContent: "center" }}>
//                 <h6 class="mb-2">Workshop Attendance List || {filteredData.length}</h6>
//             </div>
//             <div class="p-3">


//                 <input
//                     type="text"
//                     className="form-control form-control-sm mb-2"
//                     placeholder="Search by Workshop Name"
//                     value={searchQuery}
//                     onChange={handleSearch}
//                 />

//                 {/* <div>
//                     <label class="mr-3">Recording: </label>
//                     <select value={availabilityFilter} onChange={handleAvailabilityFilter} className="form-control-sm mb-2">
//                         <option value="all">All</option>
//                         <option value="available">Available</option>
//                         <option value="notAvailable">Not Available</option>
//                     </select>
//                 </div> */}

//                 <div class="table-responsive fix-width scroll-inner">
//                     <table class="table table-bordered table-hover">
//                         <thead class="thead-dark" style={{ position: "sticky", top: "0" }}>
//                             <tr>
//                                 <th>Sr. No</th>
//                                 <th>Workshop Name</th>
//                                 <th>Full Name</th>
//                                 <th>Phone No.</th>
//                                 <th>Created Date.</th>
//                             </tr>
//                         </thead>
//                         <tbody>
//                             {filteredData?.map(({ id, data }, index) => (
//                                 <>
//                                     <tr>
//                                         <td>{index + 1}</td>
//                                         <td>{data.workshop_name}</td>
//                                         <td>{data.full_name}</td>
//                                         <td>{data.phone_no}</td>
//                                         <td>{moment(data.created_date.toDate().toISOString()).format("MM/DD/YY, h:mm a")}</td>


//                                     </tr>
//                                 </>
//                             ))}


//                         </tbody>
//                     </table>
//                 </div>
//             </div>



//         </>
//     )
// }

// export default WorkshopAttendance
