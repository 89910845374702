import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import "./style.css";
import { useNavigate, Link, useParams } from "react-router-dom"
import Select from 'react-select';
import NumberCounter from 'number-counter';
import FooterMain from "./FooterMain";

export const Mehndi = () => {
    const [count, setCount] = useState(0);
    useEffect(() => {
        window.scrollTo(0, 0);
        for (let count = 0; count <= 500; count++) {
            setTimeout(() => {
                setCount(count)
            }, 1000);
        }
    }, []);

    const [selectedImage, setSelectedImage] = useState(null);


    const tipsImages = [
        { title: "", subtitle: "Indian designs", image_url: "/mehndi/Rectangle 160.png", description: "Inspired by earth, nature, and emotions, this elaborate yet condensed style of mehndi application displays great finesse and meaning in every minute intricacy." },
        { title: "", subtitle: "Arabic designs", image_url: "/mehndi/Rectangle 171.png", description: "These designs are spread out, largely consisting of cashew patterns and a lot of shading (aka Mughlai designs)" },
        { title: "", subtitle: "Indo-Arabic designs", image_url: "/mehndi/Rectangle 172.png", description: "A rich combination of Indian floral patterns and birds with the Arabic cashew designs and shading makes for a beautiful hybrid mehndi design" },
        { title: "", subtitle: "Pakistani designs", image_url: "/mehndi/Rectangle 173.png", description: "Despite similarities to the Indian style, Pakistani designs feature more domes, flowers, and leaf like patterns instead of traditional Hindu elements" },
        { title: "", subtitle: "Western designs", image_url: "/mehndi/Rectangle 174.png", description: "The diverse world of mehndi designs inspired the West to birth the concept of henna tattoos- these designs showcase geometric shapes, quirky patterns, and imitations of hand jewelry. Popular examples include the bracelet mehndi and chandelier mehndi" },
        { title: "", subtitle: "Indo-Western designs", image_url: "/mehndi/Rectangle 175.png", description: "This hybrid strikes a balance between the East and West. Worn generally as a style statement, this set of designs feature spaced out modern patterns with a traditional touch to them" },
        { title: "", subtitle: "Moroccan designs", image_url: "/mehndi/Rectangle 176.png", description: "Inspired by traditional tribal symbols and patterns, Moroccan designs hold a similarity to Western tribal tattoo art. These designs consist of zig-zag lines, geometric curves, and details holding quirky aesthetics" },

    ]
    const handleImageClick = (index) => {
        setSelectedImage(index);
    };


    const leftPartStyle = {
        flex: 1,
        border: "none",
        boxSizing: "border-box",
        // backgroundColor: "#7d6853",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    };

    return (
        <div>
            <section>
                <div class="navi" style={{ backgroundColor: "#ec5d69" }}>
                    <input type="checkbox" id="nav-check" />
                    <div class="nav-header">
                        <div class="nav-title">
                            <Link to="/"> <img src="/bridal/youvati_white_logo.png" alt="" style={{ height: "22px", marginLeft: "1rem" }} /></Link>
                        </div>
                    </div>
                    <div class="nav-btn">
                        <label for="nav-check">
                            <span></span>
                            <span></span>
                            <span></span>
                        </label>
                    </div>

                    <div class="nav-links" id="top">
                        <a href="#portfolio" class="linkcolor" style={{ color: "white" }}>Bridal</a>
                        <span class="line" style={{ color: "white" }}>|</span>
                        <a href="#portfolio" class="linkcolor" style={{ color: "white" }}>Why Choose Us</a>
                        <span class="line" style={{ color: "white" }}>|</span>
                        <a href="#partners" class="linkcolor" style={{ color: "white" }}>For Partners</a>

                        <span class="line" style={{ color: "white" }}>|</span>
                        <a href="#reachtous" class="linkcolor" style={{ color: "white" }}>Contact Us</a>

                    </div>
                </div>
            </section>


            {/* <div class="" style={{ marginLeft: "120px", marginRight: "120px" }}> */}
            {/* <div style={{ textAlign: "center" }}>
                    <h2 style={{ fontSize: "34px", fontWeight: "700", paddingLeft: "70px", marginTop: "1.5rem", color: "#DE5A59", fontFamily: "serif" }}>Mehndi</h2>
                </div> */}
            <div style={{ textAlign: "center" }}>
                <h2 style={{ fontSize: "34px", fontWeight: "700", marginTop: "1.5rem", color: "#DE5A59", fontFamily: "serif" }}>Mehndi</h2>
            </div>
            <div class="" style={{ padding: "13px", display: "flex", justifyContent: "center" }}>
                <p style={{ textAlign: "center", fontSize: "20px", fontWeight: "400", color: "black", marginTop: "1rem", fontFamily: "serif", maxWidth: "55rem", minWidth: "15rem" }}>As you may know, Mehndi is one of the most beautiful and intricate customary decorations that adorns a bride on her special day. We have a few tips for a hassle-free and effective Mehndi application experience</p>
            </div>

            <div class="" style={{ padding: "13px", display: "flex", justifyContent: "center" }}>

                <img src="/mehndi/Group 78.png" class="img-fluid" style={{ maxWidth: "55rem", minWidth: "15rem" }} alt="bridal image"></img>

            </div>

            <div class="" style={{ padding: "13px", textAlign: "-webkit-center" }}>
                <p style={{ textAlign: "center", fontSize: "20px", fontWeight: "400", color: "black", marginTop: "1rem", fontFamily: "serif", maxWidth: "55rem", minWidth: "15rem" }}>Mehndi is supposed to be applied 48 hours prior to the main event of matrimony.</p>
                <p style={{ textAlign: "center", fontSize: "20px", fontWeight: "400", color: "black", marginTop: "1rem", fontFamily: "serif", maxWidth: "55rem", minWidth: "15rem" }}>Mehndi is supposed to be applied 48 hours prior to the main event of matrimony.</p>
                <p style={{ textAlign: "center", fontSize: "20px", fontWeight: "400", color: "black", marginTop: "1rem", fontFamily: "serif", maxWidth: "55rem", minWidth: "15rem" }}>Mehndi removal should not be followed immediately by shaving / waxing of hands /legs.</p>
                <p style={{ textAlign: "center", fontSize: "20px", fontWeight: "400", color: "black", marginTop: "1rem", fontFamily: "serif", maxWidth: "55rem", minWidth: "15rem" }}>Ensure that your henna artist uses only natural mehndi.</p>
                <p style={{ textAlign: "center", fontSize: "20px", fontWeight: "400", color: "black", marginTop: "1rem", fontFamily: "serif", maxWidth: "55rem", minWidth: "15rem" }}>Get your nails done before sitting with your henna artist, and apply Vaseline on your nails before mehndi is applied to your hands.</p>
                <p style={{ textAlign: "center", fontSize: "20px", fontWeight: "400", color: "black", marginTop: "1rem", fontFamily: "serif", maxWidth: "55rem", minWidth: "15rem" }}>Scrape off the mehndi in the morning after the day of application, and try your best not to come in contact with water or soap for the next 12 hours. Upon completing this 12 hour wait, apply coconut oil to the areas where mehndi was applied.</p>
            </div>

            <div style={{ textAlign: "center" }}>
                <h2 style={{ fontSize: "34px", fontWeight: "700", marginTop: "3rem", marginBottom: "2rem", color: "black", fontFamily: "serif" }}>Different types of Mehndi designs</h2>
            </div>

            {tipsImages.map((tip, index) => (
                <div key={index} style={{ marginBottom: "2rem", textAlign: "center" }}>
                    <div style={{ textAlign: "center" }}>
                        <h2 style={{ fontSize: "34px", fontWeight: "700", color: "black", fontFamily: "serif" }}>{tip.title}</h2>
                        {/* <div style={{ fontSize: "34px", fontWeight: "700", color: "#DE5A59", fontFamily: "serif", display: "flex", justifyContent: "center" }}><h2 style={{ fontSize: "34px", fontWeight: "700", color: "#DE5A59", fontFamily: "serif", width: "50%", textAlign: "center", borderBottom: "3.5px solid rgb(222, 90, 89)", lineHeight: "0.1em", margin: "25px 7px 40px" }}><span style={{ background: "#fff", padding: "0 10px" }}>{tip.subtitle}</span></h2></div> */}
                        <div style={{ display: "flex", justifyContent: "center" }}><span class="subs" style={{ fontSize: "34px", fontWeight: "700", color: "#DE5A59", }}>{tip.subtitle}</span></div>
                        {/* <div style={{ fontSize: "34px", fontWeight: "700", color: "#DE5A59", fontFamily: "serif", display: "flex", justifyContent: "center" }}><h2 style={{ width: "100%", textAlign: "center", borderBottom: "1px solid #000", lineHeight: "0.1em", margin: "25px 7px 40px" }}><span style={{ background: "#fff", padding: "0 10px" }}>{tip.subtitle}</span></h2></div> */}
                        {/* <div class="about-border" style={{ width: "200px", height: "3px", background: "#DE5A59", margin: "20px auto" }}></div> */}
                    </div>

                    <div class="" style={{ padding: "13px" }}>
                        <img style={{ borderRadius: "8px", width: "100%", height: "348px", objectFit: "contain" }}
                            class=""
                            src={tip.image_url}
                        />
                    </div>

                    <div class="" style={{ padding: "13px", display: "flex", justifyContent: "center" }}>
                        <p style={{ textAlign: "center", fontSize: "20px", fontWeight: "400", color: "black", fontFamily: "serif", maxWidth: "55rem", minWidth: "15rem" }}>{tip.description}</p>
                    </div>


                </div>
            ))}



            <div style={{ height: "10rem" }}></div>

            <FooterMain />

        </div>
    );
};



