import React, { useState, useEffect } from 'react';
import AdminPanel from './AdminPanel';
import Swal from 'sweetalert2'
import { useNavigate, Link, useParams } from 'react-router-dom';
import db from "./Firebase";
import { storage } from './Firebase';
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import ViewAppData from './ViewAppData';
import Multiselect from 'multiselect-react-dropdown';


function ShortlistedPortfolios() {
    const navigate = useNavigate();
    const moment = extendMoment(Moment);
    const initialFormStateFeedback = { id: '', username: '', phone_no: '', pin: 200, listID: 200, status: 1, listing: 200, starPerformer: 0, premiumUser: 0, verifiedUser: 0, customOne: 0, customTwo: 0, customThree: '', customFour: '', price: '', package: [], updated_date: new Date() }
    const [submit, setSubmit] = useState(initialFormStateFeedback)
    const [data, setData] = useState([]);
    const [editID, setEditID] = useState("");
    const [edit, setEdit] = useState(false);
    const [ShowProgress, setShowProgress] = useState(false);
    const [show, setshow] = useState(true);
    const [progresspercent, setProgresspercent] = useState(0);

    const [options, setOptions] = useState([
        { name: 'Online' },
        { name: 'Aurangabad' },
        { name: 'Bathinda' },
        { name: 'Ghaziabad' },
        { name: 'Meerut' },
        { name: 'Mansa' },
        { name: 'Patiala' }
    ]);

    const [selectedValue, setSelectedValue] = useState([]);


    const onSelect = (selectedList, selectedItem) => {
        // Handle selection
        // ...
        setSelectedValue(selectedList);
    }

    const onRemove = (selectedList, removedItem) => {
        // Handle removal
        // ...
        setSelectedValue(selectedList);
    }

    useEffect(() => {
        const storedUsername = localStorage.getItem("email");
        const storedPassword = localStorage.getItem("password");
        const storedRemember = localStorage.getItem("remember");

        if (storedRemember === "false" || !storedUsername || !storedPassword) {
            navigate('/signin');
        }
    }, []);


    const handleChange = (e) => {
        const { id, value } = e.target
        setSubmit({ ...submit, [id]: value })
    }

    const changeStatus = (data, id) => {
        setEdit(true)
        setEditID(id)
        setSubmit({ id: id, parlour_name: data.parlour_name, username: data.username, phone_no: data.phone_no, pin: data.pin, listID: data.listID, status: data.status, listing: data.listing, starPerformer: data.starPerformer, premiumUser: data.premiumUser, verifiedUser: data.verifiedUser, customOne: data.customOne, customTwo: data.customTwo, customThree: data.customThree, customFour: data.customFour, price: data.price, package: data.package, updated_date: new Date() })
        openModalShow();
    }

    const changeStatusAdd = () => {
        setEdit(false)
        setSubmit({ id: '', parlour_name: "", username: '', phone_no: '', pin: 200, listID: 200, status: 1, listing: 200, starPerformer: 0, premiumUser: 0, verifiedUser: 0, customOne: 0, customTwo: 0, customThree: '', customFour: '', price: '', package: [], updated_date: new Date() });
        openModalShow();
    }


    const openModalShow = () => {
        // alert("ooo")
        window.openModelshow();
    }

    const closeModel = () => {
        window.openModelNew();
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        // console.log(locationData);

        db.collection("website_portfolio_listing").doc(editID).update({
            'pin': submit.pin,
            'listID': parseInt(submit.listID),
            'status': submit.status,
            'listing': submit.listing,
            'starPerformer': submit.starPerformer,
            'premiumUser': submit.premiumUser,
            'verifiedUser': submit.verifiedUser,
            'customOne': submit.customOne,
            'customTwo': submit.customTwo,
            'customThree': submit.customThree,
            'customFour': submit.customFour,
            'package': submit.package,
            'customFour': submit.customFour,
            'updated_date': new Date(),
        }).then(() => {
            console.log('Updated Successfully');
            const Toast = Swal.mixin({
                toast: true,
                background: '#69aba6',
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            })

            Toast.fire({
                icon: 'success',
                title: 'Updated Successfully'
            })
            closeModel();
            setSubmit({ id: '', parlour_name: "", username: '', phone_no: '', pin: 200, listID: 200, status: 1, listing: 200, starPerformer: 0, premiumUser: 0, verifiedUser: 0, customOne: 0, customTwo: 0, customThree: '', customFour: '', price: '', package: [], updated_date: new Date() })
        })



    }



    useEffect(() => {
        db.collection("website_portfolio_listing").orderBy("listID", "asc").onSnapshot((snapshot) => {

            setData(
                snapshot.docs.map((doc) => ({
                    id: doc.id,
                    data: doc.data(),
                }))
            );
        });


    }, []);

    return (
        <>
            <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
                {/* <ViewAppData /> */}
                <div class="p-2" style={{ textAlign: "start", paddingBottom: "5px" }}>
                    {/* <span class="btn bg-gradient-secondary btn-sm mb-0 mr-2" style={{ color: "white" }} >&nbsp; Download Excel</span> */}
                    <Link to="/admin_panel"><img src="/images/menubutton.png" class="menu-toggle" alt="Responsive image" style={{ height: "2rem" }} /></Link>

                </div>

                <div class="">
                    <div class="card">
                        <h4>Shortlisted Portfolio</h4>
                        <div class="table-responsive fix-width scroll-inner">
                            <table class="table align-items-center mb-0">
                                <thead>
                                    <tr style={{ textAlign: "left" }}>
                                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Parlour Name</th>
                                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">User Name</th>
                                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Phone No</th>
                                        <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Listing Order</th>
                                        <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Price</th>
                                        <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Status</th>
                                        <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Action</th>
                                        {/* <th class="text-secondary opacity-7"></th> */}
                                    </tr>
                                </thead>
                                <tbody style={{ textAlign: "left" }}>
                                    {data?.map(({ id, data }, index) => (
                                        <>
                                            <tr>

                                                <td>
                                                    <p class="text-xs font-weight-bold mb-0">{data.parlour_name}</p>
                                                    {/* <p class="text-xs text-secondary mb-0">Organization</p> */}
                                                </td>
                                                <td>
                                                    <Link to={`/${data.username}`}> <p class="text-info text-xs font-weight-bold mb-0">{data.username}</p></Link>
                                                    {/* <p class="text-xs text-secondary mb-0">Organization</p> */}
                                                </td>
                                                <td class="align-middle text-center text-sm">
                                                    <p class="text-xs font-weight-bold mb-0">{data.phone_no}</p>
                                                </td>
                                                <td class="align-middle text-center text-sm">
                                                    <p class="text-xs font-weight-bold mb-0">{data.listID}</p>
                                                </td>
                                                <td class="align-middle text-center text-sm">
                                                    <p class="text-xs font-weight-bold mb-0">{data.price}</p>
                                                </td>

                                                <td class="align-middle text-center text-sm">
                                                    <span class="text-secondary text-xs font-weight-normal">{moment(data.updated_date.toDate().toISOString()).format("MM/DD/YY, h:mm a")}</span>
                                                </td>

                                                <td class="align-middle text-center" onClick={() => changeStatus(data, id)}>
                                                    <span class="text-info text-xs font-weight-normal" style={{ cursor: "pointer" }}>Edit</span>
                                                </td>

                                            </tr>
                                        </>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

            </main>






            <div class="col-md-12">
                <div class="myId modal fade" id="" tabindex="-1" role="dialog" aria-labelledby="modal-form" aria-hidden="true" data-backdrop="static">
                    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div class="modal-content">
                            <div class="modal-body">


                                <div class="">
                                    <form onSubmit={handleSubmit}>
                                        <div class="">
                                            <div class="" style={{ textAlign: "center", }}><span style={{ color: "black", fontSize: "25px", fontWeight: "700", textTransform: "uppercase" }}>Edit Shortlisted Portfolio</span></div>
                                            <div class="" style={{ textAlign: "center", color: "black", paddingTop: "10px" }}>
                                            </div>
                                            <div class="">
                                                <div class="row">
                                                    <div class="col-md-4">
                                                        <span style={{ fontSize: "12px", fontWeight: "500", color: "grey" }}>Parlour Name</span>
                                                        <input style={{
                                                            // width: "17rem",
                                                            height: "45px",
                                                            padding: "12px 20px",
                                                            boxSizing: "border-box",
                                                            border: "2px solid #ccc",
                                                            borderRadius: "4px",
                                                            backgroundColor: "#f8f8f8",
                                                            fontSize: "16px",
                                                            resize: "none"
                                                        }} class="w3l_text_box" id="parlour_name" placeholder="Parlour Name" name="parlour_name" type="text" onChange={handleChange} value={submit.parlour_name} disabled />
                                                    </div>

                                                    <div class="col-md-4">
                                                        <span style={{ fontSize: "12px", fontWeight: "500", color: "grey" }}>Phone Number</span>
                                                        <input style={{
                                                            // width: "17rem",
                                                            height: "45px",
                                                            padding: "12px 20px",
                                                            boxSizing: "border-box",
                                                            border: "2px solid #ccc",
                                                            borderRadius: "4px",
                                                            backgroundColor: "#f8f8f8",
                                                            fontSize: "16px",
                                                            resize: "none"
                                                        }} class="w3l_text_box" id="phone_no" placeholder="Phone No" name="Phone No" type="text" onChange={handleChange} value={submit.phone_no} required />
                                                    </div>
                                                    <div class="col-md-4">
                                                        <span style={{ fontSize: "12px", fontWeight: "500", color: "grey" }}>Listing Order</span>
                                                        <input style={{
                                                            // width: "17rem",
                                                            height: "45px",
                                                            padding: "12px 20px",
                                                            boxSizing: "border-box",
                                                            border: "2px solid #ccc",
                                                            borderRadius: "4px",
                                                            backgroundColor: "#f8f8f8",
                                                            fontSize: "16px",
                                                            resize: "none"
                                                        }} class="w3l_text_box" id="listID" placeholder="Listing Order" name="Listing Order" type="text" onChange={handleChange} value={submit.listID} required />
                                                    </div>

                                                </div>
                                            </div>


                                            <div class="pb-4">
                                                <div class="row">
                                                    <div class="col-md-4">
                                                        <span style={{ fontSize: "12px", fontWeight: "500", color: "grey" }}>Price</span>
                                                        <input style={{
                                                            // width: "17rem",
                                                            height: "45px",
                                                            padding: "12px 20px",
                                                            boxSizing: "border-box",
                                                            border: "2px solid #ccc",
                                                            borderRadius: "4px",
                                                            backgroundColor: "#f8f8f8",
                                                            fontSize: "16px",
                                                            resize: "none"
                                                        }} class="w3l_text_box" id="price" placeholder="Price" name="Price" onChange={handleChange} value={submit.price} type="text" required />
                                                    </div>
                                                    <div class="col-md-4">
                                                        <span style={{ fontSize: "12px", fontWeight: "500", color: "grey" }}>Status</span>
                                                        <input style={{
                                                            // width: "17rem",
                                                            height: "45px",
                                                            padding: "12px 20px",
                                                            boxSizing: "border-box",
                                                            border: "2px solid #ccc",
                                                            borderRadius: "4px",
                                                            backgroundColor: "#f8f8f8",
                                                            fontSize: "16px",
                                                            resize: "none"
                                                        }} class="w3l_text_box" id="status" placeholder="Status" name="Status" onChange={handleChange} value={submit.status} type="text" required />
                                                    </div>
                                                </div>
                                            </div>
                                            {/* 



                                            <textarea style={{
                                                width: "100%",
                                                height: "15px",
                                                padding: "12px 20px",
                                                boxSizing: "border-box",
                                                border: "2px solid #ccc",
                                                borderRadius: "4px",
                                                backgroundColor: "#f8f8f8",
                                                fontSize: "16px",
                                                resize: "none"
                                            }} class="w3l_text_box" id="message" placeholder="Write a message..." name="Message" onChange={handleChange} value={submit.message} required />


 */}


                                            <div class="modal-footer">
                                                {/* {submit.id == "" ? <></> : <a class="btn bg-gradient-danger btn-sm mb-0" style={{ color: "white" }} onClick={() => deleteData(submit.id)}>Delete</a>} */}
                                                <a class="btn bg-gradient-info btn-sm mb-0" style={{ color: "white" }} onClick={closeModel}>Close</a>
                                                <button type='submit' class="btn bg-gradient-danger btn-sm mb-0" style={{ backgroundColor: "green", color: "white" }}>{edit ? "Update" : "Submit"}</button>
                                            </div>


                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >

        </>
    )
}

export default ShortlistedPortfolios;
