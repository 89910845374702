import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import db from "./Firebase";
import { CSVLink } from "react-csv";

function UpcomingSession() {
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [uidDetailsMap, setUidDetailsMap] = useState({});
    const [searchTerm, setSearchTerm] = useState('');
    const [csvData, setCsvData] = useState([]);

    const headers = [
        { label: "Session Name", key: "session_name" },
        { label: "Session Date", key: "session_date" },
        { label: "Registered Name", key: "registered_name" },
        { label: "Registered Phone", key: "registered_phone" }
    ];

    useEffect(() => {
        const storedUsername = localStorage.getItem("email");
        const storedPassword = localStorage.getItem("password");
        const storedRemember = localStorage.getItem("remember");

        if (storedRemember === "false" || !storedUsername || !storedPassword) {
            navigate('/signin');
        }
    }, [navigate]);

    useEffect(() => {
        const fetchData = async () => {
            const sessionsSnapshot = await db.collection("guidance_session").get();
            const sessions = sessionsSnapshot.docs.map(doc => ({
                id: doc.id,
                data: doc.data()
            }));

            setData(sessions);

            const allPhones = [];
            sessions.forEach(session => {
                const phone = session.data.phone;
                if (phone && !allPhones.includes(phone)) {
                    allPhones.push(phone);
                }
            });

            if (allPhones.length > 0) {
                const promises = allPhones.map(uid => db.collection("personal_details").doc(uid).get());
                Promise.all(promises).then(querySnapshots => {
                    const uidDetailsMap = {};
                    querySnapshots.forEach(doc => {
                        if (doc.exists) {
                            const data = doc.data();
                            uidDetailsMap[doc.id] = { name: data.full_name, phone_no: data.phone_no };
                        }
                    });
                    setUidDetailsMap(uidDetailsMap);

                    const formattedCsvData = sessions.map(session => ({
                        session_name: session.data.session_name,
                        session_date: session.data.session_date,
                        registered_name: uidDetailsMap[session.data.phone]?.name || session.data.registered_name,
                        registered_phone: uidDetailsMap[session.data.phone]?.phone_no || session.data.phone
                    }));

                    console.log('Formatted CSV Data:', formattedCsvData); // Add logging
                    setCsvData(formattedCsvData);
                });
            }
        };

        fetchData();
    }, []);

    const filteredData = data.filter(session => 
        session.data.session_name?.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <>
            <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
                <div className="p-2" style={{ paddingBottom: "5px", display: "flex", justifyContent: "space-between" }}>
                    <Link to="/admin_panel"><img src="/images/menubutton.png" className="menu-toggle" alt="Responsive image" style={{ height: "2rem" }} /></Link>
                    <input 
                        type="text" 
                        placeholder="Search Workshop Name" 
                        value={searchTerm} 
                        onChange={e => setSearchTerm(e.target.value)} 
                        className="form-control"
                        style={{ maxWidth: "300px", marginRight: "10px" }}
                    />
                    <CSVLink 
                        data={csvData} 
                        headers={headers}
                        filename="upcoming_sessions.csv"
                        className="btn btn-dark"
                        style={{ height: "2rem" }}
                    >
                        Download Excel
                    </CSVLink>
                </div>
                <div className="">
                    <div className="p-2">
                        <div style={{ textAlign: "end", paddingBottom: "5px" }}>
                        </div>
                        <div className="card">
                            <div className="card-header pb-0 px-3">
                                <h6 className="mb-2">Guidance Session</h6>
                            </div>
                            <div className="">
                                <div className="card">
                                    <div className="table-responsive fix-width scroll-inner">
                                        <table className="table table-bordered table-hover">
                                            <thead className="thead-dark" style={{ position: "sticky", top: "0" }}>
                                                <tr>
                                                    <th className="col">Session Name</th>
                                                    <th className="col">Session Date</th>
                                                    <th className="col ps-2">Registered Name</th>
                                                    <th className="col ps-2">Registered Phone</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {filteredData.map(({ id, data }) => (
                                                    <tr key={id}>
                                                        <td>
                                                            <p className="text-xs font-weight-bold mb-0">{data.session_name}</p>
                                                        </td>
                                                        <td>
                                                            <p className="text-xs font-weight-bold mb-0">{data.session_date}</p>
                                                        </td>
                                                        <td>
                                                            <p className="text-xs font-weight-bold mb-0">{uidDetailsMap[data.phone]?.name || data.registered_name}</p>
                                                        </td>
                                                        <td>
                                                            <p className="text-xs font-weight-bold mb-0">{uidDetailsMap[data.phone]?.phone_no || data.phone}</p>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
}

export default UpcomingSession;




// import React, { useState, useEffect } from 'react';
// import { useNavigate, Link } from 'react-router-dom';
// import db from "./Firebase";
// import { CSVLink } from "react-csv";

// function UpcomingSession() {
//     const navigate = useNavigate();
//     const [data, setData] = useState([]);
//     const [uidDetailsMap, setUidDetailsMap] = useState({});
//     const [searchTerm, setSearchTerm] = useState('');
//     const [csvData, setCsvData] = useState([]);

//     const headers = [
//         { label: "Session Name", key: "session_name" },
//         { label: "Registered Name", key: "registered_name" },
//         { label: "Registered Phone", key: "registered_phone" }
//     ];

//     useEffect(() => {
//         const storedUsername = localStorage.getItem("email");
//         const storedPassword = localStorage.getItem("password");
//         const storedRemember = localStorage.getItem("remember");

//         if (storedRemember === "false" || !storedUsername || !storedPassword) {
//             navigate('/signin');
//         }
//     }, [navigate]);

//     useEffect(() => {
//         const fetchData = async () => {
//             const sessionsSnapshot = await db.collection("guidance_session").get();
//             const sessions = sessionsSnapshot.docs.map(doc => ({
//                 id: doc.id,
//                 data: doc.data()
//             }));

//             setData(sessions);

//             const allPhones = [];
//             sessions.forEach(session => {
//                 const phone = session.data.phone;
//                 if (phone && !allPhones.includes(phone)) {
//                     allPhones.push(phone);
//                 }
//             });

//             // if (allPhones.length > 0) {
//             //     console.log(allPhones)
//             //     const promises = allPhones.map(phone => 
//             //         db.collection("personal_details").doc(phone).get()
//             //     );
//             //     const querySnapshots = await Promise.all(promises);
                

//             //     const phoneDetailsMap = {};
//             //     querySnapshots.forEach(querySnapshot => {
//             //         querySnapshot.forEach(doc => {
//             //             const data = doc.data();
//             //             console.log(first)
//             //             // phoneDetailsMap[data.phone_no] = { name: data.full_name, phone_no: data.phone_no };
//             //             phoneDetailsMap[doc.id] = { name: data.full_name, phone_no: data.phone_no };
//             //         });
//             //     });
//             //     setUidDetailsMap(phoneDetailsMap);

//             //     const formattedCsvData = sessions.map(session => ({
//             //         session_name: session.data.session_name,
//             //         registered_name: phoneDetailsMap[session.data.phone]?.name || session.data.registered_name,
//             //         registered_phone: phoneDetailsMap[session.data.phone]?.phone_no || session.data.phone
//             //     }));

//             //     setCsvData(formattedCsvData);
//             // }

//             if (allPhones.length > 0) {
//                 const promises = allPhones.map(uid => db.collection("personal_details").doc(uid).get());
//                 Promise.all(promises).then(querySnapshots => {
//                     const uidDetailsMap = {};
//                     querySnapshots.forEach(doc => {
//                         if (doc.exists) {
//                             const data = doc.data();
//                             uidDetailsMap[doc.id] = { name: data.full_name, phone_no: data.phone_no };
//                         }
//                     });
//                     setUidDetailsMap(uidDetailsMap);
//                 });
//             }
//         };

//         fetchData();
//     }, []);

//     const filteredData = data.filter(session => 
//         session.data.session_name?.toLowerCase().includes(searchTerm.toLowerCase())
//     );

//     return (
//         <>
//             <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
//                 <div class="p-2" style={{ paddingBottom: "5px", display: "flex", justifyContent: "space-between" }}>
//                     <Link to="/admin_panel"><img src="/images/menubutton.png" class="menu-toggle" alt="Responsive image" style={{ height: "2rem" }} /></Link>
//                     <input 
//                         type="text" 
//                         placeholder="Search Workshop Name" 
//                         value={searchTerm} 
//                         onChange={e => setSearchTerm(e.target.value)} 
//                         class="form-control"
//                         style={{ maxWidth: "300px", marginRight: "10px" }}
//                     />
//                     <CSVLink 
//                         data={csvData} 
//                         headers={headers}
//                         filename="upcoming_sessions.csv"
//                         class="btn btn-dark"
//                         style={{ height: "2rem" }}
//                     >
//                         Download Excel
//                     </CSVLink>
//                 </div>
//                 <div class="">
//                     <div class="p-2">
//                         <div style={{ textAlign: "end", paddingBottom: "5px" }}>
//                         </div>
//                         <div class="card">
//                             <div class="card-header pb-0 px-3">
//                                 <h6 class="mb-2">Guidance Session</h6>
//                             </div>
//                             <div class="">
//                                 <div class="card">
//                                     <div class="table-responsive fix-width scroll-inner">
//                                         <table class="table table-bordered table-hover">
//                                             <thead class="thead-dark" style={{ position: "sticky", top: "0" }}>
//                                                 <tr>
//                                                     <th class="col">Session Name</th>
//                                                     <th class="col">Session Date</th>
//                                                     <th class="col ps-2">Registered Name</th>
//                                                     <th class="col ps-2">Registered Phone</th>
//                                                 </tr>
//                                             </thead>
//                                             <tbody>
//                                                 {filteredData?.map(({ id, data }) => (
//                                                     <tr key={id}>
//                                                         <td>
//                                                             <p class="text-xs font-weight-bold mb-0">{data.session_name}</p>
//                                                         </td>
//                                                         <td>
//                                                             <p class="text-xs font-weight-bold mb-0">{data.session_date}</p>
//                                                         </td>
//                                                         <td>
//                                                             <p class="text-xs font-weight-bold mb-0">{uidDetailsMap[data.phone]?.name || data.registered_name}</p>
//                                                         </td>
//                                                         <td>
//                                                             <p class="text-xs font-weight-bold mb-0">{uidDetailsMap[data.phone]?.phone_no || data.phone}</p>
//                                                         </td>
//                                                     </tr>
//                                                 ))}
//                                             </tbody>
//                                         </table>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </main>
//         </>
//     );
// }

// export default UpcomingSession;






// // import React, { useState, useEffect } from 'react';
// // import { useNavigate, Link } from 'react-router-dom';
// // import db from "./Firebase";
// // import { CSVLink } from "react-csv";

// // function UpcomingSession() {
// //     const navigate = useNavigate();
// //     const [data, setData] = useState([]);
// //     const [uidDetailsMap, setUidDetailsMap] = useState({});
// //     const [searchTerm, setSearchTerm] = useState('');

// //     useEffect(() => {
// //         const storedUsername = localStorage.getItem("email");
// //         const storedPassword = localStorage.getItem("password");
// //         const storedRemember = localStorage.getItem("remember");

// //         if (storedRemember === "false" || !storedUsername || !storedPassword) {
// //             navigate('/signin');
// //         }
// //     }, []);

// //     useEffect(() => {
// //         db.collection("guidance_session").onSnapshot((snapshot) => {
// //             const sessions = snapshot.docs.map((doc) => ({
// //                 id: doc.id,
// //                 data: doc.data()
// //             }));

// //             setData(sessions);

// //             const allUids = [];
// //             sessions.forEach(session => {
// //                 session.data.registered_uid.forEach(uid => {
// //                     if (!allUids.includes(uid)) {
// //                         allUids.push(uid);
// //                     }
// //                 });
// //             });

// //             if (allUids.length > 0) {
// //                 const promises = allUids.map(uid => db.collection("personal_details").doc(uid).get());
// //                 Promise.all(promises).then(querySnapshots => {
// //                     const uidDetailsMap = {};
// //                     querySnapshots.forEach(doc => {
// //                         if (doc.exists) {
// //                             const data = doc.data();
// //                             uidDetailsMap[doc.id] = { name: data.full_name, phone_no: data.phone_no };
// //                         }
// //                     });
// //                     setUidDetailsMap(uidDetailsMap);
// //                 });
// //             }
// //         });
// //     }, []);

// //     const filteredData = data.filter(session => 
// //         session.data.name.toLowerCase().includes(searchTerm.toLowerCase())
// //     );

// //     const csvData = filteredData.flatMap(({ id, data }) => 
// //         data.registered_uid.map(uid => ({
// //             session_name: data.name,
// //             registered_name: uidDetailsMap[uid]?.name || uid,
// //             registered_phone: uidDetailsMap[uid]?.phone_no || 'N/A'
// //         }))
// //     );

// //     const headers = [
// //         { label: "Session Name", key: "session_name" },
// //         { label: "Registered Name", key: "registered_name" },
// //         { label: "Registered Phone", key: "registered_phone" }
// //     ];

// //     return (
// //         <>
// //             <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
// //                 <div class="p-2" style={{ paddingBottom: "5px", display: "flex", justifyContent: "space-between" }}>
// //                     <Link to="/admin_panel"><img src="/images/menubutton.png" class="menu-toggle" alt="Responsive image" style={{ height: "2rem" }} /></Link>
// //                     <input 
// //                         type="text" 
// //                         placeholder="Search Workshop Name" 
// //                         value={searchTerm} 
// //                         onChange={e => setSearchTerm(e.target.value)} 
// //                         class="form-control"
// //                         style={{ maxWidth: "300px", marginRight: "10px" }}
// //                     />
// //                     <CSVLink 
// //                         data={csvData} 
// //                         headers={headers}
// //                         filename="upcoming_sessions.csv"
// //                         class="btn btn-dark"
// //                         style={{ height: "2rem" }}
// //                     >
// //                         Download Excel
// //                     </CSVLink>
// //                 </div>
// //                 <div class="">
// //                     <div class="p-2">
// //                         <div style={{ textAlign: "end", paddingBottom: "5px" }}>
// //                         </div>
// //                         <div class="card">
// //                             <div class="card-header pb-0 px-3">
// //                                 <h6 class="mb-2">Guidance Session</h6>
// //                             </div>
// //                             <div class="">
// //                                 <div class="card">
// //                                     <div class="table-responsive fix-width scroll-inner">
// //                                         <table class="table table-bordered table-hover">
// //                                             <thead class="thead-dark" style={{ position: "sticky", top: "0" }}>
// //                                                 <tr>
// //                                                     <th class="col">Session Name</th>
// //                                                     <th class="col ps-2">Registered Names</th>
// //                                                     <th class="col ps-2">Registered Phone</th>
// //                                                 </tr>
// //                                             </thead>
// //                                             <tbody>
// //                                                 {filteredData?.map(({ id, data }) => (
// //                                                     data.registered_uid.map((uid, index) => (
// //                                                         <tr key={`${id}-${uid}`}>
// //                                                             <td>
// //                                                                 <p class="text-xs font-weight-bold mb-0">{data.name}</p>
// //                                                             </td>
// //                                                             <td>
// //                                                                 <p class="text-xs font-weight-bold mb-0">{uidDetailsMap[uid]?.name || uid}</p>
// //                                                             </td>
// //                                                             <td>
// //                                                                 <p class="text-xs font-weight-bold mb-0">{uidDetailsMap[uid]?.phone_no || 'N/A'}</p>
// //                                                             </td>
// //                                                         </tr>
// //                                                     ))
// //                                                 ))}
// //                                             </tbody>
// //                                         </table>
// //                                     </div>
// //                                 </div>
// //                             </div>
// //                         </div>
// //                     </div>
// //                 </div>
// //             </main>
// //         </>
// //     );
// // }

// // export default UpcomingSession;


// // import React, { useState, useEffect } from 'react';
// // import { useNavigate, Link } from 'react-router-dom';
// // import db from "./Firebase";
// // import { CSVLink } from "react-csv";

// // function UpcomingSession() {
// //     const navigate = useNavigate();
// //     const [data, setData] = useState([]);
// //     const [uidDetailsMap, setUidDetailsMap] = useState({});
// //     const [searchTerm, setSearchTerm] = useState('');

// //     useEffect(() => {
// //         const storedUsername = localStorage.getItem("email");
// //         const storedPassword = localStorage.getItem("password");
// //         const storedRemember = localStorage.getItem("remember");

// //         if (storedRemember === "false" || !storedUsername || !storedPassword) {
// //             navigate('/signin');
// //         }
// //     }, []);

// //     useEffect(() => {
// //         db.collection("upcoming_sessions").onSnapshot((snapshot) => {
// //             const sessions = snapshot.docs.map((doc) => ({
// //                 id: doc.id,
// //                 data: doc.data()
// //             }));

// //             setData(sessions);

// //             const allUids = [];
// //             sessions.forEach(session => {
// //                 session.data.registered_uid.forEach(uid => {
// //                     if (!allUids.includes(uid)) {
// //                         allUids.push(uid);
// //                     }
// //                 });
// //             });

// //             if (allUids.length > 0) {
// //                 const promises = allUids.map(uid => db.collection("personal_details").doc(uid).get());
// //                 Promise.all(promises).then(querySnapshots => {
// //                     const uidDetailsMap = {};
// //                     querySnapshots.forEach(doc => {
// //                         if (doc.exists) {
// //                             const data = doc.data();
// //                             uidDetailsMap[doc.id] = { name: data.full_name, phone_no: data.phone_no };
// //                         }
// //                     });
// //                     setUidDetailsMap(uidDetailsMap);
// //                 });
// //             }
// //         });
// //     }, []);

// //     const filteredData = data.filter(session => 
// //         session.data.name.toLowerCase().includes(searchTerm.toLowerCase())
// //     );

// //     const csvData = filteredData.flatMap(({ id, data }) => 
// //         data.registered_uid.map(uid => ({
// //             session_name: data.name,
// //             registered_name: uidDetailsMap[uid]?.name || uid,
// //             registered_phone: uidDetailsMap[uid]?.phone_no || 'N/A'
// //         }))
// //     );

// //     const headers = [
// //         { label: "Session Name", key: "session_name" },
// //         { label: "Registered Name", key: "registered_name" },
// //         { label: "Registered Phone", key: "registered_phone" }
// //     ];

// //     return (
// //         <>
// //             <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
// //                 <div class="p-2" style={{ paddingBottom: "5px", display: "flex", justifyContent: "space-between" }}>
// //                     <Link to="/admin_panel"><img src="/images/menubutton.png" class="menu-toggle" alt="Responsive image" style={{ height: "2rem" }} /></Link>
// //                     <input 
// //                         type="text" 
// //                         placeholder="Search Workshop Name" 
// //                         value={searchTerm} 
// //                         onChange={e => setSearchTerm(e.target.value)} 
// //                         class="form-control"
// //                         style={{ maxWidth: "300px", marginRight: "10px" }}
// //                     />
// //                     <CSVLink 
// //                         data={csvData} 
// //                         headers={headers}
// //                         filename="upcoming_sessions.csv"
// //                         class="btn btn-dark"
// //                         style={{ height: "2rem" }}
// //                     >
// //                         Download Excel
// //                     </CSVLink>
// //                 </div>
// //                 <div class="">
// //                     <div class="p-2">
// //                         <div style={{ textAlign: "end", paddingBottom: "5px" }}>
// //                         </div>
// //                         <div class="card">
// //                             <div class="card-header pb-0 px-3">
// //                                 <h6 class="mb-2">Guidance Session</h6>
// //                             </div>
// //                             <div class="">
// //                                 <div class="card">
// //                                     <div class="table-responsive fix-width scroll-inner">
// //                                         <table class="table table-bordered table-hover">
// //                                             <thead class="thead-dark" style={{ position: "sticky", top: "0" }}>
// //                                                 <tr>
// //                                                     <th class="col">Session Name</th>
// //                                                     <th class="col ps-2">Registered Names</th>
// //                                                     <th class="col ps-2">Registered Phone</th>
// //                                                 </tr>
// //                                             </thead>
// //                                             <tbody>
// //                                                 {filteredData?.map(({ id, data }) => (
// //                                                     data.registered_uid.map((uid, index) => (
// //                                                         <tr key={`${id}-${uid}`}>
// //                                                             <td>
// //                                                                 <p class="text-xs font-weight-bold mb-0">{data.name}</p>
// //                                                             </td>
// //                                                             <td>
// //                                                                 <p class="text-xs font-weight-bold mb-0">{uidDetailsMap[uid]?.name || uid}</p>
// //                                                             </td>
// //                                                             <td>
// //                                                                 <p class="text-xs font-weight-bold mb-0">{uidDetailsMap[uid]?.phone_no || 'N/A'}</p>
// //                                                             </td>
// //                                                         </tr>
// //                                                     ))
// //                                                 ))}
// //                                             </tbody>
// //                                         </table>
// //                                     </div>
// //                                 </div>
// //                             </div>
// //                         </div>
// //                     </div>
// //                 </div>
// //             </main>
// //         </>
// //     );
// // }

// // export default UpcomingSession;




// // import React, { useState, useEffect } from 'react';
// // import { useNavigate, Link } from 'react-router-dom';
// // import db from "./Firebase";
// // import { arrayUnion } from "firebase/firestore";
// // import { storage } from './Firebase';
// // import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
// // import Moment from 'moment';
// // import { extendMoment } from 'moment-range';
// // import ViewAllRequests from './ViewAllRequests';
// // import { CSVLink } from "react-csv";

// // function UpcomingSession() {
// //     const navigate = useNavigate();
// //     const [data, setData] = useState([]);
// //     const [uidDetailsMap, setUidDetailsMap] = useState({});

// //     useEffect(() => {
// //         const storedUsername = localStorage.getItem("email");
// //         const storedPassword = localStorage.getItem("password");
// //         const storedRemember = localStorage.getItem("remember");

// //         if (storedRemember === "false" || !storedUsername || !storedPassword) {
// //             navigate('/signin');
// //         }
// //     }, []);

// //     useEffect(() => {
// //         db.collection("upcoming_sessions").onSnapshot((snapshot) => {
// //             const sessions = snapshot.docs.map((doc) => ({
// //                 id: doc.id,
// //                 data: doc.data()
// //             }));

// //             setData(sessions);

// //             const allUids = [];
// //             sessions.forEach(session => {
// //                 session.data.registered_uid.forEach(uid => {
// //                     if (!allUids.includes(uid)) {
// //                         allUids.push(uid);
// //                     }
// //                 });
// //             });

// //             if (allUids.length > 0) {
// //                 const promises = allUids.map(uid => db.collection("personal_details").doc(uid).get());
// //                 Promise.all(promises).then(querySnapshots => {
// //                     const uidDetailsMap = {};
// //                     querySnapshots.forEach(doc => {
// //                         if (doc.exists) {
// //                             const data = doc.data();
// //                             uidDetailsMap[doc.id] = { name: data.full_name, phone_no: data.phone_no };
// //                         }
// //                     });
// //                     setUidDetailsMap(uidDetailsMap);
// //                 });
// //             }
// //         });
// //     }, []);

// //     return (
// //         <>
// //             <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
// //                 <div class="p-2" style={{ paddingBottom: "5px", display: "flex", justifyContent: "space-between" }}>
// //                     <Link to="/admin_panel"><img src="/images/menubutton.png" class="menu-toggle" alt="Responsive image" style={{ height: "2rem" }} /></Link>
// //                 </div>
// //                 <div class="">
// //                     <div class="p-2">
// //                         <div style={{ textAlign: "end", paddingBottom: "5px" }}>
// //                         </div>
// //                         <div class="card">
// //                             <div class="card-header pb-0 px-3">
// //                                 <h6 class="mb-2">Upcoming Session</h6>
// //                             </div>
// //                             <div class="">
// //                                 <div class="card">
// //                                     <div class="table-responsive fix-width scroll-inner">
// //                                         <table class="table table-bordered table-hover">
// //                                             <thead class="thead-dark" style={{ position: "sticky", top: "0" }}>
// //                                                 <tr>
// //                                                     <th class="col">Session Name</th>
// //                                                     <th class="col ps-2">Register Count</th>
// //                                                     <th class="col ps-2">Registered Names</th>
// //                                                     <th class="col ps-2">Registered Phone</th>
// //                                                 </tr>
// //                                             </thead>
// //                                             <tbody>
// //                                                 {data?.map(({ id, data }, index) => (
// //                                                     <tr key={id}>
// //                                                         <td>
// //                                                             <p class="text-xs font-weight-bold mb-0">{data.name}</p>
// //                                                         </td>
// //                                                         <td>
// //                                                             <div class="d-flex px-2 py-1">
// //                                                                 <div class="d-flex flex-column justify-content-center">
// //                                                                     <h6 class="mb-0 text-xs">{data.registered_uid.length}</h6>
// //                                                                 </div>
// //                                                             </div>
// //                                                         </td>
// //                                                         {/* <td>
// //                                                             <ul>
// //                                                                 {data.registered_uid.map((uid, index) => (
// //                                                                     <li key={index} class="text-xs">{uidDetailsMap[uid]?.name || uid}</li>
// //                                                                 ))}
// //                                                             </ul>
// //                                                         </td>
// //                                                         <td>
// //                                                             <ul>
// //                                                                 {data.registered_uid.map((uid, index) => (
// //                                                                     <li key={index} class="text-xs">{uidDetailsMap[uid]?.phone_no || 'N/A'}</li>
// //                                                                 ))}
// //                                                             </ul>
// //                                                         </td> */}
// //                                                         <td>
// //     <div>
// //         {data.registered_uid.map((uid, index) => (
// //             <div key={index} class="text-xs" style={{ borderBottom: "1px solid black", padding: "5px 0" }}>
// //                 {uidDetailsMap[uid]?.name || uid}
// //             </div>
// //         ))}
// //     </div>
// // </td>
// // <td>
// //     <div>
// //         {data.registered_uid.map((uid, index) => (
// //             <div key={index} class="text-xs" style={{ borderBottom: "1px solid black", padding: "5px 0" }}>
// //                 {uidDetailsMap[uid]?.phone_no || 'N/A'}
// //             </div>
// //         ))}
// //     </div>
// // </td>

// //                                                     </tr>
// //                                                 ))}
// //                                             </tbody>
// //                                         </table>
// //                                     </div>
// //                                 </div>
// //                             </div>
// //                         </div>
// //                     </div>
// //                 </div>
// //             </main>
// //         </>
// //     );
// // }

// // export default UpcomingSession;


// // import React, { useState, useEffect } from 'react';
// // import { useNavigate, Link } from 'react-router-dom';
// // import db from "./Firebase";
// // import { arrayUnion } from "firebase/firestore";
// // import { storage } from './Firebase';
// // import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
// // import Moment from 'moment';
// // import { extendMoment } from 'moment-range';
// // import ViewAllRequests from './ViewAllRequests';
// // import { CSVLink } from "react-csv";

// // function UpcomingSession() {
// //     const navigate = useNavigate();
// //     const [data, setData] = useState([]);
// //     const [uidNameMap, setUidNameMap] = useState({});

// //     useEffect(() => {
// //         const storedUsername = localStorage.getItem("email");
// //         const storedPassword = localStorage.getItem("password");
// //         const storedRemember = localStorage.getItem("remember");

// //         if (storedRemember === "false" || !storedUsername || !storedPassword) {
// //             navigate('/signin');
// //         }
// //     }, []);

// //     useEffect(() => {
// //         db.collection("upcoming_sessions").onSnapshot((snapshot) => {
// //             const sessions = snapshot.docs.map((doc) => ({
// //                 id: doc.id,
// //                 data: doc.data()
// //             }));

// //             setData(sessions);

// //             const allUids = [];
// //             sessions.forEach(session => {
// //                 session.data.registered_uid.forEach(uid => {
// //                     if (!allUids.includes(uid)) {
// //                         allUids.push(uid);
// //                     }
// //                 });
// //             });

// //             if (allUids.length > 0) {
// //                 const promises = allUids.map(uid => db.collection("personal_details").doc(uid).get());
// //                 Promise.all(promises).then(querySnapshots => {
// //                     const uidNameMap = {};
// //                     querySnapshots.forEach(doc => {
// //                         if (doc.exists) {
// //                             const data = doc.data();
// //                             uidNameMap[doc.id] = data.full_name;
// //                         }
// //                     });
// //                     setUidNameMap(uidNameMap);
// //                 });
// //             }
// //         });
// //     }, []);

// //     return (
// //         <>
// //             <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
// //                 <div class="p-2" style={{ paddingBottom: "5px", display: "flex", justifyContent: "space-between" }}>
// //                     <Link to="/admin_panel"><img src="/images/menubutton.png" class="menu-toggle" alt="Responsive image" style={{ height: "2rem" }} /></Link>
// //                 </div>
// //                 <div class="">
// //                     <div class="p-2">
// //                         <div style={{ textAlign: "end", paddingBottom: "5px" }}>
// //                         </div>
// //                         <div class="card">
// //                             <div class="card-header pb-0 px-3">
// //                                 <h6 class="mb-2">Upcoming Session</h6>
// //                             </div>
// //                             <div class="">
// //                                 <div class="card">
// //                                     <div class="table-responsive fix-width scroll-inner">
// //                                         <table class="table table-bordered table-hover">
// //                                             <thead class="thead-dark" style={{ position: "sticky", top: "0" }}>
// //                                                 <tr>
// //                                                     <th class="col">Session Name</th>
// //                                                     <th class="col ps-2">Register Count</th>
// //                                                     <th class="col ps-2">Registered Names</th>
// //                                                 </tr>
// //                                             </thead>
// //                                             <tbody>
// //                                                 {data?.map(({ id, data }, index) => (
// //                                                     <tr key={id}>
// //                                                         <td>
// //                                                             <p class="text-xs font-weight-bold mb-0">{data.name}</p>
// //                                                         </td>
// //                                                         <td>
// //                                                             <div class="d-flex px-2 py-1">
// //                                                                 <div class="d-flex flex-column justify-content-center">
// //                                                                     <h6 class="mb-0 text-xs">{data.registered_uid.length}</h6>
// //                                                                 </div>
// //                                                             </div>
// //                                                         </td>
// //                                                         <td>
// //                                                             <ul>
// //                                                                 {data.registered_uid.map((uid, index) => (
// //                                                                     <li key={index} class="text-xs">{uidNameMap[uid] || uid}</li>
// //                                                                 ))}
// //                                                             </ul>
// //                                                         </td>
                                                        
// //                                                     </tr>
// //                                                 ))}
// //                                             </tbody>
// //                                         </table>
// //                                     </div>
// //                                 </div>
// //                             </div>
// //                         </div>
// //                     </div>
// //                 </div>
// //             </main>
// //         </>
// //     );
// // }

// // export default UpcomingSession;




// // import React, { useState, useEffect } from 'react';
// // import { useNavigate, Link, useParams } from 'react-router-dom';
// // import db from "./Firebase";
// // import { arrayUnion } from "firebase/firestore";
// // import { storage } from './Firebase';
// // import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
// // import Moment from 'moment';
// // import { extendMoment } from 'moment-range';
// // import ViewAllRequests from './ViewAllRequests';
// // import { CSVLink } from "react-csv";

// // function UpcomingSession() {
// //     const navigate = useNavigate();

// //     const [data, setData] = useState([])

// //     useEffect(() => {
// //         const storedUsername = localStorage.getItem("email");
// //         const storedPassword = localStorage.getItem("password");
// //         const storedRemember = localStorage.getItem("remember");

// //         if (storedRemember === "false" || !storedUsername || !storedPassword) {
// //             navigate('/signin');
// //         }
// //     }, []);

// //     useEffect(() => {
// //         db.collection("upcoming_sessions").onSnapshot((snapshot) => {
// //             setData(
// //                 snapshot.docs.map((doc) => ({
// //                     id: doc.id,
// //                     data: doc.data()
// //                 }))
// //             );
// //         });
// //     }, []);

// //     return (
// //         <>
// //             <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
// //                 <div class="p-2" style={{ paddingBottom: "5px", display: "flex", justifyContent: "space-between" }}>
// //                     <Link to="/admin_panel"><img src="/images/menubutton.png" class="menu-toggle" alt="Responsive image" style={{ height: "2rem" }} /></Link>
// //                 </div>
// //                 <div class="">
// //                     <div class="p-2">
// //                         <div style={{ textAlign: "end", paddingBottom: "5px" }}>
// //                         </div>
// //                         <div class="card">
// //                             <div class="card-header pb-0 px-3">
// //                                 <h6 class="mb-2">Upcoming Session</h6>
// //                             </div>
// //                             <div class="">
// //                                 <div class="card">
// //                                     <div class="table-responsive fix-width scroll-inner">
// //                                         <table class="table table-bordered">
// //                                             <thead class="thead-dark" style={{ position: "sticky", top: "0" }}>
// //                                                 <tr>
// //                                                     <th class="col">Session Name</th>
// //                                                     <th class="col ps-2">Register Count</th>
// //                                                     <th class="col ps-2">Registered UIDs</th>
// //                                                 </tr>
// //                                             </thead>
// //                                             <tbody>
// //                                                 {data?.map(({ id, data }, index) => (
// //                                                     <tr key={id}>
// //                                                         <td>
// //                                                             <p class="text-xs font-weight-bold mb-0">{data.name}</p>
// //                                                         </td>
// //                                                         <td>
// //                                                             <div class="d-flex px-2 py-1">
// //                                                                 <div class="d-flex flex-column justify-content-center">
// //                                                                     <h6 class="mb-0 text-xs">{data.registered_uid.length}</h6>
// //                                                                 </div>
// //                                                             </div>
// //                                                         </td>
// //                                                         <td>
// //                                                             <ul>
// //                                                                 {data.registered_uid.map((uid, index) => (
// //                                                                     <li key={index} class="text-xs">{uid}</li>
// //                                                                 ))}
// //                                                             </ul>
// //                                                         </td>
// //                                                     </tr>
// //                                                 ))}
// //                                             </tbody>
// //                                         </table>
// //                                     </div>
// //                                 </div>
// //                             </div>
// //                         </div>
// //                     </div>
// //                 </div>
// //             </main>
// //         </>
// //     );
// // }

// // export default UpcomingSession;




// // import React, { useState, useEffect } from 'react';
// // import { useNavigate, Link, useParams } from 'react-router-dom';
// // import db from "./Firebase";
// // import { arrayUnion } from "firebase/firestore";
// // import { storage } from './Firebase';
// // import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
// // import Moment from 'moment';
// // import { extendMoment } from 'moment-range';
// // import ViewAllRequests from './ViewAllRequests';
// // import { CSVLink } from "react-csv";

// // function UpcomingSession() {
// //     const navigate = useNavigate();



// //     const [data, setData] = useState([])

// //     useEffect(() => {
// //         const storedUsername = localStorage.getItem("email");
// //         const storedPassword = localStorage.getItem("password");
// //         const storedRemember = localStorage.getItem("remember");

// //         if (storedRemember === "false" || !storedUsername || !storedPassword) {
// //             navigate('/signin');
// //         }
// //     }, []);

// //     useEffect(() => {
// //         db.collection("upcoming_sessions").onSnapshot((snapshot) => {


// //             setData(
// //                 snapshot.docs.map((doc) => ({
// //                     id: doc.id,
// //                     data: doc.data()
// //                 }))
// //             );

// //         });

// //     }, []);
// //     return (
// //         <>
// //             {/* <ViewAllRequests /> */}

// //             <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
// //                 <div class="p-2" style={{ paddingBottom: "5px", display: "flex", justifyContent: "space-between" }}>
// //                     {/* <span class="btn bg-gradient-secondary btn-sm mb-0 mr-2" style={{ color: "white" }} >&nbsp; Download Excel</span> */}
// //                     <Link to="/admin_panel"><img src="/images/menubutton.png" class="menu-toggle" alt="Responsive image" style={{ height: "2rem" }} /></Link>
// //                     {/* <CSVLink data={data} headers={headers}>
// //                         <span class="btn bg-gradient-secondary btn-sm mb-0 mr-2" style={{ color: "white" }} >&nbsp; Download Excel</span>
// //                     </CSVLink> */}
// //                 </div>
// //                 <div class="">


// //                     <div class="p-2">
// //                         <div style={{ textAlign: "end", paddingBottom: "5px" }}>
// //                             {/* <Link to="/admin_panel" class="btn bg-gradient-primary btn-sm mb-0 mr-2" style={{ color: "white" }} >&nbsp; Go Back</Link> */}

// //                         </div>
// //                         <div class="card">
// //                             <div class="card-header pb-0 px-3">
// //                                 <h6 class="mb-2">Upcoming Session</h6>
// //                             </div>
// //                             <div class="">

// //                                 <div class="card">
// //                                     <div class="table-responsive fix-width scroll-inner">
// //                                         <table class="table table-bordered table-hover">
// //                                             <thead class="thead-dark" style={{ position: "sticky", top: "0" }}>
// //                                                 <tr>
// //                                                     <th class="col">Session Name</th>
// //                                                     <th class="col ps-2">Register Count</th>
// //                                                 </tr>
// //                                             </thead>
// //                                             <tbody>
// //                                                 {data?.map(({ id, data }, index) => (
// //                                                     <>
// //                                                         <tr>
// //                                                             <td>
// //                                                                 <p class="text-xs font-weight-bold mb-0">{data.name}</p>
// //                                                                 {/* <p class="text-xs text-secondary mb-0">24 Nov 2022</p> */}
// //                                                             </td>
// //                                                             <td>
// //                                                                 <div class="d-flex px-2 py-1">

// //                                                                     <div class="d-flex flex-column justify-content-center">
// //                                                                         <h6 class="mb-0 text-xs">{data.registered_uid.length}</h6>
// //                                                                     </div>
// //                                                                 </div>
// //                                                             </td>


// //                                                         </tr>

// //                                                     </>
// //                                                 ))}


// //                                             </tbody>
// //                                         </table>
// //                                     </div>
// //                                 </div>
// //                             </div>
// //                         </div>
// //                     </div>

// //                 </div>


// //             </main>


// //         </>
// //     )
// // }

// // export default UpcomingSession;