import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate, Link, useParams } from "react-router-dom"
import Select from 'react-select';
import FooterMain from "./FooterMain";
import Swal from 'sweetalert2'
import db from "./Firebase";
import { storage } from './Firebase';


function Bridal() {


    return (
        <>
            <div className="Frame1" style={{ width: 1920, height: 6075, position: 'relative', background: 'white' }}>
                <div className="Rectangle25" style={{ width: 1920, height: 565, left: 0, top: 3576, position: 'absolute', background: '#212529' }} />
                <img className="Rectangle1" style={{ width: 1920, height: 465, left: 0, top: 99, position: 'absolute' }} src="https://s1.1zoom.me/b5050/465/France_Forests_Stones_Rivers_Bras-Panon_Reunion_518138_1920x1080.jpg" />
                <div className="Rectangle3" style={{ width: 798, height: 465, left: 1122, top: 99, position: 'absolute', background: '#7D6853' }} />
                <div className="Rectangle2" style={{ width: 576, height: 63, left: 1218, top: 396, position: 'absolute', background: 'rgba(247.56, 243.44, 243.44, 0.88)', boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.25)', borderRadius: 20 }} />
                <div className="SearchViaState" style={{ width: 239, height: 30, left: 1270, top: 414, position: 'absolute', color: '#493939', fontSize: 24, fontFamily: 'Roboto', fontWeight: '500', wordWrap: 'break-word' }}>Search via state</div>
                <div className="FindThePerfectBeautyProfessionalNextDoor" style={{ left: 1218, top: 203, position: 'absolute', color: '#F9F3F3', fontSize: 54, fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word' }}>Find the Perfect Beauty <br />Professional Next Door!</div>
                <div className="Rectangle4" style={{ width: 1920, height: 99, left: 0, top: 0, position: 'absolute', background: '#E15F5E', boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.25)' }} />
                <div className="Line3" style={{ width: 39, height: 0, left: 1714, top: 408, position: 'absolute', transform: 'rotate(90deg)', transformOrigin: '0 0', border: '1.80px #493939 solid' }}></div>
                <img className="DownArrow1" style={{ width: 38, height: 38, left: 1731, top: 408, position: 'absolute' }} src="https://via.placeholder.com/38x38" />
                <div className="Rectangle5" style={{ width: 195, height: 48, left: 119, top: 25, position: 'absolute', background: '#F3F1F1', borderRadius: 10 }} />
                <img className="YouvatiLogo1" style={{ width: 156, height: 39, left: 138, top: 28, position: 'absolute' }} src="https://via.placeholder.com/156x39" />
                <div className="WeHaveBeautyProfessionalsListedFromTheFollowingCities" style={{ left: 443, top: 648, position: 'absolute', color: '#0B0A0A', fontSize: 36, fontFamily: 'Roboto', fontWeight: '500', wordWrap: 'break-word' }}>We Have Beauty Professionals Listed From The Following Cities:</div>
                <div className="DelhiMumbaiAurangabadWashimJalnaAgraKanpurMeerutGhaziabadChandigarghPatialaAmritsarBathindaMansa" style={{ width: 1020, left: 447, top: 747, position: 'absolute', textAlign: 'center', color: '#141313', fontSize: 32, fontFamily: 'Roboto', fontWeight: '400', lineHeight: 50, wordWrap: 'break-word' }}>Delhi | Mumbai | Aurangabad | Washim | Jalna | Agra | Kanpur | Meerut | Ghaziabad | Chandigargh | Patiala | Amritsar | Bathinda | Mansa</div>
                <div className="WhyChooseUs" style={{ left: 1241, top: 33, position: 'absolute', color: 'white', fontSize: 28, fontFamily: 'Roboto', fontWeight: '500', wordWrap: 'break-word' }}>Why Choose Us</div>
                <div className="ForPartners" style={{ left: 1472, top: 33, position: 'absolute', color: 'white', fontSize: 28, fontFamily: 'Roboto', fontWeight: '500', wordWrap: 'break-word' }}>For Partners</div>
                <div className="ContactUs" style={{ left: 1662, top: 33, position: 'absolute', color: 'white', fontSize: 28, fontFamily: 'Roboto', fontWeight: '500', wordWrap: 'break-word' }}>Contact Us</div>
                <div className="Line4" style={{ width: 26.50, height: 0, left: 1644, top: 36, position: 'absolute', transform: 'rotate(90deg)', transformOrigin: '0 0', border: '1.40px white solid' }}></div>
                <div className="Line5" style={{ width: 26.50, height: 0, left: 1455.50, top: 35.50, position: 'absolute', transform: 'rotate(90deg)', transformOrigin: '0 0', border: '1.40px white solid' }}></div>
                <div className="Line6" style={{ width: 26.50, height: 0, left: 1222, top: 36, position: 'absolute', transform: 'rotate(90deg)', transformOrigin: '0 0', border: '1.40px white solid' }}></div>
                <div className="Line7" style={{ width: 1029, height: 0, left: 443, top: 706, position: 'absolute', border: '4px #0B0A0A solid' }}></div>
                <img className="Ellipse4" style={{ width: 276, height: 276, left: 1175, top: 1077, position: 'absolute', borderRadius: 9999 }} src="https://via.placeholder.com/276x276" />
                <img className="Ellipse5" style={{ width: 276, height: 276, left: 1528, top: 1077, position: 'absolute', borderRadius: 9999 }} src="https://via.placeholder.com/276x276" />
                <div className="ServiceCategories" style={{ left: 127, top: 956, position: 'absolute', textAlign: 'center', color: '#141313', fontSize: 48, fontFamily: 'Roboto', fontWeight: '600', wordWrap: 'break-word' }}>Service Categories</div>
                <div className="TopBridalArtists" style={{ left: 143, top: 1878, position: 'absolute', textAlign: 'center', color: '#141313', fontSize: 48, fontFamily: 'Roboto', fontWeight: '600', wordWrap: 'break-word' }}>Top Bridal Artists</div>
                <div className="Others" style={{ left: 1609, top: 1393, position: 'absolute', textAlign: 'center', color: '#141313', fontSize: 38, fontFamily: 'Roboto', fontWeight: '500', wordWrap: 'break-word' }}>Others</div>
                <div className="Rectangle6" style={{ width: 1922, height: 284, left: 0, top: 1502, position: 'absolute', background: '#141313' }} />
                <div className="Rectangle7" style={{ width: 243, height: 199, left: 116, top: 1549, position: 'absolute', background: 'white', borderRadius: 10 }} />
                <div className="Rectangle9" style={{ width: 243, height: 199, left: 598, top: 1549, position: 'absolute', background: 'white', borderRadius: 10 }} />
                <div className="Rectangle10" style={{ width: 243, height: 199, left: 1078, top: 1549, position: 'absolute', background: 'white', borderRadius: 10 }} />
                <div className="Rectangle8" style={{ width: 243, height: 199, left: 1561, top: 1549, position: 'absolute', background: 'white', borderRadius: 10 }} />
                <div style={{ left: 212, top: 1603, position: 'absolute', textAlign: 'center', color: 'black', fontSize: 44, fontFamily: 'Roboto', fontWeight: '600', wordWrap: 'break-word' }}>48</div>
                <div style={{ left: 664, top: 1603, position: 'absolute', textAlign: 'center', color: 'black', fontSize: 44, fontFamily: 'Roboto', fontWeight: '600', wordWrap: 'break-word' }}>500 +</div>
                <div style={{ left: 1119, top: 1601, position: 'absolute', textAlign: 'center', color: 'black', fontSize: 44, fontFamily: 'Roboto', fontWeight: '600', wordWrap: 'break-word' }}>10000 +</div>
                <div className="8" style={{ left: 1651, top: 1603, position: 'absolute', textAlign: 'center', color: 'black', fontSize: 44, fontFamily: 'Roboto', fontWeight: '600', wordWrap: 'break-word' }}>4.8 </div>
                <div className="Cities" style={{ left: 209, top: 1667, position: 'absolute', textAlign: 'center', color: 'black', fontSize: 22, fontFamily: 'Roboto', fontWeight: '400', wordWrap: 'break-word' }}>Cities</div>
                <div className="BeautyProfessionals" style={{ left: 616, top: 1667, position: 'absolute', textAlign: 'center', color: 'black', fontSize: 22, fontFamily: 'Roboto', fontWeight: '400', wordWrap: 'break-word' }}>Beauty Professionals</div>
                <div className="BookingsDone" style={{ left: 1125, top: 1661, position: 'absolute', textAlign: 'center', color: 'black', fontSize: 22, fontFamily: 'Roboto', fontWeight: '400', wordWrap: 'break-word' }}>Bookings Done</div>
                <div className="AverageRating" style={{ left: 1608, top: 1667, position: 'absolute', textAlign: 'center', color: 'black', fontSize: 22, fontFamily: 'Roboto', fontWeight: '400', wordWrap: 'break-word' }}>Average Rating</div>
                <img className="Rectangle12" style={{ width: 353, height: 353, left: 545.19, top: 2019, position: 'absolute' }} src="https://via.placeholder.com/353x353" />
                <img className="Rectangle14" style={{ width: 353, height: 353, left: 1451, top: 2019, position: 'absolute' }} src="https://via.placeholder.com/353x353" />
                <div className="NehaMakeovers" style={{ left: 172, top: 2425, position: 'absolute', textAlign: 'center', color: 'black', fontSize: 32, fontFamily: 'Roboto', fontWeight: '600', wordWrap: 'break-word' }}>Neha Makeovers</div>
                <div className="PihuMakeover" style={{ left: 616, top: 2425, position: 'absolute', textAlign: 'center', color: 'black', fontSize: 32, fontFamily: 'Roboto', fontWeight: '600', wordWrap: 'break-word' }}>Pihu Makeover</div>
                <div className="SitaMakeover" style={{ left: 1524, top: 2425, position: 'absolute', textAlign: 'center', color: 'black', fontSize: 32, fontFamily: 'Roboto', fontWeight: '600', wordWrap: 'break-word' }}>Sita Makeover</div>
                <img className="Rectangle15" style={{ width: 353, height: 353, left: 116, top: 2019, position: 'absolute' }} src="https://via.placeholder.com/353x353" />
                <img className="Rectangle17" style={{ width: 353, height: 353, left: 1006, top: 2019, position: 'absolute' }} src="https://via.placeholder.com/353x353" />
                <div className="MahiMakeover" style={{ left: 1072, top: 2425, position: 'absolute', textAlign: 'center', color: 'black', fontSize: 32, fontFamily: 'Roboto', fontWeight: '600', wordWrap: 'break-word' }}>Mahi Makeover</div>
                <img className="Rectangle20" style={{ width: 300, height: 300, left: 108, top: 3184, position: 'absolute', borderRadius: 20 }} src="https://via.placeholder.com/300x300" />
                <img className="Rectangle21" style={{ width: 300, height: 300, left: 476, top: 3184, position: 'absolute', borderRadius: 20 }} src="https://via.placeholder.com/300x300" />
                <img className="Rectangle22" style={{ width: 300, height: 300, left: 844, top: 3184, position: 'absolute', borderRadius: 20 }} src="https://via.placeholder.com/300x300" />
                <img className="Rectangle23" style={{ width: 300, height: 300, left: 1212, top: 3184, position: 'absolute', borderRadius: 20 }} src="https://via.placeholder.com/300x300" />
                <div className="Rectangle19" style={{ width: 1922, height: 284, left: 0, top: 2588, position: 'absolute', background: 'black' }} />
                <img className="Banner1012" style={{ width: 256, height: 284, left: 1359, top: 2588, position: 'absolute' }} src="https://via.placeholder.com/256x284" />
                <img className="Banner1013" style={{ width: 312, height: 284, left: 811, top: 2588, position: 'absolute' }} src="https://via.placeholder.com/312x284" />
                <img className="Banner1014" style={{ width: 318, height: 284, left: 262, top: 2588, position: 'absolute' }} src="https://via.placeholder.com/318x284" />
                <img className="New1" style={{ width: 22, height: 22, left: 1200, top: 11, position: 'absolute' }} src="https://via.placeholder.com/22x22" />
                <div className="Bridal" style={{ left: 1135, top: 33, position: 'absolute', color: 'white', fontSize: 28, fontFamily: 'Roboto', fontWeight: '400', wordWrap: 'break-word' }}>Bridal</div>
                <div className="WeEmployTheBestExpertsInTheBeautyIndustryAndEducateOurOwnAwardWinningSpecialistsThroughConstantTrainingHereAreTheMostProminentTalentsOfOurTeamWhoMakeYourBeautyDreamsComeTrue" style={{ left: 358, top: 2957, position: 'absolute', textAlign: 'center', color: '#141313', fontSize: 32, fontFamily: 'Roboto', fontWeight: '400', wordWrap: 'break-word' }}>We employ the best experts in the beauty industry and educate our own <br />award-winning specialists through constant training. Here are the most prominent<br /> talents of our team who make your beauty dreams come true.</div>
                <img className="Rectangle24" style={{ width: 216, height: 300, left: 1580, top: 3184, position: 'absolute', borderTopLeftRadius: 20, borderTopRightRadius: 20 }} src="https://via.placeholder.com/216x300" />
                <div className="WeLikeToMaintainAConstantConnectionWithOurCustomersAndReceiveFeedbackOnEveryServiceWhetherItSASimpleHaircutOrComplexWeddingMakeupIfYouVeAlreadyTriedOutOurServicesFeelFreeToContactUsAndSendYourTestimonial" style={{ width: 702, left: 108, top: 3797, position: 'absolute', color: '#F5F5F5', fontSize: 20, fontFamily: 'Roboto', fontWeight: '500', lineHeight: 36, wordWrap: 'break-word' }}>We like to maintain a constant connection with our customers and receive feedback on every service, whether it's a simple haircut or complex wedding makeup. If you've already tried out our services,feel free to contact us and send your testimonial.</div>
                <div className="TestimonialsOfOurClients" style={{ left: 108, top: 3658, position: 'absolute', color: '#F5F5F5', fontSize: 42, fontFamily: 'Roboto', fontWeight: '600', lineHeight: 44, wordWrap: 'break-word' }}>Testimonials <br />           of Our Clients</div>
                <div className="Line9" style={{ width: 90, height: 0, left: 108, top: 3729, position: 'absolute', border: '3px #EAEE25 solid' }}></div>
                <div className="Rectangle26" style={{ width: 780, height: 402, left: 1016, top: 3658, position: 'absolute', background: '#282C30', boxShadow: '0px 1.600000023841858px 1.600000023841858px rgba(0, 0, 0, 0.25)', borderRadius: 20 }} />
                <div className="Line10" style={{ width: 587, height: 0, left: 1103, top: 3731, position: 'absolute', border: '3px #EAEE25 solid' }}></div>
                <div className="UnlikeOtherSalonsWePreferToMaintainAConstantConnectionWithOurCustomersAndReceiveFeedbackOnEveryServiceWhetherItSASimpleHaircutOrComplexWeddingMakeupIfYouVeAlreadyVisitedGloryFeelFreeToContactUsAndSendYourTestimonial" style={{ width: 607, left: 1103, top: 3768, position: 'absolute', color: 'white', fontSize: 20, fontFamily: 'Roboto', fontWeight: '400', lineHeight: 36, wordWrap: 'break-word' }}>Unlike other salons, we prefer to maintain a constant connection with our customers and receive feedback on every service, whether it’s a simple haircut or complex wedding makeup. If you’ve already visited Glory, feel free to contact us and send your testimonial.</div>
                <div className="AnushkaSharma" style={{ left: 1103, top: 3944, position: 'absolute', color: 'white', fontSize: 30, fontFamily: 'Roboto', fontWeight: '500', lineHeight: 36, wordWrap: 'break-word' }}>Anushka Sharma</div>
                <div className="Rectangle31" style={{ width: 58, height: 234, left: 79, top: 3217, position: 'absolute', background: 'rgba(217, 217, 217, 0.75)', boxShadow: '0.6000000238418579px 1.600000023841858px 1.600000023841858px 0.6000000238418579px rgba(0, 0, 0, 0.25)', borderRadius: 3 }} />
                <div className="Rectangle32" style={{ width: 58, height: 234, left: 1767, top: 3217, position: 'absolute', background: 'rgba(217, 217, 217, 0.75)', boxShadow: '0.6000000238418579px 1.600000023841858px 1.600000023841858px 0.6000000238418579px rgba(0, 0, 0, 0.25)', borderRadius: 3 }} />
                <img className="Next2" style={{ width: 47, height: 47, left: 132, top: 3357, position: 'absolute', transform: 'rotate(180deg)', transformOrigin: '0 0', boxShadow: '0px 0.800000011920929px 0.800000011920929px rgba(0, 0, 0, 0.25)' }} src="https://via.placeholder.com/47x47" />
                <img className="Next1" style={{ width: 47, height: 47, left: 1773, top: 3310, position: 'absolute', boxShadow: '0px 0.800000011920929px 0.800000011920929px rgba(0, 0, 0, 0.25)' }} src="https://via.placeholder.com/47x47" />
                <img className="Icons8Star965" style={{ width: 26, height: 26, left: 172, top: 2477, position: 'absolute' }} src="https://via.placeholder.com/26x26" />
                <img className="Icons8Star966" style={{ width: 26, height: 26, left: 208, top: 2477, position: 'absolute' }} src="https://via.placeholder.com/26x26" />
                <img className="Icons8Star9611" style={{ width: 26, height: 26, left: 244, top: 2477, position: 'absolute' }} src="https://via.placeholder.com/26x26" />
                <img className="Icons8Star9612" style={{ width: 26, height: 26, left: 280, top: 2477, position: 'absolute' }} src="https://via.placeholder.com/26x26" />
                <img className="Icons8Star9613" style={{ width: 26, height: 26, left: 616, top: 2477, position: 'absolute' }} src="https://via.placeholder.com/26x26" />
                <img className="Icons8Star9614" style={{ width: 26, height: 26, left: 659, top: 2477, position: 'absolute' }} src="https://via.placeholder.com/26x26" />
                <img className="Icons8Star9615" style={{ width: 26, height: 26, left: 695, top: 2477, position: 'absolute' }} src="https://via.placeholder.com/26x26" />
                <img className="Icons8Star9616" style={{ width: 26, height: 26, left: 731, top: 2477, position: 'absolute' }} src="https://via.placeholder.com/26x26" />
                <img className="Icons8Star9645" style={{ width: 26, height: 26, left: 760, top: 2477, position: 'absolute' }} src="https://via.placeholder.com/26x26" />
                <img className="Icons8Star9617" style={{ width: 26, height: 26, left: 1072, top: 2477, position: 'absolute' }} src="https://via.placeholder.com/26x26" />
                <img className="Icons8Star9618" style={{ width: 26, height: 26, left: 1108, top: 2477, position: 'absolute' }} src="https://via.placeholder.com/26x26" />
                <img className="Icons8Star9619" style={{ width: 26, height: 26, left: 1144, top: 2477, position: 'absolute' }} src="https://via.placeholder.com/26x26" />
                <img className="Icons8Star9620" style={{ width: 26, height: 26, left: 1180, top: 2477, position: 'absolute' }} src="https://via.placeholder.com/26x26" />
                <img className="Icons8Star1005" style={{ width: 26, height: 24.14, left: 1216, top: 2479, position: 'absolute' }} src="https://via.placeholder.com/26x24" />
                <img className="Icons8Star9647" style={{ width: 26, height: 26, left: 172, top: 2477, position: 'absolute' }} src="https://via.placeholder.com/26x26" />
                <img className="Icons8Star9648" style={{ width: 26, height: 26, left: 208, top: 2477, position: 'absolute' }} src="https://via.placeholder.com/26x26" />
                <img className="Icons8Star9649" style={{ width: 26, height: 26, left: 244, top: 2477, position: 'absolute' }} src="https://via.placeholder.com/26x26" />
                <img className="Icons8Star9650" style={{ width: 26, height: 26, left: 280, top: 2477, position: 'absolute' }} src="https://via.placeholder.com/26x26" />
                <img className="Icons8Star10011" style={{ width: 26, height: 24.14, left: 316, top: 2478, position: 'absolute' }} src="https://via.placeholder.com/26x24" />
                <img className="Icons8Star9621" style={{ width: 26, height: 26, left: 1524, top: 2477, position: 'absolute' }} src="https://via.placeholder.com/26x26" />
                <img className="Icons8Star9622" style={{ width: 26, height: 26, left: 1560, top: 2477, position: 'absolute' }} src="https://via.placeholder.com/26x26" />
                <img className="Icons8Star9623" style={{ width: 26, height: 26, left: 1596, top: 2477, position: 'absolute' }} src="https://via.placeholder.com/26x26" />
                <img className="Icons8Star9624" style={{ width: 26, height: 26, left: 1632, top: 2477, position: 'absolute' }} src="https://via.placeholder.com/26x26" />
                <img className="Icons8Star9643" style={{ width: 26, height: 26, left: 1668, top: 2477, position: 'absolute' }} src="https://via.placeholder.com/26x26" />
                <img className="Rectangle33" style={{ width: 1135, height: 465, left: 1126, top: 99, position: 'absolute', transform: 'rotate(180deg)', transformOrigin: '0 0' }} src="https://via.placeholder.com/1135x465" />
                <div className="Rectangle30" style={{ width: 363, height: 245, left: 722, top: 219, position: 'absolute', background: 'rgba(217, 217, 217, 0.55)', boxShadow: '0px 1.2000000476837158px 1.2000000476837158px 1.2000000476837158px rgba(0, 0, 0, 0.25)', borderRadius: 20 }} />
                <div className="WeddingSeasonComing" style={{ left: 760, top: 273, position: 'absolute', color: '#E15F5E', fontSize: 36, fontFamily: 'Roboto', fontWeight: '600', lineHeight: 30, wordWrap: 'break-word' }}>Wedding Season<br />Coming...</div>
                <div className="ChooseYourDreamAristAndMakeYourWeddingLookSpecial" style={{ left: 760, top: 363, position: 'absolute', color: '#231E1E', fontSize: 20, fontFamily: 'Roboto', fontWeight: '600', lineHeight: 18, wordWrap: 'break-word' }}>Choose your Dream Arist and<br />make your Wedding Look Special!</div>
                <img className="Screenshot20231013141721" style={{ width: 1920, height: 899, left: 0, top: 4141, position: 'absolute' }} src="https://via.placeholder.com/1920x899" />
                <img className="Screenshot20231013141737" style={{ width: 1911, height: 419, left: 9, top: 5004, position: 'absolute' }} src="https://via.placeholder.com/1911x419" />
                <img className="Screenshot202310131420471" style={{ width: 1911, height: 722, left: 9, top: 5353, position: 'absolute' }} src="https://via.placeholder.com/1911x722" />
                <img className="Ellipse1" style={{ width: 276, height: 276, left: 470, top: 1077, position: 'absolute', borderRadius: 9999 }} src="https://via.placeholder.com/276x276" />
                <img className="Ellipse6" style={{ width: 276, height: 276, left: 118, top: 1077, position: 'absolute', borderRadius: 9999 }} src="https://via.placeholder.com/276x276" />
                <img className="Ellipse2" style={{ width: 276, height: 276, left: 823, top: 1077, position: 'absolute', borderRadius: 9999 }} src="https://via.placeholder.com/276x276" />
                <img className="Ellipse3" style={{ width: 276, height: 276, left: 1176, top: 1077, position: 'absolute', borderRadius: 9999 }} src="https://via.placeholder.com/276x276" />
                <div className="Makeup" style={{ left: 540, top: 1393, position: 'absolute', textAlign: 'center', color: '#141313', fontSize: 38, fontFamily: 'Roboto', fontWeight: '500', wordWrap: 'break-word' }}>Makeup</div>
                <div className="Bridal" style={{ left: 207, top: 1393, position: 'absolute', textAlign: 'center', color: '#141313', fontSize: 38, fontFamily: 'Roboto', fontWeight: '500', wordWrap: 'break-word' }}>Bridal</div>
                <div className="Skin" style={{ left: 924, top: 1393, position: 'absolute', textAlign: 'center', color: '#141313', fontSize: 38, fontFamily: 'Roboto', fontWeight: '500', wordWrap: 'break-word' }}>Skin</div>
                <div className="Hair" style={{ left: 1286, top: 1393, position: 'absolute', textAlign: 'center', color: '#141313', fontSize: 38, fontFamily: 'Roboto', fontWeight: '500', wordWrap: 'break-word' }}>Hair</div>
            </div>
            {/* <div className="frame">
                <div className="group">
                    <div className="group-wrapper">
                        <div className="overlap-wrapper">
                            <div className="overlap">
                                <img className="img" alt="Group" src="group-2.png" />
                                <div className="div">
                                    <div className="overlap-group">
                                        <img className="ellipse" alt="Ellipse" src="ellipse-12.png" />
                                    </div>
                                    <img className="ellipse-2" alt="Ellipse" src="ellipse-8.png" />
                                    <img className="ellipse-3" alt="Ellipse" src="ellipse-9.png" />
                                    <img className="ellipse-4" alt="Ellipse" src="ellipse-10.png" />
                                    <img className="ellipse-5" alt="Ellipse" src="ellipse-11.png" />
                                    <div className="text-wrapper">Bridal</div>
                                    <div className="text-wrapper-2">Couple</div>
                                    <div className="text-wrapper-3">Groom</div>
                                    <div className="text-wrapper-4">Function</div>
                                    <div className="text-wrapper-5">Pre-Wedding</div>
                                    <img className="screenshot" alt="Screenshot" src="screenshot-2023-10-13-142047-2.png" />
                                    <div className="group-2">
                                        <div className="overlap-2">
                                            <img
                                                className="pexels-dhiraj-jain"
                                                alt="Pexels dhiraj jain"
                                                src="pexels-dhiraj-jain-12737566-1.png"
                                            />
                                            <img className="pexels-sant-singh" alt="Pexels sant singh" src="pexels-sant-singh-11168809-1.png" />
                                            <img
                                                className="pexels-banu-film-ads"
                                                alt="Pexels banu film ads"
                                                src="pexels-banu-film-ads-12432460-1.png"
                                            />
                                            <div className="flexcontainer">
                                                <p className="text">
                                                    <span className="span">
                                                        “All women are pretty without makeup –– but <br />
                                                    </span>
                                                </p>
                                                <p className="text">
                                                    <span className="span">with the right makeup they can be pretty powerful.”</span>
                                                </p>
                                            </div>
                                            <div className="flexcontainer-2">
                                                <p className="text">
                                                    <span className="span">
                                                        “All women are pretty without makeup –– but <br />
                                                    </span>
                                                </p>
                                                <p className="text">
                                                    <span className="span">with the right makeup they can be pretty powerful.”</span>
                                                </p>
                                            </div>
                                            <div className="flexcontainer-3">
                                                <p className="text">
                                                    <span className="span">
                                                        “All women are pretty without <br />
                                                    </span>
                                                </p>
                                                <p className="text">
                                                    <span className="span">
                                                        makeup –– but with the right <br />
                                                    </span>
                                                </p>
                                                <p className="text">
                                                    <span className="span">makeup they can be pretty powerful.”</span>
                                                </p>
                                            </div>
                                        </div>
                                        <img className="rectangle" alt="Rectangle" src="rectangle-39.png" />
                                        <img className="rectangle-2" alt="Rectangle" src="rectangle-40.png" />
                                        <div className="overlap-3">
                                            <div className="youvati-logo-wrapper">
                                                <img className="youvati-logo" alt="Youvati logo" src="youvati-logo-2.png" />
                                            </div>
                                            <div className="text-wrapper-6">Why Choose Us</div>
                                            <div className="text-wrapper-7">For Partners</div>
                                            <div className="text-wrapper-8">Contact Us</div>
                                            <img className="line" alt="Line" src="line-12.svg" />
                                            <img className="line-2" alt="Line" src="line-13.svg" />
                                            <img className="line-3" alt="Line" src="line-14.svg" />
                                            <div className="overlap-4">
                                                <div className="text-wrapper-9">Bridal</div>
                                                <div className="text-wrapper-9">Bridal</div>
                                            </div>
                                            <img className="new" alt="New" src="new-2.png" />
                                        </div>
                                        <div className="overlap-5">
                                            <img className="rectangle-3" alt="Rectangle" src="rectangle-83.png" />
                                            <div className="text-wrapper-10">Facial</div>
                                        </div>
                                        <div className="text-wrapper-11">Tips For You</div>
                                        <div className="overlap-group-2">
                                            <img className="rectangle-3" alt="Rectangle" src="rectangle-85.png" />
                                            <div className="text-wrapper-12">Jewellery</div>
                                        </div>
                                        <div className="overlap-6">
                                            <img className="rectangle-3" alt="Rectangle" src="rectangle-84.png" />
                                            <div className="text-wrapper-13">Outfits</div>
                                        </div>
                                        <div className="overlap-7">
                                            <img className="rectangle-3" alt="Rectangle" src="rectangle-87.png" />
                                            <div className="text-wrapper-14">Mehndi</div>
                                        </div>
                                        <div className="text-wrapper-15">View more...</div>
                                        <div className="text-wrapper-16">Top Bridal Artists</div>
                                        <div className="text-wrapper-17">Wedding Clicks</div>
                                        <img className="rectangle-4" alt="Rectangle" src="rectangle-35.png" />
                                        <img className="rectangle-5" alt="Rectangle" src="rectangle-37.png" />
                                        <div className="text-wrapper-18">Neha Makeovers</div>
                                        <div className="text-wrapper-19">Pihu Makeover</div>
                                        <div className="text-wrapper-20">Sita Makeover</div>
                                        <img className="rectangle-6" alt="Rectangle" src="rectangle-38.png" />
                                        <img className="rectangle-7" alt="Rectangle" src="image.png" />
                                        <div className="text-wrapper-21">Mahi Makeover</div>
                                        <div className="star-wrapper">
                                            <img className="star" alt="Star" src="icons8-star-96-64.png" />
                                        </div>
                                        <div className="icons-star-wrapper">
                                            <img className="star" alt="Star" src="icons8-star-96-65.png" />
                                        </div>
                                        <div className="img-wrapper">
                                            <img className="star" alt="Star" src="icons8-star-96-66.png" />
                                        </div>
                                        <div className="overlap-8">
                                            <img className="star" alt="Star" src="icons8-star-96-67.png" />
                                        </div>
                                        <img className="icons-star" alt="Star" src="icons8-star-96-55.png" />
                                        <img className="star-2" alt="Star" src="icons8-star-96-56.png" />
                                        <img className="star-3" alt="Star" src="icons8-star-96-57.png" />
                                        <img className="star-4" alt="Star" src="icons8-star-96-58.png" />
                                        <img className="star-5" alt="Star" src="icons8-star-96-59.png" />
                                        <img className="star-6" alt="Star" src="icons8-star-96-60.png" />
                                        <img className="star-7" alt="Star" src="icons8-star-96-61.png" />
                                        <img className="star-8" alt="Star" src="icons8-star-96-62.png" />
                                        <img className="star-9" alt="Star" src="icons8-star-96-63.png" />
                                        <img className="star-10" alt="Star" src="icons8-star-100-12.png" />
                                        <img className="star-11" alt="Star" src="icons8-star-100-13.png" />
                                        <img className="star-12" alt="Star" src="icons8-star-96-68.png" />
                                        <img className="star-13" alt="Star" src="icons8-star-96-69.png" />
                                        <img className="star-14" alt="Star" src="icons8-star-96-70.png" />
                                        <img className="star-15" alt="Star" src="icons8-star-96-71.png" />
                                        <img className="star-16" alt="Star" src="icons8-star-96-72.png" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
        </>
    );
}

export default Bridal;

// import React from "react";
// import { useEffect } from "react";
// import { useState } from "react";
// import { useNavigate, Link, useParams } from "react-router-dom"
// import Select from 'react-select';
// import FooterMain from "./FooterMain";
// import Swal from 'sweetalert2'
// import db from "./Firebase";
// import { storage } from './Firebase';


// function Bridal() {


//     return (
//         <>
//             <div className="frame">
//                 <div className="div">
//                     <div className="overlap-group">
//                         <p className="we-like-to-maintain">
//                             We like to maintain a constant connection with our customers and receive feedback on every service, whether
//                             it&#39;s a simple haircut or complex wedding makeup. If you&#39;ve already tried out our services,feel free
//                             to contact us and send your testimonial.
//                         </p>
//                         <div className="overlap">
//                             <div className="flexcontainer">
//                                 <p className="text">
//                                     <span className="text-wrapper">
//                                         Testimonials <br />
//                                     </span>
//                                 </p>
//                                 <p className="text">
//                                     <span className="text-wrapper">
//                                         &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; of Our Clients
//                                     </span>
//                                 </p>
//                             </div>
//                             <img className="line" alt="Line" src="line-9.svg" />
//                         </div>
//                         <div className="overlap-2">
//                             <img className="img" alt="Line" src="line-10.svg" />
//                             <p className="p">
//                                 Unlike other salons, we prefer to maintain a constant connection with our customers and receive feedback
//                                 on every service, whether it’s a simple haircut or complex wedding makeup. If you’ve already visited
//                                 Glory, feel free to contact us and send your testimonial.
//                             </p>
//                             <div className="text-wrapper-2">Anushka Sharma</div>
//                         </div>
//                     </div>
//                     <div className="overlap-3">
//                         <div className="rectangle" />
//                         <div className="rectangle-2" />
//                         <div className="text-wrapper-3">Search via state</div>
//                         <div className="flexcontainer-2">
//                             <p className="span-wrapper">
//                                 <span className="span">
//                                     Find the Perfect Beauty <br />
//                                 </span>
//                             </p>
//                             <p className="span-wrapper">
//                                 <span className="span">Professional Next Door!</span>
//                             </p>
//                         </div>
//                         <img className="line-2" alt="Line" src="line-3.svg" />
//                         <img className="down-arrow" alt="Down arrow" src="down-arrow-1.png" />
//                         <img className="rectangle-3" alt="Rectangle" src="rectangle-33.png" />
//                         <div className="rectangle-4" />
//                         <div className="flexcontainer-3">
//                             <p className="text-2">
//                                 <span className="text-wrapper-4">
//                                     Wedding Season
//                                     <br />
//                                 </span>
//                             </p>
//                             <p className="text-2">
//                                 <span className="text-wrapper-4">Coming...</span>
//                             </p>
//                         </div>
//                         <div className="flexcontainer-4">
//                             <p className="text-3">
//                                 <span className="text-wrapper-5">
//                                     Choose your Dream Arist and
//                                     <br />
//                                 </span>
//                             </p>
//                             <p className="text-3">
//                                 <span className="text-wrapper-5">make your Wedding Look Special!</span>
//                             </p>
//                         </div>
//                     </div>
//                     <div className="overlap-4">
//                         <div className="youvati-logo-wrapper">
//                             <img className="youvati-logo" alt="Youvati logo" src="youvati-logo-1.png" />
//                         </div>
//                         <div className="text-wrapper-6">Why Choose Us</div>
//                         <div className="text-wrapper-7">For Partners</div>
//                         <div className="text-wrapper-8">Contact Us</div>
//                         <img className="line-3" alt="Line" src="line-4.svg" />
//                         <img className="line-4" alt="Line" src="line-5.svg" />
//                         <div className="overlap-5">
//                             <img className="line-5" alt="Line" src="line-6.svg" />
//                             <div className="overlap-6">
//                                 <img className="new" alt="New" src="new-1.png" />
//                                 <div className="text-wrapper-9">Bridal</div>
//                             </div>
//                         </div>
//                     </div>
//                     <p className="text-wrapper-10">We Have Beauty Professionals Listed From The Following Cities:</p>
//                     <p className="text-wrapper-11">
//                         Delhi | Mumbai | Aurangabad | Washim | Jalna | Agra | Kanpur | Meerut | Ghaziabad | Chandigargh | Patiala |
//                         Amritsar | Bathinda | Mansa
//                     </p>
//                     <img className="line-6" alt="Line" src="line-7.svg" />
//                     <div className="overlap-7">
//                         <img className="ellipse" alt="Ellipse" src="ellipse-4.png" />
//                         <img className="ellipse-2" alt="Ellipse" src="ellipse-3.png" />
//                     </div>
//                     <img className="ellipse-3" alt="Ellipse" src="ellipse-5.png" />
//                     <div className="text-wrapper-12">Service Categories</div>
//                     <div className="text-wrapper-13">Top Bridal Artists</div>
//                     <div className="text-wrapper-14">Others</div>
//                     <div className="overlap-8">
//                         <div className="overlap-9">
//                             <div className="text-wrapper-15">48</div>
//                             <div className="text-wrapper-16">Cities</div>
//                         </div>
//                         <div className="overlap-10">
//                             <div className="text-wrapper-17">500 +</div>
//                             <div className="text-wrapper-18">Beauty Professionals</div>
//                         </div>
//                         <div className="overlap-11">
//                             <div className="text-wrapper-19">10000 +</div>
//                             <div className="text-wrapper-20">Bookings Done</div>
//                         </div>
//                         <div className="overlap-12">
//                             <div className="text-wrapper-21">4.8</div>
//                             <div className="text-wrapper-22">Average Rating</div>
//                         </div>
//                     </div>
//                     <img className="rectangle-5" alt="Rectangle" src="rectangle-12.png" />
//                     <img className="rectangle-6" alt="Rectangle" src="rectangle-14.png" />
//                     <div className="text-wrapper-23">Neha Makeovers</div>
//                     <div className="text-wrapper-24">Pihu Makeover</div>
//                     <div className="text-wrapper-25">Sita Makeover</div>
//                     <img className="rectangle-7" alt="Rectangle" src="rectangle-15.png" />
//                     <img className="rectangle-8" alt="Rectangle" src="rectangle-17.png" />
//                     <div className="text-wrapper-26">Mahi Makeover</div>
//                     <div className="overlap-13">
//                         <img className="rectangle-9" alt="Rectangle" src="rectangle-20.png" />
//                         <div className="rectangle-10" />
//                         <img className="next" alt="Next" src="next-2.png" />
//                     </div>
//                     <img className="rectangle-11" alt="Rectangle" src="rectangle-21.png" />
//                     <img className="rectangle-12" alt="Rectangle" src="rectangle-22.png" />
//                     <img className="rectangle-13" alt="Rectangle" src="rectangle-23.png" />
//                     <div className="overlap-14">
//                         <img className="banner" alt="Banner" src="banner101-2.png" />
//                         <img className="banner-2" alt="Banner" src="banner101-3.png" />
//                         <img className="banner-3" alt="Banner" src="banner101-4.png" />
//                     </div>
//                     <div className="flexcontainer-5">
//                         <p className="text-4">
//                             <span className="text-wrapper-27">
//                                 We employ the best experts in the beauty industry and educate our own <br />
//                             </span>
//                         </p>
//                         <p className="text-4">
//                             <span className="text-wrapper-27">
//                                 award-winning specialists through constant training. Here are the most prominent
//                                 <br />
//                             </span>
//                         </p>
//                         <p className="text-4">
//                             <span className="text-wrapper-27"> talents of our team who make your beauty dreams come true.</span>
//                         </p>
//                     </div>
//                     <div className="overlap-15">
//                         <img className="rectangle-14" alt="Rectangle" src="rectangle-24.png" />
//                         <div className="rectangle-15" />
//                         <img className="next-2" alt="Next" src="next-1.png" />
//                     </div>
//                     <div className="star-wrapper">
//                         <img className="star" alt="Star" src="icons8-star-96-47.png" />
//                     </div>
//                     <div className="icons-star-wrapper">
//                         <img className="star" alt="Star" src="icons8-star-96-48.png" />
//                     </div>
//                     <div className="img-wrapper">
//                         <img className="star" alt="Star" src="icons8-star-96-49.png" />
//                     </div>
//                     <div className="overlap-16">
//                         <img className="star" alt="Star" src="icons8-star-96-50.png" />
//                     </div>
//                     <img className="icons-star" alt="Star" src="icons8-star-96-13.png" />
//                     <img className="star-2" alt="Star" src="icons8-star-96-14.png" />
//                     <img className="star-3" alt="Star" src="icons8-star-96-15.png" />
//                     <img className="star-4" alt="Star" src="icons8-star-96-16.png" />
//                     <img className="star-5" alt="Star" src="icons8-star-96-45.png" />
//                     <img className="star-6" alt="Star" src="icons8-star-96-17.png" />
//                     <img className="star-7" alt="Star" src="icons8-star-96-18.png" />
//                     <img className="star-8" alt="Star" src="icons8-star-96-19.png" />
//                     <img className="star-9" alt="Star" src="icons8-star-96-20.png" />
//                     <img className="star-10" alt="Star" src="icons8-star-100-5.png" />
//                     <img className="star-11" alt="Star" src="icons8-star-100-11.png" />
//                     <img className="star-12" alt="Star" src="icons8-star-96-21.png" />
//                     <img className="star-13" alt="Star" src="icons8-star-96-22.png" />
//                     <img className="star-14" alt="Star" src="icons8-star-96-23.png" />
//                     <img className="star-15" alt="Star" src="icons8-star-96-24.png" />
//                     <img className="star-16" alt="Star" src="icons8-star-96-43.png" />
//                     <div className="overlap-17">
//                         <img className="screenshot" alt="Screenshot" src="screenshot-2023-10-13-141721.png" />
//                         <img className="screenshot-2" alt="Screenshot" src="screenshot-2023-10-13-141737.png" />
//                         <img className="screenshot-3" alt="Screenshot" src="screenshot-2023-10-13-142047-1.png" />
//                     </div>
//                     <img className="ellipse-4" alt="Ellipse" src="ellipse-1.png" />
//                     <img className="ellipse-5" alt="Ellipse" src="ellipse-6.png" />
//                     <img className="ellipse-6" alt="Ellipse" src="ellipse-2.png" />
//                     <div className="text-wrapper-28">Makeup</div>
//                     <div className="text-wrapper-29">Bridal</div>
//                     <div className="text-wrapper-30">Skin</div>
//                     <div className="text-wrapper-31">Hair</div>
//                 </div>
//             </div>
//         </>
//     );
// }

// export default Bridal;