import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import db from "./Firebase";
import { CSVLink } from "react-csv";
import Moment from 'moment';
import { extendMoment } from 'moment-range';

function WorkshopQuery() {
    const navigate = useNavigate();
    const moment = extendMoment(Moment);
    const [data, setData] = useState([]);
    const [csvdata, setCsvdata] = useState([]);
    const [uid, setUid] = useState('');
    const [searchQuery, setSearchQuery] = useState("");
    const [lastVisible, setLastVisible] = useState(null);
    const [loading, setLoading] = useState(true);
    const [hasMore, setHasMore] = useState(true);

    const headers = [
        "Full Name",
        "Username",
        "Phone No",
        "Workshop",
        "Query",
        "Status",
        "Updated Date",
        "Created Date",
    ];

    const [doneCount, setDoneCount] = useState(0);

    useEffect(() => {
        const storedUsername = localStorage.getItem("email");
        const storedPassword = localStorage.getItem("password");
        const storedRemember = localStorage.getItem("remember");

        if (storedRemember === "false" || !storedUsername || !storedPassword) {
            navigate('/signin');
        }
    }, []);

    useEffect(() => {
        fetchInitialData();
    }, []);

    const fetchInitialData = async () => {
        setLoading(true);
        const snapshot = await db.collection("workshop_query")
            .orderBy("created_date", "desc")
            .limit(20)
            .get();
        const lastVisibleDoc = snapshot.docs[snapshot.docs.length - 1];
        const newData = snapshot.docs.map((doc) => ({
            id: doc.id,
            data: doc.data(),
        }));

        const count = newData.filter(item => item.data.status === 'done').length;

        setData(newData);
        setLastVisible(lastVisibleDoc);
        setCsvdata(newData.map((item) => ({
            ...item.data,
            created_date: moment(item.data.created_date.toDate().toISOString()).format("MM/DD/YY, h:mm a"),
            updated_date: moment(item.data.updated_date.toDate().toISOString()).format("MM/DD/YY, h:mm a"),
        })));
        setDoneCount(count);
        setHasMore(snapshot.docs.length === 20);
        setLoading(false);
    };

    const loadMoreData = async () => {
        if (!lastVisible) return;

        setLoading(true);
        const snapshot = await db.collection("workshop_query")
            .orderBy("created_date", "desc")
            .startAfter(lastVisible)
            .limit(20)
            .get();
        const lastVisibleDoc = snapshot.docs[snapshot.docs.length - 1];
        const newData = snapshot.docs.map((doc) => ({
            id: doc.id,
            data: doc.data(),
        }));

        const count = newData.filter(item => item.data.status === 'done').length;

        setData((prevData) => [...prevData, ...newData]);
        setLastVisible(lastVisibleDoc);
        setCsvdata((prevData) => [...prevData, ...newData.map((item) => ({
            ...item.data,
            created_date: moment(item.data.created_date.toDate().toISOString()).format("MM/DD/YY, h:mm a"),
            updated_date: moment(item.data.updated_date.toDate().toISOString()).format("MM/DD/YY, h:mm a"),
        }))]);
        setDoneCount(prevCount => prevCount + count);
        setHasMore(snapshot.docs.length === 20);
        setLoading(false);
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const filteredData = data.filter((item) => {
        const username = item.data.username.toLowerCase();
        const full_name = item.data.full_name.toLowerCase();
        const phone_no = item.data.phone_no.toLowerCase();
        const event_name = item.data.event_name.toLowerCase();
        const status = item.data.status.toLowerCase();
        const query = searchQuery.toLowerCase();

        return (
            username.includes(query) ||
            full_name.includes(query) ||
            phone_no.includes(query) ||
            event_name.includes(query) ||
            status.includes(query)
        );
    });

    const prepareCSVData = () => {
        const csvData = filteredData.map((item) => ([
            item.data.full_name,
            item.data.username,
            item.data.phone_no,
            item.data.event_name,
            item.data.query,
            item.data.status,
            moment(item.data.created_date.toDate().toISOString()).format("MM/DD/YY, h:mm a"),
            moment(item.data.updated_date.toDate().toISOString()).format("MM/DD/YY, h:mm a"),
        ]));

        return csvData;
    };

    const handleChange2 = async (e, msg) => {
        const { id, value } = e.target;
        if (value === "1") {
            const { value: text } = await Swal.fire({
                input: 'textarea',
                inputLabel: 'Remarks',
                inputPlaceholder: 'Enter Remarks Here...',
                showCancelButton: true,
            });

            if (text) {
                db.collection("workshop_query").doc(uid).update({ 'status': "done", remarks: text, updated_date: new Date() }).then(() => {
                    console.log("query updated");
                    Swal.fire({
                        icon: 'success',
                        title: 'Updated successfully',
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', Swal.stopTimer);
                            toast.addEventListener('mouseleave', Swal.resumeTimer);
                        },
                    });
                });
            }
        }
    };

    return (
        <>
            <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg">
                <div className="p-2" style={{ paddingBottom: "5px", display: "flex", justifyContent: "space-between" }}>
                    <Link to="/admin_panel">
                        <img src="/images/menubutton.png" className="menu-toggle" alt="Responsive image" style={{ height: "2rem" }} />
                    </Link>
                    <CSVLink data={prepareCSVData()} headers={headers} filename={"workshop_queries.csv"}>
                        <span className="btn bg-gradient-secondary btn-sm mb-0 mr-2" style={{ color: "white" }}>
                            &nbsp; Download Excel
                        </span>
                    </CSVLink>
                </div>
                <div className="p-2">
                    <div className="card">
                        <div className="card-header pb-0 px-3">
                            <h6 className="mb-2">Workshop Query || {`Records : ${filteredData.length}`}</h6>
                        </div>
                        <div className="d-flex p-2" style={{ justifyContent: "center", fontWeight: "bold" }}>
                            Total : {data.length} || Pending : {data.length - doneCount} || Done : {doneCount}
                        </div>
                        <input
                            type="text"
                            className="form-control form-control-sm"
                            placeholder="Search by Name, Username, Phone, Workshop, or Status"
                            value={searchQuery}
                            onChange={handleSearchChange}
                        />
                        <div className="card">
                            <div className="table-responsive fix-width scroll-inner">
                                <table className="table table-bordered table-hover">
                                    <thead className="thead-dark" style={{ position: "sticky", top: "0" }}>
                                        <tr style={{ textAlign: "left" }}>
                                            <th className="col ps-2">S.No</th>
                                            <th className="col ps-2">Full Name</th>
                                            <th className="col">Username</th>
                                            <th className="col ps-2">Phone No</th>
                                            <th className="col ps-2">Workshop</th>
                                            <th className="col ps-2">Query</th>
                                            <th className="col ps-2">Remark</th>
                                            <th className="col ps-2">Status</th>
                                            <th className="col ps-2">Created Date</th>
                                            <th className="col ps-2">Updated Date</th>
                                            <th className="col ps-2">Action</th>
                                            <th className="col ps-2">Remarks</th>
                                        </tr>
                                    </thead>
                                    <tbody style={{ textAlign: "left" }}>
                                        {filteredData.map((item, index) => (
                                            <tr key={item.id}>
                                                <td>
                                                    <p className="text-xs mb-0">{index + 1}.</p>
                                                </td>
                                                <td>
                                                    <p className="text-xs mb-0">{item.data.full_name}</p>
                                                </td>
                                                <td>
                                                    <p className="text-xs mb-0">{item.data.username}</p>
                                                </td>
                                                <td>
                                                    <p className="text-xs mb-0">{item.data.phone_no}</p>
                                                </td>
                                                <td>
                                                    <p className="text-xs mb-0">{item.data.event_name}</p>
                                                </td>
                                                <td style={{ whiteSpace: "pre-line", width: "21rem" }}>
                                                    <p className="text-xs mb-0">{item.data.query}</p>
                                                </td>
                                                <td style={{ whiteSpace: "pre-line", width: "21rem" }}>
                                                    <p className="text-xs mb-0">{item.data.remark}</p>
                                                </td>
                                                <td>
                                                    <p className="text-xs mb-0">{item.data.status}</p>
                                                </td>
                                                <td>
                                                    <div className="d-flex px-2 py-1">
                                                        <div className="d-flex flex-column justify-content-center">
                                                            <h6 className="mb-0 text-xs">{moment(item.data.created_date.toDate().toISOString()).format("MM/DD/YY, h:mm a")}</h6>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="d-flex px-2 py-1">
                                                        <div className="d-flex flex-column justify-content-center">
                                                            <h6 className="mb-0 text-xs">{moment(item.data.updated_date.toDate().toISOString()).format("MM/DD/YY, h:mm a")}</h6>
                                                        </div>
                                                    </div>
                                                </td>
                                                {item.data.status === "pending" ? (
                                                    <td>
                                                        <select className="form-control" id={index} name="status" onClick={() => setUid(item.id)} onChange={handleChange2} value={item.data.status} style={{ padding: "7px", width: "9rem" }}>
                                                            <option selected={item.data.status === "pending"} value="0">Pending</option>
                                                            <option selected={item.data.status === "done"} value="1">Done</option>
                                                        </select>
                                                    </td>
                                                ) : (
                                                    <td>
                                                        <select className="form-control" id="request_status" name="request_status" style={{ width: "8rem", background: "green", color: "white" }} disabled>
                                                            <option selected value="Done">Done</option>
                                                        </select>
                                                    </td>
                                                )}
                                                <td>
                                                    <textarea className="form-control" value={item.data.remarks} style={{ width: "18rem", background: "#ddd" }} disabled />
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                {hasMore && (
                                    <div className="" style={{ paddingTop: "2rem" }}>
                                        <button onClick={loadMoreData} className="btn btn-dark">Load More</button>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
}

export default WorkshopQuery;



// import React, { useState, useEffect } from 'react';
// import { useNavigate, Link, useParams } from 'react-router-dom';
// import Swal from 'sweetalert2'
// import db from "./Firebase";
// import { arrayUnion } from "firebase/firestore";
// import { storage } from './Firebase';
// import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
// import Moment from 'moment';
// import { extendMoment } from 'moment-range';
// import ViewAllRequests from './ViewAllRequests';
// import ViewAppData from './ViewAppData';
// import { CSVLink } from "react-csv";

// function WorkshopQuery() {
//     const navigate = useNavigate();
//     const moment = extendMoment(Moment);
//     const [data, setData] = useState([]);
//     const [csvdata, setCsvdata] = useState([]);
//     const [uid, setUid] = useState('');
//     const [searchQuery, setSearchQuery] = useState("");

//     useEffect(() => {
//         const storedUsername = localStorage.getItem("email");
//         const storedPassword = localStorage.getItem("password");
//         const storedRemember = localStorage.getItem("remember");

//         if (storedRemember === "false" || !storedUsername || !storedPassword) {
//             navigate('/signin');
//         }
//     }, []);

//     const headers = [
//         "Full Name",
//         "Username",
//         "Phone No",
//         "Workshop",
//         "Query",
//         "Status",
//         "Updated Date",
//         "Created Date",
//         // Add more column labels as needed
//     ];

//     const [doneCount, setDoneCount] = useState(0);
//     useEffect(() => {
//         const fetchData = () => {
//             try {
//                 db.collection("workshop_query")
//                     .orderBy("created_date", "desc")
//                     .onSnapshot((snapshot) => {
//                         const dataArray = [];
//                         let count = 0;

//                         snapshot.forEach((doc) => {
//                             const documentData = {
//                                 id: doc.id,
//                                 data: doc.data(),
//                             };

//                             dataArray.push(documentData);

//                             // Check the status field and increment count
//                             if (documentData.data.status === 'done') {
//                                 count += 1;
//                             }
//                         });

//                         setData(dataArray);
//                         setDoneCount(count);
//                     });
//             } catch (error) {
//                 console.error('Error fetching data:', error);
//             }
//         };

//         fetchData();
//     }, []);

//     // useEffect(() => {
//     //     // const usersData = [];

//     //     // Fetch data from "users_location" collection
//     //     // db.collection("workshop_query").orderBy("created_date", "desc").onSnapshot((snapshot) => {
//     //     //     usersData.length = 0; // Clear the array
//     //     //     snapshot.docs.forEach((doc) => {
//     //     //         usersData.push({
//     //     //             id: doc.id,
//     //     //             data: doc.data(),
//     //     //         });
//     //     //     });
//     //     //     // mergeData();
//     //     //     setData(usersData);
//     //     // });

//     //     db.collection("workshop_query").orderBy("created_date", "desc").onSnapshot((snapshot) => {
//     //         setData(
//     //             snapshot.docs.map((doc) => ({
//     //                 id: doc.id,
//     //                 data: doc.data(),
//     //             }))
//     //         );
//     //     });

//     // }, []);

//     const handleSearchChange = (event) => {
//         setSearchQuery(event.target.value);
//     };


//     // Function to filter data based on the search query
//     const filteredData = data.filter((item) => {
//         const username = item.data.username.toLowerCase();
//         const full_name = item.data.full_name.toLowerCase();
//         const phone_no = item.data.phone_no.toLowerCase();
//         const event_name = item.data.event_name.toLowerCase();
//         const status = item.data.status.toLowerCase();
//         const query = searchQuery.toLowerCase();

//         return (
//             username.includes(query) ||
//             full_name.includes(query) ||
//             phone_no.includes(query) ||
//             event_name.includes(query) ||
//             status.includes(query)
//         );
//     });

//     // Function to convert data to CSV format
//     const prepareCSVData = () => {
//         const csvData = filteredData.map((item, index) => ([
//             item.data.full_name,
//             item.data.username,
//             item.data.phone_no,
//             item.data.event_name,
//             item.data.query,
//             item.data.status,
//             moment(item.data.created_date.toDate().toISOString()).format("MM/DD/YY, h:mm a"),
//             moment(item.data.updated_date.toDate().toISOString()).format("MM/DD/YY, h:mm a"),
//             // Add more columns as needed
//         ]));

//         return csvData;
//     };


//     const handleChange2 = async (e, msg) => {
//         const { id, value } = e.target
//         if (e.target.value == "1") {
//             const { value: text } = await Swal.fire({
//                 input: 'textarea',
//                 inputLabel: 'Remarks',
//                 inputPlaceholder: 'Enter Remarks Here...',
//                 showCancelButton: true,
//             })

//             if (text) {
//                 // Swal.fire(`Entered text: ${text}`)
//                 db.collection("workshop_query").doc(uid).update({ 'status': "done", remarks: text, updated_date: new Date() }).then(() => {
//                     console.log("query added");
//                     const Toast = Swal.mixin({
//                         toast: true,
//                         position: 'top-end',
//                         showConfirmButton: false,
//                         timer: 3000,
//                         timerProgressBar: true,
//                         didOpen: (toast) => {
//                             toast.addEventListener('mouseenter', Swal.stopTimer);
//                             toast.addEventListener('mouseleave', Swal.resumeTimer);
//                         },
//                     });

//                     Toast.fire({
//                         icon: 'success',
//                         title: 'Updated successfully',
//                     });
//                 })
//             }

//         } else {
//             // alert("two")
//             // db.collection("business_card_request").doc(uid).update({ 'status': "0", 'updated_date': new Date() }).then(() => {
//             //     console.log("user added");
//             // })
//         }
//     }




//     return (
//         <>
//             <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg">

//                 <div class="p-2" style={{ paddingBottom: "5px", display: "flex", justifyContent: "space-between" }}>
//                     <Link to="/admin_panel">
//                         <img src="/images/menubutton.png" class="menu-toggle" alt="Responsive image" style={{ height: "2rem" }} />
//                     </Link>
//                     <CSVLink data={prepareCSVData()} headers={headers} filename={"clicked_logs.csv"}>
//                         <span class="btn bg-gradient-secondary btn-sm mb-0 mr-2" style={{ color: "white" }}>
//                             &nbsp; Download Excel
//                         </span>
//                     </CSVLink>
//                 </div>
//                 <div class="">

//                     <div class="p-2">

//                         <div class="card">
//                             <div class="card-header pb-0 px-3">
//                                 <h6 class="mb-2">Workshop Query || {`Records : ${filteredData.length}`}</h6>
//                             </div>
//                             <div class="d-flex p-2" style={{ justifyContent: "center", fontWeight: "bold" }}>
//                                 Total : {data.length} || Pending : {data.length - doneCount} || Done : {doneCount}
//                             </div>
//                             <input
//                                 type="text"
//                                 className="form-control form-control-sm"
//                                 placeholder="Search by Name, Username, Phone, Workshop, or Status"
//                                 value={searchQuery}
//                                 onChange={handleSearchChange}
//                             />
//                             <div class="">
//                                 <div class="table-responsive fix-width scroll-inner">
//                                     <table class="table table-bordered table-hover">
//                                         <thead class="thead-dark" style={{ position: "sticky", top: "0" }}>
//                                             <tr style={{ textAlign: "left" }}>
//                                                 <th class="col ps-2">S.No</th>
//                                                 <th class="col ps-2">Full Name</th>
//                                                 <th class="col">Username</th>
//                                                 <th class="col ps-2">Phone No</th>
//                                                 <th class="col ps-2">Workshop</th>
//                                                 <th class="col ps-2">Query</th>
//                                                 <th class="col ps-2">Remark</th>
//                                                 <th class="col ps-2">Status</th>
//                                                 <th class="col ps-2">Created Date</th>
//                                                 <th class="col ps-2">Updated Date</th>
//                                                 <th class="col ps-2">Action</th>
//                                                 <th class="col ps-2">Remarks</th>
//                                             </tr>
//                                         </thead>
//                                         <tbody style={{ textAlign: "left" }}>
//                                             {filteredData.map((item, index) => (
//                                                 <tr key={item.id}>
//                                                     <td>
//                                                         <p class="text-xs mb-0">{index + 1}.</p>
//                                                     </td>
//                                                     <td>
//                                                         <p class="text-xs mb-0">{item.data.full_name}</p>
//                                                     </td>
//                                                     <td>
//                                                         <p class="text-xs mb-0">{item.data.username}</p>
//                                                     </td>
//                                                     <td>
//                                                         <p class="text-xs mb-0">{item.data.phone_no}</p>
//                                                     </td>
//                                                     <td>
//                                                         <p class="text-xs mb-0">{item.data.event_name}</p>
//                                                     </td>

//                                                     <td style={{ whiteSpace: "pre-line", width: "21rem" }}>
//                                                         <p class="text-xs mb-0">{item.data.query}</p>
//                                                     </td>
//                                                     <td style={{ whiteSpace: "pre-line", width: "21rem" }}>
//                                                         <p class="text-xs mb-0">{item.data.remark}</p>
//                                                     </td>
//                                                     <td>
//                                                         <p class="text-xs mb-0">{item.data.status}</p>
//                                                     </td>


//                                                     <td>
//                                                         <div class="d-flex px-2 py-1">
//                                                             <div class="d-flex flex-column justify-content-center">
//                                                                 <h6 class="mb-0 text-xs">{moment(item.data.created_date.toDate().toISOString()).format("MM/DD/YY, h:mm a")}</h6>
//                                                             </div>
//                                                         </div>
//                                                     </td>

//                                                     <td>
//                                                         <div class="d-flex px-2 py-1">
//                                                             <div class="d-flex flex-column justify-content-center">
//                                                                 <h6 class="mb-0 text-xs">{moment(item.data.updated_date.toDate().toISOString()).format("MM/DD/YY, h:mm a")}</h6>
//                                                             </div>
//                                                         </div>
//                                                     </td>
//                                                     {item.data.status === "pending" ?
//                                                         <>
//                                                             <td>
//                                                                 <select class="form-control" id={index} name="status" onClick={() => setUid(item.id)} onChange={handleChange2} value={data.status} style={{ padding: "7px", width: "9rem", backgroundColor: data.status == 1 ? "#28a745" : "transparent" }} disabled={data.status == 1 ? true : false} >
//                                                                     <option selected={item.data.status === "pending" ? true : false} value="0">Pending</option>
//                                                                     <option selected={item.data.status === "done" ? true : false} value="1">Done</option>
//                                                                 </select>
//                                                             </td>
//                                                         </> : <>
//                                                             <td>
//                                                                 <select class="form-control" id="request_status" name="request_status" style={{ width: "8rem", background: "green", color: "white" }}>
//                                                                     <option selected value="Done" disabled>Done</option>
//                                                                 </select>
//                                                             </td>

//                                                         </>}

//                                                     <td>
//                                                         <textarea class="form-control" value={item.data.remarks} style={{ width: "18rem", background: "#ddd" }} disabled />
//                                                     </td>

//                                                     {/* Other table cells */}
//                                                 </tr>
//                                             ))}
//                                         </tbody>
//                                     </table>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </main>
//         </>
//     );
// }

// export default WorkshopQuery;
