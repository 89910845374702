import React, { useState, useEffect } from 'react';
import { useNavigate, Link, useParams } from 'react-router-dom';
import db from "./Firebase";
import firebaseApp from "./Firebase";
import { arrayUnion } from "firebase/firestore";
import { storage } from './Firebase';
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import ViewAllRequests from './ViewAllRequests';
import ViewAppData from './ViewAppData';
import { CSVLink } from "react-csv";

function Notifications() {
    const navigate = useNavigate();
    const moment = extendMoment(Moment);
    const [data, setData] = useState([]);

    useEffect(() => {
        const storedUsername = localStorage.getItem("email");
        const storedPassword = localStorage.getItem("password");
        const storedRemember = localStorage.getItem("remember");

        if (storedRemember === "false" || !storedUsername || !storedPassword) {
            navigate('/signin');
        }
    }, []);



    useEffect(() => {
        const usersData = [];

        // Fetch data from "users_location" collection
        db.collection("all_notification").orderBy("created_date", "desc").onSnapshot((snapshot) => {
            setData(
                snapshot.docs.map((doc) => ({
                    id: doc.id,
                    data: doc.data(),
                }))
            );

        });

    }, []);






    return (
        <>
            <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg">

                <div class="p-2" style={{ textAlign: "start", paddingBottom: "5px" }}>
                    <Link to="/admin_panel">
                        <img src="/images/menubutton.png" class="menu-toggle" alt="Responsive image" style={{ height: "2rem" }} />
                    </Link>
                </div>
                <div class="row">

                    <div class="mt-4 p-4">

                        <div class="card">
                            <div class="card-header pb-0 px-3">
                                <h6 class="mb-2">All Notifications || {`Records : ${data.length}`}</h6>
                            </div>

                            <div class="card-body">
                                <div class="table-responsive" style={{ maxHeight: "400px", overflowY: "scroll" }}>
                                    <table class="table align-items-center mb-0">
                                        <thead>
                                            <tr style={{ textAlign: "left" }}>
                                                <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">S.No</th>
                                                <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Name</th>
                                                <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Created Date</th>
                                                <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Message</th>
                                                <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody style={{ textAlign: "left" }}>
                                            {data?.map(({ id, data }, index) => (
                                                <tr key={id}>
                                                    <td>
                                                        <p class="text-xs mb-0">{index + 1}.</p>
                                                    </td>
                                                    <td>
                                                        <p class="text-xs mb-0">{data.name}</p>
                                                    </td>

                                                    <td>
                                                        <div class="d-flex px-2 py-1">
                                                            <div class="d-flex flex-column justify-content-center">
                                                                <h6 class="mb-0 text-xs">{moment(data.created_date.toDate().toISOString()).format("MM/DD/YY, h:mm a")}</h6>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <p class="text-xs mb-0" style={{ whiteSpace: 'pre-wrap' }}>{data.message}</p>
                                                    </td>

                                                    <td>
                                                        <Link to={`/${data.link}`}><span style={{ cursor: "pointer" }} class="badge badge-success ms-auto mb-auto mr-3">View</span></Link>
                                                        {/* <span style={{ cursor: "pointer" }} class="badge badge-danger ms-auto mb-auto mr-3">Delete</span> */}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
}

export default Notifications;
