import React, { useState, useEffect } from 'react';
import AdminPanel from './AdminPanel';
import Swal from 'sweetalert2'
import { useNavigate, Link, useParams } from 'react-router-dom';
import db from "./Firebase";
import { storage } from './Firebase';
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import ViewAppData from './ViewAppData';
import Multiselect from 'multiselect-react-dropdown';


function WorkshopData() {
    const navigate = useNavigate();
    const moment = extendMoment(Moment);
    const initialFormStateFeedback = { id: '', image: '', name: '', expert: '', phone_no: '', description: '', type: '', mode: '', direction: '', instagram_id: '', organiser: '', amount: '', isCertificate: '', isVideo: '', created_by: '', event_type: '', start_date_time: '', end_date_time: '', zoom_link: '', passcode: '', meeting_id: '', created_date: new Date() }
    const [submit, setSubmit] = useState(initialFormStateFeedback)
    const [data, setData] = useState([]);
    const [editID, setEditID] = useState("");
    const [edit, setEdit] = useState(false);
    const [ShowProgress, setShowProgress] = useState(false);
    const [show, setshow] = useState(true);
    const [progresspercent, setProgresspercent] = useState(0);

    const [options, setOptions] = useState([
        { name: 'Online' },
        { name: 'Aurangabad' },
        { name: 'Bathinda' },
        { name: 'Ghaziabad' },
        { name: 'Meerut' },
        { name: 'Mansa' },
        { name: 'Patiala' }
    ]);

    const [selectedValue, setSelectedValue] = useState([]);


    const onSelect = (selectedList, selectedItem) => {
        // Handle selection
        // ...
        setSelectedValue(selectedList);
    }

    const onRemove = (selectedList, removedItem) => {
        // Handle removal
        // ...
        setSelectedValue(selectedList);
    }

    useEffect(() => {
        const storedUsername = localStorage.getItem("email");
        const storedPassword = localStorage.getItem("password");
        const storedRemember = localStorage.getItem("remember");

        if (storedRemember === "false" || !storedUsername || !storedPassword) {
            navigate('/signin');
        }
    }, []);


    const handleChange = (e) => {
        const { id, value } = e.target
        setSubmit({ ...submit, [id]: value })
    }

    const changeStatus = (data, id) => {
        setEdit(true)
        setEditID(id)
        const dataFromDatabase = data.location.map((name) => ({
            name: name, // Assuming your data has a 'name' field
        }));
        setSelectedValue(dataFromDatabase);
        setSubmit({ id: id, image: data.image, name: data.name, expert: data.expert, phone_no: data.phone_no, description: data.description, type: data.type, mode: data.mode, direction: data.direction, instagram_id: data.instagram_id, organiser: data.organiser, event_type: data.event_type, amount: data.amount, isCertificate: data.isCertificate, isVideo: data.isVideo, created_by: data.created_by, start_date_time: moment(data.start_date_time.toDate().toISOString()).format("YYYY-MM-DDTHH:mm"), end_date_time: moment(data.end_date_time.toDate().toISOString()).format("YYYY-MM-DDTHH:mm"), zoom_link: data.zoom_link, passcode: data.passcode, meeting_id: data.meeting_id, created_date: new Date() });
        openModalShow();
    }

    const addNew = () => {
        setEdit(false)
        openModalShow();
        setSubmit({ id: '', image: '', name: '', expert: '', phone_no: '', description: '', type: '', mode: '', direction: '', instagram_id: '', organiser: '', event_type: '', amount: '', isCertificate: '', isVideo: '', created_by: localStorage.getItem("email"), start_date_time: '', end_date_time: '', zoom_link: '', passcode: '', meeting_id: '', created_date: new Date() });
    }

    const openModalShow = () => {
        // alert("ooo")
        window.openModelshow();
    }

    const closeModel = () => {
        window.openModelNew();
    }

    const handleSubmit = (e) => {
        console.log(e.target);
        setShowProgress(true)
        const locationData = [];
        console.log("callingggggg")
        e.preventDefault()
        selectedValue.map(item => {
            locationData.push(item.name)
        })
        // console.log(locationData);
        if (locationData.length == 0) {
            alert("Please add location first!")
        } else {
            if (edit) {
                console.log("edit")
                if (e.target[0]?.files[0] == null) {
                    db.collection("workshop_event_data").doc(editID).update({
                        'name': submit.name,
                        'name_english': submit.name,
                        'expert': submit.expert,
                        'phone_no': submit.phone_no,
                        'description': submit.description,
                        'type': submit.type,
                        'mode': submit.mode,
                        'direction': submit.direction,
                        'instagram_id': submit.instagram_id,
                        'zoom_link': submit.zoom_link,
                        'passcode': submit.passcode,
                        'meeting_id': submit.meeting_id,
                        'amount': submit.amount,
                        'event_type': submit.event_type,
                        'recorded_session': '',
                        'organiser': submit.organiser,
                        'isCertificate': submit.isCertificate,
                        'isVideo': submit.isVideo,
                        'created_by': localStorage.getItem("email"),
                        'start_date_time': new Date(submit.start_date_time),
                        'end_date_time': new Date(submit.end_date_time),
                        'uid': "",
                        'location': locationData,
                        'pin': 0,
                        'image': submit.image,
                        'created_date': new Date(),
                    }).then(() => {
                        console.log('Updated Successfully');
                        setShowProgress(false)
                        const Toast = Swal.mixin({
                            toast: true,
                            background: '#69aba6',
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 3000,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener('mouseenter', Swal.stopTimer)
                                toast.addEventListener('mouseleave', Swal.resumeTimer)
                            }
                        })

                        Toast.fire({
                            icon: 'success',
                            title: 'Updated Successfully'
                        })
                        closeModel();

                        setSubmit({ id: '', image: '', name: '', expert: '', phone_no: '', description: '', mode: '', direction: '', instagram_id: '', type: '', organiser: '', event_type: '', amount: '', isCertificate: '', isVideo: '', created_by: '', zoom_link: '', passcode: '', meeting_id: '', start_date_time: '', end_date_time: '', created_date: new Date() })
                    })
                } else {
                    const file = e.target[0]?.files[0]
                    // console.log(file)
                    if (!file) return;
                    const storageRef = ref(storage, `files/${file.name}`);
                    const uploadTask = uploadBytesResumable(storageRef, file);
                    setshow(false)

                    uploadTask.on("state_changed",
                        (snapshot) => {
                            const progress =
                                Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                            setProgresspercent(progress);
                        },
                        (error) => {
                            alert(error);
                        },
                        () => {

                            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                                // setImgUrl(downloadURL)
                                db.collection("workshop_event_data").doc(editID).update({
                                    'name': submit.name,
                                    'name_english': submit.name,
                                    'expert': submit.expert,
                                    'phone_no': submit.phone_no,
                                    'description': submit.description,
                                    'type': submit.type,
                                    'mode': submit.mode,
                                    'direction': submit.direction,
                                    'instagram_id': submit.instagram_id,
                                    'zoom_link': submit.zoom_link,
                                    'passcode': submit.passcode,
                                    'meeting_id': submit.meeting_id,
                                    'amount': submit.amount,
                                    'recorded_session': '',
                                    'organiser': submit.organiser,
                                    'event_type': submit.event_type,
                                    'isCertificate': submit.isCertificate,
                                    'isVideo': submit.isVideo,
                                    'created_by': localStorage.getItem("email"),
                                    'start_date_time': new Date(submit.start_date_time),
                                    'end_date_time': new Date(submit.end_date_time),
                                    'uid': "",
                                    'pin': 0,
                                    'location': locationData,
                                    'image': downloadURL,
                                    'created_date': new Date(),
                                }).then(() => {
                                    console.log('Updated Successfully');
                                    setShowProgress(false)
                                    const Toast = Swal.mixin({
                                        toast: true,
                                        background: '#69aba6',
                                        position: 'top-end',
                                        showConfirmButton: false,
                                        timer: 3000,
                                        timerProgressBar: true,
                                        didOpen: (toast) => {
                                            toast.addEventListener('mouseenter', Swal.stopTimer)
                                            toast.addEventListener('mouseleave', Swal.resumeTimer)
                                        }
                                    })

                                    Toast.fire({
                                        icon: 'success',
                                        title: 'Updated Successfully'
                                    })
                                    closeModel();
                                    setshow(true)
                                    setSubmit({ id: '', image: '', name: '', expert: '', phone_no: '', description: '', type: '', mode: '', direction: '', instagram_id: '', organiser: '', event_type: '', amount: '', isCertificate: '', isVideo: '', created_by: '', zoom_link: '', passcode: '', meeting_id: '', start_date_time: '', end_date_time: '', created_date: new Date() })
                                })
                            });
                        }
                    );
                }

            } else {
                const file = e.target[0]?.files[0]
                // console.log(file)
                if (!file) return;
                const storageRef = ref(storage, `files/${file.name}`);
                const uploadTask = uploadBytesResumable(storageRef, file);
                setshow(false)

                uploadTask.on("state_changed",
                    (snapshot) => {
                        const progress =
                            Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                        setProgresspercent(progress);
                    },
                    (error) => {
                        alert(error);
                    },
                    () => {

                        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                            // setImgUrl(downloadURL)
                            db.collection("workshop_event_data").add({
                                'name': submit.name,
                                'name_english': submit.name,
                                'expert': submit.expert,
                                'phone_no': submit.phone_no,
                                'description': submit.description,
                                'type': submit.type,
                                'mode': submit.mode,
                                'direction': submit.direction,
                                'instagram_id': submit.instagram_id,
                                'zoom_link': submit.zoom_link,
                                'passcode': submit.passcode,
                                'meeting_id': submit.meeting_id,
                                'amount': submit.amount,
                                'recorded_session': '',
                                'organiser': submit.organiser,
                                'event_type': submit.event_type,
                                'isCertificate': submit.isCertificate,
                                'isVideo': submit.isVideo,
                                'created_by': localStorage.getItem("email"),
                                'location': locationData,
                                'start_date_time': new Date(submit.start_date_time),
                                'end_date_time': new Date(submit.end_date_time),
                                'uid': "",
                                'pin': 0,
                                'image': downloadURL,
                                'created_date': new Date(),
                            }).then(() => {
                                console.log('Created Successfully');
                                const Toast = Swal.mixin({
                                    toast: true,
                                    background: '#69aba6',
                                    position: 'top-end',
                                    showConfirmButton: false,
                                    timer: 3000,
                                    timerProgressBar: true,
                                    didOpen: (toast) => {
                                        toast.addEventListener('mouseenter', Swal.stopTimer)
                                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                                    }
                                })
                                setShowProgress(false)

                                Toast.fire({
                                    icon: 'success',
                                    title: 'Created Successfully'
                                })
                                closeModel();
                                setshow(true)
                                setSubmit({ id: '', image: '', name: '', expert: '', phone_no: '', description: '', zoom_link: '', passcode: '', meeting_id: '', type: '', mode: '', direction: '', instagram_id: '', organiser: '', event_type: '', amount: '', isCertificate: '', isVideo: '', created_by: '', start_date_time: '', end_date_time: '', created_date: new Date() })
                            })
                        });
                    }
                );
            }
        }


    }

    const deleteData = (id) => {
        closeModel();
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                db.collection("workshop_event_data").doc(id).delete().then(() => {
                    console.log('Deleted Successfully');
                    setSubmit({ id: '', image: '', name: '', expert: '', phone_no: '', description: '', type: '', mode: '', direction: '', instagram_id: '', start_date_time: '', zoom_link: '', passcode: '', meeting_id: '', end_date_time: '', created_date: new Date() })
                    closeModel();
                    Swal.fire(
                        'Deleted!',
                        'Data has been deleted.',
                        'success'
                    )
                })

            }
        })

    }

    useEffect(() => {
        db.collection("workshop_event_data").orderBy("created_date", "desc").onSnapshot((snapshot) => {

            setData(
                snapshot.docs.map((doc) => ({
                    id: doc.id,
                    data: doc.data(),
                }))
            );
        });


    }, []);

    const locations = [];

    const handleLocationChange = (event) => {
        const location = event.target.value;
        const isChecked = event.target.checked;
        console.log(event.target)

        if (isChecked) {
            // Add the location to the array
            // You can replace 'locations' with your desired array variable
            locations.push(location);
        } else {
            // Remove the location from the array
            const index = locations.indexOf(location);
            if (index !== -1) {
                locations.splice(index, 1);
            }
        }
        console.log(locations)
    };


    return (
        <>
            <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
                {/* <ViewAppData /> */}
                <div class="p-2" style={{ textAlign: "start", paddingBottom: "5px" }}>
                    {/* <span class="btn bg-gradient-secondary btn-sm mb-0 mr-2" style={{ color: "white" }} >&nbsp; Download Excel</span> */}
                    <Link to="/admin_panel"><img src="/images/menubutton.png" class="menu-toggle" alt="Responsive image" style={{ height: "2rem" }} /></Link>

                </div>

                <div class="p-2">
                    <div style={{ textAlign: "end", paddingBottom: "5px" }}>
                        <a class="btn bg-gradient-primary btn-sm mb-0" style={{ color: "white" }} onClick={() => addNew()}>+&nbsp; Add New Workshop</a>
                        {/* <Link to="/admin_panel" class="btn bg-gradient-primary btn-sm mb-0 ml-2" style={{ color: "white" }} >&nbsp; Go Back</Link> */}
                    </div>
                    <div class="card">
                        <div class="">
                            <div class="table-responsive fix-width scroll-inner">
                                <table class="table table-bordered table-hover">
                                    <thead class="thead-dark" style={{ position: "sticky", top: "0" }}>
                                        <tr style={{ textAlign: "left" }}>
                                            <th class="col">Workshop Name</th>
                                            <th class="col ps-2">Expert Name</th>
                                            <th class="text-center col">Type</th>
                                            <th class="text-center col">Start Date</th>
                                            <th class="text-center col">End Date</th>
                                            <th class="text-center col">Action</th>
                                            {/* <th class="text-secondary opacity-7"></th> */}
                                        </tr>
                                    </thead>
                                    <tbody style={{ textAlign: "left" }}>
                                        {data?.map(({ id, data }, index) => (
                                            <>
                                                <tr>
                                                    <td>
                                                        <div class="d-flex px-2 py-1">
                                                            <div style={{ width: "7rem", marginRight: "13px" }}>
                                                                <img src={data.image} class="me-3" style={{ height: "4rem" }} />
                                                            </div>
                                                            <div class="d-flex flex-column justify-content-center">
                                                                <h6 class="mb-0 text-xs">{data.name}</h6>
                                                                <p class="text-xs text-secondary mb-0 btn-tooltip" data-bs-toggle="tooltip" data-bs-placement="top" title={data.description} data-container="body" data-animation="true" style={{ whiteSpace: "nowrap", width: "20rem", overflow: "hidden", textOverflow: "ellipsis" }}><i class="material-icons" style={{ fontSize: "12px", color: "#e73573" }}>info</i>  {data.description}</p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <p class="text-xs font-weight-bold mb-0">{data.expert}</p>
                                                        {/* <p class="text-xs text-secondary mb-0">Organization</p> */}
                                                    </td>
                                                    <td class="align-middle text-center text-sm">
                                                        <span class="badge badge-sm badge-success">{data.type}</span>
                                                    </td>
                                                    <td class="align-middle text-center text-sm">
                                                        <span class="text-secondary text-xs font-weight-normal">{moment(data.start_date_time.toDate().toISOString()).format("MM/DD/YY, h:mm a")}</span>
                                                    </td>
                                                    <td class="align-middle text-center text-sm">
                                                        <span class="text-secondary text-xs font-weight-normal">{moment(data.end_date_time.toDate().toISOString()).format("MM/DD/YY, h:mm a")}</span>
                                                    </td>
                                                    <td class="align-middle text-center" onClick={() => changeStatus(data, id)}>
                                                        <span class="text-info text-xs font-weight-normal" style={{ cursor: "pointer" }}>Edit</span>
                                                    </td>

                                                </tr>
                                            </>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

            </main>




            <div class="col-md-12">
                <div class="myId modal fade" id="" tabindex="-1" role="dialog" aria-labelledby="modal-form" aria-hidden="true" data-backdrop="static">
                    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div class="modal-content">
                            <div class="modal-body">


                                <div class="">
                                    <form onSubmit={handleSubmit}>
                                        <div class="">
                                            <div class="" style={{ textAlign: "center", }}><span style={{ color: "black", fontSize: "25px", fontWeight: "700", textTransform: "uppercase" }}>Create Workshop</span></div>
                                            <div class="" style={{ textAlign: "center", color: "black", paddingTop: "10px" }}>
                                            </div>
                                            <div class="">
                                                <div class="row">
                                                    <div class="col-md-4">
                                                        {edit ? <input type="file" id="image" style={{
                                                            // width: "17rem",
                                                            height: "45px",
                                                            padding: "12px 20px",
                                                            boxSizing: "border-box",
                                                            border: "2px solid #ccc",
                                                            borderRadius: "4px",
                                                            backgroundColor: "#f8f8f8",
                                                            fontSize: "16px",
                                                            resize: "none"
                                                        }} class="form-control" onfocus="focused(this)" onfocusout="defocused(this)" placeholder="Image" /> : <input type="file" id="image" style={{
                                                            // width: "17rem",
                                                            height: "45px",
                                                            padding: "12px 20px",
                                                            boxSizing: "border-box",
                                                            border: "2px solid #ccc",
                                                            borderRadius: "4px",
                                                            backgroundColor: "#f8f8f8",
                                                            fontSize: "16px",
                                                            resize: "none"
                                                        }} class="form-control" onfocus="focused(this)" onfocusout="defocused(this)" placeholder="Image" required />}
                                                    </div>
                                                    <div class="col-md-4">
                                                        <input style={{
                                                            // width: "17rem",
                                                            height: "45px",
                                                            padding: "12px 20px",
                                                            boxSizing: "border-box",
                                                            border: "2px solid #ccc",
                                                            borderRadius: "4px",
                                                            backgroundColor: "#f8f8f8",
                                                            fontSize: "16px",
                                                            resize: "none"
                                                        }} class="w3l_text_box" id="name" placeholder="Workshop Name" name="Full Name" type="text" onChange={handleChange} value={submit.name} required />
                                                    </div>
                                                    <div class="col-md-4">
                                                        <input style={{
                                                            // width: "17rem",
                                                            height: "45px",
                                                            padding: "12px 20px",
                                                            boxSizing: "border-box",
                                                            border: "2px solid #ccc",
                                                            borderRadius: "4px",
                                                            backgroundColor: "#f8f8f8",
                                                            fontSize: "16px",
                                                            resize: "none"
                                                        }} class="w3l_text_box" id="expert" placeholder="Expert Name" name="Expert Name" type="text" onChange={handleChange} value={submit.expert} required />
                                                    </div>

                                                </div>
                                            </div>


                                            <div class="">
                                                <div class="row">
                                                    <div class="col-md-4">
                                                        <li>
                                                            <select class="form-control" style={{
                                                                // width: "17rem",
                                                                height: "45px",
                                                                padding: "5px 16px",
                                                                boxSizing: "border-box",
                                                                border: "2px solid #ccc",
                                                                borderRadius: "4px",
                                                                backgroundColor: "#f8f8f8",
                                                                fontSize: "16px",
                                                                resize: "none"
                                                            }} id="type" onChange={handleChange} value={submit.type} required>
                                                                <option value="">Select Type</option>
                                                                <option value="Paid">Paid</option>
                                                                <option value="Free">Free</option>
                                                            </select>
                                                        </li>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <input style={{
                                                            // width: "17rem",
                                                            height: "45px",
                                                            padding: "12px 20px",
                                                            boxSizing: "border-box",
                                                            border: "2px solid #ccc",
                                                            borderRadius: "4px",
                                                            backgroundColor: "#f8f8f8",
                                                            fontSize: "16px",
                                                            resize: "none"
                                                        }} class="w3l_text_box" id="organiser" placeholder="Organiser" name="Organiser" onChange={handleChange} value={submit.organiser} type="text" required />
                                                    </div>

                                                    <div class="col-md-4">
                                                        <input style={{
                                                            // width: "17rem",
                                                            height: "45px",
                                                            padding: "12px 20px",
                                                            boxSizing: "border-box",
                                                            border: "2px solid #ccc",
                                                            borderRadius: "4px",
                                                            backgroundColor: "#f8f8f8",
                                                            fontSize: "16px",
                                                            resize: "none"
                                                        }} class="w3l_text_box" id="amount" placeholder="Amount" name="amount" type="number" onChange={handleChange} value={submit.amount} required />
                                                    </div>
                                                </div>
                                            </div>



                                            <div class="">
                                                <div class="row">
                                                    <div class="col-md-4">
                                                        <input style={{
                                                            // width: "17rem",
                                                            height: "45px",
                                                            padding: "12px 20px",
                                                            boxSizing: "border-box",
                                                            border: "2px solid #ccc",
                                                            borderRadius: "4px",
                                                            backgroundColor: "#f8f8f8",
                                                            fontSize: "16px",
                                                            resize: "none"
                                                        }} class="w3l_text_box" id="meeting_id" placeholder="Meeting ID" name="Meeting id" type="text" onChange={handleChange} value={submit.meeting_id} required />
                                                    </div>
                                                    <div class="col-md-4">
                                                        <input style={{
                                                            // width: "17rem",
                                                            height: "45px",
                                                            padding: "12px 20px",
                                                            boxSizing: "border-box",
                                                            border: "2px solid #ccc",
                                                            borderRadius: "4px",
                                                            backgroundColor: "#f8f8f8",
                                                            fontSize: "16px",
                                                            resize: "none"
                                                        }} class="w3l_text_box" id="passcode" placeholder="Passcode" name="passcode" type="text" onChange={handleChange} value={submit.passcode} required />
                                                    </div>

                                                    <div class="col-md-4">
                                                        <input style={{
                                                            // width: "17rem",
                                                            height: "45px",
                                                            padding: "12px 20px",
                                                            boxSizing: "border-box",
                                                            border: "2px solid #ccc",
                                                            borderRadius: "4px",
                                                            backgroundColor: "#f8f8f8",
                                                            fontSize: "16px",
                                                            resize: "none"
                                                        }} class="w3l_text_box" id="zoom_link" placeholder="Zoom Link" name="zoomlink" type="text" onChange={handleChange} value={submit.zoom_link} required />
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="">
                                                <div class="row" style={{ paddingBottom: "1.5rem" }}>

                                                    <div class="col-md-4">
                                                        <li>
                                                            <select class="form-control" style={{
                                                                // width: "17rem",
                                                                height: "45px",
                                                                padding: "5px 16px",
                                                                boxSizing: "border-box",
                                                                border: "2px solid #ccc",
                                                                borderRadius: "4px",
                                                                backgroundColor: "#f8f8f8",
                                                                fontSize: "16px",
                                                                resize: "none"
                                                            }} id="event_type" onChange={handleChange} value={submit.event_type} required>
                                                                <option value="">Event Type</option>
                                                                <option value="workshop">workshop</option>
                                                                <option value="event">event</option>
                                                            </select>
                                                        </li>
                                                    </div>

                                                    <div class="col-md-4">
                                                        <li>
                                                            <select class="form-control" style={{
                                                                // width: "17rem",
                                                                height: "45px",
                                                                padding: "5px 16px",
                                                                boxSizing: "border-box",
                                                                border: "2px solid #ccc",
                                                                borderRadius: "4px",
                                                                backgroundColor: "#f8f8f8",
                                                                fontSize: "16px",
                                                                resize: "none"
                                                            }} id="isCertificate" onChange={handleChange} value={submit.isCertificate} required>
                                                                <option value="">Certificate Available</option>
                                                                <option value="yes">yes</option>
                                                                <option value="no">no</option>
                                                            </select>
                                                        </li>
                                                    </div>

                                                    <div class="col-md-4">
                                                        <li>
                                                            <select class="form-control" style={{
                                                                // width: "17rem",
                                                                height: "45px",
                                                                padding: "5px 16px",
                                                                boxSizing: "border-box",
                                                                border: "2px solid #ccc",
                                                                borderRadius: "4px",
                                                                backgroundColor: "#f8f8f8",
                                                                fontSize: "16px",
                                                                resize: "none"
                                                            }} id="isVideo" onChange={handleChange} value={submit.isVideo} required>
                                                                <option value="">Video Available</option>
                                                                <option value="yes">yes</option>
                                                                <option value="no">no</option>
                                                            </select>
                                                        </li>
                                                    </div>


                                                </div>
                                            </div>


                                            <div class="">
                                                <div class="row">

                                                    <div class="col-md-4">
                                                        <input type="datetime-local" style={{
                                                            // width: "17rem",
                                                            height: "45px",
                                                            padding: "12px 20px",
                                                            boxSizing: "border-box",
                                                            border: "2px solid #ccc",
                                                            borderRadius: "4px",
                                                            backgroundColor: "#f8f8f8",
                                                            fontSize: "16px",
                                                            resize: "none"
                                                        }} id="start_date_time" onChange={handleChange} value={submit.start_date_time} class="form-control" required />
                                                    </div>

                                                    <div class="col-md-4">
                                                        <input type="datetime-local" style={{
                                                            // width: "17rem",
                                                            height: "45px",
                                                            padding: "12px 20px",
                                                            boxSizing: "border-box",
                                                            border: "2px solid #ccc",
                                                            borderRadius: "4px",
                                                            backgroundColor: "#f8f8f8",
                                                            fontSize: "16px",
                                                            resize: "none"
                                                        }} id="end_date_time" onChange={handleChange} value={submit.end_date_time} class="form-control" required />
                                                    </div>


                                                    <div class="col-md-4">
                                                        <input style={{
                                                            // width: "17rem",
                                                            height: "45px",
                                                            padding: "12px 20px",
                                                            boxSizing: "border-box",
                                                            border: "2px solid #ccc",
                                                            borderRadius: "4px",
                                                            backgroundColor: "#f8f8f8",
                                                            fontSize: "16px",
                                                            resize: "none"
                                                        }} class="w3l_text_box" id="phone_no" placeholder="Phone Number" name="Phone Number" type="number" onfocus="focused(this)" onfocusout="defocused(this)" onChange={handleChange} value={submit.phone_no} required />
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="">
                                                <div class="row">
                                                    <div class="col-md-4">
                                                        <li>
                                                            <select class="form-control" style={{
                                                                // width: "17rem",
                                                                height: "45px",
                                                                padding: "5px 16px",
                                                                boxSizing: "border-box",
                                                                border: "2px solid #ccc",
                                                                borderRadius: "4px",
                                                                backgroundColor: "#f8f8f8",
                                                                fontSize: "16px",
                                                                resize: "none"
                                                            }} id="mode" onChange={handleChange} value={submit.mode} required>
                                                                <option value="">Select Mode</option>
                                                                <option value="online">Online</option>
                                                                <option value="offline">Offline</option>
                                                            </select>
                                                        </li>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <input style={{
                                                            // width: "17rem",
                                                            height: "45px",
                                                            padding: "12px 20px",
                                                            boxSizing: "border-box",
                                                            border: "2px solid #ccc",
                                                            borderRadius: "4px",
                                                            backgroundColor: "#f8f8f8",
                                                            fontSize: "16px",
                                                            resize: "none"
                                                        }} class="w3l_text_box" id="direction" placeholder="Direction" name="Direction" onChange={handleChange} value={submit.direction} type="text" />
                                                    </div>

                                                    <div class="col-md-4">
                                                        <input style={{
                                                            // width: "17rem",
                                                            height: "45px",
                                                            padding: "12px 20px",
                                                            boxSizing: "border-box",
                                                            border: "2px solid #ccc",
                                                            borderRadius: "4px",
                                                            backgroundColor: "#f8f8f8",
                                                            fontSize: "16px",
                                                            resize: "none"
                                                        }} class="w3l_text_box" id="instagram_id" placeholder="instagram_id" name="Instagram Id" onChange={handleChange} value={submit.instagram_id} type="text" />
                                                    </div>

                                                    <div class="col-md-4">

                                                    </div>
                                                </div>
                                            </div>






                                            {/* 
                                            <div class="">
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <li>
                                                            <label style={{ display: "flex" }}>Service Name</label>
                                                            <input style={{
                                                                // width: "17rem",
                                                                height: "45px",
                                                                padding: "12px 20px",
                                                                boxSizing: "border-box",
                                                                border: "2px solid #ccc",
                                                                borderRadius: "4px",
                                                                backgroundColor: "#f8f8f8",
                                                                fontSize: "16px",
                                                                resize: "none"
                                                            }} class="w3l_text_box" placeholder="Service Name" name="Service Name" type="text" required />
                                                        </li>

                                                    </div>

                                                    <div class="col-md-6">
                                                        <li>
                                                            <label style={{ display: "flex" }}>Start Datetime</label>
                                                            <input type="datetime-local" id="start_date_time" min={currentDate} class="form-control" required />
                                                        </li>
                                                    </div>


                                                </div>
                                            </div> */}


                                            <textarea style={{
                                                width: "100%",
                                                height: "15px",
                                                padding: "12px 20px",
                                                boxSizing: "border-box",
                                                border: "2px solid #ccc",
                                                borderRadius: "4px",
                                                backgroundColor: "#f8f8f8",
                                                fontSize: "16px",
                                                resize: "none"
                                            }} class="w3l_text_box" id="description" placeholder="Write a description..." name="Description" onChange={handleChange} value={submit.description} required />

                                            <Multiselect
                                                options={options}
                                                selectedValues={selectedValue}
                                                onSelect={onSelect}
                                                onRemove={onRemove}
                                                displayValue="name"
                                                required
                                            />

                                            {/* <div class="">
                                                    <div class="col-md-6">
                                                        <div class="form-check">
                                                            <input
                                                                type="radio"
                                                                class="form-check-input"
                                                                id="in_parlour"
                                                                name="topping"
                                                                value="no"
                                                                checked={topping === "no"}
                                                                style={{ display: "block", position: 'absolute' }}
                                                                onChange={onOptionChange}
                                                            />

                                                            <label class="form-check-label" for="checkbox1">
                                                                In Parlour
                                                            </label>
                                                        </div>
                                                    </div>
                                                   
                                                </div> */}




                                            <div class="modal-footer">
                                                {submit.id == "" ? <></> : <a class="btn bg-gradient-danger btn-sm mb-0" style={{ color: "white" }} onClick={() => deleteData(submit.id)}>Delete</a>}
                                                <a class="btn bg-gradient-info btn-sm mb-0" style={{ color: "white" }} onClick={closeModel}>Close</a>
                                                <button type='submit' class="btn bg-gradient-danger btn-sm mb-0" style={{ backgroundColor: "green", color: "white" }}>{edit ? "Update" : "Submit"}</button>
                                            </div>


                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >

        </>
    )
}

export default WorkshopData;







// <div class="col-md-4">
// <div class="myId modal fade" id="" tabindex="-1" role="dialog" aria-labelledby="modal-form" aria-hidden="true">
//     <div class="modal-dialog modal-dialog-centered" role="document">
//         <div class="modal-content">
//             <div class="modal-body p-0">
//                 <div class="card card-plain">
//                     <form onSubmit={handleSubmit}>
//                         <div class="card-body">
//                             <div class="modal-body">
//                                 <div class="row">
//                                     <div class="col-md-12">
//                                         <span>Workshop/Event Image:</span>
//                                         {submit.image == "" ? <></> : <img src={submit.image} class="avatar avatar-sm me-3" />}
//                                         <div class="">
//                                             {edit ? <input type="file" id="image" class="" onfocus="focused(this)" onfocusout="defocused(this)" placeholder="Image" /> : <input type="file" id="image" class="" onfocus="focused(this)" onfocusout="defocused(this)" placeholder="Image" required />}
//                                         </div>
//                                     </div>
//                                 </div>
//                                 <div class="">
//                                     <div class="col-md-6">
//                                         <li>
//                                             <label style={{ display: "flex" }}>Name</label>
//                                             <input type="text" id="name" class="form-control" onfocus="focused(this)" onfocusout="defocused(this)" placeholder="Name" onChange={handleChange} value={submit.name} required />
//                                         </li>
//                                     </div>
//                                     <div class="col-md-6">
//                                         <li>
//                                             <label style={{ display: "flex" }}>Expert</label>
//                                             <input type="text" id="expert" class="form-control" onfocus="focused(this)" onfocusout="defocused(this)" onChange={handleChange} value={submit.expert} placeholder="Expert" required />
//                                         </li>
//                                     </div>
//                                 </div>

//                                 <div class="">
//                                     <div class="col-md-6">
//                                         <li>
//                                             <label style={{ display: "flex" }}>Select Type</label>
//                                             <select class="form-control" id="type" onChange={handleChange} value={submit.type} required>
//                                                 <option value="">Select Type</option>
//                                                 <option value="Paid">Paid</option>
//                                                 <option value="Free">Free</option>
//                                             </select>
//                                         </li>
//                                     </div>


//                                     <div class="col-md-6">
//                                         <li>
//                                             <label style={{ display: "flex" }}>Phone No</label>
//                                             <input type="number" id="phone_no" class="form-control" onfocus="focused(this)" onfocusout="defocused(this)" onChange={handleChange} value={submit.phone_no} placeholder="Phone Number" required />
//                                         </li>
//                                     </div>


//                                 </div>

//                                 {/* <div class="row">
//                                     <div class="col-md-6">
//                                         <div class="input-group-outline">
//                                             <select class="form-control" id="type" onChange={handleChange} value={submit.type} required>
//                                                 <option value="">Select Type</option>
//                                                 <option value="Paid">Paid</option>
//                                                 <option value="Free">Free</option>
//                                             </select>
//                                         </div>
//                                     </div>
//                                     <div class="col-md-6">
//                                         <div class="input-group input-group-outline">
//                                             <input type="number" id="phone_no" class="form-control" onfocus="focused(this)" onfocusout="defocused(this)" onChange={handleChange} value={submit.phone_no} placeholder="Phone Number" required />
//                                         </div>
//                                     </div>
//                                 </div> */}
//                                 <div class="">
//                                     <div class="col-md-6">
//                                         <li>
//                                             <label style={{ display: "flex" }}>Start Datetime</label>
//                                             <input type="datetime-local" id="start_date_time" onChange={handleChange} value={submit.start_date_time} class="form-control" required />
//                                         </li>
//                                     </div>


//                                     <div class="col-md-6">
//                                         <li>
//                                             <label style={{ display: "flex" }}>End Datetime</label>
//                                             <input type="datetime-local" id="end_date_time" onChange={handleChange} value={submit.end_date_time} class="form-control" required />
//                                         </li>
//                                     </div>


//                                 </div>
//                                 {/* <div class="row">
//                                     <div class="col-md-6">
//                                         <div class="input-group-static">
//                                             <label>Start Datetime</label>
//                                             <input type="datetime-local" id="start_date_time" onChange={handleChange} value={submit.start_date_time} class="form-control" required />
//                                         </div>
//                                     </div>
//                                     <div class="col-md-6">
//                                         <div class="input-group-static">
//                                             <label>End Datetime</label>
//                                             <input type="datetime-local" id="end_date_time" onChange={handleChange} value={submit.end_date_time} class="form-control" required />
//                                         </div>
//                                     </div>
//                                 </div> */}

//                                 <div class="">
//                                     <div class="col-md-6">
//                                         <li>
//                                             <label style={{ display: "flex" }}>Meeting ID</label>
//                                             <input type="text" id="meeting_id" class="form-control" onfocus="focused(this)" onfocusout="defocused(this)" placeholder="Meeting ID" onChange={handleChange} value={submit.meeting_id} required />
//                                         </li>
//                                     </div>
//                                     <div class="col-md-6">
//                                         <li>
//                                             <label style={{ display: "flex" }}>Passcode</label>
//                                             <input type="text" id="passcode" class="form-control" onfocus="focused(this)" onfocusout="defocused(this)" onChange={handleChange} value={submit.passcode} placeholder="Passcode" required />
//                                         </li>
//                                     </div>
//                                 </div>

//                                 <div class="">
//                                     <div class="col-md-12">
//                                         <li>
//                                             <label style={{ display: "flex" }}>Zoom Link</label>
//                                             <input type="text" id="zoom_link" class="form-control" onfocus="focused(this)" onfocusout="defocused(this)" placeholder="Zoom Link" onChange={handleChange} value={submit.zoom_link} required />
//                                         </li>
//                                     </div>
//                                 </div>

//                                 <div class="input-group input-group-dynamic">
//                                     <textarea class="form-control" rows="5" id='description' placeholder="Description" onChange={handleChange} value={submit.description} spellcheck="false" required></textarea>
//                                 </div>

//                                 <div class="col" style={{ textAlign: "start" }}>
//                                     <label style={{ fontSize: "14px", fontWeight: "700" }}>Location:</label>
//                                 </div>

//                                 <Multiselect
//                                     options={options}
//                                     selectedValues={selectedValue}
//                                     onSelect={onSelect}
//                                     onRemove={onRemove}
//                                     displayValue="name"
//                                     required
//                                 />

//                                 {/* <div class="">

//                                     <div class="col-md-12">
//                                         <div class="input-group input-group-outline">

//                                             <div class="checkbox-group">
//                                                 <label class="checkbox-label">
//                                                     <input type="checkbox" id="location1" onChange={handleLocationChange} value="Aurangabad" />
//                                                     <span class="checkbox-custom"></span>
//                                                     <span class="checkbox-text">Aurangabad</span>
//                                                 </label>
//                                             </div>
//                                             <div class="checkbox-group">
//                                                 <label class="checkbox-label">
//                                                     <input type="checkbox" id="location2" onChange={handleLocationChange} value="Bathinda" />
//                                                     <span class="checkbox-custom"></span>
//                                                     <span class="checkbox-text">Bathinda</span>
//                                                 </label>
//                                             </div>
//                                             <div class="checkbox-group">
//                                                 <label class="checkbox-label">
//                                                     <input type="checkbox" id="location3" onChange={handleLocationChange} value="Ghaziabad" />
//                                                     <span class="checkbox-custom"></span>
//                                                     <span class="checkbox-text">Ghaziabad</span>
//                                                 </label>
//                                             </div>
//                                             <div class="checkbox-group">
//                                                 <label class="checkbox-label">
//                                                     <input type="checkbox" id="location3" onChange={handleLocationChange} value="Meerut" />
//                                                     <span class="checkbox-custom"></span>
//                                                     <span class="checkbox-text">Meerut</span>
//                                                 </label>
//                                             </div>
//                                             <div class="checkbox-group">
//                                                 <label class="checkbox-label">
//                                                     <input type="checkbox" id="location4" onChange={handleLocationChange} value="Mansa" />
//                                                     <span class="checkbox-custom"></span>
//                                                     <span class="checkbox-text">Mansa</span>
//                                                 </label>
//                                             </div>
//                                             <div class="checkbox-group">
//                                                 <label class="checkbox-label">
//                                                     <input type="checkbox" id="location5" onChange={handleLocationChange} value="Patiala" />
//                                                     <span class="checkbox-custom"></span>
//                                                     <span class="checkbox-text">Patiala</span>
//                                                 </label>
//                                             </div>
//                                             <div class="checkbox-group">
//                                                 <label class="checkbox-label">
//                                                     <input type="checkbox" id="location5" onChange={handleLocationChange} value="Online" />
//                                                     <span class="checkbox-custom"></span>
//                                                     <span class="checkbox-text">Online</span>
//                                                 </label>
//                                             </div>
//                                         </div>
//                                     </div>

//                                 </div> */}
//                             </div>
//                             <div class="modal-footer">
//                                 {submit.id == "" ? <></> : <a class="btn bg-gradient-danger btn-sm mb-0" style={{ color: "white" }} onClick={() => deleteData(submit.id)}>Delete</a>}
//                                 <a class="btn bg-gradient-info btn-sm mb-0" style={{ color: "white" }} onClick={closeModel}>Close</a>
//                                 <button type='submit' class="btn bg-gradient-danger btn-sm mb-0" style={{ backgroundColor: "green", color: "white" }}>{edit ? "Update" : "Submit"}</button>
//                             </div>
//                         </div>
//                     </form>
//                 </div>
//             </div>
//         </div>
//     </div>
// </div>
// </div>