import React, { useState, useEffect } from "react";
import { useNavigate, Link, useParams } from 'react-router-dom';
import Swal from 'sweetalert2'
import db from "./Firebase";
import { storage } from './Firebase';
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";

function Shop() {
    const initialFormStateFeedback = { type: '', product_name: '', brand_name: '', quantity: '', itemcount: '', price: '', seller: '', authorization_code: '', image_url: '', updated_date: new Date() }
    const [submit, setSubmit] = useState(initialFormStateFeedback)
    const [data, setData] = useState([]);
    const [ShowProgress, setShowProgress] = useState(false);
    const [show, setshow] = useState(true);
    const [progresspercent, setProgresspercent] = useState(0);

    const [editID, setEditID] = useState("");
    const [basketData, setBasketData] = useState([]);
    const [productID, setProductID] = useState([]);
    const [basketCount, setBasketcount] = useState(0);
    const [brand, setBrand] = useState([]);
    const [type, setType] = useState([]);
    const [typeValue, setTypeValue] = useState([]);


    const [value, setValue] = useState()
    const [input, setInput] = useState("");

    const handleChange = (e) => {
        e.preventDefault();
        setInput(e.target.value);
    };

    useEffect(() => {
        db.collection("consumables_data").onSnapshot((snapshot) => {
            setData(
                snapshot.docs.map((doc) => ({
                    id: doc.id,
                    data: doc.data(),

                }))
            );

            snapshot.docs.map(e => {
                if (!brand.includes(e.data()['brand_name'])) {
                    brand.push(e.data()['brand_name'])
                }
            })

            snapshot.docs.map(e => {
                if (!type.includes(e.data()['type'])) {
                    type.push(e.data()['type'])
                }
            })
            // snapshot.docs.map(e => brand.push(e.data['brand_name']))
        });

        if (JSON.parse(localStorage.getItem("productID")) !== null) {
            setBasketData(JSON.parse(localStorage.getItem("basket")));
        } else {
            setBasketData([])
        }

        if (JSON.parse(localStorage.getItem("productID")) !== null) {
            setProductID(JSON.parse(localStorage.getItem("productID")) || "[]");
        } else {
            setProductID([])
        }
        // setBasketcount(JSON.parse(localStorage.getItem("productID")).length)
        if (JSON.parse(localStorage.getItem("productID")) !== null) {
            setBasketcount(JSON.parse(localStorage.getItem("productID")).length)
        } else {
            setBasketcount(0)
        }


    }, []);

    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const addToBasket = (data, id) => {
        if (productID.includes(id)) {
            console.log("already added")
        } else {
            data["id"] = id;
            data["itemcount"] = 1;
            basketData.push(data)
            productID.push(id)
            localStorage.setItem("basket", JSON.stringify(basketData));
            localStorage.setItem("productID", JSON.stringify(productID));
            console.log(data)
        }

        // if (basketData.indexOf(data) === -1) {
        //     basketData.push(data);
        //     console.log(data);
        //     localStorage.setItem("basket", JSON.stringify(basketData));
        // }
        // 
        setBasketcount(productID.length)
        console.log(basketData)
        // forceUpdate()


    }
    const removeFromBasket = (data, id) => {
        data["id"] = id;
        const itemToBeRemoved = data
        basketData.splice(basketData.findIndex(a => a.id === itemToBeRemoved.id), 1)
        const newIds = productID.filter(e => e !== id)
        setProductID(newIds)
        setBasketcount(newIds.length)
        localStorage.setItem("basket", JSON.stringify(basketData));
        localStorage.setItem("productID", JSON.stringify(newIds));
        console.log(basketData)
        forceUpdate()

    }

    const handleChangeType = (e) => {
        console.log(e.target.value)
        if (e.target.value != "null") {
            setTypeValue(e.target.value)
            db.collection("consumables_data").where('type', '==', e.target.value).onSnapshot((snapshot) => {
                setData(
                    snapshot.docs.map((doc) => ({
                        id: doc.id,
                        data: doc.data(),
                    }))
                );
            });
        }
    }

    const handleChangeBrand = (e) => {
        // console.log(e.target.value)
        if (e.target.value != "null") {
            setValue(e.target.value)
            db.collection("consumables_data").where('brand_name', '==', e.target.value).onSnapshot((snapshot) => {
                setData(
                    snapshot.docs.map((doc) => ({
                        id: doc.id,
                        data: doc.data(),
                    }))
                );
            });
        }
    }

    const resetAll = () => {
        setInput("")
        setValue("")
        setTypeValue("")
        db.collection("consumables_data").onSnapshot((snapshot) => {
            setData(
                snapshot.docs.map((doc) => ({
                    id: doc.id,
                    data: doc.data(),

                }))
            );

            snapshot.docs.map(e => {
                if (!brand.includes(e.data()['brand_name'])) {
                    brand.push(e.data()['brand_name'])
                }
            })

            snapshot.docs.map(e => {
                if (!type.includes(e.data()['type'])) {
                    type.push(e.data()['type'])
                }
            })
            // snapshot.docs.map(e => brand.push(e.data['brand_name']))
        });
    }

    return (
        <>
            <div class="">
                <section class="slider_section ">
                    <div class="slider_bg_box">
                        <img src="/shop/slider-bg.jpg" alt="" ></img>
                    </div>
                    <div id="customCarousel1" class="carousel slide" data-ride="carousel">
                        <div class="carousel-inner">
                            <div class="carousel-item active">
                                <div class="container ">
                                    <div class="row">
                                        <div class="col-md-7 col-lg-6 ">
                                            <div class="detail-box">
                                                <h1>
                                                    <span>
                                                        Sale 20% Off
                                                    </span>
                                                    <br />
                                                    On Everything
                                                </h1>
                                                <p>
                                                    Explicabo esse amet tempora quibusdam laudantium, laborum eaque magnam fugiat hic? Esse
                                                    dicta aliquid error repudiandae earum suscipit fugiat molestias, veniam, vel architecto
                                                    veritatis delectus repellat modi impedit sequi.
                                                </p>
                                                <div class="btn-box">
                                                    <a href="" class="btn1">
                                                        Shop Now
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="carousel-item ">
                                <div class="container ">
                                    <div class="row">
                                        <div class="col-md-7 col-lg-6 ">
                                            <div class="detail-box">
                                                <h1>
                                                    <span>
                                                        Sale 20% Off
                                                    </span>
                                                    <br />
                                                    On Everything
                                                </h1>
                                                <p>
                                                    Explicabo esse amet tempora quibusdam laudantium, laborum eaque magnam fugiat hic? Esse
                                                    dicta aliquid error repudiandae earum suscipit fugiat molestias, veniam, vel architecto
                                                    veritatis delectus repellat modi impedit sequi.
                                                </p>
                                                <div class="btn-box">
                                                    <a href="" class="btn1">
                                                        Shop Now
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="carousel-item">
                                <div class="container ">
                                    <div class="row">
                                        <div class="col-md-7 col-lg-6 ">
                                            <div class="detail-box">
                                                <h1>
                                                    <span>
                                                        Sale 20% Off
                                                    </span>
                                                    <br />
                                                    On Everything
                                                </h1>
                                                <p>
                                                    Explicabo esse amet tempora quibusdam laudantium, laborum eaque magnam fugiat hic? Esse
                                                    dicta aliquid error repudiandae earum suscipit fugiat molestias, veniam, vel architecto
                                                    veritatis delectus repellat modi impedit sequi.
                                                </p>
                                                <div class="btn-box">
                                                    <a href="" class="btn1">
                                                        Shop Now
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="container">
                            <ol class="carousel-indicators">
                                <li data-target="#customCarousel1" data-slide-to="0" class="active"></li>
                                <li data-target="#customCarousel1" data-slide-to="1"></li>
                                <li data-target="#customCarousel1" data-slide-to="2"></li>
                            </ol>
                        </div>
                    </div>
                </section>
            </div>

            <section class="why_section layout_padding">
                <div class="container">
                    <div class="heading_container heading_center">
                        <h2>
                            Why Shop With Us
                        </h2>
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <div class="box ">
                                <div class="img-box" style={{ textAlign: "-webkit-center" }}>
                                    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512"
                                        style={{ enableBackground: "new 0 0 512 512", }} xmlSpace="preserve">
                                        <g>
                                            <g>
                                                <path d="M476.158,231.363l-13.259-53.035c3.625-0.77,6.345-3.986,6.345-7.839v-8.551c0-18.566-15.105-33.67-33.67-33.67h-60.392
                                    V110.63c0-9.136-7.432-16.568-16.568-16.568H50.772c-9.136,0-16.568,7.432-16.568,16.568V256c0,4.427,3.589,8.017,8.017,8.017
                                    c4.427,0,8.017-3.589,8.017-8.017V110.63c0-0.295,0.239-0.534,0.534-0.534h307.841c0.295,0,0.534,0.239,0.534,0.534v145.372
                                    c0,4.427,3.589,8.017,8.017,8.017c4.427,0,8.017-3.589,8.017-8.017v-9.088h94.569c0.008,0,0.014,0.002,0.021,0.002
                                    c0.008,0,0.015-0.001,0.022-0.001c11.637,0.008,21.518,7.646,24.912,18.171h-24.928c-4.427,0-8.017,3.589-8.017,8.017v17.102
                                    c0,13.851,11.268,25.119,25.119,25.119h9.086v35.273h-20.962c-6.886-19.883-25.787-34.205-47.982-34.205
                                    s-41.097,14.322-47.982,34.205h-3.86v-60.393c0-4.427-3.589-8.017-8.017-8.017c-4.427,0-8.017,3.589-8.017,8.017v60.391H192.817
                                    c-6.886-19.883-25.787-34.205-47.982-34.205s-41.097,14.322-47.982,34.205H50.772c-0.295,0-0.534-0.239-0.534-0.534v-17.637
                                    h34.739c4.427,0,8.017-3.589,8.017-8.017s-3.589-8.017-8.017-8.017H8.017c-4.427,0-8.017,3.589-8.017,8.017
                                    s3.589,8.017,8.017,8.017h26.188v17.637c0,9.136,7.432,16.568,16.568,16.568h43.304c-0.002,0.178-0.014,0.355-0.014,0.534
                                    c0,27.996,22.777,50.772,50.772,50.772s50.772-22.776,50.772-50.772c0-0.18-0.012-0.356-0.014-0.534h180.67
                                    c-0.002,0.178-0.014,0.355-0.014,0.534c0,27.996,22.777,50.772,50.772,50.772c27.995,0,50.772-22.776,50.772-50.772
                                    c0-0.18-0.012-0.356-0.014-0.534h26.203c4.427,0,8.017-3.589,8.017-8.017v-85.511C512,251.989,496.423,234.448,476.158,231.363z
                                    M375.182,144.301h60.392c9.725,0,17.637,7.912,17.637,17.637v0.534h-78.029V144.301z M375.182,230.881v-52.376h71.235
                                    l13.094,52.376H375.182z M144.835,401.904c-19.155,0-34.739-15.583-34.739-34.739s15.584-34.739,34.739-34.739
                                    c19.155,0,34.739,15.583,34.739,34.739S163.99,401.904,144.835,401.904z M427.023,401.904c-19.155,0-34.739-15.583-34.739-34.739
                                    s15.584-34.739,34.739-34.739c19.155,0,34.739,15.583,34.739,34.739S446.178,401.904,427.023,401.904z M495.967,299.29h-9.086
                                    c-5.01,0-9.086-4.076-9.086-9.086v-9.086h18.171V299.29z" />
                                            </g>
                                        </g>
                                        <g>
                                            <g>
                                                <path
                                                    d="M144.835,350.597c-9.136,0-16.568,7.432-16.568,16.568c0,9.136,7.432,16.568,16.568,16.568
                                    c9.136,0,16.568-7.432,16.568-16.568C161.403,358.029,153.971,350.597,144.835,350.597z" />
                                            </g>
                                        </g>
                                        <g>
                                            <g>
                                                <path
                                                    d="M427.023,350.597c-9.136,0-16.568,7.432-16.568,16.568c0,9.136,7.432,16.568,16.568,16.568
                                    c9.136,0,16.568-7.432,16.568-16.568C443.591,358.029,436.159,350.597,427.023,350.597z" />
                                            </g>
                                        </g>
                                        <g>
                                            <g>
                                                <path d="M332.96,316.393H213.244c-4.427,0-8.017,3.589-8.017,8.017s3.589,8.017,8.017,8.017H332.96
                                    c4.427,0,8.017-3.589,8.017-8.017S337.388,316.393,332.96,316.393z" />
                                            </g>
                                        </g>
                                        <g>
                                            <g>
                                                <path d="M127.733,282.188H25.119c-4.427,0-8.017,3.589-8.017,8.017s3.589,8.017,8.017,8.017h102.614
                                    c4.427,0,8.017-3.589,8.017-8.017S132.16,282.188,127.733,282.188z" />
                                            </g>
                                        </g>
                                        <g>
                                            <g>
                                                <path d="M278.771,173.37c-3.13-3.13-8.207-3.13-11.337,0.001l-71.292,71.291l-37.087-37.087c-3.131-3.131-8.207-3.131-11.337,0
                                    c-3.131,3.131-3.131,8.206,0,11.337l42.756,42.756c1.565,1.566,3.617,2.348,5.668,2.348s4.104-0.782,5.668-2.348l76.96-76.96
                                    C281.901,181.576,281.901,176.501,278.771,173.37z" />
                                            </g>
                                        </g>
                                        <g>
                                        </g>
                                        <g>
                                        </g>
                                        <g>
                                        </g>
                                        <g>
                                        </g>
                                        <g>
                                        </g>
                                        <g>
                                        </g>
                                        <g>
                                        </g>
                                        <g>
                                        </g>
                                        <g>
                                        </g>
                                        <g>
                                        </g>
                                        <g>
                                        </g>
                                        <g>
                                        </g>
                                        <g>
                                        </g>
                                        <g>
                                        </g>
                                        <g>
                                        </g>
                                    </svg>
                                </div>
                                <div class="detail-box">
                                    <h5>
                                        Fast Delivery
                                    </h5>
                                    <p>
                                        variations of passages of Lorem Ipsum available
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="box ">
                                <div class="img-box" style={{ textAlign: "-webkit-center" }}>
                                    <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 490.667 490.667"
                                        style={{ enableBackground: "new 0 0 490.667 490.667", }} xmlSpace="preserve">
                                        <g>
                                            <g>
                                                <path d="M138.667,192H96c-5.888,0-10.667,4.779-10.667,10.667V288c0,5.888,4.779,10.667,10.667,10.667s10.667-4.779,10.667-10.667
                                    v-74.667h32c5.888,0,10.667-4.779,10.667-10.667S144.555,192,138.667,192z" />
                                            </g>
                                        </g>
                                        <g>
                                            <g>
                                                <path d="M117.333,234.667H96c-5.888,0-10.667,4.779-10.667,10.667S90.112,256,96,256h21.333c5.888,0,10.667-4.779,10.667-10.667
                                    S123.221,234.667,117.333,234.667z" />
                                            </g>
                                        </g>
                                        <g>
                                            <g>
                                                <path d="M245.333,0C110.059,0,0,110.059,0,245.333s110.059,245.333,245.333,245.333s245.333-110.059,245.333-245.333
                                    S380.608,0,245.333,0z M245.333,469.333c-123.52,0-224-100.48-224-224s100.48-224,224-224s224,100.48,224,224
                                    S368.853,469.333,245.333,469.333z" />
                                            </g>
                                        </g>
                                        <g>
                                            <g>
                                                <path d="M386.752,131.989C352.085,88.789,300.544,64,245.333,64s-106.752,24.789-141.419,67.989
                                    c-3.691,4.587-2.965,11.307,1.643,14.997c4.587,3.691,11.307,2.965,14.976-1.643c30.613-38.144,76.096-60.011,124.8-60.011
                                    s94.187,21.867,124.779,60.011c2.112,2.624,5.205,3.989,8.32,3.989c2.368,0,4.715-0.768,6.677-2.347
                                    C389.717,143.296,390.443,136.576,386.752,131.989z" />
                                            </g>
                                        </g>
                                        <g>
                                            <g>
                                                <path
                                                    d="M376.405,354.923c-4.224-4.032-11.008-3.861-15.061,0.405c-30.613,32.235-71.808,50.005-116.011,50.005
                                    s-85.397-17.771-115.989-50.005c-4.032-4.309-10.816-4.437-15.061-0.405c-4.309,4.053-4.459,10.816-0.405,15.083
                                    c34.667,36.544,81.344,56.661,131.456,56.661s96.789-20.117,131.477-56.661C380.864,365.739,380.693,358.976,376.405,354.923z" />
                                            </g>
                                        </g>
                                        <g>
                                            <g>
                                                <path d="M206.805,255.723c15.701-2.027,27.861-15.488,27.861-31.723c0-17.643-14.357-32-32-32h-21.333
                                    c-5.888,0-10.667,4.779-10.667,10.667v42.581c0,0.043,0,0.107,0,0.149V288c0,5.888,4.779,10.667,10.667,10.667
                                    S192,293.888,192,288v-16.917l24.448,24.469c2.091,2.069,4.821,3.115,7.552,3.115c2.731,0,5.461-1.045,7.531-3.136
                                    c4.16-4.16,4.16-10.923,0-15.083L206.805,255.723z M192,234.667v-21.333h10.667c5.867,0,10.667,4.779,10.667,10.667
                                    s-4.8,10.667-10.667,10.667H192z" />
                                            </g>
                                        </g>
                                        <g>
                                            <g>
                                                <path d="M309.333,277.333h-32v-64h32c5.888,0,10.667-4.779,10.667-10.667S315.221,192,309.333,192h-42.667
                                    c-5.888,0-10.667,4.779-10.667,10.667V288c0,5.888,4.779,10.667,10.667,10.667h42.667c5.888,0,10.667-4.779,10.667-10.667
                                    S315.221,277.333,309.333,277.333z" />
                                            </g>
                                        </g>
                                        <g>
                                            <g>
                                                <path d="M288,234.667h-21.333c-5.888,0-10.667,4.779-10.667,10.667S260.779,256,266.667,256H288
                                    c5.888,0,10.667-4.779,10.667-10.667S293.888,234.667,288,234.667z" />
                                            </g>
                                        </g>
                                        <g>
                                            <g>
                                                <path d="M394.667,277.333h-32v-64h32c5.888,0,10.667-4.779,10.667-10.667S400.555,192,394.667,192H352
                                    c-5.888,0-10.667,4.779-10.667,10.667V288c0,5.888,4.779,10.667,10.667,10.667h42.667c5.888,0,10.667-4.779,10.667-10.667
                                    S400.555,277.333,394.667,277.333z" />
                                            </g>
                                        </g>
                                        <g>
                                            <g>
                                                <path d="M373.333,234.667H352c-5.888,0-10.667,4.779-10.667,10.667S346.112,256,352,256h21.333
                                    c5.888,0,10.667-4.779,10.667-10.667S379.221,234.667,373.333,234.667z" />
                                            </g>
                                        </g>
                                        <g>
                                        </g>
                                        <g>
                                        </g>
                                        <g>
                                        </g>
                                        <g>
                                        </g>
                                        <g>
                                        </g>
                                        <g>
                                        </g>
                                        <g>
                                        </g>
                                        <g>
                                        </g>
                                        <g>
                                        </g>
                                        <g>
                                        </g>
                                        <g>
                                        </g>
                                        <g>
                                        </g>
                                        <g>
                                        </g>
                                        <g>
                                        </g>
                                        <g>
                                        </g>
                                    </svg>
                                </div>
                                <div class="detail-box">
                                    <h5>
                                        Free Shiping
                                    </h5>
                                    <p>
                                        variations of passages of Lorem Ipsum available
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="box ">
                                <div class="img-box" style={{ textAlign: "-webkit-center" }}>
                                    <svg id="_30_Premium" height="512" viewBox="0 0 512 512" width="512"
                                        xmlns="http://www.w3.org/2000/svg" data-name="30_Premium">
                                        <g id="filled">
                                            <path
                                                d="m252.92 300h3.08a124.245 124.245 0 1 0 -4.49-.09c.075.009.15.023.226.03.394.039.789.06 1.184.06zm-96.92-124a100 100 0 1 1 100 100 100.113 100.113 0 0 1 -100-100z" />
                                            <path
                                                d="m447.445 387.635-80.4-80.4a171.682 171.682 0 0 0 60.955-131.235c0-94.841-77.159-172-172-172s-172 77.159-172 172c0 73.747 46.657 136.794 112 161.2v158.8c-.3 9.289 11.094 15.384 18.656 9.984l41.344-27.562 41.344 27.562c7.574 5.4 18.949-.7 18.656-9.984v-70.109l46.6 46.594c6.395 6.789 18.712 3.025 20.253-6.132l9.74-48.724 48.725-9.742c9.163-1.531 12.904-13.893 6.127-20.252zm-339.445-211.635c0-81.607 66.393-148 148-148s148 66.393 148 148-66.393 148-148 148-148-66.393-148-148zm154.656 278.016a12 12 0 0 0 -13.312 0l-29.344 19.562v-129.378a172.338 172.338 0 0 0 72 0v129.38zm117.381-58.353a12 12 0 0 0 -9.415 9.415l-6.913 34.58-47.709-47.709v-54.749a171.469 171.469 0 0 0 31.467-15.6l67.151 67.152z" />
                                            <path
                                                d="m287.62 236.985c8.349 4.694 19.251-3.212 17.367-12.618l-5.841-35.145 25.384-25c7.049-6.5 2.89-19.3-6.634-20.415l-35.23-5.306-15.933-31.867c-4.009-8.713-17.457-8.711-21.466 0l-15.933 31.866-35.23 5.306c-9.526 1.119-13.681 13.911-6.634 20.415l25.384 25-5.841 35.145c-1.879 9.406 9 17.31 17.367 12.618l31.62-16.414zm-53-32.359 2.928-17.615a12 12 0 0 0 -3.417-10.516l-12.721-12.531 17.658-2.66a12 12 0 0 0 8.947-6.5l7.985-15.971 7.985 15.972a12 12 0 0 0 8.947 6.5l17.658 2.66-12.723 12.535a12 12 0 0 0 -3.417 10.516l2.928 17.615-15.849-8.231a12 12 0 0 0 -11.058 0z" />
                                        </g>
                                    </svg>
                                </div>
                                <div class="detail-box">
                                    <h5>
                                        Best Quality
                                    </h5>
                                    <p>
                                        variations of passages of Lorem Ipsum available
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <section class="arrival_section">
                <div class="container">
                    <div class="box">
                        <div class="arrival_bg_box">
                            <img src="/shop/arrival-bg.png" alt=""></img>
                        </div>
                        <div class="row">
                            <div class="col-md-6 ml-auto">
                                <div class="heading_container remove_line_bt">
                                    <h2>
                                        #NewArrivals
                                    </h2>
                                </div>
                                <p style={{ marginTop: "20px", marginBottom: "30px" }}>
                                    Vitae fugiat laboriosam officia perferendis provident aliquid voluptatibus dolorem, fugit ullam sit
                                    earum id eaque nisi hic? Tenetur commodi, nisi rem vel, ea eaque ab ipsa, autem similique ex unde!
                                </p>
                                <a href="">
                                    Shop Now
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

            {/* <section class="w3l-grids-hny-2">
                <div class="grids-hny-2-mian py-5">
                    <div class="container py-lg-5">

                        <h3 class="hny-title mb-0 text-center">Shop With <span>Us</span></h3>
                        <p class="mb-4 text-center">Handpicked Favourites just for you</p>
                        <div class="welcome-grids row mt-5">
                            <div class="col-lg-2 col-md-4 col-6 welcome-image">
                                <div class="boxhny13">
                                    <a href="#URL">
                                        <img src="/shop/p1.png" class="img-fluid" alt="" />
                                        <div class="boxhny-content">
                                            <h3 class="title">Product</h3>
                                        </div>
                                    </a>
                                </div>
                                <h4><a href="#URL">Hand Bags</a></h4>

                            </div>

                            <div class="col-lg-2 col-md-4 col-6 welcome-image">
                                <div class="boxhny13">
                                    <a href="#URL">
                                        <img src="/shop/p1.png" class="img-fluid" alt="" />
                                        <div class="boxhny-content">
                                            <h3 class="title">Product</h3>
                                        </div>
                                    </a>
                                </div>
                                <h4><a href="#URL">
                                    Watches</a></h4>


                            </div>
                            <div class="col-lg-2 col-md-4 col-6 welcome-image">
                                <div class="boxhny13">
                                    <a href="#URL">
                                        <img src="/shop/p1.png" class="img-fluid" alt="" />
                                        <div class="boxhny-content">
                                            <h3 class="title">Product</h3>
                                        </div>
                                    </a>
                                </div>
                                <h4><a href="#URL">Shoes</a></h4>


                            </div>
                            <div class="col-lg-2 col-md-4 col-6 welcome-image">
                                <div class="boxhny13">
                                    <a href="#URL">
                                        <img src="/shop/p1.png" class="img-fluid" alt="" />
                                        <div class="boxhny-content">
                                            <h3 class="title">Product</h3>
                                        </div>
                                    </a>
                                </div>
                                <h4><a href="#URL">Suits</a></h4>

                            </div>
                            <div class="col-lg-2 col-md-4 col-6 welcome-image">
                                <div class="boxhny13">
                                    <a href="#URL">
                                        <img src="/shop/p1.png" class="img-fluid" alt="" />
                                        <div class="boxhny-content">
                                            <h3 class="title">Product</h3>
                                        </div>
                                    </a>
                                </div>
                                <h4><a href="#URL">
                                    Accessories</a></h4>


                            </div>
                            <div class="col-lg-2 col-md-4 col-6 welcome-image">
                                <div class="boxhny13">
                                    <a href="#URL">
                                        <img src="/shop/p1.png" class="img-fluid" alt="" />
                                        <div class="boxhny-content">
                                            <h3 class="title">Product</h3>
                                        </div>
                                    </a>
                                </div>
                                <h4><a href="#URL">
                                    Fragrances</a></h4>


                            </div>
                        </div>

                    </div>
                </div>
            </section> */}

            <section class="product_section layout_padding">
                <div class="container">
                    <div class="heading_container heading_center">
                        <h2>
                            Our <span>products</span>
                        </h2>
                    </div>
                    <div class="row">

                        <div class="col-lg-4" style={{ padding: "15px" }}>
                            <div class="input-group input-group-solid">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" style={{ height: "38px" }} >
                                        <span class="svg-icon svg-icon-lg"><svg xmlns="http://www.w3.org/2000/svg" xmlnsxlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                <rect x="0" y="0" width="24" height="24" />
                                                <path d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z" fill="#000000" fill-rule="nonzero" opacity="0.3" />
                                                <path d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z" fill="#000000" fill-rule="nonzero" />
                                            </g>
                                        </svg></span>                        </span>
                                </div>
                                <input type="text"
                                    class="form-control"
                                    style={{ textTransform: "lowercase" }}
                                    placeholder="Search Products"
                                    onChange={handleChange}
                                    value={input}
                                />
                            </div>
                        </div>
                        <div class="col-lg-4" style={{ padding: "15px" }}>
                            <select class="form-control" id="brandID" onChange={handleChangeBrand} value={value} required>
                                <option selected value={"null"}>--Select Brand--</option>
                                {brand?.map((item, index) => {
                                    return (
                                        <option value={item}>{item}</option>
                                    )
                                })}

                            </select>
                        </div>
                        <div class="col-lg-4" style={{ padding: "15px" }}>
                            <select class="form-control" id="type" onChange={handleChangeType} value={typeValue} required>
                                <option selected value={"null"}>--Select Type--</option>
                                {type?.map((item, index) => {
                                    return (
                                        <option value={item}>{item}</option>
                                    )
                                })}
                            </select>
                        </div>
                    </div>
                    <div style={{ textAlign: "end" }} onClick={() => resetAll()}>
                        <p style={{ fontWeight: "600", color: "#f27474", cursor: "pointer" }}>Reset Filter</p>
                    </div>

                    <hr style={{
                        backgroundColor: "#d4573f",
                        marginTop: "1rem",
                    }}></hr>
                    <div class="row">
                        {data?.map(({ id, data }, index) => {
                            if ((data['product_name'].toLowerCase().includes(input))) {
                                return (
                                    <>
                                        <div class="col-sm-6 col-md-4 col-lg-4">
                                            <div class="box">
                                                <div class="option_container">
                                                    <div class="options">

                                                        {productID.includes(id) ? <>
                                                            <span class="option1" onClick={() => removeFromBasket(data, id)}>
                                                                Remove
                                                            </span></> : <>
                                                            <span class="option1" onClick={() => addToBasket(data, id)}>
                                                                Add to cart
                                                            </span></>}
                                                        <span href="" class="option2">
                                                            Buy Now
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="img-box">
                                                    <img src={data.image_url} alt=""></img>
                                                </div>
                                                <div class="detail-box">
                                                    <h5>
                                                        {data.product_name}
                                                    </h5>
                                                    <h6>
                                                        ₹{data.price}
                                                    </h6>
                                                </div>
                                            </div>
                                        </div>
                                    </>

                                )
                            }
                        })}

                    </div>
                    <div class="btn-box">
                        <a href="">
                            View All products
                        </a>
                    </div>
                </div>
            </section>
            <Link to="/cart" class="float">
                {/* <i class="fa fa-plus my-float"></i> */}
                {/* <span class="fa fa-paper-plane my-float"></span> */}
                <span style={{
                    position: "absolute",
                    marginLeft: "11px",
                    fontWeight: "600",
                    fontSize: "15px",
                    marginTop: "8px",
                    background: "#f7444e",
                    width: "28px",
                    height: "28px",
                    borderRadius: "50%",
                    padding: "3px"
                }}> {basketCount} </span>
                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 122.9 107.5" style={{ enableBackground: "new 0 0 122.9 107.5", height: "34px", marginTop: "16px", marginLeft: "8px" }} xmlSpace="preserve"><g><path d="M3.9,7.9C1.8,7.9,0,6.1,0,3.9C0,1.8,1.8,0,3.9,0h10.2c0.1,0,0.3,0,0.4,0c3.6,0.1,6.8,0.8,9.5,2.5c3,1.9,5.2,4.8,6.4,9.1 c0,0.1,0,0.2,0.1,0.3l1,4H119c2.2,0,3.9,1.8,3.9,3.9c0,0.4-0.1,0.8-0.2,1.2l-10.2,41.1c-0.4,1.8-2,3-3.8,3v0H44.7 c1.4,5.2,2.8,8,4.7,9.3c2.3,1.5,6.3,1.6,13,1.5h0.1v0h45.2c2.2,0,3.9,1.8,3.9,3.9c0,2.2-1.8,3.9-3.9,3.9H62.5v0 c-8.3,0.1-13.4-0.1-17.5-2.8c-4.2-2.8-6.4-7.6-8.6-16.3l0,0L23,13.9c0-0.1,0-0.1-0.1-0.2c-0.6-2.2-1.6-3.7-3-4.5 c-1.4-0.9-3.3-1.3-5.5-1.3c-0.1,0-0.2,0-0.3,0H3.9L3.9,7.9z M96,88.3c5.3,0,9.6,4.3,9.6,9.6c0,5.3-4.3,9.6-9.6,9.6 c-5.3,0-9.6-4.3-9.6-9.6C86.4,92.6,90.7,88.3,96,88.3L96,88.3z M53.9,88.3c5.3,0,9.6,4.3,9.6,9.6c0,5.3-4.3,9.6-9.6,9.6 c-5.3,0-9.6-4.3-9.6-9.6C44.3,92.6,48.6,88.3,53.9,88.3L53.9,88.3z M33.7,23.7l8.9,33.5h63.1l8.3-33.5H33.7L33.7,23.7z" /></g></svg>

            </Link>
            {/* <span class="fa fa-paper-plane my-float"></span> */}
        </>
    )
}

export default Shop