import React, { useState, useEffect } from 'react';
import AdminPanel from './AdminPanel';
import Swal from 'sweetalert2'
import db from "./Firebase";
import { storage } from './Firebase';
import { useNavigate, Link, useParams } from 'react-router-dom';
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import ViewAppData from './ViewAppData';

function FreeBusinessCards() {
    const navigate = useNavigate();
    const initialFormStateFeedback = { image_url: '', created_date: new Date() }
    const [submit, setSubmit] = useState(initialFormStateFeedback)
    const [data, setData] = useState([]);
    const [editID, setEditID] = useState("");
    const [edit, setEdit] = useState(false);
    const [ShowProgress, setShowProgress] = useState(false);
    const [show, setshow] = useState(true);
    const [progresspercent, setProgresspercent] = useState(0);


    useEffect(() => {
        const storedUsername = localStorage.getItem("email");
        const storedPassword = localStorage.getItem("password");
        const storedRemember = localStorage.getItem("remember");

        if (storedRemember === "false" || !storedUsername || !storedPassword) {
            navigate('/signin');
        }
    }, []);

    const handleChange = (e) => {
        const { id, value } = e.target
        setSubmit({ ...submit, [id]: value })
    }

    const changeStatus = () => {
        setEdit(true)
        setSubmit({ image_url: data.image_url, created_date: new Date() });
        openModalShow();
    }

    const addNew = () => {
        setEdit(false)
        openModalShow();
        setSubmit({ image_url: '', created_date: new Date() });
    }

    const openModalShow = () => {
        // alert("ooo")
        window.openModelshow();
    }

    const closeModel = () => {
        window.openModelNew();
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        const file = e.target[0]?.files[0]
        // console.log(file)
        if (!file) return;
        const storageRef = ref(storage, `files/${file.name}`);
        const uploadTask = uploadBytesResumable(storageRef, file);
        setshow(false)

        uploadTask.on("state_changed",
            (snapshot) => {
                const progress =
                    Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                setProgresspercent(progress);
            },
            (error) => {
                alert(error);
            },
            () => {

                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    // setImgUrl(downloadURL)
                    db.collection("business_card").add({
                        'image_url': downloadURL,
                        'created_date': new Date(),
                    }).then(() => {
                        console.log('Created Successfully');
                        const Toast = Swal.mixin({
                            toast: true,
                            background: '#69aba6',
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 3000,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener('mouseenter', Swal.stopTimer)
                                toast.addEventListener('mouseleave', Swal.resumeTimer)
                            }
                        })

                        Toast.fire({
                            icon: 'success',
                            title: 'Created Successfully'
                        })
                        closeModel();
                        setshow(true)
                        setSubmit({ image_url: '', created_date: new Date() })
                    })
                });
            }
        );

    }

    const deleteData = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                db.collection("business_card").doc(id).delete().then(() => {
                    console.log('Deleted Successfully');
                    setSubmit({ image_url: '', created_date: new Date() })
                    closeModel();
                    Swal.fire(
                        'Deleted!',
                        'Data has been deleted.',
                        'success'
                    )
                })

            }
        })

    }

    useEffect(() => {
        db.collection("business_card").orderBy("created_date", "desc").onSnapshot((snapshot) => {
            setData(
                snapshot.docs.map((doc) => ({
                    id: doc.id,
                    data: doc.data(),
                }))
            );
        });

    }, []);

    return (
        <>
            <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">

                <div class="p-2" style={{ paddingBottom: "5px", display: "flex", justifyContent: "space-between" }}>
                    {/* <span class="btn bg-gradient-secondary btn-sm mb-0 mr-2" style={{ color: "white" }} >&nbsp; Download Excel</span> */}
                    <Link to="/admin_panel"><img src="/images/menubutton.png" class="menu-toggle" alt="Responsive image" style={{ height: "2rem" }} /></Link>
                    <a class="btn bg-gradient-primary btn-sm mb-0" style={{ color: "white" }} onClick={() => changeStatus()}>+&nbsp; Add New Business Card</a>
                </div>

                <div class="">
                    <div style={{ textAlign: "end", paddingBottom: "5px" }}>
                    </div>
                    <div class="card">
                        <div class="card-header pb-0 px-3">
                            <h6 class="mb-2">Free Business card</h6>
                        </div>
                        <div class="table-responsive fix-width scroll-inner">
                            <table class="table table-bordered table-hover">
                                <thead class="thead-dark" style={{ position: "sticky", top: "0" }}>
                                    <tr>
                                        <th class="col">Image</th>
                                        <th class="text-center col">Action</th>
                                        {/* <th class="text-secondary opacity-7"></th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {data?.map(({ id, data }, index) => (
                                        <>
                                            <tr>
                                                <td>
                                                    <div class="d-flex px-2 py-1">
                                                        <div>
                                                            <img src={data.image_url} class="" style={{ height: "4rem" }} />
                                                        </div>

                                                    </div>
                                                </td>


                                                <td class="align-middle text-center" onClick={() => deleteData(id)}>
                                                    <span class="text-info text-xs font-weight-normal" style={{ cursor: "pointer" }}>Delete</span>
                                                </td>

                                            </tr>
                                        </>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

            </main>

            <div class="col-md-4">
                <div class="myId modal fade" id="" tabindex="-1" role="dialog" aria-labelledby="modal-form" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered" role="document">
                        <div class="modal-content">
                            <div class="modal-body p-0">
                                <div class="card card-plain">
                                    {/* <div class="card-header pb-0 text-left">
                                        <h5 class="pb-4" style={{ fontSize: "13px", fontWeight: "bolder" }}>Enter your email and password to sign in</h5>
                                    </div> */}
                                    <form onSubmit={handleSubmit}>
                                        <div class="card-body">
                                            <div class="modal-body">
                                                <div class="row">

                                                    <div class="col-md-12">
                                                        <span>Free Business Image :</span>
                                                        <div class="">
                                                            <input type="file" id="email" class="" onfocus="focused(this)" onfocusout="defocused(this)" placeholder="City" required />
                                                        </div>
                                                    </div>


                                                </div>

                                            </div>
                                            <div class="modal-footer">
                                                {/* <a class="btn bg-gradient-danger btn-sm mb-0" style={{ color: "white" }}>Delete</a> */}
                                                <a class="btn bg-gradient-info btn-sm mb-0" style={{ color: "white" }} onClick={closeModel}>Close</a>
                                                <button type='submit' class="btn bg-gradient-danger btn-sm mb-0" style={{ backgroundColor: "green", color: "white" }}>Submit</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FreeBusinessCards;