import React, { useState, useEffect } from 'react';
import AdminPanel from './AdminPanel';
import Swal from 'sweetalert2'
import { useNavigate, Link, useParams } from 'react-router-dom';
import db from "./Firebase";
import { storage } from './Firebase';
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import ViewAppData from './ViewAppData';
import Multiselect from 'multiselect-react-dropdown';


function AdvertiseData() {
    const navigate = useNavigate();
    const moment = extendMoment(Moment);
    const initialFormStateFeedback = { id: '', name: '', phone_no: '', design_name: '', link: '', type: '', created_date: new Date() }
    const [submit, setSubmit] = useState(initialFormStateFeedback)
    const [data, setData] = useState([]);
    const [editID, setEditID] = useState("");
    const [edit, setEdit] = useState(false);
    const [ShowProgress, setShowProgress] = useState(false);
    const [show, setshow] = useState(true);
    const [progresspercent, setProgresspercent] = useState(0);
    const [dataFilter, setDataFilter] = useState("");

    const [selectedValue, setSelectedValue] = useState([]);


    const onSelect = (selectedList, selectedItem) => {
        // Handle selection
        // ...
        setSelectedValue(selectedList);
    }

    const onRemove = (selectedList, removedItem) => {
        // Handle removal
        // ...
        setSelectedValue(selectedList);
    }

    useEffect(() => {
        const storedUsername = localStorage.getItem("email");
        const storedPassword = localStorage.getItem("password");
        const storedRemember = localStorage.getItem("remember");

        if (storedRemember === "false" || !storedUsername || !storedPassword) {
            navigate('/signin');
        }
    }, []);


    const handleChange = (e) => {
        const { id, value } = e.target
        setSubmit({ ...submit, [id]: value })
    }

    const changeStatus = (data, id) => {
        setEdit(true)
        setEditID(id)
        setSubmit({ id: id, name: data.name, phone_no: data.phone_no, design_name: data.design_name, link: data.link, type: data.type, created_date: new Date() })
        openModalShow();
    }

    const changeStatusAdd = () => {
        setEdit(false)
        setSubmit({ id: '', name: '', phone_no: '', design_name: '', link: '', type: '', created_date: new Date() });
        openModalShow();
    }


    const openModalShow = () => {
        // alert("ooo")
        window.openModelshow();
    }

    const closeModel = () => {
        window.openModelNew();
    }



    const handleFilter = (e) => {
        console.log(e.target.value)
        setDataFilter(e.target.value)
        if (e.target.value === "all users") {
            db.collection("download_advertise_content").orderBy("created_date", "desc").onSnapshot((snapshot) => {

                setData(
                    snapshot.docs.map((doc) => ({
                        id: doc.id,
                        data: doc.data(),
                    }))
                );
            });

        } else if (e.target.value === "all users") {

        }

    }

    useEffect(() => {


    }, []);

    return (
        <>
            <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
                <div class="p-2" style={{ paddingBottom: "5px", display: "flex", justifyContent: "space-between" }}>
                    {/* <span class="btn bg-gradient-secondary btn-sm mb-0 mr-2" style={{ color: "white" }} >&nbsp; Download Excel</span> */}
                    <Link to="/admin_panel"><img src="/images/menubutton.png" class="menu-toggle" alt="Responsive image" style={{ height: "2rem" }} /></Link>


                </div>

                <div class="">
                    <select value={dataFilter} onChange={handleFilter} className="form-control" style={{ marginRight: "1rem", width: "18rem", margin: "1rem" }}>
                        <option value="--">Filter data</option>
                        <option value="all users">Free Visiting Card</option>
                        <option value="portfolio">Premium Visiting card</option>
                        <option value="mybusiness">Free Visiting card Done</option>
                        <option value="mybusiness">Premium Visiting card Done</option>

                        <option value="all users">Free Banner </option>
                        <option value="portfolio">Premium Banner </option>
                        <option value="mybusiness">Free Banner  Done</option>
                        <option value="mybusiness">Premium Banner  Done</option>

                        <option value="all users">Free SMG </option>
                        <option value="portfolio">Premium SMG </option>
                        <option value="mybusiness">Free SMG  Done</option>
                        <option value="mybusiness">Premium SMG  Done</option>


                        <option value="all users">Free WB </option>
                        <option value="portfolio">Premium WB </option>
                        <option value="mybusiness">Free WB  Done</option>
                        <option value="mybusiness">Premium WB  Done</option>


                        <option value="all users">Free GMB </option>
                        <option value="portfolio">Premium GMB </option>
                        <option value="mybusiness">Free GMB  Done</option>
                        <option value="mybusiness">Premium GMB  Done</option>

                    </select>
                    <div class="card">
                        <div class="table-responsive fix-width scroll-inner">
                            <table class="table table-bordered table-hover">
                                <thead class="thead-dark" style={{ position: "sticky", top: "0" }}>
                                    <tr style={{ textAlign: "left" }}>
                                        <th class="col">Name</th>
                                        <th class="col ps-2">Phone No</th>
                                        <th class="text-center col">Design Name</th>
                                        <th class="text-center col">Type</th>
                                        <th class="text-center col">Created Date</th>
                                        <th class="text-center col">Link</th>
                                        <th class="text-center col">Action</th>
                                        {/* <th class="text-secondary opacity-7"></th> */}
                                    </tr>
                                </thead>
                                <tbody style={{ textAlign: "left" }}>
                                    {data?.map(({ id, data }, index) => (
                                        <>
                                            <tr>

                                                <td>
                                                    <p class="text-xs font-weight-bold mb-0">{data.name}</p>
                                                    {/* <p class="text-xs text-secondary mb-0">Organization</p> */}
                                                </td>
                                                <td class="align-middle text-center text-sm">
                                                    <p class="text-xs font-weight-bold mb-0">{data.phone_no}</p>
                                                </td>
                                                <td class="align-middle text-center text-sm">
                                                    <p class="text-xs font-weight-bold mb-0">{data.design_name}</p>
                                                </td>
                                                <td class="align-middle text-center text-sm">
                                                    <p class="text-xs font-weight-bold mb-0">{data.type}</p>
                                                </td>
                                                <td class="align-middle text-center text-sm">
                                                    <span class="text-secondary text-xs font-weight-normal">{moment(data.created_date.toDate().toISOString()).format("MM/DD/YY, h:mm a")}</span>
                                                </td>
                                                <td class="align-middle text-center text-sm">
                                                    <p class="text-xs font-weight-bold mb-0">{data.link}</p>
                                                </td>

                                                <td class="align-middle text-center" onClick={() => changeStatus(data, id)}>
                                                    <span class="text-info text-xs font-weight-normal" style={{ cursor: "pointer" }}>Edit</span>
                                                </td>

                                            </tr>
                                        </>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

            </main>


        </>
    )
}

export default AdvertiseData;
