import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate, Link, useParams } from "react-router-dom"
import Select from 'react-select';

const options = [
    { value: 'Andhra Pradesh', label: 'Andhra Pradesh' },
    { value: 'Arunachal Pradesh', label: 'Arunachal Pradesh' },
    { value: 'Assam', label: 'Assam' },
    { value: 'Bihar', label: 'Bihar' },
    { value: 'Chhattisgarh', label: 'Chhattisgarh' },
    { value: 'Goa', label: 'Goa' },
    { value: 'Gujarat', label: 'Gujarat' },
    { value: 'Haryana', label: 'Haryana' },
    { value: 'Himachal Pradesh', label: 'Himachal Pradesh' },
    { value: 'Jammu and Kashmir', label: 'Jammu and Kashmir' },
    { value: 'Jharkhand', label: 'Jharkhand' },
    { value: 'Karnataka', label: 'Karnataka' },
    { value: 'Kerala', label: 'Kerala' },
    { value: 'Madhya Pradesh', label: 'Madhya Pradesh' },
    { value: 'Maharashtra', label: 'Maharashtra' },
    { value: 'Manipur', label: 'Manipur' },
    { value: 'Meghalaya', label: 'Meghalaya' },
    { value: 'Mizoram', label: 'Mizoram' },
    { value: 'Nagaland', label: 'Nagaland' },
    { value: 'Odisha', label: 'Odisha' },
    { value: 'Punjab', label: 'Punjab' },
    { value: 'Rajasthan', label: 'Rajasthan' },
    { value: 'Sikkim', label: 'Sikkim' },
    { value: 'Tamil Nadu', label: 'Tamil Nadu' },
    { value: 'Telangana', label: 'Telangana' },
    { value: 'Tripura', label: 'Tripura' },
    { value: 'Uttarakhand', label: 'Uttarakhand' },
    { value: 'Uttar Pradesh', label: 'Uttar Pradesh' },
    { value: 'West Bengal', label: 'West Bengal' },
    { value: 'Andaman and Nicobar Islands', label: 'Andaman and Nicobar Islands' },
    { value: 'Chandigarh', label: 'Chandigarh' },
    { value: 'Dadra and Nagar Haveli', label: 'Dadra and Nagar Haveli' },
    { value: 'Daman and Diu', label: 'Daman and Diu' },
    { value: 'Delhi', label: 'Delhi' },
    { value: 'Lakshadweep', label: 'Lakshadweep' },
    { value: 'Puducherry', label: 'Puducherry' },
];

function FooterMain() {

    const [selectedOption, setSelectedOption] = useState(null);

    return (
        <>
            <div class="footer-04">
                <div class="container">
                    <div class="row">
                        <div class="col-md-6">
                            <ul style={{ paddingLeft: "3rem", paddingTop: "2rem", }}>
                                <Link to="/"><li style={{ color: "white" }}>Home</li></Link>
                                <Link to="/aboutus"><li style={{ color: "white" }}>About Us</li></Link>
                                <Link to="/privacy_policy"><li style={{ color: "white" }}>Privacy Policy</li></Link>
                                <Link to="/contactus"><li style={{ color: "white" }}>Contact Us</li></Link>
                                {/* <li style={{ color: "white" }}>About Us</li> */}
                                {/* <li style={{ color: "white" }}>Privacy Policy</li> */}
                                {/* <li style={{ color: "white" }}>Contact Us</li> */}
                                <li><img style={{ width: "9rem", paddingTop: "1rem" }} alt="playstore" src="https://b.zmtcdn.com/data/webuikit/23e930757c3df49840c482a8638bf5c31556001144.png" loading="lazy" class="sc-s1isp7-5 fyZwWD" /></li>
                            </ul>
                        </div>
                        <div class="col-md-6 pt-3">
                            <div class="wrapper">
                                <div class="">
                                    {/* <Select
                                        defaultValue={selectedOption}
                                        onChange={setSelectedOption}
                                        options={options}
                                        placeholder="Search via state"
                                    /> */}

                                </div>
                                <ul class="ftco-footer-social p-0 pt-4 mr-4" style={{ display: "flex", justifyContent: "center" }}>
                                    <a href="https://www.facebook.com/youvatiofficial?mibextid=ZbWKwL" target="_blank"><img src="/images/facebookk.png" style={{ height: "30px", paddingRight: "10px" }}></img></a>
                                    <a href="https://api.whatsapp.com/send?phone=7428790444" target="_blank"><img src="/images/whatsappp.png" style={{ height: "30px", paddingRight: "10px" }}></img></a>
                                    <a href="https://instagram.com/youvatiofficial?igshid=MzRlODBiNWFlZA==" target="_blank"><img src="/images/insta.png" style={{ height: "30px", paddingRight: "10px" }}></img></a>
                                    <a href="https://twitter.com/Youvatiofficial?s=20" target="_blank"><img src="/images/twitter.png" style={{ height: "30px", paddingRight: "10px" }}></img></a>
                                    <a href="https://www.linkedin.com/company/youvati/ " target="_blank"><img src="/images/linkedin.png" style={{ height: "30px", paddingRight: "10px" }}></img></a>
                                </ul>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="" style={{ textAlign: "center", background: "#272727", color: "white", paddingBottom: "1rem" }}>Powred By ~ Youvati</div>
            </div>
        </>
    );
}

export default FooterMain;