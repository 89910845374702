// import React, { useState, useEffect } from 'react';
// import { useNavigate, Link, useParams } from 'react-router-dom';
// import db from "./Firebase";
// import { arrayUnion } from "firebase/firestore";
// import { storage } from './Firebase';
// import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
// import Moment from 'moment';
// import { extendMoment } from 'moment-range';
// import { DateRangePicker } from 'react-date-range'
// import { CSVLink } from "react-csv";
// import 'react-date-range/dist/styles.css'; // main style file
// import 'react-date-range/dist/theme/default.css';
// import { DateRange } from 'react-date-range';
// import ReactFlexyTable from 'react-flexy-table'
// import 'react-flexy-table/dist/index.css';

// function LoanRequest() {

//     const data = [
//         { name: 'John', age: 25, city: 'New York', name2: 'John', age2: 25, city2: 'New York' },
//         { name: 'Mary', age: 30, city: 'Los Angeles', name2: 'John', age2: 25, city2: 'New York' },
//     ];

//     const columns = [
//         { header: 'Name', key: 'name' },
//         { header: 'Age', key: 'age' },
//         { header: 'City', key: 'city' },
//     ];

//     const additionalCols = [
//         {
//             header: 'Actions',
//             td: (data) => {
//                 return (
//                     <div>
//                         <img
//                             src="{deleteIcon}"
//                             width='30'
//                             height='20'
//                             onClick={() => alert('this is delete for id ' + data.id)}
//                         />{' '}
//                 // delete icon
//                         <img
//                             src="{editIcon}"
//                             width='30'
//                             height='20'
//                             onClick={() => alert('this is edit for id ' + data.id)}
//                         /> // edit icon
//                     </div>
//                 )
//             }
//         }
//     ]


//     return (
//         <>
//             <div>
//                 <ReactFlexyTable data={data} filterable globalSearch className='mytabo' />
//             </div>
//         </>
//     )
// }

// export default LoanRequest;

import React, { useState, useEffect } from 'react';
import { useNavigate, Link, useParams } from 'react-router-dom';
import db from "./Firebase";
import firebaseApp from "./Firebase";
import { arrayUnion } from "firebase/firestore";
import { storage } from './Firebase';
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import ViewAllRequests from './ViewAllRequests';
import ViewAppData from './ViewAppData';

import { useTable, useSortBy, useFilters, useGlobalFilter, useAsyncDebounce, usePagination } from "react-table";
import { CSVLink } from "react-csv";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css';
import { DateRange } from 'react-date-range';

function GlobalFilter({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
}) {
    const temp = preGlobalFilteredRows.length;
    const [value, setValue] = React.useState(globalFilter);
    const onChange = useAsyncDebounce((value) => {
        setGlobalFilter(value || undefined);
    }, 200);

    return (
        <span>
            {/* Search:{" "} */}
            <input
                class="form-control"
                value={value || ""}
                onChange={(e) => {
                    setValue(e.target.value);
                    onChange(e.target.value);
                }}
                placeholder={`Search ${temp} records...`}

            />
        </span>
    );
}

// Define a default UI for filtering
function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
}) {
    const temp = preFilteredRows.length;

    return (
        <input class="form-control" style={{ marginTop: "11px" }} id="email" onChange={(e) => {
            setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
        }} value={filterValue || ""} placeholder="Search" />
        // <input
        //     value={filterValue || ""}
        //     onChange={(e) => {
        //         setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
        //     }}
        //     placeholder={`Search ${temp} records...`}
        // />
    );
}


function LoanRequest() {
    const navigate = useNavigate();
    const moment = extendMoment(Moment);
    const [tableData, setTableData] = useState([]);
    const [data, setData] = useState([]);
    const [pdata, setPData] = useState({});
    const [csvdata, setCsvdata] = useState([]);

    useEffect(() => {
        const storedUsername = localStorage.getItem("email");
        const storedPassword = localStorage.getItem("password");
        const storedRemember = localStorage.getItem("remember");

        if (storedRemember === "false" || !storedUsername || !storedPassword) {
            navigate('/signin');
        }
    }, []);

    const headers = [
        { label: "Applicant Name", key: "applicant_name" },
        { label: "Applicant Name", key: "applicant_phone" },
        { label: "loan_id", key: "loan_id" },
        { label: "applicant_date_of_birth", key: "applicant_date_of_birth" },
        { label: "loan_type", key: "loan_type" },
        { label: "gender", key: "gender" },
        { label: "applicant_alternate_phone", key: "applicant_alternate_phone" },
        { label: "applicant_marital_status", key: "applicant_marital_status" },
        { label: "fathers_name", key: "fathers_name" },
        { label: "mothers_name", key: "mothers_name" },
        { label: "city", key: "city" },
        { label: "state", key: "state" },
        { label: "pincode", key: "pincode" },
        { label: "full_address", key: "full_address" },
        { label: "highest_education", key: "highest_education" },
        { label: "business_type", key: "business_type" },
        { label: "parlour_freelancer_name", key: "parlour_freelancer_name" },
        { label: "parlour_address", key: "parlour_address" },
        { label: "ownership_type", key: "ownership_type" },
        { label: "working_since", key: "working_since" },
        { label: "monthly_since", key: "monthly_since" },
        { label: "bank_account_number", key: "bank_account_number" },
        { label: "bank_name", key: "bank_name" },
        { label: "ifsc_code", key: "ifsc_code" },
        { label: "upi_id", key: "upi_id" },
        { label: "loan_amount", key: "loan_amount" },
        { label: "have_you_taken_loan_in_past", key: "have_you_taken_loan_in_past" },
        { label: "needGrant", key: "needGrant" },
        { label: "purposeOfReason", key: "purposeOfReason" },
        { label: "reasonEstimated", key: "reasonEstimated" },
        { label: "repaymentModel", key: "repaymentModel" },
        { label: "how_did_you_know_about_scheme", key: "how_did_you_know_about_scheme" },
        { label: "aadhar_front", key: "aadhar_front" },
        { label: "aadhar_back", key: "aadhar_back" },
        { label: "pan", key: "pan" },
        { label: "passbook", key: "passbook" },
        { label: "parlour_photo", key: "parlour_photo" },
        { label: "applicant_photo", key: "applicant_photo" },
        { label: "error_message", key: "error_message" },
        { label: "document_status", key: "document_status" },
        { label: "document_status_done_msg", key: "document_status_done_msg" },
        { label: "document_status_pending_msg", key: "document_status_pending_msg" },
        { label: "verification_status", key: "verification_status" },
        { label: "verification_status_done_msg", key: "verification_status_done_msg" },
        { label: "verification_status_pending_msg", key: "verification_status_pending_msg" },
        { label: "nbfc_status", key: "nbfc_status" },
        { label: "nbfc_status_done_msg", key: "nbfc_status_done_msg" },
        { label: "nbfc_status_pending_msg", key: "nbfc_status_pending_msg" },
        { label: "penny_check_status", key: "penny_check_status" },
        { label: "penny_check_status_done_msg", key: "penny_check_status_done_msg" },
        { label: "penny_check_status_pending_msg", key: "penny_check_status_pending_msg" },
        { label: "godrej_status", key: "godrej_status" },
        { label: "godrej_status_done_msg", key: "godrej_status_done_msg" },
        { label: "godrej_status_pending_msg", key: "godrej_status_pending_msg" },
        { label: "loan_disbursement_status", key: "loan_disbursement_status" },
        { label: "loan_disbursement_status_done_msg", key: "loan_disbursement_status_done_msg" },
        { label: "loan_disbursement_status_pending_msg", key: "loan_disbursement_status_pending_msg" },
        { label: "custom_msg_one", key: "custom_msg_one" },
        { label: "custom_msg_two", key: "custom_msg_two" },
        { label: "status", key: "status" },
        { label: "created_date", key: "created_date", dataFormat: "MM/DD/YY, h:mm a" },
        { label: "updated_date", key: "updated_date", dataFormat: "MM/DD/YY, h:mm a" },
    ];

    const changeStatus = () => {
        openModalShow();
    }

    const openModalShow = () => {
        // alert("ooo")
        window.openModelshow();
    }

    const closeModel = () => {
        window.openModelNew();
    }

    const LoanProcessed = async () => {
        const snapshot = await db.collection("loan").where("status", "==", 1).orderBy("created_date", "desc").get();
        const data = snapshot.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
        }));

        setCsvdata(
            snapshot.docs.map((doc) => {
                const data = doc.data();
                return {
                    ...data,
                    created_date: moment(data.created_date.toDate().toISOString()).format("MM/DD/YY, h:mm a"),
                    updated_date: moment(data.updated_date.toDate().toISOString()).format("MM/DD/YY, h:mm a"),
                };
            })
        );

        setTableData(data);
        // LoanProcessed();
    };

    const LoanUnderProcess = async () => {
        const snapshot = await db.collection("loan").where("status", "==", 0).orderBy("created_date", "desc").get();
        const data = snapshot.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
        }));
        setCsvdata(
            snapshot.docs.map((doc) => {
                const data = doc.data();
                return {
                    ...data,
                    created_date: moment(data.created_date.toDate().toISOString()).format("MM/DD/YY, h:mm a"),
                    updated_date: moment(data.updated_date.toDate().toISOString()).format("MM/DD/YY, h:mm a"),
                };
            })
        );

        setTableData(data);
        // LoanUnderProcess();
    };

    const LoanRejected = async () => {
        const snapshot = await db.collection("loan").where("status", "==", 2).orderBy("created_date", "desc").get();
        const data = snapshot.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
        }));

        setTableData(data);
        setCsvdata(
            snapshot.docs.map((doc) => {
                const data = doc.data();
                return {
                    ...data,
                    created_date: moment(data.created_date.toDate().toISOString()).format("MM/DD/YY, h:mm a"),
                    updated_date: moment(data.updated_date.toDate().toISOString()).format("MM/DD/YY, h:mm a"),
                };
            })
        );
        // LoanUnderProcess();
    };

    const LoanRepaymentCompleted = async () => {
        const snapshot = await db.collection("loan").where("repayment_completed", "==", 1).orderBy("updated_date", "desc").get();
        const data = snapshot.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
        }));

        setTableData(data);
        setCsvdata(
            snapshot.docs.map((doc) => {
                const data = doc.data();
                return {
                    ...data,
                    created_date: moment(data.created_date.toDate().toISOString()).format("MM/DD/YY, h:mm a"),
                    updated_date: moment(data.updated_date.toDate().toISOString()).format("MM/DD/YY, h:mm a"),
                };
            })
        );
        // LoanUnderProcess();
    };

    const uniqueData = [];

    const LoanRepaymentStarted = async () => {
        const snapshot = await db.collection("repayment_model").where("status", "==", "1").get();
        const data = snapshot.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
        }));

        // Create a new Set to store unique loan_id values
        const uniqueLoanIds = new Set();

        // Iterate over the data array
        data.forEach((item) => {
            uniqueLoanIds.add(item.loan_id);
        });

        // Convert the Set back to an array
        const uniqueData = Array.from(uniqueLoanIds);

        console.log(uniqueData);

        const snapshot1 = await db.collection("loan").orderBy("created_date", "desc").get();
        const data1 = snapshot1.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
        }));

        // Filter the data based on the loan_id values present in the uniqueData array
        const filteredData = data1.filter((item) => uniqueData.includes(item.loan_id));
        setTableData(filteredData);
        // console.log(filteredData);
    };


    const LoanRepaymentProcess = async () => {
        const snapshot = await db.collection("loan").where("status", "==", 0).orderBy("created_date", "desc").get();
        const data = snapshot.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
        }));

        // Filter the data based on the loan_id values present in the uniqueData array
        const filteredData = data.filter((item) => uniqueData.includes(item.loan_id));

        setTableData(filteredData);
    };


    const allData = async () => {
        const snapshot = await db.collection("loan").orderBy("created_date", "desc").get();
        const data = snapshot.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
        }));

        setTableData(data);

    };





    useEffect(() => {
        const fetchData = async () => {
            const snapshot = await db.collection("loan").orderBy("created_date", "desc").get();
            const data = snapshot.docs.map((doc) => ({
                ...doc.data(),
                id: doc.id,
            }));

            setTableData(data);

        };
        fetchData();

        db.collection("loan").orderBy("created_date", "desc").onSnapshot((snapshot) => {
            setCsvdata(
                snapshot.docs.map((doc) => {
                    const data = doc.data();
                    return {
                        ...data,
                        created_date: moment(data.created_date.toDate().toISOString()).format("MM/DD/YY, h:mm a"),
                        updated_date: moment(data.updated_date.toDate().toISOString()).format("MM/DD/YY, h:mm a"),
                    };
                })
            );
        });
    }, []);
    // useEffect(() => {
    //     db.collection("portfolio_details").orderBy("created_date", "desc").onSnapshot((snapshot) => {

    //         setData(
    //             snapshot.docs.map((doc) => ({
    //                 id: doc.id,
    //                 data: doc.data(),
    //             }))
    //         );
    //     });

    // }, []);

    const columns = React.useMemo(
        () => [

            {
                Header: "Applicant Name",
                accessor: "applicant_name", // accessor is the "key" in the data
            },
            {
                Header: "Created Date",
                accessor: "created_date",
                Cell: ({ value }) => (
                    moment(value.toDate().toISOString()).format("MM/DD/YY, h:mm a")
                ),
            },
            {
                Header: "Applicant Phone",
                accessor: "loan_id", // accessor is the "key" in the data
            },
            {
                Header: "Loan Amount",
                accessor: "loan_amount", // accessor is the "key" in the data
            },

            {
                Header: "Bank Name",
                accessor: "bank_name", // accessor is the "key" in the data
            },

            {
                Header: "City Name",
                accessor: "city", // accessor is the "key" in the data
            },

            {
                Header: "Loan Type",
                accessor: "loan_type", // accessor is the "key" in the data
            },
            {
                Header: "Username",
                accessor: "username", // accessor is the "key" in the data
                Cell: ({ row }) => (
                    <Link to={`/loan_details/${row.original.applicant_phone}`} ><h6 class="mb-0 text-xs">View</h6></Link>
                    // <a href={row.original.username} target="_blank" rel="noopener noreferrer">
                    //     {row.original.username}
                    // </a>
                ),
            },

            // {
            //     Header: "Created Date",
            //     accessor: "created_date", // accessor is the "key" in the data
            // },

        ],
        []
    );

    const defaultColumn = React.useMemo(
        () => ({
            // Let's set up our default Filter UI
            Filter: DefaultColumnFilter,
        }),
        []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state,
        visibleColumns,
        preGlobalFilteredRows,
        setGlobalFilter,
        page,
        state: { pageIndex, pageSize },
        setPageSize,
        gotoPage,
        nextPage,
        previousPage,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
    } = useTable(
        {
            columns,
            data: tableData,
            defaultColumn, // Be sure to pass the defaultColumn option
        },
        useFilters,
        useGlobalFilter,
        useSortBy,
        usePagination
    );

    return (
        <>
            {/* <ViewAllRequests /> */}
            <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
                <div class="p-2" style={{ textAlign: "start", paddingBottom: "5px" }}>
                    {/* <span class="btn bg-gradient-secondary btn-sm mb-0 mr-2" style={{ color: "white" }} >&nbsp; Download Excel</span> */}
                    <Link to="/admin_panel"><img src="/images/menubutton.png" class="menu-toggle" alt="Responsive image" style={{ height: "2rem" }} /></Link>

                </div>
                <div class="row">


                    <div class="mt-4 p-4">
                        <div class="card-footer p-1" style={{ borderStyle: "none" }}>
                            <div class="row">
                                <div class="pr-4" style={{ display: "flex", justifyContent: "flex-end" }}>
                                    <span class="badge badge-warning dropdown-toggle" data-toggle="dropdown" id="dropdownMenuButton" aria-haspopup="true" aria-expanded="false" style={{ cursor: "pointer", backgroundColor: "#ec407a", color: "white", padding: "9px", marginRight: "1rem" }}>Services</span>
                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                        <span class="dropdown-item" onClick={() => LoanUnderProcess()} style={{ cursor: "pointer" }}>Under review</span>
                                        <span class="dropdown-item" onClick={() => LoanProcessed()}>Loan Processed</span>
                                        <span class="dropdown-item" onClick={() => LoanRejected()}>Loan Rejected</span>
                                        <span class="dropdown-item" onClick={() => LoanRepaymentStarted()}>Repayment Started</span>
                                        <span class="dropdown-item" onClick={() => LoanRepaymentCompleted()}>Repayment Completed</span>
                                        <span class="dropdown-item" onClick={() => allData()}>All</span>
                                    </div>
                                    <CSVLink data={csvdata} headers={headers}>
                                        <span class="btn bg-gradient-secondary btn-sm mb-0 mr-2" style={{ color: "white" }} >&nbsp; Download Excel</span>
                                    </CSVLink>
                                </div>
                            </div>


                        </div>


                        {/* <div style={{ textAlign: "end", paddingBottom: "5px" }}>
                            <span class="btn bg-gradient-secondary btn-sm mb-0 mr-2" style={{ color: "white" }} onClick={() => LoanUnderProcess()}  >&nbsp; Under review</span>
                            <span class="btn bg-gradient-secondary btn-sm mb-0 mr-2" style={{ color: "white" }} onClick={() => LoanProcessed()} >&nbsp; Loan Processed</span>
                            <span class="btn bg-gradient-secondary btn-sm mb-0 mr-2" style={{ color: "white" }} onClick={() => LoanRejected()} >&nbsp; Rejected</span>
                            <span class="btn bg-gradient-secondary btn-sm mb-0 mr-2" style={{ color: "white" }} onClick={() => LoanRepaymentStarted()} >&nbsp; Repayment Started</span>
                            <span class="btn bg-gradient-secondary btn-sm mb-0 mr-2" style={{ color: "white" }} onClick={() => LoanRepaymentCompleted()} >&nbsp; Repayment Completed</span>
                            <CSVLink data={csvdata} headers={headers}>
                                <span class="btn bg-gradient-secondary btn-sm mb-0 mr-2" style={{ color: "white" }} >&nbsp; Download Excel</span>
                            </CSVLink>

                        </div> */}
                        <div class="card">
                            <div class="card-header pb-0 px-3">
                                <h6 class="mb-2">Loan Details</h6>
                            </div>
                            <div class="">
                                <div class="card">
                                    <div class="table-responsive fix-width scroll-inner">
                                        <table {...getTableProps()} class="table align-items-center mb-0">

                                            <thead>
                                                {headerGroups.map((headerGroup) => (
                                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                                        {headerGroup.headers.map((column) => (
                                                            <th
                                                                {...column.getHeaderProps(column.getSortByToggleProps())}
                                                                style={{
                                                                    textAlign: "left",
                                                                    borderBottom: "solid 1px black",
                                                                    color: "black",
                                                                }}
                                                                class="text-uppercase text-xxs font-weight-bolder"
                                                            >
                                                                {column.render("Header")}
                                                                <span>
                                                                    {column.isSorted ? (column.isSortedDesc ? "🔽" : "🔼") : ""}
                                                                </span>
                                                                <div>{column.canFilter ? column.render("Filter") : null}</div>
                                                            </th>
                                                        ))}
                                                    </tr>
                                                ))}
                                                <tr>
                                                    <th
                                                        colSpan={visibleColumns.length}
                                                        style={{
                                                            textAlign: "left",
                                                        }}
                                                    >
                                                        <GlobalFilter
                                                            preGlobalFilteredRows={preGlobalFilteredRows}
                                                            globalFilter={state.globalFilter}
                                                            setGlobalFilter={setGlobalFilter}
                                                        />
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody {...getTableBodyProps()} style={{ textAlign: "start" }}>
                                                {page.map((row, i) => {
                                                    prepareRow(row);
                                                    return (
                                                        <tr {...row.getRowProps()}>
                                                            {row.cells.map((cell) => {
                                                                return (
                                                                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                                                                );
                                                            })}
                                                        </tr>
                                                    );
                                                })}


                                            </tbody>


                                        </table>
                                        <div className="" style={{ paddingTop: "2rem" }}>
                                            <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                                                {"<<"}
                                            </button>{" "}
                                            <button onClick={() => previousPage()} disabled={!canPreviousPage}>
                                                {"<"}
                                            </button>{" "}
                                            <button onClick={() => nextPage()} disabled={!canNextPage}>
                                                {">"}
                                            </button>{" "}
                                            <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                                                {">>"}
                                            </button>{" "}
                                            <span>
                                                Page{" "}
                                                <strong>
                                                    {pageIndex + 1} of {pageOptions.length}
                                                </strong>{" "}
                                            </span>
                                            <span>
                                                | Go to page:{" "}
                                                <input
                                                    type="number"
                                                    defaultValue={pageIndex + 1}
                                                    onChange={(e) => {
                                                        const page = e.target.value ? Number(e.target.value) - 1 : 0;
                                                        gotoPage(page);
                                                    }}
                                                    style={{ width: "100px" }}
                                                />
                                            </span>{" "}
                                            <select
                                                value={pageSize}
                                                onChange={(e) => {
                                                    setPageSize(Number(e.target.value));
                                                }}
                                            >
                                                {[10, 20, 30, 40, 50].map((pageSize) => (
                                                    <option key={pageSize} value={pageSize}>
                                                        Show {pageSize}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                {/* <div class="card">
                    <div class="table-responsive fix-width scroll-inner">
                        <table class="table align-items-center mb-0 table-bordered">
                            <thead>
                                <tr>
                                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Name</th>
                                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Title/Designation</th>
                                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">City</th>
                                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">State</th>
                                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Phone No</th>
                                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">User Name/Portfolio</th>
                                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Created Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data?.map(({ id, data }, index) => (
                                    <>
                                        <tr>
                                            <td>
                                                <div class="d-flex px-2 py-1">
                                                    <div>
                                                        <img src={data.portfolio_image} class="avatar avatar-sm me-3" />
                                                    </div>
                                                    <div class="d-flex flex-column justify-content-center">
                                                        <h6 class="mb-0 font-weight-bold text-xs" style={{ cursor: "pointer", color: "#17a2b8" }}>{data.first_name}  {data.last_name}</h6>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <p class="text-xs mb-0">{data.title_designation}</p>
                                              </td>
                                            <td>
                                                <p class="text-xs mb-0">{data.city}</p>
                                               </td>
                                            <td>
                                                <p class="text-xs mb-0">{data.state}</p>
                                               </td>
                                            <td>
                                                <p class="text-xs mb-0">{data.phone_no}</p>
                                              </td>
                                           

                                            <td>
                                                <div class="d-flex px-2 py-1">
                                                    <div class="d-flex flex-column justify-content-center">
                                                        <a href={data.username} style={{ color: "blue" }} target="_blank" class="mb-0 text-xs">{data.username}</a>
                                                    </div>
                                                </div>
                                            </td>

                                            <td>
                                                <div class="d-flex px-2 py-1">
                                                    <div class="d-flex flex-column justify-content-center">
                                                        <h6 class="mb-0 text-xs">{moment(data.created_date.toDate().toISOString()).format("MM/DD/YY, h:mm a")}</h6>
                                                    </div>
                                                </div>
                                            </td>

                                            <td>
                                                <div class="d-flex px-2 py-1">
                                                    <div class="d-flex flex-column justify-content-center">
                                                        <h6 class="mb-0 text-xs">{data.upi_address}</h6>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>

                                    </>
                                ))}


                            </tbody>
                        </table>
                    </div>
                </div> */}
                            </div>
                        </div>
                    </div>

                </div>

            </main>
        </>
    );
}

export default LoanRequest;












































// import { useTable, useSortBy, useFilters, useGlobalFilter, useAsyncDebounce, } from "react-table";
// import React, { useState, useEffect } from 'react';
// import { useNavigate, Link, useParams } from 'react-router-dom';
// import db from "./Firebase";
// import { arrayUnion } from "firebase/firestore";
// import { storage } from './Firebase';
// import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
// import Moment from 'moment';
// import { extendMoment } from 'moment-range';
// import { DateRangePicker } from 'react-date-range'
// import { CSVLink } from "react-csv";
// import 'react-date-range/dist/styles.css'; // main style file
// import 'react-date-range/dist/theme/default.css';
// import { DateRange } from 'react-date-range';


// function GlobalFilter({
//     preGlobalFilteredRows,
//     globalFilter,
//     setGlobalFilter,
// }) {
//     const temp = preGlobalFilteredRows.length;
//     const [value, setValue] = React.useState(globalFilter);
//     const onChange = useAsyncDebounce((value) => {
//         setGlobalFilter(value || undefined);
//     }, 200);

//     return (
//         <span>
//             Search:{" "}
//             <input
//                 value={value || ""}
//                 onChange={(e) => {
//                     setValue(e.target.value);
//                     onChange(e.target.value);
//                 }}
//                 placeholder={`${temp} records...`}
//                 style={{
//                     fontSize: "1.1rem",
//                     border: "0",
//                 }}
//             />
//         </span>
//     );
// }

// // Define a default UI for filtering
// function DefaultColumnFilter({
//     column: { filterValue, preFilteredRows, setFilter },
// }) {
//     const temp = preFilteredRows.length;

//     return (
//         <input
//             value={filterValue || ""}
//             onChange={(e) => {
//                 setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
//             }}
//             placeholder={`Search ${temp} records...`}
//         />
//     );
// }

// function LoanRequest() {
//     const [tableData, setTableData] = useState([]);

//     useEffect(() => {
//         // db.collection("loan").orderBy("created_date", "desc").onSnapshot((snapshot) => {
//         //     setData(
//         //         snapshot.docs.map((doc) => ({
//         //             id: doc.id,
//         //             data: doc.data(),
//         //         }))
//         //     );
//         // });

//         const fetchData = async () => {
//             const snapshot = await db.collection("portfolio_details").get();
//             const data = snapshot.docs.map((doc) => ({
//                 ...doc.data(),
//                 id: doc.id,
//             }));
//             setTableData(data);
//         };
//         fetchData();
//     }, []);

//     const columns = React.useMemo(
//         () => [
//             {
//                 Header: "Name",
//                 accessor: "full_name",
//             },
//             {
//                 Header: "Age",
//                 accessor: "city",
//             },
//             {
//                 Header: "Gender",
//                 accessor: "parlour_type",
//             },
//         ],
//         []
//     );

//     // const tableInstance = useTable({ columns, data: tableData }, useFilters,
//     //     useGlobalFilter,
//     //     useSortBy);

//     const defaultColumn = React.useMemo(
//         () => ({
//             // Let's set up our default Filter UI
//             Filter: DefaultColumnFilter,
//         }),
//         []
//     );

//     const {
//         getTableProps,
//         getTableBodyProps,
//         headerGroups,
//         rows,
//         prepareRow,
//         state,
//         visibleColumns,
//         preGlobalFilteredRows,
//         setGlobalFilter,
//     } = useTable(
//         {
//             columns,
//             data: tableData,
//             defaultColumn, // Be sure to pass the defaultColumn option
//         },
//         useFilters,
//         useGlobalFilter,
//         useSortBy
//     );

//     return (
//         <table {...getTableProps()}>
//             <thead>
//                 {headerGroups.map((headerGroup) => (
//                     <tr {...headerGroup.getHeaderGroupProps()}>
//                         {headerGroup.headers.map((column) => (
//                             <th {...column.getHeaderProps()}>{column.render("Header")}</th>
//                         ))}
//                     </tr>
//                 ))}
//             </thead>
//             <tbody {...getTableBodyProps()}>
//                 {rows.map((row) => {
//                     prepareRow(row);
//                     return (
//                         <tr {...row.getRowProps()}>
//                             {row.cells.map((cell) => {
//                                 return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
//                             })}
//                         </tr>
//                     );
//                 })}
//             </tbody>
//         </table>
//     );
// }

// export default LoanRequest;