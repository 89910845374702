import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import db from "./Firebase";
import { collection, query, where, getDocs } from "firebase/firestore";
import Moment from 'moment';

function WorkshopPaid() {
    const [workshopDetails, setWorkshopDetails] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const q = query(collection(db, "workshop_registration"), where("payment", "==", "Paid"));
                const snapshot = await getDocs(q);
                const details = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    data: doc.data(),
                }));
                setWorkshopDetails(details);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, []);

    return (
        <>
            <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg">
                <div className="p-2" style={{ paddingBottom: "5px", display: "flex", justifyContent: "space-between" }}>
                    <Link to="/admin_panel">
                        <img src="/images/menubutton.png" className="menu-toggle" alt="Responsive image" style={{ height: "2rem" }} />
                    </Link>
                </div>
                <div className="">
                    <div className="p-2">
                        <div className="card">
                            <div className="card-header pb-0 px-3">
                                <h6 className="mb-2">Workshop Registration Details (Paid)</h6>
                            </div>
                            <div className="card-body">
                                <div className="table-responsive fix-width scroll-inner">
                                    <table className="table table-bordered table-hover">
                                        <thead className="thead-dark" style={{ position: "sticky", top: "0" }}>
                                            <tr>
                                                <th>Full Name</th>
                                                <th>Email</th>
                                                <th>Phone</th>
                                                <th>Workshop Date</th>
                                                <th>Workshop Event Name</th>
                                                <th>Workshop Location</th>
                                                <th>Payment</th>
                                                <th>Payment ID</th>
                                                <th>Created Date</th>
                                                <th>Description</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {workshopDetails.map(({ id, data }) => (
                                                <tr key={id}>
                                                    <td>{data.full_name}</td>
                                                    <td>{data.email}</td>
                                                    <td>{data.phone}</td>
                                                    <td>{data.workshop_Date}</td>
                                                    <td>{data.workshop_event_name}</td>
                                                    <td>{data.workshop_location}</td>
                                                    <td>{data.payment}</td>
                                                    <td>{data.payment_id}</td>
                                                    <td>{Moment(data.created_date.toDate()).format('MMM Do YY, h:mm a')}</td>
                                                    <td>{data.description}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
}

export default WorkshopPaid;
