import React, { useState, useEffect } from 'react';
import AdminPanel from './AdminPanel';
import { useNavigate, Link, useParams } from 'react-router-dom';
import ViewAppData from './ViewAppData';

function GetOnRent() {

    const [view, setView] = useState(false);

    const open = () => {
        setView(true);
    }

    const changeStatus = () => {
        openModalShow();
    }

    const openModalShow = () => {
        // alert("ooo")
        window.openModelshow();
    }

    const closeModel = () => {
        window.openModelNew();
    }

    return (
        <>
            <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">

                <ViewAppData />

                <div class="container-fluid py-4">

                    <div style={{ textAlign: "end", paddingBottom: "5px" }}>
                        <Link to="/admin_panel" class="btn bg-gradient-primary btn-sm mb-0 mr-2" style={{ color: "white" }} >&nbsp; Go Back</Link>
                    </div>
                    <div class="card">
                        <div class="table-responsive fix-width scroll-inner">
                            <table class="table align-items-center mb-0">
                                <thead>
                                    <tr>
                                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Station Type</th>
                                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Location</th>
                                        <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Images</th>
                                        {/* <th class="text-secondary opacity-7"></th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <p class="text-xs font-weight-bold mb-0" onClick={() => openModalShow()}>Meerut</p>
                                            {/* <p class="text-xs text-secondary mb-0">Organization</p> */}
                                        </td>
                                        <td>
                                            <div class="d-flex px-2 py-1">

                                                <div class="d-flex flex-column justify-content-center">
                                                    <h6 class="mb-0 text-xs">Makeup,Facial,Manicuare,Pedicure,Nail</h6>
                                                </div>
                                            </div>
                                        </td>

                                        <td class="align-middle text-center text-sm">
                                            <div>
                                                <img src="https://demos.creative-tim.com/test/material-dashboard-pro/assets/img/team-2.jpg" class="avatar avatar-sm me-3" />
                                            </div>
                                        </td>


                                    </tr>


                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

            </main>


        </>
    )
}

export default GetOnRent;