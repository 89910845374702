import React, { useState, useEffect } from 'react';
import { useNavigate, Link, useParams } from 'react-router-dom';
import db from "./Firebase";
import firebaseApp from "./Firebase";
import { arrayUnion } from "firebase/firestore";
import { storage } from './Firebase';
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import ViewAllRequests from './ViewAllRequests';
import ViewAppData from './ViewAppData';
import { useTable, useSortBy, useFilters, useGlobalFilter, useAsyncDebounce, usePagination } from "react-table";
import { CSVLink } from "react-csv";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css';
import { DateRange } from 'react-date-range';

function GlobalFilter({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
}) {
    const temp = preGlobalFilteredRows.length;
    const [value, setValue] = React.useState(globalFilter);
    const onChange = useAsyncDebounce((value) => {
        setGlobalFilter(value || undefined);
    }, 200);

    return (
        <span>
            <input
                className="form-control"
                value={value || ""}
                onChange={(e) => {
                    setValue(e.target.value);
                    onChange(e.target.value);
                }}
                placeholder={`Search ${temp} records...`}
            />
        </span>
    );
}

// Define a default UI for filtering
function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
}) {
    const temp = preFilteredRows.length;

    return (
        <input className="form-control" style={{ marginTop: "11px" }} id="email" onChange={(e) => {
            setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
        }} value={filterValue || ""} placeholder="Search" />
    );
}

function PortfolioData() {
    const navigate = useNavigate();
    const moment = extendMoment(Moment);
    const [tableData, setTableData] = useState([]);
    const [visibleData, setVisibleData] = useState([]);
    const [pdata, setPData] = useState({});
    const [csvData, setCsvData] = useState([]);
    const [dataLimit, setDataLimit] = useState(20);

    const headers = [
        { label: "First Name", key: "first_name" },
        { label: "Last Name", key: "last_name" },
        { label: "Parlour Name", key: "parlour_name" },
        { label: "Username", key: "username" },
        { label: "Title/Designation", key: "title_designation" },
        { label: "Phone Number", key: "phone_no" },
        { label: "City", key: "city" },
        { label: "State", key: "state" },
        { label: "Pincode", key: "pincode" },
        { label: "Address", key: "detailed_address" },
        { label: "Created Date", key: "created_date" },
    ];

    useEffect(() => {
        const storedUsername = localStorage.getItem("email");
        const storedPassword = localStorage.getItem("password");
        const storedRemember = localStorage.getItem("remember");

        if (storedRemember === "false" || !storedUsername || !storedPassword) {
            navigate('/signin');
        }
    }, []);

    const changeStatus = () => {
        openModalShow();
    }

    const openModalShow = () => {
        window.openModelshow();
    }

    const closeModel = () => {
        window.openModelNew();
    }

    useEffect(() => {
        const fetchData = async () => {
            const snapshot = await db.collection("portfolio_details").orderBy("created_date", "desc").get();
            const data = snapshot.docs.map((doc) => ({
                ...doc.data(),
                id: doc.id,
            }));

            setTableData(data);
            setVisibleData(data.slice(0, dataLimit));
            setCsvData(
                data.map((doc) => {
                    return {
                        ...doc,
                        created_date: moment(doc.created_date.toDate().toISOString()).format("MM/DD/YY, h:mm a"),
                    };
                })
            );
        };
        fetchData();
    }, [dataLimit]);

    const loadMoreData = () => {
        setDataLimit(dataLimit + 20);
    };

    const columns = React.useMemo(
        () => [
            {
                Header: "Image",
                accessor: "portfolio_image",
                Filter: ({ column }) => null,
                filterable: false,
                disableSortBy: true,
                disableFilters: true,
                Cell: ({ value }) => <img src={value} alt="Avatar" className="avatar avatar-sm me-3" />,
            },
            {
                Header: "Username",
                accessor: "username", // accessor is the "key" in the data
                Cell: ({ row }) => (
                    <a href={row.original.username} target="_blank" rel="noopener noreferrer">
                        {row.original.username}
                    </a>
                ),
            },
            {
                Header: "Name",
                filterable: false,
                disableSortBy: true,
                accessor: (row) => row.first_name + " " + row.last_name,
            },
            {
                Header: "Title/Designation",
                accessor: "title_designation", // accessor is the "key" in the data
            },
            {
                Header: "City",
                accessor: "city", // accessor is the "key" in the data
            },
            {
                Header: "Pincode",
                accessor: "pincode", // accessor is the "key" in the data
            },
            {
                Header: "Phone No",
                accessor: "phone_no", // accessor is the "key" in the data
            },
            {
                Header: "Created Date",
                accessor: "created_date",
                Cell: ({ value }) => (
                    moment(value.toDate().toISOString()).format("MM/DD/YY, h:mm a")
                ),
            },
        ],
        []
    );

    const defaultColumn = React.useMemo(
        () => ({
            Filter: DefaultColumnFilter,
        }),
        []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state,
        visibleColumns,
        preGlobalFilteredRows,
        setGlobalFilter,
        page,
        state: { pageIndex, pageSize },
        setPageSize,
        gotoPage,
        nextPage,
        previousPage,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
    } = useTable(
        {
            columns,
            data: visibleData,
            defaultColumn,
        },
        useFilters,
        useGlobalFilter,
        useSortBy,
        usePagination
    );

    return (
        <>
            <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg">
                <div className="p-2" style={{ textAlign: "start", paddingBottom: "5px" }}>
                    <Link to="/admin_panel"><img src="/images/menubutton.png" className="menu-toggle" alt="Responsive image" style={{ height: "2rem" }} /></Link>
                </div>
                <div className="row">
                    <div className="mt-4 p-4">
                        <div style={{ textAlign: "end", paddingBottom: "5px" }}>
                            <CSVLink data={csvData.slice(0, dataLimit)} headers={headers}>
                                <span className="btn bg-gradient-secondary btn-sm mb-0 mr-2" style={{ color: "white" }}>&nbsp; Download Excel</span>
                            </CSVLink>
                        </div>
                        <div className="card">
                            <div className="card-header pb-0 px-3">
                                <h6 className="mb-2">Users Portfolio</h6>
                            </div>
                            <div className="">
                                <div className="card">
                                    <div className="table-responsive fix-width scroll-inner">
                                        <table {...getTableProps()} className="table align-items-center mb-0">
                                            <thead>
                                                {headerGroups.map((headerGroup) => (
                                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                                        {headerGroup.headers.map((column) => (
                                                            <th
                                                                {...column.getHeaderProps(column.getSortByToggleProps())}
                                                                style={{
                                                                    textAlign: "left",
                                                                    borderBottom: "solid 1px black",
                                                                    color: "black",
                                                                }}
                                                                className="text-uppercase text-xxs font-weight-bolder"
                                                            >
                                                                {column.render("Header")}
                                                                <span>
                                                                    {column.isSorted ? (column.isSortedDesc ? "🔽" : "🔼") : ""}
                                                                </span>
                                                                <div>{column.canFilter ? column.render("Filter") : null}</div>
                                                            </th>
                                                        ))}
                                                    </tr>
                                                ))}
                                                <tr>
                                                    <th
                                                        colSpan={visibleColumns.length}
                                                        style={{
                                                            textAlign: "left",
                                                        }}
                                                    >
                                                        <GlobalFilter
                                                            preGlobalFilteredRows={preGlobalFilteredRows}
                                                            globalFilter={state.globalFilter}
                                                            setGlobalFilter={setGlobalFilter}
                                                        />
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody {...getTableBodyProps()}>
                                                {rows.map((row, i) => {
                                                    prepareRow(row);
                                                    return (
                                                        <tr {...row.getRowProps()}>
                                                            {row.cells.map((cell) => {
                                                                return (
                                                                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                                                                );
                                                            })}
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                 
                                    </div>
                                    <div class="" style={{ paddingTop: "2rem" }}>
                                            <button onClick={loadMoreData} className="btn btn-dark">Load More</button>
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}

export default PortfolioData;


// import React, { useState, useEffect } from 'react';
// import { useNavigate, Link, useParams } from 'react-router-dom';
// import db from "./Firebase";
// import firebaseApp from "./Firebase";
// import { arrayUnion } from "firebase/firestore";
// import { storage } from './Firebase';
// import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
// import Moment from 'moment';
// import { extendMoment } from 'moment-range';
// import ViewAllRequests from './ViewAllRequests';
// import ViewAppData from './ViewAppData';

// import { useTable, useSortBy, useFilters, useGlobalFilter, useAsyncDebounce, usePagination } from "react-table";
// import { CSVLink } from "react-csv";
// import 'react-date-range/dist/styles.css'; // main style file
// import 'react-date-range/dist/theme/default.css';
// import { DateRange } from 'react-date-range';

// function GlobalFilter({
//     preGlobalFilteredRows,
//     globalFilter,
//     setGlobalFilter,
// }) {
//     const temp = preGlobalFilteredRows.length;
//     const [value, setValue] = React.useState(globalFilter);
//     const onChange = useAsyncDebounce((value) => {
//         setGlobalFilter(value || undefined);
//     }, 200);

//     return (
//         <span>
//             {/* Search:{" "} */}
//             <input
//                 class="form-control"
//                 value={value || ""}
//                 onChange={(e) => {
//                     setValue(e.target.value);
//                     onChange(e.target.value);
//                 }}
//                 placeholder={`Search ${temp} records...`}

//             />
//         </span>
//     );
// }

// // Define a default UI for filtering
// function DefaultColumnFilter({
//     column: { filterValue, preFilteredRows, setFilter },
// }) {
//     const temp = preFilteredRows.length;

//     return (
//         <input class="form-control" style={{ marginTop: "11px" }} id="email" onChange={(e) => {
//             setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
//         }} value={filterValue || ""} placeholder="Search" />
//         // <input
//         //     value={filterValue || ""}
//         //     onChange={(e) => {
//         //         setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
//         //     }}
//         //     placeholder={`Search ${temp} records...`}
//         // />
//     );
// }


// function PortfolioData() {
//     const navigate = useNavigate();
//     const moment = extendMoment(Moment);
//     const [tableData, setTableData] = useState([]);
//     const [data, setData] = useState([]);
//     const [pdata, setPData] = useState({});

//     const [csvdata, setCsvdata] = useState([]);

//     const headers = [
//         { label: "First Name", key: "first_name" },
//         { label: "Last Name", key: "last_name" },
//         { label: "Parlour Name", key: "parlour_name" },
//         { label: "Username", key: "username" },
//         { label: "Title/Designation", key: "title_designation" },
//         { label: "Phone Number", key: "phone_no" },
//         { label: "City", key: "city" },
//         { label: "State", key: "state" },
//         { label: "Pincode", key: "pincode" },
//         { label: "Address", key: "detailed_address" },
//         { label: "Created Date", key: "created_date" },
//     ];

//     useEffect(() => {
//         const storedUsername = localStorage.getItem("email");
//         const storedPassword = localStorage.getItem("password");
//         const storedRemember = localStorage.getItem("remember");

//         if (storedRemember === "false" || !storedUsername || !storedPassword) {
//             navigate('/signin');
//         }
//     }, []);

//     const changeStatus = () => {
//         openModalShow();
//     }

//     const openModalShow = () => {
//         // alert("ooo")
//         window.openModelshow();
//     }

//     const closeModel = () => {
//         window.openModelNew();
//     }

//     useEffect(() => {
//         const fetchData = async () => {
//             const snapshot = await db.collection("portfolio_details").orderBy("created_date", "desc").get();
//             const data = snapshot.docs.map((doc) => ({
//                 ...doc.data(),
//                 id: doc.id,
//             }));

//             setCsvdata(
//                 snapshot.docs.map((doc) => {
//                     const data = doc.data();
//                     return {
//                         ...data,
//                         created_date: moment(data.created_date.toDate().toISOString()).format("MM/DD/YY, h:mm a"),
//                     };
//                 })
//             );

//             setTableData(data);
//         };
//         fetchData();
//     }, []);
//     // useEffect(() => {
//     //     db.collection("portfolio_details").orderBy("created_date", "desc").onSnapshot((snapshot) => {

//     //         setData(
//     //             snapshot.docs.map((doc) => ({
//     //                 id: doc.id,
//     //                 data: doc.data(),
//     //             }))
//     //         );
//     //     });

//     // }, []);

//     const columns = React.useMemo(
//         () => [
//             {
//                 Header: "Image",
//                 accessor: "portfolio_image",
//                 Filter: ({ column }) => null,
//                 filterable: false,
//                 disableSortBy: true,
//                 disableFilters: true,
//                 Cell: ({ value }) => <img src={value} alt="Avatar" class="avatar avatar-sm me-3" />,
//             },
//             {
//                 Header: "Username",
//                 accessor: "username", // accessor is the "key" in the data
//                 Cell: ({ row }) => (
//                     <a href={row.original.username} target="_blank" rel="noopener noreferrer">
//                         {row.original.username}
//                     </a>
//                 ),
//             },
//             {
//                 Header: "Name",
//                 filterable: false,
//                 disableSortBy: true,
//                 // accessor: "first_name", // accessor is the "key" in the data
//                 accessor: (row) => row.first_name + " " + row.last_name,
//             },
//             {
//                 Header: "Title/Designation",
//                 accessor: "title_designation", // accessor is the "key" in the data
//             },
//             {
//                 Header: "City",
//                 accessor: "city", // accessor is the "key" in the data
//             },
//             {
//                 Header: "Pincode",
//                 accessor: "pincode", // accessor is the "key" in the data
//             },

//             {
//                 Header: "Phone No",
//                 accessor: "phone_no", // accessor is the "key" in the data
//             },

//             {
//                 Header: "Created Date",
//                 accessor: "created_date",
//                 Cell: ({ value }) => (
//                     moment(value.toDate().toISOString()).format("MM/DD/YY, h:mm a")
//                 ),
//             },
//             // {
//             //     Header: "Created Date",
//             //     accessor: "created_date", // accessor is the "key" in the data
//             // },

//         ],
//         []
//     );

//     const defaultColumn = React.useMemo(
//         () => ({
//             // Let's set up our default Filter UI
//             Filter: DefaultColumnFilter,
//         }),
//         []
//     );

//     const {
//         getTableProps,
//         getTableBodyProps,
//         headerGroups,
//         rows,
//         prepareRow,
//         state,
//         visibleColumns,
//         preGlobalFilteredRows,
//         setGlobalFilter,
//         page,
//         state: { pageIndex, pageSize },
//         setPageSize,
//         gotoPage,
//         nextPage,
//         previousPage,
//         canPreviousPage,
//         canNextPage,
//         pageOptions,
//         pageCount,
//     } = useTable(
//         {
//             columns,
//             data: tableData,
//             defaultColumn, // Be sure to pass the defaultColumn option
//         },
//         useFilters,
//         useGlobalFilter,
//         useSortBy,
//         usePagination
//     );


//     return (
//         <>
//             {/* <ViewAppData /> */}
//             <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
//                 <div class="p-2" style={{ textAlign: "start", paddingBottom: "5px" }}>
//                     {/* <span class="btn bg-gradient-secondary btn-sm mb-0 mr-2" style={{ color: "white" }} >&nbsp; Download Excel</span> */}
//                     <Link to="/admin_panel"><img src="/images/menubutton.png" class="menu-toggle" alt="Responsive image" style={{ height: "2rem" }} /></Link>

//                 </div>
//                 <div class="row">


//                     <div class="mt-4 p-4">
//                         <div style={{ textAlign: "end", paddingBottom: "5px" }}>
//                             {/* <Link to="/admin_panel" class="btn bg-gradient-primary btn-sm mb-0 mr-2" style={{ color: "white" }} >&nbsp; Go Back</Link> */}
//                         </div>
//                         <div style={{ textAlign: "end", paddingBottom: "5px" }}>
//                             {/* <Link to="/admin_panel" class="btn bg-gradient-primary btn-sm mb-0 mr-2" style={{ color: "white" }} >&nbsp; Go Back</Link> */}
//                             <CSVLink data={csvdata} headers={headers}>
//                                 <span class="btn bg-gradient-secondary btn-sm mb-0 mr-2" style={{ color: "white" }} >&nbsp; Download Excel</span>
//                             </CSVLink>
//                         </div>
//                         <div class="card">
//                             <div class="card-header pb-0 px-3">
//                                 <h6 class="mb-2">Users Portfolio</h6>
//                             </div>
//                             <div class="">
//                                 <div class="card">
//                                     <div class="table-responsive fix-width scroll-inner">
//                                         <table {...getTableProps()} class="table align-items-center mb-0">

//                                             <thead>
//                                                 {headerGroups.map((headerGroup) => (
//                                                     <tr {...headerGroup.getHeaderGroupProps()}>
//                                                         {headerGroup.headers.map((column) => (
//                                                             <th
//                                                                 {...column.getHeaderProps(column.getSortByToggleProps())}
//                                                                 style={{
//                                                                     textAlign: "left",
//                                                                     borderBottom: "solid 1px black",
//                                                                     color: "black",
//                                                                 }}
//                                                                 class="text-uppercase text-xxs font-weight-bolder"
//                                                             >
//                                                                 {column.render("Header")}
//                                                                 <span>
//                                                                     {column.isSorted ? (column.isSortedDesc ? "🔽" : "🔼") : ""}
//                                                                 </span>
//                                                                 <div>{column.canFilter ? column.render("Filter") : null}</div>
//                                                             </th>
//                                                         ))}
//                                                     </tr>
//                                                 ))}
//                                                 <tr>
//                                                     <th
//                                                         colSpan={visibleColumns.length}
//                                                         style={{
//                                                             textAlign: "left",
//                                                         }}
//                                                     >
//                                                         <GlobalFilter
//                                                             preGlobalFilteredRows={preGlobalFilteredRows}
//                                                             globalFilter={state.globalFilter}
//                                                             setGlobalFilter={setGlobalFilter}
//                                                         />
//                                                     </th>
//                                                 </tr>
//                                             </thead>
//                                             <tbody {...getTableBodyProps()}>
//                                                 {page.map((row, i) => {
//                                                     prepareRow(row);
//                                                     return (
//                                                         <tr {...row.getRowProps()}>
//                                                             {row.cells.map((cell) => {
//                                                                 return (
//                                                                     <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
//                                                                 );
//                                                             })}
//                                                         </tr>
//                                                     );
//                                                 })}


//                                             </tbody>


//                                         </table>
//                                         <div className="" style={{ paddingTop: "2rem" }}>
//                                             <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
//                                                 {"<<"}
//                                             </button>{" "}
//                                             <button onClick={() => previousPage()} disabled={!canPreviousPage}>
//                                                 {"<"}
//                                             </button>{" "}
//                                             <button onClick={() => nextPage()} disabled={!canNextPage}>
//                                                 {">"}
//                                             </button>{" "}
//                                             <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
//                                                 {">>"}
//                                             </button>{" "}
//                                             <span>
//                                                 Page{" "}
//                                                 <strong>
//                                                     {pageIndex + 1} of {pageOptions.length}
//                                                 </strong>{" "}
//                                             </span>
//                                             <span>
//                                                 | Go to page:{" "}
//                                                 <input
//                                                     type="number"
//                                                     defaultValue={pageIndex + 1}
//                                                     onChange={(e) => {
//                                                         const page = e.target.value ? Number(e.target.value) - 1 : 0;
//                                                         gotoPage(page);
//                                                     }}
//                                                     style={{ width: "100px" }}
//                                                 />
//                                             </span>{" "}
//                                             <select
//                                                 value={pageSize}
//                                                 onChange={(e) => {
//                                                     setPageSize(Number(e.target.value));
//                                                 }}
//                                             >
//                                                 {[10, 20, 30, 40, 50].map((pageSize) => (
//                                                     <option key={pageSize} value={pageSize}>
//                                                         Show {pageSize}
//                                                     </option>
//                                                 ))}
//                                             </select>
//                                         </div>
//                                     </div>
//                                 </div>
//                                 {/* <div class="card">
//                                     <div class="table-responsive fix-width scroll-inner">
//                                         <table class="table align-items-center mb-0 table-bordered">
//                                             <thead>
//                                                 <tr>
//                                                     <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Name</th>
//                                                     <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Title/Designation</th>
//                                                     <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">City</th>
//                                                     <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">State</th>
//                                                     <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Phone No</th>
//                                                     <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">User Name/Portfolio</th>
//                                                     <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Created Date</th>
//                                                 </tr>
//                                             </thead>
//                                             <tbody>
//                                                 {data?.map(({ id, data }, index) => (
//                                                     <>
//                                                         <tr>
//                                                             <td>
//                                                                 <div class="d-flex px-2 py-1">
//                                                                     <div>
//                                                                         <img src={data.portfolio_image} class="avatar avatar-sm me-3" />
//                                                                     </div>
//                                                                     <div class="d-flex flex-column justify-content-center">
//                                                                         <h6 class="mb-0 font-weight-bold text-xs" style={{ cursor: "pointer", color: "#17a2b8" }}>{data.first_name}  {data.last_name}</h6>
//                                                                     </div>
//                                                                 </div>
//                                                             </td>
//                                                             <td>
//                                                                 <p class="text-xs mb-0">{data.title_designation}</p>
//                                                               </td>
//                                                             <td>
//                                                                 <p class="text-xs mb-0">{data.city}</p>
//                                                                </td>
//                                                             <td>
//                                                                 <p class="text-xs mb-0">{data.state}</p>
//                                                                </td>
//                                                             <td>
//                                                                 <p class="text-xs mb-0">{data.phone_no}</p>
//                                                               </td>
                                                           

//                                                             <td>
//                                                                 <div class="d-flex px-2 py-1">
//                                                                     <div class="d-flex flex-column justify-content-center">
//                                                                         <a href={data.username} style={{ color: "blue" }} target="_blank" class="mb-0 text-xs">{data.username}</a>
//                                                                     </div>
//                                                                 </div>
//                                                             </td>

//                                                             <td>
//                                                                 <div class="d-flex px-2 py-1">
//                                                                     <div class="d-flex flex-column justify-content-center">
//                                                                         <h6 class="mb-0 text-xs">{moment(data.created_date.toDate().toISOString()).format("MM/DD/YY, h:mm a")}</h6>
//                                                                     </div>
//                                                                 </div>
//                                                             </td>

//                                                             <td>
//                                                                 <div class="d-flex px-2 py-1">
//                                                                     <div class="d-flex flex-column justify-content-center">
//                                                                         <h6 class="mb-0 text-xs">{data.upi_address}</h6>
//                                                                     </div>
//                                                                 </div>
//                                                             </td>
//                                                         </tr>

//                                                     </>
//                                                 ))}


//                                             </tbody>
//                                         </table>
//                                     </div>
//                                 </div> */}
//                             </div>
//                         </div>
//                     </div>

//                 </div>

//             </main>

//             <div class="col-md-4">
//                 <div class="myId modal fade" id="" tabindex="-1" role="dialog" aria-labelledby="modal-form" aria-hidden="true">
//                     <div class="modal-dialog modal-dialog-centered" role="document">
//                         <div class="modal-content">
//                             <div class="modal-body p-0">
//                                 <div class="card card-plain">
//                                     <div class="card-body">
//                                         <div class="modal-body">
//                                             {pdata.parlour_name == null ? <><span>No Data Found</span></> : <>
//                                                 <div>
//                                                     <div class="row">
//                                                         {/* <span>Parlour Image</span> <img src="https://demos.creative-tim.com/test/material-dashboard-pro/assets/img/team-2.jpg" class="" style={{ height: "15rem" }} /> */}
//                                                         <div class="col-md-12">

//                                                             <span style={{ color: "grey" }}>Parlour Name</span> : <span>{pdata.parlour_name}</span>                                                    <hr />
//                                                             <span style={{ color: "grey" }}>Phone No </span> : <span> {pdata.phone_no}</span><hr />
//                                                             <span style={{ color: "grey" }}>Pincode </span> : <span> {pdata.pincode}</span><hr />
//                                                             <span style={{ color: "grey" }}>Owner Type </span> : <span>{pdata.owner}</span><hr />
//                                                             <span style={{ color: "grey" }}>User Name </span> : <span>{pdata.username}</span><hr />
//                                                             <span style={{ color: "grey" }}>Partner Type </span> : <span> {pdata.partner_type}</span><hr />
//                                                             <span style={{ color: "grey" }}>Address </span> : <span>{pdata.full_address}</span>
//                                                         </div>


//                                                     </div>
//                                                     <hr />
//                                                     <span style={{ color: "grey" }}>Business Image : </span>
//                                                     {pdata.salon_business_images == "" ? <>
//                                                         <span>No image found !</span>
//                                                     </>
//                                                         :
//                                                         <>
//                                                             <div class="row">
//                                                                 {pdata.salon_business_images?.map((imgData) => (
//                                                                     <>
//                                                                         <img src={imgData} class="p-3" style={{ height: "6rem", width: "7rem" }} />
//                                                                     </>
//                                                                 ))}
//                                                             </div>
//                                                         </>}

//                                                     <hr />
//                                                     <span style={{ color: "grey" }}>Saloon Image : </span>
//                                                     {pdata.services_images == "" ? <>
//                                                         <span>No image found !</span>
//                                                     </>
//                                                         :
//                                                         <>
//                                                             <div class="row">
//                                                                 {pdata.services_images?.map((imgData) => (
//                                                                     <>
//                                                                         <img src={imgData} class="p-3" style={{ height: "6rem", width: "7rem" }} />
//                                                                     </>
//                                                                 ))}
//                                                             </div>
//                                                         </>}


//                                                 </div>
//                                             </>}


//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>


//         </>
//     )
// }

// export default PortfolioData;





// import React, { useState, useEffect } from 'react';
// import { useNavigate, Link, useParams } from 'react-router-dom';
// import db from "./Firebase";
// import firebaseApp from "./Firebase";
// import { arrayUnion } from "firebase/firestore";
// import { storage } from './Firebase';
// import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
// import Moment from 'moment';
// import { extendMoment } from 'moment-range';
// import ViewAllRequests from './ViewAllRequests';
// import ViewAppData from './ViewAppData';

// import { useTable, useSortBy, useFilters, useGlobalFilter, useAsyncDebounce, usePagination } from "react-table";
// import { CSVLink } from "react-csv";
// import 'react-date-range/dist/styles.css'; // main style file
// import 'react-date-range/dist/theme/default.css';
// import { DateRange } from 'react-date-range';

// function GlobalFilter({
//     preGlobalFilteredRows,
//     globalFilter,
//     setGlobalFilter,
// }) {
//     const temp = preGlobalFilteredRows.length;
//     const [value, setValue] = React.useState(globalFilter);
//     const onChange = useAsyncDebounce((value) => {
//         setGlobalFilter(value || undefined);
//     }, 200);

//     return (
//         <span>
//             {/* Search:{" "} */}
//             <input
//                 class="form-control"
//                 value={value || ""}
//                 onChange={(e) => {
//                     setValue(e.target.value);
//                     onChange(e.target.value);
//                 }}
//                 placeholder={`Search ${temp} records...`}

//             />
//         </span>
//     );
// }

// // Define a default UI for filtering
// function DefaultColumnFilter({
//     column: { filterValue, preFilteredRows, setFilter },
// }) {
//     const temp = preFilteredRows.length;

//     return (
//         <input class="form-control" style={{ marginTop: "11px" }} id="email" onChange={(e) => {
//             setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
//         }} value={filterValue || ""} placeholder="Search" />
//         // <input
//         //     value={filterValue || ""}
//         //     onChange={(e) => {
//         //         setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
//         //     }}
//         //     placeholder={`Search ${temp} records...`}
//         // />
//     );
// }


// function LoanRequest() {
//     const moment = extendMoment(Moment);
//     const [tableData, setTableData] = useState([]);
//     const [data, setData] = useState([]);
//     const [pdata, setPData] = useState({});

//     const changeStatus = () => {
//         openModalShow();
//     }

//     const openModalShow = () => {
//         // alert("ooo")
//         window.openModelshow();
//     }

//     const closeModel = () => {
//         window.openModelNew();
//     }

//     useEffect(() => {
//         const fetchData = async () => {
//             const snapshot = await db.collection("portfolio_details").orderBy("created_date", "desc").get();
//             const data = snapshot.docs.map((doc) => ({
//                 ...doc.data(),
//                 id: doc.id,
//             }));
//             setTableData(data);
//         };
//         fetchData();
//     }, []);
//     // useEffect(() => {
//     //     db.collection("portfolio_details").orderBy("created_date", "desc").onSnapshot((snapshot) => {

//     //         setData(
//     //             snapshot.docs.map((doc) => ({
//     //                 id: doc.id,
//     //                 data: doc.data(),
//     //             }))
//     //         );
//     //     });

//     // }, []);

//     const columns = React.useMemo(
//         () => [
//             {
//                 Header: "Image",
//                 accessor: "portfolio_image",
//                 Filter: ({ column }) => null,
//                 filterable: false,
//                 disableSortBy: true,
//                 disableFilters: true,
//                 Cell: ({ value }) => <img src={value} alt="Avatar" class="avatar avatar-sm me-3" />,
//             },
//             {
//                 Header: "Name",
//                 filterable: false,
//                 disableSortBy: true,
//                 // accessor: "first_name", // accessor is the "key" in the data
//                 accessor: (row) => row.first_name + " " + row.last_name,
//             },
//             {
//                 Header: "Title/Designation",
//                 accessor: "title_designation", // accessor is the "key" in the data
//             },
//             {
//                 Header: "City",
//                 accessor: "city", // accessor is the "key" in the data
//             },

//             {
//                 Header: "Phone No",
//                 accessor: "phone_no", // accessor is the "key" in the data
//             },
//             {
//                 Header: "Username",
//                 accessor: "username", // accessor is the "key" in the data
//                 Cell: ({ row }) => (
//                     <a href={row.original.username} target="_blank" rel="noopener noreferrer">
//                         {row.original.username}
//                     </a>
//                 ),
//             },
//             {
//                 Header: "Created Date",
//                 accessor: "created_date",
//                 Cell: ({ value }) => (
//                     moment(value.toDate().toISOString()).format("MM/DD/YY, h:mm a")
//                 ),
//             },
//             // {
//             //     Header: "Created Date",
//             //     accessor: "created_date", // accessor is the "key" in the data
//             // },

//         ],
//         []
//     );

//     const defaultColumn = React.useMemo(
//         () => ({
//             // Let's set up our default Filter UI
//             Filter: DefaultColumnFilter,
//         }),
//         []
//     );

//     const {
//         getTableProps,
//         getTableBodyProps,
//         headerGroups,
//         rows,
//         prepareRow,
//         state,
//         visibleColumns,
//         preGlobalFilteredRows,
//         setGlobalFilter,
//         page,
//         state: { pageIndex, pageSize },
//         setPageSize,
//         gotoPage,
//         nextPage,
//         previousPage,
//         canPreviousPage,
//         canNextPage,
//         pageOptions,
//         pageCount,
//     } = useTable(
//         {
//             columns,
//             data: tableData,
//             defaultColumn, // Be sure to pass the defaultColumn option
//         },
//         useFilters,
//         useGlobalFilter,
//         useSortBy,
//         usePagination
//     );

//     return (
//         <>

//             <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">

//                 <div class="row">

//                     <div class="mt-4 p-4">
//                         <div style={{ textAlign: "end", paddingBottom: "5px" }}>
//                             <Link to="/admin_panel" class="btn bg-gradient-primary btn-sm mb-0 mr-2" style={{ color: "white" }} >&nbsp; Go Back</Link>
//                             {/* <CSVLink data={csvdata} headers={headers}>
//                                 <span class="btn bg-gradient-secondary btn-sm mb-0 mr-2" style={{ color: "white" }} >&nbsp; Download Excel</span>
//                             </CSVLink> */}
//                         </div>
//                         <div class="card">
//                             <div class="card-header pb-0 px-3">
//                                 <h6 class="mb-2">Loan Request</h6>
//                             </div>
//                             <div class="container-fluid py-4">



//                                 <div class="card">
//                                     <div class="table-responsive fix-width scroll-inner">
//                                         <table {...getTableProps()} class="table align-items-center mb-0">

//                                             <thead>
//                                                 {headerGroups.map((headerGroup) => (
//                                                     <tr {...headerGroup.getHeaderGroupProps()}>
//                                                         {headerGroup.headers.map((column) => (
//                                                             <th
//                                                                 {...column.getHeaderProps(column.getSortByToggleProps())}
//                                                                 style={{
//                                                                     textAlign: "left",
//                                                                     borderBottom: "solid 1px black",
//                                                                     color: "black",
//                                                                 }}
//                                                                 class="text-uppercase text-xxs font-weight-bolder"
//                                                             >
//                                                                 {column.render("Header")}
//                                                                 <span>
//                                                                     {column.isSorted ? (column.isSortedDesc ? "🔽" : "🔼") : ""}
//                                                                 </span>
//                                                                 <div>{column.canFilter ? column.render("Filter") : null}</div>
//                                                             </th>
//                                                         ))}
//                                                     </tr>
//                                                 ))}
//                                                 <tr>
//                                                     <th
//                                                         colSpan={visibleColumns.length}
//                                                         style={{
//                                                             textAlign: "left",
//                                                         }}
//                                                     >
//                                                         <GlobalFilter
//                                                             preGlobalFilteredRows={preGlobalFilteredRows}
//                                                             globalFilter={state.globalFilter}
//                                                             setGlobalFilter={setGlobalFilter}
//                                                         />
//                                                     </th>
//                                                 </tr>
//                                             </thead>
//                                             <tbody {...getTableBodyProps()}>
//                                                 {page.map((row) => {
//                                                     prepareRow(row);
//                                                     return (
//                                                         <tr {...row.getRowProps()}>
//                                                             {row.cells.map((cell) => {
//                                                                 return (
//                                                                     <td
//                                                                         {...cell.getCellProps()}
//                                                                         style={{
//                                                                             padding: "10px",
//                                                                             border: "solid 1px gray",
//                                                                         }}
//                                                                     >
//                                                                         {cell.render("Cell")}
//                                                                     </td>
//                                                                 );
//                                                             })}
//                                                         </tr>
//                                                     );
//                                                 })}
//                                             </tbody>
//                                             <div className="pagination">
//                                                 <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
//                                                     {"<<"}
//                                                 </button>{" "}
//                                                 <button onClick={() => previousPage()} disabled={!canPreviousPage}>
//                                                     {"<"}
//                                                 </button>{" "}
//                                                 <button onClick={() => nextPage()} disabled={!canNextPage}>
//                                                     {">"}
//                                                 </button>{" "}
//                                                 <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
//                                                     {">>"}
//                                                 </button>{" "}
//                                                 <span>
//                                                     Page{" "}
//                                                     <strong>
//                                                         {pageIndex + 1} of {pageOptions.length}
//                                                     </strong>{" "}
//                                                 </span>
//                                                 <span>
//                                                     | Go to page:{" "}
//                                                     <input
//                                                         type="number"
//                                                         defaultValue={pageIndex + 1}
//                                                         onChange={(e) => {
//                                                             const page = e.target.value ? Number(e.target.value) - 1 : 0;
//                                                             gotoPage(page);
//                                                         }}
//                                                         style={{ width: "100px" }}
//                                                     />
//                                                 </span>{" "}
//                                                 <select
//                                                     value={pageSize}
//                                                     onChange={(e) => {
//                                                         setPageSize(Number(e.target.value));
//                                                     }}
//                                                 >
//                                                     {[10, 20, 30, 40, 50].map((pageSize) => (
//                                                         <option key={pageSize} value={pageSize}>
//                                                             Show {pageSize}
//                                                         </option>
//                                                     ))}
//                                                 </select>
//                                             </div>
//                                         </table>

//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>

//                 </div>


//             </main>
//         </>
//     );
// }

// export default LoanRequest;