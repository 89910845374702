import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import db from "./Firebase";
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import { CSVLink } from "react-csv";

function LocationData() {
    const navigate = useNavigate();
    const moment = extendMoment(Moment);
    const [data, setData] = useState([]);
    const [csvdata, setCsvdata] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [dataLimit, setDataLimit] = useState(20);

    useEffect(() => {
        const storedUsername = localStorage.getItem("email");
        const storedPassword = localStorage.getItem("password");
        const storedRemember = localStorage.getItem("remember");

        if (storedRemember === "false" || !storedUsername || !storedPassword) {
            navigate('/signin');
        }
    }, []);

    const headers = [
        "Username",
        "Postal Code",
        "Locality",
        "Country",
        "State",
        "City",
        "Phone",
        "Full Address",
        "Created Date",
        // Add more column labels as needed
    ];

    useEffect(() => {
        const usersData = [];
        const parlourData = [];

        // Fetch data from "users_location" collection
        db.collection("users_location").orderBy("created_date", "desc").onSnapshot((snapshot) => {
            usersData.length = 0; // Clear the array
            snapshot.docs.forEach((doc) => {
                usersData.push({
                    id: doc.id,
                    data: doc.data(),
                });
            });
            mergeData();
        });

        // Fetch data from "parlour_name" collection
        db.collection("parlour_name").onSnapshot((snapshot) => {
            parlourData.length = 0; // Clear the array
            snapshot.docs.forEach((doc) => {
                parlourData.push({
                    id: doc.id,
                    data: doc.data(),
                });
            });
            mergeData();
        });

        // Function to merge data when both sets are available
        function mergeData() {
            if (usersData.length > 0 && parlourData.length > 0) {
                // Merge the data based on the username
                const mergedData = usersData.map((user) => {
                    const parlour = parlourData.find((p) => p.data.username === user.data.username);
                    return {
                        ...user,
                        parlour: parlour ? parlour.data : null,
                    };
                });

                // Now, mergedData contains the merged information
                setData(mergedData);
            }
        }
    }, []);

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    // Function to filter data based on the search query
    const filteredData = data.filter((item) => {
        const username = item.data.username.toLowerCase();
        const city = item.parlour ? (item.parlour.city || "").toLowerCase() : "";
        const state = item.parlour ? (item.parlour.state || "").toLowerCase() : "";
        const phone = item.parlour ? item.parlour.phone.toLowerCase() : "";
        const query = searchQuery.toLowerCase();

        return (
            username.includes(query) ||
            city.includes(query) ||
            state.includes(query) ||
            phone.includes(query)
        );
    });

    // Function to convert data to CSV format
    const prepareCSVData = () => {
        const csvData = filteredData.map((item, index) => ([
            item.data.username,
            item.data.postal_code,
            item.data.locality,
            item.data.country,
            item.parlour ? item.parlour.state : '',
            item.parlour ? item.parlour.city : '',
            item.parlour ? item.parlour.phone : '',
            item.data.full_address,
            moment(item.data.created_date.toDate().toISOString()).format("MM/DD/YY, h:mm a"),
            // Add more columns as needed
        ]));

        return csvData;
    };

    const limitedData = filteredData.slice(0, dataLimit);

    const loadMoreData = () => {
        setDataLimit(dataLimit + 20);
    };

    return (
        <>
            <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg">
                <div class="p-2" style={{ paddingBottom: "5px", display: "flex", justifyContent: "space-between" }}>
                    <Link to="/admin_panel">
                        <img src="/images/menubutton.png" class="menu-toggle" alt="Responsive image" style={{ height: "2rem" }} />
                    </Link>
                    <CSVLink data={prepareCSVData()} headers={headers} filename={"users_data.csv"}>
                        <span class="btn bg-gradient-secondary btn-sm mb-0 mr-2" style={{ color: "white" }}>
                            &nbsp; Download Excel
                        </span>
                    </CSVLink>
                </div>
                <div class="">

                    <div class="p-2">

                        <div class="card">
                            <div class="card-header pb-0 px-3">
                                <h6 class="mb-2">User's Location Data || {`Records : ${filteredData.length}`}</h6>
                            </div>
                            <input
                                type="text"
                                className="form-control form-control-sm"
                                placeholder="Search by Username, City, State, or Phone Number"
                                value={searchQuery}
                                onChange={handleSearchChange}
                            />
                            <div class="card">
                                <div class="table-responsive fix-width scroll-inner">
                                    <table class="table table-bordered table-hover">
                                        <thead class="thead-dark" style={{ position: "sticky", top: "0" }}>
                                            <tr style={{ textAlign: "left" }}>
                                                <th class="ps-2">S.No</th>
                                                <th class="ps-2">Username</th>
                                                <th class="">Postal Code</th>
                                                <th class="">Locality</th>
                                                <th class="ps-2">Country</th>
                                                <th class="ps-2">State</th>
                                                <th class="ps-2">City</th>
                                                <th class="ps-2">Phone</th>
                                                <th class="ps-2">Full Address</th>
                                                <th class="ps-2">Created Date</th>
                                            </tr>
                                        </thead>
                                        <tbody style={{ textAlign: "left" }}>
                                            {limitedData.map((item, index) => (
                                                <tr key={item.id}>
                                                    <td>
                                                        <p class="text-xs mb-0">{index + 1}.</p>
                                                    </td>
                                                    <td>
                                                        <p class="text-xs mb-0">{item.data.username}</p>
                                                    </td>
                                                    <td>
                                                        <p class="text-xs mb-0">{item.data.postal_code}</p>
                                                    </td>
                                                    <td>
                                                        <p class="text-xs mb-0">{item.data.locality}</p>
                                                    </td>
                                                    <td>
                                                        <p class="text-xs mb-0">{item.data.country}</p>
                                                    </td>
                                                    <td>
                                                        <p class="text-xs mb-0">{item.parlour ? item.parlour.state : ''}</p>
                                                    </td>
                                                    <td>
                                                        <p class="text-xs mb-0">{item.parlour ? item.parlour.city : ''}</p>
                                                    </td>
                                                    <td>
                                                        <p class="text-xs mb-0">{item.parlour ? item.parlour.phone : ''}</p>
                                                    </td>
                                                    <td style={{ whiteSpace: 'pre-wrap' }}>
                                                        <p class="text-xs font-weight-bold mb-0" style={{ width: "15rem" }}>{item.data.full_address}</p>
                                                    </td>
                                                    <td>
                                                        <div class="d-flex px-2 py-1">
                                                            <div class="d-flex flex-column justify-content-center">
                                                                <h6 class="mb-0 text-xs">{moment(item.data.created_date.toDate().toISOString()).format("MM/DD/YY, h:mm a")}</h6>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    <div className="" style={{ paddingTop: "2rem" }}>
                                        <button onClick={loadMoreData} className="btn btn-dark">Load More</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
}

export default LocationData;


// import React, { useState, useEffect } from 'react';
// import { useNavigate, Link, useParams } from 'react-router-dom';
// import db from "./Firebase";
// import firebaseApp from "./Firebase";
// import { arrayUnion } from "firebase/firestore";
// import { storage } from './Firebase';
// import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
// import Moment from 'moment';
// import { extendMoment } from 'moment-range';
// import ViewAllRequests from './ViewAllRequests';
// import ViewAppData from './ViewAppData';
// import { CSVLink } from "react-csv";

// function LocationData() {
//     const navigate = useNavigate();
//     const moment = extendMoment(Moment);
//     const [data, setData] = useState([]);
//     const [dataU, setDataU] = useState([]);
//     const [csvdata, setCsvdata] = useState([]);
//     const [searchQuery, setSearchQuery] = useState("");

//     useEffect(() => {
//         const storedUsername = localStorage.getItem("email");
//         const storedPassword = localStorage.getItem("password");
//         const storedRemember = localStorage.getItem("remember");

//         if (storedRemember === "false" || !storedUsername || !storedPassword) {
//             navigate('/signin');
//         }
//     }, []);

//     // const headers = [
//     //     { label: "Username", key: "username" },
//     //     { label: "Postal Code", key: "postal_code" },
//     //     { label: "Locality", key: "locality" },
//     //     { label: "Country", key: "country" },
//     //     { label: "State", key: "state" },
//     //     { label: "City", key: "city" },
//     //     { label: "Phone", key: "phone" },
//     //     { label: "Full Address", key: "full_address" },
//     //     { label: "Created Date", key: "created_date", dataFormat: "MM/DD/YY, h:mm a" },
//     // ];
//     const headers = [
//         "Username",
//         "Postal Code",
//         "Locality",
//         "Country",
//         "State",
//         "City",
//         "Phone",
//         "Full Address",
//         "Created Date",
//         // Add more column labels as needed
//     ];

//     useEffect(() => {
//         const usersData = [];
//         const parlourData = [];

//         // Fetch data from "users_location" collection
//         db.collection("users_location").orderBy("created_date", "desc").onSnapshot((snapshot) => {
//             usersData.length = 0; // Clear the array
//             snapshot.docs.forEach((doc) => {
//                 usersData.push({
//                     id: doc.id,
//                     data: doc.data(),
//                 });
//             });
//             mergeData();
//         });

//         // Fetch data from "parlour_name" collection
//         db.collection("parlour_name").onSnapshot((snapshot) => {
//             parlourData.length = 0; // Clear the array
//             snapshot.docs.forEach((doc) => {
//                 parlourData.push({
//                     id: doc.id,
//                     data: doc.data(),
//                 });
//             });
//             mergeData();
//         });

//         // Function to merge data when both sets are available
//         function mergeData() {
//             if (usersData.length > 0 && parlourData.length > 0) {
//                 // Merge the data based on the username
//                 const mergedData = usersData.map((user) => {
//                     const parlour = parlourData.find((p) => p.data.username === user.data.username);
//                     return {
//                         ...user,
//                         parlour: parlour ? parlour.data : null,
//                     };
//                 });

//                 // Now, mergedData contains the merged information
//                 setData(mergedData);
//             }
//         }
//     }, []);

//     const handleSearchChange = (event) => {
//         setSearchQuery(event.target.value);
//     };


//     // Function to filter data based on the search query
//     const filteredData = data.filter((item) => {
//         const username = item.data.username.toLowerCase();
//         const city = item.parlour ? (item.parlour.city || "").toLowerCase() : "";
//         const state = item.parlour ? (item.parlour.state || "").toLowerCase() : "";
//         const phone = item.parlour ? item.parlour.phone.toLowerCase() : "";
//         const query = searchQuery.toLowerCase();

//         return (
//             username.includes(query) ||
//             city.includes(query) ||
//             state.includes(query) ||
//             phone.includes(query)
//         );
//     });

//     // Function to convert data to CSV format
//     const prepareCSVData = () => {
//         const csvData = filteredData.map((item, index) => ([
//             item.data.username,
//             item.data.postal_code,
//             item.data.locality,
//             item.data.country,
//             item.parlour ? item.parlour.state : '',
//             item.parlour ? item.parlour.city : '',
//             item.parlour ? item.parlour.phone : '',
//             item.data.full_address,
//             moment(item.data.created_date.toDate().toISOString()).format("MM/DD/YY, h:mm a"),
//             // Add more columns as needed
//         ]));

//         return csvData;
//     };





//     return (
//         <>
//             <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg">
//                 <div class="p-2" style={{ paddingBottom: "5px", display: "flex", justifyContent: "space-between" }}>
//                     <Link to="/admin_panel">
//                         <img src="/images/menubutton.png" class="menu-toggle" alt="Responsive image" style={{ height: "2rem" }} />
//                     </Link>
//                     <CSVLink data={prepareCSVData()} headers={headers} filename={"users_data.csv"}>
//                         <span class="btn bg-gradient-secondary btn-sm mb-0 mr-2" style={{ color: "white" }}>
//                             &nbsp; Download Excel
//                         </span>
//                     </CSVLink>
//                 </div>
//                 <div class="">

//                     <div class="p-2">

//                         <div class="card">
//                             <div class="card-header pb-0 px-3">
//                                 <h6 class="mb-2">User's Location Data || {`Records : ${filteredData.length}`}</h6>
//                             </div>
//                             <input
//                                 type="text"
//                                 className="form-control form-control-sm"
//                                 placeholder="Search by Username, City, State, or Phone Number"
//                                 value={searchQuery}
//                                 onChange={handleSearchChange}
//                             />
//                             <div class="card">
//                                 <div class="table-responsive fix-width scroll-inner">
//                                     <table class="table table-bordered table-hover">
//                                         <thead class="thead-dark" style={{ position: "sticky", top: "0" }}>
//                                             <tr style={{ textAlign: "left" }}>
//                                                 <th class="ps-2">S.No</th>
//                                                 <th class="ps-2">Username</th>
//                                                 <th class="">Postal Code</th>
//                                                 <th class="">Locality</th>
//                                                 <th class="ps-2">Country</th>
//                                                 <th class="ps-2">State</th>
//                                                 <th class="ps-2">City</th>
//                                                 <th class="ps-2">Phone</th>
//                                                 <th class="ps-2">Full Address</th>
//                                                 <th class="ps-2">Created Date</th>
//                                             </tr>
//                                         </thead>
//                                         <tbody style={{ textAlign: "left" }}>
//                                             {filteredData.map((item, index) => (
//                                                 <tr key={item.id}>
//                                                     <td>
//                                                         <p class="text-xs mb-0">{index + 1}.</p>
//                                                     </td>
//                                                     <td>
//                                                         <p class="text-xs mb-0">{item.data.username}</p>
//                                                     </td>
//                                                     <td>
//                                                         <p class="text-xs mb-0">{item.data.postal_code}</p>
//                                                     </td>
//                                                     <td>
//                                                         <p class="text-xs mb-0">{item.data.locality}</p>
//                                                     </td>
//                                                     <td>
//                                                         <p class="text-xs mb-0">{item.data.country}</p>
//                                                     </td>

//                                                     <td>
//                                                         <p class="text-xs mb-0">{item.parlour ? item.parlour.state : ''}</p>
//                                                     </td>

//                                                     <td>
//                                                         <p class="text-xs mb-0">{item.parlour ? item.parlour.city : ''}</p>
//                                                     </td>
//                                                     <td>
//                                                         <p class="text-xs mb-0">{item.parlour ? item.parlour.phone : ''}</p>
//                                                     </td>
//                                                     <td style={{ whiteSpace: 'pre-wrap' }}>
//                                                         <p class="text-xs font-weight-bold mb-0" style={{ width: "15rem" }}>{item.data.full_address}</p>

//                                                     </td>


//                                                     <td>
//                                                         <div class="d-flex px-2 py-1">
//                                                             <div class="d-flex flex-column justify-content-center">
//                                                                 <h6 class="mb-0 text-xs">{moment(item.data.created_date.toDate().toISOString()).format("MM/DD/YY, h:mm a")}</h6>
//                                                             </div>
//                                                         </div>
//                                                     </td>

//                                                     {/* Other table cells */}
//                                                 </tr>
//                                             ))}
//                                         </tbody>
//                                     </table>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </main>
//         </>
//     );
// }

// export default LocationData;
