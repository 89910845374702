import React, { useState, useEffect } from 'react';
import { useNavigate, Link, useParams } from 'react-router-dom';
import db from "./Firebase";
import firebaseApp from "./Firebase";
import { arrayUnion } from "firebase/firestore";
import { storage } from './Firebase';
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import ViewAllRequests from './ViewAllRequests';
import ViewAppData from './ViewAppData';
import { CSVLink } from "react-csv";

function WebsiteMessage() {
    const moment = extendMoment(Moment);
    const [data, setData] = useState([]);

    useEffect(() => {
        db.collection("website_message").orderBy("created_date", "desc").onSnapshot((snapshot) => {

            setData(
                snapshot.docs.map((doc) => ({
                    id: doc.id,
                    data: doc.data(),
                }))
            );

        });

    }, []);

    return (
        <>
            {/* <ViewAppData /> */}
            <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
                <div class="p-2" style={{ textAlign: "start", paddingBottom: "5px" }}>
                    {/* <span class="btn bg-gradient-secondary btn-sm mb-0 mr-2" style={{ color: "white" }} >&nbsp; Download Excel</span> */}
                    <Link to="/admin_panel"><img src="/images/menubutton.png" class="menu-toggle" alt="Responsive image" style={{ height: "2rem" }} /></Link>

                </div>
                <div class="">

                    <div class="p-2">

                        <div class="card">
                            <div class="card-header pb-0 px-3">
                                <h6 class="mb-2">Website Message</h6>
                            </div>
                            <div class="">
                                <div class="card">
                                    <div class="table-responsive fix-width scroll-inner">
                                        <table class="table table-bordered table-hover">
                                            <thead class="thead-dark" style={{ position: "sticky", top: "0" }}>
                                                <tr style={{ textAlign: "left" }}>
                                                    <th class="col ps-2">Name</th>
                                                    <th class="col">Created Date</th>
                                                    <th class="col ps-2">Email</th>
                                                    <th class="col ps-2">Message</th>
                                                </tr>
                                            </thead>
                                            <tbody style={{ textAlign: "left" }}>
                                                {data?.map(({ id, data }, index) => (
                                                    <>
                                                        <tr>

                                                            <td>
                                                                <p class="text-xs mb-0">{data.name}</p>
                                                                {/* <p class="text-xs text-secondary mb-0">{moment(data.created_date.toDate().toISOString()).format("MM/DD/YY, h:mm a")}</p> */}
                                                            </td>

                                                            <td>
                                                                <p class="text-xs mb-0">{moment(data.created_date.toDate().toISOString()).format("MM/DD/YY, h:mm a")}</p>
                                                                {/* <p class="text-xs text-secondary mb-0">{moment(data.created_date.toDate().toISOString()).format("MM/DD/YY, h:mm a")}</p> */}
                                                            </td>

                                                            <td>
                                                                <p class="text-xs mb-0">{data.email}</p>
                                                                {/* <p class="text-xs text-secondary mb-0">{moment(data.created_date.toDate().toISOString()).format("MM/DD/YY, h:mm a")}</p> */}
                                                            </td>

                                                            <td style={{ whiteSpace: 'pre-wrap' }}>
                                                                <p class="text-xs font-weight-bold mb-0" style={{ width: "35rem" }}>{data.message}</p>

                                                            </td>
                                                        </tr>

                                                    </>
                                                ))}


                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </main>
        </>
    )
}

export default WebsiteMessage;