import React, { useState, useEffect } from 'react';
import { useNavigate, Link, useParams } from 'react-router-dom';
import db from "./Firebase";

function UserBasedRole() {
    const navigate = useNavigate();
    const [userData, setUserData] = useState([]);

    useEffect(() => {
        const storedUsername = localStorage.getItem("email");


        // db.collection("user_based_role").where('email', '==', storedUsername).onSnapshot((snapshot) => {
        //     const userRoles = snapshot.docs.map((doc) => doc.data().role);
        //     console.log(userRoles[0]);

        //     if (userRoles[0].includes("admin") && userRoles[0].includes("employee")) {
        //         console.log("You have two rights admin and employee")
        //         navigate('/admin_panel');
        //     } else if (userRoles[0].includes("employee")) {
        //         console.log("You have one right")
        //         navigate('/');
        //     }
        //     else if (userRoles[0].includes("admin")) {
        //         console.log("You have one right only admin")
        //     }
        //     else {
        //         console.log("You have no specific rights")
        //     }
        // });
    }, []);

    return (

        <>
            <div style={{ height: "20rem", backgroundColor: "black" }}>
                <div class="" style={{ display: "flex", justifyContent: "center" }}> <h1 style={{ color: "red" }} class="">Access Denied</h1></div>
                <div class="" style={{ display: "flex", justifyContent: "center" }}> <h3 class="" style={{ color: "white" }}>You dont have permission to view this site.</h3></div>
                <div class="" style={{ display: "flex", justifyContent: "center" }}> <h3 class="" style={{ color: "white" }}>🚫🚫🚫🚫</h3></div>
                <div class="" style={{ display: "flex", justifyContent: "center" }}><h6 style={{ color: "red", textDecoration: "underline" }} class="w3-center w3-animate-zoom">error code:403 forbidden</h6></div>

            </div>
        </>

        // <div>
        //     {userData.map((user) => (
        //         <div key={user.id}>
        //             <h1>Welcome, {user.data.username}</h1>
        //             <p>{getUserRightsMessage(user.data.role)}</p>
        //         </div>
        //     ))}
        // </div>

    );
}

export default UserBasedRole;
