import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import "./style.css";
import { useNavigate, Link, useParams } from "react-router-dom"
import Select from 'react-select';
import NumberCounter from 'number-counter';
import FooterMain from "./FooterMain";

export const Jwellery = () => {
    const [count, setCount] = useState(0);
    useEffect(() => {
        window.scrollTo(0, 0);
        for (let count = 0; count <= 500; count++) {
            setTimeout(() => {
                setCount(count)
            }, 1000);
        }
    }, []);

    const [selectedImage, setSelectedImage] = useState(null);


    const tipsImages = [
        { title: "", subtitle: "Kundan", image_url: "/jewellry/Rectangle 184.png", description: "Crafted by Rajasthani artisans, Kundan jewelry is marked by a meeting of flawless finish and intricate design. It utilizes glass imitations set in stones with gold or silver tones." },
        { title: "", subtitle: "Mughal", image_url: "/jewellry/Rectangle 181.png", description: "This jewelry style has been boosted back into prominence thanks to Bollywood and its influence on Indian pop culture. Mughal jewelry has also been experimented with by various designers. This style sets itself apart by using chunky gemstones (mostly navratna stones) and ethnic enameled motifs." },
        { title: "", subtitle: "Polki", image_url: "/jewellry/Rectangle 180.png", description: "Crafted from uncut diamonds, Polki jewelry comes with a lower price tag than cut-diamond jewelry and displays immense craftsmanship in its intricacies." },
        { title: "", subtitle: "Pachchikam", image_url: "/jewellry/Rectangle 182.png", description: "Originally from Kutch and Gujarat, Pachchikam jewelry lays precious and semi-precious stones into a silver base. The crude artwork on this style of jewelry is often hailed for its unique and superior finish." },
        { title: "", subtitle: "Temple", image_url: "/jewellry/Rectangle 183.png", description: "The name Temple jewelry originates from having been used to deck the idols of gods and goddesses in temples. From temples, this style of jewelry found its way into Indian classical dance forms and much later, into bridal jewelry. It mainly consists of chunky jewelry set in ornate patterns with sacred pendants of Hindu deities." },

    ]
    const handleImageClick = (index) => {
        setSelectedImage(index);
    };


    const leftPartStyle = {
        flex: 1,
        border: "none",
        boxSizing: "border-box",
        // backgroundColor: "#7d6853",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    };

    return (
        <div>
            <section>
                <div class="navi" style={{ backgroundColor: "#ec5d69" }}>
                    <input type="checkbox" id="nav-check" />
                    <div class="nav-header">
                        <div class="nav-title">
                            <Link to="/"> <img src="/bridal/youvati_white_logo.png" alt="" style={{ height: "22px", marginLeft: "1rem" }} /></Link>
                        </div>
                    </div>
                    <div class="nav-btn">
                        <label for="nav-check">
                            <span></span>
                            <span></span>
                            <span></span>
                        </label>
                    </div>

                    <div class="nav-links" id="top">
                        <a href="#portfolio" class="linkcolor" style={{ color: "white" }}>Bridal</a>
                        <span class="line" style={{ color: "white" }}>|</span>
                        <a href="#portfolio" class="linkcolor" style={{ color: "white" }}>Why Choose Us</a>
                        <span class="line" style={{ color: "white" }}>|</span>
                        <a href="#partners" class="linkcolor" style={{ color: "white" }}>For Partners</a>

                        <span class="line" style={{ color: "white" }}>|</span>
                        <a href="#reachtous" class="linkcolor" style={{ color: "white" }}>Contact Us</a>

                    </div>
                </div>
            </section>


            {/* <div class="" style={{ marginLeft: "120px", marginRight: "120px" }}> */}
            {/* <div style={{ textAlign: "center" }}>
                <h2 style={{ fontSize: "34px", fontWeight: "700", paddingLeft: "70px", marginTop: "1.5rem", color: "#DE5A59", fontFamily: "serif" }}>Jewellery</h2>
            </div> */}

            <div style={{ textAlign: "center" }}>
                <h2 style={{ fontSize: "34px", fontWeight: "700", marginTop: "1.5rem", color: "#DE5A59", fontFamily: "serif" }}>Jewellery</h2>
            </div>
            <div class="" style={{ padding: "13px", display: "flex", justifyContent: "center" }}>
                <p style={{ textAlign: "center", fontSize: "20px", fontWeight: "400", color: "black", marginTop: "1rem", fontFamily: "serif", maxWidth: "55rem", minWidth: "15rem" }}>There may be ten different things that set your wedding outfit apart from the rest, but it is definitely the jewelry that adds a touch of elegance to your look. Considering the investment that goes into selecting such elegant adornments, it is important to make informed decisions on the quality and style of the jewelry you choose to wear on your big day.</p>
            </div>



            <div class="" style={{ padding: "13px", display: "flex", justifyContent: "center" }}>

                <img src="/jewellry/Group 79.png" class="img-fluid" style={{ maxWidth: "55rem", minWidth: "15rem" }} alt="bridal image"></img>

            </div>

            <div style={{ textAlign: "center" }}>
                <h2 style={{ fontSize: "34px", fontWeight: "700", marginTop: "1.5rem", color: "black", fontFamily: "serif" }}>Different styles of bridal jewelry are as follows</h2>
            </div>

            {/* <div style={{ textAlign: "center" }}>
                <h2 style={{ fontSize: "34px", fontWeight: "700", marginTop: "3rem", color: "black", fontFamily: "serif" }}>Different styles of bridal jewelry are as follows</h2>
            </div> */}

            {tipsImages.map((tip, index) => (
                <div key={index} style={{ marginBottom: "2rem", textAlign: "center" }}>
                    <div style={{ textAlign: "center" }}>
                        <h2 style={{ fontSize: "34px", fontWeight: "700", color: "black", fontFamily: "serif" }}>{tip.title}</h2>
                        {/* <div style={{ fontSize: "34px", fontWeight: "700", color: "#DE5A59", fontFamily: "serif", display: "flex", justifyContent: "center" }}><h2 style={{ fontSize: "34px", fontWeight: "700", color: "#DE5A59", fontFamily: "serif", width: "50%", textAlign: "center", borderBottom: "3.5px solid rgb(222, 90, 89)", lineHeight: "0.1em", margin: "25px 7px 40px" }}><span style={{ background: "#fff", padding: "0 10px" }}>{tip.subtitle}</span></h2></div> */}
                        <div style={{ display: "flex", justifyContent: "center" }}><span class="subs" style={{ fontSize: "34px", fontWeight: "700", color: "#DE5A59", }}>{tip.subtitle}</span></div>
                        {/* <div style={{ fontSize: "34px", fontWeight: "700", color: "#DE5A59", fontFamily: "serif", display: "flex", justifyContent: "center" }}><h2 style={{ width: "100%", textAlign: "center", borderBottom: "1px solid #000", lineHeight: "0.1em", margin: "25px 7px 40px" }}><span style={{ background: "#fff", padding: "0 10px" }}>{tip.subtitle}</span></h2></div> */}
                        {/* <div class="about-border" style={{ width: "200px", height: "3px", background: "#DE5A59", margin: "20px auto" }}></div> */}
                    </div>

                    <div class="" style={{ padding: "13px" }}>
                        <img style={{ borderRadius: "8px", width: "100%", height: "348px", objectFit: "contain" }}
                            class=""
                            src={tip.image_url}
                        />
                    </div>

                    <div class="" style={{ padding: "13px", display: "flex", justifyContent: "center" }}>
                        <p style={{ textAlign: "center", fontSize: "20px", fontWeight: "400", color: "black", fontFamily: "serif", maxWidth: "55rem", minWidth: "15rem" }}>{tip.description}</p>
                    </div>


                </div>
            ))}
            {/* </div> */}



            <div style={{ height: "10rem" }}></div>

            <FooterMain />

        </div>
    );
};



