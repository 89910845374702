import React, { useState, useEffect } from 'react';
import { useNavigate, Link, useParams } from 'react-router-dom';
import ViewAllRequests from './ViewAllRequests';
import ViewAppData from './ViewAppData';
import db from "./Firebase";
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import BeautyKitRequest from "./BeautyKitRequest";
import BookDemoRequest from "./BookDemoRequest";
import BusinessCardRequest from "./BusinessCardRequest";
import CourseData from "./CourseData";
import CourseDataHindi from "./CourseDataHindi";
import FreeBusinessCards from "./FreeBusinessCards";
import HireRequest from "./HierRequest";
import LoanRequest from "./LoanRequest";
import PortfolioData from "./PortfolioData";
import PremiumCardRequest from "./PremiumCardRequest";
import PremiumCards from "./PremiumCards";
import PromotionalData from "./PromotionalData";
import RequestCallBack from "./RequestCallBack";
import UsersProfile from "./UsersProfile";
import Workshop from "./Workshop";
import WorkshopData from "./WorkshopData";
import WorkshopDataHindi from "./WorkshopDataHindi";
import WorkshopRegistration from "./WorkshopRegistration";
// import Analytics from './Analytics';
import CustomerData from './CustomerData';
import DeletePortfolioRequest from './DeletePortfolioRequest';
import ReferredCourses from './ReferredCourses';
import PortfolioAnalyser from './PortfolioAnalyser';
import PortfolioReviews from './PortfolioReviews';
import Appointments from './Appointments';

function Analytics() {
    const navigate = useNavigate();
    const moment = extendMoment(Moment);
    const [activeIndex, setActiveIndex] = useState(null);
    const [showAppData, setShowAppData] = useState(false);
    const [showRequestData, setShowRequestData] = useState(false);
    const [showCreateData, setShowCreateData] = useState(false);
    const [view, setView] = useState(false);
    const [activeTabIndex, setActiveTabIndex] = useState(0);
    const tabData = [
        { label: 'View App Data', content: 'Content 1' },
        { label: 'View All Requests', content: 'Content 2' },
    ];
    const handleTabClick = (index) => {
        setActiveTabIndex(index);
        console.log(index)
    };

    useEffect(() => {
        const storedEmail = localStorage.getItem("email");
        const storedPassword = localStorage.getItem("password");
        const storedRemember = localStorage.getItem("remember");

        if (storedRemember === "false" || !storedEmail || !storedPassword) {
            navigate('/signin');
        }

        db.collection("user_based_role").where("email", "==", storedEmail).where("status", "==", "0").onSnapshot((snapshot) => {
            if (!snapshot.empty) {
                navigate('/signin');

            }
        });
    }, []);

    const [data, setData] = useState([]);
    const [rev, setRev] = useState(0);
    const [count, setCount] = useState([]);
    const [showHide, setShowHide] = useState(true);

    const [udata, setUdata] = useState([]);

    const webData = [50, 20, 10, 22, 50, 10, 40];

    const chartsOne = () => {
        // window.websiteView();
        window.charts();
    }



    const chrtFun = () => {

    }

    const Jstart = new Date('2024-01-01T00:00:00.000z');
    const Jend = new Date('2024-01-31T23:59:59.000z');

    const Astart = new Date('2024-02-01T00:00:00.000z');
    const Aend = new Date('2024-02-28T23:59:59.000z');

    const Sstart = new Date('2024-03-18T00:00:00.000z');
    const Send = new Date('2024-03-31T23:59:59.000z');

    const Ostart = new Date('2024-04-01T00:00:00.000z');
    const Oend = new Date('2024-04-30T23:59:59.000z');

    const Nstart = new Date('2024-05-01T00:00:00.000z');
    const Nend = new Date('2024-05-31T23:59:59.000z');

    const Dstart = new Date('2024-06-01T00:00:00.000z');
    const Dend = new Date('2024-06-30T23:59:59.000z');

    const Bstart = new Date('2024-07-01T00:00:00.000z');
    const Bend = new Date('2024-07-31T23:59:59.000z');

    var [appDownload, setAppdownload] = useState([]);
    var [portCreated, setPortCreated] = useState([]);
    // var [count, setCount] = useState(0);

    const [idMapping, setIdMapping] = useState([]);


    const [courseEnrollment, setCourseEnrollment] = useState([]);
    const [workshopCompleted, setWorkshopCompleted] = useState([]);


    const downloadMonthely = () => {
        const today = new Date();
        today.setHours(0);
        today.setMinutes(0);
        today.setMilliseconds(0);
        today.setSeconds(0);

        var date = new Date();
        var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        console.log(firstDay);
        console.log(lastDay);
        db.collection("personal_details").where('created_date', '>=', firstDay).where('created_date', '<=', lastDay).onSnapshot((snapshot) => {
            setUdata(
                snapshot.docs.map((doc) => ({
                    id: doc.id,
                    data: doc.data(),
                }))
            );
        });
    }

    const downloadPreMonthly = () => {
        // const today = new Date();
        // today.setHours(0);
        // today.setMinutes(0);
        // today.setMilliseconds(0);
        // today.setSeconds(0);


        const date = new Date();
        // Get the first day of the previous month
        const firstDay = new Date(Date.UTC(date.getFullYear(), date.getMonth() - 1, 1));
        // Get the last day of the previous month
        const lastDay = new Date(Date.UTC(date.getFullYear(), date.getMonth(), 0));


        // Get the first day of the previous month
        // const firstDay = new Date(date.getFullYear(), date.getMonth() - 1, 1);
        // Get the last day of the previous month
        // const lastDay = new Date(date.getFullYear(), date.getMonth(), 0);
        console.log(firstDay);
        console.log(lastDay);
        db.collection("personal_details")
            .where('created_date', '>=', firstDay)
            .where('created_date', '<=', lastDay)
            .onSnapshot((snapshot) => {
                setUdata(
                    snapshot.docs.map((doc) => ({
                        id: doc.id,
                        data: doc.data(),
                    }))
                );
            });
    };


    const downloadDaily = () => {
        const today = new Date();
        today.setHours(0, 0, 0, 0); // Set time to midnight for the beginning of the day

        db.collection("personal_details")
            .where('created_date', '>=', today)
            .where('created_date', '<', new Date(today.getTime() + 24 * 60 * 60 * 1000)) // Add 24 hours to get the end of the day
            .onSnapshot((snapshot) => {
                setUdata(
                    snapshot.docs.map((doc) => ({
                        id: doc.id,
                        data: doc.data(),
                    }))
                );
            });
    }



    const downloadWeekly = () => {
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        const currentDay = today.getDay(); // 0 (Sunday) to 6 (Saturday)
        const startOfWeek = new Date(today); // Make a copy of today
        startOfWeek.setDate(today.getDate() - currentDay); // Move back to the start of the current week (Sunday)
        const endOfWeek = new Date(startOfWeek);
        endOfWeek.setDate(startOfWeek.getDate() + 6); // Move to the end of the current week (Saturday)

        // Assuming Firestore 'created_date' field is a Timestamp
        console.log(startOfWeek);
        console.log(endOfWeek);
        db.collection("personal_details")
            .where('created_date', '>=', startOfWeek)
            .where('created_date', '<=', endOfWeek)
            .onSnapshot((snapshot) => {
                setUdata(
                    snapshot.docs.map((doc) => ({
                        id: doc.id,
                        data: doc.data(),
                    }))
                );
            });
    }


    const portoMonthely = () => {
        const today = new Date();
        today.setHours(0);
        today.setMinutes(0);
        today.setMilliseconds(0);
        today.setSeconds(0);

        var date = new Date();
        var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        console.log(firstDay);
        console.log(lastDay);
        db.collection("portfolio_details").where('created_date', '>=', firstDay).where('created_date', '<=', lastDay).onSnapshot((snapshot) => {
            setPorto(
                snapshot.docs.map((doc) => ({
                    id: doc.id,
                    data: doc.data(),
                }))
            );
        });
    }

    const portoDaily = () => {
        const today = new Date();
        today.setHours(0, 0, 0, 0); // Set time to midnight for the beginning of the day

        db.collection("portfolio_details")
            .where('created_date', '>=', today)
            .where('created_date', '<', new Date(today.getTime() + 24 * 60 * 60 * 1000)) // Add 24 hours to get the end of the day
            .onSnapshot((snapshot) => {
                setPorto(
                    snapshot.docs.map((doc) => ({
                        id: doc.id,
                        data: doc.data(),
                    }))
                );
            });
    }


    const portoWeekly = () => {
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        const currentDay = today.getDay(); // 0 (Sunday) to 6 (Saturday)
        const startOfWeek = new Date(today); // Make a copy of today
        startOfWeek.setDate(today.getDate() - currentDay); // Move back to the start of the current week (Sunday)
        const endOfWeek = new Date(startOfWeek);
        endOfWeek.setDate(startOfWeek.getDate() + 6); // Move to the end of the current week (Saturday)

        // Assuming Firestore 'created_date' field is a Timestamp
        console.log(startOfWeek);
        console.log(endOfWeek);
        db.collection("portfolio_details")
            .where('created_date', '>=', startOfWeek)
            .where('created_date', '<=', endOfWeek)
            .onSnapshot((snapshot) => {
                setPorto(
                    snapshot.docs.map((doc) => ({
                        id: doc.id,
                        data: doc.data(),
                    }))
                );
            });
    }

    const loanMonthely = () => {
        // const today = new Date();
        // today.setHours(0);
        // today.setMinutes(0);
        // today.setMilliseconds(0);
        // today.setSeconds(0);

        // var date = new Date();
        // var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        // var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        // console.log(firstDay);
        // console.log(lastDay);
        // db.collection("loan").where('created_date', '>=', firstDay).where('created_date', '<=', lastDay).onSnapshot((snapshot) => {
        //     setLoan(
        //         snapshot.docs.map((doc) => ({
        //             id: doc.id,
        //             data: doc.data(),
        //         }))
        //     );
        // });
    }

    const loanWeekly = () => {
        // const today = new Date();
        // today.setHours(0, 0, 0, 0);

        // const currentDay = today.getDay(); // 0 (Sunday) to 6 (Saturday)
        // const startOfWeek = new Date(today); // Make a copy of today
        // startOfWeek.setDate(today.getDate() - currentDay); // Move back to the start of the current week (Sunday)
        // const endOfWeek = new Date(startOfWeek);
        // endOfWeek.setDate(startOfWeek.getDate() + 6); // Move to the end of the current week (Saturday)

        // // Assuming Firestore 'created_date' field is a Timestamp
        // console.log(startOfWeek);
        // console.log(endOfWeek);
        // db.collection("loan")
        //     .where('created_date', '>=', startOfWeek)
        //     .where('created_date', '<=', endOfWeek)
        //     .onSnapshot((snapshot) => {
        //         setLoan(
        //             snapshot.docs.map((doc) => ({
        //                 id: doc.id,
        //                 data: doc.data(),
        //             }))
        //         );
        //     });
    }

    const loanDisMonthely = () => {
        // const today = new Date();
        // today.setHours(0);
        // today.setMinutes(0);
        // today.setMilliseconds(0);
        // today.setSeconds(0);

        // var date = new Date();
        // var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        // var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        // console.log(firstDay);
        // console.log(lastDay);

        // db.collection("loan")
        //     .where('status', '==', 1)
        //     .where('created_date', '>=', firstDay)
        //     .where('created_date', '<=', lastDay) // Add your third "where" condition here
        //     .onSnapshot((snapshot) => {
        //         setDisbCount(
        //             snapshot.docs.map((doc) => ({
        //                 id: doc.id,
        //                 data: doc.data(),
        //             }, console.log(doc.data().created_date)))
        //         );

        //     });
    }


    const downloadQuaterly = () => {
        const today = new Date();
        const quarter = Math.floor((today.getMonth() / 3));
        // const startFullQuarter = new Date(today.getFullYear(), quarter * 6, 1);
        // const endFullQuarter = new Date(startFullQuarter.getFullYear(), startFullQuarter.getMonth() + 6, 0);
        // const startFullQuarter = new Date(today.getFullYear(), quarter * 6, 1);
        // const endFullQuarter = new Date(startFullQuarter.getFullYear(), startFullQuarter.getMonth() + 6, 0);
        const startFullQuarter = new Date(today.getFullYear(), quarter * 3, 1);
        const endFullQuarter = new Date(startFullQuarter.getFullYear(), startFullQuarter.getMonth() + 3, 0);


        console.log(startFullQuarter);
        console.log(endFullQuarter);
        db.collection("personal_details").where('created_date', '>=', startFullQuarter).where('created_date', '<=', endFullQuarter).onSnapshot((snapshot) => {
            setUdata(
                snapshot.docs.map((doc) => ({
                    id: doc.id,
                    data: doc.data(),
                }))
            );
        });
    }


    const portoQuaterly = () => {
        const today = new Date();
        const quarter = Math.floor((today.getMonth() / 3));
        // const startFullQuarter = new Date(today.getFullYear(), quarter * 6, 1);
        // const endFullQuarter = new Date(startFullQuarter.getFullYear(), startFullQuarter.getMonth() + 6, 0);
        // const startFullQuarter = new Date(today.getFullYear(), quarter * 6, 1);
        // const endFullQuarter = new Date(startFullQuarter.getFullYear(), startFullQuarter.getMonth() + 6, 0);
        const startFullQuarter = new Date(today.getFullYear(), quarter * 3, 1);
        const endFullQuarter = new Date(startFullQuarter.getFullYear(), startFullQuarter.getMonth() + 3, 0);


        console.log(startFullQuarter);
        console.log(endFullQuarter);
        db.collection("portfolio_details").where('created_date', '>=', startFullQuarter).where('created_date', '<=', endFullQuarter).onSnapshot((snapshot) => {
            setPorto(
                snapshot.docs.map((doc) => ({
                    id: doc.id,
                    data: doc.data(),
                }))
            );
        });
    }

    const loanQuaterly = () => {
        // const today = new Date();
        // const quarter = Math.floor((today.getMonth() / 3));
        // // const startFullQuarter = new Date(today.getFullYear(), quarter * 6, 1);
        // // const endFullQuarter = new Date(startFullQuarter.getFullYear(), startFullQuarter.getMonth() + 6, 0);
        // // const startFullQuarter = new Date(today.getFullYear(), quarter * 6, 1);
        // // const endFullQuarter = new Date(startFullQuarter.getFullYear(), startFullQuarter.getMonth() + 6, 0);
        // const startFullQuarter = new Date(today.getFullYear(), quarter * 3, 1);
        // const endFullQuarter = new Date(startFullQuarter.getFullYear(), startFullQuarter.getMonth() + 3, 0);


        // console.log(startFullQuarter);
        // console.log(endFullQuarter);
        // db.collection("loan").where('created_date', '>=', startFullQuarter).where('created_date', '<=', endFullQuarter).onSnapshot((snapshot) => {
        //     setLoan(
        //         snapshot.docs.map((doc) => ({
        //             id: doc.id,
        //             data: doc.data(),
        //         }))
        //     );
        // });
    }

    const loanDisQuaterly = () => {
        // const today = new Date();
        // const quarter = Math.floor((today.getMonth() / 3));
        // // const startFullQuarter = new Date(today.getFullYear(), quarter * 6, 1);
        // // const endFullQuarter = new Date(startFullQuarter.getFullYear(), startFullQuarter.getMonth() + 6, 0);
        // // const startFullQuarter = new Date(today.getFullYear(), quarter * 6, 1);
        // // const endFullQuarter = new Date(startFullQuarter.getFullYear(), startFullQuarter.getMonth() + 6, 0);
        // const startFullQuarter = new Date(today.getFullYear(), quarter * 3, 1);
        // const endFullQuarter = new Date(startFullQuarter.getFullYear(), startFullQuarter.getMonth() + 3, 0);


        // console.log(startFullQuarter);
        // console.log(endFullQuarter);
        // db.collection("loan").where('created_date', '>=', startFullQuarter).where('created_date', '<=', endFullQuarter).where("status", "==", 1).onSnapshot((snapshot) => {
        //     setDisbCount(
        //         snapshot.docs.map((doc) => ({
        //             id: doc.id,
        //             data: doc.data(),
        //         }))
        //     );
        // });
    }


    const downloadYearly = () => {
        const currentYear = new Date().getFullYear();
        console.log(currentYear); // 👉️2022

        const firstDay = new Date(currentYear, 0, 1);
        console.log(firstDay); // 👉️ Sat Jan 01 2022

        const lastDay = new Date(currentYear, 11, 31);
        console.log(lastDay);

        db.collection("personal_details").where('created_date', '>=', firstDay).where('created_date', '<=', lastDay).onSnapshot((snapshot) => {
            setUdata(
                snapshot.docs.map((doc) => ({
                    id: doc.id,
                    data: doc.data(),
                }))
            );
        });

    }

    const downloadYearlyAll = () => {

        db.collection("personal_details").onSnapshot((snapshot) => {
            setUdata(
                snapshot.docs.map((doc) => ({
                    id: doc.id,
                    data: doc.data(),
                }))
            );
        });

    }

    const portoYearly = () => {
        const currentYear = new Date().getFullYear();
        console.log(currentYear); // 👉️2022

        const firstDay = new Date(currentYear, 0, 1);
        console.log(firstDay); // 👉️ Sat Jan 01 2022

        const lastDay = new Date(currentYear, 11, 31);
        console.log(lastDay);

        db.collection("portfolio_details").where('created_date', '>=', firstDay).where('created_date', '<=', lastDay).onSnapshot((snapshot) => {
            setPorto(
                snapshot.docs.map((doc) => ({
                    id: doc.id,
                    data: doc.data(),
                }))
            );
        });

    }


    const loanYearly = () => {
        // const currentYear = new Date().getFullYear();
        // console.log(currentYear); // 👉️2022

        // const firstDay = new Date(currentYear, 0, 1);
        // console.log(firstDay); // 👉️ Sat Jan 01 2022

        // const lastDay = new Date(currentYear, 11, 31);
        // console.log(lastDay);

        // db.collection("loan").where('created_date', '>=', firstDay).where('created_date', '<=', lastDay).onSnapshot((snapshot) => {
        //     setLoan(
        //         snapshot.docs.map((doc) => ({
        //             id: doc.id,
        //             data: doc.data(),
        //         }))
        //     );
        // });

    }

    const loanDisYearly = () => {
        // const currentYear = new Date().getFullYear();
        // console.log(currentYear); // 👉️2022

        // const firstDay = new Date(currentYear, 0, 1);
        // console.log(firstDay); // 👉️ Sat Jan 01 2022

        // const lastDay = new Date(currentYear, 11, 31);
        // console.log(lastDay);

        // db.collection("loan").where('created_date', '>=', firstDay).where('created_date', '<=', lastDay).where("status", "==", 1).onSnapshot((snapshot) => {
        //     setDisbCount(
        //         snapshot.docs.map((doc) => ({
        //             id: doc.id,
        //             data: doc.data(),
        //         }))
        //     );
        // });

    }

    const loanDisWeekly = () => {
        // const today = new Date();
        // today.setHours(0, 0, 0, 0);

        // const currentDay = today.getDay(); // 0 (Sunday) to 6 (Saturday)
        // const startOfWeek = new Date(today); // Make a copy of today
        // startOfWeek.setDate(today.getDate() - currentDay); // Move back to the start of the current week (Sunday)
        // const endOfWeek = new Date(startOfWeek);
        // endOfWeek.setDate(startOfWeek.getDate() + 6); // Move to the end of the current week (Saturday)

        // // Assuming Firestore 'created_date' field is a Timestamp
        // console.log(startOfWeek);
        // console.log(endOfWeek);
        // db.collection("loan")
        //     .where('created_date', '>=', startOfWeek)
        //     .where('created_date', '<=', endOfWeek)
        //     .where("status", "==", 1)
        //     .onSnapshot((snapshot) => {
        //         setDisbCount(
        //             snapshot.docs.map((doc) => ({
        //                 id: doc.id,
        //                 data: doc.data(),
        //             }))
        //         );
        //     });
    }


    const portoAll = () => {
        db.collection("portfolio_details").onSnapshot((snapshot) => {
            setPorto(
                snapshot.docs.map((doc) => ({
                    id: doc.id,
                    data: doc.data(),
                }))
            );
        });

    }

    const loanAll = () => {
        db.collection("loan").onSnapshot((snapshot) => {
            // setLoan(
            //     snapshot.docs.map((doc) => ({
            //         id: doc.id,
            //         data: doc.data(),
            //     }))
            // );
            setLoan(snapshot.docs.length)
        });

    }



    const loanDisAll = () => {
        db.collection("loan").where("status", "==", 1).onSnapshot((snapshot) => {
            // setDisbCount(
            //     snapshot.docs.map((doc) => ({
            //         id: doc.id,
            //         data: doc.data(),
            //     }))
            // );
            setLoan(snapshot.docs.length)
        });

    }


    const workshopMonthely = () => {
        const today = new Date();
        today.setHours(0);
        today.setMinutes(0);
        today.setMilliseconds(0);
        today.setSeconds(0);

        var date = new Date();
        var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        console.log(firstDay);
        console.log(lastDay);
        db.collection("workshop_event_data").where('created_date', '>=', firstDay).where('created_date', '<=', lastDay).onSnapshot((snapshot) => {
            setWorkshopCompleted(
                snapshot.docs.map((doc) => ({
                    id: doc.id,
                    data: doc.data(),
                }))
            );
        });
    }

    const workshopQuaterly = () => {
        const today = new Date();
        const quarter = Math.floor((today.getMonth() / 3));
        const startFullQuarter = new Date(today.getFullYear(), quarter * 3, 1);
        const endFullQuarter = new Date(startFullQuarter.getFullYear(), startFullQuarter.getMonth() + 3, 0);


        console.log(startFullQuarter);
        console.log(endFullQuarter);
        db.collection("workshop_event_data").where('created_date', '>=', startFullQuarter).where('created_date', '<=', endFullQuarter).onSnapshot((snapshot) => {
            setWorkshopCompleted(
                snapshot.docs.map((doc) => ({
                    id: doc.id,
                    data: doc.data(),
                }))
            );
        });
    }

    const workshopYearly = () => {
        const currentYear = new Date().getFullYear();
        console.log(currentYear); // 👉️2022

        const firstDay = new Date(currentYear, 0, 1);
        console.log(firstDay); // 👉️ Sat Jan 01 2022

        const lastDay = new Date(currentYear, 11, 31);
        console.log(lastDay);

        db.collection("workshop_event_data").where('created_date', '>=', firstDay).where('created_date', '<=', lastDay).where("status", "==", 1).onSnapshot((snapshot) => {
            setWorkshopCompleted(
                snapshot.docs.map((doc) => ({
                    id: doc.id,
                    data: doc.data(),
                }))
            );
        });

    }


    const workshopAll = () => {
        db.collection("workshop_event_data").onSnapshot((snapshot) => {
            setWorkshopCompleted(
                snapshot.docs.map((doc) => ({
                    id: doc.id,
                    data: doc.data(),
                }))
            );
        });

    }

    const open = () => {
        setView(true);
    }

    const changeStatus = () => {
        openModalShow();
    }

    const openModalShow = () => {
        // alert("ooo")
        window.openModelshow();
    }

    const closeModel = () => {
        window.openModelNew();
    }

    const handleChange4 = async (id) => {
        // Swal.fire(name)
        db.collection("all_notification").doc(id).update({ 'status': "1" }).then(() => {
            // console.log("user added");
        })
    }


    const [porto, setPorto] = useState([]);
    const [loan, setLoan] = useState(0);
    const [totalPorto, setTotalPorto] = useState(0);
    const [dicbCount, setDisbCount] = useState([]);
    const [disbAmount, setDisbAmount] = useState(0);
    const [repayAmt, setRepayAmt] = useState(0);
    const [cardLength, setCardLength] = useState(0);
    const [bannercardLength, setBannerCardLength] = useState(0);
    const [bannercardLengthPre, setBannerCardLengthPre] = useState([]);
    const [businessCard, setBusinessCard] = useState([])
    const [bannerCard, setBannerCard] = useState([])
    const [smgCard, setSmgCard] = useState([])
    const [smgCardPre, setSmgCardPre] = useState([])
    const [businessCardPre, setBusinessCardPre] = useState([])
    const [totalDataLength, setTotalDataLength] = useState(0);
    const [totalBannerLength, setTotalBannerLength] = useState(0);
    const [totalSmgLength, setTotalSmgLength] = useState(0);
    const [appointments, setAppointments] = useState([])
    const [reviewCount, setReviewCount] = useState([])
    const [whatsappBusiness, setWhatsappBusiness] = useState([])
    const [googleBusiness, setGoogleBusiness] = useState([])
    const [workshopRegistration, setWorkshopRegistration] = useState([])


    const [stateName, setStateName] = useState([])


    const [uniqueStatesAll, setuniqueStatesAll] = useState([])
    const [stateCountsAll, setstateCountsAll] = useState([])

    const [usersState, setUsersState] = useState([]);
    const [usersCity, setUsersCity] = useState([]);
    const [sortOrder, setSortOrder] = useState('');

    // useEffect(() => {
    //     const unsubscribe = db.collection('parlour_name')

    //         .onSnapshot((snapshot) => {
    //             const userCounts = {};

    //             snapshot.forEach((doc) => {
    //                 const { state, city } = doc.data();
    //                 const key = `${state}-${city}`;
    //                 userCounts[key] = (userCounts[key] || 0) + 1;
    //             });

    //             const data = Object.keys(userCounts).map((key) => {
    //                 const [state, city] = key.split('-');
    //                 const downloadCount = userCounts[key];
    //                 return { state, city, downloadCount };
    //             });

    //             // Sort the data based on the current sort order and column
    //             const sortedData = data.sort((a, b) => {
    //                 if (sortOrder === 'asc') {
    //                     return a.downloadCount - b.downloadCount;
    //                 } else {
    //                     return b.downloadCount - a.downloadCount;
    //                 }
    //             });

    //             setUsers(sortedData);
    //         });

    //     return () => unsubscribe();
    // }, [sortOrder]);

    // const handleSortClick = () => {
    //     // Toggle the sort order when the "Number of Downloads" heading is clicked
    //     setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    // };

    // useEffect(() => {
    //     const unsubscribe = db.collection('parlour_name').onSnapshot((snapshot) => {
    //         const cityCounts = {};

    //         snapshot.forEach((doc) => {
    //             const { city } = doc.data();
    //             cityCounts[city] = (cityCounts[city] || 0) + 1;
    //         });

    //         const data = Object.keys(cityCounts).map((city) => {
    //             const downloadCount = cityCounts[city];
    //             return { city, downloadCount };
    //         });

    //         // Sort the data based on the current sort order and column
    //         const sortedData = data.sort((a, b) => {
    //             if (sortOrder === 'asc') {
    //                 return a.downloadCount - b.downloadCount;
    //             } else {
    //                 return b.downloadCount - a.downloadCount;
    //             }
    //         });

    //         setUsersCity(sortedData);
    //     });

    //     return () => unsubscribe();
    // }, [sortOrder]);



    // useEffect(() => {
    //     const unsubscribe = db.collection('parlour_name').onSnapshot((snapshot) => {
    //         const stateCounts = {};

    //         snapshot.forEach((doc) => {
    //             const { state } = doc.data();
    //             stateCounts[state] = (stateCounts[state] || 0) + 1;
    //         });

    //         const data = Object.keys(stateCounts).map((state) => {
    //             const downloadCount = stateCounts[state];
    //             return { state, downloadCount };
    //         });

    //         // Sort the data based on the current sort order and column
    //         const sortedData = data.sort((a, b) => {
    //             if (sortOrder === 'asc') {
    //                 return a.downloadCount - b.downloadCount;
    //             } else {
    //                 return b.downloadCount - a.downloadCount;
    //             }
    //         });

    //         setUsers(sortedData);
    //     });

    //     return () => unsubscribe();
    // }, [sortOrder]);


    useEffect(() => {
        const unsubscribe = db.collection('parlour_name').onSnapshot((snapshot) => {
            const cityCounts = {};
            const stateCounts = {};

            snapshot.forEach((doc) => {
                const { state, city } = doc.data();

                // Count for city
                cityCounts[city] = (cityCounts[city] || 0) + 1;

                // Count for state
                stateCounts[state] = (stateCounts[state] || 0) + 1;
            });

            const cityData = Object.keys(cityCounts).map((city) => {
                const downloadCount = cityCounts[city];
                return { city, downloadCount };
            });

            const stateData = Object.keys(stateCounts).map((state) => {
                const downloadCount = stateCounts[state];
                return { state, downloadCount };
            });

            // Sort the city data based on the current sort order and column
            const sortedCityData = cityData.sort((a, b) => {
                if (sortOrder === 'asc') {
                    return a.downloadCount - b.downloadCount;
                } else {
                    return b.downloadCount - a.downloadCount;
                }
            });

            // Sort the state data based on the current sort order and column
            const sortedStateData = stateData.sort((a, b) => {
                if (sortOrder === 'asc') {
                    return a.downloadCount - b.downloadCount;
                } else {
                    return b.downloadCount - a.downloadCount;
                }
            });

            setUsersCity(sortedCityData);
            setUsersState(sortedStateData);
        });

        return () => unsubscribe();
    }, [sortOrder]);


    const handleSortClick = () => {
        // Toggle the sort order when the "Number of Downloads" heading is clicked
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    };


    // const [appointments, setAppointments] = useState([]);

    const APONall = () => {
        // const PremiumCardRequested = [];
        // const cardRequested = [];
        const fetchData = async () => {
            try {
                const [businessCardSnapshot] = await Promise.all([
                    db.collection("appointments_data").get(),
                ]);

                const totalDataCount = businessCardSnapshot.docs.length
                setAppointments(totalDataCount);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    };



    const APONmon = () => {
        const today = new Date();
        today.setHours(0);
        today.setMinutes(0);
        today.setMilliseconds(0);
        today.setSeconds(0);

        var date = new Date();
        var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);


        const fetchData = async () => {
            try {
                const [businessCardSnapshot] = await Promise.all([
                    db.collection("appointments_data").where('date', '>=', firstDay).where('date', '<=', lastDay).get(),

                ]);



                // cardRequested(businessCardSnapshot.docs.length);
                // PremiumCardRequested(premiumCardSnapshot.docs.length);

                const totalDataCount = businessCardSnapshot.docs.length;
                setAppointments(totalDataCount);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();

    }


    const APONweek = () => {
        // alert("call")
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        const currentDay = today.getDay(); // 0 (Sunday) to 6 (Saturday)
        const startOfWeek = new Date(today); // Make a copy of today
        startOfWeek.setDate(today.getDate() - currentDay); // Move back to the start of the current week (Sunday)
        const endOfWeek = new Date(startOfWeek);
        endOfWeek.setDate(startOfWeek.getDate() + 6); // Move to the end of the current week (Saturday)

        // Assuming Firestore 'date' field is a Timestamp
        console.log(startOfWeek);
        console.log(endOfWeek);


        const fetchData = async () => {
            try {
                const [businessCardSnapshot] = await Promise.all([
                    db.collection("appointments_data").where('date', '>=', startOfWeek)
                        .where('date', '<=', endOfWeek).get(),
                ]);

                const totalDataCount = businessCardSnapshot.docs.length;
                setAppointments(totalDataCount);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }

    const APONQuater = async () => {
        try {
            const today = new Date();
            today.setHours(0);
            today.setMinutes(0);
            today.setMilliseconds(0);
            today.setSeconds(0);

            const quarter = Math.floor(today.getMonth() / 3);

            const startQuarter = new Date(today.getFullYear(), quarter * 3, 1);
            const endQuarter = new Date(startQuarter.getFullYear(), startQuarter.getMonth() + 3, 0);

            const [appointmentsDataSnapshot] = await Promise.all([
                db.collection("appointments_data")
                    .where('date', '>=', startQuarter)
                    .where('date', '<=', endQuarter)
                    .get(),
            ]);

            const totalDataCount = appointmentsDataSnapshot.docs.length;
            setAppointments(totalDataCount);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };





    useEffect(() => {
        BCall();
        BNRall();
        SMGall();
        APONall();
        loanAll();
        // db.collection("loan").onSnapshot((snapshot) => {
        //     setLoan(
        //         snapshot.docs.map((doc) => ({
        //             id: doc.id,
        //             data: doc.data(),
        //         }))
        //     );
        // });

        db.collection("revenue_table").onSnapshot((snapshot) => {
            if (!snapshot.empty) {
                const data = snapshot.docs[0].data();
                const revenueValue = data.revenue;
                setRev(revenueValue)
                // Now, you have the value of the "revenue" field in the revenueValue variable
            }
        });


        db.collection("loan").where("status", "==", 1).onSnapshot((snapshot) => {
            // setDisbCount(snapshot.size);
            setDisbCount(
                snapshot.docs.map((doc) => ({
                    id: doc.id,
                    data: doc.data(),
                }))
            );
            setDisbAmount(snapshot.docs.reduce((accumulator, doc) => accumulator + parseInt(doc.data().loan_amount.substr(3, 8)), 0));

            // setDisbAmount(snapshot.docs.reduce((accumulator, doc) => accumulator + parseInt(doc.data().loan_amount.substr(3, 8), 0)));
        });

        db.collection("repayment_model").where("status", "==", "1").onSnapshot((snapshot) => {
            setRepayAmt(snapshot.docs.reduce((accumulator, doc) => accumulator + parseInt(doc.data().amount), 0));
            // setDisbAmount(snapshot.docs.reduce((accumulator, doc) => accumulator + parseInt(doc.data().loan_amount.substr(3, 8), 0)));
        });


        db.collection("personal_details").onSnapshot((snapshot) => {
            setUdata(
                snapshot.docs.map((doc) => ({
                    id: doc.id,
                    data: doc.data(),
                }))
            );
        });
        db.collection("portfolio_details").onSnapshot((snapshot) => {
            setPorto(
                snapshot.docs.map((doc) => ({
                    id: doc.id,
                    data: doc.data(),
                }))
            );
        });

        // db.collection("appointments_data").onSnapshot((snapshot) => {
        //     setAppointments(
        //         snapshot.docs.map((doc) => ({
        //             id: doc.id,
        //             data: doc.data(),
        //         }))
        //     );
        // });


        db.collection("whatsapp_business_request").onSnapshot((snapshot) => {
            setWhatsappBusiness(
                snapshot.docs.map((doc) => ({
                    id: doc.id,
                    data: doc.data(),
                }))
            );
        });

        db.collection("google_business_request").onSnapshot((snapshot) => {
            setGoogleBusiness(
                snapshot.docs.map((doc) => ({
                    id: doc.id,
                    data: doc.data(),
                }))
            );
        });

        db.collection("workshop_registration").onSnapshot((snapshot) => {
            setWorkshopRegistration(
                snapshot.docs.map((doc) => ({
                    id: doc.id,
                    data: doc.data(),
                }))
            );
        });




        db.collection("parlour_feedback").onSnapshot((snapshot) => {
            setReviewCount(
                snapshot.docs.map((doc) => ({
                    id: doc.id,
                    data: doc.data(),
                }))
            );
        });

        var arr1 = []; // for freelancers
        var arr2 = []; // for parlours

        db.collection("portfolio_details").onSnapshot((snapshot) => {
            snapshot.docs.map((doc) => {
                if (doc.data().title_designation === 'Beauty Professional') {
                    arr2.push(doc.data().name); // push the name of the parlour into arr2
                } else if (doc.data().title_designation === 'Freelancer') {
                    arr1.push(doc.data().name); // push the name of the freelancer into arr1
                }
            });

            var arrLen = [arr1.length, arr2.length];
            console.log(arrLen);
            window.donught(arrLen);
        })



        var uniqueStates = [];
        var stateCounts = [];

        db.collection("portfolio_details").onSnapshot((snapshot) => {
            uniqueStates = []; // Reset the uniqueStates array
            stateCounts = []; // Reset the stateCounts array

            snapshot.docs.forEach((doc) => {
                var state = doc.data().state;

                if (state && !uniqueStates.includes(state)) {
                    uniqueStates.push(state);
                    stateCounts.push(1);
                } else {
                    var index = uniqueStates.indexOf(state);
                    if (index !== -1) {
                        stateCounts[index] += 1;
                    }
                }
            });
            window.pieChart(uniqueStates, stateCounts);
            setStateName(uniqueStates);
            setstateCountsAll(stateCounts)
            // console.log(`0-0-0-0---${uniqueStates}`);
            // console.log(`-==--=-=-${stateCounts}`);
        });

        // window.donught([20, 10, 40, 50]);


        db.collection("course_enrollment").onSnapshot((snapshot) => {

            setCourseEnrollment(
                snapshot.docs.map((doc) => ({
                    data: doc.data(),
                }))
            );
        });

        var wDay = new Date();
        db.collection("workshop_event_data").where('created_date', '<=', wDay).onSnapshot((snapshot) => {
            setWorkshopCompleted(
                snapshot.docs.map((doc) => ({
                    id: doc.id,
                    data: doc.data(),
                }))
            );
        });

        // var jy = 0;
        // var au = 0;
        // var se = 0;
        // var oc = 0;
        // var no = 0;
        // var de = 0;
        // var be = 0;

        // // db.collection("personal_details").onSnapshot((snapshot) => {
        // //     snapshot.docs.map((doc) => {
        // //         if (doc.data().created_date.toDate() >= Jstart && doc.data().created_date.toDate() <= Aend) {
        // //             jy = jy += 1
        // //         }

        // //         if (doc.data().created_date.toDate() >= Astart && doc.data().created_date.toDate() <= Aend) {
        // //             au = au += 1
        // //         }

        // //         if (doc.data().created_date.toDate() >= Sstart && doc.data().created_date.toDate() <= Send) {
        // //             se = se += 1
        // //         }

        // //         if (doc.data().created_date.toDate() >= Ostart && doc.data().created_date.toDate() <= Oend) {
        // //             oc = oc += 1
        // //         }

        // //         if (doc.data().created_date.toDate() >= Nstart && doc.data().created_date.toDate() <= Nend) {
        // //             no = no += 1
        // //         }

        // //         if (doc.data().created_date.toDate() >= Dstart && doc.data().created_date.toDate() <= Dend) {
        // //             de = de += 1
        // //         }

        // //         if (doc.data().created_date.toDate() >= Bstart && doc.data().created_date.toDate() <= Bend) {
        // //             be = be += 1
        // //         }
        // //     })
        // //     setAppdownload([jy, au, se, oc, no, de])
        // //     // window.websiteView([50, 20, 10, 22, 50, 10]);
        // //     window.websiteView([au, se, oc, no, de, be]);
        // //     console.log(appDownload);

        // // });

        const currentDate = new Date(); // Current date

        const appDownload = new Array(6).fill(0); // Initialize an array to store counts for each month

        const monthNames = [
            "Jan", "Feb", "Mar", "Apr", "May", "Jun",
            "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
        ];


        // for (let i = 0; i < 6; i++) {
        //     const startOfMonth = new Date(
        //         currentDate.getFullYear(),
        //         currentDate.getMonth() - i,
        //         1
        //     );
        //     const endOfMonth = new Date(
        //         currentDate.getFullYear(),
        //         currentDate.getMonth() - i + 1,
        //         0,
        //         23,
        //         59,
        //         59
        //     );

        //     db.collection("personal_details").onSnapshot((snapshot) => {
        //         snapshot.docs.map((doc) => {
        //             const createdDate = doc.data().created_date.toDate();
        //             if (createdDate >= startOfMonth && createdDate <= endOfMonth) {
        //                 appDownload[5 - i] += 1; // Store data in reverse order
        //             }
        //         });
        //         const currentMonthNames = monthNames.slice(
        //             currentDate.getMonth() - 5,
        //             currentDate.getMonth() + 1
        //         );


        //         setAppdownload(appDownload);
        //         window.websiteView(currentMonthNames, appDownload);
        //         console.log(`currentMonthNames -- ${currentMonthNames}`);
        //     });
        // }
        // Get the current month
        const currentMonth = monthNames[currentDate.getMonth()];
        // Get the names of the last 5 months
        const lastMonths = [];
        for (let i = 1; i <= 5; i++) {
            const previousMonthIndex = (currentDate.getMonth() - i + 12) % 12; // Handle wrapping from Jan to Dec
            lastMonths.push(monthNames[previousMonthIndex]);
        }
        lastMonths.reverse();

        const mergedMonths = [...lastMonths, currentMonth];
        console.log("Merged Months in Ascending Order:", mergedMonths);

        // console.log("Current Month:", currentMonth);
        // console.log("Last 5 Months:", lastMonths);

        for (let i = 0; i < 6; i++) {
            const startOfMonth = new Date(
                currentDate.getFullYear(),
                currentDate.getMonth() - i,
                1
            );
            const endOfMonth = new Date(
                currentDate.getFullYear(),
                currentDate.getMonth() - i + 1,
                0,
                23,
                59,
                59
            );

            db.collection("personal_details")
                .where('created_date', '>=', startOfMonth)
                .where('created_date', '<=', endOfMonth)
                .get()
                .then((querySnapshot) => {
                    const count = querySnapshot.size;
                    querySnapshot.forEach((doc) => {
                        appDownload[5 - i] += 1;
                    });

                    const currentMonthIndex = (currentDate.getMonth() - i + 12) % 12;
                    const monthName = monthNames[currentMonthIndex];
                    console.log(`Month: ${monthName}, Downloads: ${count}`);

                    // Calculate the slice range based on your desired order
                    const sliceStart = currentMonthIndex;
                    const sliceEnd = currentMonthIndex - 6 < 0 ? 12 : currentMonthIndex - 6;

                    const currentMonthNames = monthNames.slice(sliceEnd, sliceStart + 1).reverse();



                    setAppdownload(appDownload);
                    window.websiteView(mergedMonths, appDownload);
                    // window.websiteView(currentMonthNames, appDownload);
                    console.log(`currentMonthNames -- ${currentMonthNames}`);
                })
                .catch((error) => {
                    console.log("Error getting documents: ", error);
                });
        }



        chartsOne();

        db.collection("all_notification").orderBy("created_date", "desc").limit(25).onSnapshot((snapshot) => {
            setData(
                snapshot.docs.map((doc) => ({
                    id: doc.id,
                    data: doc.data(),
                }))
            );
        });

        db.collection("all_notification").where('status', '==', "0").onSnapshot((snapshot) => {
            setCount(
                snapshot.docs.map((doc) => ({
                    id: doc.id,
                    data: doc.data(),
                }))
            );
        });

        // var jyP = 0;
        // var auP = 0;
        // var seP = 0;
        // var ocP = 0;
        // var noP = 0;
        // var deP = 0;
        // var beP = 0;

        // db.collection("portfolio_details").onSnapshot((snapshot) => {
        //     snapshot.docs.map((doc) => {
        //         if (doc.data().created_date.toDate() >= Jstart && doc.data().created_date.toDate() <= Aend) {
        //             jyP = jyP += 1
        //         }

        //         if (doc.data().created_date.toDate() >= Astart && doc.data().created_date.toDate() <= Aend) {
        //             auP = auP += 1
        //         }

        //         if (doc.data().created_date.toDate() >= Sstart && doc.data().created_date.toDate() <= Send) {
        //             seP = seP += 1
        //         }

        //         if (doc.data().created_date.toDate() >= Ostart && doc.data().created_date.toDate() <= Oend) {
        //             ocP = ocP += 1
        //         }

        //         if (doc.data().created_date.toDate() >= Nstart && doc.data().created_date.toDate() <= Nend) {
        //             noP = noP += 1
        //         }

        //         if (doc.data().created_date.toDate() >= Dstart && doc.data().created_date.toDate() <= Dend) {
        //             deP = deP += 1
        //         }
        //         if (doc.data().created_date.toDate() >= Bstart && doc.data().created_date.toDate() <= Bend) {
        //             beP = beP += 1
        //         }
        //     })
        //     setPortCreated([jyP, auP, seP, ocP, noP, deP])
        //     window.portoView([auP, seP, ocP, noP, deP, beP]);

        // });


        const portfolioCreated = new Array(6).fill(0);

        for (let i = 0; i < 6; i++) {
            const startOfMonth = new Date(
                currentDate.getFullYear(),
                currentDate.getMonth() - i,
                1
            );
            const endOfMonth = new Date(
                currentDate.getFullYear(),
                currentDate.getMonth() - i + 1,
                0,
                23,
                59,
                59
            );

            db.collection("portfolio_details").onSnapshot((snapshot) => {
                snapshot.docs.map((doc) => {
                    const createdDate = doc.data().created_date.toDate();
                    if (createdDate >= startOfMonth && createdDate <= endOfMonth) {
                        portfolioCreated[5 - i] += 1; // Store data in reverse order
                    }
                });
                const currentMonthNames = monthNames.slice(
                    currentDate.getMonth() - 5,
                    currentDate.getMonth() + 1
                );

                setPortCreated(portfolioCreated);
                window.portoView(mergedMonths, portfolioCreated);
                console.log(portfolioCreated);
            });
        }


        // var jyRE = 0;
        // var auRE = 0;
        // var seRE = 0;
        // var ocRE = 0;
        // var noRE = 0;
        // var deRE = 0;

        // db.collection("repayment_model").where("status", "==", "1").onSnapshot((snapshot) => {
        //     snapshot.docs.map((doc) => {
        //         var amount = parseInt(doc.data().amount); // Parse the amount value to an integer

        //         if (doc.data().updated_date.toDate() >= Jstart && doc.data().updated_date.toDate() <= Aend) {
        //             jyRE += amount;
        //         }

        //         if (doc.data().updated_date.toDate() >= Astart && doc.data().updated_date.toDate() <= Aend) {
        //             auRE += amount;
        //         }

        //         if (doc.data().updated_date.toDate() >= Sstart && doc.data().updated_date.toDate() <= Send) {
        //             seRE += amount;
        //         }

        //         if (doc.data().updated_date.toDate() >= Ostart && doc.data().updated_date.toDate() <= Oend) {
        //             ocRE += amount;
        //         }

        //         if (doc.data().updated_date.toDate() >= Nstart && doc.data().updated_date.toDate() <= Nend) {
        //             noRE += amount;
        //         }

        //         if (doc.data().updated_date.toDate() >= Dstart && doc.data().updated_date.toDate() <= Dend) {
        //             deRE += amount;
        //         }
        //     });
        //     console.log([jyRE, auRE, seRE, ocRE, noRE, deRE])

        //     window.rePayView([jyRE, auRE, seRE, ocRE, noRE, deRE]);
        // });

        // var monthNames = [
        //     "January", "February", "March", "April", "May", "June",
        //     "July", "August", "September", "October", "November", "December"
        // ];

        var repayAmounts = [0, 0, 0, 0, 0, 0];

        db.collection("repayment_model")
            .where("status", "==", "1")
            .onSnapshot((snapshot) => {
                snapshot.docs.forEach((doc) => {
                    var amount = parseInt(doc.data().amount);
                    var updatedDate = doc.data().updated_date.toDate();

                    var currentDate = new Date();
                    var monthIndex =
                        (currentDate.getFullYear() - updatedDate.getFullYear()) * 12 +
                        (currentDate.getMonth() - updatedDate.getMonth());

                    if (monthIndex >= 0 && monthIndex < 6) {
                        repayAmounts[5 - monthIndex] += amount;
                    }
                });

                const currentMonthNames = monthNames.slice(
                    currentDate.getMonth() - 5,
                    currentDate.getMonth() + 1
                );

                // Display month names and corresponding amounts
                // for (var i = 0; i < repayAmounts.length; i++) {
                //     console.log(monthNames[i] + " - " + repayAmounts[i]);
                // }

                window.rePayView(mergedMonths, repayAmounts);
            });


        // var monthNames = [
        //     "January", "February", "March", "April", "May", "June",
        //     "July", "August", "September", "October", "November", "December"
        // ];

        // var repayAmounts = Array(12).fill(0);

        // db.collection("repayment_model")
        //     .where("status", "==", "1")
        //     .onSnapshot((snapshot) => {
        //         snapshot.docs.forEach((doc) => {
        //             var amount = parseInt(doc.data().amount);
        //             var updatedDate = doc.data().updated_date.toDate();

        //             var monthIndex = updatedDate.getMonth();
        //             repayAmounts[monthIndex] += amount;
        //         });

        //         // Display month names and corresponding amounts
        //         for (var i = 0; i < repayAmounts.length; i++) {
        //             console.log(monthNames[i] + " - " + repayAmounts[i]);
        //         }
        //     });


        db.collection("smg_request").where('status', '==', "1").onSnapshot((snapshot) => {
            setSmgCard(
                snapshot.docs.map((doc) => ({
                    id: doc.id,
                    data: doc.data(),
                }))
            );
            // alert(snapshot.docs.length)
        });

        db.collection("premium_card_request").where('payment', '==', "99").where('status', '==', "1").onSnapshot((snapshot) => {
            setSmgCardPre(
                snapshot.docs.map((doc) => ({
                    id: doc.id,
                    data: doc.data(),
                }))
            );
            // alert(snapshot.docs.length)
        });



        db.collection("banner_request").where('status', '==', "1").onSnapshot((snapshot) => {
            setBannerCard(
                snapshot.docs.map((doc) => ({
                    id: doc.id,
                    data: doc.data(),
                }))
            );
            // alert(snapshot.docs.length)
        });

        db.collection("premium_banner_request").where('status', '==', "1").onSnapshot((snapshot) => {
            setBannerCardLengthPre(
                snapshot.docs.map((doc) => ({
                    id: doc.id,
                    data: doc.data(),
                }))
            );
            // alert(snapshot.docs.length)
        });



        db.collection("business_card_request").where('status', '==', "1").onSnapshot((snapshot) => {
            setBusinessCard(
                snapshot.docs.map((doc) => ({
                    id: doc.id,
                    data: doc.data(),
                }))
            );
            setCardLength(snapshot.docs.length)
        });

        db.collection("premium_card_request").where('payment', '==', "49").where('status', '==', "1").onSnapshot((snapshot) => {
            setBusinessCardPre(
                snapshot.docs.map((doc) => ({
                    id: doc.id,
                    data: doc.data(),
                }))
            );
            setCardLength(cardLength + snapshot.docs.length)
        });

        // const totalLength = businessCard.length + businessCardPre.length;
        // setTotalDataLength(totalLength);

        chartsOne();
        // getPortfoliosByMonth();
    }, []);


    // useEffect(() => {
    //     const fetchData = async () => {
    //         try {
    //             const snapshot = await db.collection("business_card_request").get();
    //             const allData = snapshot.docs.map((doc) => ({
    //                 id: doc.id,
    //                 data: doc.data(),
    //             }));
    //             setBusinessCardRequest(allData);
    //             setCardLength(allData.length);

    //             // Filter and calculate weekly count
    //             const currentWeekStart = new Date();
    //             currentWeekStart.setHours(0, 0, 0, 0);
    //             currentWeekStart.setDate(currentWeekStart.getDate() - currentWeekStart.getDay());

    //             const weeklyData = allData.filter(item => new Date(item.data.created_date) >= currentWeekStart);
    //             setWeeklyCount(weeklyData.length);

    //             // Filter and calculate monthly count
    //             const currentMonthStart = new Date();
    //             currentMonthStart.setHours(0, 0, 0, 0);
    //             currentMonthStart.setDate(1);

    //             const monthlyData = allData.filter(item => new Date(item.data.created_date) >= currentMonthStart);
    //             setMonthlyCount(monthlyData.length);
    //         } catch (error) {
    //             console.error('Error fetching data:', error);
    //         }
    //     };

    //     fetchData(); // Fetch data once

    // }, []);




    // useEffect(() => {
    //     const unsubscribeBusinessCard = db.collection.collection("business_card_request")
    //         .onSnapshot((snapshot) => {
    //             setBusinessCard(
    //                 snapshot.docs.map((doc) => ({
    //                     id: doc.id,
    //                     data: doc.data(),
    //                 }))
    //             );
    //         });

    //     const unsubscribeBusinessCardPre = db.collection.collection("premium_card_request")
    //         .onSnapshot((snapshot) => {
    //             setBusinessCardPre(
    //                 snapshot.docs.map((doc) => ({
    //                     id: doc.id,
    //                     data: doc.data(),
    //                 }))
    //             );
    //         });


    // }, []);

    // useEffect(() => {
    //     const totalLength = businessCard.length + businessCardPre.length;
    //     setTotalDataLength(totalLength);
    // }, [businessCard, businessCardPre]);

    const AllCard = (() => {
        // const totalLength = businessCard.length + businessCardPre.length;
        setTotalDataLength(0);
    })

    const FreeCard = () => {
        db.collection("business_card_request").where('status', '==', "1").onSnapshot((snapshot) => {
            setBusinessCard(
                snapshot.docs.map((doc) => ({
                    id: doc.id,
                    data: doc.data(),
                }))
            );
            setTotalDataLength(snapshot.docs.length)
        });

    }

    const PreCard = () => {
        db.collection("premium_card_request").where('payment', '==', "49").where('status', '==', "1").onSnapshot((snapshot) => {
            setBusinessCardPre(
                snapshot.docs.map((doc) => ({
                    id: doc.id,
                    data: doc.data(),
                }))
            );
            setTotalDataLength(snapshot.docs.length)
        });

    }

    const [finaldis, setsFinaldis] = useState('dis');

    const diss = () => {
        setsFinaldis("dis");
    }
    const ree = () => {
        setsFinaldis("re");
    }

    // =============================================================================

    const [bcardRequested, setBcardRequested] = useState([]);

    const BCall = () => {
        // const PremiumCardRequested = [];
        // const cardRequested = [];
        const fetchData = async () => {
            try {
                const [businessCardSnapshot, premiumCardSnapshot] = await Promise.all([
                    db.collection("business_card_request").get(),
                    db.collection("premium_card_request").get()
                ]);

                const totalDataCount = businessCardSnapshot.docs.length + premiumCardSnapshot.docs.length;
                setBcardRequested(totalDataCount);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    };



    const BCmon = () => {
        const today = new Date();
        today.setHours(0);
        today.setMinutes(0);
        today.setMilliseconds(0);
        today.setSeconds(0);

        var date = new Date();
        var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);


        const fetchData = async () => {
            try {
                const [businessCardSnapshot, premiumCardSnapshot] = await Promise.all([
                    db.collection("business_card_request").where('created_date', '>=', firstDay).where('created_date', '<=', lastDay).get(),
                    db.collection("premium_card_request").where('created_date', '>=', firstDay).where('created_date', '<=', lastDay).get()
                ]);



                // cardRequested(businessCardSnapshot.docs.length);
                // PremiumCardRequested(premiumCardSnapshot.docs.length);

                const totalDataCount = businessCardSnapshot.docs.length + premiumCardSnapshot.docs.length;
                setBcardRequested(totalDataCount);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();

    }

    const BCQuater = async () => {
        try {
            const today = new Date();
            const quarter = Math.floor(today.getMonth() / 3);

            const startQuarter = new Date(today.getFullYear(), quarter * 3, 1);
            const endQuarter = new Date(startQuarter.getFullYear(), startQuarter.getMonth() + 3, 0);

            const [businessCardSnapshot, premiumCardSnapshot] = await Promise.all([
                db.collection("business_card_request").where('created_date', '>=', startQuarter).where('created_date', '<=', endQuarter).get(),
                db.collection("premium_card_request").where('created_date', '>=', startQuarter).where('created_date', '<=', endQuarter).get()
            ]);

            // Process your data here
            const totalDataCount = businessCardSnapshot.docs.length + premiumCardSnapshot.docs.length;
            console.log('Total Data Count for Quarter:', totalDataCount);
            setBcardRequested(totalDataCount);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    // Call the function




    const BCweek = () => {
        // alert("call")
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        const currentDay = today.getDay(); // 0 (Sunday) to 6 (Saturday)
        const startOfWeek = new Date(today); // Make a copy of today
        startOfWeek.setDate(today.getDate() - currentDay); // Move back to the start of the current week (Sunday)
        const endOfWeek = new Date(startOfWeek);
        endOfWeek.setDate(startOfWeek.getDate() + 6); // Move to the end of the current week (Saturday)

        // Assuming Firestore 'created_date' field is a Timestamp
        console.log(startOfWeek);
        console.log(endOfWeek);


        const fetchData = async () => {
            try {
                const [businessCardSnapshot, premiumCardSnapshot] = await Promise.all([
                    db.collection("business_card_request").where('created_date', '>=', startOfWeek)
                        .where('created_date', '<=', endOfWeek).get(),
                    db.collection("premium_card_request").where('created_date', '>=', startOfWeek)
                        .where('created_date', '<=', endOfWeek).get()
                ]);

                const totalDataCount = businessCardSnapshot.docs.length + premiumCardSnapshot.docs.length;
                setBcardRequested(totalDataCount);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }


    const [bnrcardRequested, setBnrcardRequested] = useState([]);

    const BNRall = () => {
        // const PremiumCardRequested = [];
        // const cardRequested = [];
        const fetchData = async () => {
            try {
                const [businessCardSnapshot, premiumCardSnapshot] = await Promise.all([
                    db.collection("banner_request").get(),
                    db.collection("premium_banner_request").get()
                ]);

                const totalDataCount = businessCardSnapshot.docs.length + premiumCardSnapshot.docs.length;
                setBnrcardRequested(totalDataCount);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    };



    const BNRmon = () => {
        const today = new Date();
        today.setHours(0);
        today.setMinutes(0);
        today.setMilliseconds(0);
        today.setSeconds(0);

        var date = new Date();
        var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);


        const fetchData = async () => {
            try {
                const [businessCardSnapshot, premiumCardSnapshot] = await Promise.all([
                    db.collection("banner_request").where('created_date', '>=', firstDay).where('created_date', '<=', lastDay).get(),
                    db.collection("premium_banner_request").where('created_date', '>=', firstDay).where('created_date', '<=', lastDay).get()
                ]);



                // cardRequested(businessCardSnapshot.docs.length);
                // PremiumCardRequested(premiumCardSnapshot.docs.length);

                const totalDataCount = businessCardSnapshot.docs.length + premiumCardSnapshot.docs.length;
                setBnrcardRequested(totalDataCount);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();

    }

    const BNRQuater = async () => {
        try {
            const today = new Date();
            today.setHours(0);
            today.setMinutes(0);
            today.setMilliseconds(0);
            today.setSeconds(0);

            const quarter = Math.floor(today.getMonth() / 3);

            const startQuarter = new Date(today.getFullYear(), quarter * 3, 1);
            const endQuarter = new Date(startQuarter.getFullYear(), startQuarter.getMonth() + 3, 0);

            const [bannerRequestSnapshot, premiumBannerRequestSnapshot] = await Promise.all([
                db.collection("banner_request").where('created_date', '>=', startQuarter).where('created_date', '<=', endQuarter).get(),
                db.collection("premium_banner_request").where('created_date', '>=', startQuarter).where('created_date', '<=', endQuarter).get()
            ]);

            const totalDataCount = bannerRequestSnapshot.docs.length + premiumBannerRequestSnapshot.docs.length;
            setBnrcardRequested(totalDataCount);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };




    const BNRweek = () => {
        // alert("call")
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        const currentDay = today.getDay(); // 0 (Sunday) to 6 (Saturday)
        const startOfWeek = new Date(today); // Make a copy of today
        startOfWeek.setDate(today.getDate() - currentDay); // Move back to the start of the current week (Sunday)
        const endOfWeek = new Date(startOfWeek);
        endOfWeek.setDate(startOfWeek.getDate() + 6); // Move to the end of the current week (Saturday)

        // Assuming Firestore 'created_date' field is a Timestamp
        console.log(startOfWeek);
        console.log(endOfWeek);


        const fetchData = async () => {
            try {
                const [businessCardSnapshot, premiumCardSnapshot] = await Promise.all([
                    db.collection("banner_request").where('created_date', '>=', startOfWeek)
                        .where('created_date', '<=', endOfWeek).get(),
                    db.collection("premium_banner_request").where('created_date', '>=', startOfWeek)
                        .where('created_date', '<=', endOfWeek).get()
                ]);

                const totalDataCount = businessCardSnapshot.docs.length + premiumCardSnapshot.docs.length;
                setBnrcardRequested(totalDataCount);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }



    const [smgcardRequested, setSmgcardRequested] = useState([]);


    const SMGall = () => {
        // const PremiumCardRequested = [];
        // const cardRequested = [];
        const fetchData = async () => {
            try {
                const [businessCardSnapshot, premiumCardSnapshot] = await Promise.all([
                    db.collection("smg_request").get(),
                    db.collection("premium_card_request").where('payment', '==', "99").get()
                ]);

                const totalDataCount = businessCardSnapshot.docs.length + premiumCardSnapshot.docs.length;
                setSmgcardRequested(totalDataCount);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    };



    const SMGmon = () => {
        const today = new Date();
        today.setHours(0);
        today.setMinutes(0);
        today.setMilliseconds(0);
        today.setSeconds(0);

        var date = new Date();
        var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);


        const fetchData = async () => {
            try {
                const [businessCardSnapshot, premiumCardSnapshot] = await Promise.all([
                    db.collection("smg_request").where('created_date', '>=', firstDay).where('created_date', '<=', lastDay).get(),
                    db.collection("premium_card_request").where('payment', '==', "99").where('created_date', '>=', firstDay).where('created_date', '<=', lastDay).get()
                ]);



                // cardRequested(businessCardSnapshot.docs.length);
                // PremiumCardRequested(premiumCardSnapshot.docs.length);

                const totalDataCount = businessCardSnapshot.docs.length + premiumCardSnapshot.docs.length;
                console.log(totalDataCount)
                setSmgcardRequested(totalDataCount);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();

    }

    const SMGQuater = async () => {
        try {
            const today = new Date();
            today.setHours(0);
            today.setMinutes(0);
            today.setMilliseconds(0);
            today.setSeconds(0);

            const quarter = Math.floor(today.getMonth() / 3);

            const startQuarter = new Date(today.getFullYear(), quarter * 3, 1);
            const endQuarter = new Date(startQuarter.getFullYear(), startQuarter.getMonth() + 3, 0);

            const [smgRequestSnapshot, premiumCardSnapshot] = await Promise.all([
                db.collection("smg_request").where('created_date', '>=', startQuarter).where('created_date', '<=', endQuarter).get(),
                db.collection("premium_card_request").where('payment', '==', "99").where('created_date', '>=', startQuarter).where('created_date', '<=', endQuarter).get()
            ]);

            const totalDataCount = smgRequestSnapshot.docs.length + premiumCardSnapshot.docs.length;
            console.log(totalDataCount)
            setSmgcardRequested(totalDataCount);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };




    const SMGweek = () => {
        // alert("call")
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        const currentDay = today.getDay(); // 0 (Sunday) to 6 (Saturday)
        const startOfWeek = new Date(today); // Make a copy of today
        startOfWeek.setDate(today.getDate() - currentDay); // Move back to the start of the current week (Sunday)
        const endOfWeek = new Date(startOfWeek);
        endOfWeek.setDate(startOfWeek.getDate() + 6); // Move to the end of the current week (Saturday)

        // Assuming Firestore 'created_date' field is a Timestamp
        console.log(startOfWeek);
        console.log(endOfWeek);


        const fetchData = async () => {
            try {
                const [businessCardSnapshot, premiumCardSnapshot] = await Promise.all([
                    db.collection("smg_request").where('created_date', '>=', startOfWeek)
                        .where('created_date', '<=', endOfWeek).get(),
                    db.collection("premium_card_request").where('payment', '==', "99").where('created_date', '>=', startOfWeek)
                        .where('created_date', '<=', endOfWeek).get()
                ]);

                const totalDataCount = businessCardSnapshot.docs.length + premiumCardSnapshot.docs.length;
                setSmgcardRequested(totalDataCount);
                console.log(totalDataCount)
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }


    const fetchWhatsappBusinessMonthly = async () => {
        try {
            const today = new Date();
            today.setHours(0);
            today.setMinutes(0);
            today.setMilliseconds(0);
            today.setSeconds(0);

            const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
            const lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);

            const snapshot = await db.collection("whatsapp_business_request")
                .where('status', '==', "1")
                .where('created_date', '>=', firstDay)
                .where('created_date', '<=', lastDay)
                .get();

            setWhatsappBusiness(
                snapshot.docs.map((doc) => ({
                    id: doc.id,
                    data: doc.data(),
                }))
            );
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const fetchWhatsappBusinessWeekly = async () => {
        try {
            const today = new Date();
            today.setHours(0);
            today.setMinutes(0);
            today.setMilliseconds(0);
            today.setSeconds(0);

            const startOfWeek = new Date(today);
            startOfWeek.setDate(today.getDate() - today.getDay()); // Adjust to the start of the week (Sunday)

            const endOfWeek = new Date(today);
            endOfWeek.setDate(today.getDate() + (6 - today.getDay())); // Adjust to the end of the week (Saturday)

            const snapshot = await db.collection("whatsapp_business_request")
                .where('status', '==', "1")
                .where('created_date', '>=', startOfWeek)
                .where('created_date', '<=', endOfWeek)
                .get();

            setWhatsappBusiness(
                snapshot.docs.map((doc) => ({
                    id: doc.id,
                    data: doc.data(),
                }))
            );
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };


    const fetchWhatsappBusinessQuarterly = async () => {
        try {
            const today = new Date();
            today.setHours(0);
            today.setMinutes(0);
            today.setMilliseconds(0);
            today.setSeconds(0);

            const quarter = Math.floor(today.getMonth() / 3);

            const startQuarter = new Date(today.getFullYear(), quarter * 3, 1);
            const endQuarter = new Date(startQuarter.getFullYear(), startQuarter.getMonth() + 3, 0);

            const snapshot = await db.collection("whatsapp_business_request")
                .where('status', '==', "1")
                .where('created_date', '>=', startQuarter)
                .where('created_date', '<=', endQuarter)
                .get();

            setWhatsappBusiness(
                snapshot.docs.map((doc) => ({
                    id: doc.id,
                    data: doc.data(),
                }))
            );
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const fetchWhatsappBusinessYearly = async () => {
        db.collection("whatsapp_business_request").where('status', '==', "1").onSnapshot((snapshot) => {
            setWhatsappBusiness(
                snapshot.docs.map((doc) => ({
                    id: doc.id,
                    data: doc.data(),
                }))
            );
        });
    };




    const fetchParlourFeedbackMonthly = async () => {
        try {
            const today = new Date();
            today.setHours(0);
            today.setMinutes(0);
            today.setMilliseconds(0);
            today.setSeconds(0);

            const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
            const lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);

            const snapshot = await db.collection("parlour_feedback")
                .where('created_date', '>=', firstDay)
                .where('created_date', '<=', lastDay)
                .get();

            setReviewCount(
                snapshot.docs.map((doc) => ({
                    id: doc.id,
                    data: doc.data(),
                }))
            );
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const fetchParlourFeedbackQuarterly = async () => {
        try {
            const today = new Date();
            today.setHours(0);
            today.setMinutes(0);
            today.setMilliseconds(0);
            today.setSeconds(0);

            const quarter = Math.floor(today.getMonth() / 3);

            const startQuarter = new Date(today.getFullYear(), quarter * 3, 1);
            const endQuarter = new Date(startQuarter.getFullYear(), startQuarter.getMonth() + 3, 0);

            const snapshot = await db.collection("parlour_feedback")
                .where('created_date', '>=', startQuarter)
                .where('created_date', '<=', endQuarter)
                .get();

            setReviewCount(
                snapshot.docs.map((doc) => ({
                    id: doc.id,
                    data: doc.data(),
                }))
            );
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const fetchParlourFeedbackWeekly = async () => {
        try {
            const today = new Date();
            today.setHours(0);
            today.setMinutes(0);
            today.setMilliseconds(0);
            today.setSeconds(0);

            const startOfWeek = new Date(today);
            startOfWeek.setDate(today.getDate() - today.getDay()); // Adjust to the start of the week (Sunday)

            const endOfWeek = new Date(today);
            endOfWeek.setDate(today.getDate() + (6 - today.getDay())); // Adjust to the end of the week (Saturday)

            const snapshot = await db.collection("parlour_feedback")
                .where('created_date', '>=', startOfWeek)
                .where('created_date', '<=', endOfWeek)
                .get();

            setReviewCount(
                snapshot.docs.map((doc) => ({
                    id: doc.id,
                    data: doc.data(),
                }))
            );
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const fetchParlourFeedbackYearly = async () => {
        try {
            const today = new Date();
            today.setHours(0);
            today.setMinutes(0);
            today.setMilliseconds(0);
            today.setSeconds(0);

            const startOfYear = new Date(today.getFullYear(), 0, 1);
            const endOfYear = new Date(today.getFullYear(), 11, 31);

            const snapshot = await db.collection("parlour_feedback")
                .where('created_date', '>=', startOfYear)
                .where('created_date', '<=', endOfYear)
                .get();

            setReviewCount(
                snapshot.docs.map((doc) => ({
                    id: doc.id,
                    data: doc.data(),
                }))
            );
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };



    // ===================================================================

    const fetchGMBMonthly = async () => {
        try {
            const today = new Date();
            today.setHours(0);
            today.setMinutes(0);
            today.setMilliseconds(0);
            today.setSeconds(0);

            const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
            const lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);

            const snapshot = await db.collection("google_business_request")
                .where('created_date', '>=', firstDay)
                .where('created_date', '<=', lastDay)
                .get();

            setGoogleBusiness(
                snapshot.docs.map((doc) => ({
                    id: doc.id,
                    data: doc.data(),
                }))
            );
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const fetchGMBQuarterly = async () => {
        try {
            const today = new Date();
            today.setHours(0);
            today.setMinutes(0);
            today.setMilliseconds(0);
            today.setSeconds(0);

            const quarter = Math.floor(today.getMonth() / 3);

            const startQuarter = new Date(today.getFullYear(), quarter * 3, 1);
            const endQuarter = new Date(startQuarter.getFullYear(), startQuarter.getMonth() + 3, 0);

            const snapshot = await db.collection("google_business_request")
                .where('created_date', '>=', startQuarter)
                .where('created_date', '<=', endQuarter)
                .get();

            setGoogleBusiness(
                snapshot.docs.map((doc) => ({
                    id: doc.id,
                    data: doc.data(),
                }))
            );
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const fetchGMBWeekly = async () => {
        try {
            const today = new Date();
            today.setHours(0);
            today.setMinutes(0);
            today.setMilliseconds(0);
            today.setSeconds(0);

            const startOfWeek = new Date(today);
            startOfWeek.setDate(today.getDate() - today.getDay()); // Adjust to the start of the week (Sunday)

            const endOfWeek = new Date(today);
            endOfWeek.setDate(today.getDate() + (6 - today.getDay())); // Adjust to the end of the week (Saturday)

            const snapshot = await db.collection("google_business_request")
                .where('created_date', '>=', startOfWeek)
                .where('created_date', '<=', endOfWeek)
                .get();

            setGoogleBusiness(
                snapshot.docs.map((doc) => ({
                    id: doc.id,
                    data: doc.data(),
                }))
            );
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const fetchGMBYearly = async () => {
        try {
            const today = new Date();
            today.setHours(0);
            today.setMinutes(0);
            today.setMilliseconds(0);
            today.setSeconds(0);

            const startOfYear = new Date(today.getFullYear(), 0, 1);
            const endOfYear = new Date(today.getFullYear(), 11, 31);

            const snapshot = await db.collection("google_business_request")
                .where('created_date', '>=', startOfYear)
                .where('created_date', '<=', endOfYear)
                .get();

            setGoogleBusiness(
                snapshot.docs.map((doc) => ({
                    id: doc.id,
                    data: doc.data(),
                }))
            );
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };













    // =============================================================================




    const AllBanner = (() => {
        // const totalLength = businessCard.length + businessCardPre.length;
        setTotalBannerLength(0);
    })

    const FreeBanner = () => {
        db.collection("banner_request").where('status', '==', "1").onSnapshot((snapshot) => {
            setBannerCard(
                snapshot.docs.map((doc) => ({
                    id: doc.id,
                    data: doc.data(),
                }))
            );
            setTotalBannerLength(snapshot.docs.length)
        });

    }

    const PreBanner = () => {
        db.collection("premium_banner_request").where('status', '==', "1").onSnapshot((snapshot) => {
            setBannerCardLengthPre(
                snapshot.docs.map((doc) => ({
                    id: doc.id,
                    data: doc.data(),
                }))
            );
            setTotalBannerLength(snapshot.docs.length)
        });

    }

    const AllSmg = (() => {
        // const totalLength = businessCard.length + businessCardPre.length;
        setTotalSmgLength(0);
    })

    const FreeSmg = () => {
        db.collection("smg_request").where('status', '==', "1").onSnapshot((snapshot) => {
            setSmgCard(
                snapshot.docs.map((doc) => ({
                    id: doc.id,
                    data: doc.data(),
                }))
            );
            setTotalSmgLength(snapshot.docs.length)
        });

    }

    const PreSmg = () => {
        db.collection("premium_card_request").where('payment', '==', "99").where('status', '==', "1").onSnapshot((snapshot) => {
            setSmgCardPre(
                snapshot.docs.map((doc) => ({
                    id: doc.id,
                    data: doc.data(),
                }))
            );
            setTotalSmgLength(snapshot.docs.length)
        });

    }

    const logout = () => {
        localStorage.removeItem("email");
        localStorage.removeItem("password");
        localStorage.removeItem("remember");
        navigate('/signin')
    };


    const [viewMore, setViewMore] = useState(false);

    const viewMo = () => {
        if (viewMore) {
            setViewMore(false)
        } else {
            setViewMore(true)
        }
    }

    const ReadAll = () => {
        {
            count?.map(({ id, data }, index) => (
                db.collection("all_notification").doc(id).update({ 'status': "1" }).then(() => {
                    // console.log(`updated ${id}`);
                })
            ))
        }
    }

    const DeleteAll = () => {
        {
            data?.map(({ id, data }, index) => (
                db.collection("all_notification").doc(id).delete().then(() => {
                    // console.log("user added");
                })
            ))
        }
    }

    const showHideBar = () => {
        if (showHide) {
            setShowHide(false)
            console.log("hidee")
        } else {
            setShowHide(true)
            console.log("showww")
        }
    }

    const tog = () => {
        // window.websiteView();
        window.togg();
    }

    const [ola, setOla] = useState(true);

    const analyticsReload = () => {
        setActiveIndex(null);
        db.collection("personal_details").onSnapshot((snapshot) => {
            setUdata(
                snapshot.docs.map((doc) => ({
                    id: doc.id,
                    data: doc.data(),
                }))
            );
        });

        var arr1 = [];
        var arr2 = [];
        var arr3 = [];
        var arr4 = [];
        db.collection("personal_details").onSnapshot((snapshot) => {

            // setCourseEnrollment(
            //     snapshot.docs.map((doc) => ({
            //         data: doc.data(),
            //     }))
            // );
            snapshot.docs.map((doc) => (
                // console.log(doc.data().type),
                doc.data().type == 'parlour' ? arr2.push(doc.data().type) : null,
                doc.data().type == 'freelancer' ? arr1.push(doc.data().type) : null,
                doc.data().type == 'student' ? arr3.push(doc.data().type) : null
            ))

            var arrLen = [];
            arrLen.push(arr1.length)
            arrLen.push(arr2.length)
            arrLen.push(arr3.length)
            console.log(arrLen)
            window.donught(arrLen);
        });

        // window.donught([20, 10, 40, 50]);


        db.collection("course_enrollment").onSnapshot((snapshot) => {

            setCourseEnrollment(
                snapshot.docs.map((doc) => ({
                    data: doc.data(),
                }))
            );
        });

        var wDay = new Date();
        db.collection("workshop_event_data").where('created_date', '<=', wDay).onSnapshot((snapshot) => {
            setWorkshopCompleted(
                snapshot.docs.map((doc) => ({
                    id: doc.id,
                    data: doc.data(),
                }))
            );
        });

        // var jy = 0;
        // var au = 0;
        // var se = 0;
        // var oc = 0;
        // var no = 0;
        // var de = 0;
        // var be = 0;

        // db.collection("personal_details").onSnapshot((snapshot) => {
        //     snapshot.docs.map((doc) => {
        //         if (doc.data().created_date.toDate() >= Jstart && doc.data().created_date.toDate() <= Aend) {
        //             jy = jy += 1
        //         }

        //         if (doc.data().created_date.toDate() >= Astart && doc.data().created_date.toDate() <= Aend) {
        //             au = au += 1
        //         }

        //         if (doc.data().created_date.toDate() >= Sstart && doc.data().created_date.toDate() <= Send) {
        //             se = se += 1
        //         }

        //         if (doc.data().created_date.toDate() >= Ostart && doc.data().created_date.toDate() <= Oend) {
        //             oc = oc += 1
        //         }

        //         if (doc.data().created_date.toDate() >= Nstart && doc.data().created_date.toDate() <= Nend) {
        //             no = no += 1
        //         }

        //         if (doc.data().created_date.toDate() >= Dstart && doc.data().created_date.toDate() <= Dend) {
        //             de = de += 1
        //         }
        //         if (doc.data().created_date.toDate() >= Bstart && doc.data().created_date.toDate() <= Bend) {
        //             be = be += 1
        //         }
        //     })
        //     setAppdownload([jy, au, se, oc, no, de])
        //     // window.websiteView([50, 20, 10, 22, 50, 10]);
        //     window.websiteView([au, se, oc, no, de, be]);
        //     console.log(appDownload);

        // });

        const currentDate = new Date(); // Current date

        const appDownload = new Array(6).fill(0); // Initialize an array to store counts for each month

        const monthNames = [
            "Jan", "Feb", "Mar", "Apr", "May", "Jun",
            "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
        ];

        const currentMonth = monthNames[currentDate.getMonth()];
        // Get the names of the last 5 months
        const lastMonths = [];
        for (let i = 1; i <= 5; i++) {
            const previousMonthIndex = (currentDate.getMonth() - i + 12) % 12; // Handle wrapping from Jan to Dec
            lastMonths.push(monthNames[previousMonthIndex]);
        }
        lastMonths.reverse();

        const mergedMonths = [...lastMonths, currentMonth];
        console.log("Merged Months in Ascending Order:", mergedMonths);

        for (let i = 0; i < 6; i++) {
            const startOfMonth = new Date(
                currentDate.getFullYear(),
                currentDate.getMonth() - i,
                1
            );
            const endOfMonth = new Date(
                currentDate.getFullYear(),
                currentDate.getMonth() - i + 1,
                0,
                23,
                59,
                59
            );

            db.collection("personal_details").onSnapshot((snapshot) => {
                snapshot.docs.map((doc) => {
                    const createdDate = doc.data().created_date.toDate();
                    if (createdDate >= startOfMonth && createdDate <= endOfMonth) {
                        appDownload[5 - i] += 1; // Store data in reverse order
                    }
                });
                const currentMonthNames = monthNames.slice(
                    currentDate.getMonth() - 5,
                    currentDate.getMonth() + 1
                );

                setAppdownload(appDownload);
                window.websiteView(mergedMonths, appDownload);
                // console.log(`currentMonthNames -- ${currentMonthNames}`);
            });
        }

        // var jyP = 0;
        // var auP = 0;
        // var seP = 0;
        // var ocP = 0;
        // var noP = 0;
        // var deP = 0;
        // var beP = 0;

        // db.collection("portfolio_details").onSnapshot((snapshot) => {
        //     snapshot.docs.map((doc) => {
        //         if (doc.data().created_date.toDate() >= Jstart && doc.data().created_date.toDate() <= Aend) {
        //             jyP = jyP += 1
        //         }

        //         if (doc.data().created_date.toDate() >= Astart && doc.data().created_date.toDate() <= Aend) {
        //             auP = auP += 1
        //         }

        //         if (doc.data().created_date.toDate() >= Sstart && doc.data().created_date.toDate() <= Send) {
        //             seP = seP += 1
        //         }

        //         if (doc.data().created_date.toDate() >= Ostart && doc.data().created_date.toDate() <= Oend) {
        //             ocP = ocP += 1
        //         }

        //         if (doc.data().created_date.toDate() >= Nstart && doc.data().created_date.toDate() <= Nend) {
        //             noP = noP += 1
        //         }

        //         if (doc.data().created_date.toDate() >= Dstart && doc.data().created_date.toDate() <= Dend) {
        //             deP = deP += 1
        //         }
        //         if (doc.data().created_date.toDate() >= Bstart && doc.data().created_date.toDate() <= Bend) {
        //             beP = beP += 1
        //         }
        //     })
        //     setPortCreated([jyP, auP, seP, ocP, noP, deP])
        //     window.portoView([auP, seP, ocP, noP, deP, beP]);

        // });

        const portfolioCreated = new Array(6).fill(0);
        for (let i = 0; i < 6; i++) {
            const startOfMonth = new Date(
                currentDate.getFullYear(),
                currentDate.getMonth() - i,
                1
            );
            const endOfMonth = new Date(
                currentDate.getFullYear(),
                currentDate.getMonth() - i + 1,
                0,
                23,
                59,
                59
            );

            db.collection("portfolio_details").onSnapshot((snapshot) => {
                snapshot.docs.map((doc) => {
                    const createdDate = doc.data().created_date.toDate();
                    if (createdDate >= startOfMonth && createdDate <= endOfMonth) {
                        portfolioCreated[5 - i] += 1; // Store data in reverse order
                    }
                });

                const currentMonthNames = monthNames.slice(
                    currentDate.getMonth() - 5,
                    currentDate.getMonth() + 1
                );

                setPortCreated(portfolioCreated);
                window.portoView(mergedMonths, portfolioCreated);
                console.log(portfolioCreated);
            });
        }


        // var jyRE = 0;
        // var auRE = 0;
        // var seRE = 0;
        // var ocRE = 0;
        // var noRE = 0;
        // var deRE = 0;

        // db.collection("repayment_model").where("status", "==", "1").onSnapshot((snapshot) => {
        //     snapshot.docs.map((doc) => {
        //         var amount = parseInt(doc.data().amount); // Parse the amount value to an integer

        //         if (doc.data().updated_date.toDate() >= Jstart && doc.data().updated_date.toDate() <= Aend) {
        //             jyRE += amount;
        //         }

        //         if (doc.data().updated_date.toDate() >= Astart && doc.data().updated_date.toDate() <= Aend) {
        //             auRE += amount;
        //         }

        //         if (doc.data().updated_date.toDate() >= Sstart && doc.data().updated_date.toDate() <= Send) {
        //             seRE += amount;
        //         }

        //         if (doc.data().updated_date.toDate() >= Ostart && doc.data().updated_date.toDate() <= Oend) {
        //             ocRE += amount;
        //         }

        //         if (doc.data().updated_date.toDate() >= Nstart && doc.data().updated_date.toDate() <= Nend) {
        //             noRE += amount;
        //         }

        //         if (doc.data().updated_date.toDate() >= Dstart && doc.data().updated_date.toDate() <= Dend) {
        //             deRE += amount;
        //         }
        //     });
        //     console.log([jyRE, auRE, seRE, ocRE, noRE, deRE])

        //     window.rePayView([jyRE, auRE, seRE, ocRE, noRE, deRE]);
        // });

        // var monthNames = [
        //     "January", "February", "March", "April", "May", "June",
        //     "July", "August", "September", "October", "November", "December"
        // ];

        var repayAmounts = [0, 0, 0, 0, 0, 0];

        db.collection("repayment_model")
            .where("status", "==", "1")
            .onSnapshot((snapshot) => {
                snapshot.docs.forEach((doc) => {
                    var amount = parseInt(doc.data().amount);
                    var updatedDate = doc.data().updated_date.toDate();

                    var currentDate = new Date();
                    var monthIndex =
                        (currentDate.getFullYear() - updatedDate.getFullYear()) * 12 +
                        (currentDate.getMonth() - updatedDate.getMonth());

                    if (monthIndex >= 0 && monthIndex < 6) {
                        repayAmounts[5 - monthIndex] += amount;
                    }
                });

                const currentMonthNames = monthNames.slice(
                    currentDate.getMonth() - 5,
                    currentDate.getMonth() + 1
                );

                // Display month names and corresponding amounts
                // for (var i = 0; i < repayAmounts.length; i++) {
                //     console.log(monthNames[i] + " - " + repayAmounts[i]);
                // }

                window.rePayView(mergedMonths, repayAmounts);
            });




        chartsOne();

        db.collection("all_notification").orderBy("created_date", "desc").where('status', '==', "0").limit(25).onSnapshot((snapshot) => {
            setData(
                snapshot.docs.map((doc) => ({
                    id: doc.id,
                    data: doc.data(),
                }))
            );
        });

        db.collection("all_notification").where('status', '==', "0").onSnapshot((snapshot) => {
            setCount(
                snapshot.docs.map((doc) => ({
                    id: doc.id,
                    data: doc.data(),
                }))
            );
        });

        chartsOne();
    }




    return (
        <>
            <div id="">

                <div id="page-content-wrapper">
                    <img src="/images/menubutton.png" class="menu-toggle" alt="Responsive image" style={{ height: "2rem" }} />
                    <div class="icons">

                        <div class="notification">
                            <a href="#">
                                <div class="notBtn" href="#">
                                    <div class="number">{count.length}</div>
                                    <i class="fas fa-bell"></i>
                                    <div class="box">
                                        <div class="display">
                                            <div class="nothing">
                                                <i class="fas fa-child stick"></i>
                                                <div class="cent">Looks Like your all caught up!</div>
                                            </div>
                                            <div class="cont">
                                                <ul class="" style={{ display: "flex" }}>
                                                    <li class="nav-item" onClick={() => ReadAll()}>
                                                        <a class="nav-link" aria-current="page" href="#">Read All</a>
                                                    </li>
                                                    <li class="nav-item" onClick={() => DeleteAll()}>
                                                        <a class="nav-link" href="#">Delete All</a>
                                                    </li>
                                                </ul>
                                                {/* {notifications.map((notification, index) => ( */}
                                                {data?.map(({ id, data }, index) => (
                                                    <div class="sec new" key={index} onClick={() => handleChange4(id)} style={{ backgroundColor: data.status === "0" ? "#7b809a" : "#fff" }}>
                                                        <Link to={`/${data.link}`}>
                                                            <div class="profCont">
                                                                <img class="profile" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSNYNUR0cDh_AMKk8M1h1TzUHFlcuRBfCsIMg&usqp=CAU" />
                                                            </div>
                                                            <div class="txt"><span style={{ color: "black", fontWeight: "600", fontSize: "14px", textTransform: "capitalize" }}>{data.name}</span> <span style={{ color: data.status === "0" ? "white" : "grey", fontWeight: "400", fontSize: "12px" }}>{data.message}</span></div>
                                                            <div class="txt sub">{moment(data.created_date.toDate().toISOString()).format("MM/DD/YY, h:mm a")}</div>
                                                        </Link>
                                                    </div>
                                                ))}
                                                <li class="nav-item" style={{ textAlign: "center" }}>
                                                    <Link to='/notifications'><a class="nav-link" href="#">View All</a></Link>
                                                </li>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>


                    <div class="">
                        {/* <div class="row">
                            <div class="col-lg-12">
                                <h1>Simple Sidebar</h1>
                                <p>This template has a responsive menu toggling system. The menu will appear collapsed on smaller screens, and will appear non-collapsed on larger screens. When toggled using the button below, the menu will appear/disappear. On small screens, the page content will be pushed off canvas.</p>
                                <p>Make sure to keep all page content within the <code>#page-content-wrapper</code>.</p>
                                <a href="#menu-toggle" onClick={() => tog()} class="btn btn-default menu-toggle">Toggle Menu</a>
                            </div>
                        </div> */}
                        <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
                            {/* <div class="col-lg-12" style={{ display: "inline-flex", justifyContent: "end" }}>
        <li class="nav-item dropdown pe-2 d-flex align-items-center">
            <a href="javascript:;" class="nav-link text-body p-0" id="dropdownMenuButton" data-bs-toggle="dropdown"
                aria-expanded="false">
                <i class="fa fa-bell cursor-pointer fa-lg"></i>
            </a>

        </li>
    </div> */}

                            {activeIndex === null ? <>
                                <div class="row pt-5">
                                    <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
                                        <div class="card">
                                            <div class="card-header p-2 pt-1" style={{ borderStyle: "none" }}>
                                                <div
                                                    class="icon-md icon-shape bg-gradient-dark shadow-dark text-center border-radius-xl mt-n4 position-absolute">
                                                    <i class="material-icons opacity-10">weekend</i>
                                                </div>
                                                <div class="text-end pt-1">
                                                    <p class="mb-0 text-capitalize" style={{ fontWeight: "bold", fontSize: "13.1px" }}>Apps Download</p>
                                                    <h4 class="mb-0" style={{ fontWeight: "700", fontSize: "22px" }}>{udata.length}</h4>
                                                </div>
                                            </div>
                                            <hr class="dark horizontal my-0" />
                                            <div class="card-footer p-1" style={{ borderStyle: "none" }}>
                                                <div class="row">
                                                    <div class="pr-4" style={{ display: "flex", justifyContent: "end" }}>
                                                        <span class="badge badge-warning dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ cursor: "pointer", backgroundColor: "#ec407a", color: "white" }}>Filter</span>
                                                        <div class="dropdown-menu">
                                                            <a class="dropdown-item" href="#" onClick={() => downloadDaily()}>Daily</a>
                                                            <a class="dropdown-item" href="#" onClick={() => downloadWeekly()}>Weekly</a>
                                                            <a class="dropdown-item" href="#" onClick={() => downloadMonthely()}>Monthly</a>
                                                            <a class="dropdown-item" href="#" onClick={() => downloadQuaterly()}>Quarterly</a>
                                                            <a class="dropdown-item" href="#" onClick={() => downloadYearly()}>Yearly</a>
                                                            <a class="dropdown-item" href="#" onClick={() => downloadPreMonthly()}>Prev month</a>
                                                            <a class="dropdown-item" href="#" onClick={() => downloadYearlyAll()}>All</a>
                                                            {/* <div class="dropdown-divider"></div>
                                                <a class="dropdown-item" href="#">Separated link</a> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
                                        <div class="card">
                                            <div class="card-header p-2 pt-1" style={{ borderStyle: "none" }}>
                                                <div
                                                    class="icon-md icon-shape bg-gradient-primary shadow-primary text-center border-radius-xl mt-n4 position-absolute">
                                                    <i class="material-icons opacity-10">person</i>
                                                </div>
                                                <div class="text-end pt-1">
                                                    <p class="mb-0 text-capitalize" style={{ fontWeight: "bold", fontSize: "13.1px" }}>Total Revenue</p>
                                                    <h4 class="mb-0" style={{ fontWeight: "700", fontSize: "22px" }}>₹ {rev}</h4>
                                                </div>
                                            </div>
                                            <hr class="dark horizontal my-0" />
                                            <div class="card-footer p-1" style={{ borderStyle: "none" }}>
                                                <div class="row">
                                                    <div class="pr-4" style={{ display: "flex", justifyContent: "end" }}>
                                                        <span class="badge badge-warning dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ cursor: "pointer", backgroundColor: "#ec407a", color: "white" }}>Filter</span>
                                                        <div class="dropdown-menu">
                                                            <a class="dropdown-item" href="#">Monthly</a>
                                                            <a class="dropdown-item" href="#">Quarterly</a>
                                                            <a class="dropdown-item" href="#">Yearly</a>
                                                            {/* <div class="dropdown-divider"></div>
                                                <a class="dropdown-item" href="#">Separated link</a> */}
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
                                        <div class="card">
                                            <div class="card-header p-2 pt-1" style={{ borderStyle: "none" }}>
                                                <div
                                                    class="icon-md icon-shape bg-gradient-success shadow-success text-center border-radius-xl mt-n4 position-absolute">
                                                    <i class="material-icons opacity-10">person</i>
                                                </div>
                                                <div class="text-end pt-1">
                                                    <p class="mb-0 text-capitalize" style={{ fontWeight: "bold", fontSize: "13.1px" }}>Workshop Completed</p>
                                                    <h4 class="mb-0" style={{ fontWeight: "700", fontSize: "22px" }}>{workshopCompleted.length}</h4>
                                                </div>
                                            </div>
                                            <hr class="dark horizontal my-0" />
                                            <div class="card-footer p-1" style={{ borderStyle: "none" }}>
                                                <div class="row">


                                                    <div class="pr-4" style={{ display: "flex", justifyContent: "end" }}>
                                                        <span class="badge badge-warning dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ cursor: "pointer", backgroundColor: "#ec407a", color: "white" }}>Filter</span>
                                                        <div class="dropdown-menu">
                                                            <a class="dropdown-item" href="#" onClick={() => workshopMonthely()}>Monthly</a>
                                                            <a class="dropdown-item" href="#" onClick={() => workshopQuaterly()}>Quarterly</a>
                                                            <a class="dropdown-item" href="#" onClick={() => workshopYearly()}>Yearly</a>
                                                            <a class="dropdown-item" href="#" onClick={() => workshopAll()}>All</a>
                                                            {/* <div class="dropdown-divider"></div>
                                                <a class="dropdown-item" href="#">Separated link</a> */}
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-3 col-sm-6">
                                        <div class="card">
                                            <div class="card-header p-2 pt-1" style={{ borderStyle: "none" }}>
                                                <div
                                                    class="icon-md icon-shape bg-gradient-info shadow-info text-center border-radius-xl mt-n4 position-absolute">
                                                    <i class="material-icons opacity-10">weekend</i>
                                                </div>
                                                <div class="text-end pt-1">
                                                    <p class="mb-0 text-capitalize" style={{ fontWeight: "bold", fontSize: "13.1px" }}>Portfolio Created</p>
                                                    <h4 class="mb-0" style={{ fontWeight: "700", fontSize: "22px" }}>{porto.length}</h4>
                                                </div>
                                            </div>
                                            <hr class="dark horizontal my-0" />
                                            <div class="card-footer p-1" style={{ borderStyle: "none" }}>
                                                <div class="row">
                                                    <div class="pr-4" style={{ display: "flex", justifyContent: "end" }}>
                                                        <span class="badge badge-warning dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ cursor: "pointer", backgroundColor: "#ec407a", color: "white" }}>Filter</span>
                                                        <div class="dropdown-menu">
                                                            <a class="dropdown-item" href="#" onClick={() => portoDaily()}>Daily</a>
                                                            <a class="dropdown-item" href="#" onClick={() => portoWeekly()}>Weekly</a>
                                                            <a class="dropdown-item" href="#" onClick={() => portoMonthely()}>Monthly</a>
                                                            <a class="dropdown-item" href="#" onClick={() => portoQuaterly()}>Quarterly</a>
                                                            <a class="dropdown-item" href="#" onClick={() => portoYearly()}>Yearly</a>
                                                            <a class="dropdown-item" href="#" onClick={() => portoAll()}>All</a>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {viewMore ? <><div class="p-3"></div></> : <></>}

                                {viewMore ?
                                    <>
                                        <div class="row">
                                            <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
                                                <div class="card">
                                                    <div class="card-header p-2 pt-1" style={{ borderStyle: "none" }}>
                                                        <div
                                                            class="icon-md icon-shape bg-gradient-success shadow-success text-center border-radius-xl mt-n4 position-absolute">
                                                            <i class="material-icons opacity-10">person</i>
                                                        </div>
                                                        <div class="text-end pt-1">
                                                            <p class="mb-0 text-capitalize" style={{ fontWeight: "bold", fontSize: "13.1px" }}>Total Loan Application</p>
                                                            <h4 class="mb-0" style={{ fontWeight: "700", fontSize: "22px" }}>{loan}</h4>
                                                        </div>
                                                    </div>
                                                    <hr class="dark horizontal my-0" />
                                                    <div class="card-footer p-1" style={{ borderStyle: "none" }}>
                                                        <div class="row">

                                                            <div class="pr-4" style={{ display: "flex", justifyContent: "end" }}>
                                                                <span class="badge badge-warning dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ cursor: "pointer", backgroundColor: "#ec407a", color: "white" }}>Filter</span>
                                                                <div class="dropdown-menu">
                                                                    <a class="dropdown-item" href="#" onClick={() => loanDisAll()}>Total Loan Disbursed</a>
                                                                    {/* <a class="dropdown-item" href="#" onClick={() => loanMonthely()}>Amount Disbursed</a> */}
                                                                    {/* ₹ {disbAmount.toLocaleString()} */}
                                                                    {/* ₹ {repayAmt.toLocaleString()} */}
                                                                    {/* <a class="dropdown-item" href="#" onClick={() => loanQuaterly()}>Repayment Amount</a> */}
                                                                    <a class="dropdown-item" href="#" onClick={() => loanAll()}>Total Loan Applications</a>
                                                                </div>
                                                            </div>


                                                            {/* <div class="pr-4" style={{ display: "flex", justifyContent: "end" }}>
                                                                <span class="badge badge-warning dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ cursor: "pointer", backgroundColor: "#ec407a", color: "white" }}>Filter</span>
                                                                <div class="dropdown-menu">
                                                                    <a class="dropdown-item" href="#" onClick={() => loanWeekly()}>Weekly</a>
                                                                    <a class="dropdown-item" href="#" onClick={() => loanMonthely()}>Monthly</a>
                                                                    <a class="dropdown-item" href="#" onClick={() => loanQuaterly()}>Quarterly</a>
                                                                    <a class="dropdown-item" href="#" onClick={() => loanYearly()}>Yearly</a>
                                                                    <a class="dropdown-item" href="#" onClick={() => loanAll()}>All</a>
                                                                </div>
                                                            </div> */}
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>


                                            <div class="col-xl-3 col-sm-6">
                                                <div class="card">
                                                    <div class="card-header p-2 pt-1" style={{ borderStyle: "none" }}>
                                                        <div
                                                            class="icon-md icon-shape bg-gradient-info shadow-info text-center border-radius-xl mt-n4 position-absolute">
                                                            <i class="material-icons opacity-10">weekend</i>
                                                        </div>
                                                        <div class="text-end pt-1">
                                                            <p class="mb-0 text-capitalize" style={{ fontWeight: "bold", fontSize: "13.1px" }}>Amount Disbursed</p>
                                                            <h4 class="mb-0" style={{ fontWeight: "700", fontSize: "22px" }}>₹ {finaldis === "dis" ? disbAmount.toLocaleString() : repayAmt.toLocaleString()}</h4>
                                                        </div>
                                                    </div>
                                                    <hr class="dark horizontal my-0" />
                                                    <div class="card-footer p-1" style={{ borderStyle: "none" }}>
                                                        <div class="row">
                                                            <div class="pr-4" style={{ display: "flex", justifyContent: "end" }}>
                                                                <span class="badge badge-warning dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ cursor: "pointer", backgroundColor: "#ec407a", color: "white" }}>Filter</span>
                                                                <div class="dropdown-menu">
                                                                    <a class="dropdown-item" href="#" onClick={() => diss()}>Amount Disbursed</a>
                                                                    <a class="dropdown-item" href="#" onClick={() => ree()}>Repayment Amount</a>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-xl-3 col-sm-6">
                                                <div class="card">
                                                    <div class="card-header p-2 pt-1" style={{ borderStyle: "none" }}>
                                                        <div
                                                            class="icon-md icon-shape bg-gradient-info shadow-info text-center border-radius-xl mt-n4 position-absolute">
                                                            <i class="material-icons opacity-10">weekend</i>
                                                        </div>
                                                        <div class="text-end pt-1">
                                                            <p class="mb-0 text-capitalize" style={{ fontWeight: "bold", fontSize: "13.1px" }}>Customer Reviews</p>
                                                            <h4 class="mb-0" style={{ fontWeight: "700", fontSize: "22px" }}> {reviewCount.length}</h4>
                                                        </div>
                                                    </div>

                                                    <hr class="dark horizontal my-0" />
                                                    <div class="card-footer p-1" style={{ borderStyle: "none" }}>
                                                        <div class="row">
                                                            <div class="pr-4" style={{ display: "flex", justifyContent: "end" }}>
                                                                <span class="badge badge-warning dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ cursor: "pointer", backgroundColor: "#ec407a", color: "white" }}>Filter</span>
                                                                <div class="dropdown-menu">
                                                                    <a class="dropdown-item" href="#" onClick={() => fetchParlourFeedbackWeekly()}>Weekly</a>
                                                                    <a class="dropdown-item" href="#" onClick={() => fetchParlourFeedbackMonthly()}>Monthly</a>
                                                                    <a class="dropdown-item" href="#" onClick={() => fetchParlourFeedbackQuarterly()}>Quaterly</a>
                                                                    <a class="dropdown-item" href="#" onClick={() => fetchParlourFeedbackYearly()}>Yearly</a>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-xl-3 col-sm-6">
                                                <div class="card">
                                                    <div class="card-header p-2 pt-1" style={{ borderStyle: "none" }}>
                                                        <div
                                                            class="icon-md icon-shape bg-gradient-info shadow-info text-center border-radius-xl mt-n4 position-absolute">
                                                            <i class="material-icons opacity-10">weekend</i>
                                                        </div>
                                                        <div class="text-end pt-1">
                                                            <p class="mb-0 text-capitalize" style={{ fontWeight: "bold", fontSize: "13.1px" }}>All Workshop Reg.</p>
                                                            <h4 class="mb-0" style={{ fontWeight: "700", fontSize: "22px" }}> {workshopRegistration.length}</h4>
                                                        </div>
                                                    </div>
                                                    <hr class="dark horizontal my-0" />
                                                    <div class="card-footer p-1" style={{ borderStyle: "none" }}>
                                                        <div class="row">
                                                            <div class="pr-4" style={{ display: "flex", justifyContent: "end" }}>
                                                                <span class="badge badge-warning dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ cursor: "pointer", backgroundColor: "#fff", color: "white" }}>Filter</span>

                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>


                                            {/* <div class="col-xl-3 col-sm-6">
                                                <div class="card">
                                                    <div class="card-header p-2 pt-1" style={{ borderStyle: "none" }}>
                                                        <div
                                                            class="icon-md icon-shape bg-gradient-info shadow-info text-center border-radius-xl mt-n4 position-absolute">
                                                            <i class="material-icons opacity-10">weekend</i>
                                                        </div>
                                                        <div class="text-end pt-1">
                                                            <p class="mb-0 text-capitalize" style={{ fontWeight: "bold", fontSize: "13.1px" }}>Repayment Amount</p>
                                                            <h4 class="mb-0" style={{ fontWeight: "700", fontSize: "22px" }}>₹ {repayAmt.toLocaleString()}</h4>
                                                        </div>
                                                    </div>
                                                    <hr class="dark horizontal my-0" />
                                                    <div class="card-footer p-1" style={{ borderStyle: "none" }}>
                                                        <div class="row">
                                                            <div class="pr-4" style={{ display: "flex", justifyContent: "end" }}>
                                                                <span class="badge badge-warning dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ cursor: "pointer", backgroundColor: "#fff", color: "white" }}>Filter</span>

                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div> */}


                                            {/* <div class="col-xl-3 col-sm-6">
                                            <div class="card">
                                                <div class="card-header p-2 pt-1" style={{ borderStyle: "none" }}>
                                                    <div
                                                        class="icon-md icon-shape bg-gradient-info shadow-info text-center border-radius-xl mt-n4 position-absolute">
                                                        <i class="material-icons opacity-10">weekend</i>
                                                    </div>
                                                    <div class="text-end pt-1">
                                                        <p class="mb-0 text-capitalize" style={{ fontWeight: "bold", fontSize: "13.1px" }}>Total Demo Booked</p>
                                                        <h4 class="mb-0" style={{ fontWeight: "700", fontSize: "22px" }}>0</h4>
                                                    </div>
                                                </div>
                                                <hr class="dark horizontal my-0" />
                                                <div class="card-footer p-1" style={{ borderStyle: "none" }}>
                                                    <div class="row">
                                                        <div class="pr-4" style={{ display: "flex", justifyContent: "end" }}>
                                                            <span class="badge badge-warning dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ cursor: "pointer", backgroundColor: "#ec407a", color: "white" }}>Filter</span>
                                                            <div class="dropdown-menu">
                                                                <a class="dropdown-item" href="#">Monthly</a>
                                                                <a class="dropdown-item" href="#">Quarterly</a>
                                                                <a class="dropdown-item" href="#">Yearly</a>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div> */}
                                            {/* <div class="col-xl-3 col-sm-6">
                                            <div class="card">
                                                <div class="card-header p-2 pt-1" style={{ borderStyle: "none" }}>
                                                    <div
                                                        class="icon-md icon-shape bg-gradient-info shadow-info text-center border-radius-xl mt-n4 position-absolute">
                                                        <i class="material-icons opacity-10">weekend</i>
                                                    </div>
                                                    <div class="text-end pt-1">
                                                        <p class="mb-0 text-capitalize" style={{ fontWeight: "bold", fontSize: "13.1px" }}>Course Enrollment</p>
                                                        <h4 class="mb-0" style={{ fontWeight: "700", fontSize: "22px" }}>{courseEnrollment.length}</h4>
                                                    </div>
                                                </div>
                                                <hr class="dark horizontal my-0" />
                                                <div class="card-footer p-1" style={{ borderStyle: "none" }}>
                                                    <div class="row">
                                                        <div class="pr-4" style={{ display: "flex", justifyContent: "end" }}>
                                                            <span class="badge badge-warning dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ cursor: "pointer", backgroundColor: "#ec407a", color: "white" }}>Filter</span>
                                                            <div class="dropdown-menu">
                                                                <a class="dropdown-item" href="#">Monthly</a>
                                                                <a class="dropdown-item" href="#">Quarterly</a>
                                                                <a class="dropdown-item" href="#">Yearly</a>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div> */}
                                        </div>

                                        <div class="row">
                                            <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
                                                <div class="card">
                                                    <div class="card-header p-2 pt-1" style={{ borderStyle: "none" }}>
                                                        <div
                                                            class="icon-md icon-shape bg-gradient-success shadow-success text-center border-radius-xl mt-n4 position-absolute">
                                                            <i class="material-icons opacity-10">person</i>
                                                        </div>
                                                        <div class="text-end pt-1">
                                                            <p class="mb-0 text-capitalize" style={{ fontWeight: "bold", fontSize: "13.1px" }}>Visiting Card Request</p>
                                                            <h4 class="mb-0" style={{ fontWeight: "700", fontSize: "22px" }}>{bcardRequested}</h4>
                                                        </div>
                                                    </div>
                                                    <hr class="dark horizontal my-0" />
                                                    <div class="card-footer p-1" style={{ borderStyle: "none" }}>
                                                        <div class="row">


                                                            <div class="pr-4" style={{ display: "flex", justifyContent: "end" }}>
                                                                <span class="badge badge-warning dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ cursor: "pointer", backgroundColor: "#ec407a", color: "white" }}>Filter</span>
                                                                <div class="dropdown-menu">
                                                                    <a class="dropdown-item" href="#" onClick={() => BCweek()}>Weekly</a>
                                                                    <a class="dropdown-item" href="#" onClick={() => BCmon()}>Monthly</a>
                                                                    <a class="dropdown-item" href="#" onClick={() => BCQuater()}>Quarterly</a>
                                                                    <a class="dropdown-item" href="#" onClick={() => BCall()}>All</a>
                                                                    {/* <div class="dropdown-divider"></div>
                                                <a class="dropdown-item" href="#">Separated link</a> */}
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
                                                <div class="card">
                                                    <div class="card-header p-2 pt-1" style={{ borderStyle: "none" }}>
                                                        <div
                                                            class="icon-md icon-shape bg-gradient-success shadow-success text-center border-radius-xl mt-n4 position-absolute">
                                                            <i class="material-icons opacity-10">person</i>
                                                        </div>
                                                        <div class="text-end pt-1">
                                                            <p class="mb-0 text-capitalize" style={{ fontWeight: "bold", fontSize: "13.1px" }}>visiting cards created</p>
                                                            <h4 class="mb-0" style={{ fontWeight: "700", fontSize: "22px" }}>{totalDataLength == 0 ? businessCard.length + businessCardPre.length : totalDataLength}</h4>
                                                        </div>
                                                    </div>
                                                    <hr class="dark horizontal my-0" />
                                                    <div class="card-footer p-1" style={{ borderStyle: "none" }}>
                                                        <div class="row">


                                                            <div class="pr-4" style={{ display: "flex", justifyContent: "end" }}>
                                                                <span class="badge badge-warning dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ cursor: "pointer", backgroundColor: "#ec407a", color: "white" }}>Filter</span>
                                                                <div class="dropdown-menu">
                                                                    <a class="dropdown-item" href="#" onClick={() => FreeCard()}>Free</a>
                                                                    <a class="dropdown-item" href="#" onClick={() => PreCard()}>Premium</a>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div> */}
                                            <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
                                                <div class="card">
                                                    <div class="card-header p-2 pt-1" style={{ borderStyle: "none" }}>
                                                        <div
                                                            class="icon-md icon-shape bg-gradient-info shadow-info text-center border-radius-xl mt-n4 position-absolute">
                                                            <i class="material-icons opacity-10">person</i>
                                                        </div>
                                                        <div class="text-end pt-1">
                                                            <p class="mb-0 text-capitalize" style={{ fontWeight: "bold", fontSize: "13.1px" }}>Banner Request</p>
                                                            <h4 class="mb-0" style={{ fontWeight: "700", fontSize: "22px" }}>{bnrcardRequested}</h4>
                                                        </div>
                                                    </div>
                                                    <hr class="dark horizontal my-0" />
                                                    <div class="card-footer p-1" style={{ borderStyle: "none" }}>
                                                        <div class="row">


                                                            <div class="pr-4" style={{ display: "flex", justifyContent: "end" }}>
                                                                <span class="badge badge-warning dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ cursor: "pointer", backgroundColor: "#ec407a", color: "white" }}>Filter</span>
                                                                <div class="dropdown-menu">
                                                                    <a class="dropdown-item" href="#" onClick={() => BNRweek()}>Weekly</a>
                                                                    <a class="dropdown-item" href="#" onClick={() => BNRmon()}>Monthly</a>
                                                                    <a class="dropdown-item" href="#" onClick={() => BNRQuater()}>Quarterly</a>
                                                                    <a class="dropdown-item" href="#" onClick={() => BNRall()}>All</a>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>

                                                </div>
                                            </div>
                                            {/* <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
                                                <div class="card">
                                                    <div class="card-header p-2 pt-1" style={{ borderStyle: "none" }}>
                                                        <div
                                                            class="icon-md icon-shape bg-gradient-success shadow-success text-center border-radius-xl mt-n4 position-absolute">
                                                            <i class="material-icons opacity-10">person</i>
                                                        </div>
                                                        <div class="text-end pt-1">
                                                            <p class="mb-0 text-capitalize" style={{ fontWeight: "bold", fontSize: "13.1px" }}>Banner created</p>
                                                            <h4 class="mb-0" style={{ fontWeight: "700", fontSize: "22px" }}>{totalBannerLength == 0 ? bannerCard.length + bannercardLengthPre.length : totalBannerLength}</h4>
                                                        </div>
                                                    </div>
                                                    <hr class="dark horizontal my-0" />
                                                    <div class="card-footer p-1" style={{ borderStyle: "none" }}>
                                                        <div class="row">


                                                            <div class="pr-4" style={{ display: "flex", justifyContent: "end" }}>
                                                                <span class="badge badge-warning dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ cursor: "pointer", backgroundColor: "#ec407a", color: "white" }}>Filter</span>
                                                                <div class="dropdown-menu">
                                                                    <a class="dropdown-item" href="#" onClick={() => FreeBanner()}>Free</a>
                                                                    <a class="dropdown-item" href="#" onClick={() => PreBanner()}>Premium</a>
                                                                    <a class="dropdown-item" href="#" onClick={() => AllBanner()}>All</a>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div> */}

                                            {/* <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
                                                <div class="card">
                                                    <div class="card-header p-2 pt-1" style={{ borderStyle: "none" }}>
                                                        <div
                                                            class="icon-md icon-shape bg-gradient-success shadow-success text-center border-radius-xl mt-n4 position-absolute">
                                                            <i class="material-icons opacity-10">person</i>
                                                        </div>
                                                        <div class="text-end pt-1">
                                                            <p class="mb-0 text-capitalize" style={{ fontWeight: "bold", fontSize: "13.1px" }}>SMG created</p>
                                                            <h4 class="mb-0" style={{ fontWeight: "700", fontSize: "22px" }}>{totalSmgLength == 0 ? smgCard.length + smgCardPre.length : totalSmgLength}</h4>
                                                        </div>
                                                    </div>
                                                    <hr class="dark horizontal my-0" />
                                                    <div class="card-footer p-1" style={{ borderStyle: "none" }}>
                                                        <div class="row">


                                                            <div class="pr-4" style={{ display: "flex", justifyContent: "end" }}>
                                                                <span class="badge badge-warning dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ cursor: "pointer", backgroundColor: "#ec407a", color: "white" }}>Filter</span>
                                                                <div class="dropdown-menu">
                                                                    <a class="dropdown-item" href="#" onClick={() => FreeSmg()}>Free</a>
                                                                    <a class="dropdown-item" href="#" onClick={() => PreSmg()}>Premium</a>
                                                                    <a class="dropdown-item" href="#" onClick={() => AllSmg()}>All</a>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div> */}
                                            <div class="col-xl-3 col-sm-6">
                                                <div class="card">
                                                    <div class="card-header p-2 pt-1" style={{ borderStyle: "none" }}>
                                                        <div
                                                            class="icon-md icon-shape bg-gradient-info shadow-info text-center border-radius-xl mt-n4 position-absolute">
                                                            <i class="material-icons opacity-10">weekend</i>
                                                        </div>
                                                        <div class="text-end pt-1">
                                                            <p class="mb-0 text-capitalize" style={{ fontWeight: "bold", fontSize: "13.1px" }}>SMG Request</p>
                                                            <h4 class="mb-0" style={{ fontWeight: "700", fontSize: "22px" }}> {smgcardRequested}</h4>
                                                        </div>
                                                    </div>
                                                    <hr class="dark horizontal my-0" />
                                                    <div class="card-footer p-1" style={{ borderStyle: "none" }}>
                                                        <div class="row">
                                                            <div class="pr-4" style={{ display: "flex", justifyContent: "end" }}>
                                                                <span class="badge badge-warning dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ cursor: "pointer", backgroundColor: "#ec407a", color: "white" }}>Filter</span>
                                                                <div class="dropdown-menu">
                                                                    <a class="dropdown-item" href="#" onClick={() => SMGweek()}>Weekly</a>
                                                                    <a class="dropdown-item" href="#" onClick={() => SMGmon()}>Monthly</a>
                                                                    <a class="dropdown-item" href="#" onClick={() => SMGQuater()}>Quaterly</a>
                                                                    <a class="dropdown-item" href="#" onClick={() => SMGall()}>All</a>
                                                                    {/* <div class="dropdown-divider"></div>
                                                <a class="dropdown-item" href="#">Separated link</a> */}
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
                                                <div class="card">
                                                    <div class="card-header p-2 pt-1" style={{ borderStyle: "none" }}>
                                                        <div
                                                            class="icon-md icon-shape bg-gradient-info shadow-info text-center border-radius-xl mt-n4 position-absolute">
                                                            <i class="material-icons opacity-10">person</i>
                                                        </div>
                                                        <div class="text-end pt-1">
                                                            <p class="mb-0 text-capitalize" style={{ fontWeight: "bold", fontSize: "13.1px" }}>Whatsapp Business</p>
                                                            <h4 class="mb-0" style={{ fontWeight: "700", fontSize: "22px" }}>{whatsappBusiness.length}</h4>
                                                        </div>
                                                    </div>
                                                    <hr class="dark horizontal my-0" />
                                                    <div class="card-footer p-1" style={{ borderStyle: "none" }}>
                                                        <div class="row">
                                                            <div class="pr-4" style={{ display: "flex", justifyContent: "end" }}>
                                                                <span class="badge badge-warning dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ cursor: "pointer", backgroundColor: "#ec407a", color: "white" }}>Filter</span>
                                                                <div class="dropdown-menu">
                                                                    <a class="dropdown-item" href="#" onClick={() => fetchWhatsappBusinessWeekly()}>Weekly</a>
                                                                    <a class="dropdown-item" href="#" onClick={() => fetchWhatsappBusinessMonthly()}>Monthly</a>
                                                                    <a class="dropdown-item" href="#" onClick={() => fetchWhatsappBusinessQuarterly()}>Quaterly</a>
                                                                    <a class="dropdown-item" href="#" onClick={() => fetchWhatsappBusinessYearly()}>All</a>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>

                                                </div>
                                            </div>


                                        </div>



                                        <div class="row">
                                            <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
                                                <div class="card">
                                                    <div class="card-header p-2 pt-1" style={{ borderStyle: "none" }}>
                                                        <div
                                                            class="icon-md icon-shape bg-gradient-success shadow-success text-center border-radius-xl mt-n4 position-absolute">
                                                            <i class="material-icons opacity-10">person</i>
                                                        </div>
                                                        <div class="text-end pt-1">
                                                            <p class="mb-0 text-capitalize" style={{ fontWeight: "bold", fontSize: "13.1px" }}>Google My Business</p>
                                                            <h4 class="mb-0" style={{ fontWeight: "700", fontSize: "22px" }}>{googleBusiness.length}</h4>
                                                        </div>
                                                    </div>
                                                    <hr class="dark horizontal my-0" />
                                                    <div class="card-footer p-1" style={{ borderStyle: "none" }}>
                                                        <div class="row">


                                                            <div class="pr-4" style={{ display: "flex", justifyContent: "end" }}>
                                                                <span class="badge badge-warning dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ cursor: "pointer", backgroundColor: "#ec407a", color: "white" }}>Filter</span>
                                                                <div class="dropdown-menu">
                                                                    <a class="dropdown-item" href="#" onClick={() => fetchGMBWeekly()}>Weekly</a>
                                                                    <a class="dropdown-item" href="#" onClick={() => fetchGMBMonthly()}>Monthly</a>
                                                                    <a class="dropdown-item" href="#" onClick={() => fetchGMBQuarterly()}>Quarterly</a>
                                                                    <a class="dropdown-item" href="#" onClick={() => fetchGMBYearly()}>Yearly</a>
                                                                </div>

                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
                                                <div class="card">
                                                    <div class="card-header p-2 pt-1" style={{ borderStyle: "none" }}>
                                                        <div
                                                            class="icon-md icon-shape bg-gradient-info shadow-info text-center border-radius-xl mt-n4 position-absolute">
                                                            <i class="material-icons opacity-10">person</i>
                                                        </div>
                                                        <div class="text-end pt-1">
                                                            <p class="mb-0 text-capitalize" style={{ fontWeight: "bold", fontSize: "13.1px" }}>Total Appointments</p>
                                                            <h4 class="mb-0" style={{ fontWeight: "700", fontSize: "22px" }}>{appointments}</h4>
                                                        </div>
                                                    </div>
                                                    <hr class="dark horizontal my-0" />
                                                    <div class="card-footer p-1" style={{ borderStyle: "none" }}>
                                                        <div class="row">


                                                            <div class="pr-4" style={{ display: "flex", justifyContent: "end" }}>
                                                                <span class="badge badge-warning dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ cursor: "pointer", backgroundColor: "#ec407a", color: "white" }}>Filter</span>
                                                                <div class="dropdown-menu">
                                                                    <a class="dropdown-item" href="#" onClick={() => APONweek()}>Weekly</a>
                                                                    <a class="dropdown-item" href="#" onClick={() => APONmon()}>Monthly</a>
                                                                    <a class="dropdown-item" href="#" onClick={() => APONQuater()}>Quaterly</a>
                                                                    <a class="dropdown-item" href="#" onClick={() => APONall()}>All</a>
                                                                    {/* <div class="dropdown-divider"></div>
                                                <a class="dropdown-item" href="#">Separated link</a> */}
                                                                </div>
                                                            </div>

                                                        </div>

                                                    </div>

                                                </div>
                                            </div>






                                        </div>


                                        <div class="row">
                                            {/* <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
                                                <div class="card">
                                                    <div class="card-header p-2 pt-1" style={{ borderStyle: "none" }}>
                                                        <div
                                                            class="icon-md icon-shape bg-gradient-success shadow-success text-center border-radius-xl mt-n4 position-absolute">
                                                            <i class="material-icons opacity-10">person</i>
                                                        </div>
                                                        <div class="text-end pt-1">
                                                            <p class="mb-0 text-capitalize" style={{ fontWeight: "bold", fontSize: "13.1px" }}>Visiting Card Request</p>
                                                            <h4 class="mb-0" style={{ fontWeight: "700", fontSize: "22px" }}>{bcardRequested}</h4>
                                                        </div>
                                                    </div>
                                                    <hr class="dark horizontal my-0" />
                                                    <div class="card-footer p-1" style={{ borderStyle: "none" }}>
                                                        <div class="row">


                                                            <div class="pr-4" style={{ display: "flex", justifyContent: "end" }}>
                                                                <span class="badge badge-warning dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ cursor: "pointer", backgroundColor: "#ec407a", color: "white" }}>Filter</span>
                                                                <div class="dropdown-menu">
                                                                    <a class="dropdown-item" href="#" onClick={() => BCweek()}>Weekly</a>
                                                                    <a class="dropdown-item" href="#" onClick={() => BCmon()}>Monthly</a>
                                                                    <a class="dropdown-item" href="#" onClick={() => BCall()}>All</a>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div> */}

                                            {/* <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
                                                <div class="card">
                                                    <div class="card-header p-2 pt-1" style={{ borderStyle: "none" }}>
                                                        <div
                                                            class="icon-md icon-shape bg-gradient-info shadow-info text-center border-radius-xl mt-n4 position-absolute">
                                                            <i class="material-icons opacity-10">person</i>
                                                        </div>
                                                        <div class="text-end pt-1">
                                                            <p class="mb-0 text-capitalize" style={{ fontWeight: "bold", fontSize: "13.1px" }}>Banner Request</p>
                                                            <h4 class="mb-0" style={{ fontWeight: "700", fontSize: "22px" }}>{bnrcardRequested}</h4>
                                                        </div>
                                                    </div>
                                                    <hr class="dark horizontal my-0" />
                                                    <div class="card-footer p-1" style={{ borderStyle: "none" }}>
                                                        <div class="row">


                                                            <div class="pr-4" style={{ display: "flex", justifyContent: "end" }}>
                                                                <span class="badge badge-warning dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ cursor: "pointer", backgroundColor: "#ec407a", color: "white" }}>Filter</span>
                                                                <div class="dropdown-menu">
                                                                    <a class="dropdown-item" href="#" onClick={() => BNRweek()}>Weekly</a>
                                                                    <a class="dropdown-item" href="#" onClick={() => BNRmon()}>Monthly</a>
                                                                    <a class="dropdown-item" href="#" onClick={() => BNRall()}>All</a>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>

                                                </div>
                                            </div> */}

                                            {/* <div class="col-xl-3 col-sm-6">
                                                <div class="card">
                                                    <div class="card-header p-2 pt-1" style={{ borderStyle: "none" }}>
                                                        <div
                                                            class="icon-md icon-shape bg-gradient-info shadow-info text-center border-radius-xl mt-n4 position-absolute">
                                                            <i class="material-icons opacity-10">weekend</i>
                                                        </div>
                                                        <div class="text-end pt-1">
                                                            <p class="mb-0 text-capitalize" style={{ fontWeight: "bold", fontSize: "13.1px" }}>SMG Request</p>
                                                            <h4 class="mb-0" style={{ fontWeight: "700", fontSize: "22px" }}> {smgcardRequested}</h4>
                                                        </div>
                                                    </div>
                                                    <hr class="dark horizontal my-0" />
                                                    <div class="card-footer p-1" style={{ borderStyle: "none" }}>
                                                        <div class="row">
                                                            <div class="pr-4" style={{ display: "flex", justifyContent: "end" }}>
                                                                <span class="badge badge-warning dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ cursor: "pointer", backgroundColor: "#ec407a", color: "white" }}>Filter</span>
                                                                <div class="dropdown-menu">
                                                                    <a class="dropdown-item" href="#" onClick={() => SMGweek()}>Weekly</a>
                                                                    <a class="dropdown-item" href="#" onClick={() => SMGmon()}>Monthly</a>
                                                                    <a class="dropdown-item" href="#" onClick={() => SMGall()}>All</a>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div> */}

                                        </div>
                                    </> : <></>}





                                <div class="pt-2" style={{ textAlign: "end" }}><span style={{ fontWeight: 500, fontSize: "15px", color: "#17a2b8", cursor: "pointer" }} onClick={() => viewMo()}> {viewMore ? <>View Less</> : <>View More</>}</span></div>


                                <div class="row mt-4">
                                    <div class="col-lg-4 col-md-6 mt-4 mb-4">
                                        <div class="card">
                                            <div class="card-header p-0 position-relative mt-n4 mx-3 bg-transparent">
                                                <div class="bg-gradient-primary shadow-primary border-radius-lg py-3 pe-1">
                                                    <div class="chart">
                                                        <canvas id="chart-bars" class="chart-canvas" height="170"></canvas>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card-body">
                                                <h6 class="mb-0 ">Month wise App Download</h6>
                                                <p class="text-sm ">Last Campaign Performance</p>
                                                <hr class="dark horizontal" />
                                                {/* <div class="d-flex ">
                                        <i class="material-icons text-sm my-auto me-1">schedule</i>
                                        <p class="mb-0 text-sm"> campaign sent 2 days ago </p>
                                    </div> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 mt-4 mb-4">
                                        <div class="card">
                                            <div class="card-header p-0 position-relative mt-n4 mx-3 bg-transparent">
                                                <div class="bg-gradient-success shadow-success border-radius-lg py-3 pe-1">
                                                    <div class="chart">
                                                        <canvas id="chart-line" class="chart-canvas" height="170"></canvas>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card-body">
                                                <h6 class="mb-0 ">Portfolio Created</h6>
                                                <p class="text-sm "> (<span class="font-weight-bolder">+15%</span>) Campaign Performance. </p>
                                                <hr class="dark horizontal" />
                                                {/* <div class="d-flex ">
                                        <i class="material-icons text-sm my-auto me-1">schedule</i>
                                        <p class="mb-0 text-sm"> updated 4 min ago </p>
                                    </div> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 mt-4 mb-3">
                                        <div class="card">
                                            <div class="card-header p-0 position-relative mt-n4 mx-3 bg-transparent">
                                                <div class="bg-gradient-dark shadow-dark border-radius-lg py-3 pe-1">
                                                    <div class="chart">
                                                        <canvas id="chart-line-tasks" class="chart-canvas" height="170"></canvas>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card-body">
                                                <h6 class="mb-0 ">Loan Repayment</h6>
                                                <p class="text-sm ">&nbsp;</p>
                                                <hr class="dark horizontal" />
                                                {/* <div class="d-flex ">
                                        <i class="material-icons text-sm my-auto me-1">schedule</i>
                                        <p class="mb-0 text-sm">just updated</p>
                                    </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div class="row mb-4">
                                    <div class="col-lg-6 col-md-6 mb-md-0 mb-4">
                                        <div class="card" style={{ height: "30rem", overflowY: "auto" }}>
                                            <div class="card-header pb-0">
                                                <div class="row">
                                                    <div class="col-lg-6 col-7">
                                                        <h6>State Wise Download</h6>
                                                    </div>

                                                </div>
                                            </div>
                                            <div class="card-body px-0 pb-2">
                                                <div class="table-responsive">
                                                    <table class="table align-items-center mb-0">
                                                        <thead>
                                                            <tr style={{ textAlign: "justify" }}>
                                                                <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">State</th>
                                                                <th onClick={handleSortClick}
                                                                    style={{ cursor: 'pointer' }} class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                                                    No. Of Downloads</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {usersState.map((user, index) => (
                                                                <tr key={index} >
                                                                    <td>
                                                                        <div className="d-flex px-2 py-1">
                                                                            <div className="d-flex flex-column justify-content-center">
                                                                                <h6 className="mb-0 text-sm">{user.state}</h6>
                                                                            </div>
                                                                        </div>
                                                                    </td>

                                                                    <td class="align-middle" >
                                                                        <div class="progress-wrapper w-75 mx-auto">
                                                                            <div class="progress-info">
                                                                                <div class="progress-percentage">
                                                                                    <span class="text-xs font-weight-bold">{user.downloadCount}</span>
                                                                                </div>
                                                                            </div>
                                                                            <div class="progress">
                                                                                <div class="progress-bar bg-gradient-info w-60" role="progressbar" aria-valuenow="100"
                                                                                    aria-valuemin="0" aria-valuemax="100"></div>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6">
                                        <div class="card" style={{ height: "30rem", overflowY: "auto" }}>
                                            <div class="card-header pb-0">
                                                <h6>City Wise Download</h6>
                                            </div>
                                            <div class="card-body px-0 pb-2">
                                                <div class="table-responsive">
                                                    <table class="table align-items-center mb-0">
                                                        <thead>
                                                            <tr style={{ textAlign: "justify" }}>
                                                                <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">City</th>
                                                                <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                                                    No. Of Downloads</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {usersCity.map((user, index) => (
                                                                <tr key={index}>
                                                                    <td>
                                                                        <div className="d-flex px-2 py-1">
                                                                            <div className="d-flex flex-column justify-content-center">
                                                                                <h6 className="mb-0 text-sm">{user.city}</h6>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td class="align-middle">
                                                                        <div class="progress-wrapper w-75 mx-auto">
                                                                            <div class="progress-info">
                                                                                <div class="progress-percentage">
                                                                                    <span class="text-xs font-weight-bold">{user.downloadCount}</span>
                                                                                </div>
                                                                            </div>
                                                                            <div class="progress">
                                                                                <div class="progress-bar bg-gradient-info w-60" role="progressbar" aria-valuenow="100"
                                                                                    aria-valuemin="0" aria-valuemax="100"></div>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div class="row mt-4">
                                    <div class="col-lg-6 col-12">
                                        <div class="card mt-4">
                                            {/* <div class="card-header p-3 pt-2">
                                                <div class="icon-lg icon-shape bg-gradient-warning shadow-warning text-center border-radius-xl mt-n4 me-3 float-start">
                                                    <i class="material-icons opacity-10">donut_small</i>
                                                </div>
                                                <h6 class="mb-0">State wise Portfolio</h6>
                                                <p class="mb-0 text-sm">Analytics Insights</p>
                                            </div>
                                            <div class="card-body d-flex p-3 pt-0">
                                                <div class="chart w-50">

                                                    <canvas id="pie-chart" class="chart-canvas" height="300px"></canvas>
                                                </div>
                                                <div class="table-responsive w-50">
                                                    <table class="table align-items-center mb-0">
                                                        <tbody>
                                                            {stateName?.map((data, index) => {
                                                                const portfolioCount = stateCountsAll[index]; // Get the count of portfolios for the current state
                                                                const totalPortfolios = stateCountsAll.reduce((sum, count) => sum + count, 0); // Calculate the total count of portfolios

                                                                // Calculate the percentage
                                                                const percentage = (portfolioCount / totalPortfolios) * 100;

                                                                return (
                                                                    <tr key={index}>
                                                                        <td>
                                                                            <div className="d-flex px-2 py-1">
                                                                                <div className="d-flex flex-column justify-content-center">
                                                                                    <h6 className="mb-0 text-sm">{data}</h6>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <td className="align-middle text-center text-sm">
                                                                            <span className="text-xs font-weight-bold">{percentage.toFixed(2)}%</span>
                                                                        </td>
                                                                    </tr>
                                                                );
                                                            })}

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-12 mt-md-0 mt-4">
                                        <div class="card mt-4">
                                            {/* <div class="card-header p-3 pt-2">
                                                <div class="icon-lg icon-shape bg-gradient-warning shadow-warning text-center border-radius-xl mt-n4 me-3 float-start">
                                                    <i class="material-icons opacity-10">pie_chart</i>
                                                </div>
                                                <h6 class="mb-0">User Types</h6>
                                                <p class="mb-0 text-sm">Analytics Insights</p>
                                            </div>
                                            <div class="card-body d-flex p-3 pt-0">
                                                <div class="chart w-50">
                                                    <canvas id="doughnut-chart" class="chart-canvas" height="460" width="514" style={{ display: "block", boxSizing: "border-box", height: "307.2px", width: "343px" }}></canvas>
                                                </div>
                                                <div class="w-50 my-auto ms-5">
                                                    <span class=" badge-lg badge-dot me-4 d-block text-start">
                                                        <span style={{ height: "13px", width: "12px", backgroundColor: "#007bff", borderRadius: "50%", display: "inline-block", marginRight: "7px" }}></span>
                                                        <span class="text-dark">Parlour</span>
                                                    </span>
                                                    <span class=" badge-lg badge-dot me-4 d-block text-start">
                                                        <span style={{ height: "13px", width: "12px", backgroundColor: "green", borderRadius: "50%", display: "inline-block", marginRight: "7px" }}></span>
                                                        <span class="text-dark">Freelancer</span>
                                                    </span>

                                                    <p class="text-sm mt-3">
                                                    </p>
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </> : <></>}




                            {/* <div class="">
                                {activeIndex == 0 ? <CourseData /> : null}
                                {activeIndex == 1 ? <CourseDataHindi /> : null}
                                {activeIndex == 2 ? <PromotionalData /> : null}
                                {activeIndex == 3 ? <FreeBusinessCards /> : null}
                                {activeIndex == 4 ? <PremiumCards /> : null}
                                {activeIndex == 5 ? <WorkshopData /> : null}
                                {activeIndex == 6 ? <WorkshopDataHindi /> : null}
                                {activeIndex == 7 ? <UsersProfile /> : null}
                                {activeIndex == 8 ? <PortfolioData /> : null}
                                {activeIndex == 9 ? <CustomerData /> : null}
                                {activeIndex == 10 ? <ReferredCourses /> : null}
                                {activeIndex == 11 ? <BeautyKitRequest /> : null}
                                {activeIndex == 12 ? <BookDemoRequest /> : null}
                                {activeIndex == 13 ? <BusinessCardRequest /> : null}
                                {activeIndex == 14 ? <HireRequest /> : null}
                                {activeIndex == 15 ? <LoanRequest /> : null}
                                {activeIndex == 16 ? <PremiumCardRequest /> : null}
                                {activeIndex == 17 ? <WorkshopRegistration /> : null}
                                {activeIndex == 18 ? <Workshop /> : null}
                                {activeIndex == 19 ? <RequestCallBack /> : null}
                                {activeIndex == 20 ? <DeletePortfolioRequest /> : null}
                                {activeIndex == 21 ? <PortfolioAnalyser /> : null}
                                {activeIndex == 22 ? <PortfolioReviews /> : null}
                                {activeIndex == 23 ? <Appointments /> : null}

                            </div> */}
                        </main>
                    </div>
                </div>

            </div>
        </>
    )
}

export default Analytics;



// import React, { useState, useEffect } from 'react';
// import { useNavigate, Link, useParams } from 'react-router-dom';
// import ViewAllRequests from './ViewAllRequests';
// import ViewAppData from './ViewAppData';
// import db from "./Firebase";
// import Moment from 'moment';
// import { extendMoment } from 'moment-range';
// // import { google } from 'googleapis';

// function Analytics() {
//     const navigate = useNavigate();
//     const moment = extendMoment(Moment);
//     const [activeIndex, setActiveIndex] = useState(null);
//     const [showAppData, setShowAppData] = useState(false);
//     const [showRequestData, setShowRequestData] = useState(false);
//     const [view, setView] = useState(false);
//     const [activeTabIndex, setActiveTabIndex] = useState(0);
//     const tabData = [
//         { label: 'View App Data', content: 'Content 1' },
//         { label: 'View All Requests', content: 'Content 2' },
//     ];
//     const handleTabClick = (index) => {
//         setActiveTabIndex(index);
//         console.log(index)
//     };


//     const [data, setData] = useState([]);
//     const [count, setCount] = useState([]);
//     const [showHide, setShowHide] = useState(true);

//     const [udata, setUdata] = useState([]);

//     const webData = [50, 20, 10, 22, 50, 10, 40];

//     const chartsOne = () => {
//         // window.websiteView();
//         window.charts();
//     }



//     const chrtFun = () => {

//     }

//     // const Jstart = new Date('2023-01-01T00:00:00.000z');
//     // const Jend = new Date('2023-01-31T23:59:59.000z');

//     // const Astart = new Date('2023-02-01T00:00:00.000z');
//     // const Aend = new Date('2023-02-28T23:59:59.000z');

//     // const Sstart = new Date('2023-03-18T00:00:00.000z');
//     // const Send = new Date('2023-03-31T23:59:59.000z');

//     // const Ostart = new Date('2023-04-01T00:00:00.000z');
//     // const Oend = new Date('2023-04-30T23:59:59.000z');

//     // const Nstart = new Date('2023-05-01T00:00:00.000z');
//     // const Nend = new Date('2023-05-31T23:59:59.000z');

//     // const Dstart = new Date('2023-06-01T00:00:00.000z');
//     // const Dend = new Date('2023-06-30T23:59:59.000z');

//     var [appDownload, setAppdownload] = useState([]);
//     var [portCreated, setPortCreated] = useState([]);
//     // var [count, setCount] = useState(0);

//     const [idMapping, setIdMapping] = useState([]);


//     const [courseEnrollment, setCourseEnrollment] = useState([]);
//     const [workshopCompleted, setWorkshopCompleted] = useState([]);


//     const downloadMonthely = () => {
//         const today = new Date();
//         today.setHours(0);
//         today.setMinutes(0);
//         today.setMilliseconds(0);
//         today.setSeconds(0);

//         var date = new Date();
//         var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
//         var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
//         console.log(firstDay);
//         console.log(lastDay);
//         db.collection("personal_details").where('created_date', '>=', firstDay).where('created_date', '<=', lastDay).onSnapshot((snapshot) => {
//             setUdata(
//                 snapshot.docs.map((doc) => ({
//                     id: doc.id,
//                     data: doc.data(),
//                 }))
//             );
//         });
//     }

//     const portoMonthely = () => {
//         const today = new Date();
//         today.setHours(0);
//         today.setMinutes(0);
//         today.setMilliseconds(0);
//         today.setSeconds(0);

//         var date = new Date();
//         var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
//         var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
//         console.log(firstDay);
//         console.log(lastDay);
//         db.collection("portfolio_details").where('created_date', '>=', firstDay).where('created_date', '<=', lastDay).onSnapshot((snapshot) => {
//             setPorto(
//                 snapshot.docs.map((doc) => ({
//                     id: doc.id,
//                     data: doc.data(),
//                 }))
//             );
//         });
//     }

//     const loanMonthely = () => {
//         const today = new Date();
//         today.setHours(0);
//         today.setMinutes(0);
//         today.setMilliseconds(0);
//         today.setSeconds(0);

//         var date = new Date();
//         var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
//         var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
//         console.log(firstDay);
//         console.log(lastDay);
//         db.collection("loan").where('created_date', '>=', firstDay).where('created_date', '<=', lastDay).onSnapshot((snapshot) => {
//             setLoan(
//                 snapshot.docs.map((doc) => ({
//                     id: doc.id,
//                     data: doc.data(),
//                 }))
//             );
//         });
//     }

//     const loanDisMonthely = () => {
//         const today = new Date();
//         today.setHours(0);
//         today.setMinutes(0);
//         today.setMilliseconds(0);
//         today.setSeconds(0);

//         var date = new Date();
//         var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
//         var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
//         console.log(firstDay);
//         console.log(lastDay);

//         db.collection("loan")
//             .where('status', '==', 1)
//             .where('created_date', '>=', firstDay)
//             .where('created_date', '<=', lastDay) // Add your third "where" condition here
//             .onSnapshot((snapshot) => {
//                 setDisbCount(
//                     snapshot.docs.map((doc) => ({
//                         id: doc.id,
//                         data: doc.data(),
//                     }, console.log(doc.data().created_date)))
//                 );

//             });
//     }


//     const downloadQuaterly = () => {
//         const today = new Date();
//         const quarter = Math.floor((today.getMonth() / 3));
//         // const startFullQuarter = new Date(today.getFullYear(), quarter * 6, 1);
//         // const endFullQuarter = new Date(startFullQuarter.getFullYear(), startFullQuarter.getMonth() + 6, 0);
//         // const startFullQuarter = new Date(today.getFullYear(), quarter * 6, 1);
//         // const endFullQuarter = new Date(startFullQuarter.getFullYear(), startFullQuarter.getMonth() + 6, 0);
//         const startFullQuarter = new Date(today.getFullYear(), quarter * 3, 1);
//         const endFullQuarter = new Date(startFullQuarter.getFullYear(), startFullQuarter.getMonth() + 3, 0);


//         console.log(startFullQuarter);
//         console.log(endFullQuarter);
//         db.collection("personal_details").where('created_date', '>=', startFullQuarter).where('created_date', '<=', endFullQuarter).onSnapshot((snapshot) => {
//             setUdata(
//                 snapshot.docs.map((doc) => ({
//                     id: doc.id,
//                     data: doc.data(),
//                 }))
//             );
//         });
//     }


//     const portoQuaterly = () => {
//         const today = new Date();
//         const quarter = Math.floor((today.getMonth() / 3));
//         // const startFullQuarter = new Date(today.getFullYear(), quarter * 6, 1);
//         // const endFullQuarter = new Date(startFullQuarter.getFullYear(), startFullQuarter.getMonth() + 6, 0);
//         // const startFullQuarter = new Date(today.getFullYear(), quarter * 6, 1);
//         // const endFullQuarter = new Date(startFullQuarter.getFullYear(), startFullQuarter.getMonth() + 6, 0);
//         const startFullQuarter = new Date(today.getFullYear(), quarter * 3, 1);
//         const endFullQuarter = new Date(startFullQuarter.getFullYear(), startFullQuarter.getMonth() + 3, 0);


//         console.log(startFullQuarter);
//         console.log(endFullQuarter);
//         db.collection("portfolio_details").where('created_date', '>=', startFullQuarter).where('created_date', '<=', endFullQuarter).onSnapshot((snapshot) => {
//             setPorto(
//                 snapshot.docs.map((doc) => ({
//                     id: doc.id,
//                     data: doc.data(),
//                 }))
//             );
//         });
//     }

//     const loanQuaterly = () => {
//         const today = new Date();
//         const quarter = Math.floor((today.getMonth() / 3));
//         // const startFullQuarter = new Date(today.getFullYear(), quarter * 6, 1);
//         // const endFullQuarter = new Date(startFullQuarter.getFullYear(), startFullQuarter.getMonth() + 6, 0);
//         // const startFullQuarter = new Date(today.getFullYear(), quarter * 6, 1);
//         // const endFullQuarter = new Date(startFullQuarter.getFullYear(), startFullQuarter.getMonth() + 6, 0);
//         const startFullQuarter = new Date(today.getFullYear(), quarter * 3, 1);
//         const endFullQuarter = new Date(startFullQuarter.getFullYear(), startFullQuarter.getMonth() + 3, 0);


//         console.log(startFullQuarter);
//         console.log(endFullQuarter);
//         db.collection("loan").where('created_date', '>=', startFullQuarter).where('created_date', '<=', endFullQuarter).onSnapshot((snapshot) => {
//             setLoan(
//                 snapshot.docs.map((doc) => ({
//                     id: doc.id,
//                     data: doc.data(),
//                 }))
//             );
//         });
//     }

//     const loanDisQuaterly = () => {
//         const today = new Date();
//         const quarter = Math.floor((today.getMonth() / 3));
//         // const startFullQuarter = new Date(today.getFullYear(), quarter * 6, 1);
//         // const endFullQuarter = new Date(startFullQuarter.getFullYear(), startFullQuarter.getMonth() + 6, 0);
//         // const startFullQuarter = new Date(today.getFullYear(), quarter * 6, 1);
//         // const endFullQuarter = new Date(startFullQuarter.getFullYear(), startFullQuarter.getMonth() + 6, 0);
//         const startFullQuarter = new Date(today.getFullYear(), quarter * 3, 1);
//         const endFullQuarter = new Date(startFullQuarter.getFullYear(), startFullQuarter.getMonth() + 3, 0);


//         console.log(startFullQuarter);
//         console.log(endFullQuarter);
//         db.collection("loan").where('created_date', '>=', startFullQuarter).where('created_date', '<=', endFullQuarter).where("status", "==", 1).onSnapshot((snapshot) => {
//             setDisbCount(
//                 snapshot.docs.map((doc) => ({
//                     id: doc.id,
//                     data: doc.data(),
//                 }))
//             );
//         });
//     }


//     const downloadYearly = () => {
//         const currentYear = new Date().getFullYear();
//         console.log(currentYear); // 👉️2022

//         const firstDay = new Date(currentYear, 0, 1);
//         console.log(firstDay); // 👉️ Sat Jan 01 2022

//         const lastDay = new Date(currentYear, 11, 31);
//         console.log(lastDay);

//         db.collection("personal_details").where('created_date', '>=', firstDay).where('created_date', '<=', lastDay).onSnapshot((snapshot) => {
//             setUdata(
//                 snapshot.docs.map((doc) => ({
//                     id: doc.id,
//                     data: doc.data(),
//                 }))
//             );
//         });

//     }

//     const downloadYearlyAll = () => {

//         db.collection("personal_details").onSnapshot((snapshot) => {
//             setUdata(
//                 snapshot.docs.map((doc) => ({
//                     id: doc.id,
//                     data: doc.data(),
//                 }))
//             );
//         });

//     }

//     const portoYearly = () => {
//         const currentYear = new Date().getFullYear();
//         console.log(currentYear); // 👉️2022

//         const firstDay = new Date(currentYear, 0, 1);
//         console.log(firstDay); // 👉️ Sat Jan 01 2022

//         const lastDay = new Date(currentYear, 11, 31);
//         console.log(lastDay);

//         db.collection("portfolio_details").where('created_date', '>=', firstDay).where('created_date', '<=', lastDay).onSnapshot((snapshot) => {
//             setPorto(
//                 snapshot.docs.map((doc) => ({
//                     id: doc.id,
//                     data: doc.data(),
//                 }))
//             );
//         });

//     }




//     const loanYearly = () => {
//         const currentYear = new Date().getFullYear();
//         console.log(currentYear); // 👉️2022

//         const firstDay = new Date(currentYear, 0, 1);
//         console.log(firstDay); // 👉️ Sat Jan 01 2022

//         const lastDay = new Date(currentYear, 11, 31);
//         console.log(lastDay);

//         db.collection("loan").where('created_date', '>=', firstDay).where('created_date', '<=', lastDay).onSnapshot((snapshot) => {
//             setLoan(
//                 snapshot.docs.map((doc) => ({
//                     id: doc.id,
//                     data: doc.data(),
//                 }))
//             );
//         });

//     }

//     const loanDisYearly = () => {
//         const currentYear = new Date().getFullYear();
//         console.log(currentYear); // 👉️2022

//         const firstDay = new Date(currentYear, 0, 1);
//         console.log(firstDay); // 👉️ Sat Jan 01 2022

//         const lastDay = new Date(currentYear, 11, 31);
//         console.log(lastDay);

//         db.collection("loan").where('created_date', '>=', firstDay).where('created_date', '<=', lastDay).where("status", "==", 1).onSnapshot((snapshot) => {
//             setDisbCount(
//                 snapshot.docs.map((doc) => ({
//                     id: doc.id,
//                     data: doc.data(),
//                 }))
//             );
//         });

//     }


//     const portoAll = () => {
//         db.collection("portfolio_details").onSnapshot((snapshot) => {
//             setPorto(
//                 snapshot.docs.map((doc) => ({
//                     id: doc.id,
//                     data: doc.data(),
//                 }))
//             );
//         });

//     }

//     const loanAll = () => {
//         db.collection("loan").onSnapshot((snapshot) => {
//             setLoan(
//                 snapshot.docs.map((doc) => ({
//                     id: doc.id,
//                     data: doc.data(),
//                 }))
//             );
//         });

//     }



//     const loanDisAll = () => {
//         db.collection("loan").where("status", "==", 1).onSnapshot((snapshot) => {
//             setDisbCount(
//                 snapshot.docs.map((doc) => ({
//                     id: doc.id,
//                     data: doc.data(),
//                 }))
//             );
//         });

//     }


//     const workshopMonthely = () => {
//         const today = new Date();
//         today.setHours(0);
//         today.setMinutes(0);
//         today.setMilliseconds(0);
//         today.setSeconds(0);

//         var date = new Date();
//         var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
//         var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
//         console.log(firstDay);
//         console.log(lastDay);
//         db.collection("workshop_event_data").where('created_date', '>=', firstDay).where('created_date', '<=', lastDay).onSnapshot((snapshot) => {
//             setWorkshopCompleted(
//                 snapshot.docs.map((doc) => ({
//                     id: doc.id,
//                     data: doc.data(),
//                 }))
//             );
//         });
//     }

//     const workshopQuaterly = () => {
//         const today = new Date();
//         const quarter = Math.floor((today.getMonth() / 3));
//         const startFullQuarter = new Date(today.getFullYear(), quarter * 3, 1);
//         const endFullQuarter = new Date(startFullQuarter.getFullYear(), startFullQuarter.getMonth() + 3, 0);


//         console.log(startFullQuarter);
//         console.log(endFullQuarter);
//         db.collection("workshop_event_data").where('created_date', '>=', startFullQuarter).where('created_date', '<=', endFullQuarter).onSnapshot((snapshot) => {
//             setWorkshopCompleted(
//                 snapshot.docs.map((doc) => ({
//                     id: doc.id,
//                     data: doc.data(),
//                 }))
//             );
//         });
//     }

//     const workshopYearly = () => {
//         const currentYear = new Date().getFullYear();
//         console.log(currentYear); // 👉️2022

//         const firstDay = new Date(currentYear, 0, 1);
//         console.log(firstDay); // 👉️ Sat Jan 01 2022

//         const lastDay = new Date(currentYear, 11, 31);
//         console.log(lastDay);

//         db.collection("workshop_event_data").where('created_date', '>=', firstDay).where('created_date', '<=', lastDay).where("status", "==", 1).onSnapshot((snapshot) => {
//             setWorkshopCompleted(
//                 snapshot.docs.map((doc) => ({
//                     id: doc.id,
//                     data: doc.data(),
//                 }))
//             );
//         });

//     }


//     const workshopAll = () => {
//         db.collection("workshop_event_data").onSnapshot((snapshot) => {
//             setWorkshopCompleted(
//                 snapshot.docs.map((doc) => ({
//                     id: doc.id,
//                     data: doc.data(),
//                 }))
//             );
//         });

//     }

//     const open = () => {
//         setView(true);
//     }

//     const changeStatus = () => {
//         openModalShow();
//     }

//     const openModalShow = () => {
//         // alert("ooo")
//         window.openModelshow();
//     }

//     const closeModel = () => {
//         window.openModelNew();
//     }

//     const handleChange4 = async (id) => {
//         // Swal.fire(name)
//         db.collection("all_notification").doc(id).update({ 'status': "1" }).then(() => {
//             // console.log("user added");
//         })
//     }


//     const [rev, setRev] = useState(0);
//     const [porto, setPorto] = useState([]);
//     const [loan, setLoan] = useState([]);
//     const [totalPorto, setTotalPorto] = useState(0);
//     const [dicbCount, setDisbCount] = useState([]);
//     const [disbAmount, setDisbAmount] = useState(0);
//     const [repayAmt, setRepayAmt] = useState(0);
//     const [cardLength, setCardLength] = useState(0);
//     const [businessCard, setBusinessCard] = useState([])
//     const [businessCardPre, setBusinessCardPre] = useState([])
//     const [totalDataLength, setTotalDataLength] = useState(0);
//     const [appointments, setAppointments] = useState([])
//     const [reviewCount, setReviewCount] = useState([])


//     const [stateName, setStateName] = useState([])


//     const [uniqueStatesAll, setuniqueStatesAll] = useState([])
//     const [stateCountsAll, setstateCountsAll] = useState([])


//     useEffect(() => {
//         db.collection("loan").onSnapshot((snapshot) => {
//             setLoan(
//                 snapshot.docs.map((doc) => ({
//                     id: doc.id,
//                     data: doc.data(),
//                 }))
//             );
//         });
//         db.collection("loan").where("status", "==", 1).onSnapshot((snapshot) => {
//             // setDisbCount(snapshot.size);
//             setDisbCount(
//                 snapshot.docs.map((doc) => ({
//                     id: doc.id,
//                     data: doc.data(),
//                 }))
//             );
//             setDisbAmount(snapshot.docs.reduce((accumulator, doc) => accumulator + parseInt(doc.data().loan_amount.substr(3, 8)), 0));
//             // setDisbAmount(snapshot.docs.reduce((accumulator, doc) => accumulator + parseInt(doc.data().loan_amount.substr(3, 8), 0)));
//         });

//         db.collection("repayment_model").where("status", "==", "1").onSnapshot((snapshot) => {
//             setRepayAmt(snapshot.docs.reduce((accumulator, doc) => accumulator + parseInt(doc.data().amount), 0));
//             // setDisbAmount(snapshot.docs.reduce((accumulator, doc) => accumulator + parseInt(doc.data().loan_amount.substr(3, 8), 0)));
//         });

//         db.collection("revenue_table").onSnapshot((snapshot) => {
//             setRev(
//                 snapshot.docs.map((doc) => parseInt(doc.data()[0].revenue))
//             );
//         });


//         db.collection("personal_details").onSnapshot((snapshot) => {
//             setUdata(
//                 snapshot.docs.map((doc) => ({
//                     id: doc.id,
//                     data: doc.data(),
//                 }))
//             );
//         });
//         db.collection("portfolio_details").onSnapshot((snapshot) => {
//             setPorto(
//                 snapshot.docs.map((doc) => ({
//                     id: doc.id,
//                     data: doc.data(),
//                 }))
//             );
//         });

//         db.collection("appointments").onSnapshot((snapshot) => {
//             setAppointments(
//                 snapshot.docs.map((doc) => ({
//                     id: doc.id,
//                     data: doc.data(),
//                 }))
//             );
//         });

//         db.collection("parlour_feedback").onSnapshot((snapshot) => {
//             setReviewCount(
//                 snapshot.docs.map((doc) => ({
//                     id: doc.id,
//                     data: doc.data(),
//                 }))
//             );
//         });

//         var arr1 = []; // for freelancers
//         var arr2 = []; // for parlours

//         db.collection("portfolio_details").onSnapshot((snapshot) => {
//             snapshot.docs.map((doc) => {
//                 if (doc.data().title_designation === 'Beauty Professional') {
//                     arr2.push(doc.data().name); // push the name of the parlour into arr2
//                 } else if (doc.data().title_designation === 'Freelancer') {
//                     arr1.push(doc.data().name); // push the name of the freelancer into arr1
//                 }
//             });

//             var arrLen = [arr1.length, arr2.length];
//             console.log(arrLen);
//             window.donught(arrLen);
//         })



//         var uniqueStates = [];
//         var stateCounts = [];

//         db.collection("portfolio_details").onSnapshot((snapshot) => {
//             uniqueStates = []; // Reset the uniqueStates array
//             stateCounts = []; // Reset the stateCounts array

//             snapshot.docs.forEach((doc) => {
//                 var state = doc.data().state;

//                 if (state && !uniqueStates.includes(state)) {
//                     uniqueStates.push(state);
//                     stateCounts.push(1);
//                 } else {
//                     var index = uniqueStates.indexOf(state);
//                     if (index !== -1) {
//                         stateCounts[index] += 1;
//                     }
//                 }
//             });
//             window.pieChart(uniqueStates, stateCounts);
//             setStateName(uniqueStates);
//             setstateCountsAll(stateCounts)
//             // console.log(`0-0-0-0---${uniqueStates}`);
//             // console.log(`-==--=-=-${stateCounts}`);
//         });

//         // window.donught([20, 10, 40, 50]);


//         db.collection("course_enrollment").onSnapshot((snapshot) => {

//             setCourseEnrollment(
//                 snapshot.docs.map((doc) => ({
//                     data: doc.data(),
//                 }))
//             );
//         });

//         var wDay = new Date();
//         db.collection("workshop_event_data").where('created_date', '<=', wDay).onSnapshot((snapshot) => {
//             setWorkshopCompleted(
//                 snapshot.docs.map((doc) => ({
//                     id: doc.id,
//                     data: doc.data(),
//                 }))
//             );
//         });

//         // var jy = 0;
//         // var au = 0;
//         // var se = 0;
//         // var oc = 0;
//         // var no = 0;
//         // var de = 0;

//         // db.collection("personal_details").onSnapshot((snapshot) => {
//         //     snapshot.docs.map((doc) => {
//         //         if (doc.data().created_date.toDate() >= Jstart && doc.data().created_date.toDate() <= Aend) {
//         //             jy = jy += 1
//         //         }

//         //         if (doc.data().created_date.toDate() >= Astart && doc.data().created_date.toDate() <= Aend) {
//         //             au = au += 1
//         //         }

//         //         if (doc.data().created_date.toDate() >= Sstart && doc.data().created_date.toDate() <= Send) {
//         //             se = se += 1
//         //         }

//         //         if (doc.data().created_date.toDate() >= Ostart && doc.data().created_date.toDate() <= Oend) {
//         //             oc = oc += 1
//         //         }

//         //         if (doc.data().created_date.toDate() >= Nstart && doc.data().created_date.toDate() <= Nend) {
//         //             no = no += 1
//         //         }

//         //         if (doc.data().created_date.toDate() >= Dstart && doc.data().created_date.toDate() <= Dend) {
//         //             de = de += 1
//         //         }
//         //     })
//         //     setAppdownload([jy, au, se, oc, no, de])
//         //     // window.websiteView([50, 20, 10, 22, 50, 10]);
//         //     window.websiteView([jy, au, se, oc, no, de]);
//         //     console.log(appDownload);

//         // });

//         const currentDate = new Date(); // Current date

//         const appDownload = new Array(6).fill(0); // Initialize an array to store counts for each month

//         const monthNames = [
//             "Jan", "Feb", "Mar", "Apr", "May", "Jun",
//             "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
//         ];


//         for (let i = 0; i < 6; i++) {
//             const startOfMonth = new Date(
//                 currentDate.getFullYear(),
//                 currentDate.getMonth() - i,
//                 1
//             );
//             const endOfMonth = new Date(
//                 currentDate.getFullYear(),
//                 currentDate.getMonth() - i + 1,
//                 0,
//                 23,
//                 59,
//                 59
//             );

//             db.collection("personal_details").onSnapshot((snapshot) => {
//                 snapshot.docs.map((doc) => {
//                     const createdDate = doc.data().created_date.toDate();
//                     if (createdDate >= startOfMonth && createdDate <= endOfMonth) {
//                         appDownload[5 - i] += 1; // Store data in reverse order
//                     }
//                 });
//                 const currentMonthNames = monthNames.slice(
//                     currentDate.getMonth() - 5,
//                     currentDate.getMonth() + 1
//                 );


//                 setAppdownload(appDownload);
//                 window.websiteView(currentMonthNames, appDownload);
//                 console.log(currentMonthNames);
//             });
//         }





//         chartsOne();

//         db.collection("all_notification").orderBy("created_date", "desc").onSnapshot((snapshot) => {
//             setData(
//                 snapshot.docs.map((doc) => ({
//                     id: doc.id,
//                     data: doc.data(),
//                 }))
//             );
//         });

//         db.collection("all_notification").where('status', '==', "0").onSnapshot((snapshot) => {
//             setCount(
//                 snapshot.docs.map((doc) => ({
//                     id: doc.id,
//                     data: doc.data(),
//                 }))
//             );
//         });

//         // var jyP = 0;
//         // var auP = 0;
//         // var seP = 0;
//         // var ocP = 0;
//         // var noP = 0;
//         // var deP = 0;

//         // db.collection("portfolio_details").onSnapshot((snapshot) => {
//         //     snapshot.docs.map((doc) => {
//         //         if (doc.data().created_date.toDate() >= Jstart && doc.data().created_date.toDate() <= Aend) {
//         //             jyP = jyP += 1
//         //         }

//         //         if (doc.data().created_date.toDate() >= Astart && doc.data().created_date.toDate() <= Aend) {
//         //             auP = auP += 1
//         //         }

//         //         if (doc.data().created_date.toDate() >= Sstart && doc.data().created_date.toDate() <= Send) {
//         //             seP = seP += 1
//         //         }

//         //         if (doc.data().created_date.toDate() >= Ostart && doc.data().created_date.toDate() <= Oend) {
//         //             ocP = ocP += 1
//         //         }

//         //         if (doc.data().created_date.toDate() >= Nstart && doc.data().created_date.toDate() <= Nend) {
//         //             noP = noP += 1
//         //         }

//         //         if (doc.data().created_date.toDate() >= Dstart && doc.data().created_date.toDate() <= Dend) {
//         //             deP = deP += 1
//         //         }
//         //     })
//         //     setPortCreated([jyP, auP, seP, ocP, noP, deP])
//         //     window.portoView([jyP, auP, seP, ocP, noP, deP]);

//         // });


//         const portfolioCreated = new Array(6).fill(0);

//         for (let i = 0; i < 6; i++) {
//             const startOfMonth = new Date(
//                 currentDate.getFullYear(),
//                 currentDate.getMonth() - i,
//                 1
//             );
//             const endOfMonth = new Date(
//                 currentDate.getFullYear(),
//                 currentDate.getMonth() - i + 1,
//                 0,
//                 23,
//                 59,
//                 59
//             );

//             db.collection("portfolio_details").onSnapshot((snapshot) => {
//                 snapshot.docs.map((doc) => {
//                     const createdDate = doc.data().created_date.toDate();
//                     if (createdDate >= startOfMonth && createdDate <= endOfMonth) {
//                         portfolioCreated[5 - i] += 1; // Store data in reverse order
//                     }
//                 });
//                 const currentMonthNames = monthNames.slice(
//                     currentDate.getMonth() - 5,
//                     currentDate.getMonth() + 1
//                 );

//                 setPortCreated(portfolioCreated);
//                 window.portoView(currentMonthNames, portfolioCreated);
//                 console.log(portfolioCreated);
//             });
//         }

//         // var jyRE = 0;
//         // var auRE = 0;
//         // var seRE = 0;
//         // var ocRE = 0;
//         // var noRE = 0;
//         // var deRE = 0;

//         // db.collection("repayment_model").where("status", "==", "1").onSnapshot((snapshot) => {
//         //     snapshot.docs.map((doc) => {
//         //         var amount = parseInt(doc.data().amount); // Parse the amount value to an integer

//         //         if (doc.data().updated_date.toDate() >= Jstart && doc.data().updated_date.toDate() <= Aend) {
//         //             jyRE += amount;
//         //         }

//         //         if (doc.data().updated_date.toDate() >= Astart && doc.data().updated_date.toDate() <= Aend) {
//         //             auRE += amount;
//         //         }

//         //         if (doc.data().updated_date.toDate() >= Sstart && doc.data().updated_date.toDate() <= Send) {
//         //             seRE += amount;
//         //         }

//         //         if (doc.data().updated_date.toDate() >= Ostart && doc.data().updated_date.toDate() <= Oend) {
//         //             ocRE += amount;
//         //         }

//         //         if (doc.data().updated_date.toDate() >= Nstart && doc.data().updated_date.toDate() <= Nend) {
//         //             noRE += amount;
//         //         }

//         //         if (doc.data().updated_date.toDate() >= Dstart && doc.data().updated_date.toDate() <= Dend) {
//         //             deRE += amount;
//         //         }
//         //     });
//         //     console.log([jyRE, auRE, seRE, ocRE, noRE, deRE])

//         //     window.rePayView([jyRE, auRE, seRE, ocRE, noRE, deRE]);
//         // });

//         var repayAmounts = [0, 0, 0, 0, 0, 0];

//         db.collection("repayment_model")
//             .where("status", "==", "1")
//             .onSnapshot((snapshot) => {
//                 snapshot.docs.forEach((doc) => {
//                     var amount = parseInt(doc.data().amount);
//                     var updatedDate = doc.data().updated_date.toDate();

//                     var currentDate = new Date();
//                     var monthIndex =
//                         (currentDate.getFullYear() - updatedDate.getFullYear()) * 12 +
//                         (currentDate.getMonth() - updatedDate.getMonth());

//                     if (monthIndex >= 0 && monthIndex < 6) {
//                         repayAmounts[5 - monthIndex] += amount;
//                     }
//                 });

//                 const currentMonthNames = monthNames.slice(
//                     currentDate.getMonth() - 5,
//                     currentDate.getMonth() + 1
//                 );

//                 // Display month names and corresponding amounts
//                 // for (var i = 0; i < repayAmounts.length; i++) {
//                 //     console.log(monthNames[i] + " - " + repayAmounts[i]);
//                 // }

//                 window.rePayView(currentMonthNames, repayAmounts);
//             });



//         db.collection("business_card_request").where('status', '==', "1").onSnapshot((snapshot) => {
//             setBusinessCard(
//                 snapshot.docs.map((doc) => ({
//                     id: doc.id,
//                     data: doc.data(),
//                 }))
//             );
//             setCardLength(snapshot.docs.length)
//         });

//         db.collection("premium_card_request").onSnapshot((snapshot) => {
//             setBusinessCardPre(
//                 snapshot.docs.map((doc) => ({
//                     id: doc.id,
//                     data: doc.data(),
//                 }))
//             );
//             setCardLength(cardLength + snapshot.docs.length)
//         });

//         // const totalLength = businessCard.length + businessCardPre.length;
//         // setTotalDataLength(totalLength);

//         chartsOne();
//         // getPortfoliosByMonth();
//     }, []);



//     // useEffect(() => {
//     //     const unsubscribeBusinessCard = db.collection.collection("business_card_request")
//     //         .onSnapshot((snapshot) => {
//     //             setBusinessCard(
//     //                 snapshot.docs.map((doc) => ({
//     //                     id: doc.id,
//     //                     data: doc.data(),
//     //                 }))
//     //             );
//     //         });

//     //     const unsubscribeBusinessCardPre = db.collection.collection("premium_card_request")
//     //         .onSnapshot((snapshot) => {
//     //             setBusinessCardPre(
//     //                 snapshot.docs.map((doc) => ({
//     //                     id: doc.id,
//     //                     data: doc.data(),
//     //                 }))
//     //             );
//     //         });


//     // }, []);

//     // useEffect(() => {
//     //     const totalLength = businessCard.length + businessCardPre.length;
//     //     setTotalDataLength(totalLength);
//     // }, [businessCard, businessCardPre]);

//     const AllCard = (() => {
//         // const totalLength = businessCard.length + businessCardPre.length;
//         setTotalDataLength(0);
//     })

//     const FreeCard = () => {
//         db.collection("business_card_request").where('status', '==', "1").onSnapshot((snapshot) => {
//             setBusinessCard(
//                 snapshot.docs.map((doc) => ({
//                     id: doc.id,
//                     data: doc.data(),
//                 }))
//             );
//             setTotalDataLength(snapshot.docs.length)
//         });

//     }

//     const PreCard = () => {
//         db.collection("premium_card_request").onSnapshot((snapshot) => {
//             setBusinessCardPre(
//                 snapshot.docs.map((doc) => ({
//                     id: doc.id,
//                     data: doc.data(),
//                 }))
//             );
//             setTotalDataLength(snapshot.docs.length)
//         });

//     }



//     const [viewMore, setViewMore] = useState(true);

//     const viewMo = () => {
//         if (viewMore) {
//             setViewMore(false)
//         } else {
//             setViewMore(true)
//         }
//     }

//     const ReadAll = () => {
//         {
//             data?.map(({ id, data }, index) => (
//                 db.collection("all_notification").doc(id).update({ 'status': "1" }).then(() => {
//                     // console.log("user added");
//                 })
//             ))
//         }
//     }

//     const DeleteAll = () => {
//         {
//             data?.map(({ id, data }, index) => (
//                 db.collection("all_notification").doc(id).delete().then(() => {
//                     // console.log("user added");
//                 })
//             ))
//         }
//     }

//     const showHideBar = () => {
//         if (showHide) {
//             setShowHide(false)
//             console.log("hidee")
//         } else {
//             setShowHide(true)
//             console.log("showww")
//         }
//     }

//     const tog = () => {
//         // window.websiteView();
//         window.togg();
//     }

//     const [ola, setOla] = useState(true);

//     const analyticsReload = () => {
//         setActiveIndex(null);
//         db.collection("personal_details").onSnapshot((snapshot) => {
//             setUdata(
//                 snapshot.docs.map((doc) => ({
//                     id: doc.id,
//                     data: doc.data(),
//                 }))
//             );
//         });

//         var arr1 = [];
//         var arr2 = [];
//         var arr3 = [];
//         var arr4 = [];
//         db.collection("personal_details").onSnapshot((snapshot) => {

//             // setCourseEnrollment(
//             //     snapshot.docs.map((doc) => ({
//             //         data: doc.data(),
//             //     }))
//             // );
//             snapshot.docs.map((doc) => (
//                 // console.log(doc.data().type),
//                 doc.data().type == 'parlour' ? arr2.push(doc.data().type) : null,
//                 doc.data().type == 'freelancer' ? arr1.push(doc.data().type) : null,
//                 doc.data().type == 'student' ? arr3.push(doc.data().type) : null
//             ))

//             var arrLen = [];
//             arrLen.push(arr1.length)
//             arrLen.push(arr2.length)
//             arrLen.push(arr3.length)
//             console.log(arrLen)
//             window.donught(arrLen);
//         });

//         // window.donught([20, 10, 40, 50]);


//         db.collection("course_enrollment").onSnapshot((snapshot) => {

//             setCourseEnrollment(
//                 snapshot.docs.map((doc) => ({
//                     data: doc.data(),
//                 }))
//             );
//         });

//         var wDay = new Date();
//         db.collection("workshop_event_data").where('created_date', '<=', wDay).onSnapshot((snapshot) => {
//             setWorkshopCompleted(
//                 snapshot.docs.map((doc) => ({
//                     id: doc.id,
//                     data: doc.data(),
//                 }))
//             );
//         });

//         // var jy = 0;
//         // var au = 0;
//         // var se = 0;
//         // var oc = 0;
//         // var no = 0;
//         // var de = 0;

//         // db.collection("personal_details").onSnapshot((snapshot) => {
//         //     snapshot.docs.map((doc) => {
//         //         if (doc.data().created_date.toDate() >= Jstart && doc.data().created_date.toDate() <= Aend) {
//         //             jy = jy += 1
//         //         }

//         //         if (doc.data().created_date.toDate() >= Astart && doc.data().created_date.toDate() <= Aend) {
//         //             au = au += 1
//         //         }

//         //         if (doc.data().created_date.toDate() >= Sstart && doc.data().created_date.toDate() <= Send) {
//         //             se = se += 1
//         //         }

//         //         if (doc.data().created_date.toDate() >= Ostart && doc.data().created_date.toDate() <= Oend) {
//         //             oc = oc += 1
//         //         }

//         //         if (doc.data().created_date.toDate() >= Nstart && doc.data().created_date.toDate() <= Nend) {
//         //             no = no += 1
//         //         }

//         //         if (doc.data().created_date.toDate() >= Dstart && doc.data().created_date.toDate() <= Dend) {
//         //             de = de += 1
//         //         }
//         //     })
//         //     setAppdownload([jy, au, se, oc, no, de])
//         //     // window.websiteView([50, 20, 10, 22, 50, 10]);
//         //     window.websiteView([jy, au, se, oc, no, de]);
//         //     console.log(appDownload);

//         // });

//         const currentDate = new Date(); // Current date

//         const appDownload = new Array(6).fill(0); // Initialize an array to store counts for each month

//         const monthNames = [
//             "Jan", "Feb", "Mar", "Apr", "May", "Jun",
//             "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
//         ];

//         for (let i = 0; i < 6; i++) {
//             const startOfMonth = new Date(
//                 currentDate.getFullYear(),
//                 currentDate.getMonth() - i,
//                 1
//             );
//             const endOfMonth = new Date(
//                 currentDate.getFullYear(),
//                 currentDate.getMonth() - i + 1,
//                 0,
//                 23,
//                 59,
//                 59
//             );

//             db.collection("personal_details").onSnapshot((snapshot) => {
//                 snapshot.docs.map((doc) => {
//                     const createdDate = doc.data().created_date.toDate();
//                     if (createdDate >= startOfMonth && createdDate <= endOfMonth) {
//                         appDownload[5 - i] += 1; // Store data in reverse order
//                     }
//                 });
//                 const currentMonthNames = monthNames.slice(
//                     currentDate.getMonth() - 5,
//                     currentDate.getMonth() + 1
//                 );

//                 setAppdownload(appDownload);
//                 window.websiteView(currentMonthNames, appDownload);
//                 // console.log(`currentMonthNames -- ${currentMonthNames}`);
//             });
//         }



//         // var jyP = 0;
//         // var auP = 0;
//         // var seP = 0;
//         // var ocP = 0;
//         // var noP = 0;
//         // var deP = 0;

//         // db.collection("portfolio_details").onSnapshot((snapshot) => {
//         //     snapshot.docs.map((doc) => {
//         //         if (doc.data().created_date.toDate() >= Jstart && doc.data().created_date.toDate() <= Aend) {
//         //             jyP = jyP += 1
//         //         }

//         //         if (doc.data().created_date.toDate() >= Astart && doc.data().created_date.toDate() <= Aend) {
//         //             auP = auP += 1
//         //         }

//         //         if (doc.data().created_date.toDate() >= Sstart && doc.data().created_date.toDate() <= Send) {
//         //             seP = seP += 1
//         //         }

//         //         if (doc.data().created_date.toDate() >= Ostart && doc.data().created_date.toDate() <= Oend) {
//         //             ocP = ocP += 1
//         //         }

//         //         if (doc.data().created_date.toDate() >= Nstart && doc.data().created_date.toDate() <= Nend) {
//         //             noP = noP += 1
//         //         }

//         //         if (doc.data().created_date.toDate() >= Dstart && doc.data().created_date.toDate() <= Dend) {
//         //             deP = deP += 1
//         //         }
//         //     })
//         //     setPortCreated([jyP, auP, seP, ocP, noP, deP])
//         //     window.portoView([jyP, auP, seP, ocP, noP, deP]);

//         // });



//         const portfolioCreated = new Array(6).fill(0);
//         for (let i = 0; i < 6; i++) {
//             const startOfMonth = new Date(
//                 currentDate.getFullYear(),
//                 currentDate.getMonth() - i,
//                 1
//             );
//             const endOfMonth = new Date(
//                 currentDate.getFullYear(),
//                 currentDate.getMonth() - i + 1,
//                 0,
//                 23,
//                 59,
//                 59
//             );

//             db.collection("portfolio_details").onSnapshot((snapshot) => {
//                 snapshot.docs.map((doc) => {
//                     const createdDate = doc.data().created_date.toDate();
//                     if (createdDate >= startOfMonth && createdDate <= endOfMonth) {
//                         portfolioCreated[5 - i] += 1; // Store data in reverse order
//                     }
//                 });

//                 const currentMonthNames = monthNames.slice(
//                     currentDate.getMonth() - 5,
//                     currentDate.getMonth() + 1
//                 );

//                 setPortCreated(portfolioCreated);
//                 window.portoView(currentMonthNames, portfolioCreated);
//                 console.log(portfolioCreated);
//             });
//         }



//         // var jyRE = 0;
//         // var auRE = 0;
//         // var seRE = 0;
//         // var ocRE = 0;
//         // var noRE = 0;
//         // var deRE = 0;

//         // db.collection("repayment_model").where("status", "==", "1").onSnapshot((snapshot) => {
//         //     snapshot.docs.map((doc) => {
//         //         var amount = parseInt(doc.data().amount); // Parse the amount value to an integer

//         //         if (doc.data().updated_date.toDate() >= Jstart && doc.data().updated_date.toDate() <= Aend) {
//         //             jyRE += amount;
//         //         }

//         //         if (doc.data().updated_date.toDate() >= Astart && doc.data().updated_date.toDate() <= Aend) {
//         //             auRE += amount;
//         //         }

//         //         if (doc.data().updated_date.toDate() >= Sstart && doc.data().updated_date.toDate() <= Send) {
//         //             seRE += amount;
//         //         }

//         //         if (doc.data().updated_date.toDate() >= Ostart && doc.data().updated_date.toDate() <= Oend) {
//         //             ocRE += amount;
//         //         }

//         //         if (doc.data().updated_date.toDate() >= Nstart && doc.data().updated_date.toDate() <= Nend) {
//         //             noRE += amount;
//         //         }

//         //         if (doc.data().updated_date.toDate() >= Dstart && doc.data().updated_date.toDate() <= Dend) {
//         //             deRE += amount;
//         //         }
//         //     });
//         //     console.log([jyRE, auRE, seRE, ocRE, noRE, deRE])

//         //     window.rePayView([jyRE, auRE, seRE, ocRE, noRE, deRE]);
//         // });


//         var repayAmounts = [0, 0, 0, 0, 0, 0];

//         db.collection("repayment_model")
//             .where("status", "==", "1")
//             .onSnapshot((snapshot) => {
//                 snapshot.docs.forEach((doc) => {
//                     var amount = parseInt(doc.data().amount);
//                     var updatedDate = doc.data().updated_date.toDate();

//                     var currentDate = new Date();
//                     var monthIndex =
//                         (currentDate.getFullYear() - updatedDate.getFullYear()) * 12 +
//                         (currentDate.getMonth() - updatedDate.getMonth());

//                     if (monthIndex >= 0 && monthIndex < 6) {
//                         repayAmounts[5 - monthIndex] += amount;
//                     }
//                 });

//                 const currentMonthNames = monthNames.slice(
//                     currentDate.getMonth() - 5,
//                     currentDate.getMonth() + 1
//                 );

//                 // Display month names and corresponding amounts
//                 // for (var i = 0; i < repayAmounts.length; i++) {
//                 //     console.log(monthNames[i] + " - " + repayAmounts[i]);
//                 // }

//                 window.rePayView(currentMonthNames, repayAmounts);
//             });




//         chartsOne();

//         db.collection("all_notification").orderBy("created_date", "desc").onSnapshot((snapshot) => {
//             setData(
//                 snapshot.docs.map((doc) => ({
//                     id: doc.id,
//                     data: doc.data(),
//                 }))
//             );
//         });

//         db.collection("all_notification").where('status', '==', "0").onSnapshot((snapshot) => {
//             setCount(
//                 snapshot.docs.map((doc) => ({
//                     id: doc.id,
//                     data: doc.data(),
//                 }))
//             );
//         });

//         chartsOne();
//     }




//     return (
//         <>


//             <div id="page-content-wrapper">


//                 <div class="">
//                     {/* <div class="row">
//                             <div class="col-lg-12">
//                                 <h1>Simple Sidebar</h1>
//                                 <p>This template has a responsive menu toggling system. The menu will appear collapsed on smaller screens, and will appear non-collapsed on larger screens. When toggled using the button below, the menu will appear/disappear. On small screens, the page content will be pushed off canvas.</p>
//                                 <p>Make sure to keep all page content within the <code>#page-content-wrapper</code>.</p>
//                                 <a href="#menu-toggle" onClick={() => tog()} class="btn btn-default menu-toggle">Toggle Menu</a>
//                             </div>
//                         </div> */}
//                     <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
//                         {/* <div class="col-lg-12" style={{ display: "inline-flex", justifyContent: "end" }}>
//         <li class="nav-item dropdown pe-2 d-flex align-items-center">
//             <a href="javascript:;" class="nav-link text-body p-0" id="dropdownMenuButton" data-bs-toggle="dropdown"
//                 aria-expanded="false">
//                 <i class="fa fa-bell cursor-pointer fa-lg"></i>
//             </a>

//         </li>
//     </div> */}
//                         {activeIndex === null ? <>
//                             <div class="row pt-5">
//                                 <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
//                                     <div class="card">
//                                         <div class="card-header p-2 pt-1" style={{ borderStyle: "none" }}>
//                                             <div
//                                                 class="icon-md icon-shape bg-gradient-dark shadow-dark text-center border-radius-xl mt-n4 position-absolute">
//                                                 <i class="material-icons opacity-10">weekend</i>
//                                             </div>
//                                             <div class="text-end pt-1">
//                                                 <p class="mb-0 text-capitalize" style={{ fontWeight: "bold", fontSize: "13.1px" }}>Apps Download</p>
//                                                 <h4 class="mb-0" style={{ fontWeight: "700", fontSize: "22px" }}>{udata.length}</h4>
//                                             </div>
//                                         </div>
//                                         <hr class="dark horizontal my-0" />
//                                         <div class="card-footer p-1" style={{ borderStyle: "none" }}>
//                                             <div class="row">
//                                                 <div class="pr-4" style={{ display: "flex", justifyContent: "end" }}>
//                                                     <span class="badge badge-warning dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ cursor: "pointer", backgroundColor: "#ec407a", color: "white" }}>Filter</span>
//                                                     <div class="dropdown-menu">
//                                                         <a class="dropdown-item" href="#" onClick={() => downloadMonthely()}>Monthly</a>
//                                                         <a class="dropdown-item" href="#" onClick={() => downloadQuaterly()}>Quarterly</a>
//                                                         <a class="dropdown-item" href="#" onClick={() => downloadYearly()}>Yearly</a>
//                                                         <a class="dropdown-item" href="#" onClick={() => downloadYearlyAll()}>All</a>
//                                                         {/* <div class="dropdown-divider"></div>
//                                                 <a class="dropdown-item" href="#">Separated link</a> */}
//                                                     </div>
//                                                 </div>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </div>
//                                 <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
//                                     <div class="card">
//                                         <div class="card-header p-2 pt-1" style={{ borderStyle: "none" }}>
//                                             <div
//                                                 class="icon-md icon-shape bg-gradient-primary shadow-primary text-center border-radius-xl mt-n4 position-absolute">
//                                                 <i class="material-icons opacity-10">person</i>
//                                             </div>
//                                             <div class="text-end pt-1">
//                                                 <p class="mb-0 text-capitalize" style={{ fontWeight: "bold", fontSize: "13.1px" }}>Total Revenue</p>
//                                                 <h4 class="mb-0" style={{ fontWeight: "700", fontSize: "22px" }}>₹ {rev}</h4>
//                                             </div>
//                                         </div>
//                                         <hr class="dark horizontal my-0" />
//                                         <div class="card-footer p-1" style={{ borderStyle: "none" }}>
//                                             <div class="row">
//                                                 <div class="pr-4" style={{ display: "flex", justifyContent: "end" }}>
//                                                     <span class="badge badge-warning dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ cursor: "pointer", backgroundColor: "#ec407a", color: "white" }}>Filter</span>
//                                                     <div class="dropdown-menu">
//                                                         <a class="dropdown-item" href="#">Monthly</a>
//                                                         <a class="dropdown-item" href="#">Quarterly</a>
//                                                         <a class="dropdown-item" href="#">Yearly</a>
//                                                         {/* <div class="dropdown-divider"></div>
//                                                 <a class="dropdown-item" href="#">Separated link</a> */}
//                                                     </div>
//                                                 </div>
//                                             </div>

//                                         </div>
//                                     </div>
//                                 </div>
//                                 <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
//                                     <div class="card">
//                                         <div class="card-header p-2 pt-1" style={{ borderStyle: "none" }}>
//                                             <div
//                                                 class="icon-md icon-shape bg-gradient-success shadow-success text-center border-radius-xl mt-n4 position-absolute">
//                                                 <i class="material-icons opacity-10">person</i>
//                                             </div>
//                                             <div class="text-end pt-1">
//                                                 <p class="mb-0 text-capitalize" style={{ fontWeight: "bold", fontSize: "13.1px" }}>Workshop Completed</p>
//                                                 <h4 class="mb-0" style={{ fontWeight: "700", fontSize: "22px" }}>{workshopCompleted.length}</h4>
//                                             </div>
//                                         </div>
//                                         <hr class="dark horizontal my-0" />
//                                         <div class="card-footer p-1" style={{ borderStyle: "none" }}>
//                                             <div class="row">


//                                                 <div class="pr-4" style={{ display: "flex", justifyContent: "end" }}>
//                                                     <span class="badge badge-warning dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ cursor: "pointer", backgroundColor: "#ec407a", color: "white" }}>Filter</span>
//                                                     <div class="dropdown-menu">
//                                                         <a class="dropdown-item" href="#" onClick={() => workshopMonthely()}>Monthly</a>
//                                                         <a class="dropdown-item" href="#" onClick={() => workshopQuaterly()}>Quarterly</a>
//                                                         <a class="dropdown-item" href="#" onClick={() => workshopYearly()}>Yearly</a>
//                                                         <a class="dropdown-item" href="#" onClick={() => workshopAll()}>All</a>
//                                                         {/* <div class="dropdown-divider"></div>
//                                                 <a class="dropdown-item" href="#">Separated link</a> */}
//                                                     </div>
//                                                 </div>
//                                             </div>

//                                         </div>
//                                     </div>
//                                 </div>
//                                 <div class="col-xl-3 col-sm-6">
//                                     <div class="card">
//                                         <div class="card-header p-2 pt-1" style={{ borderStyle: "none" }}>
//                                             <div
//                                                 class="icon-md icon-shape bg-gradient-info shadow-info text-center border-radius-xl mt-n4 position-absolute">
//                                                 <i class="material-icons opacity-10">weekend</i>
//                                             </div>
//                                             <div class="text-end pt-1">
//                                                 <p class="mb-0 text-capitalize" style={{ fontWeight: "bold", fontSize: "13.1px" }}>Portfolio Created</p>
//                                                 <h4 class="mb-0" style={{ fontWeight: "700", fontSize: "22px" }}>{porto.length}</h4>
//                                             </div>
//                                         </div>
//                                         <hr class="dark horizontal my-0" />
//                                         <div class="card-footer p-1" style={{ borderStyle: "none" }}>
//                                             <div class="row">
//                                                 <div class="pr-4" style={{ display: "flex", justifyContent: "end" }}>
//                                                     <span class="badge badge-warning dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ cursor: "pointer", backgroundColor: "#ec407a", color: "white" }}>Filter</span>
//                                                     <div class="dropdown-menu">
//                                                         <a class="dropdown-item" href="#" onClick={() => portoMonthely()}>Monthly</a>
//                                                         <a class="dropdown-item" href="#" onClick={() => portoQuaterly()}>Quarterly</a>
//                                                         <a class="dropdown-item" href="#" onClick={() => portoYearly()}>Yearly</a>
//                                                         <a class="dropdown-item" href="#" onClick={() => portoAll()}>All</a>
//                                                     </div>
//                                                 </div>
//                                             </div>

//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                             {viewMore ? <><div class="p-3"></div></> : <></>}

//                             {viewMore ?
//                                 <>
//                                     <div class="row">
//                                         <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
//                                             <div class="card">
//                                                 <div class="card-header p-2 pt-1" style={{ borderStyle: "none" }}>
//                                                     <div
//                                                         class="icon-md icon-shape bg-gradient-success shadow-success text-center border-radius-xl mt-n4 position-absolute">
//                                                         <i class="material-icons opacity-10">person</i>
//                                                     </div>
//                                                     <div class="text-end pt-1">
//                                                         <p class="mb-0 text-capitalize" style={{ fontWeight: "bold", fontSize: "13.1px" }}>Total Loan Application</p>
//                                                         <h4 class="mb-0" style={{ fontWeight: "700", fontSize: "22px" }}>{loan.length}</h4>
//                                                     </div>
//                                                 </div>
//                                                 <hr class="dark horizontal my-0" />
//                                                 <div class="card-footer p-1" style={{ borderStyle: "none" }}>
//                                                     <div class="row">


//                                                         <div class="pr-4" style={{ display: "flex", justifyContent: "end" }}>
//                                                             <span class="badge badge-warning dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ cursor: "pointer", backgroundColor: "#ec407a", color: "white" }}>Filter</span>
//                                                             <div class="dropdown-menu">
//                                                                 <a class="dropdown-item" href="#" onClick={() => loanMonthely()}>Monthly</a>
//                                                                 <a class="dropdown-item" href="#" onClick={() => loanQuaterly()}>Quarterly</a>
//                                                                 <a class="dropdown-item" href="#" onClick={() => loanYearly()}>Yearly</a>
//                                                                 <a class="dropdown-item" href="#" onClick={() => loanAll()}>All</a>
//                                                                 {/* <div class="dropdown-divider"></div>
//                                                 <a class="dropdown-item" href="#">Separated link</a> */}
//                                                             </div>
//                                                         </div>
//                                                     </div>

//                                                 </div>
//                                             </div>
//                                         </div>
//                                         <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
//                                             <div class="card">
//                                                 <div class="card-header p-2 pt-1" style={{ borderStyle: "none" }}>
//                                                     <div
//                                                         class="icon-md icon-shape bg-gradient-info shadow-info text-center border-radius-xl mt-n4 position-absolute">
//                                                         <i class="material-icons opacity-10">person</i>
//                                                     </div>
//                                                     <div class="text-end pt-1">
//                                                         <p class="mb-0 text-capitalize" style={{ fontWeight: "bold", fontSize: "13.1px" }}>Total Loan Disbursed</p>
//                                                         <h4 class="mb-0" style={{ fontWeight: "700", fontSize: "22px" }}>{dicbCount.length}</h4>
//                                                     </div>
//                                                 </div>
//                                                 <hr class="dark horizontal my-0" />
//                                                 <div class="card-footer p-1" style={{ borderStyle: "none" }}>
//                                                     <div class="row">


//                                                         <div class="pr-4" style={{ display: "flex", justifyContent: "end" }}>
//                                                             <span class="badge badge-warning dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ cursor: "pointer", backgroundColor: "#ec407a", color: "white" }}>Filter</span>
//                                                             <div class="dropdown-menu">
//                                                                 <a class="dropdown-item" href="#" onClick={() => loanDisMonthely()}>Monthly</a>
//                                                                 <a class="dropdown-item" href="#" onClick={() => loanDisQuaterly()}>Quarterly</a>
//                                                                 <a class="dropdown-item" href="#" onClick={() => loanDisYearly()}>Yearly</a>
//                                                                 <a class="dropdown-item" href="#" onClick={() => loanDisAll()}>All</a>
//                                                                 {/* <div class="dropdown-divider"></div>
//                                                 <a class="dropdown-item" href="#">Separated link</a> */}
//                                                             </div>
//                                                         </div>
//                                                     </div>

//                                                 </div>

//                                             </div>
//                                         </div>

//                                         <div class="col-xl-3 col-sm-6">
//                                             <div class="card">
//                                                 <div class="card-header p-2 pt-1" style={{ borderStyle: "none" }}>
//                                                     <div
//                                                         class="icon-md icon-shape bg-gradient-info shadow-info text-center border-radius-xl mt-n4 position-absolute">
//                                                         <i class="material-icons opacity-10">weekend</i>
//                                                     </div>
//                                                     <div class="text-end pt-1">
//                                                         <p class="mb-0 text-capitalize" style={{ fontWeight: "bold", fontSize: "13.1px" }}>Amount Disbursed</p>
//                                                         <h4 class="mb-0" style={{ fontWeight: "700", fontSize: "22px" }}>₹ {disbAmount.toLocaleString()}</h4>
//                                                     </div>
//                                                 </div>
//                                                 <hr class="dark horizontal my-0" />
//                                                 <div class="card-footer p-1" style={{ borderStyle: "none" }}>
//                                                     <div class="row">
//                                                         <div class="pr-4" style={{ display: "flex", justifyContent: "end" }}>
//                                                             <span class="badge badge-warning dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ cursor: "pointer", backgroundColor: "#fff", color: "white" }}>Filter</span>

//                                                         </div>
//                                                     </div>

//                                                 </div>
//                                             </div>
//                                         </div>

//                                         <div class="col-xl-3 col-sm-6">
//                                             <div class="card">
//                                                 <div class="card-header p-2 pt-1" style={{ borderStyle: "none" }}>
//                                                     <div
//                                                         class="icon-md icon-shape bg-gradient-info shadow-info text-center border-radius-xl mt-n4 position-absolute">
//                                                         <i class="material-icons opacity-10">weekend</i>
//                                                     </div>
//                                                     <div class="text-end pt-1">
//                                                         <p class="mb-0 text-capitalize" style={{ fontWeight: "bold", fontSize: "13.1px" }}>Repayment Amount</p>
//                                                         <h4 class="mb-0" style={{ fontWeight: "700", fontSize: "22px" }}>₹ {repayAmt.toLocaleString()}</h4>
//                                                     </div>
//                                                 </div>
//                                                 <hr class="dark horizontal my-0" />
//                                                 <div class="card-footer p-1" style={{ borderStyle: "none" }}>
//                                                     <div class="row">
//                                                         <div class="pr-4" style={{ display: "flex", justifyContent: "end" }}>
//                                                             <span class="badge badge-warning dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ cursor: "pointer", backgroundColor: "#fff", color: "white" }}>Filter</span>

//                                                         </div>
//                                                     </div>

//                                                 </div>
//                                             </div>
//                                         </div>
//                                         {/* <div class="col-xl-3 col-sm-6">
//                                             <div class="card">
//                                                 <div class="card-header p-2 pt-1" style={{ borderStyle: "none" }}>
//                                                     <div
//                                                         class="icon-md icon-shape bg-gradient-info shadow-info text-center border-radius-xl mt-n4 position-absolute">
//                                                         <i class="material-icons opacity-10">weekend</i>
//                                                     </div>
//                                                     <div class="text-end pt-1">
//                                                         <p class="mb-0 text-capitalize" style={{ fontWeight: "bold", fontSize: "13.1px" }}>Total Demo Booked</p>
//                                                         <h4 class="mb-0" style={{ fontWeight: "700", fontSize: "22px" }}>0</h4>
//                                                     </div>
//                                                 </div>
//                                                 <hr class="dark horizontal my-0" />
//                                                 <div class="card-footer p-1" style={{ borderStyle: "none" }}>
//                                                     <div class="row">
//                                                         <div class="pr-4" style={{ display: "flex", justifyContent: "end" }}>
//                                                             <span class="badge badge-warning dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ cursor: "pointer", backgroundColor: "#ec407a", color: "white" }}>Filter</span>
//                                                             <div class="dropdown-menu">
//                                                                 <a class="dropdown-item" href="#">Monthly</a>
//                                                                 <a class="dropdown-item" href="#">Quarterly</a>
//                                                                 <a class="dropdown-item" href="#">Yearly</a>
//                                                             </div>
//                                                         </div>
//                                                     </div>

//                                                 </div>
//                                             </div>
//                                         </div> */}
//                                         {/* <div class="col-xl-3 col-sm-6">
//                                             <div class="card">
//                                                 <div class="card-header p-2 pt-1" style={{ borderStyle: "none" }}>
//                                                     <div
//                                                         class="icon-md icon-shape bg-gradient-info shadow-info text-center border-radius-xl mt-n4 position-absolute">
//                                                         <i class="material-icons opacity-10">weekend</i>
//                                                     </div>
//                                                     <div class="text-end pt-1">
//                                                         <p class="mb-0 text-capitalize" style={{ fontWeight: "bold", fontSize: "13.1px" }}>Course Enrollment</p>
//                                                         <h4 class="mb-0" style={{ fontWeight: "700", fontSize: "22px" }}>{courseEnrollment.length}</h4>
//                                                     </div>
//                                                 </div>
//                                                 <hr class="dark horizontal my-0" />
//                                                 <div class="card-footer p-1" style={{ borderStyle: "none" }}>
//                                                     <div class="row">
//                                                         <div class="pr-4" style={{ display: "flex", justifyContent: "end" }}>
//                                                             <span class="badge badge-warning dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ cursor: "pointer", backgroundColor: "#ec407a", color: "white" }}>Filter</span>
//                                                             <div class="dropdown-menu">
//                                                                 <a class="dropdown-item" href="#">Monthly</a>
//                                                                 <a class="dropdown-item" href="#">Quarterly</a>
//                                                                 <a class="dropdown-item" href="#">Yearly</a>
//                                                             </div>
//                                                         </div>
//                                                     </div>

//                                                 </div>
//                                             </div>
//                                         </div> */}
//                                     </div>
//                                     <div class="row">
//                                         <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
//                                             <div class="card">
//                                                 <div class="card-header p-2 pt-1" style={{ borderStyle: "none" }}>
//                                                     <div
//                                                         class="icon-md icon-shape bg-gradient-success shadow-success text-center border-radius-xl mt-n4 position-absolute">
//                                                         <i class="material-icons opacity-10">person</i>
//                                                     </div>
//                                                     <div class="text-end pt-1">
//                                                         <p class="mb-0 text-capitalize" style={{ fontWeight: "bold", fontSize: "13.1px" }}>visiting cards created</p>
//                                                         <h4 class="mb-0" style={{ fontWeight: "700", fontSize: "22px" }}>{totalDataLength == 0 ? businessCard.length + businessCardPre.length : totalDataLength}</h4>
//                                                     </div>
//                                                 </div>
//                                                 <hr class="dark horizontal my-0" />
//                                                 <div class="card-footer p-1" style={{ borderStyle: "none" }}>
//                                                     <div class="row">


//                                                         <div class="pr-4" style={{ display: "flex", justifyContent: "end" }}>
//                                                             <span class="badge badge-warning dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ cursor: "pointer", backgroundColor: "#ec407a", color: "white" }}>Filter</span>
//                                                             <div class="dropdown-menu">
//                                                                 <a class="dropdown-item" href="#" onClick={() => FreeCard()}>Free</a>
//                                                                 <a class="dropdown-item" href="#" onClick={() => PreCard()}>Premium</a>
//                                                                 <a class="dropdown-item" href="#" onClick={() => AllCard()}>All</a>
//                                                                 {/* <div class="dropdown-divider"></div>
//                                                 <a class="dropdown-item" href="#">Separated link</a> */}
//                                                             </div>
//                                                         </div>
//                                                     </div>

//                                                 </div>
//                                             </div>
//                                         </div>
//                                         <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
//                                             <div class="card">
//                                                 <div class="card-header p-2 pt-1" style={{ borderStyle: "none" }}>
//                                                     <div
//                                                         class="icon-md icon-shape bg-gradient-info shadow-info text-center border-radius-xl mt-n4 position-absolute">
//                                                         <i class="material-icons opacity-10">person</i>
//                                                     </div>
//                                                     <div class="text-end pt-1">
//                                                         <p class="mb-0 text-capitalize" style={{ fontWeight: "bold", fontSize: "13.1px" }}>Total Appointments</p>
//                                                         <h4 class="mb-0" style={{ fontWeight: "700", fontSize: "22px" }}>{appointments.length}</h4>
//                                                     </div>
//                                                 </div>
//                                                 <hr class="dark horizontal my-0" />
//                                                 <div class="card-footer p-1" style={{ borderStyle: "none" }}>
//                                                     <div class="row">


//                                                         <div class="pr-4" style={{ display: "flex", justifyContent: "end" }}>
//                                                             <span class="badge badge-warning dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ cursor: "pointer", backgroundColor: "#fff", color: "white" }}>Filter</span>

//                                                         </div>
//                                                     </div>

//                                                 </div>

//                                             </div>
//                                         </div>

//                                         <div class="col-xl-3 col-sm-6">
//                                             <div class="card">
//                                                 <div class="card-header p-2 pt-1" style={{ borderStyle: "none" }}>
//                                                     <div
//                                                         class="icon-md icon-shape bg-gradient-info shadow-info text-center border-radius-xl mt-n4 position-absolute">
//                                                         <i class="material-icons opacity-10">weekend</i>
//                                                     </div>
//                                                     <div class="text-end pt-1">
//                                                         <p class="mb-0 text-capitalize" style={{ fontWeight: "bold", fontSize: "13.1px" }}>Customer Reviews</p>
//                                                         <h4 class="mb-0" style={{ fontWeight: "700", fontSize: "22px" }}>{reviewCount.length}</h4>
//                                                     </div>
//                                                 </div>
//                                                 <hr class="dark horizontal my-0" />
//                                                 <div class="card-footer p-1" style={{ borderStyle: "none" }}>
//                                                     <div class="row">
//                                                         <div class="pr-4" style={{ display: "flex", justifyContent: "end" }}>
//                                                             <span class="badge badge-warning dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ cursor: "pointer", backgroundColor: "#fff", color: "white" }}>Filter</span>

//                                                         </div>
//                                                     </div>

//                                                 </div>
//                                             </div>
//                                         </div>

//                                     </div> </> : <></>}

//                             <div class="pt-2" style={{ textAlign: "end" }}><span style={{ fontWeight: 500, fontSize: "15px", color: "#17a2b8", cursor: "pointer" }} onClick={() => viewMo()}> {viewMore ? <>View Less</> : <>View More</>}</span></div>


//                             <div class="row mt-4">
//                                 <div class="col-lg-4 col-md-6 mt-4 mb-4">
//                                     <div class="card">
//                                         <div class="card-header p-0 position-relative mt-n4 mx-3 bg-transparent">
//                                             <div class="bg-gradient-primary shadow-primary border-radius-lg py-3 pe-1">
//                                                 <div class="chart">
//                                                     <canvas id="chart-bars" class="chart-canvas" height="170"></canvas>
//                                                 </div>
//                                             </div>
//                                         </div>
//                                         <div class="card-body">
//                                             <h6 class="mb-0 ">Month wise App Download</h6>
//                                             <p class="text-sm ">Last Campaign Performance</p>
//                                             <hr class="dark horizontal" />
//                                             {/* <div class="d-flex ">
//                                         <i class="material-icons text-sm my-auto me-1">schedule</i>
//                                         <p class="mb-0 text-sm"> campaign sent 2 days ago </p>
//                                     </div> */}
//                                         </div>
//                                     </div>
//                                 </div>
//                                 <div class="col-lg-4 col-md-6 mt-4 mb-4">
//                                     <div class="card">
//                                         <div class="card-header p-0 position-relative mt-n4 mx-3 bg-transparent">
//                                             <div class="bg-gradient-success shadow-success border-radius-lg py-3 pe-1">
//                                                 <div class="chart">
//                                                     <canvas id="chart-line" class="chart-canvas" height="170"></canvas>
//                                                 </div>
//                                             </div>
//                                         </div>
//                                         <div class="card-body">
//                                             <h6 class="mb-0 ">Portfolio Created</h6>
//                                             <p class="text-sm "> (<span class="font-weight-bolder">+15%</span>) Campaign Performance. </p>
//                                             <hr class="dark horizontal" />
//                                             {/* <div class="d-flex ">
//                                         <i class="material-icons text-sm my-auto me-1">schedule</i>
//                                         <p class="mb-0 text-sm"> updated 4 min ago </p>
//                                     </div> */}
//                                         </div>
//                                     </div>
//                                 </div>
//                                 <div class="col-lg-4 mt-4 mb-3">
//                                     <div class="card">
//                                         <div class="card-header p-0 position-relative mt-n4 mx-3 bg-transparent">
//                                             <div class="bg-gradient-dark shadow-dark border-radius-lg py-3 pe-1">
//                                                 <div class="chart">
//                                                     <canvas id="chart-line-tasks" class="chart-canvas" height="170"></canvas>
//                                                 </div>
//                                             </div>
//                                         </div>
//                                         <div class="card-body">
//                                             <h6 class="mb-0 ">Loan Repayment</h6>
//                                             <p class="text-sm ">&nbsp;</p>
//                                             <hr class="dark horizontal" />
//                                             {/* <div class="d-flex ">
//                                         <i class="material-icons text-sm my-auto me-1">schedule</i>
//                                         <p class="mb-0 text-sm">just updated</p>
//                                     </div> */}
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>


//                             <div class="row mt-4">
//                                 <div class="col-lg-6 col-12">
//                                     <div class="card mt-4">
//                                         <div class="card-header p-3 pt-2">
//                                             <div class="icon-lg icon-shape bg-gradient-warning shadow-warning text-center border-radius-xl mt-n4 me-3 float-start">
//                                                 <i class="material-icons opacity-10">donut_small</i>
//                                             </div>
//                                             <h6 class="mb-0">State wise Portfolio</h6>
//                                             <p class="mb-0 text-sm">Analytics Insights</p>
//                                         </div>
//                                         <div class="card-body d-flex p-3 pt-0">
//                                             <div class="chart w-50">

//                                                 <canvas id="pie-chart" class="chart-canvas" height="300px"></canvas>
//                                             </div>
//                                             <div class="table-responsive w-50">
//                                                 <table class="table align-items-center mb-0">
//                                                     <tbody>
//                                                         {stateName?.map((data, index) => {
//                                                             const portfolioCount = stateCountsAll[index]; // Get the count of portfolios for the current state
//                                                             const totalPortfolios = stateCountsAll.reduce((sum, count) => sum + count, 0); // Calculate the total count of portfolios

//                                                             // Calculate the percentage
//                                                             const percentage = (portfolioCount / totalPortfolios) * 100;

//                                                             return (
//                                                                 <tr key={index}>
//                                                                     <td>
//                                                                         <div className="d-flex px-2 py-1">
//                                                                             <div className="d-flex flex-column justify-content-center">
//                                                                                 <h6 className="mb-0 text-sm">{data}</h6>
//                                                                             </div>
//                                                                         </div>
//                                                                     </td>
//                                                                     <td className="align-middle text-center text-sm">
//                                                                         <span className="text-xs font-weight-bold">{percentage.toFixed(2)}%</span>
//                                                                     </td>
//                                                                 </tr>
//                                                             );
//                                                         })}

//                                                     </tbody>
//                                                 </table>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </div>
//                                 <div class="col-lg-6 col-12 mt-md-0 mt-4">
//                                     <div class="card mt-4">
//                                         <div class="card-header p-3 pt-2">
//                                             <div class="icon-lg icon-shape bg-gradient-warning shadow-warning text-center border-radius-xl mt-n4 me-3 float-start">
//                                                 <i class="material-icons opacity-10">pie_chart</i>
//                                             </div>
//                                             <h6 class="mb-0">User Types</h6>
//                                             <p class="mb-0 text-sm">Analytics Insights</p>
//                                         </div>
//                                         <div class="card-body d-flex p-3 pt-0">
//                                             <div class="chart w-50">
//                                                 <canvas id="doughnut-chart" class="chart-canvas" height="460" width="514" style={{ display: "block", boxSizing: "border-box", height: "307.2px", width: "343px" }}></canvas>
//                                             </div>
//                                             <div class="w-50 my-auto ms-5">
//                                                 <span class=" badge-lg badge-dot me-4 d-block text-start">
//                                                     <span style={{ height: "13px", width: "12px", backgroundColor: "#007bff", borderRadius: "50%", display: "inline-block", marginRight: "7px" }}></span>
//                                                     <span class="text-dark">Parlour</span>
//                                                 </span>
//                                                 <span class=" badge-lg badge-dot me-4 d-block text-start">
//                                                     <span style={{ height: "13px", width: "12px", backgroundColor: "green", borderRadius: "50%", display: "inline-block", marginRight: "7px" }}></span>
//                                                     <span class="text-dark">Freelancer</span>
//                                                 </span>
//                                                 {/* <span class=" badge-lg badge-dot me-4 d-block text-start">
//                                             <span style={{ height: "13px", width: "12px", backgroundColor: "#fb8c00", borderRadius: "50%", display: "inline-block", marginRight: "7px" }}></span>
//                                             <span class="text-dark">Saloon</span>
//                                         </span> */}

//                                                 <p class="text-sm mt-3">
//                                                     {/* More than <b>1,200,000</b> sales are made using referral marketing, and <b>700,000</b> are from social media. */}
//                                                 </p>
//                                                 {/* <a class="btn bg-gradient-secondary mb-0" href="javascript:;">Read more</a> */}
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                         </> : <></>}




//                         {/* <div class="">
//                                 {activeIndex == 0 ? <CourseData /> : null}
//                                 {activeIndex == 1 ? <CourseDataHindi /> : null}
//                                 {activeIndex == 2 ? <PromotionalData /> : null}
//                                 {activeIndex == 3 ? <FreeBusinessCards /> : null}
//                                 {activeIndex == 4 ? <PremiumCards /> : null}
//                                 {activeIndex == 5 ? <WorkshopData /> : null}
//                                 {activeIndex == 6 ? <WorkshopDataHindi /> : null}
//                                 {activeIndex == 7 ? <UsersProfile /> : null}
//                                 {activeIndex == 8 ? <PortfolioData /> : null}
//                                 {activeIndex == 9 ? <CustomerData /> : null}
//                                 {activeIndex == 10 ? <ReferredCourses /> : null}
//                                 {activeIndex == 11 ? <BeautyKitRequest /> : null}
//                                 {activeIndex == 12 ? <BookDemoRequest /> : null}
//                                 {activeIndex == 13 ? <BusinessCardRequest /> : null}
//                                 {activeIndex == 14 ? <HireRequest /> : null}
//                                 {activeIndex == 15 ? <LoanRequest /> : null}
//                                 {activeIndex == 16 ? <PremiumCardRequest /> : null}
//                                 {activeIndex == 17 ? <WorkshopRegistration /> : null}
//                                 {activeIndex == 18 ? <Workshop /> : null}
//                                 {activeIndex == 19 ? <RequestCallBack /> : null}
//                                 {activeIndex == 20 ? <DeletePortfolioRequest /> : null}
//                                 {activeIndex == 21 ? <PortfolioAnalyser /> : null}
//                                 {activeIndex == 22 ? <PortfolioReviews /> : null}
//                                 {activeIndex == 23 ? <Appointments /> : null}

//                             </div> */}
//                     </main>
//                 </div>
//             </div>

//         </>
//     )
// }

// export default Analytics;




// // import React, { useState, useEffect } from 'react';
// // import { useNavigate } from 'react-router-dom';
// // import AdminPanel from './AdminPanel';
// // import db from "./Firebase";
// // import { storage } from './Firebase';
// // import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
// // import Moment from 'moment';
// // import { extendMoment } from 'moment-range';
// // import DateTime from 'react-datetime';

// // function Analytics() {
// //     const navigate = useNavigate();
// //     const moment = extendMoment(Moment);
// //     const [data, setData] = useState([]);
// //     const initialFormStateFeedback = { email: '', password: '', created_date: new Date() }
// //     const [submit, setSubmit] = useState(initialFormStateFeedback);
// //     const [udata, setUdata] = useState([]);


// //     const handleChange = (e) => {
// //         const { id, value } = e.target
// //         setSubmit({ ...submit, [id]: value })
// //     }

// //     const reset = () => {
// //         setSubmit({ email: '', password: '', created_date: new Date() })
// //     }


// //     const handleSubmit = (e) => {
// //         e.preventDefault();
// //         console.log(submit)
// //         window.openModelNew();
// //         if (submit.email == "admin@gmail.com" && submit.password == "admin") {
// //             navigate('/admin_panel');
// //         } else {
// //             alert("Wrong");
// //         }
// //     }

// //     const webData = [50, 20, 10, 22, 50, 10, 40];

// //     const chartsOne = () => {
// //         // window.websiteView();
// //         window.charts();
// //     }

// //     const openModalShow = () => {
// //         // alert("ooo")
// //         window.openModelshow();
// //     }

// //     const changeStatus = () => {
// //         openModalShow();
// //     }

// //     const chrtFun = () => {

// //     }

// //     const Jstart = new Date('2023-01-01T00:00:00.000z');
// //     const Jend = new Date('2023-01-31T23:59:59.000z');

// //     const Astart = new Date('2023-02-01T00:00:00.000z');
// //     const Aend = new Date('2023-02-28T23:59:59.000z');

// //     const Sstart = new Date('2023-03-18T00:00:00.000z');
// //     const Send = new Date('2023-03-31T23:59:59.000z');

// //     const Ostart = new Date('2023-04-01T00:00:00.000z');
// //     const Oend = new Date('2023-04-30T23:59:59.000z');

// //     const Nstart = new Date('2023-05-01T00:00:00.000z');
// //     const Nend = new Date('2023-05-31T23:59:59.000z');

// //     const Dstart = new Date('2023-06-01T00:00:00.000z');
// //     const Dend = new Date('2023-06-30T23:59:59.000z');

// //     var [appDownload, setAppdownload] = useState([]);
// //     var [count, setCount] = useState(0);

// //     const [idMapping, setIdMapping] = useState([]);


// //     const [courseEnrollment, setCourseEnrollment] = useState([]);
// //     const [workshopCompleted, setWorkshopCompleted] = useState([]);


// //     const downloadMonthely = () => {
// //         const today = new Date();
// //         today.setHours(0);
// //         today.setMinutes(0);
// //         today.setMilliseconds(0);
// //         today.setSeconds(0);

// //         var date = new Date();
// //         var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
// //         var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
// //         console.log(firstDay);
// //         console.log(lastDay);
// //         db.collection("personal_details").where('created_date', '>=', firstDay).where('created_date', '<=', lastDay).onSnapshot((snapshot) => {
// //             setUdata(
// //                 snapshot.docs.map((doc) => ({
// //                     id: doc.id,
// //                     data: doc.data(),
// //                 }))
// //             );
// //         });
// //     }

// //     const downloadQuaterly = () => {
// //         const today = new Date();
// //         const quarter = Math.floor((today.getMonth() / 3));
// //         // const startFullQuarter = new Date(today.getFullYear(), quarter * 6, 1);
// //         // const endFullQuarter = new Date(startFullQuarter.getFullYear(), startFullQuarter.getMonth() + 6, 0);
// //         // const startFullQuarter = new Date(today.getFullYear(), quarter * 6, 1);
// //         // const endFullQuarter = new Date(startFullQuarter.getFullYear(), startFullQuarter.getMonth() + 6, 0);
// //         const startFullQuarter = new Date(today.getFullYear(), quarter * 3, 1);
// //         const endFullQuarter = new Date(startFullQuarter.getFullYear(), startFullQuarter.getMonth() + 3, 0);


// //         console.log(startFullQuarter);
// //         console.log(endFullQuarter);
// //         db.collection("personal_details").where('created_date', '>=', startFullQuarter).where('created_date', '<=', endFullQuarter).onSnapshot((snapshot) => {
// //             setUdata(
// //                 snapshot.docs.map((doc) => ({
// //                     id: doc.id,
// //                     data: doc.data(),
// //                 }))
// //             );
// //         });
// //     }

// //     const downloadYearly = () => {
// //         const currentYear = new Date().getFullYear();
// //         console.log(currentYear); // 👉️2022

// //         const firstDay = new Date(currentYear, 0, 1);
// //         console.log(firstDay); // 👉️ Sat Jan 01 2022

// //         const lastDay = new Date(currentYear, 11, 31);
// //         console.log(lastDay);

// //         db.collection("personal_details").where('created_date', '>=', firstDay).where('created_date', '<=', lastDay).onSnapshot((snapshot) => {
// //             setUdata(
// //                 snapshot.docs.map((doc) => ({
// //                     id: doc.id,
// //                     data: doc.data(),
// //                 }))
// //             );
// //         });

// //     }

// //     useEffect(() => {

// //         db.collection("personal_details").onSnapshot((snapshot) => {
// //             setUdata(
// //                 snapshot.docs.map((doc) => ({
// //                     id: doc.id,
// //                     data: doc.data(),
// //                 }))
// //             );
// //         });

// //         var arr1 = [];
// //         var arr2 = [];
// //         var arr3 = [];
// //         var arr4 = [];
// //         db.collection("personal_details").onSnapshot((snapshot) => {

// //             // setCourseEnrollment(
// //             //     snapshot.docs.map((doc) => ({
// //             //         data: doc.data(),
// //             //     }))
// //             // );
// //             snapshot.docs.map((doc) => (
// //                 // console.log(doc.data().type),
// //                 doc.data().type == 'parlour' ? arr2.push(doc.data().type) : null,
// //                 doc.data().type == 'freelancer' ? arr1.push(doc.data().type) : null,
// //                 doc.data().type == 'student' ? arr3.push(doc.data().type) : null
// //             ))

// //             var arrLen = [];
// //             arrLen.push(arr1.length)
// //             arrLen.push(arr2.length)
// //             arrLen.push(arr3.length)
// //             console.log(arrLen)
// //             window.donught(arrLen);
// //         });

// //         // window.donught([20, 10, 40, 50]);


// //         db.collection("course_enrollment").onSnapshot((snapshot) => {

// //             setCourseEnrollment(
// //                 snapshot.docs.map((doc) => ({
// //                     data: doc.data(),
// //                 }))
// //             );
// //         });

// //         var wDay = new Date();
// //         db.collection("workshop_event_data").where('created_date', '<=', wDay).onSnapshot((snapshot) => {
// //             setWorkshopCompleted(
// //                 snapshot.docs.map((doc) => ({
// //                     id: doc.id,
// //                     data: doc.data(),
// //                 }))
// //             );
// //         });


// //         // db.collection('personal_details').where('created_date', '>=', start).where('created_date', '<=', end).get().then(doc => {
// //         //     console.log(doc.data)
// //         // });
// //         // db.collection("personal_details").where('created_date', '>=', Ostart).where('created_date', '<=', Oend).onSnapshot((snapshot) => {

// //         //     // setData(
// //         //     //     snapshot.docs.map((doc) => ({
// //         //     //         id: doc.id,
// //         //     //         data: doc.data(),
// //         //     //     }))
// //         //     // );

// //         //     snapshot.docs.map((doc) => (
// //         //         // console.log(doc.data())

// //         //         console.log(snapshot.size),
// //         //         appDownload = snapshot.size,
// //         //         console.log(appDownload)
// //         //     ))
// //         var jy = 0;
// //         var au = 0;
// //         var se = 0;
// //         var oc = 0;
// //         var no = 0;
// //         var de = 0;
// //         db.collection("personal_details").onSnapshot((snapshot) => {
// //             snapshot.docs.map((doc) => {
// //                 if (doc.data().created_date.toDate() >= Jstart && doc.data().created_date.toDate() <= Aend) {
// //                     jy = jy += 1
// //                 }

// //                 if (doc.data().created_date.toDate() >= Astart && doc.data().created_date.toDate() <= Aend) {
// //                     au = au += 1
// //                 }

// //                 if (doc.data().created_date.toDate() >= Sstart && doc.data().created_date.toDate() <= Send) {
// //                     se = se += 1
// //                 }

// //                 if (doc.data().created_date.toDate() >= Ostart && doc.data().created_date.toDate() <= Oend) {
// //                     oc = oc += 1
// //                 }

// //                 if (doc.data().created_date.toDate() >= Nstart && doc.data().created_date.toDate() <= Nend) {
// //                     no = no += 1
// //                 }

// //                 if (doc.data().created_date.toDate() >= Dstart && doc.data().created_date.toDate() <= Dend) {
// //                     de = de += 1
// //                 }
// //             })
// //             setAppdownload([jy, au, se, oc, no, de])
// //             // window.websiteView([50, 20, 10, 22, 50, 10]);
// //             window.websiteView([jy, au, se, oc, no, de]);
// //             console.log(appDownload);

// //         });
// //         // db.collection("personal_details").where('created_date', '>=', Astart).where('created_date', '<=', Aend).onSnapshot((snapshot) => {
// //         //     snapshot.docs.map((doc) => {
// //         //         if (doc.data().created_date.toDate() >= Ostart && doc.data().created_date.toDate() <= Oend) {
// //         //             jy = jy += 1
// //         //         } else {
// //         //             jy = 0;
// //         //         }
// //         //     })
// //         //     console.log(jy);
// //         // });
// //         chartsOne();
// //     }, []);

// //     const [viewMore, setViewMore] = useState(false);

// //     const view = () => {
// //         if (viewMore) {
// //             setViewMore(false)
// //         } else {
// //             setViewMore(true)
// //         }
// //     }

// //     return (
// //         <>
// //             <div class="col-md-4">
// //                 <div class="myId modal fade" id="" tabindex="-1" role="dialog" aria-labelledby="modal-form" aria-hidden="true">
// //                     <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
// //                         <div class="modal-content">
// //                             <div class="modal-body p-0">
// //                                 <div class="card card-plain">
// //                                     <div class="card-header pb-0 text-left">
// //                                         <h5 class="pb-4" style={{ fontSize: "13px", fontWeight: "bolder" }}>Enter your email and password to sign in</h5>
// //                                     </div>
// //                                     <div class="card-body">
// //                                         <form role="form text-left" onSubmit={handleSubmit}>
// //                                             <div class="input-group input-group-outline my-3">
// //                                                 {/* <label class="form-label">Email</label> */}
// //                                                 <input type="email" id="email" class="form-control" onfocus="focused(this)" onfocusout="defocused(this)" onChange={handleChange} value={submit.email} placeholder="Email" required />
// //                                             </div>
// //                                             <div class="input-group input-group-outline my-3">
// //                                                 <input type="password" id="password" onChange={handleChange} value={submit.password} class="form-control" onfocus="focused(this)" onfocusout="defocused(this)" placeholder="Password" required />
// //                                             </div>

// //                                             <div class="text-center">
// //                                                 <button type="submit" style={{ backgroundColor: "#1a73e8" }} class="btn btn-round bg-gradient-info btn-lg w-100 mt-4 mb-0">Sign in</button>
// //                                             </div>
// //                                         </form>
// //                                     </div>
// //                                 </div>
// //                             </div>
// //                         </div>
// //                     </div>
// //                 </div>
// //             </div>

// //             <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
// //                 <div class="" style={{ display: "flex", justifyContent: "flex-end", paddingRight: "14px", paddingTop: "6px" }}>

// //                     <span onClick={() => changeStatus()} class="" style={{
// //                         background: "#ec407a", fontWeight: "600", width: "63px", height: "63px", borderRadius: "50%", display: "flex",
// //                         alignItems: "center", justifyContent: "center", color: "white", cursor: "pointer"
// //                     }}>Login
// //                     </span>
// //                 </div>
// //                 <div class="container-fluid py-4">
// //                     <div class="row">
// //                         <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
// //                             <div class="card">
// //                                 <div class="card-header p-3 pt-2" style={{ borderStyle: "none" }}>
// //                                     <div
// //                                         class="icon-lg icon-shape bg-gradient-dark shadow-dark text-center border-radius-xl mt-n4 position-absolute">
// //                                         <i class="material-icons opacity-10">weekend</i>
// //                                     </div>
// //                                     <div class="text-end pt-1">
// //                                         <p class="text-sm mb-0 text-capitalize" style={{ fontWeight: "bold" }}>Apps Download</p>
// //                                         <h4 class="mb-0" style={{ fontWeight: "700", fontSize: "22px" }}>{udata.length}</h4>
// //                                     </div>
// //                                 </div>
// //                                 <hr class="dark horizontal my-0" />
// //                                 <div class="card-footer p-3" style={{ borderStyle: "none" }}>
// //                                     <div class="row">
// //                                         <div class="col-sm-8">
// //                                             <span class="mb-0" style={{ fontSize: "14px", fontWeight: "500" }}><span class="text-success text-sm font-weight-bolder">+55% </span>than last month</span>
// //                                         </div>

// //                                         <div class="col-sm-4">
// //                                             <span class="badge badge-warning dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ cursor: "pointer", backgroundColor: "#ec407a", color: "white" }}>Filter</span>
// //                                             <div class="dropdown-menu">
// //                                                 <a class="dropdown-item" href="#" onClick={() => downloadMonthely()}>Monthly</a>
// //                                                 <a class="dropdown-item" href="#" onClick={() => downloadQuaterly()}>Quarterly</a>
// //                                                 <a class="dropdown-item" href="#" onClick={() => downloadYearly()}>Yearly</a>
// //                                                 {/* <div class="dropdown-divider"></div>
// //                                                 <a class="dropdown-item" href="#">Separated link</a> */}
// //                                             </div>
// //                                         </div>
// //                                     </div>

// //                                 </div>
// //                             </div>
// //                         </div>
// //                         <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
// //                             <div class="card">
// //                                 <div class="card-header p-3 pt-2" style={{ borderStyle: "none" }}>
// //                                     <div
// //                                         class="icon-lg icon-shape bg-gradient-primary shadow-primary text-center border-radius-xl mt-n4 position-absolute">
// //                                         <i class="material-icons opacity-10">person</i>
// //                                     </div>
// //                                     <div class="text-end pt-1">
// //                                         <p class="text-sm mb-0 text-capitalize" style={{ fontWeight: "bold" }}>Total Revenue</p>
// //                                         <h4 class="mb-0" style={{ fontWeight: "700", fontSize: "22px" }}>20</h4>
// //                                     </div>
// //                                 </div>
// //                                 <hr class="dark horizontal my-0" />
// //                                 <div class="card-footer p-3" style={{ borderStyle: "none" }}>
// //                                     <div class="row">
// //                                         <div class="col-sm-8">
// //                                             <p class="mb-0" style={{ fontSize: "14px", fontWeight: "500" }}><span class="text-success text-sm font-weight-bolder">+3% </span>than last month</p>
// //                                         </div>

// //                                         <div class="col-sm-4">
// //                                             <span class="badge badge-warning dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ cursor: "pointer", backgroundColor: "#ec407a", color: "white" }}>Filter</span>
// //                                             <div class="dropdown-menu">
// //                                                 <a class="dropdown-item" href="#">Monthly</a>
// //                                                 <a class="dropdown-item" href="#">Quarterly</a>
// //                                                 <a class="dropdown-item" href="#">Yearly</a>
// //                                                 {/* <div class="dropdown-divider"></div>
// //                                                 <a class="dropdown-item" href="#">Separated link</a> */}
// //                                             </div>
// //                                         </div>
// //                                     </div>

// //                                 </div>

// //                             </div>
// //                         </div>
// //                         <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
// //                             <div class="card">
// //                                 <div class="card-header p-3 pt-2" style={{ borderStyle: "none" }}>
// //                                     <div
// //                                         class="icon-lg icon-shape bg-gradient-success shadow-success text-center border-radius-xl mt-n4 position-absolute">
// //                                         <i class="material-icons opacity-10">person</i>
// //                                     </div>
// //                                     <div class="text-end pt-1">
// //                                         <p class="text-sm mb-0 text-capitalize" style={{ fontWeight: "bold" }}>Workshop Completed</p>
// //                                         <h4 class="mb-0" style={{ fontWeight: "700", fontSize: "22px" }}>{workshopCompleted.length}</h4>
// //                                     </div>
// //                                 </div>
// //                                 <hr class="dark horizontal my-0" />
// //                                 <div class="card-footer p-3" style={{ borderStyle: "none" }}>
// //                                     <div class="row">
// //                                         <div class="col-sm-8">
// //                                             <p class="mb-0" style={{ fontSize: "14px", fontWeight: "500" }}><span class="text-danger text-sm font-weight-bolder">-2%</span> than last month</p>
// //                                         </div>

// //                                         <div class="col-sm-4">
// //                                             <span class="badge badge-warning dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ cursor: "pointer", backgroundColor: "#ec407a", color: "white" }}>Filter</span>
// //                                             <div class="dropdown-menu">
// //                                                 <a class="dropdown-item" href="#">Monthly</a>
// //                                                 <a class="dropdown-item" href="#">Quarterly</a>
// //                                                 <a class="dropdown-item" href="#">Yearly</a>
// //                                                 {/* <div class="dropdown-divider"></div>
// //                                                 <a class="dropdown-item" href="#">Separated link</a> */}
// //                                             </div>
// //                                         </div>
// //                                     </div>

// //                                 </div>

// //                             </div>
// //                         </div>
// //                         <div class="col-xl-3 col-sm-6">
// //                             <div class="card">
// //                                 <div class="card-header p-3 pt-2" style={{ borderStyle: "none" }}>
// //                                     <div
// //                                         class="icon-lg icon-shape bg-gradient-info shadow-info text-center border-radius-xl mt-n4 position-absolute">
// //                                         <i class="material-icons opacity-10">weekend</i>
// //                                     </div>
// //                                     <div class="text-end pt-1">
// //                                         <p class="text-sm mb-0 text-capitalize" style={{ fontWeight: "bold" }}>Course Enrolled</p>
// //                                         <h4 class="mb-0" style={{ fontWeight: "700", fontSize: "22px" }}>{courseEnrollment.length}</h4>
// //                                     </div>
// //                                 </div>
// //                                 <hr class="dark horizontal my-0" />
// //                                 <div class="card-footer p-3" style={{ borderStyle: "none" }}>
// //                                     <div class="row">
// //                                         <div class="col-sm-8">
// //                                             <p class="mb-0" style={{ fontSize: "14px", fontWeight: "500" }}><span class="text-success text-sm font-weight-bolder">+5% </span>than last month</p>
// //                                         </div>

// //                                         <div class="col-sm-4">
// //                                             <span class="badge badge-warning dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ cursor: "pointer", backgroundColor: "#ec407a", color: "white" }}>Filter</span>
// //                                             <div class="dropdown-menu">
// //                                                 <a class="dropdown-item" href="#">Monthly</a>
// //                                                 <a class="dropdown-item" href="#">Quarterly</a>
// //                                                 <a class="dropdown-item" href="#">Yearly</a>
// //                                                 {/* <div class="dropdown-divider"></div>
// //                                                 <a class="dropdown-item" href="#">Separated link</a> */}
// //                                             </div>
// //                                         </div>
// //                                     </div>

// //                                 </div>
// //                             </div>
// //                         </div>
// //                     </div>
// //                     {viewMore ? <><div class="p-3"></div></> : <></>}

// //                     {viewMore ?
// //                         <>
// //                             <div class="row">
// //                                 <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
// //                                     <div class="card">
// //                                         <div class="card-header p-3 pt-2" style={{ borderStyle: "none" }}>
// //                                             <div
// //                                                 class="icon-lg icon-shape bg-gradient-warning shadow-success text-center border-radius-xl mt-n4 position-absolute">
// //                                                 <i class="material-icons opacity-10">person</i>
// //                                             </div>
// //                                             <div class="text-end pt-1">
// //                                                 <p class="text-sm mb-0 text-capitalize" style={{ fontWeight: "bold" }}>Mentors Session</p>
// //                                                 <h4 class="mb-0" style={{ fontWeight: "700", fontSize: "22px" }}>12</h4>
// //                                             </div>
// //                                         </div>
// //                                         <hr class="dark horizontal my-0" />
// //                                         <div class="card-footer p-3" style={{ borderStyle: "none" }}>
// //                                             <div class="row">
// //                                                 <div class="col-sm-8">
// //                                                     <p class="mb-0" style={{ fontSize: "14px", fontWeight: "500" }}><span class="text-danger text-sm font-weight-bolder">-2%</span> than last month</p>
// //                                                 </div>

// //                                                 <div class="col-sm-4">
// //                                                     <span class="badge badge-warning dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ cursor: "pointer", backgroundColor: "#ec407a", color: "white" }}>Filter</span>
// //                                                     <div class="dropdown-menu">
// //                                                         <a class="dropdown-item" href="#">Monthly</a>
// //                                                         <a class="dropdown-item" href="#">Quarterly</a>
// //                                                         <a class="dropdown-item" href="#">Yearly</a>
// //                                                         {/* <div class="dropdown-divider"></div>
// //                                                 <a class="dropdown-item" href="#">Separated link</a> */}
// //                                                     </div>
// //                                                 </div>
// //                                             </div>

// //                                         </div>

// //                                     </div>
// //                                 </div>
// //                                 <div class="col-xl-3 col-sm-6">
// //                                     <div class="card">
// //                                         <div class="card-header p-3 pt-2" style={{ borderStyle: "none" }}>
// //                                             <div
// //                                                 class="icon-lg icon-shape bg-gradient-secondary shadow-info text-center border-radius-xl mt-n4 position-absolute">
// //                                                 <i class="material-icons opacity-10">weekend</i>
// //                                             </div>
// //                                             <div class="text-end pt-1">
// //                                                 <p class="text-sm mb-0 text-capitalize" style={{ fontWeight: "bold" }}>Total Demo Booked</p>
// //                                                 <h4 class="mb-0" style={{ fontWeight: "700", fontSize: "22px" }}>9</h4>
// //                                             </div>
// //                                         </div>
// //                                         <hr class="dark horizontal my-0" />
// //                                         <div class="card-footer p-3" style={{ borderStyle: "none" }}>
// //                                             <div class="row">
// //                                                 <div class="col-sm-8">
// //                                                     <p class="mb-0" style={{ fontSize: "14px", fontWeight: "500" }}><span class="text-success text-sm font-weight-bolder">+5% </span>than last month</p>
// //                                                 </div>

// //                                                 <div class="col-sm-4">
// //                                                     <span class="badge badge-warning dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ cursor: "pointer", backgroundColor: "#ec407a", color: "white" }}>Filter</span>
// //                                                     <div class="dropdown-menu">
// //                                                         <a class="dropdown-item" href="#">Monthly</a>
// //                                                         <a class="dropdown-item" href="#">Quarterly</a>
// //                                                         <a class="dropdown-item" href="#">Yearly</a>
// //                                                         {/* <div class="dropdown-divider"></div>
// //                                                 <a class="dropdown-item" href="#">Separated link</a> */}
// //                                                     </div>
// //                                                 </div>
// //                                             </div>

// //                                         </div>
// //                                     </div>
// //                                 </div>
// //                             </div>
// //                         </>
// //                         :
// //                         <>
// //                         </>}

// //                     <div class="" style={{ textAlign: "end" }}><span style={{ fontWeight: 500, fontSize: "15px", color: "#17a2b8", cursor: "pointer" }} onClick={() => view()}> {viewMore ? <>View Less</> : <>View More</>}</span></div>

// //                     <div class="row mt-4">
// //                         <div class="col-lg-4 col-md-6 mt-4 mb-4">
// //                             <div class="card z-index-2 ">
// //                                 <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2 bg-transparent">
// //                                     <div class="bg-gradient-primary shadow-primary border-radius-lg py-3 pe-1">
// //                                         <div class="chart">
// //                                             <canvas id="chart-bars" class="chart-canvas" height="170"></canvas>
// //                                         </div>
// //                                     </div>
// //                                 </div>
// //                                 <div class="card-body">
// //                                     <h6 class="mb-0 ">Month wise App Download</h6>
// //                                     <p class="text-sm ">Last Campaign Performance</p>
// //                                     <hr class="dark horizontal" />
// //                                     {/* <div class="d-flex ">
// //                                         <i class="material-icons text-sm my-auto me-1">schedule</i>
// //                                         <p class="mb-0 text-sm"> campaign sent 2 days ago </p>
// //                                     </div> */}
// //                                 </div>
// //                             </div>
// //                         </div>
// //                         <div class="col-lg-4 col-md-6 mt-4 mb-4">
// //                             <div class="card z-index-2  ">
// //                                 <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2 bg-transparent">
// //                                     <div class="bg-gradient-success shadow-success border-radius-lg py-3 pe-1">
// //                                         <div class="chart">
// //                                             <canvas id="chart-line" class="chart-canvas" height="170"></canvas>
// //                                         </div>
// //                                     </div>
// //                                 </div>
// //                                 <div class="card-body">
// //                                     <h6 class="mb-0 ">District wise downloads</h6>
// //                                     <p class="text-sm "> (<span class="font-weight-bolder">+15%</span>) increase in today sales. </p>
// //                                     <hr class="dark horizontal" />
// //                                     {/* <div class="d-flex ">
// //                                         <i class="material-icons text-sm my-auto me-1">schedule</i>
// //                                         <p class="mb-0 text-sm"> updated 4 min ago </p>
// //                                     </div> */}
// //                                 </div>
// //                             </div>
// //                         </div>
// //                         <div class="col-lg-4 mt-4 mb-3">
// //                             <div class="card z-index-2 ">
// //                                 <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2 bg-transparent">
// //                                     <div class="bg-gradient-dark shadow-dark border-radius-lg py-3 pe-1">
// //                                         <div class="chart">
// //                                             <canvas id="chart-line-tasks" class="chart-canvas" height="170"></canvas>
// //                                         </div>
// //                                     </div>
// //                                 </div>
// //                                 <div class="card-body">
// //                                     <h6 class="mb-0 ">Consumables GMV</h6>
// //                                     <p class="text-sm ">Last Campaign Performance</p>
// //                                     <hr class="dark horizontal" />
// //                                     {/* <div class="d-flex ">
// //                                         <i class="material-icons text-sm my-auto me-1">schedule</i>
// //                                         <p class="mb-0 text-sm">just updated</p>
// //                                     </div> */}
// //                                 </div>
// //                             </div>
// //                         </div>
// //                     </div>
// //                     <div class="row mb-4">
// //                         <div class="col-lg-8 col-md-6 mb-md-0 mb-4">
// //                             <div class="card">
// //                                 <div class="card-header pb-0">
// //                                     <div class="row">
// //                                         <div class="col-lg-6 col-7">
// //                                             <h6 style={{ fontWeight: "700", paddingBottom: "10px" }}>Loan Request Details</h6>
// //                                             <p class="text-sm mb-0" style={{ fontWeight: "400", paddingBottom: "1rem" }}>
// //                                                 <i class="fa fa-check text-info" aria-hidden="true"></i>
// //                                                 <span class="font-weight-bold ms-1">30 done</span> this month
// //                                             </p>
// //                                         </div>
// //                                         <div class="col-lg-6 col-5 my-auto text-end">
// //                                             <div class="dropdown float-lg-end pe-4">
// //                                                 <a class="cursor-pointer" id="dropdownTable" data-bs-toggle="dropdown" aria-expanded="false">
// //                                                     <i class="fa fa-ellipsis-v text-secondary"></i>
// //                                                 </a>
// //                                                 <ul class="dropdown-menu px-2 py-3 ms-sm-n4 ms-n5" aria-labelledby="dropdownTable">
// //                                                     <li><a class="dropdown-item border-radius-md" href="javascript:;">Action</a></li>
// //                                                     <li><a class="dropdown-item border-radius-md" href="javascript:;">Another action</a></li>
// //                                                     <li><a class="dropdown-item border-radius-md" href="javascript:;">Something else here</a></li>
// //                                                 </ul>
// //                                             </div>
// //                                         </div>
// //                                     </div>
// //                                 </div>
// //                                 <div class="card-body px-0 pb-2">
// //                                     <div class="table-responsive fix-width scroll-inner">
// //                                         <table class="table align-items-center mb-0">
// //                                             <thead>
// //                                                 <tr>
// //                                                     <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Customer Name</th>
// //                                                     <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Date</th>
// //                                                     <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Budget
// //                                                     </th>
// //                                                     <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
// //                                                         Completion</th>
// //                                                 </tr>
// //                                             </thead>
// //                                             <tbody>
// //                                                 <tr>
// //                                                     <td>
// //                                                         <div class="d-flex px-2 py-1">
// //                                                             <div>
// //                                                                 <img
// //                                                                     src="/img/team-1.jpg" alt="team1"
// //                                                                     class="avatar avatar-sm me-3" />
// //                                                             </div>
// //                                                             <div class="d-flex flex-column justify-content-center">
// //                                                                 <h6 class="mb-0 text-sm" style={{ fontWeight: "500" }}>Sanjana</h6>
// //                                                             </div>
// //                                                         </div>
// //                                                     </td>
// //                                                     <td>
// //                                                         <div class="avatar-group mt-2">
// //                                                             <h6 class="mb-0 text-sm" style={{ fontWeight: "500" }}>26 Apr, 2022</h6>
// //                                                         </div>
// //                                                     </td>
// //                                                     <td class="align-middle text-center text-sm">
// //                                                         <span class="text-xs font-weight-bold"> ₹ 14,000 </span>
// //                                                     </td>
// //                                                     <td class="align-middle">
// //                                                         <div class="progress-wrapper w-75 mx-auto">
// //                                                             <div class="progress-info">
// //                                                                 <div class="progress-percentage">
// //                                                                     <span class="text-xs font-weight-bold">60%</span>
// //                                                                 </div>
// //                                                             </div>
// //                                                             <div class="progress">
// //                                                                 <div class="progress-bar bg-gradient-info w-60" role="progressbar" aria-valuenow="60"
// //                                                                     aria-valuemin="0" aria-valuemax="100"></div>
// //                                                             </div>
// //                                                         </div>
// //                                                     </td>
// //                                                 </tr>
// //                                                 <tr>
// //                                                     <td>
// //                                                         <div class="d-flex px-2 py-1">
// //                                                             <div>
// //                                                                 <img
// //                                                                     src="/img/team-1.jpg" alt="team1"
// //                                                                     class="avatar avatar-sm me-3" />
// //                                                             </div>
// //                                                             <div class="d-flex flex-column justify-content-center">
// //                                                                 <h6 class="mb-0 text-sm" style={{ fontWeight: "500" }}>Monica</h6>
// //                                                             </div>
// //                                                         </div>
// //                                                     </td>
// //                                                     <td>
// //                                                         <div class="avatar-group mt-2">
// //                                                             <h6 class="mb-0 text-sm" style={{ fontWeight: "500" }}>26 Apr, 2022</h6>
// //                                                         </div>
// //                                                     </td>
// //                                                     <td class="align-middle text-center text-sm">
// //                                                         <span class="text-xs font-weight-bold"> ₹ 3,000 </span>
// //                                                     </td>
// //                                                     <td class="align-middle">
// //                                                         <div class="progress-wrapper w-75 mx-auto">
// //                                                             <div class="progress-info">
// //                                                                 <div class="progress-percentage">
// //                                                                     <span class="text-xs font-weight-bold">10%</span>
// //                                                                 </div>
// //                                                             </div>
// //                                                             <div class="progress">
// //                                                                 <div class="progress-bar bg-gradient-info w-10" role="progressbar" aria-valuenow="10"
// //                                                                     aria-valuemin="0" aria-valuemax="100"></div>
// //                                                             </div>
// //                                                         </div>
// //                                                     </td>
// //                                                 </tr>
// //                                                 <tr>
// //                                                     <td>
// //                                                         <div class="d-flex px-2 py-1">
// //                                                             <div>
// //                                                                 <img
// //                                                                     src="/img/team-1.jpg" alt="team1"
// //                                                                     class="avatar avatar-sm me-3" />
// //                                                             </div>
// //                                                             <div class="d-flex flex-column justify-content-center">
// //                                                                 <h6 class="mb-0 text-sm" style={{ fontWeight: "500" }}>Aarti Agarwaal</h6>
// //                                                             </div>
// //                                                         </div>
// //                                                     </td>
// //                                                     <td>
// //                                                         <div class="avatar-group mt-2">
// //                                                             <h6 class="mb-0 text-sm" style={{ fontWeight: "500" }}>26 Apr, 2022</h6>
// //                                                         </div>
// //                                                     </td>
// //                                                     <td class="align-middle text-center text-sm">
// //                                                         <span class="text-xs font-weight-bold"> ₹ 2,500 </span>
// //                                                     </td>
// //                                                     <td class="align-middle">
// //                                                         <div class="progress-wrapper w-75 mx-auto">
// //                                                             <div class="progress-info">
// //                                                                 <div class="progress-percentage">
// //                                                                     <span class="text-xs font-weight-bold">100%</span>
// //                                                                 </div>
// //                                                             </div>
// //                                                             <div class="progress">
// //                                                                 <div class="progress-bar bg-gradient-success w-100" role="progressbar" aria-valuenow="100"
// //                                                                     aria-valuemin="0" aria-valuemax="100"></div>
// //                                                             </div>
// //                                                         </div>
// //                                                     </td>
// //                                                 </tr>
// //                                                 <tr>
// //                                                     <td>
// //                                                         <div class="d-flex px-2 py-1">
// //                                                             <div>
// //                                                                 <img
// //                                                                     src="/img/team-1.jpg" alt="team1"
// //                                                                     class="avatar avatar-sm me-3" />
// //                                                             </div>
// //                                                             <div class="d-flex flex-column justify-content-center">
// //                                                                 <h6 class="mb-0 text-sm" style={{ fontWeight: "500" }}>Radha Krishna</h6>
// //                                                             </div>
// //                                                         </div>
// //                                                     </td>
// //                                                     <td>
// //                                                         <div class="avatar-group mt-2">
// //                                                             <h6 class="mb-0 text-sm" style={{ fontWeight: "500" }}>26 Apr, 2022</h6>
// //                                                         </div>
// //                                                     </td>
// //                                                     <td class="align-middle text-center text-sm">
// //                                                         <span class="text-xs font-weight-bold"> ₹ 20,500 </span>
// //                                                     </td>
// //                                                     <td class="align-middle">
// //                                                         <div class="progress-wrapper w-75 mx-auto">
// //                                                             <div class="progress-info">
// //                                                                 <div class="progress-percentage">
// //                                                                     <span class="text-xs font-weight-bold">100%</span>
// //                                                                 </div>
// //                                                             </div>
// //                                                             <div class="progress">
// //                                                                 <div class="progress-bar bg-gradient-success w-100" role="progressbar" aria-valuenow="100"
// //                                                                     aria-valuemin="0" aria-valuemax="100"></div>
// //                                                             </div>
// //                                                         </div>
// //                                                     </td>
// //                                                 </tr>
// //                                                 <tr>
// //                                                     <td>
// //                                                         <div class="d-flex px-2 py-1">
// //                                                             <div>
// //                                                                 <img
// //                                                                     src="/img/team-1.jpg" alt="team1"
// //                                                                     class="avatar avatar-sm me-3" />
// //                                                             </div>
// //                                                             <div class="d-flex flex-column justify-content-center">
// //                                                                 <h6 class="mb-0 text-sm" style={{ fontWeight: "500" }}>Chandrika Sara</h6>
// //                                                             </div>
// //                                                         </div>
// //                                                     </td>
// //                                                     <td>
// //                                                         <div class="avatar-group mt-2">
// //                                                             <h6 class="mb-0 text-sm" style={{ fontWeight: "500" }}>26 Apr, 2022</h6>
// //                                                         </div>
// //                                                     </td>
// //                                                     <td class="align-middle text-center text-sm">
// //                                                         <span class="text-xs font-weight-bold"> ₹ 500 </span>
// //                                                     </td>
// //                                                     <td class="align-middle">
// //                                                         <div class="progress-wrapper w-75 mx-auto">
// //                                                             <div class="progress-info">
// //                                                                 <div class="progress-percentage">
// //                                                                     <span class="text-xs font-weight-bold">25%</span>
// //                                                                 </div>
// //                                                             </div>
// //                                                             <div class="progress">
// //                                                                 <div class="progress-bar bg-gradient-info w-25" role="progressbar" aria-valuenow="25"
// //                                                                     aria-valuemin="0" aria-valuemax="25"></div>
// //                                                             </div>
// //                                                         </div>
// //                                                     </td>
// //                                                 </tr>
// //                                                 <tr>
// //                                                     <td>
// //                                                         <div class="d-flex px-2 py-1">
// //                                                             <div>
// //                                                                 <img
// //                                                                     src="/img/team-1.jpg" alt="team1"
// //                                                                     class="avatar avatar-sm me-3" />
// //                                                             </div>
// //                                                             <div class="d-flex flex-column justify-content-center">
// //                                                                 <h6 class="mb-0 text-sm" style={{ fontWeight: "500" }}>Kashis Singh</h6>
// //                                                             </div>
// //                                                         </div>
// //                                                     </td>
// //                                                     <td>
// //                                                         <div class="avatar-group mt-2">
// //                                                             <h6 class="mb-0 text-sm" style={{ fontWeight: "500" }}>26 Apr, 2022</h6>
// //                                                         </div>
// //                                                     </td>
// //                                                     <td class="align-middle text-center text-sm">
// //                                                         <span class="text-xs font-weight-bold"> ₹ 2,000 </span>
// //                                                     </td>
// //                                                     <td class="align-middle">
// //                                                         <div class="progress-wrapper w-75 mx-auto">
// //                                                             <div class="progress-info">
// //                                                                 <div class="progress-percentage">
// //                                                                     <span class="text-xs font-weight-bold">40%</span>
// //                                                                 </div>
// //                                                             </div>
// //                                                             <div class="progress">
// //                                                                 <div class="progress-bar bg-gradient-info w-40" role="progressbar" aria-valuenow="40"
// //                                                                     aria-valuemin="0" aria-valuemax="40"></div>
// //                                                             </div>
// //                                                         </div>
// //                                                     </td>
// //                                                 </tr>
// //                                             </tbody>
// //                                         </table>
// //                                     </div>
// //                                 </div>
// //                             </div>
// //                         </div>
// //                         <div class="col-lg-4 col-md-6">
// //                             <div class="card h-100">
// //                                 <div class="card-header pb-0">
// //                                     <h6 style={{ fontWeight: "700", paddingBottom: "10px" }}>Orders overview</h6>
// //                                     <p class="text-sm" style={{ fontWeight: "400", paddingBottom: "1rem" }}>
// //                                         <i class="fa fa-arrow-up text-success" aria-hidden="true" style={{ paddingRight: "10px" }}></i>
// //                                         <span class="font-weight-bold">24%</span> this month
// //                                     </p>
// //                                 </div>
// //                                 <div class="card-body p-3">
// //                                     <div class="timeline timeline-one-side">
// //                                         <div class="timeline-block mb-3">
// //                                             <span class="timeline-step">
// //                                                 <i class="material-icons text-success text-gradient">notifications</i>
// //                                             </span>
// //                                             <div class="timeline-content">
// //                                                 <h6 class="text-dark text-sm font-weight-bold mb-0">New Order Received worth ₹ 2400</h6>
// //                                                 <p class="text-secondary font-weight-bold text-xs mt-1 mb-0">22 DEC 7:20 PM</p>
// //                                             </div>
// //                                         </div>
// //                                         <div class="timeline-block mb-3">
// //                                             <span class="timeline-step">
// //                                                 <i class="material-icons text-success text-gradient">notifications</i>
// //                                             </span>
// //                                             <div class="timeline-content">
// //                                                 <h6 class="text-dark text-sm font-weight-bold mb-0">New Order Received worth ₹ 400</h6>
// //                                                 <p class="text-secondary font-weight-bold text-xs mt-1 mb-0">21 DEC 11 PM</p>
// //                                             </div>
// //                                         </div>
// //                                         <div class="timeline-block mb-3">
// //                                             <span class="timeline-step">
// //                                                 <i class="material-icons text-success text-gradient">notifications</i>
// //                                             </span>
// //                                             <div class="timeline-content">
// //                                                 <h6 class="text-dark text-sm font-weight-bold mb-0">New Order Received worth ₹ 800</h6>
// //                                                 <p class="text-secondary font-weight-bold text-xs mt-1 mb-0">21 DEC 9:34 PM</p>
// //                                             </div>
// //                                         </div>
// //                                         <div class="timeline-block mb-3">
// //                                             <span class="timeline-step">
// //                                                 <i class="material-icons text-success text-gradient">notifications</i>
// //                                             </span>
// //                                             <div class="timeline-content">
// //                                                 <h6 class="text-dark text-sm font-weight-bold mb-0">New Order Received worth ₹ 1200</h6>
// //                                                 <p class="text-secondary font-weight-bold text-xs mt-1 mb-0">20 DEC 2:20 AM</p>
// //                                             </div>
// //                                         </div>
// //                                         <div class="timeline-block mb-3">
// //                                             <span class="timeline-step">
// //                                                 <i class="material-icons text-success text-gradient">notifications</i>
// //                                             </span>
// //                                             <div class="timeline-content">
// //                                                 <h6 class="text-dark text-sm font-weight-bold mb-0">New Order Received worth ₹ 2200</h6>
// //                                                 <p class="text-secondary font-weight-bold text-xs mt-1 mb-0">18 DEC 4:54 AM</p>
// //                                             </div>
// //                                         </div>
// //                                         <div class="timeline-block">
// //                                             <span class="timeline-step">
// //                                                 <i class="material-icons text-success text-gradient">notifications</i>
// //                                             </span>
// //                                             <div class="timeline-content">
// //                                                 <h6 class="text-dark text-sm font-weight-bold mb-0">New Order Received worth ₹ 1400</h6>
// //                                                 <p class="text-secondary font-weight-bold text-xs mt-1 mb-0">17 DEC</p>
// //                                             </div>
// //                                         </div>
// //                                     </div>
// //                                 </div>
// //                             </div>
// //                         </div>
// //                     </div>



// //                     <div class="row mt-4">
// //                         <div class="col-lg-6 col-12">
// //                             <div class="card z-index-2 mt-4">
// //                                 <div class="card-header p-3 pt-2">
// //                                     <div class="icon-lg icon-shape bg-gradient-warning shadow-warning text-center border-radius-xl mt-n4 me-3 float-start">
// //                                         <i class="material-icons opacity-10">donut_small</i>
// //                                     </div>
// //                                     <h6 class="mb-0">Total Revenue From App</h6>
// //                                     <p class="mb-0 text-sm">Month Wise</p>
// //                                 </div>
// //                                 <div class="card-body d-flex p-3 pt-0">
// //                                     <div class="chart w-50">
// //                                         {/* <canvas id="doughnut-chart" class="chart-canvas" height="456" width="550" style={{ display: "block", boxSizing: "border-box", height: "304px", width: "367px" }}></canvas> */}

// //                                         <canvas id="pie-chart" class="chart-canvas" height="300px"></canvas>
// //                                     </div>
// //                                     <div class="table-responsive w-50">
// //                                         <table class="table align-items-center mb-0">
// //                                             <tbody>
// //                                                 {/* <tr>
// //                                                     <td>
// //                                                         <div class="d-flex px-2 py-1">
// //                                                             <div class="d-flex flex-column justify-content-center">
// //                                                                 <h6 class="mb-0 text-sm">January</h6>
// //                                                             </div>
// //                                                         </div>
// //                                                     </td>
// //                                                     <td class="align-middle text-center text-sm">
// //                                                         <span class="text-xs font-weight-bold"> 25% </span>
// //                                                     </td>
// //                                                 </tr>
// //                                                 <tr>
// //                                                     <td>
// //                                                         <div class="d-flex px-2 py-1">

// //                                                             <div class="d-flex flex-column justify-content-center">
// //                                                                 <h6 class="mb-0 text-sm">February</h6>
// //                                                             </div>
// //                                                         </div>
// //                                                     </td>
// //                                                     <td class="align-middle text-center text-sm">
// //                                                         <span class="text-xs font-weight-bold"> 13% </span>
// //                                                     </td>
// //                                                 </tr>
// //                                                 <tr>
// //                                                     <td>
// //                                                         <div class="d-flex px-2 py-1">

// //                                                             <div class="d-flex flex-column justify-content-center">
// //                                                                 <h6 class="mb-0 text-sm">March</h6>
// //                                                             </div>
// //                                                         </div>
// //                                                     </td>
// //                                                     <td class="align-middle text-center text-sm">
// //                                                         <span class="text-xs font-weight-bold"> 13% </span>
// //                                                     </td>
// //                                                 </tr>
// //                                                 <tr>
// //                                                     <td>
// //                                                         <div class="d-flex px-2 py-1">

// //                                                             <div class="d-flex flex-column justify-content-center">
// //                                                                 <h6 class="mb-0 text-sm">April</h6>
// //                                                             </div>
// //                                                         </div>
// //                                                     </td>
// //                                                     <td class="align-middle text-center text-sm">
// //                                                         <span class="text-xs font-weight-bold"> 13% </span>
// //                                                     </td>
// //                                                 </tr>
// //                                                 <tr>
// //                                                     <td>
// //                                                         <div class="d-flex px-2 py-1">

// //                                                             <div class="d-flex flex-column justify-content-center">
// //                                                                 <h6 class="mb-0 text-sm">May</h6>
// //                                                             </div>
// //                                                         </div>
// //                                                     </td>
// //                                                     <td class="align-middle text-center text-sm">
// //                                                         <span class="text-xs font-weight-bold"> 13% </span>
// //                                                     </td>
// //                                                 </tr>
// //                                                 <tr>
// //                                                     <td>
// //                                                         <div class="d-flex px-2 py-1">

// //                                                             <div class="d-flex flex-column justify-content-center">
// //                                                                 <h6 class="mb-0 text-sm">June</h6>
// //                                                             </div>
// //                                                         </div>
// //                                                     </td>
// //                                                     <td class="align-middle text-center text-sm">
// //                                                         <span class="text-xs font-weight-bold"> 13% </span>
// //                                                     </td>
// //                                                 </tr> */}
// //                                                 <tr>
// //                                                     <td>
// //                                                         <div class="d-flex px-2 py-1">

// //                                                             <div class="d-flex flex-column justify-content-center">
// //                                                                 <h6 class="mb-0 text-sm">July</h6>
// //                                                             </div>
// //                                                         </div>
// //                                                     </td>
// //                                                     <td class="align-middle text-center text-sm">
// //                                                         <span class="text-xs font-weight-bold"> 13% </span>
// //                                                     </td>
// //                                                 </tr>
// //                                                 <tr>
// //                                                     <td>
// //                                                         <div class="d-flex px-2 py-1">

// //                                                             <div class="d-flex flex-column justify-content-center">
// //                                                                 <h6 class="mb-0 text-sm">August</h6>
// //                                                             </div>
// //                                                         </div>
// //                                                     </td>
// //                                                     <td class="align-middle text-center text-sm">
// //                                                         <span class="text-xs font-weight-bold"> 13% </span>
// //                                                     </td>
// //                                                 </tr>
// //                                                 <tr>
// //                                                     <td>
// //                                                         <div class="d-flex px-2 py-1">

// //                                                             <div class="d-flex flex-column justify-content-center">
// //                                                                 <h6 class="mb-0 text-sm">September</h6>
// //                                                             </div>
// //                                                         </div>
// //                                                     </td>
// //                                                     <td class="align-middle text-center text-sm">
// //                                                         <span class="text-xs font-weight-bold"> 13% </span>
// //                                                     </td>
// //                                                 </tr>
// //                                                 <tr>
// //                                                     <td>
// //                                                         <div class="d-flex px-2 py-1">

// //                                                             <div class="d-flex flex-column justify-content-center">
// //                                                                 <h6 class="mb-0 text-sm">October</h6>
// //                                                             </div>
// //                                                         </div>
// //                                                     </td>
// //                                                     <td class="align-middle text-center text-sm">
// //                                                         <span class="text-xs font-weight-bold"> 13% </span>
// //                                                     </td>
// //                                                 </tr>
// //                                                 <tr>
// //                                                     <td>
// //                                                         <div class="d-flex px-2 py-1">

// //                                                             <div class="d-flex flex-column justify-content-center">
// //                                                                 <h6 class="mb-0 text-sm">November</h6>
// //                                                             </div>
// //                                                         </div>
// //                                                     </td>
// //                                                     <td class="align-middle text-center text-sm">
// //                                                         <span class="text-xs font-weight-bold"> 13% </span>
// //                                                     </td>
// //                                                 </tr>
// //                                                 <tr>
// //                                                     <td>
// //                                                         <div class="d-flex px-2 py-1">

// //                                                             <div class="d-flex flex-column justify-content-center">
// //                                                                 <h6 class="mb-0 text-sm">December</h6>
// //                                                             </div>
// //                                                         </div>
// //                                                     </td>
// //                                                     <td class="align-middle text-center text-sm">
// //                                                         <span class="text-xs font-weight-bold"> 13% </span>
// //                                                     </td>
// //                                                 </tr>

// //                                             </tbody>
// //                                         </table>
// //                                     </div>
// //                                 </div>
// //                             </div>
// //                         </div>
// //                         <div class="col-lg-6 col-12 mt-md-0 mt-4">
// //                             <div class="card z-index-2 mt-4">
// //                                 <div class="card-header p-3 pt-2">
// //                                     <div class="icon-lg icon-shape bg-gradient-warning shadow-warning text-center border-radius-xl mt-n4 me-3 float-start">
// //                                         <i class="material-icons opacity-10">pie_chart</i>
// //                                     </div>
// //                                     <h6 class="mb-0">User Types</h6>
// //                                     <p class="mb-0 text-sm">Analytics Insights</p>
// //                                 </div>
// //                                 <div class="card-body d-flex p-3 pt-0">
// //                                     <div class="chart w-50">
// //                                         <canvas id="doughnut-chart" class="chart-canvas" height="460" width="514" style={{ display: "block", boxSizing: "border-box", height: "307.2px", width: "343px" }}></canvas>
// //                                     </div>
// //                                     <div class="w-50 my-auto ms-5">
// //                                         <span class=" badge-lg badge-dot me-4 d-block text-start">
// //                                             <span style={{ height: "13px", width: "12px", backgroundColor: "#007bff", borderRadius: "50%", display: "inline-block", marginRight: "7px" }}></span>
// //                                             <span class="text-dark">Parlour</span>
// //                                         </span>
// //                                         <span class=" badge-lg badge-dot me-4 d-block text-start">
// //                                             <span style={{ height: "13px", width: "12px", backgroundColor: "green", borderRadius: "50%", display: "inline-block", marginRight: "7px" }}></span>
// //                                             <span class="text-dark">Freelancer</span>
// //                                         </span>
// //                                         {/* <span class=" badge-lg badge-dot me-4 d-block text-start">
// //                                             <span style={{ height: "13px", width: "12px", backgroundColor: "#fb8c00", borderRadius: "50%", display: "inline-block", marginRight: "7px" }}></span>
// //                                             <span class="text-dark">Saloon</span>
// //                                         </span> */}
// //                                         <span class=" badge-lg badge-dot me-4 d-block text-start">
// //                                             <span style={{ height: "13px", width: "12px", backgroundColor: 'rgb(255, 1, 136)', borderRadius: "50%", display: "inline-block", marginRight: "7px" }}></span>
// //                                             <span class="text-dark">Students</span>
// //                                         </span>
// //                                         <p class="text-sm mt-3">
// //                                             More than <b>1,200,000</b> sales are made using referral marketing, and <b>700,000</b> are from social media.
// //                                         </p>
// //                                         <a class="btn bg-gradient-secondary mb-0" href="javascript:;">Read more</a>
// //                                     </div>
// //                                 </div>
// //                             </div>
// //                         </div>
// //                     </div>


// //                 </div>
// //             </main>
// //         </>
// //     )
// // }

// // export default Analytics;