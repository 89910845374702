import React, { useState, useEffect } from 'react';
import { useNavigate, Link, useParams } from 'react-router-dom';
import Swal from 'sweetalert2'
import db from "./Firebase";
import { arrayUnion } from "firebase/firestore";
import { storage } from './Firebase';
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import ViewAllRequests from './ViewAllRequests';
import ViewAppData from './ViewAppData';
import { CSVLink } from "react-csv";

function FeedbackReply() {
    const navigate = useNavigate();
    const moment = extendMoment(Moment);
    const [data, setData] = useState([]);
    const [csvdata, setCsvdata] = useState([]);
    const [uid, setUid] = useState('');
    const [searchQuery, setSearchQuery] = useState("");
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [showLoginForm, setShowLoginForm] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [userName, setUserName] = useState('');
    const [nids, setNids] = useState([]);

    useEffect(() => {
        const storedUsername = localStorage.getItem("email");
        const storedPassword = localStorage.getItem("password");
        const storedRemember = localStorage.getItem("remember");

        if (storedRemember === "false" || !storedUsername || !storedPassword) {
            navigate('/signin');
        } else {
            setIsLoggedIn(true);
        }
    }, []);


    const headers = [
        "Username",
        "Expense Name",
        'description',
        "Amount",
        "Type",
        "Created Date",
        // Add more column labels as needed
    ];


    useEffect(() => {
        db.collection("workshop_feedbacks").orderBy("created_date", "desc").onSnapshot((snapshot) => {
            setData(
                snapshot.docs.map((doc) => ({
                    id: doc.id,
                    data: doc.data(),
                }))
            );
        });
    }, []);

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const handleReplyClick = async (feedbackId, wname, phno, full_name) => {
        setPhoneNumber(phno);
        setUserName(full_name);
        if (isLoggedIn) {
            const { value: text } = await Swal.fire({
                input: 'textarea',
                inputLabel: 'Update Remark',
                inputPlaceholder: 'Type your message here...',
                inputAttributes: {
                    'aria-label': 'Type your message here'
                },
                showCancelButton: true
            });

            if (text) {
                // Update Firestore document with the reply
                const feedbackRef = db.collection("workshop_feedbacks").doc(feedbackId);
                feedbackRef.update({
                    reply: arrayUnion({ reply: text, reply_date: new Date() }) // Add the new reply to the existing array
                })
                    .then(() => {

                        console.log("Reply added successfully");
                        db.collection("workshop_notification").add({ 'message': `You have received a new response from Team Youvati for your workshop feedback  : ${wname}.`, 'name': full_name, 'workshop_name': wname, 'phone_no': phno, 'created_date': new Date() }).then(() => {
                            console.log("data added");
                            findNid(phno, full_name, wname);
                        });


                    })
                    .catch((error) => {
                        console.error("Error adding reply: ", error);
                    });
            }
        } else {
            // Show login form/modal
            setShowLoginForm(true);
        }
    };

    const findNid = (phno, full_name, wname) => {
        console.log("callinggggggg")
        console.log(phno)
        if (phno) {
            console.log("callinggggggg")
            // Fetch data for the single phone number
            db.collection("personal_details")
                .where("phone_no", "==", phno)
                .get()
                .then((querySnapshot) => {
                    const documentIds = []; // Array to store document IDs
                    querySnapshot.forEach((doc) => {
                        const userData = {
                            id: doc.id,
                            data: doc.data(),
                        };
                        console.log("Fetched user data:", userData);
                        documentIds.push(doc.id); // Store the document ID
                    });
                    return documentIds; // Return the document IDs
                })
                .then((documentIds) => {
                    // Fetch notification IDs based on the document IDs
                    return db.collection("notification_ids")
                        .get()
                        .then((notificationSnapshot) => {
                            const regIds = []; // Array to store registration IDs
                            notificationSnapshot.forEach((notificationDoc) => {
                                const uid = notificationDoc.data().uid;
                                const regId = notificationDoc.data().reg_id;
                                if (documentIds.includes(uid)) {
                                    regIds.push(regId);
                                }
                            });
                            return regIds; // Return registration IDs
                        });
                })
                .then((regIds) => {
                    console.log("Registration IDs:", regIds); // Log the registration IDs
                    handlePush(regIds, full_name, wname)
                    setNids(regIds); // Set the registration IDs in state
                })
                .catch((error) => {
                    console.error("Error fetching data:", error);
                });
        } else {
            setNids([]); // Clear the registration IDs if no phone number is provided
            console.log("not foundddddddddddd")
        }
    }

    const handlePush = (regId, full_name, wname) => {
        console.log(regId)
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "key=AAAAnzqDoGw:APA91bHNYZDEZn5paZqVOV6evl4npGLdy7V1iixsNCGekPti0mkcTft5lHb9WruMaFjOdJrGVR9kkFzfC00HMfmvPUFo4sQhvXmXoom7clqOhXqMc7y5erUF75zJIxAzMZeuCtXx6PTS");

        // Check if regId is not empty before proceeding
        if (!regId) {
            console.log("No valid registration ID to send the push notification.");
            return;
        }

        const raw = JSON.stringify({
            "registration_ids": regId,
            "notification": {
                "body": `Team Youvati has responded to your feedback from the workshop : ${wname}.`,
                "title": `Hey ${full_name}`,
                "android_channel_id": "youvati",
                "image": "https://firebasestorage.googleapis.com/v0/b/youvati.appspot.com/o/Feedback%20Reply.png?alt=media&token=9ac8d342-e935-48d0-9365-71e4bf473d5f",
                "sound": true
            },
            "data": {
                "type": "msg",
                "id": "workshop",
                "image": "https://firebasestorage.googleapis.com/v0/b/youvati.appspot.com/o/Feedback%20Reply.png?alt=media&token=9ac8d342-e935-48d0-9365-71e4bf473d5f"
            }
        });

        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch("https://fcm.googleapis.com/fcm/send", requestOptions)
            .then(response => response.json())
            .then(result => {
                // Handle response
                console.log(result);
                Swal.fire(`Push notification sent successfully to device.`);
            })
            .catch(error => console.log('error', error));
    };




    const handleLogin = () => {
        // Perform login validation
        // If login is successful, set isLoggedIn to true and close the login form/modal
        setIsLoggedIn(true);
        setShowLoginForm(false);
    };


    // Function to filter data based on the search query
    const filteredData = data.filter((item) => {
        const workshop_name = item.data.workshop_name.toLowerCase();
        const full_name = item.data.full_name.toLowerCase();
        const phone_no = item.data.phone_no.toLowerCase();
        const query = searchQuery.toLowerCase();

        return (
            workshop_name.includes(query) ||
            full_name.includes(query) ||
            phone_no.includes(query)
        );
    });

    // Function to convert data to CSV format
    const prepareCSVData = () => {
        const csvData = filteredData.map((item, index) => ([
            item.data.full_name,
            item.data.workshop_name,
            item.data.rating,
            item.data.feedback,
            moment(item.data.created_date.toDate().toISOString()).format("MM/DD/YY, h:mm a"),
            // Add more columns as needed
        ]));

        return csvData;
    };






    return (
        <>
            <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg">

                <div class="p-2" style={{ paddingBottom: "5px", display: "flex", justifyContent: "space-between" }}>
                    <Link to="/admin_panel">
                        <img src="/images/menubutton.png" class="menu-toggle" alt="Responsive image" style={{ height: "2rem" }} />
                    </Link>
                    {/* <CSVLink data={prepareCSVData()} headers={headers} filename={"expenses.csv"}>
                        <span class="btn bg-gradient-secondary btn-sm mb-0 mr-2" style={{ color: "white" }}>
                            &nbsp; Download Excel
                        </span>
                    </CSVLink> */}
                </div>
                <div class="">

                    <div class="p-2">

                        <div class="card">
                            <div class="card-header pb-0 px-3">
                                <h6 class="mb-2">All Feedback || {`Records : ${filteredData.length}`}</h6>
                            </div>
                            <input
                                type="text"
                                className="form-control form-control-sm"
                                placeholder="Search by Username, Expense Name, Type, Amount"
                                value={searchQuery}
                                onChange={handleSearchChange}
                            />
                            <div class="card">
                                <div class="table-responsive fix-width scroll-inner">
                                    <table class="table table-bordered table-hover">
                                        <thead class="thead-dark" style={{ position: "sticky", top: "0" }}>
                                            <tr style={{ textAlign: "left" }}>
                                                <th class="col ps-2">S.No</th>
                                                <th class="col ps-2">Full Name</th>
                                                <th class="col ps-2">Workshop Name</th>
                                                <th class="col ps-2">Rating</th>
                                                <th class="col ps-2">Created Date</th>
                                                <th class="col ps-2">Feedback</th>
                                                <th class="col ps-2">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody style={{ textAlign: "left" }}>
                                            {filteredData.map((item, index) => (
                                                <tr key={item.id}>
                                                    <td style={{ whiteSpace: 'pre-wrap' }}>
                                                        <p class="text-xs font-weight-bold mb-0" style={{ width: "4rem" }}>{index + 1}.</p>
                                                    </td>
                                                    <td>
                                                        <p class="text-xs mb-0">{item.data.full_name}</p>
                                                        <p class="text-xs mb-0">{item.data.phone_no}</p>
                                                    </td>
                                                    <td>
                                                        <p class="text-xs mb-0">{item.data.workshop_name}</p>
                                                    </td>
                                                    <td>
                                                        <p class="text-xs mb-0">{item.data.rating} ⭐</p>
                                                    </td>
                                                    <td>
                                                        <div class="d-flex px-2 py-1">
                                                            <div class="d-flex flex-column justify-content-center">
                                                                <h6 class="mb-0 text-xs">{moment(item.data.created_date.toDate().toISOString()).format("MM/DD/YY, h:mm a")}</h6>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <p class="text-xs mb-0"><b style={{ fontSize: "12px", color: "black", fontWeight: "600" }}>Feedback : </b>{item.data.feedback}</p>
                                                        {/* Conditional rendering of replies */}
                                                        {item.data.reply && item.data.reply.length > 0 && (
                                                            item.data.reply.map((reply, replyIndex) => (
                                                                <div key={replyIndex}>
                                                                    <p class="text-xs mb-2 mt-2" style={{ color: "red" }}><b style={{ fontSize: "12px", color: "black", fontWeight: "600" }}>Reply:</b> {reply.reply}</p>
                                                                    <p class="text-xs mb-0">Reply Date: {moment(reply.reply_date.toDate().toISOString()).format("MM/DD/YY, h:mm a")}</p>
                                                                </div>
                                                            ))
                                                        )}
                                                    </td>
                                                    <td>
                                                        <button
                                                            class="btn btn-sm btn-dark"
                                                            onClick={() => handleReplyClick(item.id, item.data.workshop_name, item.data.phone_no, item.data.full_name)}
                                                        >
                                                            Reply
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>


                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
}

export default FeedbackReply;
