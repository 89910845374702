import React, { useState, useEffect } from 'react';
import * as XLSX from 'xlsx';
import DataTable from 'react-data-table-component';
import { useNavigate, Link, useParams } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2'
import db from "./Firebase";
import { storage } from './Firebase';
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import Moment from 'moment';
import DatePicker from "react-datepicker";
import { extendMoment } from 'moment-range';
import "react-datepicker/dist/react-datepicker.css";


function UploadWorkshopEvent() {
    let params = useParams();
    const navigate = useNavigate();
    const moment = extendMoment(Moment);
    const [startDate, setStartDate] = useState(new Date());


    const initialFormStateFeedback = { name: '', title: '', expert: '', type: '', date: '', description: '', authorization_code: '', created_date: new Date() }
    const [submit, setSubmit] = useState(initialFormStateFeedback)

    const [show, setshow] = useState(true);

    const [imgUrl, setImgUrl] = useState(null);
    const [progresspercent, setProgresspercent] = useState(0);


    const handleChange = (e) => {
        const { id, value } = e.target
        setSubmit({ ...submit, [id]: value })
    }

    const reset = () => {
        setSubmit({ name: '', title: '', expert: '', type: '', date: '', description: '', authorization_code: '', created_date: new Date() })
    }

    const [columns, setColumns] = useState([]);
    const [data, setData] = useState([]);
    const [ShowProgress, setShowProgress] = useState(false);
    const [showExcel, setshowExcel] = useState(false);

    const processData = dataString => {
        const dataStringLines = dataString.split(/\r\n|\n/);
        const headers = dataStringLines[0].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);

        const list = [];
        for (let i = 1; i < dataStringLines.length; i++) {
            const row = dataStringLines[i].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);
            if (headers && row.length == headers.length) {
                const obj = {};
                for (let j = 0; j < headers.length; j++) {
                    let d = row[j];
                    if (d.length > 0) {
                        if (d[0] == '"')
                            d = d.substring(1, d.length - 1);
                        if (d[d.length - 1] == '"')
                            d = d.substring(d.length - 2, 1);
                    }
                    if (headers[j]) {
                        obj[headers[j]] = d;
                    }
                }

                // remove the blank rows
                if (Object.values(obj).filter(x => x).length > 0) {
                    list.push(obj);
                }
            }
        }

        // prepare columns list from headers
        const columns = headers.map(c => ({
            name: c,
            selector: c,
        }));

        setData(list);
        setColumns(columns);
        console.log(list)
    }

    const handleFileUpload = e => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onload = (evt) => {
            /* Parse data */
            const bstr = evt.target.result;
            const wb = XLSX.read(bstr, { type: 'binary' });
            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            /* Convert array of arrays */
            const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
            processData(data);
        };
        reader.readAsBinaryString(file);
    }

    const [num, setNum] = useState(0);
    const submitdata = (e) => {
        setShowProgress(true);
        data.map((user, index) => {
            setNum(index + 1);
            db.collection("workshop_event_data").add({
                'name': user.name,
                'title': user.title,
                'expert': user.expert,
                'type': user.type,
                'date': moment(startDate).format("YYYY-MM-DD"),
                'description': user.description,
                'image': user.image,
                'created_date': new Date(),
            });
        })
    };





    var _cartList = [
        {
            'name': 'Advance Hairstyling',
            'image': "assets/images/banner1.1.png",
            'title':
                'This workshop will be on hair extensions. This will cover different types of extensions, how to do an extension, etc.',
            'expert': '----',
            'type': 'Non-Paid',
            'date': '12th June 2022',
            'created_date': new Date(),
            'description':
                'This workshop will explore the professional skills, tools and products for advance hairstyling. You will learn new techniques for hairstyling, and the romantic hairstyl look. Customer care service will also be discussed so you can grow your clientele. To succeed as a freelance business, you will learn how to set up your business structure, meet legal requirements and master the strategies to market your brand for success. enrol today!',
        },
        {
            'name': 'Micro Blading',
            'image': "assets/images/banner1.2.png",
            'title':
                'This is in high demand today and a great source of generating additional revenue for beauty parlour owners.',
            'expert': 'Neelanjana  Mandal',
            'type': 'Non-Paid',
            'date': '19th June 2022',
            'created_date': new Date(),
            'description':
                'Microblading is a cosmetic procedure that inserts pigment under your skin using a needle to give you well-defined, natural looking eyebrows. Generally, results can last from 18 to 30 months depending on your skin type, lifestyle, and how often you get touch-ups.',
        }
    ]

    // const upload = () => {
    //     _cartList.map((e, index) => {
    //         db.collection("workshop_event_data").add(e).then(() => {
    //             console.log('Submitted Successfully');
    //         })
    //     })
    // }

    const ids = ["rahul_122002", "ashutosh_560001", "mrinal_122004"];

    const handleSubmit = (e) => {
        // console.log(submit.usercode)
        // alert("okkk")
        e.preventDefault()
        if (ids.includes(submit.authorization_code)) {
            const file = e.target[0]?.files[0]
            // console.log(file)
            if (!file) return;
            const storageRef = ref(storage, `files/${file.name}`);
            const uploadTask = uploadBytesResumable(storageRef, file);
            setshow(false)

            uploadTask.on("state_changed",
                (snapshot) => {
                    const progress =
                        Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                    setProgresspercent(progress);
                },
                (error) => {
                    alert(error);
                },
                () => {
                    getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                        // setImgUrl(downloadURL)
                        db.collection("workshop_event_data").add({
                            'name': submit.name,
                            'title': submit.title,
                            'expert': submit.expert,
                            'type': submit.type,
                            'date': submit.date,
                            'description': submit.description,
                            'image': downloadURL,
                            'created_date': new Date(),
                        }).then(() => {
                            console.log('Submitted Successfully');
                            const Toast = Swal.mixin({
                                toast: true,
                                background: '#69aba6',
                                position: 'top-end',
                                showConfirmButton: false,
                                timer: 3000,
                                timerProgressBar: true,
                                didOpen: (toast) => {
                                    toast.addEventListener('mouseenter', Swal.stopTimer)
                                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                                }
                            })

                            Toast.fire({
                                icon: 'success',
                                title: 'Submitted Successfully'
                            })
                            setshow(true)
                            // navigate(`/${submit.username}`);
                            setSubmit({ name: '', title: '', expert: '', type: '', date: '', description: '', authorization_code: '', created_date: new Date() })
                        })
                    });
                }
            );
        } else {
            alert("You are not authorization to perform this action")
        }
    }

    useEffect(() => {
        // alert(params.id)
    }, []);



    return (
        <>
            {!showExcel ?
                <div id="small-dialog" class="m-3">
                    <div class="row p-1" style={{ float: "right" }}>
                        <button type="button" class="btn-sm" style={{ backgroundColor: "#337ab7", color: "white" }} onClick={() => setshowExcel(true)}>Upload Excel Data</button>
                    </div>
                    <div class="w3layouts_payment_online_form">
                        <form class="" id="" onSubmit={handleSubmit}>
                            <h4>Upload Workshop And Event</h4>
                            <ul>
                                <li style={{ textAlign: "left" }}><label for="myfile">Workshop/Event Image:</label><input type="file" id="image" name="Image" accept="image/*" />
                                </li>
                                <li><input class="w3l_text_box" id="name" onChange={handleChange} value={submit.name} placeholder="Name" name="Name" type="text" required />
                                </li>
                            </ul>
                            <ul>
                                <li>
                                    <input class="w3l_text_box" id="title" onChange={handleChange} value={submit.title} placeholder="Title" name="Title" type="text" required />
                                </li>
                                <li>
                                    <input class="w3l_text_box" id="expert" placeholder="Expert" onChange={handleChange} value={submit.expert} name="Expert" type="text" required="" />
                                </li>
                            </ul>
                            <ul>
                                <li>
                                    {/* <label>Type</label> */}
                                    <select class="form-control" id="type" onChange={handleChange} required>
                                        <option value="">--Select Type--</option>
                                        <option value="yes">Paid</option>
                                        <option value="no">Non-Paid</option>
                                    </select>
                                </li>
                                <li>
                                    <label>Date</label>
                                    <DatePicker
                                        selected={startDate}
                                        onChange={date => setStartDate(date)}
                                        minDate={new Date()}
                                        showDisabledMonthNavigation
                                        required
                                        className="form-control w-120"
                                        placeholderText="Select a date before 5 days in the future"
                                    />
                                </li>
                            </ul>
                            <ul>
                                <li>
                                    <input class="w3l_text_box" id="authorization_code" placeholder="Authorization Code" onChange={handleChange} value={submit.authorization_code} name="Authorization Code" type="text" required />
                                </li>
                                <li></li>
                            </ul>
                            <ul>
                                <li class="w3ls_address"><input class="w3l_text_box" id="description" onChange={handleChange} value={submit.description} placeholder="Description Here....." name="Description" type="text" style={{ height: "6rem" }} required /></li>
                            </ul>

                            <ul class="w3_payment_sendbtns">
                                <li><input type="reset" value="Cancel" onClick={() => navigate(`/quicklinks`)} /></li>
                                <li class="mr-4"><input type="reset" data-dismiss="modal" aria-label="Close" onClick={reset} value="Reset" /></li>
                                <li>{show ? <input type="submit" value="Submit" /> : "Please Wait..."}</li>
                            </ul>
                            <div class="clearfix"> </div>
                        </form>
                    </div>
                </div>
                :
                <>
                    <div id="small-dialog" class="m-3">
                        <div class="row p-1" style={{ float: "right" }}>
                            <a class="mr-3" href="/files/workshop_sample.xlsx" download><button type="button" class="btn-sm" style={{ backgroundColor: "#337ab7", color: "white" }} onClick={() => setshowExcel(true)}>Download Sample For Workshop</button></a>
                            <button type="button" class="btn-sm" style={{ backgroundColor: "#337ab7", color: "white" }} onClick={() => setshowExcel(false)}>Back</button>
                        </div>
                        <br />
                        <div class="card-body">
                            <div class="form-group justify-content-end row">
                                <div class="col-lg-12">
                                    <div class="form-control dropzone dropzone-default dropzone-primary dz-clickable h-auto px-4 py-2" style={{ background: "aquamarine", textAlign: "center" }}>
                                        <div class="dropzone-msg dz-message needsclick" style={{ borderStyle: "dashed", padding: "1rem", borderColor: "orange" }}>
                                            <input type="file" accept=".csv,.xlsx,.xls" onChange={handleFileUpload} />
                                            <span class="font-size-base"><i class="fas fa-cloud-upload-alt icon-md mr-1"></i>Drop files here or click.</span><br />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <DataTable
                            pagination
                            highlightOnHover
                            columns={columns}
                            data={data}
                        />

                        {data.length > 0 ? <> <div class="card-footer d-flex justify-content-end py-2">
                            <button class="btn btn-success mr-2" onClick={() => submitdata()}>Upload Data</button>
                            <div class="btn btn-secondary" role="button">Cancel</div>
                        </div></> : null
                        }

                        {ShowProgress ? <><h2 style={{ background: "darkorange", padding: "1rem", textAlign: "center", paddingBottom: "1rem", color: "white" }}>{num == data.length ? "Data Uploaded" : "Uploading Data"} - {num}/{data.length}</h2></> : ""}
                    </div>
                </>


            }

        </>


    );
}
export default UploadWorkshopEvent;