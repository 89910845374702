import React, { useState, useEffect } from "react";
import { useNavigate, Link, useParams } from "react-router-dom"
// import './style.css';
// import Slider from './Slider';
import Card from './Card';
import db from "./Firebase";
import axios from "axios";
import Geocode from "react-geocode";
// import { firebase, auth } from './FirebaseLogin';
import Swal from 'sweetalert2'
import Slider from "react-slick";
import ServicesList from "./ServicesList";


function Home() {
    var settings = {
        dots: true,
        autoplay: true
    };
    const navigate = useNavigate();

    let params = useParams();
    const [isLoading, setIsLoading] = useState(true)
    const [lat, setLat] = useState(null);
    const [lng, setLng] = useState(null);
    const [status, setStatus] = useState(null);
    const [location, setLocation] = useState([]);

    const [mynumber, setnumber] = useState("");
    const [otp, setotp] = useState('');
    const [show, setshow] = useState(false);
    const [final, setfinal] = useState('');

    const [showModal, setShowModal] = useState(false);

    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);

    const initialFormState = { full_name: '', username: params.id.toLowerCase(), email: '', phone_no: '', created_date: new Date() }
    const [submit, setSubmit] = useState(initialFormState)

    const initialFormStateRefer = { full_name: '', email: '', phone_no: '', created_date: new Date() }
    const [submitRefer, setSubmitRefer] = useState(initialFormStateRefer)

    const initialFormStateDistributor = { full_name: '', email: '', phone_no: '', space_available: '', initial_investment: '', created_date: new Date() }
    const [submitDistributor, setSubmitDistributor] = useState(initialFormStateDistributor)

    const initialFormStateTrainer = { full_name: '', email: '', phone_no: '', address: '', course: '', duration: '', timing: "", days: "", fee_proposed: "", mode: '', course_content: '', student_count: '', need_certificate: '', starting_date: '', created_date: new Date() }
    const [submitTrainer, setSubmitTrainer] = useState(initialFormStateTrainer)

    const [testimonial, setTestimonial] = useState([]);
    const [staffList, setStaffList] = useState([]);
    const [personalData, setPersonalData] = useState({});
    const [parlourData, setParlourData] = useState({});
    const [imageArray, setImageArray] = useState([])
    const [servicesArray, setServicesArray] = useState([])
    const [customerName, setCustomerName] = useState("");
    const [customerPassword, setCustomerPassword] = useState("");
    const [customersData, setCustomersData] = useState([]);
    const [updatedCustomerName, setUpdatedCustomerName] = useState("");
    const [updatedCustomerPassword, setUpdatedCustomerPassword] = useState("");
    const [dataIdToBeUpdated, setDataIdToBeUpdated] = useState("");
    const [num, setNum] = useState(0);
    const [picS, setPicS] = useState(0);
    const [picB, setPicB] = useState(0);

    const [servicesListArray, setServicesListArray] = useState([])

    var amount = 0;
    const [Verified, setVerified] = useState(false)
    const [hideButton, setHideButton] = useState(true)
    const [serviceEnable, setServiceEnable] = useState(false)
    const [selectedService, setSelectedServices] = useState("");

    function randomNumberInRange(min, max) {
        // 👇️ get number between min (inclusive) and max (inclusive)
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    const signin = () => {

        // if (submit.phone_no === "" || submit.phone_no < 10) return;

        // let verify = new firebase.auth.RecaptchaVerifier('recaptcha-container');
        // auth.signInWithPhoneNumber(`+91${submit.phone_no}`, verify).then((result) => {
        //     setfinal(result);
        //     alert("code sent! -  Check your phone for code")
        //     setshow(true);
        // })
        //     .catch((err) => {
        //         alert(err);
        //         window.location.reload()
        //     });
    }

    const ValidateOtp = () => {
        setHideButton(false)
        if (otp === null || final === null)
            return;
        final.confirm(otp).then((result) => {
            // alert("OTP validation Successful");
            const user = result.user;
            addUser(user.uid);
            console.log(user.uid);
            // success
        }).catch((err) => {
            alert("Wrong code");
        })
    }

    const addUser = (uid) => {
        db.collection("users").doc(uid).set({ 'user_id': uid, 'created_date': new Date() }).then(() => {
            addPersonalDetails(uid);
            console.log("user added")
        })

    };

    const addPersonalDetails = (uid) => {
        db.collection("personal_details").doc(uid).set({
            'full_name': submit.full_name,
            'phone_no': submit.phone_no,
            'username': submit.username,
            'upi_address': "",
            'email': submit.email,
            'image': "",
            'created_date': submit.created_date,
        }).then(
            (value) => {
                console.log("Personal Details Added");
                addUserName(uid);
            })
    };

    const addUserName = (uid) => {
        db.collection("portfolio").doc(submit.username)
            .set({ "user_id": uid }).then((value) => {
                AddParlourDetails(uid);
                console.log("username added");
            })
    };


    const AddParlourDetails = (uid) => {
        db.collection('parlour_details').doc(uid).set(
            {
                'parlour_name': parlourData.parlour_name,
                'full_address': parlourData.full_address,
                'username': submit.username,
                'pincode': parlourData.pincode,
                'phone_no': submit.phone_no,
                'owner': "",
                'partner_type': "",
                'created_date': submit.created_date,
                'salon_business_images': parlourData.image,
                'captured_location': location,
                'plus_code': parlourData.plus_code
            }).then(() => {
                console.log('parlour details added');
                const Toast = Swal.mixin({
                    toast: true,
                    background: '#69aba6',
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer)
                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                    }
                })

                Toast.fire({
                    icon: 'success',
                    title: 'You Claimed your profile successfully'
                })
                openModalNew()
                setHideButton(true)
            })
    }


    const getLocation = () => {
        if (!navigator.geolocation) {
            setStatus('Geolocation is not supported by your browser');
        } else {
            setStatus('Locating...');
            navigator.geolocation.getCurrentPosition((position) => {
                setStatus(null);
                setLat(position.coords.latitude);
                setLng(position.coords.longitude);
                axios.get(`http://api.positionstack.com/v1/reverse?limit=1&access_key=55fd6f0642632baae7777863a5546b5f&query=${position.coords.latitude},${position.coords.longitude}`).then((response) => {
                    setLocation(response.data.data[0])
                    console.log(response.data.data[0]);
                })

            }, () => {
                setStatus('Unable to retrieve your location');
            });
        }
    }

    const handleChange = (e) => {
        const { id, value } = e.target
        setSubmit({ ...submit, [id]: value })
    }

    const handleSubmit = (e) => {
        // alert(submit.phone_no.length)
        console.log(JSON.stringify(submit));
        e.preventDefault();
    }


    const handleChangeRefer = (e) => {
        const { id, value } = e.target
        setSubmitRefer({ ...submitRefer, [id]: value })
    }

    const handleSubmitRefer = (e) => {
        // alert(submit.phone_no.length)
        console.log(JSON.stringify(submit));
        e.preventDefault();

        db.collection("referred_parlour_freelancer").add({
            name: submitRefer.full_name,
            phone_no: submitRefer.phone_no,
            email: submitRefer.email,
            created_date: submitRefer.created_date,
            referred_by: params.id.toLowerCase()
        })
            .then(() => {
                console.log('referred successfull');
                const Toast = Swal.mixin({
                    toast: true,
                    background: '#69aba6',
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer)
                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                    }
                })

                Toast.fire({
                    icon: 'success',
                    title: 'Referral Successful'
                })
                openModalNew()
            });


    }



    // ------------------------
    const handleChangeDistributor = (e) => {
        const { id, value } = e.target
        setSubmitDistributor({ ...submitDistributor, [id]: value })
    }

    const handleSubmitDistributor = (e) => {
        // alert(submitDistributor.phone_no.length)
        console.log(JSON.stringify(submitDistributor));
        e.preventDefault();

        db.collection("product_distributor").add({
            name: submitDistributor.full_name,
            phone_no: submitDistributor.phone_no,
            email: submitDistributor.email,
            space_available: submitDistributor.space_available,
            initial_investment: submitDistributor.initial_investment,
            created_date: submitDistributor.created_date,
            added_by: params.id.toLowerCase()
        })
            .then(() => {
                console.log('Added successfull');
                const Toast = Swal.mixin({
                    toast: true,
                    background: '#69aba6',
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer)
                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                    }
                })

                Toast.fire({
                    icon: 'success',
                    title: 'Thank you for your interest ! We will get in touch with you'
                })
                setSubmitDistributor({ full_name: '', email: '', phone_no: '', space_available: '', initial_investment: '', created_date: new Date() })
                openModalNew()
            });


    }


    // --------------------------------------------------
    // ------------------------
    const handleChangeTrainer = (e) => {
        const { id, value } = e.target
        setSubmitTrainer({ ...submitTrainer, [id]: value })
    }

    const handleSubmitTrainer = (e) => {
        // alert(submitTrainer.phone_no.length)
        console.log(JSON.stringify(submitTrainer));
        e.preventDefault();

        db.collection("beauty_trainer").add({
            name: submitTrainer.full_name,
            phone_no: submitTrainer.phone_no,
            email: submitTrainer.email,
            address: submitTrainer.address,
            course: submitTrainer.course,
            duration: submitTrainer.duration,
            timing: submitTrainer.timing,
            days: submitTrainer.days,
            fee_proposed: submitTrainer.fee_proposed,
            mode: submitTrainer.mode,
            course_content: submitTrainer.course_content,
            student_count: submitTrainer.student_count,
            need_certificate: submitTrainer.need_certificate,
            starting_date: submitTrainer.starting_date,
            created_date: submitTrainer.created_date,
            added_by: params.id.toLowerCase()
        })
            .then(() => {
                console.log('Added successfull');
                const Toast = Swal.mixin({
                    toast: true,
                    background: '#69aba6',
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer)
                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                    }
                })

                Toast.fire({
                    icon: 'success',
                    title: 'Thank you for your interest ! We will get in touch with you'
                })
                setSubmitTrainer({ full_name: '', email: '', phone_no: '', address: '', course: '', duration: '', timing: "", days: "", fee_proposed: "", mode: '', course_content: '', student_count: '', need_certificate: '', starting_date: '', created_date: new Date() })
                openModalNew()
            });


    }


    const [servicesArrayContains, setServicesArrayContains] = useState([]);

    const openServices = (name) => {
        servicesListArray.map((item) => servicesArrayContains.push(item.type));

        // alert(servicesListArray.length)
        setSelectedServices(name);
        setServiceEnable(true);
        console.log(servicesArrayContains)
    }


    // ---------------------------------------------
    // FirebaseFirestore.instance
    // .collection('users')
    // .doc(userId)
    // .get()
    // .then((DocumentSnapshot documentSnapshot) {
    //   if (documentSnapshot.exists) {
    //     print('Document data: ${documentSnapshot.data()}');
    //   } else {
    //     print('Document does not exist on the database');
    //   }
    // });

    let frame = 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3800.547629531806!2d83.30362381385468!3d17.71881759782766!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xa888e969207d9774!2s7M95P894%2BG8!5e0!3m2!1sen!2sin!4v1655463532585!5m2!1sen!2sin';
    // https://www.google.com/maps/place/Nikky+Bawa+Beauty+Salon+-+Best+Hair+Salon+in+Bhopal/data=!4m5!3m4!1s0x397c43212c8b6fa1:0xb4b7c668acbb5622!8m2!3d23.2260601!4d77.4016706

    const openModalNew = () => {
        window.openModelNew();
    }

    useEffect(() => {
        setNum(randomNumberInRange(0, 8));
        getLocation();
        const script = document.createElement("script");
        script.src = "https://checkout.razorpay.com/v1/checkout.js";
        script.async = true;
        document.body.appendChild(script);

        db.collection("personal_details").where('username', '==', params.id.toLowerCase()).onSnapshot((snapshot) => {
            // alert("personal_details calling")
            // setCustomersData(
            //     snapshot.docs.map((doc) => ({
            //         id: doc.id,
            //         data: doc.data(),
            //     }))
            // );
            snapshot.docs.map(e =>
                db.collection("staff_list").where('uid', '==', e.id).onSnapshot((snapshot) => {
                    setStaffList(
                        snapshot.docs.map((doc) => ({
                            id: doc.id,
                            data: doc.data(),
                        }))
                    );
                    // snapshot.docs.map(e => console.log("listt-", e.data()))

                }))

            snapshot.docs.map(e => setPersonalData(e.data()))
        });

        db.collection("parlour_details").where('username', '==', params.id.toLowerCase()).onSnapshot((snapshot) => {
            // alert("parlour_details calling")
            // setCustomersData(
            //     snapshot.docs.map((doc) => ({
            //         id: doc.id,
            //         data: doc.data(),
            //     }))
            // );
            if (snapshot.docs.length > 0) {
                snapshot.docs.map(e => setCustomersData(e.data().services))
                snapshot.docs.map(e => setParlourData(e.data()))
                snapshot.docs.map(e => console.log(e.data()))
                snapshot.docs.map(e => setPicS(e.data().services_images.length))
                snapshot.docs.map(e => setPicB(e.data().salon_business_images.length))
                snapshot.docs.map(e => {
                    // console.log("services", e.data().services)
                    if (e.data().services == undefined) {
                        // alert("not exists")
                        setServicesListArray([])
                    } else {
                        setServicesListArray(e.data().services)
                    }
                }
                );
                // setServicesListArray(e.data().services)


                // console.log("services", e.data())
                setVerified(true)
                setIsLoading(false)
            } else {
                db.collection("unregistered_users").where('username', '==', params.id.toLowerCase()).onSnapshot((snapshot1) => {
                    if (snapshot1.docs.length == 0) {
                        navigate('/error');
                    } else {
                        // alert("exists")
                        setIsLoading(false);
                    }

                    snapshot1.docs.map(e => setParlourData(e.data()))
                    snapshot1.docs.map(e => console.log(e.data()))
                    snapshot1.docs.map(e => setPersonalData(e.data()))
                    setVerified(false)


                });
            }

            // snapshot.docs.map(e=> )
        });

        db.collection("parlour_feedback").where('username', '==', params.id.toLowerCase()).onSnapshot((snapshot) => {

            setTestimonial(
                snapshot.docs.map((doc) => ({
                    id: doc.id,
                    data: doc.data(),
                }))
            );

            // snapshot.docs.map(e => console.log("testi-", e.data()))
        });




    }, []);

    const ss = () => {
        setshow(false);
    }


    var settings2 = {
        dots: true,
        autoplay: true,
        slidesToShow: picS,
    };

    var settings3 = {
        dots: true,
        autoplay: true,
        slidesToShow: picB,
    };

    const clearForm = () => {
        setSubmit({ full_name: '', username: params.id.toLowerCase(), email: '', phone_no: '', created_date: new Date() });

    }

    // handleChange = (evt) => {
    //     console.log(evt.target.value)
    //     this.setState({
    //         amount: evt.target.value
    //     })
    // }


    const openPayModal = (amt) => {
        console.log("call 1")
        var amount = amt * 100; //Razorpay consider the amount in paise
        var options = {
            "key": 'rzp_test_qAtDyPMbwSui8G',
            "amount": amount, // 2000 paise = INR 20, amount in paisa
            "name": "Rahul",
            "payment_capture": 1,
            "description": "Singh",
            // 'order_id': "order_K6znRe04zXT6FG",
            "handler": function (response) {
                console.log("call 2");
                console.log(response);
                // alert(response.razorpay_payment_id);
                // alert(response.razorpay_order_id);
                // alert(response.razorpay_signature);
                var values = {
                    razorpay_signature: response.razorpay_signature,
                    razorpay_order_id: response.razorpay_order_id,
                    transactionid: response.razorpay_payment_id,
                    transactionamount: amount,

                }
                axios.post('http://localhost:5000/upgrade/payment', values)
                    .then(res => { alert("Success") })
                    .catch(e => console.log(e))
            },
            "prefill": {
                "name": submit.name,
                "email": submit.email,
                "contact": submit.phone,
            },
            "notes": {
                "address": submit.address
            },
            "theme": {
                "color": "#528ff0"
            }
        };
        var rzp1 = new window.Razorpay(options);
        rzp1.open();
        axios.post('http://localhost:5000/contact', { amount: amount })
            .then(res => {
                console.log("call 3")
                options.order_id = res.data.id;
                options.amount = res.data.amount;
                console.log(options)
                var rzp1 = new window.Razorpay(options);
                rzp1.open();
            })
            .catch(e => console.log(e))

    };
    var imgData = [
        'banner_1.jpg',
        '1bg.webp',
        '2bg.webp',
        '3bg.webp',
        '4bg.webp',
        '5bg.webp',
        '6bg.webp',
        '7bg.webp',
        '8bg.jpg',
    ];


    return (
        <>
            {isLoading ? <div>
                <img src="images/loader.gif" alt="Popup Image" class="" style={{ display: "block", marginLeft: "auto", marginRight: "auto", width: "40%" }} />
            </div> : <div>
                {/* <h1>{parlourData.parlour_name}</h1>
            <h1>{parlourData.services_images && parlourData.services_images[0]}</h1> */}



                {/* <h1>{testimonial[9].full_name}-</h1> */}
                {/* {testimonial.map((item) => {
                return <h1>{item}</h1>
            })} */}
                {/* <div style={{ display: !show ? "block" : "none" }}>
                <input class="w3l_text_box" id="phone_no" onChange={handleChange} value={submit.phone_no} placeholder="10 Digit Phone Number" name="Phone" type="tel" minlength="10" maxlength="10" required />
                <br /><br />
                <div id="recaptcha-container"></div>
                <button onClick={signin}>Send OTP</button>
            </div> */}
                {/* <button onClick={addUser}>Click</button> */}

                {Verified == false ? <div class="banner-outer" data-toggle="modal" style={{ cursor: "pointer" }} data-target="#exampleModalCenter">
                    <div class="banner-inner responsive-wrapper">
                        <p style={{ color: "white" }}>Claim Your Profile. India's Beauty Super App</p>
                        <div class="ribbon top right sticky blue" data-toggle="modal" data-target="#exampleModalCenter">Claim Now</div>
                    </div>
                </div>
                    : ""}
                {/* <h1>{parlourData.plus_code}</h1> */}
                <div id="home" class="banner" style={{ background: `url(../images/${imgData[num]}) no-repeat 0px 0px`, padding: "1rem" }}>
                    <div class="">
                        <div class="w3_agile_logo" style={{ display: "flex" }}>
                            <h1><Link to={`/`}><i class="fa fa-home" style={{ paddingRight: "18px", color: "#d4573f", fontSize: "42px" }} aria-hidden="true"></i></Link><a href=""><span>Port</span> Folio</a></h1>
                            <img src={Verified == true ? "images/verified2.png" : "images/unverified2.png"} alt="Popup Image" class="" style={{ height: "4rem", marginTop: "-13px" }} onClick={handleShow}></img>
                        </div>


                        <div class="agile_banner_info">
                            <h2><span class="d4">{personalData.full_name}</span><i>{parlourData.parlour_name}</i></h2>
                            <p style={{ textTransform: "uppercase", }}>{parlourData.full_address}, {parlourData.pincode}</p>
                        </div>
                        <div class="agileits_w3layouts_call_mail">
                            <ul style={{ marginTop: "-3rem" }}>
                                <li><i class="fa fa-phone" aria-hidden="true"></i>+91-{parlourData.phone_no == "" ? "*** *** ****" : parlourData.phone_no}</li>
                                <li><i class="fa fa-envelope-o" aria-hidden="true"></i><a
                                    href="mailto:maria@example.com">{personalData.email == "" ? "*****@gmail.com" : personalData.email}</a></li>
                            </ul>
                        </div>
                    </div>
                </div>


                {Verified == false ? <section class="contact_map_area" style={{ marginTop: "2rem", marginLeft: "1rem", marginBottom: "2rem", marginRight: "1rem" }}>
                    <iframe
                        width="100%"
                        height="300"
                        style={{ border: "0" }}
                        // src="https://www.google.com/maps/embed/v1/place?key=AIzaSyAw0My4Arc0Ht1EBA2z_7b07eA00jPs5VU&q=7F2F%2BW6 Bhopal+Madhya+Pradesh+India+Tarasha+beauty+salon+for+ladies" allowfullscreen>
                        src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyAw0My4Arc0Ht1EBA2z_7b07eA00jPs5VU&q=${parlourData.plus_code}`} allowfullscreen>
                    </iframe>
                </section> : ""}
                {/* <h1>{parlourData.plus_code}</h1> */}

                <div id="about" class="">
                    <div class="container">
                        <div class="w3_banner_bottom_grid_pos">
                            <div class="w3_banner_bottom_grid" style={{ marginBottom: "2rem" }}>
                                {/* <h3>Beauty Saloon</h3> */}
                            </div>
                        </div>
                        <div class="w3ls_banner_bottom_grid1">
                            <div class="col-md-6 w3l_banner_bottom_left">
                                <img src={personalData.image} alt=" " class="img-responsive" style={{ width: "36rem", height: "26rem" }} />
                                {/* <img src={parlourData.image} alt=" " class="img-responsive" style={{ width: "36rem", height: "26rem" }} /> */}
                            </div>
                            <div class="col-md-6 w3l_banner_bottom_right">
                                <h3>Welcome to <span><i>{parlourData.parlour_name}</i></span></h3>
                                <p>Powered by Youvati- India's Beauty Superapp</p>
                                <ul>
                                    {Verified == false ? <li><i class="fa fa-map-marker" aria-hidden="true"></i><span style={{ fontSize: "14px" }}>Best Beauty Parlour in {parlourData.place_best_beauty_parlour_in} </span></li> : ""}
                                    <li><i class="fa fa-certificate" aria-hidden="true"></i>Certified Salon</li>
                                    <li><i class="fa fa-star" aria-hidden="true"></i>{parlourData.rating == null ? "4" : parlourData.rating} / 5  (Reviews: {parlourData.reviews})</li>
                                    <li><i class="fa fa-rupee" aria-hidden="true"></i>Low Prices</li>
                                </ul>
                            </div>
                            <div class="clearfix"> </div>
                        </div>
                        {/* <div class="agileits_banner_bottom_grid_three"> */}

                        {/* <div className="container" style={{ padding: "40px", background: "#419be0" }}> */}

                        {/* <Slider {...settings2}>
                        <div>
                            <img src="http://placekitten.com/g/400/200" class="p-2" />
                        </div>
                        <div>
                            <img src="http://placekitten.com/g/400/200" class="p-2" />
                        </div>
                        <div>
                            <img src="http://placekitten.com/g/400/200" class="p-2" />
                        </div>
                        <div>
                            <img src="http://placekitten.com/g/400/200" class="p-2" />
                        </div>
                    </Slider> */}
                        {/* </div> */}


                        {/* <div class="clearfix"> </div> */}
                        {/* </div> */}
                    </div>
                </div>



                {Verified == true ? <div className="container" style={{ width: "100%", background: "#e0e0e0", padding: "29px", marginBottom: "1rem" }}>
                    <p style={{ fontSize: "22px", paddingBottom: "2px", fontWeight: "700", color: "black" }}>Service Images</p>
                    <Slider {...settings2}>

                        {parlourData.services_images && parlourData.services_images.map((item) => (
                            <div>
                                <img src={item} class="p-2" style={{ height: "16rem" }} />
                            </div>
                        ))}
                    </Slider>
                </div>
                    : ""}


                {Verified == true ? <div className="container" style={{ width: "100%", background: "#e0e0e0", padding: "29px", marginBottom: "1rem" }}>
                    <p style={{ fontSize: "22px", paddingBottom: "2px", fontWeight: "700", color: "black" }}>Business Images</p>
                    <Slider {...settings3}>

                        {parlourData.salon_business_images && parlourData.salon_business_images.map((item) => (
                            <div>
                                <img src={item} class="p-2" style={{ height: "16rem" }} />
                            </div>
                        ))}
                    </Slider>
                </div>
                    :
                    ""}


                {/* <div class="border" style={{ width: "100%", background: "#e0e0e0", padding: "29px", marginBottom: "1rem" }}>
                <Slider {...settings2}> */}
                {/* <img src="http://placekitten.com/g/400/200" class="p-2" />
                    <img src="http://placekitten.com/g/400/200" class="p-2" />
                    <img src="http://placekitten.com/g/400/200" class="p-2" />
                    <img src="http://placekitten.com/g/400/200" class="p-2" /> */}
                {/* {parlourData.services_images.map((item) => (
                        <img src={item} class="p-2" />

                    ))} */}
                {/* <img src="http://placekitten.com/g/400/200" class="p-2" />
                    <img src="http://placekitten.com/g/400/200" class="p-2" /> */}
                {/* <img src="http://placekitten.com/g/400/200" class="p-2" />
                    <img src="http://placekitten.com/g/400/200" class="p-2" /> */}
                {/* <img src="http://placekitten.com/g/400/200" class="p-2" /> */}

                {/* </Slider>
            </div> */}

                {/* <div class="schedule">
                <div class="container">
                    <div class="agileits_w3layouts_schedule">
                        <h3>Our Schedule</h3>
                        <img src="images/1_1.png" alt=" " class="img-responsive" />
                    </div>
                    <div class="agile_schedule_list">
                        <ul>
                            <li style={{ color: "black" }}><span style={{ color: "black" }}>Monday</span> <i class="fa fa-clock-o" aria-hidden="true" style={{ color: "black" }}></i>{parlourData.monday}</li>
                            <li style={{ color: "black" }}><span style={{ color: "black" }}>Tuesday</span> <i class="fa fa-clock-o" aria-hidden="true" style={{ color: "black" }}></i>{parlourData.tuesday}</li>
                            <li style={{ color: "black" }}><span style={{ color: "black" }}>Wednesday</span> <i class="fa fa-clock-o" aria-hidden="true" style={{ color: "black" }}></i>{parlourData.wednesday}</li>
                            <li style={{ color: "black" }}><span style={{ color: "black" }}>Thursday</span> <i class="fa fa-clock-o" aria-hidden="true" style={{ color: "black" }}></i>{parlourData.thursday}</li>
                            <li style={{ color: "black" }}><span style={{ color: "black" }}>Friday</span> <i class="fa fa-clock-o" aria-hidden="true" style={{ color: "black" }}></i>{parlourData.friday}</li>
                            <li style={{ color: "black" }}><span style={{ color: "black" }}>Saturday</span> <i class="fa fa-clock-o" aria-hidden="true" style={{ color: "black" }}></i>{parlourData.saturday}</li>
                            <li style={{ color: "black" }}><span style={{ color: "black" }}>Sunday</span> <i class="fa fa-clock-o" aria-hidden="true" style={{ color: "black" }}></i>{parlourData.sunday}</li>
                        </ul>
                    </div>
                    <div class="wthree_more">
                        <a href="#small-dialog"
                            class="popup-with-zoom-anim button--wayra button--border-thick button--text-upper button--size-s">book
                            now</a>
                    </div>
                </div>
            </div> */}


                {!serviceEnable ? <> <section class="services services_section-bg" id="services">
                    <div class="services_container">
                        <header class="services_section-header">
                            <h3>Services</h3>

                            <p>At My, we offer a complete range of beauty services to fit your needs. From hair styling, to facial skin care, to body massage, manicures, pedicures and more, we are your all-in-one resource for a beautiful new you come and experience it today!</p>
                        </header>

                        <div class="row">

                            <div class="col-md-6 col-lg-4" onClick={() => openServices("Facial & Clean up")}>
                                <Link to="">
                                    <div class="services_box" style={{ background: "url(../img/facial_cleanup.png) no-repeat 0px 0px", backgroundSize: "100% 100%", backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPositionX: "center", backgroundPositionY: "center" }}>
                                        {/* <div class="services_icon" style={{ background: "#000" }}>
                                    <i class="fa fa-briefcase services_service-icon" style={{ color: "#c59c35" }}></i>
                                </div> */}

                                        <h4 class="services_title"><a href="" style={{ background: "rgb(81 89 120 / 70%)", color: "white", padding: "9px" }}>  Facial & Clean up  </a></h4>

                                        {/* <p class="" style={{ color: "white", background: "rgb(81 89 120 / 64%)" }}>Laudem latine persequeris id sed, ex fabulas delectus quo. No vel partiendo abhorreant vituperatoribus.</p> */}
                                    </div>
                                </Link>
                            </div>

                            <div class="col-md-6 col-lg-4" onClick={() => openServices("Waxing")}>
                                <Link to="">
                                    <div class="services_box" style={{ background: "url(../img/waxing.png) no-repeat 0px 0px", backgroundSize: "100% 100%", backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPositionX: "center", backgroundPositionY: "center" }}>
                                        {/* <div class="services_icon" style={{ background: "#000" }}>
                                    <i class="fa fa-briefcase services_service-icon" style={{ color: "#c59c35" }}></i>
                                </div> */}

                                        <h4 class="services_title"><a href="" style={{ background: "rgb(81 89 120 / 70%)", color: "white", padding: "9px" }}>  Waxing  </a></h4>

                                        {/* <p class="" style={{ color: "white", background: "rgb(81 89 120 / 64%)" }}>Laudem latine persequeris id sed, ex fabulas delectus quo. No vel partiendo abhorreant vituperatoribus.</p> */}
                                    </div>
                                </Link>
                            </div>

                            <div class="col-md-6 col-lg-4" onClick={() => openServices("Bleach & De Tan")}>
                                <Link to="">
                                    <div class="services_box" style={{ background: "url(../img/bleach.png) no-repeat 0px 0px", backgroundSize: "100% 100%", backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPositionX: "center", backgroundPositionY: "center" }}>
                                        {/* <div class="services_icon" style={{ background: "#000" }}>
                                    <i class="fa fa-briefcase services_service-icon" style={{ color: "#c59c35" }}></i>
                                </div> */}

                                        <h4 class="services_title"><a href="" style={{ background: "rgb(81 89 120 / 70%)", color: "white", padding: "9px" }}>  Bleach & De Tan  </a></h4>

                                        {/* <p class="" style={{ color: "white", background: "rgb(81 89 120 / 64%)" }}>Laudem latine persequeris id sed, ex fabulas delectus quo. No vel partiendo abhorreant vituperatoribus.</p> */}
                                    </div>
                                </Link>
                            </div>

                            <div class="col-md-6 col-lg-4" onClick={() => openServices("Mani & Pedi")}>
                                <Link to="">
                                    <div class="services_box" style={{ background: "url(../img/pedi.png) no-repeat 0px 0px", backgroundSize: "100% 100%", backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPositionX: "center", backgroundPositionY: "center" }}>
                                        {/* <div class="services_icon" style={{ background: "#000" }}>
                                    <i class="fa fa-briefcase services_service-icon" style={{ color: "#c59c35" }}></i>
                                </div> */}

                                        <h4 class="services_title"><a href="" style={{ background: "rgb(81 89 120 / 70%)", color: "white", padding: "9px" }}>  Mani & Pedi  </a></h4>

                                        {/* <p class="" style={{ color: "white", background: "rgb(81 89 120 / 64%)" }}>Laudem latine persequeris id sed, ex fabulas delectus quo. No vel partiendo abhorreant vituperatoribus.</p> */}
                                    </div>
                                </Link>
                            </div>

                            <div class="col-md-6 col-lg-4" onClick={() => openServices("Hair Care")}>
                                <Link to="">
                                    <div class="services_box" style={{ background: "url(../img/haircare.png) no-repeat 0px 0px", backgroundSize: "100% 100%", backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPositionX: "center", backgroundPositionY: "center" }}>
                                        {/* <div class="services_icon" style={{ background: "#000" }}>
                                    <i class="fa fa-briefcase services_service-icon" style={{ color: "#c59c35" }}></i>
                                </div> */}

                                        <h4 class="services_title"><a href="" style={{ background: "rgb(81 89 120 / 70%)", color: "white", padding: "9px" }}>  Hair Care  </a></h4>

                                        {/* <p class="" style={{ color: "white", background: "rgb(81 89 120 / 64%)" }}>Laudem latine persequeris id sed, ex fabulas delectus quo. No vel partiendo abhorreant vituperatoribus.</p> */}
                                    </div>
                                </Link>
                            </div>

                            <div class="col-md-6 col-lg-4" onClick={() => openServices("Makeup")}>
                                <Link to="">
                                    <div class="services_box" style={{ background: "url(../img/makeup.png) no-repeat 0px 0px", backgroundSize: "100% 100%", backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPositionX: "center", backgroundPositionY: "center" }}>
                                        {/* <div class="services_icon" style={{ background: "#000" }}>
                                    <i class="fa fa-briefcase services_service-icon" style={{ color: "#c59c35" }}></i>
                                </div> */}

                                        <h4 class="services_title"><a href="" style={{ background: "rgb(81 89 120 / 70%)", color: "white", padding: "9px" }}>  Makeup  </a></h4>

                                        {/* <p class="" style={{ color: "white", background: "rgb(81 89 120 / 64%)" }}>Laudem latine persequeris id sed, ex fabulas delectus quo. No vel partiendo abhorreant vituperatoribus.</p> */}
                                    </div>
                                </Link>
                            </div>

                            <div class="col-md-6 col-lg-4" onClick={() => openServices("Threading")}>
                                <Link to="">
                                    <div class="services_box" style={{ background: "url(../img/threading.png) no-repeat 0px 0px", backgroundSize: "100% 100%", backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPositionX: "center", backgroundPositionY: "center" }}>
                                        {/* <div class="services_icon" style={{ background: "#000" }}>
                                    <i class="fa fa-briefcase services_service-icon" style={{ color: "#c59c35" }}></i>
                                </div> */}

                                        <h4 class="services_title"><a href="" style={{ background: "rgb(81 89 120 / 70%)", color: "white", padding: "9px" }}>  Threading  </a></h4>

                                        {/* <p class="" style={{ color: "white", background: "rgb(81 89 120 / 64%)" }}>Laudem latine persequeris id sed, ex fabulas delectus quo. No vel partiendo abhorreant vituperatoribus.</p> */}
                                    </div>
                                </Link>
                            </div>

                            <div class="col-md-6 col-lg-4" onClick={() => openServices("Body Message")}>
                                <Link to="">
                                    <div class="services_box" style={{ background: "url(../img/bodymessage.png) no-repeat 0px 0px", backgroundSize: "100% 100%", backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPositionX: "center", backgroundPositionY: "center" }}>
                                        {/* <div class="serv ices_icon" style={{ background: "#000" }}>
                                    <i class="fa fa-briefcase services_service-icon" style={{ color: "#c59c35" }}></i>
                                </div> */}

                                        <h4 class="services_title"><a href="" style={{ background: "rgb(81 89 120 / 70%)", color: "white", padding: "9px" }}>  Body Message  </a></h4>

                                        {/* <p class="" style={{ color: "white", background: "rgb(81 89 120 / 64%)" }}>Laudem latine persequeris id sed, ex fabulas delectus quo. No vel partiendo abhorreant vituperatoribus.</p> */}
                                    </div>
                                </Link>
                            </div>

                            <div class="col-md-6 col-lg-4" onClick={() => openServices("Mehendi")}>
                                <Link to="">
                                    <div class="services_box" style={{ background: "url(../img/mehendi.png) no-repeat 0px 0px", backgroundSize: "100% 100%", backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPositionX: "center", backgroundPositionY: "center" }}>
                                        {/* <div class="services_icon" style={{ background: "#000" }}>
                                    <i class="fa fa-briefcase services_service-icon" style={{ color: "#c59c35" }}></i>
                                </div> */}

                                        <h4 class="services_title"><a href="" style={{ background: "rgb(81 89 120 / 70%)", color: "white", padding: "9px" }}>  Mehendi  </a></h4>

                                        {/* <p class="" style={{ color: "white", background: "rgb(81 89 120 / 64%)" }}>Laudem latine persequeris id sed, ex fabulas delectus quo. No vel partiendo abhorreant vituperatoribus.</p> */}
                                    </div>
                                </Link>
                            </div>




                        </div>
                    </div>
                </section>
                </>
                    :
                    <>
                        <div style={{ textAlign: "right" }}>
                            <span style={{ background: "coral", marginRight: "2rem", padding: "8px", cursor: "pointer" }} onClick={() => setServiceEnable(false)}>Close</span>
                        </div>

                        <section class="" id="services">

                            <div class="container">
                                {/* <div class="row">
                                    <div class="col-lg-12">
                                        <div class="center-heading">
                                            <h2 class="section-title">Our Services</h2>
                                        </div>
                                    </div>
                                </div> */}

                                <div class="row">



                                    {servicesListArray.filter((e) => e.type == selectedService).map(item => {
                                        if (item.type == selectedService) {
                                            return <>
                                                <div class="col-lg-3 col-sm-6">
                                                    <a href="#" class="services-small-item">
                                                        <div class="pb-2">
                                                            <p> <img src={item.image} alt=" " class="img-responsive" style={{ height: "19rem", width: "20rem" }} /></p>
                                                        </div>
                                                        <h5 class="services-title">{item.type}</h5>
                                                        {/* <div class="row"> */}
                                                        <p style={{ display: "flex" }}>Estimated Time: {item.duration}</p>
                                                        {/* </div> */}
                                                        <p style={{ display: "flex" }}>Discount: {item.discount}% Off</p>
                                                        <p style={{ display: "flex" }}>Price: Rs {item.amount}/-</p>
                                                        <p class="pt-2">{item.details} </p>
                                                        <div class="button" style={{ paddingTop: "2rem" }} onClick={(e) => { openPayModal(item.amount) }}>
                                                            <i class="fa">Book Now</i>
                                                        </div>

                                                    </a>
                                                    <div class="item-bg"></div>
                                                </div>
                                            </>
                                        }


                                    })
                                    }





                                </div>
                                <div style={{ textAlign: "center" }}>
                                    <span style={{ padding: "8px", cursor: "pointer" }} >{servicesArrayContains.includes(selectedService) ? <></> : <><h1>No Service Found !!!</h1></>}</span>
                                </div>
                            </div>
                        </section>
                    </>

                }






                <div id="testimonials" class="testimonials">
                    <div class="container">
                        <div class="agileits_w3layouts_schedule">
                            <h3>Our Schedule</h3>
                            <img src="images/1_1.png" alt=" " class="img-responsive" />
                        </div>
                        <div class="agile_schedule_list">
                            {Verified == false ?
                                <ul>
                                    <li style={{ color: "black" }}><span style={{ color: "black" }}>Monday</span> <i class="fa fa-clock-o" aria-hidden="true" style={{ color: "black" }}></i>{parlourData.monday}</li>
                                    <li style={{ color: "black" }}><span style={{ color: "black" }}>Tuesday</span> <i class="fa fa-clock-o" aria-hidden="true" style={{ color: "black" }}></i>{parlourData.tuesday}</li>
                                    <li style={{ color: "black" }}><span style={{ color: "black" }}>Wednesday</span> <i class="fa fa-clock-o" aria-hidden="true" style={{ color: "black" }}></i>{parlourData.wednesday}</li>
                                    <li style={{ color: "black" }}><span style={{ color: "black" }}>Thursday</span> <i class="fa fa-clock-o" aria-hidden="true" style={{ color: "black" }}></i>{parlourData.thursday}</li>
                                    <li style={{ color: "black" }}><span style={{ color: "black" }}>Friday</span> <i class="fa fa-clock-o" aria-hidden="true" style={{ color: "black" }}></i>{parlourData.friday}</li>
                                    <li style={{ color: "black" }}><span style={{ color: "black" }}>Saturday</span> <i class="fa fa-clock-o" aria-hidden="true" style={{ color: "black" }}></i>{parlourData.saturday}</li>
                                    <li style={{ color: "black" }}><span style={{ color: "black" }}>Sunday</span> <i class="fa fa-clock-o" aria-hidden="true" style={{ color: "black" }}></i>{parlourData.sunday}</li>
                                </ul>
                                :
                                // <ul>
                                //     <li style={{ color: "black" }}><span style={{ color: "black" }}>Monday</span> <i class="fa fa-clock-o" aria-hidden="true" style={{ color: "black" }}></i>{parlourData.open_time} to {parlourData.close_time} </li>
                                //     <li style={{ color: "black" }}><span style={{ color: "black" }}>Tuesday</span> <i class="fa fa-clock-o" aria-hidden="true" style={{ color: "black" }}></i>{parlourData.open_time} to {parlourData.close_time} </li>
                                //     <li style={{ color: "black" }}><span style={{ color: "black" }}>Wednesday</span> <i class="fa fa-clock-o" aria-hidden="true" style={{ color: "black" }}></i>{parlourData.open_time} to {parlourData.close_time} </li>
                                //     <li style={{ color: "black" }}><span style={{ color: "black" }}>Thursday</span> <i class="fa fa-clock-o" aria-hidden="true" style={{ color: "black" }}></i>{parlourData.open_time} to {parlourData.close_time} </li>
                                //     <li style={{ color: "black" }}><span style={{ color: "black" }}>Friday</span> <i class="fa fa-clock-o" aria-hidden="true" style={{ color: "black" }}></i>{parlourData.open_time} to {parlourData.close_time} </li>
                                //     <li style={{ color: "black" }}><span style={{ color: "black" }}>Saturday</span> <i class="fa fa-clock-o" aria-hidden="true" style={{ color: "black" }}></i>{parlourData.open_time} to {parlourData.close_time} </li>
                                //     <li style={{ color: "black" }}><span style={{ color: "black" }}>Sunday</span> <i class="fa fa-clock-o" aria-hidden="true" style={{ color: "black" }}></i>{parlourData.open_time} to {parlourData.close_time} </li>
                                // </ul>

                                <>
                                    <ul>
                                        {parlourData.open_days && parlourData.open_days.map((item) => {
                                            if (item === "Mon") {
                                                return <li style={{ color: "black" }}><span style={{ color: "black" }}>Monday</span> <i class="fa fa-clock-o" aria-hidden="true" style={{ color: "black" }}></i>{parlourData.open_time} to {parlourData.close_time} </li>
                                            } else if (item === "Tues") {
                                                return <li style={{ color: "black" }}><span style={{ color: "black" }}>Tuesday</span> <i class="fa fa-clock-o" aria-hidden="true" style={{ color: "black" }}></i>{parlourData.open_time} to {parlourData.close_time} </li>
                                            }
                                            else if (item === "Wed") {
                                                return <li style={{ color: "black" }}><span style={{ color: "black" }}>Wednesday</span> <i class="fa fa-clock-o" aria-hidden="true" style={{ color: "black" }}></i>{parlourData.open_time} to {parlourData.close_time} </li>
                                            }
                                            else if (item === "Thurs") {
                                                return <li style={{ color: "black" }}><span style={{ color: "black" }}>Thursday</span> <i class="fa fa-clock-o" aria-hidden="true" style={{ color: "black" }}></i>{parlourData.open_time} to {parlourData.close_time} </li>
                                            }
                                            else if (item === "Fri") {
                                                return <li style={{ color: "black" }}><span style={{ color: "black" }}>Friday</span> <i class="fa fa-clock-o" aria-hidden="true" style={{ color: "black" }}></i>{parlourData.open_time} to {parlourData.close_time} </li>
                                            }
                                            else if (item === "Sat") {
                                                return <li style={{ color: "black" }}><span style={{ color: "black" }}>Saturday</span> <i class="fa fa-clock-o" aria-hidden="true" style={{ color: "black" }}></i>{parlourData.open_time} to {parlourData.close_time} </li>
                                            }
                                            else if (item === "Sun") {
                                                return <li style={{ color: "black" }}><span style={{ color: "black" }}>Sunday</span> <i class="fa fa-clock-o" aria-hidden="true" style={{ color: "black" }}></i>{parlourData.open_time} to {parlourData.close_time} </li>
                                            }

                                        })}
                                    </ul>
                                </>
                            }
                        </div>
                        <div class="wthree_more">
                            <a href="#small-dialog"
                                class="popup-with-zoom-anim button--wayra button--border-thick button--text-upper button--size-s">book
                                now</a>
                        </div>
                    </div>
                </div>

                <div class="schedule-bottom">
                    <div class="col-md-6 agileinfo_schedule_bottom_left">
                        <img src="images/bride1_1.webp" alt=" " class="img-responsive" />
                    </div>
                    <div class="col-md-6 agileits_schedule_bottom_right">
                        <div class="w3ls_schedule_bottom_right_grid">
                            <h3>Download The <span>Youvati App</span> Now</h3>
                            <p style={{ textAlign: "center" }}>India's Largest Beauty Community <br />Make Money | Save Money | Shared Community</p>
                            <div class="col-md-4 w3l_schedule_bottom_right_grid1">
                                <i class="fa fa-calendar-o" aria-hidden="true"></i>
                                <h4 style={{ fontSize: "11px" }}>Enable Customers Booking</h4>
                                {/* <h5 class="counter">653</h5> */}
                            </div>
                            <div class="col-md-4 w3l_schedule_bottom_right_grid1">
                                <i class="fa fa-user-o" aria-hidden="true"></i>
                                <h4 style={{ fontSize: "11px" }}>Update Portfolio</h4>
                                {/* <h5 class="counter">823</h5> */}
                            </div>
                            <div class="col-md-4 w3l_schedule_bottom_right_grid1">
                                <i class="fa fa-shield" aria-hidden="true"></i>
                                <h4 style={{ fontSize: "11px" }}>& More</h4>
                                {/* <h5 class="counter">45</h5> */}
                            </div>
                            <div class="clearfix"> </div>
                        </div>
                    </div>
                    <div class="clearfix"> </div>
                </div>
                {/* ------------------------------------ */}
                {/* <div id="small-dialog" class="mfp-hide"> */}


                {/* ---------------------------------Claim now start------------------------------------------- */}
                <div class="modal fade bd-example-modal-lg" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div class="modal-content">
                            {/* <div class="modal-header">
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div> */}
                            <div class="modal-body">
                                {show ?
                                    <>
                                        <div id="small-dialog" class="">
                                            <div class="w3layouts_payment_online_form">
                                                <h4>Verify OTP</h4>
                                                <ul>
                                                    <li><input class="w3l_text_box" id="otp" onChange={(e) => { setotp(e.target.value) }} placeholder="Enter OTP" name="OTP" type="text" required />
                                                    </li>
                                                </ul>

                                                <ul class="w3_payment_sendbtns">
                                                    <li><input type="reset" onClick={ss} value="Cancel" /></li>
                                                    <li>{hideButton ? <input type="submit" onClick={ValidateOtp} value="Submit OTP" /> : "Please wait.."}</li>
                                                </ul>
                                                <div class="clearfix"> </div>
                                            </div>
                                        </div>
                                    </>
                                    :
                                    <>
                                        <div id="small-dialog" class="">
                                            <div class="w3layouts_payment_online_form">
                                                <form class="" id="" onSubmit={handleSubmit}>
                                                    <h4>Claim Your Profile</h4>
                                                    <ul>
                                                        <li><input class="w3l_text_box" id="full_name" onChange={handleChange} value={submit.full_name} placeholder="Full Name" name="Full Name" type="text" required />
                                                            <p style={{ color: "white", fontSize: "10px", paddingTop: "0px" }}>.</p>
                                                        </li>
                                                        <li><input class="w3l_text_box" id="username" onChange={handleChange} value={submit.username} placeholder="User Name" name="User Name" type="text" required="" readOnly />
                                                            <p style={{ color: "red", fontSize: "10px", paddingTop: "0px" }}>*You can change username through the app</p>
                                                        </li>
                                                    </ul>
                                                    <ul>
                                                        <li><input class="w3l_text_box" id="email" onChange={handleChange} value={submit.email} placeholder="Email" name="Email" type="email" required="" /></li>
                                                        <li><input class="w3l_text_box" id="phone_no" onChange={handleChange} value={submit.phone_no} placeholder="10 Digit Phone Number" name="Phone" type="tel" minlength="10" maxlength="10" required />
                                                        </li>
                                                    </ul>
                                                    <div id="recaptcha-container"></div>

                                                    <ul class="w3_payment_sendbtns">
                                                        <li><input type="reset" onClick={clearForm} value="Reset" /></li>
                                                        <li><input type="submit" onClick={signin} value="Get OTP" /></li>
                                                    </ul>
                                                    <div class="clearfix"> </div>
                                                </form>
                                            </div>
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                {/* ---------------------------------Claim now start------------------------------------------- */}


                {/* --------------------------------Refer Parlour/freelancer Starts-------------------------------------------- */}
                <div class="modal fade bd-example-modal-lg" id="referParlour" tabindex="-1" role="dialog" aria-labelledby="referParlourTitle" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div class="modal-content">
                            {/* <div class="modal-header">
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div> */}
                            <div class="modal-body">
                                <>
                                    <div id="small-dialog" class="">
                                        <div class="w3layouts_payment_online_form">
                                            <form class="" id="" onSubmit={handleSubmitRefer}>
                                                <h4>Refer Parlour / Freelancers</h4>
                                                <ul>
                                                    <li><input class="w3l_text_box" id="full_name" onChange={handleChangeRefer} value={submitRefer.full_name} placeholder="Full Name" name="Full Name" type="text" required />
                                                    </li>
                                                    <li><input class="w3l_text_box" id="phone_no" onChange={handleChangeRefer} value={submitRefer.phone_no} placeholder="10 Digit Phone Number" name="Phone" type="tel" minlength="10" maxlength="10" required />
                                                    </li>
                                                </ul>
                                                <ul>
                                                    <li><input class="w3l_text_box" id="email" onChange={handleChangeRefer} value={submitRefer.email} placeholder="Email" name="Email" type="email" required="" /></li>
                                                    <li>
                                                    </li>
                                                </ul>

                                                <ul class="w3_payment_sendbtns">
                                                    <li><input type="reset" data-dismiss="modal" aria-label="Close" onClick={clearForm} value="Cancel" /></li>
                                                    <li><input type="submit" id="btnSave" value="Submit" /></li>
                                                </ul>
                                                <div class="clearfix"> </div>
                                            </form>
                                        </div>
                                    </div>
                                </>
                            </div>
                        </div>
                    </div>
                </div>
                {/* --------------------------------Refer Parlour/freelancer Ends-------------------------------------------- */}


                {/* --------------------------------Be a freelance trainer Starts-------------------------------------------- */}
                <div class="modal fade bd-example-modal-lg" id="beATrainer" tabindex="-1" role="dialog" aria-labelledby="beATrainerTitle" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div class="modal-content">
                            {/* <div class="modal-header">
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div> */}
                            <div class="modal-body">

                                <>
                                    <div id="small-dialog" class="">
                                        <div class="w3layouts_payment_online_form">
                                            <form class="" id="" onSubmit={handleSubmitTrainer}>
                                                <h4>Be A Beauty Trainer</h4>
                                                <ul>
                                                    <li><input class="w3l_text_box" id="full_name" onChange={handleChangeTrainer} value={submitTrainer.full_name} placeholder="Full Name" name="Full Name" type="text" required />
                                                    </li>
                                                    <li><input class="w3l_text_box" id="phone_no" onChange={handleChangeTrainer} value={submitTrainer.phone_no} placeholder="10 Digit Phone Number" name="Phone" type="tel" minlength="10" maxlength="10" required />
                                                    </li>
                                                </ul>
                                                <ul>
                                                    <li><input class="w3l_text_box" id="email" onChange={handleChangeTrainer} value={submitTrainer.email} placeholder="Email" name="Email" type="email" required="" /></li>
                                                    <li>
                                                        <input class="w3l_text_box" id="address" onChange={handleChangeTrainer} value={submitTrainer.address} placeholder="Address" name="Address" type="text" required />
                                                    </li>
                                                </ul>
                                                <ul>
                                                    <li><input class="w3l_text_box" id="course" onChange={handleChangeTrainer} value={submitTrainer.course} placeholder="Proposed Course" name="Course" type="text" required /></li>
                                                    <li>
                                                        <input class="w3l_text_box" id="duration" onChange={handleChangeTrainer} value={submitTrainer.duration} placeholder="Duration in minutes" name="Duration" type="text" required />
                                                    </li>
                                                </ul>
                                                <ul>
                                                    <li><input class="w3l_text_box" id="timing" onChange={handleChangeTrainer} value={submitTrainer.timing} placeholder="Timing" name="Timing" type="text" required /></li>
                                                    <li>
                                                        <input class="w3l_text_box" id="days" onChange={handleChangeTrainer} value={submitTrainer.days} placeholder="Days" name="Days" type="text" required />
                                                    </li>
                                                </ul>
                                                <ul>
                                                    <li><input class="w3l_text_box" id="fee_proposed" onChange={handleChangeTrainer} value={submitTrainer.fee_proposed} placeholder="Fee Proposed" name="Fee Proposed" type="text" required /></li>
                                                    <li>
                                                        <label>Mode</label>
                                                        <select class="form-control" onChange={handleChangeTrainer} id="mode" required>
                                                            <option value="">--Select--</option>
                                                            <option value="yes">Online</option>
                                                        </select>
                                                    </li>
                                                </ul>
                                                <ul>
                                                    <li>
                                                        <label>Course Content Available</label>
                                                        <select class="form-control" onChange={handleChangeTrainer} id="course_content" required>
                                                            <option value="">--Select--</option>
                                                            <option value="yes">Yes</option>
                                                            <option value="no">No</option>
                                                        </select>
                                                    </li>
                                                    <li>
                                                        <label>How many ready students you have?</label>
                                                        <select class="form-control" onChange={handleChangeTrainer} id="student_count" required>
                                                            <option value="">--Select--</option>
                                                            <option value="0">0</option>
                                                            <option value="1">1</option>
                                                            <option value="2">2</option>
                                                            <option value="3">3</option>
                                                            <option value="4">4</option>
                                                            <option value="5">5</option>
                                                            <option value="6">6</option>
                                                            <option value="7">7</option>
                                                            <option value="8">8</option>
                                                            <option value="9">9</option>
                                                            <option value="10">10</option>
                                                        </select>
                                                    </li>
                                                </ul>
                                                <ul>
                                                    <li>
                                                        <label>Need Govt. recognised skill cetrificate for the course</label>
                                                        <select class="form-control" onChange={handleChangeTrainer} id="need_certificate" required>
                                                            <option value="">--Select--</option>
                                                            <option value="yes">Yes</option>
                                                            <option value="no">NO</option>
                                                        </select>
                                                    </li>
                                                    <li>
                                                        <input class="w3l_text_box" id="starting_date" onChange={handleChangeTrainer} value={submitTrainer.starting_date} placeholder="Earliest Starting Days" name="Earliest Starting Days" type="text" required />
                                                    </li>
                                                </ul>
                                                <ul class="w3_payment_sendbtns">
                                                    <li><input type="reset" data-dismiss="modal" aria-label="Close" onClick={clearForm} value="Cancel" /></li>
                                                    <li><input type="submit" value="Submit" /></li>
                                                </ul>
                                                <div class="clearfix"> </div>
                                            </form>
                                        </div>
                                    </div>
                                </>
                            </div>
                        </div>
                    </div>
                </div>
                {/* --------------------------------Be a freelance trainer Ends-------------------------------------------- */}

                {/* --------------------------------Be a Product distributer trainer Starts-------------------------------------------- */}
                <div class="modal fade bd-example-modal-lg" id="productDistributor" tabindex="-1" role="dialog" aria-labelledby="productDistributorTitle" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div class="modal-content">
                            {/* <div class="modal-header">
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div> */}
                            <div class="modal-body">

                                <>
                                    <div id="small-dialog" class="">
                                        <div class="w3layouts_payment_online_form">
                                            <form class="" id="" onSubmit={handleSubmitDistributor}>
                                                <h4>Be A Beauty Products Distributor</h4>
                                                <ul>
                                                    <li><input class="w3l_text_box" id="full_name" onChange={handleChangeDistributor} value={submitDistributor.full_name} placeholder="Full Name" name="Full Name" type="text" required />
                                                    </li>
                                                    <li><input class="w3l_text_box" id="phone_no" onChange={handleChangeDistributor} value={submitDistributor.phone_no} placeholder="10 Digit Phone Number" name="Phone" type="tel" minlength="10" maxlength="10" required />
                                                    </li>
                                                </ul>
                                                <ul>
                                                    <li><input class="w3l_text_box" id="email" onChange={handleChangeDistributor} value={submitDistributor.email} placeholder="Email" name="Email" type="email" required="" /></li>
                                                    <li>
                                                        <input class="w3l_text_box" id="initial_investment" onChange={handleChangeDistributor} value={submitDistributor.initial_investment} placeholder="Initial Investment" name="Initial Investment" type="number" required />
                                                    </li>
                                                </ul>
                                                <ul>
                                                    <li>
                                                        <label>Space available to stock product</label>
                                                        <select class="form-control" onChange={handleChangeDistributor} id="space_available" required>
                                                            <option value="">--Select--</option>
                                                            <option value="yes">Yes</option>
                                                            <option value="no">NO</option>
                                                        </select>
                                                    </li>
                                                    <li></li>
                                                </ul>
                                                <ul class="w3_payment_sendbtns">
                                                    <li><input type="reset" data-dismiss="modal" aria-label="Close" onClick={clearForm} value="Cancel" /></li>
                                                    <li><input type="submit" value="Submit" /></li>
                                                </ul>
                                                <div class="clearfix"> </div>
                                            </form>
                                        </div>
                                    </div>
                                </>
                            </div>
                        </div>
                    </div>
                </div>
                {/* --------------------------------Be a Product distributer trainer Ends-------------------------------------------- */}








                {/* --------------------------- */}

                {Verified == false ? <></> : <div id="team" class="team">
                    <div class="container">
                        <div class="agileits_w3layouts_schedule">
                            <h3>Meet Our Specialists</h3>
                            <img src="images/1_1.png" alt=" " class="img-responsive" />
                        </div>
                        <div class="w3_agile_team_grids">
                            {/* {testimonial?.map(({ id, data }) => ( */}
                            {staffList?.map(({ id, data }) => (
                                <div class="col-md-3 w3_agile_team_grid">
                                    <div class="hover14 column">
                                        <figure><img src={data.image} alt=" " class="img-responsive" /></figure>
                                    </div>
                                    <h3>{data.staff_name}</h3>
                                    <p>- {data.designation} -</p>
                                </div>
                            ))}


                            {/* <div class="col-md-3 w3_agile_team_grid">
                            <div class="hover14 column">
                                <figure><img src="images/01.webp" alt=" " class="img-responsive" /></figure>
                            </div>
                            <h3>Anjali Singh</h3>
                            <p>- Nail Artist -</p>

                        </div> */}
                            {/* <div class="col-md-3 w3_agile_team_grid">
                            <div class="hover14 column">
                                <figure><img src="images/0_12.webp" alt=" " class="img-responsive" /></figure>
                            </div>
                            <h3>Shalini Gupta</h3>
                            <p>- Bridal Makeup Artist-</p>

                        </div> */}
                            {/* <div class="col-md-3 w3_agile_team_grid">
                            <div class="hover14 column">
                                <figure><img src="images/0_1.webp" alt=" " class="img-responsive" /></figure>
                            </div>
                            <h3>Dhruvi Mishra</h3>
                            <p>- Hair Artist -</p>
                            <ul class="agileits_social_list">
                                <li><a href="#" class="w3_agile_facebook"><i class="fa fa-facebook" aria-hidden="true"></i></a>
                                </li>
                                <li><a href="#" class="agile_twitter"><i class="fa fa-twitter" aria-hidden="true"></i></a></li>
                                <li><a href="#" class="w3_agile_dribble"><i class="fa fa-dribbble" aria-hidden="true"></i></a>
                                </li>
                                <li><a href="#" class="w3_agile_vimeo"><i class="fa fa-vimeo" aria-hidden="true"></i></a></li>
                            </ul>
                        </div> */}
                            <div class="clearfix"> </div>
                        </div>
                    </div>
                </div>
                }

                <div id="portfolio" class="portfolio">
                    <ul id="flexiselDemo1">
                        <li>
                            <div class="w3_agile_portfolio_grid">
                                <a href="images/10_1.jpg">
                                    <img src="images/10_1.jpg" alt=" " class="img-responsive" />
                                    <div class="w3layouts_news_grid_pos">
                                        <div class="wthree_text">
                                            <h3>Hair <span>Dressing</span></h3>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </li>
                        <li>
                            <div class="w3_agile_portfolio_grid">
                                <a href="images/11_1.jpg">
                                    <img src="images/11_1.jpg" alt=" " class="img-responsive" />
                                    <div class="w3layouts_news_grid_pos">
                                        <div class="wthree_text">
                                            <h3>Hair <span>Dressing</span></h3>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </li>
                        <li>
                            <div class="w3_agile_portfolio_grid">
                                <a href="images/12_1.jpg">
                                    <img src="images/12_1.jpg" alt=" " class="img-responsive" />
                                    <div class="w3layouts_news_grid_pos">
                                        <div class="wthree_text">
                                            <h3>Hair <span>Dressing</span></h3>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </li>
                        <li>
                            <div class="w3_agile_portfolio_grid">
                                <a href="images/13_1.jpg">
                                    <img src="images/13_1.jpg" alt=" " class="img-responsive" />
                                    <div class="w3layouts_news_grid_pos">
                                        <div class="wthree_text">
                                            <h3>Hair <span>Dressing</span></h3>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </li>
                        <li>
                            <div class="w3_agile_portfolio_grid">
                                <a href="images/14_1.jpg">
                                    <img src="images/14_1.jpg" alt=" " class="img-responsive" />
                                    <div class="w3layouts_news_grid_pos">
                                        <div class="wthree_text">
                                            <h3>Hair <span>Dressing</span></h3>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </li>
                        <li>
                            <div class="w3_agile_portfolio_grid">
                                <a href="images/2_1.jpg">
                                    <img src="images/2_1.jpg" alt=" " class="img-responsive" />
                                    <div class="w3layouts_news_grid_pos">
                                        <div class="wthree_text">
                                            <h3>Hair <span>Dressing</span></h3>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </li>
                        <li>
                            <div class="w3_agile_portfolio_grid">
                                <a href="images/3_1.jpg">
                                    <img src="images/3_1.jpg" alt=" " class="img-responsive" />
                                    <div class="w3layouts_news_grid_pos">
                                        <div class="wthree_text">
                                            <h3>Hair <span>Dressing</span></h3>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </li>
                        <li>
                            <div class="w3_agile_portfolio_grid">
                                <a href="images/4_1.jpg">
                                    <img src="images/4_1.jpg" alt=" " class="img-responsive" />
                                    <div class="w3layouts_news_grid_pos">
                                        <div class="wthree_text">
                                            <h3>Hair <span>Dressing</span></h3>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </li>
                    </ul>
                </div>

                {Verified == false ? <></> : <div id="testimonials" class="testimonials">
                    <div class="container" style={{ paddingLeft: "4rem", paddingRight: "4rem" }}>
                        <div class="agileits_w3layouts_schedule">
                            <h3>Our Testimonials</h3>
                            <img src="images/1_1.png" alt=" " class="img-responsive" />
                        </div>
                        <div class="w3_agile_team_grids">
                            <section class="slider">
                                <div class="">
                                    <ul class="slides">
                                        <Slider {...settings}>

                                            {testimonial?.map(({ id, data }) => (
                                                <li>
                                                    <div class="agile_testimonials_grid">
                                                        <div class="agileits_w3layouts_testimonials_grid">
                                                            <img src={data.image} alt=" " style={{ height: "7rem", width: "7rem", borderRadius: "50%" }} class="img-responsive" />
                                                            <div class="wthree_test_social_pos">

                                                            </div>
                                                        </div>
                                                        {/* <h3>Hema <span>Sen</span></h3> */}
                                                        <h3>{data.full_name}</h3>
                                                        <p>{data.feedback}</p>
                                                    </div>
                                                </li>
                                            ))}
                                        </Slider>
                                    </ul>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
                }


                <div id="contact" class="contact" style={{ background: "url(../images/17_1.jpg) no-repeat 0px 0px" }}>
                    <div class="container">
                        <div class="agileits_w3layouts_schedule">
                            <h3 class="w3_head">Youvati Offerings - Get In Touch</h3>
                            <img src="images/5_1.png" alt=" " class="img-responsive" />
                        </div>
                        <div class="w3layouts_contact_grid">
                            <p style={{ fontSize: "19px" }}>Youvati Powers Beauty parlour and freelancers to make money.</p>
                            {/* <form action="#" method="post">
					<div class="col-md-6 w3_agileits_contact_grid_left">
						<input type="text" name="Name" placeholder="Name" required="">
						<input type="email" name="Email" placeholder="Email" required="">
						<input type="text" name="Phone" placeholder="Phone" required="">
					</div>
					<div class="col-md-6 w3_agileits_contact_grid_right">
						<textarea name="Message" placeholder="Type your message..." required=""></textarea>
					</div>
					<div class="clearfix"> </div>
					<div class="w3ls_submit">
						<input type="submit" value="Submit">
					</div>
				</form> */}
                            <div class="agileinfo_contact_grid_info">
                                <div class="agileits_footer_nav">
                                    <ul>
                                        <li data-toggle="modal" data-target="#referParlour"><span class="scroll" style={{ cursor: "pointer" }}>Refer Parlours/Freelancers</span><i>-</i></li>
                                        <li data-toggle="modal" data-target="#beATrainer"><span class="scroll" style={{ cursor: "pointer" }}>Be a Beauty Trainer</span><i>-</i></li>
                                        <li data-toggle="modal" data-target="#productDistributor"><span class="scroll" style={{ cursor: "pointer" }}>Be A Beauty Products Distributor</span></li>
                                        {/* <li><a href="#team" class="scroll">Be Beauty Products Distributor</a></li> */}
                                    </ul>
                                </div>
                                <div class="agileits_footer_nav_bottom">
                                    <div class="col-md-4 w3ls_footer_nav_bottom_left">
                                        <h4><i class="fa fa-phone" aria-hidden="true"></i></h4>
                                        <p>+91-9999307595</p>
                                    </div>
                                    <div class="col-md-4 w3ls_footer_nav_bottom_left">
                                        <h4><i class="fa fa-home" aria-hidden="true"></i></h4>
                                        <p>India</p>
                                    </div>
                                    <div class="col-md-4 w3ls_footer_nav_bottom_left">
                                        <h4><i class="fa fa-envelope" aria-hidden="true"></i></h4>
                                        <p><a href="mailto:">info@youvati.com</a></p>
                                    </div>
                                    <div class="clearfix"> </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <h1>{parlourData.plus_code}</h1> */}
                <section class="contact_map_area" style={{ marginTop: "2rem", marginLeft: "1rem", marginBottom: "1rem" }}>
                    {/* <iframe
                    width="100%"
                    height="300"
                    style={{ border: "0" }}
                    // src="https://www.google.com/maps/embed/v1/place?key=AIzaSyAw0My4Arc0Ht1EBA2z_7b07eA00jPs5VU&q=7F2F%2BW6 Bhopal+Madhya+Pradesh+India+Tarasha+beauty+salon+for+ladies" allowfullscreen>
                    src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyAw0My4Arc0Ht1EBA2z_7b07eA00jPs5VU&q=${parlourData.plus_code}`} allowfullscreen>
                </iframe> */}
                </section>
                {/* <section class="contact_map_area" style={{ marginTop: "2rem", marginLeft: "1rem", marginBottom: "1rem" }}> */}

                {/* <div class="mapouter"><div class="gmap_canvas">
                    <iframe width="1200" height="192" id="gmap_canvas" src="https://maps.google.com/maps?q=P894+G8%20Visakhapatnam,%20Andhra%20Pradesh,%20India&t=&z=17&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
                </div></div> */}
                {/* <iframe src={frame} width="1220" height="250" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
                {/* <div class="responsive-map">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2822.7806761080233!2d-93.29138368446431!3d44.96844997909819!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x52b32b6ee2c87c91%3A0xc20dff2748d2bd92!2sWalker+Art+Center!5e0!3m2!1sen!2sus!4v1514524647889" width="100%" height="450" frameborder="0" style={{ border: "0" }}></iframe>
                </div>
            </section> */}


                {/* <section class="world_map_area p_100">
                <div class="container">
                    <div class="world_map_inner">
                        <img class="img-fluid" style={{ maxWidth: "100%", height: "21rem", marginLeft: "10rem", marginTop: "2rem" }} src="images/world-map.png" alt="" />
                        <div class="bd-callout">
                            <a href="https://www.google.com/maps/place/Jayasree+Beauty+Parlour/data=!4m5!3m4!1s0x3a394317bc0a2fa3:0xa69c33419ac1c632!8m2!3d17.7188209!4d83.3057692" target="_blank"><h3 style={{ color: "blue", fontWeight: "bold" }}>View Parlour Location</h3></a>
                            <p>JAIL RD, OPPOSITE NRI JUNIOR COLLEGE, NEHRU NAGAR, RAM NAGAR, VISAKHAPATNAM, ANDHRA PRADESH 530002, INDIA</p> */}
                {/* <h4><a href="#">+91-7856946583</a> <a href="#">info@nemesto.com</a></h4> */}
                {/* </div>
                    </div>
                </div>
            </section> */}
                {/* <div className="container" style={{ padding: "40px", background: "#419be0" }}>
                <Slider {...settings}>
                    <div>
                        <img src="http://placekitten.com/g/400/200" />
                    </div>
                    <div>
                        <img src="http://placekitten.com/g/400/200" />
                    </div>
                    <div>
                        <img src="http://placekitten.com/g/400/200" />
                    </div>
                    <div>
                        <img src="http://placekitten.com/g/400/200" />
                    </div>
                </Slider>
            </div>
             */}
                {/* <div id="testimonials" class="testimonials">
                <div class="container">
                    <div class="agileits_w3layouts_schedule">
                        <h3>Our Testimonials</h3>
                        <img src="images/1_1.png" alt=" " class="img-responsive" />
                    </div>
                    <Slider {...settings}>

                        <div class="w3_agile_team_grids">
                            <section class="slider">
                                <div class="">
                                    <ul class="slides">
                                        <li>
                                            <div class="agile_testimonials_grid">
                                                <div class="agileits_w3layouts_testimonials_grid">
                                                    <img src="images/testimonial.jpg" alt=" " style={{ height: "7rem", width: "7rem", borderRadius: "50%" }} class="img-responsive" />

                                                </div>
                                                <h3>Hema <span>Sen</span></h3>
                                                <p>Easy appointments, welcoming staff, and talented employees! I was thrilled with my eyebrow wax, and bang trim. I will be back for more services!</p>
                                            </div>
                                        </li>

                                    </ul>
                                </div>
                            </section>
                        </div>

                        <div class="w3_agile_team_grids">
                            <section class="slider">
                                <div class="">
                                    <ul class="slides">
                                        <li>
                                            <div class="agile_testimonials_grid">
                                                <div class="agileits_w3layouts_testimonials_grid">
                                                    <img src="images/testimonial.jpg" alt=" " style={{ height: "7rem", width: "7rem", borderRadius: "50%" }} class="img-responsive" />

                                                </div>
                                                <h3>Hema <span>Sen</span></h3>
                                                <p>Easy appointments, welcoming staff, and talented employees! I was thrilled with my eyebrow wax, and bang trim. I will be back for more services!</p>
                                            </div>
                                        </li>

                                    </ul>
                                </div>
                            </section>
                        </div>
                    </Slider>
                </div>
            </div> */}
            </div>
            }
        </>
    );
}

export default Home;
