import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import db from "./Firebase";
import { CSVLink } from "react-csv";
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function LoginLog() {
    const navigate = useNavigate();
    const moment = extendMoment(Moment);
    const [searchQuery, setSearchQuery] = useState('');
    const [loginData, setLoginData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [lastVisible, setLastVisible] = useState(null);
    const [hasMore, setHasMore] = useState(true);

    const headers = [
        { label: "Name", key: "full_name" },
        { label: "Phone No", key: "phone" },
        { label: "Username", key: "username" },
        { label: "Last Checkin", key: "last_checkin", dataFormat: "MM/DD/YY, h:mm a" },
    ];

    useEffect(() => {
        const fetchData = async () => {
            try {
                const loginSnapshot = await db.collection("user_login_logs")
                    .orderBy("last_checkin", "desc")
                    .limit(20)
                    .get();

                const lastVisibleDoc = loginSnapshot.docs[loginSnapshot.docs.length - 1];
                setLastVisible(lastVisibleDoc);

                const personalDetailsSnapshot = await db.collection("personal_details").get();

                const loginData = loginSnapshot.docs.map((loginDoc) => {
                    const loginUserData = loginDoc.data();
                    const correspondingPersonalDetails = personalDetailsSnapshot.docs.find(
                        (personalDetailsDoc) => personalDetailsDoc.id === loginUserData.uid
                    );

                    const name = correspondingPersonalDetails
                        ? correspondingPersonalDetails.data().full_name
                        : "Name not found";
                    const phone = correspondingPersonalDetails
                        ? correspondingPersonalDetails.data().phone_no
                        : "phone not found";

                    const username = correspondingPersonalDetails
                        ? correspondingPersonalDetails.data().username
                        : "phone not found";

                    return {
                        id: loginDoc.id,
                        data: loginUserData,
                        name: name,
                        phone: phone,
                        username: username,
                    };
                });

                setLoginData(loginData);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching data:", error);
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const loadMoreData = async () => {
        try {
            const loginSnapshot = await db.collection("user_login_logs")
                .orderBy("last_checkin", "desc")
                .startAfter(lastVisible)
                .limit(20)
                .get();

            const lastVisibleDoc = loginSnapshot.docs[loginSnapshot.docs.length - 1];
            setLastVisible(lastVisibleDoc);

            if (loginSnapshot.docs.length < 20) {
                setHasMore(false);
            }

            const personalDetailsSnapshot = await db.collection("personal_details").get();

            const additionalLoginData = loginSnapshot.docs.map((loginDoc) => {
                const loginUserData = loginDoc.data();
                const correspondingPersonalDetails = personalDetailsSnapshot.docs.find(
                    (personalDetailsDoc) => personalDetailsDoc.id === loginUserData.uid
                );

                const name = correspondingPersonalDetails
                    ? correspondingPersonalDetails.data().full_name
                    : "Name not found";
                const phone = correspondingPersonalDetails
                    ? correspondingPersonalDetails.data().phone_no
                    : "phone not found";

                const username = correspondingPersonalDetails
                    ? correspondingPersonalDetails.data().username
                    : "phone not found";

                return {
                    id: loginDoc.id,
                    data: loginUserData,
                    name: name,
                    phone: phone,
                    username: username,
                };
            });

            setLoginData(prevData => [...prevData, ...additionalLoginData]);
        } catch (error) {
            console.error("Error fetching more data:", error);
        }
    };

    useEffect(() => {
        // Filter data based on search query and date range
        const filtered = loginData.filter(({ name, phone, username, data }) => {
            const searchLowercase = searchQuery.toLowerCase();
            const checkinDate = moment(data.last_checkin.toDate());
            return (
                (name && name.toLowerCase().includes(searchLowercase)) ||
                (phone && phone.includes(searchQuery)) ||
                (username && username.includes(searchQuery)) ||
                (startDate && endDate && checkinDate.isBetween(startDate, endDate))
            );
        });

        setFilteredData(filtered);
    }, [searchQuery, startDate, endDate, loginData]);

    const handleFilter = async () => {
        try {
            setLoading(true);

            // Get the start and end dates for the filter
            const startDateTimestamp = startDate ? startDate.getTime() : 0;
            const endDateTimestamp = endDate ? endDate.getTime() : Date.now();

            // Retrieve personal details snapshot
            const personalDetailsSnapshot = await db.collection("personal_details").get();

            // Reference to the Firebase Firestore collection
            const loginSnapshot = await db.collection("user_login_logs")
                .where('last_checkin', '>=', new Date(startDateTimestamp))
                .where('last_checkin', '<=', new Date(endDateTimestamp))
                .get();

            const filteredLoginData = loginSnapshot.docs.map((loginDoc) => {
                const loginUserData = loginDoc.data();
                const correspondingPersonalDetails = personalDetailsSnapshot.docs.find(
                    (personalDetailsDoc) => personalDetailsDoc.id === loginUserData.uid
                );

                const name = correspondingPersonalDetails
                    ? correspondingPersonalDetails.data().full_name
                    : "Name not found";
                const phone = correspondingPersonalDetails
                    ? correspondingPersonalDetails.data().phone_no
                    : "phone not found";

                const username = correspondingPersonalDetails
                    ? correspondingPersonalDetails.data().username
                    : "phone not found";

                return {
                    id: loginDoc.id,
                    data: loginUserData,
                    name: name,
                    phone: phone,
                    username: username,
                };
            });

            setLoginData(filteredLoginData);
            setLoading(false);
        } catch (error) {
            console.error("Error fetching data:", error);
            setLoading(false);
        }
    };

    const handleSearch = (event) => {
        setSearchQuery(event.target.value);
    };

    const handleDateChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    };

    const csvData = filteredData.map(({ name, phone, username, data }) => ({
        full_name: name,
        phone: phone,
        username: username,
        last_checkin: moment(data.last_checkin.toDate()).format("MM/DD/YY, h:mm a"),
    }));

    return (
        <>
            <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
                <div className="p-2" style={{ paddingBottom: "5px", display: "flex", justifyContent: "space-between" }}>
                    <Link to="/admin_panel"><img src="/images/menubutton.png" className="menu-toggle" alt="Responsive image" style={{ height: "2rem" }} /></Link>

                    <div style={{ display: "flex", justifyContent: "end" }}>
                        <DatePicker
                            selected={startDate}
                            onChange={handleDateChange}
                            startDate={startDate}
                            endDate={endDate}
                            selectsRange
                            className="form-control form-control-sm"
                        />
                        <span className="btn bg-gradient-secondary btn-sm mb-0 mr-2" style={{ color: "white", width: "12rem", marginLeft: "2rem", height: "2rem" }} onClick={handleFilter} >&nbsp;Filter</span>

                        <CSVLink data={csvData} headers={headers} filename={"login_logs.csv"}>
                            <span className="btn bg-gradient-secondary btn-sm mb-0 mr-2" style={{ color: "white" }} >&nbsp; Download Excel</span>
                        </CSVLink>
                    </div>
                </div>
                <div className="">
                    <div className="p-2">
                        <div className="card">
                            <div className="card-header pb-0 px-3">
                                <h6 className="mb-2">Login Logs</h6>
                            </div>
                            <input
                                type="text"
                                className="form-control form-control-sm"
                                placeholder="Search by name"
                                value={searchQuery}
                                onChange={handleSearch}
                            />
                            <div className="">
                                <div className="card">
                                    <div className="table-responsive fix-width scroll-inner">
                                        <table className="table table-bordered table-hover">
                                            <thead className="thead-dark" style={{ position: "sticky", top: "0" }}>
                                                <tr style={{ textAlign: "left" }}>
                                                    <th className=" ps-2">Full Name</th>
                                                    <th className="">Phone</th>
                                                    <th className=" ps-2">Username</th>
                                                    <th className="">Last Checkin</th>
                                                </tr>
                                            </thead>
                                            <tbody style={{ textAlign: "left" }}>
                                                {filteredData.map(({ id, name, phone, username, data }, index) => (
                                                    <tr key={id}>
                                                        <td>
                                                            <p className="text-xs font-weight-bold mb-0">{name}</p>
                                                        </td>
                                                        <td>
                                                            <div className="d-flex px-2 py-1">
                                                                <div className="d-flex flex-column justify-content-center">
                                                                    <h6 className="mb-0 text-xs">{phone}</h6>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="d-flex px-2 py-1">
                                                                <div className="d-flex flex-column justify-content-center">
                                                                    <h6 className="mb-0 text-xs">{username}</h6>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="d-flex px-2 py-1">
                                                                <div className="d-flex flex-column justify-content-center">
                                                                    <h6 className="mb-0 text-xs">{moment(data.last_checkin.toDate().toISOString()).format("MM/DD/YY, h:mm a")}</h6>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                        {hasMore && (
                                            <div className="" style={{ paddingTop: "2rem" }}>
                                                <button onClick={loadMoreData} className="btn btn-dark">Load More</button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
}

export default LoginLog;


// import React, { useState, useEffect } from 'react';
// import { useNavigate, Link } from 'react-router-dom';
// import db from "./Firebase";
// import { CSVLink } from "react-csv";
// import Moment from 'moment';
// import { extendMoment } from 'moment-range';
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";

// function LoginLog() {
//     const navigate = useNavigate();
//     const moment = extendMoment(Moment);
//     const [searchQuery, setSearchQuery] = useState('');
//     const [loginData, setLoginData] = useState([]);
//     const [filteredData, setFilteredData] = useState([]);
//     const [loading, setLoading] = useState(true);
//     const [startDate, setStartDate] = useState(null);
//     const [endDate, setEndDate] = useState(null);

//     const headers = [
//         { label: "Name", key: "full_name" },
//         { label: "Phone No", key: "phone" },
//         { label: "Username", key: "username" },
//         { label: "Last Checkin", key: "last_checkin", dataFormat: "MM/DD/YY, h:mm a" },
//     ];

//     useEffect(() => {
//         const fetchData = async () => {
//             try {
//                 const loginSnapshot = await db.collection("user_login_logs").get();
//                 const personalDetailsSnapshot = await db.collection("personal_details").get();

//                 const loginData = loginSnapshot.docs.map((loginDoc) => {
//                     const loginUserData = loginDoc.data();
//                     const correspondingPersonalDetails = personalDetailsSnapshot.docs.find(
//                         (personalDetailsDoc) => personalDetailsDoc.id === loginUserData.uid
//                     );

//                     const name = correspondingPersonalDetails
//                         ? correspondingPersonalDetails.data().full_name
//                         : "Name not found";
//                     const phone = correspondingPersonalDetails
//                         ? correspondingPersonalDetails.data().phone_no
//                         : "phone not found";

//                     const username = correspondingPersonalDetails
//                         ? correspondingPersonalDetails.data().username
//                         : "phone not found";

//                     return {
//                         id: loginDoc.id,
//                         data: loginUserData,
//                         name: name,
//                         phone: phone,
//                         username: username,
//                     };
//                 });

//                 setLoginData(loginData);
//                 setLoading(false);
//             } catch (error) {
//                 console.error("Error fetching data:", error);
//                 setLoading(false);
//             }
//         };

//         fetchData();
//     }, []);

//     useEffect(() => {
//         // Filter data based on search query and date range
//         const filtered = loginData.filter(({ name, phone, username, data }) => {
//             const searchLowercase = searchQuery.toLowerCase();
//             const checkinDate = moment(data.last_checkin.toDate());
//             return (
//                 (name && name.toLowerCase().includes(searchLowercase)) ||
//                 (phone && phone.includes(searchQuery)) ||
//                 (username && username.includes(searchQuery)) ||
//                 (startDate && endDate && checkinDate.isBetween(startDate, endDate))
//             );
//         });

//         setFilteredData(filtered);
//     }, [searchQuery, startDate, endDate, loginData]);

//     const handleFilter = async () => {
//         console.log(startDate)
//         console.log(endDate)
//         try {
//             setLoading(true);

//             // Get the start and end dates for the filter
//             const startDateTimestamp = startDate ? startDate.getTime() : 0;
//             const endDateTimestamp = endDate ? endDate.getTime() : Date.now();

//             // Retrieve personal details snapshot
//             const personalDetailsSnapshot = await db.collection("personal_details").get();

//             // Reference to the Firebase Firestore collection
//             const loginSnapshot = await db.collection("user_login_logs")
//                 .where('last_checkin', '>=', new Date(startDateTimestamp))
//                 .where('last_checkin', '<=', new Date(endDateTimestamp))
//                 .get();



//             const filteredLoginData = loginSnapshot.docs.map((loginDoc) => {
//                 const loginUserData = loginDoc.data();
//                 // You can format the data as needed here
//                 const correspondingPersonalDetails = personalDetailsSnapshot.docs.find(
//                     (personalDetailsDoc) => personalDetailsDoc.id === loginUserData.uid
//                 );

//                 const name = correspondingPersonalDetails
//                     ? correspondingPersonalDetails.data().full_name
//                     : "Name not found";
//                 const phone = correspondingPersonalDetails
//                     ? correspondingPersonalDetails.data().phone_no
//                     : "phone not found";

//                 const username = correspondingPersonalDetails
//                     ? correspondingPersonalDetails.data().username
//                     : "phone not found";

//                 return {
//                     id: loginDoc.id,
//                     data: loginUserData,
//                     name: name,
//                     phone: phone,
//                     username: username,
//                     // id: loginDoc.id,
//                     // data: loginUserData,
//                     // // Add other fields as needed
//                 };
//             });

//             setLoginData(filteredLoginData);
//             console.log(filteredLoginData) // this data is coming properly
//             setLoading(false);
//         } catch (error) {
//             console.error("Error fetching data:", error);
//             setLoading(false);
//         }
//     };

//     const handleSearch = (event) => {
//         setSearchQuery(event.target.value);
//     };

//     const handleDateChange = (dates) => {
//         const [start, end] = dates;
//         setStartDate(start);
//         setEndDate(end);
//     };

//     const csvData = filteredData.map(({ name, phone, username, data }) => ({
//         full_name: name,
//         phone: phone,
//         username: username,
//         last_checkin: moment(data.last_checkin.toDate()).format("MM/DD/YY, h:mm a"),
//     }));

//     return (
//         <>
//             <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
//                 <div class="p-2" style={{ paddingBottom: "5px", display: "flex", justifyContent: "space-between" }}>
//                     <Link to="/admin_panel"><img src="/images/menubutton.png" class="menu-toggle" alt="Responsive image" style={{ height: "2rem" }} /></Link>

//                     <div style={{ display: "flex", justifyContent: "end" }}>
//                         <DatePicker
//                             selected={startDate}
//                             onChange={handleDateChange}
//                             startDate={startDate}
//                             endDate={endDate}
//                             selectsRange
//                             className="form-control form-control-sm"
//                         />
//                         <span class="btn bg-gradient-secondary btn-sm mb-0 mr-2" style={{ color: "white", width: "12rem", marginLeft: "2rem", height: "2rem" }} onClick={handleFilter} >&nbsp;Filter</span>

//                         <CSVLink data={csvData} headers={headers} filename={"login_logs.csv"}>
//                             <span class="btn bg-gradient-secondary btn-sm mb-0 mr-2" style={{ color: "white" }} >&nbsp; Download Excel</span>
//                         </CSVLink>
//                     </div>

//                     {/* <button ></button> */}
//                     {/* <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} /> */}
//                     {/* <DatePicker
//                         selected={startDate}
//                         onChange={handleDateChange}
//                         startDate={startDate}
//                         endDate={endDate}
//                         selectsRange
//                     /> */}
//                     {/* <DatePicker
//                         selected={startDate}
//                         onChange={handleDateChange}
//                         startDate={startDate}
//                         endDate={endDate}
//                         selectsRange
//                         inline
//                     />
//                     <button onClick={handleFilter}>Apply Filter</button> */}
//                 </div>
//                 <div className="">
//                     <div className="p-2">
//                         <div className="card">
//                             <div className="card-header pb-0 px-3">
//                                 <h6 className="mb-2">Login Logs</h6>

//                             </div>
//                             <input
//                                 type="text"
//                                 className="form-control form-control-sm"
//                                 placeholder="Search by name"
//                                 value={searchQuery}
//                                 onChange={handleSearch}
//                             />

//                             <div className="">
//                                 <div className="card">
//                                     <div className="table-responsive fix-width scroll-inner">
//                                         <table className="table table-bordered table-hover">
//                                             <thead className="thead-dark" style={{ position: "sticky", top: "0" }}>
//                                                 <tr style={{ textAlign: "left" }}>
//                                                     <th className=" ps-2">Full Name</th>
//                                                     <th className="">Phone</th>
//                                                     <th className=" ps-2">Username</th>
//                                                     <th className="">Last Checkin</th>
//                                                 </tr>
//                                             </thead>
//                                             <tbody style={{ textAlign: "left" }}>
//                                                 {filteredData.map(({ id, name, phone, username, data }, index) => (
//                                                     <tr key={id}>
//                                                         <td>
//                                                             <p className="text-xs font-weight-bold mb-0">{name}</p>
//                                                         </td>
//                                                         <td>
//                                                             <div className="d-flex px-2 py-1">
//                                                                 <div className="d-flex flex-column justify-content-center">
//                                                                     <h6 className="mb-0 text-xs">{phone}</h6>
//                                                                 </div>
//                                                             </div>
//                                                         </td>
//                                                         <td>
//                                                             <div className="d-flex px-2 py-1">
//                                                                 <div className="d-flex flex-column justify-content-center">
//                                                                     <h6 className="mb-0 text-xs">{username}</h6>
//                                                                 </div>
//                                                             </div>
//                                                         </td>
//                                                         <td>
//                                                             <div className="d-flex px-2 py-1">
//                                                                 <div className="d-flex flex-column justify-content-center">
//                                                                     <h6 className="mb-0 text-xs">{moment(data.last_checkin.toDate().toISOString()).format("MM/DD/YY, h:mm a")}</h6>
//                                                                 </div>
//                                                             </div>
//                                                         </td>
//                                                     </tr>
//                                                 ))}
//                                             </tbody>
//                                         </table>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </main>
//         </>
//     )
// }

// export default LoginLog;




// import React, { useState, useEffect } from 'react';
// import { useNavigate, Link } from 'react-router-dom';
// import db from "./Firebase";
// import { CSVLink } from "react-csv";
// import Moment from 'moment';
// import { extendMoment } from 'moment-range';

// function LoginLog() {
//     const navigate = useNavigate();
//     const moment = extendMoment(Moment);
//     const [mergedData, setMergedData] = useState([]);
//     const [searchQuery, setSearchQuery] = useState('');
//     const [loginData, setLoginData] = useState([]);
//     const [loading, setLoading] = useState(true);

//     const headers = [
//         { label: "Name", key: "full_name" },
//         { label: "Phone No", key: "phone" },
//         { label: "Username", key: "username" },
//         { label: "Last Checkin", key: "last_checkin", dataFormat: "MM/DD/YY, h:mm a" },
//     ];

//     useEffect(() => {
//         const fetchData = async () => {
//             try {
//                 const loginSnapshot = await db.collection("user_login_logs").get();
//                 const personalDetailsSnapshot = await db.collection("personal_details").get();

//                 const loginData = loginSnapshot.docs.map((loginDoc) => {
//                     const loginUserData = loginDoc.data();
//                     const correspondingPersonalDetails = personalDetailsSnapshot.docs.find(
//                         (personalDetailsDoc) => personalDetailsDoc.id === loginUserData.uid
//                     );

//                     const name = correspondingPersonalDetails
//                         ? correspondingPersonalDetails.data().full_name
//                         : "Name not found";
//                     const phone = correspondingPersonalDetails
//                         ? correspondingPersonalDetails.data().phone_no
//                         : "phone not found";

//                     const username = correspondingPersonalDetails
//                         ? correspondingPersonalDetails.data().username
//                         : "phone not found";

//                     return {
//                         id: loginDoc.id,
//                         data: loginUserData,
//                         name: name,
//                         phone: phone,
//                         username: username,
//                     };
//                 });

//                 setLoginData(loginData);
//                 setLoading(false);
//             } catch (error) {
//                 console.error("Error fetching data:", error);
//                 setLoading(false);
//             }
//         };

//         fetchData();
//     }, []);

//     const handleSearch = (event) => {
//         setSearchQuery(event.target.value);
//     };

//     const filteredData = loginData.filter(({ name, phone, username }) => {
//         const searchLowercase = searchQuery.toLowerCase();
//         return (
//             name && name.toLowerCase().includes(searchLowercase) ||
//             phone && phone.includes(searchQuery) ||
//             username && username.includes(searchQuery)
//         );
//     });

//     const csvData = filteredData.map(({ name, phone, username, data }) => ({
//         full_name: name,
//         phone: phone,
//         username: username,
//         last_checkin: moment(data.last_checkin.toDate()).format("MM/DD/YY, h:mm a"),
//     }));

//     return (
//         <>
//             <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
//                 <div class="p-2" style={{ paddingBottom: "5px", display: "flex", justifyContent: "space-between" }}>
//                     {/* <span class="btn bg-gradient-secondary btn-sm mb-0 mr-2" style={{ color: "white" }} >&nbsp; Download Excel</span> */}
//                     <Link to="/admin_panel"><img src="/images/menubutton.png" class="menu-toggle" alt="Responsive image" style={{ height: "2rem" }} /></Link>
//                     <CSVLink data={csvData} headers={headers} filename={"login_logs.csv"}>
//                         <span class="btn bg-gradient-secondary btn-sm mb-0 mr-2" style={{ color: "white" }} >&nbsp; Download Excel</span>
//                     </CSVLink>
//                 </div>
//                 <DatePicker
//                     selected={startDate}
//                     onChange={onChange}
//                     startDate={startDate}
//                     endDate={endDate}
//                     selectsRange
//                     inline
//                 />
//                 <div className="">
//                     <div className="p-2">

//                         <div className="card">
//                             <div className="card-header pb-0 px-3">
//                                 <h6 className="mb-2">Login Logs</h6>
//                             </div>
//                             <input
//                                 type="text"
//                                 className="form-control form-control-sm"
//                                 placeholder="Search by name"
//                                 value={searchQuery}
//                                 onChange={handleSearch}
//                             />
//                             <div className="">
//                                 <div className="card">
//                                     <div className="table-responsive fix-width scroll-inner">
//                                         <table className="table table-bordered table-hover">
//                                             <thead className="thead-dark" style={{ position: "sticky", top: "0" }}>
//                                                 <tr style={{ textAlign: "left" }}>
//                                                     <th className=" ps-2">Full Name</th>
//                                                     <th className="">Phone</th>
//                                                     <th className=" ps-2">Username</th>
//                                                     <th className="">Last Checkin</th>
//                                                 </tr>
//                                             </thead>
//                                             <tbody style={{ textAlign: "left" }}>

//                                                 {filteredData.map(({ id, name, phone, username, data }, index) => (
//                                                     <tr key={id}>
//                                                         <td>
//                                                             <p className="text-xs font-weight-bold mb-0">{name}</p>
//                                                         </td>
//                                                         <td>
//                                                             <div className="d-flex px-2 py-1">
//                                                                 <div className="d-flex flex-column justify-content-center">
//                                                                     <h6 className="mb-0 text-xs">{phone}</h6>
//                                                                 </div>
//                                                             </div>
//                                                         </td>
//                                                         <td>
//                                                             <div className="d-flex px-2 py-1">
//                                                                 <div className="d-flex flex-column justify-content-center">
//                                                                     <h6 className="mb-0 text-xs">{username}</h6>
//                                                                 </div>
//                                                             </div>
//                                                         </td>
//                                                         <td>
//                                                             <div className="d-flex px-2 py-1">
//                                                                 <div className="d-flex flex-column justify-content-center">
//                                                                     <h6 className="mb-0 text-xs">{moment(data.last_checkin.toDate().toISOString()).format("MM/DD/YY, h:mm a")}</h6>
//                                                                 </div>
//                                                             </div>
//                                                         </td>
//                                                     </tr>
//                                                 ))}


//                                             </tbody>
//                                         </table>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </main>
//         </>
//     )
// }

// export default LoginLog;
