import React, { useState, useEffect } from "react";
import Swal from 'sweetalert2'
import db from "./Firebase";
import { storage } from './Firebase';
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";

function EditConsumables() {


    const initialFormStateFeedback = { type: '', product_name: '', brand_name: '', quantity: '', itemcount: '', price: '', seller: '', authorization_code: '', image_url: '', updated_date: new Date() }
    const [submit, setSubmit] = useState(initialFormStateFeedback)
    const [data, setData] = useState([]);
    const [ShowProgress, setShowProgress] = useState(false);
    const [show, setshow] = useState(true);
    const [progresspercent, setProgresspercent] = useState(0);

    const [editID, setEditID] = useState("");

    const handleChange = (e) => {
        const { id, value } = e.target
        setSubmit({ ...submit, [id]: value })
    }

    const reset = () => {
        setSubmit({ type: '', product_name: '', brand_name: '', quantity: '', image_url: '', itemcount: '', price: '', seller: '', authorization_code: '', created_date: new Date() })
    }



    const openModalNew = () => {
        window.openModelNew();
    }

    const openModalShow = () => {
        window.openModelshow();
    }

    useEffect(() => {
        db.collection("consumables_data").onSnapshot((snapshot) => {

            setData(
                snapshot.docs.map((doc) => ({
                    id: doc.id,
                    data: doc.data(),
                }))
            );
        });

    }, []);


    const ids = ["rahul_122002", "ashutosh_560001", "mrinal_122004"];

    const handleSubmit = (e) => {
        e.preventDefault()
        if (ids.includes(submit.authorization_code)) {
            if (e.target[0]?.files[0] == null) {
                db.collection("consumables_data").doc(editID).update({
                    'type': submit.type,
                    'product_name': submit.product_name,
                    'brand_name': submit.brand_name,
                    'quantity': submit.quantity,
                    'itemcount': 0,
                    'price': submit.price,
                    'seller': submit.seller,
                    'image_url': submit.image_url,
                    'created_date': new Date(),
                }).then(() => {
                    console.log('Submitted Successfully');
                    const Toast = Swal.mixin({
                        toast: true,
                        background: '#69aba6',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', Swal.stopTimer)
                            toast.addEventListener('mouseleave', Swal.resumeTimer)
                        }
                    })

                    Toast.fire({
                        icon: 'success',
                        title: 'Submitted Successfully'
                    })
                    openModalNew()
                    setshow(true)
                    // navigate(`/${submit.username}`);
                    setSubmit({ type: '', product_name: '', brand_name: '', quantity: '', itemcount: '', price: '', seller: '', authorization_code: '', created_date: new Date() })
                })
            } else {
                const file = e.target[0]?.files[0]
                // console.log(file)
                if (!file) return;
                const storageRef = ref(storage, `files/${file.name}`);
                const uploadTask = uploadBytesResumable(storageRef, file);
                setshow(false)

                uploadTask.on("state_changed",
                    (snapshot) => {
                        const progress =
                            Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                        setProgresspercent(progress);
                    },
                    (error) => {
                        alert(error);
                    },
                    () => {

                        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                            // setImgUrl(downloadURL)
                            db.collection("consumables_data").doc(editID).update({
                                'type': submit.type,
                                'product_name': submit.product_name,
                                'brand_name': submit.brand_name,
                                'quantity': submit.quantity,
                                'itemcount': 0,
                                'price': submit.price,
                                'seller': submit.seller,
                                'image_url': downloadURL,
                                'created_date': new Date(),
                            }).then(() => {
                                console.log('Submitted Successfully');
                                const Toast = Swal.mixin({
                                    toast: true,
                                    background: '#69aba6',
                                    position: 'top-end',
                                    showConfirmButton: false,
                                    timer: 3000,
                                    timerProgressBar: true,
                                    didOpen: (toast) => {
                                        toast.addEventListener('mouseenter', Swal.stopTimer)
                                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                                    }
                                })

                                Toast.fire({
                                    icon: 'success',
                                    title: 'Submitted Successfully'
                                })
                                openModalNew()
                                setshow(true)
                                // navigate(`/${submit.username}`);
                                setSubmit({ type: '', product_name: '', brand_name: '', quantity: '', itemcount: '', price: '', seller: '', authorization_code: '', created_date: new Date() })
                            })
                        });
                    }
                );
            }
        } else {
            alert("You are not authorization to perform this action")
        }

    }

    const edit = (data, id) => {
        setEditID(id)
        setSubmit({ image_url: data.image_url, type: data.type, product_name: data.product_name, brand_name: data.brand_name, quantity: data.quantity, itemcount: 0, price: data.price, seller: data.seller })
        openModalShow();
    }

    return (
        <>
            {/* <h2>Responsive Table</h2> */}
            <div class="table-wrapper">
                <table class="fl-table">
                    <thead>
                        <tr>
                            <th>Sr. No</th>
                            <th>Image</th>
                            <th>Product Name</th>
                            <th>Brand Name</th>
                            <th>Price</th>
                            <th>Type</th>
                            <th>Quantity</th>
                            <th>Seller</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data?.map(({ id, data }, index) => (
                            <>
                                <tr>
                                    <td>{index + 1}</td>
                                    <td><img src={data.image_url} alt="consumables" width="100" /></td>
                                    <td>{data.product_name}</td>
                                    <td>{data.brand_name}</td>
                                    <td>{data.price}</td>
                                    <td>{data.type}</td>
                                    <td>{data.quantity}</td>
                                    <td>{data.seller}</td>
                                    <td><button onClick={() => edit(data, id)} type="button" style={{ background: "#007bff" }} class="btn btn-primary">Edit</button></td>
                                    {/* <td><button onClick={() => openModalNew()} type="button" style={{ background: "#007bff" }} class="btn btn-primary">Edit</button></td> */}
                                </tr>
                            </>
                        ))}


                    </tbody>
                </table>
            </div>

            <div class="myId modal fade bd-example-modal-lg" id="referParlour" tabindex="-1" role="dialog" aria-labelledby="referParlourTitle" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div class="modal-content">

                        <div class="modal-body">
                            <>
                                <div id="small-dialog" class="">
                                    <div class="w3layouts_payment_online_form">
                                        <form class="" id="" onSubmit={handleSubmit}>
                                            <h4>Upload Consumables</h4>
                                            <ul>
                                                <li style={{ textAlign: "left" }}><label for="myfile">Product Image:</label><input type="file" id="image" name="Image" accept="image/*" />
                                                </li>
                                                <li><input class="w3l_text_box" id="product_name" onChange={handleChange} value={submit.product_name} placeholder="Product Name" name="Product Name" type="text" required />
                                                </li>
                                            </ul>
                                            <ul>
                                                <li>
                                                    {/* <label>Type</label> */}
                                                    <select class="form-control" id="type" onChange={handleChange} value={submit.type} required>
                                                        <option value="">--Select Product type--</option>
                                                        <option value="Skin">Skin</option>
                                                        <option value="Hair">Hair</option>
                                                        <option value="Make-up">Make-up</option>
                                                        <option value="Nails">Nails</option>
                                                    </select>
                                                </li>
                                                <li>
                                                    <input class="w3l_text_box" id="brand_name" placeholder="brand_name" onChange={handleChange} value={submit.brand_name} name="Brand Name" type="text" required="" />
                                                </li>
                                            </ul>

                                            <ul>
                                                <li>
                                                    <input class="w3l_text_box" id="quantity" placeholder="Quantity" onChange={handleChange} value={submit.quantity} name="Quantity" type="text" required="" />
                                                </li>
                                                <li>
                                                    <input class="w3l_text_box" id="price" placeholder="Price" onChange={handleChange} value={submit.price} name="Price" type="number" required="" />
                                                </li>
                                            </ul>
                                            <ul>
                                                <li>
                                                    <input class="w3l_text_box" id="seller" placeholder="Seller" onChange={handleChange} value={submit.seller} name="Seller Name" type="text" required="" />
                                                </li>
                                                <li>
                                                    <input class="w3l_text_box" id="authorization_code" placeholder="Authorization Code" onChange={handleChange} value={submit.authorization_code} name="Authorization Code" type="text" required />
                                                </li>
                                            </ul>

                                            <ul class="w3_payment_sendbtns">
                                                <li><input type="reset" data-dismiss="modal" aria-label="Close" onClick={reset} value="Cancel" /></li>
                                                <li>{show ? <input type="submit" value="Submit" /> : "Please Wait..."}</li>
                                            </ul>

                                            {/* <ul>
                                                <li><input class="w3l_text_box" id="full_name" onChange={handleChangeRefer} value={submitRefer.full_name} placeholder="Full Name" name="Full Name" type="text" required />
                                                </li>
                                                <li><input class="w3l_text_box" id="phone_no" onChange={handleChangeRefer} value={submitRefer.phone_no} placeholder="10 Digit Phone Number" name="Phone" type="tel" minlength="10" maxlength="10" required />
                                                </li>
                                            </ul>
                                            <ul>
                                                <li><input class="w3l_text_box" id="email" onChange={handleChangeRefer} value={submitRefer.email} placeholder="Email" name="Email" type="email" required="" /></li>
                                                <li>
                                                </li>
                                            </ul>

                                            <ul class="w3_payment_sendbtns">
                                                <li><input type="reset" data-dismiss="modal" aria-label="Close" onClick={clearForm} value="Cancel" /></li>
                                                <li><input type="submit" id="btnSave" value="Submit" /></li>
                                            </ul> */}
                                            <div class="clearfix"> </div>
                                        </form>
                                    </div>
                                </div>
                            </>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EditConsumables
