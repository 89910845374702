import React, { useState, useEffect } from 'react'
import DataTable from 'react-data-table-component';
import db from "./Firebase";
import moment from 'moment';
import Swal from 'sweetalert2'

function FilterTesting() {
    const initialFormState = { pincode: '' }
    const [submit, setSubmit] = useState(initialFormState)
    const handleChange = (e) => {
        const { id, value } = e.target
        setSubmit({ ...submit, [id]: value })
    }

    const [Show, setShow] = useState(true)
    const [dataLength, setDataLength] = useState(0)

    const [query, setQuery] = useState([])
    const searchByPincode = () => {
        console.log(submit.pincode)
        // db.collection("unregistered_users").where('place_best_beauty_parlour_in', '>=', submit.pincode).where('place_best_beauty_parlour_in', '<=', submit.pincode + '~')
        db.collection("personal_details").where("full_name", '>=', submit.pincode).where("full_name", '<=', submit.pincode + "z")
            // db.collection("unregistered_users").where('pincode', '>=', submit.pincode).where('pincode', '<=', submit.pincode + '\uf8ff')
            .onSnapshot((snapshot) => {
                console.log(snapshot.docs.length)
                setDataLength(snapshot.docs.length)
                snapshot.docs.map((doc, index) => {
                    // console.log()
                    console.log("parlour_name", doc.data().full_name);
                    // console.log("parlour_name", doc.data().phone_no);
                    // console.log("timeeeeeeeeeeee", doc.data().timestamp)
                })

            })
    }




    useEffect(() => {

    }, []);
    return (
        <>

            {/* <h1 style={{ textAlign: "center", textDecoration: "underline" }}>Unregistered User List</h1> */}
            <div class="container">
                <div class="row" style={{ marginTop: "2rem" }}>
                    <div class="col-2"></div>
                    <div class="col-8"><input type="text" required class="form-control" onChange={handleChange} value={submit.parlour_name} id="pincode" placeholder="Search by pincode" /></div>
                    <div class="col-2"><button style={{ background: "blue" }} type="button" class="btn btn-primary" onClick={searchByPincode}>Search</button></div>
                </div>
            </div>
            <div>
                <h1 style={{ fontSize: "larger", fontWeight: "600", color: "red", padding: "1rem" }}>Total Records Found : <spa>{dataLength}</spa></h1>
            </div>
            <div>

            </div>


        </>

    )
}

export default FilterTesting;