import React from 'react'
import { useNavigate, Link, useParams } from 'react-router-dom';

function Certificate() {
    let params = useParams();

    const exportPdf = (selector) => {
        window.getPDF(selector)
    }


    return (
        <>
            <div className="">
                <div className="row justify-content-center">
                    <div className="col-lg-8 col-md-10 col-sm-12">
                        <div className="scrollable-container">
                            <div class="row" style={{ display: "flex", justifyContent: "center" }}>

                                <div class="pm-certificate-container d-flex flex-column-fluid size-a4 pdf-page" id="example">
                                    <div class="outer-border"></div>
                                    <div class="inner-border"></div>

                                    <div class="pm-certificate-border col-xs-12">
                                        <div class="row pm-certificate-header">
                                            <div class="pm-certificate-title cursive col-xs-12 text-center">
                                                <h2>Certificate of Completion</h2>
                                            </div>
                                        </div>

                                        <div class="pm-certificate-body">

                                            <div class="pm-certificate-block">
                                                <div class="col-xs-12">
                                                    <div class="row">
                                                        <div class="col-xs-2"></div>
                                                        <div class="pm-certificate-name underline margin-0 col-xs-8 text-center">
                                                            <span class="pm-name-text bold">{params.id}</span>
                                                        </div>
                                                        <div class="col-xs-2"></div>
                                                    </div>
                                                </div>

                                                <div class="col-xs-12">
                                                    <div class="row">
                                                        <div class="col-xs-2"></div>
                                                        <div class="pm-earned col-xs-8 text-center">
                                                            <span class="pm-earned-text padding-0 block cursive">{params.id} has earned</span>
                                                            <span class="pm-credits-text block bold sans">PD175: 1.0 Credit Hours</span>
                                                        </div>
                                                        <div class="col-xs-2"></div>
                                                        <div class="col-xs-12"></div>
                                                    </div>
                                                </div>

                                                <div class="col-xs-12">
                                                    <div class="row">
                                                        <div class="col-xs-2"></div>
                                                        <div class="pm-course-title col-xs-8 text-center">
                                                            <span class="pm-earned-text block cursive">while completing the training course entitled</span>
                                                        </div>
                                                        <div class="col-xs-2"></div>
                                                    </div>
                                                </div>

                                                <div class="col-xs-12">
                                                    <div class="row">
                                                        <div class="col-xs-2"></div>
                                                        <div class="pm-course-title underline col-xs-8 text-center">
                                                            <span class="pm-credits-text block bold sans">BPS PGS Initial PLO for Principals at Cluster Meetings</span>
                                                        </div>
                                                        <div class="col-xs-2"></div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-xs-12">
                                                <div class="row">
                                                    <div class="pm-certificate-footer">
                                                        <div class="col-xs-4 pm-certified col-xs-4 text-center">
                                                            <span class="pm-credits-text block sans">City School District</span>
                                                            <span class="pm-empty-space block underline"></span>
                                                            <span class="bold block">Crystal Benton Instructional Specialist II, Staff Development</span>
                                                        </div>
                                                        <div class="col-xs-4">

                                                        </div>
                                                        <div class="col-xs-4 pm-certified col-xs-4 text-center">
                                                            <span class="pm-credits-text block sans">Date Completed</span>
                                                            <span class="pm-empty-space block underline"></span>
                                                            <span class="bold block">DOB: </span>
                                                            <span class="bold block">Social Security # (last 4 digits)</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="" style={{ display: "flex", justifyContent: "center", padding: "1rem" }}>
                <button type="button" class="btn btn-primary font-weight-bold" onClick={() => exportPdf('.pdf-page')}>Download Certificate</button>
            </div>
        </>
    )
}

export default Certificate