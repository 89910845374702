import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import "./style.css";
import { useNavigate, Link, useParams } from "react-router-dom"
import Select from 'react-select';
import NumberCounter from 'number-counter';
import FooterMain from "./FooterMain";

export const Outfit = () => {
    const [count, setCount] = useState(0);
    useEffect(() => {
        window.scrollTo(0, 0);
        for (let count = 0; count <= 500; count++) {
            setTimeout(() => {
                setCount(count)
            }, 1000);
        }
    }, []);

    const [selectedImage, setSelectedImage] = useState(null);


    const tipsImages = [
        { title: "", subtitle: "Punjabi", image_url: "/outfit/Rectangle 177.png", description: "If you are a bride from Punjab it would be best to dress up in red or other bright shades. This is to be paired with the traditional chudha and dangling kalire. You can complete your outfit with small accessories of your choice." },
        { title: "", subtitle: "Kashmiri", image_url: "/outfit/Rectangle 178.png", description: " As a Kashmiri bride, ace the outfit game by dressing up in a heavy lehenga of a vibrant color such as red, gold, or green- colors of prosperity and happiness. " },
        { title: "", subtitle: "Tamil", image_url: "/outfit/Rectangle 179.png", description: "An exquisite Kanjeevaram sari is the way to go if you are from Tamil Nadu. The Madisar style of sari draping would be recommended. Embrace pure grace by flaunting your sari which showcases varied color combinations and a thick golden border. Apart from the heavy jewelry in use, Make sure to decorate your hair with flowers- nobody ever complained about elegance with a tinge of fragrance." },
        { title: "", subtitle: "Rajasthan", image_url: "/outfit/Rectangle 180.png", description: "An your wedding day, a heavy ghagra choli decked with zari work comes second to no outfit if you are a Rajasthani bride. Complete your outfit with a wide array of jewelry, and shine throughout your big day." },
        { title: "", subtitle: "Bengali", image_url: "/outfit/Rectangle 181.png", description: "If you are a Bengali bride, a red or maroon sari laden with intricate zari work would be quite the eyecatcher. Your hands and feet are to be decorated with Mahawar, and red and white dots that will extend from your forehead to chin. A white headgear will become the finishing touch in your stunning outfit." },
        { title: "", subtitle: "Maharashtrian", image_url: "/outfit/Rectangle 182.png", description: "The most unique element of your outfit as a Maharashtrian bride is the Mundavalya (stringed pearls tied horizontally across the forehead)- something that will be common to the outfits of both your groom and yourself. Do not forget to pair this with bangles that are red and green in color." },
        { title: "", subtitle: "Parsi", image_url: "/outfit/Rectangle 183.png", description: "The key to looking your best as a Parsi bride is to follow the “less is more” approach. A simple white/ cream coloured silk or chiffon Gara would work like magic when paired with minimalistic jewelry such as a necklace and bangles." },

    ]
    const handleImageClick = (index) => {
        setSelectedImage(index);
    };


    const leftPartStyle = {
        flex: 1,
        border: "none",
        boxSizing: "border-box",
        // backgroundColor: "#7d6853",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    };

    return (
        <div>
            <section>
                <div class="navi" style={{ backgroundColor: "#ec5d69" }}>
                    <input type="checkbox" id="nav-check" />
                    <div class="nav-header">
                        <div class="nav-title">
                            <Link to="/"> <img src="/bridal/youvati_white_logo.png" alt="" style={{ height: "22px", marginLeft: "1rem" }} /></Link>
                        </div>
                    </div>
                    <div class="nav-btn">
                        <label for="nav-check">
                            <span></span>
                            <span></span>
                            <span></span>
                        </label>
                    </div>

                    <div class="nav-links" id="top">
                        <a href="#portfolio" class="linkcolor" style={{ color: "white" }}>Bridal</a>
                        <span class="line" style={{ color: "white" }}>|</span>
                        <a href="#portfolio" class="linkcolor" style={{ color: "white" }}>Why Choose Us</a>
                        <span class="line" style={{ color: "white" }}>|</span>
                        <a href="#partners" class="linkcolor" style={{ color: "white" }}>For Partners</a>

                        <span class="line" style={{ color: "white" }}>|</span>
                        <a href="#reachtous" class="linkcolor" style={{ color: "white" }}>Contact Us</a>

                    </div>
                </div>
            </section>


            {/* <div class="" style={{ marginLeft: "120px", marginRight: "120px" }}> */}
            {/* <div style={{ textAlign: "center" }}>
                <h2 style={{ fontSize: "34px", fontWeight: "700", paddingLeft: "70px", marginTop: "1.5rem", color: "#DE5A59", fontFamily: "serif" }}>Outfit</h2>
            </div>

            <div style={{ textAlign: "center" }}>
                <h2 style={{ fontSize: "17px", fontWeight: "400", color: "black", marginTop: "1rem", fontFamily: "serif", marginBottom: "1rem", paddingLeft: "80px", marginRight: "80px" }}></h2>
            </div> */}
            <div style={{ textAlign: "center" }}>
                <h2 style={{ fontSize: "34px", fontWeight: "700", marginTop: "1.5rem", color: "#DE5A59", fontFamily: "serif" }}>Outfit</h2>
            </div>
            <div class="" style={{ padding: "13px", display: "flex", justifyContent: "center" }}>
                <p style={{ textAlign: "center", fontSize: "20px", fontWeight: "400", color: "black", marginTop: "1rem", fontFamily: "serif", maxWidth: "55rem", minWidth: "15rem" }}>One could, without any doubt, say that the bridal outfit is the most notable source of color at a wedding ceremony. Working towards looking your vibrant best at your wedding involves experimenting with different styles, shades, and fabrics while planning your attire for your big day. Our diverse cultural heritage as a nation facilitates a wide range of options while choosing your wedding outfit.</p>
            </div>
            <div class="" style={{ padding: "13px", display: "flex", justifyContent: "center" }}>

                <img src="/outfit/Rectangle 185.png" class="img-fluid" style={{ maxWidth: "55rem", minWidth: "15rem" }} alt="bridal image"></img>

            </div>

            <div style={{ textAlign: "center" }}>
                <h2 style={{ fontSize: "34px", fontWeight: "700", marginTop: "1.5rem", color: "black", fontFamily: "serif" }}>Different styles of outfits based on ethno-cultural</h2>
            </div>

            {tipsImages.map((tip, index) => (
                <div key={index} style={{ marginBottom: "2rem", textAlign: "center" }}>
                    <div style={{ textAlign: "center" }}>
                        <h2 style={{ fontSize: "34px", fontWeight: "700", color: "black", fontFamily: "serif" }}>{tip.title}</h2>
                        {/* <div style={{ fontSize: "34px", fontWeight: "700", color: "#DE5A59", fontFamily: "serif", display: "flex", justifyContent: "center" }}><h2 style={{ fontSize: "34px", fontWeight: "700", color: "#DE5A59", fontFamily: "serif", width: "50%", textAlign: "center", borderBottom: "3.5px solid rgb(222, 90, 89)", lineHeight: "0.1em", margin: "25px 7px 40px" }}><span style={{ background: "#fff", padding: "0 10px" }}>{tip.subtitle}</span></h2></div> */}
                        <div style={{ display: "flex", justifyContent: "center" }}><span class="subs" style={{ fontSize: "34px", fontWeight: "700", color: "#DE5A59", }}>{tip.subtitle}</span></div>
                        {/* <div style={{ fontSize: "34px", fontWeight: "700", color: "#DE5A59", fontFamily: "serif", display: "flex", justifyContent: "center" }}><h2 style={{ width: "100%", textAlign: "center", borderBottom: "1px solid #000", lineHeight: "0.1em", margin: "25px 7px 40px" }}><span style={{ background: "#fff", padding: "0 10px" }}>{tip.subtitle}</span></h2></div> */}
                        {/* <div class="about-border" style={{ width: "200px", height: "3px", background: "#DE5A59", margin: "20px auto" }}></div> */}
                    </div>

                    <div class="" style={{ padding: "13px" }}>
                        <img style={{ borderRadius: "8px", width: "100%", height: "348px", objectFit: "contain" }}
                            class=""
                            src={tip.image_url}
                        />
                    </div>

                    <div class="" style={{ padding: "13px", display: "flex", justifyContent: "center" }}>
                        <p style={{ textAlign: "center", fontSize: "20px", fontWeight: "400", color: "black", fontFamily: "serif", maxWidth: "55rem", minWidth: "15rem" }}>{tip.description}</p>
                    </div>


                </div>
            ))}
            {/* </div> */}



            <div style={{ height: "5rem" }}></div>

            <FooterMain />

        </div>
    );
};



