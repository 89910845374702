import React, { useState, useEffect } from 'react';
import { useNavigate, Link, useParams } from 'react-router-dom';
import Swal from 'sweetalert2'
import db from "./Firebase";
import { arrayUnion } from "firebase/firestore";
import { storage } from './Firebase';
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import ViewAllRequests from './ViewAllRequests';
import ViewAppData from './ViewAppData';
import { CSVLink } from "react-csv";

function ExpensesList() {
    const navigate = useNavigate();
    const moment = extendMoment(Moment);
    const [data, setData] = useState([]);
    const [csvdata, setCsvdata] = useState([]);
    const [uid, setUid] = useState('');
    const [searchQuery, setSearchQuery] = useState("");

    useEffect(() => {
        const storedUsername = localStorage.getItem("email");
        const storedPassword = localStorage.getItem("password");
        const storedRemember = localStorage.getItem("remember");

        if (storedRemember === "false" || !storedUsername || !storedPassword) {
            navigate('/signin');
        }
    }, []);

    const headers = [
        "Username",
        "expenseName",
        'description',
        "Amount",
        "Type",
        "Created Date",
        // Add more column labels as needed
    ];

    useEffect(() => {

        db.collection("expenses").orderBy("created_date", "desc").onSnapshot((snapshot) => {
            setData(
                snapshot.docs.map((doc) => ({
                    id: doc.id,
                    data: doc.data(),
                }))
            );
        });

    }, []);

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };


    // Function to filter data based on the search query
    const filteredData = data.filter((item) => {
        const user_name = item.data.username.toLowerCase();
        const expense_name = item.data.expenseName.toLowerCase();
        const type = item.data.paymentMethod.toLowerCase();
        const query = searchQuery.toLowerCase();

        return (
            user_name.includes(query) ||
            expense_name.includes(query) ||
            type.includes(query)
        );
    });

    // Function to convert data to CSV format
    const prepareCSVData = () => {
        const csvData = filteredData.map((item, index) => ([
            item.data.username,
            item.data.expenseName,
            item.data.description,
            item.data.charges,
            item.data.paymentMethod,
            moment(item.data.created_date.toDate().toISOString()).format("MM/DD/YY, h:mm a"),
            // Add more columns as needed
        ]));

        return csvData;
    };






    return (
        <>
            <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg">

                <div class="p-2" style={{ paddingBottom: "5px", display: "flex", justifyContent: "space-between" }}>
                    <Link to="/admin_panel">
                        <img src="/images/menubutton.png" class="menu-toggle" alt="Responsive image" style={{ height: "2rem" }} />
                    </Link>
                    <CSVLink data={prepareCSVData()} headers={headers} filename={"expenses.csv"}>
                        <span class="btn bg-gradient-secondary btn-sm mb-0 mr-2" style={{ color: "white" }}>
                            &nbsp; Download Excel
                        </span>
                    </CSVLink>
                </div>
                <div class="">

                    <div class="p-2">

                        <div class="card">
                            <div class="card-header pb-0 px-3">
                                <h6 class="mb-2">All Expanses || {`Records : ${filteredData.length}`}</h6>
                            </div>
                            <input
                                type="text"
                                className="form-control form-control-sm"
                                placeholder="Search by Username, Expense Name, Type, Amount"
                                value={searchQuery}
                                onChange={handleSearchChange}
                            />
                            <div class="card">
                                <div class="table-responsive fix-width scroll-inner">
                                    <table class="table table-bordered table-hover">
                                        <thead class="thead-dark" style={{ position: "sticky", top: "0" }}>
                                            <tr style={{ textAlign: "left" }}>
                                                <th class="col ps-2">S.No</th>
                                                <th class="col ps-2">User Name</th>
                                                <th class="col ps-2">Expense Name</th>
                                                <th class="col ps-2">Description</th>
                                                <th class="col ps-2">Amount</th>
                                                <th class="col ps-2">Type</th>
                                                <th class="col ps-2">Created Date</th>
                                            </tr>
                                        </thead>
                                        <tbody style={{ textAlign: "left" }}>
                                            {filteredData.map((item, index) => (
                                                <tr key={item.id}>

                                                    <td style={{ whiteSpace: 'pre-wrap' }}>
                                                        <p class="text-xs font-weight-bold mb-0" style={{ width: "4rem" }}>{index + 1}.</p>

                                                    </td>
                                                    <td>
                                                        <p class="text-xs mb-0">{item.data.username}</p>
                                                    </td>
                                                    <td>
                                                        <p class="text-xs mb-0">{item.data.expenseName}</p>
                                                    </td>
                                                    <td>
                                                        <p class="text-xs mb-0">{item.data.description}</p>
                                                    </td>
                                                    <td>
                                                        <p class="text-xs mb-0">{item.data.charges}</p>
                                                    </td>
                                                    <td>
                                                        <p class="text-xs mb-0">{item.data.paymentMethod}</p>
                                                    </td>

                                                    <td>
                                                        <div class="d-flex px-2 py-1">
                                                            <div class="d-flex flex-column justify-content-center">
                                                                <h6 class="mb-0 text-xs">{moment(item.data.created_date.toDate().toISOString()).format("MM/DD/YY, h:mm a")}</h6>
                                                            </div>
                                                        </div>
                                                    </td>

                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
}

export default ExpensesList;
