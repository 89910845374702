import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import db from "./Firebase";
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import { CSVLink } from "react-csv";

function CustomerData() {
    const navigate = useNavigate();
    const moment = extendMoment(Moment);
    const [data, setData] = useState([]);
    const [csvdata, setCsvdata] = useState([]);
    const [dataLimit, setDataLimit] = useState(20);

    useEffect(() => {
        const storedUsername = localStorage.getItem("email");
        const storedPassword = localStorage.getItem("password");
        const storedRemember = localStorage.getItem("remember");

        if (storedRemember === "false" || !storedUsername || !storedPassword) {
            navigate('/signin');
        }
    }, []);

    const headers = [
        { label: "Full Name", key: "name" },
        { label: "Phone No", key: "phone_no" },
        { label: "Type", key: "type" },
        { label: "Email", key: "email" },
        { label: "Additional", key: "additional" },
        { label: "Created Date", key: "created_date", dataFormat: "MM/DD/YY, h:mm a" },
    ];

    useEffect(() => {
        db.collection("customer_data").orderBy("created_date", "desc").onSnapshot((snapshot) => {
            const fetchedData = snapshot.docs.map((doc) => ({
                id: doc.id,
                data: doc.data(),
            }));
            setData(fetchedData);
            setCsvdata(
                snapshot.docs.map((doc) => {
                    const data = doc.data();
                    return {
                        ...data,
                        created_date: moment(data.created_date.toDate().toISOString()).format("MM/DD/YY, h:mm a"),
                    };
                })
            );
        });
    }, []);

    const limitedData = data.slice(0, dataLimit);

    const loadMoreData = () => {
        setDataLimit(dataLimit + 20);
    };

    return (
        <>
            <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg">
                <div className="p-2" style={{ paddingBottom: "5px", display: "flex", justifyContent: "space-between" }}>
                    <Link to="/admin_panel">
                        <img src="/images/menubutton.png" className="menu-toggle" alt="Responsive image" style={{ height: "2rem" }} />
                    </Link>
                    <CSVLink data={csvdata} headers={headers}>
                        <span className="btn bg-gradient-secondary btn-sm mb-0 mr-2" style={{ color: "white" }} >&nbsp; Download Excel</span>
                    </CSVLink>
                </div>
                <div className="">
                    <div className="p-2">
                        <div className="card">
                            <div className="card-header pb-0">
                                <h6 className="mb-2">Customer's Data</h6>
                            </div>
                            <div className="card">
                                <div className="table-responsive fix-width scroll-inner">
                                    <table className="table table-bordered table-hover">
                                        <thead className="thead-dark" style={{ position: "sticky", top: "0" }}>
                                            <tr style={{ textAlign: "left" }}>
                                                <th className="col ps-2">Name</th>
                                                <th className="col">Phone No</th>
                                                <th className="col ps-2">Type</th>
                                                <th className="col ps-2">Additional</th>
                                                <th className="col ps-2">Email</th>
                                                <th className="col ps-2">Created Date</th>
                                            </tr>
                                        </thead>
                                        <tbody style={{ textAlign: "left" }}>
                                            {limitedData.map(({ id, data }) => (
                                                <tr key={id}>
                                                    <td>
                                                        <p className="text-xs mb-0">{data.name}</p>
                                                    </td>
                                                    <td>
                                                        <p className="text-xs mb-0">{data.phone_no}</p>
                                                    </td>
                                                    <td>
                                                        <p className="text-xs mb-0">{data.type}</p>
                                                    </td>
                                                    <td>
                                                        <p className="text-xs mb-0">{data.additional}</p>
                                                    </td>
                                                    <td>
                                                        <p className="text-xs mb-0">{data.email}</p>
                                                    </td>
                                                    <td>
                                                        <div className="d-flex px-2 py-1">
                                                            <div className="d-flex flex-column justify-content-center">
                                                                <h6 className="mb-0 text-xs">{moment(data.created_date.toDate().toISOString()).format("MM/DD/YY, h:mm a")}</h6>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    <div className="" style={{ paddingTop: "2rem" }}>
                                        <button onClick={loadMoreData} className="btn btn-dark">Load More</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
}

export default CustomerData;



// import React, { useState, useEffect } from 'react';
// import { useNavigate, Link, useParams } from 'react-router-dom';
// import db from "./Firebase";
// import firebaseApp from "./Firebase";
// import { arrayUnion } from "firebase/firestore";
// import { storage } from './Firebase';
// import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
// import Moment from 'moment';
// import { extendMoment } from 'moment-range';
// import ViewAllRequests from './ViewAllRequests';
// import ViewAppData from './ViewAppData';
// import { CSVLink } from "react-csv";

// function CustomerData() {
//     const navigate = useNavigate();
//     const moment = extendMoment(Moment);
//     const [data, setData] = useState([]);
//     const [csvdata, setCsvdata] = useState([]);

//     useEffect(() => {
//         const storedUsername = localStorage.getItem("email");
//         const storedPassword = localStorage.getItem("password");
//         const storedRemember = localStorage.getItem("remember");

//         if (storedRemember === "false" || !storedUsername || !storedPassword) {
//             navigate('/signin');
//         }
//     }, []);

//     const headers = [
//         { label: "Full Name", key: "name" },
//         { label: "Phone No", key: "phone_no" },
//         { label: "Type", key: "type" },
//         { label: "Email", key: "email" },
//         { label: "Additional", key: "additional" },
//         { label: "Created Date", key: "created_date", dataFormat: "MM/DD/YY, h:mm a" },
//     ];

//     useEffect(() => {
//         db.collection("customer_data").orderBy("created_date", "desc").onSnapshot((snapshot) => {
//             setData(
//                 snapshot.docs.map((doc) => ({
//                     id: doc.id,
//                     data: doc.data(),
//                 }))
//             );
//             setCsvdata(
//                 snapshot.docs.map((doc) => {
//                     const data = doc.data();
//                     return {
//                         ...data,
//                         created_date: moment(data.created_date.toDate().toISOString()).format("MM/DD/YY, h:mm a"),
//                     };
//                 })
//             );
//         });
//     }, []);

//     return (
//         <>
//             <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg">
//                 <div class="p-2" style={{ paddingBottom: "5px", display: "flex", justifyContent: "space-between" }}>
//                     <Link to="/admin_panel">
//                         <img src="/images/menubutton.png" class="menu-toggle" alt="Responsive image" style={{ height: "2rem" }} />
//                     </Link>
//                     <CSVLink data={csvdata} headers={headers}>
//                         <span class="btn bg-gradient-secondary btn-sm mb-0 mr-2" style={{ color: "white" }} >&nbsp; Download Excel</span>
//                     </CSVLink>
//                 </div>
//                 <div class="">
//                     <div class="p-2">

//                         <div class="card">
//                             <div class="card-header pb-0">
//                                 <h6 class="mb-2">Customer's Data</h6>
//                             </div>
//                             <div class="card">
//                                 <div class="table-responsive fix-width scroll-inner">
//                                     <table class="table table-bordered table-hover">
//                                         <thead class="thead-dark" style={{ position: "sticky", top: "0" }}>
//                                             <tr style={{ textAlign: "left" }}>
//                                                 <th class="col ps-2">Name</th>
//                                                 <th class="col">Phone No</th>
//                                                 <th class="col ps-2">Type</th>
//                                                 <th class="col ps-2">Additional</th>
//                                                 <th class="col ps-2">Email</th>
//                                                 <th class="col ps-2">Created Date</th>
//                                             </tr>
//                                         </thead>
//                                         <tbody style={{ textAlign: "left" }}>
//                                             {data?.map(({ id, data }, index) => (
//                                                 <tr key={id}>
//                                                     <td>
//                                                         <p class="text-xs mb-0">{data.name}</p>
//                                                     </td>
//                                                     <td>
//                                                         <p class="text-xs mb-0">{data.phone_no}</p>
//                                                     </td>
//                                                     <td>
//                                                         <p class="text-xs mb-0">{data.type}</p>
//                                                     </td>
//                                                     <td>
//                                                         <p class="text-xs mb-0">{data.additional}</p>
//                                                     </td>
//                                                     <td>
//                                                         <p class="text-xs mb-0">{data.email}</p>
//                                                     </td>
//                                                     <td>
//                                                         <div class="d-flex px-2 py-1">
//                                                             <div class="d-flex flex-column justify-content-center">
//                                                                 <h6 class="mb-0 text-xs">{moment(data.created_date.toDate().toISOString()).format("MM/DD/YY, h:mm a")}</h6>
//                                                             </div>
//                                                         </div>
//                                                     </td>
//                                                 </tr>
//                                             ))}
//                                         </tbody>
//                                     </table>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </main>
//         </>
//     );
// }

// export default CustomerData;


// import React, { useState, useEffect } from 'react';
// import { useNavigate, Link, useParams } from 'react-router-dom';
// import db from "./Firebase";
// import firebaseApp from "./Firebase";
// import { arrayUnion } from "firebase/firestore";
// import { storage } from './Firebase';
// import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
// import Moment from 'moment';
// import { extendMoment } from 'moment-range';
// import ViewAllRequests from './ViewAllRequests';
// import ViewAppData from './ViewAppData';
// import { CSVLink } from "react-csv";

// function CustomerData() {
//     const moment = extendMoment(Moment);
//     const [data, setData] = useState([]);
//     const [csvdata, setCsvdata] = useState([]);


//     const headers = [
//         { label: "Full Name", key: "name" },
//         { label: "Phone No", key: "phone_no" },
//         { label: "Type", key: "type" },
//         { label: "Email", key: "email" },
//         { label: "Additional", key: "additional" },
//         { label: "Created Date", key: "created_date", dataFormat: "MM/DD/YY, h:mm a" },
//     ];



//     useEffect(() => {
//         db.collection("customer_data").orderBy("created_date", "desc").onSnapshot((snapshot) => {

//             setData(
//                 snapshot.docs.map((doc) => ({
//                     id: doc.id,
//                     data: doc.data(),
//                 }))
//             );
//             setCsvdata(
//                 snapshot.docs.map((doc) => {
//                     const data = doc.data();
//                     return {
//                         ...data,
//                         created_date: moment(data.created_date.toDate().toISOString()).format("MM/DD/YY, h:mm a"),
//                     };
//                 })
//             );
//         });

//     }, []);

//     return (
//         <>
//             {/* <ViewAppData /> */}
//             <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
//                 <div class="p-2" style={{ textAlign: "start", paddingBottom: "5px" }}>
//                     {/* <span class="btn bg-gradient-secondary btn-sm mb-0 mr-2" style={{ color: "white" }} >&nbsp; Download Excel</span> */}
//                     <Link to="/admin_panel"><img src="/images/menubutton.png" class="menu-toggle" alt="Responsive image" style={{ height: "2rem" }} /></Link>

//                 </div>
//                 <div class="row">


//                     <div class="mt-4 p-4">
//                         <div style={{ textAlign: "end", paddingBottom: "5px" }}>
//                             {/* <Link to="/admin_panel" class="btn bg-gradient-primary btn-sm mb-0 mr-2" style={{ color: "white" }} >&nbsp; Go Back</Link> */}
//                             <CSVLink data={csvdata} headers={headers}>
//                                 <span class="btn bg-gradient-secondary btn-sm mb-0 mr-2" style={{ color: "white" }} >&nbsp; Download Excel</span>
//                             </CSVLink>
//                         </div>
//                         <div class="card">
//                             <div class="card-header pb-0 px-3">
//                                 <h6 class="mb-2">Customer's Data</h6>
//                             </div>
//                             <div class="">
//                                 <div class="card">
//                                     <div class="table-responsive fix-width scroll-inner">
//                                         <table class="table align-items-center mb-0">
//                                             <thead>
//                                                 <tr style={{ textAlign: "left" }}>
//                                                     <th class="col ps-2">Name</th>
//                                                     <th class="col">Phone No</th>
//                                                     <th class="col ps-2">Type</th>
//                                                     <th class="col ps-2">Additional</th>
//                                                     <th class="col ps-2">Email</th>
//                                                     <th class="col ps-2">Created Date</th>
//                                                 </tr>
//                                             </thead>
//                                             <tbody style={{ textAlign: "left" }}>
//                                                 {data?.map(({ id, data }, index) => (
//                                                     <>
//                                                         <tr>

//                                                             <td>
//                                                                 <p class="text-xs mb-0">{data.name}</p>
//                                                                 {/* <p class="text-xs text-secondary mb-0">{moment(data.created_date.toDate().toISOString()).format("MM/DD/YY, h:mm a")}</p> */}
//                                                             </td>
//                                                             <td>
//                                                                 <p class="text-xs mb-0">{data.phone_no}</p>
//                                                                 {/* <p class="text-xs text-secondary mb-0">{moment(data.created_date.toDate().toISOString()).format("MM/DD/YY, h:mm a")}</p> */}
//                                                             </td>
//                                                             <td>
//                                                                 <p class="text-xs mb-0">{data.type}</p>
//                                                                 {/* <p class="text-xs text-secondary mb-0">{moment(data.created_date.toDate().toISOString()).format("MM/DD/YY, h:mm a")}</p> */}
//                                                             </td>
//                                                             <td>
//                                                                 <p class="text-xs mb-0">{data.additional}</p>
//                                                                 {/* <p class="text-xs text-secondary mb-0">{moment(data.created_date.toDate().toISOString()).format("MM/DD/YY, h:mm a")}</p> */}
//                                                             </td>
//                                                             <td>
//                                                                 <p class="text-xs mb-0">{data.email}</p>
//                                                                 {/* <p class="text-xs text-secondary mb-0">{moment(data.created_date.toDate().toISOString()).format("MM/DD/YY, h:mm a")}</p> */}
//                                                             </td>

//                                                             <td>
//                                                                 <div class="d-flex px-2 py-1">
//                                                                     <div class="d-flex flex-column justify-content-center">
//                                                                         <h6 class="mb-0 text-xs">{moment(data.created_date.toDate().toISOString()).format("MM/DD/YY, h:mm a")}</h6>
//                                                                     </div>
//                                                                 </div>
//                                                             </td>

//                                                         </tr>

//                                                     </>
//                                                 ))}


//                                             </tbody>
//                                         </table>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>

//                 </div>

//             </main>


//         </>
//     )
// }

// export default CustomerData;