
import React, { useState, useEffect } from 'react';
import { useNavigate, Link, useParams } from 'react-router-dom';
import db from "./Firebase";
import { arrayUnion } from "firebase/firestore";
import { storage } from './Firebase';
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import Moment from 'moment';
import Swal from 'sweetalert2'
import { extendMoment } from 'moment-range';
import ViewAllRequests from './ViewAllRequests';
import { CSVLink } from "react-csv";

function WorkshopReminder() {
    const navigate = useNavigate();
    const moment = extendMoment(Moment);
    const [data, setData] = useState([]);
    const [dataR, setDataR] = useState([]);
    const [uid, setUid] = useState('');
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [uuid, setUuid] = useState('');
    const [date, setDate] = useState('');
    const [location, setLocation] = useState('');
    const [wname, setWname] = useState('');
    const [csvdata, setCsvdata] = useState([]);

    const [activeForm, setActiveForm] = useState('reminder');


    const initialFormState = { title: '', description: '', image_url: '', type: '', id: '', created_date: new Date() }
    const [submitPush, setSubmitPush] = useState(initialFormState);

    const headers = [
        { label: "Full Name", key: "full_name" },
        { label: "Phone Number", key: "phone" },
        { label: "Email", key: "email" },
        { label: "Workshop Location", key: "workshop_location" },
        { label: "Workshop Event Name", key: "workshop_event_name" },
        { label: "Created Date", key: "created_date", dataFormat: "MM/DD/YY, h:mm a" },

    ];

    const addNew = () => {
        setActiveForm('push');
        openModalShow();
        // setSubmitPush({ title: '', description: '', image_url: '', type: '', id: '', created_date: new Date() });
    }

    const [filteredData, setFilteredData] = useState([]);
    const [selectedWorkshop, setSelectedWorkshop] = useState('');
    const [selectedWorkshopR, setSelectedWorkshopR] = useState('');
    const [workshopNames, setWorkshopNames] = useState([]);
    const [workshopNamesR, setWorkshopNamesR] = useState([]);
    const [filteredPhoneNumbers, setFilteredPhoneNumbers] = useState([]);

    const initialFormStateReminder = { workshop_name: '', image_url: '', text1: '', text2: '', date: '', time: '', zoom_link: '', meeting_id: '', passcode: '', text3: '', created_date: new Date() }
    const [submitReminder, setSubmitReminder] = useState(initialFormStateReminder);

    const [reminderData, setReminderData] = useState({});

    const [nids, setNids] = useState([]);

    const filter = (name) => {
        setSelectedWorkshopR(name)
        db.collection("workshop_reminder_template").where('workshop_name', '==', name).onSnapshot((snapshot) => {
            snapshot.docs.map(e => setSubmitReminder({ workshop_name: e.data().workshop_name, image_url: e.data().image_url, text1: e.data().text1, text2: e.data().text2, date: e.data().date, time: e.data().time, zoom_link: e.data().zoom_link, meeting_id: e.data().meeting_id, passcode: e.data().passcode, text3: e.data().text3, created_date: new Date() }))

        });

    }

    const handleChangeReminderP = (e) => {
        const { id, value } = e.target
        setSubmitPush({ ...submitPush, [id]: value })
    }


    useEffect(() => {
        const storedUsername = localStorage.getItem("email");
        const storedPassword = localStorage.getItem("password");
        const storedRemember = localStorage.getItem("remember");

        if (storedRemember === "false" || !storedUsername || !storedPassword) {
            navigate('/signin');
        }
    }, []);

    useEffect(() => {
        db.collection("workshop_registration").orderBy("created_date", "desc").onSnapshot((snapshot) => {
            const workshopData = snapshot.docs.map((doc) => ({
                id: doc.id,
                data: doc.data(),
            }));

            setData(workshopData);

            const csvData = workshopData.map((doc) => {
                const data = doc.data;
                return {
                    ...data,
                    created_date: moment(data.created_date.toDate().toISOString()).format("MM/DD/YY, h:mm a"),
                };
            });

            setCsvdata(csvData);

            // Extract unique workshop names
            const uniqueNames = Array.from(new Set(workshopData.map((doc) => doc.data.workshop_event_name)));
            setWorkshopNames(uniqueNames);
        });
    }, []);

    useEffect(() => {
        db.collection("workshop_reminder_template").orderBy("created_date", "desc").onSnapshot((snapshot) => {
            const workshopDataR = snapshot.docs.map((doc) => ({
                id: doc.id,
                data: doc.data(),
            }));

            setDataR(workshopDataR);

            // Extract unique workshop names
            const uniqueNames = Array.from(new Set(workshopDataR.map((doc) => doc.data.workshop_name)));
            setWorkshopNamesR(uniqueNames);
        });
    }, []);



    const [documentIds, setDocumentIds] = useState([])

    useEffect(() => {
        // Filter data based on selected workshop name
        if (selectedWorkshop) {
            const filtered = data.filter((item) => item.data.workshop_event_name === selectedWorkshop);
            setFilteredData(filtered);

            // Extract and store filtered phone numbers in an array
            const phoneNumbers = filtered.map((item) => item.data.phone);
            setFilteredPhoneNumbers(phoneNumbers);
            console.log(phoneNumbers)
            const phoneChunks = [];
            for (let i = 0; i < phoneNumbers.length; i += 10) {
                phoneChunks.push(phoneNumbers.slice(i, i + 10));
            }

            const usersData = [];

            // const promises = [];

            // // Fetch data for each batch of phone numbers and store the promises
            // phoneChunks.forEach((chunk) => {
            //     const promise = db.collection("personal_details")
            //         .where("phone_no", "in", chunk)
            //         .get()
            //         .then((querySnapshot) => {
            //             const ids = []; // Array to store document IDs for this batch
            //             querySnapshot.forEach((doc) => {
            //                 const userData = {
            //                     id: doc.id,
            //                     data: doc.data(),
            //                 };
            //                 usersData.push(userData);
            //                 ids.push(doc.id); // Store the document ID in the array for this batch
            //                 console.log("Fetched user data:", userData);
            //             });
            //             return ids; // Return the document IDs for this batch
            //         })
            //         .catch((error) => {
            //             console.error("Error fetching user data:", error);
            //             return []; // Return an empty array in case of error
            //         });
            //     promises.push(promise); // Store the promise in the array
            // });

            // // Wait for all promises to resolve
            // Promise.all(promises)
            //     .then((results) => {
            //         // Flatten the array of arrays into a single array of document IDs
            //         const documentIds = results.flat();
            //         console.log("Document IDs:", documentIds); // Log the document IDs
            //         setNids(documentIds)
            //     })
            //     .catch((error) => {
            //         console.error("Error fetching document IDs:", error);
            //     });

            const promises = []; // Array to store all promises

            // Fetch data for each batch of phone numbers and store the promises
            phoneChunks.forEach((chunk) => {
                const promise = db.collection("personal_details")
                    .where("phone_no", "in", chunk)
                    .get()
                    .then((querySnapshot) => {
                        const ids = []; // Array to store document IDs for this batch
                        querySnapshot.forEach((doc) => {
                            const userData = {
                                id: doc.id,
                                data: doc.data(),
                            };
                            usersData.push(userData);
                            ids.push(doc.id); // Store the document ID in the array for this batch
                            console.log("Fetched user data:", userData);
                        });
                        return ids; // Return the document IDs for this batch
                    })
                    .then((documentIds) => {
                        // Fetch notification IDs and filter registration IDs based on document IDs
                        return db.collection("notification_ids")
                            .get()
                            .then((notificationSnapshot) => {
                                const regIds = []; // Array to store registration IDs
                                notificationSnapshot.forEach((notificationDoc) => {
                                    const uid = notificationDoc.data().uid;
                                    const regId = notificationDoc.data().reg_id;
                                    if (documentIds.includes(uid)) {
                                        regIds.push(regId);
                                    }
                                });
                                return regIds; // Return registration IDs for this batch
                            });
                    })
                    .catch((error) => {
                        console.error("Error fetching data:", error);
                        return []; // Return an empty array in case of error
                    });
                promises.push(promise); // Store the promise in the array
            });

            // Wait for all promises to resolve
            Promise.all(promises)
                .then((results) => {
                    // Flatten the array of arrays into a single array of registration IDs
                    const allRegIds = results.flat();
                    console.log("Registration IDs:", allRegIds); // Log the registration IDs
                    setNids(allRegIds)
                })
                .catch((error) => {
                    console.error("Error fetching data:", error);
                });





            // db.collection("notification_ids").onSnapshot((snapshot) => {
            //     usersDataIds.length = 0;
            //     snapshot.docs.forEach((doc) => {
            //         usersDataIds.push({
            //             id: doc.id,
            //             data: doc.data(),
            //         });
            //     });
            //     mergeData();
            // });



            // db.collection("personal_details")
            //     .where("phone_no", "in", phoneNumbers)
            //     .onSnapshot((snapshot) => {
            //         // usersData.length = 0;
            //         snapshot.docs.forEach((doc) => {
            //             const userData = {
            //                 id: doc.id,
            //                 data: doc.data(),
            //             };
            //             // usersData.push(userData);
            //             console.log("Fetched user data:", userData); // Log each fetched user data
            //         });
            //     });

        } else {
            setFilteredData(data);
            setFilteredPhoneNumbers([]); // Clear the array when no workshop is selected
        }
    }, [selectedWorkshop, data]);

    const notifyApp = (msg) => {
        // Call the user's CollectionReference to add a new user
        db.collection("app_notification").add({ 'message': msg, 'link': "noti", 'status': "0", "uid": uid, 'created_date': new Date() }).then(() => {
            console.log("notified");
        })
    }

    const nidss = () => {
        console.log(nids);
    }

    const handleSubmitPush = (e, msg) => {
        e.preventDefault();


        console.log(nids);
        closeModel();

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "key=AAAAnzqDoGw:APA91bHNYZDEZn5paZqVOV6evl4npGLdy7V1iixsNCGekPti0mkcTft5lHb9WruMaFjOdJrGVR9kkFzfC00HMfmvPUFo4sQhvXmXoom7clqOhXqMc7y5erUF75zJIxAzMZeuCtXx6PTS");

        // Check if filteredRegIds is not empty before proceeding
        if (nids.length === 0) {
            console.log("No valid registration IDs to send the push notification.");
            return;
        }

        const batchSize = 100; // Choose an appropriate batch size

        const batches = [];
        for (let i = 0; i < nids.length; i += batchSize) {
            const batch = nids.slice(i, i + batchSize);

            // Create a separate request for each batch
            const raw = JSON.stringify({
                "registration_ids": batch,
                "notification": {
                    "body": submitPush.description,
                    "title": submitPush.title,
                    "android_channel_id": "youvati",
                    "image": submitPush.image_url,
                    "sound": true
                },
                "data": {
                    "type": submitPush.type,
                    "id": submitPush.id,
                    "image": submitPush.image_url
                }
            });

            const requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            batches.push(fetch("https://fcm.googleapis.com/fcm/send", requestOptions));
        }

        // Execute all batches concurrently
        Promise.all(batches)
            .then(responses => {
                // Handle responses as needed
                console.log(responses);
                // Additional handling if required
                Swal.fire(`Push notifications sent successfully to ${nids.length} devices.`);
            })
            .then(result => {
                // alert(JSON.stringify(result))
                Swal.fire(`Success : ${JSON.parse(result).success}
                Failed : ${JSON.parse(result).failure}`)
                console.log(result)

            })
            .catch(error => console.log('error', error));
    };

    const initialFormStateFeedback = { status: '' }
    const [submit, setSubmit] = useState(initialFormStateFeedback)


    const sendWhatsapp = () => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer EABMKlZCrATXIBAPOZBZBPqsdfot34fq0FTipZBQf3RiXZAapyCOqMay09HfhSxoB8bd5ZA7RUnLWhd0JkXtACJQ682THiDEMhQhyPOkeui8Cb8P72WPDhihUZBCkHtPLSgsmuZA6iRY6VunDDIjPA5mATQQITVXOECHqkZB5E2pzWSC2AaUp0wZCWR");
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify(
            {
                "messaging_product": "whatsapp",
                "recipient_type": "individual",
                "to": `91${phone}`,
                "type": "template",
                "template": {
                    "name": "reminder_for_online_workshop",
                    "language": {
                        "code": "en_US",
                        "policy": "deterministic"
                    },
                    "components": [
                        {
                            "type": "header",
                            "parameters": [
                                {
                                    "type": "image",
                                    "image": { "link": "https://firebasestorage.googleapis.com/v0/b/youvati.appspot.com/o/files%2F1%20(4).jpg?alt=media&token=f2dc4c80-deac-41f3-b7f9-383381736f94" }
                                }
                            ]
                        },
                        {
                            "type": "body",
                            "parameters": [
                                { "type": "text", "text": "Reminder Reminder Reminder" },
                                {
                                    "type": "text", "text": "Instagram पर अपने Beauty Business को Grow करने का Session  aaj 3 baje shuru hoga. Kripya session shuru hone se 5 minute phele join karein!"
                                },
                                { "type": "text", "text": "29 September 2023" },
                                { "type": "text", "text": "3:00 PM to 4:00 PM" },
                                { "type": "text", "text": "https://us06web.zoom.us/j/81627039938?pwd=ijJOkdK1gVGuJ8JfWsRlCVG3amGKoX.1" },
                                { "type": "text", "text": "816 2703 9938" },
                                { "type": "text", "text": "1234" },
                                { "type": "text", "text": "Instagram पर अपने Beauty Business को Grow करने " },
                            ]
                        }
                    ]
                }
            }
        );

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch("https://graph.facebook.com/v14.0/104489945783566/messages", requestOptions)
            .then(response => response.text())
            .then(result => {
                console.log(result)
                // reset();
            })
            .catch(error => {
                console.log('error', error)
            });

    };

    const reminderStatus = (name) => {
        openModalShow();
        // setSubmitReminder({ name: '', phone_no: '', message: `Hey ${personalData.first_name},\nStumbled upon your details on the Youvati website – your profile looks great! 😄 I'm interested in learning more about your services. Could you provide me with more information?\n\nThanks,`, service: '', username: '', created_date: new Date() })
    }

    const openModalShow = () => {
        // alert("ooo")
        window.openModelshow();
    }


    const closeModel = () => {
        window.openModelNew();
    }


    const handleChange2 = async (e, msg) => {
        const { id, value } = e.target
        setSubmit({ ...submit, [id]: value })
        if (e.target.value == "1") {
            // db.collection("business_card_request").doc(uid).update({ 'status': "1", 'updated_date': new Date() }).then(() => {
            //     console.log("user added");
            // })
            sendWhatsapp()

            // notifyApp(msg = `Hi ${name} This is a reminder for the upcoming workshop on (${date}, ${location}). Kindly make sure you are there 5 minutes before the workshop starts`);
        } else {
            // db.collection("business_card_request").doc(uid).update({ 'status': "0", 'updated_date': new Date() }).then(() => {
            //     console.log("user added");
            // })
        }
    }

    const handleChangeReminder = (e) => {
        const { id, value } = e.target
        setSubmitReminder({ ...submitReminder, [id]: value })
    }

    const collect = (id, name, mainID, date, location, phone, wname) => {
        setUid(id)
        setName(name)
        setUuid(mainID)
        setDate(date)
        setPhone(phone)
        setLocation(location)
        setWname(wname)
        console.log(id)
        console.log(name)
        console.log(mainID)
        console.log(date)
        console.log(location)
    }

    const [windex, setWindex] = useState();

    const sendAll = async () => {
        console.log(submitReminder);
        const successNumbers = [];
        const failureNumbers = [];

        Swal.fire({
            title: 'Are you sure?',
            text: `You want to send message to ${filteredPhoneNumbers.length} contacts`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: 'green',
            cancelButtonColor: 'black',
            confirmButtonText: 'Yes, Send'
        }).then(async (result) => {
            if (result.isConfirmed) {

                for (const e of filteredPhoneNumbers) {
                    // var myHeaders = new Headers();
                    // myHeaders.append("Authorization", "Bearer EABMKlZCrATXIBAPOZBZBPqsdfot34fq0FTipZBQf3RiXZAapyCOqMay09HfhSxoB8bd5ZA7RUnLWhd0JkXtACJQ682THiDEMhQhyPOkeui8Cb8P72WPDhihUZBCkHtPLSgsmuZA6iRY6VunDDIjPA5mATQQITVXOECHqkZB5E2pzWSC2AaUp0wZCWR");
                    // myHeaders.append("Content-Type", "application/json");

                    // var raw = JSON.stringify({
                    //     "messaging_product": "whatsapp",
                    //     "recipient_type": "individual",
                    //     "to": `91${e}`,
                    //     "type": "template",
                    //     "template": {
                    //         "name": "reminder_for_online_workshop",
                    //         "language": {
                    //             "code": "en_US",
                    //             "policy": "deterministic"
                    //         },
                    //         "components": [
                    //             {
                    //                 "type": "header",
                    //                 "parameters": [
                    //                     {
                    //                         "type": "image",
                    //                         "image": { "link": submitReminder.image_url }
                    //                     }
                    //                 ]
                    //             },
                    //             {
                    //                 "type": "body",
                    //                 "parameters": [
                    //                     { "type": "text", "text": submitReminder.text1 },
                    //                     {
                    //                         "type": "text", "text": submitReminder.text2
                    //                     },
                    //                     { "type": "text", "text": submitReminder.date },
                    //                     { "type": "text", "text": submitReminder.time },
                    //                     { "type": "text", "text": submitReminder.zoom_link },
                    //                     { "type": "text", "text": submitReminder.meeting_id },
                    //                     { "type": "text", "text": submitReminder.passcode },
                    //                     { "type": "text", "text": submitReminder.text3 },
                    //                 ]
                    //             }
                    //         ]
                    //     }
                    // });

                    // var requestOptions = {
                    //     method: 'POST',
                    //     headers: myHeaders,
                    //     body: raw,
                    //     redirect: 'follow'
                    // };

                    // try {
                    //     const response = await fetch("https://graph.facebook.com/v14.0/104489945783566/messages", requestOptions);
                    //     const result = await response.text();
                    //     console.log(result);
                    //     successNumbers.push(e);
                    // } catch (error) {
                    //     console.error('error', error);
                    //     failureNumbers.push(e);
                    // }

                    const myHeaders = new Headers();
                    myHeaders.append("Authorization", "Bearer EABMKlZCrATXIBAPOZBZBPqsdfot34fq0FTipZBQf3RiXZAapyCOqMay09HfhSxoB8bd5ZA7RUnLWhd0JkXtACJQ682THiDEMhQhyPOkeui8Cb8P72WPDhihUZBCkHtPLSgsmuZA6iRY6VunDDIjPA5mATQQITVXOECHqkZB5E2pzWSC2AaUp0wZCWR");
                    myHeaders.append("Content-Type", "application/json");

                    const raw = JSON.stringify({
                        "messaging_product": "whatsapp",
                        "to": `91${e}`,
                        "type": "template",
                        "template": {
                            "name": "reminder_for_online_workshop",
                            "language": {
                                "code": "en_US",
                                "policy": "deterministic"
                            },
                            "components": [
                                {
                                    "type": "header",
                                    "parameters": [
                                        {
                                            "type": "image",
                                            "image": { "link": submitReminder.image_url }
                                        }
                                    ]
                                },
                                {
                                    "type": "body",
                                    "parameters": [
                                        {
                                            "type": "text",
                                            "text": submitReminder.workshop_name
                                        },
                                        {
                                            "type": "text",
                                            "text": submitReminder.date
                                        },
                                        {
                                            "type": "text",
                                            "text": submitReminder.time
                                        }
                                    ]
                                }
                            ]
                        }
                    });

                    const requestOptions = {
                        method: "POST",
                        headers: myHeaders,
                        body: raw,
                        redirect: "follow"
                    };

                    fetch("https://graph.facebook.com/v14.0/104489945783566/messages", requestOptions)
                        .then((response) => response.text())
                        .then((result) => console.log(result))
                        .catch((error) => console.error(error));
                }


                const Toast = Swal.mixin({
                    toast: true,
                    background: 'white',
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer)
                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                    }
                })

                Toast.fire({
                    icon: 'success',
                    title: 'Sent Successfully'
                })

                setWorkshopNames([]);
                setWorkshopNamesR([]);

            }
        })




        // Here, you can handle successNumbers and failureNumbers as needed.
        console.log('Success Numbers:', successNumbers);
        console.log('Failure Numbers:', failureNumbers);


        // You can also display the details of success and failure numbers in your UI.



        // filteredPhoneNumbers.forEach(e => {
        //     var myHeaders = new Headers();
        //     myHeaders.append("Authorization", "Bearer EABMKlZCrATXIBAPOZBZBPqsdfot34fq0FTipZBQf3RiXZAapyCOqMay09HfhSxoB8bd5ZA7RUnLWhd0JkXtACJQ682THiDEMhQhyPOkeui8Cb8P72WPDhihUZBCkHtPLSgsmuZA6iRY6VunDDIjPA5mATQQITVXOECHqkZB5E2pzWSC2AaUp0wZCWR");
        //     myHeaders.append("Content-Type", "application/json");

        //     var raw = JSON.stringify(
        //         {
        //             "messaging_product": "whatsapp",
        //             "recipient_type": "individual",
        //             "to": `91${e}`,
        //             "type": "template",
        //             "template": {
        //                 "name": submitReminder.workshop_name,
        //                 "language": {
        //                     "code": "en_US",
        //                     "policy": "deterministic"
        //                 },
        //                 "components": [
        //                     {
        //                         "type": "header",
        //                         "parameters": [
        //                             {
        //                                 "type": "image",
        //                                 "image": { "link": submit.image_url }
        //                             }
        //                         ]
        //                     },
        //                     {
        //                         "type": "body",
        //                         "parameters": [
        //                             { "type": "text", "text": submit.text1 },
        //                             {
        //                                 "type": "text", "text": submit.text2
        //                             },
        //                             { "type": "text", "text": submit.date },
        //                             { "type": "text", "text": submit.time },
        //                             { "type": "text", "text": submit.zoom_link },
        //                             { "type": "text", "text": submit.meeting_id },
        //                             { "type": "text", "text": submit.passcode },
        //                             { "type": "text", "text": submit.text3 },
        //                         ]
        //                     }
        //                 ]
        //             }
        //         }
        //     );

        //     var requestOptions = {
        //         method: 'POST',
        //         headers: myHeaders,
        //         body: raw,
        //         redirect: 'follow'
        //     };

        //     fetch("https://graph.facebook.com/v14.0/104489945783566/messages", requestOptions)
        //         .then(response => response.text())
        //         .then(result => {
        //             console.log(result)
        //             // reset();
        //         })
        //         .catch(error => {
        //             console.log('error', error)
        //         });
        // });
    }

    const handleSubmitReminder = (e, msg) => {
        e.preventDefault()
        console.log(submitReminder);
        db.collection("workshop_reminder_template").add({
            'workshop_name': submitReminder.workshop_name,
            'image_url': submitReminder.image_url,
            'text1': "",
            'text2': "",
            'date': submitReminder.date,
            'time': submitReminder.time,
            'zoom_link': "",
            'meeting_id': "",
            'recorded_session': '',
            'passcode': "",
            'text3': "",
            'status': '1',
            'created_date': new Date(),
        }).then(() => {
            // sendWhatsappEnquiry(submitReminder.phone_no, submitReminder.name, personalData.parlour_name)
            // db.collection("customer_data").add({ 'name': submitReminder.name, 'email': '', 'phone_no': submitReminder.phone_no, 'type': "enquiry", 'additional': params.id, 'created_date': new Date(), }).then(() => {
            //     console.log("data added");
            // });
            // notifyApp(msg = submitReminder.message);
            console.log('Submitted Successfully');
            // setShowEnquiry(false);
            const Toast = Swal.mixin({
                toast: true,
                background: 'white',
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            })

            Toast.fire({
                icon: 'success',
                title: 'Submitted Successfully'
            })
            // setshow(true)
            closeModel();
            // navigate(`/${submit.username}`);
            setSubmitReminder({ workshop_name: '', image_url: '', text1: '', text2: '', date: '', time: '', zoom_link: '', meeting_id: '', passcode: '', text3: '', created_date: new Date() })
        })
    }

    return (
        <>
            {/* <ViewAllRequests /> */}
            <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
                <div class="p-2" style={{ textAlign: "start", paddingBottom: "5px" }}>
                    {/* <span class="btn bg-gradient-secondary btn-sm mb-0 mr-2" style={{ color: "white" }} >&nbsp; Download Excel</span> */}
                    <Link to="/admin_panel"><img src="/images/menubutton.png" class="menu-toggle" alt="Responsive image" style={{ height: "2rem" }} /></Link>


                </div>

                <div class="row">
                    <div class="" style={{ display: "flex", justifyContent: "end" }}>
                        <div class="row mr-3">
                            <div class="col-md-6">
                                <select
                                    className="form-control"
                                    value={selectedWorkshop}
                                    onChange={(e) => setSelectedWorkshop(e.target.value)}
                                    style={{ width: "20rem" }}
                                >
                                    <option value="">Select Workshop</option>
                                    {workshopNames.map((name) => (
                                        <option key={name} value={name}>
                                            {name}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            {selectedWorkshop === "" ? <></> : <>

                                <div class="col-md-6">
                                    <select
                                        className="form-control"
                                        value={selectedWorkshopR}
                                        onChange={(e) => filter(e.target.value)}
                                        // onChange={(e) => setSelectedWorkshopR(e.target.value)}
                                        style={{ width: "20rem" }}
                                    >
                                        <option value="">Select Reminder Template</option>
                                        {workshopNamesR.map((name, index) => (
                                            <option key={name} value={name}>
                                                {name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </>}

                        </div>

                    </div>
                    <div style={{ textAlign: "end", paddingBottom: "5px" }} class="mt-4">
                        {/* <Link to="/admin_panel" class="btn bg-gradient-primary btn-sm mb-0 mr-2" style={{ color: "white" }} >&nbsp; Go Back</Link> */}
                        {/* <CSVLink data={csvdata} headers={headers}>
                                <span class="btn bg-gradient-secondary btn-sm mb-0 mr-2" style={{ color: "white" }} >&nbsp; Download Excel</span>
                            </CSVLink> */}
                        <a class="btn bg-gradient-primary btn-sm mb-0 mr-4" style={{ color: "white" }} onClick={() => reminderStatus()}>+&nbsp; Add Reminder Template</a>
                        <a class="btn bg-gradient-primary btn-sm mb-0 mr-4" style={{ color: "white" }} onClick={() => addNew()}>+&nbsp; Send Push</a>

                        {selectedWorkshopR === "" ? <></> : <>
                            <a class="btn bg-gradient-primary btn-sm mb-0 mr-4" style={{ color: "white" }} onClick={() => sendAll()}>Send Reminder</a>
                        </>}
                    </div>

                    <div class="mt-4 p-4">

                        <div class="card">
                            <div class="card-header pb-0 px-3">
                                <h6 class="mb-2">Workshop Reminder || {filteredData.length}</h6>
                            </div>
                            <div class="">
                                <div class="card">
                                    <div class="table-responsive fix-width scroll-inner">
                                        <table class="table table-bordered table-hover">
                                            <thead class="thead-dark" style={{ position: "sticky", top: "0" }}>
                                                <tr style={{ textAlign: "left" }}>
                                                    <th class="col ps-2">Name</th>
                                                    <th class="col">Phone No</th>
                                                    <th class="col ps-2">Email</th>
                                                    <th class="col ps-2">Location</th>
                                                    <th class="col ps-2">Created Date</th>
                                                    <th class="col ps-2">Workshop/Event Name</th>
                                                    {/* <th class="col">Action</th> */}
                                                </tr>
                                            </thead>
                                            <tbody style={{ textAlign: "left" }}>
                                                {/* {data?.map(({ id, data }, index) => ( */}
                                                {filteredData.map(({ id, data }, index) => (
                                                    <>
                                                        <tr key={id}>
                                                            <td>
                                                                <p class="text-xs font-weight-bold mb-0">{data.full_name}</p>
                                                                {/* <p class="text-xs text-secondary mb-0">{moment(data.created_date.toDate().toISOString()).format("MM/DD/YY, h:mm a")}</p> */}
                                                            </td>
                                                            <td>
                                                                <div class="d-flex px-2 py-1">
                                                                    <div class="d-flex flex-column justify-content-center">
                                                                        <h6 class="mb-0 text-xs">{data.phone}</h6>
                                                                    </div>
                                                                </div>
                                                            </td>

                                                            <td>
                                                                <div class="d-flex px-2 py-1">
                                                                    <div class="d-flex flex-column justify-content-center">
                                                                        <h6 class="mb-0 text-xs">{data.email}</h6>
                                                                    </div>
                                                                </div>
                                                            </td>

                                                            <td>
                                                                <div class="d-flex px-2 py-1">
                                                                    <div class="d-flex flex-column justify-content-center">
                                                                        <h6 class="mb-0 text-xs">{data.workshop_location}</h6>
                                                                    </div>
                                                                </div>
                                                            </td>

                                                            <td>
                                                                <div class="d-flex px-2 py-1">
                                                                    <div class="d-flex flex-column justify-content-center">
                                                                        <h6 class="mb-0 text-xs">{moment(data.created_date.toDate().toISOString()).format("MM/DD/YY, h:mm a")}</h6>
                                                                    </div>
                                                                </div>
                                                            </td>

                                                            <td>
                                                                <div class="d-flex px-2 py-1">
                                                                    <div class="d-flex flex-column justify-content-center">
                                                                        <h6 class="mb-0 text-xs">{data.workshop_event_name}</h6>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            {/* <td>
                                                                <select class="form-control" id={index} name="status" onClick={() => collect(id, data.full_name, data.uid, data.workshop_Date, data.workshop_location, data.phone, data.workshop_event_name)} onChange={handleChange2} value={data.status} style={{ padding: "7px", width: "9rem", backgroundColor: data.status == 1 ? "#28a745" : "transparent" }} disabled={data.status == 1 ? true : false} >
                                                                    <option selected={data.status === 0 ? true : false} value="0">----</option>
                                                                    <option selected={data.status === 1 ? true : false} value="1">Send Reminder</option>
                                                                </select>
                                                            </td> */}
                                                        </tr>

                                                    </>
                                                ))}


                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </main>

            <div class="col-md-12">
                <div class="myId modal fade" id="" tabindex="-1" role="dialog" aria-labelledby="modal-form" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered modal-md" role="document">
                        <div class="modal-content">
                            <div class="modal-body">

                                <div class="">
                                    {activeForm === "reminder" ?
                                        <>
                                            <form class="" id="" onSubmit={handleSubmitReminder}>
                                                <div class="">
                                                    <div class="" style={{ textAlign: "center", }}><span style={{ color: "black", fontSize: "25px", fontWeight: "700", textTransform: "uppercase" }}>Create Reminder Template</span></div>
                                                    <div class="" style={{ textAlign: "center", color: "black", paddingTop: "10px" }}>
                                                    </div>

                                                    <div class="">
                                                        <div class="row">
                                                            <div class="col-md-12">
                                                                <input style={{
                                                                    // width: "17rem",
                                                                    height: "45px",
                                                                    padding: "12px 20px",
                                                                    boxSizing: "border-box",
                                                                    border: "2px solid #ccc",
                                                                    borderRadius: "4px",
                                                                    backgroundColor: "#f8f8f8",
                                                                    fontSize: "16px",
                                                                    resize: "none"
                                                                }} class="w3l_text_box" id="workshop_name" onChange={handleChangeReminder} value={submitReminder.workshop_name} placeholder="Workshop Name" name="Workshop Name" type="text" required />
                                                            </div>


                                                        </div>
                                                    </div>

                                                    <div class="">
                                                        <div class="row">
                                                            <div class="col-md-12">
                                                                <input style={{
                                                                    // width: "17rem",
                                                                    height: "45px",
                                                                    padding: "12px 20px",
                                                                    boxSizing: "border-box",
                                                                    border: "2px solid #ccc",
                                                                    borderRadius: "4px",
                                                                    backgroundColor: "#f8f8f8",
                                                                    fontSize: "16px",
                                                                    resize: "none"
                                                                }} class="w3l_text_box" id="image_url" onChange={handleChangeReminder} value={submitReminder.image_url} placeholder="Image URL" name="Image URL" type="text" required />
                                                            </div>


                                                        </div>
                                                    </div>
                                                    {/* <div class="">
                                                        <div class="row">
                                                            <div class="col-md-12">
                                                                <input style={{
                                                                    // width: "17rem",
                                                                    height: "45px",
                                                                    padding: "12px 20px",
                                                                    boxSizing: "border-box",
                                                                    border: "2px solid #ccc",
                                                                    borderRadius: "4px",
                                                                    backgroundColor: "#f8f8f8",
                                                                    fontSize: "16px",
                                                                    resize: "none"
                                                                }} class="w3l_text_box" id="text1" onChange={handleChangeReminder} value={submitReminder.text1} placeholder="Text 1" name="Text 1" type="text" required />
                                                            </div>


                                                        </div>
                                                    </div> */}
                                                    {/* <div class="">
                                                        <div class="row">
                                                            <div class="col-md-12">
                                                                <input style={{
                                                                    // width: "17rem",
                                                                    height: "45px",
                                                                    padding: "12px 20px",
                                                                    boxSizing: "border-box",
                                                                    border: "2px solid #ccc",
                                                                    borderRadius: "4px",
                                                                    backgroundColor: "#f8f8f8",
                                                                    fontSize: "16px",
                                                                    resize: "none"
                                                                }} class="w3l_text_box" id="text2" onChange={handleChangeReminder} value={submitReminder.text2} placeholder="Text 2" name="Text 2" type="text" required />
                                                            </div>


                                                        </div>
                                                    </div> */}
                                                    <div class="">
                                                        <div class="row">
                                                            <div class="col-md-6">
                                                                <input style={{
                                                                    // width: "17rem",
                                                                    height: "45px",
                                                                    padding: "12px 20px",
                                                                    boxSizing: "border-box",
                                                                    border: "2px solid #ccc",
                                                                    borderRadius: "4px",
                                                                    backgroundColor: "#f8f8f8",
                                                                    fontSize: "16px",
                                                                    resize: "none"
                                                                }} class="w3l_text_box" id="date" onChange={handleChangeReminder} value={submitReminder.date} placeholder="Date" name="Date" type="text" required />
                                                            </div>

                                                            <div class="col-md-6">
                                                                <input style={{
                                                                    // width: "17rem",
                                                                    height: "45px",
                                                                    padding: "12px 20px",
                                                                    boxSizing: "border-box",
                                                                    border: "2px solid #ccc",
                                                                    borderRadius: "4px",
                                                                    backgroundColor: "#f8f8f8",
                                                                    fontSize: "16px",
                                                                    resize: "none"
                                                                }} class="w3l_text_box" id="time" onChange={handleChangeReminder} value={submitReminder.time} placeholder="Time" name="Time" type="text" required />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* <div class="">
                                                        <div class="row">
                                                            <div class="col-md-12">
                                                                <input style={{
                                                                    // width: "17rem",
                                                                    height: "45px",
                                                                    padding: "12px 20px",
                                                                    boxSizing: "border-box",
                                                                    border: "2px solid #ccc",
                                                                    borderRadius: "4px",
                                                                    backgroundColor: "#f8f8f8",
                                                                    fontSize: "16px",
                                                                    resize: "none"
                                                                }} class="w3l_text_box" id="zoom_link" onChange={handleChangeReminder} value={submitReminder.zoom_link} placeholder="Zoom Link" name="Zoom Link" type="text" required />
                                                            </div>
                                                        </div>
                                                    </div> */}

                                                    <div class="">
                                                        <div class="row">
                                                            {/* <div class="col-md-6">
                                                                <input style={{
                                                                    // width: "17rem",
                                                                    height: "45px",
                                                                    padding: "12px 20px",
                                                                    boxSizing: "border-box",
                                                                    border: "2px solid #ccc",
                                                                    borderRadius: "4px",
                                                                    backgroundColor: "#f8f8f8",
                                                                    fontSize: "16px",
                                                                    resize: "none"
                                                                }} class="w3l_text_box" id="meeting_id" onChange={handleChangeReminder} value={submitReminder.meeting_id} placeholder="Meeting ID" name="Meeting ID" type="text" required />
                                                            </div> */}

                                                            {/* <div class="col-md-6">
                                                                <input style={{
                                                                    // width: "17rem",
                                                                    height: "45px",
                                                                    padding: "12px 20px",
                                                                    boxSizing: "border-box",
                                                                    border: "2px solid #ccc",
                                                                    borderRadius: "4px",
                                                                    backgroundColor: "#f8f8f8",
                                                                    fontSize: "16px",
                                                                    resize: "none"
                                                                }} class="w3l_text_box" id="passcode" onChange={handleChangeReminder} value={submitReminder.passcode} placeholder="Passcode" name="Passcode" type="text" required />
                                                            </div> */}

                                                        </div>
                                                    </div>

                                                    {/* <div class="">
                                                        <div class="row">
                                                            <div class="col-md-12">
                                                                <input style={{
                                                                    // width: "17rem",
                                                                    height: "45px",
                                                                    padding: "12px 20px",
                                                                    boxSizing: "border-box",
                                                                    border: "2px solid #ccc",
                                                                    borderRadius: "4px",
                                                                    backgroundColor: "#f8f8f8",
                                                                    fontSize: "16px",
                                                                    resize: "none"
                                                                }} class="w3l_text_box" id="text3" onChange={handleChangeReminder} value={submitReminder.text3} placeholder="Text 3" name="Text 3" type="text" required />
                                                            </div>


                                                        </div>
                                                    </div> */}

                                                    <div class="modal-footer">
                                                        <a class="btn bg-gradient-info btn-sm mb-0" style={{ color: "white" }} onClick={() => closeModel()}>Close</a>
                                                        {/* <a class="btn bg-gradient-success btn-sm mb-0" style={{ color: "white" }}>Submit</a> */}
                                                        <button type='submit' class="btn bg-gradient-danger btn-sm mb-0" style={{ backgroundColor: "green", color: "white" }}>Submit</button>
                                                    </div>

                                                </div>
                                            </form>
                                        </>
                                        :
                                        <>
                                            <form class="" id="" onSubmit={handleSubmitPush}>
                                                <div class="">
                                                    <div class="" style={{ textAlign: "center", }}><span style={{ color: "black", fontSize: "25px", fontWeight: "700", textTransform: "uppercase" }}>Send Push Notification</span></div>
                                                    <div class="" style={{ textAlign: "center", color: "black", paddingTop: "10px" }}>
                                                    </div>

                                                    <div class="">
                                                        <div class="row">
                                                            <div class="col-md-12">
                                                                <input style={{
                                                                    // width: "17rem",
                                                                    height: "45px",
                                                                    padding: "12px 20px",
                                                                    boxSizing: "border-box",
                                                                    border: "2px solid #ccc",
                                                                    borderRadius: "4px",
                                                                    backgroundColor: "#f8f8f8",
                                                                    fontSize: "16px",
                                                                    resize: "none"
                                                                }} class="w3l_text_box" id="title" onChange={handleChangeReminderP} value={submitPush.title} placeholder="Title" name="Title" type="text" required />
                                                            </div>


                                                        </div>
                                                    </div>

                                                    <div class="">
                                                        <div class="row">
                                                            <div class="col-md-12">
                                                                <input style={{
                                                                    // width: "17rem",
                                                                    height: "45px",
                                                                    padding: "12px 20px",
                                                                    boxSizing: "border-box",
                                                                    border: "2px solid #ccc",
                                                                    borderRadius: "4px",
                                                                    backgroundColor: "#f8f8f8",
                                                                    fontSize: "16px",
                                                                    resize: "none"
                                                                }} class="w3l_text_box" id="description" onChange={handleChangeReminderP} value={submitPush.description} placeholder="Description" name="Description" type="text" required />
                                                            </div>


                                                        </div>
                                                    </div>

                                                    <div class="">
                                                        <div class="row">
                                                            <div class="col-md-12">
                                                                <input style={{
                                                                    // width: "17rem",
                                                                    height: "45px",
                                                                    padding: "12px 20px",
                                                                    boxSizing: "border-box",
                                                                    border: "2px solid #ccc",
                                                                    borderRadius: "4px",
                                                                    backgroundColor: "#f8f8f8",
                                                                    fontSize: "16px",
                                                                    resize: "none"
                                                                }} class="w3l_text_box" id="image_url" onChange={handleChangeReminderP} value={submitPush.image_url} placeholder="Image URL" name="Image URL" type="text" />
                                                            </div>


                                                        </div>
                                                    </div>




                                                    <div class="">
                                                        <div class="row">
                                                            <div class="col-md-6">
                                                                <input style={{
                                                                    // width: "17rem",
                                                                    height: "45px",
                                                                    padding: "12px 20px",
                                                                    boxSizing: "border-box",
                                                                    border: "2px solid #ccc",
                                                                    borderRadius: "4px",
                                                                    backgroundColor: "#f8f8f8",
                                                                    fontSize: "16px",
                                                                    resize: "none"
                                                                }} class="w3l_text_box" id="type" onChange={handleChangeReminderP} value={submitPush.type} placeholder="Type" name="Type" type="text" required />
                                                            </div>

                                                            <div class="col-md-6">
                                                                <input style={{
                                                                    // width: "17rem",
                                                                    height: "45px",
                                                                    padding: "12px 20px",
                                                                    boxSizing: "border-box",
                                                                    border: "2px solid #ccc",
                                                                    borderRadius: "4px",
                                                                    backgroundColor: "#f8f8f8",
                                                                    fontSize: "16px",
                                                                    resize: "none"
                                                                }} class="w3l_text_box" id="id" onChange={handleChangeReminderP} value={submitPush.id} placeholder="ID" name="ID" type="text" required />
                                                            </div>

                                                        </div>
                                                    </div>


                                                    <div class="modal-footer">
                                                        <a class="btn bg-gradient-info btn-sm mb-0" style={{ color: "white" }} onClick={() => closeModel()}>Close</a>
                                                        {/* <a class="btn bg-gradient-success btn-sm mb-0" style={{ color: "white" }}>Submit</a> */}
                                                        <button type='submit' class="btn bg-gradient-danger btn-sm mb-0" style={{ backgroundColor: "green", color: "white" }}>Send</button>
                                                    </div>

                                                </div>
                                            </form>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default WorkshopReminder;