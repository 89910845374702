import React, { useState, useEffect } from "react";
import Swal from 'sweetalert2'
import db from "./Firebase";
import { storage } from './Firebase';
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import Moment from 'moment';
import { extendMoment } from 'moment-range';

function AddPincode() {

    const moment = extendMoment(Moment);
    const initialFormStateFeedback = { pincode: '', id: '', status: '', authorization_code: '', created_date: new Date() }
    const [submit, setSubmit] = useState(initialFormStateFeedback)
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [show, setshow] = useState(true);
    const [addPincode, setAddPincode] = useState(false);
    const [progresspercent, setProgresspercent] = useState(0);

    const [editID, setEditID] = useState("");

    const handleChange = (e) => {
        const { id, value } = e.target
        setSubmit({ ...submit, [id]: value })
    }

    const reset = () => {
        setSubmit({ pincode: '', id: '', status: '', authorization_code: '', created_date: new Date() })
    }



    const openModalNew = () => {
        window.openModelNew();
    }

    const openModalShow = () => {
        window.openModelshow();
    }

    useEffect(() => {
        db.collection("delivery_pincodes").onSnapshot((snapshot) => {

            setData(
                snapshot.docs.map((doc) => ({
                    id: doc.id,
                    data: doc.data(),
                }))
            );
        });

    }, []);


    const ids = ["rahul_122002", "ashutosh_560001", "mrinal_122004"];

    const handleSubmit = (e) => {
        setIsLoading(true)
        e.preventDefault();
        if (ids.includes(submit.authorization_code)) {
            db.collection("delivery_pincodes").add({
                pincode: submit.pincode,
                status: true,
                created_date: submit.created_date
            })
                .then(() => {
                    console.log('Added successfull');
                    const Toast = Swal.mixin({
                        toast: true,
                        background: '#69aba6',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', Swal.stopTimer)
                            toast.addEventListener('mouseleave', Swal.resumeTimer)
                        }
                    })
                    setIsLoading(false);

                    Toast.fire({
                        icon: 'success',
                        title: 'Your Pincode is added'
                    })
                    setSubmit({ pincode: '', id: '', status: '', authorization_code: '', created_date: new Date() })
                });

        } else {
            alert("You are not authorization to perform this action");
            setIsLoading(false);
        }

    }


    const changeStatus = (data, id) => {
        setSubmit({ id: id, status: data.status })
        openModalShow();
        // alert(submit.status)
    }

    const deleteData = (id) => {
        db.collection("delivery_pincodes").doc(id).delete().then(() => {
            console.log('Deleted Successfully');
            const Toast = Swal.mixin({
                toast: true,
                background: '#69aba6',
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            })
            Toast.fire({
                icon: 'success',
                title: 'Deleted'
            })
            openModalNew()
            setshow(true)
        })
    }

    const handleSubmitStatus = (e) => {

        e.preventDefault()
        db.collection("delivery_pincodes").doc(submit.id).update({
            'status': submit.status == true ? false : true,
        }).then(() => {
            console.log('Submitted Successfully');
            const Toast = Swal.mixin({
                toast: true,
                background: '#69aba6',
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            })
            Toast.fire({
                icon: 'success',
                title: 'Status Changed'
            })
            openModalNew()
            setshow(true)
        })

    }
    return (
        <>
            {/* <h2>Responsive Table</h2> */}
            {addPincode ? <>
                <div id="small-dialog" class="m-3">
                    <div class="w3layouts_payment_online_form">
                        <form class="" id="" onSubmit={handleSubmit}>
                            <h4>Add Pincodes</h4>
                            <ul>
                                <li><input class="w3l_text_box" id="pincode" onChange={handleChange} value={submit.pincode} placeholder="Pincode" name="Pincode" type="text" required />
                                </li>
                                <li>
                                    <input class="w3l_text_box" id="authorization_code" placeholder="Authorization Code" onChange={handleChange} value={submit.authorization_code} name="Authorization Code" type="text" required />
                                </li>
                            </ul>

                            <ul class="w3_payment_sendbtns">
                                <li><input type="reset" data-dismiss="modal" aria-label="Close" value="Cancel" onClick={() => setAddPincode(false)} /></li>
                                {isLoading == false ? <><li><input type="submit" value="Submit" /></li></> : <></>}
                            </ul>
                            <div class="clearfix"> </div>
                        </form>
                    </div>
                </div>
            </>
                :
                <></>
            }
            <div class="table-wrapper">
                {addPincode ? <></> : <> <div class="row" style={{ float: "right" }}><button type="button" class="btn btn-sm" style={{ backgroundColor: "cadetblue", color: "white", marginRight: "1rem", marginBottom: "10px" }} onClick={() => setAddPincode(true)}>Add Pincode</button></div></>}
                <table class="fl-table">
                    <thead>
                        <tr>
                            <th>Sr. No</th>
                            <th>Pincode</th>
                            <th>created_date</th>
                            <th>Status</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data?.map(({ id, data }, index) => (
                            <>
                                <tr>
                                    <td>{index + 1}</td>
                                    <td>{data.pincode}</td>
                                    <td>{moment(data.created_date.toDate().toISOString()).format("MM/DD/YY, h:mm a")}</td>
                                    <td>{data.status == true ? <span style={{ color: "green", fontSize: "13px", fontWeight: "700" }}>True</span> : <span style={{ color: "red", fontSize: "13px", fontWeight: "700" }}>False</span>}</td>
                                    <td><span style={{ color: "orange", fontSize: "13px", fontWeight: "700", cursor: "pointer" }} onClick={() => changeStatus(data, id)}>Change Status</span></td>
                                    {/* <td><img src={data.image_url} alt="consumables" width="100" /></td>
                                    <td>{data.brand_name}</td>
                                    <td>{data.price}</td>
                                    <td>{data.type}</td>
                                    <td>{data.quantity}</td>
                                    <td>{data.seller}</td>
                                    <td><button onClick={() => edit(data, id)} type="button" style={{ background: "#007bff" }} class="btn btn-primary">Edit</button></td> */}
                                    {/* <td><button onClick={() => openModalNew()} type="button" style={{ background: "#007bff" }} class="btn btn-primary">Edit</button></td> */}
                                </tr>
                            </>
                        ))}


                    </tbody>
                </table>
            </div>

            <div class="myId modal fade bd-example-modal-lg" id="referParlour" tabindex="-1" role="dialog" aria-labelledby="referParlourTitle" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div class="modal-content">

                        <div class="modal-body">
                            <>
                                <div id="small-dialog" class="">
                                    <div class="w3layouts_payment_online_form">
                                        <form class="" id="" onSubmit={handleSubmitStatus}>
                                            <h4>Change Pincode Status</h4>

                                            <ul class="w3_payment_sendbtns">
                                                <li><input type="reset" data-dismiss="modal" aria-label="Close" value="Delete" onClick={() => deleteData(submit.id)} /></li>
                                                <li><input type="reset" data-dismiss="modal" aria-label="Close" value="NO" style={{ marginRight: "1rem" }} /></li>
                                                <li></li>
                                                <li><input type="submit" id="btnSave" value="Yes" /></li>
                                            </ul>
                                            <div class="clearfix"> </div>
                                        </form>
                                    </div>
                                </div>
                            </>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}

export default AddPincode
