import React, { useEffect, useState } from "react";
import { auth, provider } from "./Firebase";
import { signInWithPopup } from "firebase/auth";
import IsLoggedIn from "./IsLoggedIn";

function GoogleLogin() {
    const [value, setValue] = useState('')
    const handleClick = () => {
        signInWithPopup(auth, provider).then((data) => {
            setValue(data.user.email)
            localStorage.setItem("email", data.user.email)
        })
    }

    useEffect(() => {
        setValue(localStorage.getItem('email'))
    })

    return (
        <div>
            {value ? <IsLoggedIn /> :
                <button onClick={handleClick}>Signin With Google</button>
            }
        </div>
    );
}

export default GoogleLogin;