import React, { useState, useEffect } from 'react';
import { useNavigate, Link, useParams } from 'react-router-dom';
import db from "./Firebase";
import { collection, getDocs, query, limit } from 'firebase/firestore/lite';
import Moment from 'moment';
import { extendMoment } from 'moment-range';

function AdminPanel() {
    const navigate = useNavigate();
    const moment = extendMoment(Moment);
    const [activeIndex, setActiveIndex] = useState(null);
    const [showAppData, setShowAppData] = useState(false);
    const [showRequestData, setShowRequestData] = useState(false);
    const [showCreateData, setShowCreateData] = useState(false);
    const [view, setView] = useState(false);
    const [activeTabIndex, setActiveTabIndex] = useState(0);
    const tabData = [
        { label: 'View App Data', content: 'Content 1' },
        { label: 'View All Requests', content: 'Content 2' },
    ];


    const [totalCount, setTotalCount] = useState(0);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const snapshot = await getDocs(collection(db, "personal_details"));
                const count = snapshot.size; // Get the total count
                setTotalCount(count);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, []);


    const logout = () => {
        localStorage.removeItem("email");
        localStorage.removeItem("password");
        localStorage.removeItem("remember");
        navigate('/signin')
    };






    return (
        <>
            <div id="wrapper">


                <div id="sidebar-wrapper">
                    <ul class="sidebar-nav">
                        <span style={{ cursor: "pointer" }} class=" text-white" > <li class="nav-item mt-3">
                            <h6 class=" text-uppercase text-xs text-white font-weight-bolder">Home</h6>
                        </li>
                        </span>
                        {/* <Link class={activeIndex === 21 ? "nav-link text-white active bg-gradient-primary" : "nav-link text-white"} to="/" onClick={() => setActiveIndex(21)}>
                            <div class="text-white text-center me-2 d-flex align-items-center justify-content-center">
                                    <i class="material-icons opacity-10">format_textdirection_r_to_l</i>
                                </div>
                            <span class="nav-link-text ms-1">Portfolio Analyser</span>
                        </Link> */}
                        {/* <Link class={activeIndex === 22 ? "nav-link text-white active bg-gradient-primary" : "nav-link text-white"} to="/" onClick={() => setActiveIndex(22)}>
                            <div class="text-white text-center me-2 d-flex align-items-center justify-content-center">
                                    <i class="material-icons opacity-10">format_textdirection_r_to_l</i>
                                </div>
                            <span class="nav-link-text ms-1">Portfolio Reviews</span>
                        </Link> */}
                        {/* <Link class={activeIndex === 23 ? "nav-link text-white active bg-gradient-primary" : "nav-link text-white"} to="/" onClick={() => setActiveIndex(23)}>
                            <div class="text-white text-center me-2 d-flex align-items-center justify-content-center">
                                    <i class="material-icons opacity-10">format_textdirection_r_to_l</i>
                                </div>
                            <span class="nav-link-text ms-1">All Appointments</span>
                        </Link> */}
                        <span style={{ cursor: "pointer" }} class=" text-white" to="/" onClick={() => showAppData ? setShowAppData(false) : setShowAppData(true)}> <li class="nav-item mt-3">
                            <h6 class=" text-uppercase text-xs text-white font-weight-bolder">View App Data {showAppData ? <><i class="fa fa-arrow-down ml-4" aria-hidden="true"></i></> : <><i class="fa fa-arrow-right ml-4" aria-hidden="true"></i></>}</h6>
                        </li>
                        </span>




                        {showAppData ? <><li class="nav-item">
                            <Link class={activeIndex === 7 ? "nav-link text-white active bg-gradient-primary" : "nav-link text-white"} to="/users_profile">
                                {/* <div class="text-white text-center me-2 d-flex align-items-center justify-content-center">
                                    <i class="material-icons opacity-10">format_textdirection_r_to_l</i>
                                </div> */}
                                <span class="nav-link-text ms-1">Users Profile</span>
                            </Link>
                        </li> </> : null}
                        {showAppData ? <><li class="nav-item">
                            <Link class={activeIndex === 8 ? "nav-link text-white active bg-gradient-primary" : "nav-link text-white"} to="/portfolio_data">
                                {/* <div class="text-white text-center me-2 d-flex align-items-center justify-content-center">
                                    <i class="material-icons opacity-10">format_textdirection_r_to_l</i>
                                </div> */}
                                <span class="nav-link-text ms-1">Portfolio</span>
                            </Link>
                        </li> </> : null}

                        {showAppData ? <><li class="nav-item">
                            <Link class={activeIndex === 8 ? "nav-link text-white active bg-gradient-primary" : "nav-link text-white"} to="/portfolio_analyser">
                                {/* <div class="text-white text-center me-2 d-flex align-items-center justify-content-center">
                                    <i class="material-icons opacity-10">format_textdirection_r_to_l</i>
                                </div> */}
                                <span class="nav-link-text ms-1">Portfolio Analyser</span>
                            </Link>
                        </li> </> : null}


                        {showAppData ? <><li class="nav-item">
                            <Link class={activeIndex === 8 ? "nav-link text-white active bg-gradient-primary" : "nav-link text-white"} to="/portfolio_reviews">
                                {/* <div class="text-white text-center me-2 d-flex align-items-center justify-content-center">
                                    <i class="material-icons opacity-10">format_textdirection_r_to_l</i>
                                </div> */}
                                <span class="nav-link-text ms-1">Portfolio Reviews</span>
                            </Link>
                        </li> </> : null}

                        {showAppData ? <><li class="nav-item">
                            <Link class={activeIndex === 28 ? "nav-link text-white active bg-gradient-primary" : "nav-link text-white"} to="/portfolio_full_data">
                                {/* <div class="text-white text-center me-2 d-flex align-items-center justify-content-center">
                                    <i class="material-icons opacity-10">format_textdirection_r_to_l</i>
                                </div> */}
                                <span class="nav-link-text ms-1">Portfolio All Data</span>
                            </Link>
                        </li> </> : null}

                        {showAppData ? <><li class="nav-item">
                            <Link class={activeIndex === 8 ? "nav-link text-white active bg-gradient-primary" : "nav-link text-white"} to="/appointments">
                                {/* <div class="text-white text-center me-2 d-flex align-items-center justify-content-center">
                                    <i class="material-icons opacity-10">format_textdirection_r_to_l</i>
                                </div> */}
                                <span class="nav-link-text ms-1">All Appointments</span>
                            </Link>
                        </li> </> : null}

                        {showAppData ? <><li class="nav-item">
                            <Link class={activeIndex === 8 ? "nav-link text-white active bg-gradient-primary" : "nav-link text-white"} to="/appointment_booked">
                                {/* <div class="text-white text-center me-2 d-flex align-items-center justify-content-center">
                                    <i class="material-icons opacity-10">format_textdirection_r_to_l</i>
                                </div> */}
                                <span class="nav-link-text ms-1">Booked Appointments</span>
                            </Link>
                        </li> </> : null}



                        {showAppData ? <><li class="nav-item">
                            <Link class={activeIndex === 9 ? "nav-link text-white active bg-gradient-primary" : "nav-link text-white"} to="/customer_data">
                                {/* <div class="text-white text-center me-2 d-flex align-items-center justify-content-center">
                                    <i class="material-icons opacity-10">format_textdirection_r_to_l</i>
                                </div> */}
                                <span class="nav-link-text ms-1">Customer Data</span>
                            </Link>
                        </li> </> : null}

                        {showAppData ? <><li class="nav-item">
                            <Link class={activeIndex === 10 ? "nav-link text-white active bg-gradient-primary" : "nav-link text-white"} to="/referred_courses">
                                {/* <div class="text-white text-center me-2 d-flex align-items-center justify-content-center">
                                    <i class="material-icons opacity-10">format_textdirection_r_to_l</i>
                                </div> */}
                                <span class="nav-link-text ms-1">Referred Courses</span>
                            </Link>
                        </li> </> : null}





                        {showAppData ? <><li class="nav-item">
                            <Link class={activeIndex === 28 ? "nav-link text-white active bg-gradient-primary" : "nav-link text-white"} to="/location_data">
                                {/* <div class="text-white text-center me-2 d-flex align-items-center justify-content-center">
                                    <i class="material-icons opacity-10">format_textdirection_r_to_l</i>
                                </div> */}
                                <span class="nav-link-text ms-1">User's Location</span>
                            </Link>
                        </li> </> : null}



                        {showAppData ? <><li class="nav-item">
                            <Link class={activeIndex === 39 ? "nav-link text-white active bg-gradient-primary" : "nav-link text-white"} to="/website_message">
                                {/* <div class="text-white text-center me-2 d-flex align-items-center justify-content-center">
                                    <i class="material-icons opacity-10">format_textdirection_r_to_l</i>
                                </div> */}
                                <span class="nav-link-text ms-1">Website Message</span>
                            </Link>
                        </li> </> : null}

                        {showAppData ? <><li class="nav-item">
                            <Link class={activeIndex === 39 ? "nav-link text-white active bg-gradient-primary" : "nav-link text-white"} to="/app_feedback">
                                {/* <div class="text-white text-center me-2 d-flex align-items-center justify-content-center">
                                    <i class="material-icons opacity-10">format_textdirection_r_to_l</i>
                                </div> */}
                                <span class="nav-link-text ms-1">App Feedback</span>
                            </Link>
                        </li> </> : null}

                        {showAppData ? <><li class="nav-item">
                            <Link class={activeIndex === 40 ? "nav-link text-white active bg-gradient-primary" : "nav-link text-white"} to="/appointment_analyser">
                                {/* <div class="text-white text-center me-2 d-flex align-items-center justify-content-center">
                                    <i class="material-icons opacity-10">format_textdirection_r_to_l</i>
                                </div> */}
                                <span class="nav-link-text ms-1">Appointment Analyser</span>
                            </Link>
                        </li> </> : null}

                        {showAppData ? <><li class="nav-item">
                            <Link class={activeIndex === 41 ? "nav-link text-white active bg-gradient-primary" : "nav-link text-white"} to="/my_business">
                                {/* <div class="text-white text-center me-2 d-flex align-items-center justify-content-center">
                                    <i class="material-icons opacity-10">format_textdirection_r_to_l</i>
                                </div> */}
                                <span class="nav-link-text ms-1">My Business</span>
                            </Link>
                        </li> </> : null}

                        {showAppData ? <><li class="nav-item">
                            <Link class={activeIndex === 49 ? "nav-link text-white active bg-gradient-primary" : "nav-link text-white"} to="/clicked_logs">
                                {/* <div class="text-white text-center me-2 d-flex align-items-center justify-content-center">
                                    <i class="material-icons opacity-10">format_textdirection_r_to_l</i>
                                </div> */}
                                <span class="nav-link-text ms-1">Clicked Logs</span>
                            </Link>
                        </li> </> : null}

                        {showAppData ? <><li class="nav-item">
                            <Link class={activeIndex === 79 ? "nav-link text-white active bg-gradient-primary" : "nav-link text-white"} to="/all_certificates">
                                {/* <div class="text-white text-center me-2 d-flex align-items-center justify-content-center">
                                    <i class="material-icons opacity-10">format_textdirection_r_to_l</i>
                                </div> */}
                                <span class="nav-link-text ms-1">All Certificates</span>
                            </Link>
                        </li> </> : null}

                        {showAppData ? <><li class="nav-item">
                            <Link class={activeIndex === 89 ? "nav-link text-white active bg-gradient-primary" : "nav-link text-white"} to="/workshop_feedback">
                                {/* <div class="text-white text-center me-2 d-flex align-items-center justify-content-center">
                                    <i class="material-icons opacity-10">format_textdirection_r_to_l</i>
                                </div> */}
                                <span class="nav-link-text ms-1">Workshop Feedback</span>
                            </Link>
                        </li> </> : null}

                        {showAppData ? <><li class="nav-item">
                            <Link class={activeIndex === 899 ? "nav-link text-white active bg-gradient-primary" : "nav-link text-white"} to="/workshop_payment">
                                {/* <div class="text-white text-center me-2 d-flex align-items-center justify-content-center">
                                    <i class="material-icons opacity-10">format_textdirection_r_to_l</i>
                                </div> */}
                                <span class="nav-link-text ms-1">Workshops Payment</span>
                            </Link>
                        </li> </> : null}

                        {showAppData ? <><li class="nav-item">
                            <Link class={activeIndex === 99 ? "nav-link text-white active bg-gradient-primary" : "nav-link text-white"} to="/expenses_list">
                                {/* <div class="text-white text-center me-2 d-flex align-items-center justify-content-center">
                                    <i class="material-icons opacity-10">format_textdirection_r_to_l</i>
                                </div> */}
                                <span class="nav-link-text ms-1">Expense List</span>
                            </Link>
                        </li> </> : null}

                        {showAppData ? <><li class="nav-item">
                            <Link class={activeIndex === 991 ? "nav-link text-white active bg-gradient-primary" : "nav-link text-white"} to="/workshop_registration_count">
                                {/* <div class="text-white text-center me-2 d-flex align-items-center justify-content-center">
                                    <i class="material-icons opacity-10">format_textdirection_r_to_l</i>
                                </div> */}
                                <span class="nav-link-text ms-1">Registration Count</span>
                            </Link>
                        </li> </> : null}
                        {showAppData ? <><li class="nav-item">
                            <Link class={activeIndex === 9931 ? "nav-link text-white active bg-gradient-primary" : "nav-link text-white"} to="/app_referral">
                                {/* <div class="text-white text-center me-2 d-flex align-items-center justify-content-center">
                                    <i class="material-icons opacity-10">format_textdirection_r_to_l</i>
                                </div> */}
                                <span class="nav-link-text ms-1">App Referral</span>
                            </Link>
                        </li> </> : null}

                        {showAppData ? <><li class="nav-item">
                            <Link class={activeIndex === 9932 ? "nav-link text-white active bg-gradient-primary" : "nav-link text-white"} to="/feedback_reply">
                                {/* <div class="text-white text-center me-2 d-flex align-items-center justify-content-center">
                                    <i class="material-icons opacity-10">format_textdirection_r_to_l</i>
                                </div> */}
                                <span class="nav-link-text ms-1">Feedback Reply</span>
                            </Link>
                        </li> </> : null}

                        {showAppData ? <><li class="nav-item">
                            <Link class={activeIndex === 9932 ? "nav-link text-white active bg-gradient-primary" : "nav-link text-white"} to="/user_last_checkin">
                                {/* <div class="text-white text-center me-2 d-flex align-items-center justify-content-center">
                                    <i class="material-icons opacity-10">format_textdirection_r_to_l</i>
                                </div> */}
                                <span class="nav-link-text ms-1">Users Checkin</span>
                            </Link>
                        </li> </> : null}

                        {showAppData ? <><li class="nav-item">
                            <Link class={activeIndex === 9932 ? "nav-link text-white active bg-gradient-primary" : "nav-link text-white"} to="/login_logs">
                                {/* <div class="text-white text-center me-2 d-flex align-items-center justify-content-center">
                                    <i class="material-icons opacity-10">format_textdirection_r_to_l</i>
                                </div> */}
                                <span class="nav-link-text ms-1">Login Logs</span>
                            </Link>
                        </li> </> : null}

                        {showAppData ? <><li class="nav-item">
                            <Link class={activeIndex === 9932 ? "nav-link text-white active bg-gradient-primary" : "nav-link text-white"} to="/downloaded_certificate">
                                {/* <div class="text-white text-center me-2 d-flex align-items-center justify-content-center">
                                    <i class="material-icons opacity-10">format_textdirection_r_to_l</i>
                                </div> */}
                                <span class="nav-link-text ms-1">Downloaded Cert.</span>
                            </Link>
                        </li> </> : null}


                        {showAppData ? <><li class="nav-item">
                            <Link class={activeIndex === 9939 ? "nav-link text-white active bg-gradient-primary" : "nav-link text-white"} to="/topic_request">
                                {/* <div class="text-white text-center me-2 d-flex align-items-center justify-content-center">
                                    <i class="material-icons opacity-10">format_textdirection_r_to_l</i>
                                </div> */}
                                <span class="nav-link-text ms-1">Topic Request</span>
                            </Link>
                        </li> </> : null}





                        <span style={{ cursor: "pointer" }} class=" text-white" to="/" onClick={() => showRequestData ? setShowRequestData(false) : setShowRequestData(true)}>   <li class="nav-item mt-3">
                            <h6 class=" text-uppercase text-xs text-white font-weight-bolder">View All request {showRequestData ? <><i class="fa fa-arrow-down ml-4" aria-hidden="true"></i></> : <><i class="fa fa-arrow-right ml-4" aria-hidden="true"></i></>}</h6>
                        </li> </span>
                        {showRequestData ? <> <li class="nav-item">
                            <Link class={activeIndex === 11 ? "nav-link text-white active bg-gradient-primary" : "nav-link text-white"} to="/beauty_kit_requests">
                                {/* <div class="text-white text-center me-2 d-flex align-items-center justify-content-center">
                                    <i class="material-icons opacity-10">table_view</i>
                                </div> */}
                                <span class="nav-link-text ms-1">Beauty Kit Requests</span>
                            </Link>
                        </li> </> : null}

                        {showRequestData ? <> <li class="nav-item">
                            <Link class={activeIndex === 1199 ? "nav-link text-white active bg-gradient-primary" : "nav-link text-white"} to="/upcoming_session">
                                {/* <div class="text-white text-center me-2 d-flex align-items-center justify-content-center">
                                    <i class="material-icons opacity-10">table_view</i>
                                </div> */}
                                <span class="nav-link-text ms-1">Upcoming Session</span>
                            </Link>
                        </li> </> : null}


                        {showRequestData ? <> <li class="nav-item">
                            <Link class={activeIndex === 12 ? "nav-link text-white active bg-gradient-primary" : "nav-link text-white"} to="/book_demo_requests">
                                {/* <div class="text-white text-center me-2 d-flex align-items-center justify-content-center">
                                    <i class="material-icons opacity-10">receipt_long</i>
                                </div> */}
                                <span class="nav-link-text ms-1">Book Demo Requests</span>
                            </Link>
                        </li> </> : null}
                        {showRequestData ? <> <li class="nav-item">
                            <Link class={activeIndex === 13 ? "nav-link text-white active bg-gradient-primary" : "nav-link text-white"} to="/business_card_request" >
                                {/* <div class="text-white text-center me-2 d-flex align-items-center justify-content-center">
                                    <i class="material-icons opacity-10">view_in_ar</i>
                                </div> */}
                                <span class="nav-link-text ms-1">Business card Requests</span>
                            </Link>
                        </li> </> : null}
                        {showRequestData ? <> <li class="nav-item">
                            <Link class={activeIndex === 14 ? "nav-link text-white active bg-gradient-primary" : "nav-link text-white"} to="/hire_request">
                                {/* <div class="text-white text-center me-2 d-flex align-items-center justify-content-center">
                                    <i class="material-icons opacity-10">format_textdirection_r_to_l</i>
                                </div> */}
                                <span class="nav-link-text ms-1">Hire Requests</span>
                            </Link>
                        </li> </> : null}
                        {showRequestData ? <> <li class="nav-item">
                            <Link class={activeIndex === 14 ? "nav-link text-white active bg-gradient-primary" : "nav-link text-white"} to="/event_request">
                                {/* <div class="text-white text-center me-2 d-flex align-items-center justify-content-center">
                                    <i class="material-icons opacity-10">format_textdirection_r_to_l</i>
                                </div> */}
                                <span class="nav-link-text ms-1">Event Requests</span>
                            </Link>
                        </li> </> : null}
                        {showRequestData ? <> <li class="nav-item">
                            <Link class={activeIndex === 15 ? "nav-link text-white active bg-gradient-primary" : "nav-link text-white"} to="/loan_request">
                                {/* <div class="text-white text-center me-2 d-flex align-items-center justify-content-center">
                                    <i class="material-icons opacity-10">notifications</i>
                                </div> */}
                                <span class="nav-link-text ms-1">Loan Requests</span>
                            </Link>
                        </li> </> : null}
                        {showRequestData ? <> <li class="nav-item">
                            <Link class={activeIndex === 16 ? "nav-link text-white active bg-gradient-primary" : "nav-link text-white"} to="/premium_card_request">
                                {/* <div class="text-white text-center me-2 d-flex align-items-center justify-content-center">
                                    <i class="material-icons opacity-10">format_textdirection_r_to_l</i>
                                </div> */}
                                <span class="nav-link-text ms-1">Premium Card Req.</span>
                            </Link>
                        </li> </> : null}

                        {showRequestData ? <> <li class="nav-item">
                            <Link class={activeIndex === 16 ? "nav-link text-white active bg-gradient-primary" : "nav-link text-white"} to="/premium_smg_request">
                                {/* <div class="text-white text-center me-2 d-flex align-items-center justify-content-center">
                                    <i class="material-icons opacity-10">format_textdirection_r_to_l</i>
                                </div> */}
                                <span class="nav-link-text ms-1">Premium SMG Req.</span>
                            </Link>
                        </li> </> : null}


                        {showRequestData ? <> <li class="nav-item">
                            <Link class={activeIndex === 17 ? "nav-link text-white active bg-gradient-primary" : "nav-link text-white"} to="/workshop_registration">
                                {/* <div class="text-white text-center me-2 d-flex align-items-center justify-content-center">
                                    <i class="material-icons opacity-10">format_textdirection_r_to_l</i>
                                </div> */}
                                <span class="nav-link-text ms-1">Workshop Registration</span>
                            </Link>
                        </li> </> : null}
                        {showRequestData ? <> <li class="nav-item">
                            <Link class={activeIndex === 17 ? "nav-link text-white active bg-gradient-primary" : "nav-link text-white"} to="/workshop_payment">
                                {/* <div class="text-white text-center me-2 d-flex align-items-center justify-content-center">
                                    <i class="material-icons opacity-10">format_textdirection_r_to_l</i>
                                </div> */}
                                <span class="nav-link-text ms-1">Workshop Payment</span>
                            </Link>
                        </li> </> : null}

                        {showRequestData ? <> <li class="nav-item">
                            <Link class={activeIndex === 18 ? "nav-link text-white active bg-gradient-primary" : "nav-link text-white"} to="/workshop">
                                {/* <div class="text-white text-center me-2 d-flex align-items-center justify-content-center">
                                    <i class="material-icons opacity-10">format_textdirection_r_to_l</i>
                                </div> */}
                                <span class="nav-link-text ms-1">All Workshop</span>
                            </Link>
                        </li> </> : null}

                        {showRequestData ? <><li class="nav-item">
                            <Link class={activeIndex === 59 ? "nav-link text-white active bg-gradient-primary" : "nav-link text-white"} to="/workshop_query">
                                {/* <div class="text-white text-center me-2 d-flex align-items-center justify-content-center">
                                    <i class="material-icons opacity-10">format_textdirection_r_to_l</i>
                                </div> */}
                                <span class="nav-link-text ms-1">Workshop Query</span>
                            </Link>
                        </li> </> : null}

                        {showRequestData ? <><li class="nav-item">
                            <Link class={activeIndex === 59 ? "nav-link text-white active bg-gradient-primary" : "nav-link text-white"} to="/workshop_event_request">
                                {/* <div class="text-white text-center me-2 d-flex align-items-center justify-content-center">
                                    <i class="material-icons opacity-10">format_textdirection_r_to_l</i>
                                </div> */}
                                <span class="nav-link-text ms-1">Workshop Event Request</span>
                            </Link>
                        </li> </> : null}

                        {showRequestData ? <> <li class="nav-item">
                            <Link class={activeIndex === 19 ? "nav-link text-white active bg-gradient-primary" : "nav-link text-white"} to="/request_callback">
                                {/* <div class="text-white text-center me-2 d-flex align-items-center justify-content-center">
                                    <i class="material-icons opacity-10">format_textdirection_r_to_l</i>
                                </div> */}
                                <span class="nav-link-text ms-1">Call Back Request</span>
                            </Link>
                        </li> </> : null}

                        {showRequestData ? <> <li class="nav-item">
                            <Link class={activeIndex === 20 ? "nav-link text-white active bg-gradient-primary" : "nav-link text-white"} to="/delete_portfolio_req">
                                {/* <div class="text-white text-center me-2 d-flex align-items-center justify-content-center">
                                    <i class="material-icons opacity-10">format_textdirection_r_to_l</i>
                                </div> */}
                                <span class="nav-link-text ms-1">Delete Portfolio Req.</span>
                            </Link>
                        </li> </> : null}

                        {showRequestData ? <> <li class="nav-item">
                            <Link class={activeIndex === 116 ? "nav-link text-white active bg-gradient-primary" : "nav-link text-white"} to="/banner_request">
                                {/* <div class="text-white text-center me-2 d-flex align-items-center justify-content-center">
                                    <i class="material-icons opacity-10">format_textdirection_r_to_l</i>
                                </div> */}
                                <span class="nav-link-text ms-1">Banner Request</span>
                            </Link>
                        </li> </> : null}

                        {showRequestData ? <> <li class="nav-item">
                            <Link class={activeIndex === 116 ? "nav-link text-white active bg-gradient-primary" : "nav-link text-white"} to="/premium_banner_request">
                                {/* <div class="text-white text-center me-2 d-flex align-items-center justify-content-center">
                                    <i class="material-icons opacity-10">format_textdirection_r_to_l</i>
                                </div> */}
                                <span class="nav-link-text ms-1">Premium Banner Request</span>
                            </Link>
                        </li> </> : null}

                        {showRequestData ? <> <li class="nav-item">
                            <Link class={activeIndex === 117 ? "nav-link text-white active bg-gradient-primary" : "nav-link text-white"} to="/smg_request">
                                {/* <div class="text-white text-center me-2 d-flex align-items-center justify-content-center">
                                    <i class="material-icons opacity-10">format_textdirection_r_to_l</i>
                                </div> */}
                                <span class="nav-link-text ms-1">SMG Request</span>
                            </Link>
                        </li> </> : null}

                        {showRequestData ? <> <li class="nav-item">
                            <Link class={activeIndex === 118 ? "nav-link text-white active bg-gradient-primary" : "nav-link text-white"} to="/whatsapp_business_request">
                                {/* <div class="text-white text-center me-2 d-flex align-items-center justify-content-center">
                                    <i class="material-icons opacity-10">format_textdirection_r_to_l</i>
                                </div> */}
                                <span class="nav-link-text ms-1">WB Request</span>
                            </Link>
                        </li> </> : null}

                        {showRequestData ? <> <li class="nav-item">
                            <Link class={activeIndex === 119 ? "nav-link text-white active bg-gradient-primary" : "nav-link text-white"} to="/google_business_request">
                                {/* <div class="text-white text-center me-2 d-flex align-items-center justify-content-center">
                                    <i class="material-icons opacity-10">format_textdirection_r_to_l</i>
                                </div> */}
                                <span class="nav-link-text ms-1">GB Request</span>
                            </Link>
                        </li> </> : null}


                        {showRequestData ? <> <li class="nav-item">
                            <Link class={activeIndex === 120 ? "nav-link text-white active bg-gradient-primary" : "nav-link text-white"} to="/advertise_feedback">
                                {/* <div class="text-white text-center me-2 d-flex align-items-center justify-content-center">
                                    <i class="material-icons opacity-10">format_textdirection_r_to_l</i>
                                </div> */}
                                <span class="nav-link-text ms-1">Advertise Feedback</span>
                            </Link>
                        </li> </> : null}





                        <span style={{ cursor: "pointer" }} class=" text-white" to="/" onClick={() => showCreateData ? setShowCreateData(false) : setShowCreateData(true)}>   <li class="nav-item mt-3">
                            <h6 class=" text-uppercase text-xs text-white font-weight-bolder">Create {showCreateData ? <><i class="fa fa-arrow-down ml-4" aria-hidden="true"></i></> : <><i class="fa fa-arrow-right ml-4" aria-hidden="true"></i></>}</h6>
                        </li> </span>

                        {showCreateData ? <><li class="nav-item">
                            <Link class={activeIndex === 5 ? "nav-link text-white active bg-gradient-primary" : "nav-link text-white"} to="/guidance_session">
                                {/* <div class="text-white text-center me-2 d-flex align-items-center justify-content-center">
                                    <i class="material-icons opacity-10">notifications</i>
                                </div> */}
                                <span class="nav-link-text ms-1">Guidance Session</span>
                            </Link>
                        </li> </> : null}

                        {showCreateData ? <><li class="nav-item">
                            <Link class={activeIndex === 5 ? "nav-link text-white active bg-gradient-primary" : "nav-link text-white"} to="/workshop_data">
                                {/* <div class="text-white text-center me-2 d-flex align-items-center justify-content-center">
                                    <i class="material-icons opacity-10">notifications</i>
                                </div> */}
                                <span class="nav-link-text ms-1">Workshop</span>
                            </Link>
                        </li> </> : null}

                        {showCreateData ? <><li class="nav-item">
                            <Link class={activeIndex === 6 ? "nav-link text-white active bg-gradient-primary" : "nav-link text-white"} to="/workshop_data_hindi">
                                {/* <div class="text-white text-center me-2 d-flex align-items-center justify-content-center">
                                    <i class="material-icons opacity-10">format_textdirection_r_to_l</i>
                                </div> */}
                                <span class="nav-link-text ms-1">Workshop Hindi</span>
                            </Link>
                        </li> </> : null}

                        {showCreateData ? <> <li class="nav-item">
                            <Link class={activeIndex === 48 ? "nav-link text-white active bg-gradient-primary" : "nav-link text-white"} to="/workshop_reminder">
                                {/* <div class="text-white text-center me-2 d-flex align-items-center justify-content-center">
                                    <i class="material-icons opacity-10">format_textdirection_r_to_l</i>
                                </div> */}
                                <span class="nav-link-text ms-1">Workshop Reminder</span>
                            </Link>
                        </li> </> : null}

                        {showCreateData ? <><li class="nav-item">
                            <Link class={activeIndex === 60 ? "nav-link text-white active bg-gradient-primary" : "nav-link text-white"} to="/pin_workshop">
                                {/* <div class="text-white text-center me-2 d-flex align-items-center justify-content-center">
                                    <i class="material-icons opacity-10">format_textdirection_r_to_l</i>
                                </div> */}
                                <span class="nav-link-text ms-1">Pin Workshop</span>
                            </Link>
                        </li> </> : null}

                        {showCreateData ? <><li class="nav-item">
                            <Link class={activeIndex === 61 ? "nav-link text-white active bg-gradient-primary" : "nav-link text-white"} to="/workshop_attendance">
                                {/* <div class="text-white text-center me-2 d-flex align-items-center justify-content-center">
                                    <i class="material-icons opacity-10">format_textdirection_r_to_l</i>
                                </div> */}
                                <span class="nav-link-text ms-1">Workshop Attendance</span>
                            </Link>
                        </li> </> : null}

                        {showCreateData ? <><li class="nav-item">
                            <Link class={activeIndex === 689 ? "nav-link text-white active bg-gradient-primary" : "nav-link text-white"} to="/workshop_glimpses">
                                {/* <div class="text-white text-center me-2 d-flex align-items-center justify-content-center">
                                    <i class="material-icons opacity-10">format_textdirection_r_to_l</i>
                                </div> */}
                                <span class="nav-link-text ms-1">Workshop Glimpses</span>
                            </Link>
                        </li> </> : null}

                        {showCreateData ? <><li class="nav-item">
                            <Link class={activeIndex === 62 ? "nav-link text-white active bg-gradient-primary" : "nav-link text-white"} to="/workshop_attendance_status">
                                {/* <div class="text-white text-center me-2 d-flex align-items-center justify-content-center">
                                    <i class="material-icons opacity-10">format_textdirection_r_to_l</i>
                                </div> */}
                                <span class="nav-link-text ms-1">Attendance Status </span>
                            </Link>
                        </li> </> : null}


                        {showCreateData ? <> <li class="nav-item">
                            <Link class={activeIndex === 22 ? "nav-link text-white active bg-gradient-primary" : "nav-link text-white"} to="/upload_recorded_video">
                                {/* <div class="text-white text-center me-2 d-flex align-items-center justify-content-center">
                                    <i class="material-icons opacity-10">format_textdirection_r_to_l</i>
                                </div> */}
                                <span class="nav-link-text ms-1">Upload Recorded Session</span>
                            </Link>
                        </li> </> : null}


                        {showCreateData ? <> <li class="nav-item">
                            <Link class={activeIndex === 58 ? "nav-link text-white active bg-gradient-primary" : "nav-link text-white"} to="/upload_certificates">
                                {/* <div class="text-white text-center me-2 d-flex align-items-center justify-content-center">
                                    <i class="material-icons opacity-10">format_textdirection_r_to_l</i>
                                </div> */}
                                <span class="nav-link-text ms-1">Upload Certificate</span>
                            </Link>
                        </li> </> : null}


                        {showCreateData ? <><li class="nav-item">
                            <Link class={activeIndex === 61 ? "nav-link text-white active bg-gradient-primary" : "nav-link text-white"} to="/homepage_poster">
                                {/* <div class="text-white text-center me-2 d-flex align-items-center justify-content-center">
                                    <i class="material-icons opacity-10">format_textdirection_r_to_l</i>
                                </div> */}
                                <span class="nav-link-text ms-1">Homepage Poster</span>
                            </Link>
                        </li> </> : null}

                        {showCreateData ? <><li class="nav-item">
                            <Link class={activeIndex === 10 ? "nav-link text-white active bg-gradient-primary" : "nav-link text-white"} to="/send_whatsapp">
                                {/* <div class="text-white text-center me-2 d-flex align-items-center justify-content-center">
                                    <i class="material-icons opacity-10">format_textdirection_r_to_l</i>
                                </div> */}
                                <span class="nav-link-text ms-1">Send Whatsapp</span>
                            </Link>
                        </li> </> : null}

                        {showCreateData ? <><li class="nav-item">
                            <Link class={activeIndex === 10 ? "nav-link text-white active bg-gradient-primary" : "nav-link text-white"} to="/create_whatsapp_template">
                                {/* <div class="text-white text-center me-2 d-flex align-items-center justify-content-center">
                                    <i class="material-icons opacity-10">format_textdirection_r_to_l</i>
                                </div> */}
                                <span class="nav-link-text ms-1">Create Whatsapp Template</span>
                            </Link>
                        </li> </> : null}

                        {showCreateData ? <><li class="nav-item">
                            <Link class={activeIndex === 0 ? "nav-link text-white active bg-gradient-primary" : "nav-link text-white"} to="/course_data" >
                                {/* <div class="text-white text-center me-2 d-flex align-items-center justify-content-center">
                                    <i class="material-icons opacity-10">dashboard</i>
                                </div> */}
                                <span class="nav-link-text ms-1">Courses</span>
                            </Link>
                        </li> </> : null}
                        {showCreateData ? <><li class="nav-item">
                            <Link class={activeIndex === 1 ? "nav-link text-white active bg-gradient-primary" : "nav-link text-white"} to="/course_data_hindi">
                                {/* <div class="text-white text-center me-2 d-flex align-items-center justify-content-center">
                                    <i class="material-icons opacity-10">table_view</i>
                                </div> */}
                                <span class="nav-link-text ms-1">Course Hindi</span>
                            </Link>
                        </li> </> : null}

                        {showCreateData ? <><li class="nav-item">
                            <Link class={activeIndex === 2 ? "nav-link text-white active bg-gradient-primary" : "nav-link text-white"} to="/promotional_data">
                                {/* <div class="text-white text-center me-2 d-flex align-items-center justify-content-center">
                                    <i class="material-icons opacity-10">receipt_long</i>
                                </div> */}
                                <span class="nav-link-text ms-1">Create an Offer (premade)</span>
                            </Link>
                        </li> </> : null}

                        {showCreateData ? <><li class="nav-item">
                            <Link class={activeIndex === 1225 ? "nav-link text-white active bg-gradient-primary" : "nav-link text-white"} to="/custom_cards">
                                {/* <div class="text-white text-center me-2 d-flex align-items-center justify-content-center">
                                    <i class="material-icons opacity-10">format_textdirection_r_to_l</i>
                                </div> */}
                                <span class="nav-link-text ms-1">Create an Offer (custom) </span>
                            </Link>
                        </li> </> : null}
                        {showCreateData ? <><li class="nav-item">
                            <Link class={activeIndex === 3 ? "nav-link text-white active bg-gradient-primary" : "nav-link text-white"} to="/freebusinesscards_data">
                                {/* <div class="text-white text-center me-2 d-flex align-items-center justify-content-center">
                                    <i class="material-icons opacity-10">view_in_ar</i>
                                </div> */}
                                <span class="nav-link-text ms-1">Add Free Vist. Cards</span>
                            </Link>
                        </li> </> : null}
                        {/* {showCreateData ? <><li class="nav-item">
                            <Link class={activeIndex === 4 ? "nav-link text-white active bg-gradient-primary" : "nav-link text-white"} to="/premiumcards_data">
                             
                                <span class="nav-link-text ms-1">Premium Cards</span>
                            </Link>
                        </li> </> : null} */}

                        {showCreateData ? <><li class="nav-item">
                            <Link class={activeIndex === 38 ? "nav-link text-white active bg-gradient-primary" : "nav-link text-white"} to="/add_slider_images">
                                {/* <div class="text-white text-center me-2 d-flex align-items-center justify-content-center">
                                    <i class="material-icons opacity-10">format_textdirection_r_to_l</i>
                                </div> */}
                                <span class="nav-link-text ms-1">Add Slider Images</span>
                            </Link>
                        </li> </> : null}

                        {showCreateData ? <><li class="nav-item">
                            <Link class={activeIndex === 78 ? "nav-link text-white active bg-gradient-primary" : "nav-link text-white"} to="/workshop_slider_image">
                                {/* <div class="text-white text-center me-2 d-flex align-items-center justify-content-center">
                                    <i class="material-icons opacity-10">format_textdirection_r_to_l</i>
                                </div> */}
                                <span class="nav-link-text ms-1">Add Workshop Slider</span>
                            </Link>
                        </li> </> : null}

                        {showCreateData ? <><li class="nav-item">
                            <Link class={activeIndex === 111 ? "nav-link text-white active bg-gradient-primary" : "nav-link text-white"} to="/banner_design">
                                {/* <div class="text-white text-center me-2 d-flex align-items-center justify-content-center">
                                    <i class="material-icons opacity-10">format_textdirection_r_to_l</i>
                                </div> */}
                                <span class="nav-link-text ms-1">Add Free Banner Design</span>
                            </Link>
                        </li> </> : null}

                        {showCreateData ? <><li class="nav-item">
                            <Link class={activeIndex === 112 ? "nav-link text-white active bg-gradient-primary" : "nav-link text-white"} to="/global_notification">
                                {/* <div class="text-white text-center me-2 d-flex align-items-center justify-content-center">
                                    <i class="material-icons opacity-10">format_textdirection_r_to_l</i>
                                </div> */}
                                <span class="nav-link-text ms-1">Add Global Notification</span>
                            </Link>
                        </li> </> : null}

                        {showCreateData ? <><li class="nav-item">
                            <Link class={activeIndex === 113 ? "nav-link text-white active bg-gradient-primary" : "nav-link text-white"} to="/previous_card_work">
                                {/* <div class="text-white text-center me-2 d-flex align-items-center justify-content-center">
                                    <i class="material-icons opacity-10">format_textdirection_r_to_l</i>
                                </div> */}
                                <span class="nav-link-text ms-1">Add Prem. Vist Cards</span>
                            </Link>
                        </li> </> : null}



                        {showCreateData ? <><li class="nav-item">
                            <Link class={activeIndex === 115 ? "nav-link text-white active bg-gradient-primary" : "nav-link text-white"} to="/advertise_download">
                                {/* <div class="text-white text-center me-2 d-flex align-items-center justify-content-center">
                                    <i class="material-icons opacity-10">format_textdirection_r_to_l</i>
                                </div> */}
                                <span class="nav-link-text ms-1">Advertise Download</span>
                            </Link>
                        </li> </> : null}

                        {showCreateData ? <><li class="nav-item">
                            <Link class={activeIndex === 115 ? "nav-link text-white active bg-gradient-primary" : "nav-link text-white"} to="/session_slider_images">
                                {/* <div class="text-white text-center me-2 d-flex align-items-center justify-content-center">
                                    <i class="material-icons opacity-10">format_textdirection_r_to_l</i>
                                </div> */}
                                <span class="nav-link-text ms-1">After Session Slider</span>
                            </Link>
                        </li> </> : null}


                        {showCreateData ? <><li class="nav-item">
                            <Link class={activeIndex === 1225 ? "nav-link text-white active bg-gradient-primary" : "nav-link text-white"} to="/workshop_code">
                                {/* <div class="text-white text-center me-2 d-flex align-items-center justify-content-center">
                                    <i class="material-icons opacity-10">format_textdirection_r_to_l</i>
                                </div> */}
                                <span class="nav-link-text ms-1">Workshop Code</span>
                            </Link>
                        </li> </> : null}


                        {showCreateData ? <><li class="nav-item">
                            <Link class={activeIndex === 1225 ? "nav-link text-white active bg-gradient-primary" : "nav-link text-white"} to="/workshop_tag">
                                {/* <div class="text-white text-center me-2 d-flex align-items-center justify-content-center">
                                    <i class="material-icons opacity-10">format_textdirection_r_to_l</i>
                                </div> */}
                                <span class="nav-link-text ms-1">Workshop Tag</span>
                            </Link>
                        </li> </> : null}

                        {showCreateData ? <><li class="nav-item">
                            <Link class={activeIndex === 1225 ? "nav-link text-white active bg-gradient-primary" : "nav-link text-white"} to="/social_media_graphics">
                                {/* <div class="text-white text-center me-2 d-flex align-items-center justify-content-center">
                                    <i class="material-icons opacity-10">format_textdirection_r_to_l</i>
                                </div> */}
                                <span class="nav-link-text ms-1">Add Free SMG</span>
                            </Link>
                        </li> </> : null}

                        {showCreateData ? <><li class="nav-item">
                            <Link class={activeIndex === 1225 ? "nav-link text-white active bg-gradient-primary" : "nav-link text-white"} to="/advertise_slider">
                                {/* <div class="text-white text-center me-2 d-flex align-items-center justify-content-center">
                                    <i class="material-icons opacity-10">format_textdirection_r_to_l</i>
                                </div> */}
                                <span class="nav-link-text ms-1">Add Adv Slider</span>
                            </Link>
                        </li> </> : null}

                        {showCreateData ? <><li class="nav-item">
                            <Link class={activeIndex === 1225 ? "nav-link text-white active bg-gradient-primary" : "nav-link text-white"} to="/previous_sm">
                                {/* <div class="text-white text-center me-2 d-flex align-items-center justify-content-center">
                                    <i class="material-icons opacity-10">format_textdirection_r_to_l</i>
                                </div> */}
                                <span class="nav-link-text ms-1">Add Prem SMG </span>
                            </Link>
                        </li> </> : null}










                        {/* {showCreateData ? <> <li class="nav-item">
                            <Link class={activeIndex === 60 ? "nav-link text-white active bg-gradient-primary" : "nav-link text-white"} to="/push_notifications">
                              
                                <span class="nav-link-text ms-1">Push Notification</span>
                            </Link>
                        </li> </> : null} */}

                        <span style={{ cursor: "pointer" }} class=" text-white" onClick={() => logout()}> <li class="nav-item mt-3">
                            <h6 class=" text-uppercase text-xs text-white font-weight-bolder">Logout <i class="fa fa-sign-out ml-4" aria-hidden="true" style={{ "fontSize": "20px", "color": "white" }}></i></h6>
                        </li>
                        </span>

                        <li class="nav-item">
                            <div class="text-white text-center me-2 d-flex align-items-center justify-content-center">
                                <i class="material-icons opacity-10"></i>
                            </div>
                            <span class="nav-link-text ms-1"></span>
                        </li>
                        <li class="nav-item">
                            {/* <div class="text-white text-center me-2 d-flex align-items-center justify-content-center">
                                <i class="material-icons opacity-10"></i>
                            </div> */}
                            <span class="nav-link-text ms-1"></span>
                        </li>
                        <li class="nav-item">
                            {/* <div class="text-white text-center me-2 d-flex align-items-center justify-content-center">
                                <i class="material-icons opacity-10"></i>
                            </div> */}
                            <span class="nav-link-text ms-1"></span>
                        </li>
                        <li class="nav-item">
                            {/* <div class="text-white text-center me-2 d-flex align-items-center justify-content-center">
                                <i class="material-icons opacity-10"></i>
                            </div> */}
                            <span class="nav-link-text ms-1"></span>
                        </li>




                    </ul>
                </div>

                <div class="card" style={{ padding: "2rem" }}>
                    <div class="card-header p-2 pt-1" style={{ borderStyle: "none" }}>
                        <div
                            class="icon-md icon-shape bg-gradient-dark shadow-dark text-center border-radius-xl mt-n4 position-absolute">
                            <i class="material-icons opacity-10">weekend</i>
                        </div>
                        <div class="text-end pt-1">
                            <p class="mb-0 text-capitalize" style={{ fontWeight: "bold", fontSize: "13.1px" }}>Apps Download</p>
                            <h4 class="mb-0" style={{ fontWeight: "700", fontSize: "22px" }}>{totalCount}</h4>
                        </div>
                    </div>
                    <hr class="dark horizontal my-0" />

                </div>

                <div style={{ display: "flex", justifyContent: "center" }}>
                    <img src="/images/adminpanel.jpg" class="menu-toggle" alt="Responsive image" style={{ height: "38rem" }} />
                </div>


            </div>
        </>
    )
}

export default AdminPanel;