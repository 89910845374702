import React, { useState, useEffect } from 'react';
import * as XLSX from 'xlsx';
import DataTable from 'react-data-table-component';
// import firebase from "firebase";
import db from "./Firebase";
// import moment from 'moment';
import Swal from 'sweetalert2'
import { Link } from 'react-router-dom';
import Unregistereduser from './UnregisteredUser';

function Admin() {
    const API = process.env.REACT_APP_BACKEND;
    const storage = JSON.parse(sessionStorage.getItem('jwt'));
    const token = storage ? storage.token : "";
    const reg_no = storage ? storage.user.reg_no : "";
    let A_session = sessionStorage.getItem('session');
    const [columns, setColumns] = useState([]);
    const [data, setData] = useState([]);

    const initialFormState = { username: '', password: '' }
    const [submit, setSubmit] = useState(initialFormState)
    const handleChange = (e) => {
        const { id, value } = e.target
        setSubmit({ ...submit, [id]: value })
    }

    const [Show, setShow] = useState(false);
    const [ShowProgress, setShowProgress] = useState(false);

    const [num, setNum] = useState(0);

    const handleSubmit = (e) => {
        // alert(JSON.stringify(submit))
        e.preventDefault();
        if (submit.username == 'admin' && submit.password == 'admin@123') {
            setShow(true)
        } else {
            const Toast = Swal.mixin({
                toast: true,
                background: '#69aba6',
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            })

            Toast.fire({
                icon: 'danser',
                title: 'Invalid Username Password !!!'
            })
            setSubmit({ username: '', password: '' })
        }

    }

    const processData = dataString => {
        const dataStringLines = dataString.split(/\r\n|\n/);
        const headers = dataStringLines[0].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);

        const list = [];
        for (let i = 1; i < dataStringLines.length; i++) {
            const row = dataStringLines[i].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);
            if (headers && row.length == headers.length) {
                const obj = {};
                for (let j = 0; j < headers.length; j++) {
                    let d = row[j];
                    if (d.length > 0) {
                        if (d[0] == '"')
                            d = d.substring(1, d.length - 1);
                        if (d[d.length - 1] == '"')
                            d = d.substring(d.length - 2, 1);
                    }
                    if (headers[j]) {
                        obj[headers[j]] = d;
                    }
                }

                // remove the blank rows
                if (Object.values(obj).filter(x => x).length > 0) {
                    list.push(obj);
                }
            }
        }

        // prepare columns list from headers
        const columns = headers.map(c => ({
            name: c,
            selector: c,
        }));

        setData(list);
        setColumns(columns);
        console.log(list)
    }


    // handle file upload
    const handleFileUpload = e => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onload = (evt) => {
            /* Parse data */
            const bstr = evt.target.result;
            const wb = XLSX.read(bstr, { type: 'binary' });
            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            /* Convert array of arrays */
            const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
            processData(data);
        };
        reader.readAsBinaryString(file);
    }

    const [showData, setShowData] = useState([])
    const [showData1, setShowData1] = useState([])

    const show = () => {
        // axios.get(`${API}importUnique/`, {
        //     headers: {
        //         Authorization: `JWT ${token}`,
        //     }
        // })
        //     .then((res) => {
        //         setShowData(res.data);
        //     })
        //     .catch((error) => {
        //         console.error(error)
        //     })
    }

    const handelSubmit2 = () => {
        //   console.log(showData)

        let arrayFound = []
        let arrayNotFound = []
        // data.map(item=>{
        //     array.push({...item,dob:"2020-01-02",admission_date:"2021-01-02"})
        // })
        // console.log(array)

        data.map(item => {
            if (showData.includes(item.reg_no)) {
                arrayFound.push(item)
            } else {
                arrayNotFound.push({ ...item, dob: "2020-01-02", admission_date: "2021-01-02" })
            }
        })
        // console.log(arrayFound)
        // console.log(arrayNotFound)
        setShowData1(arrayFound)

        // arrayNotFound.map(item => {
        //     let url = `${API}admission/`;
        //     axios.post(url, item, {
        //         headers: {
        //             'Authorization': `JWT ${token}`,
        //             'content-type': 'application/json'
        //         }
        //     })
        //         .then(res => console.log("Added"))
        //         .catch(err => console.log(err))
        // })
        // let timerInterval
        // Swal.fire({
        //     title: "Creating User's Profile",
        //     html: 'Thank You !',
        //     timer: 2000,
        //     timerProgressBar: true,
        //     showCancelButton: false,
        //     showConfirmButton: false
        // })

        setColumns([])
        setData([])
    }

    const submitdata = (e) => {
        setShowProgress(true);
        data.map((user, index) => {
            setNum(index + 1);
            db.collection("unregistered_users").add({
                'parlour_name': user.parlour_name,
                'phone_no': user.phone_no,
                'pincode': user.pincode,
                'place_best_beauty_parlour_in': user.place_best_beauty_parlour_in,
                'full_address': user.full_address,
                'rating': user.rating,
                'reviews': user.reviews,
                'image': user.image,
                'username': user.username,
                'plus_code': user.plus_code,
                'email': user.email,
                'monday': user.monday,
                'tuesday': user.tuesday,
                'wednesday': user.wednesday,
                'thursday': user.thursday,
                'friday': user.friday,
                'saturday': user.saturday,
                'sunday': user.sunday,
                'created_date': new Date(),
            });


        })
        // setCustomerName("");
        // setCustomerPassword("");
    };

    useEffect(() => {
        // db.collection("query")
        //     .orderBy("timestamp", "desc")
        //     .onSnapshot((snapshot) => {
        //         snapshot.docs.map((doc) => {
        //             console.log("docdocdoc", doc.data().query)
        //             console.log("timeeeeeeeeeeee", doc.data().timestamp)
        //         })

        //         setQuery(
        //             snapshot.docs.map((doc) => ({
        //                 firstname: doc.data().query.firstname,
        //                 lastname: doc.data().query.lastname,
        //                 organization: doc.data().query.organization,
        //                 phone: doc.data().query.phone,
        //                 address: doc.data().query.address,
        //                 email: doc.data().query.email,
        //                 website: doc.data().query.website,
        //                 message: doc.data().query.message,
        //                 created_date: moment.unix(doc.data().timestamp).format("MM/DD, h:mm a"),
        //             }))
        //         );

        //     });
    }, []);
    return (
        <>
            <Link to="/userlist"><div style={{ textAlign: "end", cursor: "pointer" }}><h4>View unregistered_users list</h4></div></Link>
            {Show == false ? <>

                <div class="grid aa" style={{ paddingTop: "10rem" }}>

                    <form class="form login" onSubmit={handleSubmit}>

                        <div class="form__field">
                            <label for="login__username" style={{ margin: "auto" }}><i class="fa fa-user"></i></label>
                            <input autocomplete="username" id="username" onChange={handleChange} value={submit.username} type="text" name="username" class="form__input" placeholder="Username" required />
                        </div>

                        <div class="form__field">
                            <label for="login__password" style={{ margin: "auto" }}><i class="fa fa-lock"></i></label>
                            <input id="password" type="password" name="password" onChange={handleChange} value={submit.password} class="form__input" placeholder="Password" required />
                        </div>

                        <div class="form__field">
                            <input type="submit" value="Sign In" />
                        </div>

                    </form>
                </div>


            </>
                :
                <>
                    <div class="">

                        <div class="card-body">
                            <div class="form-group justify-content-end row">
                                <div class="col-lg-12">
                                    <div class="form-control dropzone dropzone-default dropzone-primary dz-clickable h-auto px-4 py-2" style={{ background: "aquamarine", textAlign: "center" }}>
                                        <div class="dropzone-msg dz-message needsclick" style={{ borderStyle: "dashed", padding: "1rem", borderColor: "orange" }}>
                                            <input type="file" accept=".csv,.xlsx,.xls" onChange={handleFileUpload} />
                                            <span class="font-size-base"><i class="fas fa-cloud-upload-alt icon-md mr-1"></i>Drop files here or click.</span><br />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <DataTable
                            pagination
                            highlightOnHover
                            columns={columns}
                            data={data}
                        />
                        {showData1.length > 0 ?
                            <>
                                <p style={{ fontSize: "medium", fontWeight: "bold", color: "crimson", padding: "2rem" }}>* Users Already In Database</p>
                                {showData1.map(item => (<>
                                    <p style={{ marginLeft: "3rem", fontSize: "larger", fontWeight: "600" }}>{item.reg_no}</p><br />
                                </>))}
                            </>
                            :
                            null
                        }

                        {data.length > 0 ? <> <div class="card-footer d-flex justify-content-end py-2">
                            <button onClick={() => submitdata()} class="btn btn-success mr-2">Upload Data</button>
                            {/* <div class="btn btn-secondary" role="button">Cancel</div> */}
                        </div></> : null
                        }
                    </div>

                    {ShowProgress ? <><h2 style={{ background: "darkorange", padding: "1rem", textAlign: "center", paddingBottom: "1rem", color: "white" }}>{num == data.length ? "Data Uploaded" : "Uploading Data"} - {num}/{data.length}</h2></> : ""}
                </>
            }


        </>

    )
}

export default Admin;