import React, { useState, useEffect } from "react";
import { useNavigate, Link, useParams } from 'react-router-dom';
import Swal from 'sweetalert2'
import axios from "axios";
import db from "./Firebase";
import { storage } from './Firebase';
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { createExportDeclaration } from "typescript";

function Cart() {
    const initialFormStateFeedback = { name: '', phone: '', pincode: '', mode: '', address: '', amount: '', payment_id: '', products: [], status: 'pending', uid: '', created_date: new Date(), updated_date: new Date() }
    const [submit, setSubmit] = useState(initialFormStateFeedback)
    const [basketData, setBasketData] = useState([]);
    const [productID, setProductID] = useState([]);
    const [basketCount, setBasketcount] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);
    const [selectedID, setSelectedID] = useState("");
    const [show, setshow] = useState(true);
    const [showUsername, setshowUsername] = useState(false);
    const [showManualbilling, setshowmanualBilling] = useState(false);
    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://checkout.razorpay.com/v1/checkout.js";
        script.async = true;
        document.body.appendChild(script);

        if (JSON.parse(localStorage.getItem("productID")) !== null) {
            setBasketData(JSON.parse(localStorage.getItem("basket")));
            console.log(basketData)
            console.log(JSON.parse(localStorage.getItem("basket")))
            var amount = 0;
            JSON.parse(localStorage.getItem("basket")).map((e) => {
                // alert("calling 2")
                if (e['itemcount'] > 1) {
                    amount += (parseInt(e['price']) * parseInt(e['itemcount']))
                } else {
                    amount += parseInt(e['price'])
                }
                console.log(amount)
            })
            setTotalAmount(amount);
        } else {
            setBasketData([])
        }

        if (JSON.parse(localStorage.getItem("productID")) !== null) {
            setProductID(JSON.parse(localStorage.getItem("productID")) || "[]");
        } else {
            setProductID([])
        }
        // setBasketcount(JSON.parse(localStorage.getItem("productID")).length)
        if (JSON.parse(localStorage.getItem("productID")) !== null) {
            setBasketcount(JSON.parse(localStorage.getItem("productID")).length)
        } else {
            setBasketcount(0)
        }



    }, []);

    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    const removeFromBasket = (data, id) => {
        const itemToBeRemoved = data
        basketData.splice(basketData.findIndex(a => a.id === itemToBeRemoved.id), 1)
        const newIds = productID.filter(e => e !== id)
        setProductID(newIds)
        setBasketcount(newIds.length)
        localStorage.setItem("basket", JSON.stringify(basketData));
        localStorage.setItem("productID", JSON.stringify(newIds));
        console.log(basketData)
        calculateAmount()
        forceUpdate()

    }

    const calculateAmount = () => {
        console.log("calling 1")
        var amount = 0;
        basketData.map((e) => {
            // alert("calling 2")
            if (e['itemcount'] > 1) {
                amount += (parseInt(e['price']) * parseInt(e['itemcount']))
            } else {
                amount += parseInt(e['price'])
            }
            console.log(amount)
        })
        setTotalAmount(amount);
    }

    const openModalNew = () => {
        window.openModelNew();
    }

    const openModalShow = () => {
        window.openModelshow();
    }


    const handleChange = (e) => {
        var objIndex = basketData.findIndex((obj => obj.id == selectedID));
        // console.log("Before update: ", basketData[objIndex])
        basketData[objIndex].itemcount = parseInt(e.target.value)
        // console.log("After update: ", basketData[objIndex])
        console.log(basketData)
        localStorage.setItem("basket", JSON.stringify(basketData));
        // alert(e.target.value)
        // const { id, value } = e.target
        // setSubmit({ ...submit, [id]: value })
        calculateAmount()
        forceUpdate()

    }

    const handleChangeUsername = (e) => {
        setSubmit({ name: '', phone: '', pincode: '', mode: '', address: '', amount: '', payment_id: '', products: [], status: 'pending', uid: '', created_date: new Date(), updated_date: new Date() })
        if (e.target.value == "yes") {
            setshowUsername(true);
        } else {
            setshowUsername(false);
            setshowmanualBilling(true)

        }
    }

    const handleChangeBillingInfo = (e) => {
        const { id, value } = e.target
        setSubmit({ ...submit, [id]: value })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        if (submit.mode == "cod") {
            db.collection("orderd_consumables_products").add({
                'name': submit.name,
                'phone': submit.phone,
                'pincode': submit.pincode,
                'mode': submit.mode,
                'address': submit.address,
                'products': basketData,
                'amount': totalAmount,
                'payment_id': submit.payment_id,
                'status': submit.status,
                'uid': submit.uid,
                'created_date': new Date(),
            }).then(() => {
                console.log('Submitted Successfully');
                const Toast = Swal.mixin({
                    toast: true,
                    background: '#69aba6',
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer)
                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                    }
                })

                Toast.fire({
                    icon: 'success',
                    title: 'Submitted Successfully'
                })
                openModalNew()
                setshow(true)
                // navigate(`/${submit.username}`);
                setSubmit({ name: '', phone: '', pincode: '', mode: '', address: '', amount: '', payment_id: '', products: [], status: 'pending', uid: '', created_date: new Date(), updated_date: new Date() })
            })
        } else {
            openPayModal(totalAmount)
        }

    }

    const reset = () => {
        setshowUsername(false)
        setshowmanualBilling(false)
    }

    const usernameData = { user_name: '', }
    const [submitU, setSubmitU] = useState(initialFormStateFeedback)

    const handleChangeGetUsername = (e) => {
        const { id, value } = e.target
        setSubmitU({ ...submitU, [id]: value })
    }
    const [addressDetails, setAddressDetails] = useState({})
    const fetchDetails = () => {
        // alert(submitU.user_name);
        db.collection("parlour_details").where('username', '==', submitU.user_name).onSnapshot((snapshot) => {
            if (snapshot.docs.length == 0) {
                // navigate('/error');
                alert("username does not exists")
            } else {
                // alert("exists")
                // snapshot.docs.map(e => setAddressDetails(e.data()));
                snapshot.docs.map(e => {
                    submit.address = (e.data()['full_address'])
                    submit.name = (e.data()['parlour_name'])
                    submit.phone = (e.data()['phone_no'])
                    submit.pincode = (e.data()['pincode'])

                });
                setshowmanualBilling(true)
                // setIsLoading(false);
            }
        })
    }


    const createOrder = () => {
        // var values = {
        //     "amount": 78000,
        //     "currency": "INR",
        //     "receipt": "Receipt no. 12",
        //     "notes": {
        //         "notes_key_1": "Tea, Earl Grey, Hot 1",
        //         "notes_key_2": "Tea, Earl Grey… decaf 1."
        //     }
        // }
        // await axios.post('https://api.razorpay.com/v1/orders', values, {
        //     auth: {
        //         username: 'rzp_test_i4d0AGT3whDCH5',
        //         password: 'xI9ESxNn2NMC2oDtEXwU1D09'
        //     }
        // }).then(res => { console.log(res) })
        //     .catch(e => console.log(e))

    }


    const openPayModal = (amt) => {
        console.log("call 1")
        var amount = amt * 100; //Razorpay consider the amount in paise
        var options = {
            "key": 'rzp_test_i4d0AGT3whDCH5',
            "amount": amount, // 2000 paise = INR 20, amount in paisa
            "name": "Rahul",
            "payment_capture": 1,
            "description": "Singh",
            // 'order_id': "order_KAAcas8zpSo1jM",
            "handler": function (response) {
                console.log("call 2")
                console.log(response);
                // alert(response.razorpay_payment_id);
                // alert(response.razorpay_order_id);
                // alert(response.razorpay_signature);
                var values = {
                    razorpay_signature: response.razorpay_signature,
                    razorpay_order_id: response.razorpay_order_id,
                    transactionid: response.razorpay_payment_id,
                    transactionamount: amount,
                }
                // axios.post('http://localhost:5000/upgrade/payment', values)
                //     .then(res => { alert("Success") })
                //     .catch(e => console.log(e))
            },
            "prefill": {
                "name": 'Sanjana Kumari',
                "email": 'sanjana@gmail.com',
                "contact": '1234567890',
            },
            "notes": {
                "address": "Hello World"
            },
            "theme": {
                "color": "red"
            }
        };

        var rzp1 = new window.Razorpay(options);
        rzp1.open();
        // axios.post('http://localhost:5000/contact', { amount: amount })
        //     .then(res => {
        //         console.log("call 3")
        //         options.order_id = res.data.id;
        //         options.amount = res.data.amount;
        //         console.log(options)
        //         var rzp1 = new window.Razorpay(options);
        //         rzp1.open();
        //     })
        //     .catch(e => console.log(e))

    };


    return (
        <>
            <div className="App" >
                <section class="section-pagetop bg">
                    <div class="" style={{ padding: "9px" }}>
                        <h2 style={{ fontWeight: "700", fontSize: "revert", color: "#f7444e" }} class="title-page">Shopping cart</h2>
                    </div>
                </section>

                <section class="section-content padding-y">
                    <div class="" style={{ padding: "14px" }}>

                        <div class="row">
                            <main class="col-md-9">
                                <div class="card">
                                    {basketData.length <= 0 ? <>
                                        <div style={{ textAlign: "center", padding: "2rem", fontSize: "x-large", fontWeight: "600" }}>Cart is empty</div>
                                    </> :
                                        <>
                                            <table class="table table-borderless table-shopping-cart">
                                                <thead class="text-muted">
                                                    <tr class="small text-uppercase">
                                                        <th scope="col">Product</th>
                                                        <th scope="col" width="120">Quantity</th>
                                                        <th scope="col" width="120">Price</th>
                                                        <th scope="col" class="text-right" width="200"> </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {basketData?.map((data, index) => (
                                                        <>
                                                            <tr>
                                                                <td>
                                                                    <figure class="itemside">
                                                                        <div class="aside"><img src={data["image_url"]} class="img-sm" style={{ height: "93px" }} /></div>
                                                                        <figcaption class="info">
                                                                            <a href="#" class="title text-dark">{data["product_name"]}</a>
                                                                            <p class="text-muted small"> Quantity: {data["quantity"]} <br /> Brand: {data["brand_name"]}</p>
                                                                        </figcaption>
                                                                    </figure>
                                                                </td>
                                                                <td>
                                                                    <select class="form-control" onChange={handleChange} onClick={() => setSelectedID(data['id'])} value={data['itemcount']}>
                                                                        <option value="1">1</option>
                                                                        <option value="2">2</option>
                                                                        <option value="3">3</option>
                                                                        <option value="4">4</option>
                                                                        <option value="5">5</option>
                                                                    </select>
                                                                </td>
                                                                <td>
                                                                    <div class="price-wrap">
                                                                        <var class="price">₹{data["price"]}</var>
                                                                        <small class="" style={{ color: "red" }}> 50% Off </small>
                                                                    </div>
                                                                </td>
                                                                <td class="text-right">
                                                                    {/* <a data-original-title="Save to Wishlist" title="" href="" class="btn btn-light mr-2" data-toggle="tooltip"> <i class="fa fa-heart"></i></a> */}
                                                                    <span href="" class="btn btn-light" onClick={() => removeFromBasket(data, data['id'])}> Remove</span>
                                                                </td>
                                                            </tr>
                                                        </>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </>}

                                    <div class="card-body border-top">
                                        <span href="#" class="btn btn-primary float-md-right" data-toggle="modal" data-target="#referParlour"> Make Purchase <i class="fa fa-chevron-right"></i> </span>
                                        <Link to="/shop" href="#" class="btn btn-light"><i class="fa fa-chevron-left"></i> Continue shopping </Link>
                                    </div>
                                </div>

                                <div class="alert alert-success mt-3" onClick={() => calculateAmount()}>
                                    <p class="icontext"><i class="icon text-success fa fa-truck"></i> Free Delivery within 1-2 weeks</p>
                                </div>

                            </main>

                            <aside class="col-md-3">
                                {/* <div class="card mb-3">
                                <div class="card-body">
                                    <form>
                                        <div class="form-group">
                                            <label>Have coupon?</label>
                                            <div class="input-group">
                                                <input type="text" class="form-control" name="" placeholder="Coupon code" />
                                                <span class="input-group-append">
                                                    <button class="btn btn-primary">Apply</button>
                                                </span>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div> */}
                                <div class="card">
                                    <div class="card-body">
                                        <div class="row" style={{ marginBottom: "1rem" }}>
                                            <div class="col-lg-7" style={{ fontSize: "17px", fontWeight: "620", }}>Total price:</div>
                                            <div class="col-lg-5">INR {totalAmount}</div>
                                        </div>

                                        <div class="row" style={{ marginBottom: "1rem" }}>
                                            <div class="col-lg-7" style={{ fontSize: "17px", fontWeight: "620", }}>Discount:</div>
                                            <div class="col-lg-5">INR 0.00</div>

                                        </div>

                                        <div class="row" style={{ marginBottom: "1rem" }}>
                                            <div class="col-lg-7" style={{ fontSize: "18px", fontWeight: "650", }}>Total:</div>
                                            <div class="col-lg-5" style={{ fontSize: "22px", }}><strong>₹{totalAmount}</strong></div>
                                        </div>


                                        <hr />
                                        <p class="text-center mb-3">
                                            <img src="/shop/payments.png" height="26" />
                                        </p>

                                    </div>
                                </div>
                            </aside>
                        </div>

                    </div>
                </section>

                <section class="section-name bg padding-y">
                    <div class="container">
                        <h6>Payment and refund policy</h6>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                            tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                            quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                            consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
                            cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
                            proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                            tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                            quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                            consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
                            cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
                            proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>

                    </div>
                </section>

                {/* <footer class="section-footer border-top padding-y">
                <div class="container">
                    <p class="float-md-right">
                        &copy; Copyright 2020 All rights reserved
                    </p>
                    <p>
                        <a href="#">Terms and conditions</a>
                    </p>
                </div>
            </footer> */}


            </div>

            {/* --------------------------------Cart Billing Starts-------------------------------------------- */}
            <div class="modal fade bd-example-modal-lg" id="referParlour" tabindex="-1" role="dialog" aria-labelledby="referParlourTitle" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div class="modal-content">
                        {/* <div class="modal-header">
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div> */}
                        <div class="modal-body">
                            <>
                                <div id="small-dialog" class="">
                                    <div class="w3layouts_payment_online_form">
                                        <form class="" id="" onSubmit={handleSubmit}>
                                            <h4>Billing Information</h4>
                                            {!showManualbilling ? <>
                                                <ul>
                                                    <li>
                                                        <label>Have you Installed The App ?</label>
                                                        <select class="form-control" id="course_content" onChange={handleChangeUsername} required>
                                                            <option value="">--Select--</option>
                                                            <option value="yes">Yes</option>
                                                            <option value="no">No</option>
                                                        </select>
                                                    </li>
                                                    <li>
                                                        {showUsername ? <><input class="w3l_text_box" style={{ textTransform: "lowercase" }} id="user_name" placeholder="Oovve App User Name" name="User Name" onChange={handleChangeGetUsername} type="text" required /></> : <></>}

                                                    </li>


                                                </ul>
                                                <ul class="w3_payment_sendbtns">
                                                    <li><input type="reset" data-dismiss="modal" aria-label="Close" value="Cancel" /></li>
                                                    {showUsername ? <> <li><input type="reset" value="Get Details" onClick={() => fetchDetails()} /></li></> : <></>}
                                                </ul>
                                            </> : <>
                                                <ul>
                                                    <li><input class="w3l_text_box" id="name" onChange={handleChangeBillingInfo} value={submit.name} placeholder="Full Name" name="Full Name" type="text" required />
                                                    </li>
                                                    <li><input class="w3l_text_box" id="phone" onChange={handleChangeBillingInfo} value={submit.phone} placeholder="10 Digit Phone Number" name="Phone" type="tel" minlength="10" maxlength="10" required />
                                                    </li>

                                                </ul>
                                                <ul>
                                                    <li><input class="w3l_text_box" id="pincode" onChange={handleChangeBillingInfo} value={submit.pincode} placeholder="6 Digit Pincode" name="Pincode" type="tel" minlength="6" maxlength="6" required />
                                                    </li>
                                                    <li>
                                                        <select class="form-control" id="mode" onChange={handleChangeBillingInfo} value={submit.mode} required>
                                                            <option value="">--Payment Mode--</option>
                                                            <option value="cod">COD</option>
                                                            <option value="online">Pay Online</option>
                                                        </select>
                                                    </li>

                                                </ul>
                                                <ul>
                                                    <li class="w3ls_address"><input class="w3l_text_box" onChange={handleChangeBillingInfo} value={submit.address} id="address" placeholder="Your Address Here....." name="Address" type="text" style={{ height: "6rem" }} required /></li>
                                                </ul>


                                                <ul class="w3_payment_sendbtns">
                                                    <li><input type="reset" data-dismiss="modal" aria-label="Close" value="Cancel" onClick={() => reset()} /></li>
                                                    <li><li>{show ? <input type="submit" value="Submit" /> : "Please Wait..."}</li></li>
                                                </ul>
                                            </>}


                                            <div class="clearfix"> </div>
                                        </form>
                                    </div>
                                </div>
                            </>
                        </div>
                    </div>
                </div>
            </div>
            {/* --------------------------------Cart Billing Ends-------------------------------------------- */}
        </>
    );
}

export default Cart