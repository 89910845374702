import React from 'react'
import Slider from "react-slick";
// import "./index.css";

var num = 3;
function Testport(props) {
    var settings = {
        dots: true,
        autoplay: true,
        slidesToShow: num,
    };

    return (
        <>

            <div className="container" style={{ padding: "40px", background: "#419be0" }}>
                <Slider {...settings}>
                    <div>
                        <img src="http://placekitten.com/g/400/200" class="p-2" />
                    </div>
                    <div>
                        <img src="http://placekitten.com/g/400/200" class="p-2" />
                    </div>
                    <div>
                        <img src="http://placekitten.com/g/400/200" class="p-2" />
                    </div>
                    <div>
                        <img src="http://placekitten.com/g/400/200" class="p-2" />
                    </div>
                </Slider>
            </div>
        </>
    )
}

Testport.propTypes = {}

export default Testport
