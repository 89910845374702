import React from "react";
import './style.css';

function OldHomescreen() {

    return (
        <>
            <div class="main-banner" id="home">
                <div class="bg-overlay">

                    <header>
                        <div class="container">
                            <div class="header align-items-center">
                                <div class="header-agile">
                                    <h1>
                                        <a class="navbar-brand logo" href="/" style={{ fontSize: "2em" }}>
                                            Oovve
                                        </a>
                                    </h1>
                                </div>
                                <div class="nav_w3ls">
                                    <nav>
                                        <label for="drop" class="toggle"><span class="fa fa-bars" aria-hidden="true"></span></label>
                                        <input type="checkbox" id="drop" />
                                        <ul class="menu">
                                            <li class="active"><a href="index.html">Home</a></li>
                                            <li><a href="#about">About</a></li>
                                            {/* <li><a href="#services">Services</a></li> */}
                                            {/* <li class="p-0">
                                                <label for="drop-2" class="toggle">Pages <span class="fa fa-angle-down" aria-hidden="true"></span> </label>
                                                <a href="#">Drop Down <span class="fa fa-caret-down" aria-hidden="true"></span></a>
                                                <input type="checkbox" id="drop-2" />
                                                <ul class="inner-dropdown">
                                                    <li><a href="#stats">Why Choose Us</a></li>
                                                    <li><a href="#team">team</a></li>
                                                    <li><a href="#contact">Contact Us</a></li>
                                                </ul>
                                            </li> */}
                                            <li><a href="#gallery">Add Beautyparlour</a></li>
                                        </ul>
                                    </nav>
                                </div>
                                {/* <div class="buttons ml-auto">
                                    <ul class="form-w3pvt-grid">
                                        <li><a href="#contact" class="user">
                                            <span class="fa fa-user-o"></span>
                                        </a>
                                        </li>
                                        <li>
                                            <form action="#" method="post" class="newsletter">
                                                <input class="search" type="search" placeholder="" required="" />
                                                <button class="form-control btn" value=""><span class="fa fa-search"></span></button>
                                                <div class="clearfix"></div>
                                            </form>
                                        </li>

                                    </ul>

                                </div> */}

                            </div>
                        </div>
                    </header>
                    <div class="container">

                        <div class="banner-info">
                            {/* <h3>Pro Skin Whitening Creams of 2019</h3> */}
                            <h3>Get the Oovve app</h3>

                            <p><span class="fa fa-envelope" aria-hidden="true"></span> We will send you a link, open it on your phone to download the app</p>
                            <form action="#" method="post" class="">
                                <input type="email" name="Email" placeholder="Enter your email..." required="" />
                                <button type="submit" class="btn"><span class="fa fa-paper-plane"></span></button>
                                <div class="clearfix"> </div>
                            </form>
                        </div>

                    </div>
                    <div style={{ paddingLeft: "1rem", marginTop: "1rem" }}>
                        <p style={{ fontSize: "1rem", color: "white" }}>Download app from</p>
                    </div>
                    <div>
                        <div style={{ paddingLeft: "1rem", width: "10.7rem" }} class="sc-s1isp7-1 fxuOHl"><div src="" class="sc-s1isp7-3 cVOEqG"></div><img alt="image" src="https://b.zmtcdn.com/data/webuikit/23e930757c3df49840c482a8638bf5c31556001144.png" loading="lazy" class="sc-s1isp7-5 fyZwWD" /></div>
                    </div>
                </div>
            </div>

            <section class="about py-5" id="about">
                <div class="container py-md-4">
                    <div class="row py-md-5">
                        <div class="col-lg-4 about-left-w3pvt offset-lg-1 mt-lg-4">
                            <div class="main-img">
                                <img src="images/ab.jpg" alt="" class="img-fluid pos-aboimg" />
                                <img src="images/g3.jpg" alt="" class="img-fluid pos-aboimg2" />
                            </div>
                        </div>
                        <div class="col-xl-6 col-lg-7 about-right offset-xl-1">
                            <h4 class="sub-tittle">About Us</h4>
                            <h3 class="title-w3pvt pr-lg-5 mt-2">Duis aute irure olor in cillum solore</h3>
                            <p class="my-4">Lorem ipsum dolor sit amet Neque porro quisquam est qui dolorem Lorem int ipsum dolor sit
                                amet id tempor felis. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                            <p>Sed do eiusmod tempor incididunt ut labore et dolore magna aliquaLorem ipsum dolor sit.</p>
                            <a href="#services" class="btn button-style-2 mt-sm-5 mt-4">Read More</a>
                        </div>
                    </div>
                </div>
            </section>

            <section class="facts pt-5" id="stats">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-7 pt-5">
                            <div class="inner-heading">
                                <h3 class="title-w3pvt my-md-4"> Why Choose Us</h3>
                                <p class="mt-md-0 mt-2">Donec cursus congue risus, quis varius velit accumsan aliquam. Morbi semper nunc. Perspiciatis unde omnis iste
                                    natus doloret ipsum volupte ut accusal ntium dolor remque laudantium, totam dolor</p>
                            </div>
                            <div class="row mt-5 fact-grid-main">
                                <div class="col-sm-4 stats-grid">
                                    <span class="fa fa-trophy"></span>
                                    <span>250</span>
                                    <h4>Experienced Barbers</h4>
                                </div>
                                <div class="col-sm-4 stats-grid">
                                    <span class="fa fa-scissors"></span>
                                    <span>50+</span>
                                    <h4>Amazing Hairstyles</h4>
                                </div>
                                <div class="col-sm-4 stats-grid">
                                    <span class="fa fa-smile-o"></span>
                                    <span>2000+</span>
                                    <h4>Satisfied clients</h4>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-5 text-lg-right">
                            <img src="images/why.png" class="img-fluid" alt="" />
                        </div>
                    </div>
                </div>
            </section>

            <section class="mid-sec py-5" id="services">
                <div class="container py-lg-5">
                    <div class="title-desc text-center">
                        <h3 class="title-w3pvt text-capitalize mb-md-5 mb-4">Our Services</h3>
                    </div>
                    <div class="row middile-inner-con mt-md-3">
                        <div class="col-lg-4 benifit-content mt-lg-2">
                            <div class="row">
                                <div class="col-md-4 benifit-icon-content">
                                    <div class="benifit-icon">
                                        <span class="fa fa-deaf" aria-hidden="true"></span>
                                    </div>
                                </div>
                                <div class="col-md-8 benifit-w3layouts-info">
                                    <h4 class="mb-3">Hair Services</h4>
                                    <p>Morbi viverra lacus commodo felis semper lectus feugiat.</p>
                                </div>

                            </div>
                        </div>
                        <div class="col-lg-4 benifit-content mt-lg-2">
                            <div class="row">
                                <div class="col-md-4 benifit-icon-content">
                                    <div class="benifit-icon">
                                        <span class="fa fa-fire-extinguisher" aria-hidden="true"></span>
                                    </div>
                                </div>
                                <div class="col-md-8 benifit-w3layouts-info">
                                    <h4 class="mb-3">Nail Services</h4>
                                    <p>Morbi viverra lacus commodo felis semper lectus feugiat.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 benifit-content mt-lg-2">
                            <div class="row">
                                <div class="col-md-4 benifit-icon-content">
                                    <div class="benifit-icon">
                                        <span class="fa fa-paint-brush" aria-hidden="true"></span>
                                    </div>
                                </div>
                                <div class="col-md-8 benifit-w3layouts-info">
                                    <h4 class="mb-3">Makeup Services</h4>
                                    <p>Morbi viverra lacus commodo felis semper lectus feugiat.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row middile-inner-con mt-md-4">
                        <div class="col-lg-4 benifit-content mt-lg-2">
                            <div class="row">
                                <div class="col-md-4 benifit-icon-content">
                                    <div class="benifit-icon">
                                        <span class="fa fa-bed" aria-hidden="true"></span>
                                    </div>
                                </div>
                                <div class="col-md-8 benifit-w3layouts-info">
                                    <h4 class="mb-3">Spa Salon</h4>
                                    <p>Morbi viverra lacus commodo felis semper lectus feugiat.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 benifit-content mt-lg-2">
                            <div class="row">
                                <div class="col-md-4 benifit-icon-content">
                                    <div class="benifit-icon">
                                        <span class="fa fa-eraser" aria-hidden="true"></span>
                                    </div>
                                </div>
                                <div class="col-md-8 benifit-w3layouts-info">
                                    <h4 class="mb-3">Waxing</h4>
                                    <p>Morbi viverra lacus commodo felis semper lectus feugiat.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 benifit-content mt-lg-2">
                            <div class="row">
                                <div class="col-md-4 benifit-icon-content">
                                    <div class="benifit-icon">
                                        <span class="fa fa-magic" aria-hidden="true"></span>
                                    </div>
                                </div>
                                <div class="col-md-8 benifit-w3layouts-info">
                                    <h4 class="mb-3">Coloring</h4>
                                    <p>Morbi viverra lacus commodo felis semper lectus feugiat.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="team py-5" id="team">
                <div class="container py-md-5">
                    <div class="title-desc text-center">
                        <h3 class="title-w3pvt text-capitalize mb-md-5 mb-4">Our Professional Team</h3>
                    </div>
                    <div class="row team-grid">
                        <div class="col-lg-3 col-sm-6">
                            <div class="box13">
                                <img src="images/team1.jpg" class="img-fluid" alt="" />
                                <div class="box-content">
                                    <h3 class="title">Williamson</h3>
                                    <span class="post">role in detail</span>
                                    <ul class="social">
                                        <li><a href="#"><span class="fa fa-facebook"></span></a></li>
                                        <li><a href="#"><span class="fa fa-twitter"></span></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-sm-6 mt-sm-0 mt-4">
                            <div class="box13">
                                <img src="images/team2.jpg" class="img-fluid" alt="" />
                                <div class="box-content">
                                    <h3 class="title">Kristiana</h3>
                                    <span class="post">role in detail</span>
                                    <ul class="social">
                                        <li><a href="#"><span class="fa fa-facebook"></span></a></li>
                                        <li><a href="#"><span class="fa fa-twitter"></span></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-sm-6 mt-lg-0 mt-4">
                            <div class="box13">
                                <img src="images/team3.jpg" class="img-fluid" alt="" />
                                <div class="box-content">
                                    <h3 class="title">Rossie Doe</h3>
                                    <span class="post">role in detail</span>
                                    <ul class="social">
                                        <li><a href="#"><span class="fa fa-facebook"></span></a></li>
                                        <li><a href="#"><span class="fa fa-twitter"></span></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-sm-6 mt-lg-0 mt-4">
                            <div class="box13">
                                <img src="images/team4.jpg" class="img-fluid" alt="" />
                                <div class="box-content">
                                    <h3 class="title">Sara Lee</h3>
                                    <span class="post">role in detail</span>
                                    <ul class="social">
                                        <li><a href="#"><span class="fa fa-facebook"></span></a></li>
                                        <li><a href="#"><span class="fa fa-twitter"></span></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="mid-section">
                <div class="d-lg-flex p-0">
                    <div class="col-lg-6 bottom-w3layouts-left p-lg-0 pr-lg-3">
                        <img src="images/ab1.jpg" class="img-fluid" alt="" />
                        <h4 class="text-w3pvt">50% Off Any <br /> Makeup Breshes</h4>
                    </div>
                    <div class="col-lg-6 bottom-w3layouts-left bottom-w3layouts-right p-lg-0 pl-lg-3">
                        <img src="images/ab2.jpg" class="img-fluid" alt="" />
                        <h4 class="text-w3layouts">30% Off <br /> Any Spa salon</h4>
                    </div>
                    <div class="clearfix"></div>
                </div>
            </section>

            <div class="gallery py-5" id="gallery">
                <div class="container-fluid py-md-5">
                    <div class="title-desc text-center">
                        <h3 class="title-w3pvt text-capitalize mb-lg-5 mb-4">Latest Collections</h3>
                    </div>
                    <div class="row news-grids no-gutters text-center">
                        <div class="col-md-3 gal-img my-4">
                            <a href="#gal1"><img src="images/g1.jpg" alt="Gallery Image" class="img-fluid" /></a>
                        </div>
                        <div class="col-md-6">
                            <div class="row no-gutters">
                                <div class="col-md-6 gal-img">
                                    <a href="#gal2"><img src="images/g2.jpg" alt="Gallery Image" class="img-fluid" /></a>
                                </div>
                                <div class="col-md-6 gal-img">
                                    <a href="#gal3"><img src="images/g3.jpg" alt="Gallery Image" class="img-fluid" /></a>
                                </div>
                                <div class="col-md-6 gal-img">
                                    <a href="#gal4"><img src="images/g4.jpg" alt="Gallery Image" class="img-fluid" /></a>
                                </div>
                                <div class="col-md-6 gal-img">
                                    <a href="#gal5"><img src="images/g5.jpg" alt="Gallery Image" class="img-fluid" /></a>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3 gal-img my-4">
                            <a href="#gal6"><img src="images/g6.jpg" alt="Gallery Image" class="img-fluid" /></a>
                        </div>
                    </div>

                    <div id="gal1" class="pop-overlay animate">
                        <div class="popup">
                            <img src="images/g1.jpg" alt="Popup Image" class="img-fluid" />
                            <p class="mt-4">Nulla viverra pharetra se, eget pulvinar neque pharetra ac int. placerat placerat dolor.</p>
                            <a class="close" href="#gallery">&times;</a>
                        </div>
                    </div>

                    <div id="gal2" class="pop-overlay animate">
                        <div class="popup">
                            <img src="images/g2.jpg" alt="Popup Image" class="img-fluid" />
                            <p class="mt-4">Nulla viverra pharetra se, eget pulvinar neque pharetra ac int. placerat placerat dolor.</p>
                            <a class="close" href="#gallery">&times;</a>
                        </div>
                    </div>

                    <div id="gal3" class="pop-overlay animate">
                        <div class="popup">
                            <img src="images/g3.jpg" alt="Popup Image" class="img-fluid" />
                            <p class="mt-4">Nulla viverra pharetra se, eget pulvinar neque pharetra ac int. placerat placerat dolor.</p>
                            <a class="close" href="#gallery">&times;</a>
                        </div>
                    </div>

                    <div id="gal4" class="pop-overlay animate">
                        <div class="popup">
                            <img src="images/g4.jpg" alt="Popup Image" class="img-fluid" />
                            <p class="mt-4">Nulla viverra pharetra se, eget pulvinar neque pharetra ac int. placerat placerat dolor.</p>
                            <a class="close" href="#gallery">&times;</a>
                        </div>
                    </div>

                    <div id="gal5" class="pop-overlay animate">
                        <div class="popup">
                            <img src="images/g5.jpg" alt="Popup Image" class="img-fluid" />
                            <p class="mt-4">Nulla viverra pharetra se, eget pulvinar neque pharetra ac int. placerat placerat dolor.</p>
                            <a class="close" href="#gallery">&times;</a>
                        </div>
                    </div>
                    <div id="gal6" class="pop-overlay animate">
                        <div class="popup">
                            <img src="images/g6.jpg" alt="Popup Image" class="img-fluid" />
                            <p class="mt-4">Nulla viverra pharetra se, eget pulvinar neque pharetra ac int. placerat placerat dolor.</p>
                            <a class="close" href="#gallery">&times;</a>
                        </div>
                    </div>
                </div>
            </div>

            <section class="contact py-5" id="contact">
                <div class="container py-md-5">
                    <div class="title-desc text-center">
                        <h3 class="title-w3pvt two text-capitalize mb-md-5 mb-4">Contact Us</h3>
                    </div>

                    <div class="contact-form mx-auto text-left">
                        <form name="contactform" id="contactform1" method="post" action="#">
                            <div class="row">
                                <div class="col-lg-4 con-gd">
                                    <div class="form-group">
                                        <label>Name *</label>
                                        <input type="text" class="form-control" id="name" placeholder="" name="name" required="" />
                                    </div>
                                </div>
                                <div class="col-lg-4 con-gd">
                                    <div class="form-group">
                                        <label>Email *</label>
                                        <input type="email" class="form-control" id="email" placeholder="" name="email" required="" />
                                    </div>
                                </div>
                                <div class="col-lg-4 contact-page">
                                    <div class="form-group">
                                        <label>Submit *</label>
                                        <button type="submit" class="btn btn-default">Submit</button>
                                    </div>
                                </div>

                            </div>

                        </form>
                    </div>
                    <ul class="list-unstyled row text-left mb-lg-5 mb-3">
                        <li class="col-lg-4 adress-info">
                            <div class="row">
                                <div class="col-md-3 text-lg-center adress-icon">
                                    <span class="fa fa-map-marker"></span>
                                </div>
                                <div class="col-md-9 text-left">
                                    <h6>Location</h6>
                                    <p>The company name
                                        <br />New York City. </p>
                                </div>
                            </div>
                        </li>

                        <li class="col-lg-4 adress-info">
                            <div class="row">
                                <div class="col-md-3 text-lg-center adress-icon">
                                    <span class="fa fa-envelope-open-o"></span>
                                </div>
                                <div class="col-md-9 text-left">
                                    <h6>Email</h6>
                                    <a href="mailto:info@example.com">mail@example.com</a>
                                    <br />
                                    <a href="mailto:info@example.com">mail2@example.com</a>
                                </div>
                            </div>
                        </li>
                        <li class="col-lg-4 adress-info">
                            <div class="row">
                                <div class="col-md-3 text-lg-center adress-icon">
                                    <span class="fa fa-mobile"></span>
                                </div>
                                <div class="col-md-9 text-left">
                                    <h6>Phone Number</h6>
                                    <p>+ 1234567890</p>
                                    <p>+ 0987654321</p>
                                </div>
                            </div>
                        </li>
                    </ul>

                    <div class="map-w3layouts mt-lg-5 mt-4">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d423286.27404345275!2d-118.69191921441556!3d34.02016130939095!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c2c75ddc27da13%3A0xe22fdf6f254608f4!2sLos+Angeles%2C+CA%2C+USA!5e0!3m2!1sen!2sin!4v1522474296007" allowfullscreen=""></iframe>
                    </div>
                </div>

            </section>

            <div class="footer_agileinfo_topf py-5">
                <div class="container py-md-5">
                    <div class="row">
                        <div class="col-md-4 footer_w3pvt_gridf">
                            <h2><a href="index.html">Oovve <span class="blush">Blush For Your Skin</span></a> </h2>

                        </div>
                        <div class="col-md-4 col-sm-6 footer_w3pvt_gridf mt-md-0 mt-4">
                            <ul class="footer_w3pvt_gridf_list">
                                <li><span class="fa fa-angle-right" aria-hidden="true"></span>
                                    <a href="#home">Home</a>
                                </li>
                                <li><span class="fa fa-angle-right" aria-hidden="true"></span>
                                    <a href="#services">Services</a>
                                </li>
                                <li><span class="fa fa-angle-right" aria-hidden="true"></span>
                                    <a href="#stats">Choose Us</a>
                                </li>
                                <li><span class="fa fa-angle-right" aria-hidden="true"></span>
                                    <a href="#services">Features</a>
                                </li>
                            </ul>
                        </div>
                        <div class="col-md-4 col-sm-6 footer_w3pvt_gridf mt-md-0 mt-sm-4 mt-3">
                            <ul class="footer_w3pvt_gridf_list">
                                <li><span class="fa fa-angle-right" aria-hidden="true"></span>
                                    <a href="#gallery">Collections</a>
                                </li>
                                <li><span class="fa fa-angle-right" aria-hidden="true"></span>
                                    <a href="#stats">Statistics</a>
                                </li>
                                <li><span class="fa fa-angle-right" aria-hidden="true"></span>
                                    <a href="#">Terms & Conditions</a>
                                </li>
                                <li><span class="fa fa-angle-right" aria-hidden="true"></span>
                                    <a href="#contact">Contact Us</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="w3ls-fsocial-grid">
                        <h3 class="sub-w3ls-headf">Follow Us</h3>
                        <div class="social-ficons">
                            <ul>
                                <li><a href="#"><span class="fa fa-facebook"></span> Facebook</a></li>
                                <li><a href="#"><span class="fa fa-twitter"></span> Twitter</a></li>
                                <li><a href="#"><span class="fa fa-google"></span>Google</a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="move-top text-center mt-lg-4 mt-3">
                        <a href="#home"><span class="fa fa-angle-double-up" aria-hidden="true"></span></a>
                    </div>
                </div>
            </div>

            <div class="cpy-right text-center py-3">
                <p>© 2022 Oovve. All rights reserved | Design by
                    <a href="http://oovve.com"> Oovve.</a>
                </p>
            </div>



        </>
    );
}

export default OldHomescreen;
