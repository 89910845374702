import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from './Home';
import Contact from './Contact';
import Testing from './Testing';
import Portfolio from "./Portfolio";
import Homepage from "./Homepge";
import OldHomescreen from "./OldHomescreen";
import ExcelUpload from "./ExcelUpload";
import Admin from "./Admin";
import PhoneAuth from "./PhoneAuth";
import GetLocation from "./GetLocation";
import Stick from "./Stick";
import Unregistereduser from "./UnregisteredUser";
import Invite from "./Invite";
import Search from "./Search";
import Testport from "./Testport";
import BeATrainer from "./BeATrainer";
import BeDistributor from "./BeDistributor";
import Feedback from "./Feedback";
import FilterTesting from "./FilterTesting";
import UploadCourse from "./UploadCourse";
import UploadConsumables from "./UploadConsumables";
import UploadWorkshopEvent from "./UploadWorkshopEvent";
import ShareWorkshop from "./ShareWorkshop";
import Quicklinks from "./Quicklinks";
import UploadSellProducts from "./UploadSellProducts";
import UploadAwardShow from "./UploadAwardShowDetails";
import AddMentors from "./AddMentors";
import JobApplication from "./JobApplication";
import Services from "./Services";
import EditConsumables from "./EditConsumables";
import OrderConfirmation from "./OrderConfirmation";
import Trends from "./Trends";
import OoveChampian from "./OoveChampian";
import HomePagePayment from "./oldHomepage";
import PageNotFound from "./PageNotFound";
import ProductList from "./ProductList";
import ServicesList from "./ServicesList";
import WhatsappTesting from "./WhatsappTesting";
import AddPincode from "./AddPincode";
import Shop from "./Shop";
import Cart from "./Cart";
import Certificate from "./Certificate";
import RegisteredUser from "./RegisteredUser";
import Tests from "./Tests";
import Analytics from "./Analytics";
import AdminPanel from "./AdminPanel";
import MentorData from "./MentorData";
import WorkshopData from "./WorkshopData";
import GetOnRent from "./GetOnRent";
import ConsumablesData from "./ConsumablesData";
import CourseData from "./CourseData";
import PromotionalData from "./PromotionalData";
import FreeBusinessCards from "./FreeBusinessCards";
import PremiumCards from "./PremiumCards";
import BulkWhatsapp from "./BulkWhatsapp";
import GraphTest from "./GraphTest";
import ViewAppData from "./ViewAppData";
import ViewAllRequests from "./ViewAllRequests";
import BeautyKitRequest from "./BeautyKitRequest";
import BookDemoRequest from "./BookDemoRequest";
import BusinessCardRequest from "./BusinessCardRequest";
import EventRequest from "./EventRequest";
import HireRequest from "./HierRequest";
import MentorshipRequest from "./MentorshipRequest";
import ConsumablesRequest from "./ConsumablesRequest";
import PremiumCardRequest from "./PremiumCardRequest";
import RentRequest from "./RentRequest";
import WorkshopRegistration from "./WorkshopRegistration";
import UsersProfile from "./UsersProfile";
import Csv from "./Csv";
import CertificateUpload from "./CertificateUpload";
import WorkshopDataHindi from "./WorkshopDataHindi";
import Workshop from "./Workshop";
import WorkshopHindiReg from "./WorkshopHindiReg";
import PortfolioNew from "./PortfolioNew";
import Glory from "./Glory";
import GlorySlider from "./GlorySlider";
import ImageGallery from "./ImageGallery";
import AllServices from "./AllServices";
import ReviewPage from "./ReviewPage";
import Testingg from "./Testingg";
import Certificates from "./Certificates";
import CustomerReview from "./CustomerReview";
import TestimonialSlider from "./TestimonialSlider";
import TopLink from "./toplink";
import ListTest from "./ListTest";
import Footer from "./Footer";
import Artist from "./ArtistSearch";
import Header from "./Header";
import LoanDetails from "./LoanDetails";
import LoanRequest from "./LoanRequest";
import GoogleLogin from "./GoogleAuth";
import EmiDetails from "./EmiDetails";
import UploadLoanApplication from "./UploadLoanApplication";
import UploadRepayment from "./UoloadRepayment";
import PrivacyPolicy from "./PrivacyPolicy";
import AboutUs from "./AboutUs";
import ContactUs from "./ContactUs";
import PortfolioData from "./PortfolioData";
import CourseDataHindi from "./CourseDataHindi";
import RequestCallBack from "./RequestCallBack";
import Notification from "./Notification";
import Sidebar from "./Sidebar";
import CustomerData from "./CustomerData";
import ReferredCourses from "./ReferredCourses";
import SignIn from "./SignIn";
import PortfolioAnalyser from "./PortfolioAnalyser";
import PortfolioReviews from "./PortfolioReviews";
import Appointments from "./Appointments";
import DeletePortfolioRequest from "./DeletePortfolioRequest";
import WebsiteMessage from "./WebsiteMessage";
import AllAppointments from "./AllAppointments";
import MyComponent from "./MyComponent";
import SendWhatsapp from "./SendWhatsapp";
import CreateWhatsappTemplate from "./CreateWhatsappTemplate";
import PortfolioFullData from "./PortfolioFullData";
import LocationData from "./LocationData";
import AddSliderImages from "./AddSliderImages";
import PostersCreated from "./PostersCreated";
import AppFeedback from "./AppFeedback";
import AppointmentAnalyser from "./AppointmentAnalyser";
import MyBusiness from "./MyBusiness";
import WorkshopReminder from "./WorkshopReminder";
import DemoAccount from "./DemoAccount";
import UploadCertificates from "./UploadCertificates";
import UploadServiceImages from "./UploadImages";
import UploadRecordedVideo from "./UploadRecordedVideo";
import PushNotifications from "./PushNotifications";
import ClickedLogs from "./ClickedLogs";
import UserBasedRole from "./UserBasedRole";
import WorkshopQuery from "./WorkshopQuery";
import PinWorkshop from "./PinWorkshop";
import HomePagePoster from "./HomepagePoster";
import Notifications from "./Notifications";
import AllCertificates from "./AllCertificates";
import WorkshopAttendance from "./WorkshopAttendance";
import WorkshopAttendanceStatus from "./WorkshopAttendanceStatus";
import WorkshopGlimpses from "./WorkshopGlimpses";
import WorkshopEventRequest from "./WorkshopEventRequest";
import MarkAttendance from "./MarkAttendance";
import Bridal from "./Bridal";
import WorkshopSliderImages from "./WorkshopSlider";
import ExpensesList from "./Expenses";
import WorkshopFeedback from "./WorkshopFeedback";
import AppReferral from "./AppReferral";
import { BridalAtrist } from "./BridalAtrist";
import { BridalClicks } from "./BridalClicks";
import { Tips } from "./Tips";
import { BridalPage } from "./BridalPage";
import SocialMediaGraphics from "./SocialMediaGraphics";
import FreeBannerDesign from "./FreeBannerDesign";
import GlobalNotification from "./GlobalNotification";
import PreviousCardWorks from "./PreviousCardWorks";
import AdvertiseFeedback from "./AdvertiseFeedback";
import AdvertiseDownload from "./AdvertiseDownload";
import BannerRequest from "./BannerRequest";
import PremiumBannerRequest from "./PremiumBannerRequest";
import SmgRequest from "./SmgRequest";
import WhatsappBusinessRequest from "./WhatsappBusinessRequest";
import GoogleBusinessRequest from "./GoogleBusinessRequest";
import WorkshopRegistrationFilter from "./WorkshopRegistrationFilter";
import ShortlistedPortfolios from "./ShortlistedPortfolios";
import { Mehndi } from "./Mehndi";
import { Jwellery } from "./Jwellery";
import { Outfit } from "./Outfit";
import { Prewedding } from "./PreWeddingClick";
import { FunctionClick } from "./FunctionClick";
import { GroomClick } from "./GroomClick";
import { CoupleClick } from "./CoupleClick";
import { Testo } from "./testo";
import WorkshopSessionSlider from "./WorkshopSessionSlider";
import AdvertiseData from "./AdvertiseData";
import UsersLastCheckin from "./UsersLastCheckin";
import LoginLog from "./LoginLog";
import FeedbackReply from "./FeedbackReply";
import WorkshopCode from "./WorkshopCode";
import SMSComponent from "./SMSComponent";
import DownloadCertificate from "./DownloadedCertificate";
import PersonalNotification from "./PersonalNotification";
import UserLogs from "./UserLogs";
import PayUForm from "./Payment";
import WorkshopPayment from "./WorkshopPayment";
import UpcomingSession from "./UpcomingSession";
import WorkshopPaid from "./WorkshopPaid";
import GuidanceSession from "./GuidanceSession";
import SendCode from "./SendCode";
import SendCertificateCode from "./SendCertificateCode";
import WorkshopTag from "./WorkshopTag";
import TopicRequest from "./TopicRequest";
import AdvertiseSlider from "./advertiseSlider";
import PreviousSM from "./PreviousSM";
import CustomCards from "./CustomCards";
import PremiumSMG from "./PremiumSMG";
// import OpenAI from "./OpenAI";


export default function Navigator() {
    return (
        <Router>
            <Routes>
                {/* <Route path="/:id" caseSensitive={false} element={<Home />} /> */}
                {/* <Route path="/" caseSensitive={false} element={<Homepage />} /> */}
                <Route path="/upload" caseSensitive={false} element={<ExcelUpload />} />
                <Route path="/admin" caseSensitive={false} element={<Admin />} />
                <Route path="/login" caseSensitive={false} element={<PhoneAuth />} />
                <Route path="/location" caseSensitive={false} element={<GetLocation />} />
                <Route path="/stick" caseSensitive={false} element={<Stick />} />
                <Route path="/search" caseSensitive={false} element={<Search />} />
                <Route path="/userlist" caseSensitive={false} element={<Unregistereduser />} />
                <Route path="/addeduser" caseSensitive={false} element={<RegisteredUser />} />
                <Route path="/invite" caseSensitive={false} element={<Invite />} />
                <Route path="/portfolio" caseSensitive={false} element={<Portfolio />} />
                <Route path="/oldHomescreen" caseSensitive={false} element={<OldHomescreen />} />
                <Route path="/testing/:id" caseSensitive={false} element={<Testing />} />
                <Route path="/contact" caseSensitive={false} element={<Contact />} />
                <Route path="/testport" caseSensitive={false} element={<Testport />} />
                <Route path="/beatrainer" caseSensitive={false} element={<BeATrainer />} />
                <Route path="/bedistributor" caseSensitive={false} element={<BeDistributor />} />
                <Route path="/feedback/:id" caseSensitive={false} element={<Feedback />} />
                <Route path="/filters" caseSensitive={false} element={<FilterTesting />} />
                <Route path="/upload_course" caseSensitive={false} element={<UploadCourse />} />
                <Route path="/upload_consumables" caseSensitive={false} element={<UploadConsumables />} />
                <Route path="/upload_sellproducts" caseSensitive={false} element={<UploadSellProducts />} />
                <Route path="/upload_workshop" caseSensitive={false} element={<UploadWorkshopEvent />} />
                <Route path="/share_workshop/:id" caseSensitive={false} element={<ShareWorkshop />} />
                <Route path="/upload_awardshow" caseSensitive={false} element={<UploadAwardShow />} />
                <Route path="/add_mentors" caseSensitive={false} element={<AddMentors />} />
                <Route path="/job_application" caseSensitive={false} element={<JobApplication />} />
                <Route path="/quicklinks" caseSensitive={false} element={<Quicklinks />} />
                <Route path="/edit_consumables" caseSensitive={false} element={<EditConsumables />} />
                <Route path="/order_confirmation" caseSensitive={false} element={<OrderConfirmation />} />
                <Route path="/trends" caseSensitive={false} element={<Trends />} />
                <Route path="/oovve_champian" caseSensitive={false} element={<OoveChampian />} />
                <Route path="/payment" caseSensitive={false} element={<HomePagePayment />} />
                <Route path="/error" caseSensitive={false} element={<PageNotFound />} />
                <Route path="/products" caseSensitive={false} element={<ProductList />} />
                <Route path="/services_list" caseSensitive={false} element={<ServicesList />} />
                <Route path="/fb" caseSensitive={false} element={<WhatsappTesting />} />
                <Route path="/addpincode" caseSensitive={false} element={<AddPincode />} />
                <Route path="/shop" caseSensitive={false} element={<Shop />} />
                <Route path="/cart" caseSensitive={false} element={<Cart />} />
                <Route path="/certificate/:id" caseSensitive={false} element={<Certificate />} />
                <Route path="/tests" caseSensitive={false} element={<Tests />} />
                <Route path="/analytics" caseSensitive={false} element={<Analytics />} />
                <Route path="/admin_panel" caseSensitive={false} element={<AdminPanel />} />
                <Route path="/mentor_data" caseSensitive={false} element={<MentorData />} />
                <Route path="/workshop_data" caseSensitive={false} element={<WorkshopData />} />
                <Route path="/workshop_data_hindi" caseSensitive={false} element={<WorkshopDataHindi />} />
                <Route path="/getonrent_data" caseSensitive={false} element={<GetOnRent />} />
                <Route path="/consumables_data" caseSensitive={false} element={<ConsumablesData />} />
                <Route path="/course_data" caseSensitive={false} element={<CourseData />} />
                <Route path="/promotional_data" caseSensitive={false} element={<PromotionalData />} />
                <Route path="/freebusinesscards_data" caseSensitive={false} element={<FreeBusinessCards />} />
                <Route path="/premiumcards_data" caseSensitive={false} element={<PremiumCards />} />
                <Route path="/bulk_whatsapp" caseSensitive={false} element={<BulkWhatsapp />} />
                <Route path="/graph_test" caseSensitive={false} element={<GraphTest />} />
                <Route path="/view_app_data" caseSensitive={false} element={<ViewAppData />} />
                <Route path="/view_requests" caseSensitive={false} element={<ViewAllRequests />} />
                <Route path="/beauty_kit_requests" caseSensitive={false} element={<BeautyKitRequest />} />
                <Route path="/book_demo_requests" caseSensitive={false} element={<BookDemoRequest />} />
                <Route path="/business_card_request" caseSensitive={false} element={<BusinessCardRequest />} />
                <Route path="/event_request" caseSensitive={false} element={<EventRequest />} />
                <Route path="/hire_request" caseSensitive={false} element={<HireRequest />} />
                <Route path="/mentorship_request" caseSensitive={false} element={<MentorshipRequest />} />
                <Route path="/consumables_request" caseSensitive={false} element={<ConsumablesRequest />} />
                <Route path="/premium_card_request" caseSensitive={false} element={<PremiumCardRequest />} />
                <Route path="/rent_request" caseSensitive={false} element={<RentRequest />} />
                <Route path="/workshop_registration" caseSensitive={false} element={<WorkshopRegistration />} />
                <Route path="/workshop" caseSensitive={false} element={<Workshop />} />
                <Route path="/workshop_hindi_reg" caseSensitive={false} element={<WorkshopHindiReg />} />
                <Route path="/users_profile" caseSensitive={false} element={<UsersProfile />} />
                <Route path="/csv" caseSensitive={false} element={<Csv />} />
                <Route path="/certificate_upload" caseSensitive={false} element={<CertificateUpload />} />
                <Route path="/portfolio_new" caseSensitive={false} element={<PortfolioNew />} />
                <Route path="/:id" caseSensitive={false} element={<Glory />} />
                <Route path="/gslider" caseSensitive={false} element={<GlorySlider />} />
                <Route path="/:id/gallery" caseSensitive={false} element={<ImageGallery />} />
                <Route path="/:id/services" caseSensitive={false} element={<AllServices />} />
                <Route path="/:id/review" caseSensitive={false} element={<ReviewPage />} />
                <Route path="/testingg" caseSensitive={false} element={<Testingg />} />
                <Route path="/:id/certificates" caseSensitive={false} element={<Certificates />} />
                <Route path="/:id/customer_review" caseSensitive={false} element={<CustomerReview />} />
                <Route path="/testimonial_slider" caseSensitive={false} element={<TestimonialSlider />} />
                <Route path="/toplink" caseSensitive={false} element={<TopLink />} />
                <Route path="/" caseSensitive={false} element={<ListTest />} />
                <Route path="/footer" caseSensitive={false} element={<Footer />} />
                <Route path="/artist/:id" caseSensitive={false} element={<Artist />} />
                <Route path="/header" caseSensitive={false} element={<Header />} />
                <Route path="/loan_request" caseSensitive={false} element={<LoanRequest />} />
                <Route path="/loan_details/:id" caseSensitive={false} element={<LoanDetails />} />
                <Route path="/googlelogin" caseSensitive={false} element={<GoogleLogin />} />
                <Route path="/emi_details/:id" caseSensitive={false} element={<EmiDetails />} />
                <Route path="/upload_loan_application" caseSensitive={false} element={<UploadLoanApplication />} />
                <Route path="/upload_repayment" caseSensitive={false} element={<UploadRepayment />} />
                <Route path="/privacy_policy" caseSensitive={false} element={<PrivacyPolicy />} />
                <Route path="/aboutus" caseSensitive={false} element={<AboutUs />} />
                <Route path="/contactus" caseSensitive={false} element={<ContactUs />} />
                <Route path="/portfolio_data" caseSensitive={false} element={<PortfolioData />} />
                <Route path="/course_data_hindi" caseSensitive={false} element={<CourseDataHindi />} />
                <Route path="/request_callback" caseSensitive={false} element={<RequestCallBack />} />
                <Route path="/notification" caseSensitive={false} element={<Notification />} />
                <Route path="/sidebar" caseSensitive={false} element={<Sidebar />} />
                <Route path="/customer_data" caseSensitive={false} element={<CustomerData />} />
                <Route path="/referred_courses" caseSensitive={false} element={<ReferredCourses />} />
                <Route path="/signin" caseSensitive={false} element={<SignIn />} />
                <Route path="/portfolio_analyser" caseSensitive={false} element={<PortfolioAnalyser />} />
                <Route path="/portfolio_reviews" caseSensitive={false} element={<PortfolioReviews />} />
                <Route path="/appointments" caseSensitive={false} element={<Appointments />} />
                <Route path="/delete_portfolio_req" caseSensitive={false} element={<DeletePortfolioRequest />} />
                <Route path="/website_message" caseSensitive={false} element={<WebsiteMessage />} />
                <Route path="/app_feedback" caseSensitive={false} element={<AppFeedback />} />
                <Route path="/appointment_booked" caseSensitive={false} element={<AllAppointments />} />
                <Route path="/mycomponent" caseSensitive={false} element={<MyComponent />} />
                <Route path="/send_whatsapp" caseSensitive={false} element={<SendWhatsapp />} />
                <Route path="/create_whatsapp_template" caseSensitive={false} element={<CreateWhatsappTemplate />} />
                <Route path="/portfolio_full_data" caseSensitive={false} element={<PortfolioFullData />} />
                <Route path="/location_data" caseSensitive={false} element={<LocationData />} />
                <Route path="/add_slider_images" caseSensitive={false} element={<AddSliderImages />} />
                <Route path="/posters_created" caseSensitive={false} element={<PostersCreated />} />
                <Route path="/appointment_analyser" caseSensitive={false} element={<AppointmentAnalyser />} />
                <Route path="/my_business" caseSensitive={false} element={<MyBusiness />} />
                <Route path="/workshop_reminder" caseSensitive={false} element={<WorkshopReminder />} />
                {/* <Route path="/demo_account" caseSensitive={false} element={<DemoAccount />} /> */}
                <Route path="/upload_certificates" caseSensitive={false} element={<UploadCertificates />} />
                <Route path="/upload_service_images" caseSensitive={false} element={<UploadServiceImages />} />
                <Route path="/upload_recorded_video" caseSensitive={false} element={<UploadRecordedVideo />} />
                <Route path="/push_notifications" caseSensitive={false} element={<PushNotifications />} />
                <Route path="/clicked_logs" caseSensitive={false} element={<ClickedLogs />} />
                <Route path="/user_based_role" caseSensitive={false} element={<UserBasedRole />} />
                <Route path="/workshop_query" caseSensitive={false} element={<WorkshopQuery />} />
                <Route path="/pin_workshop" caseSensitive={false} element={<PinWorkshop />} />
                <Route path="/homepage_poster" caseSensitive={false} element={<HomePagePoster />} />
                <Route path="/notifications" caseSensitive={false} element={<Notifications />} />
                <Route path="/all_certificates" caseSensitive={false} element={<AllCertificates />} />
                <Route path="/workshop_attendance" caseSensitive={false} element={<WorkshopAttendance />} />
                <Route path="/workshop_attendance_status" caseSensitive={false} element={<WorkshopAttendanceStatus />} />
                <Route path="/workshop_glimpses" caseSensitive={false} element={<WorkshopGlimpses />} />
                <Route path="/workshop_event_request" caseSensitive={false} element={<WorkshopEventRequest />} />
                <Route path="/mark_attendance/:id" caseSensitive={false} element={<MarkAttendance />} />
                <Route path="/bridal" caseSensitive={false} element={<Bridal />} />
                <Route path="/workshop_slider_image" caseSensitive={false} element={<WorkshopSliderImages />} />
                <Route path="/expenses_list" caseSensitive={false} element={<ExpensesList />} />
                <Route path="/workshop_feedback" caseSensitive={false} element={<WorkshopFeedback />} />
                <Route path="/app_referral" caseSensitive={false} element={<AppReferral />} />
                <Route path="/bridal_page" caseSensitive={false} element={<BridalPage />} />
                <Route path="/bridal_artist" caseSensitive={false} element={<BridalAtrist />} />
                <Route path="/bridal_clicks" caseSensitive={false} element={<BridalClicks />} />
                <Route path="/tipsforyou" caseSensitive={false} element={<Tips />} />
                <Route path="/social_media_graphics" caseSensitive={false} element={<SocialMediaGraphics />} />
                <Route path="/banner_design" caseSensitive={false} element={<FreeBannerDesign />} />
                <Route path="/global_notification" caseSensitive={false} element={<GlobalNotification />} />
                <Route path="/previous_card_work" caseSensitive={false} element={<PreviousCardWorks />} />
                <Route path="/advertise_feedback" caseSensitive={false} element={<AdvertiseFeedback />} />
                <Route path="/advertise_download" caseSensitive={false} element={<AdvertiseDownload />} />
                <Route path="/banner_request" caseSensitive={false} element={<BannerRequest />} />
                <Route path="/premium_banner_request" caseSensitive={false} element={<PremiumBannerRequest />} />
                <Route path="/smg_request" caseSensitive={false} element={<SmgRequest />} />
                <Route path="/whatsapp_business_request" caseSensitive={false} element={<WhatsappBusinessRequest />} />
                <Route path="/google_business_request" caseSensitive={false} element={<GoogleBusinessRequest />} />
                <Route path="/workshop_registration_count" caseSensitive={false} element={<WorkshopRegistrationFilter />} />
                <Route path="/shortlisted_portfolio" caseSensitive={false} element={<ShortlistedPortfolios />} />
                <Route path="/mehndi" caseSensitive={false} element={<Mehndi />} />
                <Route path="/jwellery" caseSensitive={false} element={<Jwellery />} />
                <Route path="/outfit" caseSensitive={false} element={<Outfit />} />
                <Route path="/couple_clicks" caseSensitive={false} element={<CoupleClick />} />
                <Route path="/groom_clicks" caseSensitive={false} element={<GroomClick />} />
                <Route path="/function_clicks" caseSensitive={false} element={<FunctionClick />} />
                <Route path="/prewedding_clicks" caseSensitive={false} element={<Prewedding />} />
                <Route path="/testo" caseSensitive={false} element={<Testo />} />
                <Route path="/session_slider_images" caseSensitive={false} element={<WorkshopSessionSlider />} />
                <Route path="/advertise_data" caseSensitive={false} element={<AdvertiseData />} />
                <Route path="/user_last_checkin" caseSensitive={false} element={<UsersLastCheckin />} />
                <Route path="/login_logs" caseSensitive={false} element={<LoginLog />} />
                <Route path="/feedback_reply" caseSensitive={false} element={<FeedbackReply />} />
                <Route path="/workshop_code" caseSensitive={false} element={<WorkshopCode />} />
                <Route path="/sms" caseSensitive={false} element={<SMSComponent />} />
                <Route path="/downloaded_certificate" caseSensitive={false} element={<DownloadCertificate />} />
                <Route path="/personal_notification" caseSensitive={false} element={<PersonalNotification />} />
                <Route path="/users_log" caseSensitive={false} element={<UserLogs />} />
                <Route path="/payment_payu" caseSensitive={false} element={<PayUForm />} />
                {/* <Route path="/openai" caseSensitive={false} element={<OpenAI />} /> */}
                {/* <Route path="/register" caseSensitive={false} element={<Register />} /> */}
                {/* <Route path="*" component={<Homepage />} /> */}
                <Route path="/workshop_payment" caseSensitive={false} element={<WorkshopPayment />} />
                <Route path="/upcoming_session" caseSensitive={false} element={<UpcomingSession />} />
                <Route path="/paid_workshop" caseSensitive={false} element={<WorkshopPaid />} />
                <Route path="/guidance_session" caseSensitive={false} element={<GuidanceSession />} />
                <Route path="/get_workshop_users/:id" caseSensitive={false} element={<SendCode />} />
                <Route path="/send_certificate_code" caseSensitive={false} element={<SendCertificateCode />} />
                <Route path="/workshop_tag" caseSensitive={false} element={<WorkshopTag />} />
                <Route path="/topic_request" caseSensitive={false} element={<TopicRequest />} />
                <Route path="/advertise_slider" caseSensitive={false} element={<AdvertiseSlider />} />
                <Route path="/previous_sm" caseSensitive={false} element={<PreviousSM />} />
                <Route path="/custom_cards" caseSensitive={false} element={<CustomCards />} />
                <Route path="/premium_smg_request" caseSensitive={false} element={<PremiumSMG />} />
            </Routes>
        </Router>
    );
};