import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import db from "./Firebase";
import { CSVLink } from "react-csv";
import Moment from 'moment';
import { extendMoment } from 'moment-range';

function WorkshopRegistrationFilter() {
    const navigate = useNavigate();
    const moment = extendMoment(Moment);
    const headers = [
        { label: "Full Name", key: "fullName" },
        { label: "Phone No", key: "phoneNumber" },
        { label: "Total No of Registration", key: "count" },
    ];

    const [data, setData] = useState([]);
    const [csvdata, setCsvdata] = useState([]);
    const [phoneNumbers, setPhoneNumbers] = useState({});
    const [lastVisible, setLastVisible] = useState(null);
    const [hasMore, setHasMore] = useState(true);
    const [sortOrder, setSortOrder] = useState('asc');

    useEffect(() => {
        const storedUsername = localStorage.getItem("email");
        const storedPassword = localStorage.getItem("password");
        const storedRemember = localStorage.getItem("remember");

        if (storedRemember === "false" || !storedUsername || !storedPassword) {
            navigate('/signin');
        }
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            const registrationSnapshot = await db.collection("workshop_registration")
                .orderBy("created_date", "desc")
                .limit(20)
                .get();

            const lastVisibleDoc = registrationSnapshot.docs[registrationSnapshot.docs.length - 1];
            setLastVisible(lastVisibleDoc);

            const registrationData = registrationSnapshot.docs.map(doc => doc.data());

            // Extract unique phone numbers from both "phone" and "phone_no" fields
            const phoneNumbersData = registrationData.reduce((acc, data) => {
                const phoneNumberFromPhone = data.phone;
                const phoneNumberFromPhoneNo = data.phone_no;

                if (phoneNumberFromPhone) {
                    acc[phoneNumberFromPhone] = acc[phoneNumberFromPhone] || { count: 0, fullName: '' };
                    acc[phoneNumberFromPhone].count += 1;
                    acc[phoneNumberFromPhone].fullName = data.full_name;
                }

                if (phoneNumberFromPhoneNo) {
                    acc[phoneNumberFromPhoneNo] = acc[phoneNumberFromPhoneNo] || { count: 0, fullName: '' };
                    acc[phoneNumberFromPhoneNo].count += 1;
                    acc[phoneNumberFromPhoneNo].fullName = data.full_name;
                }

                return acc;
            }, {});

            setPhoneNumbers(phoneNumbersData);
            setCsvdata(registrationData);
            setData(registrationData);
        };

        fetchData();
    }, []);

    const loadMoreData = async () => {
        const registrationSnapshot = await db.collection("workshop_registration")
            .orderBy("created_date", "desc")
            .startAfter(lastVisible)
            .limit(20)
            .get();

        const lastVisibleDoc = registrationSnapshot.docs[registrationSnapshot.docs.length - 1];
        setLastVisible(lastVisibleDoc);

        if (registrationSnapshot.docs.length < 20) {
            setHasMore(false);
        }

        const registrationData = registrationSnapshot.docs.map(doc => doc.data());

        // Extract unique phone numbers from both "phone" and "phone_no" fields
        const phoneNumbersData = registrationData.reduce((acc, data) => {
            const phoneNumberFromPhone = data.phone;
            const phoneNumberFromPhoneNo = data.phone_no;

            if (phoneNumberFromPhone) {
                acc[phoneNumberFromPhone] = acc[phoneNumberFromPhone] || { count: 0, fullName: '' };
                acc[phoneNumberFromPhone].count += 1;
                acc[phoneNumberFromPhone].fullName = data.full_name;
            }

            if (phoneNumberFromPhoneNo) {
                acc[phoneNumberFromPhoneNo] = acc[phoneNumberFromPhoneNo] || { count: 0, fullName: '' };
                acc[phoneNumberFromPhoneNo].count += 1;
                acc[phoneNumberFromPhoneNo].fullName = data.full_name;
            }

            return acc;
        }, {});

        setPhoneNumbers(prevPhoneNumbers => ({
            ...prevPhoneNumbers,
            ...phoneNumbersData
        }));
        setCsvdata(prevCsvData => [
            ...prevCsvData,
            ...registrationData
        ]);
        setData(prevData => [
            ...prevData,
            ...registrationData
        ]);
    };

    const handleSort = () => {
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    };

    useEffect(() => {
        const fetchData = async () => {
            const registrationSnapshot = await db.collection("workshop_registration").get();
            const attendanceSnapshot = await db.collection("workshop_attendance").get();

            const registrationsData = registrationSnapshot.docs.map(doc => doc.data());
            const attendanceData = attendanceSnapshot.docs.map(doc => doc.data());

            const phoneNumbersData = registrationsData.reduce((acc, data) => {
                const phoneNumberFromPhone = data.phone;
                const phoneNumberFromPhoneNo = data.phone_no;

                if (phoneNumberFromPhone) {
                    acc[phoneNumberFromPhone] = acc[phoneNumberFromPhone] || { count: 0, fullName: '', attendanceCount: 0 };
                    acc[phoneNumberFromPhone].count += 1;
                    acc[phoneNumberFromPhone].fullName = data.full_name;
                }

                if (phoneNumberFromPhoneNo) {
                    acc[phoneNumberFromPhoneNo] = acc[phoneNumberFromPhoneNo] || { count: 0, fullName: '', attendanceCount: 0 };
                    acc[phoneNumberFromPhoneNo].count += 1;
                    acc[phoneNumberFromPhoneNo].fullName = data.full_name;
                }

                return acc;
            }, {});

            attendanceData.forEach(attendance => {
                const phoneNumber = attendance.phone_no;

                if (phoneNumber && phoneNumbersData[phoneNumber]) {
                    phoneNumbersData[phoneNumber].attendanceCount += 1;
                }
            });

            const sortedPhoneNumbers = Object.entries(phoneNumbersData)
                .sort(([, a], [, b]) => sortOrder === 'asc' ? a.count - b.count : b.count - a.count)
                .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

            setPhoneNumbers(sortedPhoneNumbers);
            setCsvdata(registrationsData);
            setData(registrationsData);
        };

        fetchData();
    }, [sortOrder]);

    const prepareCSVData = () => {
        const csvData = Object.entries(phoneNumbers).map(([phoneNumber, { count, fullName }]) => ([
            fullName,
            phoneNumber,
            count,
        ]));

        return csvData;
    };

    return (
        <>
            <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
                <div class="p-2" style={{ paddingBottom: "5px", display: "flex", justifyContent: "space-between" }}>
                    <Link to="/admin_panel"><img src="/images/menubutton.png" class="menu-toggle" alt="Responsive image" style={{ height: "2rem" }} /></Link>
                    <CSVLink data={prepareCSVData()} headers={headers} filename={"portfolio_anyliser_data.csv"}>
                        <span class="btn bg-gradient-secondary btn-sm mb-0 mr-2" style={{ color: "white" }}>
                            &nbsp; Download Excel
                        </span>
                    </CSVLink>
                </div>
                <div class="">
                    <div class="p-2">
                        <div style={{ textAlign: "end", paddingBottom: "5px" }}>
                        </div>
                        <div class="card">
                            <div class="card-header pb-0 px-3">
                                <h6 class="mb-2">Workshop Registration count</h6>
                            </div>
                            <div class="card">
                                <div class="table-responsive fix-width scroll-inner">
                                    <table class="table table-bordered table-hover">
                                        <thead class="thead-dark" style={{ position: "sticky", top: "0" }}>
                                            <tr>
                                                <th class="col">Full Name</th>
                                                <th class="col ps-2">Phone No</th>
                                                <th className="col ps-2" onClick={handleSort} style={{ cursor: 'pointer' }}>
                                                    Total Registration
                                                    {sortOrder === 'asc' ? ' ▲' : ' ▼'}
                                                </th>
                                                <th className="col ps-2">Attended</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Object.entries(phoneNumbers).map(([phoneNumber, { count, fullName, attendanceCount }]) => (
                                                <tr key={phoneNumber}>
                                                    <td>
                                                        <p className="text-xs font-weight-bold mb-0">{fullName}</p>
                                                    </td>
                                                    <td>
                                                        <p className="text-xs font-weight-bold mb-0">{phoneNumber}</p>
                                                    </td>
                                                    <td>
                                                        <div className="d-flex px-2 py-1">
                                                            <div className="d-flex flex-column justify-content-center">
                                                                <h6 className="mb-0 text-xs" style={{ width: "15rem" }}>{count}</h6>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="d-flex px-2 py-1">
                                                            <div className="d-flex flex-column justify-content-center">
                                                                <h6 className="mb-0 text-xs">{attendanceCount}</h6>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    {hasMore && (
                                        <div className="" style={{ paddingTop: "2rem" }}>
                                            <button onClick={loadMoreData} className="btn btn-dark">Load More</button>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
}

export default WorkshopRegistrationFilter;


// import React, { useState, useEffect } from 'react';
// import { useNavigate, Link, useParams } from 'react-router-dom';
// import db from "./Firebase";
// import { arrayUnion } from "firebase/firestore";
// import { storage } from './Firebase';
// import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
// import Moment from 'moment';
// import { extendMoment } from 'moment-range';
// import ViewAllRequests from './ViewAllRequests';
// import { CSVLink } from "react-csv";

// function WorkshopRegistrationFilter() {
//     const navigate = useNavigate();
//     const moment = extendMoment(Moment);
//     const headers = [
//         { label: "Full Name", key: "fullName" },
//         { label: "Phone No", key: "phoneNumber" },
//         { label: "Total No of Registration", key: "count" },
//     ];

//     const [data, setData] = useState([])
//     const [csvdata, setCsvdata] = useState([]);
//     const [phoneNumbers, setPhoneNumbers] = useState({});

//     const [sortOrder, setSortOrder] = useState('asc');

//     const handleSort = () => {
//         setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
//     };


//     // useEffect(() => {
//     //     db.collection("workshop_registration").onSnapshot((snapshot) => {
//     //         const registrationsData = snapshot.docs.map((doc) => doc.data());

//     //         // Extract unique phone numbers from both "phone" and "phone_no" fields
//     //         const phoneNumbersData = registrationsData.reduce((acc, data) => {
//     //             const phoneNumberFromPhone = data.phone;
//     //             const phoneNumberFromPhoneNo = data.phone_no;

//     //             // Check if phone number exists in "phone" field
//     //             if (phoneNumberFromPhone) {
//     //                 acc[phoneNumberFromPhone] = acc[phoneNumberFromPhone] || { count: 0, fullName: '' };
//     //                 acc[phoneNumberFromPhone].count += 1;
//     //                 acc[phoneNumberFromPhone].fullName = data.full_name;
//     //             }

//     //             // Check if phone number exists in "phone_no" field
//     //             if (phoneNumberFromPhoneNo) {
//     //                 acc[phoneNumberFromPhoneNo] = acc[phoneNumberFromPhoneNo] || { count: 0, fullName: '' };
//     //                 acc[phoneNumberFromPhoneNo].count += 1;
//     //                 acc[phoneNumberFromPhoneNo].fullName = data.full_name;
//     //             }

//     //             return acc;
//     //         }, {});

//     //         setPhoneNumbers(phoneNumbersData);
//     //         setCsvdata(registrationsData);
//     //         setData(registrationsData);
//     //         // setPhoneNumbers(Object.values(phoneNumbersData));
//     //         // setCsvdata(registrationsData);
//     //         console.log(registrationsData)
//     //     });
//     // }, []);

//     // useEffect(() => {
//     //     db.collection("workshop_registration").onSnapshot((snapshot) => {
//     //         const registrationsData = snapshot.docs.map((doc) => doc.data());

//     //         // Extract unique phone numbers from both "phone" and "phone_no" fields
//     //         const phoneNumbersData = registrationsData.reduce((acc, data) => {
//     //             const phoneNumberFromPhone = data.phone;
//     //             const phoneNumberFromPhoneNo = data.phone_no;

//     //             // Check if phone number exists in "phone" field
//     //             if (phoneNumberFromPhone) {
//     //                 acc[phoneNumberFromPhone] = acc[phoneNumberFromPhone] || { count: 0, fullName: '' };
//     //                 acc[phoneNumberFromPhone].count += 1;
//     //                 acc[phoneNumberFromPhone].fullName = data.full_name;
//     //             }

//     //             // Check if phone number exists in "phone_no" field
//     //             if (phoneNumberFromPhoneNo) {
//     //                 acc[phoneNumberFromPhoneNo] = acc[phoneNumberFromPhoneNo] || { count: 0, fullName: '' };
//     //                 acc[phoneNumberFromPhoneNo].count += 1;
//     //                 acc[phoneNumberFromPhoneNo].fullName = data.full_name;
//     //             }

//     //             return acc;
//     //         }, {});

//     //         // Sort phoneNumbersData based on the count property
//     //         const sortedPhoneNumbers = Object.entries(phoneNumbersData)
//     //             .sort(([, a], [, b]) => sortOrder === 'asc' ? a.count - b.count : b.count - a.count)
//     //             .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

//     //         setPhoneNumbers(sortedPhoneNumbers);
//     //         setCsvdata(registrationsData);
//     //         setData(registrationsData);
//     //     });
//     // }, [sortOrder]);


//     useEffect(() => {
//         // Fetch data from "workshop_registration" collection
//         const registrationPromise = db.collection("workshop_registration").get()
//             .then(snapshot => {
//                 return snapshot.docs.map(doc => doc.data());
//             });

//         // Fetch data from "workshop_attendance" collection
//         const attendancePromise = db.collection("workshop_attendance").get()
//             .then(snapshot => {
//                 return snapshot.docs.map(doc => doc.data());
//             });

//         // Combine registration and attendance data based on "phone_no"
//         Promise.all([registrationPromise, attendancePromise])
//             .then(([registrationsData, attendanceData]) => {
//                 const phoneNumbersData = registrationsData.reduce((acc, data) => {
//                     const phoneNumberFromPhone = data.phone;
//                     const phoneNumberFromPhoneNo = data.phone_no;

//                     if (phoneNumberFromPhone) {
//                         acc[phoneNumberFromPhone] = acc[phoneNumberFromPhone] || { count: 0, fullName: '', attendanceCount: 0 };
//                         acc[phoneNumberFromPhone].count += 1;
//                         acc[phoneNumberFromPhone].fullName = data.full_name;
//                     }

//                     if (phoneNumberFromPhoneNo) {
//                         acc[phoneNumberFromPhoneNo] = acc[phoneNumberFromPhoneNo] || { count: 0, fullName: '', attendanceCount: 0 };
//                         acc[phoneNumberFromPhoneNo].count += 1;
//                         acc[phoneNumberFromPhoneNo].fullName = data.full_name;
//                     }

//                     return acc;
//                 }, {});

//                 // Update attendance count in the phoneNumbersData
//                 attendanceData.forEach(attendance => {
//                     const phoneNumber = attendance.phone_no;

//                     if (phoneNumber && phoneNumbersData[phoneNumber]) {
//                         phoneNumbersData[phoneNumber].attendanceCount += 1;
//                     }
//                 });

//                 // Sort phoneNumbersData based on the count property
//                 const sortedPhoneNumbers = Object.entries(phoneNumbersData)
//                     .sort(([, a], [, b]) => sortOrder === 'asc' ? a.count - b.count : b.count - a.count)
//                     .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

//                 setPhoneNumbers(sortedPhoneNumbers);
//                 setCsvdata(registrationsData);
//                 setData(registrationsData);
//             })
//             .catch(error => {
//                 console.error("Error fetching data:", error);
//             });
//     }, [sortOrder]);


//     const handleDownloadFilteredData = () => {
//         const filteredData = phoneNumbers.map(({ fullName, phoneNumber, count }) => ({
//             fullName,
//             phoneNumber,
//             count,
//         }));

//         setCsvdata(filteredData);
//     };

//     const prepareCSVData = () => {
//         const csvData = Object.entries(phoneNumbers).map(([phoneNumber, { count, fullName }]) => ([
//             fullName,
//             phoneNumber,
//             count,
//             // Add more columns as needed
//         ]));

//         return csvData;
//     };

//     return (
//         <>
//             {/* <ViewAllRequests /> */}

//             <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
//                 <div class="p-2" style={{ paddingBottom: "5px", display: "flex", justifyContent: "space-between" }}>
//                     {/* <span class="btn bg-gradient-secondary btn-sm mb-0 mr-2" style={{ color: "white" }} >&nbsp; Download Excel</span> */}
//                     <Link to="/admin_panel"><img src="/images/menubutton.png" class="menu-toggle" alt="Responsive image" style={{ height: "2rem" }} /></Link>
//                     <CSVLink data={prepareCSVData()} headers={headers} filename={"portfolio_anyliser_data.csv"}>
//                         <span class="btn bg-gradient-secondary btn-sm mb-0 mr-2" style={{ color: "white" }}>
//                             &nbsp; Download Excel
//                         </span>
//                     </CSVLink>
//                 </div>
//                 <div class="">


//                     <div class="p-2">
//                         <div style={{ textAlign: "end", paddingBottom: "5px" }}>


//                         </div>
//                         <div class="card">
//                             <div class="card-header pb-0 px-3">
//                                 <h6 class="mb-2">Workshop Registration count</h6>
//                             </div>
//                             <div class="">
//                                 {/* <div style={{ textAlign: "end", paddingBottom: "5px" }}>
//                         <a class="btn bg-gradient-primary btn-sm mb-0" style={{ color: "white" }} onClick={() => changeStatus()}>+&nbsp; Add Rent Details</a>
//                     </div> */}
//                                 <div class="card">
//                                     <div class="table-responsive fix-width scroll-inner">
//                                         <table class="table table-bordered table-hover">
//                                             <thead class="thead-dark" style={{ position: "sticky", top: "0" }}>
//                                                 <tr>
//                                                     <th class="col">Full Name</th>
//                                                     <th class="col ps-2">Phone No</th>
//                                                     <th className="col ps-2" onClick={handleSort} style={{ cursor: 'pointer' }}>
//                                                         Total Registration
//                                                         {sortOrder === 'asc' ? ' ▲' : ' ▼'}
//                                                     </th>
//                                                     <th className="col ps-2">Attended</th>
//                                                 </tr>
//                                             </thead>
//                                             <tbody>
//                                                 {Object.entries(phoneNumbers).map(([phoneNumber, { count, fullName, attendanceCount }]) => (
//                                                     <tr key={phoneNumber}>
//                                                         <td>
//                                                             <p className="text-xs font-weight-bold mb-0">{fullName}</p>
//                                                         </td>
//                                                         <td>
//                                                             <p className="text-xs font-weight-bold mb-0">{phoneNumber}</p>
//                                                         </td>
//                                                         <td>
//                                                             <div className="d-flex px-2 py-1">
//                                                                 <div className="d-flex flex-column justify-content-center">
//                                                                     <h6 className="mb-0 text-xs" style={{ width: "15rem" }}>{count}</h6>
//                                                                 </div>
//                                                             </div>
//                                                         </td>
//                                                         <td>
//                                                             <div className="d-flex px-2 py-1">
//                                                                 <div className="d-flex flex-column justify-content-center">
//                                                                     <h6 className="mb-0 text-xs">{attendanceCount}</h6>
//                                                                 </div>
//                                                             </div>
//                                                         </td>
//                                                     </tr>
//                                                 ))}


//                                             </tbody>
//                                         </table>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>

//                 </div>


//             </main>


//         </>
//     )
// }

// export default WorkshopRegistrationFilter;