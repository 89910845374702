import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import db from "./Firebase";
import { CSVLink } from "react-csv";
import Moment from 'moment';
import { extendMoment } from 'moment-range';

function WorkshopRegistration() {
    const navigate = useNavigate();
    const moment = extendMoment(Moment);
    const [data, setData] = useState([]);
    const [csvdata, setCsvdata] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [lastVisible, setLastVisible] = useState(null);
    const [loading, setLoading] = useState(true);
    const [hasMore, setHasMore] = useState(true);

    const headers = [
        { label: "Full Name", key: "full_name" },
        { label: "Phone Number", key: "phone" },
        { label: "Email", key: "email" },
        { label: "Workshop Location", key: "workshop_location" },
        { label: "Workshop Event Name", key: "workshop_event_name" },
        { label: "Created Date", key: "created_date", dataFormat: "MM/DD/YY, h:mm a" },
    ];

    useEffect(() => {
        const storedUsername = localStorage.getItem("email");
        const storedPassword = localStorage.getItem("password");
        const storedRemember = localStorage.getItem("remember");

        if (storedRemember === "false" || !storedUsername || !storedPassword) {
            navigate('/signin');
        }
    }, []);

    useEffect(() => {
        fetchInitialData();
    }, []);

    const fetchInitialData = async () => {
        setLoading(true);
        const snapshot = await db.collection("workshop_registration")
            .orderBy("created_date", "desc")
            .limit(20)
            .get();
        const lastVisibleDoc = snapshot.docs[snapshot.docs.length - 1];
        const newData = snapshot.docs.map((doc) => ({
            id: doc.id,
            data: doc.data(),
        }));

        setData(newData);
        setLastVisible(lastVisibleDoc);
        setCsvdata(newData.map((item) => ({
            ...item.data,
            created_date: moment(item.data.created_date.toDate().toISOString()).format("MM/DD/YY, h:mm a"),
        })));
        setHasMore(snapshot.docs.length === 20);
        setLoading(false);
    };

    const loadMoreData = async () => {
        if (!lastVisible) return;

        setLoading(true);
        const snapshot = await db.collection("workshop_registration")
            .orderBy("created_date", "desc")
            .startAfter(lastVisible)
            .limit(20)
            .get();
        const lastVisibleDoc = snapshot.docs[snapshot.docs.length - 1];
        const newData = snapshot.docs.map((doc) => ({
            id: doc.id,
            data: doc.data(),
        }));

        setData((prevData) => [...prevData, ...newData]);
        setLastVisible(lastVisibleDoc);
        setCsvdata((prevData) => [...prevData, ...newData.map((item) => ({
            ...item.data,
            created_date: moment(item.data.created_date.toDate().toISOString()).format("MM/DD/YY, h:mm a"),
        }))]);
        setHasMore(snapshot.docs.length === 20);
        setLoading(false);
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const filteredData = data.filter((item) => {
        const full_name = item.data.full_name ? item.data.full_name.toLowerCase() : '';
        const phone_no = item.data.phone_no ? item.data.phone_no.toLowerCase() : '';
        const workshop_event_name = item.data.workshop_event_name ? item.data.workshop_event_name.toLowerCase() : '';
        const query = searchQuery.toLowerCase();

        return (
            full_name.includes(query) ||
            phone_no.includes(query) ||
            workshop_event_name.includes(query)
        );
    });

    return (
        <>
            <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
                <div className="p-2" style={{ paddingBottom: "5px", display: "flex", justifyContent: "space-between" }}>
                    <Link to="/admin_panel"><img src="/images/menubutton.png" className="menu-toggle" alt="Responsive image" style={{ height: "2rem" }} /></Link>
                    <CSVLink data={csvdata} headers={headers}>
                        <span className="btn bg-gradient-secondary btn-sm mb-0 mr-2" style={{ color: "white" }} >&nbsp; Download Excel</span>
                    </CSVLink>
                </div>
                <div className="row">
                    <div className="p-2">
                        <div style={{ textAlign: "end", paddingBottom: "5px" }}>
                        </div>
                        <div className="card">
                            <div className="card-header pb-0 px-3">
                                <h6 className="mb-2">Workshop Registration || {data.length}</h6>
                            </div>
                            <input
                                type="text"
                                className="form-control form-control-sm"
                                placeholder="Search by Name, Phone, Workshop"
                                value={searchQuery}
                                onChange={handleSearchChange}
                            />
                            <div className="">
                                <div className="card">
                                    <div className="table-responsive fix-width scroll-inner">
                                        <table className="table table-bordered table-hover">
                                            <thead className="thead-dark" style={{ position: "sticky", top: "0" }}>
                                                <tr style={{ textAlign: "left" }}>
                                                    <th className="col ps-2"></th>
                                                    <th className="col ps-2">Name</th>
                                                    <th className="col">Phone No</th>
                                                    <th className="col ps-2">Email</th>
                                                    <th className="col ps-2">Location</th>
                                                    <th className="col ps-2">Created Date</th>
                                                    <th className="col ps-2">Workshop/Event Name</th>
                                                    <th className="col ps-2">Payment</th>
                                                    <th className="col ps-2"></th>
                                                </tr>
                                            </thead>
                                            <tbody style={{ textAlign: "left" }}>
                                                {filteredData.map(({ id, data }, index) => (
                                                    <tr key={id}>
                                                        <td></td>
                                                        <td><p className="text-xs font-weight-bold mb-0">{data.full_name}</p></td>
                                                        <td>
                                                            <div className="d-flex px-2 py-1">
                                                                <div className="d-flex flex-column justify-content-center">
                                                                    <h6 className="mb-0 text-xs">{data.phone}</h6>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td style={{ textWrap: "balance" }}>
                                                            <div className="d-flex px-2 py-1">
                                                                <div className="d-flex flex-column justify-content-center" style={{ width: "7rem", marginRight: "13px" }}>
                                                                    <h6 className="mb-0 text-xs">{data.email}</h6>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="d-flex px-2 py-1">
                                                                <div className="d-flex flex-column justify-content-center">
                                                                    <h6 className="mb-0 text-xs">{data.workshop_location}</h6>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="d-flex px-2 py-1">
                                                                <div className="d-flex flex-column justify-content-center">
                                                                    <h6 className="mb-0 text-xs">{moment(data.created_date.toDate().toISOString()).format("MM/DD/YY, h:mm a")}</h6>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="d-flex px-2 py-1">
                                                                <div className="d-flex flex-column justify-content-center">
                                                                    <h6 className="mb-0 text-xs">{data.workshop_event_name}</h6>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="d-flex px-2 py-1">
                                                                <div className="d-flex flex-column justify-content-center">
                                                                    <h6 className="mb-0 text-xs">{data.payment}</h6>
                                                                    <p>{data.payment_id}</p>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td></td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                        {hasMore && (
                                            <div className="" style={{ paddingTop: "2rem" }}>
                                                <button onClick={loadMoreData} className="btn btn-dark">Load More</button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
}

export default WorkshopRegistration;



// // import React, { useState, useEffect } from 'react';
// // import { useNavigate, Link, useParams } from 'react-router-dom';
// // import db from "./Firebase";
// // import { arrayUnion } from "firebase/firestore";
// // import { storage } from './Firebase';
// // import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
// // import Moment from 'moment';
// // import { extendMoment } from 'moment-range';
// // import ViewAllRequests from './ViewAllRequests';
// // import { CSVLink } from "react-csv";

// // function WorkshopRegistration() {
// //     const navigate = useNavigate();
// //     const moment = extendMoment(Moment);
// //     const [data, setData] = useState([]);
// //     const [uid, setUid] = useState('');
// //     const [name, setName] = useState('');
// //     const [phone, setPhone] = useState('');
// //     const [uuid, setUuid] = useState('');
// //     const [date, setDate] = useState('');
// //     const [location, setLocation] = useState('');
// //     const [eventNames, setEventNames] = useState([]); // State for storing unique event names
// //     const [selectedEvent, setSelectedEvent] = useState(''); // State for selected event name
// //     const [csvdata, setCsvdata] = useState([]);

// //     const headers = [
// //         { label: "Full Name", key: "full_name" },
// //         { label: "Phone Number", key: "phone" },
// //         { label: "Email", key: "email" },
// //         { label: "Workshop Location", key: "workshop_location" },
// //         { label: "Workshop Event Name", key: "workshop_event_name" },
// //         { label: "Created Date", key: "created_date", dataFormat: "MM/DD/YY, h:mm a" },
// //     ];

// //     useEffect(() => {
// //         const storedUsername = localStorage.getItem("email");
// //         const storedPassword = localStorage.getItem("password");
// //         const storedRemember = localStorage.getItem("remember");

// //         if (storedRemember === "false" || !storedUsername || !storedPassword) {
// //             navigate('/signin');
// //         }
// //     }, []);

// //     useEffect(() => {
// //         db.collection("workshop_registration").orderBy("created_date", "desc").onSnapshot((snapshot) => {

// //             setData(
// //                 snapshot.docs.map((doc) => ({
// //                     id: doc.id,
// //                     data: doc.data(),
// //                 }))
// //             );

// //             setCsvdata(
// //                 snapshot.docs.map((doc) => {
// //                     const data = doc.data();
// //                     return {
// //                         ...data,
// //                         created_date: moment(data.created_date.toDate().toISOString()).format("MM/DD/YY, h:mm a"),
// //                     };
// //                 })
// //             );

// //             // Extract unique event names from the data
// //             const uniqueEventNames = [...new Set(snapshot.docs.map((doc) => doc.data().workshop_event_name))];
// //             setEventNames(uniqueEventNames);
// //         });

// //     }, []);

// //     const notifyApp = (msg) => {
// //         // Call the user's CollectionReference to add a new user
// //         db.collection("app_notification").add({ 'message': msg, 'link': "noti", 'status': "0", "uid": uid, 'created_date': new Date() }).then(() => {
// //             console.log("notified");
// //         })
// //     }

// //     const initialFormStateFeedback = { status: '' }
// //     const [submit, setSubmit] = useState(initialFormStateFeedback)

// //     const sendWhatsapp = () => {
// //         // ... (your sendWhatsapp function)
// //     };

// //     const handleChange2 = async (e, msg) => {
// //         // ... (your handleChange2 function)
// //     }

// //     const collect = (id, name, mainID, date, location, phone) => {
// //         // ... (your collect function)
// //     }

// //     return (
// //         <>
// //             {/* <ViewAllRequests /> */}
// //             <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
// //                 <div className="p-2" style={{ textAlign: "start", paddingBottom: "5px" }}>
// //                     {/* <span className="btn bg-gradient-secondary btn-sm mb-0 mr-2" style={{ color: "white" }} >&nbsp; Download Excel</span> */}
// //                     <Link to="/admin_panel"><img src="/images/menubutton.png" className="menu-toggle" alt="Responsive image" style={{ height: "2rem" }} /></Link>
// //                 </div>
// //                 <div className="row">
// //                     <div className="mt-4 p-4">
// //                         <div style={{ textAlign: "end", paddingBottom: "5px" }}>
// //                             {/* <Link to="/admin_panel" className="btn bg-gradient-primary btn-sm mb-0 mr-2" style={{ color: "white" }} >&nbsp; Go Back</Link> */}
// //                             <select className="form-select mb-2" value={selectedEvent} onChange={(e) => setSelectedEvent(e.target.value)}>
// //                                 <option value="">All Events</option>
// //                                 {eventNames.map((eventName, index) => (
// //                                     <option key={index} value={eventName}>
// //                                         {eventName}
// //                                     </option>
// //                                 ))}
// //                             </select>
// //                             <CSVLink data={csvdata} headers={headers}>
// //                                 <span className="btn bg-gradient-secondary btn-sm mb-0 mr-2" style={{ color: "white" }} >&nbsp; Download Excel</span>
// //                             </CSVLink>
// //                         </div>
// //                         <div className="card">
// //                             <div className="card-header pb-0 px-3">
// //                                 <h6 className="mb-2">Workshop Registration</h6>
// //                             </div>
// //                             <div className="">
// //                                 <div className="card">
// //                                     <div className="table-responsive">
// //                                         <table className="table align-items-center mb-0">
// //                                             <thead>
// //                                                 <tr style={{ textAlign: "left" }}>
// //                                                     <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Name</th>
// //                                                     <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Phone No</th>
// //                                                     <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Email</th>
// //                                                     <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Location</th>
// //                                                     <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Created Date</th>
// //                                                     <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Workshop/Event Name</th>
// //                                                     <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Action</th>
// //                                                 </tr>
// //                                             </thead>
// //                                             <tbody style={{ textAlign: "left" }}>
// //                                                 {data
// //                                                     .filter((item) => !selectedEvent || item.data.workshop_event_name === selectedEvent) // Filter by selected event name
// //                                                     .map(({ id, data }, index) => (
// //                                                         <tr key={id}>
// //                                                             <td>
// //                                                                 <p className="text-xs font-weight-bold mb-0">{data.full_name}</p>
// //                                                             </td>
// //                                                             <td>
// //                                                                 <div className="d-flex px-2 py-1">
// //                                                                     <div className="d-flex flex-column justify-content-center">
// //                                                                         <h6 className="mb-0 text-xs">{data.phone}</h6>
// //                                                                     </div>
// //                                                                 </div>
// //                                                             </td>
// //                                                             <td>
// //                                                                 <div className="d-flex px-2 py-1">
// //                                                                     <div className="d-flex flex-column justify-content-center">
// //                                                                         <h6 className="mb-0 text-xs">{data.email}</h6>
// //                                                                     </div>
// //                                                                 </div>
// //                                                             </td>
// //                                                             <td>
// //                                                                 <div className="d-flex px-2 py-1">
// //                                                                     <div className="d-flex flex-column justify-content-center">
// //                                                                         <h6 className="mb-0 text-xs">{data.workshop_location}</h6>
// //                                                                     </div>
// //                                                                 </div>
// //                                                             </td>
// //                                                             <td>
// //                                                                 <div className="d-flex px-2 py-1">
// //                                                                     <div className="d-flex flex-column justify-content-center">
// //                                                                         <h6 className="mb-0 text-xs">{moment(data.created_date.toDate().toISOString()).format("MM/DD/YY, h:mm a")}</h6>
// //                                                                     </div>
// //                                                                 </div>
// //                                                             </td>
// //                                                             <td>
// //                                                                 <div className="d-flex px-2 py-1">
// //                                                                     <div className="d-flex flex-column justify-content-center">
// //                                                                         <h6 className="mb-0 text-xs">{data.workshop_event_name}</h6>
// //                                                                     </div>
// //                                                                 </div>
// //                                                             </td>
// //                                                             <td>
// //                                                                 <select className="form-control" id={index} name="status" onClick={() => collect(id, data.full_name, data.uid, data.workshop_Date, data.workshop_location, data.phone)} onChange={handleChange2} value={data.status} style={{ padding: "7px", width: "9rem", backgroundColor: data.status == 1 ? "#28a745" : "transparent" }} disabled={data.status == 1 ? true : false} >
// //                                                                     <option selected={data.status === 0 ? true : false} value="0">----</option>
// //                                                                     <option selected={data.status === 1 ? true : false} value="1">Send Reminder</option>
// //                                                                 </select>
// //                                                             </td>
// //                                                         </tr>
// //                                                     ))}
// //                                             </tbody>
// //                                         </table>
// //                                     </div>
// //                                 </div>
// //                             </div>
// //                         </div>
// //                     </div>
// //                 </div>
// //             </main>
// //         </>
// //     )
// // }

// // export default WorkshopRegistration;


// import React, { useState, useEffect } from 'react';
// import { useNavigate, Link, useParams } from 'react-router-dom';
// import db from "./Firebase";
// import { arrayUnion } from "firebase/firestore";
// import { storage } from './Firebase';
// import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
// import Moment from 'moment';
// import { extendMoment } from 'moment-range';
// import ViewAllRequests from './ViewAllRequests';
// import { CSVLink } from "react-csv";

// function WorkshopRegistration() {
//     const navigate = useNavigate();
//     const moment = extendMoment(Moment);
//     const [data, setData] = useState([]);
//     const [uid, setUid] = useState('');
//     const [name, setName] = useState('');
//     const [phone, setPhone] = useState('');
//     const [uuid, setUuid] = useState('');
//     const [date, setDate] = useState('');
//     const [location, setLocation] = useState('');
//     const [wname, setWname] = useState('');
//     const [csvdata, setCsvdata] = useState([]);
//     const [searchQuery, setSearchQuery] = useState("");

//     const headers = [
//         { label: "Full Name", key: "full_name" },
//         { label: "Phone Number", key: "phone" },
//         { label: "Email", key: "email" },
//         { label: "Workshop Location", key: "workshop_location" },
//         { label: "Workshop Event Name", key: "workshop_event_name" },
//         { label: "Created Date", key: "created_date", dataFormat: "MM/DD/YY, h:mm a" },

//     ];

//     useEffect(() => {
//         const storedUsername = localStorage.getItem("email");
//         const storedPassword = localStorage.getItem("password");
//         const storedRemember = localStorage.getItem("remember");

//         if (storedRemember === "false" || !storedUsername || !storedPassword) {
//             navigate('/signin');
//         }
//     }, []);

//     useEffect(() => {
//         db.collection("workshop_registration").orderBy("created_date", "desc").onSnapshot((snapshot) => {

//             setData(
//                 snapshot.docs.map((doc) => ({
//                     id: doc.id,
//                     data: doc.data(),
//                 }))
//             );

//             setCsvdata(
//                 snapshot.docs.map((doc) => {
//                     const data = doc.data();
//                     return {
//                         ...data,
//                         created_date: moment(data.created_date.toDate().toISOString()).format("MM/DD/YY, h:mm a"),
//                     };
//                 })
//             );
//         });

//     }, []);

//     const handleSearchChange = (event) => {
//         setSearchQuery(event.target.value);
//     };

//     // Function to filter data based on the search query
//     const filteredData = data.filter((item) => {
//         const full_name = item.data.full_name ? item.data.full_name.toLowerCase() : '';
//         const phone_no = item.data.phone_no ? item.data.phone_no.toLowerCase() : '';
//         const workshop_event_name = item.data.workshop_event_name ? item.data.workshop_event_name.toLowerCase() : '';
//         const query = searchQuery.toLowerCase();

//         return (
//             full_name.includes(query) ||
//             phone_no.includes(query) ||
//             workshop_event_name.includes(query)
//         );
//     });


//     const notifyApp = (msg) => {
//         // Call the user's CollectionReference to add a new user
//         db.collection("app_notification").add({ 'message': msg, 'link': "noti", 'status': "0", "uid": uid, 'created_date': new Date() }).then(() => {
//             console.log("notified");
//         })
//     }

//     const initialFormStateFeedback = { status: '' }
//     const [submit, setSubmit] = useState(initialFormStateFeedback)


//     const sendWhatsapp = () => {
//         var myHeaders = new Headers();
//         myHeaders.append("Authorization", "Bearer EABMKlZCrATXIBAPOZBZBPqsdfot34fq0FTipZBQf3RiXZAapyCOqMay09HfhSxoB8bd5ZA7RUnLWhd0JkXtACJQ682THiDEMhQhyPOkeui8Cb8P72WPDhihUZBCkHtPLSgsmuZA6iRY6VunDDIjPA5mATQQITVXOECHqkZB5E2pzWSC2AaUp0wZCWR");
//         myHeaders.append("Content-Type", "application/json");

//         var raw = JSON.stringify(
//             {
//                 "messaging_product": "whatsapp",
//                 "recipient_type": "individual",
//                 "to": `91${phone}`,
//                 "type": "template",
//                 "template": {
//                     "name": "reminder_for_online_workshop",
//                     "language": {
//                         "code": "en_US",
//                         "policy": "deterministic"
//                     },
//                     "components": [
//                         {
//                             "type": "header",
//                             "parameters": [
//                                 {
//                                     "type": "image",
//                                     "image": { "link": "https://firebasestorage.googleapis.com/v0/b/youvati.appspot.com/o/files%2F1%20(4).jpg?alt=media&token=f2dc4c80-deac-41f3-b7f9-383381736f94" }
//                                 }
//                             ]
//                         },
//                         {
//                             "type": "body",
//                             "parameters": [
//                                 { "type": "text", "text": "Reminder Reminder Reminder" },
//                                 {
//                                     "type": "text", "text": "Whatsapp Business: व्यापार की नई शुरुआत aaj 3 baje shuru hoga. Kripya session shuru hone se 5 minute phele join karein!"
//                                 },
//                                 { "type": "text", "text": "22 September 2023" },
//                                 { "type": "text", "text": "3:00 PM to 4:00 PM" },
//                                 { "type": "text", "text": "https://us06web.zoom.us/j/85165936544?pwd=bszvWNwfvne12YBvbbPmNF9vCSvcFh.1" },
//                                 { "type": "text", "text": "85165936544" },
//                                 { "type": "text", "text": "1234" },
//                                 { "type": "text", "text": "Whatsapp Business: व्यापार की नई शुरुआत" },
//                             ]
//                         }
//                     ]
//                 }
//             }
//         );

//         var requestOptions = {
//             method: 'POST',
//             headers: myHeaders,
//             body: raw,
//             redirect: 'follow'
//         };

//         fetch("https://graph.facebook.com/v14.0/104489945783566/messages", requestOptions)
//             .then(response => response.text())
//             .then(result => {
//                 console.log(result)
//                 // reset();
//             })
//             .catch(error => {
//                 console.log('error', error)
//             });

//     };



//     const handleChange2 = async (e, msg) => {
//         const { id, value } = e.target
//         setSubmit({ ...submit, [id]: value })
//         if (e.target.value == "1") {
//             // db.collection("business_card_request").doc(uid).update({ 'status': "1", 'updated_date': new Date() }).then(() => {
//             //     console.log("user added");
//             // })
//             sendWhatsapp()

//             // notifyApp(msg = `Hi ${name} This is a reminder for the upcoming workshop on (${date}, ${location}). Kindly make sure you are there 5 minutes before the workshop starts`);
//         } else {
//             // db.collection("business_card_request").doc(uid).update({ 'status': "0", 'updated_date': new Date() }).then(() => {
//             //     console.log("user added");
//             // })
//         }
//     }

//     const collect = (id, name, mainID, date, location, phone, wname) => {
//         setUid(id)
//         setName(name)
//         setUuid(mainID)
//         setDate(date)
//         setPhone(phone)
//         setLocation(location)
//         setWname(wname)
//         console.log(id)
//         console.log(name)
//         console.log(mainID)
//         console.log(date)
//         console.log(location)
//     }

//     return (
//         <>
//             {/* <ViewAllRequests /> */}
//             <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
//                 <div class="p-2" style={{ paddingBottom: "5px", display: "flex", justifyContent: "space-between" }}>
//                     {/* <span class="btn bg-gradient-secondary btn-sm mb-0 mr-2" style={{ color: "white" }} >&nbsp; Download Excel</span> */}
//                     <Link to="/admin_panel"><img src="/images/menubutton.png" class="menu-toggle" alt="Responsive image" style={{ height: "2rem" }} /></Link>
//                     <CSVLink data={csvdata} headers={headers}>
//                         <span class="btn bg-gradient-secondary btn-sm mb-0 mr-2" style={{ color: "white" }} >&nbsp; Download Excel</span>
//                     </CSVLink>
//                 </div>
//                 <div class="row">


//                     <div class="p-2">
//                         <div style={{ textAlign: "end", paddingBottom: "5px" }}>
//                             {/* <Link to="/admin_panel" class="btn bg-gradient-primary btn-sm mb-0 mr-2" style={{ color: "white" }} >&nbsp; Go Back</Link> */}

//                         </div>
//                         <div class="card">
//                             <div class="card-header pb-0 px-3">
//                                 <h6 class="mb-2">Workshop Registration || {data.length}</h6>
//                             </div>
//                             <input
//                                 type="text"
//                                 className="form-control form-control-sm"
//                                 placeholder="Search by Name, Phone, Workshop"
//                                 value={searchQuery}
//                                 onChange={handleSearchChange}
//                             />
//                             <div class="">
//                                 <div class="card">
//                                     <div class="table-responsive fix-width scroll-inner">
//                                         <table class="table table-bordered table-hover">
//                                             <thead class="thead-dark" style={{ position: "sticky", top: "0" }}>
//                                                 <tr style={{ textAlign: "left" }}>
//                                                     <th class="col ps-2"></th>
//                                                     <th class="col ps-2">Name</th>
//                                                     <th class="col">Phone No</th>
//                                                     <th class="col ps-2">Email</th>
//                                                     <th class="col ps-2">Location</th>
//                                                     <th class="col ps-2">Created Date</th>
//                                                     <th class="col ps-2">Workshop/Event Name</th>
//                                                     <th class="col ps-2">Payment</th>
//                                                     <th class="col ps-2"></th>
//                                                     {/* <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Action</th> */}
//                                                 </tr>
//                                             </thead>
//                                             <tbody style={{ textAlign: "left" }}>
//                                                 {filteredData?.map(({ id, data }, index) => (
//                                                     <>
//                                                         <tr>
//                                                             <td></td>
//                                                             <td>
//                                                                 <p class="text-xs font-weight-bold mb-0">{data.full_name}</p>
//                                                                 {/* <p class="text-xs text-secondary mb-0">{moment(data.created_date.toDate().toISOString()).format("MM/DD/YY, h:mm a")}</p> */}
//                                                             </td>
//                                                             <td>
//                                                                 <div class="d-flex px-2 py-1">
//                                                                     <div class="d-flex flex-column justify-content-center">
//                                                                         <h6 class="mb-0 text-xs">{data.phone}</h6>
//                                                                     </div>
//                                                                 </div>
//                                                             </td>

//                                                             <td style={{ textWrap: "balance" }}>
//                                                                 <div class="d-flex px-2 py-1">
//                                                                     <div class="d-flex flex-column justify-content-center" style={{ width: "7rem", marginRight: "13px" }}>
//                                                                         <h6 class="mb-0 text-xs">{data.email}</h6>
//                                                                     </div>
//                                                                 </div>
//                                                             </td>

//                                                             <td>
//                                                                 <div class="d-flex px-2 py-1">
//                                                                     <div class="d-flex flex-column justify-content-center">
//                                                                         <h6 class="mb-0 text-xs">{data.workshop_location}</h6>
//                                                                     </div>
//                                                                 </div>
//                                                             </td>

//                                                             <td>
//                                                                 <div class="d-flex px-2 py-1">
//                                                                     <div class="d-flex flex-column justify-content-center">
//                                                                         <h6 class="mb-0 text-xs">{moment(data.created_date.toDate().toISOString()).format("MM/DD/YY, h:mm a")}</h6>
//                                                                     </div>
//                                                                 </div>
//                                                             </td>

//                                                             <td>
//                                                                 <div class="d-flex px-2 py-1">
//                                                                     <div class="d-flex flex-column justify-content-center">
//                                                                         <h6 class="mb-0 text-xs">{data.workshop_event_name}</h6>
//                                                                     </div>
//                                                                 </div>
//                                                             </td>
//                                                             <td>
//                                                                 <div class="d-flex px-2 py-1">
//                                                                     <div class="d-flex flex-column justify-content-center">
//                                                                         <h6 class="mb-0 text-xs">{data.payment}</h6>
//                                                                         <p>{data.payment_id}</p>
//                                                                     </div>
//                                                                 </div>
//                                                             </td>
//                                                             <td></td>
//                                                             {/* <td>
//                                                                 <select class="form-control" id={index} name="status" onClick={() => collect(id, data.full_name, data.uid, data.workshop_Date, data.workshop_location, data.phone, data.workshop_event_name)} onChange={handleChange2} value={data.status} style={{ padding: "7px", width: "9rem", backgroundColor: data.status == 1 ? "#28a745" : "transparent" }} disabled={data.status == 1 ? true : false} >
//                                                                     <option selected={data.status === 0 ? true : false} value="0">----</option>
//                                                                     <option selected={data.status === 1 ? true : false} value="1">Send Reminder</option>
//                                                                 </select>
//                                                             </td> */}
//                                                         </tr>

//                                                     </>
//                                                 ))}


//                                             </tbody>
//                                         </table>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>

//                 </div>

//             </main>


//         </>
//     )
// }

// export default WorkshopRegistration;