
import React, { useState, useEffect } from "react";
import { useNavigate, Link, useParams } from "react-router-dom"
import db from "./Firebase";
import axios from "axios";
// import { firebase, auth } from './FirebaseLogin';

function ImageGallery() {

    let params = useParams();
    const navigate = useNavigate();
    const [personalData, setPersonalData] = useState({});
    useEffect(() => {
        db.collection("portfolio_details").where('username', '==', params.id.toLowerCase()).onSnapshot((snapshot) => {
            snapshot.docs.map(e => setPersonalData(e.data()))
            if (snapshot.docs.length == 0) {
                navigate('/error');
            } else {
                // alert("exists")
                setIsLoading(false);
            }
        });
    }, []);
    const [isLoading, setIsLoading] = useState(true)
    return (
        <>
            <section>
                <div class="navi" >
                    <input type="checkbox" id="nav-check" />
                    <div class="nav-header">
                        <div class="nav-title">
                            <Link to={`/${params.id.toLowerCase()}`} > <img src="/images/youvati_logo.png" alt="" style={{ height: "27px", marginLeft: "2rem" }} /></Link>
                        </div>
                    </div>


                </div>
            </section>
            {isLoading ? <div>
                <img src="images/loader.gif" alt="" class="" style={{ display: "block", marginLeft: "auto", marginRight: "auto", width: "40%" }} />
            </div> : <div>
                <div class="row"> <h1 style={{ display: "flex", justifyContent: "center", fontSize: "28px" }}>Portfolio Image Gallery</h1></div>
                <hr />
                <div id="gallery" class="container-fluid">
                    {personalData.services_images.map((e) => (
                        <a href={e} target="_blank"> <img src={e} class="img-responsive" /></a>
                        // <div class="col-lg-3 col-md-4 col-xs-6 thumb">
                        //     {/* <a href="https://picsum.photos/940/650?random=1"> */}
                        //     <figure><img class="img-fluid img-thumbnail" src={e} alt="Random Image" /></figure>
                        //     {/* </a> */}
                        // </div>
                    ))
                    }
                    {/* <img src="https://source.unsplash.com/1920x1080?female,portrait" class="img-responsive" /> */}
                    {/* <a target="_blank" href="https://source.unsplash.com/1600x1200?female,portrait"> <img src="https://source.unsplash.com/1600x1200?female,portrait" class="img-responsive" /></a>
                    <img src="https://source.unsplash.com/1024x768?female,portrait" class="img-responsive" />
                    <img src="https://source.unsplash.com/1366x768?female,portrait" class="img-responsive" />
                    <video class="vid" controls>
                        <source src="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4" type="video/mp4">
                        </source>
                    </video>
                    <img src="https://source.unsplash.com/1920x1080?female,portrait" class="img-responsive" />
                    <img src="https://source.unsplash.com/640x360?female,portrait" class="img-responsive" />
                    <img src="https://source.unsplash.com/320x640?female,portrait" class="img-responsive" />
                    <img src="https://source.unsplash.com/1200x1600?female,portrait" class="card img-responsive" />
                    <img src="https://source.unsplash.com/800x600?female,portrait" class="img-responsive" />
                    <img src="https://source.unsplash.com/600x800?female,portrait" class="img-responsive" />
                    <img src="https://source.unsplash.com/400x600?female,portrait" class="img-responsive" />
                    <img src="https://source.unsplash.com/600x400?female,portrait" class="img-responsive" />
                    <img src="https://source.unsplash.com/1100x1600?female,portrait" class="img-responsive" />
                    <img src="https://source.unsplash.com/1600x1100?female,portrait" class="img-responsive" />
                    <img src="https://source.unsplash.com/992x768?female,portrait" class="img-responsive" />
                    <img src="https://source.unsplash.com/768x992?female,portrait" class="img-responsive" />
                    <img src="https://source.unsplash.com/768x992?female,portrait" class="img-responsive" />
                    <img src="https://source.unsplash.com/768x992?female,portrait" class="img-responsive" />
                    <img src="https://source.unsplash.com/768x992?female,portrait" class="img-responsive" />
                    <img src="https://source.unsplash.com/768x992?female,portrait" class="img-responsive" />
                    <img src="https://source.unsplash.com/768x992?female,portrait" class="img-responsive" />
                    <img src="https://source.unsplash.com/768x992?female,portrait" class="img-responsive" />
                    <img src="https://source.unsplash.com/768x992?female,portrait" class="img-responsive" />
                    <img src="https://source.unsplash.com/768x992?female,portrait" class="img-responsive" />
                    <img src="https://source.unsplash.com/768x992?female,portrait" class="img-responsive" />
                    <img src="https://source.unsplash.com/768x992?female,portrait" class="img-responsive" />
                    <img src="https://source.unsplash.com/768x992?female,portrait" class="img-responsive" />
                    <img src="https://source.unsplash.com/768x992?female,portrait" class="img-responsive" />
                    <img src="https://source.unsplash.com/768x992?female,portrait" class="img-responsive" />
                    <img src="https://source.unsplash.com/768x992?female,portrait" class="img-responsive" />
                    <img src="https://source.unsplash.com/768x992?female,portrait" class="img-responsive" />
                    <img src="https://source.unsplash.com/768x992?female,portrait" class="img-responsive" />
                    <img src="https://source.unsplash.com/768x992?female,portrait" class="img-responsive" />
                    <img src="https://source.unsplash.com/768x992?female,portrait" class="img-responsive" />
                    <img src="https://source.unsplash.com/768x992?female,portrait" class="img-responsive" />
                    <img src="https://source.unsplash.com/768x992?female,portrait" class="img-responsive" />
                    <img src="https://source.unsplash.com/768x992?female,portrait" class="img-responsive" />
                    <img src="https://source.unsplash.com/768x992?female,portrait" class="img-responsive" />
                    <img src="https://source.unsplash.com/768x992?female,portrait" class="img-responsive" />
                    <img src="https://source.unsplash.com/768x992?female,portrait" class="img-responsive" />
                    <img src="https://source.unsplash.com/768x992?female,portrait" class="img-responsive" />
                    <img src="https://source.unsplash.com/768x992?female,portrait" class="img-responsive" />
                    <img src="https://source.unsplash.com/768x992?female,portrait" class="img-responsive" />
                    <img src="https://source.unsplash.com/768x992?female,portrait" class="img-responsive" />
                    <img src="https://source.unsplash.com/768x992?female,portrait" class="img-responsive" />
                    <img src="https://source.unsplash.com/768x992?female,portrait" class="img-responsive" />
                    <img src="https://source.unsplash.com/768x992?female,portrait" class="img-responsive" />
                    <img src="https://source.unsplash.com/768x992?female,portrait" class="img-responsive" />
                    <img src="https://source.unsplash.com/768x992?female,portrait" class="img-responsive" />
                    <img src="https://source.unsplash.com/768x992?female,portrait" class="img-responsive" />
                    <img src="https://source.unsplash.com/768x992?female,portrait" class="img-responsive" />
                    <img src="https://source.unsplash.com/768x992?female,portrait" class="img-responsive" />
                    <img src="https://source.unsplash.com/768x992?female,portrait" class="img-responsive" />
                    <img src="https://source.unsplash.com/768x992?female,portrait" class="img-responsive" />
                    <img src="https://source.unsplash.com/768x992?female,portrait" class="img-responsive" />
                    <img src="https://source.unsplash.com/768x992?female,portrait" class="img-responsive" />
                    <img src="https://source.unsplash.com/768x992?female,portrait" class="img-responsive" />
                    <img src="https://source.unsplash.com/768x992?female,portrait" class="img-responsive" />
                    <img src="https://source.unsplash.com/768x992?female,portrait" class="img-responsive" />
                    <img src="https://source.unsplash.com/768x992?female,portrait" class="img-responsive" />
                    <img src="https://source.unsplash.com/768x992?female,portrait" class="img-responsive" />
                    <img src="https://source.unsplash.com/768x992?female,portrait" class="img-responsive" />
                    <img src="https://source.unsplash.com/768x992?female,portrait" class="img-responsive" />
                    <img src="https://source.unsplash.com/768x992?female,portrait" class="img-responsive" />
                    <img src="https://source.unsplash.com/768x992?female,portrait" class="img-responsive" />
                    <img src="https://source.unsplash.com/768x992?female,portrait" class="img-responsive" /> */}

                </div>

                <div id="myModal" class="modal fade" role="dialog">
                    <div class="modal-dialog">

                        <div class="modal-content">
                            <div class="modal-body">
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            }

        </>
    );
}

export default ImageGallery;




// import React, { useState, useEffect } from "react";
// import { useNavigate, Link, useParams } from "react-router-dom"
// import db from "./Firebase";
// import axios from "axios";
// // import { firebase, auth } from './FirebaseLogin';

// function ImageGallery() {

//     let params = useParams();
//     const navigate = useNavigate();
//     const [personalData, setPersonalData] = useState({});
//     useEffect(() => {
//         db.collection("portfolio_details").where('username', '==', params.id.toLowerCase()).onSnapshot((snapshot) => {
//             snapshot.docs.map(e => setPersonalData(e.data()))
//             if (snapshot.docs.length == 0) {
//                 navigate('/error');
//             } else {
//                 // alert("exists")
//                 setIsLoading(false);
//             }
//         });
//     }, []);
//     const [isLoading, setIsLoading] = useState(true)
//     return (
//         <>
//             <section>
//                 <div class="navi" >
//                     <input type="checkbox" id="nav-check" />
//                     <div class="nav-header">
//                         <div class="nav-title">
//                             <Link to={`/${params.id.toLowerCase()}`} > <img src="/images/youvati_logo.png" alt="" style={{ height: "27px", marginLeft: "2rem" }} /></Link>
//                         </div>
//                     </div>


//                 </div>
//             </section>
//             {isLoading ? <div>
//                 <img src="images/loader.gif" alt="Popup Image" class="" style={{ display: "block", marginLeft: "auto", marginRight: "auto", width: "40%" }} />
//             </div> : <section class="container">
//                 <h1 class="my-4 text-center">PORTFOLIO IMAGES</h1>
//                 <div class="row gallery">
//                     {personalData.services_images.map((e) => (
//                         <div class="col-lg-3 col-md-4 col-xs-6 thumb">
//                             {/* <a href="https://picsum.photos/940/650?random=1"> */}
//                             <figure><img class="img-fluid img-thumbnail" src={e} alt="Random Image" /></figure>
//                             {/* </a> */}
//                         </div>
//                     ))
//                     }

//                     {/*
//                     <div class="col-lg-3 col-md-4 col-xs-6 thumb">
//                         <a href="https://picsum.photos/940/650?random=2">
//                         <figure><img class="img-fluid img-thumbnail" src="https://picsum.photos/940/650?random=2" alt="Random Image" /></figure>
//                         </a>
//                     </div>

//                     <div class="col-lg-3 col-md-4 col-xs-6 thumb">
//                         <a href="https://picsum.photos/940/650?random=3">
//                         <figure><img class="img-fluid img-thumbnail" src="https://picsum.photos/940/650?random=3" alt="Random Image" /></figure>
//                         </a>
//                     </div>

//                     <div class="col-lg-3 col-md-4 col-xs-6 thumb">
//                         <a href="https://picsum.photos/940/650?random=4">
//                         <figure><img class="img-fluid img-thumbnail" src="https://picsum.photos/940/650?random=4" alt="Random Image" /></figure>
//                         </a>
//                     </div>

//                     <div class="col-lg-3 col-md-4 col-xs-6 thumb">
//                         <a href="https://picsum.photos/940/650?random=5">
//                         <figure><img class="img-fluid img-thumbnail" src="https://picsum.photos/940/650?random=5" alt="Random Image" /></figure>
//                         </a>
//                     </div>

//                     <div class="col-lg-3 col-md-4 col-xs-6 thumb">
//                         <a href="https://picsum.photos/940/650?random=6">
//                         <figure><img class="img-fluid img-thumbnail" src="https://picsum.photos/940/650?random=6" alt="Random Image" /></figure>
//                         </a>
//                     </div>

//                     <div class="col-lg-3 col-md-4 col-xs-6 thumb">
//                         <a href="https://picsum.photos/940/650?random=6">
//                         <figure><img class="img-fluid img-thumbnail" src="https://picsum.photos/940/650?random=6" alt="Random Image" /></figure>
//                         </a>
//                     </div>

//                     <div class="col-lg-3 col-md-4 col-xs-6 thumb">
//                         <a href="https://picsum.photos/940/650?random=7">
//                         <figure><img class="img-fluid img-thumbnail" src="https://picsum.photos/940/650?random=7" alt="Random Image" /></figure>
//                         </a>
//                     </div>

//                     <div class="col-lg-3 col-md-4 col-xs-6 thumb">
//                         <a href="https://picsum.photos/940/650?random=8">
//                         <figure><img class="img-fluid img-thumbnail" src="https://picsum.photos/940/650?random=8" alt="Random Image" /></figure>
//                         </a>
//                     </div> */}
//                 </div>
//             </section>
//             }

//         </>
//     );
// }

// export default ImageGallery;

