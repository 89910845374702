import React, { useState, useEffect } from "react";
import { useNavigate, Link, useParams } from 'react-router-dom';
import Swal from 'sweetalert2'
import db from "./Firebase";
import { storage } from './Firebase';
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import Moment from 'moment';
import { extendMoment } from 'moment-range';

function WoekshopGlimpses() {

    const navigate = useNavigate();
    const moment = extendMoment(Moment);
    const initialFormStateFeedback = { id: '', image_url: '', name: '', created_by: localStorage.getItem("email"), created_date: new Date() };
    const [submit, setSubmit] = useState(initialFormStateFeedback)

    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [show, setshow] = useState(true);
    const [addTrends, setAddTrends] = useState(false);
    const [progresspercent, setProgresspercent] = useState(0);

    const [editID, setEditID] = useState("");
    const [languageID, setLanguageID] = useState("english");

    const [searchQuery, setSearchQuery] = useState("");
    const [availabilityFilter, setAvailabilityFilter] = useState("all");

    const addNew = () => {
        // setEdit(false)
        openModalShow();
        setSubmit({ id: '', image_url: '', name: '', created_by: localStorage.getItem("email"), created_date: new Date() });
    }

    const openModalShow = () => {
        // alert("ooo")
        window.openModelshow();
    }

    const closeModel = () => {
        window.openModelNew();
    }


    useEffect(() => {
        db.collection("workshop_glimpses").orderBy("created_date", "desc").onSnapshot((snapshot) => {
            setData(
                snapshot.docs.map((doc) => ({
                    id: doc.id,
                    data: doc.data(),
                }))
            );
        });

    }, []);


    const handleSearch = (e) => {
        setSearchQuery(e.target.value); // Step 3: Update search query state
    }


    // Function to filter data based on the search query
    const filteredData = data.filter((item) => {
        const name = item.data.name.toLowerCase();
        const query = searchQuery.toLowerCase();

        return (
            name.includes(query)
        );
    });

    const handleChange = (e) => {
        const { id, value } = e.target
        setSubmit({ ...submit, [id]: value })
    }

    const handleSubmit = (e) => {
        console.log(e.target);
        e.preventDefault()

        // console.log(locationData);
        const file = e.target[0]?.files[0]
        // console.log(file)
        if (!file) return;
        const storageRef = ref(storage, `files/${file.name}`);
        const uploadTask = uploadBytesResumable(storageRef, file);
        setshow(false)

        uploadTask.on("state_changed",
            (snapshot) => {
                const progress =
                    Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                setProgresspercent(progress);
            },
            (error) => {
                alert(error);
            },
            () => {

                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    // setImgUrl(downloadURL)
                    db.collection("workshop_glimpses").add({
                        'name': submit.name,
                        'created_by': localStorage.getItem("email"),
                        'image_url': downloadURL,
                        'created_date': new Date(),
                    }).then(() => {
                        console.log('Added Successfully');
                        const Toast = Swal.mixin({
                            toast: true,
                            background: '#69aba6',
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 3000,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener('mouseenter', Swal.stopTimer)
                                toast.addEventListener('mouseleave', Swal.resumeTimer)
                            }
                        })

                        Toast.fire({
                            icon: 'success',
                            title: 'Updated Successfully'
                        })
                        closeModel();
                        setSubmit({ id: '', image_url: '', name: '', created_by: localStorage.getItem("email"), created_date: new Date() })
                    })
                });
            }
        );


    }






    return (
        <>
            <div class="p-2" style={{ paddingBottom: "5px", display: "flex", justifyContent: "space-between" }}>
                {/* <span class="btn bg-gradient-secondary btn-sm mb-0 mr-2" style={{ color: "white" }} >&nbsp; Download Excel</span> */}
                <Link to="/admin_panel"><img src="/images/menubutton.png" class="menu-toggle" alt="Responsive image" style={{ height: "2rem" }} /></Link>
                <a class="btn bg-gradient-primary btn-sm mb-0" style={{ color: "white" }} onClick={() => addNew()} >+&nbsp; Add Glimpses</a>

            </div>
            <div style={{ textAlign: "end", paddingBottom: "5px" }}>
                {/* <Link to="/admin_panel" class="btn bg-gradient-primary btn-sm mb-0 ml-2" style={{ color: "white" }} >&nbsp; Go Back</Link> */}
            </div>
            <div class="card-header pb-0 px-3" style={{ display: "flex", justifyContent: "center" }}>
                <h6 class="mb-2">Workshop Glimpses</h6>
            </div>
            <div class="p-3">


                <input
                    type="text"
                    className="form-control form-control-sm mb-2"
                    placeholder="Search by Workshop Name"
                    value={searchQuery}
                    onChange={handleSearch}
                />

                {/* <div>
                    <label class="mr-3">Recording: </label>
                    <select value={availabilityFilter} onChange={handleAvailabilityFilter} className="form-control-sm mb-2">
                        <option value="all">All</option>
                        <option value="available">Available</option>
                        <option value="notAvailable">Not Available</option>
                    </select>
                </div> */}

                <div class="table-responsive fix-width scroll-inner">
                    <table class="table table-bordered table-hover">
                        <thead class="thead-dark" style={{ position: "sticky", top: "0" }}>
                            <tr>
                                <th>Sr. No</th>
                                <th>Workshop Name</th>
                                <th>Created Date.</th>
                                <th>Images</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredData?.map(({ id, data }, index) => (
                                <>
                                    <tr>
                                        <td>{index + 1}</td>
                                        <td>{data.name}</td>
                                        <td>{moment(data.created_date.toDate().toISOString()).format("MM/DD/YY, h:mm a")}</td>
                                        <td><img src={data.image_url} class="menu-toggle" alt="Responsive image" style={{ height: "4rem" }} /></td>


                                    </tr>
                                </>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>



            <div class="col-md-12">
                <div class="myId modal fade" id="" tabindex="-1" role="dialog" aria-labelledby="modal-form" aria-hidden="true" data-backdrop="static">
                    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div class="modal-content">
                            <div class="modal-body">


                                <div class="">
                                    <form onSubmit={handleSubmit}>
                                        <div class="">
                                            <div class="" style={{ textAlign: "center", }}><span style={{ color: "black", fontSize: "25px", fontWeight: "700", textTransform: "uppercase" }}>Add Glimpses</span></div>
                                            <div class="" style={{ textAlign: "center", color: "black", paddingTop: "10px" }}>
                                            </div>
                                            <div class="">
                                                <div class="row">
                                                    <div class="col-md-4">
                                                        <input type="file" id="image_url" style={{
                                                            // width: "17rem",
                                                            height: "45px",
                                                            padding: "12px 20px",
                                                            boxSizing: "border-box",
                                                            border: "2px solid #ccc",
                                                            borderRadius: "4px",
                                                            backgroundColor: "#f8f8f8",
                                                            fontSize: "16px",
                                                            resize: "none"
                                                        }} class="form-control" onfocus="focused(this)" onfocusout="defocused(this)" placeholder="Image" required />
                                                    </div>
                                                    <div class="col-md-4">
                                                        <input style={{
                                                            // width: "17rem",
                                                            height: "45px",
                                                            padding: "12px 20px",
                                                            boxSizing: "border-box",
                                                            border: "2px solid #ccc",
                                                            borderRadius: "4px",
                                                            backgroundColor: "#f8f8f8",
                                                            fontSize: "16px",
                                                            resize: "none"
                                                        }} class="w3l_text_box" id="name" placeholder="Workshop Name" name="Workshop Name" type="text" onChange={handleChange} value={submit.name} required />
                                                    </div>
                                                    <div class="col-md-4">

                                                    </div>

                                                </div>
                                            </div>



                                            <div class="modal-footer">
                                                {/* {submit.id == "" ? <></> : <a class="btn bg-gradient-danger btn-sm mb-0" style={{ color: "white" }} onClick={() => deleteData(submit.id)}>Delete</a>} */}
                                                <a class="btn bg-gradient-info btn-sm mb-0" style={{ color: "white" }} onClick={closeModel}>Close</a>
                                                <button type='submit' class="btn bg-gradient-danger btn-sm mb-0" style={{ backgroundColor: "green", color: "white" }}>Submit</button>
                                            </div>


                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >



        </>
    )
}

export default WoekshopGlimpses
