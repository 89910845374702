// TopicRequest.js
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import db from './Firebase';

const TopicRequest = () => {
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Fetch topic_request data
                const topicRequestsSnapshot = await db.collection('topic_request').get();
                const topicRequests = topicRequestsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

                // Fetch personal_details data for each uid
                const personalDetailsPromises = topicRequests.map(async request => {
                    const personalDetailsSnapshot = await db.collection('personal_details').doc(request.uid).get();
                    return {
                        ...request,
                        name: personalDetailsSnapshot.data()?.full_name || 'Unknown',
                        phone: personalDetailsSnapshot.data()?.phone_no || 'Unknown',
                    };
                });

                const combinedData = await Promise.all(personalDetailsPromises);
                setData(combinedData);
                setFilteredData(combinedData);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    const handleSearchChange = (e) => {
        const query = e.target.value.toLowerCase();
        setSearchQuery(query);

        const filtered = data.filter(item => {
            return (
                item.name.toLowerCase().includes(query) ||
                item.phone.toLowerCase().includes(query)
            );
        });

        setFilteredData(filtered);
    };

    const headers = [
        { label: 'Name', key: 'name' },
        { label: 'Phone No', key: 'phone' },
        { label: 'Created Date', key: 'created_date' },
        { label: 'Topic', key: 'topic' },
    ];

    const csvData = filteredData.map(({ name, phone, created_date, topic }) => ({
        name,
        phone,
        created_date: new Date(created_date.seconds * 1000).toLocaleDateString(),
        topic,
    }));

    return (
        <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg">
            <div className="p-2" style={{ paddingBottom: '5px', display: 'flex', justifyContent: 'space-between' }}>
                <Link to="/admin_panel">
                    <img src="/images/menubutton.png" className="menu-toggle" alt="Responsive image" style={{ height: '2rem' }} />
                </Link>
                <CSVLink data={csvData} headers={headers} filename="topic_requests.csv">
                    <span className="btn bg-gradient-secondary btn-sm mb-0 mr-2" style={{ color: 'white' }}>
                        &nbsp; Download Excel
                    </span>
                </CSVLink>
            </div>
            <div className="p-2">
                <div style={{ textAlign: 'end', paddingBottom: '5px' }}></div>
                <div className="card">
                    <div className="card-header pb-0 px-3">
                        <h6 className="mb-2">Topic Requests || {filteredData.length}</h6>
                    </div>
                    <input
                        type="text"
                        className="form-control form-control-sm"
                        placeholder="Search by Name or Phone"
                        value={searchQuery}
                        onChange={handleSearchChange}
                    />
                    <div className="table-responsive fix-width scroll-inner">
                        <table className="table table-bordered table-hover">
                            <thead className="thead-dark" style={{ position: 'sticky', top: '0' }}>
                                <tr style={{ textAlign: 'left' }}>
                                    <th className="col ps-2">Name</th>
                                    <th className="col">Phone No</th>
                                    <th className="col ps-2">Created Date</th>
                                    <th className="col ps-2">Topic</th>
                                </tr>
                            </thead>
                            <tbody style={{ textAlign: 'left' }}>
                                {filteredData.map(({ id, name, created_date, uid, topic, phone }) => (
                                    <tr key={id}>
                                        <td>
                                            <p className="text-xs font-weight-bold mb-0">{name}</p>
                                        </td>
                                        <td>
                                            <div className="d-flex px-2 py-1">
                                                <div className="d-flex flex-column justify-content-center">
                                                    <h6 className="mb-0 text-xs">{phone}</h6>
                                                    {/* <span className="text-xs text-secondary mb-0">{phone}</span> */}
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="d-flex px-2 py-1">
                                                <div className="d-flex flex-column justify-content-center">
                                                    <h6 className="mb-0 text-xs">{new Date(created_date.seconds * 1000).toLocaleDateString()}</h6>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="d-flex px-2 py-1">
                                                <div className="d-flex flex-column justify-content-center">
                                                    <h6 className="mb-0 text-xs">{topic}</h6>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default TopicRequest;

// // TopicRequest.js
// import React, { useEffect, useState } from 'react';
// import db from "./Firebase";

// const TopicRequest = () => {
//     const [data, setData] = useState([]);

//     useEffect(() => {
//         const fetchData = async () => {
//             try {
//                 // Fetch topic_request data
//                 const topicRequestsSnapshot = await db.collection('topic_request').get();
//                 const topicRequests = topicRequestsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

//                 // Fetch personal_details data for each uid
//                 const personalDetailsPromises = topicRequests.map(async request => {
//                     console.log(request.uid)
//                     const personalDetailsSnapshot = await db.collection('personal_details').doc(request.uid).get();
//                     return { ...request, name: personalDetailsSnapshot.data()?.full_name || 'Unknown', phone: personalDetailsSnapshot.data()?.phone_no || 'Unknown' };
//                 });

//                 const combinedData = await Promise.all(personalDetailsPromises);
//                 setData(combinedData);
//             } catch (error) {
//                 console.error('Error fetching data:', error);
//             }
//         };

//         fetchData();
//     }, []);

//     return (
//         <>
//             <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
//                 <div className="p-2" style={{ paddingBottom: "5px", display: "flex", justifyContent: "space-between" }}>
//                     <Link to="/admin_panel"><img src="/images/menubutton.png" className="menu-toggle" alt="Responsive image" style={{ height: "2rem" }} /></Link>
//                     <CSVLink data={csvdata} headers={headers}>
//                         <span className="btn bg-gradient-secondary btn-sm mb-0 mr-2" style={{ color: "white" }} >&nbsp; Download Excel</span>
//                     </CSVLink>
//                 </div>
//                 <div className="">
//                     <div className="p-2">
//                         <div style={{ textAlign: "end", paddingBottom: "5px" }}>
//                         </div>
//                         <div className="card">
//                             <div className="card-header pb-0 px-3">
//                                 <h6 className="mb-2">Topic Requests || {data.length}</h6>
//                             </div>
//                             <input
//                             type="text"
//                             className="form-control form-control-sm"
//                             placeholder="Search by Phone, Workshop Name"
//                             value={searchQuery}
//                             onChange={handleSearchChange}
//                         />
//                             <div className="">
//                                 <div className="card">
//                                     <div className="table-responsive fix-width scroll-inner">
//                                         <table className="table table-bordered table-hover">
//                                             <thead className="thead-dark" style={{ position: "sticky", top: "0" }}>
//                                                 <tr style={{ textAlign: "left" }}>
//                                                     <th className="col ps-2">Name</th>
//                                                     <th className="col">Phone No</th>
//                                                     <th className="col ps-2">Created Date</th>
//                                                     <th className="col ps-2">Topic</th>
//                                                 </tr>
//                                             </thead>
//                                             <tbody style={{ textAlign: "left" }}>
//                                                 {data.map(({ id, name, created_date, uid, topic, phone }) => (
//                                                     <tr key={id}>
//                                                         <td>
//                                                             <p className="text-xs font-weight-bold mb-0">{name}</p>
//                                                         </td>
//                                                         <td>
//                                                             <div className="d-flex px-2 py-1">
//                                                                 <div className="d-flex flex-column justify-content-center">
//                                                                     <h6 className="mb-0 text-xs">
//                                                                         {phone}
//                                                                     </h6>
//                                                                     <span className="text-xs text-secondary mb-0">
//                                                                         {phone}
//                                                                     </span>
//                                                                 </div>
//                                                             </div>
//                                                         </td>
//                                                         <td>
//                                                             <div className="d-flex px-2 py-1">
//                                                                 <div className="d-flex flex-column justify-content-center">
//                                                                     <h6 className="mb-0 text-xs">{new Date(created_date.seconds * 1000).toLocaleDateString()}</h6>
//                                                                 </div>
//                                                             </div>
//                                                         </td>

//                                                         <td>
//                                                             <div className="d-flex px-2 py-1">
//                                                                 <div className="d-flex flex-column justify-content-center">
//                                                                     <h6 className="mb-0 text-xs">{topic}</h6>
//                                                                 </div>
//                                                             </div>
//                                                         </td>
//                                                     </tr>
//                                                 ))}
//                                             </tbody>
//                                         </table>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </main>
//         </>
//     );
// };

// export default TopicRequest;
