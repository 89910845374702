import React, { useState, useEffect } from 'react';
import AdminPanel from './AdminPanel';
import Swal from 'sweetalert2'
import { Link } from 'react-router-dom';
import db from "./Firebase";
import { storage } from './Firebase';
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import ViewAppData from './ViewAppData';

function ConsumablesData() {

    const initialFormStateFeedback = { id: '', available_count: '', brand_name: '', color_code: '', discounted_amount: '', discount: '', image: '', item_count: '', per_unit_price: '', price: '', product_name: '', quantity: '', seller: '', type: '', created_date: new Date() }
    const [submit, setSubmit] = useState(initialFormStateFeedback)
    const [view, setView] = useState(false);
    const [data, setData] = useState([]);
    const [editID, setEditID] = useState("");
    const [edit, setEdit] = useState(false);
    const [ShowProgress, setShowProgress] = useState(false);
    const [show, setshow] = useState(true);
    const [progresspercent, setProgresspercent] = useState(0);

    const handleChange = (e) => {
        const { id, value } = e.target
        setSubmit({ ...submit, [id]: value })
    }

    const open = () => {
        setView(true);
    }

    const changeStatus = (data, id) => {
        // console.log(data.image_url)
        setEdit(true)
        setEditID(id)
        setSubmit({ id: id, image: data.image_url, available_count: data.available_count, brand_name: data.brand_name, color_code: data.color_code, discounted_amount: data.discounted_amount, discount: data.discount, item_count: data.item_count, per_unit_price: data.per_unit_price, price: data.price, product_name: data.product_name, quantity: data.quantity, seller: data.seller, type: data.type, created_date: new Date() });
        openModalShow();
    }

    const addNew = () => {
        setEdit(false)
        openModalShow();
        setSubmit({ id: '', available_count: '', brand_name: '', color_code: '', discounted_amount: '', discount: '', image: '', item_count: '', per_unit_price: '', price: '', product_name: '', quantity: '', seller: '', type: '', created_date: new Date() });
    }

    const openModalShow = () => {
        // alert("ooo")
        window.openModelshow();
    }

    const closeModel = () => {
        window.openModelNew();
    }


    const handleSubmit = (e) => {
        console.log("callingggggg")
        e.preventDefault()
        if (edit) {
            console.log("edit")
            if (e.target[0]?.files[0] == null) {
                db.collection("consumables_data").doc(editID).update({
                    'image_url': submit.image,
                    'product_name': submit.product_name,
                    'type': submit.type,
                    'brand_name': submit.brand_name,
                    'quantity': submit.quantity,
                    'price': submit.price,
                    'seller': submit.seller,
                    'available_count': submit.available_count,
                    'color_code': submit.color_code,
                    'discount': submit.discount,
                    'discounted_amount': submit.discounted_amount,
                    'per_unit_price': submit.per_unit_price,
                    'item_count': submit.item_count,
                    created_date: new Date()
                }).then(() => {
                    console.log('Updated Successfully');
                    const Toast = Swal.mixin({
                        toast: true,
                        background: '#69aba6',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', Swal.stopTimer)
                            toast.addEventListener('mouseleave', Swal.resumeTimer)
                        }
                    })

                    Toast.fire({
                        icon: 'success',
                        title: 'Updated Successfully'
                    })
                    closeModel();

                    setSubmit({ id: '', available_count: '', brand_name: '', color_code: '', discounted_amount: '', discount: '', image: '', item_count: '', per_unit_price: '', price: '', product_name: '', quantity: '', seller: '', type: '', created_date: new Date() })
                })
            } else {
                const file = e.target[0]?.files[0]
                // console.log(file)
                if (!file) return;
                const storageRef = ref(storage, `files/${file.name}`);
                const uploadTask = uploadBytesResumable(storageRef, file);
                setshow(false)

                uploadTask.on("state_changed",
                    (snapshot) => {
                        const progress =
                            Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                        setProgresspercent(progress);
                    },
                    (error) => {
                        alert(error);
                    },
                    () => {

                        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                            // setImgUrl(downloadURL)
                            db.collection("consumables_data").doc(editID).update({
                                'image_url': downloadURL,
                                'product_name': submit.product_name,
                                'type': submit.type,
                                'brand_name': submit.brand_name,
                                'quantity': submit.quantity,
                                'price': submit.price,
                                'seller': submit.seller,
                                'available_count': submit.available_count,
                                'color_code': submit.color_code,
                                'discount': submit.discount,
                                'discounted_amount': submit.discounted_amount,
                                'per_unit_price': submit.per_unit_price,
                                'item_count': submit.item_count,
                                created_date: new Date()
                            }).then(() => {
                                console.log('Updated Successfully');
                                const Toast = Swal.mixin({
                                    toast: true,
                                    background: '#69aba6',
                                    position: 'top-end',
                                    showConfirmButton: false,
                                    timer: 3000,
                                    timerProgressBar: true,
                                    didOpen: (toast) => {
                                        toast.addEventListener('mouseenter', Swal.stopTimer)
                                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                                    }
                                })

                                Toast.fire({
                                    icon: 'success',
                                    title: 'Updated Successfully'
                                })
                                closeModel();
                                setshow(true)
                                setSubmit({ id: '', available_count: '', brand_name: '', color_code: '', discounted_amount: '', discount: '', image: '', item_count: '', per_unit_price: '', price: '', product_name: '', quantity: '', seller: '', type: '', created_date: new Date() })
                            })
                        });
                    }
                );
            }

        } else {
            if (e.target[0]?.files[0] == null) {
                db.collection("consumables_data").add({
                    'image_url': submit.image_url,
                    'product_name': submit.product_name,
                    'type': submit.type,
                    'brand_name': submit.brand_name,
                    'quantity': submit.quantity,
                    'price': submit.price,
                    'seller': submit.seller,
                    'available_count': submit.available_count,
                    'color_code': submit.color_code,
                    'discount': submit.discount,
                    'discounted_amount': submit.discounted_amount,
                    'per_unit_price': submit.per_unit_price,
                    'item_count': submit.item_count,
                    created_date: new Date()
                }).then(() => {
                    console.log('Created Successfully');
                    const Toast = Swal.mixin({
                        toast: true,
                        background: '#69aba6',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', Swal.stopTimer)
                            toast.addEventListener('mouseleave', Swal.resumeTimer)
                        }
                    })

                    Toast.fire({
                        icon: 'success',
                        title: 'Created Successfully'
                    })
                    closeModel();

                    setSubmit({ id: '', available_count: '', brand_name: '', color_code: '', discounted_amount: '', discount: '', image: '', item_count: '', per_unit_price: '', price: '', product_name: '', quantity: '', seller: '', type: '', created_date: new Date() })
                })
            } else {
                const file = e.target[0]?.files[0]
                // console.log(file)
                if (!file) return;
                const storageRef = ref(storage, `files/${file.name}`);
                const uploadTask = uploadBytesResumable(storageRef, file);
                setshow(false)

                uploadTask.on("state_changed",
                    (snapshot) => {
                        const progress =
                            Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                        setProgresspercent(progress);
                    },
                    (error) => {
                        alert(error);
                    },
                    () => {

                        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                            // setImgUrl(downloadURL)
                            db.collection("consumables_data").add({
                                'image_url': downloadURL,
                                'product_name': submit.product_name,
                                'type': submit.type,
                                'brand_name': submit.brand_name,
                                'quantity': submit.quantity,
                                'price': submit.price,
                                'seller': submit.seller,
                                'available_count': submit.available_count,
                                'color_code': submit.color_code,
                                'discount': submit.discount,
                                'discounted_amount': submit.discounted_amount,
                                'per_unit_price': submit.per_unit_price,
                                'item_count': submit.item_count,
                                created_date: new Date()
                            }).then(() => {
                                console.log('Created Successfully');
                                const Toast = Swal.mixin({
                                    toast: true,
                                    background: '#69aba6',
                                    position: 'top-end',
                                    showConfirmButton: false,
                                    timer: 3000,
                                    timerProgressBar: true,
                                    didOpen: (toast) => {
                                        toast.addEventListener('mouseenter', Swal.stopTimer)
                                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                                    }
                                })

                                Toast.fire({
                                    icon: 'success',
                                    title: 'Created Successfully'
                                })
                                closeModel();
                                setshow(true)
                                setSubmit({ id: '', available_count: '', brand_name: '', color_code: '', discounted_amount: '', discount: '', image: '', item_count: '', per_unit_price: '', price: '', product_name: '', quantity: '', seller: '', type: '', created_date: new Date() })
                            })
                        });
                    }
                );
            }
        }

    }

    const deleteData = (id) => {
        closeModel();
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                db.collection("consumables_data").doc(id).delete().then(() => {
                    console.log('Deleted Successfully');
                    setSubmit({ id: '', available_count: '', brand_name: '', color_code: '', discounted_amount: '', discount: '', image: '', item_count: '', per_unit_price: '', price: '', product_name: '', quantity: '', seller: '', type: '', created_date: new Date() })
                    closeModel();
                    Swal.fire(
                        'Deleted!',
                        'Data has been deleted.',
                        'success'
                    )
                })

            }
        })

    }

    useEffect(() => {
        db.collection("consumables_data").orderBy("created_date", "desc").onSnapshot((snapshot) => {
            setData(
                snapshot.docs.map((doc) => ({
                    id: doc.id,
                    data: doc.data(),
                }))
            );
        });

    }, []);


    return (
        <>
            <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">

                <ViewAppData />


                <div class="container-fluid py-4">
                    <div style={{ textAlign: "end", paddingBottom: "5px" }}>
                        <a class="btn bg-gradient-primary btn-sm mb-0 mr-2" style={{ color: "white" }} onClick={() => addNew()}>+&nbsp; Add New Consumables</a>
                        <Link to="/upload_consumables" class="btn bg-gradient-primary btn-sm mb-0" style={{ color: "white" }}>+&nbsp; Upload Bulk Data</Link>
                        <Link to="/admin_panel" class="btn bg-gradient-primary btn-sm mb-0 ml-2" style={{ color: "white" }} >&nbsp; Go Back</Link>

                    </div>
                    <div class="card">
                        <div class="table-responsive fix-width scroll-inner">
                            <table class="table align-items-center mb-0">
                                <thead>
                                    <tr>
                                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Product Name</th>
                                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Product Type</th>
                                        <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Price</th>
                                        <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Discount Percentage</th>
                                        <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Discounted Amount</th>
                                        <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Action</th>
                                        {/* <th class="text-secondary opacity-7"></th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {data?.map(({ id, data }, index) => (
                                        <>
                                            <tr>
                                                <td>
                                                    <div class="d-flex px-2 py-1">
                                                        <div>
                                                            <img src={data.image_url} class="me-3" style={{ height: "5rem" }} />
                                                        </div>
                                                        <div class="d-flex flex-column justify-content-center">
                                                            <h6 class="mb-0 text-xs">{data.product_name}</h6>
                                                            <p class="text-xs text-secondary mb-0">{data.brand_name}</p>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <p class="text-xs font-weight-bold mb-0">{data.type}</p>
                                                    {/* <p class="text-xs text-secondary mb-0">Organization</p> */}
                                                </td>
                                                <td class="align-middle text-center text-sm">
                                                    <span class="text-secondary text-xs font-weight-normal">₹ {data.price}</span>
                                                </td>
                                                <td class="align-middle text-center">
                                                    <span class="text-secondary text-xs font-weight-normal">{data.discount}</span>
                                                </td>
                                                <td class="align-middle text-center" >
                                                    <span class="text-secondary text-xs font-weight-normal">{data.discounted_amount}</span>
                                                </td>
                                                <td class="align-middle text-center" onClick={() => changeStatus(data, id)}>
                                                    <span class="text-info text-xs font-weight-normal" style={{ cursor: "pointer" }}>Edit</span>
                                                </td>

                                            </tr>
                                        </>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

            </main>

            <div class="col-md-4">
                <div class="myId modal fade" id="" tabindex="-1" role="dialog" aria-labelledby="modal-form" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered" role="document">
                        <div class="modal-content">
                            <div class="modal-body p-0">
                                <div class="card card-plain">
                                    {/* <div class="card-header pb-0 text-left">
                                        <h5 class="pb-4" style={{ fontSize: "13px", fontWeight: "bolder" }}>Enter your email and password to sign in</h5>
                                    </div> */}
                                    <form onSubmit={handleSubmit}>
                                        <div class="card-body">
                                            <div class="modal-body">
                                                <div class="row">

                                                    <div class="col-md-12">
                                                        <span>Product Image :</span>
                                                        {submit.image == "" ? <></> : <img src={submit.image} class="avatar me-3" style={{ height: "5rem", width: "6rem" }} />}
                                                        <div class="">
                                                            {edit ? <input type="file" id="image" class="" onfocus="focused(this)" onfocusout="defocused(this)" placeholder="Image" /> : <input type="file" id="image" class="" onfocus="focused(this)" onfocusout="defocused(this)" placeholder="Image" required />}
                                                        </div>
                                                    </div>


                                                </div>
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        {/* <div class="input-group input-group-outline my-3"> */}
                                                        <input type="text" id="product_name" class="form-control" onfocus="focused(this)" onfocusout="defocused(this)" onChange={handleChange} value={submit.product_name} placeholder="Product Name" required />
                                                        {/* </div> */}
                                                    </div>
                                                    <div class="col-md-6">

                                                        <select class="form-control" id="type" onChange={handleChange} value={submit.type} required>
                                                            <option value="">Product Type</option>
                                                            <option value="Skin">Skin</option>
                                                            <option value="Hair">Hair</option>
                                                            <option value="Make-up">Make-up</option>
                                                            <option value="Nails">Nails</option>
                                                        </select>

                                                    </div>

                                                </div>



                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <div class="input-group input-group-outline my-3">
                                                            <input type="text" id="brand_name" class="form-control" onfocus="focused(this)" onfocusout="defocused(this)" onChange={handleChange} value={submit.brand_name} placeholder="Brand Name" required />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="input-group input-group-outline my-3">
                                                            <input type="text" id="quantity" class="form-control" onfocus="focused(this)" onfocusout="defocused(this)" onChange={handleChange} value={submit.quantity} placeholder="Quantity" required />
                                                        </div>
                                                    </div>

                                                </div>


                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <div class="input-group input-group-outline my-3">
                                                            <input type="text" id="price" class="form-control" onfocus="focused(this)" onfocusout="defocused(this)" placeholder="Price" onChange={handleChange} value={submit.price} required />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="input-group input-group-outline my-3">
                                                            <input type="text" id="seller" class="form-control" onfocus="focused(this)" onfocusout="defocused(this)" onChange={handleChange} value={submit.seller} placeholder="Seller" required />
                                                        </div>
                                                    </div>

                                                </div>

                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <div class="input-group input-group-outline my-3">
                                                            <input type="number" id="available_count" class="form-control" onfocus="focused(this)" onfocusout="defocused(this)" placeholder="Available Count" onChange={handleChange} value={submit.available_count} required />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="input-group input-group-outline my-3">
                                                            <input type="text" id="color_code" class="form-control" onfocus="focused(this)" onfocusout="defocused(this)" onChange={handleChange} value={submit.color_code} placeholder="Color Code" required />
                                                        </div>
                                                    </div>

                                                </div>

                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <div class="input-group input-group-outline my-3">
                                                            <input type="text" id="discount" class="form-control" onfocus="focused(this)" onfocusout="defocused(this)" placeholder="Discount Percentage" onChange={handleChange} value={submit.discount} required />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="input-group input-group-outline my-3">
                                                            <input type="text" id="discounted_amount" class="form-control" onfocus="focused(this)" onfocusout="defocused(this)" placeholder="Discounted Amount" onChange={handleChange} value={submit.discounted_amount} required />
                                                        </div>
                                                    </div>

                                                </div>

                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <div class="input-group input-group-outline my-3">
                                                            <input type="number" id="per_unit_price" class="form-control" onfocus="focused(this)" onfocusout="defocused(this)" placeholder="Per Unit Price" onChange={handleChange} value={submit.per_unit_price} required />
                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                            <div class="modal-footer">
                                                {submit.id == "" ? <></> : <a class="btn bg-gradient-danger btn-sm mb-0" style={{ color: "white" }} onClick={() => deleteData(submit.id)}>Delete</a>}
                                                <a class="btn bg-gradient-info btn-sm mb-0" style={{ color: "white" }} onClick={closeModel}>Close</a>
                                                <button type='submit' class="btn bg-gradient-danger btn-sm mb-0" style={{ backgroundColor: "green", color: "white" }}>{edit ? "Update" : "Submit"}</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ConsumablesData;