import React, { useState, useEffect } from 'react';
import * as XLSX from 'xlsx';
import DataTable from 'react-data-table-component';
import { useNavigate, Link, useParams } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2'
import db from "./Firebase";
import { storage } from './Firebase';
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import Moment from 'moment';
import DatePicker from "react-datepicker";
import { extendMoment } from 'moment-range';
import "react-datepicker/dist/react-datepicker.css";


function OoveChampian() {
    let params = useParams();
    const navigate = useNavigate();
    const moment = extendMoment(Moment);
    const initialFormStateFeedback = {
        name: '',
        email: '',
        phone_no: '',
        pincode: '',
        whatiknow: '',
        whatihavedone: '',
        whatiwanttodonext: '',
        address: '',
        created_date: '', created_date: new Date()
    }
    const [submit, setSubmit] = useState(initialFormStateFeedback)

    const [show, setshow] = useState(true);
    const [startDate, setStartDate] = useState(new Date());
    const [imgUrl, setImgUrl] = useState(null);
    const [progresspercent, setProgresspercent] = useState(0);


    const handleChange = (e) => {
        const { id, value } = e.target
        setSubmit({ ...submit, [id]: value })
    }

    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [country, setCountry] = useState('');
    const handleChangePincode = (e) => {

        const { id, value } = e.target
        setSubmit({ ...submit, [id]: value })
        if (e.target.value.length == 6) {
            axios.get(`https://api.postalpincode.in/pincode/${e.target.value}`)
                .then(res => {
                    const alldata = res.data;
                    console.log(alldata[0]['PostOffice'][0]);
                    setCity(alldata[0]['PostOffice'][0]['District'])
                    setState(alldata[0]['PostOffice'][0]['State'])
                    setCountry(alldata[0]['PostOffice'][0]['Country'])
                })
        }
    }

    const reset = () => {
        setSubmit({
            name: '',
            email: '',
            phone_no: '',
            pincode: '',
            whatiknow: '',
            whatihavedone: '',
            whatiwanttodonext: '',
            address: '',
            created_date: '', created_date: new Date()
        })
    }


    const ids = ["rahul_122002", "ashutosh_560001", "mrinal_122004"];

    const handleSubmit = (e) => {
        // console.log(submit.usercode)
        // alert("okkk")
        e.preventDefault()
        db.collection("youvati_champian").add({
            'name': submit.name,
            'email': submit.email,
            'phone_no': submit.phone_no,
            'pincode': submit.pincode,
            'city': city,
            'state': state,
            'country': country,
            'whatiknow': submit.whatiknow,
            'whatihavedone': submit.whatihavedone,
            'whatiwanttodonext': submit.whatiwanttodonext,
            'address': submit.address,
            created_date: new Date()
        }).then(() => {
            console.log('Submitted Successfully');
            const Toast = Swal.mixin({
                toast: true,
                background: '#69aba6',
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            })

            Toast.fire({
                icon: 'success',
                title: 'Submitted Successfully'
            })
            setshow(true)
            // navigate(`/${submit.username}`);
            setSubmit({
                name: '',
                email: '',
                phone_no: '',
                pincode: '',
                whatiknow: '',
                whatihavedone: '',
                whatiwanttodonext: '',
                address: '',
                created_date: '', created_date: new Date()
            })
        })
    }

    useEffect(() => {
        // alert(params.id)
    }, []);



    return (
        <>
            <div id="small-dialog" class="m-3">
                <div class="w3layouts_payment_online_form">
                    <form class="" id="" onSubmit={handleSubmit}>
                        <h4>Oove Champian</h4>
                        <ul>

                            <li><input class="w3l_text_box" id="name" onChange={handleChange} value={submit.name} placeholder="Name" name="Name" type="text" required />
                            </li>
                            <li><input class="w3l_text_box" id="email" placeholder="Email" onChange={handleChange} value={submit.email} name="Email" type="email" required="" />
                            </li>
                        </ul>
                        <ul>
                            <li><input class="w3l_text_box" id="pincode" placeholder="*Pincode" onChange={handleChangePincode} value={submit.pincode} name="Pincode" type="number" required="" /></li>
                            <li>
                                <input class="w3l_text_box" id="phone_no" onChange={handleChange} value={submit.phone_no} placeholder="10 Digit Phone Number" name="Phone" type="tel" minlength="10" maxlength="10" required />
                            </li>
                        </ul>

                        <ul>
                            <li><input class="w3l_text_box" id="state" placeholder="*State" onChange={handleChange} value={state} name="State" type="text" required="" /></li>
                            <li><input class="w3l_text_box" id="city" placeholder="*City" onChange={handleChange} value={city} name="City" type="text" required="" /></li>

                        </ul>
                        <ul>
                            <li><input class="w3l_text_box" id="country" placeholder="*Country" onChange={handleChange} value={country} name="Country" type="text" required="" /></li>
                            <li></li>

                        </ul>
                        <ul>
                            <li class="w3ls_address"><input class="w3l_text_box" id="whatiknow" onChange={handleChange} value={submit.whatiknow} placeholder="What I know how to do ?" name="What I know how to do ?" type="text" style={{ height: "3rem" }} required /></li>
                            <li class="w3ls_address"><input class="w3l_text_box" id="whatihavedone" onChange={handleChange} value={submit.whatihavedone} placeholder="What I've done" name="What I've done" type="text" style={{ height: "3rem" }} required /></li>
                            <li class="w3ls_address"><input class="w3l_text_box" id="whatiwanttodonext" onChange={handleChange} value={submit.whatiwanttodonext} placeholder="What I want to do next" name="What I want to do next" type="text" style={{ height: "3rem" }} required /></li>
                            <li class="w3ls_address"><input class="w3l_text_box" id="address" onChange={handleChange} value={submit.address} placeholder="Your Address" name="Address" type="text" style={{ height: "6rem" }} required /></li>
                        </ul>


                        <ul class="w3_payment_sendbtns">
                            <li><input type="reset" data-dismiss="modal" aria-label="Close" onClick={reset} value="Reset" /></li>
                            <li>{show ? <input type="submit" value="Submit" /> : "Please Wait..."}</li>
                        </ul>
                        <div class="clearfix"> </div>
                    </form>
                </div>
            </div>
        </>


    );
}
export default OoveChampian;