import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import "./style.css";
import { useNavigate, Link, useParams } from "react-router-dom"
import Select from 'react-select';
import NumberCounter from 'number-counter';
import FooterMain from "./FooterMain";

export const BridalClicks = () => {

    const [count, setCount] = useState(0);
    useEffect(() => {
        window.scrollTo(0, 0);
        for (let count = 0; count <= 500; count++) {
            setTimeout(() => {
                setCount(count)
            }, 1000);
        }
    }, []);


    const containerStyle = {
        display: "flex",
        width: "100%", // Ensure the container spans the entire width
        marginTop: "-5px",
    };

    const leftPartStyle = {
        flex: 1,
        border: "none",
        boxSizing: "border-box",
        // backgroundColor: "#7d6853",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    };

    const rightPartStyle = {
        flex: 1,
        border: "none",
        boxSizing: "border-box",
        backgroundColor: "rgb(166 140 114)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    };
    const circleContainerStyle = {
        display: "flex",
        justifyContent: "space-around",
        marginTop: "20px",
    };

    const circleImageStyle = {
        borderRadius: "50%",
        width: "150px", // Adjust the size as needed
        height: "150px", // Adjust the size as needed
        objectFit: "cover",
    };

    const circleTitleStyle = {
        marginTop: "10px",
        fontSize: "16px",
        fontWeight: "600",
        textAlign: "center",
    };

    const squareContainerStyle = {
        display: "flex",
        justifyContent: "space-around",
        marginTop: "20px",
    };

    const squareImageStyle = {
        width: "250px", // Adjust the size as needed
        height: "250px", // Adjust the size as needed
        objectFit: "cover",
    };

    const squareContentStyle = {
        textAlign: "center",
        marginTop: "10px",
    };

    const ratingStarStyle = {
        color: "gold", // Adjust the color as needed
    };


    const bridalImages = [
        { image_url: "/bridalclick/Rectangle 92.png", },
        { image_url: "/bridalclick/Rectangle 93.png", },
        { image_url: "/bridalclick/Rectangle 94.png", },
        { image_url: "/bridalclick/Rectangle 104.png", },
        { image_url: "/bridalclick/Rectangle 105.png", },
        { image_url: "/bridalclick/Rectangle 106.png", },
        { image_url: "/bridalclick/Rectangle 107.png", },
        { image_url: "/bridalclick/Rectangle 108.png", },
        { image_url: "/bridalclick/Rectangle 109.png", },
        { image_url: "/bridalclick/Rectangle 110.png", },
        { image_url: "/bridalclick/Rectangle 111.png", },
        { image_url: "/bridalclick/Rectangle 112.png", },

    ]



    return (
        <div>
            <section>
                <div class="navi" style={{ backgroundColor: "#ec5d69" }}>
                    <input type="checkbox" id="nav-check" />
                    <div class="nav-header">
                        <div class="nav-title">
                            <Link to="/"> <img src="/bridal/youvati_white_logo.png" alt="" style={{ height: "27px", marginLeft: "2rem" }} /></Link>
                        </div>
                    </div>
                    <div class="nav-btn">
                        <label for="nav-check">
                            <span></span>
                            <span></span>
                            <span></span>
                        </label>
                    </div>

                    <div class="nav-links" id="top">
                        <a href="#portfolio" class="linkcolor" style={{ color: "white" }}>Bridal</a>
                        <span class="line" style={{ color: "white" }}>|</span>
                        <a href="#portfolio" class="linkcolor" style={{ color: "white" }}>Why Choose Us</a>
                        <span class="line" style={{ color: "white" }}>|</span>
                        <a href="#partners" class="linkcolor" style={{ color: "white" }}>For Partners</a>

                        <span class="line" style={{ color: "white" }}>|</span>
                        <a href="#reachtous" class="linkcolor" style={{ color: "white" }}>Contact Us</a>

                    </div>
                </div>
            </section>

            <div style={{
                justifyContent: "space-between", display: "flex",
                width: "100%", marginTop: "-5px",
            }}>
                <div style={leftPartStyle}>
                    <img
                        src="/bridal/Rectangle_94.png" // Replace with the actual image URL
                        alt="Left Part Image"
                        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                    />
                </div>


            </div>
            <div style={{ textAlign: "left" }}>
                <h2 style={{ fontSize: "24px", fontWeight: "600", color: "black", paddingLeft: "100px", marginTop: "3rem" }}>Bridal Clicks</h2>
            </div>
            <div class="container">

                <div class="row">
                    {/* <div style={{ }} class="gallery col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <h1 class="gallery-title">Bridal Clicks</h1>
                    </div> */}

                    {/* <div align="center">
                        <button class="btn btn-default filter-button" data-filter="all">All</button>
                        <button class="btn btn-default filter-button" data-filter="hdpe">HDPE Pipes</button>
                        <button class="btn btn-default filter-button" data-filter="sprinkle">Sprinkle Pipes</button>
                        <button class="btn btn-default filter-button" data-filter="spray">Spray Nozzle</button>
                        <button class="btn btn-default filter-button" data-filter="irrigation">Irrigation Pipes</button>
                    </div> */}
                    <br />


                    {bridalImages.map((imgs, index) => (
                        <div style={{ padding: "30px" }} class="gallery_product col-lg-4 col-md-4 col-sm-4 col-xs-6 filter hdpe">
                            <div class="img-wrapper">
                                <img style={{ height: "21rem", width: "18rem" }} src={imgs.image_url} class="img-responsive inner-img" />
                            </div>
                        </div>
                    ))}

                    {/* <div style={{ padding: "30px" }} class="gallery_product col-lg-4 col-md-4 col-sm-4 col-xs-6 filter sprinkle">
                        <div class="img-wrapper">
                            <img style={{ height: "21rem", width: "18rem" }} src="/bridal/Rectangle_96.png" class="img-responsive inner-img" />
                        </div>
                    </div>

                    <div style={{ padding: "30px" }} class="gallery_product col-lg-4 col-md-4 col-sm-4 col-xs-6 filter hdpe">
                        <div class="img-wrapper">
                            <img style={{ height: "21rem", width: "18rem" }} src="/bridal/Rectangle_97.png" class="img-responsive inner-img" />
                        </div>
                    </div>

                    <div style={{ padding: "30px" }} class="gallery_product col-lg-4 col-md-4 col-sm-4 col-xs-6 filter irrigation">
                        <div class="img-wrapper">
                            <img style={{ height: "21rem", width: "18rem" }} src="/bridal/Rectangle_97.png" class="img-responsive inner-img" />
                        </div>
                    </div>

                    <div style={{ padding: "30px" }} class="gallery_product col-lg-4 col-md-4 col-sm-4 col-xs-6 filter spray">
                        <div class="img-wrapper">
                            <img style={{ height: "21rem", width: "18rem" }} src="/bridal/Rectangle_95.png" class="img-responsive inner-img" />
                        </div>
                    </div>

                    <div style={{ padding: "30px" }} class="gallery_product col-lg-4 col-md-4 col-sm-4 col-xs-6 filter irrigation">
                        <div class="img-wrapper">
                            <img style={{ height: "21rem", width: "18rem" }} src="/bridal/Rectangle_96.png" class="img-responsive inner-img" />
                        </div>
                    </div>

                    <div style={{ padding: "30px" }} class="gallery_product col-lg-4 col-md-4 col-sm-4 col-xs-6 filter spray">
                        <div class="img-wrapper">
                            <img style={{ height: "21rem", width: "18rem" }} src="/bridal/Rectangle_95.png" class="img-responsive inner-img" />
                        </div>
                    </div>

                    <div style={{ padding: "30px" }} class="gallery_product col-lg-4 col-md-4 col-sm-4 col-xs-6 filter irrigation">
                        <div class="img-wrapper">
                            <img style={{ height: "21rem", width: "18rem" }} src="/bridal/Rectangle_96.png" class="img-responsive inner-img" />
                        </div>
                    </div>

                    <div style={{ padding: "30px" }} class="gallery_product col-lg-4 col-md-4 col-sm-4 col-xs-6 filter irrigation">
                        <div class="img-wrapper">
                            <img style={{ height: "21rem", width: "18rem" }} src="/bridal/Rectangle_97.png" class="img-responsive inner-img" />
                        </div>
                    </div>

                    <div style={{ padding: "30px" }} class="gallery_product col-lg-4 col-md-4 col-sm-4 col-xs-6 filter hdpe">
                        <div class="img-wrapper">
                            <img style={{ height: "21rem", width: "18rem" }} src="/bridal/Rectangle_96.png" class="img-responsive inner-img" />
                        </div>
                    </div>

                    <div style={{ padding: "30px" }} class="gallery_product col-lg-4 col-md-4 col-sm-4 col-xs-6 filter spray">
                        <div class="img-wrapper">
                            <img style={{ height: "21rem", width: "18rem" }} src="/bridal/Rectangle_95.png" class="img-responsive inner-img" />
                        </div>
                    </div>

                    <div style={{ padding: "30px" }} class="gallery_product col-lg-4 col-md-4 col-sm-4 col-xs-6 filter sprinkle">
                        <div class="img-wrapper">
                            <img style={{ height: "21rem", width: "18rem" }} src="/bridal/Rectangle_97.png" class="img-responsive inner-img" />
                        </div>
                    </div> */}
                </div>
            </div>


            <FooterMain />

        </div>
    );
};


