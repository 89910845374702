import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate, Link, useParams } from "react-router-dom"
import Select from 'react-select';
import FooterMain from "./FooterMain";
import Swal from 'sweetalert2'
import db from "./Firebase";
import { storage } from './Firebase';


function ContactUs() {

    const initialFormStateFeedback = { name: '', email: '', message: '' }
    const [submit, setSubmit] = useState(initialFormStateFeedback)

    const handleChange = (e) => {
        const { id, value } = e.target
        setSubmit({ ...submit, [id]: value })
    }

    const handleSubmit = (e) => {
        // console.log(submit.usercode)
        e.preventDefault()
        db.collection("website_message").add({
            'name': submit.name,
            'email': submit.email,
            'message': submit.message,
            'created_date': new Date(),
        }).then(() => {
            db.collection("all_notification").add({ 'name': submit.name, 'status': '0', 'link': "website_message", 'message': `Sent a message on website :- ${submit.message}`, 'created_date': new Date() }).then(() => {
                console.log("data added");
            })
            console.log('Message Sent Successfully');
            const Toast = Swal.mixin({
                toast: true,
                background: '#69aba6',
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            })

            Toast.fire({
                icon: 'success',
                title: 'Submitted Successfully'
            })
            // setshow(true)
            // navigate(`/${submit.username}`);
            setSubmit({ name: '', email: '', message: '' })
        })
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <section style={{ boxShadow: "0 6px 6px -6px #777" }}>
                <div class="navi" >
                    <input type="checkbox" id="nav-check" />
                    <div class="nav-header">
                        <div class="nav-title">
                            <Link to='/' > <img src="/images/youvati_logo.png" alt="" style={{ height: "27px", marginLeft: "2rem" }} /></Link>
                        </div>
                    </div>


                </div>
            </section>
            <div style={{ paddingTop: "5rem" }}>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div class="container1" style={{ maxWidth: "1250px !important" }}>
                        <div class="content1" style={{ flexDirection: "column-reverse" }}>
                            <div class="left-side" style={{ width: "100%", flexDirection: "row", marginTop: "40px", justifyContent: "center", flexWrap: "wrap" }}>
                                <div class="address details">
                                    <i class="fas fa-map-marker-alt"></i>
                                    <div class="topic">Address</div>
                                    <div class="text-one">Ground Floor, 35, Khasra No. 369,370,382,</div>
                                    <div class="text-two">Ghitorni Branch, 371, 100 Feet Rd,</div>
                                    <div class="text-two">near Canara Bank, Ghitorni, New Delhi, Delhi 110030</div>
                                </div>
                                <div class="phone details">
                                    <i class="fas fa-phone-alt"></i>
                                    <div class="topic">Phone</div>
                                    <div class="text-one">+91 9898989890</div>
                                    <div class="text-two">+91 9898989890</div>
                                </div>
                                <div class="email details">
                                    <i class="fa fa-envelope fa-2x"></i>
                                    <div class="topic">Email</div>
                                    <div class="text-one">info@youvati.com</div>
                                    <div class="text-two">info@empowerplus.in</div>
                                </div>
                            </div>
                            <div class="right-side" style={{ width: "100%", marginLeft: "0" }}>
                                <div class="topic-text">Send us a message</div>
                                <p>If you have any work from me or any types of quries related to my tutorial, you can send me message from here. It's my pleasure to help you.</p>
                                <form onSubmit={handleSubmit}>
                                    <div class="input-box">
                                        <input type="text" id="name" onChange={handleChange} value={submit.name} placeholder="Enter your name" required />
                                    </div>
                                    <div class="input-box">
                                        <input type="email" id="email" onChange={handleChange} value={submit.email} placeholder="Enter your email" />
                                    </div>

                                    <div class="input-box">
                                        <textarea class="form-control" id="message" onChange={handleChange} value={submit.message} rows="10" placeholder="MESSAGE" name="message" required></textarea>
                                    </div>

                                    <button class="btn btn-primary send-button" type="submit" value="SEND">
                                        <div class="alt-send-button">
                                            <i class="fa fa-paper-plane"></i><span class="send-text">SEND</span>
                                        </div>

                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <section class="contact_map_area" style={{ marginTop: "2rem", marginLeft: "1rem", marginBottom: "1rem", marginRight: "1rem" }}>
                    <iframe
                        width="100%"
                        height="300"
                        style={{ border: "0" }}
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3506.5020210930397!2d77.14293437393256!3d28.49453999033653!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d1e5b53a79cfd%3A0xc335c5d88695bcef!2sEmpower%20Pragati!5e0!3m2!1sen!2sin!4v1682072428845!5m2!1sen!2sin" allowfullscreen>
                        {/* src="https://www.google.com/maps/embed/v1/place?key=AIzaSyAw0My4Arc0Ht1EBA2z_7b07eA00jPs5VU&q=7F2F%2BW6 Bhopal+Madhya+Pradesh+India+Tarasha+beauty+salon+for+ladies" allowfullscreen> */}
                    </iframe>
                </section>
            </div>
            <FooterMain />
        </>
    );
}

export default ContactUs;