
import React, { useState, useEffect } from 'react';
import { useNavigate, Link, useParams } from 'react-router-dom';
import db from "./Firebase";
import { arrayUnion } from "firebase/firestore";
import { storage } from './Firebase';
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import Moment from 'moment';
import "./style.css";
import Select from 'react-select';
import NumberCounter from 'number-counter';
import FooterMain from "./FooterMain";
import Swal from 'sweetalert2';
import { extendMoment } from 'moment-range';


export const BridalAtrist = () => {
    const [selectedCity, setSelectedCity] = useState("");
    const [count, setCount] = useState(0);
    const navigate = useNavigate();
    const moment = extendMoment(Moment);
    const [data, setData] = useState([]);
    const [uid, setUid] = useState([]);
    const [mergedData, setMergedData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [userAverageRatings, setUserAverageRatings] = useState({});
    const [userReviewCount, setUserReviewCount] = useState({});

    useEffect(() => {
        window.scrollTo(0, 0);
        for (let count = 0; count <= 500; count++) {
            setTimeout(() => {
                setCount(count)
            }, 1000);
        }
    }, []);

    useEffect(() => {
        // Calculate average ratings for each user
        const calculateAverageRatings = () => {
            const averageRatings = {};
            db.collection('parlour_feedback').onSnapshot((snapshot) => {
                snapshot.docs.forEach((doc) => {
                    const username = doc.data().username;
                    const rating = doc.data().rating;
                    if (averageRatings[username]) {
                        // If user exists, update their average rating
                        averageRatings[username].total += rating;
                        averageRatings[username].count += 1;
                    } else {
                        // If user doesn't exist, initialize their average rating
                        averageRatings[username] = {
                            total: rating,
                            count: 1,
                        };
                    }
                });

                // Calculate and set average ratings
                for (const username in averageRatings) {
                    averageRatings[username].average =
                        averageRatings[username].total /
                        averageRatings[username].count;
                }
                setUserAverageRatings(averageRatings);
            });
        };

        calculateAverageRatings();
    }, []);

    useEffect(() => {
        db.collection('parlour_feedback').onSnapshot((snapshot) => {
            const reviewCounts = {};
            snapshot.docs.forEach((doc) => {
                const username = doc.data().username;
                reviewCounts[username] = reviewCounts[username] ? reviewCounts[username] + 1 : 1;
            });
            setUserReviewCount(reviewCounts); // Commented out as it's not used in this code snippet
        });
    }, []);


    useEffect(() => {
        const fetchData = async () => {
            try {
                const portfolioDetailsSnapshot = await db.collection("portfolio_details").get();
                const portfolioDetailsData = portfolioDetailsSnapshot.docs.reduce((acc, doc) => {
                    acc[doc.data().username] = {
                        id: doc.id,
                        data: doc.data(),
                    };
                    return acc;
                }, {});

                const websitePortfolioSnapshot = await db.collection("website_portfolio_listing").orderBy("listID", "asc").get();
                const websitePortfolioData = websitePortfolioSnapshot.docs.map((doc) => ({
                    id: doc.id,
                    data: doc.data(),
                }));

                const mergedData = websitePortfolioData.map((wpData) => ({
                    ...wpData,
                    portfolioDetail: portfolioDetailsData[wpData.data.username],
                }));

                setMergedData(mergedData);
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const filterDataByCity = () => {
        if (selectedCity === "") {
            return mergedData; // Return all data if no city is selected
        }

        return mergedData.filter(({ data }) => data.city === selectedCity);
    };

    const handleCityChange = (selectedOption) => {
        setSelectedCity(selectedOption.value);
    };

    const containerStyle = {
        display: "flex",
        width: "100%", // Ensure the container spans the entire width
        marginTop: "-5px",
    };

    const leftPartStyle = {
        flex: 1,
        border: "none",
        boxSizing: "border-box",
        backgroundColor: "#7d6853",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    };

    const rightPartStyle = {
        flex: 1,
        border: "none",
        boxSizing: "border-box",
        backgroundColor: "rgb(166 140 114)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    };
    const circleContainerStyle = {
        display: "flex",
        justifyContent: "space-around",
        marginTop: "20px",
    };

    const circleImageStyle = {
        borderRadius: "50%",
        width: "150px", // Adjust the size as needed
        height: "150px", // Adjust the size as needed
        objectFit: "cover",
    };

    const circleTitleStyle = {
        marginTop: "10px",
        fontSize: "16px",
        fontWeight: "600",
        textAlign: "center",
    };

    const squareContainerStyle = {
        display: "flex",
        justifyContent: "space-around",
        marginTop: "20px",
    };

    const squareImageStyle = {
        width: "250px", // Adjust the size as needed
        height: "250px", // Adjust the size as needed
        objectFit: "cover",
    };

    const squareContentStyle = {
        textAlign: "center",
        marginTop: "10px",
    };

    const ratingStarStyle = {
        color: "gold", // Adjust the color as needed
    };




    return (
        <div>
            <section>
                <div class="navi" style={{ backgroundColor: "#ec5d69" }}>
                    <input type="checkbox" id="nav-check" />
                    <div class="nav-header">
                        <div class="nav-title">
                            <Link to="/"> <img src="/bridal/youvati_white_logo.png" alt="" style={{ height: "21px", marginLeft: "1rem" }} /></Link>
                        </div>
                    </div>
                    <div class="nav-btn">
                        <label for="nav-check">
                            <span></span>
                            <span></span>
                            <span></span>
                        </label>
                    </div>

                    <div class="nav-links" id="top">
                        <a href="#portfolio" class="linkcolor" style={{ color: "white" }}>Bridal</a>
                        <span class="line" style={{ color: "white" }}>|</span>
                        <a href="#portfolio" class="linkcolor" style={{ color: "white" }}>Why Choose Us</a>
                        <span class="line" style={{ color: "white" }}>|</span>
                        <a href="#partners" class="linkcolor" style={{ color: "white" }}>For Partners</a>

                        <span class="line" style={{ color: "white" }}>|</span>
                        <a href="#reachtous" class="linkcolor" style={{ color: "white" }}>Contact Us</a>

                    </div>
                </div>
            </section>

            <div style={{
                justifyContent: "space-between", display: "flex",
                width: "100%", marginTop: "-5px",
            }}>
                <div style={leftPartStyle}>
                    <img
                        src="/bridal/Rectangle_93.png" // Replace with the actual image URL
                        alt="Left Part Image"
                        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                    />
                </div>

            </div>

            <div class="container mt-3" >
                <div style={{
                    justifyContent: "space-between", display: "flex",
                    width: "100%",
                    justifyContent: "end"
                }}>

                    <div class="col-md-4">
                        <Select
                            options={[
                                // Replace with your city options
                                { value: "", label: "All Cities" },
                                { value: "Meerut", label: "Meerut" },
                                { value: "Mumbai", label: "Mumbai" },
                                { value: 'Andhra Pradesh', label: 'Andhra Pradesh' },
                                { value: 'Arunachal Pradesh', label: 'Arunachal Pradesh' },
                                { value: 'Assam', label: 'Assam' },
                                { value: 'Bihar', label: 'Bihar' },
                                { value: 'Chhattisgarh', label: 'Chhattisgarh' },
                                { value: 'Goa', label: 'Goa' },
                                { value: 'Gujarat', label: 'Gujarat' },
                                { value: 'Haryana', label: 'Haryana' },
                                { value: 'Himachal Pradesh', label: 'Himachal Pradesh' },
                                { value: 'Jammu and Kashmir', label: 'Jammu and Kashmir' },
                                { value: 'Jharkhand', label: 'Jharkhand' },
                                { value: 'Karnataka', label: 'Karnataka' },
                                { value: 'Kerala', label: 'Kerala' },
                                { value: 'Madhya Pradesh', label: 'Madhya Pradesh' },
                                { value: 'Maharashtra', label: 'Maharashtra' },
                                { value: 'Manipur', label: 'Manipur' },
                                { value: 'Meghalaya', label: 'Meghalaya' },
                                { value: 'Mizoram', label: 'Mizoram' },
                                { value: 'Nagaland', label: 'Nagaland' },
                                { value: 'Odisha', label: 'Odisha' },
                                { value: 'Punjab', label: 'Punjab' },
                                { value: 'Rajasthan', label: 'Rajasthan' },
                                { value: 'Sikkim', label: 'Sikkim' },
                                { value: 'Tamil Nadu', label: 'Tamil Nadu' },
                                { value: 'Telangana', label: 'Telangana' },
                                { value: 'Tripura', label: 'Tripura' },
                                { value: 'Uttarakhand', label: 'Uttarakhand' },
                                { value: 'Uttar Pradesh', label: 'Uttar Pradesh' },
                                { value: 'West Bengal', label: 'West Bengal' },
                                { value: 'Andaman and Nicobar Islands', label: 'Andaman and Nicobar Islands' },
                                { value: 'Chandigarh', label: 'Chandigarh' },
                                { value: 'Dadra and Nagar Haveli', label: 'Dadra and Nagar Haveli' },
                                { value: 'Daman and Diu', label: 'Daman and Diu' },
                                { value: 'Delhi', label: 'Delhi' },
                                { value: 'Lakshadweep', label: 'Lakshadweep' },
                                { value: 'Puducherry', label: 'Puducherry' },
                                // ... (add more cities as needed)
                            ]}
                            onChange={handleCityChange}
                            placeholder="Select City"
                        />
                    </div>
                </div>
                {loading ? <><img src="/images/loader.gif" alt="Popup Image" class="" style={{ display: "block", marginLeft: "auto", marginRight: "auto", width: "40%" }} /></> :
                    <div class="row">
                        {/* {data?.map(({ id, data }, index) => ( */}
                        {filterDataByCity().map(({ id, data, portfolioDetail }, index) => (
                            <>
                                <div class="col-md-3 mb-4">
                                    <Link to={`/${data.username}`}>
                                        <div class="card badg" data-label='☆ Star Performer' style={{ minWidth: "15rem", maxWidth: "18rem" }}>
                                            <div class="img-wrapper" style={{ maxHeight: "16rem" }}>
                                                {/* <img class="card-img-top inner-img" src={data.portfolio_image} alt="Card image cap" /> */}
                                                <img
                                                    src={portfolioDetail ? portfolioDetail.data.portfolio_image : 'https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png'}
                                                    alt="Artist"
                                                    style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                                                />
                                            </div>
                                            <div class="" style={{ padding: "12px" }}>
                                                <div class="row">
                                                    <div class="col-md-8" style={{ height: "2rem", display: "flex" }}>
                                                        <h5 class="card-title" style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', fontSize: "14px" }}>{data.parlour_name}</h5>
                                                    </div>
                                                    <div class="col-md-4" style={{ height: "1rem", display: "flex", justifyContent: "end" }}>
                                                        <p class="card-text" style={{ fontWeight: "bolder", fontSize: "12px" }}>{userAverageRatings[data.username] ? userAverageRatings[data.username].average.toFixed(1) : "0"} ⭐</p>

                                                    </div>
                                                    <div class="col-md-12" style={{ height: "2rem", display: "flex" }}>
                                                        <h5 class="card-title" style={{ fontSize: "13px", fontWeight: "400" }}><img src="/images/locationpng.png" style={{ height: "16px" }} />  {data.city}</h5>
                                                        {/* <h5 class="card-title" style={{ fontSize: "13px", fontWeight: "400" }}>📍🌎 {data.city},{data.state}</h5> */}
                                                    </div>
                                                    <div class="col-md-12" style={{ height: "2rem", display: "flex" }}>
                                                        <h5 class="card-title" style={{ fontSize: "16px", fontWeight: "700", color: "#7b809a" }}>₹ {data.price}</h5>
                                                    </div>

                                                </div>
                                            </div>
                                        </div></Link>
                                </div>
                            </>))}

                    </div>
                }
            </div>

            <FooterMain />

        </div>
    );
};








{/* <div class="container mt-3" >
<div class="row">
    <div class="col-md-3 mb-4">
        <div class="card badg" data-label='☆ Star Performer' style={{ minWidth: "15rem", maxWidth: "18rem" }}>
            <div class="img-wrapper">
                <img class="card-img-top inner-img" src="/bridal/rectangle-17@2x.png" alt="Card image cap" />
            </div>
            <div class="" style={{ padding: "12px" }}>
                <div class="row">
                    <div class="col-md-8" style={{ height: "2rem", display: "flex" }}>
                        <h5 class="card-title" style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', fontSize: "16px" }}>Neha Makeovers with a Very Long Name</h5>
                    </div>
                    <div class="col-md-4" style={{ height: "1rem", display: "flex", justifyContent: "end" }}>
                        <p class="card-text" style={{ fontWeight: "bolder" }}>4.6 ⭐</p>
                    </div>
                    <div class="col-md-12" style={{ height: "2rem", display: "flex" }}>
                        <h5 class="card-title" style={{ fontSize: "13px", fontWeight: "400" }}>Delhi</h5>
                    </div>

                </div>
            </div>
        </div>
    </div>

    <div class="col-md-3 mb-4">
        <div class="card" style={{ minWidth: "15rem", maxWidth: "18rem" }}>
            <div class="img-wrapper">
                <img class="card-img-top inner-img" src="/bridal/rectangle-14@2x.png" alt="Card image cap" />
            </div>
            <div class="" style={{ padding: "12px" }}>
                <div class="row">
                    <div class="col-md-8" style={{ height: "2rem", display: "flex" }}>
                        <h5 class="card-title" style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', fontSize: "16px" }}>Neha Makeovers with a Very Long Name</h5>
                    </div>
                    <div class="col-md-4" style={{ height: "1rem", display: "flex", justifyContent: "end" }}>
                        <p class="card-text" style={{ fontWeight: "bolder" }}>4.6 ⭐</p>
                    </div>
                    <div class="col-md-12" style={{ height: "2rem", display: "flex" }}>
                        <h5 class="card-title" style={{ fontSize: "13px", fontWeight: "400" }}>Delhi</h5>
                    </div>

                </div>
            </div>
        </div>
    </div>
    <div class="col-md-3 mb-4">
        
        <div class="card" style={{ minWidth: "15rem", maxWidth: "18rem" }}>
            <div class="img-wrapper">
                <img class="card-img-top inner-img" src="/bridal/rectangle-12@2x.png" alt="Card image cap" />
            </div>
            <div class="" style={{ padding: "12px" }}>
                <div class="row">
                    <div class="col-md-8" style={{ height: "2rem", display: "flex" }}>
                        <h5 class="card-title" style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', fontSize: "16px" }}>Neha Makeovers with a Very Long Name</h5>
                    </div>
                    <div class="col-md-4" style={{ height: "1rem", display: "flex", justifyContent: "end" }}>
                        <p class="card-text" style={{ fontWeight: "bolder" }}>4.6 ⭐</p>
                    </div>
                    <div class="col-md-12" style={{ height: "2rem", display: "flex" }}>
                        <h5 class="card-title" style={{ fontSize: "13px", fontWeight: "400" }}>Delhi</h5>
                    </div>

                </div>
            </div>
        </div>
    </div>
    <div class="col-md-3 mb-4">
        <div class="card badg" data-label='☆ Star Performer' style={{ minWidth: "15rem", maxWidth: "18rem" }}>
            <div class="img-wrapper">
                <img class="card-img-top inner-img" src="/bridal/rectangle-20@2x.png" alt="Card image cap" />
            </div>
            <div class="" style={{ padding: "12px" }}>
                <div class="row">
                    <div class="col-md-8" style={{ height: "2rem", display: "flex" }}>
                        <h5 class="card-title" style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', fontSize: "16px" }}>Neha Makeovers with a Very Long Name</h5>
                    </div>
                    <div class="col-md-4" style={{ height: "1rem", display: "flex", justifyContent: "end" }}>
                        <p class="card-text" style={{ fontWeight: "bolder" }}>4.6 ⭐</p>
                    </div>
                    <div class="col-md-12" style={{ height: "2rem", display: "flex" }}>
                        <h5 class="card-title" style={{ fontSize: "13px", fontWeight: "400" }}>Delhi</h5>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
</div>

<div class="container mt-3" >
<div class="row">
    <div class="col-md-3 mb-4">
        <div class="card badg" data-label='☆ Star Performer' style={{ minWidth: "15rem", maxWidth: "18rem" }}>
            <div class="img-wrapper">
                <img class="card-img-top inner-img" src="/bridal/rectangle-17@2x.png" alt="Card image cap" />
            </div>
            <div class="" style={{ padding: "12px" }}>
                <div class="row">
                    <div class="col-md-8" style={{ height: "2rem", display: "flex" }}>
                        <h5 class="card-title" style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', fontSize: "16px" }}>Neha Makeovers with a Very Long Name</h5>
                    </div>
                    <div class="col-md-4" style={{ height: "1rem", display: "flex", justifyContent: "end" }}>
                        <p class="card-text" style={{ fontWeight: "bolder" }}>4.6 ⭐</p>
                    </div>
                    <div class="col-md-12" style={{ height: "2rem", display: "flex" }}>
                        <h5 class="card-title" style={{ fontSize: "13px", fontWeight: "400" }}>Delhi</h5>
                    </div>

                </div>
            </div>
        </div>
    </div>

    <div class="col-md-3 mb-4">
        <div class="card" style={{ minWidth: "15rem", maxWidth: "18rem" }}>
            <div class="img-wrapper">
                <img class="card-img-top inner-img" src="/bridal/rectangle-14@2x.png" alt="Card image cap" />
            </div>
            <div class="" style={{ padding: "12px" }}>
                <div class="row">
                    <div class="col-md-8" style={{ height: "2rem", display: "flex" }}>
                        <h5 class="card-title" style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', fontSize: "16px" }}>Neha Makeovers with a Very Long Name</h5>
                    </div>
                    <div class="col-md-4" style={{ height: "1rem", display: "flex", justifyContent: "end" }}>
                        <p class="card-text" style={{ fontWeight: "bolder" }}>4.6 ⭐</p>
                    </div>
                    <div class="col-md-12" style={{ height: "2rem", display: "flex" }}>
                        <h5 class="card-title" style={{ fontSize: "13px", fontWeight: "400" }}>Delhi</h5>
                    </div>

                </div>
            </div>
        </div>
    </div>
    <div class="col-md-3 mb-4">
        
        <div class="card" style={{ minWidth: "15rem", maxWidth: "18rem" }}>
            <div class="img-wrapper">
                <img class="card-img-top inner-img" src="/bridal/rectangle-12@2x.png" alt="Card image cap" />
            </div>
            <div class="" style={{ padding: "12px" }}>
                <div class="row">
                    <div class="col-md-8" style={{ height: "2rem", display: "flex" }}>
                        <h5 class="card-title" style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', fontSize: "16px" }}>Neha Makeovers with a Very Long Name</h5>
                    </div>
                    <div class="col-md-4" style={{ height: "1rem", display: "flex", justifyContent: "end" }}>
                        <p class="card-text" style={{ fontWeight: "bolder" }}>4.6 ⭐</p>
                    </div>
                    <div class="col-md-12" style={{ height: "2rem", display: "flex" }}>
                        <h5 class="card-title" style={{ fontSize: "13px", fontWeight: "400" }}>Delhi</h5>
                    </div>

                </div>
            </div>
        </div>
    </div>
    <div class="col-md-3 mb-4">
        <div class="card badg" data-label='☆ Star Performer' style={{ minWidth: "15rem", maxWidth: "18rem" }}>
            <div class="img-wrapper">
                <img class="card-img-top inner-img" src="/bridal/rectangle-20@2x.png" alt="Card image cap" />
            </div>
            <div class="" style={{ padding: "12px" }}>
                <div class="row">
                    <div class="col-md-8" style={{ height: "2rem", display: "flex" }}>
                        <h5 class="card-title" style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', fontSize: "16px" }}>Neha Makeovers with a Very Long Name</h5>
                    </div>
                    <div class="col-md-4" style={{ height: "1rem", display: "flex", justifyContent: "end" }}>
                        <p class="card-text" style={{ fontWeight: "bolder" }}>4.6 ⭐</p>
                    </div>
                    <div class="col-md-12" style={{ height: "2rem", display: "flex" }}>
                        <h5 class="card-title" style={{ fontSize: "13px", fontWeight: "400" }}>Delhi</h5>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
</div>

<div class="container mt-3" >
<div class="row">
    <div class="col-md-3 mb-4">
        <div class="card badg" data-label='☆ Star Performer' style={{ minWidth: "15rem", maxWidth: "18rem" }}>
            <div class="img-wrapper">
                <img class="card-img-top inner-img" src="/bridal/rectangle-17@2x.png" alt="Card image cap" />
            </div>
            <div class="" style={{ padding: "12px" }}>
                <div class="row">
                    <div class="col-md-8" style={{ height: "2rem", display: "flex" }}>
                        <h5 class="card-title" style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', fontSize: "16px" }}>Neha Makeovers with a Very Long Name</h5>
                    </div>
                    <div class="col-md-4" style={{ height: "1rem", display: "flex", justifyContent: "end" }}>
                        <p class="card-text" style={{ fontWeight: "bolder" }}>4.6 ⭐</p>
                    </div>
                    <div class="col-md-12" style={{ height: "2rem", display: "flex" }}>
                        <h5 class="card-title" style={{ fontSize: "13px", fontWeight: "400" }}>Delhi</h5>
                    </div>

                </div>
            </div>
        </div>
    </div>

    <div class="col-md-3 mb-4">
        <div class="card" style={{ minWidth: "15rem", maxWidth: "18rem" }}>
            <div class="img-wrapper">
                <img class="card-img-top inner-img" src="/bridal/rectangle-14@2x.png" alt="Card image cap" />
            </div>
            <div class="" style={{ padding: "12px" }}>
                <div class="row">
                    <div class="col-md-8" style={{ height: "2rem", display: "flex" }}>
                        <h5 class="card-title" style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', fontSize: "16px" }}>Neha Makeovers with a Very Long Name</h5>
                    </div>
                    <div class="col-md-4" style={{ height: "1rem", display: "flex", justifyContent: "end" }}>
                        <p class="card-text" style={{ fontWeight: "bolder" }}>4.6 ⭐</p>
                    </div>
                    <div class="col-md-12" style={{ height: "2rem", display: "flex" }}>
                        <h5 class="card-title" style={{ fontSize: "13px", fontWeight: "400" }}>Delhi</h5>
                    </div>

                </div>
            </div>
        </div>
    </div>
    <div class="col-md-3 mb-4">
        
        <div class="card" style={{ minWidth: "15rem", maxWidth: "18rem" }}>
            <div class="img-wrapper">
                <img class="card-img-top inner-img" src="/bridal/rectangle-12@2x.png" alt="Card image cap" />
            </div>
            <div class="" style={{ padding: "12px" }}>
                <div class="row">
                    <div class="col-md-8" style={{ height: "2rem", display: "flex" }}>
                        <h5 class="card-title" style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', fontSize: "16px" }}>Neha Makeovers with a Very Long Name</h5>
                    </div>
                    <div class="col-md-4" style={{ height: "1rem", display: "flex", justifyContent: "end" }}>
                        <p class="card-text" style={{ fontWeight: "bolder" }}>4.6 ⭐</p>
                    </div>
                    <div class="col-md-12" style={{ height: "2rem", display: "flex" }}>
                        <h5 class="card-title" style={{ fontSize: "13px", fontWeight: "400" }}>Delhi</h5>
                    </div>

                </div>
            </div>
        </div>
    </div>
    <div class="col-md-3 mb-4">
        <div class="card badg" data-label='☆ Star Performer' style={{ minWidth: "15rem", maxWidth: "18rem" }}>
            <div class="img-wrapper">
                <img class="card-img-top inner-img" src="/bridal/rectangle-20@2x.png" alt="Card image cap" />
            </div>
            <div class="" style={{ padding: "12px" }}>
                <div class="row">
                    <div class="col-md-8" style={{ height: "2rem", display: "flex" }}>
                        <h5 class="card-title" style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', fontSize: "16px" }}>Neha Makeovers with a Very Long Name</h5>
                    </div>
                    <div class="col-md-4" style={{ height: "1rem", display: "flex", justifyContent: "end" }}>
                        <p class="card-text" style={{ fontWeight: "bolder" }}>4.6 ⭐</p>
                    </div>
                    <div class="col-md-12" style={{ height: "2rem", display: "flex" }}>
                        <h5 class="card-title" style={{ fontSize: "13px", fontWeight: "400" }}>Delhi</h5>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
</div> */}