import React, { useState, useEffect } from "react";
import { useNavigate, Link, useParams } from "react-router-dom"
import db from "./Firebase";
import axios from "axios";
import Footer from "./Footer";
import Swal from 'sweetalert2'
import { firebase, auth } from './FirebaseLogin';
import Moment from 'moment';
import { extendMoment } from 'moment-range';

function AllServices() {

    let params = useParams();
    const navigate = useNavigate();
    const [personalData, setPersonalData] = useState({});
    const [showNum, setshowNum] = useState(true);
    const [showOtpBox, setshowOtpBox] = useState(false);
    const [showMessage, setshowMessage] = useState(false);
    const [name, setName] = useState('');
    const [mynumber, setnumber] = useState("");
    const [otp, setotp] = useState('');
    const [finalShow, setFinalShow] = useState(false);
    const [final, setfinal] = useState('');
    const [show, setshow] = useState(false);
    const moment = extendMoment(Moment);

    const [topping, setTopping] = useState("no")

    const onOptionChange = e => {
        setTopping(e.target.value)
        console.log(topping)
    }


    useEffect(() => {
        db.collection("portfolio_details").where('username', '==', params.id.toLowerCase()).onSnapshot((snapshot) => {
            snapshot.docs.map(e => setPersonalData(e.data()))
            if (snapshot.docs.length == 0) {
                navigate('/error');
            } else {
                // alert("exists")
                setIsLoading(false);
            }
        });
    }, []);

    const changeStatus = () => {
        openModalShow();
    }

    const openModalShow = () => {
        // alert("ooo")
        window.openModelshow();
    }

    const handleChange = (e) => {
        setnumber(e.target.value);
    }

    const ValidateOtp = () => {
        if (otp === null || final === null)
            return;
        final.confirm(otp).then((result) => {
            // alert("OTP validation Successful");
            db.collection("service_enquiry").add({
                'name': name,
                'phone': mynumber,
                'created_date': new Date(),
            }).then(() => {
                console.log('Requested successfull');
                const Toast = Swal.mixin({
                    toast: true,
                    background: '#69aba6',
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer)
                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                    }
                })

                Toast.fire({
                    icon: 'success',
                    title: 'Thank you for your interest ! We will get in touch with you'
                })
            });
            console.log(mynumber)
            setFinalShow(true);
            setshowOtpBox(false);
            setshowMessage(true);

            // success
        }).catch((err) => {
            alert("Wrong code");
        })
    }

    const signin = () => {

        if (mynumber === "" || name == "" || mynumber.length < 10) {
            alert("please enter name and valid phone number.");
        } else {
            let verify = new firebase.auth.RecaptchaVerifier('recaptcha-container');
            auth.signInWithPhoneNumber(`+91${mynumber}`, verify).then((result) => {
                setfinal(result);
                alert("code sent")
                setshow(true);
                setFinalShow(true);
                setshowNum(false);
                setshowOtpBox(true);
                db.collection("customer_data").add({ 'name': name, 'email': '', 'phone_no': mynumber, 'type': "book_now", 'additiona': params.id, 'created_date': new Date(), }).then(() => {
                    console.log("data added");
                });
            })
                .catch((err) => {
                    alert(err);
                    window.location.reload()
                });
        }
    }

    const [service_name, setService_name] = useState('');
    const [service_category, setService_category] = useState('');
    const [serviceName, setServiceName] = useState('');
    const [serviceCategory, setServiceCategory] = useState('');
    const [amount, setAmount] = useState('');
    const [showEnquiry, setShowEnquiry] = useState(false);
    const [showAp, setShowAp] = useState(false);


    const initialFormStateAppointment = { start_date_time: '', charges: '', description: '', address: '', willing_to_travel: '', name: '', parlour: '', payment_method: '', payment_mode: '', phone: '', service_name: '', service_category: '', status: '', time: '', uid: '', username: '', date: new Date() }
    const [submitAppointment, setSubmitAppointment] = useState(initialFormStateAppointment);


    const initialFormStateEnquiry = { name: '', phone_no: '', message: '', service: '', username: '', created_date: new Date() }
    const [submitEnquiry, setSubmitEnquiry] = useState(initialFormStateEnquiry);

    const handleChangeAp = (e) => {
        const { id, value, type, checked } = e.target;

        if (type === "radio") {
            // Handle radio input
            setSubmitAppointment({
                ...submitAppointment,
                [id]: value === true, // Convert the string value to a boolean
            });
        } else {
            setSubmitAppointment({
                ...submitAppointment,
                [id]: value, // Handle other input types
            });
        }

    }



    const enquiryStatus = (name) => {
        setShowAp(false);
        setShowEnquiry(true);
        setServiceName(name)
        openModalShow();
        setSubmitEnquiry({ name: '', phone_no: '', message: `Hey ${personalData.first_name},\nStumbled upon your details on the Youvati website – your profile looks great! 😄 I'm interested in learning more about your services. Could you provide me with more information?\n\nThanks,`, service: '', username: '', created_date: new Date() })
        // alert(name);
    }

    const appointmentStatus = (name, cat, amt) => {
        setShowEnquiry(false);
        setShowAp(true);
        setServiceName(name)
        setServiceCategory(cat)
        setAmount(amt)
        openModalShow();
        // alert(name);
    }

    const handleSubmitAp = (e) => {
        console.log(serviceCategory)
        console.log(serviceName)
        console.log(amount)
        e.preventDefault();
        console.log(submitAppointment)
        console.log(topping)
        console.log(amount)
        const startDateTime = new Date(submitAppointment.start_date_time); // Convert to JavaScript Date object

        const formattedTime = moment(startDateTime).format("h:mm A");


        db.collection("appointments_data").add({
            'booked_date': new Date(submitAppointment.start_date_time),
            'charges': parseInt(amount),
            'description': '',
            'name': submitAppointment.name,
            'parlour': personalData.parlour_name,
            'payment_method': '',
            'payment_mode': '',
            'phone': submitAppointment.phone,
            'service_name': serviceCategory,
            'service_category': serviceName,
            'status': '0',
            'time': formattedTime,
            'type': 'Website',
            'uid': '',
            'username': params.id,
            'date': new Date(),
            'address': topping === "no" ? personalData.parlour_name : submitAppointment.address,
            'willing_to_travel': topping,
            'updated_date': new Date(),
            'rescheduled': "no",
        }).then(() => {
            console.log('Submitted Successfully');
            sendWhatsappBooking(submitAppointment.phone, submitAppointment.name, personalData.parlour_name)
            setShowEnquiry(false);
            const Toast = Swal.mixin({
                toast: true,
                background: 'white',
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            });

            Toast.fire({
                icon: 'success',
                title: 'Appointment Requested Successfully'
            });

            setshow(true);
            closeModel();
            setSubmitAppointment({ start_date_time: '', charges: '', description: '', name: '', parlour: '', payment_method: '', payment_mode: '', phone: '', service_name: '', service_category: '', status: '', time: '', uid: '', username: '', date: new Date() });
        });
    };

    const handleChangeE = (e) => {
        const { id, value } = e.target
        setSubmitEnquiry({ ...submitEnquiry, [id]: value })
    }

    const notifyApp = (msg) => {
        // Call the user's CollectionReference to add a new user
        db.collection("app_notification").add({ 'message': msg, 'link': "Enquiries", 'status': "0", "uid": personalData.uid, 'created_date': new Date() }).then(() => {
            console.log("user added");
        })
    }

    const handleSubmitE = (e, msg) => {
        e.preventDefault()
        console.log(submitEnquiry);
        db.collection("enquiry").add({
            'name': submitEnquiry.name,
            'phone_no': submitEnquiry.phone_no,
            'message': submitEnquiry.message,
            'service': serviceName,
            'username': params.id,
            'status': '0',
            'created_date': new Date(),
        }).then(() => {
            sendWhatsappEnquiry(submitEnquiry.phone_no, submitEnquiry.name, personalData.parlour_name)
            db.collection("customer_data").add({ 'name': submitEnquiry.name, 'email': '', 'phone_no': submitEnquiry.phone_no, 'type': "enquiry", 'additional': params.id, 'created_date': new Date(), }).then(() => {
                console.log("data added");
            });
            notifyApp(msg = submitEnquiry.message);
            console.log('Submitted Successfully');
            setShowEnquiry(false);
            const Toast = Swal.mixin({
                toast: true,
                background: 'white',
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            })

            Toast.fire({
                icon: 'success',
                title: 'Submitted Successfully'
            })
            setshow(true)
            closeModel();
            // navigate(`/${submit.username}`);
            setSubmitEnquiry({ name: '', phone_no: '', message: '', service: '', username: '', created_date: new Date() })
        })
    }

    const closeModel = () => {
        window.openModelNew();
    }


    const sendWhatsappBooking = (phone, name, parlour) => {

        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer EABMKlZCrATXIBAPOZBZBPqsdfot34fq0FTipZBQf3RiXZAapyCOqMay09HfhSxoB8bd5ZA7RUnLWhd0JkXtACJQ682THiDEMhQhyPOkeui8Cb8P72WPDhihUZBCkHtPLSgsmuZA6iRY6VunDDIjPA5mATQQITVXOECHqkZB5E2pzWSC2AaUp0wZCWR");
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "messaging_product": "whatsapp",
            "to": `91${phone}`,
            "type": "template",
            "template": {
                "name": "booking_recived",
                "language": {
                    "code": "en_US",
                    "policy": "deterministic"
                },
                "components": [
                    {
                        "type": "body",
                        "parameters": [
                            {
                                "type": "text",
                                "text": name
                            },
                            {
                                "type": "text",
                                "text": parlour
                            }
                        ]
                    }
                ]
            }
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch("https://graph.facebook.com/v14.0/104489945783566/messages", requestOptions)
            .then(response => response.text())
            .then(result => console.log(result))
            .catch(error => console.log('error', error));

    }


    const sendWhatsappEnquiry = (phone, name, parlour) => {

        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer EABMKlZCrATXIBAPOZBZBPqsdfot34fq0FTipZBQf3RiXZAapyCOqMay09HfhSxoB8bd5ZA7RUnLWhd0JkXtACJQ682THiDEMhQhyPOkeui8Cb8P72WPDhihUZBCkHtPLSgsmuZA6iRY6VunDDIjPA5mATQQITVXOECHqkZB5E2pzWSC2AaUp0wZCWR");
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "messaging_product": "whatsapp",
            "to": `91${phone}`,
            "type": "template",
            "template": {
                "name": "enquiry_recived",
                "language": {
                    "code": "en_US",
                    "policy": "deterministic"
                },
                "components": [
                    {
                        "type": "body",
                        "parameters": [
                            {
                                "type": "text",
                                "text": name
                            },
                            {
                                "type": "text",
                                "text": parlour
                            }
                        ]
                    }
                ]
            }
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch("https://graph.facebook.com/v14.0/104489945783566/messages", requestOptions)
            .then(response => response.text())
            .then(result => console.log(result))
            .catch(error => console.log('error', error));

    }

    const [currentDate, setCurrentDate] = useState('');


    const [isLoading, setIsLoading] = useState(true)
    const [ind, setInd] = useState(null);

    const [selectedServiceType, setSelectedServiceType] = useState("all");

    const filterDataByServiceType = () => {
        if (selectedServiceType === "all") {
            return personalData.services;
        } else {
            return personalData.services.filter(e => e.type === selectedServiceType);
        }
    }

    const [showOnlyTen, setShowOnlyTen] = useState(true);
    const [visibleReviews, setVisibleReviews] = useState(10); // Initially, display 10 reviews

    // Function to toggle the "Show Only 10 Reviews Every Time" option
    const toggleShowOnlyTen = () => {
        setShowOnlyTen(!showOnlyTen);
        setVisibleReviews(10); // Reset visible reviews when toggling
    };

    // Function to show next 10 reviews
    const showNextReviews = () => {
        setVisibleReviews((prevVisible) => prevVisible + 10);
    };

    const showLoadMoreButton = personalData.services?.length > visibleReviews;

    return (
        <>
            <div>
                <section>
                    <div class="navi" >
                        <input type="checkbox" id="nav-check" />
                        <div class="nav-header">
                            <div class="nav-title">
                                <Link to={`/${params.id.toLowerCase()}`} > <img src="/images/youvati_logo.png" alt="" style={{ height: "27px", marginLeft: "2rem" }} /></Link>
                            </div>
                        </div>


                    </div>
                </section>

                {/* <div>
                    <button onClick={() => setSelectedServiceType("all")}>All</button>
                    <button onClick={() => setSelectedServiceType("Make Up")}>Make Up</button>
                    <button onClick={() => setSelectedServiceType("Skin")}>Skin</button>
                    <button onClick={() => setSelectedServiceType("Hair")}>Hair</button>
                    <button onClick={() => setSelectedServiceType("Nails")}>Nails</button>
                    <button onClick={() => setSelectedServiceType("Others")}>Others</button>
                </div> */}

                {/* <div class="nav-links bb" style={{ display: "flex", justifyContent: "center" }}>
                    <a class="linkcolor mr-4">Categories : </a>
                    <a href="#" style={{ cursor: 'pointer', color: selectedServiceType === "all" ? '#f5505d' : 'black' }} class="mr-4" onClick={() => setSelectedServiceType("all")}>All</a>
                    <span class="line mr-4">|</span>
                    <a href="#" style={{ cursor: 'pointer', color: selectedServiceType === "Make Up" ? '#f5505d' : 'black' }} class="mr-4" onClick={() => setSelectedServiceType("Make Up")}>Make Up</a>
                    <span class="line mr-4">|</span>
                    <a href="#" style={{ cursor: 'pointer', color: selectedServiceType === "Skin" ? '#f5505d' : 'black' }} class="mr-4" onClick={() => setSelectedServiceType("Skin")}>Skin</a>
                    <span class="line mr-4" >|</span>
                    <a href="#" style={{ cursor: 'pointer', color: selectedServiceType === "Hair" ? '#f5505d' : 'black' }} class="mr-4" onClick={() => setSelectedServiceType("Hair")}>Hair</a>
                    <span class="line mr-4">|</span>
                    <a href="#" style={{ cursor: 'pointer', color: selectedServiceType === "Nails" ? '#f5505d' : 'black' }} class="mr-4" onClick={() => setSelectedServiceType("Nails")}>Nails</a>
                    <span class="line mr-4">|</span>
                    <a href="#" style={{ cursor: 'pointer', color: selectedServiceType === "Others" ? '#f5505d' : 'black' }} class="mr-4" onClick={() => setSelectedServiceType("Others")}>Others</a>

                </div> */}
                {/* <div class="nav-links bb" style={{ display: "flex", flexWrap: "wrap", justifyContent: "center", alignItems: "center", textAlign: "center", }}>
                    <a class="linkcolor mb-2">Categories : </a>
                    <a href="#" style={{ cursor: "pointer", color: selectedServiceType === 'all' ? '#f5505d' : 'black' }} class="mb-2 mr-2" onClick={() => setSelectedServiceType("all")}>All</a>
                    <span class="line mr-2 ml-2 mb-2">|</span>
                    <a href="#" style={{ cursor: "pointer", color: selectedServiceType === 'Make Up' ? '#f5505d' : 'black' }} class="mb-2 mr-2" onClick={() => setSelectedServiceType("Make Up")}>Make Up</a>
                    <span class="line mr-2 ml-2 mb-2">|</span>
                    <a href="#" style={{ cursor: "pointer", color: selectedServiceType === 'Skin' ? '#f5505d' : 'black' }} class="mb-2 mr-2" onClick={() => setSelectedServiceType("Skin")}>Skin</a>
                    <span class="line mr-2 ml-2 mb-2">|</span>
                    <a href="#" style={{ cursor: "pointer", color: selectedServiceType === 'Hair' ? '#f5505d' : 'black' }} class="mb-2 mr-2" onClick={() => setSelectedServiceType("Hair")}>Hair</a>
                    <span class="line mr-2 ml-2 mb-2">|</span>
                    <a href="#" style={{ cursor: "pointer", color: selectedServiceType === 'Nails' ? '#f5505d' : 'black' }} class="mb-2 mr-2" onClick={() => setSelectedServiceType("Nails")}>Nails</a>
                    <span class="line mr-2 ml-2 mb-2">|</span>
                    <a href="#" style={{ cursor: "pointer", color: selectedServiceType === 'Others' ? '#f5505d' : 'black' }} class="mb-2 mr-2" onClick={() => setSelectedServiceType("Others")}>Others</a>
                </div> */}

                <div class="col smenu">
                    <a class="linkcolor mb-2">Categories : </a>
                    <a href="#" style={{ cursor: "pointer", color: selectedServiceType === 'all' ? '#f5505d' : 'black' }} class="mb-2 mr-2" onClick={() => setSelectedServiceType("all")}>All</a>
                    <a href="#" style={{ cursor: "pointer", color: selectedServiceType === 'Bridal' ? '#f5505d' : 'black' }} class="mb-2 mr-2" onClick={() => setSelectedServiceType("Bridal")}>Bridal</a>
                    <a href="#" style={{ cursor: "pointer", color: selectedServiceType === 'Make Up' ? '#f5505d' : 'black' }} class="mb-2 mr-2" onClick={() => setSelectedServiceType("Make Up")}>Make Up</a>
                    <a href="#" style={{ cursor: "pointer", color: selectedServiceType === 'Skin' ? '#f5505d' : 'black' }} class="mb-2 mr-2" onClick={() => setSelectedServiceType("Skin")}>Skin</a>
                    <a href="#" style={{ cursor: "pointer", color: selectedServiceType === 'Hair' ? '#f5505d' : 'black' }} class="mb-2 mr-2" onClick={() => setSelectedServiceType("Hair")}>Hair</a>
                    <a href="#" style={{ cursor: "pointer", color: selectedServiceType === 'Nails' ? '#f5505d' : 'black' }} class="mb-2 mr-2" onClick={() => setSelectedServiceType("Nails")}>Nails</a>
                    <a href="#" style={{ cursor: "pointer", color: selectedServiceType === 'Others' ? '#f5505d' : 'black' }} class="mb-2 mr-2" onClick={() => setSelectedServiceType("Others")}>Others</a>
                </div>

                {isLoading ? <div>
                    <img src="/images/loader.gif" alt="Popup Image" class="" style={{ display: "block", marginLeft: "auto", marginRight: "auto", width: "40%" }} />
                </div> : <>
                    <section class="" >
                        <div class="container">
                            <div class="grid-container grido" style={{}}>
                                {/* {personalData.services.map((e, index) => ( */}
                                {filterDataByServiceType()?.slice(showOnlyTen ? 0 : visibleReviews - 10, visibleReviews)?.map((e, index) => (
                                    <div key={index} class="" style={{
                                        height: "auto",
                                        borderStyle: "solid", borderWidth: "3px",
                                        borderImage: "linear-gradient(45deg, rgb(252,79,135), rgb(255,242,86)) 1"
                                    }}>
                                        <img src={e.image} class="img-fluid" style={{ width: "100%", height: "308px", objectFit: "cover" }} />
                                        <div class="p-3" style={{ color: "white", backgroundColor: "#e92b91", textAlign: "center", fontSize: "15px", fontWeight: "600", textTransform: "capitalize" }}><span>{e.display_name}</span></div>
                                        <div class="p-2" style={{ textAlign: "start" }}>
                                            <div style={{ height: "5.5rem" }}><p class="card-title" style={{
                                                fontSize: "11px", color: "black", textAlign: "justify", fontWeight: "400", overflow: "hidden",
                                                textOverflow: "ellipsis", display: "-webkit-box", webkitLineClamp: "3", lineClamp: "2", webkitBoxOrient: "vertical"
                                            }}>{e.details}</p></div>
                                        </div>
                                        <div class="container mb-4">
                                            <div class="row">
                                                <div class="col-sm-4" style={{ textAlign: "center" }}>
                                                    <span style={{ fontSize: "10px", fontWeight: "600", color: "black" }}>INR {e.amount}/-</span>
                                                </div>
                                                <div class="col-sm-4" style={{ textAlign: "center" }} onClick={() => enquiryStatus(e.display_name)} >
                                                    <span style={{ fontSize: "10px", fontWeight: "600", color: "black", cursor: "pointer" }}>Raise Enquiry</span>
                                                </div>
                                                <div class="col-sm-4" style={{ textAlign: "center" }} onClick={() => appointmentStatus(e.display_name, e.type, e.amount)} >
                                                    <span style={{ font: "bold 10px/1.5 Roboto, sans-serif", color: "#fe8e4e", cursor: "pointer" }}>Book Now</span>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                ))
                                }
                            </div>
                        </div>

                        <div class="" >
                            <div class="row" style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}>

                                {filterDataByServiceType().length == 0 ? <div class="row" style={{ textAlign: "center" }}>
                                    <h1 style={{ color: "grey", fontSize: "28px", padding: "48px" }}>No Services Found!</h1>
                                </div> : ""}
                            </div>
                        </div>


                    </section >
                </>}
            </div>

            {filterDataByServiceType()?.length === 0 ? <></> : <>
                <div class="row mt-3" style={{ display: "flex", textAlign: "center" }}>
                    {showLoadMoreButton ? (
                        <p style={{ fontSize: "small", fontWeight: "700", cursor: "pointer", color: "#ec7b3c" }} onClick={showNextReviews}>---- Load More ----</p>
                    ) : (
                        <p style={{ fontSize: "small", fontWeight: "700", color: "#ec7b3c" }}>--- No more Services ---</p>
                    )}
                </div>
            </>}

            <div class="footer-04">
                <div class="container">
                    <div class="row">
                        <div class="col-md-6">
                            <ul style={{ paddingLeft: "3rem", paddingTop: "2rem", }}>
                                <Link to="/" onClick={() => window.scrollTo(0, 0)}><li style={{ color: "white" }}>Home</li></Link>
                                <Link to="/aboutus"><li style={{ color: "white" }}>About Us</li></Link>
                                <Link to="/privacy_policy"><li style={{ color: "white" }}>Privacy Policy</li></Link>
                                <Link to="/contactus"><li style={{ color: "white" }}>Contact Us</li></Link>
                                <li><img style={{ width: "9rem", paddingTop: "1rem" }} alt="playstore" src="https://b.zmtcdn.com/data/webuikit/23e930757c3df49840c482a8638bf5c31556001144.png" loading="lazy" class="sc-s1isp7-5 fyZwWD" /></li>
                            </ul>
                        </div>
                        <div class="col-md-6 pt-3">
                            <div class="wrapper">
                                <div class=""> </div>
                                <ul class="ftco-footer-social p-0 pt-4 mr-4" style={{ display: "flex", justifyContent: "center" }}>
                                    <a href="https://www.facebook.com/youvatiofficial?mibextid=ZbWKwL" target="_blank"><img src="/images/facebookk.png" style={{ height: "30px", paddingRight: "10px" }}></img></a>
                                    <a href="https://api.whatsapp.com/send?phone=7428790444" target="_blank"><img src="/images/whatsappp.png" style={{ height: "30px", paddingRight: "10px" }}></img></a>
                                    <a href="https://instagram.com/youvatiofficial?igshid=MzRlODBiNWFlZA==" target="_blank"><img src="/images/insta.png" style={{ height: "30px", paddingRight: "10px" }}></img></a>
                                    <a href="https://twitter.com/Youvatiofficial?s=20" target="_blank"><img src="/images/twitter.png" style={{ height: "30px", paddingRight: "10px" }}></img></a>
                                    <a href="https://www.linkedin.com/company/youvati/ " target="_blank"><img src="/images/linkedin.png" style={{ height: "30px", paddingRight: "10px" }}></img></a>
                                </ul>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="" style={{ textAlign: "center", background: "#272727", color: "white", paddingBottom: "1rem" }}>Powred By ~ Youvati</div>
            </div>


            <div class="col-md-12">
                <div class="myId modal fade" id="" tabindex="-1" role="dialog" aria-labelledby="modal-form" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered modal-md" role="document">
                        <div class="modal-content">
                            <div class="modal-body">

                                {showEnquiry ? <>
                                    <div class="">
                                        <form class="" id="" onSubmit={handleSubmitE}>
                                            <div class="">
                                                <div class="" style={{ textAlign: "center", }}><span style={{ color: "black", fontSize: "25px", fontWeight: "700", textTransform: "uppercase" }}>Raise Enquiry</span></div>
                                                <div class="" style={{ textAlign: "center", color: "black", paddingTop: "10px" }}>
                                                </div>
                                                <div class="">
                                                    <div class="row">
                                                        <div class="col-md-6">
                                                            <input style={{
                                                                // width: "17rem",
                                                                height: "45px",
                                                                padding: "12px 20px",
                                                                boxSizing: "border-box",
                                                                border: "2px solid #ccc",
                                                                borderRadius: "4px",
                                                                backgroundColor: "#f8f8f8",
                                                                fontSize: "16px",
                                                                resize: "none"
                                                            }} class="w3l_text_box" id="name" onChange={handleChangeE} value={submitEnquiry.name} placeholder="Full Name" name="Full Name" type="text" required />
                                                        </div>

                                                        <div class="col-md-6">
                                                            <input style={{
                                                                // width: "17rem",
                                                                height: "45px",
                                                                padding: "12px 20px",
                                                                boxSizing: "border-box",
                                                                border: "2px solid #ccc",
                                                                borderRadius: "4px",
                                                                backgroundColor: "#f8f8f8",
                                                                fontSize: "16px",
                                                                resize: "none"
                                                            }} class="w3l_text_box" id="phone_no" onChange={handleChangeE} value={submitEnquiry.phone_no} placeholder="Phone Number" name="Phone Number" type="number" required />
                                                        </div>

                                                    </div>
                                                </div>
                                                <div class="">
                                                    <div class="row">
                                                        <div class="col-md-12">
                                                            <input style={{
                                                                // width: "17rem",
                                                                height: "45px",
                                                                padding: "12px 20px",
                                                                boxSizing: "border-box",
                                                                border: "2px solid #ccc",
                                                                borderRadius: "4px",
                                                                backgroundColor: "#f8f8f8",
                                                                fontSize: "16px",
                                                                resize: "none"
                                                            }} class="w3l_text_box" value={serviceName} placeholder="Service Name" name="Service Name" type="text" required />
                                                        </div>


                                                    </div>
                                                </div>
                                                <textarea style={{
                                                    width: "100%",
                                                    height: "15px",
                                                    padding: "12px 20px",
                                                    boxSizing: "border-box",
                                                    border: "2px solid #ccc",
                                                    borderRadius: "4px",
                                                    backgroundColor: "#f8f8f8",
                                                    fontSize: "16px",
                                                    resize: "none"
                                                }} class="w3l_text_box" id="message" onChange={handleChangeE} value={submitEnquiry.message} placeholder="Write a enquiry..." name="Enquiry" required />



                                                <div class="modal-footer">
                                                    <a class="btn bg-gradient-info btn-sm mb-0" style={{ color: "white" }} onClick={() => closeModel()}>Close</a>
                                                    {/* <a class="btn bg-gradient-success btn-sm mb-0" style={{ color: "white" }}>Submit</a> */}
                                                    <button type='submit' class="btn bg-gradient-danger btn-sm mb-0" style={{ backgroundColor: "green", color: "white" }}>Submit</button>
                                                </div>


                                            </div>
                                        </form>
                                    </div>
                                </> : <></>}
                                {showAp ? <>
                                    <div class="">
                                        <form class="" id="" onSubmit={handleSubmitAp}>
                                            <div class="">
                                                <div class="" style={{ textAlign: "center", }}><span style={{ color: "black", fontSize: "25px", fontWeight: "700", textTransform: "uppercase" }}>Appointment Booking</span></div>
                                                <div class="" style={{ textAlign: "center", color: "black", paddingTop: "10px" }}>
                                                </div>
                                                <div class="">
                                                    <div class="row">
                                                        <div class="col-md-6">
                                                            <input style={{
                                                                // width: "17rem",
                                                                height: "45px",
                                                                padding: "12px 20px",
                                                                boxSizing: "border-box",
                                                                border: "2px solid #ccc",
                                                                borderRadius: "4px",
                                                                backgroundColor: "#f8f8f8",
                                                                fontSize: "16px",
                                                                resize: "none"
                                                            }} class="w3l_text_box" id="name" onChange={handleChangeAp} value={submitAppointment.name} placeholder="Full Name" name="Full Name" type="text" required />
                                                        </div>

                                                        <div class="col-md-6">
                                                            <input style={{
                                                                // width: "17rem",
                                                                height: "45px",
                                                                padding: "12px 20px",
                                                                boxSizing: "border-box",
                                                                border: "2px solid #ccc",
                                                                borderRadius: "4px",
                                                                backgroundColor: "#f8f8f8",
                                                                fontSize: "16px",
                                                                resize: "none"
                                                            }} class="w3l_text_box" id="phone" onChange={handleChangeAp} value={submitAppointment.phone} placeholder="Phone Number" name="Phone Number" type="number" required />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="">
                                                    <div class="row">
                                                        <div class="col-md-6">
                                                            <li>
                                                                <label style={{ display: "flex" }}>Service Name</label>
                                                                <input style={{
                                                                    // width: "17rem",
                                                                    height: "45px",
                                                                    padding: "12px 20px",
                                                                    boxSizing: "border-box",
                                                                    border: "2px solid #ccc",
                                                                    borderRadius: "4px",
                                                                    backgroundColor: "#f8f8f8",
                                                                    fontSize: "16px",
                                                                    resize: "none"
                                                                }} class="w3l_text_box" value={serviceName} placeholder="Service Name" name="Service Name" type="text" required />
                                                            </li>

                                                        </div>

                                                        <div class="col-md-6">
                                                            <li>
                                                                <label style={{ display: "flex" }}>Start Datetime</label>
                                                                <input type="datetime-local" id="start_date_time" min={currentDate} onChange={handleChangeAp} value={submitAppointment.start_date_time} class="form-control" required />
                                                            </li>
                                                        </div>


                                                    </div>
                                                </div>


                                                <textarea style={{
                                                    width: "100%",
                                                    height: "15px",
                                                    padding: "12px 20px",
                                                    boxSizing: "border-box",
                                                    border: "2px solid #ccc",
                                                    borderRadius: "4px",
                                                    backgroundColor: "#f8f8f8",
                                                    fontSize: "16px",
                                                    resize: "none"
                                                }} class="w3l_text_box" id="description" onChange={handleChangeAp} value={submitAppointment.description} placeholder="Write a description..." name="Description" />

                                                <div class="">
                                                    <div class="col-md-6">
                                                        <div class="form-check">
                                                            <input
                                                                type="radio"
                                                                class="form-check-input"
                                                                id="in_parlour"
                                                                name="topping"
                                                                value="no"
                                                                checked={topping === "no"}
                                                                style={{ display: "block", position: 'absolute' }}
                                                                onChange={onOptionChange}
                                                            />

                                                            <label class="form-check-label" for="checkbox1">
                                                                In Parlour
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-check">
                                                            <input
                                                                type="radio"
                                                                class="form-check-input"
                                                                id="outstation"
                                                                name="topping"
                                                                value="yes"
                                                                onChange={onOptionChange}
                                                                style={{ display: "block", position: 'absolute' }}
                                                            />

                                                            <label class="form-check-label" for="checkbox2">
                                                                At Home/Outstation
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>

                                                {topping === "yes" ? <>    <textarea style={{
                                                    width: "100%",
                                                    height: "5px",
                                                    padding: "12px 20px",
                                                    boxSizing: "border-box",
                                                    border: "2px solid #ccc",
                                                    borderRadius: "4px",
                                                    backgroundColor: "#f8f8f8",
                                                    fontSize: "16px",
                                                    resize: "none",
                                                    minHeight: "59px",
                                                }} class="w3l_text_box" id="address" onChange={handleChangeAp} value={submitAppointment.address} placeholder="Address......." name="address" />
                                                </>
                                                    :
                                                    <></>
                                                }


                                                <div class="modal-footer">
                                                    <a class="btn bg-gradient-info btn-sm mb-0" style={{ color: "white" }} onClick={() => closeModel()}>Close</a>
                                                    {/* <a class="btn bg-gradient-success btn-sm mb-0" style={{ color: "white" }}>Submit</a> */}
                                                    <button type='submit' class="btn bg-gradient-danger btn-sm mb-0" style={{ backgroundColor: "green", color: "white" }}>Submit</button>
                                                </div>


                                            </div>
                                        </form>
                                    </div>
                                </> : <></>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default AllServices;






// import React, { useState, useEffect } from "react";
// import { useNavigate, Link, useParams } from "react-router-dom"
// import db from "./Firebase";
// import axios from "axios";
// // import { firebase, auth } from './FirebaseLogin';
// import Footer from "./Footer";
// import Swal from 'sweetalert2'
// import { firebase, auth } from './FirebaseLogin';
// import Moment from 'moment';
// import { extendMoment } from 'moment-range';

// function AllServices() {
//     let params = useParams();
//     const navigate = useNavigate();
//     const [personalData, setPersonalData] = useState({});
//     const [showNum, setshowNum] = useState(true);
//     const [showOtpBox, setshowOtpBox] = useState(false);
//     const [showMessage, setshowMessage] = useState(false);
//     const [name, setName] = useState('');
//     const [mynumber, setnumber] = useState("");
//     const [otp, setotp] = useState('');
//     const [finalShow, setFinalShow] = useState(false);
//     const [final, setfinal] = useState('');
//     const [show, setshow] = useState(false);
//     const moment = extendMoment(Moment);

//     const [topping, setTopping] = useState("no")

//     const onOptionChange = e => {
//         setTopping(e.target.value)
//         console.log(topping)
//     }


//     useEffect(() => {
//         db.collection("portfolio_details").where('username', '==', params.id.toLowerCase()).onSnapshot((snapshot) => {
//             snapshot.docs.map(e => setPersonalData(e.data()))
//             if (snapshot.docs.length == 0) {
//                 navigate('/error');
//             } else {
//                 // alert("exists")
//                 setIsLoading(false);
//             }
//         });
//     }, []);

//     const changeStatus = () => {
//         openModalShow();
//     }

//     const openModalShow = () => {
//         // alert("ooo")
//         window.openModelshow();
//     }

//     const handleChange = (e) => {
//         setnumber(e.target.value);
//     }

//     const ValidateOtp = () => {
//         if (otp === null || final === null)
//             return;
//         final.confirm(otp).then((result) => {
//             // alert("OTP validation Successful");
//             db.collection("service_enquiry").add({
//                 'name': name,
//                 'phone': mynumber,
//                 'created_date': new Date(),
//             }).then(() => {
//                 console.log('Requested successfull');
//                 const Toast = Swal.mixin({
//                     toast: true,
//                     background: '#69aba6',
//                     position: 'top-end',
//                     showConfirmButton: false,
//                     timer: 3000,
//                     timerProgressBar: true,
//                     didOpen: (toast) => {
//                         toast.addEventListener('mouseenter', Swal.stopTimer)
//                         toast.addEventListener('mouseleave', Swal.resumeTimer)
//                     }
//                 })

//                 Toast.fire({
//                     icon: 'success',
//                     title: 'Thank you for your interest ! We will get in touch with you'
//                 })
//             });
//             console.log(mynumber)
//             setFinalShow(true);
//             setshowOtpBox(false);
//             setshowMessage(true);

//             // success
//         }).catch((err) => {
//             alert("Wrong code");
//         })
//     }

//     const signin = () => {

//         if (mynumber === "" || name == "" || mynumber.length < 10) {
//             alert("please enter name and valid phone number.");
//         } else {
//             let verify = new firebase.auth.RecaptchaVerifier('recaptcha-container');
//             auth.signInWithPhoneNumber(`+91${mynumber}`, verify).then((result) => {
//                 setfinal(result);
//                 alert("code sent")
//                 setshow(true);
//                 setFinalShow(true);
//                 setshowNum(false);
//                 setshowOtpBox(true);
//                 db.collection("customer_data").add({ 'name': name, 'email': '', 'phone_no': mynumber, 'type': "book_now", 'additiona': params.id, 'created_date': new Date(), }).then(() => {
//                     console.log("data added");
//                 });
//             })
//                 .catch((err) => {
//                     alert(err);
//                     window.location.reload()
//                 });
//         }
//     }

//     const [service_name, setService_name] = useState('');
//     const [service_category, setService_category] = useState('');
//     const [serviceName, setServiceName] = useState('');
//     const [serviceCategory, setServiceCategory] = useState('');
//     const [amount, setAmount] = useState('');
//     const [showEnquiry, setShowEnquiry] = useState(false);
//     const [showAp, setShowAp] = useState(false);


//     const initialFormStateAppointment = { start_date_time: '', charges: '', description: '', address: '', willing_to_travel: '', name: '', parlour: '', payment_method: '', payment_mode: '', phone: '', service_name: '', service_category: '', status: '', time: '', uid: '', username: '', date: new Date() }
//     const [submitAppointment, setSubmitAppointment] = useState(initialFormStateAppointment);


//     const initialFormStateEnquiry = { name: '', phone_no: '', message: '', service: '', username: '', created_date: new Date() }
//     const [submitEnquiry, setSubmitEnquiry] = useState(initialFormStateEnquiry);

//     const handleChangeAp = (e) => {
//         const { id, value, type, checked } = e.target;

//         if (type === "radio") {
//             // Handle radio input
//             setSubmitAppointment({
//                 ...submitAppointment,
//                 [id]: value === true, // Convert the string value to a boolean
//             });
//         } else {
//             setSubmitAppointment({
//                 ...submitAppointment,
//                 [id]: value, // Handle other input types
//             });
//         }

//     }



//     const enquiryStatus = (name) => {
//         setShowAp(false);
//         setShowEnquiry(true);
//         setServiceName(name)
//         openModalShow();
//         setSubmitEnquiry({ name: '', phone_no: '', message: `Hey ${personalData.first_name},\nStumbled upon your details on the Youvati website – your profile looks great! 😄 I'm interested in learning more about your services. Could you provide me with more information?\n\nThanks,`, service: '', username: '', created_date: new Date() })
//         // alert(name);
//     }

//     const appointmentStatus = (name, cat, amt) => {
//         setShowEnquiry(false);
//         setShowAp(true);
//         setServiceName(name)
//         setServiceCategory(cat)
//         setAmount(amt)
//         openModalShow();
//         // alert(name);
//     }

//     const handleSubmitAp = (e) => {
//         console.log(serviceCategory)
//         console.log(serviceName)
//         console.log(amount)
//         e.preventDefault();
//         console.log(submitAppointment)
//         console.log(topping)
//         console.log(amount)
//         const startDateTime = new Date(submitAppointment.start_date_time); // Convert to JavaScript Date object

//         const formattedTime = moment(startDateTime).format("h:mm A");


//         db.collection("appointments_data").add({
//             'booked_date': new Date(submitAppointment.start_date_time),
//             'charges': parseInt(amount),
//             'description': '',
//             'name': submitAppointment.name,
//             'parlour': personalData.parlour_name,
//             'payment_method': '',
//             'payment_mode': '',
//             'phone': submitAppointment.phone,
//             'service_name': serviceCategory,
//             'service_category': serviceName,
//             'status': '0',
//             'time': formattedTime,
//             'type': 'Website',
//             'uid': '',
//             'username': params.id,
//             'date': new Date(),
//             'address': topping === "no" ? personalData.parlour_name : submitAppointment.address,
//             'willing_to_travel': topping,
//             'updated_date': new Date(),
//             'rescheduled': "no",
//         }).then(() => {
//             console.log('Submitted Successfully');
//             sendWhatsappBooking(submitAppointment.phone, submitAppointment.name, personalData.parlour_name)
//             setShowEnquiry(false);
//             const Toast = Swal.mixin({
//                 toast: true,
//                 background: 'white',
//                 position: 'top-end',
//                 showConfirmButton: false,
//                 timer: 3000,
//                 timerProgressBar: true,
//                 didOpen: (toast) => {
//                     toast.addEventListener('mouseenter', Swal.stopTimer)
//                     toast.addEventListener('mouseleave', Swal.resumeTimer)
//                 }
//             });

//             Toast.fire({
//                 icon: 'success',
//                 title: 'Appointment Requested Successfully'
//             });

//             setshow(true);
//             closeModel();
//             setSubmitAppointment({ start_date_time: '', charges: '', description: '', name: '', parlour: '', payment_method: '', payment_mode: '', phone: '', service_name: '', service_category: '', status: '', time: '', uid: '', username: '', date: new Date() });
//         });
//     };

//     const handleChangeE = (e) => {
//         const { id, value } = e.target
//         setSubmitEnquiry({ ...submitEnquiry, [id]: value })
//     }

//     const notifyApp = (msg) => {
//         // Call the user's CollectionReference to add a new user
//         db.collection("app_notification").add({ 'message': msg, 'link': "Enquiries", 'status': "0", "uid": personalData.uid, 'created_date': new Date() }).then(() => {
//             console.log("user added");
//         })
//     }

//     const handleSubmitE = (e, msg) => {
//         e.preventDefault()
//         console.log(submitEnquiry);
//         db.collection("enquiry").add({
//             'name': submitEnquiry.name,
//             'phone_no': submitEnquiry.phone_no,
//             'message': submitEnquiry.message,
//             'service': serviceName,
//             'username': params.id,
//             'status': '0',
//             'created_date': new Date(),
//         }).then(() => {
//             sendWhatsappEnquiry(submitEnquiry.phone_no, submitEnquiry.name, personalData.parlour_name)
//             db.collection("customer_data").add({ 'name': submitEnquiry.name, 'email': '', 'phone_no': submitEnquiry.phone_no, 'type': "enquiry", 'additional': params.id, 'created_date': new Date(), }).then(() => {
//                 console.log("data added");
//             });
//             notifyApp(msg = submitEnquiry.message);
//             console.log('Submitted Successfully');
//             setShowEnquiry(false);
//             const Toast = Swal.mixin({
//                 toast: true,
//                 background: 'white',
//                 position: 'top-end',
//                 showConfirmButton: false,
//                 timer: 3000,
//                 timerProgressBar: true,
//                 didOpen: (toast) => {
//                     toast.addEventListener('mouseenter', Swal.stopTimer)
//                     toast.addEventListener('mouseleave', Swal.resumeTimer)
//                 }
//             })

//             Toast.fire({
//                 icon: 'success',
//                 title: 'Submitted Successfully'
//             })
//             setshow(true)
//             closeModel();
//             // navigate(`/${submit.username}`);
//             setSubmitEnquiry({ name: '', phone_no: '', message: '', service: '', username: '', created_date: new Date() })
//         })
//     }

//     const closeModel = () => {
//         window.openModelNew();
//     }


//     const sendWhatsappBooking = (phone, name, parlour) => {

//         var myHeaders = new Headers();
//         myHeaders.append("Authorization", "Bearer EABMKlZCrATXIBAPOZBZBPqsdfot34fq0FTipZBQf3RiXZAapyCOqMay09HfhSxoB8bd5ZA7RUnLWhd0JkXtACJQ682THiDEMhQhyPOkeui8Cb8P72WPDhihUZBCkHtPLSgsmuZA6iRY6VunDDIjPA5mATQQITVXOECHqkZB5E2pzWSC2AaUp0wZCWR");
//         myHeaders.append("Content-Type", "application/json");

//         var raw = JSON.stringify({
//             "messaging_product": "whatsapp",
//             "to": `91${phone}`,
//             "type": "template",
//             "template": {
//                 "name": "booking_recived",
//                 "language": {
//                     "code": "en_US",
//                     "policy": "deterministic"
//                 },
//                 "components": [
//                     {
//                         "type": "body",
//                         "parameters": [
//                             {
//                                 "type": "text",
//                                 "text": name
//                             },
//                             {
//                                 "type": "text",
//                                 "text": parlour
//                             }
//                         ]
//                     }
//                 ]
//             }
//         });

//         var requestOptions = {
//             method: 'POST',
//             headers: myHeaders,
//             body: raw,
//             redirect: 'follow'
//         };

//         fetch("https://graph.facebook.com/v14.0/104489945783566/messages", requestOptions)
//             .then(response => response.text())
//             .then(result => console.log(result))
//             .catch(error => console.log('error', error));

//     }


//     const sendWhatsappEnquiry = (phone, name, parlour) => {

//         var myHeaders = new Headers();
//         myHeaders.append("Authorization", "Bearer EABMKlZCrATXIBAPOZBZBPqsdfot34fq0FTipZBQf3RiXZAapyCOqMay09HfhSxoB8bd5ZA7RUnLWhd0JkXtACJQ682THiDEMhQhyPOkeui8Cb8P72WPDhihUZBCkHtPLSgsmuZA6iRY6VunDDIjPA5mATQQITVXOECHqkZB5E2pzWSC2AaUp0wZCWR");
//         myHeaders.append("Content-Type", "application/json");

//         var raw = JSON.stringify({
//             "messaging_product": "whatsapp",
//             "to": `91${phone}`,
//             "type": "template",
//             "template": {
//                 "name": "enquiry_recived",
//                 "language": {
//                     "code": "en_US",
//                     "policy": "deterministic"
//                 },
//                 "components": [
//                     {
//                         "type": "body",
//                         "parameters": [
//                             {
//                                 "type": "text",
//                                 "text": name
//                             },
//                             {
//                                 "type": "text",
//                                 "text": parlour
//                             }
//                         ]
//                     }
//                 ]
//             }
//         });

//         var requestOptions = {
//             method: 'POST',
//             headers: myHeaders,
//             body: raw,
//             redirect: 'follow'
//         };

//         fetch("https://graph.facebook.com/v14.0/104489945783566/messages", requestOptions)
//             .then(response => response.text())
//             .then(result => console.log(result))
//             .catch(error => console.log('error', error));

//     }

//     const [currentDate, setCurrentDate] = useState('');


//     const [isLoading, setIsLoading] = useState(true)
//     const [ind, setInd] = useState(null);
//     return (
//         <>
//             <div>
//                 <section>
//                     <div class="navi" >
//                         <input type="checkbox" id="nav-check" />
//                         <div class="nav-header">
//                             <div class="nav-title">
//                                 <Link to={`/${params.id.toLowerCase()}`} > <img src="/images/youvati_logo.png" alt="" style={{ height: "27px", marginLeft: "2rem" }} /></Link>
//                             </div>
//                         </div>


//                     </div>
//                 </section>

//                 {isLoading ? <div>
//                     <img src="/images/loader.gif" alt="Popup Image" class="" style={{ display: "block", marginLeft: "auto", marginRight: "auto", width: "40%" }} />
//                 </div> : <>
//                     <section class="" >
//                         <div class="container">
//                             <div class="grid-container grido" style={{}}>
//                                 {personalData.services.map((e, index) => (
//                                     <div key={index} class="" style={{
//                                         height: "auto",
//                                         borderStyle: "solid", borderWidth: "3px",
//                                         borderImage: "linear-gradient(45deg, rgb(252,79,135), rgb(255,242,86)) 1"
//                                     }}>
//                                         <img src={e.image} class="img-fluid" style={{ width: "100%", height: "308px", objectFit: "cover" }} />
//                                         <div class="p-3" style={{ color: "white", backgroundColor: "#e92b91", textAlign: "center", fontSize: "15px", fontWeight: "600", textTransform: "capitalize" }}><span>{e.display_name}</span></div>
//                                         <div class="p-2" style={{ textAlign: "start" }}>
//                                             {/* <div><span class="card-title" style={{ fontSize: "14px" }}>From : {data.parlour_name} <img src="/images/verified-badge.png" style={{ width: "16px" }}></img></span></div> */}
//                                             <div style={{ height: "5.5rem" }}><p class="card-title" style={{
//                                                 fontSize: "11px", color: "black", textAlign: "justify", fontWeight: "400", overflow: "hidden",
//                                                 textOverflow: "ellipsis", display: "-webkit-box", webkitLineClamp: "3", lineClamp: "2", webkitBoxOrient: "vertical"
//                                             }}>{e.details}</p></div>
//                                         </div>
//                                         <div class="container mb-4">
//                                             <div class="row">
//                                                 <div class="col-sm-4" style={{ textAlign: "center" }}>
//                                                     <span style={{ fontSize: "10px", fontWeight: "600", color: "black" }}>INR {e.amount}/-</span>
//                                                 </div>
//                                                 <div class="col-sm-4" style={{ textAlign: "center" }} onClick={() => enquiryStatus(e.display_name)} >
//                                                     <span style={{ fontSize: "10px", fontWeight: "600", color: "black", cursor: "pointer" }}>Raise Enquiry</span>
//                                                 </div>
//                                                 <div class="col-sm-4" style={{ textAlign: "center" }} onClick={() => appointmentStatus(e.display_name, e.type, e.amount)} >
//                                                     <span style={{ font: "bold 10px/1.5 Roboto, sans-serif", color: "#fe8e4e", cursor: "pointer" }}>Book Now</span>
//                                                 </div>
//                                                 {/* <div class="col-sm-4" style={{ textAlign: "center" }}>
//                                                         {ind === index ?
//                                                             <div class="" style={{ display: "flex" }}>
//                                                                 <span style={{ font: "bold 12px/1.5 Roboto, sans-serif", color: "#fe8e4e" }}><a href={`https://api.whatsapp.com/send?phone=${personalData.whatsapp_number}&text=Hi, I'm interested in booking ${e.display_name} service. Can you please provide more details on availability, pricing, and any special offers? Thank you!`} target="_blank"><img src="/images/whatsappp.png" style={{ height: "30px", paddingRight: "10px", display: personalData.whatsapp_number === "" ? "none" : "" }}></img></a></span>
//                                                                 <span onClick={() => changeStatus()} style={{ font: "bold 12px/1.5 Roboto, sans-serif", color: "#fe8e4e" }}><img src="/images/call.png" style={{ height: "27px", paddingRight: "10px", display: personalData.whatsapp_number === "" ? "none" : "" }}></img></span>
//                                                             </div> :
//                                                             <span onClick={() => setInd(index)} style={{ font: "bold 12px/1.5 Roboto, sans-serif", color: "#fe8e4e", cursor: "pointer" }}>Book Now</span>
//                                                         }

//                                                     </div> */}

//                                             </div>
//                                         </div>
//                                         {/* <div class="container mb-4">
//                                             <div class="row">
//                                                 <div class="col-sm-7" style={{ textAlign: "center" }}>
//                                                     <span style={{ fontSize: "12px", fontWeight: "600", color: "black" }}>Price : INR {e.amount}/-</span>
//                                                 </div>
//                                                 <div class="col-sm-5" style={{ textAlign: "center" }}>
//                                                     {ind === index ?
//                                                         <div class="" style={{ display: "flex" }}>
//                                                             <span style={{ font: "bold 12px/1.5 Roboto, sans-serif", color: "#fe8e4e" }}><a href={`https://api.whatsapp.com/send?phone=${personalData.whatsapp_number}&text=Hi, I'm interested in booking ${e.display_name} service. Can you please provide more details on availability, pricing, and any special offers? Thank you!`} target="_blank"><img src="/images/whatsappp.png" style={{ height: "30px", paddingRight: "10px", display: personalData.whatsapp_number === "" ? "none" : "" }}></img></a></span>
//                                                             <span style={{ font: "bold 12px/1.5 Roboto, sans-serif", color: "#fe8e4e" }}><a href={`tel:${personalData.phone_no}`} target="_blank"><img src="/images/call.png" style={{ height: "27px", paddingRight: "10px", display: personalData.whatsapp_number === "" ? "none" : "" }}></img></a></span>
//                                                             <span onClick={() => changeStatus()} style={{ font: "bold 12px/1.5 Roboto, sans-serif", color: "#fe8e4e" }}><img src="/images/call.png" style={{ height: "27px", paddingRight: "10px", display: personalData.whatsapp_number === "" ? "none" : "" }}></img></span>
//                                                         </div> :
//                                                         <span onClick={() => setInd(index)} style={{ font: "bold 12px/1.5 Roboto, sans-serif", color: "#fe8e4e", cursor: "pointer" }}>Book Now</span>
//                                                     }

//                                                 </div>

//                                             </div>
//                                         </div> */}
//                                     </div>
//                                 ))
//                                 }
//                             </div>
//                         </div>

//                         <div class="" >
//                             <div class="row" style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}>

//                                 {personalData.services.length == 0 ? <div class="row" style={{ textAlign: "center" }}>
//                                     <h1 style={{ color: "grey", fontSize: "28px", padding: "48px" }}>No Services Found!</h1>
//                                 </div> : ""}
//                             </div>
//                         </div>


//                     </section ></>}
//             </div>

//             <div class="footer-04">
//                 <div class="container">
//                     <div class="row">
//                         <div class="col-md-6">
//                             <ul style={{ paddingLeft: "3rem", paddingTop: "2rem", }}>
//                                 <Link to="/" onClick={() => window.scrollTo(0, 0)}><li style={{ color: "white" }}>Home</li></Link>
//                                 <Link to="/aboutus"><li style={{ color: "white" }}>About Us</li></Link>
//                                 <Link to="/privacy_policy"><li style={{ color: "white" }}>Privacy Policy</li></Link>
//                                 <Link to="/contactus"><li style={{ color: "white" }}>Contact Us</li></Link>
//                                 {/* <li style={{ color: "white" }}>About Us</li> */}
//                                 {/* <li style={{ color: "white" }}>Privacy Policy</li> */}
//                                 {/* <li style={{ color: "white" }}>Contact Us</li> */}
//                                 <li><img style={{ width: "9rem", paddingTop: "1rem" }} alt="playstore" src="https://b.zmtcdn.com/data/webuikit/23e930757c3df49840c482a8638bf5c31556001144.png" loading="lazy" class="sc-s1isp7-5 fyZwWD" /></li>
//                             </ul>
//                         </div>
//                         <div class="col-md-6 pt-3">
//                             <div class="wrapper">
//                                 <div class="">
//                                     {/* <Select
//                                                 defaultValue={selectedOption}
//                                                 onChange={setSelectedOption}
//                                                 options={options}
//                                                 placeholder="Search via state"
//                                             /> */}

//                                 </div>
//                                 <ul class="ftco-footer-social p-0 pt-4 mr-4" style={{ display: "flex", justifyContent: "center" }}>
//                                     <a href="https://www.facebook.com/youvatiofficial?mibextid=ZbWKwL" target="_blank"><img src="/images/facebookk.png" style={{ height: "30px", paddingRight: "10px" }}></img></a>
//                                     <a href="https://api.whatsapp.com/send?phone=7428790444" target="_blank"><img src="/images/whatsappp.png" style={{ height: "30px", paddingRight: "10px" }}></img></a>
//                                     <a href="https://instagram.com/youvatiofficial?igshid=MzRlODBiNWFlZA==" target="_blank"><img src="/images/insta.png" style={{ height: "30px", paddingRight: "10px" }}></img></a>
//                                     <a href="https://twitter.com/Youvatiofficial?s=20" target="_blank"><img src="/images/twitter.png" style={{ height: "30px", paddingRight: "10px" }}></img></a>
//                                     <a href="https://www.linkedin.com/company/youvati/ " target="_blank"><img src="/images/linkedin.png" style={{ height: "30px", paddingRight: "10px" }}></img></a>
//                                 </ul>
//                             </div>
//                         </div>
//                     </div>

//                 </div>
//                 <div class="" style={{ textAlign: "center", background: "#272727", color: "white", paddingBottom: "1rem" }}>Powred By ~ Youvati</div>
//             </div>

//             <div class="col-md-12">
//                 <div class="myId modal fade" id="" tabindex="-1" role="dialog" aria-labelledby="modal-form" aria-hidden="true">
//                     <div class="modal-dialog modal-dialog-centered modal-md" role="document">
//                         <div class="modal-content">
//                             <div class="modal-body">

//                                 {showEnquiry ? <>
//                                     <div class="">
//                                         <form class="" id="" onSubmit={handleSubmitE}>
//                                             <div class="">
//                                                 <div class="" style={{ textAlign: "center", }}><span style={{ color: "black", fontSize: "25px", fontWeight: "700", textTransform: "uppercase" }}>Raise Enquiry</span></div>
//                                                 <div class="" style={{ textAlign: "center", color: "black", paddingTop: "10px" }}>
//                                                 </div>
//                                                 <div class="">
//                                                     <div class="row">
//                                                         <div class="col-md-6">
//                                                             <input style={{
//                                                                 // width: "17rem",
//                                                                 height: "45px",
//                                                                 padding: "12px 20px",
//                                                                 boxSizing: "border-box",
//                                                                 border: "2px solid #ccc",
//                                                                 borderRadius: "4px",
//                                                                 backgroundColor: "#f8f8f8",
//                                                                 fontSize: "16px",
//                                                                 resize: "none"
//                                                             }} class="w3l_text_box" id="name" onChange={handleChangeE} value={submitEnquiry.name} placeholder="Full Name" name="Full Name" type="text" required />
//                                                         </div>

//                                                         <div class="col-md-6">
//                                                             <input style={{
//                                                                 // width: "17rem",
//                                                                 height: "45px",
//                                                                 padding: "12px 20px",
//                                                                 boxSizing: "border-box",
//                                                                 border: "2px solid #ccc",
//                                                                 borderRadius: "4px",
//                                                                 backgroundColor: "#f8f8f8",
//                                                                 fontSize: "16px",
//                                                                 resize: "none"
//                                                             }} class="w3l_text_box" id="phone_no" onChange={handleChangeE} value={submitEnquiry.phone_no} placeholder="Phone Number" name="Phone Number" type="number" required />
//                                                         </div>

//                                                     </div>
//                                                 </div>
//                                                 <div class="">
//                                                     <div class="row">
//                                                         <div class="col-md-12">
//                                                             <input style={{
//                                                                 // width: "17rem",
//                                                                 height: "45px",
//                                                                 padding: "12px 20px",
//                                                                 boxSizing: "border-box",
//                                                                 border: "2px solid #ccc",
//                                                                 borderRadius: "4px",
//                                                                 backgroundColor: "#f8f8f8",
//                                                                 fontSize: "16px",
//                                                                 resize: "none"
//                                                             }} class="w3l_text_box" value={serviceName} placeholder="Service Name" name="Service Name" type="text" required />
//                                                         </div>


//                                                     </div>
//                                                 </div>
//                                                 <textarea style={{
//                                                     width: "100%",
//                                                     height: "15px",
//                                                     padding: "12px 20px",
//                                                     boxSizing: "border-box",
//                                                     border: "2px solid #ccc",
//                                                     borderRadius: "4px",
//                                                     backgroundColor: "#f8f8f8",
//                                                     fontSize: "16px",
//                                                     resize: "none"
//                                                 }} class="w3l_text_box" id="message" onChange={handleChangeE} value={submitEnquiry.message} placeholder="Write a enquiry..." name="Enquiry" required />



//                                                 <div class="modal-footer">
//                                                     <a class="btn bg-gradient-info btn-sm mb-0" style={{ color: "white" }} onClick={() => closeModel()}>Close</a>
//                                                     {/* <a class="btn bg-gradient-success btn-sm mb-0" style={{ color: "white" }}>Submit</a> */}
//                                                     <button type='submit' class="btn bg-gradient-danger btn-sm mb-0" style={{ backgroundColor: "green", color: "white" }}>Submit</button>
//                                                 </div>


//                                             </div>
//                                         </form>
//                                     </div>
//                                 </> : <></>}
//                                 {showAp ? <>
//                                     <div class="">
//                                         <form class="" id="" onSubmit={handleSubmitAp}>
//                                             <div class="">
//                                                 <div class="" style={{ textAlign: "center", }}><span style={{ color: "black", fontSize: "25px", fontWeight: "700", textTransform: "uppercase" }}>Appointment Booking</span></div>
//                                                 <div class="" style={{ textAlign: "center", color: "black", paddingTop: "10px" }}>
//                                                 </div>
//                                                 <div class="">
//                                                     <div class="row">
//                                                         <div class="col-md-6">
//                                                             <input style={{
//                                                                 // width: "17rem",
//                                                                 height: "45px",
//                                                                 padding: "12px 20px",
//                                                                 boxSizing: "border-box",
//                                                                 border: "2px solid #ccc",
//                                                                 borderRadius: "4px",
//                                                                 backgroundColor: "#f8f8f8",
//                                                                 fontSize: "16px",
//                                                                 resize: "none"
//                                                             }} class="w3l_text_box" id="name" onChange={handleChangeAp} value={submitAppointment.name} placeholder="Full Name" name="Full Name" type="text" required />
//                                                         </div>

//                                                         <div class="col-md-6">
//                                                             <input style={{
//                                                                 // width: "17rem",
//                                                                 height: "45px",
//                                                                 padding: "12px 20px",
//                                                                 boxSizing: "border-box",
//                                                                 border: "2px solid #ccc",
//                                                                 borderRadius: "4px",
//                                                                 backgroundColor: "#f8f8f8",
//                                                                 fontSize: "16px",
//                                                                 resize: "none"
//                                                             }} class="w3l_text_box" id="phone" onChange={handleChangeAp} value={submitAppointment.phone} placeholder="Phone Number" name="Phone Number" type="number" required />
//                                                         </div>
//                                                     </div>
//                                                 </div>
//                                                 <div class="">
//                                                     <div class="row">
//                                                         <div class="col-md-6">
//                                                             <li>
//                                                                 <label style={{ display: "flex" }}>Service Name</label>
//                                                                 <input style={{
//                                                                     // width: "17rem",
//                                                                     height: "45px",
//                                                                     padding: "12px 20px",
//                                                                     boxSizing: "border-box",
//                                                                     border: "2px solid #ccc",
//                                                                     borderRadius: "4px",
//                                                                     backgroundColor: "#f8f8f8",
//                                                                     fontSize: "16px",
//                                                                     resize: "none"
//                                                                 }} class="w3l_text_box" value={serviceName} placeholder="Service Name" name="Service Name" type="text" required />
//                                                             </li>

//                                                         </div>

//                                                         <div class="col-md-6">
//                                                             <li>
//                                                                 <label style={{ display: "flex" }}>Start Datetime</label>
//                                                                 <input type="datetime-local" id="start_date_time" min={currentDate} onChange={handleChangeAp} value={submitAppointment.start_date_time} class="form-control" required />
//                                                             </li>
//                                                         </div>


//                                                     </div>
//                                                 </div>


//                                                 <textarea style={{
//                                                     width: "100%",
//                                                     height: "15px",
//                                                     padding: "12px 20px",
//                                                     boxSizing: "border-box",
//                                                     border: "2px solid #ccc",
//                                                     borderRadius: "4px",
//                                                     backgroundColor: "#f8f8f8",
//                                                     fontSize: "16px",
//                                                     resize: "none"
//                                                 }} class="w3l_text_box" id="description" onChange={handleChangeAp} value={submitAppointment.description} placeholder="Write a description..." name="Description" />

//                                                 <div class="">
//                                                     <div class="col-md-6">
//                                                         <div class="form-check">
//                                                             <input
//                                                                 type="radio"
//                                                                 class="form-check-input"
//                                                                 id="in_parlour"
//                                                                 name="topping"
//                                                                 value="no"
//                                                                 checked={topping === "no"}
//                                                                 style={{ display: "block", position: 'absolute' }}
//                                                                 onChange={onOptionChange}
//                                                             />

//                                                             <label class="form-check-label" for="checkbox1">
//                                                                 In Parlour
//                                                             </label>
//                                                         </div>
//                                                     </div>
//                                                     <div class="col-md-6">
//                                                         <div class="form-check">
//                                                             <input
//                                                                 type="radio"
//                                                                 class="form-check-input"
//                                                                 id="outstation"
//                                                                 name="topping"
//                                                                 value="yes"
//                                                                 onChange={onOptionChange}
//                                                                 style={{ display: "block", position: 'absolute' }}
//                                                             />

//                                                             <label class="form-check-label" for="checkbox2">
//                                                                 At Home/Outstation
//                                                             </label>
//                                                         </div>
//                                                     </div>
//                                                 </div>

//                                                 {topping === "yes" ? <>    <textarea style={{
//                                                     width: "100%",
//                                                     height: "5px",
//                                                     padding: "12px 20px",
//                                                     boxSizing: "border-box",
//                                                     border: "2px solid #ccc",
//                                                     borderRadius: "4px",
//                                                     backgroundColor: "#f8f8f8",
//                                                     fontSize: "16px",
//                                                     resize: "none",
//                                                     minHeight: "59px",
//                                                 }} class="w3l_text_box" id="address" onChange={handleChangeAp} value={submitAppointment.address} placeholder="Address......." name="address" />
//                                                 </>
//                                                     :
//                                                     <></>
//                                                 }


//                                                 <div class="modal-footer">
//                                                     <a class="btn bg-gradient-info btn-sm mb-0" style={{ color: "white" }} onClick={() => closeModel()}>Close</a>
//                                                     {/* <a class="btn bg-gradient-success btn-sm mb-0" style={{ color: "white" }}>Submit</a> */}
//                                                     <button type='submit' class="btn bg-gradient-danger btn-sm mb-0" style={{ backgroundColor: "green", color: "white" }}>Submit</button>
//                                                 </div>


//                                             </div>
//                                         </form>
//                                     </div>
//                                 </> : <></>}
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>

//         </>
//     );
// }

// export default AllServices;

