import React, { useState, useEffect } from 'react';
import { useNavigate, Link, useParams } from 'react-router-dom';
import db from "./Firebase";
import { arrayUnion } from "firebase/firestore";
import { storage } from './Firebase';
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import Moment from 'moment';
import Swal from 'sweetalert2'
import { extendMoment } from 'moment-range';
import ViewAllRequests from './ViewAllRequests';
import { CSVLink } from "react-csv";

function DeletePortfolioRequest() {
    const navigate = useNavigate();
    const moment = extendMoment(Moment);
    const [data, setData] = useState([]);
    const [csvdata, setCsvdata] = useState([]);
    const [uid, setUid] = useState('');

    useEffect(() => {
        const storedUsername = localStorage.getItem("email");
        const storedPassword = localStorage.getItem("password");
        const storedRemember = localStorage.getItem("remember");

        if (storedRemember === "false" || !storedUsername || !storedPassword) {
            navigate('/signin');
        }
    }, []);

    useEffect(() => {
        db.collection("delete_portfolio_request").orderBy("created_date", "desc").onSnapshot((snapshot) => {
            setData(
                snapshot.docs.map((doc) => ({
                    id: doc.id,
                    data: doc.data(),
                }))
            );


        });
    }, []);

    const handleChange2 = async (e, msg) => {
        const { id, value } = e.target
        if (e.target.value == "1") {
            const { value: text } = await Swal.fire({
                input: 'textarea',
                inputLabel: 'Remarks',
                inputPlaceholder: 'Enter Remarks Here...',
                showCancelButton: true,
            })

            if (text) {
                // Swal.fire(`Entered text: ${text}`)
                db.collection("delete_portfolio_request").doc(uid).update({ remarks: text, updated_date: new Date() }).then(() => {
                    console.log("query added");
                    const Toast = Swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', Swal.stopTimer);
                            toast.addEventListener('mouseleave', Swal.resumeTimer);
                        },
                    });

                    Toast.fire({
                        icon: 'success',
                        title: 'Updated successfully',
                    });
                })
            }

        } else {
            // alert("two")
            // db.collection("business_card_request").doc(uid).update({ 'status': "0", 'updated_date': new Date() }).then(() => {
            //     console.log("user added");
            // })
        }
    }


    return (
        <>
            {/* <ViewAllRequests /> */}
            <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
                <div class="p-2" style={{ textAlign: "start", paddingBottom: "5px" }}>
                    {/* <span class="btn bg-gradient-secondary btn-sm mb-0 mr-2" style={{ color: "white" }} >&nbsp; Download Excel</span> */}
                    <Link to="/admin_panel"><img src="/images/menubutton.png" class="menu-toggle" alt="Responsive image" style={{ height: "2rem" }} /></Link>

                </div>
                <div class="row">
                    <div class="mt-4 p-4">
                        <div style={{ textAlign: "end", paddingBottom: "5px" }}>
                            {/* <Link to="/admin_panel" class="btn bg-gradient-primary btn-sm mb-0 mr-2" style={{ color: "white" }} >&nbsp; Go Back</Link> */}

                        </div>
                        <div class="card">
                            <div class="card-header pb-0 px-3">
                                <h6 class="mb-2">Delete Portfolio Request</h6>
                            </div>
                            <div class="">

                                <div class="card">
                                    <div class="table-responsive fix-width scroll-inner">
                                        <table class="table align-items-center mb-0">
                                            <thead>
                                                <tr style={{ textAlign: "left" }}>
                                                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Full Name</th>
                                                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Phone No</th>
                                                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Reason</th>
                                                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Created Date</th>
                                                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Action</th>
                                                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Remarks</th>
                                                </tr>
                                            </thead>
                                            <tbody style={{ textAlign: "left" }}>
                                                {data?.map(({ id, data }, index) => (
                                                    <>
                                                        <tr>
                                                            <td>
                                                                <div class="d-flex px-2 py-1">
                                                                    <div class="d-flex flex-column justify-content-center">
                                                                        <h6 class="mb-0 text-xs">{data.name}</h6>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div class="d-flex px-2 py-1">
                                                                    <div class="d-flex flex-column justify-content-center">
                                                                        <h6 class="mb-0 text-xs">{data.phone_no}</h6>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div class="d-flex px-2 py-1">
                                                                    <div class="d-flex flex-column justify-content-center">
                                                                        <h6 class="mb-0 text-xs">{data.reason}</h6>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <p class="text-xs text-secondary mb-0">{moment(data.created_date.toDate().toISOString()).format("MM/DD/YY, h:mm a")}</p>
                                                            </td>

                                                            {data.remarks == null ?
                                                                <>
                                                                    <td>
                                                                        <select class="form-control" id={index} name="status" onClick={() => setUid(id)} onChange={handleChange2} value={data.status} style={{ padding: "7px", width: "9rem", backgroundColor: data.status == 1 ? "#28a745" : "transparent" }} disabled={data.status == 1 ? true : false} >
                                                                            <option selected={data.status === "pending" ? true : false} value="0">Pending</option>
                                                                            <option selected={data.status === "done" ? true : false} value="1">Done</option>
                                                                        </select>
                                                                    </td>
                                                                </> : <>
                                                                    <td>
                                                                        <select class="form-control" id="request_status" name="request_status" style={{ width: "8rem", background: "green", color: "white" }}>
                                                                            <option selected value="Done" disabled>Done</option>
                                                                        </select>
                                                                    </td>

                                                                </>}

                                                            <td>
                                                                <textarea class="form-control" value={data.remarks} style={{ width: "18rem", background: "#ddd" }} disabled />
                                                            </td>
                                                        </tr>
                                                    </>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
}

export default DeletePortfolioRequest;

