import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2'
import db from "./Firebase";
import { storage } from './Firebase';
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";


function Invite() {
    const navigate = useNavigate();
    const initialFormState = { parlour_name: '', phone_no: '', email: '', pincode: '', place: '', plus_code: '', username: '', full_address: '', usercode: '', created_date: new Date() }
    const [submit, setSubmit] = useState(initialFormState)

    const [imgUrl, setImgUrl] = useState(null);
    const [progresspercent, setProgresspercent] = useState(0);

    const [show, setshow] = useState(true);

    const handleChange = (e) => {
        const { id, value } = e.target
        setSubmit({ ...submit, [id]: value })
    }

    const reset = () => {
        setSubmit({ parlour_name: '', phone_no: '', email: '', pincode: '', place: '', plus_code: '', username: '', full_address: '', usercode: '', created_date: new Date() })
    }

    const ids = ["rahul_122002", "ashutosh_560001", "mrinal_122004"];
    const handleSubmit = (e) => {
        console.log(submit.usercode)
        e.preventDefault()

        if (ids.includes(submit.usercode)) {
            const file = e.target[0]?.files[0]
            // console.log(file)
            if (!file) return;
            const storageRef = ref(storage, `files/${file.name}`);
            const uploadTask = uploadBytesResumable(storageRef, file);
            setshow(false)

            uploadTask.on("state_changed",
                (snapshot) => {
                    const progress =
                        Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                    setProgresspercent(progress);
                },
                (error) => {
                    alert(error);
                },
                () => {
                    getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                        // setImgUrl(downloadURL)
                        db.collection("unregistered_users").add({
                            'parlour_name': submit.parlour_name,
                            'phone_no': submit.phone_no,
                            'pincode': submit.pincode,
                            'place_best_beauty_parlour_in': submit.place,
                            'full_address': submit.full_address,
                            'rating': '',
                            'reviews': '',
                            'image': downloadURL,
                            'username': submit.username,
                            'plus_code': submit.plus_code,
                            'email': submit.email,
                            'usercode': submit.usercode,
                            'monday': '',
                            'tuesday': '',
                            'wednesday': '',
                            'thursday': '',
                            'friday': '',
                            'saturday': '',
                            'sunday': '',
                            'created_date': new Date(),
                        }).then(() => {
                            reset();
                            setshow(true)
                            console.log('Added Successfully');
                            const Toast = Swal.mixin({
                                toast: true,
                                background: '#69aba6',
                                position: 'top-end',
                                showConfirmButton: false,
                                timer: 3000,
                                timerProgressBar: true,
                                didOpen: (toast) => {
                                    toast.addEventListener('mouseenter', Swal.stopTimer)
                                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                                }
                            })

                            Toast.fire({
                                icon: 'success',
                                title: 'Beauty Parlour Added'
                            })
                            navigate(`/${submit.username}`);
                        })
                    });
                }
            );
        } else {
            alert("usercode does not exist")
        }

    }



    return (

        <>
            <div class="w3layouts_payment_online_form">
                <form class="" id="" onSubmit={handleSubmit}>
                    <h4 style={{ fontWeight: "600" }}>Add Beauty Parlour</h4>

                    <ul>
                        <li><label for="myfile">Choose Parlour Image:</label><input type="file" id="image" name="Image" accept="image/*" /></li>
                        <li><input class="w3l_text_box" id="parlour_name" onChange={handleChange} value={submit.parlour_name} placeholder="Parlour Name" name="Parlour Name" type="text" required />
                            <p style={{ color: "red", fontSize: "10px", paddingTop: "0px" }}>*eg: Hema Beauty Parlour</p>
                        </li>

                    </ul>
                    <ul>
                        <li><input class="w3l_text_box" id="phone_no" onChange={handleChange} value={submit.phone_no} placeholder="10 Digit Phone Number" name="Phone" type="tel" minlength="10" maxlength="10" required />
                            <p style={{ color: "red", fontSize: "10px", paddingTop: "0px" }}>*eg: 7890854678</p>
                        </li>
                        <li><input class="w3l_text_box" id="email" onChange={handleChange} value={submit.email} placeholder="Email / Website" name="Email" type="email" />
                            <p style={{ color: "red", fontSize: "10px", paddingTop: "0px" }}>*eg: hema@gmail.com</p>
                        </li>

                    </ul>
                    <ul>
                        <li><input class="w3l_text_box" id="pincode" onChange={handleChange} value={submit.pincode} placeholder="Pin Code" name="Pin Code" type="tel" minlength="6" maxlength="6" required />
                            <p style={{ color: "red", fontSize: "10px", paddingTop: "0px" }}>*eg: 100200</p>
                        </li>
                        <li><input class="w3l_text_box" id="place" onChange={handleChange} value={submit.place} placeholder="Place" name="Place" type="text" required="" />
                            <p style={{ color: "red", fontSize: "10px", paddingTop: "0px" }}>eg: Phagwara Jalandhar Punjab</p></li>

                    </ul>
                    <ul>
                        <li><input class="w3l_text_box" id="plus_code" placeholder="Plus Code" onChange={handleChange} value={submit.plus_code} name="Plus Code" type="text" required />
                            <p style={{ color: "red", fontSize: "10px", paddingTop: "0px" }}>*eg: H937P9 =  H937<i style={{ color: "green" }}>%2B</i>P9 Phagwara+Jalandhar+Punjab+Hema+Beauty+Parlour"</p>
                        </li>
                        <li><input class="w3l_text_box" id="username" onChange={handleChange} value={submit.username} placeholder="Username" name="Username" type="text" required />
                            <p style={{ color: "red", fontSize: "10px", paddingTop: "0px" }}>*eg: hema123 (all in small letter)</p></li>

                    </ul>

                    <ul>
                        <li><input class="w3l_text_box" placeholder="Full Address" name="Full Address" id="full_address" onChange={handleChange} value={submit.full_address} type="textarea" required />
                            <p style={{ color: "red", fontSize: "10px", paddingTop: "0px" }}>*eg: New Orean mall 2nd floor, Phagwara, Punjab, India</p></li>
                        <li><input class="w3l_text_box" id="usercode" onChange={handleChange} value={submit.usercode} placeholder="Authorization Code" name="User Code" type="text" required />
                            <p style={{ color: "red", fontSize: "10px", paddingTop: "0px" }}>.</p></li>

                    </ul>
                    {/* <ul>
                        <li class="w3ls_address">
                            <input class="w3l_text_box" placeholder="Full Address" name="Full Address" id="full_address" onChange={handleChange} value={submit.full_address} type="textarea" required="" />
                            <p style={{ color: "red", fontSize: "10px", paddingTop: "0px" }}>*eg: New Orean mall 2nd floor, Phagwara, Punjab, India</p></li>

                    </ul> */}
                    <div id="recaptcha-container"></div>

                    {show ? <ul class="w3_payment_sendbtns">
                        <li><input type="reset" value="Cancel" onClick={() => navigate(`/quicklinks`)} /></li>
                        <li class='mr-4'><input type="reset" value="Reset" onClick={reset} /></li>
                        <li><input type="submit" value="submit" /></li>
                    </ul> : <ul class="w3_payment_sendbtns"><li><p>Creating Profile......</p></li></ul>}
                    <div class="clearfix"> </div>
                </form>
            </div>
        </>



        // <div className="App">
        //     <form onSubmit={handleSubmit} className='form'>
        //         <input type='file' />
        //         <button type='submit'>Upload</button>
        //     </form>
        //     {
        //         !imgUrl &&
        //         <div className='outerbar'>
        //             <div className='innerbar' style={{ width: `${progresspercent}%` }}>{progresspercent}%</div>
        //         </div>
        //     }
        //     {
        //         imgUrl &&
        //         <img src={imgUrl} alt='uploaded file' height={200} />
        //     }
        // </div>
    );
}
export default Invite;