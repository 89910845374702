import React, { useState, useEffect } from 'react';
import { useNavigate, Link, useParams } from 'react-router-dom';
import db from "./Firebase";
import firebaseApp from "./Firebase";
import { arrayUnion } from "firebase/firestore";
import { storage } from './Firebase';
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import ViewAllRequests from './ViewAllRequests';
import ViewAppData from './ViewAppData';
import { CSVLink } from "react-csv";
import Swal from 'sweetalert2'

function ReferredCourses() {
    const navigate = useNavigate();
    const moment = extendMoment(Moment);
    const [data, setData] = useState([]);
    const [udata, setUdata] = useState([]);
    const [csvdata, setCsvdata] = useState([]);

    const initialFormStateFeedback = { request_status: '', remark: '', call_status: '', updated_date: new Date() }
    const [submit, setSubmit] = useState(initialFormStateFeedback);

    // Additional fields for Status and Remark
    const [status, setStatus] = useState("Pending");
    const [remark, setRemark] = useState("");
    const [uid, setUid] = useState('');
    const [editIndex, setEditIndex] = useState();
    const [edit, setEdit] = useState(false);


    useEffect(() => {
        const storedUsername = localStorage.getItem("email");
        const storedPassword = localStorage.getItem("password");
        const storedRemember = localStorage.getItem("remember");

        if (storedRemember === "false" || !storedUsername || !storedPassword) {
            navigate('/signin');
        }
    }, []);

    useEffect(() => {
        db.collection("referred_courses").orderBy("created_at", "desc").onSnapshot((snapshot) => {
            setData(
                snapshot.docs.map((doc) => ({
                    id: doc.id,
                    data: doc.data(),
                }))
            );
        });
    }, []);

    useEffect(() => {
        db.collection("personal_details").orderBy("created_date", "desc").onSnapshot((snapshot) => {
            setUdata(
                snapshot.docs.map((doc) => ({
                    id: doc.id,
                    data: doc.data(),
                }))
            );
        });

    }, []);

    const [idNameMapping, setIdNameMapping] = useState([]);
    const [idPhoneMapping, setIdPhoneMapping] = useState([]);
    const idmapping = () => {
        {
            udata.map(({ id, data }, index) => (
                idNameMapping[id] = data.full_name,
                idPhoneMapping[id] = data.phone_no
                // console.log(data.full_name)
            ))
            // console.log(idNameMapping[data.workshop_event_name]);
        }
    }

    const formatDateTime = (dateTime) => {
        // const unixTimestamp = 1684732371;
        const date = new Date(dateTime * 1000); // Multiply by 1000 to convert from seconds to milliseconds

        const year = date.getFullYear();
        const month = date.getMonth() + 1; // Month is zero-indexed, so add 1
        const day = date.getDate();
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const seconds = date.getSeconds();

        const formattedDateTime = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')} ${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
        return formattedDateTime;
        // console.log(dateTime); // Check the value of dateTime
        // const unixTimestamp = moment(dateTime, "YYYY-MM-DD").valueOf();
        // console.log(unixTimestamp);
        // return unixTimestamp;

    };

    const callStatus = (uid, index) => {
        console.log(uid)
        console.log(index)
        setUid(uid);
        setEditIndex(index);
    }


    const handleRemarkChange = (event) => {
        setRemark(event.target.value);
    };

    const handleStatusChange = async (e) => {
        // setStatus(event.target.value);
        // const { id, value } = e.target
        // setSubmit({ ...submit, [id]: value })

        if (e.target.value == "2") {
            const { value: text } = await Swal.fire({
                input: 'textarea',
                inputLabel: 'Update Remark',
                inputPlaceholder: 'Type your message here...',
                inputAttributes: {
                    'aria-label': 'Type your message here'
                },
                showCancelButton: true
            })
            db.collection("referred_courses").doc(uid).update({ 'request_status': "2", 'remark': text, 'updated_date': new Date() }).then(() => {
                console.log("data Updated");
            })

        } else {
            const { value: text } = await Swal.fire({
                input: 'textarea',
                inputLabel: 'Final Remark',
                inputPlaceholder: 'Type your message here...',
                inputAttributes: {
                    'aria-label': 'Type your message here'
                },
                showCancelButton: true
            })
            db.collection("referred_courses").doc(uid).update({ 'request_status': "1", 'remark': text, 'updated_date': new Date() }).then(() => {
                console.log("data Updated");
            })
        }

    };

    const handleCallStatusChange = (e) => {
        console.log("calling.....")
        console.log(uid)
        db.collection("referred_courses").doc(uid).update({ 'call_status': e.target.value, 'updated_date': new Date() }).then(() => {
            console.log("data Updated");
        })
        // console.log(e.target.value)
    }


    return (
        <>
            {idmapping()}
            <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
                <div class="p-2" style={{ textAlign: "start", paddingBottom: "5px" }}>
                    {/* <span class="btn bg-gradient-secondary btn-sm mb-0 mr-2" style={{ color: "white" }} >&nbsp; Download Excel</span> */}
                    <Link to="/admin_panel"><img src="/images/menubutton.png" class="menu-toggle" alt="Responsive image" style={{ height: "2rem" }} /></Link>

                </div>
                <div class="">
                    <div class="p-2">
                        <div style={{ textAlign: "end", paddingBottom: "5px" }}>
                        </div>
                        <div class="card">
                            <div class="card-header pb-0 px-3">
                                <h6 class="mb-2">Referred Courses</h6>
                            </div>
                            <div class="">
                                <div class="card">
                                    <div class="table-responsive fix-width scroll-inner">
                                        <table class="table table-bordered table-hover">
                                            <thead class="thead-dark" style={{ position: "sticky", top: "0" }}>
                                                <tr style={{ textAlign: "left" }}>
                                                    <th class="col ps-2">Referred By</th>
                                                    <th class="col ps-2">Referred To</th>
                                                    <th class="col ps-2">Referral Course</th>
                                                    {/* <th class="col ps-2">Contact</th> */}
                                                    <th class="col ps-2">Amount</th>
                                                    <th class="col ps-2">Created Date</th>

                                                    <th class="col">Call Status</th>
                                                    <th class="col">Status</th>
                                                    <th class="col">Final Remark</th>


                                                </tr>
                                            </thead>
                                            <tbody style={{ textAlign: "left" }}>
                                                {data?.map(({ id, data }, index) => (
                                                    <tr key={id}>
                                                        <td>
                                                            <p class="text-xs mb-0">{idNameMapping[data.notes['reffered_by']]}</p>
                                                            <p class="text-xs text-secondary mb-0">{idPhoneMapping[data.notes['reffered_by']]}</p>


                                                        </td>
                                                        <td>
                                                            <p class="text-xs mb-0">{data.customer['name']}</p>
                                                            <p class="text-xs text-secondary mb-0">{data.customer['contact']}</p>
                                                        </td>
                                                        <td>
                                                            <p class="text-xs mb-0">{data.description}</p>
                                                        </td>
                                                        {/* <td>
                                                            <p class="text-xs mb-0">{data.customer['contact']}</p>
                                                        </td> */}
                                                        <td>
                                                            <p class="text-xs mb-0">{data.amount / 100}</p>
                                                        </td>
                                                        <td>
                                                            <p class="text-xs mb-0">{formatDateTime(data.created_at)}</p>
                                                        </td>

                                                        {data.call_status ?
                                                            <>
                                                                <td>
                                                                    <select class="form-control" id="call_status" name="call_status" onClick={() => callStatus(id, index)} onChange={handleCallStatusChange} value={data.call_status} style={{ width: "10rem", background: data.call_status === "Connected" ? "green" : data.callStatus === "Did Not Answer" ? "black" : "brown", color: "white" }}>
                                                                        <option value="" disabled>Call Status</option>
                                                                        <option selected={data.call_status == "Connected" ? true : false} value="Connected">Connected</option>
                                                                        <option selected={data.call_status == "Did Not Answer" ? true : false} value="Did Not Answer">Did Not Answer</option>
                                                                        <option selected={data.call_status == "Not Reachable" ? true : false} value="Not Reachable">Not Reachable</option>
                                                                    </select>
                                                                </td>
                                                            </>
                                                            :
                                                            <>
                                                                <td>
                                                                    <select class="form-control" id="call_status" name="call_status" onClick={() => callStatus(id, index)} onChange={handleCallStatusChange} value={submit.call_status} style={{ width: "10rem", }}>
                                                                        <option value="" disabled>Call Status</option>
                                                                        <option value="Connected">Connected</option>
                                                                        <option value="Did Not Answer">Did Not Answer</option>
                                                                        <option value="Not Reachable">Not Reachable</option>
                                                                    </select>
                                                                </td>
                                                            </>
                                                        }

                                                        {data.request_status === "1" ?
                                                            <>
                                                                <td>
                                                                    <select class="form-control" id="request_status" name="request_status" onClick={() => callStatus(id, index)} onChange={handleStatusChange} value={submit.request_status} style={{ width: "8rem", background: "green", color: "white" }}>
                                                                        <option value="" disabled>Done</option>
                                                                    </select>
                                                                </td>
                                                            </>
                                                            :
                                                            <>
                                                                <td>
                                                                    <select class="form-control" id="request_status" name="request_status" onClick={() => callStatus(id, index)} onChange={handleStatusChange} value={submit.request_status} style={{ width: "8rem" }}>
                                                                        <option value="" disabled>Pending</option>
                                                                        <option selected={data.request_status == "2" ? true : false} value="2">Update Status</option>
                                                                        <option selected={data.request_status == "1" ? true : false} value="1">Done</option>
                                                                    </select>
                                                                </td>
                                                            </>
                                                        }


                                                        <td>
                                                            <textarea class="form-control" value={data.remark} onChange={handleRemarkChange} style={{ width: "15rem", background: "#ddd" }} disabled />
                                                        </td>

                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}

export default ReferredCourses;
