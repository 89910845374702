import React, { useState, useEffect } from "react";
import Swal from 'sweetalert2'
import db from "./Firebase";
import { storage } from './Firebase';
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";

function ProductList() {
    const initialFormStateFeedback = { type: '', product_name: '', brand_name: '', quantity: '', itemcount: '', price: '', seller: '', authorization_code: '', image_url: '', updated_date: new Date() }
    const [submit, setSubmit] = useState(initialFormStateFeedback)
    const [data, setData] = useState([]);
    const [ShowProgress, setShowProgress] = useState(false);
    const [show, setshow] = useState(true);
    const [progresspercent, setProgresspercent] = useState(0);

    const [editID, setEditID] = useState("");

    useEffect(() => {
        db.collection("consumables_data").onSnapshot((snapshot) => {

            setData(
                snapshot.docs.map((doc) => ({
                    id: doc.id,
                    data: doc.data(),
                }))
            );
        });

    }, []);

    return (
        <>
            <ul class="mystyle-products">
                {data?.map(({ id, data }, index) => (
                    <>
                        <li class="product">
                            <a href="14-single-product.html">
                                {/* <span class="onsale">Sale!</span> */}
                                <img alt="" class="attachment-shop_catalog" style={{ height: "16rem" }} src={data.image_url} />
                                <h3 style={{ fontSize: "18px", fontWeight: "600" }} >{data.product_name}</h3>
                                <span class="price">
                                    <del> <span class="amount">5000</span> </del>
                                    <ins> <span class="amount">{data.price}</span> </ins>
                                    <span class="sale-tag sale-tag-square">-33%</span>
                                    <span class="">Add to cart</span>
                                </span>
                            </a>
                            <a class="button add_to_cart_button product_type_simple" rel="nofollow" href="#">
                                Mua ngay
                            </a>
                            <a href="#" class="btn btn-dark btn-circle btn-review" data-toggle="tooltip" data-placement="top" title="Quick View"><i class="ion ion-ios-move"></i></a>
                        </li>
                    </>
                ))}

            </ul>
        </>
    )
}

export default ProductList