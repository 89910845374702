import React, { useState, useEffect } from 'react';
import { useNavigate, Link, useParams } from 'react-router-dom';
import db from "./Firebase";
import firebaseApp from "./Firebase";
import { arrayUnion } from "firebase/firestore";
import { storage } from './Firebase';
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import ViewAllRequests from './ViewAllRequests';
import ViewAppData from './ViewAppData';
import { CSVLink } from "react-csv";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


function UserLogs() {
    const navigate = useNavigate();
    const moment = extendMoment(Moment);
    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(5000);
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true); // New state to track if there's more data to fetch

    const headers = [
        "Username",
        "Module",
        "Layer_1",
        "Layer_2",
        "Layer_3",
        "Layer_4",
        "Layer_5",
        "Created Date",
        "Portfolio"
        // Add more column labels as needed
    ];

    useEffect(() => {
        const usersData = [];
        const parlourData = [];
        const fetchData = async () => {
            setLoading(true);
            try {
                let query = db.collection("clicked_logs")
                    .orderBy("created_date", "desc")
                    .limit(itemsPerPage);

                // If not on the first page, start after the last document of the previous page
                if (currentPage > 1) {
                    const lastDocument = data[data.length - 1];
                    query = query.startAfter(lastDocument.data.created_date);
                }

                const snapshot = await query.get();
                const newData = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    data: doc.data(),
                }));

                // Fetch data from "parlour_name" collection
                const parlourSnapshot = await db.collection("portfolio_details").get();

                parlourSnapshot.docs.forEach((doc) => {
                    parlourData.push({
                        id: doc.id,
                        data: doc.data(),
                    });
                });

                // Merge the data based on the username
                const mergedData = newData.map((user) => {
                    const parlour = parlourData.find((p) => p.data.uid === user.data.uid);
                    return {
                        ...user,
                        parlour: parlour ? parlour.data : "NA",
                    };
                });

                // Now, mergedData contains the merged information
                setData(() => [...mergedData]);
            } catch (error) {
                console.error("Error fetching data: ", error);
            }
            setLoading(false);
        };

        fetchData();
    }, [currentPage, itemsPerPage]);


    const handleNextPage = () => {
        setCurrentPage(currentPage + 1);
    };

    const handlePrevPage = () => {
        setCurrentPage(currentPage - 1);
    };

    const prepareCSVData = () => {
        const csvData = data.map((item, index) => ([
            item.data.username,
            item.data.module,
            item.data.layer_1,
            item.data.layer_2,
            item.data.layer_3,
            item.data.layer_4,
            item.data.layer_5,
            moment(item.data.created_date.toDate().toISOString()).format("MM/DD/YY, h:mm a"),
            item.parlour ? "Yes" : 'No',
            item.parlour ? item.parlour.uid ? "Yes" : "No" : '',
            // Add more columns as needed
        ]));

        return csvData;
    };

    return (
        <>
            <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
                <div class="p-2" style={{ paddingBottom: "5px", display: "flex", justifyContent: "space-between" }}>
                    <Link to="/admin_panel">
                        <img src="/images/menubutton.png" class="menu-toggle" alt="Responsive image" style={{ height: "2rem" }} />
                    </Link>
                    <CSVLink data={prepareCSVData()} headers={headers} filename={"clicked_logs.csv"}>
                        <span class="btn bg-gradient-secondary btn-sm mb-0 mr-2" style={{ color: "white" }}>
                            &nbsp; Download Excel
                        </span>
                    </CSVLink>
                </div>
                <div className="">
                    <div className="p-2">
                        <div className="card">
                            <div className="card-header pb-0 px-3" style={{ display: "flex", justifyContent: "space-between", fontWeight: "bold", alignItems: "center" }}>
                                <h6 className="mb-2">Users Clicked Logs</h6>
                                <div className="d-flex p-2" >
                                    <button class="btn btn-dark mr-2" onClick={handlePrevPage} disabled={currentPage === 1}>
                                        Previous
                                    </button>
                                    <button class="btn btn-dark mr-2" onClick={handleNextPage} disabled={!hasMore}> {/* Disable the Next button if there's no more data */}
                                        Next
                                    </button>
                                </div>
                            </div>

                            <div className="">
                                <div className="card">
                                    <div className="table-responsive fix-width scroll-inner">
                                        <table className="table table-bordered table-hover">
                                            <thead className="thead-dark" style={{ position: "sticky", top: "0" }}>
                                                <tr style={{ textAlign: "start" }}>
                                                    <th className=" ps-2">S.No</th>
                                                    <th className=" ps-2">Username</th>
                                                    <th className="">Module</th>
                                                    <th className=" ps-2">Layer_1</th>
                                                    <th className=" ps-2">Layer_2</th>
                                                    <th className=" ps-2">Layer_3</th>
                                                    <th className=" ps-2">Layer_4</th>
                                                    <th className=" ps-2">Layer_5</th>
                                                    <th className=" ps-2">Created Date</th>
                                                    <th className=" ps-2">Portfolio</th>
                                                </tr>
                                            </thead>
                                            <tbody style={{ textAlign: "start" }}>
                                                {data.map(({ id, data, parlour }, index) => (
                                                    <tr key={id}>
                                                        <td>
                                                            <p class="text-xs mb-0">{index + 1}.</p>
                                                        </td>
                                                        <td>
                                                            <a href={data.username} style={{ color: "blue", cursor: "pointer" }} target="_blank" class="mb-0 text-xs">{data.username}</a>
                                                            {/* <p class="text-xs mb-0">{data.username}</p> */}
                                                        </td>
                                                        <td>
                                                            <p class="text-xs mb-0">{data.module}</p>
                                                        </td>
                                                        <td>
                                                            <p class="text-xs mb-0">{data.layer_1}</p>
                                                        </td>
                                                        <td>
                                                            <p class="text-xs mb-0">{data.layer_2}</p>
                                                        </td>

                                                        <td>
                                                            <p class="text-xs mb-0">{data.layer_3}</p>
                                                        </td>
                                                        <td>
                                                            <p class="text-xs mb-0">{data.layer_4}</p>
                                                        </td>
                                                        <td>
                                                            <p class="text-xs mb-0">{data.layer_5}</p>
                                                        </td>

                                                        <td>
                                                            <div class="d-flex px-2 py-1">
                                                                <div class="d-flex flex-column justify-content-center">
                                                                    <h6 class="mb-0 text-xs">{moment(data.created_date.toDate().toISOString()).format("MM/DD/YY, h:mm a")}</h6>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <p class="text-xs mb-0">{parlour ? parlour.uid ? "Yes" : "NO" : ""}</p>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
}

export default UserLogs;
