import React, { useState, useEffect } from 'react';
import { useNavigate, Link, useParams } from 'react-router-dom';
import db from "./Firebase";
import { arrayUnion } from "firebase/firestore";
import { storage } from './Firebase';
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import ViewAllRequests from './ViewAllRequests';
import { CSVLink } from "react-csv";

function BeautyKitRequest() {
    const navigate = useNavigate();
    const headers = [
        { label: "Address", key: "address" },
        { label: "Kit Type", key: "kit_type" },
        { label: "Paid Amount", key: "paid_amount" },
        { label: "Parlour Name", key: "parlour_name" },
        { label: "Payment Id", key: "payment_id" },
        { label: "Payment Type", key: "payment_type" },
        { label: "Phone No", key: "phone_no" },
        { label: "Pincode", key: "pincode" },
        { label: "Status", key: "status" },
        { label: "UID", key: "uid" },
        { label: "Created Date", key: "created_date", dataFormat: "MM/DD/YY, h:mm a" },
    ];

    const [data, setData] = useState([])
    const [csvdata, setCsvdata] = useState([]);

    useEffect(() => {
        const storedUsername = localStorage.getItem("email");
        const storedPassword = localStorage.getItem("password");
        const storedRemember = localStorage.getItem("remember");

        if (storedRemember === "false" || !storedUsername || !storedPassword) {
            navigate('/signin');
        }
    }, []);

    useEffect(() => {
        db.collection("beauty_kit_request").onSnapshot((snapshot) => {
            setCsvdata(
                snapshot.docs.map((doc) => ({
                    id: doc.id,
                    data: doc.data()
                }))
            );

            setData(
                snapshot.docs.map((doc) => (
                    doc.data()
                ))
            );

        });

    }, []);
    return (
        <>
            {/* <ViewAllRequests /> */}

            <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
                <div class="p-2" style={{ paddingBottom: "5px", display: "flex", justifyContent: "space-between" }}>
                    {/* <span class="btn bg-gradient-secondary btn-sm mb-0 mr-2" style={{ color: "white" }} >&nbsp; Download Excel</span> */}
                    <Link to="/admin_panel"><img src="/images/menubutton.png" class="menu-toggle" alt="Responsive image" style={{ height: "2rem" }} /></Link>
                    <CSVLink data={data} headers={headers}>
                        <span class="btn bg-gradient-secondary btn-sm mb-0 mr-2" style={{ color: "white" }} >&nbsp; Download Excel</span>
                    </CSVLink>
                </div>
                <div class="">


                    <div class="p-2">
                        <div style={{ textAlign: "end", paddingBottom: "5px" }}>
                            {/* <Link to="/admin_panel" class="btn bg-gradient-primary btn-sm mb-0 mr-2" style={{ color: "white" }} >&nbsp; Go Back</Link> */}

                        </div>
                        <div class="card">
                            <div class="card-header pb-0 px-3">
                                <h6 class="mb-2">Beauty Kit Request</h6>
                            </div>
                            <div class="">
                                {/* <div style={{ textAlign: "end", paddingBottom: "5px" }}>
                        <a class="btn bg-gradient-primary btn-sm mb-0" style={{ color: "white" }} onClick={() => changeStatus()}>+&nbsp; Add Rent Details</a>
                    </div> */}
                                <div class="card">
                                    <div class="table-responsive fix-width scroll-inner">
                                        <table class="table table-bordered table-hover">
                                            <thead class="thead-dark" style={{ position: "sticky", top: "0" }}>
                                                <tr>
                                                    <th class="col">Parlour Name</th>
                                                    <th class="col ps-2">Kit Type</th>
                                                    <th class="col ps-2">Payment Type</th>
                                                    <th class="col ps-2">Paid Amount</th>
                                                    <th class="col ps-2">Phone No</th>
                                                    <th class="col ps-2">Pin Code</th>
                                                    <th class="col ps-2">Address</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {csvdata?.map(({ id, data }, index) => (
                                                    <>
                                                        <tr>
                                                            <td>
                                                                <p class="text-xs font-weight-bold mb-0">{data.parlour_name}</p>
                                                                {/* <p class="text-xs text-secondary mb-0">24 Nov 2022</p> */}
                                                            </td>
                                                            <td>
                                                                <div class="d-flex px-2 py-1">

                                                                    <div class="d-flex flex-column justify-content-center">
                                                                        <h6 class="mb-0 text-xs">{data.kit_type}</h6>
                                                                    </div>
                                                                </div>
                                                            </td>                     <td>
                                                                <div class="d-flex px-2 py-1">

                                                                    <div class="d-flex flex-column justify-content-center">
                                                                        <h6 class="mb-0 text-xs">{data.payment_type}</h6>
                                                                    </div>
                                                                </div>
                                                            </td>                     <td>
                                                                <div class="d-flex px-2 py-1">

                                                                    <div class="d-flex flex-column justify-content-center">
                                                                        <h6 class="mb-0 text-xs">{data.paid_amount}</h6>
                                                                    </div>
                                                                </div>
                                                            </td>                     <td>
                                                                <div class="d-flex px-2 py-1">

                                                                    <div class="d-flex flex-column justify-content-center">
                                                                        <h6 class="mb-0 text-xs">{data.phone_no}</h6>
                                                                    </div>
                                                                </div>
                                                            </td>                     <td>
                                                                <div class="d-flex px-2 py-1">

                                                                    <div class="d-flex flex-column justify-content-center">
                                                                        <h6 class="mb-0 text-xs">{data.pincode}</h6>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td style={{ whiteSpace: 'pre-wrap' }}>
                                                                <p class="text-xs font-weight-bold mb-0" style={{ width: "15rem" }}>{data.address}</p>

                                                            </td>


                                                        </tr>

                                                    </>
                                                ))}


                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>


            </main>


        </>
    )
}

export default BeautyKitRequest;