import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import db from "./Firebase";
import { CSVLink } from "react-csv";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Moment from 'moment';
import { extendMoment } from 'moment-range';

const PAGE_SIZE = 500;

function ClickedLogs() {
    const navigate = useNavigate();
    const moment = extendMoment(Moment);
    const [data, setData] = useState([]);
    const [csvdata, setCsvdata] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [lastDoc, setLastDoc] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [loading, setLoading] = useState(false);  // Loading state for delete operation
    const [deleteMessage, setDeleteMessage] = useState("");  // Message to show after delete operation

    const handleStartDateChange = (date) => {
        setStartDate(date);
    };

    const handleEndDateChange = (date) => {
        setEndDate(date);
    };

    const handleDeleteData = async () => {
        console.log("processing...");
        if (!startDate || !endDate) {
            alert("Please select both start and end dates.");
            return;
        }

        setLoading(true);  // Set loading state to true when starting the delete operation
        setDeleteMessage("");  // Clear any previous delete message

        try {
            const startTimestamp = moment(startDate).startOf('day').toDate();
            const endTimestamp = moment(endDate).endOf('day').toDate();

            const querySnapshot = await db.collection("clicked_logs")
                .where('created_date', '>=', startTimestamp)
                .where('created_date', '<=', endTimestamp)
                .get();

            const batchSize = 500;  // Firestore's batch limit is 500 operations
            const totalDocs = querySnapshot.size;
            const batches = Math.ceil(totalDocs / batchSize);
            let deletedDocs = 0;

            for (let i = 0; i < batches; i++) {
                const batch = db.batch();
                const docsToDelete = querySnapshot.docs.slice(i * batchSize, (i + 1) * batchSize);
                docsToDelete.forEach(doc => {
                    batch.delete(doc.ref);
                });
                await batch.commit();
                deletedDocs += docsToDelete.length;
            }

            setDeleteMessage(`Successfully deleted ${deletedDocs} documents.`);
        } catch (error) {
            console.error("Error deleting data:", error);
            setDeleteMessage("An error occurred while deleting data.");
        } finally {
            setLoading(false);  // Set loading state to false after the delete operation
        }
    };

    useEffect(() => {
        const storedUsername = localStorage.getItem("email");
        const storedPassword = localStorage.getItem("password");
        const storedRemember = localStorage.getItem("remember");

        if (storedRemember === "false" || !storedUsername || !storedPassword) {
            navigate('/signin');
        }
    }, [navigate]);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        const usersData = [];
        const parlourData = [];

        let query = db.collection("clicked_logs").orderBy("created_date", "desc");

        if (lastDoc) {
            query = query.startAfter(lastDoc);
        }

        query = query.limit(PAGE_SIZE);

        const snapshot = await query.get();

        snapshot.docs.forEach((doc) => {
            usersData.push({
                id: doc.id,
                data: doc.data(),
            });
        });

        const parlourSnapshot = await db.collection("portfolio_details").get();

        parlourSnapshot.docs.forEach((doc) => {
            parlourData.push({
                id: doc.id,
                data: doc.data(),
            });
        });

        const mergedData = usersData.map((user) => {
            const parlour = parlourData.find((p) => p.data.uid === user.data.uid);
            return {
                ...user,
                parlour: parlour ? parlour.data : "NA",
            };
        });

        setData((prevData) => [...prevData, ...mergedData]);
        setLastDoc(snapshot.docs[snapshot.docs.length - 1]);
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const filteredData = data.filter((item) => {
        const username = (item.data.username || '').toLowerCase();
        const module = (item.data.module || '').toLowerCase();
        const layer_1 = (item.data.layer_1 || '').toLowerCase();
        const layer_2 = (item.data.layer_2 || '').toLowerCase();
        const layer_3 = (item.data.layer_3 || '').toLowerCase();
        const layer_4 = (item.data.layer_4 || '').toLowerCase();
        const layer_5 = (item.data.layer_5 || '').toLowerCase();
        const query = (searchQuery || '').toLowerCase();

        return (
            username.includes(query) ||
            module.includes(query) ||
            layer_1.includes(query) ||
            layer_2.includes(query) ||
            layer_3.includes(query) ||
            layer_4.includes(query) ||
            layer_5.includes(query)
        );
    });

    const prepareCSVData = () => {
        const csvData = filteredData.map((item) => ({
            username: item.data.username,
            module: item.data.module,
            layer_1: item.data.layer_1,
            layer_2: item.data.layer_2,
            layer_3: item.data.layer_3,
            layer_4: item.data.layer_4,
            layer_5: item.data.layer_5,
            created_date: moment(item.data.created_date.toDate().toISOString()).format("MM/DD/YY, h:mm a"),
            portfolio: item.parlour ? "Yes" : 'No'
        }));

        return csvData;
    };

    const loadMoreData = () => {
        fetchData();
    };

    return (
        <>
            <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg">
                <div className="p-2" style={{ paddingBottom: "5px", display: "flex", justifyContent: "end" }}>
                    <div>
                        <label>Start Date:</label>
                        <DatePicker
                            selected={startDate}
                            onChange={handleStartDateChange}
                            selectsStart
                            startDate={startDate}
                            endDate={endDate}
                            className="form-control form-control-sm"
                        />
                    </div>
                    <div>
                        <label>End Date:</label>
                        <DatePicker
                            selected={endDate}
                            onChange={handleEndDateChange}
                            selectsEnd
                            startDate={startDate}
                            endDate={endDate}
                            className="form-control form-control-sm"
                        />
                    </div>
                    <button onClick={handleDeleteData} style={{ height: "2rem", marginTop: "2rem", marginLeft: "1rem" }}>
                        {loading ? "Deleting..." : "Delete Data"}
                    </button>
                </div>
                {deleteMessage && <div className="alert alert-info">{deleteMessage}</div>}
                <div class="p-2" style={{ paddingBottom: "5px", display: "flex", justifyContent: "space-between" }}>
                    <Link to="/admin_panel">
                        <img src="/images/menubutton.png" class="menu-toggle" alt="Responsive image" style={{ height: "2rem" }} />
                    </Link>
                    {/* <CSVLink data={prepareCSVData()} headers={headers} filename={"clicked_logs.csv"}>
                        <span class="btn bg-gradient-secondary btn-sm mb-0 mr-2" style={{ color: "white" }}>
                            &nbsp; Download Excel
                        </span>
                    </CSVLink> */}
                </div>
                <div class="">
                    <div class="p-2">
                        <div style={{ textAlign: "end", paddingBottom: "5px" }}>
                            <button onClick={loadMoreData}>Load More</button>
                        </div>
                        <div class="card">
                            <div class="card-header pb-0 px-3">
                                <h6 class="mb-2">User's Clicked Logs || {`Records : ${filteredData.length}`}</h6>
                            </div>
                            <input
                                type="text"
                                className="form-control form-control-sm mb-2"
                                placeholder="Search by Username, Modules, or Layers"
                                value={searchQuery}
                                onChange={handleSearchChange}
                            />
                            <table class="table align-items-center mb-0 fl-table">
                                <thead>
                                    <tr style={{ textAlign: "left" }}>
                                        <th class="ps-2">S.No</th>
                                        <th class="ps-2">Username</th>
                                        <th>Module</th>
                                        <th class="ps-2">Layer_1</th>
                                        <th class="ps-2">Layer_2</th>
                                        <th class="ps-2">Layer_3</th>
                                        <th class="ps-2">Layer_4</th>
                                        <th class="ps-2">Layer_5</th>
                                        <th class="ps-2">Created Date</th>
                                        <th class="ps-2">Portfolio</th>
                                    </tr>
                                </thead>
                            </table>
                            <div class="card-body">
                                <div class="table-responsive" style={{ maxHeight: "400px", overflowY: "scroll" }}>
                                    <table class="table align-items-center mb-0">
                                        <tbody style={{ textAlign: "left" }}>
                                            {filteredData?.map(({ id, data, parlour }, index) => (
                                                <tr key={id}>
                                                    <td>
                                                        <p class="text-xs mb-0">{index + 1}.</p>
                                                    </td>
                                                    <td>
                                                        <a href={data.username} style={{ color: "blue", cursor: "pointer" }} target="_blank" class="mb-0 text-xs">{data.username}</a>
                                                    </td>
                                                    <td>
                                                        <p class="text-xs mb-0">{data.module}</p>
                                                    </td>
                                                    <td>
                                                        <p class="text-xs mb-0">{data.layer_1}</p>
                                                    </td>
                                                    <td>
                                                        <p class="text-xs mb-0">{data.layer_2}</p>
                                                    </td>
                                                    <td>
                                                        <p class="text-xs mb-0">{data.layer_3}</p>
                                                    </td>
                                                    <td>
                                                        <p class="text-xs mb-0">{data.layer_4}</p>
                                                    </td>
                                                    <td>
                                                        <p class="text-xs mb-0">{data.layer_5}</p>
                                                    </td>
                                                    <td>
                                                        <div class="d-flex px-2 py-1">
                                                            <div class="d-flex flex-column justify-content-center">
                                                                <h6 class="mb-0 text-xs">{moment(data.created_date.toDate().toISOString()).format("MM/DD/YY, h:mm a")}</h6>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <p class="text-xs mb-0">{parlour ? parlour.uid ? "Yes" : "No" : ""}</p>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
}

export default ClickedLogs;



// import React, { useState, useEffect } from 'react';
// import { useNavigate, Link, useParams } from 'react-router-dom';
// import db from "./Firebase";
// import firebaseApp from "./Firebase";
// import { arrayUnion } from "firebase/firestore";
// import { storage } from './Firebase';
// import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
// import Moment from 'moment';
// import { extendMoment } from 'moment-range';
// import ViewAllRequests from './ViewAllRequests';
// import ViewAppData from './ViewAppData';
// import { CSVLink } from "react-csv";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";

// const PAGE_SIZE = 500;

// function ClickedLogs() {
//     const navigate = useNavigate();
//     const moment = extendMoment(Moment);
//     const [data, setData] = useState([]);
//     const [csvdata, setCsvdata] = useState([]);
//     const [searchQuery, setSearchQuery] = useState("");
//     const [lastDoc, setLastDoc] = useState(null);
//     const [startDate, setStartDate] = useState(null);
//     const [endDate, setEndDate] = useState(null);

//     const handleStartDateChange = (date) => {
//         setStartDate(date);
//     };

//     const handleEndDateChange = (date) => {
//         setEndDate(date);
//     };

//     const handleDeleteData = async () => {
//         console.log("processingggg")
//         try {
//             if (startDate && endDate) {
//                 const startTimestamp = moment(startDate).startOf('day').toDate();
//                 const endTimestamp = moment(endDate).endOf('day').toDate();

//                 await db.collection("clicked_logs")
//                     .where('created_date', '>=', startTimestamp)
//                     .where('created_date', '<=', endTimestamp)
//                     .get()
//                     .then(querySnapshot => {
//                         querySnapshot.forEach(doc => {
//                             doc.ref.delete();
//                         });
//                     });
//                 alert("Data deleted successfully!");
//             } else {
//                 alert("Please select both start and end dates.");
//             }
//         } catch (error) {
//             console.error("Error deleting data:", error);
//             alert("An error occurred while deleting data.");
//         }
//     };

//     useEffect(() => {
//         const storedUsername = localStorage.getItem("email");
//         const storedPassword = localStorage.getItem("password");
//         const storedRemember = localStorage.getItem("remember");

//         if (storedRemember === "false" || !storedUsername || !storedPassword) {
//             navigate('/signin');
//         }
//     }, []);

//     const headers = [
//         "Username",
//         "Module",
//         "Layer_1",
//         "Layer_2",
//         "Layer_3",
//         "Layer_4",
//         "Layer_5",
//         "Created Date",
//         "Portfolio"
//         // Add more column labels as needed
//     ];

//     // useEffect(() => {
//     //     const usersData = [];
//     //     const parlourData = [];

//     //     db.collection("clicked_logs").orderBy("created_date", "desc").onSnapshot((snapshot) => {
//     //         usersData.length = 0; // Clear the array
//     //         snapshot.docs.forEach((doc) => {
//     //             usersData.push({
//     //                 id: doc.id,
//     //                 data: doc.data(),
//     //             });
//     //         });
//     //         mergeData();
//     //     });

//     //     // Fetch data from "parlour_name" collection
//     //     db.collection("portfolio_details").onSnapshot((snapshot) => {
//     //         parlourData.length = 0; // Clear the array
//     //         snapshot.docs.forEach((doc) => {
//     //             parlourData.push({
//     //                 id: doc.id,
//     //                 data: doc.data(),
//     //             });
//     //         });
//     //         mergeData();
//     //     });


//     //     function mergeData() {
//     //         if (usersData.length > 0 && parlourData.length > 0) {
//     //             // Merge the data based on the username
//     //             const mergedData = usersData.map((user) => {
//     //                 const parlour = parlourData.find((p) => p.data.uid === user.data.uid);
//     //                 return {
//     //                     ...user,
//     //                     parlour: parlour ? parlour.data : "NA",
//     //                 };
//     //             });

//     //             // Now, mergedData contains the merged information
//     //             setData(mergedData);
//     //             // console.log(mergedData)
//     //         }
//     //     }

//     //     // // Fetch data from "users_location" collection
//     //     // db.collection("clicked_logs").orderBy("created_date", "desc").onSnapshot((snapshot) => {
//     //     //     usersData.length = 0; // Clear the array
//     //     //     snapshot.docs.forEach((doc) => {
//     //     //         usersData.push({
//     //     //             id: doc.id,
//     //     //             data: doc.data(),
//     //     //         });
//     //     //     });
//     //     //     // mergeData();
//     //     //     setData(usersData);
//     //     // });



//     //     // // Fetch data from "parlour_name" collection
//     //     // db.collection("parlour_name").onSnapshot((snapshot) => {
//     //     //     parlourData.length = 0; // Clear the array
//     //     //     snapshot.docs.forEach((doc) => {
//     //     //         parlourData.push({
//     //     //             id: doc.id,
//     //     //             data: doc.data(),
//     //     //         });
//     //     //     });
//     //     //     mergeData();
//     //     // });

//     //     // // Function to merge data when both sets are available
//     //     // function mergeData() {
//     //     //     if (usersData.length > 0 && parlourData.length > 0) {
//     //     //         // Merge the data based on the username
//     //     //         const mergedData = usersData.map((user) => {
//     //     //             const parlour = parlourData.find((p) => p.data.username === user.data.username);
//     //     //             return {
//     //     //                 ...user,
//     //     //                 parlour: parlour ? parlour.data : null,
//     //     //             };
//     //     //         });

//     //     //         // Now, mergedData contains the merged information
//     //     //         setData(mergedData);
//     //     //     }
//     //     // }
//     // }, []);


//     useEffect(() => {
//         // Fetch initial data
//         fetchData();
//     }, []);

//     const fetchData = async () => {
//         const usersData = [];
//         const parlourData = [];

//         let query = db.collection("clicked_logs").orderBy("created_date", "desc");

//         if (lastDoc) {
//             query = query.startAfter(lastDoc);
//         }

//         query = query.limit(PAGE_SIZE);

//         const snapshot = await query.get();

//         snapshot.docs.forEach((doc) => {
//             usersData.push({
//                 id: doc.id,
//                 data: doc.data(),
//             });
//         });

//         // Fetch data from "parlour_name" collection
//         const parlourSnapshot = await db.collection("portfolio_details").get();

//         parlourSnapshot.docs.forEach((doc) => {
//             parlourData.push({
//                 id: doc.id,
//                 data: doc.data(),
//             });
//         });

//         // Merge the data based on the username
//         const mergedData = usersData.map((user) => {
//             const parlour = parlourData.find((p) => p.data.uid === user.data.uid);
//             return {
//                 ...user,
//                 parlour: parlour ? parlour.data : "NA",
//             };
//         });

//         // Now, mergedData contains the merged information
//         setData((prevData) => [...prevData, ...mergedData]);
//         setLastDoc(snapshot.docs[snapshot.docs.length - 1]); // Set the last document for pagination
//     };

//     const handleSearchChange = (event) => {
//         setSearchQuery(event.target.value);
//     };


//     // Function to filter data based on the search query
//     // const filteredData = data.filter((item) => {
//     //     const username = item.data.username.toLowerCase();
//     //     const module = item.data.module.toLowerCase();
//     //     const layer_1 = item.data.layer_1.toLowerCase();
//     //     const layer_2 = item.data.layer_2.toLowerCase();
//     //     const layer_3 = item.data.layer_3.toLowerCase();
//     //     const layer_4 = item.data.layer_4.toLowerCase();
//     //     const layer_5 = item.data.layer_5.toLowerCase();
//     //     const query = searchQuery.toLowerCase();

//     //     return (
//     //         username.includes(query) ||
//     //         module.includes(query) ||
//     //         layer_1.includes(query) ||
//     //         layer_2.includes(query) ||
//     //         layer_3.includes(query) ||
//     //         layer_4.includes(query) ||
//     //         layer_5.includes(query)
//     //     );
//     // });

//     // Function to filter data based on the search query
//     const filteredData = data.filter((item) => {
//         const username = (item.data.username || '').toLowerCase();
//         const module = (item.data.module || '').toLowerCase();
//         const layer_1 = (item.data.layer_1 || '').toLowerCase();
//         const layer_2 = (item.data.layer_2 || '').toLowerCase();
//         const layer_3 = (item.data.layer_3 || '').toLowerCase();
//         const layer_4 = (item.data.layer_4 || '').toLowerCase();
//         const layer_5 = (item.data.layer_5 || '').toLowerCase();
//         const query = (searchQuery || '').toLowerCase();

//         return (
//             username.includes(query) ||
//             module.includes(query) ||
//             layer_1.includes(query) ||
//             layer_2.includes(query) ||
//             layer_3.includes(query) ||
//             layer_4.includes(query) ||
//             layer_5.includes(query)
//         );
//     });


//     // Function to convert data to CSV format
//     const prepareCSVData = () => {
//         const csvData = filteredData.map((item, index) => ([
//             item.data.username,
//             item.data.module,
//             item.data.layer_1,
//             item.data.layer_2,
//             item.data.layer_3,
//             item.data.layer_4,
//             item.data.layer_5,
//             moment(item.data.created_date.toDate().toISOString()).format("MM/DD/YY, h:mm a"),
//             item.parlour ? "Yes" : 'No',
//             item.parlour ? item.parlour.uid ? "Yes" : "No" : '',
//             // Add more columns as needed
//         ]));

//         return csvData;
//     };



//     const loadMoreData = () => {
//         fetchData();
//     };


//     return (
//         <>
//             <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg">
//                 <div className="p-2" style={{ paddingBottom: "5px", display: "flex", justifyContent: "end" }}>
//                     <div>
//                         <label>Start Date:</label>
//                         <DatePicker
//                             selected={startDate}
//                             onChange={handleStartDateChange}
//                             selectsStart
//                             startDate={startDate}
//                             endDate={endDate}
//                             className="form-control form-control-sm"
//                         />
//                     </div>
//                     <div>
//                         <label>End Date:</label>
//                         <DatePicker
//                             selected={endDate}
//                             onChange={handleEndDateChange}
//                             selectsEnd
//                             startDate={startDate}
//                             endDate={endDate}
//                             className="form-control form-control-sm"
//                         />
//                     </div>
//                     <button onClick={handleDeleteData} style={{ height: "2rem", marginTop: "2rem", marginLeft: "1rem" }}>Delete Data</button>

//                 </div>
//                 <div class="p-2" style={{ paddingBottom: "5px", display: "flex", justifyContent: "space-between" }}>
//                     <Link to="/admin_panel">
//                         <img src="/images/menubutton.png" class="menu-toggle" alt="Responsive image" style={{ height: "2rem" }} />
//                     </Link>
//                     <CSVLink data={prepareCSVData()} headers={headers} filename={"clicked_logs.csv"}>
//                         <span class="btn bg-gradient-secondary btn-sm mb-0 mr-2" style={{ color: "white" }}>
//                             &nbsp; Download Excel
//                         </span>
//                     </CSVLink>
//                 </div>
//                 <div class="">

//                     <div class="p-2">
//                         <div style={{ textAlign: "end", paddingBottom: "5px" }}>

//                             <button onClick={loadMoreData}>Load More</button>
//                         </div>
//                         <div class="card">
//                             <div class="card-header pb-0 px-3">
//                                 <h6 class="mb-2">User's Clicked Logs || {`Records : ${filteredData.length}`}</h6>
//                             </div>
//                             <input
//                                 type="text"
//                                 className="form-control form-control-sm mb-2"
//                                 placeholder="Search by Username, Modules, or Layers"
//                                 value={searchQuery}
//                                 onChange={handleSearchChange}
//                             />
//                             <table class="table align-items-center mb-0 fl-table" >
//                                 <thead >
//                                     <tr style={{ textAlign: "left" }}>
//                                         <th class=" ps-2">S.No</th>
//                                         <th class=" ps-2">Username</th>
//                                         <th class="">Module</th>
//                                         <th class=" ps-2">Layer_1</th>
//                                         <th class=" ps-2">Layer_2</th>
//                                         <th class=" ps-2">Layer_3</th>
//                                         <th class=" ps-2">Layer_4</th>
//                                         <th class=" ps-2">Layer_5</th>
//                                         <th class=" ps-2">Created Date</th>
//                                         <th class=" ps-2">Portfolio</th>
//                                     </tr>
//                                 </thead>
//                             </table>

//                             <div class="card-body">
//                                 <div class="table-responsive" style={{ maxHeight: "400px", overflowY: "scroll" }}>
//                                     <table class="table align-items-center mb-0" >
//                                         {/* <thead >
//                                             <tr style={{ textAlign: "left" }}>
//                                                 <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">S.No</th>
//                                                 <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Username</th>
//                                                 <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Module</th>
//                                                 <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Layer_1</th>
//                                                 <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Layer_2</th>
//                                                 <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Layer_3</th>
//                                                 <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Layer_4</th>
//                                                 <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Layer_5</th>
//                                                 <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Created Date</th>
//                                             </tr>
//                                         </thead> */}
//                                         <tbody style={{ textAlign: "left" }}>
//                                             {filteredData?.map(({ id, data, parlour }, index) => (
//                                                 <tr key={id}>
//                                                     <td>
//                                                         <p class="text-xs mb-0">{index + 1}.</p>
//                                                     </td>
//                                                     <td>
//                                                         <a href={data.username} style={{ color: "blue", cursor: "pointer" }} target="_blank" class="mb-0 text-xs">{data.username}</a>
//                                                         {/* <p class="text-xs mb-0">{data.username}</p> */}
//                                                     </td>
//                                                     <td>
//                                                         <p class="text-xs mb-0">{data.module}</p>
//                                                     </td>
//                                                     <td>
//                                                         <p class="text-xs mb-0">{data.layer_1}</p>
//                                                     </td>
//                                                     <td>
//                                                         <p class="text-xs mb-0">{data.layer_2}</p>
//                                                     </td>

//                                                     <td>
//                                                         <p class="text-xs mb-0">{data.layer_3}</p>
//                                                     </td>
//                                                     <td>
//                                                         <p class="text-xs mb-0">{data.layer_4}</p>
//                                                     </td>
//                                                     <td>
//                                                         <p class="text-xs mb-0">{data.layer_5}</p>
//                                                     </td>

//                                                     <td>
//                                                         <div class="d-flex px-2 py-1">
//                                                             <div class="d-flex flex-column justify-content-center">
//                                                                 <h6 class="mb-0 text-xs">{moment(data.created_date.toDate().toISOString()).format("MM/DD/YY, h:mm a")}</h6>
//                                                             </div>
//                                                         </div>
//                                                     </td>
//                                                     <td>
//                                                         <p class="text-xs mb-0">{parlour ? parlour.uid ? "Yes" : "NO" : ""}</p>
//                                                     </td>

//                                                     {/* Other table cells */}
//                                                 </tr>
//                                             ))}
//                                         </tbody>
//                                     </table>

//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </main>
//         </>
//     );
// }

// export default ClickedLogs;
