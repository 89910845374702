import React, { useState, useEffect } from 'react';
import { useNavigate, Link, useParams } from 'react-router-dom';
import db from "./Firebase";
import { arrayUnion } from "firebase/firestore";
import { storage } from './Firebase';
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import ViewAllRequests from './ViewAllRequests';
import { CSVLink } from "react-csv";

function RentRequest() {
    const moment = extendMoment(Moment);
    const [data, setData] = useState([]);
    const [csvdata, setCsvdata] = useState([]);

    const headers = [
        { label: "Parlour Name", key: "parlour_name" },
        { label: "Phone Number", key: "phone_no" },
        { label: "Paid Amount", key: "paid_amount" },
        { label: "Payment ID", key: "payment_id" },
        { label: "Payment Type", key: "payment_type" },
        { label: "Number Of Hours", key: "no_of_hours" },
        { label: "Address", key: "address" },
        { label: "Approved On", key: "approved_on" },
        { label: "Created Date", key: "created_date" },
        { label: "Need Consumables", key: "need_consumables" },
        { label: "Pincode", key: "pincode" },
        { label: "Selected Date/Time", key: "selected_dateTime" },
        { label: "Selected Location", key: "selected_location" },
        { label: "Status", key: "status" },
    ];

    useEffect(() => {
        db.collection("rent_request").onSnapshot((snapshot) => {

            setData(
                snapshot.docs.map((doc) => ({
                    id: doc.id,
                    data: doc.data(),
                }))
            );
            setCsvdata(
                snapshot.docs.map((doc) => (
                    doc.data()
                ))
            );
        });

    }, []);

    return (
        <>
            <ViewAllRequests />
            <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">

                <div class="row">


                    <div class="mt-4 p-4">
                        <div style={{ textAlign: "end", paddingBottom: "5px" }}>
                            <Link to="/admin_panel" class="btn bg-gradient-primary btn-sm mb-0 mr-2" style={{ color: "white" }} >&nbsp; Go Back</Link>
                            <CSVLink data={csvdata} headers={headers}>
                                <span class="btn bg-gradient-secondary btn-sm mb-0 mr-2" style={{ color: "white" }} >&nbsp; Download Excel</span>
                            </CSVLink>
                        </div>
                        <div class="card">
                            <div class="card-header pb-0 px-3">
                                <h6 class="mb-2">Rent Request</h6>
                            </div>
                            <div class="container-fluid py-4">
                                {/* <div style={{ textAlign: "end", paddingBottom: "5px" }}>
                        <a class="btn bg-gradient-primary btn-sm mb-0" style={{ color: "white" }} onClick={() => changeStatus()}>+&nbsp; Add Rent Details</a>
                    </div> */}
                                <div class="card">
                                    <div class="table-responsive fix-width scroll-inner">
                                        <table class="table align-items-center mb-0">
                                            <thead>
                                                <tr>
                                                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Parlour Name</th>
                                                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Payment Type</th>
                                                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Paid Amount</th>
                                                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Phone No</th>
                                                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Pincode</th>
                                                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Selected Location</th>
                                                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">No Of Hrs</th>
                                                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Need Consumables</th>
                                                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Selected Date/Time</th>
                                                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Address</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {data?.map(({ id, data }, index) => (
                                                    <>
                                                        <tr>
                                                            <td>
                                                                <p class="text-xs font-weight-bold mb-0">{data.parlour_name}</p>
                                                                {/* <p class="text-xs text-secondary mb-0">{moment(data.created_date.toDate().toISOString()).format("MM/DD/YY, h:mm a")}</p> */}
                                                            </td>
                                                            <td>
                                                                <div class="d-flex px-2 py-1">
                                                                    <div class="d-flex flex-column justify-content-center">
                                                                        <h6 class="mb-0 text-xs">{data.payment_type}</h6>
                                                                    </div>
                                                                </div>
                                                            </td>

                                                            <td>
                                                                <div class="d-flex px-2 py-1">
                                                                    <div class="d-flex flex-column justify-content-center">
                                                                        <h6 class="mb-0 text-xs">{data.paid_amount}</h6>
                                                                    </div>
                                                                </div>
                                                            </td>

                                                            <td>
                                                                <div class="d-flex px-2 py-1">
                                                                    <div class="d-flex flex-column justify-content-center">
                                                                        <h6 class="mb-0 text-xs">{data.phone_no}</h6>
                                                                    </div>
                                                                </div>
                                                            </td>

                                                            <td>
                                                                <div class="d-flex px-2 py-1">
                                                                    <div class="d-flex flex-column justify-content-center">
                                                                        <h6 class="mb-0 text-xs">{data.pincode}</h6>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div class="d-flex px-2 py-1">
                                                                    <div class="d-flex flex-column justify-content-center">
                                                                        <h6 class="mb-0 text-xs">{data.selected_location}</h6>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div class="d-flex px-2 py-1">
                                                                    <div class="d-flex flex-column justify-content-center">
                                                                        <h6 class="mb-0 text-xs">{data.no_of_hours}</h6>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div class="d-flex px-2 py-1">
                                                                    <div class="d-flex flex-column justify-content-center">
                                                                        <h6 class="mb-0 text-xs">{data.need_consumables}</h6>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div class="d-flex px-2 py-1">
                                                                    <div class="d-flex flex-column justify-content-center">
                                                                        <h6 class="mb-0 text-xs">{moment(data.selected_dateTime.toDate().toISOString()).format("MM/DD/YY, h:mm a")}</h6>
                                                                    </div>
                                                                </div>
                                                            </td>

                                                            <td>
                                                                <div class="d-flex px-2 py-1">
                                                                    <div class="d-flex flex-column justify-content-center">
                                                                        <h6 class="mb-0 text-xs">{data.address}</h6>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>

                                                    </>
                                                ))}


                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </main>


        </>
    )
}

export default RentRequest;