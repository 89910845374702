import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import db from "./Firebase";
import { CSVLink } from "react-csv";

function Appointments() {
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [personaldata, setPersonaldata] = useState([]);
    const [userAppointmentCounts, setUserAppointmentCounts] = useState({});
    const [sortBy, setSortBy] = useState('totalReviews');
    const [sortDirection, setSortDirection] = useState('asc');
    const [searchQuery, setSearchQuery] = useState('');
    const [dataLimit, setDataLimit] = useState(20);

    const headers = [
        { label: "Full Name", key: "full_name" },
        { label: "Phone No", key: "phone_no" },
        { label: "Username", key: "username" },
        { label: "Count", key: "count" },
    ];

    useEffect(() => {
        const storedUsername = localStorage.getItem("email");
        const storedPassword = localStorage.getItem("password");
        const storedRemember = localStorage.getItem("remember");

        if (storedRemember === "false" || !storedUsername || !storedPassword) {
            navigate('/signin');
        }
    }, []);

    useEffect(() => {
        const fetchAppointmentCounts = async () => {
            const appointmentSnapshot = await db.collection("appointments_data").get();
            const appointmentCounts = {};
            appointmentSnapshot.docs.forEach((doc) => {
                const uid = doc.data().uid;
                appointmentCounts[uid] = appointmentCounts[uid] ? appointmentCounts[uid] + 1 : 1;
            });
            setUserAppointmentCounts(appointmentCounts);
        };

        const fetchPersonalDetails = async () => {
            const personalSnapshot = await db.collection("personal_details").orderBy("created_date", "desc").get();
            const personalDetails = personalSnapshot.docs.map((doc) => ({
                id: doc.id,
                data: doc.data(),
            }));
            setPersonaldata(personalDetails);
        };

        fetchAppointmentCounts();
        fetchPersonalDetails();
    }, []);

    const handleSearch = (event) => {
        setSearchQuery(event.target.value);
    };

    const filteredData = personaldata.filter(({ data }) => {
        const full_name = data.full_name;
        const phone_no = data.phone_no.toString();

        const searchLowercase = searchQuery.toLowerCase();
        return (
            full_name.toLowerCase().includes(searchLowercase) ||
            phone_no.includes(searchQuery)
        );
    });

    const sortedData = filteredData.slice().sort((a, b) => {
        const valueA = a.data[sortBy];
        const valueB = b.data[sortBy];

        if (valueA < valueB) {
            return sortDirection === 'asc' ? -1 : 1;
        }
        if (valueA > valueB) {
            return sortDirection === 'asc' ? 1 : -1;
        }
        return 0;
    });

    const limitedData = sortedData.slice(0, dataLimit);

    const csvData = sortedData.map(({ id, data }) => ({
        full_name: data.full_name,
        phone_no: data.phone_no,
        username: data.username,
        count: userAppointmentCounts[id] || 0,
    }));

    const loadMoreData = () => {
        setDataLimit(dataLimit + 20);
    };

    return (
        <>
            <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
                <div className="p-2" style={{ paddingBottom: "5px", display: "flex", justifyContent: "space-between" }}>
                    <Link to="/admin_panel"><img src="/images/menubutton.png" className="menu-toggle" alt="Responsive image" style={{ height: "2rem" }} /></Link>
                    <CSVLink data={csvData} headers={headers} filename={"appointments.csv"}>
                        <span className="btn bg-gradient-secondary btn-sm mb-0 mr-2" style={{ color: "white" }} >&nbsp; Download Excel</span>
                    </CSVLink>
                </div>
                <div className="">
                    <div className="p-2">
                        <div className="card">
                            <div className="card-header pb-0 px-3">
                                <h6 className="mb-2">All Appointments</h6>
                            </div>
                            <input
                                type="text"
                                className="form-control form-control-sm"
                                placeholder="Search by name / phone_no"
                                value={searchQuery}
                                onChange={handleSearch}
                            />
                            <div className="">
                                <div className="card">
                                    <div className="table-responsive fix-width scroll-inner">
                                        <table className="table table-bordered table-hover">
                                            <thead className="thead-dark" style={{ position: "sticky", top: "0" }}>
                                                <tr style={{ textAlign: "left" }}>
                                                    <th className="ps-2">Full Name</th>
                                                    <th className="">Phone</th>
                                                    <th className="ps-2">Username</th>
                                                    <th className="">Appointment Count</th>
                                                </tr>
                                            </thead>
                                            <tbody style={{ textAlign: "left" }}>
                                                {limitedData.map(({ id, data }) => (
                                                    <tr key={id}>
                                                        <td>
                                                            <p className="text-xs font-weight-bold mb-0">{data.full_name}</p>
                                                        </td>
                                                        <td>
                                                            <div className="d-flex px-2 py-1">
                                                                <div className="d-flex flex-column justify-content-center">
                                                                    <h6 className="mb-0 text-xs">{data.phone_no}</h6>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="d-flex px-2 py-1">
                                                                <div className="d-flex flex-column justify-content-center">
                                                                    <h6 className="mb-0 text-xs">{data.username}</h6>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="d-flex px-2 py-1">
                                                                <div className="d-flex flex-column justify-content-center">
                                                                    <h6 className="mb-0 text-xs">{userAppointmentCounts[id] || 0}</h6>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>

                                    </div>
                                    <div className="" style={{ paddingTop: "2rem" }}>
                                        <button onClick={loadMoreData} className="btn btn-dark">Load More</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
}

export default Appointments;



// import React, { useState, useEffect } from 'react';
// import { useNavigate, Link, useParams } from 'react-router-dom';
// import db from "./Firebase";
// import { arrayUnion } from "firebase/firestore";
// import { storage } from './Firebase';
// import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
// import Moment from 'moment';
// import { extendMoment } from 'moment-range';
// import ViewAllRequests from './ViewAllRequests';
// import { CSVLink } from "react-csv";

// function Appointments() {
//     const navigate = useNavigate();
//     const moment = extendMoment(Moment);
//     const [data, setData] = useState([]);
//     const [personaldata, setPersonaldata] = useState([]);
//     const [userAppointmentCounts, setUserAppointmentCounts] = useState([]);
//     const [sortBy, setSortBy] = useState('totalReviews');
//     const [sortDirection, setSortDirection] = useState('asc');
//     const [searchQuery, setSearchQuery] = useState('');
//     const [reviewCountFilter, setReviewCountFilter] = useState(0);

//     const headers = [
//         { label: "Full Name", key: "full_name" },
//         { label: "Phone No", key: "phone_no" },
//         { label: "Username", key: "username" },
//         { label: "Count", key: "count" },
//     ];


//     useEffect(() => {
//         const storedUsername = localStorage.getItem("email");
//         const storedPassword = localStorage.getItem("password");
//         const storedRemember = localStorage.getItem("remember");

//         if (storedRemember === "false" || !storedUsername || !storedPassword) {
//             navigate('/signin');
//         }
//     }, []);

//     useEffect(() => {
//         db.collection("appointments_data").onSnapshot((snapshot) => {
//             const appointmentCounts = {};
//             snapshot.docs.forEach((doc) => {
//                 const uid = doc.data().uid;
//                 appointmentCounts[uid] = appointmentCounts[uid] ? appointmentCounts[uid] + 1 : 1;
//             });
//             setUserAppointmentCounts(appointmentCounts);
//         });

//         db.collection("personal_details").orderBy("created_date", "desc").onSnapshot((snapshot) => {
//             const personalDetails = snapshot.docs.map((doc) => ({
//                 id: doc.id,
//                 data: doc.data(),
//             }));
//             const sortedPersonalData = personalDetails.sort((a, b) => {
//                 const countA = userAppointmentCounts[a.id] || 0;
//                 const countB = userAppointmentCounts[b.id] || 0;
//                 return sortDirection === 'desc' ? countA - countB : countB - countA;
//             });
//             setPersonaldata(sortedPersonalData);
//         });
//     }, [sortDirection, userAppointmentCounts]);

//     const handleSearch = (event) => {
//         setSearchQuery(event.target.value);
//     };

//     const filteredData = personaldata.filter(({ id, data }) => {
//         const full_name = data.full_name;
//         const phone_no = data.phone_no.toString();

//         const searchLowercase = searchQuery.toLowerCase();
//         return (
//             full_name.toLowerCase().includes(searchLowercase) ||
//             phone_no.includes(searchQuery)
//         );
//     });

//     const sortedData = filteredData.slice().sort((a, b) => {
//         const valueA = a.data[sortBy];
//         const valueB = b.data[sortBy];

//         if (valueA < valueB) {
//             return sortDirection === 'asc' ? -1 : 1;
//         }
//         if (valueA > valueB) {
//             return sortDirection === 'asc' ? 1 : -1;
//         }
//         return 0;
//     });

//     const csvData = sortedData.map(({ id, data }, index) => ({
//         full_name: data.full_name,
//         phone_no: data.phone_no,
//         username: data.username,
//         count: userAppointmentCounts[id] || 0,
//     }));

//     return (
//         <>
//             <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
//                 <div class="p-2" style={{ paddingBottom: "5px", display: "flex", justifyContent: "space-between" }}>
//                     {/* <span class="btn bg-gradient-secondary btn-sm mb-0 mr-2" style={{ color: "white" }} >&nbsp; Download Excel</span> */}
//                     <Link to="/admin_panel"><img src="/images/menubutton.png" class="menu-toggle" alt="Responsive image" style={{ height: "2rem" }} /></Link>
//                     <CSVLink data={csvData} headers={headers} filename={"appointments.csv"}>
//                         <span class="btn bg-gradient-secondary btn-sm mb-0 mr-2" style={{ color: "white" }} >&nbsp; Download Excel</span>
//                     </CSVLink>
//                 </div>
//                 <div className="">
//                     <div className="p-2">

//                         <div className="card">
//                             <div className="card-header pb-0 px-3">
//                                 <h6 className="mb-2">All Appointments</h6>
//                             </div>
//                             <input
//                                 type="text"
//                                 className="form-control form-control-sm"
//                                 placeholder="Search by name / phone_no"
//                                 value={searchQuery}
//                                 onChange={handleSearch}
//                             />
//                             <div className="">
//                                 <div className="card">
//                                     <div class="table-responsive fix-width scroll-inner">
//                                         <table class="table table-bordered table-hover">
//                                             <thead class="thead-dark" style={{ position: "sticky", top: "0" }}>
//                                                 <tr style={{ textAlign: "left" }}>
//                                                     <th className=" ps-2">Full Name</th>
//                                                     <th className="">Phone</th>
//                                                     <th className=" ps-2">Username</th>
//                                                     <th className="">Appointment Count</th>
//                                                 </tr>
//                                             </thead>
//                                             <tbody style={{ textAlign: "left" }}>
//                                                 {sortedData.map(({ id, data }, index) => (
//                                                     <tr key={id}>
//                                                         <td>
//                                                             <p className="text-xs font-weight-bold mb-0">{data.full_name}</p>
//                                                         </td>
//                                                         <td>
//                                                             <div className="d-flex px-2 py-1">
//                                                                 <div className="d-flex flex-column justify-content-center">
//                                                                     <h6 className="mb-0 text-xs">{data.phone_no}</h6>
//                                                                 </div>
//                                                             </div>
//                                                         </td>
//                                                         <td>
//                                                             <div className="d-flex px-2 py-1">
//                                                                 <div className="d-flex flex-column justify-content-center">
//                                                                     <h6 className="mb-0 text-xs">{data.username}</h6>
//                                                                 </div>
//                                                             </div>
//                                                         </td>
//                                                         <td>
//                                                             <div className="d-flex px-2 py-1">
//                                                                 <div className="d-flex flex-column justify-content-center">
//                                                                     <h6 className="mb-0 text-xs">{userAppointmentCounts[id] || 0}</h6>
//                                                                 </div>
//                                                             </div>
//                                                         </td>
//                                                     </tr>
//                                                 ))}
//                                             </tbody>
//                                         </table>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </main>
//         </>
//     )
// }

// export default Appointments;





// import React, { useState, useEffect } from 'react';
// import { useNavigate, Link, useParams } from 'react-router-dom';
// import db from "./Firebase";
// import { arrayUnion } from "firebase/firestore";
// import { storage } from './Firebase';
// import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
// import Moment from 'moment';
// import { extendMoment } from 'moment-range';
// import ViewAllRequests from './ViewAllRequests';
// import { CSVLink } from "react-csv";

// function Appointments() {
//     const moment = extendMoment(Moment);
//     const [data, setData] = useState([]);
//     const [wdata, setWdata] = useState([]);
//     const [csvdata, setCsvdata] = useState([]);
//     const [sortBy, setSortBy] = useState('totalReviews');
//     const [sortDirection, setSortDirection] = useState('asc');
//     const [searchQuery, setSearchQuery] = useState('');
//     const [reviewCountFilter, setReviewCountFilter] = useState(0);

//     useEffect(() => {
//         db.collection("appointments").orderBy("booked_date", "desc").onSnapshot((snapshot) => {
//             setData(
//                 snapshot.docs.map((doc) => ({
//                     id: doc.id,
//                     data: doc.data(),
//                 }))
//             );
//         });
//     }, []);



//     const handleSearch = (event) => {
//         setSearchQuery(event.target.value);
//     };

//     const filteredData = data.filter(({ id, data }) => {
//         const parlour = data.parlour;

//         const searchLowercase = searchQuery.toLowerCase();
//         return (
//             parlour.toLowerCase().includes(searchLowercase)
//         );
//     });

//     const sortedData = filteredData.slice().sort((a, b) => {
//         const valueA = a.data[sortBy];
//         const valueB = b.data[sortBy];

//         if (valueA < valueB) {
//             return sortDirection === 'asc' ? -1 : 1;
//         }
//         if (valueA > valueB) {
//             return sortDirection === 'asc' ? 1 : -1;
//         }
//         return 0;
//     });


//     return (
//         <>
//             <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
//                 <div className="row">
//                     <div className="mt-4 p-4">
//                         <div className="">
//                             {/* <h6 className="mb-2">All Portfolio Reviews</h6> */}
//                             <div className="d-flex justify-content-end">

//                             </div>
//                         </div>
//                         <div className="card">
//                             <div className="card-header pb-0 px-3">
//                                 <h6 className="mb-2">All Appointments</h6>
//                             </div>
//                             <input
//                                 type="text"
//                                 className="form-control form-control-sm mb-2"
//                                 placeholder="Search By parlour name"
//                                 value={searchQuery}
//                                 onChange={handleSearch}
//                             />
//                             <div className="">
//                                 <div className="card">
//                                     <div className="table-responsive">
//                                         <table className="table align-items-center mb-0">
//                                             <thead>
//                                                 <tr style={{ textAlign: "left" }}>
//                                                     <th className=" ps-2">Parlour Name</th>
//                                                     <th className="">Customer Name</th>
//                                                     <th className=" ps-2">Customer Phone</th>
//                                                     <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Created Date</th>
//                                                 </tr>
//                                             </thead>
//                                             <tbody style={{ textAlign: "left" }}>
//                                                 {sortedData.map(({ id, data }, index) => (
//                                                     <tr key={id}>
//                                                         <td>
//                                                             <p className="text-xs font-weight-bold mb-0">{data.parlour}</p>
//                                                         </td>
//                                                         <td>
//                                                             <div className="d-flex px-2 py-1">
//                                                                 <div className="d-flex flex-column justify-content-center">
//                                                                     <h6 className="mb-0 text-xs">{data.name}</h6>
//                                                                 </div>
//                                                             </div>
//                                                         </td>
//                                                         <td>
//                                                             <div className="d-flex px-2 py-1">
//                                                                 <div className="d-flex flex-column justify-content-center">
//                                                                     <h6 className="mb-0 text-xs">{data.phone}</h6>
//                                                                 </div>
//                                                             </div>
//                                                         </td>

//                                                         <td>
//                                                             <div className="d-flex px-2 py-1">
//                                                                 <div className="d-flex flex-column justify-content-center">
//                                                                     <h6 className="mb-0 text-xs">{moment(data.booked_date.toDate().toISOString()).format("MM/DD/YY, h:mm a")}</h6>
//                                                                 </div>
//                                                             </div>
//                                                         </td>

//                                                     </tr>
//                                                 ))}
//                                             </tbody>
//                                         </table>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </main>
//         </>
//     )
// }

// export default Appointments;