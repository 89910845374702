import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyCG0ezOxdzfRXoDlgsMtl9VZW5aTPZsKqY",
    authDomain: "youvati.firebaseapp.com",
    projectId: "youvati",
    storageBucket: "youvati.appspot.com",
    messagingSenderId: "657189143152",
    appId: "1:683881504876:android:e53bcef0a3fa5b117bb849",
};
firebase.initializeApp(firebaseConfig);
var auth = firebase.auth();
export { auth, firebase };