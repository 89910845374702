import React, { useState, useEffect } from 'react';
import { useNavigate, Link, useParams } from 'react-router-dom';
import db from "./Firebase";
import { arrayUnion, collection } from "firebase/firestore";
import { storage } from './Firebase';
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import ViewAllRequests from './ViewAllRequests';
import { CSVLink } from "react-csv";
import axios from 'axios';

function PremiumCardRequest() {
    const navigate = useNavigate();
    const moment = extendMoment(Moment);
    const [data, setData] = useState([]);
    const [uid, setUid] = useState([]);
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [uuid, setUuid] = useState("");
    const [csvdata, setCsvdata] = useState([]);
    const [redID, setRegID] = useState("");
    const [type, setType] = useState("");
    const [filteredData, setFilteredData] = useState([]);
    const [filterDate, setFilterDate] = useState(null); // State for date filter
    const [filterStatus, setFilterStatus] = useState(null); // State for status filter

    const headers = [
        { label: "Parlour Name", key: "parlour_name" },
        { label: "Phone Number", key: "phone_number" },
        { label: "Email", key: "email" },
        { label: "Payment", key: "payment" },
        { label: "Payment ID", key: "payment_id" },
        { label: "Message", key: "message" },
        { label: "Description", key: "description" },
        { label: "Image", key: "selected_card_url" },
        { label: "Created Date", key: "created_date", dataFormat: "MM/DD/YY, h:mm a" },
    ];

    const [showPopup, setShowPopup] = useState(false);  // State for showing/hiding the modal
    const [selectedPayment, setSelectedPayment] = useState({}); // State for storing selected row data

    useEffect(() => {
        const storedUsername = localStorage.getItem("email");
        const storedPassword = localStorage.getItem("password");
        const storedRemember = localStorage.getItem("remember");

        if (storedRemember === "false" || !storedUsername || !storedPassword) {
            navigate('/signin');
        }
    }, []);

    const [doneCount, setDoneCount] = useState(0);

    const [dataDict, setDataDict] = useState({}); // Initialize dataDict as an empty object

    useEffect(() => {
        db.collection("personal_details")
            .orderBy("created_date", "desc")
            .onSnapshot((snapshot) => {
                const newDataDict = {}; // Create a new object to store updated data
                snapshot.docs.forEach((doc) => {
                    const data = doc.data();
                    newDataDict[data.phone_no] = data.full_name;
                });
                setDataDict(newDataDict); // Update the state with the new object
                console.log(newDataDict)
            });
    }, []);




    useEffect(() => {
        const fetchData = () => {
            try {
                db.collection("premium_card_request")
                    .where("payment", "==", "49")
                    .orderBy("created_date", "desc")
                    .onSnapshot((snapshot) => {
                        const dataArray = [];
                        let count = 0;

                        setCsvdata(
                            snapshot.docs.map((doc) => {
                                const data = doc.data();
                                return {
                                    ...data,
                                    created_date: moment(data.created_date.toDate().toISOString()).format("MM/DD/YY, h:mm a"),
                                };
                            })
                        );

                        snapshot.forEach((doc) => {
                            const documentData = {
                                id: doc.id,
                                data: doc.data(),
                            };

                            dataArray.push(documentData);

                            // Check the status field and increment count
                            if (documentData.data.status === '1') {
                                count += 1;
                            }
                        });

                        setData(dataArray);
                        setFilteredData(dataArray); // Initialize filteredData with all data
                        setDoneCount(count);
                        console.log(dataArray)
                    });
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    // useEffect(() => {
    //     db.collection("premium_card_request").orderBy("created_date", "desc").onSnapshot((snapshot) => {

    //         setData(
    //             snapshot.docs.map((doc) => ({
    //                 id: doc.id,
    //                 data: doc.data(),
    //             }))
    //         );

    //         setCsvdata(
    //             snapshot.docs.map((doc) => {
    //                 const data = doc.data();
    //                 return {
    //                     ...data,
    //                     created_date: moment(data.created_date.toDate().toISOString()).format("MM/DD/YY, h:mm a"),
    //                 };
    //             })
    //         );
    //     });

    // }, []);

    const notifyApp = (msg) => {
        // Call the user's CollectionReference to add a new user
        db.collection("app_notification").add({ 'message': msg, 'link': "", 'status': "0", "uid": uuid, 'created_date': new Date() }).then(() => {
            console.log("notified");
        })
    }

    const initialFormStateFeedback = { status: '' }
    const [submit, setSubmit] = useState(initialFormStateFeedback)

    const handleChange2 = async (e, msg) => {
        const { id, value } = e.target
        setSubmit({ ...submit, [id]: value })
        if (e.target.value == "1") {
            db.collection("premium_card_request").doc(uid).update({ 'status': "1", 'updated_date': new Date() }).then(() => {
                console.log("user added");
            })
            notifyApp(msg = `Hi ${name} we have sent your Premium business card, Go to Advertise section to download.`);
            sendWhatsapp()
            type == "49" ? handleSubmitPush() : handleSubmitPushsmg();
        } else {
            db.collection("premium_card_request").doc(uid).update({ 'status': "0", 'updated_date': new Date() }).then(() => {
                console.log("user added");
            })
        }
    }

    useEffect(() => {
        let filtered = data;

        // Filter by date
        if (filterDate) {
            filtered = filtered.filter(item => moment(item.data.created_date.toDate()).isSame(filterDate, 'day'));
        }

        // Filter by status
        if (filterStatus !== null && filterStatus !== undefined && filterStatus !== "") {
            filtered = filtered.filter(item => {
                // If status field is not available, assume it as "0"
                const status = item.data.status || "0";
                return status === filterStatus;
            });
        }

        setFilteredData(filtered);
    }, [filterDate, filterStatus, data]);

    const collect = (id, name, mainID, phone, pay) => {
        setUid(id)
        setName(name)
        setUuid(mainID)
        setPhone(phone)
        setType(pay)

        db.collection("notification_ids")
            .where('uid', '==', mainID)
            .onSnapshot((snapshot) => {
                snapshot.forEach((doc) => {
                    const regId = doc.data().reg_id;
                    setRegID(doc.data().reg_id);
                    console.log('reg_id:', regId);
                    // If you want to store the reg_id values in a state variable
                    // or use it elsewhere, you can set it accordingly.
                });
            });
    }


    const sendWhatsapp = (e) => {
        // console.log("wahtsapppppppppppppppppppppppppppppppppppppp")
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer EABMKlZCrATXIBAPOZBZBPqsdfot34fq0FTipZBQf3RiXZAapyCOqMay09HfhSxoB8bd5ZA7RUnLWhd0JkXtACJQ682THiDEMhQhyPOkeui8Cb8P72WPDhihUZBCkHtPLSgsmuZA6iRY6VunDDIjPA5mATQQITVXOECHqkZB5E2pzWSC2AaUp0wZCWR");
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify(
            {
                "messaging_product": "whatsapp",
                "recipient_type": "individual",
                "to": `91${phone}`,
                "type": "template",
                "template": {
                    "name": "visiting_card_done",
                    "language": {
                        "code": "en_US",
                        "policy": "deterministic"
                    },
                    "components": [
                        {
                            "type": "header",
                            "parameters": [
                                {
                                    "type": "image",
                                    "image": { "link": "https://firebasestorage.googleapis.com/v0/b/youvati.appspot.com/o/ss.jpg?alt=media&token=ad2688bb-fef3-4b28-8f02-59e73c4a4cab" }
                                }
                            ]
                        },
                        {
                            "type": "body",
                            "parameters": [
                                { "type": "text", "text": `${name}` },
                                { "type": "text", "text": "Premium Business Card" }
                            ]
                        }
                    ]
                }
            }
        );

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch("https://graph.facebook.com/v14.0/104489945783566/messages", requestOptions)
            .then(response => response.text())
            .then(result => console.log(result))
            .catch(error => {
                console.log('error', error)
            });
    };

    const handleSubmitPush = () => {
        // Use the single registration ID
        const singleRegId = redID; // Replace with your actual registration ID

        console.log(singleRegId);

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "key=AAAAnzqDoGw:APA91bHNYZDEZn5paZqVOV6evl4npGLdy7V1iixsNCGekPti0mkcTft5lHb9WruMaFjOdJrGVR9kkFzfC00HMfmvPUFo4sQhvXmXoom7clqOhXqMc7y5erUF75zJIxAzMZeuCtXx6PTS");

        // Check if the registration ID is valid before proceeding
        if (!singleRegId || singleRegId.trim() === "") {
            console.log("No valid registration ID to send the push notification.");
            return;
        }

        const raw = JSON.stringify({
            "registration_ids": [singleRegId],
            "notification": {
                "body": "Download karo, Share karo, Grow karo 🥳",
                "title": "Your Premium Visiting Card Is Ready!",
                "android_channel_id": "youvati",
                "image": "",
                "sound": true
            },
            "data": {
                "type": "msg",
                "id": "advertise",
                "image": ""
            }
        });

        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch("https://fcm.googleapis.com/fcm/send", requestOptions)
            .then(response => response.json())
            .then(data => {
                // Handle the response as needed
                console.log(data);

                // Additional handling if required
                if (data.success) {
                    // Swal.fire(`Push notification sent successfully to ${singleRegId}.`);
                    console.log(`Push notification sent successfully to ${singleRegId}.`)
                } else {
                    // Swal.fire(`Failed to send push notification to ${singleRegId}.`);
                    console.log(`Failed to send push notification to ${singleRegId}.`)
                }
            })
            .catch(error => console.log('error', error));
    };

    const handleSubmitPushsmg = () => {
        // Use the single registration ID
        const singleRegId = redID; // Replace with your actual registration ID

        console.log(singleRegId);

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "key=AAAAnzqDoGw:APA91bHNYZDEZn5paZqVOV6evl4npGLdy7V1iixsNCGekPti0mkcTft5lHb9WruMaFjOdJrGVR9kkFzfC00HMfmvPUFo4sQhvXmXoom7clqOhXqMc7y5erUF75zJIxAzMZeuCtXx6PTS");

        // Check if the registration ID is valid before proceeding
        if (!singleRegId || singleRegId.trim() === "") {
            console.log("No valid registration ID to send the push notification.");
            return;
        }

        const raw = JSON.stringify({
            "registration_ids": [singleRegId],
            "notification": {
                "body": "Download karo, Share karo, Grow karo 🥳",
                "title": "Your Premium Graphic Card Is Ready!",
                "android_channel_id": "youvati",
                "image": "",
                "sound": true
            },
            "data": {
                "type": "msg",
                "id": "advertise",
                "image": ""
            }
        });

        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch("https://fcm.googleapis.com/fcm/send", requestOptions)
            .then(response => response.json())
            .then(data => {
                // Handle the response as needed
                console.log(data);

                // Additional handling if required
                if (data.success) {
                    // Swal.fire(`Push notification sent successfully to ${singleRegId}.`);
                    console.log(`Push notification sent successfully to ${singleRegId}.`)
                } else {
                    // Swal.fire(`Failed to send push notification to ${singleRegId}.`);
                    console.log(`Failed to send push notification to ${singleRegId}.`)
                }
            })
            .catch(error => console.log('error', error));
    };


    // Function to handle popup open and store selected payment details
    const handleUpdatePayment = (id, currentPaymentId, currentPaymentStatus) => {
        setSelectedPayment({
            id,
            paymentId: currentPaymentId,
            paymentStatus: currentPaymentStatus
        });
        setShowPopup(true); // Show the popup
    };



    // Handle form submission to update payment details
    const handlePaymentSubmit = (e) => {
        e.preventDefault();

        const { id, paymentId, paymentStatus } = selectedPayment;

        db.collection("premium_card_request").doc(id).update({
            payment_id: paymentId,
            payment_status: paymentStatus
        }).then(() => {
            console.log("Payment details updated successfully");
            setShowPopup(false); // Close the popup after update
        }).catch((error) => {
            console.error("Error updating payment details: ", error);
        });
    };


    return (
        <>
            {/* <ViewAllRequests /> */}
            <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
                <div class="p-2" style={{ paddingBottom: "5px", display: "flex", justifyContent: "space-between" }}>
                    {/* <span class="btn bg-gradient-secondary btn-sm mb-0 mr-2" style={{ color: "white" }} >&nbsp; Download Excel</span> */}
                    <Link to="/admin_panel"><img src="/images/menubutton.png" class="menu-toggle" alt="Responsive image" style={{ height: "2rem" }} /></Link>
                    <CSVLink data={csvdata} headers={headers}>
                        <span class="btn bg-gradient-secondary btn-sm mb-0 mr-2" style={{ color: "white" }} >&nbsp; Download Excel</span>
                    </CSVLink>
                </div>
                <div class="">


                    <div class="p-2">
                        <div class="card">
                            <div class="card-header pb-0 px-3">
                                <h6 class="mb-2">Premium Card Request</h6>
                            </div>
                            <div class="d-flex" style={{ justifyContent: "space-between" }}>
                                <div class="d-flex p-2" style={{ justifyContent: "center", fontWeight: "bold" }}>
                                    Total : {data.length} || Pending : {data.length - doneCount} || Done : {doneCount}
                                </div>
                                <div class="d-flex" style={{ padding: "6px" }}>
                                    <input type="date" id="filterDate" style={{ width: "11rem", marginRight: "2rem" }} className="form-control" value={filterDate ? moment(filterDate).format('YYYY-MM-DD') : ''} onChange={(e) => setFilterDate(e.target.value ? moment(e.target.value).toDate() : null)} />
                                    <select id="filterStatus" style={{ width: "11rem" }} className="form-control" onChange={(e) => { setFilterDate(null); setFilterStatus(e.target.value) }}>
                                        <option value="">All</option>
                                        <option value="0">Pending</option>
                                        <option value="1">Done</option>
                                    </select>
                                </div>
                            </div>
                            <div class="">
                                {/* <div style={{ textAlign: "end", paddingBottom: "5px" }}>
                        <a class="btn bg-gradient-primary btn-sm mb-0" style={{ color: "white" }} onClick={() => changeStatus()}>+&nbsp; Add Rent Details</a>
                    </div> */}
                                <div class="card">
                                    <div class="table-responsive fix-width scroll-inner">
                                        <table class="table table-bordered table-hover">
                                            <thead class="thead-dark" style={{ position: "sticky", top: "0" }}>
                                                <tr style={{ textAlign: "start" }}>
                                                    <th class="col">Owner Name</th>
                                                    <th class="col">Requested Name</th>
                                                    <th class="col">Phone No</th>
                                                    <th class="col">Email</th>
                                                    <th class="col">Address</th>
                                                    <th class="col">Payment</th>
                                                    <th class="col">Payment Status</th>
                                                    <th class="col">Created Date</th>
                                                    <th class="col">Updated Date</th>
                                                    <th class="col">Action</th>
                                                    <th class="col ps-2">Update Payment Status</th>
                                                </tr>

                                            </thead>
                                            <tbody style={{ textAlign: "start" }}>
                                                {filteredData?.map(({ id, data }, index) => (
                                                    <>
                                                        <tr>
                                                            <td>
                                                                <p class="text-xs font-weight-bold mb-0">{dataDict[data.phone_no]}</p>

                                                            </td>
                                                            <td>
                                                                <p class="text-xs font-weight-bold mb-0">{data.parlour_name}</p>
                                                                {/* <p class="text-xs text-secondary mb-0">{moment(data.created_date.toDate().toISOString()).format("MM/DD/YY, h:mm a")}</p> */}
                                                            </td>


                                                            <td>
                                                                <p class="text-xs font-weight-bold mb-0">{data.phone_no}</p>

                                                            </td>
                                                            <td>
                                                                <p class="text-xs font-weight-bold mb-0">{data.email}</p>

                                                            </td>

                                                            <td style={{ whiteSpace: 'pre-wrap' }}>
                                                                <p class="text-xs font-weight-bold mb-0" style={{ width: "15rem" }}>{data.message}</p>

                                                            </td>
                                                            <td >
                                                                <p class="text-xs font-weight-bold mb-0">{data.payment}</p>
                                                            </td>
                                                            <td >
                                                                <p class="text-xs font-weight-bold mb-0" style={{ color: data.payment_status === "Payment Failed" ? "red" : "green" }}>{data.payment_status}</p>
                                                            </td>
                                                            <td>
                                                                <p class="text-xs font-weight-bold mb-0">{moment(data.created_date.toDate().toISOString()).format("MMM Do YY, h:mm a")}</p>
                                                            </td>
                                                            <td>
                                                                <p class="text-xs font-weight-bold mb-0">
                                                                    {data.updated_date ? moment(data.updated_date.toDate().toISOString()).format("MMM Do YY, h:mm a") : ""}
                                                                </p>
                                                            </td>

                                                            {data.status == null ? <>
                                                                <td>
                                                                    <select class="form-control" id={index} name="status" onClick={() => collect(id, data.parlour_name, data.uid, data.phone_no, data.payment)} onChange={handleChange2} value={data.status} style={{ padding: "7px", width: "9rem", backgroundColor: data.status == 1 ? "#28a745" : "transparent" }} disabled={data.status == 1 ? true : false} >
                                                                        <option selected={data.status === "0" ? true : false} value="0">Pending</option>
                                                                        <option selected={data.status === "1" ? true : false} value="1">Done</option>
                                                                    </select>
                                                                </td>
                                                            </> : <>
                                                                <td>
                                                                    <select class="form-control" id="request_status" name="request_status" style={{ width: "8rem", background: "green", color: "white" }}>
                                                                        <option selected value="Done" disabled>Done</option>
                                                                    </select>
                                                                </td>
                                                            </>}

                                                            <td>
                                                                <button
                                                                    class="btn btn-primary"
                                                                    onClick={() => handleUpdatePayment(id, data.payment_id, data.payment_status)}
                                                                >
                                                                    Update Payment
                                                                </button>
                                                            </td>



                                                            {/* <td>
                                                                <div class="d-flex px-2 py-1">

                                                                    <div class="d-flex flex-column justify-content-center">
                                                                        <h6 class="mb-0 text-xs">{data.additional_info}</h6>
                                                                    </div>
                                                                </div>
                                                            </td> */}
                                                            {/* <td>
                                                                <div class="d-flex px-2 py-1">

                                                                    <div class="d-flex flex-column justify-content-center">
                                                                        <h6 class="mb-0 text-xs">
                                                                            {data.fields}
                                                                        </h6>

                                                                    </div>
                                                                </div>
                                                            </td> */}


                                                        </tr>

                                                    </>
                                                ))}


                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>


            </main>
            {/* Popup Modal for Updating Payment */}
            {showPopup && (
                <div class="modal show" style={{ display: 'block' }}>
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title">Update Payment</h5>
                                <button type="button" class="close" onClick={() => setShowPopup(false)}>&times;</button>
                            </div>
                            <div class="modal-body">
                                <form onSubmit={handlePaymentSubmit}>
                                    <div class="form-group">
                                        <label>Payment ID</label>
                                        <input
                                            type="text"
                                            class="form-control"
                                            value={selectedPayment.paymentId}
                                            onChange={(e) => setSelectedPayment({ ...selectedPayment, paymentId: e.target.value })}
                                            required
                                        />
                                    </div>
                                    <div class="form-group">
                                        <label>Payment Status</label>
                                        <select
                                            class="form-control"
                                            value={selectedPayment.paymentStatus}
                                            onChange={(e) => setSelectedPayment({ ...selectedPayment, paymentStatus: e.target.value })}
                                            required
                                        >
                                            <option value="Payment Failed">Payment Failed</option>
                                            <option value="Payment Successful">Payment Successful</option>
                                        </select>
                                    </div>
                                    <button type="submit" class="btn btn-success">Update</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            )}

        </>
    )
}

export default PremiumCardRequest;