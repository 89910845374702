import React, { useState, useEffect } from 'react';
import { useNavigate, Link, useParams } from 'react-router-dom';
import Swal from 'sweetalert2'
import db from "./Firebase";
import { arrayUnion } from "firebase/firestore";
import { storage } from './Firebase';
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import ViewAllRequests from './ViewAllRequests';
import ViewAppData from './ViewAppData';
import { CSVLink } from "react-csv";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function PushNotifications() {
    const navigate = useNavigate();
    const moment = extendMoment(Moment);
    const initialFormStateReminder = { title: '', description: '', image_url: '', type: '', id: '', created_date: new Date() }
    const [submitPush, setSubmitPush] = useState(initialFormStateReminder);
    const [data, setData] = useState([]);
    const [allData, setAlldata] = useState([]);
    const [csvdata, setCsvdata] = useState([]);
    const [uid, setUid] = useState('');
    const [searchQuery, setSearchQuery] = useState("");
    const [allRegIds, setAllRegIds] = useState([]);

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    useEffect(() => {
        const storedUsername = localStorage.getItem("email");
        const storedPassword = localStorage.getItem("password");
        const storedRemember = localStorage.getItem("remember");

        if (storedRemember === "false" || !storedUsername || !storedPassword) {
            navigate('/signin');
        }
    }, []);


    const openModalShow = () => {
        // alert("ooo")
        window.openModelshow();
    }

    const closeModel = () => {
        window.openModelNew();
    }

    const addNew = () => {
        openModalShow();
        setSingleID('')
        // setSubmitPush({ title: '', description: '', image_url: '', type: '', id: '', created_date: new Date() });
    }


    useEffect(() => {
        const usersData = [];
        const usersDataIds = [];

        // db.collection("personal_details").limit(50).onSnapshot((snapshot) => {
        db.collection("personal_details").onSnapshot((snapshot) => {
            usersData.length = 0;
            snapshot.docs.forEach((doc) => {
                usersData.push({
                    id: doc.id,
                    data: doc.data(),
                });
            });
            mergeData();
        });


        // db.collection("notification_ids").limit(50).onSnapshot((snapshot) => {
        db.collection("notification_ids").onSnapshot((snapshot) => {
            usersDataIds.length = 0;
            snapshot.docs.forEach((doc) => {
                usersDataIds.push({
                    id: doc.id,
                    data: doc.data(),
                });
            });
            mergeData();
        });

        function mergeData() {
            if (usersData.length > 0 && usersDataIds.length > 0) {
                // Merge the data based on the username
                const mergedData = usersData.map((user) => {
                    const idData = usersDataIds.find((p) => p.data.uid === user.id);
                    return {
                        ...user,
                        n_id: idData ? idData.data.reg_id : null,
                    };
                });

                // Now, mergedData contains the merged information
                setData(mergedData);
                setAlldata(mergedData)
                // console.log(mergedData)
            }
        }

    }, []);


    const handleStartDateChange = (date) => {
        setStartDate(date);
    };

    const handleEndDateChange = (date) => {
        setEndDate(date);
    };


    const [dataFilter, setDataFilter] = useState("");
    const [selectedPortfolio, setSelectedPortfolio] = useState('');
    const [dataFilterPortfolio, setDataFilterPortfolio] = useState("");


    const [dataFilterMybusiness, setDataFilterMybusiness] = useState("");
    const [selectedMybusiness, setSelectedMybusiness] = useState('');

    const [dataFilterWorkshop, setDataFilterWorkshop] = useState("");
    const [selectedWorkshop, setSelectedWorkshop] = useState('');


    const [dataFilterAdvertise, setDataFilterAdvertise] = useState("");
    const [selectedAdvertise, setSelectedAdvertise] = useState('');

    const handleFilter = (e) => {
        setSelectedPortfolio("")
        setSelectedMybusiness("")
        setSelectedWorkshop("")
        setSelectedAdvertise("")
        setDataFilter(e.target.value)
        const usersCertificates = [];
        console.log(e.target.value)
        if (e.target.value === "all users") {

            db.collection("personal_details").onSnapshot((snapshot) => {
                const usersData = [];

                snapshot.docs.forEach((doc) => {
                    const userData = {
                        id: doc.id,
                        data: doc.data(),
                    };

                    usersData.push(userData);
                });

                const phoneNumbers = usersData.map(cert => cert.data.phone_no);
                const filteredData = allData.filter(item => phoneNumbers.includes(item.data.phone_no));

                const idsss = filteredData.map(({ n_id }) => n_id);

                setData(filteredData);
                setAllRegIds(idsss);

                console.log("Users filteredData:", filteredData);
                console.log("Users idsss:", idsss);

                // Handle the data as needed
            });



        }

        if (e.target.value === "portfolio") {
            setSelectedPortfolio("yes")
            setSelectedMybusiness("")
            setSelectedWorkshop("")
            setSelectedAdvertise("")
        }
        if (e.target.value === "mybusiness") {
            setSelectedPortfolio("")
            setSelectedMybusiness("yes")
            setSelectedWorkshop("")
            setSelectedAdvertise("")

        }
        if (e.target.value === "workshop") {
            setSelectedPortfolio("")
            setSelectedMybusiness("")
            setSelectedWorkshop("yes")
            setSelectedAdvertise("")

        }
        if (e.target.value === "advertise") {
            setSelectedPortfolio("")
            setSelectedMybusiness("")
            setSelectedWorkshop("")
            setSelectedAdvertise("yes")

        }


    }

    const handleFilterPortfolio = (e) => {
        setDataFilterPortfolio(e.target.value)
        setSelectedPortfolio("yes")
        const usersDataPortfolio = [];
        console.log(e.target.value)
        if (e.target.value === "all portfolio users") {

            db.collection("portfolio_details").onSnapshot((snapshot) => {
                usersDataPortfolio.length = 0;
                snapshot.docs.forEach((doc) => {
                    console.log(doc.id)
                    usersDataPortfolio.push({
                        id: doc.id,
                        data: doc.data(),
                    });
                });

                mergeData();

            });

            function mergeData() {
                if (allData.length > 0 && usersDataPortfolio.length > 0) {
                    // Extract phone numbers from usersDataPortfolio
                    const phoneNumbers = usersDataPortfolio.map(cert => cert.data.phone_no);
                    console.log(phoneNumbers)

                    // Filter dataAll based on matching phone numbers
                    const filteredData = allData.filter(item => phoneNumbers.includes(item.data.phone_no));

                    // Set the state with the filtered data
                    const idsss = filteredData.map(({ n_id }) => n_id);


                    setData(filteredData);
                    setAllRegIds(idsss)
                }
            }

        } else if (e.target.value === "no portfolio") {
            db.collection("portfolio_details").onSnapshot((snapshot) => {
                usersDataPortfolio.length = 0;
                snapshot.docs.forEach((doc) => {
                    console.log(doc.id)
                    usersDataPortfolio.push({
                        id: doc.id,
                        data: doc.data(),
                    });
                });

                mergeData();

            });

            function mergeData() {
                if (allData.length > 0 && usersDataPortfolio.length > 0) {
                    // Extract phone numbers from usersDataPortfolio
                    const phoneNumbers = usersDataPortfolio.map(cert => cert.data.phone_no);
                    console.log(phoneNumbers)

                    // Filter dataAll based on matching phone numbers
                    const filteredData = allData.filter(item => !phoneNumbers.includes(item.data.phone_no));

                    // Set the state with the filtered data
                    const idsss = filteredData.map(({ n_id }) => n_id);


                    setData(filteredData);
                    setAllRegIds(idsss)
                }
            }

        } else if (e.target.value === "60% or below") {
            const sixty = [];
            db.collection("portfolio_details").onSnapshot((snapshot) => {
                const usersDataPortfolio = [];
                snapshot.docs.forEach((doc) => {
                    usersDataPortfolio.push({
                        id: doc.id,
                        data: doc.data(),
                    });
                });

                // Calculate completion percentage for each user in usersDataPortfolio
                const usersWithCompletionPercentage = usersDataPortfolio.map(user => ({
                    ...user,
                    completionPercentage: calculateCompletionPercentage(user.data),
                }));

                // Filter users with a completion percentage of 60 or below
                const filteredData = usersWithCompletionPercentage.filter(user => user.completionPercentage <= 60)
                    .map(user => ({ ...user, data: user.data }));

                // Set the state with the filtered data

                // setData(filteredData);
                // console.log(filteredData)
                if (allData.length > 0 && filteredData.length > 0) {
                    // Extract phone numbers from usersDataPortfolio
                    const phoneNumbers = filteredData.map(cert => cert.data.phone_no);
                    console.log(phoneNumbers)

                    // Filter dataAll based on matching phone numbers
                    const filteredDataFinal = allData.filter(item => phoneNumbers.includes(item.data.phone_no));

                    // Set the state with the filtered data

                    const idsss = filteredDataFinal.map(({ n_id }) => n_id);

                    setData(filteredDataFinal);
                    setAllRegIds(idsss)
                }
            });
        } else if (e.target.value === "80% completed") {
            const sixty = [];
            db.collection("portfolio_details").onSnapshot((snapshot) => {
                const usersDataPortfolio = [];
                snapshot.docs.forEach((doc) => {
                    usersDataPortfolio.push({
                        id: doc.id,
                        data: doc.data(),
                    });
                });

                // Calculate completion percentage for each user in usersDataPortfolio
                const usersWithCompletionPercentage = usersDataPortfolio.map(user => ({
                    ...user,
                    completionPercentage: calculateCompletionPercentage(user.data),
                }));

                // Filter users with a completion percentage of 60 or below
                const filteredData = usersWithCompletionPercentage.filter(user => user.completionPercentage >= 80)
                    .map(user => ({ ...user, data: user.data }));

                // Set the state with the filtered data

                // setData(filteredData);
                // console.log(filteredData)
                if (allData.length > 0 && filteredData.length > 0) {
                    // Extract phone numbers from usersDataPortfolio
                    const phoneNumbers = filteredData.map(cert => cert.data.phone_no);
                    console.log(phoneNumbers)

                    // Filter dataAll based on matching phone numbers
                    const filteredDataFinal = allData.filter(item => phoneNumbers.includes(item.data.phone_no));

                    // Set the state with the filtered data
                    const idsss = filteredDataFinal.map(({ n_id }) => n_id);

                    setData(filteredDataFinal);
                    setAllRegIds(idsss)
                }
            });
        }

    }

    const handleFilterMybusiness = (e) => {
        setDataFilterMybusiness(e.target.value)
        const usersDataMyBusiness = [];
        console.log(e.target.value)

        if (e.target.value === "No Appointmnet") {

            db.collection("appointments_data").onSnapshot((snapshot) => {
                usersDataMyBusiness.length = 0;
                snapshot.docs.forEach((doc) => {
                    // console.log(doc.id)
                    usersDataMyBusiness.push({
                        id: doc.id,
                        data: doc.data(),
                    });
                });

                mergeData();

            });

            function mergeData() {
                if (allData.length > 0 && usersDataMyBusiness.length > 0) {
                    // Extract phone numbers from usersDataMyBusiness
                    const uid = usersDataMyBusiness.map(cert => cert.data.uid);
                    // console.log(uid)

                    // Filter dataAll based on matching phone numbers
                    const filteredData = allData.filter(item => !uid.includes(item.id));

                    // Set the state with the filtered data

                    const idsss = filteredData.map(({ n_id }) => n_id);

                    setData(filteredData);
                    setAllRegIds(idsss)
                }
            }

        } else if (e.target.value === "No Khata") {

            db.collection("expenses").onSnapshot((snapshot) => {
                usersDataMyBusiness.length = 0;
                snapshot.docs.forEach((doc) => {
                    // console.log(doc.id)
                    usersDataMyBusiness.push({
                        id: doc.id,
                        data: doc.data(),
                    });
                });

                mergeData();

            });

            function mergeData() {
                if (allData.length > 0 && usersDataMyBusiness.length > 0) {
                    // Extract phone numbers from usersDataMyBusiness
                    const uid = usersDataMyBusiness.map(cert => cert.data.uid);
                    console.log(uid)

                    // Filter dataAll based on matching phone numbers
                    const filteredData = allData.filter(item => !uid.includes(item.id));

                    // Set the state with the filtered data
                    const idsss = filteredData.map(({ n_id }) => n_id);

                    setData(filteredData);
                    setAllRegIds(idsss)
                }
            }

        } else if (e.target.value === "No My Business") {
            const uniquePhoneNumbersSet = new Set();
            // Fetch data from business_card_request table
            db.collection("appointments_data").onSnapshot((snapshotR) => {
                const usersDataBusinessAppointment = [];
                snapshotR.docs.forEach((doc) => {
                    const userData = {
                        id: doc.id,
                        data: doc.data(),
                    };
                    usersDataBusinessAppointment.push(userData);

                    // Add phone_no to the set
                    uniquePhoneNumbersSet.add(userData.data.uid);
                });

                // Fetch data from premium_card_request table
                db.collection("expenses").onSnapshot((snapshotP) => {
                    const usersDataExpense = [];
                    snapshotP.docs.forEach((doc) => {
                        const userData = {
                            id: doc.id,
                            data: doc.data(),
                        };
                        usersDataExpense.push(userData);

                        // Add phone_no to the set
                        uniquePhoneNumbersSet.add(userData.data.uid);
                    });

                    // Now uniquePhoneNumbersSet contains unique phone numbers from both tables
                    const uniquePhoneNumbersArray = Array.from(uniquePhoneNumbersSet);
                    const filteredData = allData.filter(item => !uniquePhoneNumbersArray.includes(item.id));

                    // const idsss = filteredData.map(({ n_id }) => n_id);


                    // setData(filteredData);
                    // setAllRegIds(idsss)
                    // console.log(idsss)

                    const idsss = filteredData.map(({ n_id }) => n_id);

                    // Remove null and blank values from idsss
                    const filteredIdsss = idsss.filter(id => id !== null && id !== "");

                    setData(filteredData);
                    setAllRegIds(filteredIdsss);
                    console.log(filteredIdsss);



                    // Handle the array of unique phone numbers as needed
                    // console.log("usersDataBusinessCard:", usersDataBusinessCard);
                    // console.log("usersDataPremiumCard:", usersDataPremiumCard);
                    // console.log("Unique Phone Numbers:", uniquePhoneNumbersArray);
                });
            });

        }

    }


    // const handleFilterWorkshop = (e) => {
    //     setDataFilterWorkshop(e.target.value)
    //     const usersDataWorkshopR = [];
    //     const usersDataWorkshopA = [];
    //     const usersDataWorkshopO = [];
    //     console.log(e.target.value)

    //     if (e.target.value === "Attended Atleast 1") {

    //         db.collection("workshop_registration").onSnapshot((snapshot) => {
    //             usersDataWorkshopR.length = 0;
    //             snapshot.docs.forEach((doc) => {
    //                 console.log(doc.id)
    //                 usersDataWorkshopR.push({
    //                     id: doc.id,
    //                     data: doc.data(),
    //                 });
    //             });


    //         });

    //         db.collection("workshop_attendance").onSnapshot((snapshot) => {
    //             usersDataWorkshopA.length = 0;
    //             snapshot.docs.forEach((doc) => {
    //                 console.log(doc.id)
    //                 usersDataWorkshopA.push({
    //                     id: doc.id,
    //                     data: doc.data(),
    //                 });
    //             });
    //             mergeData();

    //         });
    //         function mergeData() {
    //             if (usersDataWorkshopR.length > 0 && usersDataWorkshopA.length > 0) {
    //                 // Extract phone numbers from usersDataWorkshop
    //                 const phone = usersDataWorkshopR.map(cert => cert.data.phone);
    //                 console.log(phone)

    //                 // Filter dataAll based on matching phone numbers
    //                 usersDataWorkshopO = usersDataWorkshopA.filter(item => phone.includes(item.data.phone_no));

    //                 // Set the state with the filtered data
    //                 // setData(filteredData);
    //                 // mergeData();
    //                 console.log(usersDataWorkshopO);
    //             }
    //         }


    //         // function mergeData() {
    //         //     if (allData.length > 0 && usersDataWorkshopO.length > 0) {
    //         //         // Extract phone numbers from usersDataWorkshop
    //         //         const phone = usersDataWorkshop.map(cert => cert.data.phone);
    //         //         console.log(phone)

    //         //         // Filter dataAll based on matching phone numbers
    //         //         const filteredData = allData.filter(item => phone.includes(item.data.phone_no));

    //         //         // Set the state with the filtered data
    //         //         setData(filteredData);
    //         //     }
    //         // }

    //     }

    // }

    const handleFilterWorkshop = (e) => {
        setDataFilterWorkshop(e.target.value);
        const usersDataWorkshopR = [];
        const usersDataWorkshopA = [];

        if (e.target.value === "Attended Atleast 1") {
            // Fetch data from workshop_registration table
            db.collection("workshop_registration").onSnapshot((snapshotR) => {
                usersDataWorkshopR.length = 0;
                snapshotR.docs.forEach((doc) => {
                    usersDataWorkshopR.push({
                        id: doc.id,
                        data: doc.data(),
                    });
                });

                // Fetch data from workshop_attendance table
                db.collection("workshop_attendance").onSnapshot((snapshotA) => {
                    usersDataWorkshopA.length = 0;
                    snapshotA.docs.forEach((doc) => {
                        usersDataWorkshopA.push({
                            id: doc.id,
                            data: doc.data(),
                        });
                    });

                    // Get unique phone numbers from workshop registration
                    const uniquePhoneNumbers = [...new Set(usersDataWorkshopR.map(user => user.data.phone_no))];

                    // Find users who attended at least once
                    const usersAttendedAtleastOnce = uniquePhoneNumbers.filter(phone => usersDataWorkshopA.some(user => user.data.phone_no === phone));

                    const filteredData = allData.filter(item => usersAttendedAtleastOnce.includes(item.data.phone_no));

                    // Set the state with the filtered data
                    const idsss = filteredData.map(({ n_id }) => n_id);

                    setData(filteredData);
                    setAllRegIds(idsss)

                    // Find users who attended more than once
                    // const usersAttendedMoreThanOnce = uniquePhoneNumbers.filter(phone => {
                    //     const attendanceCount = usersDataWorkshopA.filter(user => user.data.phone_no === phone).length;
                    //     return attendanceCount > 1;
                    // });

                    // console.log("Unique Phone Numbers:", uniquePhoneNumbers);
                    // console.log("Users Attended Atleast Once:", usersAttendedAtleastOnce);
                    // console.log("Users Attended More Than Once:", usersAttendedMoreThanOnce);

                });
            });
        } else if (e.target.value === "Attended More Than 1 Workshop") {
            // Fetch data from workshop_registration table
            db.collection("workshop_registration").onSnapshot((snapshotR) => {
                usersDataWorkshopR.length = 0;
                snapshotR.docs.forEach((doc) => {
                    usersDataWorkshopR.push({
                        id: doc.id,
                        data: doc.data(),
                    });
                });

                // Fetch data from workshop_attendance table
                db.collection("workshop_attendance").onSnapshot((snapshotA) => {
                    usersDataWorkshopA.length = 0;
                    snapshotA.docs.forEach((doc) => {
                        usersDataWorkshopA.push({
                            id: doc.id,
                            data: doc.data(),
                        });
                    });

                    // Get unique phone numbers from workshop registration
                    const uniquePhoneNumbers = [...new Set(usersDataWorkshopR.map(user => user.data.phone_no))];

                    // Find users who attended more than once
                    const usersAttendedMoreThanOnce = uniquePhoneNumbers.filter(phone => {
                        const attendanceCount = usersDataWorkshopA.filter(user => user.data.phone_no === phone).length;
                        return attendanceCount > 1;
                    });

                    const filteredData = allData.filter(item => usersAttendedMoreThanOnce.includes(item.data.phone_no));

                    // Set the state with the filtered data
                    const idsss = filteredData.map(({ n_id }) => n_id);

                    setData(filteredData);
                    setAllRegIds(idsss)

                });
            });
        } else if (e.target.value === "Registered But Not Attended") {
            // Fetch data from workshop_registration table
            db.collection("workshop_registration").onSnapshot((snapshotR) => {
                usersDataWorkshopR.length = 0;
                snapshotR.docs.forEach((doc) => {
                    usersDataWorkshopR.push({
                        id: doc.id,
                        data: doc.data(),
                    });
                });

                // Fetch data from workshop_attendance table
                db.collection("workshop_attendance").onSnapshot((snapshotA) => {
                    usersDataWorkshopA.length = 0;
                    snapshotA.docs.forEach((doc) => {
                        usersDataWorkshopA.push({
                            id: doc.id,
                            data: doc.data(),
                        });
                    });

                    // Get unique phone numbers from workshop registration
                    const uniquePhoneNumbers = [...new Set(usersDataWorkshopR.map(user => user.data.phone_no))];

                    const usersAttendedAtleastOnce = uniquePhoneNumbers.filter(phone => usersDataWorkshopA.some(user => user.data.phone_no === phone));

                    // Find users who attended more than once
                    const usersRegisteredButDidNotAttend = uniquePhoneNumbers.filter(phone => !usersAttendedAtleastOnce.includes(phone));

                    const filteredData = allData.filter(item => usersRegisteredButDidNotAttend.includes(item.data.phone_no));

                    // Set the state with the filtered data
                    const idsss = filteredData.map(({ n_id }) => n_id);

                    setData(filteredData);
                    setAllRegIds(idsss)

                });
            });
        } else if (e.target.value === "No Workshop Attended") {
            // Fetch data from workshop_registration table
            db.collection("workshop_registration").onSnapshot((snapshotR) => {
                usersDataWorkshopR.length = 0;
                snapshotR.docs.forEach((doc) => {
                    usersDataWorkshopR.push({
                        id: doc.id,
                        data: doc.data(),
                    });
                });

                // Fetch data from workshop_attendance table
                db.collection("workshop_attendance").onSnapshot((snapshotA) => {
                    usersDataWorkshopA.length = 0;
                    snapshotA.docs.forEach((doc) => {
                        usersDataWorkshopA.push({
                            id: doc.id,
                            data: doc.data(),
                        });
                    });

                    // Get unique phone numbers from workshop registration
                    const uniquePhoneNumbers = [...new Set(usersDataWorkshopR.map(user => user.data.phone_no))];

                    const filteredData = allData.filter(item => !uniquePhoneNumbers.includes(item.data.phone_no));

                    // Set the state with the filtered data
                    const idsss = filteredData.map(({ n_id }) => n_id);

                    setData(filteredData);
                    setAllRegIds(idsss)

                });
            });
        } else if (e.target.value === "December Downloads") {
            const currentDate = new Date();
            const currentMonth = currentDate.getMonth() + 1; // Adding 1 as months are zero-based
            const currentYear = currentDate.getFullYear();

            db.collection("personal_details").onSnapshot((snapshot) => {
                const usersDataCurrentMonth = [];

                snapshot.docs.forEach((doc) => {
                    const userData = {
                        id: doc.id,
                        data: doc.data(),
                    };

                    // Assuming the created_date is a timestamp field in Firestore
                    const createdDate = userData.data.created_date.toDate();
                    const createdMonth = createdDate.getMonth() + 1; // Adding 1 as months are zero-based
                    const createdYear = createdDate.getFullYear();

                    // Check if the record's created_date is in the current month and year
                    if (createdMonth === currentMonth && createdYear === currentYear) {
                        usersDataCurrentMonth.push(userData);
                    }
                });

                // Now usersDataCurrentMonth contains only users created in the current month
                const phoneNumbers = usersDataCurrentMonth.map(cert => cert.data.phone_no);
                const filteredData = allData.filter(item => phoneNumbers.includes(item.data.phone_no));

                const idsss = filteredData.map(({ n_id }) => n_id);


                setData(filteredData);
                setAllRegIds(idsss)

                // console.log("Users Created in Current Month:", usersDataCurrentMonth);
                // console.log("Users Created in Current Month:", phoneNumbers);
                // console.log("Users Created in Current Month:", filteredData);
                // console.log("setAllRegIds:", idsss);

                // Handle the data as needed
            });



        }
        // Handle other cases if needed
    };

    const fetchDataFromTable = async (table) => {
        const snapshot = await db.collection(table).get();
        return snapshot.docs.map((doc) => ({
            id: doc.id,
            data: doc.data(),
        }));
    };


    const handleFilterAdvertise = async (e) => {
        setDataFilterAdvertise(e.target.value)
        const usersDataAdvertise = [];
        console.log(e.target.value)

        if (e.target.value === "Free Visiting Card Applied but Not Premium") {

            db.collection("business_card_request").onSnapshot((snapshotR) => {
                const usersDataBusinessCard = [];
                snapshotR.docs.forEach((doc) => {
                    usersDataBusinessCard.push({
                        id: doc.id,
                        data: doc.data(),
                    });
                });

                // Fetch data from premium_card_request table
                db.collection("premium_card_request").onSnapshot((snapshotP) => {
                    const usersDataPremiumCard = [];
                    snapshotP.docs.forEach((doc) => {
                        usersDataPremiumCard.push({
                            id: doc.id,
                            data: doc.data(),
                        });
                    });

                    // Find users who requested a business card but did not request a premium card based on phone_no
                    const usersWithoutPremiumCard = usersDataBusinessCard.filter((businessCardUser) => {
                        return !usersDataPremiumCard.some((premiumCardUser) => premiumCardUser.data.phone_no === businessCardUser.data.phone_no);
                    });

                    const phoneNumbers = usersWithoutPremiumCard.map(cert => cert.data.phone_no);
                    const filteredData = allData.filter(item => phoneNumbers.includes(item.data.phone_no));

                    const idsss = filteredData.map(({ n_id }) => n_id);


                    setData(filteredData);
                    setAllRegIds(idsss)

                    // console.log(phoneNumbers)

                    // // Now usersWithoutPremiumCard contains users who requested a business card but not a premium card
                    // console.log("usersDataBusinessCard:", usersDataBusinessCard);
                    // console.log("usersDataPremiumCard:", usersDataPremiumCard);
                    // console.log("Users who requested a business card but not a premium card:", usersWithoutPremiumCard);

                    // Handle the data as needed
                });
            });

        } else if (e.target.value === "No Visiting Card Applied") {
            const uniquePhoneNumbersSet = new Set();
            // Fetch data from business_card_request table
            db.collection("business_card_request").onSnapshot((snapshotR) => {
                const usersDataBusinessCard = [];
                snapshotR.docs.forEach((doc) => {
                    const userData = {
                        id: doc.id,
                        data: doc.data(),
                    };
                    usersDataBusinessCard.push(userData);

                    // Add phone_no to the set
                    uniquePhoneNumbersSet.add(userData.data.phone_no);
                });

                // Fetch data from premium_card_request table
                db.collection("premium_card_request").onSnapshot((snapshotP) => {
                    const usersDataPremiumCard = [];
                    snapshotP.docs.forEach((doc) => {
                        const userData = {
                            id: doc.id,
                            data: doc.data(),
                        };
                        usersDataPremiumCard.push(userData);

                        // Add phone_no to the set
                        uniquePhoneNumbersSet.add(userData.data.phone_no);
                    });

                    // Now uniquePhoneNumbersSet contains unique phone numbers from both tables
                    const uniquePhoneNumbersArray = Array.from(uniquePhoneNumbersSet);
                    const filteredData = allData.filter(item => !uniquePhoneNumbersArray.includes(item.data.phone_no));

                    const idsss = filteredData.map(({ n_id }) => n_id);


                    setData(filteredData);
                    setAllRegIds(idsss)


                    // Handle the array of unique phone numbers as needed
                    // console.log("usersDataBusinessCard:", usersDataBusinessCard);
                    // console.log("usersDataPremiumCard:", usersDataPremiumCard);
                    // console.log("Unique Phone Numbers:", uniquePhoneNumbersArray);
                });
            });

        }
        // No SMG Applied
        else if (e.target.value === "No Banner Applied") {
            const uniquePhoneNumbersSet = new Set();
            // Fetch data from business_card_request table
            db.collection("banner_request").onSnapshot((snapshotR) => {
                const usersDataBusinessCard = [];
                snapshotR.docs.forEach((doc) => {
                    const userData = {
                        id: doc.id,
                        data: doc.data(),
                    };
                    usersDataBusinessCard.push(userData);

                    // Add phone_no to the set
                    uniquePhoneNumbersSet.add(userData.data.phone_no);
                });

                // Fetch data from premium_card_request table
                db.collection("premium_banner_request").onSnapshot((snapshotP) => {
                    const usersDataPremiumCard = [];
                    snapshotP.docs.forEach((doc) => {
                        const userData = {
                            id: doc.id,
                            data: doc.data(),
                        };
                        usersDataPremiumCard.push(userData);

                        // Add phone_no to the set
                        uniquePhoneNumbersSet.add(userData.data.phone_no);
                    });

                    // Now uniquePhoneNumbersSet contains unique phone numbers from both tables
                    const uniquePhoneNumbersArray = Array.from(uniquePhoneNumbersSet);
                    const filteredData = allData.filter(item => !uniquePhoneNumbersArray.includes(item.data.phone_no));

                    const idsss = filteredData.map(({ n_id }) => n_id);


                    setData(filteredData);
                    setAllRegIds(idsss)


                    // Handle the array of unique phone numbers as needed
                    // console.log("usersDataBusinessCard:", usersDataBusinessCard);
                    // console.log("usersDataPremiumCard:", usersDataPremiumCard);
                    // console.log("Unique Phone Numbers:", uniquePhoneNumbersArray);
                });
            });

        }
        else if (e.target.value === "No SMG Applied") {
            const uniquePhoneNumbersSet = new Set();
            // Fetch data from business_card_request table
            db.collection("social_media_graphics").onSnapshot((snapshotR) => {
                const usersDataBusinessCard = [];
                snapshotR.docs.forEach((doc) => {
                    const userData = {
                        id: doc.id,
                        data: doc.data(),
                    };
                    usersDataBusinessCard.push(userData);

                    // Add phone_no to the set
                    uniquePhoneNumbersSet.add(userData.data.phone_no);
                });

                // Fetch data from premium_card_request table
                db.collection("premium_card_request").where('payment', '==', "99").onSnapshot((snapshotP) => {
                    const usersDataPremiumCard = [];
                    snapshotP.docs.forEach((doc) => {
                        const userData = {
                            id: doc.id,
                            data: doc.data(),
                        };
                        usersDataPremiumCard.push(userData);

                        // Add phone_no to the set
                        uniquePhoneNumbersSet.add(userData.data.phone_no);
                    });

                    // Now uniquePhoneNumbersSet contains unique phone numbers from both tables
                    const uniquePhoneNumbersArray = Array.from(uniquePhoneNumbersSet);
                    const filteredData = allData.filter(item => !uniquePhoneNumbersArray.includes(item.data.phone_no));

                    const idsss = filteredData.map(({ n_id }) => n_id);


                    setData(filteredData);
                    setAllRegIds(idsss)


                    // Handle the array of unique phone numbers as needed
                    // console.log("usersDataBusinessCard:", usersDataBusinessCard);
                    // console.log("usersDataPremiumCard:", usersDataPremiumCard);
                    // console.log("Unique Phone Numbers:", uniquePhoneNumbersArray);
                });
            });

        } else if (e.target.value === "Free Banner Applied but Not Premium") {
            db.collection("banner_request").onSnapshot((snapshotR) => {
                const usersDataBusinessCard = [];
                snapshotR.docs.forEach((doc) => {
                    usersDataBusinessCard.push({
                        id: doc.id,
                        data: doc.data(),
                    });
                });

                // Fetch data from premium_card_request table
                db.collection("premium_banner_request").onSnapshot((snapshotP) => {
                    const usersDataPremiumCard = [];
                    snapshotP.docs.forEach((doc) => {
                        usersDataPremiumCard.push({
                            id: doc.id,
                            data: doc.data(),
                        });
                    });

                    // Find users who requested a business card but did not request a premium card based on phone_no
                    const usersWithoutPremiumCard = usersDataBusinessCard.filter((businessCardUser) => {
                        return !usersDataPremiumCard.some((premiumCardUser) => premiumCardUser.data.phone_no === businessCardUser.data.phone_no);
                    });

                    const phoneNumbers = usersWithoutPremiumCard.map(cert => cert.data.phone_no);
                    const filteredData = allData.filter(item => phoneNumbers.includes(item.data.phone_no));

                    const idsss = filteredData.map(({ n_id }) => n_id);


                    setData(filteredData);
                    setAllRegIds(idsss)

                });
            });

        } else if (e.target.value === "Free Banner and Free SMG, No Premium Banner and No Premium SMG") {
            const [freeBannerData, freeSMGData, premiumBannerData, premiumSMGData] = await Promise.all([
                fetchDataFromTable("banner_request"),
                fetchDataFromTable("social_media_graphics"),
                fetchDataFromTable("premium_banner_request"),
                fetchDataFromTable("premium_card_request"),
            ]);

            const uniquePhoneNumbersSet = new Set();

            // Add phone numbers from both free banner and free SMG tables
            freeBannerData.forEach((userData) => {
                uniquePhoneNumbersSet.add(userData.data.phone_no);
            });

            freeSMGData.forEach((userData) => {
                uniquePhoneNumbersSet.add(userData.data.phone_no);
            });

            // Remove phone numbers from premium banner and premium SMG tables
            premiumBannerData.forEach((userData) => {
                uniquePhoneNumbersSet.delete(userData.data.phone_no);
            });

            premiumSMGData.forEach((userData) => {
                uniquePhoneNumbersSet.delete(userData.data.phone_no);
            });

            const uniquePhoneNumbersArray = Array.from(uniquePhoneNumbersSet);
            const filteredData = allData.filter(item => uniquePhoneNumbersArray.includes(item.data.phone_no));

            setData(filteredData);
            setAllRegIds(filteredData.map(({ n_id }) => n_id));

            // Handle the array of unique phone numbers as needed
            // console.log("Unique Phone Numbers:", uniquePhoneNumbersArray);
        }

    }


    const calculateCompletionPercentage = (data) => {
        let completionPercentage = 20; // Start with a base completion percentage of 0

        if (data.certificates && data.certificates.length > 0) {
            completionPercentage += 20; // Add 20% if there are certificates
        }

        if (data.services && data.services.length > 0) {
            completionPercentage += 20; // Add 20% if there are services
        }

        if (data.services_images && data.services_images.length > 0) {
            completionPercentage += 20; // Add 20% if there are service images
        }

        if (data.totalReviews && data.totalReviews > 0) {
            completionPercentage += 20; // Add 20% if there are total reviews
        }

        return completionPercentage; // Return the final completion percentage
    };



    const handleChangeReminder = (e) => {
        const { id, value } = e.target
        setSubmitPush({ ...submitPush, [id]: value })
    }


    // const handleSubmitPush = (e, msg) => {
    //     e.preventDefault()
    //     console.log(allRegIds);
    //     closeModel();
    //     var myHeaders = new Headers();
    //     myHeaders.append("Content-Type", "application/json");
    //     myHeaders.append("Authorization", "key=AAAAnzqDoGw:APA91bHNYZDEZn5paZqVOV6evl4npGLdy7V1iixsNCGekPti0mkcTft5lHb9WruMaFjOdJrGVR9kkFzfC00HMfmvPUFo4sQhvXmXoom7clqOhXqMc7y5erUF75zJIxAzMZeuCtXx6PTS");

    //     var raw = JSON.stringify({
    //         // "registration_ids": [],
    //         "registration_ids": allRegIds,
    //         "notification": {
    //             "body": submitPush.description,
    //             "title": submitPush.title,
    //             "android_channel_id": "youvati",
    //             "image": submitPush.image_url,
    //             "sound": true
    //         },
    //         "data": {
    //             "type": submitPush.type,
    //             "id": submitPush.id,
    //             "image": submitPush.image_url
    //         }
    //     });

    //     var requestOptions = {
    //         method: 'POST',
    //         headers: myHeaders,
    //         body: raw,
    //         redirect: 'follow'
    //     };

    //     fetch("https://fcm.googleapis.com/fcm/send", requestOptions)
    //         .then(response => response.text())
    //         .then(result => {
    //             // alert(JSON.stringify(result))
    //             Swal.fire(`Success : ${JSON.parse(result).success}
    //             Failed : ${JSON.parse(result).failure}`)
    //             console.log(result)

    //         })
    //         .catch(error => console.log('error', error));

    // }

    const [singleID, setSingleID] = useState('');

    const SinglePush = (nid) => {
        console.log(nid)
        setSingleID(nid)
        openModalShow();
    }

    const [selectedIds, setSelectedIds] = useState([]);

    useEffect(() => {
        console.log(selectedIds);
    }, [selectedIds]);

    const handleCheckboxChange = (id) => {
        if (selectedIds.includes(id)) {
            // If the ID is already in the array, remove it
            setSelectedIds(selectedIds.filter(selectedId => selectedId !== id));
        } else {
            // If the ID is not in the array, add it
            setSelectedIds([...selectedIds, id]);
        }
    };


    // const handleSubmitPush = (e, msg) => {
    //     e.preventDefault();

    //     if (selectedIds.length === 0) {
    //         // Remove null and blank values from allRegIds
    //         const filteredRegIds = allRegIds.filter(id => id !== null && id !== "");

    //         console.log(filteredRegIds);
    //         closeModel();

    //         var myHeaders = new Headers();
    //         myHeaders.append("Content-Type", "application/json");
    //         myHeaders.append("Authorization", "Bearer  ya29.c.c0ASRK0Gal180hLH7Yki6ipiD4QWVX9KwsLUW-NDdVG_bkgLIERAb9kG8Bdkp5UqAmvwLXa3xU00UzlXNfBrwbMFic1FiU4qjok1oygP7pb3UAghol8abelwHZst73xM1iKSmYMhQR4vDJCnqg_PK2xlol0l7fcbCE_ioxSU5FBFYMt0KmUMra4TkLlyKjEoEVM359x_tSC0M6GF0MRVodFc3IZjAh0SDEPAECDMh7ZHz_W9Eay4GaMwzqzbW5cbAYA69DMg5D7cXg1wODci6XHzK0PtC_-9P7PWfbmYwIaZkRPeirLLuiW3fYAPVNqwA3w8mkSDYSuKU7xzQqb9Eg48EAHkCFAru_Srmt4zyRmU4ca7Kmf3VMTrxqN385Kn0ge_fhrZhBIi3whta4J-gQQ8-tm_QQSJqvoIkmIOZtxVy9s4sFznp5q0dwiiOFyd1ph20sJFFjom5e3Q4lmo1ukdJzbqFdMdJYFh7x7t6k6xd3krF6XWqQFcMO90diII1534Qe0gXZX6dv-yOggVtxyQRjQrFOVavih3BpiRucc5ssbqZ-60WW5mq8vkphIB1fSuZn5hmMUvUVMuzRo5wOw91vIqMVn_muYVpty0Ok96ZlJR_jx-XQg9n5Y27zqd67bR0jwJFrl2UYI3t-8Mr6hms7XJpgI1zbsUW3Xox2idjxo-hUgbu-bjJ3Qm_OYyRcV2azsb21JhgdVurkwlr82IRZm8oSfRF9tz1q-lmYzksBRtySOomIa47M1eBfdtxJJnUQor91i7VemzOjoiMF2qZnR-oIcB0qslm0cqx_e3t70ZJWiQ6XZbc5kZcsJ_ldeVc_JBqU_eVbdj2JxB-9hVzMlqYdqjFwFt5t9nYlVc82-I06YRtjZ2dR-SR008XRpXF9Bq0oo0mt2XkRFvVJqIxmkhx6ooVab4qF2_ch11vMWm-zX2XRBcmsupmxFg6s9gr2tOnX_7_B3Mxkl02lzB8mYecR7Yqr4lr5nerhxUOhwddF16mQfyz");

    //         // Check if filteredRegIds is not empty before proceeding
    //         if (filteredRegIds.length === 0) {
    //             console.log("No valid registration IDs to send the push notification.");
    //             return;
    //         }

    //         const batchSize = 100; // Choose an appropriate batch size

    //         const batches = [];
    //         for (let i = 0; i < filteredRegIds.length; i += batchSize) {
    //             const batch = filteredRegIds.slice(i, i + batchSize);

    //             // Create a separate request for each batch
    //             const raw = JSON.stringify({
    //                 "registration_ids": batch,
    //                 "notification": {
    //                     "body": submitPush.description,
    //                     "title": submitPush.title,
    //                     "android_channel_id": "youvati",
    //                     "image": submitPush.image_url,
    //                     "sound": true
    //                 },
    //                 "data": {
    //                     "type": submitPush.type,
    //                     "id": submitPush.id,
    //                     "image": submitPush.image_url
    //                 }
    //             });

    //             const requestOptions = {
    //                 method: 'POST',
    //                 headers: myHeaders,
    //                 body: raw,
    //                 redirect: 'follow'
    //             };

    //             batches.push(fetch("https://fcm.googleapis.com/v1/projects/youvati/messages:send", requestOptions));
    //         }

    //         // Execute all batches concurrently
    //         Promise.all(batches)
    //             .then(responses => {
    //                 // Handle responses as needed
    //                 console.log(responses);
    //                 // Additional handling if required
    //                 Swal.fire(`Push notifications sent successfully to ${filteredRegIds.length} devices.`);
    //             })
    //             .then(result => {
    //                 // alert(JSON.stringify(result))
    //                 Swal.fire(`Success : ${JSON.parse(result).success}
    //             Failed : ${JSON.parse(result).failure}`)
    //                 console.log(result)

    //             })
    //             .catch(error => console.log('error', error));
    //     } else {

    //         var myHeaders = new Headers();
    //         myHeaders.append("Content-Type", "application/json");
    //         myHeaders.append("Authorization", "Bearer  ya29.c.c0ASRK0Gal180hLH7Yki6ipiD4QWVX9KwsLUW-NDdVG_bkgLIERAb9kG8Bdkp5UqAmvwLXa3xU00UzlXNfBrwbMFic1FiU4qjok1oygP7pb3UAghol8abelwHZst73xM1iKSmYMhQR4vDJCnqg_PK2xlol0l7fcbCE_ioxSU5FBFYMt0KmUMra4TkLlyKjEoEVM359x_tSC0M6GF0MRVodFc3IZjAh0SDEPAECDMh7ZHz_W9Eay4GaMwzqzbW5cbAYA69DMg5D7cXg1wODci6XHzK0PtC_-9P7PWfbmYwIaZkRPeirLLuiW3fYAPVNqwA3w8mkSDYSuKU7xzQqb9Eg48EAHkCFAru_Srmt4zyRmU4ca7Kmf3VMTrxqN385Kn0ge_fhrZhBIi3whta4J-gQQ8-tm_QQSJqvoIkmIOZtxVy9s4sFznp5q0dwiiOFyd1ph20sJFFjom5e3Q4lmo1ukdJzbqFdMdJYFh7x7t6k6xd3krF6XWqQFcMO90diII1534Qe0gXZX6dv-yOggVtxyQRjQrFOVavih3BpiRucc5ssbqZ-60WW5mq8vkphIB1fSuZn5hmMUvUVMuzRo5wOw91vIqMVn_muYVpty0Ok96ZlJR_jx-XQg9n5Y27zqd67bR0jwJFrl2UYI3t-8Mr6hms7XJpgI1zbsUW3Xox2idjxo-hUgbu-bjJ3Qm_OYyRcV2azsb21JhgdVurkwlr82IRZm8oSfRF9tz1q-lmYzksBRtySOomIa47M1eBfdtxJJnUQor91i7VemzOjoiMF2qZnR-oIcB0qslm0cqx_e3t70ZJWiQ6XZbc5kZcsJ_ldeVc_JBqU_eVbdj2JxB-9hVzMlqYdqjFwFt5t9nYlVc82-I06YRtjZ2dR-SR008XRpXF9Bq0oo0mt2XkRFvVJqIxmkhx6ooVab4qF2_ch11vMWm-zX2XRBcmsupmxFg6s9gr2tOnX_7_B3Mxkl02lzB8mYecR7Yqr4lr5nerhxUOhwddF16mQfyz");

    //         // Check if regId is not empty before proceeding


    //         const raw = JSON.stringify({
    //             "registration_ids": selectedIds,
    //             "notification": {
    //                 "body": submitPush.description,
    //                 "title": submitPush.title,
    //                 "android_channel_id": "youvati",
    //                 "image": submitPush.image_url,
    //                 "sound": true
    //             },
    //             "data": {
    //                 "type": submitPush.type,
    //                 "id": submitPush.id,
    //                 "image": submitPush.image_url
    //             }
    //         });

    //         const requestOptions = {
    //             method: 'POST',
    //             headers: myHeaders,
    //             body: raw,
    //             redirect: 'follow'
    //         };

    //         fetch("https://fcm.googleapis.com/v1/projects/youvati/messages:send", requestOptions)
    //             .then(response => response.json())
    //             .then(result => {
    //                 closeModel();
    //                 // Handle response
    //                 console.log(result);
    //                 Swal.fire(`Push notification sent successfully to device.`);
    //             })
    //             .catch(error => console.log('error', error));

    //     }
    // };

    const handleSubmitPush = (e, msg) => {
        e.preventDefault();

        if (selectedIds.length === 0) {
            // Remove null and blank values from allRegIds
            const filteredRegIds = allRegIds.filter(id => id !== null && id !== "");

            console.log(filteredRegIds);
            closeModel();

            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Authorization", "Bearer ya29.c.c0ASRK0Gal180hLH7Yki6ipiD4QWVX9KwsLUW-NDdVG_bkgLIERAb9kG8Bdkp5UqAmvwLXa3xU00UzlXNfBrwbMFic1FiU4qjok1oygP7pb3UAghol8abelwHZst73xM1iKSmYMhQR4vDJCnqg_PK2xlol0l7fcbCE_ioxSU5FBFYMt0KmUMra4TkLlyKjEoEVM359x_tSC0M6GF0MRVodFc3IZjAh0SDEPAECDMh7ZHz_W9Eay4GaMwzqzbW5cbAYA69DMg5D7cXg1wODci6XHzK0PtC_-9P7PWfbmYwIaZkRPeirLLuiW3fYAPVNqwA3w8mkSDYSuKU7xzQqb9Eg48EAHkCFAru_Srmt4zyRmU4ca7Kmf3VMTrxqN385Kn0ge_fhrZhBIi3whta4J-gQQ8-tm_QQSJqvoIkmIOZtxVy9s4sFznp5q0dwiiOFyd1ph20sJFFjom5e3Q4lmo1ukdJzbqFdMdJYFh7x7t6k6xd3krF6XWqQFcMO90diII1534Qe0gXZX6dv-yOggVtxyQRjQrFOVavih3BpiRucc5ssbqZ-60WW5mq8vkphIB1fSuZn5hmMUvUVMuzRo5wOw91vIqMVn_muYVpty0Ok96ZlJR_jx-XQg9n5Y27zqd67bR0jwJFrl2UYI3t-8Mr6hms7XJpgI1zbsUW3Xox2idjxo-hUgbu-bjJ3Qm_OYyRcV2azsb21JhgdVurkwlr82IRZm8oSfRF9tz1q-lmYzksBRtySOomIa47M1eBfdtxJJnUQor91i7VemzOjoiMF2qZnR-oIcB0qslm0cqx_e3t70ZJWiQ6XZbc5kZcsJ_ldeVc_JBqU_eVbdj2JxB-9hVzMlqYdqjFwFt5t9nYlVc82-I06YRtjZ2dR-SR008XRpXF9Bq0oo0mt2XkRFvVJqIxmkhx6ooVab4qF2_ch11vMWm-zX2XRBcmsupmxFg6s9gr2tOnX_7_B3Mxkl02lzB8mYecR7Yqr4lr5nerhxUOhwddF16mQfyz"); // Replace with your access token

            // Check if filteredRegIds is not empty before proceeding
            if (filteredRegIds.length === 0) {
                console.log("No valid registration IDs to send the push notification.");
                return;
            }

            const batchSize = 100; // Choose an appropriate batch size

            const batches = [];
            for (let i = 0; i < filteredRegIds.length; i += batchSize) {
                const batch = filteredRegIds.slice(i, i + batchSize);

                // Create a separate request for each batch
                const raw = JSON.stringify({
                    "message": {
                        "tokens": batch,
                        "notification": {
                            "body": submitPush.description,
                            "title": submitPush.title,
                            "image": submitPush.image_url
                        },
                        "data": {
                            "type": submitPush.type,
                            "id": submitPush.id,
                            "image": submitPush.image_url
                        },
                        "android": {
                            "notification": {
                                "channel_id": "youvati",
                                "sound": "default"
                            }
                        }
                    }
                });

                const requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'
                };

                batches.push(fetch("https://fcm.googleapis.com/v1/projects/youvati/messages:send", requestOptions));
            }

            // Execute all batches concurrently
            Promise.all(batches)
                .then(responses => Promise.all(responses.map(response => response.json())))
                .then(results => {
                    // Handle responses as needed
                    console.log(results);
                    Swal.fire(`Push notifications sent successfully to ${filteredRegIds.length} devices.`);
                })
                .catch(error => console.log('error', error));
        } else {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Authorization", "Bearer ya29.c.c0ASRK0Gal180hLH7Yki6ipiD4QWVX9KwsLUW-NDdVG_bkgLIERAb9kG8Bdkp5UqAmvwLXa3xU00UzlXNfBrwbMFic1FiU4qjok1oygP7pb3UAghol8abelwHZst73xM1iKSmYMhQR4vDJCnqg_PK2xlol0l7fcbCE_ioxSU5FBFYMt0KmUMra4TkLlyKjEoEVM359x_tSC0M6GF0MRVodFc3IZjAh0SDEPAECDMh7ZHz_W9Eay4GaMwzqzbW5cbAYA69DMg5D7cXg1wODci6XHzK0PtC_-9P7PWfbmYwIaZkRPeirLLuiW3fYAPVNqwA3w8mkSDYSuKU7xzQqb9Eg48EAHkCFAru_Srmt4zyRmU4ca7Kmf3VMTrxqN385Kn0ge_fhrZhBIi3whta4J-gQQ8-tm_QQSJqvoIkmIOZtxVy9s4sFznp5q0dwiiOFyd1ph20sJFFjom5e3Q4lmo1ukdJzbqFdMdJYFh7x7t6k6xd3krF6XWqQFcMO90diII1534Qe0gXZX6dv-yOggVtxyQRjQrFOVavih3BpiRucc5ssbqZ-60WW5mq8vkphIB1fSuZn5hmMUvUVMuzRo5wOw91vIqMVn_muYVpty0Ok96ZlJR_jx-XQg9n5Y27zqd67bR0jwJFrl2UYI3t-8Mr6hms7XJpgI1zbsUW3Xox2idjxo-hUgbu-bjJ3Qm_OYyRcV2azsb21JhgdVurkwlr82IRZm8oSfRF9tz1q-lmYzksBRtySOomIa47M1eBfdtxJJnUQor91i7VemzOjoiMF2qZnR-oIcB0qslm0cqx_e3t70ZJWiQ6XZbc5kZcsJ_ldeVc_JBqU_eVbdj2JxB-9hVzMlqYdqjFwFt5t9nYlVc82-I06YRtjZ2dR-SR008XRpXF9Bq0oo0mt2XkRFvVJqIxmkhx6ooVab4qF2_ch11vMWm-zX2XRBcmsupmxFg6s9gr2tOnX_7_B3Mxkl02lzB8mYecR7Yqr4lr5nerhxUOhwddF16mQfyz"); // Replace with your access token

            const raw = JSON.stringify({
                "message": {
                    "token": selectedIds[0], // Assuming you want to send a notification to a single device
                    "notification": {
                        "body": submitPush.description,
                        "title": submitPush.title,
                        "image": submitPush.image_url
                    },
                    "data": {
                        "type": submitPush.type,
                        "id": submitPush.id,
                        "image": submitPush.image_url
                    },
                    "android": {
                        "notification": {
                            "channel_id": "youvati",
                            "sound": "default"
                        }
                    }
                }
            });

            const requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch("https://fcm.googleapis.com/v1/projects/youvati/messages:send", requestOptions)
                .then(response => response.json())
                .then(result => {
                    closeModel();
                    // Handle response
                    console.log(result);
                    Swal.fire(`Push notification sent successfully to device.`);
                })
                .catch(error => console.log('error', error));
        }
    };


    const selectAll = () => {
        const allIds = data.map(item => item.n_id);
        setSelectedIds(allIds);
        console.log(allIds)
    };

    const unselectAll = () => {
        setSelectedIds([]);
    };

    const handleSelectedData = async () => {
        console.log("processingggg")
        try {
            if (startDate && endDate) {
                const startTimestamp = moment(startDate).startOf('day').toDate();
                const endTimestamp = moment(endDate).endOf('day').toDate();

                // await db.collection("clicked_logs")
                //     .where('created_date', '>=', startTimestamp)
                //     .where('created_date', '<=', endTimestamp)
                //     .get()
                //     .then(querySnapshot => {
                //         querySnapshot.forEach(doc => {
                //             doc.ref.delete();
                //         });
                //     });
                // alert("Data deleted successfully!");

                db.collection("personal_details").where('created_date', '>=', startTimestamp).where('created_date', '<=', endTimestamp).onSnapshot((snapshot) => {
                    const usersData = [];

                    snapshot.docs.forEach((doc) => {
                        const userData = {
                            id: doc.id,
                            data: doc.data(),
                        };

                        usersData.push(userData);
                    });

                    const phoneNumbers = usersData.map(cert => cert.data.phone_no);
                    const filteredData = allData.filter(item => phoneNumbers.includes(item.data.phone_no));

                    const idsss = filteredData.map(({ n_id }) => n_id);

                    setData(filteredData);
                    setAllRegIds(idsss);

                    console.log("Users filteredData:", filteredData);
                    console.log("Users idsss:", idsss);

                    // Handle the data as needed
                });
            } else {
                alert("Please select both start and end dates.");
            }
        } catch (error) {
            console.error("Error deleting data:", error);
            alert("An error occurred while deleting data.");
        }
    };



    return (
        <>
            <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg">

                <div className="p-2" style={{ paddingBottom: "5px", display: "flex", justifyContent: "end" }}>
                    <div>
                        <label>Start Date:</label>
                        <DatePicker
                            selected={startDate}
                            onChange={handleStartDateChange}
                            selectsStart
                            startDate={startDate}
                            endDate={endDate}
                            className="form-control form-control-sm"
                        />
                    </div>
                    <div>
                        <label>End Date:</label>
                        <DatePicker
                            selected={endDate}
                            onChange={handleEndDateChange}
                            selectsEnd
                            startDate={startDate}
                            endDate={endDate}
                            className="form-control form-control-sm"
                        />
                    </div>
                    <button onClick={handleSelectedData} style={{ height: "2rem", marginTop: "2rem", marginLeft: "1rem" }}>Select User Date Wise</button>

                </div>
                <div class="p-2" style={{ textAlign: "start", paddingBottom: "5px" }}>
                    <Link to="/admin_panel">
                        <img src="/images/menubutton.png" class="menu-toggle" alt="Responsive image" style={{ height: "2rem" }} />
                    </Link>
                    <div style={{ textAlign: "end", paddingBottom: "5px", display: "flex", justifyContent: "end" }}>
                        <a class="btn bg-gradient-primary btn-sm mb-0" style={{ color: "white" }} onClick={() => addNew()}>+&nbsp; Send Push Notification</a>
                    </div>
                </div>
                <div class="row">


                    <div class="p-4">

                        {/* <button class="btn btn-sm btn-primary mr-2" onClick={selectAll}>Select All</button>
                        <button class="btn btn-sm btn-secondary" onClick={unselectAll}>Unselect All</button> */}

                        <div style={{ textAlign: "end", paddingBottom: "5px", display: "flex" }}>

                            <select value={dataFilter} onChange={handleFilter} className="form-control" style={{ marginRight: "1rem" }}>
                                <option value="--">Filter data</option>
                                <option value="all users">All Users</option>
                                <option value="portfolio">Portfolio</option>
                                <option value="mybusiness">My Business</option>
                                <option value="workshop">Workshop</option>
                                <option value="advertise">Advertise</option>
                            </select>
                            {selectedPortfolio == "yes" ? <>
                                <select value={dataFilterPortfolio} onChange={handleFilterPortfolio} className="form-control">
                                    <option value="--">Filter data</option>
                                    <option value="all portfolio users">All Portfolio Users</option>
                                    <option value="no portfolio">No Portfolio</option>
                                    <option value="60% or below">60% or below</option>
                                    <option value="80% completed">80% completed</option>
                                </select></> : <></>}

                            {selectedMybusiness == "yes" ? <>
                                <select value={dataFilterMybusiness} onChange={handleFilterMybusiness} className="form-control">
                                    <option value="--">Filter data</option>
                                    <option value="No Appointmnet">No Appointmnet</option>
                                    <option value="No Khata">No Khata</option>
                                    <option value="No My Business">No My Business</option>
                                </select></> : <></>}

                            {selectedWorkshop == "yes" ? <>
                                <select value={dataFilterWorkshop} onChange={handleFilterWorkshop} className="form-control">
                                    <option value="--">Filter data</option>
                                    <option value="Attended Atleast 1">Attended Atleast 1</option>
                                    <option value="No Workshop Attended">No Workshop Attended</option>
                                    <option value="Registered But Not Attended">Registered But Not Attended</option>
                                    <option value="Attended More Than 1 Workshop">Attended More Than 1 Workshop</option>
                                    <option value="December Downloads">December Downloads</option>
                                </select></> : <></>}

                            {selectedAdvertise == "yes" ? <>
                                <select value={dataFilterAdvertise} onChange={handleFilterAdvertise} className="form-control">
                                    <option value="--">Filter data</option>
                                    <option value="Free Visiting Card Applied but Not Premium">Free Visiting Card Applied but Not Premium</option>
                                    <option value="No Visiting Card Applied">No Visiting Card Applied</option>
                                    <option value="No Banner Applied">No Banner Applied</option>
                                    <option value="No Banner Applied">No SMG Applied</option>
                                    <option value="Free Banner Applied but Not Premium">Free Banner Applied but Not Premium</option>
                                    <option value="Free Banner and Free SMG, No Premium Banner and No Premium SMG">Free Banner and Free SMG, No Premium Banner and No Premium SMG</option>

                                </select></> : <></>}

                        </div>

                        <div class="card">
                            <div class="card-header pb-0 px-3">
                                <h6 class="mb-2">Push Notification || {`Records : ${data.length}`}</h6>
                            </div>

                            <div class="card-body">
                                <div class="table-responsive" style={{ maxHeight: "400px", overflowY: "scroll" }}>
                                    <table class="table align-items-center mb-0">
                                        <thead>
                                            <tr style={{ textAlign: "left" }}>
                                                <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">S.No</th>
                                                <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"></th>
                                                <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Full Name</th>
                                                <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Username</th>
                                                <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Phone</th>
                                                <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">ID</th>
                                                <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Reg ID</th>
                                            </tr>
                                        </thead>
                                        <tbody style={{ textAlign: "left" }}>
                                            {data.map(({ id, data, n_id }, index) => (
                                                <tr key={id}>
                                                    <td>
                                                        <p class="text-xs mb-0">{index + 1}.</p>
                                                    </td>
                                                    {/* <td>
                                                        <button class="btn bg-gradient-warning btn-sm mb-0" onClick={() => SinglePush(n_id)}>➤</button>
                                                    </td> */}
                                                    <td>
                                                        {/* Checkbox */}
                                                        <input
                                                            type="checkbox"
                                                            style={{ display: "block" }}
                                                            checked={selectedIds.includes(n_id)}
                                                            onChange={() => handleCheckboxChange(n_id)}
                                                        />
                                                    </td>
                                                    <td>
                                                        <p class="text-xs mb-0">{data.full_name}</p>
                                                    </td>
                                                    <td>
                                                        <p class="text-xs mb-0">{data.username}</p>
                                                    </td>
                                                    <td>
                                                        <p class="text-xs mb-0">{data.phone_no}</p>
                                                    </td>
                                                    <td>
                                                        <p class="text-xs mb-0">{id}</p>
                                                    </td>
                                                    <td>
                                                        <p class="text-xs mb-0">{n_id}</p>
                                                    </td>
                                                    {/* Other table cells */}
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>

            <div class="col-md-12">
                <div class="myId modal fade" id="" tabindex="-1" role="dialog" aria-labelledby="modal-form" aria-hidden="true" data-backdrop="static">
                    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div class="modal-content">
                            <div class="modal-body">
                                <div class="">

                                    <div class="p-5">
                                        <form class="" id="" onSubmit={handleSubmitPush}>
                                            <div class="">
                                                <div class="" style={{ textAlign: "center", }}><span style={{ color: "black", fontSize: "25px", fontWeight: "700", textTransform: "uppercase" }}>Send Push Notification</span></div>
                                                <div class="" style={{ textAlign: "center", color: "black", paddingTop: "10px" }}>
                                                </div>

                                                <div class="">
                                                    <div class="row">
                                                        <div class="col-md-12">
                                                            <input style={{
                                                                // width: "17rem",
                                                                height: "45px",
                                                                padding: "12px 20px",
                                                                boxSizing: "border-box",
                                                                border: "2px solid #ccc",
                                                                borderRadius: "4px",
                                                                backgroundColor: "#f8f8f8",
                                                                fontSize: "16px",
                                                                resize: "none"
                                                            }} class="w3l_text_box" id="title" onChange={handleChangeReminder} value={submitPush.title} placeholder="Title" name="Title" type="text" required />
                                                        </div>


                                                    </div>
                                                </div>

                                                <div class="">
                                                    <div class="row">
                                                        <div class="col-md-12">
                                                            <input style={{
                                                                // width: "17rem",
                                                                height: "45px",
                                                                padding: "12px 20px",
                                                                boxSizing: "border-box",
                                                                border: "2px solid #ccc",
                                                                borderRadius: "4px",
                                                                backgroundColor: "#f8f8f8",
                                                                fontSize: "16px",
                                                                resize: "none"
                                                            }} class="w3l_text_box" id="description" onChange={handleChangeReminder} value={submitPush.description} placeholder="Description" name="Description" type="text" required />
                                                        </div>


                                                    </div>
                                                </div>

                                                <div class="">
                                                    <div class="row">
                                                        <div class="col-md-12">
                                                            <input style={{
                                                                // width: "17rem",
                                                                height: "45px",
                                                                padding: "12px 20px",
                                                                boxSizing: "border-box",
                                                                border: "2px solid #ccc",
                                                                borderRadius: "4px",
                                                                backgroundColor: "#f8f8f8",
                                                                fontSize: "16px",
                                                                resize: "none"
                                                            }} class="w3l_text_box" id="image_url" onChange={handleChangeReminder} value={submitPush.image_url} placeholder="Image URL" name="Image URL" type="text" />
                                                        </div>


                                                    </div>
                                                </div>




                                                <div class="">
                                                    <div class="row">
                                                        <div class="col-md-6">
                                                            <input style={{
                                                                // width: "17rem",
                                                                height: "45px",
                                                                padding: "12px 20px",
                                                                boxSizing: "border-box",
                                                                border: "2px solid #ccc",
                                                                borderRadius: "4px",
                                                                backgroundColor: "#f8f8f8",
                                                                fontSize: "16px",
                                                                resize: "none"
                                                            }} class="w3l_text_box" id="type" onChange={handleChangeReminder} value={submitPush.type} placeholder="Type" name="Type" type="text" required />
                                                        </div>

                                                        <div class="col-md-6">
                                                            <input style={{
                                                                // width: "17rem",
                                                                height: "45px",
                                                                padding: "12px 20px",
                                                                boxSizing: "border-box",
                                                                border: "2px solid #ccc",
                                                                borderRadius: "4px",
                                                                backgroundColor: "#f8f8f8",
                                                                fontSize: "16px",
                                                                resize: "none"
                                                            }} class="w3l_text_box" id="id" onChange={handleChangeReminder} value={submitPush.id} placeholder="ID" name="ID" type="text" required />
                                                        </div>

                                                    </div>
                                                </div>


                                                <div class="modal-footer">
                                                    <a class="btn bg-gradient-info btn-sm mb-0" style={{ color: "white" }} onClick={() => closeModel()}>Close</a>
                                                    {/* <a class="btn bg-gradient-success btn-sm mb-0" style={{ color: "white" }}>Submit</a> */}
                                                    <button type='submit' class="btn bg-gradient-danger btn-sm mb-0" style={{ backgroundColor: "green", color: "white" }}>Send</button>
                                                </div>

                                            </div>
                                        </form>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default PushNotifications;






// import React, { useState, useEffect } from 'react';
// import { useNavigate, Link, useParams } from 'react-router-dom';
// import db from "./Firebase";
// import { arrayUnion } from "firebase/firestore";
// import Swal from 'sweetalert2'
// import { extendMoment } from 'moment-range';
// import ViewAllRequests from './ViewAllRequests';
// import { CSVLink } from "react-csv";

// function PushNotifications() {
//     const initialFormStateReminder = { title: '', description: '', image_url: '', type: '', id: '', created_date: new Date() }
//     const [submitPush, setSubmitPush] = useState(initialFormStateReminder);

//     const [data, setData] = useState([]);
//     const [dataFilter, setDataFilter] = useState("all");

//     const [allRegIds, setAllRegIds] = useState([])

//     const [usersData, setUsersData] = useState([])
//     const [notification_id_data, setNotification_id_data] = useState([])

//     const [searchQuery, setSearchQuery] = useState("");
//     const [selectedWorkshop, setSelectedWorkshop] = useState('');
//     const [workshopNames, setWorkshopNames] = useState([]);

//     // const usersData = [];
//     // const notification_id_data = [];

//     useEffect(() => {
//         // Fetch data from "notifications_red_id_name" collection
//         db.collection("notification_ids").onSnapshot((snapshot) => {
//             notification_id_data.length = 0; // Clear the array
//             snapshot.docs.forEach((doc) => {
//                 notification_id_data.push({
//                     id: doc.id,
//                     data: doc.data(),
//                 });
//             });
//         });


//         // // Fetch data from "users_location" collection
//         // db.collection("personal_details").limit(4).onSnapshot((snapshot) => {
//         //     usersData.length = 0; // Clear the array
//         //     snapshot.docs.forEach((doc) => {
//         //         usersData.push({
//         //             id: doc.id,
//         //             data: doc.data(),
//         //         });
//         //     });
//         //     mergeData();
//         // });

//         // // Fetch data from "notifications_red_id_name" collection
//         // db.collection("notification_ids").limit(4).onSnapshot((snapshot) => {
//         //     notification_id_data.length = 0; // Clear the array
//         //     snapshot.docs.forEach((doc) => {
//         //         notification_id_data.push({
//         //             id: doc.id,
//         //             data: doc.data(),
//         //         });
//         //     });
//         //     mergeData();
//         // });

//         // // Function to merge data when both sets are available
//         // // function mergeData() {
//         // //     if (usersData.length > 0 && notification_id_data.length > 0) {
//         // //         // Merge the data based on the username
//         // //         const mergedData = usersData.map((user) => {
//         // //             const notifications_red_id = notification_id_data.find((p) => p.data['uid'] === user.id);
//         // //             // console.log(notifications_red_id.data['reg_id'])
//         // //             return {
//         // //                 ...user,
//         // //                 noti: notifications_red_id.data['reg_id'],
//         // //             };
//         // //         });

//         // //         // Now, mergedData contains the merged information
//         // //         setData(mergedData);
//         // //         console.log(mergedData)
//         // //     }
//         // // }
//         // function mergeData() {
//         //     if (usersData.length > 0 && notification_id_data.length > 0) {
//         //         const mergedData = usersData.map((user) => {
//         //             const notifications_reg_id = notification_id_data.find((p) => p.data['uid'] === user.id);
//         //             // Extract the 'reg_id' if a matching notification is found, or set to 'N/A' if not found
//         //             const reg_id = notifications_reg_id ? notifications_reg_id.data['reg_id'] : 'N/A';

//         //             return {
//         //                 ...user,
//         //                 noti: reg_id,
//         //             };
//         //         });

//         //         // Now, mergedData contains the merged information

//         //         // Extract all 'reg_id' values into a separate array
//         //         const RegIds = mergedData.map((item) => item.noti);

//         //         // Set mergedData to your state
//         //         setData(mergedData);
//         //         // console.log(mergedData);

//         //         // Set all 'reg_id' values to a state or a variable
//         //         setAllRegIds(RegIds); // Replace setAllRegIds with your state setter
//         //         // console.log(RegIds);
//         //     }
//         // }
//     }, []);

//     const [showWorkshop, setShowWorkshop] = useState(false);


//     const handleFilter = (e) => {
//         console.log(e.target.value)
//         setDataFilter(e.target.value); // Step 2: Update availability filter state
//         if (e.target.value === "test") {
//             db.collection("notif_testing").onSnapshot((snapshot) => {
//                 const useraData = snapshot.docs.map((doc) => ({
//                     id: doc.id,
//                     data: doc.data(),
//                 }));

//                 setData(useraData);

//                 const mergedData = useraData.map((user) => {

//                     const notifications_reg_id = notification_id_data.find((p) => p.data['uid'] === user.data.uid);
//                     const reg_id = notifications_reg_id ? notifications_reg_id.data['reg_id'] : 'N/A';

//                     return {
//                         ...user,
//                         noti: reg_id,
//                     };
//                 });

//                 const RegIds = mergedData.map((item) => item.noti);


//                 setData(mergedData);

//                 setAllRegIds(RegIds);
//                 console.log(RegIds);

//             });
//         } else if (e.target.value === "all users") {
//             // Fetch data from "users_location" collection
//             db.collection("personal_details").limit(4).onSnapshot((snapshot) => {
//                 usersData.length = 0; // Clear the array
//                 snapshot.docs.forEach((doc) => {
//                     usersData.push({
//                         id: doc.id,
//                         data: doc.data(),
//                     });
//                 });
//                 mergeData();
//             });



//             function mergeData() {
//                 if (usersData.length > 0 && notification_id_data.length > 0) {
//                     const mergedData = usersData.map((user) => {
//                         const notifications_reg_id = notification_id_data.find((p) => p.data['uid'] === user.id);
//                         const reg_id = notifications_reg_id ? notifications_reg_id.data['reg_id'] : 'N/A';

//                         return {
//                             ...user,
//                             noti: reg_id,
//                         };
//                     });


//                     const RegIds = mergedData.map((item) => item.noti);

//                     setData(mergedData);

//                     setAllRegIds(RegIds);
//                 }
//             }
//         }
//         else if (e.target.value === "workshop") {
//             setShowWorkshop(true)
//             db.collection("workshop_registration").orderBy("created_date", "desc").onSnapshot((snapshot) => {
//                 const workshopData = snapshot.docs.map((doc) => ({
//                     id: doc.id,
//                     data: doc.data(),
//                 }));
//                 // Extract unique workshop names
//                 const uniqueNames = Array.from(new Set(workshopData.map((doc) => doc.data.workshop_event_name)));
//                 setWorkshopNames(uniqueNames);
//             });
//         }

//     }


//     const handleSearchChange = (event) => {
//         setSearchQuery(event.target.value);
//     };


//     const handleChangeReminder = (e) => {
//         const { id, value } = e.target
//         setSubmitPush({ ...submitPush, [id]: value })
//     }

//     const handleSubmitPush = (e, msg) => {
//         e.preventDefault()
//         console.log(allRegIds);
//         var myHeaders = new Headers();
//         myHeaders.append("Content-Type", "application/json");
//         myHeaders.append("Authorization", "key=AAAAnzqDoGw:APA91bHNYZDEZn5paZqVOV6evl4npGLdy7V1iixsNCGekPti0mkcTft5lHb9WruMaFjOdJrGVR9kkFzfC00HMfmvPUFo4sQhvXmXoom7clqOhXqMc7y5erUF75zJIxAzMZeuCtXx6PTS");

//         var raw = JSON.stringify({
//             "registration_ids": allRegIds,
//             "notification": {
//                 "body": submitPush.description,
//                 "title": submitPush.title,
//                 "android_channel_id": "youvati",
//                 "image": submitPush.image_url,
//                 "sound": true
//             },
//             "data": {
//                 "type": submitPush.type,
//                 "id": submitPush.id,
//                 "image": submitPush.image_url
//             }
//         });

//         var requestOptions = {
//             method: 'POST',
//             headers: myHeaders,
//             body: raw,
//             redirect: 'follow'
//         };

//         fetch("https://fcm.googleapis.com/v1/projects/youvati/messages:send", requestOptions)
//             .then(response => response.text())
//             .then(result => {
//                 // alert(JSON.stringify(result))
//                 Swal.fire(`Success : ${JSON.parse(result).success}
//                 Failed : ${JSON.parse(result).failure}`)
//                 console.log(result)
//             })
//             .catch(error => console.log('error', error));

//     }

//     // Function to filter data based on the search query
//     // const filteredData = data.filter((item) => {
//     //     const username = item.data.username.toLowerCase();
//     //     const phone_no = item.data.phone_no.toLowerCase();
//     //     const full_name = item.data.full_name.toLowerCase();
//     //     const query = searchQuery.toLowerCase();

//     //     return (
//     //         username.includes(query) ||
//     //         phone_no.includes(query) ||
//     //         full_name.includes(query)
//     //     );
//     // });

//     const [oneData, setOneData] = useState([])
//     // const workshopFilter = (name) => {
//     //     console.log(name)
//     //     const pdata = [];
//     //     const wdata = [];

//     //     db.collection("personal_details").onSnapshot((snapshot) => {
//     //         snapshot.docs.forEach((doc) => {
//     //             pdata.push({
//     //                 id: doc.id,
//     //                 data: doc.data(),
//     //             });
//     //         });

//     //     });


//     //     db.collection("workshop_registration").where('workshop_event_name', '==', name).onSnapshot((snapshot) => {
//     //         snapshot.docs.forEach((doc) => {
//     //             wdata.push({
//     //                 id: doc.id,
//     //                 data: doc.data(),
//     //             });
//     //         });
//     //         mergeDataOne();
//     //     });


//     //     function mergeDataOne() {
//     //         console.log("mergeDataOne calling")
//     //         console.log(pdata.length)
//     //         console.log(wdata.length)
//     //         if (pdata.length > 0 && wdata.length > 0) {
//     //             const mergedDataOne = wdata.map((user) => {
//     //                 console.log(user.data.uid)
//     //                 const w_data = pdata.find((p) => p.data.phone_no === user.data.phone);
//     //                 // console.log(w_data.id)
//     //                 const mdata = w_data.id;

//     //                 return {
//     //                     ...user,
//     //                     r_id: mdata,
//     //                 };
//     //             });

//     //             setOneData(mergedDataOne)
//     //             // console.log(mergedDataOne)
//     //             mergeData();
//     //         }
//     //     }

//     //     function mergeData() {
//     //         console.log("mergeData calling")
//     //         // console.log(oneData);
//     //         if (oneData.length > 0 && notification_id_data.length > 0) {
//     //             const mergedData = oneData.map((user) => {
//     //                 console.log(user.r_id)
//     //                 const notifications_reg_id = notification_id_data.find((p) => p.data['uid'] === user.r_id);
//     //                 const reg_id = notifications_reg_id ? notifications_reg_id.data['reg_id'] : 'N/A';

//     //                 return {
//     //                     ...user,
//     //                     noti: reg_id,
//     //                 };
//     //             });


//     //             const RegIds = mergedData.map((item) => item.noti);

//     //             setData(mergedData);

//     //             setAllRegIds(RegIds);
//     //             console.log(mergedData)
//     //             console.log(RegIds)
//     //         }
//     //     }

//     // }

//     const workshopFilter = async (name) => {
//         setSelectedWorkshop(name)
//         try {
//             const pdata = [];
//             const wdata = [];

//             const personalDetailsSnapshot = await db.collection("personal_details").get();
//             personalDetailsSnapshot.forEach((doc) => {
//                 pdata.push({
//                     id: doc.id,
//                     data: doc.data(),
//                 });
//             });

//             const workshopSnapshot = await db.collection("workshop_registration").where('workshop_event_name', '==', name).get();
//             workshopSnapshot.forEach((doc) => {
//                 wdata.push({
//                     id: doc.id,
//                     data: doc.data(),
//                 });
//             });

//             const mergedDataOne = wdata.map((user) => {
//                 const w_data = pdata.find((p) => p.data.phone_no === user.data.phone);
//                 const mdata = w_data ? w_data.id : 'N/A';

//                 return {
//                     ...user,
//                     r_id: mdata,
//                 };
//             });

//             setOneData(mergedDataOne);

//             if (notification_id_data.length > 0) {
//                 const mergedData = mergedDataOne.map((user) => {
//                     const notifications_reg_id = notification_id_data.find((p) => p.data['uid'] === user.r_id);
//                     const reg_id = notifications_reg_id ? notifications_reg_id.data['reg_id'] : 'N/A';

//                     return {
//                         ...user,
//                         noti: reg_id,
//                     };
//                 });

//                 setData(mergedData);

//                 const RegIds = mergedData.map((item) => item.noti);
//                 setAllRegIds(RegIds);
//             }
//         } catch (error) {
//             console.error("Error fetching data:", error);
//         }
//     };








//     return (
//         <>
//             <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">

//                 <div class="p-5">
//                     <form class="" id="" onSubmit={handleSubmitPush}>
//                         <div class="">
//                             <div class="" style={{ textAlign: "center", }}><span style={{ color: "black", fontSize: "25px", fontWeight: "700", textTransform: "uppercase" }}>Send Push Notification</span></div>
//                             <div class="" style={{ textAlign: "center", color: "black", paddingTop: "10px" }}>
//                             </div>

//                             <div class="">
//                                 <div class="row">
//                                     <div class="col-md-12">
//                                         <input style={{
//                                             // width: "17rem",
//                                             height: "45px",
//                                             padding: "12px 20px",
//                                             boxSizing: "border-box",
//                                             border: "2px solid #ccc",
//                                             borderRadius: "4px",
//                                             backgroundColor: "#f8f8f8",
//                                             fontSize: "16px",
//                                             resize: "none"
//                                         }} class="w3l_text_box" id="title" onChange={handleChangeReminder} value={submitPush.title} placeholder="Title" name="Title" type="text" required />
//                                     </div>


//                                 </div>
//                             </div>

//                             <div class="">
//                                 <div class="row">
//                                     <div class="col-md-12">
//                                         <input style={{
//                                             // width: "17rem",
//                                             height: "45px",
//                                             padding: "12px 20px",
//                                             boxSizing: "border-box",
//                                             border: "2px solid #ccc",
//                                             borderRadius: "4px",
//                                             backgroundColor: "#f8f8f8",
//                                             fontSize: "16px",
//                                             resize: "none"
//                                         }} class="w3l_text_box" id="description" onChange={handleChangeReminder} value={submitPush.description} placeholder="Description" name="Description" type="text" required />
//                                     </div>


//                                 </div>
//                             </div>

//                             <div class="">
//                                 <div class="row">
//                                     <div class="col-md-12">
//                                         <input style={{
//                                             // width: "17rem",
//                                             height: "45px",
//                                             padding: "12px 20px",
//                                             boxSizing: "border-box",
//                                             border: "2px solid #ccc",
//                                             borderRadius: "4px",
//                                             backgroundColor: "#f8f8f8",
//                                             fontSize: "16px",
//                                             resize: "none"
//                                         }} class="w3l_text_box" id="image_url" onChange={handleChangeReminder} value={submitPush.image_url} placeholder="Image URL" name="Image URL" type="text" />
//                                     </div>


//                                 </div>
//                             </div>




//                             <div class="">
//                                 <div class="row">
//                                     <div class="col-md-6">
//                                         <input style={{
//                                             // width: "17rem",
//                                             height: "45px",
//                                             padding: "12px 20px",
//                                             boxSizing: "border-box",
//                                             border: "2px solid #ccc",
//                                             borderRadius: "4px",
//                                             backgroundColor: "#f8f8f8",
//                                             fontSize: "16px",
//                                             resize: "none"
//                                         }} class="w3l_text_box" id="type" onChange={handleChangeReminder} value={submitPush.type} placeholder="Type" name="Type" type="text" required />
//                                     </div>

//                                     <div class="col-md-6">
//                                         <input style={{
//                                             // width: "17rem",
//                                             height: "45px",
//                                             padding: "12px 20px",
//                                             boxSizing: "border-box",
//                                             border: "2px solid #ccc",
//                                             borderRadius: "4px",
//                                             backgroundColor: "#f8f8f8",
//                                             fontSize: "16px",
//                                             resize: "none"
//                                         }} class="w3l_text_box" id="id" onChange={handleChangeReminder} value={submitPush.id} placeholder="ID" name="ID" type="text" required />
//                                     </div>

//                                 </div>
//                             </div>


//                             <div class="modal-footer">
//                                 {/* <a class="btn bg-gradient-info btn-sm mb-0" style={{ color: "white" }} onClick={() => closeModel()}>Close</a> */}
//                                 {/* <a class="btn bg-gradient-success btn-sm mb-0" style={{ color: "white" }}>Submit</a> */}
//                                 <button type='submit' class="btn bg-gradient-danger btn-sm mb-0" style={{ backgroundColor: "green", color: "white" }}>Send</button>
//                             </div>

//                         </div>
//                     </form>
//                 </div>

//                 <div class="row">

//                     <div class="p-4">
//                         <div class="row mb-2">
//                             <div class="col-md-2">
//                                 <label class="mr-3">Filter: </label>
//                                 <select value={dataFilter} onChange={handleFilter} className="form-control">
//                                     <option value="--">Filter data</option>
//                                     <option value="all users">All Users</option>
//                                     <option value="test">Test Users</option>
//                                     <option value="workshop">Workshop</option>
//                                 </select>
//                             </div>
//                             <div class="col-md-2">
//                                 <label class="mr-3">Select Workshop: </label>
//                                 <select
//                                     className="form-control"
//                                     value={selectedWorkshop}
//                                     onChange={(e) => workshopFilter(e.target.value)}
//                                     style={{ width: "20rem" }}
//                                 >
//                                     <option value="">Select Workshop</option>
//                                     {workshopNames.map((name) => (
//                                         <option key={name} value={name}>
//                                             {name}
//                                         </option>
//                                     ))}
//                                 </select>
//                             </div>

//                         </div>

//                         <div class="card">
//                             {/* <input
//                                 type="text"
//                                 className="form-control form-control-sm mb-2"
//                                 placeholder="Search by Username, Full Name, or Phone Number"
//                                 value={searchQuery}
//                                 onChange={handleSearchChange}
//                             /> */}
//                             <div class="card-header pb-0 px-3">
//                                 <h6 class="mb-2">Users</h6>
//                             </div>
//                             <div class="">
//                                 <div class="card">
//                                     <div class="table-responsive fix-width scroll-inner">
//                                         <table class="table align-items-center mb-0">
//                                             <thead>
//                                                 <tr style={{ textAlign: "left" }}>
//                                                     <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Name</th>
//                                                     <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Phone No</th>
//                                                     <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">UID</th>
//                                                     <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">username</th>
//                                                     <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">reg_id</th>
//                                                 </tr>
//                                             </thead>
//                                             <tbody style={{ textAlign: "left" }}>
//                                                 {data.map(({ id, data, noti }) => {
//                                                     // console.log(data)
//                                                     return (
//                                                         <tr key={id}>
//                                                             <td>
//                                                                 <p className="text-xs font-weight-bold mb-0">{data.full_name}</p>
//                                                             </td>
//                                                             <td>
//                                                                 <div className="d-flex px-2 py-1">
//                                                                     <div className="d-flex flex-column justify-content-center">
//                                                                         <h6 className="mb-0 text-xs">{data.phone_no}</h6>
//                                                                     </div>
//                                                                 </div>
//                                                             </td>
//                                                             <td>
//                                                                 <div className="d-flex px-2 py-1">
//                                                                     <div className="d-flex flex-column justify-content-center">
//                                                                         <h6 className="mb-0 text-xs">{id}</h6>
//                                                                     </div>
//                                                                 </div>
//                                                             </td>
//                                                             <td>
//                                                                 <div className="d-flex px-2 py-1">
//                                                                     <div className="d-flex flex-column justify-content-center">
//                                                                         <h6 className="mb-0 text-xs">{data.username}</h6>
//                                                                     </div>
//                                                                 </div>
//                                                             </td>
//                                                             <td>
//                                                                 <div className="d-flex px-2 py-1">
//                                                                     <div className="d-flex flex-column justify-content-center" style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", width: "310px" }}>
//                                                                         <h6 className="mb-0 text-xs">{noti}</h6>
//                                                                     </div>
//                                                                 </div>
//                                                             </td>
//                                                         </tr>
//                                                     )
//                                                 }
//                                                 )}
//                                             </tbody>
//                                         </table>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>

//                 </div>

//             </main>
//         </>
//     )
// }

// export default PushNotifications;





// // import React, { useState, useEffect } from 'react';
// // import { useNavigate, Link, useParams } from 'react-router-dom';
// // import db from "./Firebase";
// // import { arrayUnion } from "firebase/firestore";
// // import { storage } from './Firebase';
// // import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
// // import Moment from 'moment';
// // import Swal from 'sweetalert2'
// // import { extendMoment } from 'moment-range';
// // import ViewAllRequests from './ViewAllRequests';
// // import { CSVLink } from "react-csv";

// // function PushNotifications() {
// //     const navigate = useNavigate();
// //     const moment = extendMoment(Moment);
// //     const [data, setData] = useState([]);
// //     const [dataR, setDataR] = useState([]);
// //     const [uid, setUid] = useState('');
// //     const [name, setName] = useState('');
// //     const [phone, setPhone] = useState('');
// //     const [uuid, setUuid] = useState('');
// //     const [date, setDate] = useState('');
// //     const [location, setLocation] = useState('');
// //     const [wname, setWname] = useState('');
// //     const [csvdata, setCsvdata] = useState([]);

// //     const [dataFilter, setDataFilter] = useState("all");


// //     const [filteredData, setFilteredData] = useState([]);
// //     const [selectedWorkshop, setSelectedWorkshop] = useState('');
// //     const [selectedWorkshopR, setSelectedWorkshopR] = useState('');
// //     const [workshopNames, setWorkshopNames] = useState([]);
// //     const [workshopNamesR, setWorkshopNamesR] = useState([]);
// //     const [filteredPhoneNumbers, setFilteredPhoneNumbers] = useState([]);

// //     const initialFormStateReminder = { title: '', description: '', image_url: '', type: '', id: '', created_date: new Date() }
// //     const [submitPush, setSubmitPush] = useState(initialFormStateReminder);

// //     const [reminderData, setReminderData] = useState({});

// //     const [notificationIds, setNotificationIds] = useState([]);

// //     useEffect(() => {
// //         const storedUsername = localStorage.getItem("email");
// //         const storedPassword = localStorage.getItem("password");
// //         const storedRemember = localStorage.getItem("remember");

// //         if (storedRemember === "false" || !storedUsername || !storedPassword) {
// //             navigate('/signin');
// //         }
// //     }, []);

// //     useEffect(() => {
// //         db.collection("notif_testing").onSnapshot((snapshot) => {
// //             const useraData = snapshot.docs.map((doc) => ({
// //                 id: doc.id,
// //                 data: doc.data(),
// //             }));

// //             setData(useraData);
// //         });

// //         db.collection("notification_ids").onSnapshot((snapshot) => {
// //             const notificationIdsData = snapshot.docs.map((doc) => doc.data());
// //             setNotificationIds(notificationIdsData);
// //         });
// //     }, []);


// //     const regIdsWithData = data
// //         .map(({ data }) => data.uid) // Extract the UID from each data object
// //         .filter(uid => notificationIds.some(item => item.uid === uid)) // Filter by matching UID
// //         .map(uid => {
// //             const matchingNotification = notificationIds.find(item => item.uid === uid);
// //             return matchingNotification.reg_id;
// //         });

// //     console.log(regIdsWithData);


// //     const handleChangeReminder = (e) => {
// //         const { id, value } = e.target
// //         setSubmitPush({ ...submitPush, [id]: value })
// //     }



// //     const handleSubmitPush = (e, msg) => {
// //         e.preventDefault()
// //         console.log(regIdsWithData);
// //         var myHeaders = new Headers();
// //         myHeaders.append("Content-Type", "application/json");
// //         myHeaders.append("Authorization", "key=AAAAnzqDoGw:APA91bHNYZDEZn5paZqVOV6evl4npGLdy7V1iixsNCGekPti0mkcTft5lHb9WruMaFjOdJrGVR9kkFzfC00HMfmvPUFo4sQhvXmXoom7clqOhXqMc7y5erUF75zJIxAzMZeuCtXx6PTS");

// //         var raw = JSON.stringify({
// //             "registration_ids": regIdsWithData,
// //             "notification": {
// //                 "body": submitPush.description,
// //                 "title": submitPush.title,
// //                 "android_channel_id": "youvati",
// //                 "image": submitPush.image_url,
// //                 "sound": true
// //             },
// //             "data": {
// //                 "type": submitPush.type,
// //                 "id": submitPush.id,
// //                 "image": submitPush.image_url
// //             }
// //         });

// //         var requestOptions = {
// //             method: 'POST',
// //             headers: myHeaders,
// //             body: raw,
// //             redirect: 'follow'
// //         };

// //         fetch("https://fcm.googleapis.com/fcm/send", requestOptions)
// //             .then(response => response.text())
// //             .then(result => {
// //                 console.log(result)
// //             })
// //             .catch(error => console.log('error', error));

// //     }

// //     const handleFilter = (e) => {
// //         console.log(e.target.value)
// //         setDataFilter(e.target.value); // Step 2: Update availability filter state
// //         if (e.target.value === "all users") {
// //             db.collection("personal_details").onSnapshot((snapshot) => {
// //                 const useraData = snapshot.docs.map((doc) => ({
// //                     id: doc.id,
// //                     data: doc.data(),
// //                 }));

// //                 setData(useraData);
// //             });
// //             const regIdsWithData = data
// //                 .map(({ data }) => data.uid) // Extract the UID from each data object
// //                 .filter(uid => notificationIds.some(item => item.uid === uid)) // Filter by matching UID
// //                 .map(uid => {
// //                     const matchingNotification = notificationIds.find(item => item.uid === uid);
// //                     return matchingNotification.reg_id;
// //                 });
// //         }

// //     }

// //     return (
// //         <>
// //             <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">

// //                 <div class="p-5">
// //                     <form class="" id="" onSubmit={handleSubmitPush}>
// //                         <div class="">
// //                             <div class="" style={{ textAlign: "center", }}><span style={{ color: "black", fontSize: "25px", fontWeight: "700", textTransform: "uppercase" }}>Send Push Notification</span></div>
// //                             <div class="" style={{ textAlign: "center", color: "black", paddingTop: "10px" }}>
// //                             </div>

// //                             <div class="">
// //                                 <div class="row">
// //                                     <div class="col-md-12">
// //                                         <input style={{
// //                                             // width: "17rem",
// //                                             height: "45px",
// //                                             padding: "12px 20px",
// //                                             boxSizing: "border-box",
// //                                             border: "2px solid #ccc",
// //                                             borderRadius: "4px",
// //                                             backgroundColor: "#f8f8f8",
// //                                             fontSize: "16px",
// //                                             resize: "none"
// //                                         }} class="w3l_text_box" id="title" onChange={handleChangeReminder} value={submitPush.title} placeholder="Title" name="Title" type="text" required />
// //                                     </div>


// //                                 </div>
// //                             </div>

// //                             <div class="">
// //                                 <div class="row">
// //                                     <div class="col-md-12">
// //                                         <input style={{
// //                                             // width: "17rem",
// //                                             height: "45px",
// //                                             padding: "12px 20px",
// //                                             boxSizing: "border-box",
// //                                             border: "2px solid #ccc",
// //                                             borderRadius: "4px",
// //                                             backgroundColor: "#f8f8f8",
// //                                             fontSize: "16px",
// //                                             resize: "none"
// //                                         }} class="w3l_text_box" id="description" onChange={handleChangeReminder} value={submitPush.description} placeholder="Description" name="Description" type="text" required />
// //                                     </div>


// //                                 </div>
// //                             </div>

// //                             <div class="">
// //                                 <div class="row">
// //                                     <div class="col-md-12">
// //                                         <input style={{
// //                                             // width: "17rem",
// //                                             height: "45px",
// //                                             padding: "12px 20px",
// //                                             boxSizing: "border-box",
// //                                             border: "2px solid #ccc",
// //                                             borderRadius: "4px",
// //                                             backgroundColor: "#f8f8f8",
// //                                             fontSize: "16px",
// //                                             resize: "none"
// //                                         }} class="w3l_text_box" id="image_url" onChange={handleChangeReminder} value={submitPush.image_url} placeholder="Image URL" name="Image URL" type="text" />
// //                                     </div>


// //                                 </div>
// //                             </div>




// //                             <div class="">
// //                                 <div class="row">
// //                                     <div class="col-md-6">
// //                                         <input style={{
// //                                             // width: "17rem",
// //                                             height: "45px",
// //                                             padding: "12px 20px",
// //                                             boxSizing: "border-box",
// //                                             border: "2px solid #ccc",
// //                                             borderRadius: "4px",
// //                                             backgroundColor: "#f8f8f8",
// //                                             fontSize: "16px",
// //                                             resize: "none"
// //                                         }} class="w3l_text_box" id="type" onChange={handleChangeReminder} value={submitPush.type} placeholder="Type" name="Type" type="text" required />
// //                                     </div>

// //                                     <div class="col-md-6">
// //                                         <input style={{
// //                                             // width: "17rem",
// //                                             height: "45px",
// //                                             padding: "12px 20px",
// //                                             boxSizing: "border-box",
// //                                             border: "2px solid #ccc",
// //                                             borderRadius: "4px",
// //                                             backgroundColor: "#f8f8f8",
// //                                             fontSize: "16px",
// //                                             resize: "none"
// //                                         }} class="w3l_text_box" id="id" onChange={handleChangeReminder} value={submitPush.id} placeholder="ID" name="ID" type="text" required />
// //                                     </div>

// //                                 </div>
// //                             </div>


// //                             <div class="modal-footer">
// //                                 {/* <a class="btn bg-gradient-info btn-sm mb-0" style={{ color: "white" }} onClick={() => closeModel()}>Close</a> */}
// //                                 {/* <a class="btn bg-gradient-success btn-sm mb-0" style={{ color: "white" }}>Submit</a> */}
// //                                 <button type='submit' class="btn bg-gradient-danger btn-sm mb-0" style={{ backgroundColor: "green", color: "white" }}>Submit</button>
// //                             </div>

// //                         </div>
// //                     </form>
// //                 </div>

// //                 <div class="row">

// //                     <div>
// //                         <label class="mr-3">Filter: </label>
// //                         <select value={dataFilter} onChange={handleFilter} className="form-control-sm mb-2">
// //                             <option value="available">Available</option>
// //                             <option value="all users">All Users</option>
// //                             <option value="notAvailable">Not Available</option>
// //                         </select>
// //                     </div>

// //                     <div class="p-4">

// //                         <div class="card">
// //                             <div class="card-header pb-0 px-3">
// //                                 <h6 class="mb-2">Workshop Reminder</h6>
// //                             </div>
// //                             <div class="">
// //                                 <div class="card">
// //                                     <div class="table-responsive fix-width scroll-inner">
// //                                         <table class="table align-items-center mb-0">
// //                                             <thead>
// //                                                 <tr style={{ textAlign: "left" }}>
// //                                                     <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Name</th>
// //                                                     <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Phone No</th>
// //                                                     <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">UID</th>
// //                                                     <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">username</th>
// //                                                     <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">reg_id</th>
// //                                                 </tr>
// //                                             </thead>
// //                                             <tbody style={{ textAlign: "left" }}>
// //                                                 {/* {data?.map(({ id, data }, index) => ( */}
// //                                                 {data.map(({ id, data }, index) => {
// //                                                     const matchingNotification = notificationIds.find((item) => item.uid === data.uid);
// //                                                     return (
// //                                                         <>
// //                                                             <tr key={id}>
// //                                                                 <td>
// //                                                                     <p class="text-xs font-weight-bold mb-0">{data.full_name}</p>
// //                                                                     {/* <p class="text-xs text-secondary mb-0">{moment(data.created_date.toDate().toISOString()).format("MM/DD/YY, h:mm a")}</p> */}
// //                                                                 </td>
// //                                                                 <td>
// //                                                                     <div class="d-flex px-2 py-1">
// //                                                                         <div class="d-flex flex-column justify-content-center">
// //                                                                             <h6 class="mb-0 text-xs">{data.phone_no}</h6>
// //                                                                         </div>
// //                                                                     </div>
// //                                                                 </td>

// //                                                                 <td>
// //                                                                     <div class="d-flex px-2 py-1">
// //                                                                         <div class="d-flex flex-column justify-content-center">
// //                                                                             <h6 class="mb-0 text-xs">{data.uid}</h6>
// //                                                                         </div>
// //                                                                     </div>
// //                                                                 </td>

// //                                                                 <td>
// //                                                                     <div class="d-flex px-2 py-1">
// //                                                                         <div class="d-flex flex-column justify-content-center">
// //                                                                             <h6 class="mb-0 text-xs">{data.username}</h6>
// //                                                                         </div>
// //                                                                     </div>
// //                                                                 </td>

// //                                                                 <td>
// //                                                                     <div class="d-flex px-2 py-1">
// //                                                                         <div class="d-flex flex-column justify-content-center" style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", width: "210px" }}>
// //                                                                             <h6 class="mb-0 text-xs">{matchingNotification ? matchingNotification.reg_id : 'N/A'}</h6>
// //                                                                         </div>
// //                                                                     </div>
// //                                                                 </td>

// //                                                             </tr>

// //                                                         </>
// //                                                     );
// //                                                 })}


// //                                             </tbody>
// //                                         </table>
// //                                     </div>
// //                                 </div>
// //                             </div>
// //                         </div>
// //                     </div>

// //                 </div>

// //             </main>
// //         </>
// //     )
// // }

// // export default PushNotifications;



