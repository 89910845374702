import React, { useState, useEffect } from 'react';
import * as XLSX from 'xlsx';
import DataTable from 'react-data-table-component';
import { useNavigate, Link, useParams } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2'
import db from "./Firebase";
import { storage } from './Firebase';
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";


function AddMentors() {
    let params = useParams();
    const navigate = useNavigate();

    const initialFormStateFeedback = {
        name: '',
        email: '',
        phone_no: '',
        mentorship_via: '',
        expert_in: '',
        pincode: '',
        address: '', about_yourself: '',
        authorization_code: '', created_date: new Date()
    }
    const [submit, setSubmit] = useState(initialFormStateFeedback)

    const [show, setshow] = useState(true);

    const [imgUrl, setImgUrl] = useState(null);
    const [progresspercent, setProgresspercent] = useState(0);


    const handleChange = (e) => {
        const { id, value } = e.target
        setSubmit({ ...submit, [id]: value })
    }

    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [country, setCountry] = useState('');
    const handleChangePincode = (e) => {

        const { id, value } = e.target
        setSubmit({ ...submit, [id]: value })
        if (e.target.value.length == 6) {
            axios.get(`https://api.postalpincode.in/pincode/${e.target.value}`)
                .then(res => {
                    const alldata = res.data;
                    console.log(alldata[0]['PostOffice'][0]);
                    setCity(alldata[0]['PostOffice'][0]['District'])
                    setState(alldata[0]['PostOffice'][0]['State'])
                    setCountry(alldata[0]['PostOffice'][0]['Country'])
                })
        }
    }

    const reset = () => {
        setSubmit({
            name: '',
            email: '',
            phone_no: '',
            mentorship_via: '',
            expert_in: '',
            pincode: '',
            address: '', about_yourself: '',
            authorization_code: '', created_date: new Date()
        })
    }


    const ids = ["rahul_122002", "ashutosh_560001", "mrinal_122004"];

    const handleSubmit = (e) => {
        // console.log(submit.usercode)
        // alert("okkk")
        e.preventDefault()
        if (ids.includes(submit.authorization_code)) {
            const file = e.target[0]?.files[0]
            // console.log(file)
            if (!file) return;
            const storageRef = ref(storage, `files/${file.name}`);
            const uploadTask = uploadBytesResumable(storageRef, file);
            setshow(false)

            uploadTask.on("state_changed",
                (snapshot) => {
                    const progress =
                        Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                    setProgresspercent(progress);
                },
                (error) => {
                    alert(error);
                },
                () => {
                    getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                        // setImgUrl(downloadURL)
                        db.collection("mentors_list").add({
                            'name': submit.name,
                            'email': submit.email,
                            'phone_no': submit.phone_no,
                            'mentorship_via': submit.mentorship_via,
                            'expert_in': submit.expert_in,
                            'pincode': submit.pincode,
                            'about_yourself': submit.about_yourself,
                            'city': city,
                            'state': state,
                            'image': downloadURL,
                            'country': country,
                            'address': submit.address,
                            'authorization_code': submit.authorization_code,
                            created_date: new Date()
                        }).then(() => {
                            console.log('Submitted Successfully');
                            const Toast = Swal.mixin({
                                toast: true,
                                background: '#69aba6',
                                position: 'top-end',
                                showConfirmButton: false,
                                timer: 3000,
                                timerProgressBar: true,
                                didOpen: (toast) => {
                                    toast.addEventListener('mouseenter', Swal.stopTimer)
                                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                                }
                            })

                            Toast.fire({
                                icon: 'success',
                                title: 'Submitted Successfully'
                            })
                            setshow(true)
                            // navigate(`/${submit.username}`);
                            setSubmit({
                                name: '',
                                email: '',
                                phone_no: '',
                                mentorship_via: '',
                                expert_in: '',
                                pincode: '',
                                address: '', about_yourself: '',
                                authorization_code: '', created_date: new Date()
                            })
                        })
                    });
                }
            );
        } else {
            alert("You are not authorization to perform this action")
        }
    }

    useEffect(() => {
        // alert(params.id)
    }, []);



    return (
        <>
            <div id="small-dialog" class="m-3">
                <div class="w3layouts_payment_online_form">
                    <form class="" id="" onSubmit={handleSubmit}>
                        <h4>Add Mentor</h4>
                        <ul>
                            <li style={{ textAlign: "left" }}><label for="myfile">Mentor Image:</label><input type="file" id="image" name="Image" accept="image/*" />
                            </li>
                            <li><input class="w3l_text_box" id="name" onChange={handleChange} value={submit.name} placeholder="Name" name="Name" type="text" required />
                            </li>
                        </ul>
                        <ul>
                            <li>
                                <input class="w3l_text_box" id="email" placeholder="Email" onChange={handleChange} value={submit.email} name="Email" type="email" required="" />
                            </li>
                            <li>
                                <input class="w3l_text_box" id="phone_no" onChange={handleChange} value={submit.phone_no} placeholder="10 Digit Phone Number" name="Phone" type="tel" minlength="10" maxlength="10" required />
                            </li>
                        </ul>
                        <ul>
                            <li>
                                {/* <label>Type</label> */}
                                <select class="form-control" id="mentorship_via" onChange={handleChange} required>
                                    <option value="">--Select Mentorship Via--</option>
                                    <option value="Call">Call</option>
                                    <option value="Video">Video</option>
                                    <option value="Email">Email</option>
                                    <option value="In person in your city">In person in your city</option>
                                    <option value="Any">Any</option>
                                </select>
                            </li>
                            <li>
                                <select class="form-control" id="expert_in" onChange={handleChange} required>
                                    <option value="">--Expert In--</option>
                                    <option value="Business Setup">Business Setup</option>
                                    <option value="Growth">Growth</option>
                                    <option value="New Product">New Product</option>
                                    <option value="Any for Beauty Parlour needs">Any for Beauty Parlour needs</option>
                                </select>
                            </li>
                        </ul>
                        <ul>
                            <li><input class="w3l_text_box" id="pincode" placeholder="*Pincode" onChange={handleChangePincode} value={submit.pincode} name="Pincode" type="number" required="" /></li>
                            <li><input class="w3l_text_box" id="city" placeholder="*City" onChange={handleChange} value={city} name="City" type="text" required="" /></li>

                        </ul>
                        <ul>
                            <li><input class="w3l_text_box" id="state" placeholder="*State" onChange={handleChange} value={state} name="State" type="text" required="" /></li>
                            <li><input class="w3l_text_box" id="country" placeholder="*Country" onChange={handleChange} value={country} name="Country" type="text" required="" /></li>

                        </ul>
                        <ul>
                            <li>
                                <input class="w3l_text_box" id="authorization_code" placeholder="*Authorization Code" onChange={handleChange} value={submit.authorization_code} name="Authorization Code" type="text" required />
                            </li>
                            <li>
                            </li>
                        </ul>

                        <ul>
                            <li class="w3ls_address"><input class="w3l_text_box" id="about_yourself" onChange={handleChange} value={submit.about_yourself} placeholder="About Yourself" name="About Yourself" type="text" style={{ height: "6rem" }} required /></li>
                        </ul>
                        <ul>
                            <li class="w3ls_address"><input class="w3l_text_box" id="address" onChange={handleChange} value={submit.address} placeholder="Your Address Here....." name="Address" type="text" style={{ height: "6rem" }} required /></li>
                        </ul>



                        <ul class="w3_payment_sendbtns">
                            <li><input type="reset" value="Cancel" onClick={() => navigate(`/quicklinks`)} /></li>
                            <li class="mr-4"><input type="reset" data-dismiss="modal" aria-label="Close" onClick={reset} value="Reset" /></li>
                            <li>{show ? <input type="submit" value="Submit" /> : "Please Wait..."}</li>
                        </ul>

                        <div class="clearfix"> </div>
                    </form>
                </div>
            </div>
        </>


    );
}
export default AddMentors;