import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { useNavigate, Link } from 'react-router-dom';
import db from "./Firebase";
import { storage } from './Firebase';
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import Multiselect from 'multiselect-react-dropdown';

function AdvertiseDownload() {
    const navigate = useNavigate();
    const moment = extendMoment(Moment);
    const initialFormStateFeedback = { id: '', name: '', phone_no: '', design_name: '', link: '', type: '', created_date: new Date() }
    const [submit, setSubmit] = useState(initialFormStateFeedback);
    const [data, setData] = useState([]);
    const [editID, setEditID] = useState("");
    const [edit, setEdit] = useState(false);
    const [show, setshow] = useState(true);
    const [progresspercent, setProgresspercent] = useState(0);
    const [lastVisible, setLastVisible] = useState(null);
    const [hasMore, setHasMore] = useState(true);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const storedUsername = localStorage.getItem("email");
        const storedPassword = localStorage.getItem("password");
        const storedRemember = localStorage.getItem("remember");

        if (storedRemember === "false" || !storedUsername || !storedPassword) {
            navigate('/signin');
        } else {
            fetchInitialData();
        }
    }, []);

    const fetchInitialData = async () => {
        setIsLoading(true);
        const snapshot = await db.collection("download_advertise_content")
            .orderBy("created_date", "desc")
            .limit(20)
            .get();
        const lastVisibleDoc = snapshot.docs[snapshot.docs.length - 1];
        const newData = snapshot.docs.map((doc) => ({
            id: doc.id,
            data: doc.data(),
        }));

        setData(newData);
        setLastVisible(lastVisibleDoc);
        setHasMore(snapshot.docs.length === 20);
        setIsLoading(false);
    };

    const loadMoreData = async () => {
        if (!lastVisible) return;

        setIsLoading(true);
        const snapshot = await db.collection("download_advertise_content")
            .orderBy("created_date", "desc")
            .startAfter(lastVisible)
            .limit(20)
            .get();
        const lastVisibleDoc = snapshot.docs[snapshot.docs.length - 1];
        const newData = snapshot.docs.map((doc) => ({
            id: doc.id,
            data: doc.data(),
        }));

        setData((prevData) => [...prevData, ...newData]);
        setLastVisible(lastVisibleDoc);
        setHasMore(snapshot.docs.length === 20);
        setIsLoading(false);
    };

    const handleChange = (e) => {
        const { id, value } = e.target;
        setSubmit({ ...submit, [id]: value });
    };

    const changeStatus = (data, id) => {
        setEdit(true);
        setEditID(id);
        setSubmit({ id: id, name: data.name, phone_no: data.phone_no, design_name: data.design_name, link: data.link, type: data.type, created_date: new Date() });
        openModalShow();
    };

    const changeStatusAdd = () => {
        setEdit(false);
        setSubmit({ id: '', name: '', phone_no: '', design_name: '', link: '', type: '', created_date: new Date() });
        openModalShow();
    };

    const openModalShow = () => {
        window.openModelshow();
    };

    const closeModel = () => {
        window.openModelNew();
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (edit === false) {
            db.collection("download_advertise_content").add({
                'name': submit.name,
                'phone_no': submit.phone_no,
                'design_name': submit.design_name,
                'link': submit.link,
                'type': submit.type,
                'created_date': new Date(),
            }).then(() => {
                showSuccessToast('Created Successfully');
                closeModel();
                setshow(true);
                setSubmit(initialFormStateFeedback);
            });
        } else {
            db.collection("download_advertise_content").doc(editID).update({
                'name': submit.name,
                'phone_no': submit.phone_no,
                'design_name': submit.design_name,
                'link': submit.link,
                'type': submit.type,
                'created_date': new Date(),
            }).then(() => {
                showSuccessToast('Updated Successfully');
                closeModel();
                setSubmit(initialFormStateFeedback);
            });
        }
    };

    const showSuccessToast = (message) => {
        const Toast = Swal.mixin({
            toast: true,
            background: '#69aba6',
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer);
                toast.addEventListener('mouseleave', Swal.resumeTimer);
            }
        });

        Toast.fire({
            icon: 'success',
            title: message
        });
    };

    return (
        <>
            <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg">
                <div className="p-2" style={{ paddingBottom: "5px", display: "flex", justifyContent: "space-between" }}>
                    <Link to="/admin_panel">
                        <img src="/images/menubutton.png" className="menu-toggle" alt="Responsive image" style={{ height: "2rem" }} />
                    </Link>
                    <a className="btn bg-gradient-primary btn-sm mb-0" style={{ color: "white" }} onClick={changeStatusAdd}>+&nbsp; Add</a>
                </div>

                <div className="card">
                    <div className="table-responsive fix-width scroll-inner">
                        <table className="table table-bordered table-hover">
                            <thead className="thead-dark" style={{ position: "sticky", top: "0" }}>
                                <tr style={{ textAlign: "left" }}>
                                    <th className="col">Name</th>
                                    <th className="col ps-2">Phone No</th>
                                    <th className="text-center col">Design Name</th>
                                    <th className="text-center col">Type</th>
                                    <th className="text-center col">Created Date</th>
                                    <th className="text-center col">Link</th>
                                    <th className="text-center col">Action</th>
                                </tr>
                            </thead>
                            <tbody style={{ textAlign: "left" }}>
                                {data?.map(({ id, data }, index) => (
                                    <tr key={id}>
                                        <td>
                                            <p className="text-xs font-weight-bold mb-0">{data.name}</p>
                                        </td>
                                        <td className="align-middle text-center text-sm">
                                            <p className="text-xs font-weight-bold mb-0">{data.phone_no}</p>
                                        </td>
                                        <td className="align-middle text-center text-sm">
                                            <p className="text-xs font-weight-bold mb-0">{data.design_name}</p>
                                        </td>
                                        <td className="align-middle text-center text-sm">
                                            <p className="text-xs font-weight-bold mb-0">{data.type}</p>
                                        </td>
                                        <td className="align-middle text-center text-sm">
                                            <span className="text-secondary text-xs font-weight-normal">{moment(data.created_date.toDate().toISOString()).format("MM/DD/YY, h:mm a")}</span>
                                        </td>
                                        <td className="align-middle text-center text-sm">
                                            <p className="text-xs font-weight-bold mb-0">{data.link}</p>
                                        </td>
                                        <td className="align-middle text-center" onClick={() => changeStatus(data, id)}>
                                            <span className="text-info text-xs font-weight-normal" style={{ cursor: "pointer" }}>Edit</span>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        {hasMore && (
                            <div className="" style={{ paddingTop: "2rem" }}>
                                <button onClick={loadMoreData} className="btn btn-dark" disabled={isLoading}>
                                    {isLoading ? "Loading..." : "Load More"}
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </main>

            <div className="col-md-12">
                <div className="myId modal fade" id="" tabIndex="-1" role="dialog" aria-labelledby="modal-form" aria-hidden="true" data-backdrop="static">
                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                                <form onSubmit={handleSubmit}>
                                    <div style={{ textAlign: "center" }}>
                                        <span style={{ color: "black", fontSize: "25px", fontWeight: "700", textTransform: "uppercase" }}>Advertise Download Content</span>
                                    </div>
                                    <div style={{ textAlign: "center", color: "black", paddingTop: "10px" }}></div>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <input style={inputStyle} className="w3l_text_box" id="name" placeholder="Name" name="name" type="text" onChange={handleChange} value={submit.name} required />
                                        </div>
                                        <div className="col-md-4">
                                            <input style={inputStyle} className="w3l_text_box" id="phone_no" placeholder="Phone No" name="Phone No" type="text" onChange={handleChange} value={submit.phone_no} required />
                                        </div>
                                        <div className="col-md-4">
                                            <input style={inputStyle} className="w3l_text_box" id="design_name" placeholder="Design Name" name="Design Name" type="text" onChange={handleChange} value={submit.design_name} required />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <select className="form-control" style={selectStyle} id="type" onChange={handleChange} value={submit.type} required>
                                                <option value="">Select Status</option>
                                                <option value="Premium">Premium</option>
                                                <option value="Free">Free</option>
                                            </select>
                                        </div>
                                        <div className="col-md-4">
                                            <input style={inputStyle} className="w3l_text_box" id="link" placeholder="Link" name="Link" onChange={handleChange} value={submit.link} type="text" required />
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <a className="btn bg-gradient-info btn-sm mb-0" style={{ color: "white" }} onClick={closeModel}>Close</a>
                                        <button type="submit" className="btn bg-gradient-danger btn-sm mb-0" style={{ backgroundColor: "green", color: "white" }}>{edit ? "Update" : "Submit"}</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

const inputStyle = {
    height: "45px",
    padding: "12px 20px",
    boxSizing: "border-box",
    border: "2px solid #ccc",
    borderRadius: "4px",
    backgroundColor: "#f8f8f8",
    fontSize: "16px",
    resize: "none"
};

const selectStyle = {
    height: "45px",
    padding: "5px 16px",
    boxSizing: "border-box",
    border: "2px solid #ccc",
    borderRadius: "4px",
    backgroundColor: "#f8f8f8",
    fontSize: "16px",
    resize: "none"
};

export default AdvertiseDownload;



// import React, { useState, useEffect } from 'react';
// import AdminPanel from './AdminPanel';
// import Swal from 'sweetalert2'
// import { useNavigate, Link, useParams } from 'react-router-dom';
// import db from "./Firebase";
// import { storage } from './Firebase';
// import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
// import Moment from 'moment';
// import { extendMoment } from 'moment-range';
// import ViewAppData from './ViewAppData';
// import Multiselect from 'multiselect-react-dropdown';


// function AdvertiseDownload() {
//     const navigate = useNavigate();
//     const moment = extendMoment(Moment);
//     const initialFormStateFeedback = { id: '', name: '', phone_no: '', design_name: '', link: '', type: '', created_date: new Date() }
//     const [submit, setSubmit] = useState(initialFormStateFeedback)
//     const [data, setData] = useState([]);
//     const [editID, setEditID] = useState("");
//     const [edit, setEdit] = useState(false);
//     const [ShowProgress, setShowProgress] = useState(false);
//     const [show, setshow] = useState(true);
//     const [progresspercent, setProgresspercent] = useState(0);

//     const [options, setOptions] = useState([
//         { name: 'Online' },
//         { name: 'Aurangabad' },
//         { name: 'Bathinda' },
//         { name: 'Ghaziabad' },
//         { name: 'Meerut' },
//         { name: 'Mansa' },
//         { name: 'Patiala' }
//     ]);

//     const [selectedValue, setSelectedValue] = useState([]);


//     const onSelect = (selectedList, selectedItem) => {
//         // Handle selection
//         // ...
//         setSelectedValue(selectedList);
//     }

//     const onRemove = (selectedList, removedItem) => {
//         // Handle removal
//         // ...
//         setSelectedValue(selectedList);
//     }

//     useEffect(() => {
//         const storedUsername = localStorage.getItem("email");
//         const storedPassword = localStorage.getItem("password");
//         const storedRemember = localStorage.getItem("remember");

//         if (storedRemember === "false" || !storedUsername || !storedPassword) {
//             navigate('/signin');
//         }
//     }, []);


//     const handleChange = (e) => {
//         const { id, value } = e.target
//         setSubmit({ ...submit, [id]: value })
//     }

//     const changeStatus = (data, id) => {
//         setEdit(true)
//         setEditID(id)
//         setSubmit({ id: id, name: data.name, phone_no: data.phone_no, design_name: data.design_name, link: data.link, type: data.type, created_date: new Date() })
//         openModalShow();
//     }

//     const changeStatusAdd = () => {
//         setEdit(false)
//         setSubmit({ id: '', name: '', phone_no: '', design_name: '', link: '', type: '', created_date: new Date() });
//         openModalShow();
//     }


//     const openModalShow = () => {
//         // alert("ooo")
//         window.openModelshow();
//     }

//     const closeModel = () => {
//         window.openModelNew();
//     }

//     const handleSubmit = (e) => {
//         e.preventDefault()
//         // console.log(locationData);

//         if (edit === false) {

//             db.collection("download_advertise_content").add({
//                 'name': submit.name,
//                 'phone_no': submit.phone_no,
//                 'design_name': submit.design_name,
//                 'link': submit.link,
//                 'type': submit.type,
//                 'created_date': new Date(),
//             }).then(() => {
//                 console.log('Created Successfully');
//                 const Toast = Swal.mixin({
//                     toast: true,
//                     background: '#69aba6',
//                     position: 'top-end',
//                     showConfirmButton: false,
//                     timer: 3000,
//                     timerProgressBar: true,
//                     didOpen: (toast) => {
//                         toast.addEventListener('mouseenter', Swal.stopTimer)
//                         toast.addEventListener('mouseleave', Swal.resumeTimer)
//                     }
//                 })

//                 Toast.fire({
//                     icon: 'success',
//                     title: 'Created Successfully'
//                 })
//                 closeModel();
//                 setshow(true)
//                 setSubmit({ id: '', name: '', phone_no: '', design_name: '', link: '', type: '', created_date: new Date() })
//             })

//         } else {

//             db.collection("download_advertise_content").doc(editID).update({
//                 'name': submit.name,
//                 'phone_no': submit.phone_no,
//                 'design_name': submit.design_name,
//                 'link': submit.link,
//                 'type': submit.type,
//                 'created_date': new Date(),
//             }).then(() => {
//                 console.log('Updated Successfully');
//                 const Toast = Swal.mixin({
//                     toast: true,
//                     background: '#69aba6',
//                     position: 'top-end',
//                     showConfirmButton: false,
//                     timer: 3000,
//                     timerProgressBar: true,
//                     didOpen: (toast) => {
//                         toast.addEventListener('mouseenter', Swal.stopTimer)
//                         toast.addEventListener('mouseleave', Swal.resumeTimer)
//                     }
//                 })

//                 Toast.fire({
//                     icon: 'success',
//                     title: 'Updated Successfully'
//                 })
//                 closeModel();

//                 setSubmit({ id: '', title: '', seen: [], message: '', link: '', delete: [], status: '', image_url: '', created_date: new Date() })
//             })
//         }




//     }



//     useEffect(() => {
//         db.collection("download_advertise_content").orderBy("created_date", "desc").onSnapshot((snapshot) => {

//             setData(
//                 snapshot.docs.map((doc) => ({
//                     id: doc.id,
//                     data: doc.data(),
//                 }))
//             );
//         });


//     }, []);

//     return (
//         <>
//             <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
//                 <div class="p-2" style={{ paddingBottom: "5px", display: "flex", justifyContent: "space-between" }}>
//                     {/* <span class="btn bg-gradient-secondary btn-sm mb-0 mr-2" style={{ color: "white" }} >&nbsp; Download Excel</span> */}
//                     <Link to="/admin_panel"><img src="/images/menubutton.png" class="menu-toggle" alt="Responsive image" style={{ height: "2rem" }} /></Link>
//                     <a class="btn bg-gradient-primary btn-sm mb-0" style={{ color: "white" }} onClick={() => changeStatusAdd()}>+&nbsp; Add</a>

//                 </div>

//                 <div class="">

//                     <div class="card">
//                         <div class="table-responsive fix-width scroll-inner">
//                             <table class="table table-bordered table-hover">
//                                 <thead class="thead-dark" style={{ position: "sticky", top: "0" }}>
//                                     <tr style={{ textAlign: "left" }}>
//                                         <th class="col">Name</th>
//                                         <th class="col ps-2">Phone No</th>
//                                         <th class="text-center col">Design Name</th>
//                                         <th class="text-center col">Type</th>
//                                         <th class="text-center col">Created Date</th>
//                                         <th class="text-center col">Link</th>
//                                         <th class="text-center col">Action</th>
//                                         {/* <th class="text-secondary opacity-7"></th> */}
//                                     </tr>
//                                 </thead>
//                                 <tbody style={{ textAlign: "left" }}>
//                                     {data?.map(({ id, data }, index) => (
//                                         <>
//                                             <tr>

//                                                 <td>
//                                                     <p class="text-xs font-weight-bold mb-0">{data.name}</p>
//                                                     {/* <p class="text-xs text-secondary mb-0">Organization</p> */}
//                                                 </td>
//                                                 <td class="align-middle text-center text-sm">
//                                                     <p class="text-xs font-weight-bold mb-0">{data.phone_no}</p>
//                                                 </td>
//                                                 <td class="align-middle text-center text-sm">
//                                                     <p class="text-xs font-weight-bold mb-0">{data.design_name}</p>
//                                                 </td>
//                                                 <td class="align-middle text-center text-sm">
//                                                     <p class="text-xs font-weight-bold mb-0">{data.type}</p>
//                                                 </td>
//                                                 <td class="align-middle text-center text-sm">
//                                                     <span class="text-secondary text-xs font-weight-normal">{moment(data.created_date.toDate().toISOString()).format("MM/DD/YY, h:mm a")}</span>
//                                                 </td>
//                                                 <td class="align-middle text-center text-sm">
//                                                     <p class="text-xs font-weight-bold mb-0">{data.link}</p>
//                                                 </td>

//                                                 <td class="align-middle text-center" onClick={() => changeStatus(data, id)}>
//                                                     <span class="text-info text-xs font-weight-normal" style={{ cursor: "pointer" }}>Edit</span>
//                                                 </td>

//                                             </tr>
//                                         </>
//                                     ))}
//                                 </tbody>
//                             </table>
//                         </div>
//                     </div>
//                 </div>

//             </main>






//             <div class="col-md-12">
//                 <div class="myId modal fade" id="" tabindex="-1" role="dialog" aria-labelledby="modal-form" aria-hidden="true" data-backdrop="static">
//                     <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
//                         <div class="modal-content">
//                             <div class="modal-body">


//                                 <div class="">
//                                     <form onSubmit={handleSubmit}>
//                                         <div class="">
//                                             <div class="" style={{ textAlign: "center", }}><span style={{ color: "black", fontSize: "25px", fontWeight: "700", textTransform: "uppercase" }}>Advertise Download Content </span></div>
//                                             <div class="" style={{ textAlign: "center", color: "black", paddingTop: "10px" }}>
//                                             </div>
//                                             <div class="">
//                                                 <div class="row">
//                                                     <div class="col-md-4">
//                                                         <input style={{
//                                                             // width: "17rem",
//                                                             height: "45px",
//                                                             padding: "12px 20px",
//                                                             boxSizing: "border-box",
//                                                             border: "2px solid #ccc",
//                                                             borderRadius: "4px",
//                                                             backgroundColor: "#f8f8f8",
//                                                             fontSize: "16px",
//                                                             resize: "none"
//                                                         }} class="w3l_text_box" id="name" placeholder="Name" name="name" type="text" onChange={handleChange} value={submit.name} required />
//                                                     </div>

//                                                     <div class="col-md-4">
//                                                         <input style={{
//                                                             // width: "17rem",
//                                                             height: "45px",
//                                                             padding: "12px 20px",
//                                                             boxSizing: "border-box",
//                                                             border: "2px solid #ccc",
//                                                             borderRadius: "4px",
//                                                             backgroundColor: "#f8f8f8",
//                                                             fontSize: "16px",
//                                                             resize: "none"
//                                                         }} class="w3l_text_box" id="phone_no" placeholder="Phone No" name="Phone No" type="text" onChange={handleChange} value={submit.phone_no} required />
//                                                     </div>
//                                                     <div class="col-md-4">
//                                                         <input style={{
//                                                             // width: "17rem",
//                                                             height: "45px",
//                                                             padding: "12px 20px",
//                                                             boxSizing: "border-box",
//                                                             border: "2px solid #ccc",
//                                                             borderRadius: "4px",
//                                                             backgroundColor: "#f8f8f8",
//                                                             fontSize: "16px",
//                                                             resize: "none"
//                                                         }} class="w3l_text_box" id="design_name" placeholder="Design Name" name="Design Name" type="text" onChange={handleChange} value={submit.design_name} required />
//                                                     </div>

//                                                 </div>
//                                             </div>


//                                             <div class="pb-4">
//                                                 <div class="row">
//                                                     <div class="col-md-4">
//                                                         <li>
//                                                             <select class="form-control" style={{
//                                                                 // width: "17rem",
//                                                                 height: "45px",
//                                                                 padding: "5px 16px",
//                                                                 boxSizing: "border-box",
//                                                                 border: "2px solid #ccc",
//                                                                 borderRadius: "4px",
//                                                                 backgroundColor: "#f8f8f8",
//                                                                 fontSize: "16px",
//                                                                 resize: "none"
//                                                             }} id="type" onChange={handleChange} value={submit.type} required>
//                                                                 <option value="">Select Status</option>
//                                                                 <option value="Premium">Premium</option>
//                                                                 <option value="Free">Free</option>
//                                                             </select>
//                                                         </li>
//                                                     </div>
//                                                     <div class="col-md-4">
//                                                         <input style={{
//                                                             // width: "17rem",
//                                                             height: "45px",
//                                                             padding: "12px 20px",
//                                                             boxSizing: "border-box",
//                                                             border: "2px solid #ccc",
//                                                             borderRadius: "4px",
//                                                             backgroundColor: "#f8f8f8",
//                                                             fontSize: "16px",
//                                                             resize: "none"
//                                                         }} class="w3l_text_box" id="link" placeholder="Link" name="Link" onChange={handleChange} value={submit.link} type="text" required />
//                                                     </div>

//                                                     {/* <div class="col-md-4">
//                                                         <input style={{
//                                                             // width: "17rem",
//                                                             height: "45px",
//                                                             padding: "12px 20px",
//                                                             boxSizing: "border-box",
//                                                             border: "2px solid #ccc",
//                                                             borderRadius: "4px",
//                                                             backgroundColor: "#f8f8f8",
//                                                             fontSize: "16px",
//                                                             resize: "none"
//                                                         }} class="w3l_text_box" id="amount" placeholder="Amount" name="amount" type="number" onChange={handleChange} value={submit.amount} required />
//                                                     </div> */}
//                                                 </div>
//                                             </div>
//                                             {/*



//                                             <textarea style={{
//                                                 width: "100%",
//                                                 height: "15px",
//                                                 padding: "12px 20px",
//                                                 boxSizing: "border-box",
//                                                 border: "2px solid #ccc",
//                                                 borderRadius: "4px",
//                                                 backgroundColor: "#f8f8f8",
//                                                 fontSize: "16px",
//                                                 resize: "none"
//                                             }} class="w3l_text_box" id="message" placeholder="Write a message..." name="Message" onChange={handleChange} value={submit.message} required />


//  */}


//                                             <div class="modal-footer">
//                                                 {/* {submit.id == "" ? <></> : <a class="btn bg-gradient-danger btn-sm mb-0" style={{ color: "white" }} onClick={() => deleteData(submit.id)}>Delete</a>} */}
//                                                 <a class="btn bg-gradient-info btn-sm mb-0" style={{ color: "white" }} onClick={closeModel}>Close</a>
//                                                 <button type='submit' class="btn bg-gradient-danger btn-sm mb-0" style={{ backgroundColor: "green", color: "white" }}>{edit ? "Update" : "Submit"}</button>
//                                             </div>


//                                         </div>
//                                     </form>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div >

//         </>
//     )
// }

// export default AdvertiseDownload;
