import React, { useState, useEffect } from 'react';
import { useNavigate, Link, useParams } from 'react-router-dom';

function ViewAppData() {

    return (
        <>
            <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
                <div class="container-fluid py-4">
                    <div class="row">
                        <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4 pb-4">
                            <Link to="/course_data"> <div class="card" style={{ cursor: "pointer" }}>
                                <div class="card-header p-3 pt-2" style={{ borderStyle: "none" }}>
                                    <div
                                        class="icon-shape bg-gradient-dark shadow-dark text-center border-radius-xl mt-n4 position-absolute">
                                        <i class="material-icons opacity-10">weekend</i>
                                    </div>
                                    <div class="text-end pt-1">
                                        <p class="text-sm mb-0 text-capitalize" style={{ fontWeight: "bold" }}>Courses</p>
                                    </div>
                                </div>
                            </div>
                            </Link>
                        </div>
                        <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4 pb-4">
                            <Link to="/course_data_hindi"> <div class="card" style={{ cursor: "pointer" }}>
                                <div class="card-header p-3 pt-2" style={{ borderStyle: "none" }}>
                                    <div
                                        class="icon-shape bg-gradient-dark shadow-dark text-center border-radius-xl mt-n4 position-absolute">
                                        <i class="material-icons opacity-10">weekend</i>
                                    </div>
                                    <div class="text-end pt-1">
                                        <p class="text-sm mb-0 text-capitalize" style={{ fontWeight: "bold" }}>Courses Hindi</p>
                                    </div>
                                </div>
                            </div>
                            </Link>
                        </div>
                        {/* <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4 pb-4" style={{ cursor: "pointer" }}>
                            <Link to="/mentor_data">  <div class="card">
                                <div class="card-header p-3 pt-2" style={{ borderStyle: "none" }}>
                                    <div
                                        class="icon-shape bg-gradient-primary shadow-primary text-center border-radius-xl mt-n4 position-absolute">
                                        <i class="material-icons opacity-10">person</i>
                                    </div>
                                    <div class="text-end pt-1">
                                        <p class="text-sm mb-0 text-capitalize" style={{ fontWeight: "bold" }}>Mentors</p>
                                    </div>
                                </div>

                            </div></Link>
                        </div> */}
                        {/* <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4 pb-4">
                            <Link to="/getonrent_data"> <div class="card" style={{ cursor: "pointer" }}>
                                <div class="card-header p-3 pt-2" style={{ borderStyle: "none" }}>
                                    <div
                                        class="icon-shape bg-gradient-success shadow-success text-center border-radius-xl mt-n4 position-absolute">
                                        <i class="material-icons opacity-10">handshake</i>
                                    </div>
                                    <div class="text-end pt-1">
                                        <p class="text-sm mb-0 text-capitalize" style={{ fontWeight: "bold" }}>Get On Rent</p>
                                    </div>
                                </div>
                            </div>
                            </Link>
                        </div> */}

                        <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4 pb-4">
                            <Link to="/promotional_data"> <div class="card" style={{ cursor: "pointer" }}>
                                <div class="card-header p-3 pt-2" style={{ borderStyle: "none" }}>
                                    <div
                                        class="icon-shape bg-gradient-warning shadow-success text-center border-radius-xl mt-n4 position-absolute">
                                        <i class="material-icons opacity-10">interests</i>
                                    </div>
                                    <div class="text-end pt-1">
                                        <p class="text-sm mb-0 text-capitalize" style={{ fontWeight: "bold" }}>Promotions</p>
                                    </div>
                                </div>

                            </div>
                            </Link>
                        </div>

                        <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4 pb-4">
                            <Link to="/freebusinesscards_data"> <div class="card" style={{ cursor: "pointer" }}>
                                <div class="card-header p-3 pt-2" style={{ borderStyle: "none" }}>
                                    <div
                                        class="icon-shape bg-gradient-info shadow-success text-center border-radius-xl mt-n4 position-absolute">
                                        <i class="material-icons opacity-10">image</i>
                                    </div>
                                    <div class="text-end pt-1">
                                        <p class="text-sm mb-0 text-capitalize" style={{ fontWeight: "bold" }}>Business Cards</p>
                                    </div>
                                </div>

                            </div>
                            </Link>
                        </div>

                        <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4 pb-4">
                            <Link to="/premiumcards_data"> <div class="card" style={{ cursor: "pointer" }}>
                                <div class="card-header p-3 pt-2" style={{ borderStyle: "none" }}>
                                    <div
                                        class="icon-shape bg-gradient-secondary shadow-success text-center border-radius-xl mt-n4 position-absolute">
                                        <i class="material-icons opacity-10">style</i>
                                    </div>
                                    <div class="text-end pt-1">
                                        <p class="text-sm mb-0 text-capitalize" style={{ fontWeight: "bold" }}>Premium Cards</p>
                                    </div>
                                </div>

                            </div>
                            </Link>
                        </div>

                        {/* <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4 pb-4">
                            <Link to="/consumables_data"> <div class="card" style={{ cursor: "pointer" }}>
                                <div class="card-header p-3 pt-2" style={{ borderStyle: "none" }}>
                                    <div
                                        class="icon-shape bg-gradient-danger shadow-success text-center border-radius-xl mt-n4 position-absolute">
                                        <i class="material-icons opacity-10">margin</i>
                                    </div>
                                    <div class="text-end pt-1">
                                        <p class="text-sm mb-0 text-capitalize" style={{ fontWeight: "bold" }}>Consumables</p>
                                    </div>
                                </div>

                            </div>
                            </Link>
                        </div> */}

                        <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4 pb-4">
                            <Link to="/workshop_data">    <div class="card">
                                <div class="card-header p-3 pt-2" style={{ borderStyle: "none" }}>
                                    <div
                                        class="icon-shape bg-gradient-primary shadow-success text-center border-radius-xl mt-n4 position-absolute">
                                        <i class="material-icons opacity-10">workspaces</i>
                                    </div>
                                    <div class="text-end pt-1">
                                        <p class="text-sm mb-0 text-capitalize" style={{ fontWeight: "bold" }}>Workshop</p>
                                    </div>
                                </div>

                            </div></Link>
                        </div>

                        <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4 pb-4">
                            <Link to="/workshop_data_hindi">    <div class="card">
                                <div class="card-header p-3 pt-2" style={{ borderStyle: "none" }}>
                                    <div
                                        class="icon-shape bg-gradient-primary shadow-success text-center border-radius-xl mt-n4 position-absolute">
                                        <i class="material-icons opacity-10">workspaces</i>
                                    </div>
                                    <div class="text-end pt-1">
                                        <p class="text-sm mb-0 text-capitalize" style={{ fontWeight: "bold" }}>Workshop Hindi</p>
                                    </div>
                                </div>

                            </div></Link>
                        </div>

                        <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4 pb-4" style={{ cursor: "pointer" }}>
                            <Link to="/users_profile">  <div class="card">
                                <div class="card-header p-3 pt-2" style={{ borderStyle: "none" }}>
                                    <div
                                        class="icon-shape bg-gradient-primary shadow-primary text-center border-radius-xl mt-n4 position-absolute">
                                        <i class="material-icons opacity-10">person</i>
                                    </div>
                                    <div class="text-end pt-1">
                                        <p class="text-sm mb-0 text-capitalize" style={{ fontWeight: "bold" }}>Users Profile</p>
                                    </div>
                                </div>

                            </div></Link>
                        </div>

                        <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4 pb-4" style={{ cursor: "pointer" }}>
                            <Link to="/portfolio_data">  <div class="card">
                                <div class="card-header p-3 pt-2" style={{ borderStyle: "none" }}>
                                    <div
                                        class="icon-shape bg-gradient-primary shadow-primary text-center border-radius-xl mt-n4 position-absolute">
                                        <i class="material-icons opacity-10">person</i>
                                    </div>
                                    <div class="text-end pt-1">
                                        <p class="text-sm mb-0 text-capitalize" style={{ fontWeight: "bold" }}>Portfolio</p>
                                    </div>
                                </div>

                            </div></Link>
                        </div>



                    </div>
                </div>


            </main>
        </>
    )
}

export default ViewAppData;