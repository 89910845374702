import React, { useState, useEffect } from 'react';
import { useNavigate, Link, useParams } from 'react-router-dom';
import db from "./Firebase";
import firebaseApp from "./Firebase";
import { arrayUnion } from "firebase/firestore";
import { storage } from './Firebase';
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import ViewAllRequests from './ViewAllRequests';
import ViewAppData from './ViewAppData';
import { CSVLink } from "react-csv";

function AppReferral() {
    const moment = extendMoment(Moment);
    const [data, setData] = useState([]);

    const headers = [
        "Full Name",
        "Phone No",
        "Referral Code",
        "Username",
        "Created Date",
        // Add more column labels as needed
    ];

    useEffect(() => {
        const fetchData = async () => {
            try {
                const snapshot = await db.collection("app_referral")
                    .orderBy('referral_code')
                    .orderBy("created_date", "desc")
                    .where("referral_code", "!=", "")
                    .get();

                setData(
                    snapshot.docs.map((doc) => ({
                        id: doc.id,
                        data: doc.data(),
                    }))
                );
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);



    // Function to convert data to CSV format
    const prepareCSVData = () => {
        const csvData = data.map((item, index) => ([
            item.data.name,
            item.data.phone,
            item.data.referral_code,
            item.data.username,
            moment(item.data.created_date.toDate().toISOString()).format("MM/DD/YY, h:mm a"),
            // Add more columns as needed
        ]));

        return csvData;
    };

    return (
        <>
            {/* <ViewAppData /> */}
            <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
                <div class="p-2" style={{ paddingBottom: "5px", display: "flex", justifyContent: "space-between" }}>
                    {/* <span class="btn bg-gradient-secondary btn-sm mb-0 mr-2" style={{ color: "white" }} >&nbsp; Download Excel</span> */}
                    <Link to="/admin_panel"><img src="/images/menubutton.png" class="menu-toggle" alt="Responsive image" style={{ height: "2rem" }} /></Link>
                    <CSVLink data={prepareCSVData()} headers={headers} filename={"referral_data.csv"}>
                        <span class="btn bg-gradient-secondary btn-sm mb-0 mr-2" style={{ color: "white" }}>
                            &nbsp; Download Excel
                        </span>
                    </CSVLink>
                </div>
                <div class="row">


                    <div class="p-2">

                        <div class="card p-2">
                            <div class="card-header pb-0 px-3">
                                <h6 class="mb-2">App Referral || {data.length}</h6>
                            </div>
                            <div class="">
                                <div class="card">
                                    <div class="table-responsive fix-width scroll-inner">
                                        <table class="table table-bordered table-hover">
                                            <thead class="thead-dark" style={{ position: "sticky", top: "0" }}>
                                                <tr style={{ textAlign: "left" }}>
                                                    <th class=" ps-2">Name</th>
                                                    <th class=" ps-2">Phone No</th>
                                                    <th class=" ps-2">Referral Code</th>
                                                    <th class="">Created Date</th>
                                                    <th class=" ps-2">Username</th>
                                                </tr>
                                            </thead>
                                            <tbody style={{ textAlign: "left" }}>
                                                {data?.map(({ id, data }, index) => (
                                                    <>
                                                        <tr>

                                                            <td>
                                                                <p class="text-xs mb-0">{data.name}</p>
                                                                {/* <p class="text-xs text-secondary mb-0">{moment(data.created_date.toDate().toISOString()).format("MM/DD/YY, h:mm a")}</p> */}
                                                            </td>
                                                            <td>
                                                                <p class="text-xs mb-0">{data.phone}</p>
                                                                {/* <p class="text-xs text-secondary mb-0">{moment(data.created_date.toDate().toISOString()).format("MM/DD/YY, h:mm a")}</p> */}
                                                            </td>
                                                            <td>
                                                                <p class="text-xs mb-0">{data.referral_code}</p>
                                                                {/* <p class="text-xs text-secondary mb-0">{moment(data.created_date.toDate().toISOString()).format("MM/DD/YY, h:mm a")}</p> */}
                                                            </td>
                                                            <td>
                                                                <p class="text-xs mb-0">{moment(data.created_date.toDate().toISOString()).format("MM/DD/YY, h:mm a")}</p>
                                                                {/* <p class="text-xs text-secondary mb-0">{moment(data.created_date.toDate().toISOString()).format("MM/DD/YY, h:mm a")}</p> */}
                                                            </td>
                                                            <td>
                                                                <p class="text-xs mb-0">{data.username}</p>
                                                                {/* <p class="text-xs text-secondary mb-0">{moment(data.created_date.toDate().toISOString()).format("MM/DD/YY, h:mm a")}</p> */}
                                                            </td>



                                                        </tr>

                                                    </>
                                                ))}


                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </main>


        </>
    )
}

export default AppReferral;