import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2'
import db from "./Firebase";
import { storage } from './Firebase';
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";


function BeATrainer() {
    const navigate = useNavigate();
    const initialFormStateTrainer = { full_name: '', email: '', phone_no: '', address: '', course: '', duration: '', timing: "", days: "", fee_proposed: "", mode: '', course_content: '', student_count: '', need_certificate: '', starting_date: '', created_date: new Date() }
    const [submitTrainer, setSubmitTrainer] = useState(initialFormStateTrainer)

    const [show, setshow] = useState(true);


    const handleChangeTrainer = (e) => {
        const { id, value } = e.target
        setSubmitTrainer({ ...submitTrainer, [id]: value })
    }

    const handleSubmitTrainer = (e) => {
        // alert(submitTrainer.phone_no.length)
        console.log(JSON.stringify(submitTrainer));
        e.preventDefault();

        db.collection("beauty_trainer").add({
            name: submitTrainer.full_name,
            phone_no: submitTrainer.phone_no,
            email: submitTrainer.email,
            address: submitTrainer.address,
            course: submitTrainer.course,
            duration: submitTrainer.duration,
            timing: submitTrainer.timing,
            days: submitTrainer.days,
            fee_proposed: submitTrainer.fee_proposed,
            mode: submitTrainer.mode,
            course_content: submitTrainer.course_content,
            student_count: submitTrainer.student_count,
            need_certificate: submitTrainer.need_certificate,
            starting_date: submitTrainer.starting_date,
            created_date: submitTrainer.created_date,
            uid: "",
        })
            .then(() => {
                console.log('Added successfull');
                const Toast = Swal.mixin({
                    toast: true,
                    background: '#69aba6',
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer)
                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                    }
                })

                Toast.fire({
                    icon: 'success',
                    title: 'Thank you for your interest ! We will get in touch with you'
                })
                setSubmitTrainer({ full_name: '', email: '', phone_no: '', address: '', course: '', duration: '', timing: "", days: "", fee_proposed: "", mode: '', course_content: '', student_count: '', need_certificate: '', starting_date: '', created_date: new Date() })
            });


    }

    const clearForm = () => {
        setSubmitTrainer({ full_name: '', email: '', phone_no: '', address: '', course: '', duration: '', timing: "", days: "", fee_proposed: "", mode: '', course_content: '', student_count: '', need_certificate: '', starting_date: '', created_date: new Date() });
    }
    // const handleChange = (e) => {
    //     const { id, value } = e.target
    //     setSubmit({ ...submit, [id]: value })
    // }

    // const reset = () => {
    //     setSubmit({ parlour_name: '', phone_no: '', email: '', pincode: '', place: '', plus_code: '', username: '', full_address: '', usercode: '', created_date: new Date() })
    // }

    // const ids = ["rahul_122002", "ashutosh_560001", "mrinal_122004"];
    // const handleSubmit = (e) => {
    //     console.log(submit.usercode)
    //     e.preventDefault()

    //     if (ids.includes(submit.usercode)) {
    //         const file = e.target[0]?.files[0]
    //         // console.log(file)
    //         if (!file) return;
    //         const storageRef = ref(storage, `files/${file.name}`);
    //         const uploadTask = uploadBytesResumable(storageRef, file);
    //         setshow(false)

    //         uploadTask.on("state_changed",
    //             (snapshot) => {
    //                 const progress =
    //                     Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
    //                 setProgresspercent(progress);
    //             },
    //             (error) => {
    //                 alert(error);
    //             },
    //             () => {
    //                 getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
    //                     // setImgUrl(downloadURL)
    //                     db.collection("unregistered_users").add({
    //                         'parlour_name': submit.parlour_name,
    //                         'phone_no': submit.phone_no,
    //                         'pincode': submit.pincode,
    //                         'place_best_beauty_parlour_in': submit.place,
    //                         'full_address': submit.full_address,
    //                         'rating': '',
    //                         'reviews': '',
    //                         'image': downloadURL,
    //                         'username': submit.username,
    //                         'plus_code': submit.plus_code,
    //                         'email': submit.email,
    //                         'usercode': submit.usercode,
    //                         'monday': '',
    //                         'tuesday': '',
    //                         'wednesday': '',
    //                         'thursday': '',
    //                         'friday': '',
    //                         'saturday': '',
    //                         'sunday': '',
    //                         'created_date': new Date(),
    //                     }).then(() => {
    //                         reset();
    //                         setshow(true)
    //                         console.log('Added Successfully');
    //                         const Toast = Swal.mixin({
    //                             toast: true,
    //                             background: '#69aba6',
    //                             position: 'top-end',
    //                             showConfirmButton: false,
    //                             timer: 3000,
    //                             timerProgressBar: true,
    //                             didOpen: (toast) => {
    //                                 toast.addEventListener('mouseenter', Swal.stopTimer)
    //                                 toast.addEventListener('mouseleave', Swal.resumeTimer)
    //                             }
    //                         })

    //                         Toast.fire({
    //                             icon: 'success',
    //                             title: 'Beauty Parlour Added'
    //                         })
    //                         navigate(`/${submit.username}`);
    //                     })
    //                 });
    //             }
    //         );
    //     } else {
    //         alert("usercode does not exist")
    //     }

    // }



    return (

        <>
            <div id="small-dialog" class="m-3">
                <div class="w3layouts_payment_online_form">
                    <form class="" id="" onSubmit={handleSubmitTrainer}>
                        <h4>Be A Beauty Trainer</h4>
                        <ul>
                            <li><input class="w3l_text_box" id="full_name" onChange={handleChangeTrainer} value={submitTrainer.full_name} placeholder="Full Name" name="Full Name" type="text" required />
                            </li>
                            <li><input class="w3l_text_box" id="phone_no" onChange={handleChangeTrainer} value={submitTrainer.phone_no} placeholder="10 Digit Phone Number" name="Phone" type="tel" minlength="10" maxlength="10" required />
                            </li>
                        </ul>
                        <ul>
                            <li><input class="w3l_text_box" id="email" onChange={handleChangeTrainer} value={submitTrainer.email} placeholder="Email" name="Email" type="email" required="" /></li>
                            <li>
                                <input class="w3l_text_box" id="address" onChange={handleChangeTrainer} value={submitTrainer.address} placeholder="Address" name="Address" type="text" required />
                            </li>
                        </ul>
                        <ul>
                            <li><input class="w3l_text_box" id="course" onChange={handleChangeTrainer} value={submitTrainer.course} placeholder="Proposed Course" name="Course" type="text" required /></li>
                            <li>
                                <input class="w3l_text_box" id="duration" onChange={handleChangeTrainer} value={submitTrainer.duration} placeholder="Duration in minutes" name="Duration" type="text" required />
                            </li>
                        </ul>
                        <ul>
                            <li><input class="w3l_text_box" id="timing" onChange={handleChangeTrainer} value={submitTrainer.timing} placeholder="Timing" name="Timing" type="text" required /></li>
                            <li>
                                <input class="w3l_text_box" id="days" onChange={handleChangeTrainer} value={submitTrainer.days} placeholder="Days" name="Days" type="text" required />
                            </li>
                        </ul>
                        <ul>
                            <li><input class="w3l_text_box" id="fee_proposed" onChange={handleChangeTrainer} value={submitTrainer.fee_proposed} placeholder="Fee Proposed" name="Fee Proposed" type="text" required /></li>
                            <li>
                                <label>Mode</label>
                                <select class="form-control" onChange={handleChangeTrainer} id="mode" required>
                                    <option value="">--Select--</option>
                                    <option value="yes">Online</option>
                                </select>
                            </li>
                        </ul>
                        <ul>
                            <li>
                                <label>Course Content Available</label>
                                <select class="form-control" onChange={handleChangeTrainer} id="course_content" required>
                                    <option value="">--Select--</option>
                                    <option value="yes">Yes</option>
                                    <option value="no">No</option>
                                </select>
                            </li>
                            <li>
                                <label>How many ready students you have?</label>
                                <select class="form-control" onChange={handleChangeTrainer} id="student_count" required>
                                    <option value="">--Select--</option>
                                    <option value="0">0</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="6">6</option>
                                    <option value="7">7</option>
                                    <option value="8">8</option>
                                    <option value="9">9</option>
                                    <option value="10">10</option>
                                </select>
                            </li>
                        </ul>
                        <ul>
                            <li>
                                <label>Need Govt. recognised skill cetrificate for the course</label>
                                <select class="form-control" onChange={handleChangeTrainer} id="need_certificate" required>
                                    <option value="">--Select--</option>
                                    <option value="yes">Yes</option>
                                    <option value="no">NO</option>
                                </select>
                            </li>
                            <li>
                                <input class="w3l_text_box" id="starting_date" onChange={handleChangeTrainer} value={submitTrainer.starting_date} placeholder="Earliest Starting Days" name="Earliest Starting Days" type="text" required />
                            </li>
                        </ul>
                        <ul class="w3_payment_sendbtns">
                            <li><input type="reset" value="Cancel" onClick={() => navigate(`/quicklinks`)} /></li>
                            <li class='mr-4'><input type="reset" data-dismiss="modal" aria-label="Close" onClick={clearForm} value="Reset" /></li>
                            <li><input type="submit" value="Submit" /></li>
                        </ul>
                        <div class="clearfix"> </div>
                    </form>
                </div>
            </div>
        </>


    );
}
export default BeATrainer;