// import React, { useState } from 'react';

// function SMSComponent() {
//     const [response, setResponse] = useState('');

//     const sendSms = async () => {
//         try {
//             // Construct data
//             const apiKey = "SsSIU4tDHEo-DWKzpHRKAWG8AtBIe3cMmkksUGCfPH";
//             const templateId = "1407160102500221814"; // Replace with your actual template ID
//             const sender = "WHYTEF";
//             const numbers = "917856946583";
//             const message = "This is your message"; // Message content

//             const formData = new FormData();
//             formData.append('apiKey', apiKey);
//             formData.append('numbers', numbers);
//             formData.append('template', templateId);
//             formData.append('sender', sender);
//             formData.append('message', message);

//             // Send data
//             const response = await fetch('https://api.textlocal.in/send/', {
//                 method: 'POST',
//                 body: formData
//             });

//             const responseData = await response.json();
//             setResponse(JSON.stringify(responseData));
//         } catch (error) {
//             console.error("Error SMS ", error);
//             setResponse("Error " + error);
//         }
//     };

//     return (
//         <div>
//             <button onClick={sendSms}>Send SMS</button>
//             <div>{response}</div>
//         </div>
//     );
// }

// export default SMSComponent;



import React, { useState } from 'react';

function SMSComponent() {
    const [response, setResponse] = useState('');

    const sendSms = async () => {
        try {
            // Construct data
            // const apiKey = "SsSIU4tDHEo-DWKzpHRKAWG8AtBIe3cMmkksUGCfPH";
            // // const message = "This is your message";
            // const templateId = "1407166151869660556";
            // const sender = "WHYTEF";
            // const numbers = "917856946583";

            const apiKey = "SsSIU4tDHEo-DWKzpHRKAWG8AtBIe3cMmkksUGCfPH";
            // const templateId = encodeURIComponent("1407166149705255236"); // Replace with your actual template ID
            const sender = "WHYTEF";
            const numbers = "917856946583";
            const message = "1407160102500221814";

            // Send data
            const url = `https://api.textlocal.in/send/?apiKey=${apiKey}&numbers=${numbers}&message=${message}&sender=${sender}`;
            // const url = `https://api.textlocal.in/send/?apiKey=${apiKey}&numbers=${numbers}&template=${templateId}&sender=${sender}&message=${message}`;
            const response = await fetch(url, { method: 'POST' });
            const responseData = await response.json();
            setResponse(JSON.stringify(responseData));
        } catch (error) {
            console.error("Error SMS ", error);
            setResponse("Error " + error);
        }
    };

    return (
        <div>
            <button onClick={sendSms}>Send SMS</button>
            <div>{response}</div>
        </div>
    );
}

export default SMSComponent;



// import axios from 'axios';
// import React, { useState } from 'react';

// function SMSComponent() {
//     const [phoneNumber, setPhoneNumber] = useState('917856946583');
//     const [message, setMessage] = useState('heloooo text locallllllllll');

//     const sendSMS = async () => {
//         try {
//             const response = await axios.post(
//                 'https://api.txtlocal.com/send/',
//                 {
//                     apiKey: 'SsSIU4tDHEo-DWKzpHRKAWG8AtBIe3cMmkksUGCfPH',
//                     numbers: phoneNumber,
//                     message: message,
//                     sender: 'WHYTEF' // Optional
//                 }
//             );
//             console.log(response.data);
//         } catch (error) {
//             console.error('Error sending SMS:', error);
//         }
//     };

//     return (
//         <div>
//             <input
//                 type="text"
//                 value={phoneNumber}
//                 onChange={(e) => setPhoneNumber(e.target.value)}
//                 placeholder="Enter phone number"
//             />
//             <textarea
//                 value={message}
//                 onChange={(e) => setMessage(e.target.value)}
//                 placeholder="Enter message"
//             />
//             <button onClick={sendSMS}>Send SMS</button>
//         </div>
//     );
// }

// export default SMSComponent;
