import React, { useState, useEffect } from 'react';
import { useNavigate, Link, useParams } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2'
import db from "./Firebase";
import { storage } from './Firebase';
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";


function CertificateUpload() {
    const [data, setData] = useState([]);
    const [ShowProgress, setShowProgress] = useState(false);



    const [show, setshow] = useState(true);

    const [showExcel, setshowExcel] = useState(false);

    const [imgUrl, setImgUrl] = useState(null);
    const [progresspercent, setProgresspercent] = useState(0);


    const wap = [
        // { 'phone_no': '7973693200', 'url': 'https://drive.google.com/file/d/1ki2Yc9yljVqiMikv1_9G7Rb2HSX8c5Y1/view?usp=share_link' },
        // { 'phone_no': '9056411254', 'url': 'https://drive.google.com/file/d/17MpdMsLGBNB7WxvTbmvbMG5mFDheP7Op/view?usp=share_link' },
    ]


    const handleSubmit = (e) => {
        wap.map((data) => {
            db.collection("certificates").add({
                'link': data.url,
                'phone_no': data.phone_no,
                'created_date': new Date(),
            }).then(() => {
                console.log('Created Successfully');

            })
        })
        // e.preventDefault()
        // const file = e.target[0]?.files[0]
        // console.log(file)


    }



    return (
        <>
            <button class="btn btn-success mr-2" onClick={() => handleSubmit()}>Upload Data</button>
            {/* <button class="btn btn-success mr-2" onClick={() => submitdata()}>Upload Data</button> */}
        </>


    );
}
export default CertificateUpload;