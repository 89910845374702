import React, { useState, useEffect } from 'react';
import * as XLSX from 'xlsx';
import DataTable from 'react-data-table-component';
import { useNavigate, Link, useParams } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2'
import db from "./Firebase";
import { storage } from './Firebase';
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";


function UploadCertificates() {
    let params = useParams();
    const navigate = useNavigate();

    const initialFormStateFeedback = { name: '', user_name: '', phone_no: '', link: '', type: '', created_date: new Date() }
    const [submit, setSubmit] = useState(initialFormStateFeedback)

    const [show, setshow] = useState(true);

    const [imgUrl, setImgUrl] = useState(null);
    const [progresspercent, setProgresspercent] = useState(0);

    const [columns, setColumns] = useState([]);
    const [data, setData] = useState([]);
    const [ShowProgress, setShowProgress] = useState(false);
    const [showExcel, setshowExcel] = useState(false);

    const processData = dataString => {
        const dataStringLines = dataString.split(/\r\n|\n/);
        const headers = dataStringLines[0].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);

        const list = [];
        for (let i = 1; i < dataStringLines.length; i++) {
            const row = dataStringLines[i].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);
            if (headers && row.length == headers.length) {
                const obj = {};
                for (let j = 0; j < headers.length; j++) {
                    let d = row[j];
                    if (d.length > 0) {
                        if (d[0] == '"')
                            d = d.substring(1, d.length - 1);
                        if (d[d.length - 1] == '"')
                            d = d.substring(d.length - 2, 1);
                    }
                    if (headers[j]) {
                        obj[headers[j]] = d;
                    }
                }

                // remove the blank rows
                if (Object.values(obj).filter(x => x).length > 0) {
                    list.push(obj);
                }
            }
        }

        // prepare columns list from headers
        const columns = headers.map(c => ({
            name: c,
            selector: c,
        }));

        setData(list);
        setColumns(columns);
        console.log(list)
    }

    const handleFileUpload = e => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onload = (evt) => {
            /* Parse data */
            const bstr = evt.target.result;
            const wb = XLSX.read(bstr, { type: 'binary' });
            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            /* Convert array of arrays */
            const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
            processData(data);
        };
        reader.readAsBinaryString(file);
    }

    const [num, setNum] = useState(0);
    const submitdata = (e) => {
        setShowProgress(true);
        data.map((user, index) => {
            setNum(index + 1);
            db.collection("all_certificates").add({
                'name': user.name,
                'phone_no': user.phone_no,
                'link': user.link,
                'type': user.type,
                'user_name': user.user_name,
                'created_date': new Date(),
            });
        })
    };

    const clearExcelData = () => {
        // Clear the data and columns
        setData([]);
        setColumns([]);
    }



    const handleChange = (e) => {
        const { id, value } = e.target
        setSubmit({ ...submit, [id]: value })
    }

    // const reset = () => {
    //     setSubmit({ name: '', user_name: '', phone_no: '', link: '', type: '', created_date: new Date() })
    //     // navigate(`/quicklinks`);
    // }
    const ids = ["rahul_122002", "ashutosh_560001", "mrinal_122004"];

    const handleSubmit = (e) => {
        // console.log(submit.usercode)
        // alert("okkk")
        e.preventDefault()
        if (ids.includes(submit.authorization_code)) {
            console.log("callingg 1")
            const file = e.target[0]?.files[0]
            // console.log(file)
            if (!file) return;
            const storageRef = ref(storage, `files/${file.name}`);
            const uploadTask = uploadBytesResumable(storageRef, file);
            setshow(false)

            uploadTask.on("state_changed",
                (snapshot) => {
                    const progress =
                        Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                    setProgresspercent(progress);
                },
                (error) => {
                    alert(error);
                },
                () => {
                    console.log("callingg 2")
                    getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                        // setImgUrl(downloadURL)
                        db.collection("all_certificates").add({
                            'name': submit.course_name,
                            'phone_no': submit.actual_course_fee,
                            'link': submit.loan_amount,
                            'user_name': submit.user_name,
                            'type': submit.type,
                            'created_date': new Date(),
                        }).then(() => {
                            console.log("callingg 3")
                            console.log('Submitted Successfully');
                            const Toast = Swal.mixin({
                                toast: true,
                                background: '#69aba6',
                                position: 'top-end',
                                showConfirmButton: false,
                                timer: 3000,
                                timerProgressBar: true,
                                didOpen: (toast) => {
                                    toast.addEventListener('mouseenter', Swal.stopTimer)
                                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                                }
                            })

                            Toast.fire({
                                icon: 'success',
                                title: 'Submitted Successfully'
                            })
                            setshow(true)
                            // navigate(`/${submit.username}`);
                            // setSubmit({ course_name: '', actual_course_fee: '', loan_amount: '', otp: '', type: '', programme_type: '', duration: '', description: '', twotp: '', organisation: '', authorization_code: '', created_date: new Date() })
                        })
                    });
                }
            );
        } else {
            alert("You are not authorization to perform this action")
        }
    }

    useEffect(() => {
        // alert(params.id)
    }, []);



    return (

        <>
            <div id="small-dialog" class="m-3">
                <div class="row p-1" style={{ float: "right" }}>
                    <a class="mr-3" href="/files/certificate_sample.xlsx" download><button type="button" class="btn-sm" style={{ backgroundColor: "#337ab7", color: "white" }} onClick={() => setshowExcel(true)}>Download Sample For Certificate Data</button></a>
                </div>
                <br />
                <div class="card-body">
                    <div class="form-group justify-content-end row">
                        <div class="col-lg-12">
                            <div class="form-control dropzone dropzone-default dropzone-primary dz-clickable h-auto px-4 py-2" style={{ background: "aquamarine", textAlign: "center" }}>
                                <div class="dropzone-msg dz-message needsclick" style={{ borderStyle: "dashed", padding: "1rem", borderColor: "orange" }}>
                                    <input type="file" accept=".csv,.xlsx,.xls" onChange={handleFileUpload} />
                                    <span class="font-size-base"><i class="fas fa-cloud-upload-alt icon-md mr-1"></i>Drop files here or click.</span><br />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <DataTable
                    pagination
                    highlightOnHover
                    columns={columns}
                    data={data}
                />

                {data.length > 0 ? <> <div class="card-footer d-flex justify-content-end py-2">
                    <button class="btn btn-success mr-2" onClick={() => submitdata()}>Upload Data</button>
                    <div class="btn btn-secondary" onClick={() => clearExcelData()} role="button">Cancel</div>
                </div></> : null
                }

                {ShowProgress ? <><h2 style={{ background: "darkorange", padding: "1rem", textAlign: "center", paddingBottom: "1rem", color: "white" }}>{num == data.length ? "Data Uploaded" : "Uploading Data"} - {num}/{data.length}</h2></> : ""}
            </div>
        </>


    );
}
export default UploadCertificates;



// import React, { useState, useEffect } from 'react';
// import * as XLSX from 'xlsx';
// import DataTable from 'react-data-table-component';
// import { useNavigate, Link, useParams } from 'react-router-dom';
// import axios from 'axios';
// import Swal from 'sweetalert2'
// import db from "./Firebase";
// import { storage } from './Firebase';
// import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";


// function UploadCertificates() {
//     let params = useParams();
//     const navigate = useNavigate();

//     // const initialFormStateFeedback = { name: '', user_name: '', phone_no: '', link: '', type: '', created_date: new Date() }
//     const initialFormStateFeedback = { full_name: '', workshop_event_name: '', phone_no: '', workshop_location: '', workshop_date: '', uid: '', payment_id: '', payment: '', email: '', description: '', created_date: new Date() }
//     const [submit, setSubmit] = useState(initialFormStateFeedback)

//     const [show, setshow] = useState(true);

//     const [imgUrl, setImgUrl] = useState(null);
//     const [progresspercent, setProgresspercent] = useState(0);

//     const [columns, setColumns] = useState([]);
//     const [data, setData] = useState([]);
//     const [ShowProgress, setShowProgress] = useState(false);
//     const [showExcel, setshowExcel] = useState(false);

//     const processData = dataString => {
//         const dataStringLines = dataString.split(/\r\n|\n/);
//         const headers = dataStringLines[0].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);

//         const list = [];
//         for (let i = 1; i < dataStringLines.length; i++) {
//             const row = dataStringLines[i].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);
//             if (headers && row.length == headers.length) {
//                 const obj = {};
//                 for (let j = 0; j < headers.length; j++) {
//                     let d = row[j];
//                     if (d.length > 0) {
//                         if (d[0] == '"')
//                             d = d.substring(1, d.length - 1);
//                         if (d[d.length - 1] == '"')
//                             d = d.substring(d.length - 2, 1);
//                     }
//                     if (headers[j]) {
//                         obj[headers[j]] = d;
//                     }
//                 }

//                 // remove the blank rows
//                 if (Object.values(obj).filter(x => x).length > 0) {
//                     list.push(obj);
//                 }
//             }
//         }

//         // prepare columns list from headers
//         const columns = headers.map(c => ({
//             name: c,
//             selector: c,
//         }));

//         setData(list);
//         setColumns(columns);
//         console.log(list)
//     }

//     const handleFileUpload = e => {
//         const file = e.target.files[0];
//         const reader = new FileReader();
//         reader.onload = (evt) => {
//             /* Parse data */
//             const bstr = evt.target.result;
//             const wb = XLSX.read(bstr, { type: 'binary' });
//             /* Get first worksheet */
//             const wsname = wb.SheetNames[0];
//             const ws = wb.Sheets[wsname];
//             /* Convert array of arrays */
//             const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
//             processData(data);
//         };
//         reader.readAsBinaryString(file);
//     }

//     const [num, setNum] = useState(0);
//     const submitdata = (e) => {
//         setShowProgress(true);
//         data.map((user, index) => {
//             setNum(index + 1);
//             db.collection("workshop_registration").add({
//                 // 'name': user.name,
//                 // 'phone_no': user.phone_no,
//                 // 'link': user.link,
//                 // 'type': user.type,
//                 // 'user_name': user.user_name,
//                 'full_name': user.full_name,
//                 'workshop_event_name': user.workshop_event_name,
//                 'phone_no': user.phone_no,
//                 'workshop_location': user.workshop_location,
//                 'workshop_date': user.workshop_date,
//                 'uid': user.uid,
//                 'payment_id': user.payment_id,
//                 'payment': user.payment,
//                 'email': user.email,
//                 'description': user.description,
//                 'created_date': new Date(),
//             });
//         })
//     };

//     const clearExcelData = () => {
//         // Clear the data and columns
//         setData([]);
//         setColumns([]);
//     }



//     const handleChange = (e) => {
//         const { id, value } = e.target
//         setSubmit({ ...submit, [id]: value })
//     }

//     // const reset = () => {
//     //     setSubmit({ name: '', user_name: '', phone_no: '', link: '', type: '', created_date: new Date() })
//     //     // navigate(`/quicklinks`);
//     // }
//     const ids = ["rahul_122002", "ashutosh_560001", "mrinal_122004"];

//     const handleSubmit = (e) => {
//         // console.log(submit.usercode)
//         // alert("okkk")
//         e.preventDefault()
//         if (ids.includes(submit.authorization_code)) {
//             console.log("callingg 1")
//             const file = e.target[0]?.files[0]
//             // console.log(file)
//             if (!file) return;
//             const storageRef = ref(storage, `files/${file.name}`);
//             const uploadTask = uploadBytesResumable(storageRef, file);
//             setshow(false)

//             uploadTask.on("state_changed",
//                 (snapshot) => {
//                     const progress =
//                         Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
//                     setProgresspercent(progress);
//                 },
//                 (error) => {
//                     alert(error);
//                 },
//                 () => {
//                     console.log("callingg 2")
//                     getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
//                         // setImgUrl(downloadURL)
//                         db.collection("workshop_registration").add({
//                             // 'name': submit.course_name,
//                             // 'phone_no': submit.actual_course_fee,
//                             // 'link': submit.loan_amount,
//                             // 'user_name': submit.user_name,
//                             // 'type': submit.type,
//                             // 'created_date': new Date(),
//                             'full_name': submit.full_name,
//                             'workshop_event_name': submit.workshop_name,
//                             'phone_no': submit.phone_no,
//                             'workshop_location': submit.workshop_location,
//                             'workshop_date': submit.workshop_date,
//                             'uid': submit.uid,
//                             'payment_id': submit.payment_id,
//                             'payment': submit.payment,
//                             'email': submit.email,
//                             'description': submit.description,
//                             'created_date': new Date(),
//                         }).then(() => {
//                             console.log("callingg 3")
//                             console.log('Submitted Successfully');
//                             const Toast = Swal.mixin({
//                                 toast: true,
//                                 background: '#69aba6',
//                                 position: 'top-end',
//                                 showConfirmButton: false,
//                                 timer: 3000,
//                                 timerProgressBar: true,
//                                 didOpen: (toast) => {
//                                     toast.addEventListener('mouseenter', Swal.stopTimer)
//                                     toast.addEventListener('mouseleave', Swal.resumeTimer)
//                                 }
//                             })

//                             Toast.fire({
//                                 icon: 'success',
//                                 title: 'Submitted Successfully'
//                             })
//                             setshow(true)
//                             // navigate(`/${submit.username}`);
//                             // setSubmit({ course_name: '', actual_course_fee: '', loan_amount: '', otp: '', type: '', programme_type: '', duration: '', description: '', twotp: '', organisation: '', authorization_code: '', created_date: new Date() })
//                         })
//                     });
//                 }
//             );
//         } else {
//             alert("You are not authorization to perform this action")
//         }
//     }

//     useEffect(() => {
//         // alert(params.id)
//     }, []);



//     return (

//         <>
//             <div id="small-dialog" class="m-3">
//                 <div class="row p-1" style={{ float: "right" }}>
//                     <a class="mr-3" href="/files/certificate_sample.xlsx" download><button type="button" class="btn-sm" style={{ backgroundColor: "#337ab7", color: "white" }} onClick={() => setshowExcel(true)}>Download Sample For Certificate Data</button></a>
//                 </div>
//                 <br />
//                 <div class="card-body">
//                     <div class="form-group justify-content-end row">
//                         <div class="col-lg-12">
//                             <div class="form-control dropzone dropzone-default dropzone-primary dz-clickable h-auto px-4 py-2" style={{ background: "aquamarine", textAlign: "center" }}>
//                                 <div class="dropzone-msg dz-message needsclick" style={{ borderStyle: "dashed", padding: "1rem", borderColor: "orange" }}>
//                                     <input type="file" accept=".csv,.xlsx,.xls" onChange={handleFileUpload} />
//                                     <span class="font-size-base"><i class="fas fa-cloud-upload-alt icon-md mr-1"></i>Drop files here or click.</span><br />
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//                 <DataTable
//                     pagination
//                     highlightOnHover
//                     columns={columns}
//                     data={data}
//                 />

//                 {data.length > 0 ? <> <div class="card-footer d-flex justify-content-end py-2">
//                     <button class="btn btn-success mr-2" onClick={() => submitdata()}>Upload Data</button>
//                     <div class="btn btn-secondary" onClick={() => clearExcelData()} role="button">Cancel</div>
//                 </div></> : null
//                 }

//                 {ShowProgress ? <><h2 style={{ background: "darkorange", padding: "1rem", textAlign: "center", paddingBottom: "1rem", color: "white" }}>{num == data.length ? "Data Uploaded" : "Uploading Data"} - {num}/{data.length}</h2></> : ""}
//             </div>
//         </>


//     );
// }
// export default UploadCertificates;