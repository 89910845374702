import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import "./style.css";
import { useNavigate, Link, useParams } from "react-router-dom"
import Select from 'react-select';
import NumberCounter from 'number-counter';
import FooterMain from "./FooterMain";

export const Tips = () => {
    const [count, setCount] = useState(0);
    useEffect(() => {
        window.scrollTo(0, 0);
        for (let count = 0; count <= 500; count++) {
            setTimeout(() => {
                setCount(count)
            }, 1000);
        }
    }, []);

    const [selectedImage, setSelectedImage] = useState(null);

    // const tipsImages = [
    //     "/bridal/Rectangle_115.png",
    //     "/bridal/Rectangle_116.png",
    //     // Add more image URLs as needed
    // ];

    const tipsImages = [
        { title: "Day 10", subtitle: "Prepping the Canvas", image_url: "/tips/Rectangle 158.png", description: "Start the day with a gentle cleanser to remove impurities Apply a soothing face mask for deep cleansing and hydration Drink plenty of water to keep your skin hydrated from the inside out" },
        { title: "Day 9", subtitle: "Radiant Skin", image_url: "/tips/Rectangle 159.png", description: "Exfoliate your face to remove dead skin cells Use a brightening face mask to achieve a radiant complexion Get a good night's sleep to ensure your skin looks fresh and rested" },
        { title: "Day 8", subtitle: "Hydration Boost", image_url: "/tips/Rectangle 160.png", description: "Invest in a hydrating serum to nourish your skin Apply a moisturizer with SPF to protect against UV rays Pamper your lips with a hydrating lip balm" },
        { title: "Day 7", subtitle: "Stress-Free Day", image_url: "/tips/Rectangle 161.png", description: "Practice stress-relieving activities like yoga or meditation Keep your skincare routine simple to avoid any reactions Enjoy a calming bath to relax your mind and body" },
        { title: "Day 6", subtitle: "Special Attention to Eyes", image_url: "/tips/Rectangle 162.png", description: "Use an eye mask or cucumber slices to reduce puffiness Apply an eye cream for hydration and to minimize dark circles Confirm any final details with your beauty team" },
        { title: "Day 5", subtitle: "Skin Pampering", image_url: "/tips/Rectangle 163.png", description: "Treat your skin to a nourishing sheet mask Massage your face with a jade roller for improved circulation Ensure you are drinking enough water and eating a balanced diet" },
        { title: "Day 4", subtitle: "Finalize Beauty Appointments", image_url: "/tips/Rectangle 164.png", description: "Confirm your facial and skincare appointments for the next few days Ensure that your makeup artist understands your preferences" },
        { title: "Day 3", subtitle: "Hair Removal and Final Glow", image_url: "/tips/Rectangle 165.png", description: "Complete any necessary facial hair removal Apply a hydrating overnight mask for a final skin glow Get a good night's sleep for optimal skin recovery" },
        { title: "Day 2", subtitle: "Rejuvenate and Relax", image_url: "/tips/Rectangle 166.png", description: "Get a light facial massage for relaxation Focus on calming skincare to reduce any last-minute stress Prepare a skincare emergency kit for the wedding day" },
        { title: "Day 1", subtitle: "The Bridal Glow", image_url: "/tips/Rectangle 167.png", description: "Start your day with a gentle cleanse and apply a brightening serum Allow time for a hydrating face mask for a radiant glow Follow your finalized skincare routine and enjoy the pampering" },
        {
            title: "", subtitle: "On the Wedding Day", image_url: "/tips/Group 81.png", description: "Cleanse your face and apply your trusted skincare products Work with your makeup artist for a flawless bridal look Take a moment to breathe and enjoy the beginning of your new journey"
        },
    ]

    const handleImageClick = (index) => {
        setSelectedImage(index);
    };


    const leftPartStyle = {
        flex: 1,
        border: "none",
        boxSizing: "border-box",
        // backgroundColor: "#7d6853",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    };

    return (
        <div>
            <section>
                <div class="navi" style={{ backgroundColor: "#ec5d69" }}>
                    <input type="checkbox" id="nav-check" />
                    <div class="nav-header">
                        <div class="nav-title">
                            <Link to="/"> <img src="/bridal/youvati_white_logo.png" alt="" style={{ height: "22px", marginLeft: "1rem" }} /></Link>
                        </div>
                    </div>
                    <div class="nav-btn">
                        <label for="nav-check">
                            <span></span>
                            <span></span>
                            <span></span>
                        </label>
                    </div>

                    <div class="nav-links" id="top">
                        <a href="#portfolio" class="linkcolor" style={{ color: "white" }}>Bridal</a>
                        <span class="line" style={{ color: "white" }}>|</span>
                        <a href="#portfolio" class="linkcolor" style={{ color: "white" }}>Why Choose Us</a>
                        <span class="line" style={{ color: "white" }}>|</span>
                        <a href="#partners" class="linkcolor" style={{ color: "white" }}>For Partners</a>

                        <span class="line" style={{ color: "white" }}>|</span>
                        <a href="#reachtous" class="linkcolor" style={{ color: "white" }}>Contact Us</a>

                    </div>
                </div>
            </section>

            <div style={{ textAlign: "center" }}>
                <h2 style={{ fontSize: "34px", fontWeight: "700", marginTop: "1.5rem", color: "#DE5A59", fontFamily: "serif" }}>10-Day Bridal</h2>
                <h2 style={{ fontSize: "34px", fontWeight: "700", color: "#DE5A59", fontFamily: "serif", paddingBottom: "2px" }}>Self-Care and Prep Routine</h2>
            </div>

            <div class="" style={{ padding: "13px", display: "flex", justifyContent: "center" }}>
                {/* <img style={{ borderRadius: "8px", width: "100%", height: "348px", objectFit: "contain" }}
                    class="inner-img"
                    src="/tips/Group 69.png"
                /> */}
                <img src="/tips/Group 69.png" class="img-fluid" style={{ maxWidth: "55rem", minWidth: "15rem" }} alt="bridal image"></img>

            </div>


            <div class="" style={{ padding: "13px", display: "flex", justifyContent: "center" }}>
                <p style={{ textAlign: "center", fontSize: "20px", fontWeight: "400", color: "black", marginTop: "1rem", fontFamily: "serif", maxWidth: "55rem", minWidth: "15rem" }}>The ten days leading up to your wedding are crucial for ensuring that your skin looks radiant and flawless on your special day. Both the bride and groom deserve to look their absolute best. Here are some essential facial tips for both to achieve that bridal glow and groom's charm.</p>
            </div>


            {tipsImages.map((tip, index) => (
                <div key={index} style={{ textAlign: "center" }}>
                    <div style={{ textAlign: "center" }}>
                        <h2 style={{ fontSize: "34px", fontWeight: "700", color: "black", fontFamily: "serif" }}>{tip.title}</h2>
                        {/* <div style={{ fontSize: "34px", fontWeight: "700", color: "#DE5A59", fontFamily: "serif", display: "flex", justifyContent: "center" }}><h2 style={{ fontSize: "34px", fontWeight: "700", color: "#DE5A59", fontFamily: "serif", width: "50%", textAlign: "center", borderBottom: "3.5px solid rgb(222, 90, 89)", lineHeight: "0.1em", margin: "25px 7px 40px" }}><span style={{ background: "#fff", padding: "0 10px" }}>{tip.subtitle}</span></h2></div> */}
                        <div style={{ display: "flex", justifyContent: "center" }}><span class="subs" style={{ fontSize: "34px", fontWeight: "700", color: "#DE5A59", }}>{tip.subtitle}</span></div>
                        {/* <div style={{ fontSize: "34px", fontWeight: "700", color: "#DE5A59", fontFamily: "serif", display: "flex", justifyContent: "center" }}><h2 style={{ width: "100%", textAlign: "center", borderBottom: "1px solid #000", lineHeight: "0.1em", margin: "25px 7px 40px" }}><span style={{ background: "#fff", padding: "0 10px" }}>{tip.subtitle}</span></h2></div> */}
                        {/* <div class="about-border" style={{ width: "200px", height: "3px", background: "#DE5A59", margin: "20px auto" }}></div> */}
                    </div>
                    <div class="" style={{ padding: "13px" }}>
                        <img style={{ borderRadius: "8px", width: "100%", height: "348px", objectFit: "contain" }}
                            class=""
                            src={tip.image_url}
                        />
                    </div>
                    {/* <div style={{ textAlign: "center" }}>
                        <h2 style={{ fontSize: "20px", fontWeight: "400", marginTop: "3rem", paddingLeft: "70px", color: "black", fontFamily: "serif" }}>{tip.description}</h2>
                    </div> */}
                    <div class="" style={{ padding: "13px", display: "flex", justifyContent: "center" }}>
                        <p style={{ textAlign: "center", fontSize: "20px", fontWeight: "400", color: "black", fontFamily: "serif", maxWidth: "55rem", minWidth: "15rem" }}>{tip.description}</p>
                    </div>


                </div>
            ))}
            <div style={{ textAlign: "center" }}>
                <h2 style={{ fontSize: "22px", fontWeight: "700", color: "black", fontFamily: "serif" }}>NOTICE:</h2>
                {/* <h2 style={{ fontSize: "16px", fontWeight: "400", paddingLeft: "150px", paddingRight: "150px", color: "black", fontFamily: "serif", paddingBottom: "20px" }}></h2> */}
            </div>

            <div class="" style={{ padding: "13px", display: "flex", justifyContent: "center" }}>
                <p style={{ textAlign: "center", fontSize: "17px", fontWeight: "400", color: "black", marginTop: "1rem", fontFamily: "serif", maxWidth: "55rem", minWidth: "15rem" }}>Remember to patch test any new products in advance and consult with skincare professionals if needed. This plan can be adapted based on your individual skin type and preferences.</p>
            </div>
            <div style={{ height: "10rem" }}></div>

            <FooterMain />

        </div>
    );
};

// import React from "react";
// import { useEffect } from "react";
// import { useState } from "react";
// import "./style.css";
// import { useNavigate, Link, useParams } from "react-router-dom"
// import Select from 'react-select';
// import NumberCounter from 'number-counter';
// import FooterMain from "./FooterMain";

// export const Tips = () => {
//     const [count, setCount] = useState(0);
//     useEffect(() => {
//         window.scrollTo(0, 0);
//         for (let count = 0; count <= 500; count++) {
//             setTimeout(() => {
//                 setCount(count)
//             }, 1000);
//         }
//     }, []);

//     const [selectedImage, setSelectedImage] = useState(null);

//     // const tipsImages = [
//     //     "/bridal/Rectangle_115.png",
//     //     "/bridal/Rectangle_116.png",
//     //     // Add more image URLs as needed
//     // ];

//     const tipsImages = [{ image_url: "/bridal/Group_42.png", description: ["Get a good night’s sleep. This is crucial for your skin to rejuvenate.", "On Your Wedding Day", "Cleanse your face with a gentle cleanser.", "Apply a light moisturizer suitable for your skin type.", "Use a primer before makeup to ensure a smooth application and long-lasting finish.", "Apply a light moisturizer suitable for your skin type.", "Use a primer before makeup to ensure a smooth application and long-lasting finish."] }, { image_url: "/bridal/Group_43.png", description: ["Description 2.1", "Description 2.2",] },
//     { image_url: "/bridal/Group_44.png", description: ["Description 4.1", "Description 4.2",] },
//     { image_url: "/bridal/Group_45.png", description: ["Description 5.1", "Description 5.2",] },
//     { image_url: "/bridal/Group_46.png", description: ["Description 6.1", "Description 6.2",] },
//     { image_url: "/bridal/Group_47.png", description: ["Description 7.1", "Description 7.2",] }]

//     const handleImageClick = (index) => {
//         setSelectedImage(index);
//     };


//     const leftPartStyle = {
//         flex: 1,
//         border: "none",
//         boxSizing: "border-box",
//         // backgroundColor: "#7d6853",
//         display: "flex",
//         flexDirection: "column",
//         justifyContent: "center",
//         alignItems: "center",
//     };



//     return (
//         <div>
//             <section>
//                 <div class="navi" style={{ backgroundColor: "#ec5d69" }}>
//                     <input type="checkbox" id="nav-check" />
//                     <div class="nav-header">
//                         <div class="nav-title">
//                             <Link to="/"> <img src="/bridal/youvati_white_logo.png" alt="" style={{ height: "27px", marginLeft: "2rem" }} /></Link>
//                         </div>
//                     </div>
//                     <div class="nav-btn">
//                         <label for="nav-check">
//                             <span></span>
//                             <span></span>
//                             <span></span>
//                         </label>
//                     </div>

//                     <div class="nav-links" id="top">
//                         <a href="#portfolio" class="linkcolor" style={{ color: "white" }}>Bridal</a>
//                         <span class="line" style={{ color: "white" }}>|</span>
//                         <a href="#portfolio" class="linkcolor" style={{ color: "white" }}>Why Choose Us</a>
//                         <span class="line" style={{ color: "white" }}>|</span>
//                         <a href="#partners" class="linkcolor" style={{ color: "white" }}>For Partners</a>

//                         <span class="line" style={{ color: "white" }}>|</span>
//                         <a href="#reachtous" class="linkcolor" style={{ color: "white" }}>Contact Us</a>

//                     </div>
//                 </div>
//             </section>

//             <div style={{
//                 justifyContent: "space-between", display: "flex",
//                 width: "100%", marginTop: "-5px",
//             }}>
//                 <div style={leftPartStyle}>
//                     <img
//                         src="/bridal/Rectangle_113.png" // Replace with the actual image URL
//                         alt="Left Part Image"
//                         style={{ width: '100%', height: '100%', objectFit: 'cover' }}
//                     />
//                 </div>


//             </div>

//             <div style={{ textAlign: "left" }}>
//                 <h2 style={{ fontSize: "34px", fontWeight: "700", color: "black", paddingLeft: "70px", marginTop: "3rem" }}>Facial Glow</h2>
//             </div>
//             <div style={{ textAlign: "left" }}>
//                 <h2 style={{ fontSize: "25px", fontWeight: "600", color: "black", paddingLeft: "70px", marginTop: "1rem" }}>Wedding Facial Tips for the Bride and Groom</h2>
//             </div>
//             <div style={{ textAlign: "left" }}>
//                 <h2 style={{ fontSize: "20px", fontWeight: "500", color: "black", paddingLeft: "70px", marginTop: "1rem" }}>Introduction</h2>
//             </div>
//             <div style={{ textAlign: "left" }}>
//                 <h2 style={{ fontSize: "17px", fontWeight: "500", color: "black", paddingLeft: "70px", marginTop: "1rem", marginRight: "6rem" }}>The ten days leading up to your wedding are crucial for ensuring that your skin looks radiant and flawless on your special day. Both the bride and groom deserve to look their absolute best. Here are some essential facial tips for both to achieve that bridal glow and groom's charm.</h2>
//             </div>

//             <div style={{ textAlign: "left" }}>
//                 <h2 style={{ fontSize: "30px", fontWeight: "600", color: "black", paddingLeft: "70px", marginTop: "3rem", marginBottom: "2rem" }}>10 Days Before the Wedding</h2>
//             </div>

//             {tipsImages.map((tip, index) => (
//                 <div key={index} style={{ marginBottom: "5rem", textAlign: "center" }}>
//                     <div
//                         className="container"
//                         style={{
//                             height: "20rem",
//                             backgroundImage: `url('${tip.image_url}')`,
//                             backgroundSize: "cover",
//                             cursor: "pointer",
//                         }}
//                         onClick={() => handleImageClick(index)}
//                     ></div>
//                     {selectedImage === index && (
//                         <div style={{ textAlign: "left", marginLeft: "70px", marginTop: "1rem" }}>
//                             <h2 style={{ fontSize: "25px", fontWeight: "600", color: "black" }}>
//                                 Description for Image {index + 1}

//                             </h2>
//                             <ul style={{ listStyle: "circle !important", marginLeft: "4rem" }}>
//                                 {tip.description.map((desc, descIndex) => (
//                                     <li key={descIndex} style={{ fontSize: "17px", fontWeight: "500", color: "black", display: "list-item", listStyle: "disc" }}>
//                                         {desc}
//                                     </li>
//                                 ))}
//                             </ul>
//                         </div>
//                     )}
//                 </div>
//             ))}




//             {/* Tips Images Started */}

//             {/* <div class="container" style={{ height: "20rem", marginBottom: "5rem", backgroundImage: "url('/bridal/Rectangle_115.png')", backgroundSize: "cover" }}>
//             </div>

//             <div class="container" style={{ height: "20rem", marginBottom: "5rem", backgroundImage: "url('/bridal/Rectangle_116.png')", backgroundSize: "cover" }}>
//             </div> */}

//             {/* Tips Images Ended */}




//             <FooterMain />

//         </div>
//     );
// };


