import React, { useState, useEffect } from 'react';
import { useNavigate, Link, useParams } from 'react-router-dom';
import db from "./Firebase";
import { arrayUnion } from "firebase/firestore";
import { storage } from './Firebase';
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import ViewAllRequests from './ViewAllRequests';
import { CSVLink } from "react-csv";

function PortfolioReviews() {
    const navigate = useNavigate();
    const moment = extendMoment(Moment);
    const [data, setData] = useState([]);
    const [wdata, setWdata] = useState([]);
    const [csvdata, setCsvdata] = useState([]);
    const [sortBy, setSortBy] = useState('totalReviews');
    const [sortDirection, setSortDirection] = useState('asc');
    const [searchQuery, setSearchQuery] = useState('');
    const [reviewCountFilter, setReviewCountFilter] = useState(0);

    const headers = [
        { label: "Username", key: "username" },
        { label: "Rating", key: "rating" },
        { label: "Full Name", key: "full_name" },
        { label: "Phone No", key: "phone_no" },
        { label: "Created Date", key: "created_date", dataFormat: "MM/DD/YY, h:mm a" },
        { label: "Review", key: "review" },
    ];

    useEffect(() => {
        const storedUsername = localStorage.getItem("email");
        const storedPassword = localStorage.getItem("password");
        const storedRemember = localStorage.getItem("remember");

        if (storedRemember === "false" || !storedUsername || !storedPassword) {
            navigate('/signin');
        }
    }, []);

    useEffect(() => {
        db.collection("parlour_feedback").orderBy("created_date", "desc").onSnapshot((snapshot) => {
            setData(
                snapshot.docs.map((doc) => ({
                    id: doc.id,
                    data: doc.data(),
                }))
            );
            setCsvdata(
                snapshot.docs.map((doc) => {
                    const data = doc.data();
                    return {
                        ...data,
                        created_date: moment(data.created_date.toDate().toISOString()).format("MM/DD/YY, h:mm a"),
                    };
                })
            );
        });
    }, []);



    const handleSearch = (event) => {
        setSearchQuery(event.target.value);
    };

    const filteredData = data.filter(({ id, data }) => {
        const username = data.username;
        const rating = data.rating.toString();

        const searchLowercase = searchQuery.toLowerCase();
        return (
            username.toLowerCase().includes(searchLowercase) ||
            rating.includes(searchQuery)
        );
    });

    const sortedData = filteredData.slice().sort((a, b) => {
        const valueA = a.data[sortBy];
        const valueB = b.data[sortBy];

        if (valueA < valueB) {
            return sortDirection === 'asc' ? -1 : 1;
        }
        if (valueA > valueB) {
            return sortDirection === 'asc' ? 1 : -1;
        }
        return 0;
    });

    const handleReviewCountFilter = (e) => {
        const count = Number(e.target.value);
        setReviewCountFilter(count);
    };

    return (
        <>
            <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
                <div class="p-2" style={{ paddingBottom: "5px", display: "flex", justifyContent: "space-between" }}>
                    {/* <span class="btn bg-gradient-secondary btn-sm mb-0 mr-2" style={{ color: "white" }} >&nbsp; Download Excel</span> */}
                    <Link to="/admin_panel"><img src="/images/menubutton.png" class="menu-toggle" alt="Responsive image" style={{ height: "2rem" }} /></Link>
                    <CSVLink data={csvdata} headers={headers}>
                        <span class="btn bg-gradient-secondary btn-sm mb-0 mr-2" style={{ color: "white" }} >&nbsp; Download Excel</span>
                    </CSVLink>
                </div>
                <div className="">
                    <div className="p-2">

                        {/* <div style={{ textAlign: "end", paddingBottom: "5px" }}>
                            <input
                                type="text"
                                className="form-control form-control-sm"
                                placeholder="Search"
                                value={searchQuery}
                                onChange={handleSearch}
                            />
                        </div> */}
                        <div className="card">
                            <div className="card-header pb-0 px-3">
                                <h6 className="mb-2">All Portfolio Reviews</h6>
                            </div>
                            <input
                                type="text"
                                className="form-control form-control-sm"
                                placeholder="Search By Username / Rating"
                                value={searchQuery}
                                onChange={handleSearch}
                            />
                            <div className="">
                                <div className="card">
                                    <div class="table-responsive fix-width scroll-inner">
                                        <table class="table table-bordered table-hover">
                                            <thead class="thead-dark" style={{ position: "sticky", top: "0" }}>
                                                <tr style={{ textAlign: "left" }}>
                                                    <th className="col ps-2">Username</th>
                                                    <th className="col">Rating</th>
                                                    <th className="col ps-2">Client Name</th>
                                                    {/* <th className="col ps-2">Phone No</th> */}
                                                    <th className="col ps-2">Created Date</th>
                                                    <th className="col ps-2">Review</th>
                                                </tr>
                                            </thead>
                                            <tbody style={{ textAlign: "left" }}>
                                                {sortedData.map(({ id, data }, index) => (
                                                    <tr key={id}>
                                                        <td>
                                                            <p className="text-xs font-weight-bold mb-0">{data.username}</p>
                                                        </td>
                                                        <td>
                                                            <div className="d-flex px-2 py-1">
                                                                <div className="d-flex flex-column justify-content-center">
                                                                    <h6 className="mb-0 text-xs">{data.rating}</h6>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="d-flex px-2 py-1">
                                                                <div className="d-flex flex-column justify-content-center">
                                                                    <h6 className="mb-0 text-xs">{data.full_name}</h6>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        {/* <td>
                                                            <div className="d-flex px-2 py-1">
                                                                <div className="d-flex flex-column justify-content-center">
                                                                    <h6 className="mb-0 text-xs">{data.phone_no}</h6>
                                                                </div>
                                                            </div>
                                                        </td> */}
                                                        <td>
                                                            <div className="d-flex px-2 py-1">
                                                                <div className="d-flex flex-column justify-content-center">
                                                                    <h6 className="mb-0 text-xs">{moment(data.created_date.toDate().toISOString()).format("MM/DD/YY, h:mm a")}</h6>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td style={{ whiteSpace: 'pre-wrap' }}>
                                                            <p class="text-xs font-weight-bold mb-0" style={{ width: "15rem" }}>{data.review}</p>

                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}

export default PortfolioReviews;








// import React, { useState, useEffect } from 'react';
// import { useNavigate, Link, useParams } from 'react-router-dom';
// import db from "./Firebase";
// import { arrayUnion } from "firebase/firestore";
// import { storage } from './Firebase';
// import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
// import Moment from 'moment';
// import { extendMoment } from 'moment-range';
// import ViewAllRequests from './ViewAllRequests';
// import { CSVLink } from "react-csv";

// function PortfolioReviews() {
//     const moment = extendMoment(Moment);
//     const [data, setData] = useState([]);
//     const [searchQuery, setSearchQuery] = useState("");
//     const [filteredData, setFilteredData] = useState([]);
//     const [reviewCountFilter, setReviewCountFilter] = useState(0);

//     useEffect(() => {
//         db.collection("parlour_feedback").orderBy("created_date", "desc").onSnapshot((snapshot) => {
//             setData(
//                 snapshot.docs.map((doc) => ({
//                     id: doc.id,
//                     data: doc.data(),
//                 }))
//             );
//         });
//     }, []);

//     useEffect(() => {
//         const searchData = data.filter(({ data }) => {
//             const { username, full_name, phone_no, review } = data;
//             const query = searchQuery.toLowerCase();
//             return (
//                 username.toLowerCase().includes(query) ||
//                 full_name.toLowerCase().includes(query) ||
//                 phone_no.toLowerCase().includes(query) ||
//                 review.toLowerCase().includes(query)
//             );
//         });

//         const filteredByReviewCount = searchData.filter(({ data }) => {
//             const { rating } = data;
//             return rating >= reviewCountFilter;
//         });

//         setFilteredData(filteredByReviewCount);
//     }, [searchQuery, reviewCountFilter, data]);

//     const handleSearch = (e) => {
//         setSearchQuery(e.target.value);
//     };

//     const handleReviewCountFilter = (e) => {
//         const count = Number(e.target.value);
//         setReviewCountFilter(count);
//     };

//     return (
//         <>
//             <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
//                 <div className="row">
//                     <div className="mt-4 p-4">
//                         <div className="card">
//                             <div className="card-header pb-0 px-3">
//                                 <h6 className="mb-2">All Portfolio Reviews</h6>
//                                 <div className="d-flex justify-content-end">
//                                     <input
//                                         type="text"
//                                         className="form-control form-control-sm"
//                                         placeholder="Search"
//                                         value={searchQuery}
//                                         onChange={handleSearch}
//                                     />
//                                     <select
//                                         className="form-select form-select-sm ms-2"
//                                         value={reviewCountFilter}
//                                         onChange={handleReviewCountFilter}
//                                     >
//                                         <option value={0}>All Reviews</option>
//                                         <option value={1}>1 Star and above</option>
//                                         <option value={2}>2 Stars and above</option>
//                                         <option value={3}>3 Stars and above</option>
//                                         <option value={4}>4 Stars and above</option>
//                                         <option value={5}>5 Stars</option>
//                                     </select>
//                                 </div>
//                             </div>
//                             <div className="">
//                                 <div className="card">
//                                     <div className="table-responsive">
//                                         <table className="table align-items-center mb-0">
//                                             <thead>
//                                                 <tr style={{ textAlign: "left" }}>
//                                                     <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Username</th>
//                                                     <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Rating</th>
//                                                     <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Client Name</th>
//                                                     <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Phone No</th>
//                                                     <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Created Date</th>
//                                                     <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Review</th>
//                                                 </tr>
//                                             </thead>
//                                             <tbody style={{ textAlign: "left" }}>
//                                                 {filteredData.map(({ id, data }, index) => (
//                                                     <tr key={id}>
//                                                         <td>
//                                                             <p className="text-xs font-weight-bold mb-0">{data.username}</p>
//                                                         </td>
//                                                         <td>
//                                                             <div className="d-flex px-2 py-1">
//                                                                 <div className="d-flex flex-column justify-content-center">
//                                                                     <h6 className="mb-0 text-xs">{data.rating}</h6>
//                                                                 </div>
//                                                             </div>
//                                                         </td>
//                                                         <td>
//                                                             <div className="d-flex px-2 py-1">
//                                                                 <div className="d-flex flex-column justify-content-center">
//                                                                     <h6 className="mb-0 text-xs">{data.full_name}</h6>
//                                                                 </div>
//                                                             </div>
//                                                         </td>
//                                                         <td>
//                                                             <div className="d-flex px-2 py-1">
//                                                                 <div className="d-flex flex-column justify-content-center">
//                                                                     <h6 className="mb-0 text-xs">{data.phone_no}</h6>
//                                                                 </div>
//                                                             </div>
//                                                         </td>
//                                                         <td>
//                                                             <div className="d-flex px-2 py-1">
//                                                                 <div className="d-flex flex-column justify-content-center">
//                                                                     <h6 className="mb-0 text-xs">{moment(data.created_date.toDate().toISOString()).format("MM/DD/YY, h:mm a")}</h6>
//                                                                 </div>
//                                                             </div>
//                                                         </td>
//                                                         <td>
//                                                             <div className="d-flex px-2 py-1">
//                                                                 <div className="d-flex flex-column justify-content-center">
//                                                                     <h6 className="mb-0 text-xs">{data.review}</h6>
//                                                                 </div>
//                                                             </div>
//                                                         </td>
//                                                     </tr>
//                                                 ))}
//                                             </tbody>
//                                         </table>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </main>
//         </>
//     )
// }

// export default PortfolioReviews;
