import React, { useState, useEffect } from 'react';
import { useNavigate, Link, useParams } from 'react-router-dom';
import db from "./Firebase";
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import { CSVLink } from "react-csv";

const SendCode = () => {
    const navigate = useNavigate();
    let params = useParams();
    const moment = extendMoment(Moment);
    const [data, setData] = useState([]);
    const [phoneNumbers, setPhoneNumbers] = useState([]);
    const [docIdsMap, setDocIdsMap] = useState({});

    useEffect(() => {
        const storedUsername = localStorage.getItem("email");
        const storedPassword = localStorage.getItem("password");
        const storedRemember = localStorage.getItem("remember");

        if (storedRemember === "false" || !storedUsername || !storedPassword) {
            navigate('/signin');
        }
    }, [navigate]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const snapshot = await db.collection("workshop_registration")
                    .where("workshop_event_name", "==", params.id)
                    .get();

                const phoneNumbers = [];
                const fetchedData = snapshot.docs.map((doc) => {
                    const data = doc.data();
                    phoneNumbers.push(data.phone);
                    return {
                        id: doc.id,
                        ...data,
                    };
                });

                setData(fetchedData);
                setPhoneNumbers(phoneNumbers);
                console.log("Phone Numbers:", phoneNumbers);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        const fetchPersonalDetails = async () => {
            if (phoneNumbers.length === 0) return;

            try {
                const promises = phoneNumbers.map(phone =>
                    db.collection("personal_details").where("phone_no", "==", phone).get()
                );
                const querySnapshots = await Promise.all(promises);

                const docIdsMap = {};
                querySnapshots.forEach(querySnapshot => {
                    querySnapshot.forEach(doc => {
                        docIdsMap[doc.data().phone_no] = doc.id;
                    });
                });

                setDocIdsMap(docIdsMap);
                console.log("Doc IDs:", Object.values(docIdsMap));
            } catch (error) {
                console.error("Error fetching personal details:", error);
            }
        };

        fetchPersonalDetails();
    }, [phoneNumbers]);

    const csvData = data.map((item) => ({
        name: item.full_name,
        phone: item.phone,
        doc_id: docIdsMap[item.phone] || 'N/A',
    }));

    const headers = [
        { label: "Name", key: "name" },
        { label: "Phone", key: "phone" },
        { label: "Regid", key: "doc_id" },
    ];

    return (
        <>
            <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg">
                <div class="p-2" style={{ paddingBottom: "5px", display: "flex", justifyContent: "space-between" }}>
                    <Link to="/admin_panel">
                        <img src="/images/menubutton.png" class="menu-toggle" alt="Responsive image" style={{ height: "2rem" }} />
                    </Link>
                    <CSVLink
                        data={csvData}
                        headers={headers}
                        filename="workshop_registration_glam_party_look.csv"
                        class="btn btn-dark"
                        style={{ height: "2rem" }}
                    >
                        Download Excel
                    </CSVLink>
                </div>
                <div class="">
                    <div class="p-2">
                        <div style={{ textAlign: "end", paddingBottom: "5px" }}>
                        </div>
                        <div class="card">
                            <div class="card-header pb-0 px-3">
                                <h6 class="mb-2">Workshop Registrations for "{params.id}"</h6>
                            </div>
                            <div class="">
                                <div class="card">
                                    <div class="table-responsive fix-width scroll-inner">
                                        <table class="table table-bordered table-hover">
                                            <thead class="thead-dark" style={{ position: "sticky", top: "0" }}>
                                                <tr>
                                                    <th class="col">Full Name</th>
                                                    <th class="col">Phone</th>
                                                    <th class="col">Email</th>
                                                    <th class="col">Payment Status</th>
                                                    <th class="col">Workshop Date</th>
                                                    <th class="col">Location</th>
                                                    <th class="col">Doc ID</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {data.map((item) => (
                                                    <tr key={item.id}>
                                                        <td>{item.full_name}</td>
                                                        <td>{item.phone}</td>
                                                        <td>{item.email}</td>
                                                        <td>{item.payment}</td>
                                                        <td>{moment(item.workshop_Date).format("MMM DD, YYYY h:mm A")}</td>
                                                        <td>{item.workshop_location}</td>
                                                        <td>{docIdsMap[item.phone] || 'N/A'}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
};

export default SendCode;



// import React, { useState, useEffect } from 'react';
// import { useNavigate, Link } from 'react-router-dom';
// import db from "./Firebase";
// import Moment from 'moment';
// import { extendMoment } from 'moment-range';

// const SendCode = () => {
//     const navigate = useNavigate();
//     const moment = extendMoment(Moment);
//     const [data, setData] = useState([]);
//     const [phoneNumbers, setPhoneNumbers] = useState([]);
//     const [docIdsMap, setDocIdsMap] = useState({});

//     useEffect(() => {
//         const storedUsername = localStorage.getItem("email");
//         const storedPassword = localStorage.getItem("password");
//         const storedRemember = localStorage.getItem("remember");

//         if (storedRemember === "false" || !storedUsername || !storedPassword) {
//             navigate('/signin');
//         }
//     }, [navigate]);

//     useEffect(() => {
//         const fetchData = async () => {
//             try {
//                 const snapshot = await db.collection("workshop_registration")
//                     .where("workshop_event_name", "==", "Pant Style Palazzo")
//                     .get();

//                 const phoneNumbers = [];
//                 const fetchedData = snapshot.docs.map((doc) => {
//                     const data = doc.data();
//                     phoneNumbers.push(data.phone);
//                     return {
//                         id: doc.id,
//                         ...data,
//                     };
//                 });

//                 setData(fetchedData);
//                 setPhoneNumbers(phoneNumbers);
//                 console.log("Phone Numbers:", phoneNumbers);
//             } catch (error) {
//                 console.error("Error fetching data:", error);
//             }
//         };

//         fetchData();
//     }, []);

//     useEffect(() => {
//         const fetchPersonalDetails = async () => {
//             if (phoneNumbers.length === 0) return;

//             try {
//                 const promises = phoneNumbers.map(phone =>
//                     db.collection("personal_details").where("phone_no", "==", phone).get()
//                 );
//                 const querySnapshots = await Promise.all(promises);

//                 const docIdsMap = {};
//                 querySnapshots.forEach(querySnapshot => {
//                     querySnapshot.forEach(doc => {
//                         docIdsMap[doc.data().phone_no] = doc.id;
//                     });
//                 });

//                 setDocIdsMap(docIdsMap);
//                 console.log("Doc IDs:", Object.values(docIdsMap));
//             } catch (error) {
//                 console.error("Error fetching personal details:", error);
//             }
//         };

//         fetchPersonalDetails();
//     }, [phoneNumbers]);

//     return (
//         <>
//             <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg">
//                 <div class="p-2" style={{ paddingBottom: "5px", display: "flex", justifyContent: "space-between" }}>
//                     <Link to="/admin_panel">
//                         <img src="/images/menubutton.png" class="menu-toggle" alt="Responsive image" style={{ height: "2rem" }} />
//                     </Link>
//                 </div>
//                 <div class="">
//                     <div class="p-2">
//                         <div style={{ textAlign: "end", paddingBottom: "5px" }}>
//                         </div>
//                         <div class="card">
//                             <div class="card-header pb-0 px-3">
//                                 <h6 class="mb-2">Workshop Registrations for "Glam Party Look"</h6>
//                             </div>
//                             <div class="">
//                                 <div class="card">
//                                     <div class="table-responsive fix-width scroll-inner">
//                                         <table class="table table-bordered table-hover">
//                                             <thead class="thead-dark" style={{ position: "sticky", top: "0" }}>
//                                                 <tr>
//                                                     <th class="col">Full Name</th>
//                                                     <th class="col">Phone</th>
//                                                     <th class="col">Email</th>
//                                                     <th class="col">Payment Status</th>
//                                                     <th class="col">Workshop Date</th>
//                                                     <th class="col">Location</th>
//                                                     <th class="col">Doc ID</th>
//                                                 </tr>
//                                             </thead>
//                                             <tbody>
//                                                 {data.map((item) => (
//                                                     <tr key={item.id}>
//                                                         <td>{item.full_name}</td>
//                                                         <td>{item.phone}</td>
//                                                         <td>{item.email}</td>
//                                                         <td>{item.payment}</td>
//                                                         <td>{moment(item.workshop_Date).format("MMM DD, YYYY h:mm A")}</td>
//                                                         <td>{item.workshop_location}</td>
//                                                         <td>{docIdsMap[item.phone] || 'N/A'}</td>
//                                                     </tr>
//                                                 ))}
//                                             </tbody>
//                                         </table>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </main>
//         </>
//     );
// };

// export default SendCode;
