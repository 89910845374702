import React, { useState, useEffect } from 'react';
import { useNavigate, Link, useParams } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2'
import db from "./Firebase";
import { storage } from './Firebase';
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";


function ShareWorkshop() {
    let params = useParams();
    const navigate = useNavigate();




    const initialFormStateFeedback = { name: '', email: '', phone_no: '', referred_by: '', created_date: new Date() }
    const [submit, setSubmit] = useState(initialFormStateFeedback)

    const [show, setshow] = useState(true);

    const [imgUrl, setImgUrl] = useState(null);
    const [progresspercent, setProgresspercent] = useState(0);


    const handleChange = (e) => {
        const { id, value } = e.target
        setSubmit({ ...submit, [id]: value })
    }

    const reset = () => {
        setSubmit({ name: '', email: '', phone_no: '', referred_by: '', created_date: new Date() })
    }

    const [workshopData, setWorkshopData] = useState({});


    const handleSubmit = (e) => {
        e.preventDefault()

        db.collection("workshop_participants").add({
            'name': submit.name,
            'email': submit.email,
            'phone_no': submit.phone_no,
            'workshop_name': workshopData.name,
            'created_date': new Date(),
        }).then(() => {
            console.log('Submitted Successfully');
            const Toast = Swal.mixin({
                toast: true,
                background: '#69aba6',
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            })

            Toast.fire({
                icon: 'success',
                title: 'Submitted Successfully'
            })
            setshow(true)
            // navigate(`/${submit.username}`);
            setSubmit({ name: '', email: '', phone_no: '', referred_by: '', created_date: new Date() })
        })
    }

    useEffect(() => {
        db.collection('workshop_event_data').doc(params.id).get()
            .then(snapshot => setWorkshopData(snapshot.data()))
    }, []);



    return (
        <>
            <div id="small-dialog" class="m-3">
                <div class="row"> <h4 style={{ fontSize: "25px", fontWeight: "600", paddingLeft: "4px" }}>Workshop Details</h4></div>
                <div class="container">
                    <div class="row">
                        <div class="col-sm">
                            <img src={workshopData.image}></img>
                        </div>
                        <div class="col-sm">
                            <div class="w3layouts_payment_online_form">
                                <form class="" id="" onSubmit={handleSubmit}>
                                    <h4>Enter Your Details</h4>
                                    <ul>

                                        <li><input class="w3l_text_box" id="name" onChange={handleChange} value={submit.name} placeholder="Name" name="Name" type="text" required />
                                        </li>
                                        <li><input class="w3l_text_box" id="phone_no" onChange={handleChange} value={submit.phone_no} placeholder="10 Digit Phone Number" name="Phone" type="tel" minlength="10" maxlength="10" required />
                                        </li>
                                    </ul>
                                    <ul>
                                        <li>
                                            <input class="w3l_text_box" id="email" onChange={handleChange} value={submit.email} placeholder="Email" name="Email" type="email" required="" />
                                        </li>
                                        <li>
                                        </li>
                                    </ul>

                                    <ul class="w3_payment_sendbtns">
                                        <li><input type="reset" data-dismiss="modal" aria-label="Close" onClick={reset} value="Reset" /></li>
                                        <li>{show ? <input type="submit" value="Submit" /> : "Please Wait..."}</li>
                                    </ul>
                                    <div class="clearfix"> </div>
                                </form>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            {/* <button onClick={upload}>Send</button> */}
            {/* <div id="small-dialog" class="m-3">
                <div class="w3layouts_payment_online_form" style={{ textAlign: "-webkit-center" }}>
                    <form class="" id="" onSubmit={handleSubmit}>
                        <h4>Workshop Details</h4>
                        <img src='images/bride1_1.webp' style={{ height: "27rem" }}></img>
                        <ul>

                            <li><input class="w3l_text_box" id="name" onChange={handleChange} value={submit.name} placeholder="Name" name="Name" type="text" required />
                            </li>  <li><input class="w3l_text_box" id="phone_no" onChange={handleChange} value={submit.phone_no} placeholder="Phone No" name="Phone No" type="text" required />
                            </li>
                        </ul>
                        <ul>
                            <li>
                                <input class="w3l_text_box" id="email" onChange={handleChange} value={submit.email} placeholder="Email" name="Email" type="email" required="" />
                            </li>
                            <li>
                            </li>
                        </ul>

                        <ul class="w3_payment_sendbtns">
                            <li><input type="reset" data-dismiss="modal" aria-label="Close" onClick={reset} value="Reset" /></li>
                            <li>{show ? <input type="submit" value="Submit" /> : "Please Wait..."}</li>
                        </ul>
                        <div class="clearfix"> </div>
                    </form>
                </div>
            </div> */}
        </>


    );
}
export default ShareWorkshop;