import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate, Link, useParams } from "react-router-dom"


function Header() {


    return (
        <>
            <section style={{ boxShadow: "0 6px 6px -6px #777" }}>
                <div class="navi" >
                    <input type="checkbox" id="nav-check" />
                    <div class="nav-header">
                        <div class="nav-title">
                            <Link to="/"> <img src="/images/youvati_logo.png" alt="" style={{ height: "27px", marginLeft: "2rem" }} /></Link>
                        </div>
                    </div>


                </div>
            </section>
        </>
    )
}

export default Header;