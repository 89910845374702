import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import db from "./Firebase";
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import { CSVLink } from "react-csv";

function UsersProfile() {
    const navigate = useNavigate();
    const moment = extendMoment(Moment);
    const [data, setData] = useState([]);
    const [pdata, setPData] = useState({});
    const [csvdata, setCsvdata] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [lastVisible, setLastVisible] = useState(null);
    const [loading, setLoading] = useState(false);

    const headers = [
        { label: "Full Name", key: "full_name" },
        { label: "Username", key: "username" },
        { label: "Phone No", key: "phone_no" },
        { label: "State", key: "state" },
        { label: "City", key: "city" },
        { label: "Created Date", key: "created_date" },
        // Add more column labels as needed
    ];

    useEffect(() => {
        const storedUsername = localStorage.getItem("email");
        const storedPassword = localStorage.getItem("password");
        const storedRemember = localStorage.getItem("remember");

        if (storedRemember === "false" || !storedUsername || !storedPassword) {
            navigate('/signin');
        } else {
            db.collection("user_based_role").where('email', '==', storedUsername).onSnapshot((snapshot) => {
                const userRoles = snapshot.docs.map((doc) => doc.data().role);
                if (userRoles[0].includes("admin") && userRoles[0].includes("employee")) {
                    console.log("You have two rights admin and employee")
                } else if (userRoles[0].includes("employee")) {
                    console.log("You have one right")
                } else if (userRoles[0].includes("admin")) {
                    console.log("You have one right only admin")
                } else {
                    console.log("You have no specific rights")
                }
            });
        }
    }, [navigate]);

    const getParlourDetails = (usr) => {
        setPData({});
        window.openModelshow();
        db.collection("parlour_details").where('username', '==', usr).onSnapshot((snapshot) => {
            snapshot.docs.forEach((doc) => setPData(doc.data()));
        });
    }

    useEffect(() => {
        loadInitialData();
    }, []);

    const loadInitialData = async () => {
        setLoading(true);
        const usersData = [];
        const parlourData = [];

        const usersSnapshot = await db.collection("personal_details").orderBy("created_date", "desc").limit(20).get();
        usersSnapshot.docs.forEach((doc) => {
            usersData.push({ id: doc.id, data: doc.data() });
        });
        setLastVisible(usersSnapshot.docs[usersSnapshot.docs.length - 1]);

        const parlourSnapshot = await db.collection("parlour_name").get();
        parlourSnapshot.docs.forEach((doc) => {
            parlourData.push({ id: doc.id, data: doc.data() });
        });

        mergeData(usersData, parlourData);
        setLoading(false);
    }

    const loadMoreData = async () => {
        setLoading(true);
        const usersData = [];
        const parlourData = [];

        const usersSnapshot = await db.collection("personal_details").orderBy("created_date", "desc").startAfter(lastVisible).limit(20).get();
        usersSnapshot.docs.forEach((doc) => {
            usersData.push({ id: doc.id, data: doc.data() });
        });
        setLastVisible(usersSnapshot.docs[usersSnapshot.docs.length - 1]);

        const parlourSnapshot = await db.collection("parlour_name").get();
        parlourSnapshot.docs.forEach((doc) => {
            parlourData.push({ id: doc.id, data: doc.data() });
        });

        mergeData(usersData, parlourData, true);
        setLoading(false);
    }

    const mergeData = (usersData, parlourData, append = false) => {
        if (usersData.length > 0 && parlourData.length > 0) {
            const mergedData = usersData.map((user) => {
                const parlour = parlourData.find((p) => p.data.username === user.data.username);
                return { ...user, parlour: parlour ? parlour.data : null };
            });

            setData(prevData => append ? [...prevData, ...mergedData] : mergedData);
        }
    }

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const filteredData = data.filter((item) => {
        const username = item.data && item.data.username ? item.data.username.toLowerCase() : "";
        const full_name = item.data && item.data.full_name ? item.data.full_name.toLowerCase() : "";
        const city = item.parlour && item.parlour.city ? item.parlour.city.toLowerCase() : "";
        const state = item.parlour && item.parlour.state ? item.parlour.state.toLowerCase() : "";
        const phone = item.parlour && item.parlour.phone ? item.parlour.phone.toLowerCase() : "";
        const query = searchQuery.toLowerCase();

        return (
            username.includes(query) ||
            full_name.includes(query) ||
            city.includes(query) ||
            state.includes(query) ||
            phone.includes(query)
        );
    });

    const fetchAllData = async () => {
        const allUsersData = [];
        const usersSnapshot = await db.collection("personal_details").orderBy("created_date", "desc").get();
        usersSnapshot.docs.forEach((doc) => {
            allUsersData.push({ id: doc.id, data: doc.data() });
        });

        const parlourSnapshot = await db.collection("parlour_name").get();
        const parlourData = [];
        parlourSnapshot.docs.forEach((doc) => {
            parlourData.push({ id: doc.id, data: doc.data() });
        });

        const mergedData = allUsersData.map((user) => {
            const parlour = parlourData.find((p) => p.data.username === user.data.username);
            return { ...user, parlour: parlour ? parlour.data : null };
        });

        const csvData = mergedData.map((item) => ({
            full_name: item.data.full_name,
            username: item.data.username,
            phone_no: item.data.phone_no,
            state: item.parlour ? item.parlour.state : '',
            city: item.parlour ? item.parlour.city : '',
            created_date: moment(item.data.created_date.toDate().toISOString()).format("MM/DD/YY, h:mm a"),
        }));

        setCsvdata(csvData);
    };

    const handleDownloadClick = async () => {
        setLoading(true);
        await fetchAllData();
        setLoading(false);
    };

    return (
        <>
            <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg">
                <div className="p-2" style={{ paddingBottom: "5px", display: "flex", justifyContent: "space-between" }}>
                    <Link to="/admin_panel"><img src="/images/menubutton.png" className="menu-toggle" alt="Responsive image" style={{ height: "2rem" }} /></Link>
                    <div style={{ display: "grid", justifyContent: "" }}>
                        <button onClick={handleDownloadClick} className="btn btn-dark">Prepare CSV</button>
                        <CSVLink data={csvdata} headers={headers} filename={"users_data.csv"}>
                            <span className="btn bg-gradient-secondary btn-sm mb-0 mr-2" style={{ color: "white" }}>
                                &nbsp; Download Excel
                            </span>
                        </CSVLink>
                    </div>
                </div>
                <div className="">
                    <div className="p-2">
                        <div className="card">
                            <div className="card-header pb-0 px-3">
                                <h6 className="mb-2">Users Profile</h6>
                            </div>
                            <input
                                type="text"
                                className="form-control form-control-sm"
                                placeholder="Search by Name, Username, City, State, or Phone Number"
                                value={searchQuery}
                                onChange={handleSearchChange}
                            />
                            <div className="">
                                <div className="card">
                                    <div className="table-responsive fix-width scroll-inner">
                                        <table className="table table-bordered table-hover">
                                            <thead className="thead-dark" style={{ position: "sticky", top: "0" }}>
                                                <tr style={{ textAlign: "left" }}>
                                                    <th className="ps-2">Name</th>
                                                    <th className="">Phone No</th>
                                                    <th className="ps-2">Email</th>
                                                    <th className="ps-2">State</th>
                                                    <th className="ps-2">City</th>
                                                    <th className="ps-2">Type</th>
                                                    <th className="ps-2">User Name/Portfolio</th>
                                                    <th className="ps-2">Created Date</th>
                                                    <th className="ps-2">UPI Address</th>
                                                </tr>
                                            </thead>
                                            <tbody style={{ textAlign: "left" }}>
                                                {filteredData.map(({ id, data, parlour }, index) => (
                                                    <tr key={index}>
                                                        <td>
                                                            <div className="d-flex px-2 py-1">
                                                                <div>
                                                                    <img src={data.image} className="avatar avatar-sm me-3" alt="User" />
                                                                </div>
                                                                <div className="d-flex flex-column justify-content-center">
                                                                    <h6 className="mb-0 font-weight-bold text-xs" onClick={() => getParlourDetails(data.username)} style={{ cursor: "pointer", color: "#17a2b8" }}>{data.full_name}</h6>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <p className="text-xs mb-0">{data.phone_no}</p>
                                                        </td>
                                                        <td>
                                                            <p className="text-xs mb-0">{data.email}</p>
                                                        </td>
                                                        <td>
                                                            <p className="text-xs mb-0">{parlour ? parlour.state : ''}</p>
                                                        </td>
                                                        <td>
                                                            <p className="text-xs mb-0">{parlour ? parlour.city : ''}</p>
                                                        </td>
                                                        <td>
                                                            <div className="d-flex px-2 py-1">
                                                                <div className="d-flex flex-column justify-content-center">
                                                                    <h6 className="mb-0 text-xs">{data.type}</h6>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="d-flex px-2 py-1">
                                                                <div className="d-flex flex-column justify-content-center">
                                                                    <a href={data.username} style={{ color: "blue" }} target="_blank" rel="noreferrer" className="mb-0 text-xs">{data.username}</a>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="d-flex px-2 py-1">
                                                                <div className="d-flex flex-column justify-content-center">
                                                                    <h6 className="mb-0 text-xs">{moment(data.created_date.toDate().toISOString()).format("MM/DD/YY, h:mm a")}</h6>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="d-flex px-2 py-1">
                                                                <div className="d-flex flex-column justify-content-center">
                                                                    <h6 className="mb-0 text-xs">{data.upi_address}</h6>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                        {lastVisible && (
                                            <button onClick={loadMoreData} className="btn btn-dark mt-2" disabled={loading}>
                                                {loading ? 'Loading...' : 'Load More'}
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <div className="col-md-4">
                <div className="myId modal fade" id="" tabIndex="-1" role="dialog" aria-labelledby="modal-form" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-body p-0">
                                <div className="card card-plain">
                                    <div className="card-body">
                                        <div className="modal-body">
                                            {pdata.parlour_name == null ? <span>No Data Found</span> : (
                                                <div>
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <span style={{ color: "grey" }}>Parlour Name</span> : <span>{pdata.parlour_name}</span>
                                                            <hr />
                                                            <span style={{ color: "grey" }}>Phone No </span> : <span> {pdata.phone_no}</span>
                                                            <hr />
                                                            <span style={{ color: "grey" }}>Pincode </span> : <span> {pdata.pincode}</span>
                                                            <hr />
                                                            <span style={{ color: "grey" }}>Owner Type </span> : <span>{pdata.owner}</span>
                                                            <hr />
                                                            <span style={{ color: "grey" }}>User Name </span> : <span>{pdata.username}</span>
                                                            <hr />
                                                            <span style={{ color: "grey" }}>Partner Type </span> : <span> {pdata.partner_type}</span>
                                                            <hr />
                                                            <span style={{ color: "grey" }}>Address </span> : <span>{pdata.full_address}</span>
                                                        </div>
                                                    </div>
                                                    <hr />
                                                    <span style={{ color: "grey" }}>Business Image : </span>
                                                    {pdata.salon_business_images == "" ? (
                                                        <span>No image found !</span>
                                                    ) : (
                                                        <div className="row">
                                                            {pdata.salon_business_images?.map((imgData) => (
                                                                <img src={imgData} className="p-3" style={{ height: "6rem", width: "7rem" }} alt="Business" key={imgData} />
                                                            ))}
                                                        </div>
                                                    )}
                                                    <hr />
                                                    <span style={{ color: "grey" }}>Saloon Image : </span>
                                                    {pdata.services_images == "" ? (
                                                        <span>No image found !</span>
                                                    ) : (
                                                        <div className="row">
                                                            {pdata.services_images?.map((imgData) => (
                                                                <img src={imgData} className="p-3" style={{ height: "6rem", width: "7rem" }} alt="Service" key={imgData} />
                                                            ))}
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default UsersProfile;


// import React, { useState, useEffect } from 'react';
// import { useNavigate, Link } from 'react-router-dom';
// import db from "./Firebase";
// import Moment from 'moment';
// import { extendMoment } from 'moment-range';
// import { CSVLink } from "react-csv";

// function UsersProfile() {
//     const navigate = useNavigate();
//     const moment = extendMoment(Moment);
//     const [data, setData] = useState([]);
//     const [pdata, setPData] = useState({});
//     const [csvdata, setCsvdata] = useState([]);
//     const [searchQuery, setSearchQuery] = useState("");
//     const [lastVisible, setLastVisible] = useState(null);
//     const [loading, setLoading] = useState(false);

//     const headers = [
//         "full_name",
//         "Username",
//         "Phone_no",
//         "State",
//         "City",
//         "Created Date",
//         // Add more column labels as needed
//     ];

//     useEffect(() => {
//         const storedUsername = localStorage.getItem("email");
//         const storedPassword = localStorage.getItem("password");
//         const storedRemember = localStorage.getItem("remember");

//         if (storedRemember === "false" || !storedUsername || !storedPassword) {
//             navigate('/signin');
//         } else {
//             db.collection("user_based_role").where('email', '==', storedUsername).onSnapshot((snapshot) => {
//                 const userRoles = snapshot.docs.map((doc) => doc.data().role);
//                 if (userRoles[0].includes("admin") && userRoles[0].includes("employee")) {
//                     console.log("You have two rights admin and employee")
//                 } else if (userRoles[0].includes("employee")) {
//                     console.log("You have one right")
//                 } else if (userRoles[0].includes("admin")) {
//                     console.log("You have one right only admin")
//                 } else {
//                     console.log("You have no specific rights")
//                 }
//             });
//         }
//     }, []);

//     const getParlourDetails = (usr) => {
//         setPData({})
//         window.openModelshow()
//         db.collection("parlour_details").where('username', '==', usr).onSnapshot((snapshot) => {
//             snapshot.docs.forEach((doc) => setPData(doc.data()));
//         });
//     }

//     useEffect(() => {
//         loadInitialData();
//     }, []);

//     const loadInitialData = async () => {
//         setLoading(true);
//         const usersData = [];
//         const parlourData = [];

//         const usersSnapshot = await db.collection("personal_details").orderBy("created_date", "desc").limit(20).get();
//         usersSnapshot.docs.forEach((doc) => {
//             usersData.push({ id: doc.id, data: doc.data() });
//         });
//         setLastVisible(usersSnapshot.docs[usersSnapshot.docs.length - 1]);

//         const parlourSnapshot = await db.collection("parlour_name").get();
//         parlourSnapshot.docs.forEach((doc) => {
//             parlourData.push({ id: doc.id, data: doc.data() });
//         });

//         mergeData(usersData, parlourData);
//         setLoading(false);
//     }

//     const loadMoreData = async () => {
//         setLoading(true);
//         const usersData = [];
//         const parlourData = [];

//         const usersSnapshot = await db.collection("personal_details").orderBy("created_date", "desc").startAfter(lastVisible).limit(20).get();
//         usersSnapshot.docs.forEach((doc) => {
//             usersData.push({ id: doc.id, data: doc.data() });
//         });
//         setLastVisible(usersSnapshot.docs[usersSnapshot.docs.length - 1]);

//         const parlourSnapshot = await db.collection("parlour_name").get();
//         parlourSnapshot.docs.forEach((doc) => {
//             parlourData.push({ id: doc.id, data: doc.data() });
//         });

//         mergeData(usersData, parlourData, true);
//         setLoading(false);
//     }

//     const mergeData = (usersData, parlourData, append = false) => {
//         if (usersData.length > 0 && parlourData.length > 0) {
//             const mergedData = usersData.map((user) => {
//                 const parlour = parlourData.find((p) => p.data.username === user.data.username);
//                 return { ...user, parlour: parlour ? parlour.data : null };
//             });

//             setData(prevData => append ? [...prevData, ...mergedData] : mergedData);
//         }
//     }

//     const handleSearchChange = (event) => {
//         setSearchQuery(event.target.value);
//     };

//     const filteredData = data.filter((item) => {
//         const username = item.data && item.data.username ? item.data.username.toLowerCase() : "";
//         const full_name = item.data && item.data.full_name ? item.data.full_name.toLowerCase() : "";
//         const city = item.parlour && item.parlour.city ? item.parlour.city.toLowerCase() : "";
//         const state = item.parlour && item.parlour.state ? item.parlour.state.toLowerCase() : "";
//         const phone = item.parlour && item.parlour.phone ? item.parlour.phone.toLowerCase() : "";
//         const query = searchQuery.toLowerCase();

//         return (
//             username.includes(query) ||
//             full_name.includes(query) ||
//             city.includes(query) ||
//             state.includes(query) ||
//             phone.includes(query)
//         );
//     });

//     const prepareCSVData = () => {
//         const csvData = filteredData.map((item) => ([
//             item.data.full_name,
//             item.data.username,
//             item.data.phone_no,
//             item.parlour ? item.parlour.state : '',
//             item.parlour ? item.parlour.city : '',
//             moment(item.data.created_date.toDate().toISOString()).format("MM/DD/YY, h:mm a"),
//         ]));

//         return csvData;
//     };

//     return (
//         <>
//             <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg">
//                 <div className="p-2" style={{ paddingBottom: "5px", display: "flex", justifyContent: "space-between" }}>
//                     <Link to="/admin_panel"><img src="/images/menubutton.png" className="menu-toggle" alt="Responsive image" style={{ height: "2rem" }} /></Link>
//                     <CSVLink data={prepareCSVData()} headers={headers} filename={"users_data.csv"}>
//                         <span className="btn bg-gradient-secondary btn-sm mb-0 mr-2" style={{ color: "white" }}>
//                             &nbsp; Download Excel
//                         </span>
//                     </CSVLink>
//                 </div>
//                 <div className="">
//                     <div className="p-2">
//                         <div className="card">
//                             <div className="card-header pb-0 px-3">
//                                 <h6 className="mb-2">Users Profile</h6>
//                             </div>
//                             <input
//                                 type="text"
//                                 className="form-control form-control-sm"
//                                 placeholder="Search by Name, Username, City, State, or Phone Number"
//                                 value={searchQuery}
//                                 onChange={handleSearchChange}
//                             />
//                             <div className="">
//                                 <div className="card">
//                                     <div className="table-responsive fix-width scroll-inner">
//                                         <table className="table table-bordered table-hover">
//                                             <thead className="thead-dark" style={{ position: "sticky", top: "0" }}>
//                                                 <tr style={{ textAlign: "left" }}>
//                                                     <th className="ps-2">Name</th>
//                                                     <th className="">Phone No</th>
//                                                     <th className="ps-2">Email</th>
//                                                     <th className="ps-2">State</th>
//                                                     <th className="ps-2">City</th>
//                                                     <th className="ps-2">Type</th>
//                                                     <th className="ps-2">User Name/Portfolio</th>
//                                                     <th className="ps-2">Created Date</th>
//                                                     <th className="ps-2">UPI Address</th>
//                                                 </tr>
//                                             </thead>
//                                             <tbody style={{ textAlign: "left" }}>
//                                                 {filteredData.map(({ id, data, parlour }, index) => (
//                                                     <tr key={index}>
//                                                         <td>
//                                                             <div className="d-flex px-2 py-1">
//                                                                 <div>
//                                                                     <img src={data.image} className="avatar avatar-sm me-3" alt="User" />
//                                                                 </div>
//                                                                 <div className="d-flex flex-column justify-content-center">
//                                                                     <h6 className="mb-0 font-weight-bold text-xs" onClick={() => getParlourDetails(data.username)} style={{ cursor: "pointer", color: "#17a2b8" }}>{data.full_name}</h6>
//                                                                 </div>
//                                                             </div>
//                                                         </td>
//                                                         <td>
//                                                             <p className="text-xs mb-0">{data.phone_no}</p>
//                                                         </td>
//                                                         <td>
//                                                             <p className="text-xs mb-0">{data.email}</p>
//                                                         </td>
//                                                         <td>
//                                                             <p className="text-xs mb-0">{parlour ? parlour.state : ''}</p>
//                                                         </td>
//                                                         <td>
//                                                             <p className="text-xs mb-0">{parlour ? parlour.city : ''}</p>
//                                                         </td>
//                                                         <td>
//                                                             <div className="d-flex px-2 py-1">
//                                                                 <div className="d-flex flex-column justify-content-center">
//                                                                     <h6 className="mb-0 text-xs">{data.type}</h6>
//                                                                 </div>
//                                                             </div>
//                                                         </td>
//                                                         <td>
//                                                             <div className="d-flex px-2 py-1">
//                                                                 <div className="d-flex flex-column justify-content-center">
//                                                                     <a href={data.username} style={{ color: "blue" }} target="_blank" rel="noreferrer" className="mb-0 text-xs">{data.username}</a>
//                                                                 </div>
//                                                             </div>
//                                                         </td>
//                                                         <td>
//                                                             <div className="d-flex px-2 py-1">
//                                                                 <div className="d-flex flex-column justify-content-center">
//                                                                     <h6 className="mb-0 text-xs">{moment(data.created_date.toDate().toISOString()).format("MM/DD/YY, h:mm a")}</h6>
//                                                                 </div>
//                                                             </div>
//                                                         </td>
//                                                         <td>
//                                                             <div className="d-flex px-2 py-1">
//                                                                 <div className="d-flex flex-column justify-content-center">
//                                                                     <h6 className="mb-0 text-xs">{data.upi_address}</h6>
//                                                                 </div>
//                                                             </div>
//                                                         </td>
//                                                     </tr>
//                                                 ))}
//                                             </tbody>
//                                         </table>
//                                         {lastVisible && (
//                                             <button onClick={loadMoreData} className="btn btn-dark mt-2" disabled={loading}>
//                                                 {loading ? 'Loading...' : 'Load More'}
//                                             </button>
//                                         )}
//                                     </div>

//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </main>
//             <div className="col-md-4">
//                 <div className="myId modal fade" id="" tabIndex="-1" role="dialog" aria-labelledby="modal-form" aria-hidden="true">
//                     <div className="modal-dialog modal-dialog-centered" role="document">
//                         <div className="modal-content">
//                             <div className="modal-body p-0">
//                                 <div className="card card-plain">
//                                     <div className="card-body">
//                                         <div className="modal-body">
//                                             {pdata.parlour_name == null ? <span>No Data Found</span> : (
//                                                 <div>
//                                                     <div className="row">
//                                                         <div className="col-md-12">
//                                                             <span style={{ color: "grey" }}>Parlour Name</span> : <span>{pdata.parlour_name}</span>
//                                                             <hr />
//                                                             <span style={{ color: "grey" }}>Phone No </span> : <span> {pdata.phone_no}</span>
//                                                             <hr />
//                                                             <span style={{ color: "grey" }}>Pincode </span> : <span> {pdata.pincode}</span>
//                                                             <hr />
//                                                             <span style={{ color: "grey" }}>Owner Type </span> : <span>{pdata.owner}</span>
//                                                             <hr />
//                                                             <span style={{ color: "grey" }}>User Name </span> : <span>{pdata.username}</span>
//                                                             <hr />
//                                                             <span style={{ color: "grey" }}>Partner Type </span> : <span> {pdata.partner_type}</span>
//                                                             <hr />
//                                                             <span style={{ color: "grey" }}>Address </span> : <span>{pdata.full_address}</span>
//                                                         </div>
//                                                     </div>
//                                                     <hr />
//                                                     <span style={{ color: "grey" }}>Business Image : </span>
//                                                     {pdata.salon_business_images == "" ? (
//                                                         <span>No image found !</span>
//                                                     ) : (
//                                                         <div className="row">
//                                                             {pdata.salon_business_images?.map((imgData) => (
//                                                                 <img src={imgData} className="p-3" style={{ height: "6rem", width: "7rem" }} alt="Business" key={imgData} />
//                                                             ))}
//                                                         </div>
//                                                     )}
//                                                     <hr />
//                                                     <span style={{ color: "grey" }}>Saloon Image : </span>
//                                                     {pdata.services_images == "" ? (
//                                                         <span>No image found !</span>
//                                                     ) : (
//                                                         <div className="row">
//                                                             {pdata.services_images?.map((imgData) => (
//                                                                 <img src={imgData} className="p-3" style={{ height: "6rem", width: "7rem" }} alt="Service" key={imgData} />
//                                                             ))}
//                                                         </div>
//                                                     )}
//                                                 </div>
//                                             )}
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </>
//     )
// }

// export default UsersProfile;



// // import React, { useState, useEffect } from 'react';
// // import { useNavigate, Link, useParams } from 'react-router-dom';
// // import db from "./Firebase";
// // import firebaseApp from "./Firebase";
// // import { arrayUnion } from "firebase/firestore";
// // import { storage } from './Firebase';
// // import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
// // import Moment from 'moment';
// // import { extendMoment } from 'moment-range';
// // import ViewAllRequests from './ViewAllRequests';
// // import ViewAppData from './ViewAppData';
// // import { CSVLink } from "react-csv";

// // function UsersProfile() {
// //     const navigate = useNavigate();
// //     const moment = extendMoment(Moment);
// //     const [data, setData] = useState([]);
// //     const [pdata, setPData] = useState({});
// //     const [csvdata, setCsvdata] = useState([]);
// //     const [searchQuery, setSearchQuery] = useState("");

// //     const headers = [
// //         "full_name",
// //         "Username",
// //         "Phone_no",
// //         "State",
// //         "City",
// //         "Created Date",
// //         // Add more column labels as needed
// //     ];

// //     useEffect(() => {
// //         const storedUsername = localStorage.getItem("email");
// //         const storedPassword = localStorage.getItem("password");
// //         const storedRemember = localStorage.getItem("remember");

// //         if (storedRemember === "false" || !storedUsername || !storedPassword) {
// //             navigate('/signin');
// //         } else {
// //             db.collection("user_based_role").where('email', '==', storedUsername).onSnapshot((snapshot) => {
// //                 const userRoles = snapshot.docs.map((doc) => doc.data().role);
// //                 const userPassword = snapshot.docs.map((doc) => doc.data().password);
// //                 console.log(userRoles[0]);
// //                 // console.log(userPassword[0]);

// //                 if (userRoles[0].includes("admin") && userRoles[0].includes("employee")) {
// //                     console.log("You have two rights admin and employee")
// //                     // navigate('/admin_panel');
// //                 } else if (userRoles[0].includes("employee")) {
// //                     console.log("You have one right")
// //                     // navigate('/user_based_role');
// //                 }
// //                 else if (userRoles[0].includes("admin")) {
// //                     console.log("You have one right only admin")
// //                 }
// //                 else {
// //                     console.log("You have no specific rights")
// //                 }
// //             });
// //         }
// //     }, []);

// //     const changeStatus = () => {
// //         openModalShow();
// //     }

// //     const openModalShow = () => {
// //         // alert("ooo")
// //         window.openModelshow();
// //     }

// //     const closeModel = () => {
// //         window.openModelNew();
// //     }

// //     const getParlourDetails = (usr) => {
// //         setPData({})
// //         openModalShow()
// //         console.log(usr)
// //         db.collection("parlour_details").where('username', '==', usr).onSnapshot((snapshot) => {
// //             snapshot.docs.map(e => setPData(e.data()))
// //             snapshot.docs.map(e => console.log("testi-", e.data()))


// //             // setPData(
// //             //     snapshot.docs.map((doc) => ({
// //             //         id: doc.id,
// //             //         data: doc.data(),
// //             //     }))
// //             // );

// //             // snapshot.docs.map(e => console.log("testi-", e.data()))
// //         });
// //         // console.log(id)
// //         // db.collection("parlour_details")
// //         //     .doc("0ph1x6052Jbb0Sn2Al26LLBSopR2")
// //         //     .get()
// //         //     .then((docRef) => { console.log(docRef.data()) })
// //         //     .catch((error) => { console.log(error) })
// //     }

// //     useEffect(() => {
// //         const usersData = [];
// //         const parlourData = [];

// //         // Fetch data from "users_location" collection
// //         db.collection("personal_details").orderBy("created_date", "desc").onSnapshot((snapshot) => {
// //             usersData.length = 0; // Clear the array
// //             snapshot.docs.forEach((doc) => {
// //                 usersData.push({
// //                     id: doc.id,
// //                     data: doc.data(),
// //                 });
// //             });
// //             mergeData();
// //         });

// //         // Fetch data from "parlour_name" collection
// //         db.collection("parlour_name").onSnapshot((snapshot) => {
// //             parlourData.length = 0; // Clear the array
// //             snapshot.docs.forEach((doc) => {
// //                 parlourData.push({
// //                     id: doc.id,
// //                     data: doc.data(),
// //                 });
// //             });
// //             mergeData();
// //         });
// //         // console.log(usersData)
// //         // console.log(parlourData)

// //         // Function to merge data when both sets are available
// //         function mergeData() {
// //             if (usersData.length > 0 && parlourData.length > 0) {
// //                 // Merge the data based on the username
// //                 const mergedData = usersData.map((user) => {
// //                     const parlour = parlourData.find((p) => p.data.username === user.data.username);
// //                     return {
// //                         ...user,
// //                         parlour: parlour ? parlour.data : null,
// //                     };
// //                 });

// //                 // Now, mergedData contains the merged information
// //                 setData(mergedData);
// //                 // console.log(mergedData)
// //             }
// //         }
// //     }, []);


// //     const handleSearchChange = (event) => {
// //         setSearchQuery(event.target.value);
// //     };


// //     // Function to filter data based on the search query
// //     const filteredData = data.filter((item) => {
// //         const username = item.data && item.data.username ? item.data.username.toLowerCase() : "";
// //         const full_name = item.data && item.data.full_name ? item.data.full_name.toLowerCase() : "";
// //         const city = item.parlour && item.parlour.city ? item.parlour.city.toLowerCase() : "";
// //         const state = item.parlour && item.parlour.state ? item.parlour.state.toLowerCase() : "";
// //         const phone = item.parlour && item.parlour.phone ? item.parlour.phone.toLowerCase() : "";
// //         const query = searchQuery.toLowerCase();

// //         return (
// //             username.includes(query) ||
// //             full_name.includes(query) ||
// //             city.includes(query) ||
// //             state.includes(query) ||
// //             phone.includes(query)
// //         );
// //     });

// //     // Function to convert data to CSV format
// //     const prepareCSVData = () => {
// //         const csvData = filteredData.map((item, index) => ([
// //             item.data.full_name,
// //             item.data.username,
// //             item.data.phone_no,
// //             item.parlour ? item.parlour.state : '',
// //             item.parlour ? item.parlour.city : '',
// //             moment(item.data.created_date.toDate().toISOString()).format("MM/DD/YY, h:mm a"),
// //             // Add more columns as needed
// //         ]));

// //         return csvData;
// //     };

// //     return (
// //         <>
// //             {/* <ViewAppData /> */}
// //             <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
// //                 <div class="p-2" style={{ paddingBottom: "5px", display: "flex", justifyContent: "space-between" }}>
// //                     {/* <span class="btn bg-gradient-secondary btn-sm mb-0 mr-2" style={{ color: "white" }} >&nbsp; Download Excel</span> */}
// //                     <Link to="/admin_panel"><img src="/images/menubutton.png" class="menu-toggle" alt="Responsive image" style={{ height: "2rem" }} /></Link>
// //                     <CSVLink data={prepareCSVData()} headers={headers} filename={"users_data.csv"}>
// //                         <span class="btn bg-gradient-secondary btn-sm mb-0 mr-2" style={{ color: "white" }}>
// //                             &nbsp; Download Excel
// //                         </span>
// //                     </CSVLink>
// //                 </div>
// //                 <div class="">


// //                     <div class="p-2">

// //                         <div class="card">
// //                             <div class="card-header pb-0 px-3">
// //                                 <h6 class="mb-2">Users Profile</h6>
// //                             </div>
// //                             <input
// //                                 type="text"
// //                                 className="form-control form-control-sm"
// //                                 placeholder="Search by Name, USername, City, State, or Phone Number"
// //                                 value={searchQuery}
// //                                 onChange={handleSearchChange}
// //                             />
// //                             <div class="">
// //                                 <div class="card">
// //                                     <div class="table-responsive fix-width scroll-inner">
// //                                         <table class="table table-bordered table-hover">
// //                                             <thead class="thead-dark" style={{ position: "sticky", top: "0" }}>
// //                                                 <tr style={{ textAlign: "left" }}>
// //                                                     <th class="ps-2">Name</th>
// //                                                     <th class="">Phone No</th>
// //                                                     <th class="ps-2">Email</th>
// //                                                     <th class="ps-2">State</th>
// //                                                     <th class="ps-2">City</th>
// //                                                     <th class="ps-2">Type</th>
// //                                                     <th class="ps-2">User Name/Portfolio</th>
// //                                                     <th class="ps-2">Created Date</th>
// //                                                     <th class="ps-2">UPI Address</th>
// //                                                 </tr>
// //                                             </thead>
// //                                             <tbody style={{ textAlign: "left" }}>
// //                                                 {filteredData?.map(({ id, data, parlour }, index) => (
// //                                                     <>
// //                                                         <tr key={index}>
// //                                                             <td>
// //                                                                 <div class="d-flex px-2 py-1">
// //                                                                     <div>
// //                                                                         <img src={data.image} class="avatar avatar-sm me-3" />
// //                                                                     </div>
// //                                                                     <div class="d-flex flex-column justify-content-center">
// //                                                                         <h6 class="mb-0 font-weight-bold text-xs" onClick={() => getParlourDetails(data.username)} style={{ cursor: "pointer", color: "#17a2b8" }}>{data.full_name}</h6>
// //                                                                     </div>
// //                                                                 </div>
// //                                                             </td>
// //                                                             <td>
// //                                                                 <p class="text-xs mb-0">{data.phone_no}</p>
// //                                                                 {/* <p class="text-xs text-secondary mb-0">{moment(data.created_date.toDate().toISOString()).format("MM/DD/YY, h:mm a")}</p> */}
// //                                                             </td>
// //                                                             <td>
// //                                                                 <p class="text-xs mb-0">{data.email}</p>
// //                                                                 {/* <p class="text-xs text-secondary mb-0">{moment(data.created_date.toDate().toISOString()).format("MM/DD/YY, h:mm a")}</p> */}
// //                                                             </td>
// //                                                             <td>
// //                                                                 <p class="text-xs mb-0">{parlour ? parlour.state : ''}</p>
// //                                                             </td>

// //                                                             <td>
// //                                                                 <p class="text-xs mb-0">{parlour ? parlour.city : ''}</p>
// //                                                             </td>
// //                                                             <td>
// //                                                                 <div class="d-flex px-2 py-1">
// //                                                                     <div class="d-flex flex-column justify-content-center">
// //                                                                         <h6 class="mb-0 text-xs">{data.type}</h6>
// //                                                                     </div>
// //                                                                 </div>
// //                                                             </td>

// //                                                             <td>
// //                                                                 <div class="d-flex px-2 py-1">
// //                                                                     <div class="d-flex flex-column justify-content-center">
// //                                                                         <a href={data.username} style={{ color: "blue" }} target="_blank" class="mb-0 text-xs">{data.username}</a>
// //                                                                     </div>
// //                                                                 </div>
// //                                                             </td>

// //                                                             <td>
// //                                                                 <div class="d-flex px-2 py-1">
// //                                                                     <div class="d-flex flex-column justify-content-center">
// //                                                                         <h6 class="mb-0 text-xs">{moment(data.created_date.toDate().toISOString()).format("MM/DD/YY, h:mm a")}</h6>
// //                                                                     </div>
// //                                                                 </div>
// //                                                             </td>

// //                                                             <td>
// //                                                                 <div class="d-flex px-2 py-1">
// //                                                                     <div class="d-flex flex-column justify-content-center">
// //                                                                         <h6 class="mb-0 text-xs">{data.upi_address}</h6>
// //                                                                     </div>
// //                                                                 </div>
// //                                                             </td>
// //                                                         </tr>

// //                                                     </>
// //                                                 ))}


// //                                             </tbody>
// //                                         </table>
// //                                     </div>
// //                                 </div>
// //                             </div>
// //                         </div>
// //                     </div>

// //                 </div>

// //             </main>

// //             <div class="col-md-4">
// //                 <div class="myId modal fade" id="" tabindex="-1" role="dialog" aria-labelledby="modal-form" aria-hidden="true">
// //                     <div class="modal-dialog modal-dialog-centered" role="document">
// //                         <div class="modal-content">
// //                             <div class="modal-body p-0">
// //                                 <div class="card card-plain">
// //                                     <div class="card-body">
// //                                         <div class="modal-body">
// //                                             {pdata.parlour_name == null ? <><span>No Data Found</span></> : <>
// //                                                 <div>
// //                                                     <div class="row">
// //                                                         {/* <span>Parlour Image</span> <img src="https://demos.creative-tim.com/test/material-dashboard-pro/assets/img/team-2.jpg" class="" style={{ height: "15rem" }} /> */}
// //                                                         <div class="col-md-12">

// //                                                             <span style={{ color: "grey" }}>Parlour Name</span> : <span>{pdata.parlour_name}</span>                                                    <hr />
// //                                                             <span style={{ color: "grey" }}>Phone No </span> : <span> {pdata.phone_no}</span><hr />
// //                                                             <span style={{ color: "grey" }}>Pincode </span> : <span> {pdata.pincode}</span><hr />
// //                                                             <span style={{ color: "grey" }}>Owner Type </span> : <span>{pdata.owner}</span><hr />
// //                                                             <span style={{ color: "grey" }}>User Name </span> : <span>{pdata.username}</span><hr />
// //                                                             <span style={{ color: "grey" }}>Partner Type </span> : <span> {pdata.partner_type}</span><hr />
// //                                                             <span style={{ color: "grey" }}>Address </span> : <span>{pdata.full_address}</span>
// //                                                         </div>


// //                                                     </div>
// //                                                     <hr />
// //                                                     <span style={{ color: "grey" }}>Business Image : </span>
// //                                                     {pdata.salon_business_images == "" ? <>
// //                                                         <span>No image found !</span>
// //                                                     </>
// //                                                         :
// //                                                         <>
// //                                                             <div class="row">
// //                                                                 {pdata.salon_business_images?.map((imgData) => (
// //                                                                     <>
// //                                                                         <img src={imgData} class="p-3" style={{ height: "6rem", width: "7rem" }} />
// //                                                                     </>
// //                                                                 ))}
// //                                                             </div>
// //                                                         </>}

// //                                                     <hr />
// //                                                     <span style={{ color: "grey" }}>Saloon Image : </span>
// //                                                     {pdata.services_images == "" ? <>
// //                                                         <span>No image found !</span>
// //                                                     </>
// //                                                         :
// //                                                         <>
// //                                                             <div class="row">
// //                                                                 {pdata.services_images?.map((imgData) => (
// //                                                                     <>
// //                                                                         <img src={imgData} class="p-3" style={{ height: "6rem", width: "7rem" }} />
// //                                                                     </>
// //                                                                 ))}
// //                                                             </div>
// //                                                         </>}


// //                                                 </div>
// //                                             </>}


// //                                         </div>
// //                                     </div>
// //                                 </div>
// //                             </div>
// //                         </div>
// //                     </div>
// //                 </div>
// //             </div>


// //         </>
// //     )
// // }

// // export default UsersProfile;