import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import db from "./Firebase";

function MyBusiness() {
    const navigate = useNavigate();
    const [userData, setUserData] = useState([]);
    const [sortColumn, setSortColumn] = useState(null);
    const [sortOrder, setSortOrder] = useState('asc');

    useEffect(() => {
        const storedUsername = localStorage.getItem("email");
        const storedPassword = localStorage.getItem("password");
        const storedRemember = localStorage.getItem("remember");

        if (storedRemember === "false" || !storedUsername || !storedPassword) {
            navigate('/signin');
        }
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            const appointmentsSnapshot = await db.collection("appointments").get();
            const expensesSnapshot = await db.collection("expenses").get();
            const userDataMap = new Map(); // Using a Map to store unique usernames and their data

            // Process appointments data
            appointmentsSnapshot.forEach((doc) => {
                const { username, charges } = doc.data();

                if (userDataMap.has(username)) {
                    userDataMap.get(username).appointmentCount += 1;
                    userDataMap.get(username).totalIncome += charges;
                } else {
                    userDataMap.set(username, {
                        appointmentCount: 1,
                        totalIncome: charges,
                        totalExpenses: 0, // Initialize total expenses
                    });
                }
            });

            // Process expenses data
            expensesSnapshot.forEach((doc) => {
                const { username, charges } = doc.data();

                if (userDataMap.has(username)) {
                    userDataMap.get(username).totalExpenses += charges;
                } else {
                    userDataMap.set(username, {
                        appointmentCount: 0, // Initialize appointment count
                        totalIncome: 0, // Initialize total income
                        totalExpenses: charges,
                    });
                }
            });

            const formattedData = Array.from(userDataMap).map(([username, data]) => ({
                username,
                appointmentCount: data.appointmentCount,
                totalIncome: data.totalIncome,
                totalExpenses: data.totalExpenses,
                netEarnings: data.totalIncome - data.totalExpenses, // Calculate net earnings
            }));

            setUserData(formattedData);
        };

        fetchData();
    }, []);

    // Sorting Functions
    const sortData = (column) => {
        if (column === sortColumn) {
            // Toggle sorting order if clicking the same column
            setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        } else {
            // Default to ascending order when clicking a new column
            setSortColumn(column);
            setSortOrder('asc');
        }
    };

    const getSortIcon = (column) => {
        if (column === sortColumn) {
            return sortOrder === 'asc' ? '▲' : '▼';
        }
        return '';
    };

    const sortedUserData = [...userData];

    if (sortColumn) {
        sortedUserData.sort((a, b) => {
            if (sortOrder === 'asc') {
                return a[sortColumn] - b[sortColumn];
            } else {
                return b[sortColumn] - a[sortColumn];
            }
        });
    }

    return (
        <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg">
            <div class="p-2" style={{ textAlign: "start", paddingBottom: "5px" }}>
                {/* <span class="btn bg-gradient-secondary btn-sm mb-0 mr-2" style={{ color: "white" }} >&nbsp; Download Excel</span> */}
                <Link to="/admin_panel"><img src="/images/menubutton.png" class="menu-toggle" alt="Responsive image" style={{ height: "2rem" }} /></Link>

            </div>
            <div className="">
                <div className="p-2">
                    <div className="card">
                        <div className="card-header pb-0 px-3">
                            <h6 className="mb-2">My Business</h6>
                        </div>
                        <div>
                            <div className="card">
                                <div class="table-responsive fix-width scroll-inner">
                                    <table class="table table-bordered table-hover">
                                        <thead class="thead-dark" style={{ position: "sticky", top: "0" }}>
                                            <tr style={{ textAlign: "left" }}>
                                                <th
                                                    className="col ps-2"
                                                    onClick={() => sortData('username')}
                                                >
                                                    Username {getSortIcon('username')}
                                                </th>
                                                {/* <th
                                                    className="btn btn-link p-0" style={{ fontSize: "11px", top: "7px" }}
                                                    onClick={() => sortData('appointmentCount')}
                                                >
                                                    Appointments Booked {getSortIcon('appointmentCount')}
                                                </th> */}
                                                <th
                                                    className="col" style={{ fontSize: "11px", cursor: "pointer" }}
                                                    onClick={() => sortData('totalIncome')}
                                                >
                                                    Total Income {getSortIcon('totalIncome')}
                                                </th>
                                                <th
                                                    className="col ps-2" style={{ fontSize: "11px", cursor: "pointer" }}
                                                    onClick={() => sortData('totalExpenses')}
                                                >
                                                    Total Expenses {getSortIcon('totalExpenses')}
                                                </th>
                                                <th
                                                    className="" style={{ fontSize: "11px", cursor: "pointer" }}
                                                    onClick={() => sortData('netEarnings')}
                                                >
                                                    Net Earnings {getSortIcon('netEarnings')}
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody style={{ textAlign: "left" }}>
                                            {sortedUserData.map(({ username, appointmentCount, totalIncome, totalExpenses, netEarnings }, index) => (
                                                <tr key={index}>
                                                    <td>
                                                        <a href={username} target="_blank" rel="noopener noreferrer">{username}</a>
                                                    </td>
                                                    {/* <td>{appointmentCount}</td> */}
                                                    <td>{totalIncome}</td>
                                                    <td>{totalExpenses}</td>
                                                    <td>{netEarnings}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main >
    );
}

export default MyBusiness;





