import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import "./style.css";
import { useNavigate, Link, useParams } from "react-router-dom"
import Select from 'react-select';
import NumberCounter from 'number-counter';
import FooterMain from "./FooterMain";

export const Testo = () => {
    const [count, setCount] = useState(0);
    useEffect(() => {
        window.scrollTo(0, 0);
        for (let count = 0; count <= 500; count++) {
            setTimeout(() => {
                setCount(count)
            }, 1000);
        }
    }, []);

    const [selectedImage, setSelectedImage] = useState(null);


    const tipsImages = [
        { title: "", subtitle: "Punjabi", image_url: "/outfit/Rectangle 177.png", description: "If you are a bride from Punjab it would be best to dress up in red or other bright shades. This is to be paired with the traditional chudha and dangling kalire. You can complete your outfit with small accessories of your choice." },
        { title: "", subtitle: "Kashmiri", image_url: "/outfit/Rectangle 178.png", description: " As a Kashmiri bride, ace the outfit game by dressing up in a heavy lehenga of a vibrant color such as red, gold, or green- colors of prosperity and happiness. " },
        { title: "", subtitle: "Tamil", image_url: "/outfit/Rectangle 179.png", description: "An exquisite Kanjeevaram sari is the way to go if you are from Tamil Nadu. The Madisar style of sari draping would be recommended. Embrace pure grace by flaunting your sari which showcases varied color combinations and a thick golden border. Apart from the heavy jewelry in use, Make sure to decorate your hair with flowers- nobody ever complained about elegance with a tinge of fragrance." },
        { title: "", subtitle: "Rajasthan", image_url: "/outfit/Rectangle 180.png", description: "An your wedding day, a heavy ghagra choli decked with zari work comes second to no outfit if you are a Rajasthani bride. Complete your outfit with a wide array of jewelry, and shine throughout your big day." },
        { title: "", subtitle: "Bengali", image_url: "/outfit/Rectangle 181.png", description: "If you are a Bengali bride, a red or maroon sari laden with intricate zari work would be quite the eyecatcher. Your hands and feet are to be decorated with Mahawar, and red and white dots that will extend from your forehead to chin. A white headgear will become the finishing touch in your stunning outfit." },
        { title: "", subtitle: "Maharashtrian", image_url: "/outfit/Rectangle 182.png", description: "The most unique element of your outfit as a Maharashtrian bride is the Mundavalya (stringed pearls tied horizontally across the forehead)- something that will be common to the outfits of both your groom and yourself. Do not forget to pair this with bangles that are red and green in color." },
        { title: "", subtitle: "Parsi", image_url: "/outfit/Rectangle 183.png", description: "The key to looking your best as a Parsi bride is to follow the “less is more” approach. A simple white/ cream coloured silk or chiffon Gara would work like magic when paired with minimalistic jewelry such as a necklace and bangles." },

    ]
    const handleImageClick = (index) => {
        setSelectedImage(index);
    };


    const leftPartStyle = {
        flex: 1,
        border: "none",
        boxSizing: "border-box",
        // backgroundColor: "#7d6853",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    };

    return (
        <div>
            <section>
                <div class="navi" style={{ backgroundColor: "#ec5d69" }}>
                    <input type="checkbox" id="nav-check" />
                    <div class="nav-header">
                        <div class="nav-title">
                            <Link to="/"> <img src="/bridal/youvati_white_logo.png" alt="" style={{ height: "22px", marginLeft: "1rem" }} /></Link>
                        </div>
                    </div>
                    <div class="nav-btn">
                        <label for="nav-check">
                            <span></span>
                            <span></span>
                            <span></span>
                        </label>
                    </div>

                    <div class="nav-links" id="top">
                        <a href="#portfolio" class="linkcolor" style={{ color: "white" }}>Bridal</a>
                        <span class="line" style={{ color: "white" }}>|</span>
                        <a href="#portfolio" class="linkcolor" style={{ color: "white" }}>Why Choose Us</a>
                        <span class="line" style={{ color: "white" }}>|</span>
                        <a href="#partners" class="linkcolor" style={{ color: "white" }}>For Partners</a>

                        <span class="line" style={{ color: "white" }}>|</span>
                        <a href="#reachtous" class="linkcolor" style={{ color: "white" }}>Contact Us</a>

                    </div>
                </div>
            </section>

            <div class="row">
                <div class="col" style={{ background: "", }}>
                    <h1>Find the Perfect Beauty Professional Next Door!</h1>
                    <p style={{ padding: "2rem", maxWidth: "33rem", minWidth: "1rem" }}>From makeup artists to hairstylists, we feature a range of experts with varying skills and styles. Each artist has their own profile, which showcases their portfolio, services, and pricing. You can also read reviews from previous clients to help you make an informed decision.</p>
                    <button class="" style={{ border: "2px solid #212529", backgroundColor: "#000", color: "#f8f9fa", padding: "6px 19px", fontSize: "16px", cursor: "pointer", fontWeight: "700", borderRadius: "50px", marginBottom: "2rem" }}>Download</button>
                </div>
                <div class="col" style={{ background: "red", height: "10rem", width: "645px", display: "flex", justifyContent: "center", alignItems: "center", border: "black" }}>
                    <h1>One</h1>
                </div>
                <div class="w-100 d-none d-md-block"></div>
                {/* <div class="col" style={{ background: "blue", height: "10rem" }}>one</div>
                <div class="col" style={{ background: "green", height: "10rem", width: "645px", display: "flex", justifyContent: "center", alignItems: "center", border: "black" }}>
                    <h1>Two</h1>
                </div> */}
            </div>

            <div class="row">
                {/* <div class="col" style={{ background: "", }}>
                    <h1>Find the Perfect Beauty Professional Next Door!</h1>
                    <p style={{ padding: "2rem", maxWidth: "33rem", minWidth: "1rem" }}>From makeup artists to hairstylists, we feature a range of experts with varying skills and styles. Each artist has their own profile, which showcases their portfolio, services, and pricing. You can also read reviews from previous clients to help you make an informed decision.</p>
                    <button class="" style={{ border: "2px solid #212529", backgroundColor: "#000", color: "#f8f9fa", padding: "6px 19px", fontSize: "16px", cursor: "pointer", fontWeight: "700", borderRadius: "50px", marginBottom: "2rem" }}>Download</button>
                </div>
                <div class="col" style={{ background: "red", height: "10rem", width: "645px", display: "flex", justifyContent: "center", alignItems: "center", border: "black" }}>
                    <h1>One</h1>
                </div> */}
                <div class="w-100 d-none d-md-block"></div>
                <div class="col" style={{ background: "blue", height: "10rem" }}>one</div>
                <div class="col" style={{ background: "green", height: "10rem", width: "645px", display: "flex", justifyContent: "center", alignItems: "center", border: "black" }}>
                    <h1>Two</h1>
                </div>
            </div>

        </div >
    );
};



