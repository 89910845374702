import React from 'react'

function Tests() {
    var arr = [{
        "id": "0001",
        "type": "donut",
        "name": "Cake",
        "ppu": 0.55,
        "batters":
        {
            "batter":
                [
                    { "id": "1001", "type": "Regular" },
                    { "id": "1002", "type": "Chocolate" },
                    { "id": "1003", "type": "Blueberry" },
                    { "id": "1004", "type": "Devil's Food" }
                ]
        },
        "topping":
            [
                {
                    "id": "5001", "type": "None", "tops":
                        [
                            { "id": "5001", "type": "None" },
                            { "id": "5002", "type": "Glazed" },
                            { "id": "5005", "type": "Sugar" },
                            { "id": "5007", "type": "Powdered Sugar" },
                            { "id": "5006", "type": "Chocolate with Sprinkles" },
                            { "id": "5003", "type": "Chocolate" },
                            { "id": "5004", "type": "Maple" },

                        ]
                },

            ]
    }]
    return (
        <>
            {arr.map((item, index) => (
                <>
                    <p>{item.type}</p>
                    <h1>{item.topping.map((e) => (
                        <><h1>{e.id}</h1>
                            <p>{e.tops.map((itm) => (
                                <><p>{itm.id}</p></>
                            ))}</p></>
                    ))}</h1>
                </>
            ))}
        </>
    )
}

export default Tests