import React, { useState, useEffect } from 'react';
import AdminPanel from './AdminPanel';
import Swal from 'sweetalert2'
import { useNavigate, Link, useParams } from 'react-router-dom';
import db from "./Firebase";
import { storage } from './Firebase';
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import ViewAppData from './ViewAppData';
import Multiselect from 'multiselect-react-dropdown';


function PersonalNotification() {
    const navigate = useNavigate();
    const moment = extendMoment(Moment);
    const initialFormStateFeedback = { id: '', message: '', seen: [], image_url: '', view: '', module: '', link: '', status: '', personal: '', created_date: new Date() }
    const [submit, setSubmit] = useState(initialFormStateFeedback)
    const [data, setData] = useState([]);
    const [editID, setEditID] = useState("");
    const [edit, setEdit] = useState(false);
    const [ShowProgress, setShowProgress] = useState(false);
    const [show, setshow] = useState(true);
    const [progresspercent, setProgresspercent] = useState(0);
    const [searchQuery, setSearchQuery] = useState("");

    const [options, setOptions] = useState([
        { name: 'Online' },
        { name: 'Aurangabad' },
        { name: 'Bathinda' },
        { name: 'Ghaziabad' },
        { name: 'Meerut' },
        { name: 'Mansa' },
        { name: 'Patiala' }
    ]);

    const [selectedIds, setSelectedIds] = useState([]);

    useEffect(() => {
        console.log(selectedIds);
    }, [selectedIds]);

    const handleCheckboxChange = (id) => {
        console.log(id)
        if (selectedIds.includes(id)) {
            // If the ID is already in the array, remove it
            setSelectedIds(selectedIds.filter(selectedId => selectedId !== id));
        } else {
            // If the ID is not in the array, add it
            setSelectedIds([...selectedIds, id]);
        }
    };

    const [selectedValue, setSelectedValue] = useState([]);


    const onSelect = (selectedList, selectedItem) => {
        // Handle selection
        // ...
        setSelectedValue(selectedList);
    }

    const onRemove = (selectedList, removedItem) => {
        // Handle removal
        // ...
        setSelectedValue(selectedList);
    }



    useEffect(() => {
        const storedUsername = localStorage.getItem("email");
        const storedPassword = localStorage.getItem("password");
        const storedRemember = localStorage.getItem("remember");

        if (storedRemember === "false" || !storedUsername || !storedPassword) {
            navigate('/signin');
        }
    }, []);



    useEffect(() => {
        db.collection("personal_details").orderBy("created_date", "desc").onSnapshot((snapshot) => {

            setData(
                snapshot.docs.map((doc) => ({
                    id: doc.id,
                    data: doc.data(),
                }))
            );
        });


    }, []);

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    // Function to filter data based on the search query
    const filteredData = data.filter((item) => {
        // Add null checks before accessing properties
        const user_name = item.data.username ? item.data.username.toLowerCase() : '';
        const full_name = item.data.full_name ? item.data.full_name.toLowerCase() : '';
        const phone_no = item.data.phone_no ? item.data.phone_no.toLowerCase() : '';
        const query = searchQuery.toLowerCase();

        return (
            user_name.includes(query) ||
            full_name.includes(query) ||
            phone_no.includes(query)
        );
    });



    const handleChange = (e) => {
        const { id, value } = e.target
        setSubmit({ ...submit, [id]: value })
    }


    const changeStatusAdd = () => {
        setEdit(false)
        openModalShow();
    }


    const openModalShow = () => {
        // alert("ooo")
        window.openModelshow();
    }

    const closeModel = () => {
        window.openModelNew();
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        console.log(submit);
        if (selectedIds.length === 0) {
            alert("please Select at least one user")
        } else {
            notifySelectedUsers(submit.message, submit.tag)
        }
        closeModel()

    }

    const notifySelectedUsers = (msg, tag) => {
        // Iterate over selectedIds array and notify each user
        selectedIds.forEach((id) => {
            // Call the notifyApp function for each selected ID
            notifyApp(msg, tag, id);
        });
        const Toast = Swal.mixin({
            toast: true,
            background: '#69aba6',
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })

        Toast.fire({
            icon: 'success',
            title: 'Sent Successfully'
        })
    };

    const notifyApp = (msg, tag, id) => {
        // Call the user's CollectionReference to add a new user
        db.collection("notifications").add({
            'image_url': '',
            'view': false,
            'module': 'workshop',
            'message': msg,
            'link': tag,
            'seen': [],
            'status': 1,
            "personal": id, // Use the provided ID
            'created_date': new Date()
        }).then(() => {
            console.log("Notified user with ID:", id);
        }).catch((error) => {
            console.error("Error notifying user with ID:", id, error);
        });
    };



    return (
        <>
            <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">

                <div class="p-2" style={{ paddingBottom: "5px", display: "flex", justifyContent: "space-between" }}>
                    {/* <span class="btn bg-gradient-secondary btn-sm mb-0 mr-2" style={{ color: "white" }} >&nbsp; Download Excel</span> */}
                    <Link to="/admin_panel"><img src="/images/menubutton.png" class="menu-toggle" alt="Responsive image" style={{ height: "2rem" }} /></Link>
                    <a class="btn bg-gradient-primary btn-sm mb-0" style={{ color: "white" }} onClick={() => changeStatusAdd()}>+&nbsp; Add</a>
                </div>

                <div class="p-2">
                    <div class="card-header pb-0 px-3" style={{ display: "flex", justifyContent: "center" }}>
                        <h6 class="mb-2">Personal Notification || {data?.length}</h6>

                    </div>
                    <input
                        type="text"
                        className="form-control form-control-sm"
                        placeholder="Search by Username, Phone, full name"
                        value={searchQuery}
                        onChange={handleSearchChange}
                    />


                    <div class="card">
                        <div class="table-responsive fix-width scroll-inner">
                            <table class="table table-bordered table-hover">
                                <thead class="thead-dark" style={{ position: "sticky", top: "0" }}>
                                    <tr >
                                        <th class="col">Image</th>
                                        <th class="col ps-2">Select</th>
                                        <th class="col ps-2">Full Name</th>
                                        <th class="text-center col">Phone No</th>
                                        <th class="text-center col">username</th>
                                        <th class="text-center col">Created Date</th>
                                    </tr>
                                </thead>
                                <tbody >
                                    {filteredData?.map(({ id, data }, index) => (
                                        <>
                                            <tr key={index}>
                                                <td>
                                                    <div class="d-flex px-2 py-1">
                                                        <div style={{ width: "7rem", marginRight: "13px" }}>
                                                            <img src={data.image} class="me-3" style={{ height: "4rem" }} />
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <input
                                                        type="checkbox"
                                                        style={{ display: "block" }}
                                                        checked={selectedIds.includes(id)}
                                                        onChange={() => handleCheckboxChange(id)}
                                                    />
                                                </td>
                                                <td>
                                                    <p class="text-xs font-weight-bold mb-0">{data.full_name}</p>
                                                    {/* <p class="text-xs text-secondary mb-0">Organization</p> */}
                                                </td>
                                                <td class="align-middle text-center text-sm">
                                                    <p class="text-xs font-weight-bold mb-0">{data.phone_no}</p>
                                                </td>
                                                <td class="align-middle text-center text-sm">
                                                    <p class="text-xs font-weight-bold mb-0">{data.username}</p>
                                                </td>
                                                <td class="align-middle text-center text-sm">
                                                    <span class="text-secondary text-xs font-weight-normal">{moment(data.created_date.toDate().toISOString()).format("MM/DD/YY, h:mm a")}</span>
                                                </td>



                                            </tr>
                                        </>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

            </main>






            <div class="col-md-12">
                <div class="myId modal fade" id="" tabindex="-1" role="dialog" aria-labelledby="modal-form" aria-hidden="true" data-backdrop="static">
                    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div class="modal-content">
                            <div class="modal-body">


                                <div class="">
                                    <form onSubmit={handleSubmit}>
                                        <div class="">
                                            <div class="" style={{ textAlign: "center", }}><span style={{ color: "black", fontSize: "25px", fontWeight: "700", textTransform: "uppercase" }}>Personal Notification</span></div>
                                            <div class="" style={{ textAlign: "center", color: "black", paddingTop: "10px" }}>
                                            </div>

                                            <textarea style={{
                                                width: "100%",
                                                height: "15px",
                                                padding: "12px 20px",
                                                boxSizing: "border-box",
                                                border: "2px solid #ccc",
                                                borderRadius: "4px",
                                                backgroundColor: "#f8f8f8",
                                                fontSize: "16px",
                                                resize: "none"
                                            }} class="w3l_text_box" id="message" placeholder="Write a message..." name="Message" onChange={handleChange} value={submit.message} required />

                                            <div class="">
                                                <div class="row">
                                                    <div class="col-md-8">
                                                        <select class="form-control" id="tag" name="tag" onChange={handleChange} value={data.tag} required>
                                                            <option value="">Select Tag</option>
                                                            <option value="Advertise">Advertise</option>
                                                            <option value="WorkshopList">WorkshopList</option>
                                                            <option value="Courses">Courses</option>
                                                            <option value="CreatePoster">CreatePoster</option>
                                                            <option value="VisitingCard">VisitingCard</option>
                                                            <option value="PortfolioPreview">PortfolioPreview</option>
                                                            <option value="AppointmentList">AppointmentList</option>
                                                            <option value="Enquiries">Enquiries</option>
                                                            <option value="UnderReviewPage">UnderReviewPage</option>
                                                            <option value="noti">Notification</option>
                                                        </select>
                                                    </div>

                                                </div>
                                            </div>



                                            <div class="modal-footer">
                                                {/* {submit.id == "" ? <></> : <a class="btn bg-gradient-danger btn-sm mb-0" style={{ color: "white" }} onClick={() => deleteData(submit.id)}>Delete</a>} */}
                                                <a class="btn bg-gradient-info btn-sm mb-0" style={{ color: "white" }} onClick={closeModel}>Close</a>
                                                <button type='submit' class="btn bg-gradient-danger btn-sm mb-0" style={{ backgroundColor: "green", color: "white" }}>{edit ? "Update" : "Submit"}</button>
                                            </div>


                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >

        </>
    )
}

export default PersonalNotification;
