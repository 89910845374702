import React, { useState, useEffect } from 'react';
import AdminPanel from './AdminPanel';
import { useNavigate, Link, useParams } from 'react-router-dom';
import Swal from 'sweetalert2'
import db from "./Firebase";
import { storage } from './Firebase';
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import ViewAppData from './ViewAppData';

function MentorData() {
    const initialFormStateFeedback = { id: '', name: '', email: '', phone_no: '', pincode: '', city: '', state: '', expert_in: '', mentorship_via: '', about_yourself: '', address: '', country: '', image: '', uid: '', created_date: new Date() }
    const [submit, setSubmit] = useState(initialFormStateFeedback)
    const [view, setView] = useState(false);
    const [data, setData] = useState([]);
    const [editID, setEditID] = useState("");
    const [edit, setEdit] = useState(false);
    const [ShowProgress, setShowProgress] = useState(false);
    const [show, setshow] = useState(true);
    const [progresspercent, setProgresspercent] = useState(0);

    const handleChange = (e) => {
        const { id, value } = e.target
        setSubmit({ ...submit, [id]: value })
    }

    const open = () => {
        setView(true);
    }

    const changeStatus = (data, id) => {
        setEdit(true)
        setEditID(id)
        setSubmit({ id: id, name: data.name, email: data.email, phone_no: data.phone_no, pincode: data.pincode, city: data.city, state: data.state, expert_in: data.expert_in, mentorship_via: data.mentorship_via, about_yourself: data.about_yourself, address: data.address, country: data.country, image: data.image, uid: data.uid, });
        openModalShow();
    }

    const addNew = () => {
        setEdit(false)
        openModalShow();
        setSubmit({ id: '', name: '', email: '', phone_no: '', pincode: '', city: '', state: '', expert_in: '', mentorship_via: '', about_yourself: '', address: '', country: '', image: '', uid: '', created_date: new Date() });
    }

    const openModalShow = () => {
        // alert("ooo")
        window.openModelshow();
    }

    const closeModel = () => {
        window.openModelNew();
    }


    const handleSubmit = (e) => {
        console.log("callingggggg")
        e.preventDefault()
        if (edit) {
            console.log("edit")
            if (e.target[0]?.files[0] == null) {
                db.collection("mentors_list").doc(editID).update({
                    'name': submit.name,
                    'email': submit.email,
                    'phone_no': submit.phone_no,
                    'pincode': submit.pincode,
                    'city': submit.city,
                    'state': submit.state,
                    'country': submit.country,
                    'expert_in': submit.expert_in,
                    'mentorship_via': submit.mentorship_via,
                    'about_yourself': submit.about_yourself,
                    'address': submit.address,
                    'uid': submit.uid,
                    'authorization_code': "",
                    'image': submit.image,
                    'created_date': new Date(),
                }).then(() => {
                    console.log('Updated Successfully');
                    const Toast = Swal.mixin({
                        toast: true,
                        background: '#69aba6',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', Swal.stopTimer)
                            toast.addEventListener('mouseleave', Swal.resumeTimer)
                        }
                    })

                    Toast.fire({
                        icon: 'success',
                        title: 'Updated Successfully'
                    })
                    closeModel();

                    setSubmit({ id: '', name: '', email: '', phone_no: '', pincode: '', city: '', state: '', expert_in: '', mentorship_via: '', about_yourself: '', address: '', country: '', image: '', uid: '', created_date: new Date() })
                })
            } else {
                const file = e.target[0]?.files[0]
                // console.log(file)
                if (!file) return;
                const storageRef = ref(storage, `files/${file.name}`);
                const uploadTask = uploadBytesResumable(storageRef, file);
                setshow(false)

                uploadTask.on("state_changed",
                    (snapshot) => {
                        const progress =
                            Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                        setProgresspercent(progress);
                    },
                    (error) => {
                        alert(error);
                    },
                    () => {

                        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                            // setImgUrl(downloadURL)
                            db.collection("mentors_list").doc(editID).update({
                                'name': submit.name,
                                'email': submit.email,
                                'phone_no': submit.phone_no,
                                'pincode': submit.pincode,
                                'city': submit.city,
                                'state': submit.state,
                                'country': submit.country,
                                'expert_in': submit.expert_in,
                                'mentorship_via': submit.mentorship_via,
                                'about_yourself': submit.about_yourself,
                                'address': submit.address,
                                'uid': submit.uid,
                                'authorization_code': "",
                                'image': downloadURL,
                                'created_date': new Date(),
                            }).then(() => {
                                console.log('Updated Successfully');
                                const Toast = Swal.mixin({
                                    toast: true,
                                    background: '#69aba6',
                                    position: 'top-end',
                                    showConfirmButton: false,
                                    timer: 3000,
                                    timerProgressBar: true,
                                    didOpen: (toast) => {
                                        toast.addEventListener('mouseenter', Swal.stopTimer)
                                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                                    }
                                })

                                Toast.fire({
                                    icon: 'success',
                                    title: 'Updated Successfully'
                                })
                                closeModel();
                                setshow(true)
                                setSubmit({ type: '', product_name: '', brand_name: '', quantity: '', itemcount: '', price: '', seller: '', authorization_code: '', created_date: new Date() })
                            })
                        });
                    }
                );
            }

        } else {
            if (e.target[0]?.files[0] == null) {
                db.collection("mentors_list").add({
                    'name': submit.name,
                    'email': submit.email,
                    'phone_no': submit.phone_no,
                    'pincode': submit.pincode,
                    'city': submit.city,
                    'state': submit.state,
                    'country': submit.country,
                    'expert_in': submit.expert_in,
                    'mentorship_via': submit.mentorship_via,
                    'about_yourself': submit.about_yourself,
                    'address': submit.address,
                    'uid': submit.uid,
                    'authorization_code': "",
                    'image': submit.image,
                    'created_date': new Date(),
                }).then(() => {
                    console.log('Created Successfully');
                    const Toast = Swal.mixin({
                        toast: true,
                        background: '#69aba6',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', Swal.stopTimer)
                            toast.addEventListener('mouseleave', Swal.resumeTimer)
                        }
                    })

                    Toast.fire({
                        icon: 'success',
                        title: 'Created Successfully'
                    })
                    closeModel();

                    setSubmit({ id: '', name: '', email: '', phone_no: '', pincode: '', city: '', state: '', expert_in: '', mentorship_via: '', about_yourself: '', address: '', country: '', image: '', uid: '', created_date: new Date() })
                })
            } else {
                const file = e.target[0]?.files[0]
                // console.log(file)
                if (!file) return;
                const storageRef = ref(storage, `files/${file.name}`);
                const uploadTask = uploadBytesResumable(storageRef, file);
                setshow(false)

                uploadTask.on("state_changed",
                    (snapshot) => {
                        const progress =
                            Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                        setProgresspercent(progress);
                    },
                    (error) => {
                        alert(error);
                    },
                    () => {

                        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                            // setImgUrl(downloadURL)
                            db.collection("mentors_list").add({
                                'name': submit.name,
                                'email': submit.email,
                                'phone_no': submit.phone_no,
                                'pincode': submit.pincode,
                                'city': submit.city,
                                'state': submit.state,
                                'country': submit.country,
                                'expert_in': submit.expert_in,
                                'mentorship_via': submit.mentorship_via,
                                'about_yourself': submit.about_yourself,
                                'address': submit.address,
                                'uid': submit.uid,
                                'authorization_code': "",
                                'image': downloadURL,
                                'created_date': new Date(),
                            }).then(() => {
                                console.log('Created Successfully');
                                const Toast = Swal.mixin({
                                    toast: true,
                                    background: '#69aba6',
                                    position: 'top-end',
                                    showConfirmButton: false,
                                    timer: 3000,
                                    timerProgressBar: true,
                                    didOpen: (toast) => {
                                        toast.addEventListener('mouseenter', Swal.stopTimer)
                                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                                    }
                                })

                                Toast.fire({
                                    icon: 'success',
                                    title: 'Created Successfully'
                                })
                                closeModel();
                                setshow(true)
                                setSubmit({ type: '', product_name: '', brand_name: '', quantity: '', itemcount: '', price: '', seller: '', authorization_code: '', created_date: new Date() })
                            })
                        });
                    }
                );
            }
        }

    }

    const deleteData = (id) => {
        closeModel();
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                db.collection("mentors_list").doc(id).delete().then(() => {
                    console.log('Deleted Successfully');
                    setSubmit({ id: '', name: '', email: '', phone_no: '', pincode: '', city: '', state: '', expert_in: '', mentorship_via: '', about_yourself: '', address: '', country: '', image: '', uid: '', created_date: new Date() })
                    closeModel();
                    Swal.fire(
                        'Deleted!',
                        'Data has been deleted.',
                        'success'
                    )
                })

            }
        })

    }

    useEffect(() => {
        db.collection("mentors_list").orderBy("created_date", "desc").onSnapshot((snapshot) => {

            setData(
                snapshot.docs.map((doc) => ({
                    id: doc.id,
                    data: doc.data(),
                }))
            );
        });

    }, []);

    return (
        <>
            <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">

                <ViewAppData />


                <div class="container-fluid py-4">
                    <div style={{ textAlign: "end", paddingBottom: "5px" }}>
                        <a class="btn bg-gradient-primary btn-sm mb-0" style={{ color: "white" }} onClick={() => addNew()}>+&nbsp; Add New Mentor</a>
                        <Link to="/admin_panel" class="btn bg-gradient-primary btn-sm mb-0 ml-2" style={{ color: "white" }} >&nbsp; Go Back</Link>
                    </div>
                    <div class="card">
                        <div class="table-responsive fix-width scroll-inner">
                            <table class="table align-items-center mb-0">
                                <thead>
                                    <tr>
                                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Mentor Name</th>
                                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Expert in</th>
                                        <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Mode</th>
                                        <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Phone No</th>
                                        <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Action</th>
                                        {/* <th class="text-secondary opacity-7"></th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {data?.map(({ id, data }, index) => (
                                        <>
                                            <tr>
                                                <td>
                                                    <div class="d-flex px-2 py-1">
                                                        <div>
                                                            <img src={data.image} class="avatar avatar-sm me-3" />
                                                        </div>
                                                        <div class="d-flex flex-column justify-content-center">
                                                            <h6 class="mb-0 text-xs">{data.name}</h6>
                                                            <p class="text-xs text-secondary mb-0">{data.email}</p>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <p class="text-xs font-weight-bold mb-0">{data.expert_in}</p>
                                                    {/* <p class="text-xs text-secondary mb-0">Organization</p> */}
                                                </td>
                                                <td class="align-middle text-center text-sm">
                                                    <span class="badge badge-sm badge-success">{data.mentorship_via}</span>
                                                </td>
                                                <td class="align-middle text-center">
                                                    <span class="text-secondary text-xs font-weight-normal">{data.phone_no}</span>
                                                </td>
                                                <td class="align-middle text-center" onClick={() => changeStatus(data, id)}>
                                                    <span class="text-info text-xs font-weight-normal" style={{ cursor: "pointer" }}>Edit</span>
                                                </td>

                                            </tr>
                                        </>
                                    ))}

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

            </main>

            <div class="col-md-4">
                <div class="myId modal fade" id="" tabindex="-1" role="dialog" aria-labelledby="modal-form" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered" role="document">
                        <div class="modal-content">
                            <div class="modal-body p-0">
                                <div class="card card-plain">
                                    {/* <div class="card-header pb-0 text-left">
                                        <h5 class="pb-4" style={{ fontSize: "13px", fontWeight: "bolder" }}>Enter your email and password to sign in</h5>
                                    </div> */}
                                    <form onSubmit={handleSubmit}>
                                        <div class="card-body">
                                            <div class="modal-body">

                                                <div class="row">

                                                    <div class="col-md-12">
                                                        <span>Mentor Image :</span>
                                                        {submit.image == "" ? <></> : <img src={submit.image} class="avatar avatar-sm me-3" />}
                                                        <div class="">
                                                            {edit ? <input type="file" id="image" class="" onfocus="focused(this)" onfocusout="defocused(this)" placeholder="Image" /> : <input type="file" id="image" class="" onfocus="focused(this)" onfocusout="defocused(this)" placeholder="Image" required />}
                                                        </div>
                                                    </div>


                                                </div>
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <div class="input-group input-group-outline my-3">
                                                            <input type="text" id="name" class="form-control" onfocus="focused(this)" onfocusout="defocused(this)" onChange={handleChange} value={submit.name} placeholder="Mentor Name" required />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="input-group input-group-outline my-3">
                                                            <input type="email" id="email" class="form-control" onfocus="focused(this)" onfocusout="defocused(this)" onChange={handleChange} value={submit.email} placeholder="Email" required />
                                                        </div>
                                                    </div>
                                                </div>



                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <div class="input-group input-group-outline my-3">
                                                            <input type="number" id="phone_no" class="form-control" onfocus="focused(this)" onfocusout="defocused(this)" onChange={handleChange} value={submit.phone_no} placeholder="Phone Number" required />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="input-group input-group-outline my-3">
                                                            <input type="text" id="pincode" class="form-control" onfocus="focused(this)" onfocusout="defocused(this)" onChange={handleChange} value={submit.pincode} placeholder="Pincode" required />
                                                        </div>
                                                    </div>

                                                </div>


                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <div class="input-group input-group-outline my-3">
                                                            <input type="text" id="city" class="form-control" onfocus="focused(this)" onfocusout="defocused(this)" onChange={handleChange} value={submit.city} placeholder="City" required />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="input-group input-group-outline my-3">
                                                            <input type="text" id="state" class="form-control" onfocus="focused(this)" onfocusout="defocused(this)" onChange={handleChange} value={submit.state} placeholder="State" required />
                                                        </div>
                                                    </div>

                                                </div>

                                                <div class="row pb-3">
                                                    <div class="col-md-6">
                                                        <select class="form-control" id="expert_in" onChange={handleChange} value={submit.expert_in} required>
                                                            <option value="">Expert In</option>
                                                            <option value="Business Setup">Business Setup</option>
                                                            <option value="Growth">Growth</option>
                                                            <option value="New product">New product</option>
                                                            <option value="Any For Beauty Parlour Needs">Any For Beauty Parlour Needs</option>
                                                        </select>

                                                    </div>
                                                    <div class="col-md-6">
                                                        <select class="form-control" id="mentorship_via" onChange={handleChange} value={submit.mentorship_via} required>
                                                            <option value="">Mentorship Via</option>
                                                            <option value="Call">Call</option>
                                                            <option value="Video">Video</option>
                                                            <option value="Email">Email</option>
                                                            <option value="In person in your city">In person in your city</option>
                                                            <option value="Any">Any</option>
                                                        </select>
                                                    </div>
                                                </div>

                                                <div class="input-group input-group-dynamic">
                                                    <textarea class="form-control" id="about_yourself" rows="5" placeholder="Say a few words about mentor" onChange={handleChange} value={submit.about_yourself} spellcheck="false"></textarea>
                                                </div>
                                            </div>
                                            <div class="modal-footer">

                                                {submit.id == "" ? <></> : <a class="btn bg-gradient-danger btn-sm mb-0" style={{ color: "white" }} onClick={() => deleteData(submit.id)}>Delete</a>}
                                                <a class="btn bg-gradient-info btn-sm mb-0" style={{ color: "white" }} onClick={closeModel}>Close</a>
                                                <button type='submit' class="btn bg-gradient-danger btn-sm mb-0" style={{ backgroundColor: "green", color: "white" }}>{edit ? "Update" : "Submit"}</button>
                                                {/* <input type="submit" class="btn bg-gradient-success btn-sm mb-0" style={{ backgroundColor: "green" }} value="Submit" onClick={() => handleSubmit()} /> */}
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MentorData;