import React, { useState, useEffect } from 'react';
import * as XLSX from 'xlsx';
import DataTable from 'react-data-table-component';
import { useNavigate, Link, useParams } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2'
import db from "./Firebase";
import { storage } from './Firebase';
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";


function UploadLoanApplication() {
    let params = useParams();
    const navigate = useNavigate();
    const [columns, setColumns] = useState([]);
    const [data, setData] = useState([]);
    const [ShowProgress, setShowProgress] = useState(false);




    const initialFormStateFeedback = { type: '', product_name: '', brand_name: '', quantity: '', itemcount: '', price: '', seller: '', authorization_code: '', created_date: new Date() }
    const [submit, setSubmit] = useState(initialFormStateFeedback)

    const [show, setshow] = useState(true);

    const [showExcel, setshowExcel] = useState(false);

    const [imgUrl, setImgUrl] = useState(null);
    const [progresspercent, setProgresspercent] = useState(0);




    const processData = dataString => {
        const dataStringLines = dataString.split(/\r\n|\n/);
        const headers = dataStringLines[0].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);

        const list = [];
        for (let i = 1; i < dataStringLines.length; i++) {
            const row = dataStringLines[i].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);
            if (headers && row.length == headers.length) {
                const obj = {};
                for (let j = 0; j < headers.length; j++) {
                    let d = row[j];
                    if (d.length > 0) {
                        if (d[0] == '"')
                            d = d.substring(1, d.length - 1);
                        if (d[d.length - 1] == '"')
                            d = d.substring(d.length - 2, 1);
                    }
                    if (headers[j]) {
                        obj[headers[j]] = d;
                    }
                }

                // remove the blank rows
                if (Object.values(obj).filter(x => x).length > 0) {
                    list.push(obj);
                }
            }
        }

        // prepare columns list from headers
        const columns = headers.map(c => ({
            name: c,
            selector: c,
        }));

        setData(list);
        setColumns(columns);
        console.log(list)
    }

    const [showData, setShowData] = useState([])
    const [showData1, setShowData1] = useState([])


    // const upload = () => {
    //     // db.collection("consumables_data").add({ _cartList }).then(() => {
    //     //     console.log('Submitted Successfully');
    //     // })
    //     _cartList.map((e, index) => {
    //         db.collection("consumables_data").add(e).then(() => {
    //             console.log('Submitted Successfully');
    //         })
    //     })
    // }

    // handle file upload
    const handleFileUpload = e => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onload = (evt) => {
            /* Parse data */
            const bstr = evt.target.result;
            const wb = XLSX.read(bstr, { type: 'binary' });
            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            /* Convert array of arrays */
            const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
            processData(data);
        };
        reader.readAsBinaryString(file);
    }
    const [num, setNum] = useState(0);
    const submitdata = (e) => {
        setShowProgress(true);
        data.map((user, index) => {
            setNum(index + 1);
            db.collection("loan").add({
                'uid': '',
                'loan_id': user.loan_id,
                'applicant_date_of_birth': user.applicant_date_of_birth,
                'loan_type': user.loan_type,
                'gender': "Female",
                'loan_disbursement_status': user.loan_disbursement_status,
                'applicant_name': user.applicant_name,
                'applicant_phone': user.applicant_phone,
                'applicant_alternate_phone': user.applicant_alternate_phone,
                'applicant_marital_status': user.applicant_marital_status,
                'fathers_name': user.fathers_name,
                'mothers_name': user.mothers_name,
                'city': user.city,
                'state': user.state,
                'pincode': user.pincode,
                'full_address': user.full_address,
                'highest_education': user.highest_education,
                'business_type': user.business_type,
                'parlour_freelancer_name': user.parlour_freelancer_name,
                'parlour_address': user.parlour_address,
                'ownership_type': user.ownership_type,
                'working_since': user.working_since,
                'monthly_since': user.monthly_since,
                'bank_account_number': user.bank_account_number,
                'bank_name': user.bank_name,
                'ifsc_code': user.ifsc_code,
                'upi_id': user.upi_id,
                'loan_amount': user.loan_amount,
                'loan_disbursement_date': new Date(user.loan_disbursement_date),
                'have_you_taken_loan_in_past': user.have_you_taken_loan_in_past,
                'needGrant': user.needGrant,
                'purposeOfReason': user.purposeOfReason,
                'reasonEstimated': user.reasonEstimated,
                'repaymentModel': user.repaymentModel,
                'how_did_you_know_about_scheme': user.how_did_you_know_about_scheme,
                'aadhar_front': user.aadhar_front,
                'aadhar_back': user.aadhar_back,
                'pan': user.pan,
                'passbook': user.passbook,
                'parlour_photo': user.parlour_photo,
                'applicant_photo': user.applicant_photo,
                'error_message': user.error_message,
                'document_status': parseInt(user.document_status),
                'document_status_done_msg': user.document_status_done_msg,
                'document_status_pending_msg': user.document_status_pending_msg,
                'verification_status': parseInt(user.verification_status),
                'verification_status_done_msg': user.verification_status_done_msg,
                'verification_status_pending_msg': user.verification_status_pending_msg,
                'nbfc_status': parseInt(user.nbfc_status),
                'nbfc_status_done_msg': user.nbfc_status_done_msg,
                'nbfc_status_pending_msg': user.nbfc_status_pending_msg,
                'penny_check_status': parseInt(user.penny_check_status),
                'penny_check_status_done_msg': user.penny_check_status_done_msg,
                'penny_check_status_pending_msg': user.penny_check_status_pending_msg,
                'godrej_status': parseInt(user.godrej_status),
                'godrej_status_done_msg': user.godrej_status_done_msg,
                'godrej_status_pending_msg': user.godrej_status_pending_msg,
                'loan_disbursement_status': parseInt(user.loan_disbursement_status),
                'loan_disbursement_status_done_msg': user.loan_disbursement_status_done_msg,
                'loan_disbursement_status_pending_msg': user.loan_disbursement_status_pending_msg,
                'custom_msg_one': user.custom_msg_one,
                'custom_msg_two': user.custom_msg_two,
                'status': parseInt(user.status),
                'created_date': new Date(user.created_date),
                'updated_date': new Date(),
                // =======================================
            }).then(() => {
                console.log('Added Successfully');
                const Toast = Swal.mixin({
                    toast: true,
                    background: '#69aba6',
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer)
                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                    }
                })
                Toast.fire({
                    icon: 'success',
                    title: 'Data Added Successfully'
                })
            })
        })
    };

    const cancleAll = () => {
        setColumns([])
        setData([])
    }



    useEffect(() => {
        // alert(params.id)
    }, []);



    return (
        <>
            {/* <button onClick={upload}>Send</button> */}


            <div id="" class="m-3">
                {/* <div class="row p-1" style={{ float: "right" }}>
                    <a class="mr-3" href="/files/consumables.csv" download><button type="button" class="btn-sm" style={{ backgroundColor: "#337ab7", color: "white" }} onClick={() => setshowExcel(true)}>Download Sample For Consumables</button></a>
                </div>
                <br /> */}
                {/* <div style={{ textAlign: "end", paddingBottom: "5px" }}>
                    <a class="btn bg-gradient-primary btn-sm mb-0 mr-2" href="/files/consumables.csv" download style={{ color: "white" }} onClick={() => setshowExcel(true)}>+&nbsp; Download Sample For Consumables</a>
                    <Link to="/consumables_data" class="btn bg-gradient-info btn-sm mb-0" style={{ color: "white" }}>&nbsp; Go Back</Link>
                </div> */}
                <div class="card-body">
                    <div class="form-group justify-content-end row">
                        <div class="col-lg-12">
                            <div class="form-control dropzone dropzone-default dropzone-primary dz-clickable h-auto px-4 py-2" style={{ background: "aquamarine", textAlign: "center" }}>
                                <div class="dropzone-msg dz-message needsclick" style={{ borderStyle: "dashed", padding: "1rem", borderColor: "orange" }}>
                                    <input type="file" accept=".csv,.xlsx,.xls" onChange={handleFileUpload} />
                                    <span class="font-size-base"><i class="fas fa-cloud-upload-alt icon-md mr-1"></i>Drop files here or click.</span><br />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <DataTable
                    pagination
                    highlightOnHover
                    columns={columns}
                    data={data}
                />

                {data.length > 0 ? <> <div class="card-footer d-flex justify-content-end py-2">
                    <button class="btn btn-success mr-2" onClick={() => submitdata()}>Upload Data</button>
                    <div class="btn btn-secondary" role="button" onClick={() => cancleAll()}>Cancel</div>
                </div></> : null
                }

                {ShowProgress ? <><h2 style={{ background: "darkorange", padding: "1rem", textAlign: "center", paddingBottom: "1rem", color: "white" }}>{num == data.length ? "Data Uploaded" : "Uploading Data"} - {num}/{data.length}</h2></> : ""}
            </div>
        </>


    );
}
export default UploadLoanApplication;