import React, { useState, useEffect, useRef } from 'react';
import db from "./Firebase";
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import { firebase, auth } from './FirebaseLogin';

const Notification = () => {
    // const navigate = useNavigate();
    const notificationRef = useRef(null);
    const [showNotification, setShowNotification] = useState(false);
    const moment = extendMoment(Moment);

    const notifications = [
        {
            link: 'https://codepen.io/Golez/',
            image: 'https://c1.staticflickr.com/5/4007/4626436851_5629a97f30_b.jpg',
            message: 'James liked your post: "Pure css notification box"',
            time: '11/7 - 2:30 pm'
        },
        {
            link: 'https://codepen.io/Golez/',
            image: 'https://obamawhitehouse.archives.gov/sites/obamawhitehouse.archives.gov/files/styles/person_medium_photo/public/person-photo/amanda_lucidon22.jpg?itok=JFPi8OFJ',
            message: 'Annita liked your post: "Pure css notification box"',
            time: '11/7 - 2:13 pm'
        }, {
            link: 'https://codepen.io/Golez/',
            image: 'https://obamawhitehouse.archives.gov/sites/obamawhitehouse.archives.gov/files/styles/person_medium_photo/public/person-photo/amanda_lucidon22.jpg?itok=JFPi8OFJ',
            message: 'Annita liked your post: "Pure css notification box"',
            time: '11/7 - 2:13 pm'
        }, {
            link: 'https://codepen.io/Golez/',
            image: 'https://obamawhitehouse.archives.gov/sites/obamawhitehouse.archives.gov/files/styles/person_medium_photo/public/person-photo/amanda_lucidon22.jpg?itok=JFPi8OFJ',
            message: 'Annita liked your post: "Pure css notification box"',
            time: '11/7 - 2:13 pm'
        }, {
            link: 'https://codepen.io/Golez/',
            image: 'https://obamawhitehouse.archives.gov/sites/obamawhitehouse.archives.gov/files/styles/person_medium_photo/public/person-photo/amanda_lucidon22.jpg?itok=JFPi8OFJ',
            message: 'Annita liked your post: "Pure css notification box"',
            time: '11/7 - 2:13 pm'
        }, {
            link: 'https://codepen.io/Golez/',
            image: 'https://obamawhitehouse.archives.gov/sites/obamawhitehouse.archives.gov/files/styles/person_medium_photo/public/person-photo/amanda_lucidon22.jpg?itok=JFPi8OFJ',
            message: 'Annita liked your post: "Pure css notification box"',
            time: '11/7 - 2:13 pm'
        },
    ];

    useEffect(() => {
        // db.collection("parlour_feedback").where('username', '==', params.id.toLowerCase()).orderBy("created_date", "desc").onSnapshot((snapshot) => {
        //     setData(
        //         snapshot.docs.map((doc) => ({
        //             id: doc.id,
        //             data: doc.data(),
        //         }))
        //     );
        // });
        const handleClickOutside = (event) => {
            if (notificationRef.current && !notificationRef.current.contains(event.target)) {
                setShowNotification(false);
            }
        };

        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    const handleNotificationClick = () => {
        setShowNotification(!showNotification);
    };

    return (
        <>

            <div class="icons">

                <div class="notification">
                    <a href="#">
                        <div class="notBtn" href="#">
                            <div class="number">2</div>
                            <i class="fas fa-bell"></i>
                            <div class="box">
                                <div class="display">
                                    <div class="nothing">
                                        <i class="fas fa-child stick"></i>
                                        <div class="cent">Looks Like your all caught up!</div>
                                    </div>
                                    <div class="cont">
                                        {notifications.map((notification, index) => (
                                            <div class="sec new" key={index}>
                                                <a href={notification.link}>
                                                    <div class="profCont">
                                                        <img class="profile" src={notification.image} />
                                                    </div>
                                                    <div class="txt">{notification.message}</div>
                                                    <div class="txt sub">{notification.time}</div>
                                                </a>
                                            </div>
                                        ))}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </a>
                </div>
            </div>

            {/* {notifications.map((notification, index) => (
                <div class="sec new" key={index}>
                    <a href={notification.link}>
                        <div class="profCont">
                            <img class="profile" src={notification.image} />
                        </div>
                        <div class="txt">{notification.message}</div>
                        <div class="txt sub">{notification.time}</div>
                    </a>
                </div>
            ))} */}



        </>
    );
};

export default Notification;
