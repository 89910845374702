import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom"
import './style.css';
import Slider from './Slider';
import Card from './Card';
import db from "./Firebase";
import axios from "axios";
function HomePagePayment() {

    let params = useParams();
    const [personalData, setPersonalData] = useState({});
    const [parlourData, setParlourData] = useState({});
    const [imageArray, setImageArray] = useState([])
    const [servicesArray, setServicesArray] = useState([])
    const [customerName, setCustomerName] = useState("");
    const [customerPassword, setCustomerPassword] = useState("");
    const [customersData, setCustomersData] = useState([]);
    const [updatedCustomerName, setUpdatedCustomerName] = useState("");
    const [updatedCustomerPassword, setUpdatedCustomerPassword] = useState("");
    const [dataIdToBeUpdated, setDataIdToBeUpdated] = useState("");

    var amount = 0;



    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://checkout.razorpay.com/v1/checkout.js";
        script.async = true;
        document.body.appendChild(script);



    }, []);

    // handleChange = (evt) => {
    //     console.log(evt.target.value)
    //     this.setState({
    //         amount: evt.target.value
    //     })
    // }


    const openPayModal = (amt) => {
        console.log("call")
        var amount = amt * 100; //Razorpay consider the amount in paise
        var options = {
            "key": 'rzp_test_qAtDyPMbwSui8G',
            "amount": amount, // 2000 paise = INR 20, amount in paisa
            "name": "Rahul",
            "description": "Singh",
            'order_id': "",
            "handler": function (response) {
                console.log(response);
                var values = {
                    razorpay_signature: response.razorpay_signature,
                    razorpay_order_id: response.razorpay_order_id,
                    transactionid: response.razorpay_payment_id,
                    transactionamount: amount,
                }
                axios.post('http://localhost:5000/upgrade/payment', values)
                    .then(res => { alert("Success") })
                    .catch(e => console.log(e))
            },
            "prefill": {
                "name": 'Sanjana Kumari',
                "email": 'sanjana@gmail.com',
                "contact": '1234567890',
            },
            "notes": {
                "address": "Hello World"
            },
            "theme": {
                "color": "#528ff0"
            }
        };
        var rzp1 = new window.Razorpay(options);
        rzp1.open();
        // axios.post('http://localhost:3000/contact', { amount: amount })
        //     .then(res => {
        //         options.order_id = res.data.id;
        //         options.amount = res.data.amount;
        //         console.log(options)
        //         var rzp1 = new window.Razorpay(options);
        //         rzp1.open();
        //     })
        //     .catch(e => console.log(e))

    };
    return (
        <>
            <div>
                <h1>Helkoooooooooooooooooooooooooooooo</h1>
            </div>
            <button onClick={(e) => { openPayModal(1500) }} class="bg-blue-500 rounded-full font-bold text-white shover:bg-blue-600" style={{ height: "2rem", width: "11rem" }}>
                Price 4000/- onwards
            </button>








        </>
    );
}

export default HomePagePayment;
