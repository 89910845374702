
import React, { useState, useEffect } from 'react';
import { useNavigate, Link, useParams } from 'react-router-dom';
import db from "./Firebase";
import { arrayUnion } from "firebase/firestore";
import { storage } from './Firebase';
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import Moment from 'moment';
import "./style.css";
import Select from 'react-select';
import NumberCounter from 'number-counter';
import FooterMain from "./FooterMain";

export const BridalPage = () => {
    const [data, setData] = useState([]);
    const [mergedData, setMergedData] = useState([]);

    const containerStyle = {
        display: "flex",
        width: "100%", // Ensure the container spans the entire width
        marginTop: "-5px",
    };

    const leftPartStyle = {
        flex: 1,
        border: "none",
        boxSizing: "border-box",
        backgroundColor: "#7d6853",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    };

    const rightPartStyle = {
        flex: 1,
        border: "none",
        boxSizing: "border-box",
        backgroundColor: "#7d6853",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    };
    const circleContainerStyle = {
        display: "flex",
        // justifyContent: "space-around",
        marginTop: "20px",
        overflow: "scroll",
    };

    const circleImageStyle = {
        borderRadius: "50%",
        width: "150px", // Adjust the size as needed
        height: "150px", // Adjust the size as needed
        objectFit: "cover",
    };

    const circleTitleStyle = {
        marginTop: "10px",
        fontSize: "16px",
        fontWeight: "600",
        textAlign: "center",
    };

    const squareContainerStyle = {
        display: "flex",
        justifyContent: "space-around",
        marginTop: "20px",
    };

    const squareImageStyle = {
        width: "250px", // Adjust the size as needed
        height: "250px", // Adjust the size as needed
        objectFit: "cover",
    };

    const squareContentStyle = {
        textAlign: "center",
        marginTop: "10px",
        display: "flex", justifyContent: "center"
    };

    const ratingStarStyle = {
        color: "gold", // Adjust the color as needed
    };

    const [count, setCount] = useState(0);

    useEffect(() => {
        window.scrollTo(0, 0);
        for (let count = 0; count <= 500; count++) {
            setTimeout(() => {
                setCount(count)
            }, 1000);
        }
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            const portfolioDetailsSnapshot = await db.collection("portfolio_details").get();
            const portfolioDetailsData = portfolioDetailsSnapshot.docs.reduce((acc, doc) => {
                acc[doc.data().username] = {
                    id: doc.id,
                    data: doc.data(),
                };
                return acc;
            }, {});

            const websitePortfolioSnapshot = await db.collection("website_portfolio_listing").orderBy("listID", "asc").limit(4).get();
            const websitePortfolioData = websitePortfolioSnapshot.docs.map((doc) => ({
                id: doc.id,
                data: doc.data(),
            }));

            // Merge the data based on the common field 'username'
            const mergedData = websitePortfolioData.map((wpData) => {
                const matchingPortfolioDetail = portfolioDetailsData[wpData.data.username];
                return {
                    ...wpData,
                    portfolioDetail: matchingPortfolioDetail,
                };
            });

            setMergedData(mergedData);
        };

        fetchData();
    }, []);

    return (
        <div>
            <section style={{ boxShadow: "0 6px 6px -6px #777" }}>
                <div class="navi" style={{ backgroundColor: "#ec5d69" }}>
                    <input type="checkbox" id="nav-check" />
                    <div class="nav-header">
                        <div class="nav-title">
                            <Link to="/"> <img src="/bridal/youvati_white_logo.png" alt="" style={{ height: "22px", marginLeft: "1rem" }} /></Link>
                        </div>
                    </div>
                    <div class="nav-btn">
                        <label for="nav-check">
                            <span></span>
                            <span></span>
                            <span></span>
                        </label>
                    </div>
                    <div class="nav-links" id="top">
                        <a href="#portfolio" class="linkcolor" style={{ color: "white" }}>Bridal</a>
                        <span class="line" style={{ color: "white" }}>|</span>
                        <a href="#portfolio" class="linkcolor" style={{ color: "white" }}>Why Choose Us</a>
                        <span class="line" style={{ color: "white" }}>|</span>
                        <a href="#partners" class="linkcolor" style={{ color: "white" }}>For Partners</a>

                        <span class="line" style={{ color: "white" }}>|</span>
                        <a href="#reachtous" class="linkcolor" style={{ color: "white" }}>Contact Us</a>
                    </div>
                </div>
            </section>

            <div style={{
                justifyContent: "space-between", display: "flex",
                width: "100%", marginTop: "-5px",
            }}>
                <div style={leftPartStyle}>
                    <img
                        src="/bridal/Group_58.png" // Replace with the actual image URL
                        alt="Left Part Image"
                        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                    />
                </div>
            </div>
            <div style={{ textAlign: "left", marginTop: "20px", marginTop: "20px", display: "flex", justifyContent: "space-between" }}>
                <h2 style={{ fontSize: "24px", fontWeight: "600", color: "black", paddingLeft: "4rem", paddingTop: "2rem" }}>Top Bridal Artist</h2>
                <Link to="/bridal_artist"> <p style={{ paddingRight: "4rem", color: "#f5505d", fontWeight: "400", paddingTop: "25px" }}>View More...</p> </Link>
            </div>
            <div class="">
                <div class="row py-2">

                    <div class="col-12 text-center">
                        {/* <h2 class="mb-2 h1 font-weight-bold">Super clean user interface for easier use.</h2>
                        <p class="text-muted mb-5 pb-3">Our design projects are fresh and simple and will benefit your
                            business greatly.</p> */}
                        <div class="row">
                            {mergedData.map(({ id, data, portfolioDetail }, index) => (
                                <>
                                    <div class="col-lg-3 col-md-6 profile-square-image-250 mt-2 ">
                                        <Link to={`/${data.username}`}><div class="img-wrapper"> <img src={portfolioDetail ? portfolioDetail.data.portfolio_image : '/default-image.jpg'} class="inner-img" /></div>
                                            <div style={squareContentStyle}>
                                                <div style={{ width: "150px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", }}>
                                                    <span style={{ fontSize: "16px", fontWeight: "600", color: "black" }}>
                                                        {data.parlour_name}
                                                    </span>
                                                    <br />
                                                    <span style={ratingStarStyle}>★</span>
                                                    <span style={ratingStarStyle}>★</span>
                                                    <span style={ratingStarStyle}>★</span>
                                                    <span style={ratingStarStyle}>★</span>
                                                    <span style={ratingStarStyle}>★</span>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                </>
                            ))}

                        </div>

                    </div>
                </div>
            </div>

            <div style={{ textAlign: "left", marginTop: "20px" }}>
                <h2 style={{ fontSize: "24px", fontWeight: "600", color: "black", paddingLeft: "4rem", paddingTop: "2rem" }}>Tips For You</h2>
            </div>

            <div class="row">

                <div class="col-12 text-center">
                    <div class="row">
                        <div class="col-lg-6 col-md-6" style={{ paddingTop: "1rem" }}>
                            <Link to="/tipsforyou"><img src="/bridal/four4.png" style={{ width: "90%" }}></img></Link>
                        </div>
                        <div class="col-lg-6 col-md-6" style={{ paddingTop: "1rem" }}>
                            <Link to="/mehndi"><img src="/bridal/two2.png" style={{ width: "90%" }}></img></Link>
                        </div>
                        <div class="col-lg-6 col-md-6" style={{ paddingTop: "1rem" }}>
                            <Link to="/jwellery"><img src="/bridal/one1.png" style={{ width: "90%" }}></img></Link>
                        </div>
                        <div class="col-lg-6 col-md-6" style={{ paddingTop: "1rem" }}>
                            <Link to="/outfit"><img src="/bridal/three3.png" style={{ width: "90%" }}></img></Link>
                        </div>
                    </div>

                </div>
            </div>



            {/* <div class="text-center" style={{ marginTop: "30px", paddingLeft: "3rem", paddingRight: "3rem" }}>
                <div class="row row-cols-4">
                    <div class="col">

                        <Link to="/tipsforyou">   <div style={{ height: "5rem" }}>
                            <div class="row">
                                <div class="col-md-8" style={{ backgroundColor: "#CFCDB8", height: "5rem", backgroundSize: "cover", borderTopLeftRadius: "30px", borderBottomLeftRadius: "30px" }}><h1 style={{ color: "black" }}>Facial</h1></div>
                                <div class="col-6 col-md-4" style={{ height: "5rem", backgroundImage: "url('/bridal/Rectangle_83.png')", backgroundSize: "cover", backgroundPosition: "right top", borderTopRightRadius: "30px", borderBottomRightRadius: "30px" }}>
                                </div>
                            </div>
                        </div></Link>
                    </div>
                    <div class="col">
                        <Link to="/mehndi">
                            <div style={{ height: "5rem" }}>
                                <div class="">
                                    <div class="col-md-8" style={{ backgroundColor: "#DFB2AD", height: "5rem", backgroundSize: "cover", borderTopLeftRadius: "30px", borderBottomLeftRadius: "30px" }}><h1 style={{ color: "black" }}>Mehndi</h1></div>
                                    <div class="col-6 col-md-4" style={{ height: "5rem", backgroundImage: "url('/bridal/Rectangle_87.png')", backgroundSize: "cover", backgroundPosition: "right top", borderTopRightRadius: "30px", borderBottomRightRadius: "30px" }}>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>

                <div class="row row-cols-4" style={{ marginTop: "1rem" }}>
                    <div class="col">
                        <Link to="/jwellery">
                            <div style={{ height: "5rem" }}>
                                <div class="row">
                                    <div class="col-md-8" style={{ backgroundColor: "#F4D5C2", height: "5rem", backgroundSize: "cover", borderTopLeftRadius: "30px", borderBottomLeftRadius: "30px" }}><h1 style={{ color: "black" }}>Jwellery</h1></div>
                                    <div class="col-6 col-md-4" style={{ height: "5rem", backgroundImage: "url('/bridal/Rectangle_85.png')", backgroundSize: "cover", backgroundPosition: "right top", borderTopRightRadius: "30px", borderBottomRightRadius: "30px" }}>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div class="col">
                        <Link to="/outfit">
                            <div style={{ height: "5rem" }}>
                                <div class="">
                                    <div class="col-md-8" style={{ backgroundColor: "#D8DFFC", height: "5rem", backgroundSize: "cover", borderTopLeftRadius: "30px", borderBottomLeftRadius: "30px" }}><h1 style={{ color: "black" }}>Outfits</h1></div>
                                    <div class="col-6 col-md-4" style={{ height: "5rem", backgroundImage: "url('/bridal/Rectangle_84.png')", backgroundSize: "cover", backgroundPosition: "right top", borderTopRightRadius: "30px", borderBottomRightRadius: "30px" }}>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
            </div> */}
            <div class="col-md-12 col-12 mb-3 tfus">
                <div class="d-flex justify-content-center align-items-center" style={{ marginBottom: "2rem", marginTop: "2rem" }}>
                    <img src="/bridal/Group_40.png" style={{ maxWidth: "100%", maxHeight: "100%" }} />
                </div>
                <div class="d-flex justify-content-center align-items-center" style={{ marginBottom: "2rem" }}>
                    <img src="/bridal/Group_41.png" style={{ maxWidth: "100%", maxHeight: "100%" }} />
                </div>
                <div class="d-flex justify-content-center align-items-center" style={{ marginBottom: "2rem" }}>
                    <img src="/bridal/Group_55.png" style={{ maxWidth: "100%", maxHeight: "100%" }} />
                </div>
                <div class="d-flex justify-content-center align-items-center" style={{ marginBottom: "2rem" }}>
                    <img src="/bridal/Group_54.png" style={{ maxWidth: "100%", maxHeight: "100%" }} />
                </div>
                <div class="d-flex justify-content-center align-items-center" style={{ marginBottom: "2rem" }}>
                    <img src="/bridal/Group_52.png" style={{ maxWidth: "100%", maxHeight: "100%" }} />
                </div>
                <div class="d-flex justify-content-center align-items-center" style={{ marginBottom: "2rem" }}>
                    <img src="/bridal/Group_53.png" style={{ maxWidth: "100%", maxHeight: "100%" }} />
                </div>
            </div>

            <div class="row tfu" style={{ marginRight: "56px", marginLeft: "21px", border: "2px solid #f6b896", borderRadius: "30px", backgroundColor: "#f6b896" }}>

                <div class="col-md-12 col-12 mb-3">
                    <div class="d-flex justify-content-center align-items-center" style={{ height: "28rem" }}>
                        <a href="#" class="hover-switch">
                            <img src="/bridal/Group_55.png" style={{ maxWidth: "100%", maxHeight: "100%" }} />
                            <img src="/bridal/Group_41.png" style={{ maxWidth: "100%", maxHeight: "100%" }} />
                        </a>
                    </div>
                </div>

                <div class="col-md-12 col-12 mb-3">
                    <div class="d-flex justify-content-center align-items-center" style={{ height: "28rem" }}>
                        <a href="#" class="hover-switch">
                            <img src="/bridal/Group_55.png" style={{ maxWidth: "100%", maxHeight: "100%" }} />
                            <img src="/bridal/Group_54.png" style={{ maxWidth: "100%", maxHeight: "100%" }} />
                        </a>
                    </div>
                </div>

                <div class="col-md-12 col-12 mb-3">
                    <div class="d-flex justify-content-center align-items-center" style={{ height: "28rem" }}>
                        <a href="#" class="hover-switch">
                            <img src="/bridal/Group_52.png" style={{ maxWidth: "100%", maxHeight: "100%" }} />
                            <img src="/bridal/Group_53.png" style={{ maxWidth: "100%", maxHeight: "100%" }} />
                        </a>
                    </div>
                </div>

            </div>



            {/* <div class="row" style={{ marginRight: "56px", marginLeft: "21px", border: "2px solid #f6b896", borderRadius: "30px", backgroundColor: "#f6b896" }}>

                <div class="col-6 col-md-12 d-flex justify-content-center align-items-center" style={{ backgroundColor: "", height: "28rem", margin: "0 10px" }}>
                    <a href="#" class="hover-switch">
                        <img src="/bridal/Group_40.png" style={{ maxWidth: "100%", maxHeight: "100%" }} />
                        <img src="/bridal/Group_41.png" style={{ maxWidth: "100%", maxHeight: "100%" }} />
                    </a>
                </div>

                <div class="col-6 col-md-12 d-flex justify-content-center align-items-center" style={{ backgroundColor: "", height: "28rem", margin: "0 10px" }}>
                    <a href="#" class="hover-switch">
                        <img src="/bridal/Group_55.png" style={{ maxWidth: "100%", maxHeight: "100%" }} />
                        <img src="/bridal/Group_54.png" style={{ maxWidth: "100%", maxHeight: "100%" }} />
                    </a>
                </div>



                <div class="col-6 col-md-12 d-flex justify-content-center align-items-center" style={{ backgroundColor: "", height: "28rem", margin: "0 10px" }}>
                    <a href="#" class="hover-switch">
                        <img src="/bridal/Group_52.png" style={{ maxWidth: "100%", maxHeight: "100%" }} />
                        <img src="/bridal/Group_53.png" style={{ maxWidth: "100%", maxHeight: "100%" }} />
                    </a>
                </div>
               
            </div> */}

            <div style={{ textAlign: "left", marginTop: "20px" }}>
                <h2 style={{ fontSize: "24px", fontWeight: "600", color: "black", paddingLeft: "4rem", paddingTop: "2rem" }}>Wedding Clicks</h2>
            </div>

            <div style={circleContainerStyle}>
                <Link to="/bridal_clicks" style={{ marginRight: "4rem", marginLeft: "2rem" }}>
                    <div>
                        <img
                            src="/bridal/ellipse-6@2x.png" // Replace with the actual image URL
                            alt="Category 1"
                            style={circleImageStyle}
                        />
                        <p style={circleTitleStyle}>Bridal</p>
                    </div>
                </Link>
                <Link to="/couple_clicks" style={{ marginRight: "4rem" }}>
                    <div>
                        <img
                            src="/bridal/Ellipse_15.png" // Replace with the actual image URL
                            alt="Category 2"
                            style={circleImageStyle}
                        />
                        <p style={circleTitleStyle}>Couple</p>
                    </div>
                </Link>
                <Link to="/groom_clicks" style={{ marginRight: "4rem" }}>
                    <div>
                        <img
                            src="/bridal/Ellipse_17.png" // Replace with the actual image URL
                            alt="Category 3"
                            style={circleImageStyle}
                        />
                        <p style={circleTitleStyle}>Groom</p>
                    </div>
                </Link>
                <Link to="/function_clicks" style={{ marginRight: "4rem" }}>
                    <div>
                        <img
                            src="/bridal/Ellipse_18.png" // Replace with the actual image URL
                            alt="Category 4"
                            style={circleImageStyle}
                        />
                        <p style={circleTitleStyle}>Function</p>
                    </div>
                </Link>
                <Link to="/prewedding_clicks" style={{ marginRight: "4rem" }}>
                    <div>
                        <img
                            src="/bridal/Ellipse_14.png" // Replace with the actual image URL
                            alt="Category 5"
                            style={circleImageStyle}
                        />
                        <p style={circleTitleStyle}>Pre-Wedding</p>
                    </div>
                </Link>

                <Link to="/prewedding_clicks" style={{ marginRight: "4rem" }}>
                    <div>
                        <img
                            src="/pwc/Rectangle 92.png" // Replace with the actual image URL
                            alt="Category 5"
                            style={circleImageStyle}
                        />
                        <p style={circleTitleStyle}>Wedding Destination</p>
                    </div>
                </Link>
            </div>

            <FooterMain />

        </div >
    );
};



















// import React, { useState, useEffect } from 'react';
// import { useNavigate, Link, useParams } from 'react-router-dom';
// import db from "./Firebase";
// import { arrayUnion } from "firebase/firestore";
// import { storage } from './Firebase';
// import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
// import Moment from 'moment';
// import "./style.css";
// import Select from 'react-select';
// import NumberCounter from 'number-counter';
// import FooterMain from "./FooterMain";

// export const BridalPage = () => {
//     const [data, setData] = useState([]);
//     const [mergedData, setMergedData] = useState([]);

//     const containerStyle = {
//         display: "flex",
//         width: "100%", // Ensure the container spans the entire width
//         marginTop: "-5px",
//     };

//     const leftPartStyle = {
//         flex: 1,
//         border: "none",
//         boxSizing: "border-box",
//         backgroundColor: "#7d6853",
//         display: "flex",
//         flexDirection: "column",
//         justifyContent: "center",
//         alignItems: "center",
//     };

//     const rightPartStyle = {
//         flex: 1,
//         border: "none",
//         boxSizing: "border-box",
//         backgroundColor: "#7d6853",
//         display: "flex",
//         flexDirection: "column",
//         justifyContent: "center",
//         alignItems: "center",
//     };
//     const circleContainerStyle = {
//         display: "flex",
//         justifyContent: "space-around",
//         marginTop: "20px",
//     };

//     const circleImageStyle = {
//         borderRadius: "50%",
//         width: "150px", // Adjust the size as needed
//         height: "150px", // Adjust the size as needed
//         objectFit: "cover",
//     };

//     const circleTitleStyle = {
//         marginTop: "10px",
//         fontSize: "16px",
//         fontWeight: "600",
//         textAlign: "center",
//     };

//     const squareContainerStyle = {
//         display: "flex",
//         justifyContent: "space-around",
//         marginTop: "20px",
//     };

//     const squareImageStyle = {
//         width: "250px", // Adjust the size as needed
//         height: "250px", // Adjust the size as needed
//         objectFit: "cover",
//     };

//     const squareContentStyle = {
//         textAlign: "center",
//         marginTop: "10px",
//         display: "flex", justifyContent: "center"
//     };

//     const ratingStarStyle = {
//         color: "gold", // Adjust the color as needed
//     };

//     const [count, setCount] = useState(0);

//     useEffect(() => {
//         window.scrollTo(0, 0);
//         for (let count = 0; count <= 500; count++) {
//             setTimeout(() => {
//                 setCount(count)
//             }, 1000);
//         }
//     }, []);

//     useEffect(() => {
//         const fetchData = async () => {
//             const portfolioDetailsSnapshot = await db.collection("portfolio_details").get();
//             const portfolioDetailsData = portfolioDetailsSnapshot.docs.reduce((acc, doc) => {
//                 acc[doc.data().username] = {
//                     id: doc.id,
//                     data: doc.data(),
//                 };
//                 return acc;
//             }, {});

//             const websitePortfolioSnapshot = await db.collection("website_portfolio_listing").orderBy("listID", "asc").limit(4).get();
//             const websitePortfolioData = websitePortfolioSnapshot.docs.map((doc) => ({
//                 id: doc.id,
//                 data: doc.data(),
//             }));

//             // Merge the data based on the common field 'username'
//             const mergedData = websitePortfolioData.map((wpData) => {
//                 const matchingPortfolioDetail = portfolioDetailsData[wpData.data.username];
//                 return {
//                     ...wpData,
//                     portfolioDetail: matchingPortfolioDetail,
//                 };
//             });

//             setMergedData(mergedData);
//         };

//         fetchData();
//     }, []);

//     return (
//         <div>
//             <section style={{ boxShadow: "0 6px 6px -6px #777" }}>
//                 <div class="navi" style={{ backgroundColor: "#ec5d69" }}>
//                     <input type="checkbox" id="nav-check" />
//                     <div class="nav-header">
//                         <div class="nav-title">
//                             <Link to="/"> <img src="/bridal/youvati_white_logo.png" alt="" style={{ height: "27px", marginLeft: "2rem" }} /></Link>
//                         </div>
//                     </div>
//                     <div class="nav-btn">
//                         <label for="nav-check">
//                             <span></span>
//                             <span></span>
//                             <span></span>
//                         </label>
//                     </div>
//                     <div class="nav-links" id="top">
//                         <a href="#portfolio" class="linkcolor" style={{ color: "white" }}>Bridal</a>
//                         <span class="line" style={{ color: "white" }}>|</span>
//                         <a href="#portfolio" class="linkcolor" style={{ color: "white" }}>Why Choose Us</a>
//                         <span class="line" style={{ color: "white" }}>|</span>
//                         <a href="#partners" class="linkcolor" style={{ color: "white" }}>For Partners</a>

//                         <span class="line" style={{ color: "white" }}>|</span>
//                         <a href="#reachtous" class="linkcolor" style={{ color: "white" }}>Contact Us</a>
//                     </div>
//                 </div>
//             </section>
//             {/* <div style={{
//                 justifyContent: "space-between", display: "flex",
//                 width: "100%", marginTop: "-5px",
//             }}>
//                 <div style={leftPartStyle}>
//                     <img
//                         src="/bridal/rectangle-40@2x.png" // Replace with the actual image URL
//                         alt="Left Part Image"
//                         style={{ width: '100%', height: '100%', objectFit: 'cover' }}
//                     />
//                 </div>
//                 <div style={{ ...rightPartStyle, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
//                     <img
//                         src="/bridal/rectangle-40@2x.png" // Replace with the actual image URL
//                         alt="Left Part Image"
//                         style={{ width: '100%', height: '100%', objectFit: 'cover' }}
//                     />
//                 </div>

//             </div> */}
//             <div style={{ backgroundColor: "transparent", height: "19rem", marginTop: "-5px" }}>
//                 <a href="#" class="hover-sw">
//                     <img
//                         src="/bridal/Group_60.png" // Replace with the actual image URL
//                         alt="Left Part Image"
//                         style={{ width: '100%', height: '60%', objectFit: 'cover' }}
//                     />
//                     <img
//                         src="/bridal/Group_58.png" // Replace with the actual image URL
//                         alt="Left Part Image"
//                         style={{ width: '100%', height: '60%', objectFit: 'cover' }}
//                     />

//                 </a>
//             </div>
//             {/* <div style={{
//                 justifyContent: "space-between", display: "flex",
//                 width: "100%", marginTop: "-5px",
//             }}>
//                 <div style={leftPartStyle}>
//                     <a href="#" class="hover-sw">
//                         <img
//                             src="/bridal/Group_58.png" // Replace with the actual image URL
//                             alt="Left Part Image"
//                             style={{ width: '100%', height: '100%', objectFit: 'cover' }}
//                         />
//                         <img
//                             src="/bridal/Group_60.png" // Replace with the actual image URL
//                             alt="Left Part Image"
//                             style={{ width: '100%', height: '100%', objectFit: 'cover' }}
//                         />
//                     </a>
//                 </div>


//             </div> */}

//             <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "20px" }}>
//                 <div>
//                     <h2 style={{ fontSize: "24px", fontWeight: "600", color: "black", paddingLeft: "4rem", paddingTop: "2rem" }}>Top Bridal Artist</h2>
//                 </div>
//                 <div>
//                     <Link to="/bridal_artist"> <p style={{ paddingRight: "4rem", color: "#f5505d", fontWeight: "400", paddingTop: "25px" }}>View More...</p> </Link>
//                 </div>
//             </div>

//             <div style={squareContainerStyle}>

//                 {/* {data?.map(({ id, data }, index) => ( */}
//                 {mergedData.map(({ id, data, portfolioDetail }, index) => (
//                     <>
//                         <Link to={`/${data.username}`}>
//                             <div>
//                                 <div class="img-wrapper">
//                                     <img
//                                         class="inner-img"
//                                         src={portfolioDetail ? portfolioDetail.data.portfolio_image : '/default-image.jpg'}  // Replace with the actual image URL
//                                         alt="Category 1"
//                                         style={squareImageStyle}
//                                     />
//                                 </div>
//                                 <div style={squareContentStyle}>
//                                     <div style={{ width: "150px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", }}>
//                                         <span style={{ fontSize: "16px", fontWeight: "600", color: "black" }}>
//                                             {data.parlour_name}
//                                         </span>
//                                         <br />
//                                         <span style={ratingStarStyle}>★</span>
//                                         <span style={ratingStarStyle}>★</span>
//                                         <span style={ratingStarStyle}>★</span>
//                                         <span style={ratingStarStyle}>★</span>
//                                         <span style={ratingStarStyle}>★</span>
//                                     </div>
//                                 </div>
//                             </div>
//                         </Link>
//                     </>
//                 ))}

//                 {/* <div>
//                     <div class="img-wrapper">
//                         <img
//                             class="inner-img"
//                             src="/bridal/rectangle-12@2x.png" // Replace with the actual image URL
//                             alt="Category 1"
//                             style={squareImageStyle}
//                         />
//                     </div>
//                     <div style={squareContentStyle}>
//                         <div style={{ width: "150px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", }}>
//                             <span style={{ fontSize: "16px", fontWeight: "600", color: "black" }}>
//                                 Pihu Makeovers
//                             </span>
//                             <br />
//                             <span style={ratingStarStyle}>★</span>
//                             <span style={ratingStarStyle}>★</span>
//                             <span style={ratingStarStyle}>★</span>
//                             <span style={ratingStarStyle}>★</span>
//                             <span style={ratingStarStyle}>★</span>
//                         </div>
//                     </div>
//                 </div>
//                 <div>
//                     <div class="img-wrapper">
//                         <img
//                             class="inner-img"
//                             src="/bridal/rectangle-17@2x.png" // Replace with the actual image URL
//                             alt="Category 1"
//                             style={squareImageStyle}
//                         />
//                     </div>
//                     <div style={squareContentStyle}>
//                         <div style={{ width: "150px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", }}>
//                             <span style={{ fontSize: "16px", fontWeight: "600", color: "black" }}>
//                                 Mahi Makeovers
//                             </span>
//                             <br />
//                             <span style={ratingStarStyle}>★</span>
//                             <span style={ratingStarStyle}>★</span>
//                             <span style={ratingStarStyle}>★</span>
//                             <span style={ratingStarStyle}>★</span>
//                             <span style={ratingStarStyle}>★</span>
//                         </div>
//                     </div>
//                 </div>
//                 <div>
//                     <div class="img-wrapper">
//                         <img
//                             class="inner-img"
//                             src="/bridal/rectangle-14@2x.png" // Replace with the actual image URL
//                             alt="Category 1"
//                             style={squareImageStyle}
//                         />
//                     </div>
//                     <div style={squareContentStyle}>
//                         <div style={{ width: "150px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", }}>
//                             <span style={{ fontSize: "16px", fontWeight: "600", color: "black" }}>
//                                 Sita Makeovers
//                             </span>
//                             <br />
//                             <span style={ratingStarStyle}>★</span>
//                             <span style={ratingStarStyle}>★</span>
//                             <span style={ratingStarStyle}>★</span>
//                             <span style={ratingStarStyle}>★</span>
//                             <span style={ratingStarStyle}>★</span>
//                         </div>
//                     </div>
//                 </div> */}
//             </div>

//             <div style={{ textAlign: "left", marginTop: "20px" }}>
//                 <h2 style={{ fontSize: "24px", fontWeight: "600", color: "black", paddingLeft: "4rem", paddingTop: "2rem" }}>Tips For You</h2>
//             </div>

//             <div class="text-center" style={{ marginTop: "30px", paddingLeft: "3rem", paddingRight: "3rem" }}>
//                 <div class="row row-cols-4">
//                     <div class="col">

//                         <Link to="/tipsforyou">   <div style={{ height: "5rem" }}>
//                             <div class="row">
//                                 <div class="col-md-8" style={{ backgroundColor: "#CFCDB8", height: "5rem", backgroundSize: "cover", borderTopLeftRadius: "30px", borderBottomLeftRadius: "30px" }}><h1 style={{ color: "black" }}>Facial</h1></div>
//                                 <div class="col-6 col-md-4" style={{ height: "5rem", backgroundImage: "url('/bridal/Rectangle_83.png')", backgroundSize: "cover", backgroundPosition: "right top", borderTopRightRadius: "30px", borderBottomRightRadius: "30px" }}>
//                                 </div>
//                             </div>
//                         </div></Link>
//                     </div>
//                     <div class="col">
//                         <Link to="/mehndi">
//                             <div style={{ height: "5rem" }}>
//                                 <div class="">
//                                     <div class="col-md-8" style={{ backgroundColor: "#DFB2AD", height: "5rem", backgroundSize: "cover", borderTopLeftRadius: "30px", borderBottomLeftRadius: "30px" }}><h1 style={{ color: "black" }}>Mehndi</h1></div>
//                                     <div class="col-6 col-md-4" style={{ height: "5rem", backgroundImage: "url('/bridal/Rectangle_87.png')", backgroundSize: "cover", backgroundPosition: "right top", borderTopRightRadius: "30px", borderBottomRightRadius: "30px" }}>
//                                     </div>
//                                 </div>
//                             </div>
//                         </Link>
//                     </div>
//                 </div>

//                 <div class="row row-cols-4" style={{ marginTop: "1rem" }}>
//                     <div class="col">
//                         <Link to="/jwellery">
//                             <div style={{ height: "5rem" }}>
//                                 <div class="row">
//                                     <div class="col-md-8" style={{ backgroundColor: "#F4D5C2", height: "5rem", backgroundSize: "cover", borderTopLeftRadius: "30px", borderBottomLeftRadius: "30px" }}><h1 style={{ color: "black" }}>Jwellery</h1></div>
//                                     <div class="col-6 col-md-4" style={{ height: "5rem", backgroundImage: "url('/bridal/Rectangle_85.png')", backgroundSize: "cover", backgroundPosition: "right top", borderTopRightRadius: "30px", borderBottomRightRadius: "30px" }}>
//                                     </div>
//                                 </div>
//                             </div>
//                         </Link>
//                     </div>
//                     <div class="col">
//                         <Link to="/outfit">
//                             <div style={{ height: "5rem" }}>
//                                 <div class="">
//                                     <div class="col-md-8" style={{ backgroundColor: "#D8DFFC", height: "5rem", backgroundSize: "cover", borderTopLeftRadius: "30px", borderBottomLeftRadius: "30px" }}><h1 style={{ color: "black" }}>Outfits</h1></div>
//                                     <div class="col-6 col-md-4" style={{ height: "5rem", backgroundImage: "url('/bridal/Rectangle_84.png')", backgroundSize: "cover", backgroundPosition: "right top", borderTopRightRadius: "30px", borderBottomRightRadius: "30px" }}>
//                                     </div>
//                                 </div>
//                             </div>
//                         </Link>
//                     </div>
//                 </div>
//             </div>

//             <div class="row" style={{ marginRight: "56px", marginLeft: "21px", border: "2px solid #f6b896", borderRadius: "30px", backgroundColor: "#f6b896" }}>

//                 <div class="col-6 col-md-12 d-flex justify-content-center align-items-center" style={{ backgroundColor: "", height: "28rem", margin: "0 10px" }}>
//                     <a href="#" class="hover-switch">
//                         <img src="/bridal/Group_40.png" style={{ maxWidth: "100%", maxHeight: "100%" }} />
//                         <img src="/bridal/Group_41.png" style={{ maxWidth: "100%", maxHeight: "100%" }} />
//                     </a>
//                 </div>

//                 <div class="col-6 col-md-12 d-flex justify-content-center align-items-center" style={{ backgroundColor: "", height: "28rem", margin: "0 10px" }}>
//                     <a href="#" class="hover-switch">
//                         <img src="/bridal/Group_55.png" style={{ maxWidth: "100%", maxHeight: "100%" }} />
//                         <img src="/bridal/Group_54.png" style={{ maxWidth: "100%", maxHeight: "100%" }} />
//                     </a>
//                 </div>



//                 <div class="col-6 col-md-12 d-flex justify-content-center align-items-center" style={{ backgroundColor: "", height: "28rem", margin: "0 10px" }}>
//                     <a href="#" class="hover-switch">
//                         <img src="/bridal/Group_52.png" style={{ maxWidth: "100%", maxHeight: "100%" }} />
//                         <img src="/bridal/Group_53.png" style={{ maxWidth: "100%", maxHeight: "100%" }} />
//                     </a>
//                 </div>

//                 {/* <div class="col-md-4 d-flex justify-content-center align-items-center" style={{ backgroundColor: "#F6B896", height: "28rem" }}>
//                     <h1 style={{ textAlign: "center", fontSize: "31px", color: "black" }}>“All women are pretty without makeup –– but
//                         with the right makeup they can be pretty powerful.”</h1>
//                 </div> */}
//                 {/*
//                 <div class="col-md-8 d-flex justify-content-center align-items-center" style={{ backgroundColor: "#F6B896", height: "28rem" }}>
//                     <h1 style={{ textAlign: "center", fontSize: "31px", color: "black" }}>“All women are pretty without makeup –– but
//                         with the right makeup they can be pretty powerful.”</h1>
//                 </div> */}
//                 {/*
//                 <div class="col-6 col-md-4 d-flex justify-content-center align-items-center" style={{ backgroundColor: "#F6B896", height: "28rem" }}>
//                     <img
//                         src="/bridal/pexels-dhiraj-jain-12737566 1.png" // Replace with the actual image URL
//                         alt="Category 5"
//                         style={{ maxWidth: "100%", maxHeight: "90%" }}
//                     />
//                 </div> */}

//                 {/* <div class="col-6 col-md-5 d-flex justify-content-center align-items-center" style={{ backgroundColor: "#F6B896", height: "28rem" }}>
//                     <img
//                         src="/bridal/pexels-sant-singh-11168809 2.png" // Replace with the actual image URL
//                         alt="Category 5"
//                         style={{ maxWidth: "80%", maxHeight: "90%" }}
//                     />
//                 </div> */}
//                 {/* <div class="col-md-7 d-flex justify-content-center align-items-center" style={{ backgroundColor: "#F6B896", height: "28rem" }}>
//                     <h1 style={{ textAlign: "center", fontSize: "31px", color: "black" }}>“All women are pretty without makeup –– but
//                         with the right makeup they can be pretty powerful.”</h1>
//                 </div> */}



//             </div>

//             <div style={{ textAlign: "left", marginTop: "20px" }}>
//                 <h2 style={{ fontSize: "24px", fontWeight: "600", color: "black", paddingLeft: "4rem", paddingTop: "2rem" }}>Wedding Clicks</h2>
//             </div>

//             <div style={circleContainerStyle}>
//                 <Link to="/bridal_clicks">
//                     <div>
//                         <img
//                             src="/bridal/ellipse-6@2x.png" // Replace with the actual image URL
//                             alt="Category 1"
//                             style={circleImageStyle}
//                         />
//                         <p style={circleTitleStyle}>Bridal</p>
//                     </div>
//                 </Link>
//                 <Link to="/couple_clicks">
//                     <div>
//                         <img
//                             src="/bridal/Ellipse_15.png" // Replace with the actual image URL
//                             alt="Category 2"
//                             style={circleImageStyle}
//                         />
//                         <p style={circleTitleStyle}>Couple</p>
//                     </div>
//                 </Link>
//                 <Link to="/groom_clicks">
//                     <div>
//                         <img
//                             src="/bridal/Ellipse_17.png" // Replace with the actual image URL
//                             alt="Category 3"
//                             style={circleImageStyle}
//                         />
//                         <p style={circleTitleStyle}>Groom</p>
//                     </div>
//                 </Link>
//                 <Link to="/function_clicks">
//                     <div>
//                         <img
//                             src="/bridal/Ellipse_18.png" // Replace with the actual image URL
//                             alt="Category 4"
//                             style={circleImageStyle}
//                         />
//                         <p style={circleTitleStyle}>Function</p>
//                     </div>
//                 </Link>
//                 <Link to="/prewedding_clicks">
//                     <div>
//                         <img
//                             src="/bridal/Ellipse_14.png" // Replace with the actual image URL
//                             alt="Category 5"
//                             style={circleImageStyle}
//                         />
//                         <p style={circleTitleStyle}>Pre-Wedding</p>
//                     </div>
//                 </Link>
//             </div>

//             <FooterMain />

//         </div >
//     );
// };

