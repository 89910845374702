import React, { useState, useEffect } from 'react';
import { useNavigate, Link, useParams } from 'react-router-dom';
import db from "./Firebase";
import { arrayUnion } from "firebase/firestore";
import { storage } from './Firebase';
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import ViewAllRequests from './ViewAllRequests';
import { CSVLink } from "react-csv";
import Swal from 'sweetalert2'

function BannerRequest() {
    const navigate = useNavigate();
    const moment = extendMoment(Moment);
    const [uid, setUid] = useState([]);
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [uuid, setUuid] = useState("");
    const headers = [
        { label: "Name", key: "name" },
        { label: "parlour", key: "owner_name" },
        { label: "Phone No", key: "phone_no" },
        { label: "Size", key: "size" },
        { label: "Whatsapp No", key: "whatsapp_no" },
        { label: "Address", key: "additional_info" },
        { label: "Message", key: "message" },
        { label: "Email", key: "email" },
        { label: "Image URL", key: "image_url" },
        { label: "Created Date", key: "created_date", dataFormat: "MM/DD/YY, h:mm a" },
    ];

    const [data, setData] = useState([])
    const [csvdata, setCsvdata] = useState([]);
    const [redID, setRegID] = useState("");

    useEffect(() => {
        const storedUsername = localStorage.getItem("email");
        const storedPassword = localStorage.getItem("password");
        const storedRemember = localStorage.getItem("remember");

        if (storedRemember === "false" || !storedUsername || !storedPassword) {
            navigate('/signin');
        }
    }, []);

    const [doneCount, setDoneCount] = useState(0);

    useEffect(() => {
        const fetchData = () => {
            try {
                db.collection("banner_request")
                    .orderBy("created_date", "desc")
                    .onSnapshot((snapshot) => {
                        const dataArray = [];
                        let count = 0;
                        setCsvdata(
                            snapshot.docs.map((doc) => ({
                                id: doc.id,
                                data: doc.data()
                            }))
                        );

                        snapshot.forEach((doc) => {
                            const documentData = {
                                id: doc.id,
                                data: doc.data(),
                            };

                            dataArray.push(documentData);

                            // Check the status field and increment count
                            if (documentData.data.status === '1') {
                                count += 1;
                            }
                        });

                        setData(dataArray);
                        setDoneCount(count);
                    });
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    // useEffect(() => {
    //     db.collection("banner_request").orderBy("created_date", "desc").onSnapshot((snapshot) => {
    //         setCsvdata(
    //             snapshot.docs.map((doc) => ({
    //                 id: doc.id,
    //                 data: doc.data()
    //             }))
    //         );

    //         setData(
    //             snapshot.docs.map((doc) => (
    //                 doc.data()
    //             ))
    //         );

    //     });

    // }, []);

    // const notifyApp = (msg) => {
    //     // Call the user's CollectionReference to add a new user
    //     db.collection("app_notification").add({ 'message': msg, 'link': "", 'status': "0", "uid": uuid, 'created_date': new Date() }).then(() => {
    //         console.log("notified");
    //     })
    // }

    const notifyApp = (msg,) => {
        // Call the user's CollectionReference to add a new user
        db.collection("notifications").add({ 'created_date': new Date(), image_url: "", link: "Advertise", message: msg, module: "advertise", personal: uuid, seen: [], status: 1, view: false }).then(() => {
            console.log("notified");
        })
    }

    const initialFormStateFeedback = { status: '' }
    const [submit, setSubmit] = useState(initialFormStateFeedback)


    const handleChange2 = async (e, msg) => {
        const { id, value } = e.target
        setSubmit({ ...submit, [id]: value })
        if (e.target.value == "1") {
            const { value: text } = await Swal.fire({
                input: "textarea",
                inputLabel: "Advertise download Link",
                inputPlaceholder: "Type your link here...",
                inputAttributes: {
                    "aria-label": "Type your link here"
                },
                showCancelButton: true,
                inputValidator: (value) => {
                    if (!value) {
                        return "You need to enter link!";
                    }
                }
            });
            if (text) {
                // alert("add link")
                db.collection("download_advertise_content").add({
                    'name': "Free Banner",
                    'phone_no': phone,
                    'design_name': "",
                    'link': text,
                    'type': "Free",
                    'created_date': new Date(),
                }).then(() => {
                    console.log('Created Successfully');
                    const Toast = Swal.mixin({
                        toast: true,
                        background: '#69aba6',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', Swal.stopTimer)
                            toast.addEventListener('mouseleave', Swal.resumeTimer)
                        }
                    })
                    db.collection("banner_request").doc(uid).update({ 'status': "1", 'updated_date': new Date() }).then(() => {
                        console.log("user added");
                    })
                    notifyApp(msg = `Hi ${name} we have sent your Free Banner, go to Advertise section to download .`);
                    sendWhatsapp()
                    handleSubmitPush("Download karo, Share karo, Grow karo 🥳", "Your Free Banner Card Is Ready!")
                });
            }
            // console.log(name)
            // console.log(phone)

        } else if (e.target.value == "2") {
            const { value: text } = await Swal.fire({
                input: "textarea",
                inputLabel: "Remark",
                inputPlaceholder: "Type your remark here...",
                inputAttributes: {
                    "aria-label": "Type your remark here"
                },
                showCancelButton: true,
                inputValidator: (value) => {
                    if (!value) {
                        return "You need to write something!";
                    }
                }
            });

            if (text) {
                const newRemark = {
                    text: text,
                    updated_date: new Date(),
                    updated_by: email,
                };
                const docRef = db.collection("banner_request").doc(uid);
                console.log(uid)
                docRef.update({
                    fields: arrayUnion(newRemark)
                })
                    .then(() => {
                        rejectWhatsapp()
                        notifyApp(msg = `Hey ${name} 😇 Based on your confirmation, we are cancelling your request for the Free Banner. You can always apply again in the future whenever you need the service.`);
                        db.collection("banner_request").doc(uid).update({ 'status': "2", 'updated_date': new Date() }).then(() => {
                            console.log("data added");
                        })
                        console.log("Document updated");
                        handleSubmitPush("✅ As per your confirmation,", "🗑️ we have completely deleted your request.")
                    })
                    .catch((error) => {
                        console.error("Error updating document: ", error);
                    });
            }

            // addRemark(uid)
        } else if (e.target.value == "3") {
            callbackWhatsapp()
            // callBackPush()
            db.collection("banner_request").doc(uid).update({ 'status': "3", 'updated_date': new Date() }).then(() => {
                console.log("data added");
            })
            addRemark(uid)
            handleSubmitPush("Quick update on your Free Banner request!", "Call us now @ +91 7065038326 📞")
        } else {
            db.collection("banner_request").doc(uid).update({ 'status': "0", 'updated_date': new Date() }).then(() => {
                console.log("user added");
            })
        }
    }

    const addRemark = async (id) => {
        const { value: text } = await Swal.fire({
            input: "textarea",
            inputLabel: "Remark",
            inputPlaceholder: "Type your remark here...",
            inputAttributes: {
                "aria-label": "Type your remark here"
            },
            showCancelButton: true,
            inputValidator: (value) => {
                if (!value) {
                    return "You need to write something!";
                }
            }
        });

        if (text) {
            const newRemark = {
                text: text,
                updated_date: new Date(),
                updated_by: email,
            };
            const docRef = db.collection("banner_request").doc(id);
            console.log(id)
            docRef.update({
                fields: arrayUnion(newRemark)
            })
                .then(() => {
                    console.log("Document updated");
                })
                .catch((error) => {
                    console.error("Error updating document: ", error);
                });
        }
    }

    const callbackWhatsapp = (e) => {
        // console.log("wahtsapppppppppppppppppppppppppppppppppppppp")
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer EABMKlZCrATXIBAPOZBZBPqsdfot34fq0FTipZBQf3RiXZAapyCOqMay09HfhSxoB8bd5ZA7RUnLWhd0JkXtACJQ682THiDEMhQhyPOkeui8Cb8P72WPDhihUZBCkHtPLSgsmuZA6iRY6VunDDIjPA5mATQQITVXOECHqkZB5E2pzWSC2AaUp0wZCWR");
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify(
            {
                "messaging_product": "whatsapp",
                "recipient_type": "individual",
                "to": `91${phone}`,
                "type": "template",
                "template": {
                    "name": "call_back_request",
                    "language": {
                        "code": "en_US",
                        "policy": "deterministic"
                    },
                    "components": [
                        {
                            "type": "body",
                            "parameters": [
                                { "type": "text", "text": `${name}` },
                                { "type": "text", "text": "Free Banner" }
                            ]
                        }
                    ]
                }
            }
        );

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch("https://graph.facebook.com/v14.0/104489945783566/messages", requestOptions)
            .then(response => response.text())
            .then(result => console.log(result))
            .catch(error => {
                console.log('error', error)
            });
    };


    const rejectWhatsapp = (e) => {
        // console.log("wahtsapppppppppppppppppppppppppppppppppppppp")
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer EABMKlZCrATXIBAPOZBZBPqsdfot34fq0FTipZBQf3RiXZAapyCOqMay09HfhSxoB8bd5ZA7RUnLWhd0JkXtACJQ682THiDEMhQhyPOkeui8Cb8P72WPDhihUZBCkHtPLSgsmuZA6iRY6VunDDIjPA5mATQQITVXOECHqkZB5E2pzWSC2AaUp0wZCWR");
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify(
            {
                "messaging_product": "whatsapp",
                "recipient_type": "individual",
                "to": `91${phone}`,
                "type": "template",
                "template": {
                    "name": "advertise_cancelled_request",
                    "language": {
                        "code": "en_US",
                        "policy": "deterministic"
                    },
                    "components": [
                        {
                            "type": "body",
                            "parameters": [
                                { "type": "text", "text": `${name}` },
                                { "type": "text", "text": "Free Banner" }
                            ]
                        }
                    ]
                }
            }
        );

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch("https://graph.facebook.com/v14.0/104489945783566/messages", requestOptions)
            .then(response => response.text())
            .then(result => console.log(result))
            .catch(error => {
                console.log('error', error)
            });
    };


    const collect = (id, name, mainID, phone) => {
        setUid(id)
        setName(name)
        setUuid(mainID)
        setEmail(email)
        setPhone(phone)

        db.collection("notification_ids")
            .where('uid', '==', mainID)
            .onSnapshot((snapshot) => {
                snapshot.forEach((doc) => {
                    const regId = doc.data().reg_id;
                    setRegID(doc.data().reg_id);
                    console.log('reg_id:', regId);
                    // If you want to store the reg_id values in a state variable
                    // or use it elsewhere, you can set it accordingly.
                });
            });
    }


    const sendWhatsapp = (e) => {
        console.log("wahtsap calling")
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer EABMKlZCrATXIBAPOZBZBPqsdfot34fq0FTipZBQf3RiXZAapyCOqMay09HfhSxoB8bd5ZA7RUnLWhd0JkXtACJQ682THiDEMhQhyPOkeui8Cb8P72WPDhihUZBCkHtPLSgsmuZA6iRY6VunDDIjPA5mATQQITVXOECHqkZB5E2pzWSC2AaUp0wZCWR");
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify(
            {
                "messaging_product": "whatsapp",
                "recipient_type": "individual",
                "to": `91${phone}`,
                "type": "template",
                "template": {
                    "name": "visiting_card_done",
                    "language": {
                        "code": "en_US",
                        "policy": "deterministic"
                    },
                    "components": [
                        {
                            "type": "header",
                            "parameters": [
                                {
                                    "type": "image",
                                    "image": { "link": "https://firebasestorage.googleapis.com/v0/b/youvati.appspot.com/o/ss.jpg?alt=media&token=ad2688bb-fef3-4b28-8f02-59e73c4a4cab" }
                                }
                            ]
                        },
                        {
                            "type": "body",
                            "parameters": [
                                { "type": "text", "text": `${name}` },
                                { "type": "text", "text": "Free Banner" },
                            ]
                        }
                    ]
                }
            }
        );

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch("https://graph.facebook.com/v14.0/104489945783566/messages", requestOptions)
            .then(response => response.text())
            .then(result => console.log(result))
            .catch(error => {
                console.log('error', error)
            });
    };

    const handleSubmitPush = (body, title) => {
        // Use the single registration ID
        const singleRegId = redID; // Replace with your actual registration ID

        console.log(singleRegId);

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "key=AAAAnzqDoGw:APA91bHNYZDEZn5paZqVOV6evl4npGLdy7V1iixsNCGekPti0mkcTft5lHb9WruMaFjOdJrGVR9kkFzfC00HMfmvPUFo4sQhvXmXoom7clqOhXqMc7y5erUF75zJIxAzMZeuCtXx6PTS");

        // Check if the registration ID is valid before proceeding
        if (!singleRegId || singleRegId.trim() === "") {
            console.log("No valid registration ID to send the push notification.");
            return;
        }

        const raw = JSON.stringify({
            "registration_ids": [singleRegId],
            "notification": {
                "body": body,
                "title": title,
                "android_channel_id": "youvati",
                "image": "",
                "sound": true
            },
            "data": {
                "type": "msg",
                "id": "advertise",
                "image": ""
            }
        });

        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch("https://fcm.googleapis.com/fcm/send", requestOptions)
            .then(response => response.json())
            .then(data => {
                // Handle the response as needed
                console.log(data);

                // Additional handling if required
                if (data.success) {
                    // Swal.fire(`Push notification sent successfully to ${singleRegId}.`);
                    console.log(`Push notification sent successfully to ${singleRegId}.`)
                } else {
                    // Swal.fire(`Failed to send push notification to ${singleRegId}.`);
                    console.log(`Failed to send push notification to ${singleRegId}.`)
                }
            })
            .catch(error => console.log('error', error));
    };

    const [dataDict, setDataDict] = useState({}); // Initialize dataDict as an empty object

    useEffect(() => {
        db.collection("personal_details")
            .orderBy("created_date", "desc")
            .onSnapshot((snapshot) => {
                const newDataDict = {}; // Create a new object to store updated data
                snapshot.docs.forEach((doc) => {
                    const data = doc.data();
                    newDataDict[data.phone_no] = data.full_name;
                });
                setDataDict(newDataDict); // Update the state with the new object
                console.log(newDataDict)
            });
    }, []);

    const openModalShow = () => {
        // alert("ooo")
        window.openModelshow();
    }

    const closeModel = () => {
        window.openModelNew();
    }

    const [remarks, setRemarks] = useState([]);

    const viewRemark = (data) => {
        setRemarks(data)
        console.log("ppppppp")
        openModalShow();
        // const newRemark = {
        //     text: "heloooo",
        //     updated_date: new Date(),
        //     updated_by: "meeeee@gggg",
        // };
        // const docRef = db.collection("business_card_request").doc(id);
        // console.log(id)
        // docRef.update({
        //     fields: arrayUnion(newRemark)
        // })
        //     .then(() => {
        //         console.log("Document updated");
        //     })
        //     .catch((error) => {
        //         console.error("Error updating document: ", error);
        //     });
    }


    const csvExcelData = csvdata.map(({ id, data }, index) => ({
        name: data.name,
        owner_name: data.owner_name,
        phone_no: data.phone_no,
        size: data.size,
        whatsapp_no: data.whatsapp_no,
        additional_info: data.additional_info,
        message: data.message,
        email: data.email,
        image_url: data.image_url,
        created_date: data.created_date,
    }));


    return (
        <>
            {/* <ViewAllRequests /> */}

            <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
                <div class="p-2" style={{ paddingBottom: "5px", display: "flex", justifyContent: "space-between" }}>
                    {/* <span class="btn bg-gradient-secondary btn-sm mb-0 mr-2" style={{ color: "white" }} >&nbsp; Download Excel</span> */}
                    <Link to="/admin_panel"><img src="/images/menubutton.png" class="menu-toggle" alt="Responsive image" style={{ height: "2rem" }} /></Link>
                    <CSVLink data={csvExcelData} headers={headers}>
                        <span class="btn bg-gradient-secondary btn-sm mb-0 mr-2" style={{ color: "white" }} >&nbsp; Download Excel</span>
                    </CSVLink>
                </div>
                <div class="">


                    <div class="p-2">
                        <div style={{ textAlign: "end", paddingBottom: "5px" }}>
                            {/* <Link to="/admin_panel" class="btn bg-gradient-primary btn-sm mb-0 mr-2" style={{ color: "white" }} >&nbsp; Go Back</Link> */}

                        </div>
                        <div class="card">
                            <div class="card-header pb-0 px-3">
                                <h6 class="mb-2">Free Banner Request</h6>
                            </div>
                            <div class="d-flex p-2" style={{ justifyContent: "center", fontWeight: "bold" }}>
                                Total : {data.length} || Pending : {data.length - doneCount} || Done : {doneCount}
                            </div>
                            <div class="">
                                {/* <div style={{ textAlign: "end", paddingBottom: "5px" }}>
                        <a class="btn bg-gradient-primary btn-sm mb-0" style={{ color: "white" }} onClick={() => changeStatus()}>+&nbsp; Add Rent Details</a>
                    </div> */}
                                <div class="card">
                                    <div class="table-responsive fix-width scroll-inner">
                                        <table class="table table-bordered table-hover">
                                            <thead class="thead-dark" style={{ position: "sticky", top: "0" }}>
                                                <tr>
                                                    <th class="col">Owner Name</th>
                                                    <th class="col">Card Name</th>
                                                    <th class="col">Requested Name</th>
                                                    <th class="col ps-2">Phone No</th>
                                                    <th class="col ps-2">Size</th>
                                                    <th class="col ps-2">Whatsapp No</th>
                                                    <th class="col ps-2">Address</th>
                                                    <th class="col ps-2">Message</th>
                                                    <th class="col ps-2">Email</th>
                                                    <th class="col ps-2">Image</th>
                                                    <th class="col ps-2">Created Date</th>
                                                    <th class="col ps-6">Remark</th>
                                                    <th class="col ps-2">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {csvdata?.map(({ id, data }, index) => (
                                                    <>
                                                        <tr>
                                                            <td>
                                                                <p class="text-xs font-weight-bold mb-0">{dataDict[data.phone_no]}</p>

                                                            </td>
                                                            <td>
                                                                <p class="text-xs font-weight-bold mb-0">{data.name}</p>
                                                                {/* <p class="text-xs text-secondary mb-0">24 Nov 2022</p> */}
                                                            </td>
                                                            <td>
                                                                <div class="d-flex px-2 py-1">

                                                                    <div class="d-flex flex-column justify-content-center">
                                                                        <h6 class="mb-0 text-xs">{data.owner_name}</h6>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div class="d-flex px-2 py-1">

                                                                    <div class="d-flex flex-column justify-content-center">
                                                                        <h6 class="mb-0 text-xs">{data.phone_no}</h6>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div class="d-flex px-2 py-1">

                                                                    <div class="d-flex flex-column justify-content-center">
                                                                        <h6 class="mb-0 text-xs">{data.size}</h6>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div class="d-flex px-2 py-1">

                                                                    <div class="d-flex flex-column justify-content-center">
                                                                        <h6 class="mb-0 text-xs">{data.whatsapp_no}</h6>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td style={{ whiteSpace: 'pre-wrap' }}>
                                                                <p class="text-xs font-weight-bold mb-0" style={{ width: "15rem" }}>{data.additional_info}</p>

                                                            </td>
                                                            <td style={{ whiteSpace: 'pre-wrap' }}>
                                                                <p class="text-xs font-weight-bold mb-0" style={{ width: "15rem" }}>{data.message}</p>

                                                            </td>
                                                            <td style={{ whiteSpace: 'pre-wrap' }}>
                                                                <p class="text-xs font-weight-bold mb-0" style={{ width: "15rem" }}>{data.email}</p>

                                                            </td>
                                                            <td>
                                                                <div class="d-flex px-2 py-1">
                                                                    <div style={{ width: "7rem", marginRight: "13px" }}>
                                                                        <img src={data.image_url} class="me-3" style={{ height: "4rem" }} />
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td class="align-middle text-center text-sm">
                                                                <span class="text-secondary text-xs font-weight-normal">{moment(data.created_date.toDate().toISOString()).format("MM/DD/YY, h:mm a")}</span>
                                                            </td>

                                                            <td style={{ whiteSpace: 'pre-wrap' }}>

                                                                <div class="d-flex px-2 py-1">
                                                                    <div class="d-flex flex-column justify-content-center">
                                                                        <h6 class="mb-0 text-xs">
                                                                            {data.fields && data.fields.length > 0 && (
                                                                                <>

                                                                                    {data.fields.reduce((latestField, field) => {
                                                                                        const fieldDate = field.updated_date.toDate();
                                                                                        if (fieldDate > latestField.updated_date.toDate()) {
                                                                                            latestField = field;
                                                                                        }
                                                                                        return latestField;
                                                                                    }, data.fields[0]).text}
                                                                                </>
                                                                            )}
                                                                        </h6>
                                                                    </div>
                                                                </div>

                                                                <button class="btn bg-gradient-dark btn-sm mb-0 mr-1" style={{ fontSize: "11px" }} onClick={() => addRemark(id)}>➕ </button>
                                                                <button class="btn bg-gradient-dark btn-sm mb-0" style={{ fontSize: "11px" }} onClick={() => viewRemark(data.fields)}>👁️</button>


                                                            </td>

                                                            {data.status == null ? <>
                                                                <td style={{ whiteSpace: "nowrap" }}>
                                                                    <select class="form-control" id={index} name="status" onClick={() => collect(id, data.owner_name, data.uid, data.phone_no)} onChange={handleChange2} value={data.status} style={{ padding: "7px", width: "9rem", backgroundColor: data.status == 1 ? "#28a745" : data.status == 2 ? "red" : data.status == 3 ? "yellow" : "transparent" }} disabled={data.status == 1 ? true : false} >
                                                                        <option selected={data.status === "0" ? true : false} value="0">Pending</option>
                                                                        <option selected={data.status === "1" ? true : false} value="1">Done</option>
                                                                        <option selected={data.status === "2" ? true : false} value="2">Rejected</option>
                                                                        <option selected={data.status === "3" ? true : false} value="3">Call Back request</option>
                                                                    </select>
                                                                </td>
                                                            </> : <>
                                                                {data.status === "2" ? <>
                                                                    <td>
                                                                        <select class="form-control" id={index} name="status" onClick={() => collect(id, data.owner_name, data.uid, data.phone_no)} value={data.status} style={{ padding: "7px", width: "9rem", backgroundColor: data.status == 1 ? "#28a745" : data.status == 2 ? "red" : data.status == 3 ? "yellow" : "transparent" }} disabled={data.status == 1 ? true : false} >
                                                                            <option selected={data.status === "2" ? true : false} value="2">Rejected</option>
                                                                        </select>
                                                                    </td>

                                                                </> : <>
                                                                    <td>
                                                                        <select class="form-control" id={index} name="status" onClick={() => collect(id, data.owner_name, data.uid, data.phone_no)} onChange={handleChange2} value={data.status} style={{ padding: "7px", width: "9rem", backgroundColor: data.status == 1 ? "#28a745" : data.status == 2 ? "red" : data.status == 3 ? "yellow" : "transparent" }} disabled={data.status == 1 ? true : false} >
                                                                            <option selected={data.status === "0" ? true : false} value="0">Pending</option>
                                                                            <option selected={data.status === "1" ? true : false} value="1">Done</option>
                                                                            <option selected={data.status === "2" ? true : false} value="2">Rejected</option>
                                                                            <option selected={data.status === "3" ? true : false} value="3">Call Back request</option>
                                                                        </select>
                                                                    </td>
                                                                </>}
                                                            </>}



                                                        </tr>

                                                    </>
                                                ))}


                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>




            </main>

            <div class="col-md-12">
                <div class="myId modal fade" id="" tabindex="-1" role="dialog" aria-labelledby="modal-form" aria-hidden="true" data-backdrop="static">
                    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div class="modal-content">
                            <div class="modal-body">
                                {remarks
                                    ?.sort((a, b) => b.updated_date.toDate() - a.updated_date.toDate()) // Sort the remarks array in descending order by updated_date
                                    .map((data, index) => (
                                        <>
                                            <div class="item" key={index}>
                                                <h5 class="company-name" style={{ fontSize: "1rem" }}>{data.text}</h5>
                                                <div class="job-info">
                                                    <div>{moment(data.updated_date.toDate().toISOString()).format("MM/DD/YY, h:mm a")}</div>
                                                </div>
                                                <span style={{ color: "#e91e63" }}>{data.updated_by}</span>
                                            </div>
                                            <hr width="100%" size="2" color="black" noshade />
                                        </>
                                    ))}

                                <div class="modal-footer">
                                    <a class="btn bg-gradient-info btn-sm mb-0" style={{ color: "white" }} onClick={closeModel}>Close</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >


        </>
    )
}

export default BannerRequest;