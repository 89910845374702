import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2'
import { useNavigate, Link, useParams } from 'react-router-dom';
import db from "./Firebase";
import { storage } from './Firebase';
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import Moment from 'moment';
import { extendMoment } from 'moment-range';

// function TestimonialSlider(props) {
//     const colorCode = props.data;

function TestimonialSlider() {

    let params = useParams();
    const moment = extendMoment(Moment);
    const [data, setData] = useState([]);
    const [edata, setEdata] = useState([]);

    const testi = () => {
        // window.websiteView();
        window.testimonial(edata);
        // alert(JSON.stringify(edata));
        // alert("2")
    }
    useEffect(() => {

        db.collection("parlour_feedback").where('username', '==', params.id.toLowerCase()).orderBy("created_date", "desc").onSnapshot((snapshot) => {
            setData(
                snapshot.docs.map((doc) => ({
                    id: doc.id,
                    data: doc.data(),
                }))
            );
        });

        // var allData = [];
        db.collection("parlour_feedback").where('username', '==', params.id.toLowerCase()).orderBy("created_date", "desc").onSnapshot((snapshot) => {
            snapshot.docs.map((e) => {
                // console.log(e.data().full_name)
                var data = {
                    "name": e.data().full_name,
                    "position": "",
                    "photo": e.data().image,
                    "text": e.data().review,

                };
                edata.push(data);
                // alert("1")
                testi();
                // alert(data)
            })
        });


    }, []);
    return (
        // console.log(data[0]?.data.review),
        // console.log(data[0]?.data.full_name),
        <>
            <div class="testimonial-container">

                {data.length == 0 ? null : <div class="progress-bar"></div>}

                {data.length == 0 ? <><p style={{ fontWeight: "600", fontSize: "28px", color: "beige" }}>No Reviews Yet</p></> : ""}
                {/* <div class="fas fa-quote-left fa-quote"></div>
                <div class="fas fa-quote-right fa-quote"></div> */}
                {/* <p class="testimonial pt-4" style={{ color: colorCode.color_code === "white" ? "black" : colorCode.color_code === "black" ? "white" : "white", }}>{data[0]?.data.review}</p> */}
                <p class="testimonial pt-4" style={{ color: "thistle" }}>{data[0]?.data.review}</p>
                <div class="user" style={{ display: "flex" }}>
                    <img src={data[0]?.data.image} style={{ display: "none" }} alt="user" class="user-image" />
                    <div class="user-details">
                        <h4 class="username" >{data[0]?.data.full_name}</h4>
                        <p class="role"></p>
                    </div>
                </div>
            </div>
        </>
    );
}

export default TestimonialSlider;

