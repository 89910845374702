import React, { useState, useEffect } from 'react';
import * as XLSX from 'xlsx';
import DataTable from 'react-data-table-component';
import { useNavigate, Link, useParams } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2'
import db from "./Firebase";
import { storage } from './Firebase';
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";


function UploadCourse() {
    let params = useParams();
    const navigate = useNavigate();

    const initialFormStateFeedback = { course_name: '', actual_course_fee: '', loan_amount: '', otp: '', type: '', programme_type: '', duration: '', description: '', twotp: '', organisation: '', authorization_code: '', created_date: new Date() }
    const [submit, setSubmit] = useState(initialFormStateFeedback)

    const [show, setshow] = useState(true);

    const [imgUrl, setImgUrl] = useState(null);
    const [progresspercent, setProgresspercent] = useState(0);

    const [columns, setColumns] = useState([]);
    const [data, setData] = useState([]);
    const [ShowProgress, setShowProgress] = useState(false);
    const [showExcel, setshowExcel] = useState(false);

    const processData = dataString => {
        const dataStringLines = dataString.split(/\r\n|\n/);
        const headers = dataStringLines[0].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);

        const list = [];
        for (let i = 1; i < dataStringLines.length; i++) {
            const row = dataStringLines[i].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);
            if (headers && row.length == headers.length) {
                const obj = {};
                for (let j = 0; j < headers.length; j++) {
                    let d = row[j];
                    if (d.length > 0) {
                        if (d[0] == '"')
                            d = d.substring(1, d.length - 1);
                        if (d[d.length - 1] == '"')
                            d = d.substring(d.length - 2, 1);
                    }
                    if (headers[j]) {
                        obj[headers[j]] = d;
                    }
                }

                // remove the blank rows
                if (Object.values(obj).filter(x => x).length > 0) {
                    list.push(obj);
                }
            }
        }

        // prepare columns list from headers
        const columns = headers.map(c => ({
            name: c,
            selector: c,
        }));

        setData(list);
        setColumns(columns);
        console.log(list)
    }

    const handleFileUpload = e => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onload = (evt) => {
            /* Parse data */
            const bstr = evt.target.result;
            const wb = XLSX.read(bstr, { type: 'binary' });
            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            /* Convert array of arrays */
            const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
            processData(data);
        };
        reader.readAsBinaryString(file);
    }

    const [num, setNum] = useState(0);
    const submitdata = (e) => {
        setShowProgress(true);
        data.map((user, index) => {
            setNum(index + 1);
            db.collection("consumables_data").add({
                'course_name': user.course_name,
                'actual_course_fee': user.actual_course_fee,
                'loan_amount': user.loan_amount,
                'otp': user.otp,
                'programme_type': user.programme_type,
                'type': user.mode,
                'duration': user.duration,
                'description': user.description,
                '2tp': user.two_tp,
                'organisation': user.organisation,
                'authorization_code': user.authorization_code,
                'image': user.image,
                'created_date': new Date(),
            });
        })
    };



    const handleChange = (e) => {
        const { id, value } = e.target
        setSubmit({ ...submit, [id]: value })
    }

    const reset = () => {
        setSubmit({ course_name: '', actual_course_fee: '', loan_amount: '', otp: '', type: '', programme_type: '', duration: '', description: '', twotp: '', organisation: '', authorization_code: '', created_date: new Date() })
        navigate(`/quicklinks`);
    }
    const ids = ["rahul_122002", "ashutosh_560001", "mrinal_122004"];

    const handleSubmit = (e) => {
        // console.log(submit.usercode)
        // alert("okkk")
        e.preventDefault()
        if (ids.includes(submit.authorization_code)) {
            console.log("callingg 1")
            const file = e.target[0]?.files[0]
            // console.log(file)
            if (!file) return;
            const storageRef = ref(storage, `files/${file.name}`);
            const uploadTask = uploadBytesResumable(storageRef, file);
            setshow(false)

            uploadTask.on("state_changed",
                (snapshot) => {
                    const progress =
                        Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                    setProgresspercent(progress);
                },
                (error) => {
                    alert(error);
                },
                () => {
                    console.log("callingg 2")
                    getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                        // setImgUrl(downloadURL)
                        db.collection("course_data").add({
                            'course_name': submit.course_name,
                            'actual_course_fee': submit.actual_course_fee,
                            'loan_amount': submit.loan_amount,
                            'otp': submit.otp,
                            'type': submit.type,
                            'programme_type': submit.programme_type,
                            'duration': submit.duration,
                            'description': submit.description,
                            '2tp': submit.twotp,
                            'organisation': submit.organisation,
                            'authorization_code': submit.authorization_code,
                            'image': downloadURL,
                            'created_date': new Date(),
                        }).then(() => {
                            console.log("callingg 3")
                            console.log('Submitted Successfully');
                            const Toast = Swal.mixin({
                                toast: true,
                                background: '#69aba6',
                                position: 'top-end',
                                showConfirmButton: false,
                                timer: 3000,
                                timerProgressBar: true,
                                didOpen: (toast) => {
                                    toast.addEventListener('mouseenter', Swal.stopTimer)
                                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                                }
                            })

                            Toast.fire({
                                icon: 'success',
                                title: 'Submitted Successfully'
                            })
                            setshow(true)
                            // navigate(`/${submit.username}`);
                            setSubmit({ course_name: '', actual_course_fee: '', loan_amount: '', otp: '', type: '', programme_type: '', duration: '', description: '', twotp: '', organisation: '', authorization_code: '', created_date: new Date() })
                        })
                    });
                }
            );
        } else {
            alert("You are not authorization to perform this action")
        }
    }

    useEffect(() => {
        // alert(params.id)
    }, []);



    return (

        <>
            {!showExcel ?
                <div id="small-dialog" class="m-3">
                    <div class="row p-1" style={{ float: "right" }}>
                        <button type="button" class="btn-sm" style={{ backgroundColor: "#337ab7", color: "white" }} onClick={() => setshowExcel(true)}>Upload Excel Data</button>
                    </div>
                    <div class="w3layouts_payment_online_form">
                        <form class="" id="" onSubmit={handleSubmit}>
                            <h4>Upload Course</h4>
                            <ul>
                                <li style={{ textAlign: "left" }}><label for="myfile">Course Image:</label><input type="file" id="image" name="Image" accept="image/*" />
                                </li>
                                <li><input class="w3l_text_box" id="course_name" onChange={handleChange} value={submit.course_name} placeholder="Course Name" name="Course Name" type="text" required />
                                </li>
                            </ul>
                            <ul>
                                <li>
                                    <input class="w3l_text_box" id="actual_course_fee" onChange={handleChange} value={submit.actual_course_fee} placeholder="Actual Course Fee" name="Actual Course Fee" type="number" required />
                                </li>
                                <li>
                                    <input class="w3l_text_box" id="duration" placeholder="Duration" onChange={handleChange} value={submit.duration} name="Duration" type="text" required />
                                </li>
                            </ul>
                            <ul>
                                <li>
                                    <input class="w3l_text_box" id="otp" placeholder="One Time Payment" onChange={handleChange} value={submit.otp} name="One Time Payment" type="number" required="" />
                                </li>
                                <li>
                                    <input class="w3l_text_box" id="twotp" placeholder="Two Time Payment" onChange={handleChange} value={submit.twotp} name="Two Time Payment" type="text" required="" />
                                </li>
                            </ul>
                            <ul>
                                <li>
                                    {/* <label>Type</label> */}
                                    <select class="form-control" id="type" onChange={handleChange} required>
                                        <option value="">--Select Mode--</option>
                                        <option value="Online">Online</option>
                                        <option value="Offline">Offline</option>
                                    </select>
                                </li>
                                <li>
                                    {/* <label>Type</label> */}
                                    <select class="form-control" id="programme_type" onChange={handleChange} required>
                                        <option value="">--Select Certificate type--</option>
                                        <option value="Certificate Programme">Certificate Programme</option>
                                        <option value="Non Certificate Programme">Non Certificate Programme</option>
                                    </select>
                                </li>
                            </ul>
                            <ul>
                                <li>
                                    <input class="w3l_text_box" id="loan_amount" placeholder="Loan Amount" onChange={handleChange} value={submit.loan_amount} name="Loan Amount" type="number" required="" />
                                </li>
                                <li>
                                    <input class="w3l_text_box" id="organisation" placeholder="Organisation" onChange={handleChange} value={submit.organisation} name="Organisation" type="text" required="" />
                                </li>
                            </ul>
                            <ul>
                                <li>
                                    <input class="w3l_text_box" id="authorization_code" placeholder="Authorization Code" onChange={handleChange} value={submit.authorization_code} name="Authorization Code" type="text" required />
                                </li>
                                <li></li>
                            </ul>
                            <ul>
                                <li class="w3ls_address"><input class="w3l_text_box" id="description" onChange={handleChange} value={submit.description} placeholder="Course Description Here....." name="Description" type="text" style={{ height: "6rem" }} required /></li>
                            </ul>

                            <ul class="w3_payment_sendbtns">
                                <li><input type="reset" data-dismiss="modal" aria-label="Close" onClick={reset} value="Cancel" /></li>
                                <li>{show ? <input type="submit" value="Submit" /> : "Please Wait..."}</li>
                            </ul>
                            <div class="clearfix"> </div>
                        </form>
                    </div>
                </div>
                :
                <>
                    <div id="small-dialog" class="m-3">
                        <div class="row p-1" style={{ float: "right" }}>
                            <a class="mr-3" href="/files/reffer_course.xlsx" download><button type="button" class="btn-sm" style={{ backgroundColor: "#337ab7", color: "white" }} onClick={() => setshowExcel(true)}>Download Sample For Course</button></a>
                            <button type="button" class="btn-sm" style={{ backgroundColor: "#337ab7", color: "white" }} onClick={() => setshowExcel(false)}>Back</button>
                        </div>
                        <br />
                        <div class="card-body">
                            <div class="form-group justify-content-end row">
                                <div class="col-lg-12">
                                    <div class="form-control dropzone dropzone-default dropzone-primary dz-clickable h-auto px-4 py-2" style={{ background: "aquamarine", textAlign: "center" }}>
                                        <div class="dropzone-msg dz-message needsclick" style={{ borderStyle: "dashed", padding: "1rem", borderColor: "orange" }}>
                                            <input type="file" accept=".csv,.xlsx,.xls" onChange={handleFileUpload} />
                                            <span class="font-size-base"><i class="fas fa-cloud-upload-alt icon-md mr-1"></i>Drop files here or click.</span><br />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <DataTable
                            pagination
                            highlightOnHover
                            columns={columns}
                            data={data}
                        />

                        {data.length > 0 ? <> <div class="card-footer d-flex justify-content-end py-2">
                            <button class="btn btn-success mr-2" onClick={() => submitdata()}>Upload Data</button>
                            <div class="btn btn-secondary" role="button">Cancel</div>
                        </div></> : null
                        }

                        {ShowProgress ? <><h2 style={{ background: "darkorange", padding: "1rem", textAlign: "center", paddingBottom: "1rem", color: "white" }}>{num == data.length ? "Data Uploaded" : "Uploading Data"} - {num}/{data.length}</h2></> : ""}
                    </div>
                </>


            }
        </>


    );
}

export default UploadCourse;