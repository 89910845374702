import React, { useState, useEffect } from "react";
import { useNavigate, Link, useParams } from 'react-router-dom';
import Swal from 'sweetalert2'
import db from "./Firebase";
import { storage } from './Firebase';
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import Moment from 'moment';
import { extendMoment } from 'moment-range';

function MarkAttendance() {
    let params = useParams();
    const moment = extendMoment(Moment);
    const [data, setData] = useState([]);
    const [progress, setProgress] = useState(false);
    const initialFormStateFeedback = { full_name: '', phone_no: '', workshop_name: params.id, event_name: '', created_date: new Date() }
    const [submit, setSubmit] = useState(initialFormStateFeedback)



    const handleChange = (e) => {
        const { id, value } = e.target
        setSubmit({ ...submit, [id]: value })
    }


    useEffect(() => {
        db.collection("workshop_mark_attendance_status").onSnapshot((snapshot) => {

            setData(
                snapshot.docs.map((doc) => ({
                    id: doc.id,
                    data: doc.data(),
                }))
            );
        });

    }, []);


    const handleSubmit = (e, msg) => {
        e.preventDefault();
        setProgress(true);

        // Check if a record with the same phone number and workshop name already exists
        db.collection("workshop_attendance")
            .where("phone_no", "==", submit.phone_no)
            .where("workshop_name", "==", params.id)
            .get()
            .then((querySnapshot) => {
                if (!querySnapshot.empty) {
                    // Record already exists, show an alert or take appropriate action
                    alert("Attendance already marked for this workshop");
                    setProgress(false);
                } else {
                    // Record doesn't exist, proceed to add the new attendance record
                    db.collection("workshop_attendance").add({
                        'full_name': submit.full_name,
                        'phone_no': submit.phone_no,
                        'workshop_name': params.id,
                        'event_name': "From Website",
                        'created_date': new Date(),
                    }).then(() => {
                        const Toast = Swal.mixin({
                            toast: true,
                            background: 'white',
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 3000,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener('mouseenter', Swal.stopTimer);
                                toast.addEventListener('mouseleave', Swal.resumeTimer);
                            }
                        });

                        Toast.fire({
                            icon: 'success',
                            title: 'Attendance Marked Successfully'
                        });

                        // Reset the form
                        setSubmit({ full_name: '', phone_no: '', workshop_name: params.id, event_name: '', created_date: new Date() });
                        setProgress(false);
                    });
                }
            })
            .catch((error) => {
                console.error("Error checking attendance:", error);
                setProgress(false);
            });
    };



    // const handleSubmit = (e, msg) => {
    //     e.preventDefault()
    //     setProgress(true)

    //     db.collection("workshop_attendance").add({
    //         'full_name': submit.full_name,
    //         'phone_no': submit.phone_no,
    //         'workshop_name': params.id,
    //         'event_name': "",
    //         'created_date': new Date(),
    //     }).then(() => {
    //         const Toast = Swal.mixin({
    //             toast: true,
    //             background: 'white',
    //             position: 'top-end',
    //             showConfirmButton: false,
    //             timer: 3000,
    //             timerProgressBar: true,
    //             didOpen: (toast) => {
    //                 toast.addEventListener('mouseenter', Swal.stopTimer)
    //                 toast.addEventListener('mouseleave', Swal.resumeTimer)
    //             }
    //         })

    //         Toast.fire({
    //             icon: 'success',
    //             title: 'Attendance Marked Successfully'
    //         })
    //         // setProgress(false)
    //         // navigate(`/${submit.username}`);
    //         setSubmit({ full_name: '', phone_no: '', workshop_name: params.id, event_name: '', created_date: new Date() })
    //     })
    // }







    return (
        <>

            {/* <div class="card-header pb-0 px-3" style={{ display: "flex", justifyContent: "center" }}>
                <h6 class="mb-2">Workshop Attendance Status</h6>
            </div> */}
            <div class="card-header pb-0 px-3" style={{ display: "flex", justifyContent: "center" }}>
                <div class="p-5" style={{ border: "8px solid #ccc", borderRadius: "4px" }}>
                    <div class="">
                        <form onSubmit={handleSubmit}>
                            <div class="">
                                <div class="" style={{ textAlign: "center" }}>
                                    <span style={{ color: "black", fontSize: "25px", fontWeight: "700", textTransform: "uppercase" }}>Mark Attendance</span>
                                </div>
                                <div class="" style={{ textAlign: "center", color: "black", paddingTop: "10px" }}>
                                </div>
                                <div class="">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <input style={{
                                                height: "45px",
                                                padding: "12px 20px",
                                                boxSizing: "border-box",
                                                border: "2px solid #ccc",
                                                borderRadius: "4px",
                                                backgroundColor: "#f8f8f8",
                                                fontSize: "16px",
                                                resize: "none"
                                            }} class="w3l_text_box" id="full_name" placeholder="Full Name" name="Full Name" type="text" onChange={handleChange} value={submit.full_name} required />
                                        </div>
                                        <div class="col-md-4">
                                            <input style={{
                                                height: "45px",
                                                padding: "12px 20px",
                                                boxSizing: "border-box",
                                                border: "2px solid #ccc",
                                                borderRadius: "4px",
                                                backgroundColor: "#f8f8f8",
                                                fontSize: "16px",
                                                resize: "none"
                                            }} class="w3l_text_box" id="phone_no" placeholder="Phone Number" name="Phone Number" type="number" onChange={handleChange} value={submit.phone_no} required />
                                        </div>
                                        <div class="col-md-4">
                                            <input style={{
                                                height: "45px",
                                                padding: "12px 20px",
                                                boxSizing: "border-box",
                                                border: "2px solid #ccc",
                                                borderRadius: "4px",
                                                backgroundColor: "#f8f8f8",
                                                fontSize: "16px",
                                                resize: "none"
                                            }} class="w3l_text_box" id="workshop_name" placeholder="Workshop Name" name="Workshop Name" type="text" onChange={handleChange} value={submit.workshop_name} required />
                                        </div>
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    {progress ? <></> : <button type='submit' class="btn bg-gradient-danger btn-sm mb-0" style={{ backgroundColor: "green", color: "white" }}>Submit</button>}
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>




        </>
    )
}

export default MarkAttendance
