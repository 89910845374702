import React, { useState, useEffect } from "react";

function ServicesList() {
    const [servicesArray, setServicesArray] = useState([])

    useEffect(() => {


    }, []);

    return (
        <>

            <section class="pb-5" id="services">

                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="center-heading">
                                <h2 class="section-title">Our Services</h2>
                            </div>
                        </div>
                    </div>

                    <div class="row">

                        <div class="col-lg-3 col-sm-6">
                            <a href="#" class="services-small-item">
                                <div class="pb-2">
                                    <p> <img src="img/facial_cleanup.png" alt=" " class="img-responsive" /></p>
                                </div>
                                <h5 class="services-title">Bridal Makeup</h5>
                                {/* <div class="row"> */}
                                <p style={{ display: "flex" }}>Estimated Time: 2 Hrs</p>
                                {/* </div> */}
                                <p style={{ display: "flex" }}>Discount: 10% Off</p>
                                <p style={{ display: "flex" }}>Price: Rs 550/- Off</p>

                                <p class="pt-2">Lorem Ipsum is simply dummy text of the printing and typesetting industry. </p>
                                <div class="button" style={{ paddingTop: "2rem" }}>
                                    <i class="fa">Book Now</i>
                                </div>

                            </a>
                            <div class="item-bg"></div>
                        </div>




                    </div>
                </div>
            </section>
        </>
    )
}

export default ServicesList