import React, { useState, useEffect } from 'react';
import * as XLSX from 'xlsx';
import DataTable from 'react-data-table-component';
import { useNavigate, Link, useParams } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2'
import db from "./Firebase";
import { storage } from './Firebase';
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";


function GraphTest() {
    let params = useParams();
    const navigate = useNavigate();

    const [data, setData] = useState([]);


    const group = ["student", 'parlour', 'freelancer', 'student', 'student', 'freelancer'];

    // const [idmapping, setIdmapping] = useState([]);


    useEffect(() => {
        var arr1 = [];
        var arr2 = [];
        var arr3 = [];
        var arr4 = [];
        db.collection("personal_details").onSnapshot((snapshot) => {

            // setCourseEnrollment(
            //     snapshot.docs.map((doc) => ({
            //         data: doc.data(),
            //     }))
            // );
            snapshot.docs.map((doc) => (
                // console.log(doc.data().type),
                doc.data().type == 'freelancer' ? arr1.push(doc.data().type) : null,
                doc.data().type == 'parlour' ? arr2.push(doc.data().type) : null,
                doc.data().type == 'student' ? arr3.push(doc.data().type) : null,
                doc.data().type == 'saloon' ? arr4.push(doc.data().type) : null
            ))

            var arrLen = [];
            arrLen.push(arr1.length)
            arrLen.push(arr2.length)
            arrLen.push(arr3.length)
            arrLen.push(arr4.length)
            console.log(arrLen)
        });

    }, []);

    const submit = () => {
    }

    const fetch = () => {

    }



    return (
        <>

            <button class="btn btn-success mr-2" onClick={() => submit()} >Upload Data</button>
            <button class="btn btn-success mr-2" onClick={() => fetch()} >Fetch Data</button>
        </>


    );
}
export default GraphTest;