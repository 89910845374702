import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import db from "./Firebase";
import Moment from 'moment';
import { extendMoment } from 'moment-range';

function AllAppointments() {
    const moment = extendMoment(Moment);
    const [data, setData] = useState([]);
    const [dataLimit, setDataLimit] = useState(20);

    useEffect(() => {
        db.collection("appointments_data").orderBy("date", "desc").onSnapshot((snapshot) => {
            const fetchedData = snapshot.docs.map((doc) => ({
                id: doc.id,
                data: doc.data(),
            }));
            setData(fetchedData);
        });
    }, []);

    const limitedData = data.slice(0, dataLimit);

    const loadMoreData = () => {
        setDataLimit(dataLimit + 20);
    };

    return (
        <>
            <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
                <div className="p-2" style={{ textAlign: "start", paddingBottom: "5px" }}>
                    <Link to="/admin_panel"><img src="/images/menubutton.png" className="menu-toggle" alt="Responsive image" style={{ height: "2rem" }} /></Link>
                </div>
                <div className="2">
                    <div className="p-2">
                        <div className="card">
                            <div className="card-header pb-0 px-3">
                                <h6 className="mb-2">Booked Appointment</h6>
                            </div>
                            <div className="">
                                <div className="card">
                                    <div className="table-responsive fix-width scroll-inner">
                                        <table className="table table-bordered table-hover">
                                            <thead className="thead-dark" style={{ position: "sticky", top: "0" }}>
                                                <tr style={{ textAlign: "left" }}>
                                                    <th className="ps-2">Username</th>
                                                    <th className="ps-2">Parlour Name</th>
                                                    <th className="">Customer Name</th>
                                                    <th className="ps-2">Created Date</th>
                                                    <th className="ps-2">App. Date</th>
                                                </tr>
                                            </thead>
                                            <tbody style={{ textAlign: "left" }}>
                                                {limitedData.map(({ id, data }, index) => (
                                                    <tr key={id}>
                                                        <td>
                                                            <span style={{ color: "black" }} className="mb-0">{data.username}</span>
                                                        </td>
                                                        <td>
                                                            <span className="text-xs mb-0">{data.parlour}</span>
                                                        </td>
                                                        <td>
                                                            <span className="text-xs mb-0">{data.phone} || {data.name}</span>
                                                        </td>
                                                        <td>
                                                            <span className="text-xs mb-0">{moment(data.date.toDate().toISOString()).format("MM/DD/YY, h:mm a")}</span>
                                                        </td>
                                                        <td>
                                                            <span className="text-xs mb-0">{moment(data.booked_date.toDate().toISOString()).format("MM/DD/YY")} || {data.time}</span>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>

                                    </div>
                                    <div className="" style={{ paddingTop: "2rem" }}>
                                        <button onClick={loadMoreData} className="btn btn-dark">Load More</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
}

export default AllAppointments;


// import React, { useState, useEffect } from 'react';
// import { useNavigate, Link, useParams } from 'react-router-dom';
// import db from "./Firebase";
// import firebaseApp from "./Firebase";
// import { arrayUnion } from "firebase/firestore";
// import { storage } from './Firebase';
// import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
// import Moment from 'moment';
// import { extendMoment } from 'moment-range';
// import ViewAllRequests from './ViewAllRequests';
// import ViewAppData from './ViewAppData';
// import { CSVLink } from "react-csv";

// function AllAppointments() {
//     const moment = extendMoment(Moment);
//     const [data, setData] = useState([]);

//     useEffect(() => {
//         db.collection("appointments_data").orderBy("date", "desc").onSnapshot((snapshot) => {

//             setData(
//                 snapshot.docs.map((doc) => ({
//                     id: doc.id,
//                     data: doc.data(),
//                 }))
//             );

//         });

//     }, []);

//     return (
//         <>
//             {/* <ViewAppData /> */}
//             <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
//                 <div class="p-2" style={{ textAlign: "start", paddingBottom: "5px" }}>
//                     {/* <span class="btn bg-gradient-secondary btn-sm mb-0 mr-2" style={{ color: "white" }} >&nbsp; Download Excel</span> */}
//                     <Link to="/admin_panel"><img src="/images/menubutton.png" class="menu-toggle" alt="Responsive image" style={{ height: "2rem" }} /></Link>

//                 </div>
//                 <div class="2">


//                     <div class="p-2">

//                         <div class="card">
//                             <div class="card-header pb-0 px-3">
//                                 <h6 class="mb-2">Booked Appointment</h6>
//                             </div>
//                             <div class="">
//                                 <div class="card">
//                                     <div class="table-responsive fix-width scroll-inner">
//                                         <table class="table table-bordered table-hover">
//                                             <thead class="thead-dark" style={{ position: "sticky", top: "0" }}>
//                                                 <tr style={{ textAlign: "left" }}>
//                                                     <th class=" ps-2">Username</th>
//                                                     <th class=" ps-2">Parlour Name</th>
//                                                     <th class="">Customer Name</th>
//                                                     {/* <th class=" ps-2">Customer Phone No.</th> */}
//                                                     <th class=" ps-2">Created Date</th>
//                                                     <th class=" ps-2">App. Date</th>
//                                                 </tr>
//                                             </thead>
//                                             <tbody style={{ textAlign: "left" }}>
//                                                 {data?.map(({ id, data }, index) => (
//                                                     <>
//                                                         <tr>

//                                                             <td>
//                                                                 <span style={{ color: "black" }} class="mb-0">{data.username}</span>
//                                                                 {/* <span class="text-xs text-secondary mb-0">{moment(data.created_date.toDate().toISOString()).format("MM/DD/YY, h:mm a")}</span> */}
//                                                             </td>
//                                                             <td>
//                                                                 <span class="text-xs mb-0">{data.parlour}</span>
//                                                                 {/* <span class="text-xs text-secondary mb-0">{moment(data.created_date.toDate().toISOString()).format("MM/DD/YY, h:mm a")}</span> */}
//                                                             </td>
//                                                             <td>
//                                                                 <span class="text-xs mb-0">{data.phone} || {data.name}</span>
//                                                                 {/* <span class="text-xs text-secondary mb-0">{moment(data.created_date.toDate().toISOString()).format("MM/DD/YY, h:mm a")}</span> */}
//                                                             </td>
//                                                             {/* <td>
//                                                                 <span class="text-xs mb-0">{data.phone}</span>
//                                                                 <span class="text-xs text-secondary mb-0">{moment(data.created_date.toDate().toISOString()).format("MM/DD/YY, h:mm a")}</span>
//                                                             </td> */}
//                                                             <td>
//                                                                 <span class="text-xs mb-0">{moment(data.date.toDate().toISOString()).format("MM/DD/YY, h:mm a")}</span>
//                                                                 {/* <span class="text-xs text-secondary mb-0">{moment(data.created_date.toDate().toISOString()).format("MM/DD/YY, h:mm a")}</span> */}
//                                                             </td>
//                                                             <td>
//                                                                 <span class="text-xs mb-0">{moment(data.booked_date.toDate().toISOString()).format("MM/DD/YY")} || {data.time}</span>
//                                                                 {/* <span class="text-xs text-secondary mb-0">{moment(data.created_date.toDate().toISOString()).format("MM/DD/YY, h:mm a")}</span> */}
//                                                             </td>



//                                                         </tr>

//                                                     </>
//                                                 ))}


//                                             </tbody>
//                                         </table>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>

//                 </div>

//             </main>


//         </>
//     )
// }

// export default AllAppointments;