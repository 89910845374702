import React, { useState, useEffect } from 'react';
import AdminPanel from './AdminPanel';
import { useNavigate, Link, useParams } from 'react-router-dom';
import Swal from 'sweetalert2'
import db from "./Firebase";
import { arrayUnion } from "firebase/firestore";
import { storage } from './Firebase';
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import ViewAppData from './ViewAppData';

function CourseData() {
    const navigate = useNavigate();
    const initialFormStateFeedback = { id: '', description: '', minimum_payment: '', duration: '', programme_type: '', actual_course_fee: '', contact_info: '', course_name: '', img: '', key_points: '', loan_amount: '', otp: '', provider: '', referral_money: '', serial_no: '', slider_images: [], sub_duration: '', type: '', created_date: new Date() }
    const [submit, setSubmit] = useState(initialFormStateFeedback)
    const [view, setView] = useState(false);
    const [data, setData] = useState([]);
    const [editID, setEditID] = useState("");
    const [edit, setEdit] = useState(false);
    const [ShowProgress, setShowProgress] = useState(false);
    const [show, setshow] = useState(true);
    const [progresspercent, setProgresspercent] = useState(0);
    const [simg, setSimg] = useState([]);
    const [form, setForm] = useState(false);

    useEffect(() => {
        const storedUsername = localStorage.getItem("email");
        const storedPassword = localStorage.getItem("password");
        const storedRemember = localStorage.getItem("remember");

        if (storedRemember === "false" || !storedUsername || !storedPassword) {
            navigate('/signin');
        }
    }, []);

    const handleChange = (e) => {
        const { id, value } = e.target
        setSubmit({ ...submit, [id]: value })
    }

    const open = () => {
        setView(true);
    }

    const changeStatusForm = (data, id) => {
        // console.log(data.slider_images)
        setForm(true)
        setSimg(data.slider_images)
        setEdit(true)
        setEditID(id)
        setSubmit({ id: id, description: data.Description, minimum_payment: data.minimum_payment, duration: data.Duration, programme_type: data.Programme_type, actual_course_fee: data.actual_course_fee, contact_info: data.contact_info, course_name: data.course_name, img: data.img, key_points: data.key_points, loan_amount: data.loan_amount, otp: data.otp, provider: data.provider, referral_money: data.referral_money, serial_no: data.serial_no, slider_images: [], sub_duration: data.sub_duration, type: data.type, created_date: new Date() });
        openModalShow();
    }

    const changeStatusSlider = (data, id) => {
        // console.log(data.slider_images)
        setForm(false)
        setSimg(data.slider_images)
        setEdit(true)
        setEditID(id)
        setSubmit({ id: id, description: data.Description, minimum_payment: data.minimum_payment, duration: data.Duration, programme_type: data.Programme_type, actual_course_fee: data.actual_course_fee, contact_info: data.contact_info, course_name: data.course_name, img: data.img, key_points: data.key_points, loan_amount: data.loan_amount, otp: data.otp, provider: data.provider, referral_money: data.referral_money, serial_no: data.serial_no, slider_images: [], sub_duration: data.sub_duration, type: data.type, created_date: new Date() });
        openModalShow();
    }

    const addNew = () => {
        setSubmit({ id: '', description: '', minimum_payment: '', duration: '', programme_type: '', actual_course_fee: '', contact_info: '', course_name: '', img: '', key_points: '', loan_amount: '', otp: '', provider: '', referral_money: '', serial_no: '', slider_images: [], sub_duration: '', type: '', created_date: new Date() });
        setEdit(false)
        setForm(true)
        openModalShow();
    }

    const openModalShow = () => {
        // alert("ooo")
        window.openModelshow();
    }

    const closeModel = () => {
        window.openModelNew();
    }

    const handleSubmit = (e) => {
        console.log("callingg")
        e.preventDefault()

        if (edit) {
            console.log("edit")
            if (e.target[0]?.files[0] == null) {
                db.collection("course_data").doc(editID).update({
                    'Description': submit.description,
                    'minimum_payment': parseInt(submit.minimum_payment),
                    'Duration': submit.duration,
                    'Programme_type': submit.programme_type,
                    'actual_course_fee': parseInt(submit.actual_course_fee),
                    'contact_info': submit.contact_info,
                    'course_name': submit.course_name,
                    'img': submit.img,
                    'key_points': submit.key_points,
                    'loan_amount': parseInt(submit.loan_amount),
                    'otp': parseInt(submit.otp),
                    'provider': submit.provider,
                    'referral_money': parseInt(submit.referral_money),
                    'serial_no': submit.serial_no,
                    'slider_images': simg,
                    'sub_duration': submit.sub_duration,
                    'type': submit.type,
                    created_date: new Date()
                }).then(() => {
                    console.log('Updated Successfully');
                    const Toast = Swal.mixin({
                        toast: true,
                        background: '#69aba6',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', Swal.stopTimer)
                            toast.addEventListener('mouseleave', Swal.resumeTimer)
                        }
                    })

                    Toast.fire({
                        icon: 'success',
                        title: 'Updated Successfully'
                    })
                    closeModel();

                    setSubmit({ id: '', description: '', minimum_payment: '', duration: '', programme_type: '', actual_course_fee: '', contact_info: '', course_name: '', img: '', key_points: '', loan_amount: '', otp: '', provider: '', referral_money: '', serial_no: '', slider_images: [], sub_duration: '', type: '', created_date: new Date() })
                })
            } else {
                const file = e.target[0]?.files[0]
                // console.log(file)
                if (!file) return;
                const storageRef = ref(storage, `files/${file.name}`);
                const uploadTask = uploadBytesResumable(storageRef, file);
                setshow(false)

                uploadTask.on("state_changed",
                    (snapshot) => {
                        const progress =
                            Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                        setProgresspercent(progress);
                    },
                    (error) => {
                        alert(error);
                    },
                    () => {

                        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                            // setImgUrl(downloadURL)
                            db.collection("course_data").doc(editID).update({
                                'Description': submit.description,
                                'minimum_payment': parseInt(submit.minimum_payment),
                                'Duration': submit.duration,
                                'Programme_type': submit.programme_type,
                                'actual_course_fee': parseInt(submit.actual_course_fee),
                                'contact_info': submit.contact_info,
                                'course_name': submit.course_name,
                                'img': downloadURL,
                                'key_points': submit.key_points,
                                'loan_amount': parseInt(submit.loan_amount),
                                'otp': parseInt(submit.otp),
                                'provider': submit.provider,
                                'referral_money': parseInt(submit.referral_money),
                                'serial_no': submit.serial_no,
                                'slider_images': simg,
                                'sub_duration': submit.sub_duration,
                                'type': submit.type,
                                created_date: new Date()
                            }).then(() => {
                                console.log('Updated Successfully');
                                const Toast = Swal.mixin({
                                    toast: true,
                                    background: '#69aba6',
                                    position: 'top-end',
                                    showConfirmButton: false,
                                    timer: 3000,
                                    timerProgressBar: true,
                                    didOpen: (toast) => {
                                        toast.addEventListener('mouseenter', Swal.stopTimer)
                                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                                    }
                                })

                                Toast.fire({
                                    icon: 'success',
                                    title: 'Updated Successfully'
                                })
                                closeModel();
                                setshow(true)
                                setSubmit({ id: '', description: '', minimum_payment: '', duration: '', programme_type: '', actual_course_fee: '', contact_info: '', course_name: '', img: '', key_points: '', loan_amount: '', otp: '', provider: '', referral_money: '', serial_no: '', slider_images: [], sub_duration: '', type: '', created_date: new Date() })
                            })
                        });
                    }
                );
            }

        } else {
            if (e.target[0]?.files[0] == null) {
                db.collection("course_data").add({
                    'Description': submit.description,
                    'minimum_payment': parseInt(submit.minimum_payment),
                    'Duration': submit.duration,
                    'Programme_type': submit.programme_type,
                    'actual_course_fee': parseInt(submit.actual_course_fee),
                    'contact_info': submit.contact_info,
                    'course_name': submit.course_name,
                    'img': submit.img,
                    'key_points': submit.key_points,
                    'loan_amount': parseInt(submit.loan_amount),
                    'otp': parseInt(submit.otp),
                    'provider': submit.provider,
                    'referral_money': parseInt(submit.referral_money),
                    'serial_no': submit.serial_no,
                    'slider_images': simg,
                    'sub_duration': submit.sub_duration,
                    'type': submit.type,
                    created_date: new Date()
                }).then(() => {
                    console.log('Created Successfully');
                    const Toast = Swal.mixin({
                        toast: true,
                        background: '#69aba6',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', Swal.stopTimer)
                            toast.addEventListener('mouseleave', Swal.resumeTimer)
                        }
                    })

                    Toast.fire({
                        icon: 'success',
                        title: 'Created Successfully'
                    })
                    closeModel();

                    setSubmit({ id: '', description: '', minimum_payment: '', duration: '', programme_type: '', actual_course_fee: '', contact_info: '', course_name: '', img: '', key_points: '', loan_amount: '', otp: '', provider: '', referral_money: '', serial_no: '', slider_images: [], sub_duration: '', type: '', created_date: new Date() })
                })
            } else {
                const file = e.target[0]?.files[0]
                // console.log(file)
                if (!file) return;
                const storageRef = ref(storage, `files/${file.name}`);
                const uploadTask = uploadBytesResumable(storageRef, file);
                setshow(false)

                uploadTask.on("state_changed",
                    (snapshot) => {
                        const progress =
                            Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                        setProgresspercent(progress);
                    },
                    (error) => {
                        alert(error);
                    },
                    () => {

                        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                            // setImgUrl(downloadURL)
                            db.collection("course_data").add({
                                'Description': submit.description,
                                'minimum_payment': parseInt(submit.minimum_payment),
                                'Duration': submit.duration,
                                'Programme_type': submit.programme_type,
                                'actual_course_fee': parseInt(submit.actual_course_fee),
                                'contact_info': submit.contact_info,
                                'course_name': submit.course_name,
                                'img': downloadURL,
                                'key_points': submit.key_points,
                                'loan_amount': parseInt(submit.loan_amount),
                                'otp': parseInt(submit.otp),
                                'provider': submit.provider,
                                'referral_money': parseInt(submit.referral_money),
                                'serial_no': submit.serial_no,
                                'slider_images': simg,
                                'sub_duration': submit.sub_duration,
                                'type': submit.type,
                                created_date: new Date()
                            }).then(() => {
                                console.log('Created Successfully');
                                const Toast = Swal.mixin({
                                    toast: true,
                                    background: '#69aba6',
                                    position: 'top-end',
                                    showConfirmButton: false,
                                    timer: 3000,
                                    timerProgressBar: true,
                                    didOpen: (toast) => {
                                        toast.addEventListener('mouseenter', Swal.stopTimer)
                                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                                    }
                                })

                                Toast.fire({
                                    icon: 'success',
                                    title: 'Created Successfully'
                                })
                                closeModel();
                                setshow(true)
                                setSubmit({ id: '', description: '', minimum_payment: '', duration: '', programme_type: '', actual_course_fee: '', contact_info: '', course_name: '', img: '', key_points: '', loan_amount: '', otp: '', provider: '', referral_money: '', serial_no: '', slider_images: [], sub_duration: '', type: '', created_date: new Date() })
                            })
                        });
                    }
                );
            }
        }

    }

    const deleteData = (id) => {
        closeModel();
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                db.collection("course_data").doc(id).delete().then(() => {
                    console.log('Deleted Successfully');
                    setSubmit({ id: '', description: '', minimum_payment: '', duration: '', programme_type: '', actual_course_fee: '', contact_info: '', course_name: '', img: '', key_points: '', loan_amount: '', otp: '', provider: '', referral_money: '', serial_no: '', slider_images: [], sub_duration: '', type: '', created_date: new Date() })
                    closeModel();
                    Swal.fire(
                        'Deleted!',
                        'Data has been deleted.',
                        'success'
                    )
                })

            }
        })

    }

    const uploader = async () => {
        const { value: file } = await Swal.fire({
            title: 'Select image',
            input: 'file',
            inputAttributes: {
                'accept': 'image/*',
                'aria-label': 'Upload your profile picture'
            }
        })

        if (file) {
            const reader = new FileReader()
            reader.onload = (e) => {
                Swal.fire({
                    title: 'Your uploaded picture',
                    imageUrl: e.target.result,
                    imageAlt: 'The uploaded picture'
                })
            }
            reader.readAsDataURL(file)
        }
    }

    const uploadImage = (e) => {
        // console.log(simg)
        e.preventDefault()
        const file = e.target[0]?.files[0]
        // console.log(file)
        if (!file) return;
        const storageRef = ref(storage, `files/${file.name}`);
        const uploadTask = uploadBytesResumable(storageRef, file);
        setshow(false)

        uploadTask.on("state_changed",
            (snapshot) => {
                const progress =
                    Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                setProgresspercent(progress);
            },
            (error) => {
                alert(error);
            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    // setImgUrl(downloadURL)
                    db.collection("course_data").doc(editID).update({
                        'slider_images': arrayUnion(downloadURL),
                    }).then(() => {
                        console.log('Updated Successfully');
                        const Toast = Swal.mixin({
                            toast: true,
                            background: '#69aba6',
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 3000,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener('mouseenter', Swal.stopTimer)
                                toast.addEventListener('mouseleave', Swal.resumeTimer)
                            }
                        })
                        Toast.fire({
                            icon: 'success',
                            title: 'Updated Successfully'
                        })
                        e.target.reset();
                        closeModel();
                    })
                });
            }
        );

    }

    useEffect(() => {
        window.scrollTo(0, 0)
        db.collection("course_data").orderBy("created_date", "desc").onSnapshot((snapshot) => {
            setData(
                snapshot.docs.map((doc) => ({
                    id: doc.id,
                    data: doc.data(),
                }))
            );
        });

    }, []);




    return (
        <>
            <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">

                {/* <ViewAppData /> */}
                <div class="p-2" style={{ paddingBottom: "5px", display: "flex", justifyContent: "space-between" }}>
                    {/* <span class="btn bg-gradient-secondary btn-sm mb-0 mr-2" style={{ color: "white" }} >&nbsp; Download Excel</span> */}
                    <Link to="/admin_panel"><img src="/images/menubutton.png" class="menu-toggle" alt="Responsive image" style={{ height: "2rem" }} /></Link>
                    <a class="btn bg-gradient-primary btn-sm mb-0 mr-2" style={{ color: "white" }} onClick={() => addNew()}>+&nbsp; Add New Course</a>
                </div>


                <div class="pt-4">
                    <div style={{ textAlign: "end", paddingBottom: "5px" }}>

                        {/* <Link to="/admin_panel" class="btn bg-gradient-primary btn-sm mb-0 mr-2" style={{ color: "white" }} >&nbsp; Go Back</Link> */}
                        {/* <a class="btn bg-gradient-primary btn-sm mb-0" style={{ color: "white" }}>+&nbsp; Upload Bulk Data</a> */}
                    </div>
                    <div class="card">
                        <div class="table-responsive fix-width scroll-inner">
                            <table class="table table-bordered table-hover">
                                <thead class="thead-dark" style={{ position: "sticky", top: "0" }}>
                                    <tr style={{ textAlign: "left" }}>
                                        <th class="col">Course Name/Duration</th>
                                        <th class="col ps-2">Actual Course Fee</th>
                                        <th class="text-center col">One Time Payment</th>
                                        <th class="text-center col">Provider</th>
                                        <th class="text-center col">Slider Image</th>
                                        <th class="text-center col">Action</th>
                                        {/* <th class="text-secondary opacity-7"></th> */}
                                    </tr>
                                </thead>
                                <tbody style={{ textAlign: "left" }}>
                                    {data?.map(({ id, data }, index) => (
                                        <>
                                            <tr key={index}>
                                                <td>
                                                    <div class="d-flex px-2 py-1">
                                                        <div style={{ width: "6rem" }}>
                                                            <img src={data.img} class="me-3" style={{ height: "4rem" }} />
                                                        </div>
                                                        <div class="d-flex flex-column justify-content-center pl-2">
                                                            <h6 class="mb-0 text-xs">{data.course_name}</h6>
                                                            <p class="text-xs text-secondary mb-0">{data.duration} HRS</p>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <p class="text-xs font-weight-bold mb-0">₹ {data.actual_course_fee}</p>
                                                    {/* <p class="text-xs text-secondary mb-0">Organization</p> */}
                                                </td>
                                                <td class="align-middle text-center text-sm">
                                                    <span class="text-secondary text-xs font-weight-normal">₹ {data.otp}</span>
                                                </td>
                                                <td class="align-middle text-center">
                                                    <span class="text-secondary text-xs font-weight-normal">{data.provider}</span>
                                                </td>

                                                {/* <td class="align-middle text-center" onClick={() => uploader()}>
                                                    <span class="text-secondary text-xs font-weight-normal" style={{ cursor: "pointer" }}>Edit Slider</span>
                                                </td> */}
                                                <td class="align-middle text-center" onClick={() => changeStatusSlider(data, id)}>
                                                    <span class="text-info text-xs font-weight-normal" style={{ cursor: "pointer" }}>Edit Slider</span>
                                                </td>
                                                <td class="align-middle text-center" onClick={() => changeStatusForm(data, id)}>
                                                    <span class="text-info text-xs font-weight-normal" style={{ cursor: "pointer" }}>Edit</span>
                                                </td>

                                            </tr>
                                        </>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

            </main>


            {form ? <>
                <div class="col-md-4">
                    <div class="myId modal fade" id="" tabindex="-1" role="dialog" aria-labelledby="modal-form" aria-hidden="true">
                        <div class="modal-dialog modal-dialog-centered" role="document">
                            <div class="modal-content">
                                <div class="modal-body p-0">
                                    <div class="card card-plain">
                                        <form onSubmit={handleSubmit}>
                                            <div class="card-body">
                                                <div class="modal-body">
                                                    <div class="row">

                                                        <div class="col-md-12">
                                                            <span>Course Image :</span>
                                                            {submit.img == "" ? <></> : <img src={submit.img} class="avatar me-3" />}
                                                            <div class="">
                                                                {edit ? <input type="file" id="image" class="" onfocus="focused(this)" onfocusout="defocused(this)" placeholder="Image" /> : <input type="file" id="image" class="" onfocus="focused(this)" onfocusout="defocused(this)" placeholder="Image" required />}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="">
                                                        <div class="col-md-6">
                                                            {/* <div class="input-group input-group-outline my-3">
                                                                <input type="text" id="course_name" class="form-control" onfocus="focused(this)" onfocusout="defocused(this)" onChange={handleChange} value={submit.course_name} placeholder="Course Name" required />
                                                            </div> */}
                                                            <li>
                                                                <label style={{ display: "flex" }}>Course Name</label>
                                                                <input type="text" id="course_name" class="form-control" onfocus="focused(this)" onfocusout="defocused(this)" onChange={handleChange} value={submit.course_name} placeholder="Course Name" required />
                                                            </li>
                                                        </div>
                                                        <div class="col-md-6">
                                                            {/* <div class="input-group input-group-outline my-3">
                                                                <input type="text" id="actual_course_fee" class="form-control" onfocus="focused(this)" onfocusout="defocused(this)" onChange={handleChange} value={submit.actual_course_fee} placeholder="Actual Course Fee" required />
                                                            </div> */}
                                                            <li>
                                                                <label style={{ display: "flex" }}>Actual Course Fee</label>
                                                                <input type="text" id="actual_course_fee" class="form-control" onfocus="focused(this)" onfocusout="defocused(this)" onChange={handleChange} value={submit.actual_course_fee} placeholder="Actual Course Fee" required />
                                                            </li>
                                                        </div>
                                                    </div>

                                                    <div class="">
                                                        <div class="col-md-6">
                                                            {/* <div class="input-group input-group-outline my-3">
                                                                <input type="text" id="otp" class="form-control" onfocus="focused(this)" onfocusout="defocused(this)" onChange={handleChange} value={submit.otp} placeholder="OTP" required />
                                                            </div> */}
                                                            <li>
                                                                <label style={{ display: "flex" }}>OTP</label>
                                                                <input type="text" id="otp" class="form-control" onfocus="focused(this)" onfocusout="defocused(this)" onChange={handleChange} value={submit.otp} placeholder="OTP" required />
                                                            </li>
                                                        </div>


                                                        <div class="col-md-6">
                                                            {/* <div class="input-group input-group-outline my-3">
                                                                <input type="text" id="minimum_payment" class="form-control" onfocus="focused(this)" onfocusout="defocused(this)" onChange={handleChange} value={submit.minimum_payment} placeholder="minimum_payment" required />
                                                            </div> */}
                                                            <li>
                                                                <label style={{ display: "flex" }}>Minimum Payment</label>
                                                                <input type="text" id="minimum_payment" class="form-control" onfocus="focused(this)" onfocusout="defocused(this)" onChange={handleChange} value={submit.minimum_payment} placeholder="Minimum Payment" required />
                                                            </li>
                                                        </div>


                                                    </div>


                                                    <div class="">
                                                        <div class="col-md-6">
                                                            {/* <div class="input-group input-group-outline my-3">
                                                                <input type="text" id="duration" class="form-control" onfocus="focused(this)" onfocusout="defocused(this)" onChange={handleChange} value={submit.duration} placeholder="Duration" required />
                                                            </div> */}
                                                            <li>
                                                                <label style={{ display: "flex" }}>Duration</label>
                                                                <input type="text" id="duration" class="form-control" onfocus="focused(this)" onfocusout="defocused(this)" onChange={handleChange} value={submit.duration} placeholder="Duration" required />
                                                            </li>
                                                        </div>
                                                        <div class="col-md-6">
                                                            {/* <div class="input-group input-group-outline my-3">
                                                                <input type="text" id="provider" class="form-control" onfocus="focused(this)" onfocusout="defocused(this)" onChange={handleChange} value={submit.provider} placeholder="Provider" required />
                                                            </div> */}
                                                            <li>
                                                                <label style={{ display: "flex" }}>Provider</label>
                                                                <input type="text" id="provider" class="form-control" onfocus="focused(this)" onfocusout="defocused(this)" onChange={handleChange} value={submit.provider} placeholder="Provider" required />
                                                            </li>
                                                        </div>

                                                    </div>


                                                    <div class="">
                                                        <div class="col-md-6">
                                                            {/* <div class="input-group input-group-outline my-3">
                                                                <input type="number" id="serial_no" class="form-control" onfocus="focused(this)" onfocusout="defocused(this)" onChange={handleChange} value={submit.serial_no} placeholder="Serial Number" required />
                                                            </div> */}
                                                            <li>
                                                                <label style={{ display: "flex" }}>Serial No</label>
                                                                <input type="number" id="serial_no" class="form-control" onfocus="focused(this)" onfocusout="defocused(this)" onChange={handleChange} value={submit.serial_no} placeholder="Serial Number" required />
                                                            </li>
                                                        </div>

                                                        <div class="col-md-6">

                                                            {/* <div class="input-group-outline my-3">
                                                                <select class="form-control" id="type" onChange={handleChange} value={submit.type} required>
                                                                    <option value="">Type</option>
                                                                    <option value="advanced">Advanced</option>
                                                                    <option value="flagship">Flagship</option>
                                                                    <option value="short_term">Short Term</option>
                                                                </select>
                                                            </div> */}
                                                            <li>
                                                                <label style={{ display: "flex" }}>Course Type</label>
                                                                <select class="form-control" id="type" onChange={handleChange} value={submit.type} required>
                                                                    <option value="">Type</option>
                                                                    <option value="advanced">Advanced</option>
                                                                    <option value="flagship">Flagship</option>
                                                                    <option value="short_term">Short Term</option>
                                                                </select>
                                                            </li>

                                                        </div>

                                                    </div>
                                                    <div class="input-group input-group-dynamic pb-3">
                                                        <textarea class="form-control" rows="5" id="description" placeholder="Course Description" onChange={handleChange} value={submit.description} spellcheck="false"></textarea>
                                                    </div>

                                                    <div class="input-group input-group-dynamic pb-3">
                                                        <textarea class="form-control" rows="2" id="key_points" placeholder="Key Points" onChange={handleChange} value={submit.key_points} spellcheck="false"></textarea>
                                                    </div>

                                                    <div class="input-group input-group-dynamic pb-3">
                                                        <textarea class="form-control" rows="1" id="sub_duration" onChange={handleChange} value={submit.sub_duration} placeholder="Sub Description" spellcheck="false"></textarea>
                                                    </div>

                                                </div>
                                                <div class="modal-footer">
                                                    {submit.id == "" ? <></> : <a class="btn bg-gradient-danger btn-sm mb-0" style={{ color: "white" }} onClick={() => deleteData(submit.id)}>Delete</a>}
                                                    <a class="btn bg-gradient-info btn-sm mb-0" style={{ color: "white" }} onClick={closeModel}>Close</a>
                                                    <button type='submit' class="btn bg-gradient-danger btn-sm mb-0" style={{ backgroundColor: "green", color: "white" }}>{edit ? "Update" : "Submit"}</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
                :
                <>
                    <div class="col-md-4">
                        <div class="myId modal fade" id="" tabindex="-1" role="dialog" aria-labelledby="modal-form" aria-hidden="true">
                            <div class="modal-dialog modal-dialog-centered" role="document">
                                <div class="modal-content">
                                    <form onSubmit={uploadImage}>
                                        <div class="modal-body p-0">
                                            <div class="card card-plain">
                                                <div class="card-body">
                                                    <div class="modal-body">
                                                        <div class="row">

                                                            <div class="col-md-12">
                                                                <span>Slider Image :</span>
                                                                <div class="">
                                                                    {edit ? <input type="file" id="image" class="" onfocus="focused(this)" onfocusout="defocused(this)" placeholder="Image" /> : <input type="file" id="image" class="" onfocus="focused(this)" onfocusout="defocused(this)" placeholder="Image" required />}
                                                                </div>
                                                            </div>

                                                            {submit.img == "" ? <>
                                                            </>
                                                                :
                                                                <>
                                                                    <div class="row">
                                                                        {simg?.map((imgData) => (
                                                                            <>
                                                                                <img src={imgData} class="p-3" style={{ height: "6rem", width: "7rem" }} />
                                                                            </>
                                                                        ))}
                                                                    </div>
                                                                </>}

                                                        </div>

                                                    </div>
                                                    <div class="modal-footer">
                                                        <a class="btn bg-gradient-info btn-sm mb-0" style={{ color: "white" }} onClick={closeModel}>Close</a>
                                                        <button type='submit' class="btn bg-gradient-danger btn-sm mb-0" style={{ backgroundColor: "green", color: "white" }}>Submit</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }







        </>
    )
}

export default CourseData;