import React, { useState, useEffect } from 'react';
import { useNavigate, Link, useParams } from 'react-router-dom';
import Swal from 'sweetalert2'
import db from "./Firebase";
import { arrayUnion } from "firebase/firestore";
import { storage } from './Firebase';
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import ViewAllRequests from './ViewAllRequests';
import ViewAppData from './ViewAppData';
import { CSVLink } from "react-csv";

function WorkshopEventRequest() {
    const navigate = useNavigate();
    const moment = extendMoment(Moment);
    const [data, setData] = useState([]);
    const [csvdata, setCsvdata] = useState([]);
    const [uid, setUid] = useState('');
    const [searchQuery, setSearchQuery] = useState("");

    useEffect(() => {
        const storedUsername = localStorage.getItem("email");
        const storedPassword = localStorage.getItem("password");
        const storedRemember = localStorage.getItem("remember");

        if (storedRemember === "false" || !storedUsername || !storedPassword) {
            navigate('/signin');
        }
    }, []);

    const headers = [
        "Full Name",
        "Phone No",
        "Event Mode",
        "Event Tppe",
        "Email",
        "Description",
        "Event Date",
        "Created Date",
        "Status",
        // Add more column labels as needed
    ];

    const [doneCount, setDoneCount] = useState(0);

    useEffect(() => {
        const fetchData = () => {
            try {
                db.collection("workshop_event_request")
                    .orderBy("created_date", "desc")
                    .onSnapshot((snapshot) => {
                        const dataArray = [];
                        let count = 0;

                        snapshot.forEach((doc) => {
                            const documentData = {
                                id: doc.id,
                                data: doc.data(),
                            };

                            dataArray.push(documentData);

                            // Check the status field and increment count
                            if (documentData.data.status === '1') {
                                count += 1;
                            }
                        });

                        setData(dataArray);
                        setDoneCount(count);
                    });
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);



    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };





    // Function to filter data based on the search query
    const filteredData = data.filter((item) => {
        const full_name = item.data.full_name.toLowerCase();
        const phone_no = item.data.phone_no.toLowerCase();
        const email = item.data.email.toLowerCase();
        const event_mode = item.data.event_mode.toLowerCase();
        const event_type = item.data.event_type.toLowerCase();
        const description = item.data.description.toLowerCase();
        const query = searchQuery.toLowerCase();

        return (
            email.includes(query) ||
            full_name.includes(query) ||
            phone_no.includes(query) ||
            event_mode.includes(query) ||
            event_type.includes(query) ||
            description.includes(query)
        );
    });


    // Function to convert data to CSV format
    const prepareCSVData = () => {
        const csvData = filteredData.map((item, index) => ([
            item.data.full_name,
            item.data.phone_no,
            item.data.event_mode,
            item.data.event_type,
            item.data.email,
            item.data.description,
            item.data.query,
            moment(item.data.event_date.toDate().toISOString()).format("MM/DD/YY, h:mm a"),
            moment(item.data.created_date.toDate().toISOString()).format("MM/DD/YY, h:mm a"),
            item.data.status,
            // Add more columns as needed
        ]));

        return csvData;
    };


    const handleChange2 = async (e, msg) => {
        const { id, value } = e.target
        if (e.target.value == "1") {
            const { value: text } = await Swal.fire({
                input: 'textarea',
                inputLabel: 'Remarks',
                inputPlaceholder: 'Enter Remarks Here...',
                showCancelButton: true,
            })

            if (text) {
                // Swal.fire(`Entered text: ${text}`)
                db.collection("workshop_event_request").doc(uid).update({ 'status': "1", remarks: text, updated_date: new Date() }).then(() => {
                    console.log("query added");
                    const Toast = Swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', Swal.stopTimer);
                            toast.addEventListener('mouseleave', Swal.resumeTimer);
                        },
                    });

                    Toast.fire({
                        icon: 'success',
                        title: 'Updated successfully',
                    });
                })
            }

        } else {

        }
    }




    return (
        <>
            <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg">

                <div class="p-2" style={{ paddingBottom: "5px", display: "flex", justifyContent: "space-between" }}>
                    <Link to="/admin_panel">
                        <img src="/images/menubutton.png" class="menu-toggle" alt="Responsive image" style={{ height: "2rem" }} />
                    </Link>
                    <CSVLink data={prepareCSVData()} headers={headers} filename={"clicked_logs.csv"}>
                        <span class="btn bg-gradient-secondary btn-sm mb-0 mr-2" style={{ color: "white" }}>
                            &nbsp; Download Excel
                        </span>
                    </CSVLink>
                </div>
                <div class="">

                    <div class="p-2">
                        <div style={{ textAlign: "end", paddingBottom: "5px" }}>


                        </div>
                        <div class="card">
                            <div class="card-header pb-0 px-3">
                                <h6 class="mb-2">Workshop/Event Request || {`Records : ${filteredData.length}`}</h6>
                            </div>
                            <div class="d-flex p-2" style={{ justifyContent: "center", fontWeight: "bold" }}>
                                Total : {data.length} || Pending : {data.length - doneCount} || Done : {doneCount}
                            </div>
                            <input
                                type="text"
                                className="form-control form-control-sm"
                                placeholder="Search by Name, Username, Phone, Workshop, or Status"
                                value={searchQuery}
                                onChange={handleSearchChange}
                            />
                            <div class="">
                                <div class="table-responsive fix-width scroll-inner">
                                    <table class="table table-bordered table-hover">
                                        <thead class="thead-dark" style={{ position: "sticky", top: "0" }}>
                                            <tr>

                                                <th class="col ps-2">S.No</th>
                                                <th class="col ps-2">Full Name</th>
                                                <th class="col ps-2">Phone No</th>
                                                <th class="col">event_mode</th>
                                                <th class="col ps-2">event_type</th>
                                                <th class="col ps-2">email</th>
                                                <th class="col">description</th>
                                                <th class="col ps-2">event_date</th>
                                                <th class="col ps-2">Created Date</th>
                                                {/* <th class="col ps-2">Status</th> */}
                                                <th class="col ps-2">Action</th>
                                                <th class="col ps-2">Remarks</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {filteredData.map((item, index) => (
                                                <tr key={item.id}>
                                                    <td>
                                                        <p class="text-xs mb-0">{index + 1}.</p>
                                                    </td>
                                                    <td>
                                                        <p class="text-xs mb-0">{item.data.full_name}</p>
                                                    </td>
                                                    <td>
                                                        <p class="text-xs mb-0">{item.data.phone_no}</p>
                                                    </td>
                                                    <td>
                                                        <p class="text-xs mb-0">{item.data.event_mode}</p>
                                                    </td>
                                                    <td>
                                                        <p class="text-xs mb-0">{item.data.event_type}</p>
                                                    </td>

                                                    <td>
                                                        <p class="text-xs mb-0">{item.data.email}</p>
                                                    </td>
                                                    {/* <td style={{ whiteSpace: "pre-line", width: "21rem" }}>
                                                        <p class="text-xs mb-0">{item.data.description}</p>
                                                    </td> */}
                                                    <td style={{ whiteSpace: 'pre-wrap' }}>
                                                        <p class="text-xs font-weight-bold mb-0" style={{ width: "15rem" }}>{item.data.description}</p>

                                                    </td>



                                                    <td>
                                                        <div class="d-flex px-2 py-1">
                                                            <div class="d-flex flex-column justify-content-center">
                                                                <h6 class="mb-0 text-xs">{moment(item.data.event_date.toDate().toISOString()).format("MM/DD/YY, h:mm a")}</h6>
                                                            </div>
                                                        </div>
                                                    </td>


                                                    <td>
                                                        <div class="d-flex px-2 py-1">
                                                            <div class="d-flex flex-column justify-content-center">
                                                                <h6 class="mb-0 text-xs">{moment(item.data.created_date.toDate().toISOString()).format("MM/DD/YY, h:mm a")}</h6>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    {/* <td>
                                                        <p class="text-xs mb-0">{item.data.status}</p>
                                                    </td> */}
                                                    {item.data.status === 0 ?
                                                        <>
                                                            <td>
                                                                <select class="form-control" id={index} name="status" onClick={() => setUid(item.id)} onChange={handleChange2} value={data.status} style={{ padding: "7px", width: "9rem", backgroundColor: data.status == 1 ? "#28a745" : "transparent" }} disabled={data.status == 1 ? true : false} >
                                                                    <option selected={item.data.status === "0" ? true : false} value="0">Pending</option>
                                                                    <option selected={item.data.status === "1" ? true : false} value="1">Done</option>
                                                                </select>
                                                            </td>
                                                        </> : <>
                                                            <td>
                                                                <select class="form-control" id="request_status" name="request_status" style={{ width: "8rem", background: "green", color: "white" }}>
                                                                    <option selected value="1" disabled>Done</option>
                                                                </select>
                                                            </td>

                                                        </>}

                                                    <td>
                                                        <textarea class="form-control" value={item.data.remarks} style={{ width: "18rem", background: "#ddd" }} disabled />
                                                    </td>

                                                    {/* Other table cells */}
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
}

export default WorkshopEventRequest;
