import React, { useState, useEffect } from 'react';
import { useNavigate, Link, useParams } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2'
import db from "./Firebase";
import { storage } from './Firebase';
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";


function Feedback() {
    let params = useParams();
    const navigate = useNavigate();

    const initialFormStateFeedback = { full_name: '', feedback: '', created_date: new Date() }
    const [submit, setSubmit] = useState(initialFormStateFeedback)

    const [show, setshow] = useState(true);

    const [imgUrl, setImgUrl] = useState(null);
    const [progresspercent, setProgresspercent] = useState(0);


    const handleChange = (e) => {
        const { id, value } = e.target
        setSubmit({ ...submit, [id]: value })
    }

    const reset = () => {
        setSubmit({ full_name: '', feedback: '', created_date: new Date() })
    }

    const handleSubmit = (e) => {
        // console.log(submit.usercode)
        // alert("okkk")
        e.preventDefault()

        const file = e.target[0]?.files[0]
        // console.log(file)
        if (!file) return;
        const storageRef = ref(storage, `files/${file.name}`);
        const uploadTask = uploadBytesResumable(storageRef, file);
        setshow(false)

        uploadTask.on("state_changed",
            (snapshot) => {
                const progress =
                    Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                setProgresspercent(progress);
            },
            (error) => {
                alert(error);
            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    // setImgUrl(downloadURL)
                    db.collection("parlour_feedback").add({
                        'full_name': submit.full_name,
                        'feedback': submit.feedback,
                        'image': downloadURL,
                        'username': params.id,
                        'created_date': new Date(),
                    }).then(() => {

                        console.log('Submitted Successfully');
                        const Toast = Swal.mixin({
                            toast: true,
                            background: '#69aba6',
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 3000,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener('mouseenter', Swal.stopTimer)
                                toast.addEventListener('mouseleave', Swal.resumeTimer)
                            }
                        })

                        Toast.fire({
                            icon: 'success',
                            title: 'Submitted Successfully'
                        })
                        setshow(true)
                        // navigate(`/${submit.username}`);
                        setSubmit({ full_name: '', feedback: '', created_date: new Date() })
                    })
                });
            }
        );

    }

    useEffect(() => {
        // alert(params.id)
    }, []);



    return (

        <>
            <div id="small-dialog" class="m-3">
                <div class="w3layouts_payment_online_form">
                    <form class="" id="" onSubmit={handleSubmit}>
                        <h4>Parlour Feedback</h4>
                        <ul>
                            <li style={{ textAlign: "left" }}><label for="myfile">Choose Your Image:</label><input type="file" id="image" name="Image" accept="image/*" />
                            </li>
                            <li><input class="w3l_text_box" id="full_name" onChange={handleChange} value={submit.full_name} placeholder="Full Name" name="Full Name" type="text" required />
                            </li>
                        </ul>
                        <ul>
                            <li class="w3ls_address"><input class="w3l_text_box" id="feedback" onChange={handleChange} value={submit.feedback} placeholder="Write your Feedback Here....." name="Feedback" type="text" style={{ height: "6rem" }} required /></li>
                        </ul>

                        <ul class="w3_payment_sendbtns">
                            <li><input type="reset" data-dismiss="modal" aria-label="Close" onClick={reset} value="Reset" /></li>
                            <li>{show ? <input type="submit" value="Submit" /> : "Please Wait..."}</li>
                        </ul>
                        <div class="clearfix"> </div>
                    </form>
                </div>
            </div>
        </>


    );
}
export default Feedback;