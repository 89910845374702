import React, { useState, useEffect } from "react";
// import "./portfolio.css";

function PortfolioNew() {


    return (
        <>
            <div class="" style={{ borderColor: "orange", borderWidth: "1px", height: "45rem", backgroundImage: "url(images/layer_1.jpg)" }}>
                <div class="row" style={{ backgroundImage: "url(images/rounded_rectangle_1_copy.png)", height: "40rem", marginLeft: "2rem", marginRight: "2rem", marginTop: "2rem", padding: "2rem", borderRadius: "10px" }}>
                    <div class="col">
                        <img class="youvati-logo" src="images/youvati_logo.png" alt="" style={{ height: "2rem", width: "rem" }} />
                    </div>
                    <div class="col">
                        <div class="ellipse-1"></div>
                    </div>
                    <div class="col" style={{ textAlign: "end", display: "contents" }}>
                        <p class="portfolio" style={{ fontSize: "16px", fontWeight: "400", color: "black", padding: "7px" }}>Portfolio</p>
                        <div class="rectangle-3" style={{ height: "15px", width: "1px", background: "#222222", marginTop: "17px" }}></div>
                        <p class="services" style={{ fontSize: "16px", fontWeight: "400", color: "black", padding: "7px" }}>Services</p>
                        <div class="rectangle-3" style={{ height: "15px", width: "1px", background: "#222222", marginTop: "17px" }}></div>
                        <p class="reviews" style={{ fontSize: "16px", fontWeight: "400", color: "black", padding: "7px" }}>Reviews</p>
                    </div>
                    <div class="" style={{ textAlign: "center" }}>
                        <p class="textName">Simran Bajwa</p>
                        <p class="textName2">Professional Skin Specialist</p>
                        <div class="rectangle-1"></div>
                        <p class="textName3">Simran is a Professional Skin Specialist, who has been in the industry for the past 5 years
                            and is trained in Laser Hair Removal and Microdermabrasion. She offers complete skin care treatments, that
                            is customized for each &nbsp;client’s needs</p>
                    </div>
                </div>

                {/* <div class="container" style={{ borderColor: "red", borderWidth: "1px", }}>
                    <div class="row">
                        <div class="col">
                            <img class="youvati-logo" src="images/youvati_logo.png" alt="" />
                        </div>
                        <div class="col" style={{ textAlign: "end", display: "contents" }}>
                            <p class="portfolio" style={{ fontSize: "1.0rem" }}>Portfolio</p>
                            <div class="rectangle-3" style={{ height: "15px", width: "1px", background: "#222222", marginTop: "2px !important" }}></div>
                            <p class="services">Services</p>
                            <div class="rectangle-3" style={{ height: "22px", width: "1px", background: "#222222", marginTop: "2px !important" }}></div>
                            <p class="reviews">Reviews</p>
                        </div>

                    </div>
                </div> */}
            </div>
        </>
    );
}

export default PortfolioNew;
