import React, { useState, useEffect } from "react";
import { useNavigate, Link, useParams } from 'react-router-dom';
import Swal from 'sweetalert2'
import db from "./Firebase";
import { storage } from './Firebase';
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import Moment from 'moment';
import { extendMoment } from 'moment-range';

function WorkshopAttendanceStatus() {

    const moment = extendMoment(Moment);
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [show, setshow] = useState(true);
    const [addTrends, setAddTrends] = useState(false);
    const [progresspercent, setProgresspercent] = useState(0);

    const [editID, setEditID] = useState("");
    const [languageID, setLanguageID] = useState("english");

    const [searchQuery, setSearchQuery] = useState("");
    const [availabilityFilter, setAvailabilityFilter] = useState("all");


    useEffect(() => {
        db.collection("workshop_mark_attendance_status").onSnapshot((snapshot) => {

            setData(
                snapshot.docs.map((doc) => ({
                    id: doc.id,
                    data: doc.data(),
                }))
            );
        });

    }, []);



    const changeStatus = async (uid) => {

        const { value: text } = await Swal.fire({
            input: 'text',
            inputLabel: 'Status Update',
            inputPlaceholder: 'Enter Status Number.....'
        })

        if (text === "1" || text === "0") {
            // Swal.fire(`Entered text: ${text}`)
            db.collection("workshop_mark_attendance_status").doc(uid).update({ 'status': text }).then(() => {
                console.log("status added");
            })
        } else {
            alert("please add only 1 or 0")
        }

    }

    const changeStatusName = async (uid) => {

        const { value: text } = await Swal.fire({
            input: 'text',
            inputLabel: 'Workshop Name',
            inputPlaceholder: 'Enter workshop Name.....'
        })

        if (text) {
            // Swal.fire(`Entered text: ${text}`)
            db.collection("workshop_mark_attendance_status").doc(uid).update({ 'name': text }).then(() => {
                console.log("name added");
            })
        } else {
            alert("please enter workshop name")
        }

    }




    return (
        <>
            <div class="p-2" style={{ textAlign: "start", paddingBottom: "5px" }}>
                {/* <span class="btn bg-gradient-secondary btn-sm mb-0 mr-2" style={{ color: "white" }} >&nbsp; Download Excel</span> */}
                <Link to="/admin_panel"><img src="/images/menubutton.png" class="menu-toggle" alt="Responsive image" style={{ height: "2rem" }} /></Link>

            </div>
            <div class="card-header pb-0 px-3" style={{ display: "flex", justifyContent: "center" }}>
                <h6 class="mb-2">Workshop Attendance Status</h6>
            </div>
            <div class="table-responsive fix-width scroll-inner">
                <table class="table table-bordered table-hover">
                    <thead class="thead-dark" style={{ position: "sticky", top: "0" }}>
                        <tr>
                            <th>Sr. No</th>
                            <th>Workshop Attendance Status</th>
                            <th>Status</th>
                            <th>Action 1</th>
                            <th>Action 2</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data?.map(({ id, data }, index) => (
                            <>
                                <tr>
                                    <td>{index + 1}</td>
                                    <td>{data.name}</td>
                                    <td>{data.status}</td>
                                    <td>
                                        <span style={{ color: "orange", fontSize: "13px", fontWeight: "700", cursor: "pointer" }} onClick={() => changeStatusName(id)}>Update Name</span>

                                    </td>
                                    <td>
                                        <span style={{ color: "orange", fontSize: "13px", fontWeight: "700", cursor: "pointer" }} onClick={() => changeStatus(id)}>Update Status</span>

                                    </td>
                                </tr>
                            </>
                        ))}


                    </tbody>
                </table>
            </div>



        </>
    )
}

export default WorkshopAttendanceStatus
