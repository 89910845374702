import React, { useState, useEffect } from 'react';
import * as XLSX from 'xlsx';
import DataTable from 'react-data-table-component';
import { useNavigate, Link, useParams } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2'
import db from "./Firebase";
import { storage } from './Firebase';
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";


function CreateWhatsappTemplate() {
    let params = useParams();
    const navigate = useNavigate();

    const initialFormStateFeedback = {
        category: '',
        template_name: '',
        header: '',
        header_data: '',
        body: '',
        footer: '',
        buttons: '',
        phone_button_text: '',
        button_phone_no: '',
        web_button_text: '',
        website_url: '',
    }
    const [submit, setSubmit] = useState(initialFormStateFeedback)

    const [show, setshow] = useState(true);

    const [progresspercent, setProgresspercent] = useState(0);


    const handleChange = (e) => {
        const { id, value } = e.target
        setSubmit({ ...submit, [id]: value })
    }


    const reset = () => {
        setSubmit({
            category: '',
            template_name: '',
            header: '',
            header_data: '',
            body: '',
            footer: '',
            buttons: '',
            phone_button_text: '',
            button_phone_no: '',
            web_button_text: '',
            website_url: '',
        })
    }


    const handleSubmit = (e) => {
        // console.log(submit.usercode)
        // alert("okkk")
        e.preventDefault()
        db.collection("whatsapp_template").add({
            'category': submit.category,
            'template_name': submit.template_name,
            'header': submit.header,
            'header_data': submit.header_data,
            'body': submit.body,
            'footer': submit.footer,
            'buttons': submit.buttons,
            'phone_button_text': submit.phone_button_text,
            'button_phone_no': submit.button_phone_no,
            'web_button_text': submit.web_button_text,
            'website_url': submit.website_url,
            'created_date': new Date()
        }).then(() => {
            console.log('Submitted Successfully');
            const Toast = Swal.mixin({
                toast: true,
                background: '#69aba6',
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            })

            Toast.fire({
                icon: 'success',
                title: 'Submitted Successfully'
            })
            setshow(true)
            // navigate(`/${submit.username}`);
            setSubmit({
                category: '',
                template_name: '',
                header: '',
                header_data: '',
                body: '',
                footer: '',
                buttons: '',
                phone_button_text: '',
                button_phone_no: '',
                web_button_text: '',
                website_url: '',
            })
        })
    }


    return (
        <>
            <div id="" class="m-3" style={{ border: "10px solid #344767" }}>
                <div class="w3layouts_payment_online_form">
                    <form class="" id="" onSubmit={handleSubmit}>
                        <h4>Create Whatsapp Template</h4>
                        <ul>
                            <li><select class="form-control" id="category" onChange={handleChange} required>
                                <option value="">-- Choose Category --</option>
                                <option value="Marketing">Marketing</option>
                                <option value="Utility">Utility</option>
                            </select></li>
                            <li><input class="w3l_text_box" id="template_name" placeholder="*Template Name" onChange={handleChange} value={submit.template_name} name="Template Name" type="text" required="" /></li>
                        </ul>

                        <ul>
                            <li><select class="form-control" id="header" onChange={handleChange} required>
                                <option value="">-- Header --</option>
                                <option value="None">None</option>
                                <option value="Text">Text</option>
                                <option value="Media">Media (URL)</option>
                            </select></li>
                            <li><input class="w3l_text_box" id="header_data" placeholder="Header Data - text/image url - (Optional)" onChange={handleChange} value={submit.header_data} name="Header Data" type="text" /></li>
                        </ul>

                        <ul>
                            <li class="w3ls_address"><input class="w3l_text_box" id="body" onChange={handleChange} value={submit.body} placeholder="Enter Text/Message in English" name="body" type="text" style={{ height: "6rem" }} required /></li>
                        </ul>



                        <ul>
                            <li>
                                <input class="w3l_text_box" id="footer" placeholder="Footer Text (Optional)" onChange={handleChange} value={submit.footer} name="Footer" type="text" required />
                            </li>
                            <li><select class="form-control" id="buttons" onChange={handleChange} required>
                                <option value="">-- Buttons --</option>
                                <option value="none">None</option>
                                <option value="Call to action">Call to action</option>
                                <option value="Marketing opt-out">Marketing opt-out</option>
                                <option value="Custom">Custom</option>
                            </select></li>
                        </ul>


                        <ul>
                            <li><input class="w3l_text_box" id="phone_button_text" placeholder="Call Button Text" onChange={handleChange} value={submit.phone_button_text} name="Button Text" type="text" /></li>
                            <li><input class="w3l_text_box" id="button_phone_no" placeholder="Phone Number" onChange={handleChange} value={submit.button_phone_no} name="Phone Number" type="text" /></li>
                        </ul>

                        <ul>
                            <li><input class="w3l_text_box" id="web_button_text" placeholder="Website Button Text" onChange={handleChange} value={submit.web_button_text} name="Button Text" type="text" /></li>
                            <li><input class="w3l_text_box" id="website_url" placeholder="Website URL" onChange={handleChange} value={submit.website_url} name="Website URL" type="text" /></li>
                        </ul>




                        <ul class="w3_payment_sendbtns">
                            <li><input type="reset" value="Cancel" onClick={() => navigate(`/admin_panel`)} /></li>
                            <li class="mr-4"><input type="reset" data-dismiss="modal" aria-label="Close" onClick={reset} value="Reset" /></li>
                            <li>{show ? <input type="submit" value="Submit" /> : "Please Wait..."}</li>
                        </ul>

                        <div class="clearfix"> </div>
                    </form>
                </div>
            </div>
        </>


    );
}
export default CreateWhatsappTemplate;