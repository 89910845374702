import React, { useState, useEffect } from 'react';
import { useNavigate, Link, useParams } from 'react-router-dom';
import db from "./Firebase";
import { storage } from './Firebase';
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import ViewAllRequests from './ViewAllRequests';
import { CSVLink } from "react-csv";


function Csv() {
    const headers = [
        { label: "Address", key: "address" },
        { label: "Kit Type", key: "kit_type" },
        { label: "Paid Amount", key: "paid_amount" },
        { label: "Parlour Name", key: "parlour_name" },
        { label: "Payment Id", key: "payment_id" },
        { label: "Payment Type", key: "payment_type" },
        { label: "Phone No", key: "phone_no" },
        { label: "Pincode", key: "pincode" },
        { label: "Status", key: "status" },
        { label: "UID", key: "uid" },
    ];

    // var data = [
    //     { firstname: "Ahmed", lastname: "Tomi", email: "ah@smthing.co.com" },
    //     { firstname: "Yezzi", lastname: "Min l3b", email: "ymin@cocococo.com" }
    // ];
    const [data, setData] = useState([{ firstname: "Ahmed", lastname: "Tomi", email: "ah@smthing.co.com" },
    { firstname: "Yezzi", lastname: "Min l3b", email: "ymin@cocococo.com" }])

    useEffect(() => {
        db.collection("beauty_kit_request").onSnapshot((snapshot) => {
            // snapshot.docs.map(e => data.push(e.data()))
            // console.log(ai)
            setData(
                snapshot.docs.map((doc) => (
                    doc.data()
                ))
            );

        });
        setData([{ firstname: "Rahul", lastname: "Singh", email: "rahul@gmail.com" }])
        console.log(data)
    }, []);



    return (
        <>
            <CSVLink data={data} headers={headers}>
                Download me
            </CSVLink>;
        </>
    )
}

export default Csv;