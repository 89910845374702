import React, { useState, useEffect } from 'react';
import AdminPanel from './AdminPanel';
import Swal from 'sweetalert2'
import { useNavigate, Link, useParams } from 'react-router-dom';
import db from "./Firebase";
import { storage } from './Firebase';
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import ViewAppData from './ViewAppData';


function AddSliderImages() {
    const navigate = useNavigate();
    const moment = extendMoment(Moment);
    const initialFormStateFeedback = { id: '', image_url: '', tabIndex: '', type: '', status: '', created_date: new Date() }
    const [submit, setSubmit] = useState(initialFormStateFeedback)
    const [data, setData] = useState([]);
    const [editID, setEditID] = useState("");
    const [edit, setEdit] = useState(false);
    const [ShowProgress, setShowProgress] = useState(false);
    const [show, setshow] = useState(true);
    const [progresspercent, setProgresspercent] = useState(0);

    useEffect(() => {
        const storedUsername = localStorage.getItem("email");
        const storedPassword = localStorage.getItem("password");
        const storedRemember = localStorage.getItem("remember");

        if (storedRemember === "false" || !storedUsername || !storedPassword) {
            navigate('/signin');
        }
    }, []);


    const handleChange = (e) => {
        const { id, value } = e.target
        setSubmit({ ...submit, [id]: value })
    }

    const changeStatus = (data, id) => {
        setEdit(true)
        setEditID(id)
        setSubmit({ id: id, image_url: data.image_url, tabIndex: data.tabIndex, type: data.type, status: data.status, created_date: new Date() });
        openModalShow();
    }

    const addNew = () => {
        setEdit(false)
        openModalShow();
        setSubmit({ id: '', image_url: '', tabIndex: '', type: '', status: '', created_date: new Date() });
    }

    const openModalShow = () => {
        // alert("ooo")
        window.openModelshow();
    }

    const closeModel = () => {
        window.openModelNew();
    }

    const handleSubmit = (e) => {
        console.log("callingggggg")
        e.preventDefault()
        if (edit) {
            console.log("edit")
            if (e.target[0]?.files[0] == null) {
                db.collection("slider_images").doc(editID).update({
                    'image_url': submit.image_url,
                    'status': submit.status,
                    'tabIndex': parseInt(submit.tabIndex),
                    'type': submit.type,
                    'created_date': new Date(),
                }).then(() => {
                    console.log('Updated Successfully');
                    const Toast = Swal.mixin({
                        toast: true,
                        background: '#69aba6',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', Swal.stopTimer)
                            toast.addEventListener('mouseleave', Swal.resumeTimer)
                        }
                    })

                    Toast.fire({
                        icon: 'success',
                        title: 'Updated Successfully'
                    })
                    closeModel();

                    setSubmit({ id: '', image_url: '', tabIndex: '', type: '', status: '', created_date: new Date() })
                })
            } else {
                const file = e.target[0]?.files[0]
                // console.log(file)
                if (!file) return;
                const storageRef = ref(storage, `files/${file.name}`);
                const uploadTask = uploadBytesResumable(storageRef, file);
                setshow(false)

                uploadTask.on("state_changed",
                    (snapshot) => {
                        const progress =
                            Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                        setProgresspercent(progress);
                    },
                    (error) => {
                        alert(error);
                    },
                    () => {

                        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                            // setImgUrl(downloadURL)
                            db.collection("slider_images").doc(editID).update({
                                'image_url': downloadURL,
                                'status': submit.status,
                                'tabIndex': submit.tabIndex,
                                'type': submit.type,
                                'created_date': new Date(),
                            }).then(() => {
                                console.log('Updated Successfully');
                                const Toast = Swal.mixin({
                                    toast: true,
                                    background: '#69aba6',
                                    position: 'top-end',
                                    showConfirmButton: false,
                                    timer: 3000,
                                    timerProgressBar: true,
                                    didOpen: (toast) => {
                                        toast.addEventListener('mouseenter', Swal.stopTimer)
                                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                                    }
                                })

                                Toast.fire({
                                    icon: 'success',
                                    title: 'Updated Successfully'
                                })
                                closeModel();
                                setshow(true)
                                setSubmit({ id: '', image_url: '', tabIndex: '', type: '', status: '', created_date: new Date() })
                            })
                        });
                    }
                );
            }

        } else {
            const file = e.target[0]?.files[0]
            // console.log(file)
            if (!file) return;
            const storageRef = ref(storage, `files/${file.name}`);
            const uploadTask = uploadBytesResumable(storageRef, file);
            setshow(false)

            uploadTask.on("state_changed",
                (snapshot) => {
                    const progress =
                        Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                    setProgresspercent(progress);
                },
                (error) => {
                    alert(error);
                },
                () => {

                    getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                        // setImgUrl(downloadURL)
                        db.collection("slider_images").add({
                            'image_url': downloadURL,
                            'status': submit.status,
                            'tabIndex': submit.tabIndex,
                            'type': submit.type,
                            'created_date': new Date(),
                        }).then(() => {
                            console.log('Created Successfully');
                            const Toast = Swal.mixin({
                                toast: true,
                                background: '#69aba6',
                                position: 'top-end',
                                showConfirmButton: false,
                                timer: 3000,
                                timerProgressBar: true,
                                didOpen: (toast) => {
                                    toast.addEventListener('mouseenter', Swal.stopTimer)
                                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                                }
                            })

                            Toast.fire({
                                icon: 'success',
                                title: 'Created Successfully'
                            })
                            closeModel();
                            setshow(true)
                            setSubmit({ id: '', image_url: '', tabIndex: '', type: '', status: '', created_date: new Date() })
                        })
                    });
                }
            );
        }

    }

    const deleteData = (id) => {
        closeModel();
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                db.collection("slider_images").doc(id).delete().then(() => {
                    console.log('Deleted Successfully');
                    setSubmit({ id: '', image_url: '', tabIndex: '', type: '', status: '', created_date: new Date() })
                    closeModel();
                    Swal.fire(
                        'Deleted!',
                        'Data has been deleted.',
                        'success'
                    )
                })

            }
        })

    }

    const arr = ["", "Advertise", "Courses", "Loan", "Portfolio", "Workshop"];

    useEffect(() => {
        db.collection("slider_images").orderBy("tabIndex", "asc").onSnapshot((snapshot) => {
            setData(
                snapshot.docs.map((doc) => ({
                    id: doc.id,
                    data: doc.data(),
                }))
            );
        });

    }, []);





    return (
        <>
            <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
                <div class="p-2" style={{ paddingBottom: "5px", display: "flex", justifyContent: "space-between" }}>
                    {/* <span class="btn bg-gradient-secondary btn-sm mb-0 mr-2" style={{ color: "white" }} >&nbsp; Download Excel</span> */}
                    <Link to="/admin_panel"><img src="/images/menubutton.png" class="menu-toggle" alt="Responsive image" style={{ height: "2rem" }} /></Link>
                    <a class="btn bg-gradient-primary btn-sm mb-0" style={{ color: "white" }} onClick={() => addNew()}>+&nbsp; Add New Slider Image</a>

                </div>

                <div class="">
                    <div style={{ textAlign: "end", paddingBottom: "5px" }}>
                        {/* <Link to="/admin_panel" class="btn bg-gradient-primary btn-sm mb-0 ml-2" style={{ color: "white" }} >&nbsp; Go Back</Link> */}
                    </div>
                    <div class="card">
                        <div class="card-header pb-0 px-3">
                            <h6 class="mb-2">Add Slider Images</h6>
                        </div>
                        <div class="card">
                            <div class="table-responsive fix-width scroll-inner">
                                <table class="table table-bordered table-hover">
                                    <thead class="thead-dark" style={{ position: "sticky", top: "0" }}>
                                        <tr style={{ textAlign: "left" }}>
                                            <th class="">Slider Image</th>
                                            <th class=" ps-2">Module</th>
                                            <th class="text-center ">Type</th>
                                            <th class="text-center ">Created Date</th>
                                            <th class="text-center ">Action</th>
                                            {/* <th class="text-secondary opacity-7"></th> */}
                                        </tr>
                                    </thead>
                                    <tbody style={{ textAlign: "left" }}>
                                        {data?.map(({ id, data }, index) => (
                                            <>
                                                <tr>
                                                    <td>
                                                        <div class="d-flex px-2 py-1">
                                                            <div style={{ width: "7rem", marginRight: "13px" }}>
                                                                <img src={data.image_url} class="me-3" style={{ height: "4rem" }} />
                                                            </div>
                                                            <div class="d-flex flex-column justify-content-center">
                                                                {/* <h6 class="mb-0 text-xs">{data.name}</h6> */}
                                                                {/* <p class="text-xs text-secondary mb-0 btn-tooltip" data-bs-toggle="tooltip" data-bs-placement="top" title={data.description} data-container="body" data-animation="true" style={{ whiteSpace: "nowrap", width: "20rem", overflow: "hidden", textOverflow: "ellipsis" }}><i class="material-icons" style={{ fontSize: "12px", color: "#e73573" }}>info</i>  {data.description}</p> */}
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <p class="text-xs font-weight-bold mb-0">{arr[data.tabIndex]}</p>
                                                        {/* <p class="text-xs text-secondary mb-0">Organization</p> */}
                                                    </td>
                                                    <td class="align-middle text-center text-sm">
                                                        <span class="badge badge-sm badge-success">{data.type}</span>
                                                    </td>
                                                    <td class="align-middle text-center text-sm">
                                                        <span class="text-secondary text-xs font-weight-normal">{moment(data.created_date.toDate().toISOString()).format("MM/DD/YY, h:mm a")}</span>
                                                    </td>

                                                    <td class="align-middle text-center" onClick={() => changeStatus(data, id)}>
                                                        <span class="text-info text-xs font-weight-normal" style={{ cursor: "pointer" }}>Edit</span>
                                                    </td>

                                                </tr>
                                            </>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

            </main>

            <div class="col-md-4">
                <div class="myId modal fade" id="" tabindex="-1" role="dialog" aria-labelledby="modal-form" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered" role="document">
                        <div class="modal-content">
                            <div class="modal-body p-0">
                                <div class="card card-plain">
                                    <form onSubmit={handleSubmit}>
                                        <div class="card-body">
                                            <div class="modal-body">
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <span>Slider Image:</span>
                                                        {submit.image_url == "" ? <></> : <img src={submit.image_url} class="avatar avatar-xl me-3" />}
                                                        <div class="">
                                                            {edit ? <input type="file" id="image_url" class="" onfocus="focused(this)" onfocusout="defocused(this)" placeholder="Image" /> : <input type="file" id="image_url" class="" onfocus="focused(this)" onfocusout="defocused(this)" placeholder="Image" required />}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="">
                                                    <div class="col-md-6">
                                                        <li>
                                                            <label style={{ display: "flex" }}>Select Language Type</label>
                                                            <select class="form-control" id="type" onChange={handleChange} value={submit.type} required>
                                                                <option value="">Select Type</option>
                                                                <option value="english">English</option>
                                                                <option value="hindi">Hindi</option>
                                                            </select>
                                                        </li>
                                                    </div>

                                                    {/* const arr = ["", "Advertise", "Courses", "Loan", "Portfolio", "Workshop"]; */}
                                                    <div class="col-md-6">
                                                        <li>
                                                            <label style={{ display: "flex" }}>Select Module</label>
                                                            <select class="form-control" id="tabIndex" onChange={handleChange} value={submit.tabIndex} required>
                                                                <option value="">Select Module</option>
                                                                <option value="1">Advertise</option>
                                                                <option value="2">Course</option>
                                                                <option value="3">Loan</option>
                                                                <option value="4">Portfolio</option>
                                                                <option value="5">Workshop</option>
                                                            </select>
                                                        </li>
                                                    </div>


                                                </div>


                                                <div class="">
                                                    <div class="col-md-6">
                                                        <li style={{ marginTop: 10 }}>
                                                            <label style={{ display: "flex" }}></label>
                                                            <select class="form-control" id="status" onChange={handleChange} value={submit.status} required>
                                                                <option value="">Select Status</option>
                                                                <option value="1">Active</option>
                                                                <option value="0">InActive</option>
                                                            </select>
                                                        </li>
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="modal-footer">
                                                {submit.id == "" ? <></> : <a class="btn bg-gradient-danger btn-sm mb-0" style={{ color: "white" }} onClick={() => deleteData(submit.id)}>Delete</a>}
                                                <a class="btn bg-gradient-info btn-sm mb-0" style={{ color: "white" }} onClick={closeModel}>Close</a>
                                                <button type='submit' class="btn bg-gradient-danger btn-sm mb-0" style={{ backgroundColor: "green", color: "white" }}>{edit ? "Update" : "Submit"}</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default AddSliderImages;