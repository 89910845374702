import React, { useState, useEffect } from "react";
import Swal from 'sweetalert2'
import { useNavigate, Link, useParams } from "react-router-dom"
import db from "./Firebase";
import axios from "axios";
// import { firebase, auth } from './FirebaseLogin';
import Moment from 'moment';
import { extendMoment } from 'moment-range';


function EmiDetails() {
    const moment = extendMoment(Moment);
    let params = useParams();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true)
    const [personalData, setPersonalData] = useState({});
    const [data, setData] = useState([]);
    const [uid, setUid] = useState('');
    const [editID, setEditID] = useState('');
    const [edit, setEdit] = useState(false);
    const [repayment, setRepayment] = useState([])

    useEffect(() => {
        db.collection("loan").where('applicant_phone', '==', params.id.substr(0, 10)).onSnapshot((snapshot) => {
            // snapshot.docs.map(e => setPersonalData(e.data()))
            snapshot.docs.map((e) => {
                const docId = e.id;
                setPersonalData(e.data())
                setUid(docId)
            });
            // snapshot.docs.map((doc) => {
            //     const docId = doc.id; // Get the document ID
            //     const data = doc.data(); // Get the data

            //     setPersonalData(docId, data); // Pass both docId and data to the function
            //   });


            if (snapshot.docs.length == 0) {
                navigate('/error');
            } else {
                // alert("exists")
                setIsLoading(false);
            }
        });

        db.collection("repayment_model").where('loan_id', '==', params.id).onSnapshot((snapshot) => {
            setRepayment(
                snapshot.docs.map((doc) => ({
                    id: doc.id,
                    data: doc.data(),
                }))
            );
        });




    }, []);





    const initialFormStateRepayment = { loan_amount: '', loan_id: "", loan_disbursement_date: new Date(), emi_date: '', amount: '', payment_link: '', paid_on: '', status: '', }
    const [repay, setRepay] = useState(initialFormStateRepayment)


    const handleChangeRepay = (e) => {
        const { id, value } = e.target
        setRepay({ ...repay, [id]: value })
        console.log(e.target.value)
    }


    const handleRepayment = async () => {
        if (repay.amount == "" || repay.emi_date == "") {
            alert("Please fill the fields first!")
        } else {
            db.collection("repayment_model").add({ 'loan_amount': personalData.loan_amount, 'loan_id': personalData.loan_id, 'loan_disbursement_date': personalData.loan_disbursement_date, 'emi_date': new Date(repay.emi_date), 'amount': personalData.repaymentModel.substr(4, 4), 'payment_link': repay.payment_link, 'paid_on': repay.paid_on, 'status': repay.status, 'updated_date': new Date() }).then(() => {
                console.log("repayment_model");
                setRepay({ loan_amount: '', loan_id: "", loan_disbursement_date: new Date(), emi_date: '', amount: '', payment_link: '', paid_on: '', status: '', })
            })
        }

        // alert("pp")
    }

    const repaymentCompleted = async () => {
        Swal.fire({
            title: 'Do you want to complete this repayment status',
            showDenyButton: true,
            confirmButtonText: 'Complete',
            denyButtonText: `Cancel`,
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                db.collection("loan").doc(uid).update({ 'repayment_completed': 1, 'updated_date': new Date() }).then(() => {
                    Swal.fire('Completed!', '', 'success')
                })

            } else if (result.isDenied) {
                Swal.fire('Changes are not saved', '', 'info')
            }
        })

        // alert("pp")
    }

    const changeStatus = (data, id) => {
        setEdit(true)
        setEditID(id)
        // setSubmit({ id: id, image: data.image, name: data.name, expert: data.expert, phone_no: data.phone_no, description: data.description, type: data.type, start_date_time: moment(data.start_date_time.toDate().toISOString()).format("YYYY-MM-DDThh:mm"), end_date_time: moment(data.end_date_time.toDate().toISOString()).format("YYYY-MM-DDThh:mm"), created_date: new Date() });
        setRepay({ loan_amount: data.loan_amount, loan_id: data.loan_id, loan_disbursement_date: data.loan_disbursement_date, emi_date: moment(data.emi_date.toDate().toISOString()).format("YYYY-MM-DDThh:mm"), amount: data.amount, payment_link: data.payment_link, paid_on: data.paid_on, status: data.status, })
    }

    const updateRepayment = async () => {

        db.collection("repayment_model").doc(editID).update({ 'loan_amount': personalData.loan_amount, 'loan_id': personalData.loan_id, 'loan_disbursement_date': personalData.loan_disbursement_date, 'emi_date': new Date(repay.emi_date), 'amount': repay.amount, 'payment_link': repay.status == 1 ? '' : repay.payment_link, 'paid_on': repay.paid_on, 'status': repay.status, 'updated_date': new Date() }).then(() => {

            setRepay({ loan_amount: '', loan_id: "", emi_date: '', amount: '', payment_link: '', paid_on: '', status: '', })
        })
        setEdit(false)
        // alert("pp")
    }


    return (
        <>
            {/* {repay.amount === personalData.repaymentModel.substr(4, 4)} */}
            {isLoading ? <div>
                <img src="/images/loader.gif" alt="Popup Image" class="center" style={{
                    display: "block",
                    marginRight: "auto",
                    position: "absolute",
                    height: "200px",
                    width: "200px",
                    top: "40%",
                    left: "50%",
                    marginLeft: "-96px",
                    marginTop: "-50px"
                }} />
            </div> :
                <div class="container-fluid my-3 py-3">
                    <div class="row mb-5">

                        <div class="col-lg-12 mt-lg-0 mt-4">


                            <div class="card mt-4" id="bank-details">
                                <div class="card-header">
                                    <h5>Repayment Model</h5>
                                    Loan ID : {personalData.loan_id}
                                </div>
                                <div class="card-body pt-0">
                                    <div class="row">
                                        <div class="col-6 pt-3">
                                            <div class="input-group-outline pt-3">
                                                <label class="form-label pb-2">Total Amount</label>
                                                <input type="text" class="form-control" value={personalData.loan_amount} />
                                            </div>
                                        </div>
                                        <div class="col-6 pt-3">
                                            <div class="input-group-outline pt-3">
                                                <label class="form-label pb-2">Repayment Model</label>
                                                <input type="text" class="form-control" onfocus="focused(this)" onfocusout="defocused(this)" value={personalData.repaymentModel} />
                                            </div>
                                        </div>

                                        <div class="col-6 pt-3">
                                            <div class="input-group-outline pt-3">
                                                <label class="form-label pb-2">Loan Disbursement Date</label>
                                                <input type="text" class="form-control" onfocus="focused(this)" onfocusout="defocused(this)" value={moment(personalData.loan_disbursement_date.toDate().toISOString()).format("MM/DD/YY, h:mm a")} />
                                            </div>
                                        </div>

                                        <div class="card" style={{ marginTop: "2rem" }}>
                                            <div class="table-responsive fix-width scroll-inner">
                                                <table class="table align-items-center mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">EMI Date</th>
                                                            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Amount</th>
                                                            <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Payment Link</th>
                                                            <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Updated On</th>
                                                            <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Status</th>
                                                            <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {repayment?.map(({ id, data }, index) => (
                                                            <>
                                                                <tr style={{ backgroundColor: data.status === "1" ? "#cddfce" : "transparent" }}>
                                                                    <td>
                                                                        <p class="text-xs font-weight-bold mb-0">{moment(data.emi_date.toDate().toISOString()).format("MM/DD/YY, h:mm a")}</p>
                                                                        {/* <p class="text-xs text-secondary mb-0">Organization</p> */}
                                                                    </td>
                                                                    <td class="align-middle text-center text-sm">
                                                                        <span class="text-secondary text-xs font-weight-normal">{data.amount}</span>
                                                                    </td>
                                                                    <td class="align-middle text-center text-sm">
                                                                        <span class="text-secondary text-xs font-weight-normal">{data.payment_link}</span>
                                                                    </td>
                                                                    <td class="align-middle text-center text-sm">
                                                                        <span class="text-secondary text-xs font-weight-normal">{moment(data.updated_date.toDate().toISOString()).format("MM/DD/YY, h:mm a")}</span>
                                                                    </td>
                                                                    <td class="align-middle text-center text-sm">
                                                                        <span class="text-secondary text-xs font-weight-normal">{data.status}</span>
                                                                    </td>
                                                                    {/* <td class="align-middle text-center" onClick={() => changeStatus(data, id)}> */}
                                                                    {data.status == 1 ?
                                                                        <td class="align-middle text-center">
                                                                            <span class="text-info text-xs font-weight-normal" style={{ cursor: "pointer" }}>Done</span>
                                                                        </td>
                                                                        :
                                                                        <td class="align-middle text-center" onClick={() => changeStatus(data, id)}>
                                                                            <span class="text-info text-xs font-weight-normal" style={{ cursor: "pointer" }}>Edit</span>
                                                                        </td>
                                                                    }


                                                                </tr>
                                                            </>
                                                        ))}

                                                    </tbody>
                                                </table>
                                                <div class="mt-4">{repayment.length == 0 ? "No data found!!!" : null}</div>
                                            </div>
                                        </div>


                                        {personalData.repayment_completed == 0 ?
                                            <>
                                                <div class="card mt-4" id="bank-details">
                                                    <div class="card-header">
                                                        <h5>Create EMI Plan</h5>
                                                    </div>
                                                    <div class="card-body pt-0">
                                                        <div class="row">

                                                            <div class="col-4 pt-3">
                                                                <div class="input-group-outline pt-3">
                                                                    <label class="form-label pb-2">EMI Date</label>
                                                                    {/* <input type="text" class="form-control" onfocus="focused(this)" onfocusout="defocused(this)" onChange={handleChangeRepay} value={repay.emi_date} id="emi_date" /> */}
                                                                    <input type="datetime-local" id="emi_date" onChange={handleChangeRepay} value={repay.emi_date} class="form-control" required />
                                                                </div>
                                                            </div>

                                                            <div class="col-4 pt-3">
                                                                <div class="input-group-outline pt-3">
                                                                    <label class="form-label pb-2">Amount</label>
                                                                    <input type="text" class="form-control" onfocus="focused(this)" onfocusout="defocused(this)" onChange={handleChangeRepay} value={repay.amount} id="amount" />
                                                                </div>
                                                            </div>
                                                            <div class="col-4 pt-3">
                                                                <div class="input-group-outline pt-3">
                                                                    <label class="form-label pb-2">Payment Link</label>
                                                                    <input type="text" class="form-control" onfocus="focused(this)" onfocusout="defocused(this)" onChange={handleChangeRepay} value={repay.payment_link} id="payment_link" />
                                                                </div>
                                                            </div>
                                                            <div class="col-4 pt-3">
                                                                <div class="input-group-outline pt-3">
                                                                    <label class="form-label pb-2">Status</label>
                                                                    {/* <input type="text" class="form-control" onfocus="focused(this)" onfocusout="defocused(this)" onChange={handleChangeRepay} value={repay.status} id="status" /> */}
                                                                    <select class="form-control" id="status" name="document_status" onChange={handleChangeRepay} value={repay.status}>
                                                                        <option value="">Change Status</option>
                                                                        <option value="0">UnPaid</option>
                                                                        <option value="1">Paid</option>
                                                                    </select>
                                                                </div>
                                                            </div>

                                                            {/* <div class="col-4 pt-3">
                                                        <div class="input-group-outline pt-3">
                                                            <label class="form-label pb-2">Paid On</label>
                                                            <input type="datetime-local" id="paid_on" onChange={handleChangeRepay} value={repay.paid_on} class="form-control" required />
                                                        </div>
                                                    </div> */}

                                                        </div>


                                                    </div>
                                                </div>
                                            </> : <>

                                            </>}




                                    </div>

                                    {personalData.repayment_completed == 0 ?
                                        <>
                                            {edit ?
                                                <button class="btn bg-gradient-warning mb-0 ms-2 mt-4" type="button" name="button" onClick={() => updateRepayment()}>Update</button>
                                                : <button class="btn bg-gradient-success mb-0 ms-2 mt-4" type="button" name="button" onClick={() => handleRepayment()}>Create EMI</button>}

                                            {/* // <button class="btn bg-gradient-Success mb-0 ms-2" type="button" name="button" onClick={() => handleRepayment()}>Create</button>} */}
                                            <button class="btn bg-gradient-warning mb-0 ms-2 mt-4" type="button" name="button" onClick={() => repaymentCompleted()}>Complete Repayment</button>

                                        </>
                                        :
                                        <>
                                            <button class="btn bg-gradient-warning mb-0 ms-2 mt-4" type="button" name="button" >Repayment Completed</button>
                                        </>
                                    }


                                </div>
                            </div>

                        </div>
                    </div>

                </div>

            }

        </>

    );

}

export default EmiDetails;

