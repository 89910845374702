import React, { useState, useEffect } from 'react';
import { useNavigate, Link, useParams } from 'react-router-dom';
import db from "./Firebase";
import { arrayUnion } from "firebase/firestore";
import { storage } from './Firebase';
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import ViewAllRequests from './ViewAllRequests';
import { CSVLink } from "react-csv";

function Workshop() {
    const navigate = useNavigate();
    const moment = extendMoment(Moment);
    const [data, setData] = useState([]);
    const [wdata, setWdata] = useState([]);
    const [csvdata, setCsvdata] = useState([]);

    const [mappedData, setMappedData] = useState([]);

    useEffect(() => {
        const storedUsername = localStorage.getItem("email");
        const storedPassword = localStorage.getItem("password");
        const storedRemember = localStorage.getItem("remember");

        if (storedRemember === "false" || !storedUsername || !storedPassword) {
            navigate('/signin');
        }
    }, []);

    const headers = [
        { label: "Full Name", key: "name" },
        { label: "Expert", key: "expert" },
        { label: "Start Date Time", key: "start_date_time", dataFormat: "MM/DD/YY, h:mm a" },
        { label: "End Date Time", key: "end_date_time", dataFormat: "MM/DD/YY, h:mm a" },

    ];

    useEffect(() => {
        // db.collection("workshop_event_data_hindi").orderBy("created_date", "desc").onSnapshot((snapshot) => {

        //     setData(
        //         snapshot.docs.map((doc) => ({
        //             id: doc.id,
        //             data: doc.data(),
        //         }))
        //     );

        //     setCsvdata(
        //         snapshot.docs.map((doc) => {
        //             const data = doc.data();
        //             return {
        //                 ...data,
        //                 start_date_time: moment(data.start_date_time.toDate().toISOString()).format("MM/DD/YY, h:mm a"),
        //                 end_date_time: moment(data.end_date_time.toDate().toISOString()).format("MM/DD/YY, h:mm a"),
        //             };
        //         })
        //     );
        // });

        db.collection("workshop_event_data").orderBy("created_date", "desc").onSnapshot((snapshot) => {
            setData(
                snapshot.docs.map((doc) => ({
                    id: doc.id,
                    data: doc.data(),
                }))
            );
        });

        // db.collection("workshop_registration").orderBy("created_date", "desc").onSnapshot((snapshot) => {
        //     setWdata(
        //         snapshot.docs.map((doc) => ({
        //             id: doc.id,
        //             data: doc.data(),
        //         }))
        //     );
        // });
        db.collection("workshop_registration").orderBy("created_date", "desc").onSnapshot((snapshot) => {
            setWdata(
                snapshot.docs.map((doc) => ({
                    id: doc.id,
                    data: doc.data(),
                }))
            );
        });

    }, []);


    useEffect(() => {
        // ... existing code

        // Call the mapping function
        const mappedData = mapWorkshopsToRegistrations(data, wdata);

        // Update the state with the mapped data and ID mapping
        setMappedData(mappedData);
    }, [data, wdata]);



    // const [idNameMapping, setIdNameMapping] = useState([]);
    // const idmapping = () => {
    //     {
    //         wdata.map(({ id, data }, index) => (
    //             idNameMapping[data.workshop_event_name] = (idNameMapping[data.workshop_event_name] || 0) + 1
    //         ))
    //         console.log(idNameMapping[data.workshop_event_name]);
    //     }
    // }

    function mapWorkshopsToRegistrations(workshops, registrations) {
        const workshopMap = new Map();

        // Create a map of workshop names to workshop data
        workshops.forEach(({ id, data }) => {
            workshopMap.set(data['name'], { id, name: data['name'], expert: data['expert'], end_date_time: data['end_date_time'], start_date_time: data['start_date_time'], totalRegistrations: 0 });
        });

        // Count registrations for each workshop
        registrations.forEach(({ id, data }) => {
            const workshopName = data['workshop_event_name'];
            if (workshopMap.has(workshopName)) {
                workshopMap.get(workshopName).totalRegistrations++;
            }
        });

        // Convert the map values to an array
        const mappedData = Array.from(workshopMap.values());

        return mappedData;
    }





    return (
        <>

            <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
                <div class="p-2" style={{ textAlign: "start", paddingBottom: "5px" }}>
                    {/* <span class="btn bg-gradient-secondary btn-sm mb-0 mr-2" style={{ color: "white" }} >&nbsp; Download Excel</span> */}
                    <Link to="/admin_panel"><img src="/images/menubutton.png" class="menu-toggle" alt="Responsive image" style={{ height: "2rem" }} /></Link>

                </div>
                <div class="">
                    <div class="p-2">
                        <div style={{ textAlign: "end", paddingBottom: "5px" }}>
                            {/* <Link to="/admin_panel" class="btn bg-gradient-primary btn-sm mb-0 mr-2" style={{ color: "white" }} >&nbsp; Go Back</Link> */}
                            <Link to="/workshop_hindi_reg" class="btn bg-gradient-primary btn-sm mb-0 mr-2" style={{ color: "white" }} >&nbsp; Workshop Hindi Registration Data</Link>

                            <CSVLink data={csvdata} headers={headers}>
                                <span class="btn bg-gradient-secondary btn-sm mb-0 mr-2" style={{ color: "white" }} >&nbsp; Download Excel</span>
                            </CSVLink>
                        </div>
                        <div class="card">
                            <div class="card-header pb-0 px-3">
                                <h6 class="mb-2">All Workshop Registration</h6>
                            </div>
                            <div class="">
                                <div class="card">
                                    <div class="table-responsive fix-width scroll-inner">
                                        <table class="table table-bordered table-hover">
                                            <thead class="thead-dark" style={{ position: "sticky", top: "0" }}>
                                                <tr style={{ textAlign: "left" }}>
                                                    <th class="col ps-2">Workshop Name</th>
                                                    <th class="col">Expert</th>
                                                    <th class="col ps-2">Start Date Time</th>
                                                    <th class="col ps-2"> End Date Time</th>
                                                    <th class="col ps-2">Total Registration</th>
                                                </tr>
                                            </thead>
                                            <tbody style={{ textAlign: "left" }}>
                                                {mappedData.map(({ id, name, expert, end_date_time, start_date_time, totalRegistrations }, index) => (
                                                    <tr key={id}>
                                                        <td>
                                                            <p className="text-xs font-weight-bold mb-0">{name}</p>
                                                        </td>
                                                        <td>
                                                            <div className="d-flex px-2 py-1">
                                                                <div className="d-flex flex-column justify-content-center">
                                                                    <h6 className="mb-0 text-xs">{expert}</h6>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="d-flex px-2 py-1">
                                                                <div className="d-flex flex-column justify-content-center">
                                                                    <h6 class="mb-0 text-xs">{moment(start_date_time.toDate().toISOString()).format("MM/DD/YY, h:mm a")}</h6>
                                                                    {/* <h6 className="mb-0 text-xs">{moment(start_date_time.toDate().toISOString()).format("MM/DD/YY, h:mm a")}</h6> */}
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="d-flex px-2 py-1">
                                                                <div className="d-flex flex-column justify-content-center">
                                                                    <h6 className="mb-0 text-xs">{moment(end_date_time.toDate().toISOString()).format("MM/DD/YY, h:mm a")}</h6>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="d-flex px-2 py-1">
                                                                <div className="d-flex flex-column justify-content-center">
                                                                    <h6 className="mb-0 text-xs">{totalRegistrations}</h6>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </main>


        </>
    )
}

export default Workshop;