import React, { useState, useEffect } from 'react';
import { useNavigate, Link, useParams } from 'react-router-dom';
import db from "./Firebase";
import { arrayUnion } from "firebase/firestore";
import { storage } from './Firebase';
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import ViewAllRequests from './ViewAllRequests';
import { CSVLink } from "react-csv";
import Swal from 'sweetalert2'

function EventRequest() {
    const moment = extendMoment(Moment);
    const [data, setData] = useState([]);
    const [csvdata, setCsvdata] = useState([]);

    const headers = [
        { label: "Event Type", key: "event_type" },
        { label: "Assistance Neede For", key: "assistance_neede_for" },
        { label: "Contact Number", key: "contact_no" },
        { label: "Description", key: "job_description" },
        { label: "No Of People Required", key: "no_of_people_required" },
        { label: "Parlour Address", key: "parlour_address" },
        { label: "Willing To Pay", key: "willing_to_pay" },
        { label: "Sutaible DateTime", key: "sutable_date_time" },
        { label: "Username", key: "username" },
        { label: "Name", key: "name" },
        { label: "Created Date", key: "created_date" },
    ];

    const initialFormStateFeedback = { request_status: '', remark: '', call_status: '', updated_date: new Date() }
    const [submit, setSubmit] = useState(initialFormStateFeedback);

    // Additional fields for Status and Remark
    const [status, setStatus] = useState("Pending");
    const [remark, setRemark] = useState("");
    const [uid, setUid] = useState('');
    const [editIndex, setEditIndex] = useState();
    const [edit, setEdit] = useState(false);

    const [doneCount, setDoneCount] = useState(0);


    useEffect(() => {
        const fetchData = () => {
            try {
                db.collection("event_request")
                    .orderBy("created_date", "desc")
                    .onSnapshot((snapshot) => {
                        const dataArray = [];
                        let count = 0;

                        setCsvdata(
                            snapshot.docs.map((doc) => (
                                doc.data()
                            ))
                        );

                        snapshot.forEach((doc) => {
                            const documentData = {
                                id: doc.id,
                                data: doc.data(),
                            };

                            dataArray.push(documentData);

                            // Check the status field and increment count
                            if (documentData.data.request_status === '1') {
                                count += 1;
                            }
                        });

                        setData(dataArray);
                        setDoneCount(count);
                    });
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    // useEffect(() => {
    //     db.collection("event_request").orderBy("created_date", "desc").onSnapshot((snapshot) => {

    //         setData(
    //             snapshot.docs.map((doc) => ({
    //                 id: doc.id,
    //                 data: doc.data(),
    //             }))
    //         );

    //         setCsvdata(
    //             snapshot.docs.map((doc) => (
    //                 doc.data()
    //             ))
    //         );
    //     });

    // }, []);

    const callStatus = (uid, index) => {
        console.log(uid)
        console.log(index)
        setUid(uid);
        setEditIndex(index);
    }

    const handleRemarkChange = (event) => {
        setRemark(event.target.value);
    };

    const handleStatusChange = async (e) => {
        // setStatus(event.target.value);
        // const { id, value } = e.target
        // setSubmit({ ...submit, [id]: value })

        if (e.target.value == "2") {
            const { value: text } = await Swal.fire({
                input: 'textarea',
                inputLabel: 'Update Remark',
                inputPlaceholder: 'Type your message here...',
                inputAttributes: {
                    'aria-label': 'Type your message here'
                },
                showCancelButton: true
            })
            db.collection("event_request").doc(uid).update({ 'request_status': "2", 'remark': text, 'updated_date': new Date() }).then(() => {
                console.log("data Updated");
            })

        } else {
            const { value: text } = await Swal.fire({
                input: 'textarea',
                inputLabel: 'Final Remark',
                inputPlaceholder: 'Type your message here...',
                inputAttributes: {
                    'aria-label': 'Type your message here'
                },
                showCancelButton: true
            })
            db.collection("event_request").doc(uid).update({ 'request_status': "1", 'remark': text, 'updated_date': new Date() }).then(() => {
                console.log("data Updated");
            })
        }

    };

    const handleCallStatusChange = (e) => {
        console.log("calling.....")
        console.log(uid)
        db.collection("event_request").doc(uid).update({ 'call_status': e.target.value, 'updated_date': new Date() }).then(() => {
            console.log("data Updated");
        })
        // console.log(e.target.value)
    }

    return (
        <>
            <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
                <div class="p-2" style={{ paddingBottom: "5px", display: "flex", justifyContent: "space-between" }}>
                    {/* <span class="btn bg-gradient-secondary btn-sm mb-0 mr-2" style={{ color: "white" }} >&nbsp; Download Excel</span> */}
                    <Link to="/admin_panel"><img src="/images/menubutton.png" class="menu-toggle" alt="Responsive image" style={{ height: "2rem" }} /></Link>
                    <CSVLink data={csvdata} headers={headers}>
                        <span class="btn bg-gradient-secondary btn-sm mb-0 mr-2" style={{ color: "white" }} >&nbsp; Download Excel</span>
                    </CSVLink>

                </div>

                <div class="">
                    <div class="p-2">
                        <div class="card">
                            <div class="card-header pb-0 px-3">
                                <h6 class="mb-2">Event Request</h6>
                            </div>
                            <div class="d-flex p-2" style={{ justifyContent: "center", fontWeight: "bold" }}>
                                Total : {data.length} || Pending : {data.length - doneCount} || Done : {doneCount}
                            </div>
                            <div class="">
                                {/* <div style={{ textAlign: "end", paddingBottom: "5px" }}>
                        <a class="btn bg-gradient-primary btn-sm mb-0" style={{ color: "white" }} onClick={() => changeStatus()}>+&nbsp; Add Rent Details</a>
                    </div> */}
                                <div class="">
                                    <div class="table-responsive fix-width scroll-inner">
                                        <table class="table table-bordered table-hover">
                                            <thead class="thead-dark" style={{ position: "sticky", top: "0" }}>
                                                <tr style={{ textAlign: "start" }}>
                                                    <th class="col ps-2">Event Type</th>
                                                    <th class="col">Username</th>
                                                    <th class="col">Name</th>
                                                    <th class="col">Parlour Address</th>
                                                    <th class="col ps-2">Contact No</th>
                                                    <th class="col ps-2">Assistant Need For</th>
                                                    <th class="col ps-2">Job Description</th>
                                                    <th class="col ps-2">People Required</th>
                                                    <th class="col ps-2">Willing To Pay</th>
                                                    <th class="col">Call Status</th>
                                                    <th class="col">Status</th>
                                                    <th class="col">Final Remark</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {data?.map(({ id, data }, index) => (
                                                    <>
                                                        <tr style={{ textAlign: "start" }}>
                                                            <td>
                                                                <p class="text-xs font-weight-bold mb-0">{data.event_type}</p>
                                                                <p class="text-xs text-secondary mb-0">{moment(data.created_date.toDate().toISOString()).format("MM/DD/YY, h:mm a")}</p>
                                                            </td>
                                                            <td>
                                                                <div class="d-flex px-2 py-1">

                                                                    <div class="d-flex flex-column justify-content-center">
                                                                        <h6 class="mb-0 text-xs">{data.username}</h6>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div class="d-flex px-2 py-1">

                                                                    <div class="d-flex flex-column justify-content-center">
                                                                        <h6 class="mb-0 text-xs">{data.name}</h6>
                                                                    </div>
                                                                </div>
                                                            </td>

                                                            <td style={{ whiteSpace: 'pre-wrap' }}>
                                                                <p class="text-xs font-weight-bold mb-0" style={{ width: "15rem" }}>{data.parlour_address}</p>

                                                            </td>



                                                            <td>
                                                                <div class="d-flex px-2 py-1">

                                                                    <div class="d-flex flex-column justify-content-center">
                                                                        <h6 class="mb-0 text-xs">{data.contact_no}</h6>
                                                                    </div>
                                                                </div>
                                                            </td>                     <td>
                                                                <div class="d-flex px-2 py-1">

                                                                    <div class="d-flex flex-column justify-content-center">
                                                                        <h6 class="mb-0 text-xs">{data.assistance_neede_for}</h6>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td style={{ whiteSpace: 'pre-wrap' }}>
                                                                <p class="text-xs font-weight-bold mb-0" style={{ width: "15rem" }}>{data.job_description}</p>

                                                            </td>

                                                            <td>
                                                                <div class="d-flex px-2 py-1">

                                                                    <div class="d-flex flex-column justify-content-center">
                                                                        <h6 class="mb-0 text-xs">{data.no_of_people_required}</h6>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div class="d-flex px-2 py-1">

                                                                    <div class="d-flex flex-column justify-content-center">
                                                                        <h6 class="mb-0 text-xs">
                                                                            {data.willing_to_pay}
                                                                        </h6>

                                                                    </div>
                                                                </div>
                                                            </td>
                                                            {data.call_status ?
                                                                <>
                                                                    <td>
                                                                        <select class="form-control" id="call_status" name="call_status" onClick={() => callStatus(id, index)} onChange={handleCallStatusChange} value={data.call_status} style={{ width: "10rem", background: data.call_status === "Connected" ? "green" : data.callStatus === "Did Not Answer" ? "black" : "brown", color: "white" }}>
                                                                            <option value="" disabled>Call Status</option>
                                                                            <option selected={data.call_status == "Connected" ? true : false} value="Connected">Connected</option>
                                                                            <option selected={data.call_status == "Did Not Answer" ? true : false} value="Did Not Answer">Did Not Answer</option>
                                                                            <option selected={data.call_status == "Not Reachable" ? true : false} value="Not Reachable">Not Reachable</option>
                                                                        </select>
                                                                    </td>
                                                                </>
                                                                :
                                                                <>
                                                                    <td>
                                                                        <select class="form-control" id="call_status" name="call_status" onClick={() => callStatus(id, index)} onChange={handleCallStatusChange} value={submit.call_status} style={{ width: "10rem", }}>
                                                                            <option value="" disabled>Call Status</option>
                                                                            <option value="Connected">Connected</option>
                                                                            <option value="Did Not Answer">Did Not Answer</option>
                                                                            <option value="Not Reachable">Not Reachable</option>
                                                                        </select>
                                                                    </td>
                                                                </>
                                                            }

                                                            {data.request_status === "1" ?
                                                                <>
                                                                    <td>
                                                                        <select class="form-control" id="request_status" name="request_status" onClick={() => callStatus(id, index)} onChange={handleStatusChange} value={submit.request_status} style={{ width: "8rem", background: "green", color: "white" }}>
                                                                            <option value="" disabled>Done</option>
                                                                        </select>
                                                                    </td>
                                                                </>
                                                                :
                                                                <>
                                                                    <td>
                                                                        <select class="form-control" id="request_status" name="request_status" onClick={() => callStatus(id, index)} onChange={handleStatusChange} value={submit.request_status} style={{ width: "8rem" }}>
                                                                            <option value="" disabled>Pending</option>
                                                                            <option selected={data.request_status == "2" ? true : false} value="2">Update Status</option>
                                                                            <option selected={data.request_status == "1" ? true : false} value="1">Done</option>
                                                                        </select>
                                                                    </td>
                                                                </>
                                                            }


                                                            <td>
                                                                <textarea class="form-control" value={data.remark} onChange={handleRemarkChange} style={{ width: "10rem", background: "#ddd" }} disabled />
                                                            </td>


                                                        </tr>

                                                    </>
                                                ))}


                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>


            </main>


        </>
    )
}

export default EventRequest;