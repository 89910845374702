import React from 'react'
import { Link } from 'react-router-dom';

function Quicklinks() {
    return (
        <>
            <div class="col-lg-12 p-2" >
                <h1 style={{ fontSize: "24px", fontWeight: "600" }}>Refer & Earn</h1>
                <hr class="mt-2" style={{ width: "100%", textAlign: "left", marginLeft: "0" }}></hr>
            </div>



            <div class="container">
                <div class="row" >
                    <Link to="/upload_course" class="ex1" style={{ textDecoration: 'none' }}>
                        <div class="col-sm m-2 p-2" style={{ backgroundColor: "#f8f9fa", borderRadius: "20px" }}>
                            <ul>
                                <li style={{ fontSize: "21px" }}><i class="fa fa-bullseye" style={{ color: "#d4573f", }} aria-hidden="true"></i>       Upload Course</li>
                            </ul>
                        </div>
                    </Link>
                    <div class="col-sm" >

                    </div>
                    <div class="col-sm">

                    </div>
                </div>
            </div>


            <div class="col-lg-12 p-2" >
                <h1 style={{ fontSize: "24px", fontWeight: "600" }}>Consumables</h1>
                <hr class="mt-2" style={{ width: "100%", textAlign: "left", marginLeft: "0" }}></hr>
            </div>


            <div class="container">

                <div class="row">
                    <Link to="/upload_consumables" class="ex1" style={{ textDecoration: 'none', marginRight: "3rem" }}>
                        <div class="col-sm m-2 p-2" style={{ backgroundColor: "#f8f9fa", borderRadius: "20px" }}>
                            <ul>
                                <li style={{ fontSize: "21px" }}><i class="fa fa-bullseye" style={{ color: "#d4573f", }} aria-hidden="true"></i>       Upload Consumables</li>
                            </ul>
                        </div>
                    </Link>

                    <Link to="/edit_consumables" class="ex1" style={{ textDecoration: 'none', marginRight: "3rem" }}>
                        <div class="col-sm m-2 p-2" style={{ backgroundColor: "#f8f9fa", borderRadius: "20px" }}>
                            <ul>
                                <li style={{ fontSize: "21px" }}><i class="fa fa-bullseye" style={{ color: "#d4573f", }} aria-hidden="true"></i>       Edit Consumables</li>
                            </ul>
                        </div>
                    </Link>

                    <Link to="/upload_consumables" class="ex1" style={{ textDecoration: 'none' }}>
                        <div class="col-sm m-2 p-2" style={{ backgroundColor: "#f8f9fa", borderRadius: "20px" }}>
                            <ul>
                                <li style={{ fontSize: "21px" }}><i class="fa fa-bullseye" style={{ color: "#d4573f", }} aria-hidden="true"></i>       Upload Sell Products</li>
                            </ul>
                        </div>
                    </Link>
                    <div class="col-sm">

                    </div>
                </div>

            </div>


            <div class="col-lg-12 p-2" >
                <h1 style={{ fontSize: "24px", fontWeight: "600" }}>Parlour Management</h1>
                <hr class="mt-2" style={{ width: "100%", textAlign: "left", marginLeft: "0" }}></hr>
            </div>


            <div class="container">
                <div class="row">
                    <Link to="/invite" class="ex1" style={{ textDecoration: 'none', marginRight: "3rem" }}>
                        <div class="col-sm m-2 p-2" style={{ backgroundColor: "#f8f9fa", borderRadius: "20px" }}>
                            <ul>
                                <li style={{ fontSize: "21px" }}><i class="fa fa-bullseye" style={{ color: "#d4573f", }} aria-hidden="true"></i>   Add New Beauty Parlour</li>
                            </ul>
                        </div>
                    </Link>
                    <Link to="/beatrainer" class="ex1" style={{ textDecoration: 'none', marginRight: "3rem" }}>
                        <div class="col-sm m-2 p-2" style={{ backgroundColor: "#f8f9fa", borderRadius: "20px" }}>
                            <ul>
                                <li style={{ fontSize: "21px" }}><i class="fa fa-bullseye" style={{ color: "#d4573f", }} aria-hidden="true"></i>   Be A Trainer</li>
                            </ul>
                        </div>
                    </Link>
                    <Link to="/bedistributor" class="ex1" style={{ textDecoration: 'none' }}>
                        <div class="col-sm m-2 p-2" style={{ backgroundColor: "#f8f9fa", borderRadius: "20px" }}>
                            <ul>
                                <li style={{ fontSize: "21px" }}><i class="fa fa-bullseye" style={{ color: "#d4573f", }} aria-hidden="true"></i>   Be A Distributor</li>
                            </ul>
                        </div>
                    </Link>
                </div>
            </div>

            <div class="col-lg-12 p-2" >
                <h1 style={{ fontSize: "24px", fontWeight: "600" }}>Grow Business</h1>
                <hr class="mt-2" style={{ width: "100%", textAlign: "left", marginLeft: "0" }}></hr>
            </div>


            <div class="container">
                <div class="row">

                    <Link to="/add_mentors" class="ex1" style={{ textDecoration: 'none', marginRight: "3rem" }}>
                        <div class="col-sm m-2 p-2" style={{ backgroundColor: "#f8f9fa", borderRadius: "20px" }}>
                            <ul>
                                <li style={{ fontSize: "21px" }}><i class="fa fa-bullseye" style={{ color: "#d4573f", }} aria-hidden="true"></i> Add Mentor Details</li>
                            </ul>
                        </div>
                    </Link>
                    <Link to="/job_application" class="ex1" style={{ textDecoration: 'none' }}>
                        <div class="col-sm m-2 p-2" style={{ backgroundColor: "#f8f9fa", borderRadius: "20px" }}>
                            <ul>
                                <li style={{ fontSize: "21px" }}><i class="fa fa-bullseye" style={{ color: "#d4573f", }} aria-hidden="true"></i> Job Application</li>
                            </ul>
                        </div>
                    </Link>

                    <div class="col-sm">

                    </div>
                </div>
            </div>



            <div class="col-lg-12 p-2" >
                <h1 style={{ fontSize: "24px", fontWeight: "600" }}>Workshop & Event</h1>
                <hr class="mt-2" style={{ width: "100%", textAlign: "left", marginLeft: "0" }}></hr>
            </div>


            <div class="container">
                <div class="row">
                    <Link to="/upload_workshop" class="ex1" style={{ textDecoration: 'none', marginRight: "3rem" }}>
                        <div class="col-sm m-2 p-2" style={{ backgroundColor: "#f8f9fa", borderRadius: "20px" }}>
                            <ul>
                                <li style={{ fontSize: "21px" }}><i class="fa fa-bullseye" style={{ color: "#d4573f", }} aria-hidden="true"></i> Upload Workshop</li>
                            </ul>
                        </div>
                    </Link>
                    {/* <Link to="/share_workshop" class="ex1" style={{ textDecoration: 'none' }}>
                        <div class="col-sm m-2 p-2" style={{ backgroundColor: "#f8f9fa", borderRadius: "20px" }}>
                            <ul>
                                <li style={{ fontSize: "21px" }}><i class="fa fa-bullseye" style={{ color: "#d4573f", }} aria-hidden="true"></i> Share Workshop</li>
                            </ul>
                        </div></Link> */}
                    <div class="col-sm">

                    </div>          <div class="col-sm">

                    </div>
                </div>
            </div>

            <div class="col-lg-12 p-2" >
                <h1 style={{ fontSize: "24px", fontWeight: "600" }}>Award Show</h1>
                <hr class="mt-2" style={{ width: "100%", textAlign: "left", marginLeft: "0" }}></hr>
            </div>


            <div class="container">
                <div class="row">
                    <Link to="/upload_awardshow" class="ex1" style={{ textDecoration: 'none', marginRight: "3rem" }}>
                        <div class="col-sm m-2 p-2" style={{ backgroundColor: "#f8f9fa", borderRadius: "20px" }}>
                            <ul>
                                <li style={{ fontSize: "21px" }}><i class="fa fa-bullseye" style={{ color: "#d4573f", }} aria-hidden="true"></i> Upload Award Show Data</li>
                            </ul>
                        </div></Link>

                    <div class="col-sm">

                    </div>
                </div>
            </div>


            <div class="col-lg-12 p-2" >
                <h1 style={{ fontSize: "24px", fontWeight: "600" }}>Admin</h1>
                <hr class="mt-2" style={{ width: "100%", textAlign: "left", marginLeft: "0" }}></hr>
            </div>


            <div class="container">
                <div class="row">
                    <Link to="/admin" class="ex1" style={{ textDecoration: 'none', marginRight: "3rem" }}>
                        <div class="col-sm m-2 p-2" style={{ backgroundColor: "#f8f9fa", borderRadius: "20px" }}>
                            <ul>
                                <li style={{ fontSize: "21px" }}><i class="fa fa-bullseye" style={{ color: "#d4573f", }} aria-hidden="true"></i> Upload Parlour Data</li>
                            </ul>
                        </div></Link>
                    <Link to="/search" class="ex1" style={{ textDecoration: 'none', marginRight: "3rem" }}>
                        <div class="col-sm m-2 p-2" style={{ backgroundColor: "#f8f9fa", borderRadius: "20px" }}>
                            <ul>
                                <li style={{ fontSize: "21px" }}><i class="fa fa-bullseye" style={{ color: "#d4573f", }} aria-hidden="true"></i> Search Parlour details</li>
                            </ul>
                        </div>
                    </Link>

                    {/* ===========important========================= */}
                    <Link to="/order_confirmation" class="ex1" style={{ textDecoration: 'none', marginRight: "3rem" }}>
                        <div class="col-sm m-2 p-2" style={{ backgroundColor: "#f8f9fa", borderRadius: "20px" }}>
                            <ul>
                                <li style={{ fontSize: "21px" }}><i class="fa fa-bullseye" style={{ color: "#d4573f", }} aria-hidden="true"></i> Order Confirmation</li>
                            </ul>
                        </div></Link>

                    <Link to="/addpincode" class="ex1" style={{ textDecoration: 'none' }}>
                        <div class="col-sm m-2 p-2" style={{ backgroundColor: "#f8f9fa", borderRadius: "20px" }}>
                            <ul>
                                <li style={{ fontSize: "21px" }}><i class="fa fa-bullseye" style={{ color: "#d4573f", }} aria-hidden="true"></i> Add Pincode</li>
                            </ul>
                        </div></Link>

                    <div class="col-sm">

                    </div>
                </div>
            </div>



            <div class="col-lg-12 p-2" >
                <h1 style={{ fontSize: "24px", fontWeight: "600" }}>Others</h1>
                <hr class="mt-2" style={{ width: "100%", textAlign: "left", marginLeft: "0" }}></hr>
            </div>


            <div class="container">
                <div class="row">
                    <Link to="/upload_awardshow" class="ex1" style={{ textDecoration: 'none', marginRight: "3rem" }}>
                        <div class="col-sm m-2 p-2" style={{ backgroundColor: "#f8f9fa", borderRadius: "20px" }}>
                            <ul>
                                <li style={{ fontSize: "21px" }}><i class="fa fa-bullseye" style={{ color: "#d4573f", }} aria-hidden="true"></i> Upload Award Show Data</li>
                            </ul>
                        </div></Link>
                    <Link to="/trends" class="ex1" style={{ textDecoration: 'none', marginRight: "3rem" }}>
                        <div class="col-sm m-2 p-2" style={{ backgroundColor: "#f8f9fa", borderRadius: "20px" }}>
                            <ul>
                                <li style={{ fontSize: "21px" }}><i class="fa fa-bullseye" style={{ color: "#d4573f", }} aria-hidden="true"></i> Trends Video</li>
                            </ul>
                        </div></Link>


                    <Link to="/oovve_champian" class="ex1" style={{ textDecoration: 'none', marginRight: "3rem" }}>
                        <div class="col-sm m-2 p-2" style={{ backgroundColor: "#f8f9fa", borderRadius: "20px" }}>
                            <ul>
                                <li style={{ fontSize: "21px" }}><i class="fa fa-bullseye" style={{ color: "#d4573f", }} aria-hidden="true"></i>  Oove Champian</li>
                            </ul>
                        </div></Link>
                    {/* <Link to="/add_mentors" class="ex1" style={{ textDecoration: 'none', marginRight: "3rem" }}>
                        <div class="col-sm m-2 p-2" style={{ backgroundColor: "#f8f9fa", borderRadius: "20px" }}>
                            <ul>
                                <li style={{ fontSize: "21px" }}><i class="fa fa-bullseye" style={{ color: "#d4573f", }} aria-hidden="true"></i> Add Mentor Details</li>
                            </ul>
                        </div>
                    </Link> */}
                    {/* <Link to="/job_application" class="ex1" style={{ textDecoration: 'none' }}>
                        <div class="col-sm m-2 p-2" style={{ backgroundColor: "#f8f9fa", borderRadius: "20px" }}>
                            <ul>
                                <li style={{ fontSize: "21px" }}><i class="fa fa-bullseye" style={{ color: "#d4573f", }} aria-hidden="true"></i> Job Application</li>
                            </ul>
                        </div>
                    </Link> */}

                    <div class="col-sm">

                    </div>
                </div>
            </div>

        </>

    )
}

export default Quicklinks