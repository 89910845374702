import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate, Link, useParams } from "react-router-dom"
import Select from 'react-select';
import FooterMain from "./FooterMain";


function AboutUs() {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);


    return (
        <>
            <div class="">
                <div class="row">
                    <div class="col-xs-12 col-sm-12 col-lg-12 p-5" style={{ backgroundColor: "#fef0e4", }}>
                        <div class="row">
                            <div class="col-sm">

                            </div>
                            <div class="col-sm">
                                <img style={{ height: "20rem" }} class="img-responsive" src="/images/wel101.svg" />
                            </div>
                            <div class="col-sm">

                            </div>
                        </div>


                        <div class="col-xs-12 col-sm-12 col-lg-12" style={{ display: "flex", justifyContent: "center" }}>
                            <h1 class="responsive-font-example" style={{ fontFamily: "fangsong", color: "#dc3545" }}>Welcome to Youvati</h1>
                        </div>

                        <div class="col-xs-12 col-sm-12 col-lg-12">
                            <p style={{ color: "black", fontWeight: "bold", textAlign: "justify" }}>A platform that empowers beauty professionals to grow their business and connect with customers easily. We are proud to be part of Empower Pragati, an award-winning skill training organization that has trained 5 lakh youth since its inception and is a partner of the National Skill Development Corporation of India.</p>
                        </div>

                    </div>
                </div>
            </div>
            <div class="">
                <div class="containero" style={{ display: "flex", alignItems: "center", padding: "2rem" }}>
                    <div class="text" style={{ flex: "1" }}>
                        <div class="container">
                            <p style={{ color: "black", fontWeight: "400", }}>Welcome to Youvati, a platform that empowers beauty professionals to grow their business and connect with customers easily. We are proud to be part of Empower Pragati, an award-winning skill training organization that has trained 5 lakh youth since its inception and is a partner of the National Skill Development Corporation of India.</p>

                            <p style={{ color: "black", fontWeight: "400", }}>We understand the challenges faced by beauty-preneurs (BP's), from limited access to marketing resources and customers to lack of capital to expand their business. That's why we've created Youvati, a platform that helps BP’s overcome these challenges and increase their income.</p>
                            <p style={{ color: "black", fontWeight: "400", }}>With Youvati, beauticians can access a range of services to reach more customers and expand their offerings. Our platform connects them to customers looking for quality beauty services at affordable prices, and provides marketing tools to create compelling promotional materials and showcase their work with a professional portfolio website.</p>
                            <p style={{ color: "black", fontWeight: "400", }}>For customers, we provide convenience and quality by bringing all beauty services to their doorstep without compromising on cost.</p>
                            <p style={{ color: "black", fontWeight: "400", }}>At Youvati, we are dedicated to supporting the beauty industry in India and helping beauty professionals succeed. We believe that everyone deserves access to quality beauty services, and we're here to make that a reality. Join us today and be part of the Youvati community!</p>
                        </div>
                    </div>
                    <div class="" style={{ flex: "1", textAlign: "right" }}>
                        <img src="/images/bt101.svg" alt="Your Image" style={{ maxWidth: "100%" }} />
                    </div>
                </div>
                {/* <div class="row">
                    <div class="wrap col" style={{ display: "flex", flexDirection: "column", width: "60%", margin: "2rem auto", lineHeight: "1.6" }}>
                        <div class="container">
                            <p style={{ color: "black", fontWeight: "400", }}>Welcome to Youvati, a platform that empowers beauty professionals to grow their business and connect with customers easily. We are proud to be part of Empower Pragati, an award-winning skill training organization that has trained 5 lakh youth since its inception and is a partner of the National Skill Development Corporation of India.</p>

                            <p style={{ color: "black", fontWeight: "400", }}>We understand the challenges faced by beauty-preneurs (BP's), from limited access to marketing resources and customers to lack of capital to expand their business. That's why we've created Youvati, a platform that helps BP’s overcome these challenges and increase their income.</p>
                            <p style={{ color: "black", fontWeight: "400", }}>With Youvati, beauticians can access a range of services to reach more customers and expand their offerings. Our platform connects them to customers looking for quality beauty services at affordable prices, and provides marketing tools to create compelling promotional materials and showcase their work with a professional portfolio website.</p>
                            <p style={{ color: "black", fontWeight: "400", }}>For customers, we provide convenience and quality by bringing all beauty services to their doorstep without compromising on cost.</p>
                            <p style={{ color: "black", fontWeight: "400", }}>At Youvati, we are dedicated to supporting the beauty industry in India and helping beauty professionals succeed. We believe that everyone deserves access to quality beauty services, and we're here to make that a reality. Join us today and be part of the Youvati community!</p>
                        </div>
                    </div>
                    <div class="col">
                        <img style={{ maxHeight: "35rem" }} class="img-responsive" src="/images/beautywale.png" />
                    </div>
                </div> */}
            </div>

            {/* <div class="wrap" style={{ display: "flex", flexDirection: "column", width: "80%", margin: "2rem auto", lineHeight: "1.6" }}> */}
            <div class="" style={{ display: "flex", justifyContent: "center", color: "black", fontWeight: "400" }}>
                {/* <p>Note that this technique will also make the image responsive when the width of the window is beyond the size of the image. Go full screen to see the effect.</p> */}
            </div>

            <FooterMain />



            {/* <section style={{ boxShadow: "0 6px 6px -6px #777" }}>
                <div class="navi" >
                    <input type="checkbox" id="nav-check" />
                    <div class="nav-header">
                        <div class="nav-title">
                            <Link to='/' > <img src="/images/youvati_logo.png" alt="" style={{ height: "27px", marginLeft: "2rem" }} /></Link>
                        </div>
                    </div>


                </div>
            </section>

            <h2 style={{ textAlign: "center" }}>About Us Page</h2>
            <div class="row">
                <div class="column">
                    <div class="card">
                        <div class="container">
                            <p style={{ color: "black" }}>Welcome to Youvati, a platform that empowers beauty professionals to grow their business and connect with customers easily. We are proud to be part of Empower Pragati, an award-winning skill training organization that has trained 5 lakh youth since its inception and is a partner of the National Skill Development Corporation of India.</p>

                            <p style={{ color: "black" }}>We understand the challenges faced by beauty-preneurs (BP’s), from limited access to marketing resources and customers to lack of capital to expand their business. That's why we've created Youvati, a platform that helps BP’s overcome these challenges and increase their income.</p>
                            <p style={{ color: "black" }}>With Youvati, beauticians can access a range of services to reach more customers and expand their offerings. Our platform connects them to customers looking for quality beauty services at affordable prices, and provides marketing tools to create compelling promotional materials and showcase their work with a professional portfolio website.</p>
                            <p style={{ color: "black" }}>For customers, we provide convenience and quality by bringing all beauty services to their doorstep without compromising on cost.</p>
                            <p style={{ color: "black" }}>At Youvati, we are dedicated to supporting the beauty industry in India and helping beauty professionals succeed. We believe that everyone deserves access to quality beauty services, and we're here to make that a reality. Join us today and be part of the Youvati community!</p>
                        </div>
                    </div>
                </div>
            </div>
            <FooterMain /> */}
        </>
    );
}

export default AboutUs;