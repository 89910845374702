import React, { useState, useEffect } from 'react';
import AdminPanel from './AdminPanel';
import Swal from 'sweetalert2'
import { useNavigate, Link, useParams } from 'react-router-dom';
import db from "./Firebase";
import { storage } from './Firebase';
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import ViewAppData from './ViewAppData';
import Multiselect from 'multiselect-react-dropdown';


function GuidanceSession() {
    const navigate = useNavigate();
    const moment = extendMoment(Moment);
    const initialFormStateFeedback = { id: '', image: '', name: '', description: '', start_date_time: '', end_date_time: '', join_link: '', registered_uid: [], status: '', created_date: new Date(), }
    const [submit, setSubmit] = useState(initialFormStateFeedback)
    const [data, setData] = useState([]);
    const [editID, setEditID] = useState("");
    const [edit, setEdit] = useState(false);
    const [ShowProgress, setShowProgress] = useState(false);
    const [show, setshow] = useState(true);
    const [progresspercent, setProgresspercent] = useState(0);

    useEffect(() => {
        const storedUsername = localStorage.getItem("email");
        const storedPassword = localStorage.getItem("password");
        const storedRemember = localStorage.getItem("remember");

        if (storedRemember === "false" || !storedUsername || !storedPassword) {
            navigate('/signin');
        }
    }, []);

    const [options, setOptions] = useState([
        { name: 'Online' },
        { name: 'Aurangabad' },
        { name: 'Bathinda' },
        { name: 'Ghaziabad' },
        { name: 'Meerut' },
        { name: 'Mansa' },
        { name: 'Patiala' }
    ]);

    const [selectedValue, setSelectedValue] = useState([]);


    const onSelect = (selectedList, selectedItem) => {
        // Handle selection
        // ...
        setSelectedValue(selectedList);
    }

    const onRemove = (selectedList, removedItem) => {
        // Handle removal
        // ...
        setSelectedValue(selectedList);
    }

    const handleChange = (e) => {
        const { id, value } = e.target
        setSubmit({ ...submit, [id]: value })
    }

    const changeStatus = (data, id) => {
        setEdit(true)
        setEditID(id)
        // setSubmit({ id: id, image: data.image, name: data.name, name_english: data.name_english, expert: data.expert, phone_no: data.phone_no, description: data.description, type: data.type, mode: data.mode, direction: data.direction, instagram_id: data.instagram_id, organiser: data.organiser, event_type: data.event_type, amount: data.amount, isCertificate: data.isCertificate, isVideo: data.isVideo, created_by: data.created_by, start_date_time: moment(data.start_date_time.toDate().toISOString()).format("YYYY-MM-DDTHH:mm"), end_date_time: moment(data.end_date_time.toDate().toISOString()).format("YYYY-MM-DDTHH:mm"), join_link: data.join_link, passcode: data.passcode, meeting_id: data.meeting_id, created_date: new Date() });
        setSubmit({ id: id, image: data.image, name: data.name, description: data.description, start_date_time: moment(data.start_date_time.toDate().toISOString()).format("YYYY-MM-DDTHH:mm"), end_date_time: moment(data.end_date_time.toDate().toISOString()).format("YYYY-MM-DDTHH:mm"), join_link: data.join_link, registered_uid: data.registered_uid, status: data.status, created_date: new Date(), })
        openModalShow();
    }

    const addNew = () => {
        setEdit(false)
        openModalShow();
        setSubmit({ id: '', image: '', name: '', description: '', start_date_time: '', end_date_time: '', join_link: '', registered_uid: [], status: '', created_date: new Date(), });
    }

    const openModalShow = () => {
        // alert("ooo")
        window.openModelshow();
    }

    const closeModel = () => {
        window.openModelNew();
    }

    const handleSubmit = (e) => {
        console.log("callingggggg")
        e.preventDefault()

        if (edit) {
            console.log("edit")
            if (e.target[0]?.files[0] == null) {
                db.collection("upcoming_sessions").doc(editID).update({
                    'name': submit.name,
                    'description': submit.description,
                    'start_date_time': new Date(submit.start_date_time),
                    'end_date_time': new Date(submit.end_date_time),
                    'join_link': submit.join_link,
                    'registered_uid': [],
                    'status': "1",
                    'image': submit.image,
                    'created_date': new Date(),
                }).then(() => {
                    console.log('Updated Successfully');
                    const Toast = Swal.mixin({
                        toast: true,
                        background: '#69aba6',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', Swal.stopTimer)
                            toast.addEventListener('mouseleave', Swal.resumeTimer)
                        }
                    })

                    Toast.fire({
                        icon: 'success',
                        title: 'Updated Successfully'
                    })
                    closeModel();

                    setSubmit({ id: '', image: '', name: '', description: '', start_date_time: '', end_date_time: '', join_link: '', registered_uid: [], status: '', created_date: new Date(), })
                })
            } else {
                const file = e.target[0]?.files[0]
                // console.log(file)
                if (!file) return;
                const storageRef = ref(storage, `files/${file.name}`);
                const uploadTask = uploadBytesResumable(storageRef, file);
                setshow(false)

                uploadTask.on("state_changed",
                    (snapshot) => {
                        const progress =
                            Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                        setProgresspercent(progress);
                    },
                    (error) => {
                        alert(error);
                    },
                    () => {

                        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                            // setImgUrl(downloadURL)
                            db.collection("upcoming_sessions").doc(editID).update({
                                'name': submit.name,
                                'description': submit.description,
                                'start_date_time': new Date(submit.start_date_time),
                                'end_date_time': new Date(submit.end_date_time),
                                'join_link': submit.join_link,
                                'registered_uid': [],
                                'status': "1",
                                'image': downloadURL,
                                'created_date': new Date(),

                            }).then(() => {
                                console.log('Updated Successfully');
                                const Toast = Swal.mixin({
                                    toast: true,
                                    background: '#69aba6',
                                    position: 'top-end',
                                    showConfirmButton: false,
                                    timer: 3000,
                                    timerProgressBar: true,
                                    didOpen: (toast) => {
                                        toast.addEventListener('mouseenter', Swal.stopTimer)
                                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                                    }
                                })

                                Toast.fire({
                                    icon: 'success',
                                    title: 'Updated Successfully'
                                })
                                closeModel();
                                setshow(true)
                                setSubmit({ id: '', image: '', name: '', description: '', start_date_time: '', end_date_time: '', join_link: '', registered_uid: [], status: '', created_date: new Date(), })
                            })
                        });
                    }
                );
            }

        } else {
            const file = e.target[0]?.files[0]
            // console.log(file)
            if (!file) return;
            const storageRef = ref(storage, `files/${file.name}`);
            const uploadTask = uploadBytesResumable(storageRef, file);
            setshow(false)

            uploadTask.on("state_changed",
                (snapshot) => {
                    const progress =
                        Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                    setProgresspercent(progress);
                },
                (error) => {
                    alert(error);
                },
                () => {

                    getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                        // setImgUrl(downloadURL)
                        db.collection("upcoming_sessions").add({
                            'name': submit.name,
                            'description': submit.description,
                            'start_date_time': new Date(submit.start_date_time),
                            'end_date_time': new Date(submit.end_date_time),
                            'join_link': submit.join_link,
                            'registered_uid': [],
                            'status': "1",
                            'image': downloadURL,
                            'created_date': new Date(),
                        }).then(() => {
                            console.log('Created Successfully');
                            const Toast = Swal.mixin({
                                toast: true,
                                background: '#69aba6',
                                position: 'top-end',
                                showConfirmButton: false,
                                timer: 3000,
                                timerProgressBar: true,
                                didOpen: (toast) => {
                                    toast.addEventListener('mouseenter', Swal.stopTimer)
                                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                                }
                            })

                            Toast.fire({
                                icon: 'success',
                                title: 'Created Successfully'
                            })
                            closeModel();
                            setshow(true)
                            setSubmit({ id: '', image: '', name: '', description: '', start_date_time: '', end_date_time: '', join_link: '', registered_uid: [], status: '', created_date: new Date(), })
                        })
                    });
                }
            );
        }
        // console.log(locationData);


    }

    const deleteData = (id) => {
        closeModel();
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                db.collection("upcoming_sessions").doc(id).delete().then(() => {
                    console.log('Deleted Successfully');
                    setSubmit({ id: '', image: '', name: '', description: '', start_date_time: '', end_date_time: '', join_link: '', registered_uid: [], status: '', created_date: new Date(), })
                    closeModel();
                    Swal.fire(
                        'Deleted!',
                        'Data has been deleted.',
                        'success'
                    )
                })

            }
        })

    }

    const locations = [];

    const handleLocationChange = (event) => {
        const location = event.target.value;
        const isChecked = event.target.checked;

        if (isChecked) {
            // Add the location to the array
            // You can replace 'locations' with your desired array variable
            locations.push(location);
        } else {
            // Remove the location from the array
            const index = locations.indexOf(location);
            if (index !== -1) {
                locations.splice(index, 1);
            }
        }
        console.log(locations)
    };

    useEffect(() => {
        db.collection("upcoming_sessions").orderBy("created_date", "desc").onSnapshot((snapshot) => {

            setData(
                snapshot.docs.map((doc) => ({
                    id: doc.id,
                    data: doc.data(),
                }))
            );
        });

    }, []);



    return (
        <>
            <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
                <div class="p-2" style={{ paddingBottom: "5px", display: "flex", justifyContent: "space-between" }}>
                    {/* <span class="btn bg-gradient-secondary btn-sm mb-0 mr-2" style={{ color: "white" }} >&nbsp; Download Excel</span> */}
                    <Link to="/admin_panel"><img src="/images/menubutton.png" class="menu-toggle" alt="Responsive image" style={{ height: "2rem" }} /></Link>
                    <a class="btn bg-gradient-primary btn-sm mb-0" style={{ color: "white" }} onClick={() => addNew()}>+&nbsp; Add New Session</a>

                </div>
                <div class="">
                    <div style={{ textAlign: "end", paddingBottom: "5px" }}>
                        {/* <Link to="/admin_panel" class="btn bg-gradient-primary btn-sm mb-0 ml-2" style={{ color: "white" }} >&nbsp; Go Back</Link> */}
                    </div>
                    <div class="card">
                        <div class="table-responsive fix-width scroll-inner">
                            <table class="table table-bordered table-hover">
                                <thead class="thead-dark" style={{ position: "sticky", top: "0" }}>
                                    <tr style={{ textAlign: "left" }}>
                                        <th class="col">Session Image</th>
                                        <th class="col">Session Name</th>
                                        <th class="col ps-2">Description</th>
                                        <th class="text-center col">Start Date</th>
                                        <th class="text-center col">End Date</th>
                                        <th class="text-center col">Action</th>
                                    </tr>
                                </thead>
                                <tbody style={{ textAlign: "left" }}>
                                    {data?.map(({ id, data }, index) => (
                                        <>
                                            <tr>
                                                <td>
                                                    <div class="d-flex px-2 py-1">
                                                        <div style={{ width: "7rem", marginRight: "13px" }}>
                                                            <img src={data.image} class="me-3" style={{ height: "4rem" }} />
                                                        </div>
                                                        {/* <div class="d-flex flex-column justify-content-center">
                                                            <h6 class="mb-0 text-xs">{data.name}</h6>
                                                            <p class="text-xs text-secondary mb-0 btn-tooltip" data-bs-toggle="tooltip" data-bs-placement="top" title={data.description} data-container="body" data-animation="true" style={{ whiteSpace: "nowrap", width: "20rem", overflow: "hidden", textOverflow: "ellipsis" }}><i class="material-icons" style={{ fontSize: "12px", color: "#e73573" }}>info</i>  {data.description}</p>
                                                        </div> */}
                                                    </div>
                                                </td>

                                                <td>
                                                    <div class="d-flex px-2 py-1">

                                                        <div class="d-flex flex-column justify-content-center">
                                                            <h6 class="mb-0 text-xs">{data.name}</h6>
                                                        </div>
                                                    </div>
                                                </td>

                                                <td>
                                                    <div class="d-flex px-2 py-1">

                                                        <div class="d-flex flex-column justify-content-center">
                                                            <p class="text-xs text-secondary mb-0 btn-tooltip" data-bs-toggle="tooltip" data-bs-placement="top" title={data.description} data-container="body" data-animation="true" style={{ whiteSpace: "nowrap", width: "20rem", overflow: "hidden", textOverflow: "ellipsis" }}><i class="material-icons" style={{ fontSize: "12px", color: "#e73573" }}>info</i>  {data.description}</p>
                                                        </div>
                                                    </div>
                                                </td>


                                                <td class="align-middle text-center text-sm">
                                                    <span class="text-secondary text-xs font-weight-normal">{moment(data.start_date_time.toDate().toISOString()).format("MM/DD/YY, h:mm a")}</span>
                                                </td>
                                                <td class="align-middle text-center text-sm">
                                                    <span class="text-secondary text-xs font-weight-normal">{moment(data.end_date_time.toDate().toISOString()).format("MM/DD/YY, h:mm a")}</span>
                                                </td>
                                                <td class="align-middle text-center" onClick={() => changeStatus(data, id)}>
                                                    <span class="text-info text-xs font-weight-normal" style={{ cursor: "pointer" }}>Edit</span>
                                                </td>

                                            </tr>
                                        </>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

            </main>

            <div class="col-md-12">
                <div class="myId modal fade" id="" tabindex="-1" role="dialog" aria-labelledby="modal-form" aria-hidden="true" data-backdrop="static">
                    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div class="modal-content">
                            <div class="modal-body">


                                <div class="">
                                    <form onSubmit={handleSubmit}>
                                        <div class="">
                                            <div class="" style={{ textAlign: "center", }}><span style={{ color: "black", fontSize: "25px", fontWeight: "700", textTransform: "uppercase" }}>Create Workshop</span></div>
                                            <div class="" style={{ textAlign: "center", color: "black", paddingTop: "10px" }}>
                                            </div>
                                            <div class="">
                                                <div class="row">
                                                    <div class="col-md-4">
                                                        {edit ? <input type="file" id="image" style={{
                                                            // width: "17rem",
                                                            height: "45px",
                                                            padding: "12px 20px",
                                                            boxSizing: "border-box",
                                                            border: "2px solid #ccc",
                                                            borderRadius: "4px",
                                                            backgroundColor: "#f8f8f8",
                                                            fontSize: "16px",
                                                            resize: "none"
                                                        }} class="form-control" onfocus="focused(this)" onfocusout="defocused(this)" placeholder="Image" /> : <input type="file" id="image" style={{
                                                            // width: "17rem",
                                                            height: "45px",
                                                            padding: "12px 20px",
                                                            boxSizing: "border-box",
                                                            border: "2px solid #ccc",
                                                            borderRadius: "4px",
                                                            backgroundColor: "#f8f8f8",
                                                            fontSize: "16px",
                                                            resize: "none"
                                                        }} class="form-control" onfocus="focused(this)" onfocusout="defocused(this)" placeholder="Image" required />}
                                                    </div>
                                                    <div class="col-md-4">
                                                        <input style={{
                                                            // width: "17rem",
                                                            height: "45px",
                                                            padding: "12px 20px",
                                                            boxSizing: "border-box",
                                                            border: "2px solid #ccc",
                                                            borderRadius: "4px",
                                                            backgroundColor: "#f8f8f8",
                                                            fontSize: "16px",
                                                            resize: "none"
                                                        }} class="w3l_text_box" id="name" placeholder="Session Name" name="Full Name" type="text" onChange={handleChange} value={submit.name} required />
                                                    </div>

                                                    <div class="col-md-4">
                                                        <input style={{
                                                            // width: "17rem",
                                                            height: "45px",
                                                            padding: "12px 20px",
                                                            boxSizing: "border-box",
                                                            border: "2px solid #ccc",
                                                            borderRadius: "4px",
                                                            backgroundColor: "#f8f8f8",
                                                            fontSize: "16px",
                                                            resize: "none"
                                                        }} class="w3l_text_box" id="join_link" placeholder="Zoom Link" name="zoomlink" type="text" onChange={handleChange} value={submit.join_link} required />
                                                    </div>
                                                </div>
                                            </div>










                                            <div class="">
                                                <div class="row">

                                                    <div class="col-md-4">
                                                        <input type="datetime-local" style={{
                                                            // width: "17rem",
                                                            height: "45px",
                                                            padding: "12px 20px",
                                                            boxSizing: "border-box",
                                                            border: "2px solid #ccc",
                                                            borderRadius: "4px",
                                                            backgroundColor: "#f8f8f8",
                                                            fontSize: "16px",
                                                            resize: "none"
                                                        }} id="start_date_time" onChange={handleChange} value={submit.start_date_time} class="form-control" required />
                                                    </div>

                                                    <div class="col-md-4">
                                                        <input type="datetime-local" style={{
                                                            // width: "17rem",
                                                            height: "45px",
                                                            padding: "12px 20px",
                                                            boxSizing: "border-box",
                                                            border: "2px solid #ccc",
                                                            borderRadius: "4px",
                                                            backgroundColor: "#f8f8f8",
                                                            fontSize: "16px",
                                                            resize: "none"
                                                        }} id="end_date_time" onChange={handleChange} value={submit.end_date_time} class="form-control" required />
                                                    </div>
                                                </div>
                                            </div>




                                            <textarea style={{
                                                width: "100%",
                                                height: "15px",
                                                padding: "12px 20px",
                                                boxSizing: "border-box",
                                                border: "2px solid #ccc",
                                                borderRadius: "4px",
                                                backgroundColor: "#f8f8f8",
                                                fontSize: "16px",
                                                resize: "none"
                                            }} class="w3l_text_box" id="description" placeholder="Write a description..." name="Description" onChange={handleChange} value={submit.description} required />






                                            <div class="modal-footer">
                                                {submit.id == "" ? <></> : <a class="btn bg-gradient-danger btn-sm mb-0" style={{ color: "white" }} onClick={() => deleteData(submit.id)}>Delete</a>}
                                                <a class="btn bg-gradient-info btn-sm mb-0" style={{ color: "white" }} onClick={closeModel}>Close</a>
                                                <button type='submit' class="btn bg-gradient-danger btn-sm mb-0" style={{ backgroundColor: "green", color: "white" }}>{edit ? "Update" : "Submit"}</button>
                                            </div>


                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >



        </>
    )
}

export default GuidanceSession;
