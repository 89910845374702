import React from 'react'

function PageNotFound() {
    return (
        <div>
            <img src="images/error.png" alt="Popup Image" class="" style={{ display: "block", marginLeft: "auto", marginRight: "auto", width: "50%" }} />
        </div>
    )
}


export default PageNotFound
