import React from "react";

function Testingg() {
    return (
        <>

            <div class="container-fluid">
                <div class="row p-5" style={{ border: "4px solid grey" }}>
                    <div class="col-lg-4" style={{ border: "4px solid red", height: "5rem" }}></div>
                    <div class="col-lg-8" style={{ border: "4px solid green", height: "15rem" }}></div>
                </div>
            </div>

        </>
    );
}

export default Testingg;

