import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate, Link, useParams } from "react-router-dom"
import Select from 'react-select';
import FooterMain from "./FooterMain";


function PrivacyPolicy() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>

            <div class="row p-5">
                <div class="" style={{ display: "flex", justifyContent: "left", fontSize: "24px" }}>
                    <p style={{ color: "black", fontWeight: "400", textAlign: "justify" }}><strong>Privacy Policy</strong></p>

                </div>
                <div class="col-4 ppoo">
                    <img style={{ minWidth: "8rem" }} class="img-responsive" src="/images/privacyelement.png" />
                </div>
                <div class="col-12 p-4 ppoo"><p style={{ color: "black", fontWeight: "400", textAlign: "justify" }}> At Youvati, we take your privacy seriously. This Privacy Policy outlines how we collect, use, and protect your personal information. By using our website, you consent to the terms of this Privacy Policy.
                </p>
                    <p style={{ color: "black", fontWeight: "400", textAlign: "justify" }}>Information Collection and Use We may collect personal information from you when you use our website, such as your name, email address, phone number, and other information necessary to provide our services. We may use this information to communicate with you, provide customer support, and improve our services.
                    </p>
                </div>


                <div class="col-8 p-4 ppo"><p style={{ color: "black", fontWeight: "400", textAlign: "justify" }}> At Youvati, we take your privacy seriously. This Privacy Policy outlines how we collect, use, and protect your personal information. By using our website, you consent to the terms of this Privacy Policy.
                </p>
                    <p style={{ color: "black", fontWeight: "400", textAlign: "justify" }}>Information Collection and Use We may collect personal information from you when you use our website, such as your name, email address, phone number, and other information necessary to provide our services. We may use this information to communicate with you, provide customer support, and improve our services.
                    </p>
                </div>
                <div class="col-4 ppo">
                    <img style={{ width: "17rem" }} class="img-responsive" src="/images/ple101.svg" />
                </div>
                <div class="" style={{ display: "flex", justifyContent: "center", color: "black", fontWeight: "400" }}>
                    <p style={{ color: "black", fontWeight: "400", textAlign: "justify" }}>Information Sharing We will not sell, rent, or share your personal information with third parties without your consent, except as required by law or to protect our rights. We may share your personal information with our affiliates and service providers to provide our services to you.</p>

                </div>
                <div class="" style={{ display: "flex", justifyContent: "center", color: "black", fontWeight: "400" }}>
                    <p style={{ color: "black", fontWeight: "400", textAlign: "justify" }}><strong>Security:</strong> We take reasonable steps to protect your personal information from unauthorized access, use, or disclosure. However, no system can guarantee absolute security. Therefore, we cannot guarantee the security of your personal information.
                    </p>
                </div>
                <div class="" style={{ display: "flex", justifyContent: "center", color: "black", fontWeight: "400" }}>
                    <p style={{ color: "black", fontWeight: "400", textAlign: "justify" }}>Cookies and Other Tracking Technologies We may use cookies and other tracking technologies to improve our website and services. These technologies may collect information such as your IP address, browser type, and browsing behaviour. You can disable cookies in your browser settings, but this may limit the functionality of our website.
                        Links to Third-Party Websites Our website may contain links to third-party websites. We are not responsible for the privacy practices of these websites. We encourage you to review the privacy policies of these websites before providing any personal information.
                    </p>
                </div>
                <div class="" style={{ display: "flex", justifyContent: "center", color: "black", fontWeight: "400" }}>
                    <p style={{ color: "black", fontWeight: "400", textAlign: "justify" }}>Changes to this Privacy Policy We reserve the right to update this Privacy Policy from time to time. We will post any changes to this Privacy Policy on our website. Your continued use of our website after any changes to this Privacy Policy will constitute your acceptance of the changes.</p>

                </div>


                <div class="" style={{ display: "flex", justifyContent: "left", color: "black", fontWeight: "400" }}>
                    <p style={{ color: "black", fontWeight: "400", textAlign: "justify" }}><strong>Contact Us:</strong> If you have any questions or concerns about this Privacy Policy, please contact us at info@youvati.com
                    </p>
                </div>
                <div class="" style={{ display: "flex", justifyContent: "left", color: "black", fontWeight: "400" }}>
                    <p style={{ color: "black", fontWeight: "400", textAlign: "justify" }}><strong>Thank you for using Youvati!</strong></p>

                </div>
            </div>
            {/* <section style={{ boxShadow: "0 6px 6px -6px #777" }}>
                <div class="navi" >
                    <input type="checkbox" id="nav-check" />
                    <div class="nav-header">
                        <div class="nav-title">
                            <Link to='/' > <img src="/images/youvati_logo.png" alt="" style={{ height: "27px", marginLeft: "2rem" }} /></Link>
                        </div>
                    </div>
                </div>
            </section>
            <div class="container">
                <h2>Privacy Policy</h2>
                <p style={{ color: "black" }}> At Youvati, we take your privacy seriously. This Privacy Policy outlines how we collect, use, and protect your personal information. By using our website, you consent to the terms of this Privacy Policy.
                </p>
                <p style={{ color: "black" }}>Information Collection and Use We may collect personal information from you when you use our website, such as your name, email address, phone number, and other information necessary to provide our services. We may use this information to communicate with you, provide customer support, and improve our services.
                </p>
                <p style={{ color: "black" }}>Information Sharing We will not sell, rent, or share your personal information with third parties without your consent, except as required by law or to protect our rights. We may share your personal information with our affiliates and service providers to provide our services to you.
                </p>
                <p style={{ color: "black" }}><strong>Security:</strong> We take reasonable steps to protect your personal information from unauthorized access, use, or disclosure. However, no system can guarantee absolute security. Therefore, we cannot guarantee the security of your personal information.
                </p>
                <p style={{ color: "black" }}>Cookies and Other Tracking Technologies We may use cookies and other tracking technologies to improve our website and services. These technologies may collect information such as your IP address, browser type, and browsing behaviour. You can disable cookies in your browser settings, but this may limit the functionality of our website.
                    Links to Third-Party Websites Our website may contain links to third-party websites. We are not responsible for the privacy practices of these websites. We encourage you to review the privacy policies of these websites before providing any personal information.
                </p>
                <p style={{ color: "black" }}>Changes to this Privacy Policy We reserve the right to update this Privacy Policy from time to time. We will post any changes to this Privacy Policy on our website. Your continued use of our website after any changes to this Privacy Policy will constitute your acceptance of the changes.</p>

                <p style={{ color: "black" }}><strong>Contact Us:</strong> If you have any questions or concerns about this Privacy Policy, please contact us at info@youvati.com
                </p>
                <p style={{ color: "black" }}><strong>Thank you for using Youvati!</strong></p>
              
            </div> */}
            <FooterMain />
        </>
    );
}

export default PrivacyPolicy;