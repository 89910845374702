import React, { useState, useEffect } from 'react';
import * as XLSX from 'xlsx';
import DataTable from 'react-data-table-component';
import { useNavigate, Link, useParams } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2'
import db from "./Firebase";
import { storage } from './Firebase';
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";


function SendWhatsapp() {
    let params = useParams();
    const navigate = useNavigate();

    const initialFormStateFeedback = {
        email: '',
        phone_no: '',
        image_url: '',
        template: '',
    }
    const [submit, setSubmit] = useState(initialFormStateFeedback)

    const [show, setshow] = useState(true);

    const [progresspercent, setProgresspercent] = useState(0);


    const handleChange = (e) => {
        const { id, value } = e.target
        setSubmit({ ...submit, [id]: value })
    }


    const reset = () => {
        setSubmit({
            email: '',
            phone_no: '',
            image_url: '',
            template: '',
        })
    }


    const handleSubmit = (e) => {
        console.log(submit)
        // alert("okkk")
        e.preventDefault()
        if (submit.template == "visiting_card_done") {
            sendWhatsapp();
        } else {
            alert("none")
        }


    }

    const sendWhatsapp = () => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer EABMKlZCrATXIBAPOZBZBPqsdfot34fq0FTipZBQf3RiXZAapyCOqMay09HfhSxoB8bd5ZA7RUnLWhd0JkXtACJQ682THiDEMhQhyPOkeui8Cb8P72WPDhihUZBCkHtPLSgsmuZA6iRY6VunDDIjPA5mATQQITVXOECHqkZB5E2pzWSC2AaUp0wZCWR");
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify(
            {
                "messaging_product": "whatsapp",
                "recipient_type": "individual",
                "to": `91${submit.phone_no}`,
                "type": "template",
                "template": {
                    "name": "visiting_card_done",
                    "language": {
                        "code": "en_US",
                        "policy": "deterministic"
                    },
                    "components": [
                        {
                            "type": "header",
                            "parameters": [
                                {
                                    "type": "image",
                                    "image": { "link": `${submit.image_url}` }
                                }
                            ]
                        },
                        {
                            "type": "body",
                            "parameters": [
                                { "type": "text", "text": `${submit.email}` }
                            ]
                        }
                    ]
                }
            }
        );

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch("https://graph.facebook.com/v14.0/104489945783566/messages", requestOptions)
            .then(response => response.text())
            .then(result => {
                console.log(result)
                // reset();
            })
            .catch(error => {
                console.log('error', error)
            });

    };



    return (
        <>
            <div id="" class="m-3" style={{ border: "10px solid #344767" }}>
                <div class="w3layouts_payment_online_form">
                    <form class="" id="" onSubmit={handleSubmit}>
                        <h4>Send Whatsapp</h4>
                        <select class="form-control" id="template" onChange={handleChange} required>
                            <option value="">-- Select Whatsapp Template --</option>
                            <option value="visiting_card_done">Visiting Card Done</option>
                            <option value="loan">Loan Done</option>
                        </select>

                        <ul>
                            <li><input class="w3l_text_box" id="email" placeholder="*Email" onChange={handleChange} value={submit.email} name="Email" type="text" required="" /></li>
                            <li><input class="w3l_text_box" id="phone_no" placeholder="*Phone Number" onChange={handleChange} value={submit.phone_no} name="Phone Number" type="number" required="" /></li>
                        </ul>

                        <ul>
                            <li>
                                <input class="w3l_text_box" id="image_url" placeholder="*Image URL" onChange={handleChange} value={submit.image_url} name="Image URL" type="text" required />
                            </li>
                            <li>
                            </li>
                        </ul>


                        <div class="row" style={{ display: "flex", justifyContent: "left" }}>
                            <div class="multiline-text" style={{
                                whiteWpace: "pre-line",
                                maxWidth: "35rem",
                                backgroundColor: "#f0f2f5",
                                padding: "23px"
                            }}>
                                <p style={{ color: "black", textAlign: "justify" }}>✨🌟Congratulations🌟✨ आपका *Free Custom Visiting Card* आपके रजिस्टर्ड e-mail address ({submit.email})  पर भेजा जा चूका है। We hope you like it! अगर आपको Youvati की यह सर्विस पसंद आई तो हमें *Play Store पर ज़रूर Review दीजिये।* Link: https://shorturl.at/fzAR6 अन्य किसी जानकारी के लिए संपर्क करें - +917428790444</p>
                            </div>
                        </div>

                        <ul class="w3_payment_sendbtns">
                            <li><input type="reset" value="Cancel" onClick={() => navigate(`/admin_panel`)} /></li>
                            <li class="mr-4"><input type="reset" data-dismiss="modal" aria-label="Close" onClick={reset} value="Reset" /></li>
                            <li>{show ? <input type="submit" value="Submit" /> : "Please Wait..."}</li>
                        </ul>

                        <div class="clearfix"> </div>
                    </form>
                </div>
            </div>
        </>


    );
}
export default SendWhatsapp;