import React, { useState, useEffect } from 'react';
import { useNavigate, Link, useParams } from 'react-router-dom';
import db from "./Firebase";
import { arrayUnion } from "firebase/firestore";
import { storage } from './Firebase';
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import ViewAllRequests from './ViewAllRequests';

function ConsumablesRequest() {
    const moment = extendMoment(Moment);
    const [data, setData] = useState([]);

    useEffect(() => {
        db.collection("orderd_consumables_products").onSnapshot((snapshot) => {

            setData(
                snapshot.docs.map((doc) => ({
                    id: doc.id,
                    data: doc.data(),
                }))
            );
        });

    }, []);

    // const products = (data) => {
    //     console.log(data)
    // }

    const [productData, setProductsData] = useState([]);

    const products = (data) => {
        setProductsData(data)
        openModalShow();
        console.log(data)
    }

    const openModalShow = () => {
        // alert("ooo")
        window.openModelshow();
    }

    const closeModel = () => {
        window.openModelNew();
    }

    return (
        <>
            <ViewAllRequests />
            <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">

                <div class="row">


                    <div class="mt-4 p-4">
                        <div style={{ textAlign: "end", paddingBottom: "5px" }}>
                            <Link to="/admin_panel" class="btn bg-gradient-primary btn-sm mb-0 mr-2" style={{ color: "white" }} >&nbsp; Go Back</Link>
                        </div>
                        <div class="card">
                            <div class="card-header pb-0 px-3">
                                <h6 class="mb-2">Consumables Request</h6>
                            </div>
                            <div class="container-fluid py-4">

                                <div class="card">
                                    <div class="table-responsive fix-width scroll-inner">
                                        <table class="table align-items-center mb-0">
                                            <thead>
                                                <tr>
                                                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Parlour Name</th>
                                                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Mode</th>
                                                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Amount</th>
                                                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Phone No</th>
                                                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Pincode</th>
                                                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Status</th>
                                                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Address</th>
                                                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Products</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {data?.map(({ id, data }, index) => (
                                                    <>
                                                        <tr>
                                                            <td>
                                                                <p class="text-xs font-weight-bold mb-0">{data.name}</p>
                                                                <p class="text-xs text-secondary mb-0">{moment(data.created_date.toDate().toISOString()).format("MM/DD/YY, h:mm a")}</p>
                                                            </td>
                                                            <td>
                                                                <div class="d-flex px-2 py-1">

                                                                    <div class="d-flex flex-column justify-content-center">
                                                                        <h6 class="mb-0 text-xs">{data.mode}</h6>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div class="d-flex px-2 py-1">

                                                                    <div class="d-flex flex-column justify-content-center">
                                                                        <h6 class="mb-0 text-xs">{data.amount}</h6>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div class="d-flex px-2 py-1">

                                                                    <div class="d-flex flex-column justify-content-center">
                                                                        <h6 class="mb-0 text-xs">{data.phone}</h6>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div class="d-flex px-2 py-1">

                                                                    <div class="d-flex flex-column justify-content-center">
                                                                        <h6 class="mb-0 text-xs">{data.pincode}</h6>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div class="d-flex px-2 py-1">

                                                                    <div class="d-flex flex-column justify-content-center">
                                                                        <h6 class="mb-0 text-xs">{data.status}</h6>
                                                                    </div>
                                                                </div>
                                                            </td>

                                                            <td>
                                                                <div class="d-flex px-2 py-1">

                                                                    <div class="d-flex flex-column justify-content-center">
                                                                        <h6 class="mb-0 text-xs">{data.address}</h6>
                                                                    </div>
                                                                </div>
                                                            </td>

                                                            {/* <td>
                                                                <div class="d-flex px-2 py-1">

                                                                    <div class="d-flex flex-column justify-content-center">
                                                                        <h6 class="mb-0 text-xs" onClick={() => products(data.products)}>View Products</h6>
                                                                    </div>
                                                                </div>
                                                            </td> */}

                                                            <td class="align-middle text-center" onClick={() => products(data.products)}>
                                                                <span class="text-info text-xs font-weight-normal" style={{ cursor: "pointer" }}>View Products</span>
                                                            </td>


                                                        </tr>

                                                    </>
                                                ))}


                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>


            </main>

            <div class="col-md-4">
                <div class="myId modal fade" id="" tabindex="-1" role="dialog" aria-labelledby="modal-form" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered" role="document">
                        <div class="modal-content">
                            <div class="modal-body p-0">
                                <div class="card card-plain">

                                    <div class="card-body">
                                        <div class="modal-body">

                                            <div class="card">
                                                <div class="table-responsive fix-width scroll-inner">
                                                    <table class="table align-items-center mb-0">
                                                        <thead>
                                                            <tr>
                                                                <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Image</th>
                                                                <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Name</th>
                                                                <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Item count</th>
                                                                <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Brand</th>
                                                                <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Quantity</th>
                                                                <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Type</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {productData?.map((prodata) => (
                                                                <>
                                                                    <tr>
                                                                        <td>
                                                                            <img src={prodata.image_url} class="avatar avatar-sm me-3" style={{ height: "5rem" }} />
                                                                        </td>
                                                                        <td>
                                                                            <div class="d-flex px-2 py-1">

                                                                                <div class="d-flex flex-column justify-content-center">
                                                                                    <h6 class="mb-0 text-xs">{prodata.product_name}</h6>
                                                                                </div>
                                                                            </div>
                                                                        </td>                     <td>
                                                                            <div class="d-flex px-2 py-1">

                                                                                <div class="d-flex flex-column justify-content-center">
                                                                                    <h6 class="mb-0 text-xs">{prodata.itemcount}</h6>
                                                                                </div>
                                                                            </div>
                                                                        </td>                     <td>
                                                                            <div class="d-flex px-2 py-1">

                                                                                <div class="d-flex flex-column justify-content-center">
                                                                                    <h6 class="mb-0 text-xs">{prodata.brand_name}</h6>
                                                                                </div>
                                                                            </div>
                                                                        </td>                     <td>
                                                                            <div class="d-flex px-2 py-1">

                                                                                <div class="d-flex flex-column justify-content-center">
                                                                                    <h6 class="mb-0 text-xs">{prodata.quantity}</h6>
                                                                                </div>
                                                                            </div>
                                                                        </td>                     <td>
                                                                            <div class="d-flex px-2 py-1">

                                                                                <div class="d-flex flex-column justify-content-center">
                                                                                    <h6 class="mb-0 text-xs">{prodata.type}</h6>
                                                                                </div>
                                                                            </div>
                                                                        </td>

                                                                    </tr>

                                                                </>
                                                            ))}


                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}

export default ConsumablesRequest;