import React, { useState, useEffect } from 'react';
import { useNavigate, Link, useParams } from 'react-router-dom';
import db from "./Firebase";
import firebaseApp from "./Firebase";
import { arrayUnion } from "firebase/firestore";
import { storage } from './Firebase';
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import ViewAllRequests from './ViewAllRequests';
import ViewAppData from './ViewAppData';
import { CSVLink } from "react-csv";

function AppFeedback() {

    const moment = extendMoment(Moment);
    const [data, setData] = useState([]);

    useEffect(() => {
        db.collection("feedback").orderBy("created_date", "desc").onSnapshot((snapshot) => {

            setData(
                snapshot.docs.map((doc) => ({
                    id: doc.id,
                    data: doc.data(),
                }))
            );

        });

    }, []);

    return (
        <>
            {/* <ViewAllRequests /> */}

            <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
                <div class="p-2" style={{ textAlign: "start", paddingBottom: "5px" }}>
                    {/* <span class="btn bg-gradient-secondary btn-sm mb-0 mr-2" style={{ color: "white" }} >&nbsp; Download Excel</span> */}
                    <Link to="/admin_panel"><img src="/images/menubutton.png" class="menu-toggle" alt="Responsive image" style={{ height: "2rem" }} /></Link>


                </div>
                <div class="">


                    <div class="p-2">

                        <div class="card">
                            <div class="card-header pb-0 px-3">
                                <h6 class="mb-2">App Feedback</h6>
                            </div>
                            <div class="">

                                <div class="table-responsive fix-width scroll-inner">
                                    <table class="table table-bordered table-hover">
                                        <thead class="thead-dark" style={{ position: "sticky", top: "0" }}>
                                            <tr>
                                                <th class=" ps-2">Name</th>
                                                <th class="">Created Date</th>
                                                <th class=" ps-2">Message</th>
                                                {/* <th class=" ps-2">Email</th> */}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data?.map(({ id, data }, index) => (
                                                <>
                                                    <tr>

                                                        <td>
                                                            <p class="text-xs mb-0">{data.name}</p>
                                                            {/* <p class="text-xs text-secondary mb-0">{moment(data.created_date.toDate().toISOString()).format("MM/DD/YY, h:mm a")}</p> */}
                                                        </td>
                                                        <td>
                                                            <p class="text-xs mb-0">{moment(data.created_date.toDate().toISOString()).format("MM/DD/YY, h:mm a")}</p>
                                                            {/* <p class="text-xs text-secondary mb-0">{moment(data.created_date.toDate().toISOString()).format("MM/DD/YY, h:mm a")}</p> */}
                                                        </td>
                                                        <td style={{ whiteSpace: 'pre-wrap' }}>
                                                            <p class="text-xs font-weight-bold mb-0" >{data.message}</p>

                                                        </td>
                                                        {/* <td>
                                                            <p class="text-xs mb-0">{data.email}</p>
                                                        </td> */}


                                                    </tr>

                                                </>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>


                                {/* <div style={{ textAlign: "end", paddingBottom: "5px" }}>
                        <a class="btn bg-gradient-primary btn-sm mb-0" style={{ color: "white" }} onClick={() => changeStatus()}>+&nbsp; Add Rent Details</a>
                    </div> */}

                            </div>
                        </div>
                    </div>

                </div>


            </main>


        </>
    )
}

export default AppFeedback;