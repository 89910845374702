import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, Link, useParams } from 'react-router-dom';
import db from "./Firebase";
import Swal from 'sweetalert2'
import { arrayUnion } from "firebase/firestore";
import { storage } from './Firebase';
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import ViewAllRequests from './ViewAllRequests';
import { CSVLink } from "react-csv";

function RequestCallBack() {
    const navigate = useNavigate();
    const moment = extendMoment(Moment);
    const [data, setData] = useState([]);
    const [csvdata, setCsvdata] = useState([]);

    const initialFormStateFeedback = { request_status: '', remark: '', call_status: '', updated_date: new Date() }
    const [submit, setSubmit] = useState(initialFormStateFeedback)

    // Additional fields for Status and Remark
    const [status, setStatus] = useState("Pending");
    const [remark, setRemark] = useState("");
    const [uid, setUid] = useState('');
    const [editIndex, setEditIndex] = useState();
    const [edit, setEdit] = useState(false);





    useEffect(() => {
        const storedUsername = localStorage.getItem("email");
        const storedPassword = localStorage.getItem("password");
        const storedRemember = localStorage.getItem("remember");

        if (storedRemember === "false" || !storedUsername || !storedPassword) {
            navigate('/signin');
        }
    }, []);

    const headers = [
        { label: "Parlour Name", key: "parlour_name" },
        { label: "Phone No", key: "phone_no" },
        { label: "Email", key: "email" },
        { label: "Address", key: "address" },
        { label: "Additional Info", key: "additional_info" },
        { label: "Created Date", key: "created_date", dataFormat: "MM/DD/YY, h:mm a" },
        { label: "Status", key: "status" }, // Include Status in the CSV
        { label: "Remark", key: "remark" }, // Include Remark in the CSV
    ];

    const [doneCount, setDoneCount] = useState(0);

    useEffect(() => {
        const fetchData = () => {
            try {
                db.collection("request_call_back")
                    .orderBy("created_date", "desc")
                    .onSnapshot((snapshot) => {
                        const dataArray = [];
                        let count = 0;

                        setCsvdata(
                            snapshot.docs.map((doc) => {
                                const data = doc.data();
                                return {
                                    ...data,
                                    created_date: moment(data.created_date.toDate().toISOString()).format("MM/DD/YY, h:mm a"),
                                };
                            })
                        );

                        snapshot.forEach((doc) => {
                            const documentData = {
                                id: doc.id,
                                data: doc.data(),
                            };

                            dataArray.push(documentData);

                            // Check the status field and increment count
                            if (documentData.data.request_status === '1') {
                                count += 1;
                            }
                        });

                        setData(dataArray);
                        setDoneCount(count);
                    });
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    // useEffect(() => {
    //     db.collection("request_call_back").orderBy("created_date", "desc").onSnapshot((snapshot) => {

    //         setData(
    //             snapshot.docs.map((doc) => ({
    //                 id: doc.id,
    //                 data: doc.data(),
    //             }))
    //         );

    //         setCsvdata(
    //             snapshot.docs.map((doc) => {
    //                 const data = doc.data();
    //                 return {
    //                     ...data,
    //                     created_date: moment(data.created_date.toDate().toISOString()).format("MM/DD/YY, h:mm a"),
    //                 };
    //             })
    //         );
    //     });

    // }, []);

    const callStatus = (uid, index) => {
        setUid(uid);
        setEditIndex(index);
    }

    const handelChangeUID = (uid, index) => {
        setUid(uid);
        setEditIndex(index);
    }

    const handleRemarkChange = (event) => {
        setRemark(event.target.value);
    };

    const handleStatusChange = async (e) => {
        // setStatus(event.target.value);
        // const { id, value } = e.target
        // setSubmit({ ...submit, [id]: value })

        if (e.target.value == "2") {
            const { value: text } = await Swal.fire({
                input: 'textarea',
                inputLabel: 'Update Remark',
                inputPlaceholder: 'Type your message here...',
                inputAttributes: {
                    'aria-label': 'Type your message here'
                },
                showCancelButton: true
            })
            db.collection("request_call_back").doc(uid).update({ 'request_status': "2", 'remark': text, 'updated_date': new Date() }).then(() => {
                console.log("data Updated");
            })

        } else {
            const { value: text } = await Swal.fire({
                input: 'textarea',
                inputLabel: 'Final Remark',
                inputPlaceholder: 'Type your message here...',
                inputAttributes: {
                    'aria-label': 'Type your message here'
                },
                showCancelButton: true
            })
            db.collection("request_call_back").doc(uid).update({ 'request_status': "1", 'remark': text, 'updated_date': new Date() }).then(() => {
                console.log("data Updated");
            })
        }

    };

    const handleCallStatusChange = (e) => {
        db.collection("request_call_back").doc(uid).update({ 'call_status': e.target.value, 'updated_date': new Date() }).then(() => {
            console.log("data Updated");
        })
        // console.log(e.target.value)
    }


    return (
        <>
            <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
                <div class="p-2" style={{ paddingBottom: "5px", display: "flex", justifyContent: "space-between" }}>
                    <Link to="/admin_panel"><img src="/images/menubutton.png" class="menu-toggle" alt="Responsive image" style={{ height: "2rem" }} /></Link>
                    <CSVLink data={csvdata} headers={headers}>
                        <span class="btn bg-gradient-secondary btn-sm mb-0 mr-2" style={{ color: "white" }} >&nbsp; Download Excel</span>
                    </CSVLink>
                </div>
                <div class="">


                    <div class="p-2">
                        <div class="card">
                            <div class="card-header pb-0 px-3">
                                <h6 class="mb-2">Call Back Request</h6>
                            </div>
                            <div class="d-flex p-2" style={{ justifyContent: "center", fontWeight: "bold" }}>
                                Total : {data.length} || Pending : {data.length - doneCount} || Done : {doneCount}
                            </div>

                            <div class="">

                                <div class="card">

                                    <div class="table-responsive fix-width scroll-inner">
                                        <table class="table table-bordered table-hover">
                                            <thead class="thead-dark" style={{ position: "sticky", top: "0" }}>
                                                <tr style={{ textAlign: "left" }}>
                                                    <th class="col">Name</th>
                                                    <th class="col">Phone No</th>
                                                    <th class="col">Email</th>
                                                    <th class="col">Created Date</th>
                                                    <th class="col">Description</th>
                                                    <th class="col">Address</th>
                                                    <th class="col">Call Status</th>
                                                    <th class="col">Status</th>
                                                    <th class="col">Final Remark</th>
                                                </tr>
                                            </thead>
                                            <tbody style={{ textAlign: "left" }}>
                                                {data?.map(({ id, data }, index) => (
                                                    <>
                                                        <tr>

                                                            <td>
                                                                <p class="text-xs font-weight-bold mb-0">{data.parlour_name}</p>
                                                            </td>

                                                            <td>
                                                                <p class="text-xs font-weight-bold mb-0">{data.phone_no}</p>

                                                            </td>
                                                            <td>
                                                                <p class="text-xs font-weight-bold mb-0">{data.email}</p>

                                                            </td>
                                                            <td>
                                                                <p class="text-xs font-weight-bold mb-0">{moment(data.created_date.toDate().toISOString()).format("MM/DD/YY, h:mm a")}</p>

                                                            </td>
                                                            <td>
                                                                <p class="text-xs font-weight-bold mb-0">{data.description}</p>
                                                            </td>
                                                            <td style={{ whiteSpace: "pre-line", width: "21rem" }}>{data.message}
                                                            </td>
                                                            {data.call_status ?
                                                                <>
                                                                    <td>
                                                                        <select class="form-control" id="call_status" name="call_status" onClick={() => callStatus(id, index)} onChange={handleCallStatusChange} value={data.call_status} style={{ width: "10rem", background: data.call_status === "Connected" ? "green" : data.callStatus === "Did Not Answer" ? "black" : "brown", color: "white" }}>
                                                                            <option value="" disabled>Call Status</option>
                                                                            <option selected={data.call_status == "Connected" ? true : false} value="Connected">Connected</option>
                                                                            <option selected={data.call_status == "Did Not Answer" ? true : false} value="Did Not Answer">Did Not Answer</option>
                                                                            <option selected={data.call_status == "Not Reachable" ? true : false} value="Not Reachable">Not Reachable</option>
                                                                        </select>
                                                                    </td>
                                                                </>
                                                                :
                                                                <>
                                                                    <td>
                                                                        <select class="form-control" id="call_status" name="call_status" onClick={() => callStatus(id, index)} onChange={handleCallStatusChange} value={submit.call_status} style={{ width: "10rem", }}>
                                                                            <option value="" disabled>Call Status</option>
                                                                            <option value="Connected">Connected</option>
                                                                            <option value="Did Not Answer">Did Not Answer</option>
                                                                            <option value="Not Reachable">Not Reachable</option>
                                                                        </select>
                                                                    </td>
                                                                </>
                                                            }

                                                            {data.request_status === "1" ?
                                                                <>
                                                                    <td>
                                                                        <select class="form-control" id="request_status" name="request_status" onClick={() => callStatus(id, index)} onChange={handleStatusChange} value={submit.request_status} style={{ width: "8rem", background: "green", color: "white" }}>
                                                                            <option value="" disabled>Done</option>
                                                                        </select>
                                                                    </td>
                                                                </>
                                                                :
                                                                <>
                                                                    <td>
                                                                        <select class="form-control" id="request_status" name="request_status" onClick={() => callStatus(id, index)} onChange={handleStatusChange} value={submit.request_status} style={{ width: "8rem" }}>
                                                                            <option value="" disabled>Pending</option>
                                                                            <option selected={data.request_status == "2" ? true : false} value="2">Update Status</option>
                                                                            <option selected={data.request_status == "1" ? true : false} value="1">Done</option>
                                                                        </select>
                                                                    </td>
                                                                </>
                                                            }
                                                            <td>
                                                                <textarea class="form-control" value={data.remark} onChange={handleRemarkChange} style={{ width: "10rem", background: "#ddd" }} disabled />
                                                            </td>


                                                        </tr>
                                                    </>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}

export default RequestCallBack;



// import React, { useState, useEffect } from 'react';
// import { useNavigate, Link, useParams } from 'react-router-dom';
// import db from "./Firebase";
// import { arrayUnion } from "firebase/firestore";
// import { storage } from './Firebase';
// import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
// import Moment from 'moment';
// import { extendMoment } from 'moment-range';
// import ViewAllRequests from './ViewAllRequests';
// import { CSVLink } from "react-csv";

// function RequestCallBack() {
//     const navigate = useNavigate();
//     const moment = extendMoment(Moment);
//     const [data, setData] = useState([]);
//     const [csvdata, setCsvdata] = useState([]);


//     useEffect(() => {
//         const storedUsername = localStorage.getItem("email");
//         const storedPassword = localStorage.getItem("password");
//         const storedRemember = localStorage.getItem("remember");

//         if (storedRemember === "false" || !storedUsername || !storedPassword) {
//             navigate('/signin');
//         }
//     }, []);

//     const headers = [
//         { label: "Parlour Name", key: "parlour_name" },
//         { label: "Phone No", key: "phone_no" },
//         { label: "Email", key: "email" },
//         { label: "Address", key: "address" },
//         { label: "Additional Info", key: "additional_info" },
//         { label: "Created Date", key: "created_date", dataFormat: "MM/DD/YY, h:mm a" },
//     ];

//     useEffect(() => {
//         db.collection("request_call_back").orderBy("created_date", "desc").onSnapshot((snapshot) => {

//             setData(
//                 snapshot.docs.map((doc) => ({
//                     id: doc.id,
//                     data: doc.data(),
//                 }))
//             );

//             setCsvdata(
//                 snapshot.docs.map((doc) => {
//                     const data = doc.data();
//                     return {
//                         ...data,
//                         created_date: moment(data.created_date.toDate().toISOString()).format("MM/DD/YY, h:mm a"),
//                     };
//                 })
//             );
//         });

//     }, []);

//     return (
//         <>
//             <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
//                 <div class="p-2" style={{ textAlign: "start", paddingBottom: "5px" }}>
//                     <Link to="/admin_panel"><img src="/images/menubutton.png" class="menu-toggle" alt="Responsive image" style={{ height: "2rem" }} /></Link>

//                 </div>
//                 <div class="row">


//                     <div class="mt-4 p-4">
//                         <div style={{ textAlign: "end", paddingBottom: "5px" }}>
//                             <CSVLink data={csvdata} headers={headers}>
//                                 <span class="btn bg-gradient-secondary btn-sm mb-0 mr-2" style={{ color: "white" }} >&nbsp; Download Excel</span>
//                             </CSVLink>
//                         </div>
//                         <div class="card">
//                             <div class="card-header pb-0 px-3">
//                                 <h6 class="mb-2">Call Back Request</h6>
//                             </div>
//                             <div class="">

//                                 <div class="card">
//                                     <div class="table-responsive fix-width scroll-inner">
//                                         <table class="table align-items-center mb-0">
//                                             <thead>
//                                                 <tr style={{ textAlign: "left" }}>
//                                                     <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Name</th>
//                                                     <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Phone No</th>
//                                                     <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Email</th>
//                                                     <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Created Date</th>
//                                                     <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Address</th>
//                                                     <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Message</th>
//                                                 </tr>
//                                             </thead>
//                                             <tbody style={{ textAlign: "left" }}>
//                                                 {data?.map(({ id, data }, index) => (
//                                                     <>
//                                                         <tr>

//                                                             <td>
//                                                                 <p class="text-xs font-weight-bold mb-0">{data.parlour_name}</p>
//                                                             </td>

//                                                             <td>
//                                                                 <p class="text-xs font-weight-bold mb-0">{data.phone_no}</p>

//                                                             </td>
//                                                             <td>
//                                                                 <p class="text-xs font-weight-bold mb-0">{data.email}</p>

//                                                             </td>
//                                                             <td>
//                                                                 <p class="text-xs font-weight-bold mb-0">{moment(data.created_date.toDate().toISOString()).format("MM/DD/YY, h:mm a")}</p>

//                                                             </td>
//                                                             <td>
//                                                                 <p class="text-xs font-weight-bold mb-0">{data.description}</p>
//                                                             </td>
//                                                             <td>
//                                                                 <p class="text-xs font-weight-bold mb-0">{data.message}</p>
//                                                             </td>



//                                                         </tr>

//                                                     </>
//                                                 ))}


//                                             </tbody>
//                                         </table>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>

//                 </div>


//             </main>


//         </>
//     )
// }

// export default RequestCallBack;