import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import db from "./Firebase";
import { CSVLink } from "react-csv";
import Moment from 'moment';
import { extendMoment } from 'moment-range';

function BookDemoRequest() {
    const navigate = useNavigate();
    const moment = extendMoment(Moment);
    const [data, setData] = useState([]);
    const [csvdata, setCsvdata] = useState([]);
    const [lastVisible, setLastVisible] = useState(null);
    const [hasMore, setHasMore] = useState(true);
    const [uid, setUid] = useState('');

    const headers = [
        { label: "Full Name", key: "full_name" },
        { label: "Phone No", key: "phone_no" },
        { label: "Email", key: "email" },
        { label: "Course Name", key: "course_name" },
        { label: "State", key: "state" },
        { label: "Created Date", key: "created_date", dataFormat: "MM/DD/YY, h:mm a" },
        { label: "Selected Date", key: "selected_date", dataFormat: "MM/DD/YY, h:mm a" },
    ];

    const initialFormStateFeedback = { request_status: '', remark: '', call_status: '', demo_feedback: '', updated_date: new Date() };
    const [submit, setSubmit] = useState(initialFormStateFeedback);

    useEffect(() => {
        const storedUsername = localStorage.getItem("email");
        const storedPassword = localStorage.getItem("password");
        const storedRemember = localStorage.getItem("remember");

        if (storedRemember === "false" || !storedUsername || !storedPassword) {
            navigate('/signin');
        }
    }, [navigate]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const snapshot = await db.collection("book_demo_details")
                    .orderBy("created_date", "desc")
                    .limit(20)
                    .get();

                const lastVisibleDoc = snapshot.docs[snapshot.docs.length - 1];
                setLastVisible(lastVisibleDoc);

                const fetchedData = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    data: doc.data(),
                }));

                setData(fetchedData);

                setCsvdata(
                    snapshot.docs.map((doc) => {
                        const data = doc.data();
                        return {
                            ...data,
                            created_date: moment(data.created_date.toDate().toISOString()).format("MM/DD/YY, h:mm a"),
                            selected_date: moment(data.selected_date.toDate().toISOString()).format("MM/DD/YY, h:mm a"),
                        };
                    })
                );
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, []);

    const loadMoreData = async () => {
        try {
            const snapshot = await db.collection("book_demo_details")
                .orderBy("created_date", "desc")
                .startAfter(lastVisible)
                .limit(20)
                .get();

            const lastVisibleDoc = snapshot.docs[snapshot.docs.length - 1];
            setLastVisible(lastVisibleDoc);

            if (snapshot.docs.length < 20) {
                setHasMore(false);
            }

            const fetchedData = snapshot.docs.map((doc) => ({
                id: doc.id,
                data: doc.data(),
            }));

            setData((prevData) => [...prevData, ...fetchedData]);

            setCsvdata((prevData) => [
                ...prevData,
                ...snapshot.docs.map((doc) => {
                    const data = doc.data();
                    return {
                        ...data,
                        created_date: moment(data.created_date.toDate().toISOString()).format("MM/DD/YY, h:mm a"),
                        selected_date: moment(data.selected_date.toDate().toISOString()).format("MM/DD/YY, h:mm a"),
                    };
                }),
            ]);
        } catch (error) {
            console.error("Error fetching more data:", error);
        }
    };

    const updateData = (id, updates) => {
        setData((prevData) =>
            prevData.map((item) =>
                item.id === id ? { ...item, data: { ...item.data, ...updates } } : item
            )
        );
    };

    const handleStatusChange = async (e) => {
        const statusValue = e.target.value;
        const { value: text } = await Swal.fire({
            input: 'textarea',
            inputLabel: statusValue === "2" ? 'Update Remark' : 'Final Remark',
            inputPlaceholder: 'Type your message here...',
            inputAttributes: {
                'aria-label': 'Type your message here'
            },
            showCancelButton: true
        });

        if (text) {
            const updatedStatus = {
                'request_status': statusValue,
                'remark': text,
                'updated_date': new Date()
            };

            await db.collection("book_demo_details").doc(uid).update(updatedStatus);
            updateData(uid, updatedStatus);
            Swal.fire("Updated!", "The status has been updated.", "success");
        }
    };

    const handleCallStatusChange = async (e) => {
        const callStatusValue = e.target.value;
        await db.collection("book_demo_details").doc(uid).update({ 'call_status': callStatusValue, 'updated_date': new Date() });
        updateData(uid, { 'call_status': callStatusValue, 'updated_date': new Date() });
        Swal.fire("Updated!", "The call status has been updated.", "success");
    };

    const handleFeedbackStatusChange = async (e) => {
        const feedbackValue = e.target.value;
        await db.collection("book_demo_details").doc(uid).update({ 'demo_feedback': feedbackValue, 'updated_date': new Date() });
        updateData(uid, { 'demo_feedback': feedbackValue, 'updated_date': new Date() });
        Swal.fire("Updated!", "The feedback has been updated.", "success");
    };

    return (
        <>
            <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg">
                <div class="p-2" style={{ paddingBottom: "5px", display: "flex", justifyContent: "space-between" }}>
                    <Link to="/admin_panel">
                        <img src="/images/menubutton.png" class="menu-toggle" alt="Responsive image" style={{ height: "2rem" }} />
                    </Link>
                    <CSVLink data={csvdata} headers={headers}>
                        <span class="btn bg-gradient-secondary btn-sm mb-0 mr-2" style={{ color: "white" }}>
                            &nbsp; Download Excel
                        </span>
                    </CSVLink>
                </div>
                <div class="">
                    <div class="p-2">
                        <div class="card">
                            <div class="card-header pb-0 px-3">
                                <h6 class="mb-2">Book Demo Request</h6>
                            </div>
                            <div class="">
                                <div class="card">
                                    <div class="table-responsive fix-width scroll-inner">
                                        <table class="table table-bordered table-hover">
                                            <thead class="thead-dark" style={{ position: "sticky", top: "0" }}>
                                                <tr style={{ textAlign: "left" }}>
                                                    <th class="col ps-2">Full Name</th>
                                                    <th class="col ps-2">Phone No</th>
                                                    <th class="col ps-2">Email</th>
                                                    <th class="col">Course Name</th>
                                                    <th class="col ps-2">State</th>
                                                    <th class="col ps-2">Selected Date</th>
                                                    <th class="col">Call Status</th>
                                                    <th class="col">Feedback</th>
                                                    <th class="col">Status</th>
                                                    <th class="col">Final Remark</th>
                                                </tr>
                                            </thead>
                                            <tbody style={{ textAlign: "left" }}>
                                                {data.map(({ id, data }, index) => (
                                                    <tr key={id}>
                                                        <td>
                                                            <div class="d-flex px-2 py-1">
                                                                <div class="d-flex flex-column justify-content-center">
                                                                    <h6 class="mb-0 text-xs">{data.full_name}</h6>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="d-flex px-2 py-1">
                                                                <div class="d-flex flex-column justify-content-center">
                                                                    <h6 class="mb-0 text-xs">{data.phone_no}</h6>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td style={{ whiteSpace: 'pre-wrap' }}>
                                                            <p class="text-xs font-weight-bold mb-0" style={{ width: "15rem" }}>{data.email}</p>
                                                        </td>
                                                        <td style={{ whiteSpace: 'pre-wrap' }}>
                                                            <p class="text-xs font-weight-bold mb-0" style={{ width: "15rem" }}>{data.course_name}</p>
                                                        </td>
                                                        <td>
                                                            <div class="d-flex px-2 py-1">
                                                                <div class="d-flex flex-column justify-content-center">
                                                                    <h6 class="mb-0 text-xs">{data.state}</h6>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="d-flex px-2 py-1">
                                                                <div class="d-flex flex-column justify-content-center">
                                                                    <h6 class="mb-0 text-xs">{moment(data.selected_date.toDate().toISOString()).format("MM/DD/YY, h:mm a")}</h6>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <select
                                                                class="form-control"
                                                                id="call_status"
                                                                name="call_status"
                                                                onClick={() => setUid(id)}
                                                                onChange={handleCallStatusChange}
                                                                value={data.call_status || submit.call_status}
                                                                style={{
                                                                    width: "10rem",
                                                                    background: data.call_status === "Connected"
                                                                        ? "green"
                                                                        : data.call_status === "Did Not Answer"
                                                                            ? "black"
                                                                            : "brown",
                                                                    color: "white"
                                                                }}>
                                                                <option value="" disabled>Call Status</option>
                                                                <option value="Connected">Connected</option>
                                                                <option value="Did Not Answer">Did Not Answer</option>
                                                                <option value="Not Reachable">Not Reachable</option>
                                                            </select>
                                                        </td>
                                                        <td>
                                                            <select
                                                                class="form-control"
                                                                id="demo_feedback"
                                                                name="demo_feedback"
                                                                onClick={() => setUid(id)}
                                                                onChange={handleFeedbackStatusChange}
                                                                value={data.demo_feedback || submit.demo_feedback}
                                                                style={{ width: "10rem" }}>
                                                                <option value="" disabled>Feedback Status</option>
                                                                <option value="Excellent">Excellent</option>
                                                                <option value="Very Good">Very Good</option>
                                                                <option value="Good">Good</option>
                                                                <option value="Average">Average</option>
                                                                <option value="Not Satisfied">Not Satisfied</option>
                                                            </select>
                                                        </td>
                                                        <td>
                                                            <select
                                                                class="form-control"
                                                                id="request_status"
                                                                name="request_status"
                                                                onClick={() => setUid(id)}
                                                                onChange={handleStatusChange}
                                                                value={data.request_status || submit.request_status}
                                                                style={{
                                                                    width: "8rem",
                                                                    background: data.request_status === "1"
                                                                        ? "green"
                                                                        : "white",
                                                                    color: data.request_status === "1"
                                                                        ? "white"
                                                                        : "black"
                                                                }}>
                                                                <option value="" disabled>{data.request_status === "1" ? "Done" : "Pending"}</option>
                                                                <option value="2">Update Status</option>
                                                                <option value="1">Done</option>
                                                            </select>
                                                        </td>
                                                        <td>
                                                            <textarea
                                                                class="form-control"
                                                                value={data.remark}
                                                                // onChange={handleRemarkChange}
                                                                style={{ width: "18rem", background: "#ddd" }}
                                                                disabled
                                                            />
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                        {hasMore && (
                                            <div className="" style={{ paddingTop: "2rem" }}>
                                                <button onClick={loadMoreData} className="btn btn-dark">Load More</button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
}

export default BookDemoRequest;



// import React, { useState, useEffect } from 'react';
// import { useNavigate, Link } from 'react-router-dom';
// import Swal from 'sweetalert2';
// import db from "./Firebase";
// import { CSVLink } from "react-csv";
// import Moment from 'moment';
// import { extendMoment } from 'moment-range';

// function BookDemoRequest() {
//     const navigate = useNavigate();
//     const moment = extendMoment(Moment);
//     const [data, setData] = useState([]);
//     const [csvdata, setCsvdata] = useState([]);
//     const [lastVisible, setLastVisible] = useState(null);
//     const [hasMore, setHasMore] = useState(true);

//     const headers = [
//         { label: "Full Name", key: "full_name" },
//         { label: "Phone No", key: "phone_no" },
//         { label: "Email", key: "email" },
//         { label: "Course Name", key: "course_name" },
//         { label: "State", key: "state" },
//         { label: "Created Date", key: "created_date", dataFormat: "MM/DD/YY, h:mm a" },
//         { label: "Selected Date", key: "selected_date", dataFormat: "MM/DD/YY, h:mm a" },
//     ];

//     const initialFormStateFeedback = { request_status: '', remark: '', call_status: '', demo_feedback: '', updated_date: new Date() };
//     const [submit, setSubmit] = useState(initialFormStateFeedback);

//     const [status, setStatus] = useState("Pending");
//     const [remark, setRemark] = useState("");
//     const [uid, setUid] = useState('');
//     const [editIndex, setEditIndex] = useState();
//     const [edit, setEdit] = useState(false);

//     useEffect(() => {
//         const storedUsername = localStorage.getItem("email");
//         const storedPassword = localStorage.getItem("password");
//         const storedRemember = localStorage.getItem("remember");

//         if (storedRemember === "false" || !storedUsername || !storedPassword) {
//             navigate('/signin');
//         }
//     }, [navigate]);

//     const callStatus = (uid, index) => {
//         setUid(uid);
//         setEditIndex(index);
//     }

//     const handleRemarkChange = (event) => {
//         setRemark(event.target.value);
//     };

//     useEffect(() => {
//         const fetchData = async () => {
//             try {
//                 const snapshot = await db.collection("book_demo_details")
//                     .orderBy("created_date", "desc")
//                     .limit(20)
//                     .get();

//                 const lastVisibleDoc = snapshot.docs[snapshot.docs.length - 1];
//                 setLastVisible(lastVisibleDoc);

//                 const fetchedData = snapshot.docs.map((doc) => ({
//                     id: doc.id,
//                     data: doc.data(),
//                 }));

//                 setData(fetchedData);

//                 setCsvdata(
//                     snapshot.docs.map((doc) => {
//                         const data = doc.data();
//                         return {
//                             ...data,
//                             created_date: moment(data.created_date.toDate().toISOString()).format("MM/DD/YY, h:mm a"),
//                             selected_date: moment(data.selected_date.toDate().toISOString()).format("MM/DD/YY, h:mm a"),
//                         };
//                     })
//                 );
//             } catch (error) {
//                 console.error("Error fetching data:", error);
//             }
//         };

//         fetchData();
//     }, []);

//     const loadMoreData = async () => {
//         try {
//             const snapshot = await db.collection("book_demo_details")
//                 .orderBy("created_date", "desc")
//                 .startAfter(lastVisible)
//                 .limit(20)
//                 .get();

//             const lastVisibleDoc = snapshot.docs[snapshot.docs.length - 1];
//             setLastVisible(lastVisibleDoc);

//             if (snapshot.docs.length < 20) {
//                 setHasMore(false);
//             }

//             const fetchedData = snapshot.docs.map((doc) => ({
//                 id: doc.id,
//                 data: doc.data(),
//             }));

//             setData((prevData) => [...prevData, ...fetchedData]);

//             setCsvdata((prevData) => [
//                 ...prevData,
//                 ...snapshot.docs.map((doc) => {
//                     const data = doc.data();
//                     return {
//                         ...data,
//                         created_date: moment(data.created_date.toDate().toISOString()).format("MM/DD/YY, h:mm a"),
//                         selected_date: moment(data.selected_date.toDate().toISOString()).format("MM/DD/YY, h:mm a"),
//                     };
//                 }),
//             ]);
//         } catch (error) {
//             console.error("Error fetching more data:", error);
//         }
//     };

//     const handleStatusChange = async (e) => {
//         const statusValue = e.target.value;
//         const { value: text } = await Swal.fire({
//             input: 'textarea',
//             inputLabel: statusValue === "2" ? 'Update Remark' : 'Final Remark',
//             inputPlaceholder: 'Type your message here...',
//             inputAttributes: {
//                 'aria-label': 'Type your message here'
//             },
//             showCancelButton: true
//         });

//         const updatedStatus = {
//             'request_status': statusValue,
//             'remark': text,
//             'updated_date': new Date()
//         };

//         db.collection("book_demo_details").doc(uid).update(updatedStatus).then(() => {
//             console.log("Data Updated");
//         });
//     };

//     const handleCallStatusChange = (e) => {
//         const callStatusValue = e.target.value;
//         db.collection("book_demo_details").doc(uid).update({ 'call_status': callStatusValue, 'updated_date': new Date() }).then(() => {
//             console.log("Data Updated");
//         });
//     };

//     const handleFeedbackStatusChange = (e) => {
//         const feedbackValue = e.target.value;
//         db.collection("book_demo_details").doc(uid).update({ 'demo_feedback': feedbackValue, 'updated_date': new Date() }).then(() => {
//             console.log("Data Updated");
//         });
//     };

//     return (
//         <>
//             <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg">
//                 <div class="p-2" style={{ paddingBottom: "5px", display: "flex", justifyContent: "space-between" }}>
//                     <Link to="/admin_panel">
//                         <img src="/images/menubutton.png" class="menu-toggle" alt="Responsive image" style={{ height: "2rem" }} />
//                     </Link>
//                     <CSVLink data={csvdata} headers={headers}>
//                         <span class="btn bg-gradient-secondary btn-sm mb-0 mr-2" style={{ color: "white" }}>
//                             &nbsp; Download Excel
//                         </span>
//                     </CSVLink>
//                 </div>
//                 <div class="">
//                     <div class="p-2">
//                         <div class="card">
//                             <div class="card-header pb-0 px-3">
//                                 <h6 class="mb-2">Book Demo Request</h6>
//                             </div>
//                             <div class="">
//                                 <div class="card">
//                                     <div class="table-responsive fix-width scroll-inner">
//                                         <table class="table table-bordered table-hover">
//                                             <thead class="thead-dark" style={{ position: "sticky", top: "0" }}>
//                                                 <tr style={{ textAlign: "left" }}>
//                                                     <th class="col ps-2">Full Name</th>
//                                                     <th class="col ps-2">Phone No</th>
//                                                     <th class="col ps-2">Email</th>
//                                                     <th class="col">Course Name</th>
//                                                     <th class="col ps-2">State</th>
//                                                     <th class="col ps-2">Selected Date</th>
//                                                     <th class="col">Call Status</th>
//                                                     <th class="col">Feedback</th>
//                                                     <th class="col">Status</th>
//                                                     <th class="col">Final Remark</th>
//                                                 </tr>
//                                             </thead>
//                                             <tbody style={{ textAlign: "left" }}>
//                                                 {data.map(({ id, data }, index) => (
//                                                     <tr key={id}>
//                                                         <td>
//                                                             <div class="d-flex px-2 py-1">
//                                                                 <div class="d-flex flex-column justify-content-center">
//                                                                     <h6 class="mb-0 text-xs">{data.full_name}</h6>
//                                                                 </div>
//                                                             </div>
//                                                         </td>
//                                                         <td>
//                                                             <div class="d-flex px-2 py-1">
//                                                                 <div class="d-flex flex-column justify-content-center">
//                                                                     <h6 class="mb-0 text-xs">{data.phone_no}</h6>
//                                                                 </div>
//                                                             </div>
//                                                         </td>
//                                                         <td style={{ whiteSpace: 'pre-wrap' }}>
//                                                             <p class="text-xs font-weight-bold mb-0" style={{ width: "15rem" }}>{data.email}</p>
//                                                         </td>
//                                                         <td style={{ whiteSpace: 'pre-wrap' }}>
//                                                             <p class="text-xs font-weight-bold mb-0" style={{ width: "15rem" }}>{data.course_name}</p>
//                                                         </td>
//                                                         <td>
//                                                             <div class="d-flex px-2 py-1">
//                                                                 <div class="d-flex flex-column justify-content-center">
//                                                                     <h6 class="mb-0 text-xs">{data.state}</h6>
//                                                                 </div>
//                                                             </div>
//                                                         </td>
//                                                         <td>
//                                                             <div class="d-flex px-2 py-1">
//                                                                 <div class="d-flex flex-column justify-content-center">
//                                                                     <h6 class="mb-0 text-xs">{moment(data.selected_date.toDate().toISOString()).format("MM/DD/YY, h:mm a")}</h6>
//                                                                 </div>
//                                                             </div>
//                                                         </td>
//                                                         <td>
//                                                             <select
//                                                                 class="form-control"
//                                                                 id="call_status"
//                                                                 name="call_status"
//                                                                 onClick={() => callStatus(id, index)}
//                                                                 onChange={handleCallStatusChange}
//                                                                 value={data.call_status || submit.call_status}
//                                                                 style={{
//                                                                     width: "10rem",
//                                                                     background: data.call_status === "Connected"
//                                                                         ? "green"
//                                                                         : data.call_status === "Did Not Answer"
//                                                                             ? "black"
//                                                                             : "brown",
//                                                                     color: "white"
//                                                                 }}>
//                                                                 <option value="" disabled>Call Status</option>
//                                                                 <option value="Connected">Connected</option>
//                                                                 <option value="Did Not Answer">Did Not Answer</option>
//                                                                 <option value="Not Reachable">Not Reachable</option>
//                                                             </select>
//                                                         </td>
//                                                         <td>
//                                                             <select
//                                                                 class="form-control"
//                                                                 id="demo_feedback"
//                                                                 name="demo_feedback"
//                                                                 onClick={() => callStatus(id, index)}
//                                                                 onChange={handleFeedbackStatusChange}
//                                                                 value={data.demo_feedback || submit.demo_feedback}
//                                                                 style={{ width: "10rem" }}>
//                                                                 <option value="" disabled>Feedback Status</option>
//                                                                 <option value="Excellent">Excellent</option>
//                                                                 <option value="Very Good">Very Good</option>
//                                                                 <option value="Good">Good</option>
//                                                                 <option value="Average">Average</option>
//                                                                 <option value="Not Satisfied">Not Satisfied</option>
//                                                             </select>
//                                                         </td>
//                                                         <td>
//                                                             <select
//                                                                 class="form-control"
//                                                                 id="request_status"
//                                                                 name="request_status"
//                                                                 onClick={() => callStatus(id, index)}
//                                                                 onChange={handleStatusChange}
//                                                                 value={data.request_status || submit.request_status}
//                                                                 style={{
//                                                                     width: "8rem",
//                                                                     background: data.request_status === "1"
//                                                                         ? "green"
//                                                                         : "white",
//                                                                     color: data.request_status === "1"
//                                                                         ? "white"
//                                                                         : "black"
//                                                                 }}>
//                                                                 <option value="" disabled>{data.request_status === "1" ? "Done" : "Pending"}</option>
//                                                                 <option value="2">Update Status</option>
//                                                                 <option value="1">Done</option>
//                                                             </select>
//                                                         </td>
//                                                         <td>
//                                                             <textarea
//                                                                 class="form-control"
//                                                                 value={data.remark}
//                                                                 onChange={handleRemarkChange}
//                                                                 style={{ width: "18rem", background: "#ddd" }}
//                                                                 disabled
//                                                             />
//                                                         </td>
//                                                     </tr>
//                                                 ))}
//                                             </tbody>
//                                         </table>
//                                         {hasMore && (
//                                             <div className="" style={{ paddingTop: "2rem" }}>
//                                                 <button onClick={loadMoreData} className="btn btn-dark">Load More</button>
//                                             </div>
//                                         )}
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </main>
//         </>
//     );
// }

// export default BookDemoRequest;




// import React, { useState, useEffect } from 'react';
// import { useNavigate, Link, useParams } from 'react-router-dom';
// import db from "./Firebase";
// import { arrayUnion } from "firebase/firestore";
// import { storage } from './Firebase';
// import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
// import Moment from 'moment';
// import { extendMoment } from 'moment-range';
// import ViewAllRequests from './ViewAllRequests';
// import { CSVLink } from "react-csv";
// import Swal from 'sweetalert2'

// function BookDemoRequest() {
//     const navigate = useNavigate();
//     const moment = extendMoment(Moment);
//     const [data, setData] = useState([]);
//     const [csvdata, setCsvdata] = useState([]);

//     const headers = [
//         { label: "Full Name", key: "full_name" },
//         { label: "Phone No", key: "phone_no" },
//         { label: "Email", key: "email" },
//         { label: "Course Name", key: "course_name" },
//         { label: "State", key: "state" },
//         { label: "Created Date", key: "created_date", dataFormat: "MM/DD/YY, h:mm a" },
//         { label: "Selected Date", key: "selected_date", dataFormat: "MM/DD/YY, h:mm a" },
//     ];


//     const initialFormStateFeedback = { request_status: '', remark: '', call_status: '', demo_feedback: '', updated_date: new Date() }
//     const [submit, setSubmit] = useState(initialFormStateFeedback);


//     // Additional fields for Status and Remark
//     const [status, setStatus] = useState("Pending");
//     const [remark, setRemark] = useState("");
//     const [uid, setUid] = useState('');
//     const [editIndex, setEditIndex] = useState();
//     const [edit, setEdit] = useState(false);



//     useEffect(() => {
//         const storedUsername = localStorage.getItem("email");
//         const storedPassword = localStorage.getItem("password");
//         const storedRemember = localStorage.getItem("remember");

//         if (storedRemember === "false" || !storedUsername || !storedPassword) {
//             navigate('/signin');
//         }
//     }, []);

//     const callStatus = (uid, index) => {
//         console.log(uid)
//         console.log(index)
//         setUid(uid);
//         setEditIndex(index);
//     }

//     const handleRemarkChange = (event) => {
//         setRemark(event.target.value);
//     };

//     useEffect(() => {
//         db.collection("book_demo_details").orderBy("created_date", "desc").onSnapshot((snapshot) => {
//             setData(
//                 snapshot.docs.map((doc) => ({
//                     id: doc.id,
//                     data: doc.data(),
//                 }))
//             );

//             setCsvdata(
//                 snapshot.docs.map((doc) => {
//                     const data = doc.data();
//                     return {
//                         ...data,
//                         created_date: moment(data.created_date.toDate().toISOString()).format("MM/DD/YY, h:mm a"),
//                         selected_date: moment(data.selected_date.toDate().toISOString()).format("MM/DD/YY, h:mm a"),
//                     };
//                 })
//             );
//         });
//     }, []);


//     const handleStatusChange = async (e) => {
//         // setStatus(event.target.value);
//         // const { id, value } = e.target
//         // setSubmit({ ...submit, [id]: value })

//         if (e.target.value == "2") {
//             const { value: text } = await Swal.fire({
//                 input: 'textarea',
//                 inputLabel: 'Update Remark',
//                 inputPlaceholder: 'Type your message here...',
//                 inputAttributes: {
//                     'aria-label': 'Type your message here'
//                 },
//                 showCancelButton: true
//             })
//             db.collection("book_demo_details").doc(uid).update({ 'request_status': "2", 'remark': text, 'updated_date': new Date() }).then(() => {
//                 console.log("data Updated");
//             })

//         } else {
//             const { value: text } = await Swal.fire({
//                 input: 'textarea',
//                 inputLabel: 'Final Remark',
//                 inputPlaceholder: 'Type your message here...',
//                 inputAttributes: {
//                     'aria-label': 'Type your message here'
//                 },
//                 showCancelButton: true
//             })
//             db.collection("book_demo_details").doc(uid).update({ 'request_status': "1", 'remark': text, 'updated_date': new Date() }).then(() => {
//                 console.log("data Updated");
//             })
//         }

//     };

//     const handleCallStatusChange = (e) => {
//         console.log("calling.....")
//         console.log(uid)
//         db.collection("book_demo_details").doc(uid).update({ 'call_status': e.target.value, 'updated_date': new Date() }).then(() => {
//             console.log("data Updated");
//         })
//         // console.log(e.target.value)
//     }

//     const handleFeedbackStatusChange = (e) => {
//         console.log("calling.....Feedback....")
//         console.log(uid)
//         db.collection("book_demo_details").doc(uid).update({ 'demo_feedback': e.target.value, 'updated_date': new Date() }).then(() => {
//             console.log("data Updated");
//         })
//         // console.log(e.target.value)
//     }

//     return (
//         <>
//             {/* <ViewAllRequests /> */}
//             <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
//                 <div class="p-2" style={{ paddingBottom: "5px", display: "flex", justifyContent: "space-between" }}>
//                     {/* <span class="btn bg-gradient-secondary btn-sm mb-0 mr-2" style={{ color: "white" }} >&nbsp; Download Excel</span> */}
//                     <Link to="/admin_panel"><img src="/images/menubutton.png" class="menu-toggle" alt="Responsive image" style={{ height: "2rem" }} /></Link>
//                     <CSVLink data={csvdata} headers={headers}>
//                         <span class="btn bg-gradient-secondary btn-sm mb-0 mr-2" style={{ color: "white" }} >&nbsp; Download Excel</span>
//                     </CSVLink>
//                 </div>
//                 <div class="">
//                     <div class="p-2">

//                         <div class="card">
//                             <div class="card-header pb-0 px-3">
//                                 <h6 class="mb-2">Book Demo Request</h6>
//                             </div>
//                             <div class="">

//                                 <div class="card">
//                                     <div class="table-responsive fix-width scroll-inner">
//                                         <table class="table table-bordered table-hover">
//                                             <thead class="thead-dark" style={{ position: "sticky", top: "0" }}>
//                                                 <tr style={{ textAlign: "left" }}>
//                                                     <th class="col ps-2">Full Name</th>
//                                                     <th class="col ps-2">Phone No</th>
//                                                     <th class="col ps-2">Email</th>
//                                                     <th class="col">Course Name</th>
//                                                     <th class="col ps-2">State</th>
//                                                     <th class="col ps-2">Selected Date</th>
//                                                     <th class="col">Call Status</th>
//                                                     <th class="col">Feedback</th>
//                                                     <th class="col">Status</th>
//                                                     <th class="col">Final Remark</th>
//                                                 </tr>
//                                             </thead>
//                                             <tbody style={{ textAlign: "left" }}>
//                                                 {data?.map(({ id, data }, index) => (
//                                                     <>
//                                                         <tr>
//                                                             <td>
//                                                                 <div class="d-flex px-2 py-1">
//                                                                     <div class="d-flex flex-column justify-content-center">
//                                                                         <h6 class="mb-0 text-xs">{data.full_name}</h6>
//                                                                     </div>
//                                                                 </div>
//                                                             </td>
//                                                             <td>
//                                                                 <div class="d-flex px-2 py-1">
//                                                                     <div class="d-flex flex-column justify-content-center">
//                                                                         <h6 class="mb-0 text-xs">{data.phone_no}</h6>
//                                                                     </div>
//                                                                 </div>
//                                                             </td>
//                                                             <td style={{ whiteSpace: 'pre-wrap' }}>
//                                                                 <p class="text-xs font-weight-bold mb-0" style={{ width: "15rem" }}>{data.email}</p>

//                                                             </td>

//                                                             <td style={{ whiteSpace: 'pre-wrap' }}>
//                                                                 <p class="text-xs font-weight-bold mb-0" style={{ width: "15rem" }}>{data.course_name}</p>

//                                                             </td>

//                                                             <td>
//                                                                 <div class="d-flex px-2 py-1">
//                                                                     <div class="d-flex flex-column justify-content-center">
//                                                                         <h6 class="mb-0 text-xs">{data.state}</h6>
//                                                                     </div>
//                                                                 </div>
//                                                             </td>
//                                                             <td>
//                                                                 <div class="d-flex px-2 py-1">
//                                                                     <div class="d-flex flex-column justify-content-center">
//                                                                         <h6 class="mb-0 text-xs">{moment(data.selected_date.toDate().toISOString()).format("MM/DD/YY, h:mm a")}</h6>
//                                                                     </div>
//                                                                 </div>
//                                                             </td>

//                                                             {data.call_status ?
//                                                                 <>
//                                                                     <td>
//                                                                         <select class="form-control" id="call_status" name="call_status" onClick={() => callStatus(id, index)} onChange={handleCallStatusChange} value={data.call_status} style={{ width: "10rem", background: data.call_status === "Connected" ? "green" : data.callStatus === "Did Not Answer" ? "black" : "brown", color: "white" }}>
//                                                                             <option value="" disabled>Call Status</option>
//                                                                             <option selected={data.call_status == "Connected" ? true : false} value="Connected">Connected</option>
//                                                                             <option selected={data.call_status == "Did Not Answer" ? true : false} value="Did Not Answer">Did Not Answer</option>
//                                                                             <option selected={data.call_status == "Not Reachable" ? true : false} value="Not Reachable">Not Reachable</option>
//                                                                         </select>
//                                                                     </td>
//                                                                 </>
//                                                                 :
//                                                                 <>
//                                                                     <td>
//                                                                         <select class="form-control" id="call_status" name="call_status" onClick={() => callStatus(id, index)} onChange={handleCallStatusChange} value={submit.call_status} style={{ width: "10rem", }}>
//                                                                             <option value="" disabled>Call Status</option>
//                                                                             <option value="Connected">Connected</option>
//                                                                             <option value="Did Not Answer">Did Not Answer</option>
//                                                                             <option value="Not Reachable">Not Reachable</option>
//                                                                         </select>
//                                                                     </td>
//                                                                 </>
//                                                             }





//                                                             {data.demo_feedback ?
//                                                                 <>
//                                                                     <td>
//                                                                         <select class="form-control" id="demo_feedback" name="demo_feedback" onClick={() => callStatus(id, index)} onChange={handleFeedbackStatusChange} value={data.demo_feedback} style={{ width: "10rem" }}>
//                                                                             <option value="" disabled>Feedback Status</option>
//                                                                             <option selected={data.demo_feedback == "Excellent" ? true : false} value="Excellent">Excellent</option>
//                                                                             <option selected={data.demo_feedback == "Very Good" ? true : false} value="Very Good">Very Good</option>
//                                                                             <option selected={data.demo_feedback == "Good" ? true : false} value="Good">Good</option>
//                                                                             <option selected={data.demo_feedback == "Average" ? true : false} value="Average">Average</option>
//                                                                             <option selected={data.demo_feedback == "Not Satisfied" ? true : false} value="Not Satisfied">Not Satisfied</option>
//                                                                         </select>
//                                                                     </td>
//                                                                 </>
//                                                                 :
//                                                                 <>
//                                                                     <td>
//                                                                         <select class="form-control" id="demo_feedback" name="demo_feedback" onClick={() => callStatus(id, index)} onChange={handleFeedbackStatusChange} value={submit.demo_feedback} style={{ width: "10rem", }}>
//                                                                             <option value="" disabled>Feedback Status</option>
//                                                                             <option value="Excellent">Excellent</option>
//                                                                             <option value="Very Good">Very Good</option>
//                                                                             <option value="Good">Good</option>
//                                                                             <option value="Average">Average</option>
//                                                                             <option value="Not Satisfied">Not Satisfied</option>
//                                                                         </select>
//                                                                     </td>
//                                                                 </>
//                                                             }




//                                                             {data.request_status === "1" ?
//                                                                 <>
//                                                                     <td>
//                                                                         <select class="form-control" id="request_status" name="request_status" onClick={() => callStatus(id, index)} onChange={handleStatusChange} value={submit.request_status} style={{ width: "8rem", background: "green", color: "white" }}>
//                                                                             <option value="" disabled>Done</option>
//                                                                         </select>
//                                                                     </td>
//                                                                 </>
//                                                                 :
//                                                                 <>
//                                                                     <td>
//                                                                         <select class="form-control" id="request_status" name="request_status" onClick={() => callStatus(id, index)} onChange={handleStatusChange} value={submit.request_status} style={{ width: "8rem" }}>
//                                                                             <option value="" disabled>Pending</option>
//                                                                             <option selected={data.request_status == "2" ? true : false} value="2">Update Status</option>
//                                                                             <option selected={data.request_status == "1" ? true : false} value="1">Done</option>
//                                                                         </select>
//                                                                     </td>
//                                                                 </>
//                                                             }


//                                                             <td>
//                                                                 <textarea class="form-control" value={data.remark} onChange={handleRemarkChange} style={{ width: "18rem", background: "#ddd" }} disabled />
//                                                             </td>

//                                                         </tr>
//                                                     </>
//                                                 ))}
//                                             </tbody>
//                                         </table>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </main>
//         </>
//     );
// }

// export default BookDemoRequest;



// import React, { useState, useEffect } from 'react';
// import { useNavigate, Link, useParams } from 'react-router-dom';
// import db from "./Firebase";
// import { arrayUnion } from "firebase/firestore";
// import { storage } from './Firebase';
// import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
// import Moment from 'moment';
// import { extendMoment } from 'moment-range';
// import ViewAllRequests from './ViewAllRequests';
// import { CSVLink } from "react-csv";

// function BookDemoRequest() {
//     const moment = extendMoment(Moment);
//     const [data, setData] = useState([]);
//     const [csvdata, setCsvdata] = useState([]);

//     const headers = [
//         { label: "Full Name", key: "full_name" },
//         { label: "Phone No", key: "phone_no" },
//         { label: "Email", key: "email" },
//         { label: "Course Name", key: "course_name" },
//         { label: "State", key: "state" },
//         { label: "Created Date", key: "created_date" },
//         { label: "Selected Date", key: "selected_date" },
//     ];

//     useEffect(() => {
//         db.collection("book_demo_details").orderBy("created_date", "desc").onSnapshot((snapshot) => {

//             setData(
//                 snapshot.docs.map((doc) => ({
//                     id: doc.id,
//                     data: doc.data(),
//                 }))
//             );

//             setCsvdata(
//                 snapshot.docs.map((doc) => (
//                     doc.data()
//                 ))
//             );
//         });

//     }, []);

//     return (
//         <>
//             {/* <ViewAllRequests /> */}
//             <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">

//                 <div class="row">
//                     <div class="mt-4 p-4">
//                         <div style={{ textAlign: "end", paddingBottom: "5px" }}>
//                             {/* <Link to="/admin_panel" class="btn bg-gradient-primary btn-sm mb-0 mr-2" style={{ color: "white" }} >&nbsp; Go Back</Link> */}
//                             <CSVLink data={csvdata} headers={headers}>
//                                 <span class="btn bg-gradient-secondary btn-sm mb-0 mr-2" style={{ color: "white" }} >&nbsp; Download Excel</span>
//                             </CSVLink>
//                         </div>
//                         <div class="card">
//                             <div class="card-header pb-0 px-3">
//                                 <h6 class="mb-2">Book Demo Request</h6>
//                             </div>
//                             <div class="">

//                                 <div class="card">
//                                     <div class="table-responsive fix-width scroll-inner">
//                                         <table class="table align-items-center mb-0">
//                                             <thead>
//                                                 <tr style={{ textAlign: "left" }}>
//                                                     <th class="col ps-2">Full Name</th>
//                                                     <th class="col ps-2">Phone No</th>
//                                                     <th class="col ps-2">Email</th>
//                                                     <th class="col">Course Name</th>
//                                                     <th class="col ps-2">State</th>
//                                                     <th class="col ps-2">Selected Date</th>
//                                                 </tr>
//                                             </thead>
//                                             <tbody style={{ textAlign: "left" }}>
//                                                 {data?.map(({ id, data }, index) => (
//                                                     <>
//                                                         <tr>

//                                                             <td>
//                                                                 <div class="d-flex px-2 py-1">

//                                                                     <div class="d-flex flex-column justify-content-center">
//                                                                         <h6 class="mb-0 text-xs">{data.full_name}</h6>
//                                                                     </div>
//                                                                 </div>
//                                                             </td>
//                                                             <td>
//                                                                 <div class="d-flex px-2 py-1">

//                                                                     <div class="d-flex flex-column justify-content-center">
//                                                                         <h6 class="mb-0 text-xs">{data.phone_no}</h6>
//                                                                     </div>
//                                                                 </div>
//                                                             </td>
//                                                             <td>
//                                                                 <div class="d-flex px-2 py-1">

//                                                                     <div class="d-flex flex-column justify-content-center">
//                                                                         <h6 class="mb-0 text-xs">{data.email}</h6>
//                                                                     </div>
//                                                                 </div>
//                                                             </td>
//                                                             <td>
//                                                                 <p class="text-xs font-weight-bold mb-0">{data.course_name}</p>
//                                                                 <p class="text-xs text-secondary mb-0">{moment(data.created_date.toDate().toISOString()).format("MM/DD/YY, h:mm a")}</p>
//                                                             </td>


//                                                             <td>
//                                                                 <div class="d-flex px-2 py-1">

//                                                                     <div class="d-flex flex-column justify-content-center">
//                                                                         <h6 class="mb-0 text-xs">{data.state}</h6>
//                                                                     </div>
//                                                                 </div>
//                                                             </td>
//                                                             <td>
//                                                                 <div class="d-flex px-2 py-1">

//                                                                     <div class="d-flex flex-column justify-content-center">
//                                                                         <h6 class="mb-0 text-xs">{moment(data.selected_date.toDate().toISOString()).format("MM/DD/YY, h:mm a")}</h6>
//                                                                     </div>
//                                                                 </div>
//                                                             </td>


//                                                         </tr>

//                                                     </>
//                                                 ))}


//                                             </tbody>
//                                         </table>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>

//                 </div>


//             </main>


//         </>
//     )
// }

// export default BookDemoRequest;