import React, { useState, useEffect } from 'react';
import { useNavigate, Link, useParams } from 'react-router-dom';
import db from "./Firebase";
import { arrayUnion } from "firebase/firestore";
import { storage } from './Firebase';
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import ViewAllRequests from './ViewAllRequests';
import { CSVLink } from "react-csv";
import Swal from 'sweetalert2'

function HireRequest() {
    const navigate = useNavigate();
    const moment = extendMoment(Moment);
    const [data, setData] = useState([]);
    const [port, setPort] = useState([]);
    // const [uid, setUid] = useState('');
    const [csvdata, setCsvdata] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [filterDate, setFilterDate] = useState(null); // State for date filter
    const [filterStatus, setFilterStatus] = useState(null); // State for status filter



    const headers = [
        { label: "Job Role", key: "job_role" },
        { label: "Experience", key: "experience" },
        { label: "Name", key: "name" },
        { label: "Username", key: "username" },
        { label: "name", key: "name" },
        { label: "Phone Number", key: "phone_no" },
        { label: "Additional Comment", key: "additional_comment" },
        { label: "Priority", key: "priority" },
        { label: "Salary Range", key: "salary_range" },
        { label: "Start Date", key: "start_date" },
        { label: "Type", key: "type" },
        { label: "Working Hours", key: "working_hours" },
        { label: "Created Date", key: "created_date", dataFormat: "MM/DD/YY, h:mm a" },
    ];


    const initialFormStateFeedback = { request_status: '', remark: '', call_status: '', updated_date: new Date() }
    const [submit, setSubmit] = useState(initialFormStateFeedback);


    // Additional fields for Status and Remark
    const [status, setStatus] = useState("Pending");
    const [remark, setRemark] = useState("");
    const [uid, setUid] = useState('');
    const [editIndex, setEditIndex] = useState();
    const [edit, setEdit] = useState(false);

    useEffect(() => {
        const storedUsername = localStorage.getItem("email");
        const storedPassword = localStorage.getItem("password");
        const storedRemember = localStorage.getItem("remember");

        if (storedRemember === "false" || !storedUsername || !storedPassword) {
            navigate('/signin');
        }
    }, []);

    const [doneCount, setDoneCount] = useState(0);

    useEffect(() => {
        const fetchData = () => {
            try {
                db.collection("hire_request")
                    .orderBy("created_date", "desc")
                    .onSnapshot((snapshot) => {
                        const dataArray = [];
                        let count = 0;

                        snapshot.forEach((doc) => {
                            const documentData = {
                                id: doc.id,
                                data: doc.data(),
                            };

                            dataArray.push(documentData);

                            // Check the status field and increment count
                            if (documentData.data.request_status === '1') {
                                count += 1;
                            }
                        });
                        setData(dataArray);
                        setDoneCount(count);
                        setFilteredData(dataArray);
                    });
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);


    useEffect(() => {
        const unsubscribePortfolio = db.collection("portfolio_details").onSnapshot((snapshot) => {
            setPort(
                snapshot.docs.map((doc) => ({
                    id: doc.id,
                    data: doc.data(),
                }))
            );
        });





        const unsubscribeHireRequest = db.collection('hire_request').orderBy('created_date', 'desc').onSnapshot((snapshot) => {
            // setData(
            //     snapshot.docs.map((doc) => ({
            //         id: doc.id,
            //         data: doc.data(),
            //     }))
            // );
            setCsvdata(
                snapshot.docs.map((doc) => {
                    const data = doc.data();
                    return {
                        ...data,
                        created_date: moment(data.created_date.toDate().toISOString()).format("MM/DD/YY, h:mm a"),
                    };
                })
            );

        });

        // Clean up the listeners when the component unmounts
        return () => {
            unsubscribePortfolio();
            unsubscribeHireRequest();
        };
    }, []);

    useEffect(() => {
        let filtered = data;

        // Filter by date
        if (filterDate) {
            filtered = filtered.filter(item => moment(item.data.created_date.toDate()).isSame(filterDate, 'day'));
        }

        // Filter by status
        if (filterStatus !== null && filterStatus !== undefined && filterStatus !== "") {
            filtered = filtered.filter(item => {
                // If status field is not available, assume it as "0"
                const status = item.data.request_status || "2";
                return status === filterStatus;
            });
        }

        setFilteredData(filtered);
    }, [filterDate, filterStatus, data]);

    // Helper function to map username with parlour name
    const mapUsernameToParlourName = (username) => {
        const portfolioItem = port.find((item) => item.data.username === username);
        return portfolioItem ? portfolioItem.data.parlour_name : 'Unknown'; // Return 'Unknown' if no match found
    };

    // useEffect(() => {
    //     db.collection("portfolio_details").onSnapshot((snapshot) => {

    //         setPort(
    //             snapshot.docs.map((doc) => ({
    //                 id: doc.id,
    //                 data: doc.data(),
    //             }))
    //         );
    //     });

    //     db.collection("hire_request").orderBy("created_date", "desc").onSnapshot((snapshot) => {

    //         setData(
    //             snapshot.docs.map((doc) => ({
    //                 id: doc.id,
    //                 data: doc.data(),
    //             }))
    //         );
    //     });

    // }, []);

    const notifyApp = (msg) => {
        // Call the user's CollectionReference to add a new user
        db.collection("app_notification").add({ 'message': msg, 'link': "", 'status': "0", "uid": uid, 'created_date': new Date() }).then(() => {
            console.log("notified");
        })
    }



    // const initialFormStateFeedback = { status: '' }
    // const [submit, setSubmit] = useState(initialFormStateFeedback)

    const handleChange2 = async (e, msg) => {
        const { id, value } = e.target
        setSubmit({ ...submit, [id]: value })
        if (e.target.value == "1") {
            // db.collection("business_card_request").doc(uid).update({ 'status': "1", 'updated_date': new Date() }).then(() => {
            //     console.log("user added");
            // })
            notifyApp(msg = 'Hi our executive tried getting in touch with you regarding hiring request. Kindly call us back at 7428790444 ');
        } else {
            // db.collection("business_card_request").doc(uid).update({ 'status': "0", 'updated_date': new Date() }).then(() => {
            //     console.log("user added");
            // })
        }
    }

    const collect = (id) => {
        setUid(id)
    }


    const callStatus = (uid, index) => {
        console.log(uid)
        console.log(index)
        setUid(uid);
        setEditIndex(index);
    }

    const handelChangeUID = (uid, index) => {
        setUid(uid);
        setEditIndex(index);
    }

    const handleRemarkChange = (event) => {
        setRemark(event.target.value);
    };

    const handleStatusChange = async (e) => {
        // setStatus(event.target.value);
        // const { id, value } = e.target
        // setSubmit({ ...submit, [id]: value })

        if (e.target.value == "2") {
            const { value: text } = await Swal.fire({
                input: 'textarea',
                inputLabel: 'Update Remark',
                inputPlaceholder: 'Type your message here...',
                inputAttributes: {
                    'aria-label': 'Type your message here'
                },
                showCancelButton: true
            })
            db.collection("hire_request").doc(uid).update({ 'request_status': "2", 'remark': text, 'updated_date': new Date() }).then(() => {
                console.log("data Updated");
            })

        } else {
            const { value: text } = await Swal.fire({
                input: 'textarea',
                inputLabel: 'Final Remark',
                inputPlaceholder: 'Type your message here...',
                inputAttributes: {
                    'aria-label': 'Type your message here'
                },
                showCancelButton: true
            })
            db.collection("hire_request").doc(uid).update({ 'request_status': "1", 'remark': text, 'updated_date': new Date() }).then(() => {
                console.log("data Updated");
            })
        }

    };

    const handleCallStatusChange = (e) => {
        console.log("calling.....")
        console.log(uid)
        db.collection("hire_request").doc(uid).update({ 'call_status': e.target.value, 'updated_date': new Date() }).then(() => {
            console.log("data Updated");
        })
        // console.log(e.target.value)
    }



    return (
        <>
            {/* <ViewAllRequests /> */}
            <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
                <div class="p-2" style={{ paddingBottom: "5px", display: "flex", justifyContent: "space-between" }}>
                    {/* <span class="btn bg-gradient-secondary btn-sm mb-0 mr-2" style={{ color: "white" }} >&nbsp; Download Excel</span> */}
                    <Link to="/admin_panel"><img src="/images/menubutton.png" class="menu-toggle" alt="Responsive image" style={{ height: "2rem" }} /></Link>
                    <CSVLink data={csvdata} headers={headers}>
                        <span class="btn bg-gradient-secondary btn-sm mb-0 mr-2" style={{ color: "white" }} >&nbsp; Download Excel</span>
                    </CSVLink>
                </div>
                <div class="">

                    <div class="p-2">
                        <div style={{ textAlign: "end", paddingBottom: "5px" }}>
                            {/* <Link to="/admin_panel" class="btn bg-gradient-primary btn-sm mb-0 mr-2" style={{ color: "white" }} >&nbsp; Go Back</Link> */}

                        </div>
                        <div class="card">
                            <div class="card-header pb-0 px-3">
                                <h6 class="mb-2">Hire Request</h6>
                            </div>
                            {/* <div class="d-flex p-2" style={{ justifyContent: "center", fontWeight: "bold" }}>
                                Total : {data.length} || Pending : {data.length - doneCount} || Done : {doneCount}
                            </div> */}
                            <div class="d-flex" style={{ justifyContent: "space-between" }}>
                                <div class="d-flex p-2" style={{ justifyContent: "center", fontWeight: "bold" }}>
                                    Total : {data.length} || Pending : {data.length - doneCount} || Done : {doneCount}
                                </div>
                                <div class="d-flex" style={{ padding: "6px" }}>
                                    <input type="date" id="filterDate" style={{ width: "11rem", marginRight: "2rem" }} className="form-control" value={filterDate ? moment(filterDate).format('YYYY-MM-DD') : ''} onChange={(e) => setFilterDate(e.target.value ? moment(e.target.value).toDate() : null)} />
                                    <select id="filterStatus" style={{ width: "11rem" }} className="form-control" onChange={(e) => { setFilterDate(null); setFilterStatus(e.target.value) }}>
                                        <option value="">All</option>
                                        <option value="2">Pending</option>
                                        <option value="1">Done</option>
                                    </select>
                                </div>
                            </div>
                            <div class="">
                                {/* <div style={{ textAlign: "end", paddingBottom: "5px" }}>
                        <a class="btn bg-gradient-primary btn-sm mb-0" style={{ color: "white" }} onClick={() => changeStatus()}>+&nbsp; Add Rent Details</a>
                    </div> */}
                                <div class="card">
                                    <div class="table-responsive fix-width scroll-inner">
                                        <table class="table table-bordered table-hover">
                                            <thead class="thead-dark" style={{ position: "sticky", top: "0" }}>
                                                <tr style={{ textAlign: "start" }}>
                                                    <th class="col ps-2">Job Role</th>
                                                    <th class="col">Phone No</th>
                                                    <th class="col">Name</th>
                                                    <th class="col">Parlour Name</th>
                                                    <th class="col ps-2">Experience</th>
                                                    <th class="col ps-2">Salary Range</th>
                                                    <th class="col ps-2">Type</th>
                                                    <th class="col ps-2">Working Hrs</th>
                                                    <th class="col ps-2">Start Date</th>
                                                    <th class="col ps-2">Priority</th>
                                                    {/* <th class="col">Action</th> */}
                                                    <th class="col ps-2">Additional Comment</th>
                                                    {/* <th class="col ps-2">Selected Days</th> */}
                                                    <th class="col">Call Status</th>
                                                    <th class="col">Status</th>
                                                    <th class="col">Final Remark</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {/* {data.map((item, id, index) => ( */}
                                                {filteredData?.map(({ id, data }, index) => (
                                                    <>
                                                        <tr>
                                                            <td class="pl-2" style={{ textAlign: "start" }}>
                                                                <p class="text-xs font-weight-bold mb-0">{data.job_role}</p>
                                                                <p class="text-xs text-secondary mb-0">{moment(data.created_date.toDate().toISOString()).format("MM/DD/YY, h:mm a")}</p>
                                                            </td>
                                                            <td class="pl-2" style={{ textAlign: "start" }}>
                                                                <div class="d-flex px-2 py-1">

                                                                    <div class="d-flex flex-column justify-content-center">
                                                                        <h6 class="mb-0 text-xs">{data.phone_no}</h6>
                                                                    </div>
                                                                </div>
                                                            </td>

                                                            <td class="pl-2" style={{ textAlign: "start" }}>
                                                                <div class="d-flex px-2 py-1">

                                                                    <div class="d-flex flex-column justify-content-center">
                                                                        <h6 class="mb-0 text-xs">{data.name}</h6>
                                                                    </div>
                                                                </div>
                                                            </td>

                                                            <td style={{ whiteSpace: 'pre-wrap' }}>
                                                                <p class="text-xs font-weight-bold mb-0" style={{ width: "15rem" }}>{data.username}</p>

                                                            </td>




                                                            <td class="pl-2" style={{ textAlign: "start" }}>
                                                                <div class="d-flex px-2 py-1">

                                                                    <div class="d-flex flex-column justify-content-center">
                                                                        <h6 class="mb-0 text-xs">{data.experience}</h6>
                                                                    </div>
                                                                </div>
                                                            </td>                     <td class="pl-2" style={{ textAlign: "start" }}>
                                                                <div class="d-flex px-2 py-1">

                                                                    <div class="d-flex flex-column justify-content-center">
                                                                        <h6 class="mb-0 text-xs">{data.salary_range}</h6>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td class="pl-2" style={{ textAlign: "start" }}>
                                                                <div class="d-flex px-2 py-1">

                                                                    <div class="d-flex flex-column justify-content-center">
                                                                        <h6 class="mb-0 text-xs">{data.type}</h6>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td class="pl-2" style={{ textAlign: "start" }}>
                                                                <div class="d-flex px-2 py-1">

                                                                    <div class="d-flex flex-column justify-content-center">
                                                                        <h6 class="mb-0 text-xs">{data.working_hours}</h6>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td class="pl-2" style={{ textAlign: "start" }}>
                                                                <div class="d-flex px-2 py-1">

                                                                    <div class="d-flex flex-column justify-content-center">
                                                                        <h6 class="mb-0 text-xs">
                                                                            {data.start_date}
                                                                        </h6>

                                                                    </div>
                                                                </div>
                                                            </td>

                                                            <td class="pl-2" style={{ textAlign: "start" }}>
                                                                <div class="d-flex px-2 py-1">

                                                                    <div class="d-flex flex-column justify-content-center">
                                                                        <h6 class="mb-0 text-xs">
                                                                            {data.priority}
                                                                        </h6>

                                                                    </div>
                                                                </div>
                                                            </td>

                                                            <td style={{ whiteSpace: 'pre-wrap' }}>
                                                                <p class="text-xs font-weight-bold mb-0" style={{ width: "15rem" }}>{data.additional_comment}</p>

                                                            </td>



                                                            {data.call_status ?
                                                                <>
                                                                    <td>
                                                                        <select class="form-control" id="call_status" name="call_status" onClick={() => callStatus(id, index)} onChange={handleCallStatusChange} value={data.call_status} style={{ width: "10rem", background: data.call_status === "Connected" ? "green" : data.callStatus === "Did Not Answer" ? "black" : "brown", color: "white" }}>
                                                                            <option value="" disabled>Call Status</option>
                                                                            <option selected={data.call_status == "Connected" ? true : false} value="Connected">Connected</option>
                                                                            <option selected={data.call_status == "Did Not Answer" ? true : false} value="Did Not Answer">Did Not Answer</option>
                                                                            <option selected={data.call_status == "Not Reachable" ? true : false} value="Not Reachable">Not Reachable</option>
                                                                        </select>
                                                                    </td>
                                                                </>
                                                                :
                                                                <>
                                                                    <td>
                                                                        <select class="form-control" id="call_status" name="call_status" onClick={() => callStatus(id, index)} onChange={handleCallStatusChange} value={submit.call_status} style={{ width: "10rem", }}>
                                                                            <option value="" disabled>Call Status</option>
                                                                            <option value="Connected">Connected</option>
                                                                            <option value="Did Not Answer">Did Not Answer</option>
                                                                            <option value="Not Reachable">Not Reachable</option>
                                                                        </select>
                                                                    </td>
                                                                </>
                                                            }

                                                            {data.request_status === "1" ?
                                                                <>
                                                                    <td>
                                                                        <select class="form-control" id="request_status" name="request_status" onClick={() => callStatus(id, index)} onChange={handleStatusChange} value={submit.request_status} style={{ width: "8rem", background: "green", color: "white" }}>
                                                                            <option value="" disabled>Done</option>
                                                                        </select>
                                                                    </td>
                                                                </>
                                                                :
                                                                <>
                                                                    <td>
                                                                        <select class="form-control" id="request_status" name="request_status" onClick={() => callStatus(id, index)} onChange={handleStatusChange} value={submit.request_status} style={{ width: "8rem" }}>
                                                                            <option value="" disabled>Pending</option>
                                                                            <option selected={data.request_status == "2" ? true : false} value="2">Update Status</option>
                                                                            <option selected={data.request_status == "1" ? true : false} value="1">Done</option>
                                                                        </select>
                                                                    </td>
                                                                </>
                                                            }


                                                            <td>
                                                                <textarea class="form-control" value={data.remark} onChange={handleRemarkChange} style={{ width: "10rem", background: "#ddd" }} disabled />
                                                            </td>


                                                        </tr>

                                                    </>
                                                    // ))}
                                                ))}


                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>


            </main>


        </>
    )
}

export default HireRequest;