import React, { useState, useEffect } from "react";
import { useNavigate, Link, useParams } from "react-router-dom"
import db from "./Firebase";
import axios from "axios";
// import { firebase, auth } from './FirebaseLogin';

function TopLink() {

    return (
        <>
            <div id="slider-container" class="slider">
                <div class="slide">
                    <img src="https://images.pexels.com/photos/2100868/pexels-photo-2100868.jpeg?auto=compress&cs=tinysrgb&w=600" alt="" />
                </div>
                <div class="slide">
                    <img src="https://images.pexels.com/photos/206329/pexels-photo-206329.jpeg?auto=compress&cs=tinysrgb&w=600" alt="" />
                </div>
                <div class="slide">
                    <img src="https://images.pexels.com/photos/1308885/pexels-photo-1308885.jpeg?auto=compress&cs=tinysrgb&w=600" alt="" />
                </div>
                <div class="slide">
                    <img src="https://images.pexels.com/photos/3014853/pexels-photo-3014853.jpeg?auto=compress&cs=tinysrgb&w=600" alt="" />
                </div>
                <div class="slide">
                    <img src="https://images.pexels.com/photos/157757/wedding-dresses-fashion-character-bride-157757.jpeg?auto=compress&cs=tinysrgb&w=600" alt="" />
                </div>
                {/* <div class="slide">
                    <img src="https://images.unsplash.com/photo-1511593358241-7eea1f3c84e5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=967&q=20" alt="" />
                </div> */}
                {/* <div class="slide">
                    <img src="https://images.unsplash.com/photo-1560259324-12a044e67c34?ixlib=rb-1.2.1&auto=format&fit=crop&w=967&q=20" alt="" />
                </div> */}
                {/* <div class="slide">
                    <img src="images.unsplash.com/photo-1532787799187-93655e51d472?ixlib=rb-1.2.1&auto=format&fit=crop&w=967&q=20" alt="" />
                </div> */}
                <div onClick={() => window.prevS()} class="control-prev-btn">
                    {`<`}
                </div>
                <div onClick={() => window.nextS()} class="control-next-btn">
                    {`>`}
                </div>
            </div>


            <div class="overlay"></div>
        </>
    );
}
export default TopLink;